import {Column} from 'react-table';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import ProblemStatusCell from '@src/components/tableCells/ProblemStatusCell';
import WarrantyClaimStatusCell from '@src/components/tableCells/WarrantyClaimStatusCell';
import ProblemFixCell from '@src/components/tableCells/ProblemFixCell/ProblemFixCell';
import OwnerAcceptanceStatusCell from '@src/components/tableCells/OwnerAcceptanceStatusCell';
import CheckInfoDesktopCell from '@src/components/tableCells/CheckInfoDesktopCell';
import OwnerAcceptanceInfoDesktopCell from '@src/components/tableCells/OwnerAcceptanceInfoDesktopCell';
import WarrantyClaimInfoDesktopCell from '@src/components/tableCells/WarrantyClaimInfoDesktopCell';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {extractSpaceProblemCommentsByProblem} from '@src/store/modules/entities/space/selectors';
import InternalAcceptanceInfoDesktopCell from '@src/components/tableCells/InternalAcceptanceInfoDesktopCell';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';

export const desktopColumns: Array<Column<ISpaceEntity>> = [
	{
		id: 'number',
		Header: HeaderCellWithRowSelectWrap('№, описание'),
		Cell: CellWrap({
			check: CheckInfoDesktopCell,
			'owner-acceptance': OwnerAcceptanceInfoDesktopCell,
			'internal-acceptance': InternalAcceptanceInfoDesktopCell,
			'warranty-claim': WarrantyClaimInfoDesktopCell,
			problem: ProblemInfoCellWrap(
				ProblemInfoDesktopCell,
				extractSpaceProblemCommentsByProblem
			),
			inspection: InspectionInfoDesktopCell
		}),
		width: 200,
		minWidth: 200
	},
	{
		id: 'status',
		Header: 'Статус',
		Cell: CellWrap({
			'owner-acceptance': OwnerAcceptanceStatusCell,
			'warranty-claim': WarrantyClaimStatusCell,
			problem: ProblemStatusCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'category',
		Header: 'Вид работ',
		Cell: CellWrap({
			problem: CategoryCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'fix',
		Header: 'Устранение',
		Cell: CellWrap({problem: ProblemFixCell}),
		width: 80,
		minWidth: 80
	}
];
