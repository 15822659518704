import React from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractWorkAcceptancesStatusesAsArray} from '@src/store/modules/dictionaries/workAcceptanceStatuses/selectors';

export const WorkAcceptancesStatusesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId?: string, entity?: string}>();

	const workAcceptanceStatuses = useAppSelector(extractWorkAcceptancesStatusesAsArray);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={workAcceptanceStatuses}
			label="Статус приёмок работ"
			filterName="statuses"
		/>
	);
};
