import {createSelector} from 'reselect';
import {getInitialStateForPage} from './reducers';
import {IState} from '@src/store/modules';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';

const getUserId = (state: IState) => state.auth.profile.id;

/**
 * Возвращает нарушения для конкретного объекта
 */

export const extractChecksData = createSelector(
	(state: IState) => state.entities.checks,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(data, objectId) => data[objectId] || getInitialStateForPage()
);

/**
 * Возвращает проверки в виде массива
 */
export const extractChecksAsArray = createSelector([extractChecksData], data =>
	data.allIds.map(id => data.byId[id]));

/**
 * Возвращает проверки которые создавал пользователь в виде массива
 */
export const extractChecksCreatedByUserAsArray = createSelector(
	[extractChecksAsArray, getUserId],
	(data: ICheck[], userId: string) => data.filter(check => check.createdBy?.id === userId)
);
