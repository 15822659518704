import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';

interface IPageBreadcrumbsProps {
	children?: React.ReactNode;
}

const AllObjectPageBreadcrumbs = ({children}: IPageBreadcrumbsProps) => (
	<AppBreadcrumbs
		className="problems-page__breadcrumbs"
		items={[]}
	>
		{children}
	</AppBreadcrumbs>
);

export default memo(AllObjectPageBreadcrumbs);
