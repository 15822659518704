import React from 'react';
import {EditorInfo, EntityGrid, EntityGridItem, InlineButton, Plate} from '@tehzor/ui-components';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import Documents from './Documents';
import {useEditableInternalAcceptanceAttachmentsDialog} from '@src/components/EditableInternalAcceptanceAttachmentsDialog/hooks/useEditableInternalAcceptanceAttachmentsDialog';
import {useInternalAcceptancePermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';
import DescriptionDesktop from './Description.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import DescriptionMobile from './Description.mobile';

interface IDesktopInfoProps {
	objectId: string;
	internalAcceptance: ILinkedInternalAcceptance;
}

const DesktopInfo = ({objectId, internalAcceptance}: IDesktopInfoProps) => {
	// Диалог редактирования вложений
	const [editingAttachmentsDialog, openEditAttachmentsDialog]
		= useEditableInternalAcceptanceAttachmentsDialog(
			objectId,
			internalAcceptance.id,
			internalAcceptance
		);
	const permissions = useInternalAcceptancePermissions(objectId, internalAcceptance);
	const isDesktop = useIsDesktop();
	return (
		<Plate>
			<EntityGrid>
				{isDesktop ? (
					<DescriptionDesktop
						objectId={objectId}
						internalAcceptanceId={internalAcceptance.id}
						value={internalAcceptance.description}
						canEdit={permissions.canEdit}
					/>
				) : (
					<DescriptionMobile
						objectId={objectId}
						internalAcceptanceId={internalAcceptance.id}
						value={internalAcceptance.description}
						canEdit={permissions.canEdit}
					/>
				)}

				<EntityGridItem
					label="Документы"
					icon={<i className="tz-document-20"/>}
					fullRow
					buttons={
						permissions.canEdit ? (
							<InlineButton
								className="internal-acceptance-page__documents-edit-icon"
								type="accent"
								leftIcon={<i className="tz-edit-16"/>}
								onClick={openEditAttachmentsDialog}
							/>
						) : null
					}
				>
					<Documents internalAcceptance={internalAcceptance}/>
				</EntityGridItem>
				<EditorInfo
					icon={<i className="tz-document-20"/>}
					label="Создано"
					date={internalAcceptance.createdAt}
					user={internalAcceptance.createdBy}
					fullRow
				/>
				{internalAcceptance.createdAt !== internalAcceptance.modifiedAt && (
					<EditorInfo
						icon={<i className="tz-edit-20"/>}
						label="Изменено"
						date={internalAcceptance.modifiedAt}
						user={internalAcceptance.modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default DesktopInfo;
