import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {IGetWarrantyClaimPayload} from './actions/get';
import {
	IGetWarrantyClaimProblemsPayload,
	IEditWarrantyClaimStatusPayload,
	IMoveWarrantyClaimPayload,
	IEditWarrantyClaimPayload,
	IGetWarrantyClaimProblemRepliesPayload
} from './actions';
import {
	IAddProblemPayload,
	IDeleteProblemPayload,
	IEditProblemStatusPayload
} from '@src/store/modules/pages/problem/actions';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {IAddProblemCommentPayload} from '@src/store/modules/entities/problemComment/actions';

const {entitiesCacheTime} = config;

export interface IWarrantyClaimState {
	data?: ILinkedWarrantyClaim;
	expires?: number;
	problems: {
		byId: Record<string, IListProblem>;
		allIds: string[];
		expires?: number;
	};
	problemReplies: {
		byId: Record<string, IProblemComment>;
		allIds: string[];
		expires?: number;
	};
}

export const getInitialState = (): IWarrantyClaimState => ({
	problems: {
		byId: {},
		allIds: []
	},
	problemReplies: {
		byId: {},
		allIds: []
	}
});

export default createReducer<IWarrantyClaimState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetWarrantyClaimPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditWarrantyClaimPayload}) => {
		state.data = {...state.data, ...payload};
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_STATUS_SUCCESS]: (state, {payload}: {payload: IEditWarrantyClaimStatusPayload}) => {
		state.data = {...state.data, ...payload};
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.MOVE_SUCCESS]: (state, {payload}: {payload: IMoveWarrantyClaimPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Загрузка сущностей
	[types.GET_PROBLEMS_SUCCESS]: (
		state,
		{payload}: {payload: IGetWarrantyClaimProblemsPayload}
	) => {
		state.problems.byId = payload.byId;
		state.problems.allIds = payload.allIds;
		state.problems.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_PROBLEM_REPLIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetWarrantyClaimProblemRepliesPayload}
	) => {
		state.problemReplies.byId = payload.byId;
		state.problemReplies.allIds = payload.allIds;
		state.problemReplies.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Нарушения
	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
		if (state.data?.id === payload.links?.warrantyClaimId) {
			state.problems.byId[payload.id] = payload;
			state.problems.allIds.unshift(payload.id);
		}
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemStatusPayload}
	) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state.problems.allIds.includes(payload.problemId)) {
			state.problems.allIds = state.problems.allIds.filter(id => id !== payload.problemId);
			delete state.problems.byId[payload.problemId];
		}
	},
	// Ответы на нарушения
	[problemReplyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
		if (state.data?.id === payload.links?.warrantyClaimId) {
			state.problemReplies.byId[payload.id] = payload;
			state.problemReplies.allIds.unshift(payload.id);
		}
	},
	[CLEAR_STORE]: getInitialState
});
