import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {requestProblems} from '@src/api/backend/problems';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetWorkAcceptanceProblemsPayload = INormalizedData<IListProblem>;

const request = () => ({type: types.GET_PROBLEMS_REQUEST});

const success = (response: IGetWorkAcceptanceProblemsPayload) => ({
	type: types.GET_PROBLEMS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке нарушений');
	return {
		type: types.GET_PROBLEMS_FAILURE,
		payload: error
	};
};

/**
 * Получает список нарушений для конкретной приёмки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приёмки работ
 */
export const getWorkAcceptanceProblems = (objectId: string, workAcceptanceId: string) =>
	checkExpiration<IState, IGetWorkAcceptanceProblemsPayload, ApiAction>(
		s => s.entities.workAcceptance.problems,
		createApiAction<IGetWorkAcceptanceProblemsPayload>(request, success, failure, () =>
			requestProblems(
				{
					objects: [objectId],
					workAcceptanceIds: [workAcceptanceId]
				},
				{createdAt: false},
				0,
				1000
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);
