import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editProfile} from '@src/store/modules/auth/profile/actions';
import {Toggle} from '@tehzor/ui-components';
import React, {ChangeEvent, useCallback} from 'react';

interface ISubscribeSettingsProps {
	value?: boolean;
}

export const SubscribeSettings = ({value}: ISubscribeSettingsProps) => {
	const dispatch = useAppDispatch();
	const handleChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
		await dispatch(editProfile({subscribed: event.target?.checked}));
	}, []);

	return (
		<div>
			<Toggle
				checked={value}
				onChange={handleChange}
			>
				Получение уведомлений на электронную почту
			</Toggle>
		</div>
	);
};