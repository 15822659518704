import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetCheckResponse} from '@src/api/backend/check';
import {queryClient} from '@src/api/QueryClient';
import {checksQueryKeys} from '@src/api/cache/checks/keys';

export type IGetOneUserCheckPayload = IGetCheckResponse;

const request = () => ({
	type: types.GET_ONE_REQUEST
});

const success = (response: IGetCheckResponse) => ({
	type: types.GET_ONE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке проверки');
	return {
		type: types.GET_ONE_FAILURE,
		payload: error
	};
};

/**
 * Получает одну проверку по id
 */
export const getUserCheck = (objectId: string, checkId: string) =>
	createApiAction<IGetCheckResponse>(request, success, failure, () => {
		const data = queryClient.fetchQuery<IGetCheckResponse>({
			queryKey: [...checksQueryKeys.detail(checkId), objectId],
			staleTime: 0
		});
		return data;
	});
