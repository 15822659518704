import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {deleteDevice} from '@src/store/modules/entities/devices/actions/delete';
import {Button, Dialog, IconButton} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import React from 'react';
import {useAsyncFn} from 'react-use';
import './DevicesHistoryDialog.less';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {multipleLogout} from '@src/store/modules/auth/actions/multipleLogout';
import {extractFilteredDevicesAsArray} from '@src/store/modules/entities/devices/selectors';
import {logout} from '@src/store/modules/auth/actions';
import {useGetDeviceId} from '@src/core/hooks/useGetDeviceId';

interface IDevicesHistoryDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

const deleteIcon = <i className="tz-delete"/>;
const checkIcon = <i className="tz-notifications-status"/>;

const DevicesHistoryDialog = ({isOpen, onClose}: IDevicesHistoryDialogProps) => {
	const devices = useAppSelector(extractFilteredDevicesAsArray);
	const currentDeviceId = useGetDeviceId();

	const dispatch = useAppDispatch();

	const [logoutDialog, getLogoutConfirmation] = useConfirmDialog(
		'Выйти',
		'Вы действительно хотите выйти из устройства?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [multipleLogoutDialog, getMultipleLogoutConfirmation] = useConfirmDialog(
		'Выйти',
		'Вы действительно хотите выйти со всех устройств?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async (id: string) => {
		if (await getLogoutConfirmation()) {
			await dispatch(deleteDevice(id));

			if (currentDeviceId === id) {
				await dispatch(logout());
			}
		}
	});

	const [, handleMultipleLogout] = useAsyncFn(async () => {
		if (await getMultipleLogoutConfirmation()) {
			await dispatch(multipleLogout());
		}
	});

	return (
		<Dialog
			className="devices-history-dialog"
			isOpen={isOpen}
			title="История сессий"
			fullScreenOnTablet
			onRequestClose={onClose}
			footer={(
				<Button
					type="accent-red"
					label="Выйти со всех устройств"
					onClick={handleMultipleLogout}
				/>
			)}
		>
			{devices.map(({id, platform, browser, os, lastUsedAt, createdAt}) => (
				<div
					className="devices-history-dialog__plate-card"
					key={id}
				>
					<div className="devices-history-dialog__plate-card__content">
						<div className="devices-history-dialog__plate-card__content-item">
							<span>Платформа:</span>
							{' '}
							{platform}
						</div>
						<div className="devices-history-dialog__plate-card__content-item">
							<span>Операционная система:</span>
							{' '}
							{os}
						</div>
						<div className="devices-history-dialog__plate-card__content-item">
							<span>Браузер:</span>
							{' '}
							{browser}
						</div>
						<div className="devices-history-dialog__plate-card__content-item">
							<span>Первый вход в аккаунт:</span>
							{' '}
							{format(new Date(createdAt || 0), dateTimeCommaSeparatedFormat)}
						</div>
						<div className="devices-history-dialog__plate-card__content-item">
							<span>Недавние действия:</span>
							{' '}
							{format(new Date(lastUsedAt || 0), dateTimeCommaSeparatedFormat)}
						</div>
						{id === currentDeviceId ? (
							<div className="devices-history-dialog__plate-card__content-item">
								{checkIcon}
								<span>Текущий сеанс</span>
							</div>
						) : null}
					</div>
					<IconButton
						className="devices-history-dialog__plate-card-btn"
						type="inline-red-accent"
						onClick={() => handleDelete(id)}
					>
						{deleteIcon}
					</IconButton>
				</div>
			))}
			{logoutDialog}
			{multipleLogoutDialog}
		</Dialog>
	);
};

export default DevicesHistoryDialog;
