import React from 'react';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {AllWorkAcceptancesPage} from '@src/pages/WorkAcceptancesPage';
import {WorkAcceptancesFiltersRoutingPage} from '@src/pages/filters/WorkAcceptancesFiltersRoutingPage';
import {workAcceptancesFilterRoutes} from './workAcceptancesFilterRoutes';

export const workAcceptancesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllWorkAcceptancesPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<WorkAcceptancesFiltersRoutingPage/>}/>,
		children: workAcceptancesFilterRoutes
	}
];
