import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetCheckRecordsResponse} from '@src/api/backend/checkRecords';
import * as types from '../../constants/records';
import {queryClient} from '@src/api/QueryClient';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {getCachedCheckRecords} from '@src/store/persistentStorage/offlineActions/checkRecords';

export type IGetCheckRecordsPayload = IGetCheckRecordsResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetCheckRecordsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {...response}
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка записей');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список записей по категории
 *
 * @param checkListIds идентификатор чек-листа
 * @param objectsIds идентификаторы объектов
 * @param spaceId идентификатор помещения
 * @param workAcceptanceIds идентификаторы приемок работ
 */
export const getCheckRecords
	= (
		checkListIds: string[],
		objectsIds?: string[],
		spaceId?: string,
		workAcceptanceIds?: string[]
	): AppThunkAction<Promise<IGetCheckRecordsResponse>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetCheckRecordsResponse>(request, success, failure, async () => {
				const s = getState();
				if (s.settings.offlineMode.available && !s.offlineMode.networkStatus) {
					const offlineData = await getCachedCheckRecords(
						checkListIds,
						objectsIds,
						spaceId,
						workAcceptanceIds
					);
					return offlineData;
				}
				const data = await queryClient.fetchQuery<IGetCheckRecordsResponse>(
					[
						...checkRecordsQueryKeys.list(),
						checkListIds,
						objectsIds,
						spaceId,
						workAcceptanceIds
					],
					{staleTime: Infinity}
				);
				return data;
			})
		);
