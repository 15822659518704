import React from 'react';
import './WorkAcceptancesPage.less';
import classNames from 'classnames';
import useAsync from 'react-use/lib/useAsync';
import {LoadingPanel} from '@tehzor/ui-components';
import {WorkAcceptancesPageCounter} from './components/WorkAcceptancesPageHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getWorkAcceptances} from '@src/store/modules/entities/workAcceptances/actions';
import {WorkAcceptancesFilters} from './components/filters/WorkAcceptancesFilters';
import {WorkAcceptancesTable} from './components/table/WorkAcceptancesTable';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {ObjectPageBreadcrumbs} from './components/ObjectPageBreadcrumbs';
import {getStructuresList} from '@src/store/modules/entities/structures/actions';
import {useParams} from 'react-router-dom';

export const WorkAcceptancesPage = ({className}: {className?: string}) => {
	useScrollRestoration();

	const {objectId} = useParams<{objectId?: string}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {state} = useEntitiesFiltersCtx();

	const loadingState = useAsync(async () => {
		await dispatch(getWorkAcceptances(objectId || 'all', state));
		if (objectId) {
			await dispatch(getStructuresList(objectId));
		}
	}, [objectId, state]);

	return (
		<LoadingPanel
			className="work-acceptances-page__loading-panel"
			active={loadingState.loading}
		>
			<div
				className={classNames(
					'page-cont',
					'work-acceptances-page',
					{'work-acceptances-page_small-margin': !objectId},
					className
				)}
			>
				{objectId !== undefined && (
					<ObjectPageBreadcrumbs
						isDesktop={isDesktop}
						objectId={objectId}
					/>
				)}
				<div className="work-acceptances-page__filters-bar">
					<WorkAcceptancesFilters objectId={objectId} />
				</div>
				<WorkAcceptancesPageCounter objectId={objectId} />
				<WorkAcceptancesTable objectId={objectId} />
			</div>
		</LoadingPanel>
	);
};
