import {PrivateRoute} from '@src/containers/PrivateRoute';
import OfflineJournalFiltersRoutingPage from '@src/pages/filters/OfflineJournalFiltersRoutingPage';
import React from 'react';

export const offlineJournalFilterRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<OfflineJournalFiltersRoutingPage/>}/>
	}
];
