import React from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';

export const StructureTypeCell = ({row}: CellProps<IEnrichedStructure>) => {
	const structure = row.original;
	const structureTypes = useAppSelector(s => s.dictionaries.structureTypes);

	return <>{structureTypes.byId[structure.type]?.name ?? ''}</>;
};
