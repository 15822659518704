import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {RESET} from '@src/store/modules/entities/spaceTypeDecorationSets/constants';
import {IEditSpaceTypeDecorationResponse} from '@src/api/backend/spaceTypeDecoration';

export type ISpaceTypeDecorationsState = ILoadedEntityState<ISpaceTypeDecoration>;

export default createReducer<ISpaceTypeDecorationsState>(
	getLoadedEntitiesInitialState<ISpaceTypeDecoration>(),
	{
		[types.GET_REQUEST]: getLoadedEntitiesInitialState,
		[types.GET_SUCCESS]: getLoadedEntitiesSuccess<ISpaceTypeDecoration>(),
		[types.ADD_SUCCESS]: (state, {payload}: {payload: ISpaceTypeDecoration[]}) => {
			payload.forEach(el => {
				state.byId[el.id] = el;
				state.allIds.push(el.id);
			});
		},
		[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditSpaceTypeDecorationResponse}) => {
			payload.created.forEach(el => {
				state.byId[el.id] = el;
				state.allIds.push(el.id);
			});
			payload.edited.forEach(el => {
				state.byId[el.id] = el;
			});
			payload.deleted.forEach(el => {
				delete state.byId[el.id];
				state.allIds.filter(id => id !== el.id);
			});
		},
		[CLEAR_STORE]: getLoadedEntitiesInitialState,
		[RESET]: getLoadedEntitiesInitialState
	}
);
