import React, {Dispatch, useCallback} from 'react';
import './CriticalCheckbox.less';
import {Checkbox} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';

interface ICriticalCheckboxProps<S, E> {
	value?: boolean;
	plannedFixDateValue?: number | null;
	disabled?: boolean;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
}

export const CriticalCheckbox = <S extends {plannedFixDate?: number | null, critical: boolean}, E>(
	props: ICriticalCheckboxProps<S, E>
) => {
	const {value, plannedFixDateValue, disabled, editingDispatch} = props;

	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		editingDispatch({type: 'update', field: 'critical', value: checked});

		if (checked) {
			editingDispatch({
				type: 'update',
				field: 'plannedFixDate',
				value: Date.now() + 86400000 // +24 часа
			});
		} else {
			// plannedFixDateValue намеренно не добавлен в deps, чтобы хранить первоначальное значение
			editingDispatch({type: 'update', field: 'plannedFixDate', value: plannedFixDateValue});
		}
	}, []);

	return (
		<Checkbox
			className="editable-critical-checkbox"
			checked={value}
			onChange={handleChange}
			disabled={disabled}
		>
			<span className="editable-critical-checkbox__label">Критично</span>
			<span className="editable-critical-checkbox__explanation">(24 часа на устранение)</span>
		</Checkbox>
	);
};
