import {useMemo} from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceStatusesDataByObjectId} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

/**
 * Возвращает стадию помещения исходя из его статуса
 *
 * @param objectId id объекта
 * @param space помещение
 */
export function useSpaceStage(objectId: string, space?: ISpace) {
	const statuses = useAppSelector(s => extractSpaceStatusesDataByObjectId(s, objectId));

	return useMemo(
		() => (space ? statuses.byId[space.status]?.stage : ObjectStageIds.BUILDING),
		[space, statuses]
	);
}
