import {Column} from 'react-table';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {CreatedCell} from './cells/CreatedCell';
import {NameCell} from './cells/NameCell';

export const desktopColumns: Array<Column<IDocument>> = [
	{
		id: 'name',
		Header: 'Название',
		width: 100,
		Cell: NameCell
	},
	{
		id: 'exportTemplate',
		Header: 'Шаблон экспорта',
		accessor: row => (row.exportTemplate ? row.exportTemplate?.name : ''),
		width: 100
	},
	{
		id: 'company',
		Header: 'Компания',
		accessor: row => (row.company ? row.company.name : ''),
		width: 100
	},
	{
		id: 'contractor',
		Header: 'Подрядчик',
		accessor: row => (row.contractor ? row.contractor.name : ''),
		width: 80,
		sortDescFirst: true
	},
	{
		id: 'created',
		Header: 'Сформировано',
		width: 80,
		Cell: CreatedCell
	}
];
