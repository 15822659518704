import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {
	extractObject,
	extractObjectsAsArrayByIds
} from '@src/store/modules/dictionaries/objects/selectors';
import {normalizeData} from '@tehzor/tools/utils/normalizeData';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import ISpaceIndicatorsSet from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicatorsSet';

// const extractAllIds = (state: IState) => state.entities.spaceIndicatorsSets.allIds || [];
const extractById = (state: IState): Record<string, ISpaceIndicatorsSet> =>
	state.dictionaries.spaceIndicatorsSets.byId || {};

/**
 * Возвращает индикаторы для конкретного объекта
 */
export const extractSpaceIndicatorsAsMap = createSelector(
	[extractById, extractObject],
	(byId, object) => {
		const s = byId[object.spaceIndicatorsSet];
		return normalizeData<ISpaceIndicator>(s ? s.data : []);
	}
);

/**
 * Возвращает индикаторы для нескольких объектов
 */
export const extractSpaceIndicatorsForObjectsAsMap = createSelector(
	[extractById, extractObjectsAsArrayByIds],
	(byId, objects) =>
		normalizeData<ISpaceIndicator>(
			Array.from(
				new Set(objects
					.filter(object => byId[object.spaceIndicatorsSet])
					.map(object => byId[object.spaceIndicatorsSet].data).flat())
			) || []
		)
);

/**
 * Возвращает индикаторы для конкретного объекта в виде массива
 */
export const extractSpaceIndicatorsAsArray = createSelector(
	[extractById, extractObject],
	(byId, object) => {
		const s = byId[object.spaceIndicatorsSet];
		return s ? s.data : [];
	}
);
