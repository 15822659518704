import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetInspectionsResponse extends INormalizedData<IListInspection> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список осмотров
 */
export const requestInspections = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) =>
	wsConnector.sendAuthorizedRequest<IGetInspectionsResponse>('getInspections', {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	});
