import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemTypes from '../../pages/problem/constants';
import * as checkTypes from '@src/store/modules/entities/check/constants';
import * as spaceTypes from '@src/store/modules/entities/space/constants';
import * as categoryTypes from '@src/store/modules/dictionaries/categories/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import {IGetProblemsPayload} from './actions';
import {
	IAddProblemPayload,
	IDeleteProblemPayload,
	IEditProblemPayload,
	IEditProblemStatusPayload
} from '@src/store/modules/pages/problem/actions';
import {CHANGE_SORT, CLEAR_FILTERS} from '@src/store/modules/settings/pages/problems/constants';
import * as networkTypes from '@src/store/modules/offlineMode/constants';
import {IUpdateProblemLocation} from './actions/updateProblemLocation';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

const {entitiesCacheTime} = config;

export interface IProblemsPageState {
	byId: {
		[id: string]: IListProblem;
	};
	allIds: string[];
	offset: number;
	total: number;
	expires?: number;
	selectedRows: string[];
	isLoading?: boolean;
}

export interface IProblemsPagesState {
	[objectId: string]: IProblemsPageState;
}

export const getInitialStateForPage = (): IProblemsPageState => ({
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	expires: undefined,
	selectedRows: [],
	isLoading: undefined
});

const clearPage = (state: IProblemsPagesState, {payload}: {payload: {objectId: string}}) => {
	if (payload.objectId !== 'all') {
		state[payload.objectId] = getInitialStateForPage();
	}
	state[payload.objectId] = getInitialStateForPage();
};

const updateProblem = (state: IProblemsPagesState, {payload}: {payload: IEditProblemPayload}) => {
	if (state.all) {
		const page = state.all;
		page.expires = undefined;
	}
	if (state[payload.objectId]) {
		const page = state[payload.objectId];
		page.expires = undefined;
	}
};

const deleteProblem = (state: IProblemsPagesState, objectId: string, problemId: string) => {
	const page = state[objectId];
	page.allIds = page.allIds.filter(id => id !== problemId);
	delete page.byId[problemId];
	page.expires = undefined;
};

export default createReducer<IProblemsPagesState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetProblemsPayload}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			const page = state[payload.objectId];
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.offset = payload.offset;
			page.total = payload.total;
			page.expires = Date.now() + entitiesCacheTime * 1000;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string, offset: number}}
		) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			const page = state[payload.objectId];
			if (page) {
				page.offset = payload.offset;
				page.expires = undefined;
			}
		},
		[types.CHANGE_LOADING]: (
			state,
			{payload}: {payload: {objectId: string, loading: boolean}}
		) => {
			const page = state[payload.objectId];
			page.isLoading = payload.loading;
		},
		[types.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: {objectId: string, selectedRows: string[]}}
		) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		[types.CLEAR_EXPIRATION]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state && state?.[payload.objectId]?.expires) {
				state[payload.objectId].expires = undefined;
			}
		},
		// При сортировке нужно обнулить expires для игнорирования кеша
		[CHANGE_SORT]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			state[payload.objectId].expires = undefined;
		},
		// При сбрасывании фильтров нужно обнулить expires для игнорирования кеша
		[CLEAR_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			state[payload.objectId].expires = undefined;
		},
		// При манипуляциях с затрагивающими данными необходимо обновить нарушения
		[spaceTypes.ADD_SUCCESS]: clearPage,
		[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].expires = undefined;
			}
			if (state.all) {
				state.all.expires = undefined;
			}
		},
		[problemTypes.COPY_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].expires = undefined;
			}
			if (state.all) {
				state.all.expires = undefined;
			}
		},
		[problemTypes.EDIT_STATUS_SUCCESS]: (
			state,
			{payload}: {payload: IEditProblemStatusPayload}
		) => {
			if (state[payload.objectId] && state[payload.objectId].byId[payload.id]) {
				state[payload.objectId].byId[payload.id].status = payload.status;
			}
			if (state.all && state.all.byId[payload.id]) {
				state.all.byId[payload.id].status = payload.status;
			}
		},
		[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
			if (payload.objectId !== 'all' && state[payload.objectId]) {
				deleteProblem(state, payload.objectId, payload.problemId);
			}
			if (state.all) {
				deleteProblem(state, 'all', payload.problemId);
			}
		},
		[problemTypes.EDIT_SUCCESS]: updateProblem,
		[checkTypes.DELETE_SUCCESS]: clearPage,
		[categoryTypes.EDIT_SUCCESS]: clearPage,
		[categoryTypes.DELETE_SUCCESS]: clearPage,
		[networkTypes.CHANGE_NETWORK_STATUS]: (state, {payload}: PayloadAction<boolean>) =>
			(payload === false ? {} : getInitialStateForPage()),
		[CLEAR_STORE]: () => ({}),
		[types.UPDATE_PROBLEM_LOCATION]: (
			state,
			{payload}: PayloadAction<IUpdateProblemLocation>
		) => {
			if (state[payload.objectId]) {
				if (payload.location) {
					state[payload.objectId].byId[payload.problemId].location = payload.location;
				}
			}
		},
		[CLEAR_BEFORE_UPDATE]: () => {
			void queryClient.invalidateQueries(problemsQueryKeys.all());
			void queryClient.invalidateQueries(problemsQueryKeys.one());
		}
	}
);
