import {wsConnector} from '../wsConnector';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IAddInternalAcceptanceResponse = IInternalAcceptance;

/**
 * Добавляет внутреннюю приёмку
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 */
export const makeInternalAcceptanceAddRequest = (
	objectId: string,
	links: IInternalAcceptance['links'] | undefined,
	stage: ObjectStageIds = ObjectStageIds.ACCEPTANCE
) => wsConnector.sendAuthorizedRequest<IAddInternalAcceptanceResponse>('addInternalAcceptance', {objectId, links, stage});
