import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetWarrantyClaimsResponse extends INormalizedData<IListWarrantyClaim> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список гарантийных обращений
 */
export const requestWarrantyClaims = (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) =>
	wsConnector.sendAuthorizedRequest<IGetWarrantyClaimsResponse>('getWarrantyClaims', {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	});
