import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/reducers';
import {queryClient} from '@src/api/QueryClient';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';

export interface IGetInternalAcceptancesPayload extends IGetInternalAcceptancesResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetInternalAcceptancesResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка внутренних приемок');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

export const getInternalAcceptancesActions = {request, success, failure};
/**
 * Получает список проверок
 */
export const getInternalAcceptances
	= (
		objectId: string,
		state?: IInternalAcceptancesFiltersState
	): AppThunkAction<Promise<IGetInternalAcceptancesResponse>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetInternalAcceptancesResponse>(
				() => request(objectId),
				(res: IGetInternalAcceptancesResponse) => success(objectId, res),
				failure,
				() => {
					const s = getState();
					if (
						!s.entities.internalAcceptances[objectId]
						|| !s.settings.pages.internalAcceptances[objectId]
					) {
						throw new StoreError('Empty store before getting internalAcceptances');
					}
					const {offset} = s.entities.internalAcceptances[objectId];
					const {filters, sort, pageSize}
						= s.settings.pages.internalAcceptances[objectId];
					const data = queryClient.fetchQuery<IGetInternalAcceptancesResponse>({
						queryKey: [
							...internalAcceptancesQueryKeys.list(),
							state || filters,
							sort,
							offset,
							pageSize
						],
						staleTime: Infinity
					});
					return data;
				}
			)
		);
