import {wsConnector} from '../wsConnector';
import {ISavingTask} from '@tehzor/tools/interfaces/tasks/ISavingTask';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

export type IAddTaskResponse = ITask;

/**
 * Добавляет задачу
 *
 * @param fields данные задачи
 */
export const makeTaskAddRequest = (fields: ISavingTask) =>
	wsConnector.sendAuthorizedRequest<IAddTaskResponse>('addTask', {...fields});
