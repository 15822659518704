import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUsersAsArray} from '@src/store/modules/dictionaries/users/selectors';
import {MobileFilter} from '@tehzor/ui-components';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {useFilterPageTransition} from '../utils/pagesRouting';

interface IInspectorsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (change: IChanges) => void;
}

export const InspectorsFilterMobile = memo(({
	objectId, value, entity, onChange
}: IInspectorsFilterMobileProps) => {
	const [selectedUsers, setSelectedUsers] = useState(value);
	const [search, setSearch] = useState('');
	useUpdateEffect(() => setSelectedUsers(value), [value]);

	const users = useAppSelector(extractUsersAsArray);
	const elements = useMemo(() =>
		users.map(({id, fullName}) => ({id, name: fullName})), [users]);
	const filteredData = useMemo(() =>
		flatFilter(elements, 'name', search), [elements, search]);

	const handleExpand = useFilterPageTransition(objectId, entity, 'inspectors');

	const handleChange = useCallback((v?: string[]) => {
		onChange({inspectors: v});
	}, []);

	return (
		<MobileFilter
			label="Проверяющие"
			elements={filteredData}
			selected={selectedUsers}
			onChange={handleChange}
			onExpand={handleExpand}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
		/>
	);
});