import {createReducer} from '@reduxjs/toolkit';
import {IProblemView} from '@tehzor/tools/interfaces/problemView/IProblemView';
import * as types from './constants';
import {IAddProblemViewPayload, IAddProblemViewRequestPayload, IGetProblemViewsPayload, IGetProblemViewsRequestPayload} from './actions';

export interface IProblemViewsState {
	byId: {
		[id: string]: IProblemView;
	};
	allIds: string[];
	isLoading?: boolean;
}

export interface IProblemsViewsState {
	[problemId: string]: IProblemViewsState;
}

export const getInitialState = (): IProblemViewsState => ({
	byId: {},
	allIds: [],
	isLoading: undefined
});

export const problemViews = createReducer<IProblemsViewsState>(
	{},
	{
		[types.GET_REQUEST]: (
			state,
			{payload}: {payload: IGetProblemViewsRequestPayload}
		) => {
			if (!state.hasOwnProperty(payload.problemId)) {
				state[payload.problemId] = getInitialState();
			}
			state[payload.problemId].isLoading = true;
		},
		[types.GET_SUCCESS]: (
			state,
			{payload}: {payload: IGetProblemViewsPayload}
		) => {
			const views = state[payload.problemId];

			views.allIds = payload.allIds;
			views.byId = payload.byId;
			views.isLoading = false;
		},
		[types.ADD_REQUEST]: (
			state,
			{payload}: {payload: IAddProblemViewRequestPayload}
		) => {
			if (!state.hasOwnProperty(payload.problemId)) {
				state[payload.problemId] = getInitialState();
			}
			state[payload.problemId].isLoading = true;
		},
		[types.ADD_SUCCESS]: (
			state,
			{payload}: {payload: IAddProblemViewPayload}
		) => {
			const views = state[payload.problemId];

			if (!views.allIds.includes(payload.id)) {
				views.allIds.push(payload.id);
				views.byId[payload.id] = payload;
			}
		}
	}
);