import React, {useCallback} from 'react';
import {format} from 'date-fns';
import IconButton from '../buttons/IconButton';

const dateTimeLetterSeparatedFormat = 'dd.MM.yyyy, в HH:mm';

const bucketIcon = <i className="tz-delete notification__delete-btn-icon"/>;

const defaultEntityIcon = <i className="tz-notifications-default-entity notification__icons-entity"/>;
const defaultTypeIcon = <i className="tz-notifications-default-type notification__icons-type-default"/>;

// const addIcon = <i className="tz-notifications-add notification__icons-type-add"/>;
// const editIcon = <i className="tz-notifications-edit notification__icons-type-edit"/>;
// const deleteIcon = <i className="tz-notifications-delete notification__icons-type-delete"/>;
//
// const statusWaitingIcon = <i className="tz-notifications-status notification__icons-type-status-waiting"/>;
// const statusVerificationIcon = <i className="tz-notifications-status notification__icons-type-status-verification"/>;
// const statusFixedIcon = <i className="tz-notifications-status notification__icons-type-status-fixed"/>;
// const statusHandlingIcon = <i className="tz-notifications-status notification__icons-type-status-handling"/>;
//
// const plannedFixDateExpiredIcon = <i className="tz-clock notification__icons-type-fix-date-expired"/>;
// const plannedFixDateComingIcon = <i className="tz-clock notification__icons-type-fix-date-coming"/>;

// const fileIcon = <i className="tz-notifications-attachment notification__icons-entity"/>;
// const messageIcon = <i className="tz-notifications-message notification__icons-entity"/>;
// const problemIcon = <i className="tz-problem-28 notification__icons-entity"/>;
// const reportIcon = <i className="tz-check-28 notification__icons-entity"/>;
// const inspectionIcon = <i className="tz-notifications-inspection notification__icons-entity"/>;

interface INotification {
	id: string;
	type: string;
	date: string;
	fields?: {[key: string]: any};
	htmlMessage: string;
}

interface INotificationProps {
	data: INotification;

	onDelete?(id: string): void;

	onClick?(event: React.MouseEvent): void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getEntityIcon = (type: string): React.ReactElement =>
	// const reg = /(?<!^)(?=[A-Z])/;
	// const words = type.split(reg);
	// if (!words) { return defaultEntityIcon; }
	// const lowerCaseWords = words.map(item => item.toLowerCase());
	//
	// if (lowerCaseWords.includes('attachment')) { return fileIcon; }
	// if (lowerCaseWords.includes('message')) { return messageIcon; }
	//
	// if (lowerCaseWords.includes('report')) { return reportIcon; }
	// if (lowerCaseWords.includes('problem')) { return problemIcon; }
	// if (lowerCaseWords.includes('inspection')) { return inspectionIcon; }

	 defaultEntityIcon
;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getTypeIcon = (type: string, fields?: INotification['fields']): React.ReactElement | null =>
	// const reg = /(?<!^)(?=[A-Z])/;
	// const words = type.split(reg);
	// if (!words) { return defaultTypeIcon; }
	// const lowerCaseWords = words.map(item => item.toLowerCase());
	//
	// if (lowerCaseWords.includes('fix') && lowerCaseWords.includes('date')) {
	// 	return fields?.interval < 0 ? plannedFixDateComingIcon : plannedFixDateExpiredIcon;
	// }
	// if (lowerCaseWords.includes('status') && fields) {
	// 	switch (fields?.status) {
	// 		case 'waiting':
	// 			return statusWaitingIcon;
	// 		case 'handling':
	// 			return statusHandlingIcon;
	// 		case 'verification':
	// 			return statusVerificationIcon;
	// 		case 'fixed':
	// 			return statusFixedIcon;
	// 	}
	// }
	//
	// if (lowerCaseWords.includes('added')) { return addIcon; }
	// if (lowerCaseWords.includes('edited')) { return editIcon; }
	// if (lowerCaseWords.includes('deleted')) { return deleteIcon; }

	 defaultTypeIcon;
const Notification = ({data, onDelete, onClick}: INotificationProps) => {
	const {id, type, date, htmlMessage, fields} = data;

	const handleDelete = useCallback(() => {
		if (onDelete) {
			onDelete(id);
		}
	}, [id]);

	const entityIcon = getEntityIcon(type);
	const typeIcon = getTypeIcon(type, fields);

	return (
		<div className="notification">
			<div className="notification__icons">
				{entityIcon}
				{typeIcon && (
					<div className="notification__icons-type">{typeIcon}</div>
				)}
			</div>
			<div className="notification__body">
				<div
					className="notification__body-message"
					dangerouslySetInnerHTML={{__html: htmlMessage}}
					onClick={onClick}
				/>
				<div className="notification__body-date">
					{format(new Date(date), dateTimeLetterSeparatedFormat)}
				</div>
			</div>
			<div className="notification__delete">
				<IconButton onClick={handleDelete}>{bucketIcon}</IconButton>
			</div>

		</div>
	);
};

Notification.displayName = 'Notification';

export {INotification};
export default Notification;
