import {OfflineModeStatus} from '@tehzor/tools/enums/OfflineModeStatus';

export const offlineModeDescriptions: Record<OfflineModeStatus, string> = {
	[OfflineModeStatus.UNKNOWN]: 'Неизвестная ошибка',
	[OfflineModeStatus.SAVING]: 'Доступ к сети восстановлен, данные передаются в систему',
	[OfflineModeStatus.SAVING_COMPLETE]: 'Данные переданы на сервер',
	[OfflineModeStatus.SAVING_ERROR]:
		'Проверьте подключение к интернету и попробуйте ещё раз. При возникновении повторной ошибки обратитесь в техподдержку',
	[OfflineModeStatus.CACHING]:
		'Данные сохраняются на вашем устройстве для дальнейшей работы в автономном режиме. Пожалуйста, оставайтесь в сети до окончания процесса',
	[OfflineModeStatus.CACHING_ERROR]:
		'Проверьте подключение к интернету и попробуйте ещё раз. При возникновении повторной ошибки обратитесь в техподдержку',
	[OfflineModeStatus.ONLINE_READY]:
		'Данные загружены на устройство. Система готова к автономной работе',
	[OfflineModeStatus.ONLINE_CACHE_MISSING]: '',
	[OfflineModeStatus.OFFLINE_READY]:
		'Данные будут сохранены на вашем устройстве и загружены в систему при подключении к интернету',
	[OfflineModeStatus.OFFLINE_ERROR]: '',
	[OfflineModeStatus.OFFLINE_CACHE_MISSING]: ''
};
