import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {FloatingActionButton, IconButton} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getSpace} from '@src/store/modules/entities/space/actions';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useAggregateEntityPermissions} from '@src/core/hooks/permissions/useAggregateEntityPermissions';

interface IEntityAddingProps {
	objectId: string;
	check: ICheck;
}

const EntityAdding = ({objectId, check}: IEntityAddingProps) => {
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();

	const defaultData = useSpaceLocationForEntities(objectId, check.links?.spaceId);

	const [dialog, open] = useAddingCheckDialog({
		objectId,
		checkId: check.id,
		links: check.links,
		stage: object.stage,
		defaultProblemData: defaultData,
		defaultInspectionData: defaultData,
		createdBy: check.createdBy?.id
	});

	const [, handleClick] = useAsyncFn(async () => {
		if (check.links?.spaceId) {
			await dispatch(getSpace(objectId, check.links.spaceId));
		}
		open();
	}, [objectId, check, open]);

	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const checkProblemPermissions = useAggregateEntityPermissions(objectId, 'checkProblemsAdd', {createdBy: check.createdBy?.id});
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);
	const checkInspectionPermissions = useAggregateEntityPermissions(objectId, 'checkInspectionsAdd', {createdBy: check.createdBy?.id});

	return (problemsPermissions.canAdd && checkProblemPermissions.canAdd)
	|| (inspectionsPermissions.canAdd && checkInspectionPermissions.canAdd) ? (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<IconButton
					type="accent-blue"
					onClick={handleClick}
				>
					<i className="tz-plus-20"/>
				</IconButton>
			</Desktop>
			{dialog}
		</>
	) : null;
};

export default EntityAdding;
