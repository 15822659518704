import React, {useCallback} from 'react';
import {InternalAcceptancesPage} from './InternalAcceptancesPage';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import ActionsMobile from './components/actions/Actions.mobile';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/reducers';
import {
	changeFilters,
	clearFilters
} from '@src/store/modules/settings/pages/internalAcceptances/actions';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';
import {changeOffset} from '@src/store/modules/entities/internalAcceptances/actions';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const InternalAcceptancesPageWrap = () => {
	const dispatch = useAppDispatch();
	const {objectId} = useStrictParams<{objectId: string}>();
	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {
		sectionsMenu,
		mobileRightButtons: <ActionsMobile objectId={objectId}/>
	});

	const {filters} = useAppSelector(s => extractInternalAcceptancesPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: IInternalAcceptancesFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId || 'all', 0));
		},
		[objectId, dispatch]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId, dispatch]);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId={objectId}
			onClear={onClear}
		>
			<InternalAcceptancesPage key={objectId}/>
		</EntitiesFiltersProvider>
	);
};

export default InternalAcceptancesPageWrap;
