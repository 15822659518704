import {IAddSpaceStatusesSetResponse, requestAddSpaceStatusesSet} from '@src/api/backend/spaceStatusesSets/add';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import * as types from '../constants';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IAddSpaceStatusesSetPayload = IAddSpaceStatusesSetResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (spaceStatusesSet: ISpaceStatusesSet) => ({
	type: types.ADD_SUCCESS,
	payload: {...spaceStatusesSet} as IAddSpaceStatusesSetPayload
});

const failure = (error: IError) => ({
	type: types.ADD_FAILURE,
	payload: error
});

export const addSpaceStatusesSet = (spaceStatusesSet: ISavingSpaceStatusesSet) =>
	createApiAction(
		request, success, failure,
		async () => {
			void queryClient.invalidateQueries(spaceStatusesSetsQueryKeys.list());
			void queryClient.invalidateQueries(spaceStatusesQueryKeys.list());
			void queryClient.invalidateQueries(spacesQueryKeys.list());

			return requestAddSpaceStatusesSet(spaceStatusesSet);
		}
	);