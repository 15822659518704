import React, {useCallback, useEffect, useState} from 'react';
import {
	convertSpaceTypeDecorationToSave,
	convertSpaceTypeDecorationSetToSave,
	errorsFns,
	isEdited
} from '@src/core/hooks/states/useEditableSpaceTypeDecorationState/state';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {useEditableSpaceTypeDecorationState} from '@src/core/hooks/states/useEditableSpaceTypeDecorationState';
import {EditableSpaceTypeDecoration} from '../EditableSpaceTypeDecoration';
import {EditableSpaceTypeDecorationItems} from '../components/EditableTypeDecorationItems';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	companyId: {fieldId: 'companyId', isRequired: true},
	objects: {fieldId: 'objects', isRequired: true}
};

interface IHookArgs {
	typeDecorationSet?: ISpaceTypeDecorationSet;
	typeDecoration?: ISpaceTypeDecoration[];
	saving?: boolean;
}

export const useEditableSpaceTypeDecoration = ({
	typeDecorationSet,
	typeDecoration,
	saving
}: IHookArgs): [
	React.ReactNode,
	React.ReactNode,
	() =>
		| {
				typeDecorationSet?: ISavingSpaceTypeDecorationSet;
				typeDecoration?: ISavingSpaceTypeDecoration[];
		  }
		| undefined,
	() => void,
	boolean
] => {
	const [editingState, editingDispatch] = useEditableSpaceTypeDecorationState({
		typeDecorationSet,
		typeDecoration
	});
	const [isBlocking, setIsBlocking] = useState(false);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, typeDecorationSet));
	}, [editingState, typeDecorationSet]);

	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}

		if (!isEdited(editingState, typeDecorationSet)) {
			return undefined;
		}

		const savingSpaceTypeDecorationSet = convertSpaceTypeDecorationSetToSave(
			editingState,
			typeDecorationSet,
			false
		);
		const savingSpaceTypeDecoration = convertSpaceTypeDecorationToSave(
			editingState,
			typeDecoration,
			false
		);

		return {
			typeDecorationSet: savingSpaceTypeDecorationSet,
			typeDecoration: savingSpaceTypeDecoration
		};
	}, [editingState, typeDecorationSet, typeDecoration]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: {typeDecorationSet, typeDecoration}
		});
	}, [typeDecorationSet, typeDecorationSet]);

	const SpaceTypeDecorationFields = (
		<EditableSpaceTypeDecoration
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving}
		/>
	);

	const SpaceTypeDecorationItemsFields = (
		<EditableSpaceTypeDecorationItems
			editingState={editingState}
			editingDispatch={editingDispatch}
		/>
	);

	return [
		SpaceTypeDecorationFields,
		SpaceTypeDecorationItemsFields,
		getSavingData,
		reset,
		isBlocking
	];
};
