import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLatestUserWarrantyClaims} from '@src/store/modules/dictionaries/latestUserWarrantyClaims/actions/get';
import {
	extractLatestWarrantyClaimId,
	extractLatestWarrantyClaimsAsArray
} from '@src/store/modules/dictionaries/latestUserWarrantyClaims/selectors';
import {extractWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import React, {ReactNode, Dispatch, SetStateAction, Ref, useEffect} from 'react';
import {useAsync} from 'react-use';
import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog';
import AddingWarrantyClaim, {IAddingWarrantyClaimRefProps} from '../components/AddingWarrantyClaim';
import DialogTitle from '../components/DialogTitle';

interface IUseClaimMapsProps {
	objectId: string;
	claimId?: string;
	spaceId?: string;
	stage: ObjectStageIds;
	links?: IWarrantyClaim['links'];
	view: AddingWarrantyClaimView;
	addingEntityRef: Ref<IAddingProblemRefProps | IAddingWarrantyClaimRefProps>;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedWarrantyClaimId?: string;
	setSelectedWarrantyClaimId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;

	onClose: () => void;
}

export const useClaimMaps = (
	props: IUseClaimMapsProps
): [Record<ObjectStageIds, ReactNode>, Record<ObjectStageIds, IEntitySelectProps>] => {
	const {
		objectId,
		claimId,
		spaceId,
		stage,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedWarrantyClaimId,
		setSelectedWarrantyClaimId,
		defaultProblemData,
		onClose
	} = props;

	const contentMap = {} as Record<ObjectStageIds, ReactNode>;
	const entitiesSelectMapProps = {} as Record<ObjectStageIds, IEntitySelectProps>;

	const latestWarrantyClaimId = useAppSelector(s =>
		extractLatestWarrantyClaimId(s, objectId, links?.spaceId));
	const latestWarrantyClaims = useAppSelector(s =>
		extractLatestWarrantyClaimsAsArray(s, objectId, links?.spaceId));

	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (latestWarrantyClaimId) {
			setSelectedWarrantyClaimId(latestWarrantyClaimId);
		}
	}, [latestWarrantyClaimId]);

	useAsync(async () => {
		if (online) {
			await dispatch(getLatestUserWarrantyClaims());
		}
	}, [online]);

	contentMap[stage] = (
		<>
			<div className="adding-warranty-claim-dialog__title">
				<DialogTitle view={view}/>
			</div>
			{view === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={{...links, warrantyClaimId: selectedWarrantyClaimId}}
					stage={stage}
					defaultData={defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : view === 'claim' ? (
				<AddingWarrantyClaim
					objectId={objectId}
					spaceId={spaceId}
					links={links}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	const warrantyClaim = useAppSelector(s => (claimId ? extractWarrantyClaim(s) : undefined));

	entitiesSelectMapProps[stage] = {
		name: 'Гарантийное обращение',
		newName: 'Новое гарантийное обращение',
		selected: selectedWarrantyClaimId,
		onEntityChange: setSelectedWarrantyClaimId,
		withoutNew: !!warrantyClaim,
		entities: warrantyClaim ? [warrantyClaim] : latestWarrantyClaims,
		localEntities: []
	};

	return [contentMap, entitiesSelectMapProps];
};
