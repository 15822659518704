import {wsConnector} from '../wsConnector';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export type IEditWorkAcceptanceResponsibleResponse = IWorkAcceptance;

/**
 * Изменяет ответственных приёмки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приёмки работ
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const makeWorkAcceptanceResponsibleEditRequest = (
	objectId: string,
	workAcceptanceId: string,
	respUsers?: string[],
	activeGroup?: string
) => wsConnector.sendAuthorizedRequest<IEditWorkAcceptanceResponsibleResponse>('editWorkAcceptanceResponsible', {
		objectId,
		workAcceptanceId,
		respUsers,
		activeGroup
	});
