import {getCategories} from '@src/store/modules/dictionaries/categories/actions';
import {getCategoriesSets} from '@src/store/modules/dictionaries/categoriesSets/actions';
import {getCheckRecordStatuses} from '@src/store/modules/dictionaries/checkRecordStatuses/actions';
import {getContractForms} from '@src/store/modules/dictionaries/contractForms/actions';
import {getExportTemplatesDestinations} from '@src/store/modules/dictionaries/exportTemplatesDestinations/actions';
import {getObjectStages} from '@src/store/modules/dictionaries/objectStages/actions';
import {getOwnerAcceptanceResolutions} from '@src/store/modules/dictionaries/ownerAcceptanceResolutions/actions';
import {getOwnerAcceptanceStatuses} from '@src/store/modules/dictionaries/ownerAcceptanceStatuses/actions';
import {getProblemStatuses} from '@src/store/modules/dictionaries/problemStatuses/actions';
import {getProblemTags} from '@src/store/modules/dictionaries/problemTags/actions';
import {getProblemTagsSets} from '@src/store/modules/dictionaries/problemTagsSets/actions';
import {getProblemTemplatesSets} from '@src/store/modules/dictionaries/problemTemplatesSets/actions';
import {getRegulatoryStandards} from '@src/store/modules/dictionaries/regulatoryStandards/actions';
import {getResponsibilityRules} from '@src/store/modules/dictionaries/responsibilityRules/actions';
import {getSpaceIndicatorsSets} from '@src/store/modules/dictionaries/spaceIndicatorsSets/actions';
import {getSpaceStatusesSets} from '@src/store/modules/dictionaries/spaceStatusesSets/actions';
import {getSpaceStatuses} from '@src/store/modules/dictionaries/spaceStatuses/actions';
import {getSpaceTypes} from '@src/store/modules/dictionaries/spaceTypes/actions';
import {getStructureTypes} from '@src/store/modules/dictionaries/structureTypes/actions';
import {getTaskPriorities} from '@src/store/modules/dictionaries/taskPriorities/actions';
import {getTaskStatuses} from '@src/store/modules/dictionaries/taskStatuses/actions';
import {getTaskTypes} from '@src/store/modules/dictionaries/taskTypes/actions';
import {getUsers} from '@src/store/modules/dictionaries/users/actions';
import {getWarrantyClaimStatuses} from '@src/store/modules/dictionaries/warrantyClaimStatuses/actions';
import {getWorkingGroups} from '@src/store/modules/dictionaries/workingGroups/actions';
import {getExportTemplates} from '@src/store/modules/dictionaries/exportTemplates/actions/get';
import {getSpaceTypeDecorationsAll} from '@src/store/modules/entities/spaceTypeDecorations/actions';
import {getSpaceTypeDecorationSets} from '@src/store/modules/entities/spaceTypeDecorationSets/actions';
import {getWorkAcceptanceStatuses} from '@src/store/modules/dictionaries/workAcceptanceStatuses/actions';
import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {getResources, IResourceMapType} from '@src/utils/getResources';
import {getWorkingGroupTypes} from '@src/store/modules/dictionaries/workingGroupTypes/actions';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import {getCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import {getDocumentTypes} from '@src/store/modules/dictionaries/documentTypes/actions';

const resourceMap: IResourceMapType = {
	all: [{actions: [getContractForms, getUsers, getRegulatoryStandards, getExportTemplates, getDocumentTypes]}],
	'categories-view': [{actions: [getCategoriesSets, getCategories]}],
	'check-records-view': [{actions: [getCheckRecordStatuses]}],
	'export-templates-edit': [{actions: [getExportTemplatesDestinations]}],
	'spaces-view': [{actions: [getSpaceTypes]}],
	objectsView: [{actions: [getObjectStages]}],
	'owner-acceptances-view': [{actions: [getOwnerAcceptanceStatuses, getOwnerAcceptanceResolutions]}],
	problemsView: [{actions: [getProblemStatuses]}],
	'problem-tags-sets-view': [{actions: [getProblemTagsSets, getProblemTags]}],
	'problem-templates-sets-view': [{actions: [getProblemTemplatesSets]}],
	spaceIndicatorsSetsView: [{actions: [getSpaceIndicatorsSets]}],
	spaceStatusesSetsView: [{actions: [getSpaceStatusesSets, getSpaceStatuses]}],
	'space-type-decoration-sets-view': [{actions: [getSpaceTypeDecorationSets]}],
	'space-type-decoration-view': [{actions: [getSpaceTypeDecorationsAll]}],
	'structures-view': [{actions: [getStructureTypes]}],
	'responsibility-rules-view': [{actions: [getResponsibilityRules]}],
	'tasks-view': [{actions: [getTaskStatuses, getTaskTypes, getTaskPriorities]}],
	'working-groups-view': [{actions: [getWorkingGroupTypes, getWorkingGroups]}],
	'warranty-claims-view': [{actions: [getWarrantyClaimStatuses]}],
	'work-acceptances-view': [{actions: [getWorkAcceptanceStatuses]}],
	'check-lists-view': [{actions: [getCheckLists, getCheckItems]}]
};

export const getInitialResources = (roles: IUserRole[]) => getResources(resourceMap, roles);
