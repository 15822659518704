import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {offlineJournalActionType} from '../enums/actionsTypes';

export const useLocalWarrantyClaimsJournal = () => {
	const {data} = useQuery<IListWarrantyClaim[], unknown, IOfflineJournalEntity[]>({
		queryKey: warrantyClaimsQueryKeys.localList(),
		select: warrantyClaims =>
			warrantyClaims.map(
				claim =>
					({
						entity: {
							entityId: claim.id,
							entityLocalNumber: claim.localNumber,
							entityType: offlineJournalActionType.WARRANTY_CLAIM_ADD
						},
						object: {
							objectId: claim.object?.id,
							objectName: claim.object?.name
						},
						transfer: {
							status: claim.transferStatus,
							statusMessage: claim.transferStatus
								? offlineModeTransferTitles[claim.transferStatus]
								: ''
						},
						createdAt: claim.createdAt
					} as IOfflineJournalEntity)
			)
	});
	return data || [];
};
