import classNames from 'classnames';
import React from 'react';
import './BlockPlaceholder.less';

interface IBlockPlaceholderProps {
	className?: string;
	style?: React.CSSProperties;
	width?: string | number;
	height?: string | number;
}

const BlockPlaceholder = ({className, style, width, height}: IBlockPlaceholderProps) => {
	const styles: React.CSSProperties = {...style};
	if (width !== undefined) {
		styles.width = width;
	}
	if (height !== undefined) {
		styles.height = height;
	}

	return (
		<div
			className={classNames('block-placeholder', className)}
			style={styles}
		/>
);
};

export default BlockPlaceholder;
