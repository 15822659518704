import React, {useCallback} from 'react';
import './SpacesPage.less';
import {useAsync} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import {getCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import {getSpaceTypeDecorationSets} from '@src/store/modules/entities/spaceTypeDecorationSets/actions/get';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/spaces/actions';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {changeOffset} from '@src/store/modules/entities/spaces/actions';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {LoadingPanel} from '@tehzor/ui-components';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useIsFetching} from '@tanstack/react-query';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const SpacesPage = () => {
	useScrollRestoration();

	const {objectId} = useStrictParams<{objectId: string}>();

	const object = useAppSelector(s => extractCurrentTreeObject(s, objectId));
	const roles = useAppSelector(extractUserRoles);
	const {filters} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const isSpacesLoading = !!useIsFetching(spacesQueryKeys.list());
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const updateButtonLoading = useAppSelector(s => s.app.updateData.status);

	const dispatch = useAppDispatch();

	const loadingState = useAsync(async () => {
		if (online) {
			await dispatch(getSpaceTypeDecorationSets());
			if (!object || !roles) {
				return;
			}

			if (hasPermission(roles, 'check-lists-view')) {
				await dispatch(getCheckLists());
			}
			if (hasPermission(roles, 'check-items-view')) {
				await dispatch(getCheckItems());
			}
		}
	}, [object, online]);

	const applyFilters = useCallback(
		(value: ISpacesFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId]);

	const isDesktop = useIsDesktop();

	return (
		<EntitiesFiltersProvider
			objectId={objectId}
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
		>
			<LoadingPanel
				className="spaces-page__loading-panel"
				active={
					online ? loadingState.loading || isSpacesLoading || updateButtonLoading : false
				}
			>
				{isDesktop ? <Desktop objectId={objectId}/> : <Mobile objectId={objectId}/>}
			</LoadingPanel>
		</EntitiesFiltersProvider>
	);
};

export default SpacesPage;
