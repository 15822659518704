import React from 'react';
import {CellProps} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';

export const LocationCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {plan, markers, object} = row.original;

	return (
		<div className="owner-acceptances-page__d-table-location">
			{!!object && (
				<div className="owner-acceptances-page__d-table-location-object">{object.name}</div>
			)}

			{(plan?.name || plan?.floor || markers) && (
				<div className="owner-acceptances-page__d-table-location-block">
					{plan?.name && (
						<div className="owner-acceptances-page__d-table-location-line">
							<span className="owner-acceptances-page__d-table-location-title">План:</span>
							<div className="owner-acceptances-page__d-table-location-subtitle">{plan?.name}</div>
						</div>
					)}
					{plan?.floor && (
						<div className="owner-acceptances-page__d-table-location-line">
							<span className="owner-acceptances-page__d-table-location-title">Этаж:</span>
							<div className="owner-acceptances-page__d-table-location-subtitle">{plan?.floor}</div>
						</div>
					)}
					{!!markers?.length && (
						<div className="owner-acceptances-page__d-table-location-line">
							<span className="owner-acceptances-page__d-table-location-title">Место:</span>
							<div className="owner-acceptances-page__d-table-location-subtitle">
								{markers[0].name}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};