import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemStatusesAsArray} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import {LegendGroup} from './LegendGroup';

export const LegendContent = () => {
	const problemStatuses = useAppSelector(extractProblemStatusesAsArray);

	return (
		<div className="objects-page__legend-content">
			<LegendGroup
				title="Нарушения:"
				items={problemStatuses}
			/>
		</div>
	);
};