import React, {useCallback, useState} from 'react';
import './EditableInternalAcceptanceAttachmentsDialog.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useEditableInternalAcceptanceAttachments} from '../EditableInternalAcceptanceAttachments/hooks/useEditableInternalAcceptanceAttachments';
import {editInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/actions/edit';

interface IEditableInternalAcceptanceAttachmentsDialogProps {
	objectId: string;
	internalAcceptanceId: string;
	internalAcceptance: IInternalAcceptance;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования вложений внутренней приемки
 */
export const EditableInternalAcceptanceAttachmentsDialog = ({
	objectId,
	internalAcceptanceId,
	internalAcceptance,
	isOpen,
	onClose
}: IEditableInternalAcceptanceAttachmentsDialogProps) => {
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);

	const [fields, getSavingData, reset, isBlocking] = useEditableInternalAcceptanceAttachments(
		objectId,
		internalAcceptance,
		saving
	);
	const save = useCallback(async () => {
		setSaving(true);
		if (internalAcceptanceId) {
			const savingData = await getSavingData();
			if (savingData) {
				if (!networkStatus) {
					// Локальное сохранение
					// const savingData = convertToSave(editingState, problem, true);
					// savingData.newAttachments = convertToLocalSave(files);
					// TODO Локальное сохранение
				} else {
					try {
						await dispatch(
							editInternalAcceptance(objectId, internalAcceptanceId, savingData)
						);
						onClose();
					} catch (error) {
						console.log(error);
						// TODO Локальное сохранение
					}
				}
			}
		}
		setSaving(false);
	}, [networkStatus, objectId, internalAcceptanceId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-internal-acceptance-attachments-dialog"
			isOpen={isOpen}
			title="Редактировать вложения"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};