import React from 'react';
import './AddingDocuments.less';
import classNames from 'classnames';
import FilesUploader from '@tehzor/tools/components/FilesUploader2';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {convertClassNames} from '../../../utils/convertClassNames';
import {UploadingFile} from '../../files';

interface IAddingDocumentsProps {
	className?: string | {
		root?: string;
		file?: string;
	};
	style?: React.CSSProperties;
	savedAttachments?: IAttachment[];
	disabled?: boolean;
}

class AddingDocuments extends FilesUploader<IAddingDocumentsProps> {
	render() {
		const {
			className,
			style,
			uploadingFiles: files,
			disabled
		} = this.props;

		const classes = convertClassNames(className);

		return (
			<div
				className={classNames('adding-documents', classes.root)}
				style={style}
			>
				{files.map(file => (
					<UploadingFile
						{...file}
						className={classNames('adding-documents__file', classes.file)}
						data={file.key}
						disabled={disabled}
						onDelete={this.deleteFile}
						onReload={this.reloadFile}
					/>
				))}
			</div>
		);
	}
}

export default AddingDocuments;