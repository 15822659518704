import React from 'react';
import classNames from 'classnames';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {LegendDialog} from './legend';
import {LoadingPanel} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import MobileSchemas from './schema/Schemas.mobile';
import MobileTable from './table/Table.mobile';
import {MobileFiltersLinkBtn} from '@src/components/MobileFiltersLinkBtn';
import {MobileDisplayModeSwitch} from './DisplayModeSwitch.mobile';
import {SpaceTypeMenu} from './typeFilter/SpaceTypeMenu';
import {useTemporaryDisablingSchemas} from '../hooks/useTemporaryDisablingSchemas';
import MobileActions from './actions/Actions.mobile';
import {extractSpacesListData} from '@src/store/modules/entities/spaces/selectors/lists';
import {MobileSelectionClearing} from './selection/SelectionClearing.mobile';
import {StageVisibilityFilter} from './filters/StageVisibilityFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {FloorSort} from './sort/FloorSort';
import PageBreadcrumbs from './PageBreadcrumbs';
import {extractSpacesAsArrayWithFiltered} from '@src/store/modules/entities/spaces/selectors/schemas';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {
	extractObject,
	extractTargetObjects
} from '@src/store/modules/dictionaries/objects/selectors';
import {extractSpaceTypesForObjectAsArray} from '@src/store/modules/dictionaries/spaceTypes/selectors';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';

interface IMobileProps {
	objectId: string;
	loading?: boolean;
}

const Mobile = ({objectId, loading}: IMobileProps) => {
	const {displayMode, schemaView} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const {selected} = useAppSelector(s => extractSpacesListData(s, objectId));

	const {state} = useEntitiesFiltersCtx();
	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(
		objectId,
		{
			sectionsMenu,
			mobileLeftButton:
				selected.length > 0 ? <MobileSelectionClearing objectId={objectId}/> : undefined,
			mobileRightButtons: <MobileActions objectId={objectId}/>
		},
		[objectId, selected]
	);

	// TODO Убрать после ускорения бэкенда и оптимизации загрузки большого кол-ва шахматок
	const needHideSchema = useTemporaryDisablingSchemas(objectId);
	const targetObjects = useAppSelector(s => extractTargetObjects(s, objectId));
	const object = useAppSelector(s => extractObject(s, objectId));
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const sharedSettings = useAppSelector(s => s.settings.pages.spaces.shared);
	const data: Record<
		string,
		{
			spaces: IEnrichedSpace[];
			filtered: IListSpace[];
		}
	> = useAppSelector(s =>
		extractSpacesAsArrayWithFiltered(s, targetObjects, object, pageSettings, sharedSettings));
	const typeIds = Object.values(data).reduce(
		(acc, el) => {
			el.filtered.forEach(space => {
				acc.filtered.push(space.type);
			});
			el.spaces.forEach(space => {
				acc.spaces.push(space.type);
			});
			return acc;
		},
		{spaces: [], filtered: []} as {spaces: string[], filtered: string[]}
	);
	const spaceTypes = useAppSelector(s => extractSpaceTypesForObjectAsArray(s, targetObjects));
	const types: Array<[ISpaceType, {spaces: number, filtred: number}]> = spaceTypes.map(type => [
		type,
		{
			spaces: typeIds.spaces.filter(el => el === type.id).length,
			filtred: typeIds.filtered.filter(el => el === type.id).length
		}
	]);

	return (
		<div
			className={classNames('page-cont', 'spaces-page', {
				'page-cont_extra-top-margin': sectionsMenu.length
			})}
		>
			<PageBreadcrumbs objectId={objectId}/>
			<div className="spaces-page__m-controls-bar">
				{needHideSchema ? <div/> : <MobileDisplayModeSwitch objectId={objectId}/>}

				<div className="spaces-page__space-type-menu-btn-wrap">
					{displayMode === SpacesDisplayMode.SCHEMA && (
						<SpaceTypeMenu
							objectId={objectId}
							types={types}
						/>
					)}
				</div>

				<LegendDialog/>

				<MobileFiltersLinkBtn
					state={state}
					objectId={objectId}
					entity="spaces"
				/>
			</div>

			<div className="spaces-page__filter-bar">
				<StageVisibilityFilter
					className="spaces-page__m-visibility-filter"
					objectId={objectId}
				/>
				{(displayMode === SpacesDisplayMode.SCHEMA && schemaView !== SpacesSchemaVariants.REPORTS) && (
					<FloorSort objectId={objectId}/>
				)}
			</div>

			<LoadingPanel
				className="spaces-page__loading-panel"
				active={loading}
			>
				<>
					{displayMode === SpacesDisplayMode.SCHEMA && (
						<MobileSchemas
							objectId={objectId}
							data={data}
						/>
					)}
					{displayMode === SpacesDisplayMode.LIST && (
						<MobileTable objectId={objectId}/>
					)}
				</>
			</LoadingPanel>
		</div>
	);
};

export default Mobile;
