import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import React from 'react';
import {DesktopAddButton} from './DesktopAddButton';
import {useExportTemplatePermissions} from '@src/core/hooks/permissions/useExportTemplatePermissions';
import {useManageExportTemplateBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageExportTemplateBreadcrumbs';

export const PageBreadcrumbs = () => {
	const breadcrumbs = useManageExportTemplateBreadcrumbs();
	const permissions = useExportTemplatePermissions();

	return (
		<AppBreadcrumbs
			className="manage-export-templates-page__breadcrumbs"
			items={breadcrumbs}
		>
			{permissions.canEdit ? <DesktopAddButton/> : null}
		</AppBreadcrumbs>
	);
};
