import {combineReducers} from 'redux';
import checkLists from './checkLists/reducers';
import problem from './problem/reducers';
import {inspection} from './inspection/reducers';
import task from './task/reducers';
import spaces from './spaces/reducers';
import {structures} from './structures/reducers';
import internalAcceptances from './internalAcceptances/reducers';

export default combineReducers({
	checkLists,
	problem,
	inspection,
	task,
	spaces,
	structures,
	internalAcceptances
});
