import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {deleteTask} from '@src/store/modules/pages/task/actions';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {extractTask} from '@src/store/modules/pages/task/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getTasks} from '@src/store/modules/entities/tasks/actions';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useEditingTaskDialog} from '@src/components/EditingTaskDialog';

interface IMenuProps {
	taskId: string;
}

const Menu = ({taskId}: IMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();

	const task = useAppSelector(extractTask);
	const permissions = useTasksPermissions(task?.objectId);
	const [taskEditDialog, handleOpenEditTaskDialog] = useEditingTaskDialog({taskId: task?.id});

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить задачу',
		'Вы действительно хотите удалить задачу',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteTask(taskId));
			await dispatch(getTasks());
			goBack();
		}
	}, [taskId]);

	const items = [];
	items.push(updateData);
	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit"/>}
				onClick={handleOpenEditTaskDialog}
			>
				Редактировать
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
			{permissions.canEdit && taskEditDialog}
		</>
	) : null;
};

export default Menu;
