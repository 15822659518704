import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@src/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractSpaceStatusesAsArrayByObjectId,
	extractSpaceStatusesDataByObjectId
} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';

interface ISpaceStatusProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	objectId: string;
}

export const SpaceStatus = <S extends {status?: string}, E>({
	className,
	style,
	label = 'Статус помещения',
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	objectId
}: ISpaceStatusProps<S, E>) => {
	const statuses = useAppSelector(s => extractSpaceStatusesAsArrayByObjectId(s, objectId));
	const statusesMap = useAppSelector(s => extractSpaceStatusesDataByObjectId(s, objectId).byId);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'status', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'status'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={value ? statusesMap[value]?.name : ''}
						icon={<i className="tz-simple-arrow-20"/>}
						error={required && hasError ? 'Выберите статус' : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{statuses.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
