import ICreationStatsItem from '@tehzor/tools/interfaces/statistics/ICreationStatsItem';

export interface StatsByFields {
	spaces: number;
	checks: number;
	problems: number;
	problemReplies: number;
	inspections: number;
	warrantyClaims: number;
}

export type StatsMap = Record<string, StatsByFields>;

export type ContractorsStatsMap = Record<
	string,
	{
		stats: StatsByFields;
		usersStats: StatsMap;
	}
>;

const getEmpty = () => ({spaces: 0, checks: 0, problems: 0, problemReplies: 0, inspections: 0, warrantyClaims: 0});

const createAggregator
	= <T extends Record<string, unknown> = StatsMap>(
		fn: (input: ICreationStatsItem[], output: T, field: string) => void
	) =>
	({
		spaces,
		checks,
		problems,
		problemReplies,
		inspections,
		warrantyClaims
	}: {
		spaces?: ICreationStatsItem[];
		checks: ICreationStatsItem[];
		problems: ICreationStatsItem[];
		problemReplies: ICreationStatsItem[];
		inspections: ICreationStatsItem[];
		warrantyClaims?: ICreationStatsItem[];
	}) => {
		const result = {} as T;
		if (spaces) {
			fn(spaces, result, 'spaces');
		}
		fn(checks, result, 'checks');
		fn(problems, result, 'problems');
		fn(problemReplies, result, 'problemReplies');
		fn(inspections, result, 'inspections');
		if (warrantyClaims) {
			fn(warrantyClaims, result, 'warrantyClaims');
		}

		return result;
	};

export const byCompanies = createAggregator((input, output, field) => {
	for (const item of input) {
		if (!output[item.companyId]) {
			output[item.companyId] = getEmpty();
		}
		output[item.companyId][field] += item.count;
	}
});

export const byObjects = createAggregator((input, output, field) => {
	for (const item of input) {
		for (const objectId of item.objects) {
			if (!output[objectId]) {
				output[objectId] = getEmpty();
			}
			output[objectId][field] += item.count;
		}
	}
});

export const byUsers = createAggregator((input, output, field) => {
	for (const item of input) {
		if (item.userId) {
			if (!output[item.userId]) {
				output[item.userId] = getEmpty();
			}
			output[item.userId][field] += item.count;
		}
	}
});

export const byContractors = createAggregator<ContractorsStatsMap>((input, output, field) => {
	for (const item of input) {
		if (item.contractors) {
			for (const c of item.contractors) {
				if (!output[c.companyId]) {
					output[c.companyId] = {
						stats: getEmpty(),
						usersStats: {}
					};
				}
				if (!output[c.companyId].usersStats[c.userId]) {
					output[c.companyId].usersStats[c.userId] = getEmpty();
				}
				output[c.companyId].stats[field] += item.count;
				output[c.companyId].usersStats[c.userId][field]
					= (output[c.companyId].usersStats[c.userId][field] ?? 0) + item.count;
			}
		}
	}
});
