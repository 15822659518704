import {IUserRole, UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import IProblemReply from '@tehzor/tools/interfaces/IProblemReply';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import IPermission from '@tehzor/tools/interfaces/IPermission';

/**
 * Проверяет типовые ограничения checksCreatedByUser, checksProblemsHaveResponsibleUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор проверки
 * @param problems список нарушений проверки
 */
export const testCheckRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: ICheck['createdBy'],
	problems?: ILinkedProblem[]
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		return !p.restrictions
			? true
			: (!!p.restrictions.checksCreatedByUser && createdBy && userId === createdBy.id)
					|| (!!p.restrictions.checksProblemsHaveResponsibleUser
						&& problems
						&& problems.some(item => item.respUsers && item.respUsers.includes(userId)));
	}
	return false;
};

/**
 * Проверяет наличие Разрешения без вложенных ограничений
 *
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 */
export const testCheckPermission = (
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	return !!p;
};

/**
 * Проверяет наличие вложенных ограничений
 *
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 */
export const testCheckRestrictionsList = (
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		if (p.restrictions) {
			return {...(p.restrictions as {[key: string]: boolean})};
		}
	}
	return undefined;
};

/**
 * Проверяет типовые ограничения internalAcceptancesCreatedByUser, internalAcceptancesProblemsHaveResponsibleUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор
 * @param problems список нарушений
 */
export const testInternalAcceptanceRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: ICheck['createdBy'],
	problems?: ILinkedProblem[]
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		return !p.restrictions
			? true
			: (!!p.restrictions.internalAcceptancesCreatedByUser
					&& createdBy
					&& userId === createdBy.id)
					|| (!!p.restrictions.internalAcceptancesProblemsHaveResponsibleUser
						&& problems
						&& problems.some(item => item.respUsers && item.respUsers.includes(userId)));
	}
	return false;
};

/**
 * Проверяет наличие Разрешения без вложенных ограничений
 *
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 */
export const testInternalAcceptancePermission = (
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	return !!p;
};

/**
 * Проверяет наличие вложенных ограничений
 *
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 */
export const testInternalAcceptanceRestrictionsList = (
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		if (p.restrictions) {
			return {...(p.restrictions as {[key: string]: boolean})};
		}
	}
	return undefined;
};

/**
 * Проверяет типовые ограничения problemsCreatedByUser, problemsHaveResponsibleUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param problemCreatedBy автор нарушения
 * @param problemRespUsers ответственные за нарушение
 */
export const testProblemRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	problemCreatedBy?: ILinkedProblem['createdBy'],
	problemRespUsers?: string[]
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		return !p.restrictions
			? true
			: (!!p.restrictions.problemsCreatedByUser
					&& problemCreatedBy
					&& userId === problemCreatedBy)
					|| (!!p.restrictions.problemsHaveResponsibleUser
						&& problemRespUsers
						&& problemRespUsers.includes(userId));
	}
	return false;
};

const isFieldDisabled = (
	acc: Record<string, boolean>,
	el: string,
	permission: IPermission,
	userId: string,
	problemCreatedBy?: ILinkedProblem['createdBy'],
	problemRespUsers?: string[],
	problemWatchers?: string[],
	activeGroupLeader?: string,
	saving?: boolean,
	creating?: boolean
): Record<string, boolean> => {
	acc[el] = saving
		? false
		: permission.fields
		  && permission.fields[el]
		  && Object.values(permission.fields[el]).includes(true)
		  && !creating
		? permission.fields[el].allProblemsDisabled
			? false
			: (!!permission.fields[el].problemsCreatedByUser
					&& problemCreatedBy
					&& userId === problemCreatedBy)
			  || (!!permission.fields[el].problemsHaveResponsibleUser
					&& problemRespUsers
					&& problemRespUsers.includes(userId))
			  || (!!permission.fields[el].problemsHaveWatcherUser
					&& problemWatchers
					&& problemWatchers.includes(userId))
			  || (!!permission.fields[el].problemsHaveLeaderInActiveGroup
					&& activeGroupLeader === userId)
		: true;

	return acc;
};

export type IProblemAvailableField =
	| 'description'
	| 'plannedFixDate'
	| 'critical'
	| 'categoryId'
	| 'reason'
	| 'location'
	| 'floor'
	| 'respUsers'
	| 'inspectors'
	| 'prescription'
	| 'attachments'
	| 'problemTags'
	| 'contractId';

export const testProblemFieldsRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	problemCreatedBy?: ILinkedProblem['createdBy'],
	problemRespUsers?: string[],
	problemWatchers?: string[],
	activeGroupLeader?: string,
	saving?: boolean,
	creating?: boolean
): Record<IProblemAvailableField, boolean> | undefined => {
	const permission = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);

	if (permission) {
		const fields: IProblemAvailableField[] = [
			'description',
			'plannedFixDate',
			'critical',
			'categoryId',
			'reason',
			'location',
			'floor',
			'respUsers',
			'inspectors',
			'prescription',
			'attachments',
			'problemTags',
			'contractId'
		];

		return fields.reduce(
			(acc: Record<IProblemAvailableField, boolean>, el: string) =>
				isFieldDisabled(
					acc,
					el,
					permission,
					userId,
					problemCreatedBy,
					problemRespUsers,
					problemWatchers,
					activeGroupLeader,
					saving,
					creating
				),
			{} as Record<IProblemAvailableField, boolean>
		);
	}
	return undefined;
};

/**
 * Проверяет типовые ограничения problemCommentsCreatedByUser у комментария
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор комментария
 */
export const testProblemCommentRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: IBriefUser
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		return !p.restrictions
			? true
			: !!p.restrictions.problemCommentsCreatedByUser && createdBy && userId === createdBy.id;
	}
	return false;
};

/**
 * Проверяет типовые ограничения reportCreatedBy, problemCreatedBy, replyCreatedBy
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param problemCreatedBy автор нарушения
 * @param replyCreatedBy автор ответа
 */
export const testProblemReplyRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	problemCreatedBy?: ILinkedProblem['createdBy'],
	replyCreatedBy?: IProblemReply['createdBy']
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		return !p.restrictions
			? true
			: (!!p.restrictions.problemsCreatedByUser
					&& problemCreatedBy
					&& userId === problemCreatedBy)
					|| (!!p.restrictions.problemsRepliesCreatedByUser
						&& replyCreatedBy
						&& userId === replyCreatedBy.id);
	}
	return false;
};

/**
 * Проверяет типовые ограничения inspectionsCreatedByUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param inspectionCreatedBy автор осмотра
 */
export const testInspectionRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	inspectionCreatedBy?: IInspection['createdBy']
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		return !p.restrictions
			? true
			: !!p.restrictions.inspectionsCreatedByUser
					&& inspectionCreatedBy
					&& userId === inspectionCreatedBy.id;
	}
	return false;
};
