import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {queryClient} from '@src/api/QueryClient';
import {IGetSpaceStatusesSetsResponse} from '@src/api/backend/spaceStatusesSets';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';

export type IGetSpaceStatusesSetsPayload = IGetSpaceStatusesSetsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetSpaceStatusesSetsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке статусов помещений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список наборов статусов помещений
 */
export const getSpaceStatusesSets = () =>
	createApiAction<IGetSpaceStatusesSetsResponse>(
		request, success, failure,
		() => queryClient.fetchQuery(
			spaceStatusesSetsQueryKeys.list(),
			{staleTime: Infinity}
		)
	);
