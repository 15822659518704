import IUser from '@tehzor/tools/interfaces/IUser';
import {getPermissionsForProblem} from './getPermissionsForProblem';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {dateTimeCommaSeparatedFormat, dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {format} from 'date-fns';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {IMarker} from '@tehzor/tools/interfaces/markers/IMarker';
import {convertProblem} from '@src/pages/ProblemPage/utils/convertProblem';

/**
 * Преобразовывает нарушения в необходимый для вывода формат
 *
 * @param problems массив нарушений
 * @param problemTagsMap метки нарушений
 * @param statusesMap статусы
 * @param usersMap пользователи
 * @param groupsMap группы
 * @param markers маркеры
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 */
export const convertProblems = (
	problems: IListProblem[],
	problemTagsMap: Record<string, IProblemTag>,
	statusesMap: Record<string, IProblemStatus>,
	usersMap: Record<string, IBriefUser>,
	groupsMap: Record<string, IWorkingGroup>,
	markers: IMarker[],
	networkStatus: boolean,
	user?: IUser
): IPreparedProblem[] =>
	problems.map(item => ({
		data: convertProblem(item, usersMap),
		id: item.id,
		objectId: item.objectId,
		object: item.object,
		links: item.links,
		number: item.number,
		localNumber: item.localNumber,
		description: item.description,
		status: {
			id: item.status,
			name: statusesMap[item.status]?.name
		},
		transferStatus: item.transferStatus,
		plannedFixDate: item.plannedFixDate
			? format(item.plannedFixDate, dateTimeCommaSeparatedFormat)
			: '',
		plannedFixDateOriginal: item.plannedFixDate,
		actualFixDate: item.actualFixDate
			? format(item.actualFixDate, dateTimeCommaSeparatedFormat)
			: '',
		actualFixDateOriginal: item.actualFixDate,
		categoryId: item.categoryId,
		plan: item.plan?.name || '',
		floor: item.floor || '',
		location: item.displayLocation || '',
		hasLocationComments: markers
		.reduce((acc: IMarker[], marker) =>
		(marker?.links?.problemId === item.id ? [...acc, marker] : acc), [])
		.some(marker => marker.description),
		inspectors: item.inspectors?.length
			? item.inspectors?.map(id => usersMap[id])
			: undefined,
		respUsers: item.respUsers?.length
			? item.respUsers?.map(id => usersMap[id]?.displayName)
			: undefined,
		activeGroup: item.activeGroup ? groupsMap[item.activeGroup]?.name : undefined,
		stage: item.stage,
		attachment: item.attachments ? item.attachments.find(att => att.preview) : undefined,
		watchers: item.watchers?.map(id => usersMap[id]?.displayName).join(', ') || '',
		critical: item.critical,
		problemTags: item.problemTags?.length
			? item.problemTags?.map(id => problemTagsMap[id]?.color)
			: undefined,
		localCreatedAt: item.localCreatedAt ? format(item.localCreatedAt, dateTimeLetterSeparatedFormat) : '',
		createdBy: item.createdBy && usersMap[item.createdBy]
			? usersMap[item.createdBy]?.displayName || ''
			: '',
		createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
		modifiedBy: item.modifiedBy && usersMap[item.modifiedBy]
			? usersMap[item.modifiedBy].displayName || ''
			: '',
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeCommaSeparatedFormat) : '',
		...getPermissionsForProblem(item, networkStatus, item.object?.id, user)
	}));
