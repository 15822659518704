import React from 'react';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {TasksPageControlBar} from '@src/pages/TasksPage/components/TasksPageControlBar';
import {TasksPageControlBarMobile} from '@src/pages/TasksPage/components/TasksPageControlBarMobile';
import {ScheduleTable} from '@src/pages/TasksPage/components/tabs/TasksSchedulePage/components';
import {FiltersContextWrap} from '@src/pages/TasksPage/components/FiltersContextWrap';
import {TasksFilters} from '@src/pages/TasksPage/components/TasksFilters';
import {LegendTasks} from './components/LegendTasks';
import {SelectMode} from './components/SelectMode';

export const TaskSchedulePage = () => {
	useAppHeader({
		title: 'Задачи',
		mobileRightButtons: <AppUpdateMenu/>
	});

	const isDesktop = useIsDesktop();

	return (
		<FiltersContextWrap>
			{isDesktop ? (
				<>
					<TasksPageControlBar/>
					<TasksFilters/>
				</>
			)
				: <TasksPageControlBarMobile/>}
			<div className="manage-task-schedule-page-bar">
				<SelectMode/>
				<LegendTasks/>
			</div>
			<ScheduleTable/>
		</FiltersContextWrap>
	);
};
