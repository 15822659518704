import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

/**
 * Преобразовывает задачи в необходимый для вывода формат
 *
 * @param data массив задач
 */
export const useEnrichedTasks = (
	data: ITask[], loading?: boolean
): IEnrichedTask[] => {
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);
	const taskStatusesMap = useAppSelector(s => s.dictionaries.taskStatuses.byId);
	const taskTypesMap = useAppSelector(s => s.dictionaries.taskTypes.byId);
	const taskPrioritiesMap = useAppSelector(s => s.dictionaries.taskPriorities.byId);

	return useMemo(
		() =>
			(!loading ? data.map(item => ({
				...item,
				objectId: objectsMap[item.objectId],
				status: item.status ? taskStatusesMap[item.status] : undefined,
				taskPriority: item.taskPriority ? taskPrioritiesMap[item.taskPriority] : undefined,
				taskType: item.taskType ? taskTypesMap[item.taskType] : undefined,
				floor: item.floor,
				respUsers: item.respUsers?.map(userId => usersMap[userId]),
				taskIntervalStart: item?.taskIntervalStart,
				taskIntervalEnd: item.taskIntervalEnd,
				createdBy: item.createdBy ? usersMap[item.createdBy] : undefined,
				createdAt: item.createdAt
					? format(item.createdAt, dateTimeCommaSeparatedFormat)
					: '',
				modifiedBy: item.modifiedBy ? usersMap[item.modifiedBy] : undefined,
				modifiedAt: item.modifiedAt
					? format(item.modifiedAt, dateTimeCommaSeparatedFormat)
					: ''
			})) : []),
		[data, loading, usersMap, objectsMap, taskStatusesMap, taskTypesMap, taskPrioritiesMap]
	);
};
