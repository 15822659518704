import './PlanViewer.less';
import PlanBase from '../PlanBase';
import AbstractHandler from './handlers/AbstractHandler';
import SelectableHandler, {ISelectableSector} from './handlers/SelectableHandler';
import CommonHandler from './handlers/CommonHandler';
import PointsHandler, {ISelectablePoint} from './handlers/PointsHandler';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import IShape from '@tehzor/tools/interfaces/plans/IShape';
import {IPlanBaseProps} from '../PlanBase/PlanBase';

export type Mode = 'view' | 'edit';

export type InputType = 'sectors' | 'points';

export type Path = 'problem' | 'structure';

interface ILayerWithHandler {
	id: string;
	name: string;
	type?: string;
	visible?: boolean;
	handler: AbstractHandler<IShape>;
}

interface IPlanViewerProps {
	mode: Mode;
	inputType: InputType;
	image: string;
	layers: ILayer[];
	visibleLayers: string[];
	sectors: ILocationMarker[];
	points: ILocationMarker[];
	multiplePoints?: boolean;
	singleSelectedPoint: number;
	pointsColor?: string;
	path: Path;
	entitySectors?: ILocationMarker[];
	entityPoints?: ILocationMarker[];
	selectPoint?: (point: number) => void;
	selectSector?: (sector: number) => void;
	onSectorsChange?(shapes: ILocationMarker[]): void;

	onPointsChange?(points: ILocationMarker[]): void;

	onLayersVisibleChange?(index: number, value: boolean): void;

	onMouseMovingChange?(active: boolean): void;
}

/**
 * Компонент для просмотра плана и выбора секторов и точек
 */
class PlanViewer extends PlanBase<IPlanViewerProps> {
	static defaultProps: Partial<IPlanViewerProps & IPlanBaseProps> = {
		frameClass: 'plan-viewer__frame',
		wrapperClass: 'plan-viewer__wrapper',
		svgClass: 'plan-viewer__svg',
		multiplePoints: false
	};

	// Слои с фигурами
	private _layers: ILayerWithHandler[] = [];

	// Секторы
	private _sectorsData: ILocationMarker[] = [];

	// Обработчик слоя с точками
	private _pointsLayerHandler?: PointsHandler;

	// Обработчик слоя с точками родительской сущности
	private _entityPointsLayerHandler?: PointsHandler;

	// Секторы родительской сущности
	private _entitySectorsData: ILocationMarker[] = [];

	// Перетаскивание ладошкой активно
	private _isMovingActive = true;

	// Перетаскивание точек активно: true - вкл перетаскивание
	private _isMovingPointsActive = false;

	// Начальная позиция по x при перетаскивании
	private _movingStartPositionX = 0;

	// Начальная позиция по y при перетаскивании
	private _movingStartPositionY = 0;

	/**
	 * Обработка пермещения плана тач жестком
	 */
	private _handleTouchPanMove = rafThrottle(event => {
		if (this._isMovingPointsActive) {
			return;
		}
		if (this._isMovingActive && this._frame) {
			this._frame.scrollLeft -= event.center.x - this._movingStartPositionX;
			this._frame.scrollTop -= event.center.y - this._movingStartPositionY;
			this._movingStartPositionX = event.center.x;
			this._movingStartPositionY = event.center.y;
		}
	});

	componentDidMount() {
		this._initialize();
	}

	componentDidUpdate(prevProps: IPlanViewerProps) {
		const {image, visibleLayers} = prevProps;
		this._initializeLayers();
		if (image !== this.props.image) {
			this._initialize();
		} else if (visibleLayers !== this.props.visibleLayers) {
			this._updateLayersVisibility();
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		this._destroySectorsLayers();
		this._destroyPointsLayer();
	}

	onScale = () => {
		this._initializeLayers();
	};

	/**
	 * Изменяет название точки
	 *
	 * @param index индекс
	 * @param name название
	 */
	editPointName = (index: number, name: string) => {
		if (this._pointsLayerHandler) {
			this._pointsLayerHandler.editPointName(index, name);
		}
	};

	/**
	 * Удаляет точку
	 *
	 * @param index индекс
	 */
	deletePoint = (index: number) => {
		if (this._pointsLayerHandler) {
			this._pointsLayerHandler.deletePoint(index);
		}
	};

	protected addFrameEventListeners() {
		super.addFrameEventListeners();

		if (this._hammer) {
			this._hammer.on('panstart', this._handleTouchPanStart);
			this._hammer.on('panend', this._handleTouchPanEnd);
			this._hammer.on('panmove', this._handleTouchPanMove);
		}
	}

	protected removeFrameEventListeners() {
		super.removeFrameEventListeners();

		if (this._hammer) {
			this._hammer.off('panstart', this._handleTouchPanStart);
			this._hammer.off('panend', this._handleTouchPanEnd);
			this._hammer.off('panmove', this._handleTouchPanMove);
		}
	}

	/**
	 * Инициализирует компонент
	 */
	private _initialize = () => {
		this.initDOMElements(this.props.image, () => {
			this._initializeLayers();
		});
		if (this._svg) {
			this._svg.addClass('svg');
		}
	};

	/**
	 * Инициализирует слои с фигурами
	 */
	private _initializeLayers = () => {
		const {inputType} = this.props;

		this._destroySectorsLayers();
		this._destroyPointsLayer();

		if (inputType === 'sectors') {
			this._initializeSectorsLayers();
		}
		if (inputType === 'points') {
			this._initializePointsLayer();
		}

		this._initializeEntitySectorsLayers();
		this._initializeEntityPointsLayer();
	};

	/**
	 * Инициализирует слои для отображения секторов родительской сущности
	 */
	private _initializeEntitySectorsLayers = () => {
		if (!this._svg || !this._layersGroup) {
			return;
		}
		const {layers, entitySectors} = this.props;

		if (!entitySectors) {
			return;
		}
		const sectorsIds: string[] = entitySectors.filter(item => item.sector).map(item => item.sector!);
		this._entitySectorsData = entitySectors;

		for (let i = 0, layer, handler; i < layers.length; i++) {
			layer = layers[i];

			if (layer.shapes === undefined) {
				continue;
			}

			let visibleShapes: string[];
			if (layer.type === 'axes') {
				visibleShapes = layer.shapes ? layer.shapes.map(item => item.id) : [];
			} else {
				visibleShapes = sectorsIds;
			}
			handler = new CommonHandler(
				this._svg,
				this._layersGroup,
				layer.id,
				!!layer.visible,
				layer.shapes,
				visibleShapes,
				this._entitySectorsData,
				'entity'
			);

			this._layers.push({
				id: layer.id,
				name: layer.name,
				type: layer.type,
				visible: layer.visible,
				handler
			});
		}
		this._entitySectorsData = entitySectors;
	};

	/**
	 * Инициализирует слой для отображения точек родительской сущности
	 */
	private _initializeEntityPointsLayer = () => {
		const {layers, entityPoints, path} = this.props;
		if (this._svg && entityPoints) {
			this._entityPointsLayerHandler = new PointsHandler(
				this._svg,
				'view',
				false,
				entityPoints,
				layers,
				undefined,
				undefined,
				'structure',
				path,
				this._zoomValue
			);
		}
	};

	/**
	 * Инициализирует слои для варианта выбора "секторы"
	 */
	private _initializeSectorsLayers = () => {
		if (!this._svg || !this._layersGroup) {
			return;
		}
		const {mode, layers, visibleLayers, sectors} = this.props;

		const sectorsIds: string[] = sectors.filter(item => item.sector).map(item => item.sector!);
		this._sectorsData = sectors;
		for (let i = 0, layer, handler; i < layers.length; i++) {
			layer = layers[i];

			if (layer.shapes === undefined) {
				continue;
			}
			if (mode === 'edit') {
				if (layer.type === 'axes') {
					const visibleShapes = layer.shapes ? layer.shapes.map(item => item.id) : [];
					handler = new CommonHandler(
						this._svg,
						this._layersGroup,
						layer.id,
						!!layer.visible,
						layer.shapes,
						visibleShapes,
						[] as ILocationMarker[],
						layer.type
					);
				} else {
					handler = new SelectableHandler(
						this._svg,
						this._layersGroup,
						layer.id,
						visibleLayers.includes(layer.id),
						layer.shapes,
						this._sectorsData,
						(editedectorsData: ILocationMarker[]) => this._emitSectorsChanged(editedectorsData)
					);
				}
			} else {
				let visibleShapes: string[];
				if (layer.type === 'axes') {
					visibleShapes = layer.shapes ? layer.shapes.map(item => item.id) : [];
				} else {
					visibleShapes = sectorsIds;
				}
				handler = new CommonHandler(
					this._svg,
					this._layersGroup,
					layer.id,
					!!layer.visible,
					layer.shapes,
					visibleShapes,
					this._sectorsData,
					layer.type,
					(editedectorsData: ILocationMarker[]) => this._emitSectorsChanged(editedectorsData)
				);
			}

			this._layers.push({
				id: layer.id,
				name: layer.name,
				type: layer.type,
				visible: layer.visible,
				handler
			});
		}
		this._sectorsData = sectors;
	};

	/**
	 * Инициализирует слой для варианта выбора "точки"
	 */
	private _initializePointsLayer = () => {
		const {mode, layers, points, multiplePoints = false, singleSelectedPoint, pointsColor, path} = this.props;
		if (this._svg) {
			this._pointsLayerHandler = new PointsHandler(
				this._svg,
				mode,
				multiplePoints,
				points,
				layers,
				singleSelectedPoint,
				pointsColor,
				undefined,
				path,
				this._zoomValue,
				this._handlePointsChanged,
				this._handlePointMoving
			);
		}
	};

	/**
	 * Очищает ресурсы, связанные со слоями
	 */
	private _destroySectorsLayers = () => {
		for (const layer of this._layers) {
			layer.handler.destroy();
		}
		this._layers = [];
	};

	/**
	 * Очищает ресурсы, связанные со слоем точек
	 */
	private _destroyPointsLayer = () => {
		if (this._pointsLayerHandler) {
			this._pointsLayerHandler.destroy();
		}
		if (this._entityPointsLayerHandler) {
			this._entityPointsLayerHandler.destroy();
		}
	};

	/**
	 * Обновляет видимость слоёв
	 */
	private _updateLayersVisibility = () => {
		const {visibleLayers} = this.props;
		for (const layer of this._layers) {
			layer.handler.toggleVisible(visibleLayers.includes(layer.id));
		}
	};

	/**
	 * Обрабатывает событие начала перетаскивания плана
	 *
	 * @param event событие
	 */
	// @ts-ignore
	private _handleTouchPanStart = event => {
		if (this._isMovingPointsActive) {
			return;
		}
		this._isMovingActive = true;
		this._movingStartPositionX = event.center.x;
		this._movingStartPositionY = event.center.y;
		this._updateHandlersPlanMoving();
	};

	/**
	 * Обрабатывает событие конца перетаскивания плана
	 */
	private _handleTouchPanEnd = () => {
		this._isMovingActive = false;
		this._updateHandlersPlanMoving();
	};

	/**
	 * Оповещает обработчики слоёв о премещении плана
	 */
	private _updateHandlersPlanMoving = () => {
		const {inputType} = this.props;
		if (inputType === 'points' && this._pointsLayerHandler) {
			this._pointsLayerHandler.togglePlanMoving(this._isMovingActive);
		}
		if (inputType === 'sectors') {
			this._layers.forEach(layer => {
				layer.handler.togglePlanMoving(this._isMovingActive);
			});
		}
	};

	/**
	 * Обрабатывает событие изменения активности перетаскивания точки
	 *
	 * @param active флаг
	 */
	private _handlePointMoving = (active: boolean) => {
		this._isMovingPointsActive = active;
	};

	/**
	 * Оповещает об изменении фигур в конкретном слое
	 *
	 * @param layerIndex индекс слоя
	 * @param shapes фигуры
	 */
	private _emitSectorsChanged = (editedectorsData: ISelectableSector[]) => {
		const {onSectorsChange, selectSector} = this.props;
		if (onSectorsChange) {
			onSectorsChange(this._sectorsData = editedectorsData);
		}
		if (selectSector) {
			const selectedPoints = editedectorsData.filter(
				(point: ISelectableSector) => point.selected
			);
			if (selectedPoints.length === 1) {
				selectSector(
					editedectorsData.findIndex((point: ISelectableSector) => point.selected)
				);
			}
		}
	};

	/**
	 * Оповещает об изменении точек
	 *
	 * @param points точки
	 */
	private _handlePointsChanged = (points: ISelectablePoint[]) => {
		const {onPointsChange, selectPoint} = this.props;
		if (onPointsChange) {
			onPointsChange(points);
		}
		if (selectPoint) {
			const selectedPoints = points.filter((point: ISelectablePoint) => point.selected);
			if (selectedPoints.length === 1) {
				selectPoint(points.findIndex((point: ISelectablePoint) => point.selected));
			}
		}
	};
}

export default PlanViewer;
