import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {offlineJournalActionType} from '../enums/actionsTypes';

export const useLocalInternalAcceptancesJournal = () => {
	const {data} = useQuery<IListInternalAcceptance[], unknown, IOfflineJournalEntity[]>({
		queryKey: internalAcceptancesQueryKeys.localList(),
		select: internalAcceptances =>
			internalAcceptances.map(
				acceptance =>
					({
						entity: {
							entityId: acceptance.id,
							entityLocalNumber: acceptance.localNumber,
							entityType: offlineJournalActionType.INTERNAL_ACCEPTANCE_ADD
						},
						object: {
							objectId: acceptance.object?.id,
							objectName: acceptance.object?.name
						},
						transfer: {
							status: acceptance.transferStatus,
							statusMessage: acceptance.transferStatus
								? offlineModeTransferTitles[acceptance.transferStatus]
								: ''
						},
						createdAt: acceptance.createdAt
					} as IOfflineJournalEntity)
			)
	});
	return data || [];
};
