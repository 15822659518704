import {requestSpace} from '@src/api/backend/space/get';
import {createEntityAction} from '@src/store/modules/app/links/actions/createEntityAction';
import {getSpaceTypes} from '@src/store/modules/dictionaries/spaceTypes/actions';

/**
 * Возвращает помещение по id
 */
export const getSpace = createEntityAction('spaceId', requestSpace, {
	loadAdditionalData: () => dispatch => {
		void dispatch(getSpaceTypes());
	}
});
