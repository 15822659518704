const domain = 'inspections';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const CHANGE_OFFSET = `${domain}/changeOffset`;
export const CHANGE_SELECTED_ROWS = `${domain}/changeSelectedRows`;
export const TOGGLE_ALL_ROWS_SELECTED = `${domain}/toggleAllRowsSelected`;
export const CLEAR_EXPIRATION = `${domain}/clear-expiration`;

export const EXPORT_REQUEST = `${domain}/export/request`;
export const EXPORT_SUCCESS = `${domain}/export/success`;
export const EXPORT_FAILURE = `${domain}/export/failure`;

export const SEND_REQUEST = `${domain}/send/request`;
export const SEND_SUCCESS = `${domain}/send/success`;
export const SEND_FAILURE = `${domain}/send/failure`;
