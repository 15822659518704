import React, {useCallback, useEffect, useState} from 'react';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {
	useEditableOwnerAcceptanceState,
	convertToSave,
	hasAttachmentsError,
	isEdited,
	errorsFns
} from '@src/core/hooks/states/useEditableOwnerAcceptanceState';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import EditableOwnerAcceptanceAttachments from '../EditableOwnerAcceptanceAttachments';
import {ISavingOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ISavingOwnerAcceptance';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	phone: {fieldId: 'phone', isRequired: true},
	email: {fieldId: 'email', isRequired: true}
};

/**
 * Логика редактирования вложений
 */
export const useEditableOwnerAcceptanceAttachments = (
	objectId: string,
	acceptance: IOwnerAcceptance,
	saving: boolean
): [React.ReactNode, () => Promise<ISavingOwnerAcceptance | undefined>, (
) => void, boolean] => {
	// TODO Определение типа настроек
	// const fieldsSettings = useFieldsSettings(objectId, 'warrantyClaim');

	const [editingState, editingDispatch] = useEditableOwnerAcceptanceState(acceptance);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(
			isEdited(editingState, acceptance) || isFilesExist(uploadingFilesState.value)
		);
	}, [editingState, uploadingFilesState.value, acceptance]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, errorsFns, fieldsSettings)
			|| (hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, acceptance)
			&& (!isFilesExist(files) || someFilesHaveError(files))
		) {
			return undefined;
		}
		const savingData = convertToSave(editingState, acceptance, true);
		savingData.newAttachments = convertFilesToSave(files);
		return savingData;
	}, [editingState, objectId, acceptance, fieldsSettings]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: acceptance
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [acceptance]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [acceptance]);

	const fields = (
		<EditableOwnerAcceptanceAttachments
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
