import {
	IDeleteManyCategoriesResponse,
	requestDeleteManyCategories
} from '@src/api/backend/categories';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {queryClient} from '@src/api/QueryClient';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';

export interface IDeleteManyCategoriesPayload {
	categoriesSetId: string;
}

const request = () => ({
	type: types.DELETE_MANY_REQUEST
});

const success = (categoriesSetId: string) => ({
	type: types.DELETE_MANY_SUCCESS,
	payload: {categoriesSetId} as IDeleteManyCategoriesPayload
});

const failure = (error: IError) => ({
	type: types.DELETE_MANY_FAILURE,
	payload: error
});

export const deleteCategories = (categoriesSetId: string) =>
	createApiAction<IDeleteManyCategoriesResponse>(
		request,
		() => success(categoriesSetId),
		failure,
		async () => {
			const data = await requestDeleteManyCategories(categoriesSetId);
			await queryClient.invalidateQueries(categoriesQueryKeys.list());
			return data;
		}
	);
