import React, {memo, useCallback, useReducer} from 'react';
import {ActionButtons, Button, Dialog, TabContent, TabLink, Tabs} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import ObjectsTab from './components/ObjectsTab';
import './StatsFiltersDialog.less';
import {init, reducer} from './utils/state';
import {FiltersDialogDispatchCtx} from './utils/FiltersDialogDispatchCtx';
import FiltersTags from './components/FiltersTags';
import {convertToStore} from './utils/convertToStore';
import {extractStatsSources} from '@src/store/modules/statistics/sources/selectors';
import InspectorsTab from './components/InspectorsTab';
import ContractorsTab from './components/ContractorsTab';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {IBuildingStatisticsFiltersState} from '@src/store/modules/settings/pages/statistics/building/reducers/filters';
import {AnyAction} from '@reduxjs/toolkit';

interface IStatsFiltersDialogProps {
	// TODO Отвязать от строительства
	filters: IBuildingStatisticsFiltersState;
	filtersDialogTabIndex: number;
	isOpen: boolean;

	changeFilters: (value: IBuildingStatisticsFiltersState) => AnyAction;
	changeFiltersDialogTabIndex: (value: number) => AnyAction;
	onRequestClose: () => void;
}

const StatsFiltersDialog = (props: IStatsFiltersDialogProps) => {
	const {
		filters,
		filtersDialogTabIndex,
		isOpen,
		changeFilters,
		changeFiltersDialogTabIndex,
		onRequestClose
	} = props;

	const sources = useAppSelector(extractStatsSources);
	const companies = useAppSelector(s => s.dictionaries.companies);
	const dispatch = useAppDispatch();

	const [filtersState, filtersDispatch] = useReducer(
		reducer,
		{
			filters,
			sources
		},
		init
	);

	useUpdateEffect(() => {
		filtersDispatch({
			type: 'reset-checked',
			filters,
			sources
		});
	}, [filters]);

	const handleTabChange = useCallback((index: number) => {
		dispatch(changeFiltersDialogTabIndex(index));
	}, []);

	const handleOk = useCallback(() => {
		const newFilters = convertToStore(filtersState, sources, companies);
		dispatch(changeFilters(newFilters));
		onRequestClose();
	}, [filtersState, sources, onRequestClose]);

	const handleCancel = useCallback(() => {
		filtersDispatch({
			type: 'reset-checked',
			filters,
			sources
		});
		onRequestClose();
	}, [filters, onRequestClose]);

	return (
		<Dialog
			className={{
				root: 'stats-filters-dialog',
				body: 'stats-filters-dialog__body'
			}}
			title="Фильтры"
			footer={(
				<ActionButtons className="stats-filters-dialog__action-buttons">
					<Button
						type="accent-blue"
						label="Показать результаты"
						onClick={handleOk}
					/>
					<Button
						type="cancel"
						label="Отмена"
						onClick={handleCancel}
					/>
				</ActionButtons>
			)}
			isOpen={isOpen}
			useContentOpenAnimation
			useContentCloseAnimation
			onRequestClose={handleCancel}
		>
			<FiltersDialogDispatchCtx.Provider value={filtersDispatch}>
				<FiltersTags state={filtersState}/>

				<Tabs
					className={{
						root: 'stats-filters-dialog__tabs',
						links: 'stats-filters-dialog__tabs-links',
						content: 'stats-filters-dialog__tabs-content'
					}}
					activeTab={filtersDialogTabIndex}
					onActiveTabChange={handleTabChange}
					links={[
						<TabLink
							key="objects"
							label={companies.allIds.length > 1 ? 'Компании и объекты' : 'Объекты'}
						/>,
						<TabLink
							key="inspectors"
							label="Проверяющие"
						/>,
						<TabLink
							key="contractors"
							label="Подрядчики"
						/>
					]}
				>
					<TabContent className="stats-filters-dialog__tabs-content">
						<ObjectsTab
							checked={filtersState.checkedObjects}
							expanded={filtersState.expandedObjects}
						/>
					</TabContent>

					<TabContent className="stats-filters-dialog__tabs-content">
						<InspectorsTab
							checked={filtersState.checkedUsers}
							expanded={filtersState.expandedUsers}
						/>
					</TabContent>

					<TabContent className="stats-filters-dialog__tabs-content">
						<ContractorsTab
							checked={filtersState.checkedContractors}
							expanded={filtersState.expandedContractors}
						/>
					</TabContent>
				</Tabs>
			</FiltersDialogDispatchCtx.Provider>
		</Dialog>
	);
};

export default memo(StatsFiltersDialog);
