import React, {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {expiredOptions} from '@tehzor/tools/interfaces/expiration/IExpirationFilter';

interface IExpiredFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const ExpiredFilterMobile = memo((props: IExpiredFilterMobileProps) => (
	<BaseListFilterMobile
		{...props}
		options={expiredOptions as Array<{id: string, name: string}>}
		label="Просрочено"
		filterName="expired"
	/>
));
