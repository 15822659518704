import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {ISpacesCheckListsDataQueryKey, spacesQueryKeys} from '../keys';
import {requestSpacesCheckListsData} from '@src/api/backend/spaces/checkListsData';

export const useSpacesCheckListsDataQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spacesQueryKeys.checkListsData(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpacesCheckListsDataQueryKey>) => {
			const [, , objectId, spacesIds] = queryKey;
			return requestSpacesCheckListsData({objectId, spacesIds});
		},
		staleTime: 1000 * 60 * 60 * 24, // 1 день
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
