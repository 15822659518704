import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as checkListTypes from '../../entities/checkList/constants';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	getLoadableEntitiesInitialState,
	ILoadableEntitiesState,
	handleLoadableEntitiesGetting,
	handleLoadableEntityAdding,
	handleLoadableEntityEditing,
	handleLoadableEntityDeletion,
	handleLoadableEntitiesStartLoading,
	handleLoadableEntitiesStopLoading
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type ICheckListsState = ILoadableEntitiesState<ICheckList>;

export const checkLists = createReducer<ICheckListsState>(
	getLoadableEntitiesInitialState<ICheckList>(),
	{
		[types.GET_REQUEST]: handleLoadableEntitiesStartLoading<ICheckList>(),
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ICheckList>(),
		[types.GET_FAILURE]: handleLoadableEntitiesStopLoading<ICheckList>(),
		[checkListTypes.ADD_SUCCESS]: handleLoadableEntityAdding<ICheckList>(),
		[checkListTypes.EDIT_SUCCESS]: handleLoadableEntityEditing<ICheckList>(),
		[checkListTypes.DELETE_SUCCESS]: handleLoadableEntityDeletion<ICheckList>(),
		[CLEAR_STORE]: getLoadableEntitiesInitialState,
		[CLEAR_BEFORE_UPDATE]: getLoadableEntitiesInitialState
	}
);
