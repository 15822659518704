import React, {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton, TreeSelect, TreeSelectTagOption} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {extractProblemTagsAsArray} from '@src/store/modules/dictionaries/problemTags/selectors';
import {extractProblemTagsSetsAsArrayForObject} from '@src/store/modules/dictionaries/problemTagsSets/selectors';
import {makeTreeData} from '@src/store/modules/dictionaries/problemTagsSets/utils/makeTreeData';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const ProblemTagsSetFilterPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{problemTags?: string[]}>();
	const [selected, setSelected] = useState(state.problemTags);
	const [expanded, setExpanded] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const allTags = useAppSelector(extractProblemTagsAsArray);
	const tagsSets = useAppSelector(s =>
		extractProblemTagsSetsAsArrayForObject(s, objectId || 'all'));

	const treeData = useMemo(() => makeTreeData(tagsSets, allTags), [tagsSets, allTags]);

	const {filteredData, expanded: expandedValue} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpanded(expandedValue.map(item => item.id));
	}, [expandedValue]);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({problemTags: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: 'Метки',
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label="Сбросить"
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={isLocalStateEqual(state.problemTags, selected)}
			onApplyClick={handleApply}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selected}
				onChange={setSelected}
				expandedValue={expanded}
				onExpand={setExpanded}
				TreeItem={TreeSelectTagOption}
				isCheckable
			/>
		</FilterPage>
	);
};

export default ProblemTagsSetFilterPage;
