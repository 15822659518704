import React from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {getObjectStats} from '@src/store/modules/dictionaries/objects/actions';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IProblemEntity[];
	selectedClearing: () => void;
}

const SelectedEntitiesDeletion = ({
	selectedEntities,
	selectedClearing
}: ISelectedEntitiesDeletionProps) => {
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить нарушения',
		'Вы действительно хотите удалить выбранные нарушения?',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			const objectsIds: string[] = [];
			for (const problem of selectedEntities) {
				await dispatch(deleteProblem(problem.data.objectId, problem.data.id));
				objectsIds.push(problem.data.objectId);
			}
			selectedClearing();
			await dispatch(getObjectStats(objectsIds));
		}
	}, [selectedEntities, selectedClearing]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete"/>
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
