import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetWorkAcceptancesResponse extends INormalizedData<IListWorkAcceptance> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список приёмок работ
 */
export const requestWorkAcceptances = (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => (wsConnector.sendAuthorizedRequest<IGetWorkAcceptancesResponse>('getWorkAcceptances', {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	}));