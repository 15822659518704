import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useSpaceStatusesSetPermissions} from '@src/core/hooks/permissions/useSpaceStatusesSetsPermissions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {getSpaceStatuses} from '@src/store/modules/dictionaries/spaceStatuses/actions';
import {extractSpaceStatusesAsArrayBySetId} from '@src/store/modules/dictionaries/spaceStatuses/selectors';
import {getSpaceStatusesSets} from '@src/store/modules/dictionaries/spaceStatusesSets/actions';
import {extractSpaceStatusesSetById} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {addErrorToast} from '@src/utils/toasts';
import {LoadingPanel} from '@tehzor/ui-components';
import React, {useEffect} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useAsync} from 'react-use';
import {SpaceStatusesSetContent} from './components/SpaceStatusesSetContent';
import './SpaceStatusesSetPage.less';

export const SpaceStatusesSetPage = () => {
	useScrollRestoration();

	const dispatch = useAppDispatch();
	const {spaceStatusesSetId} = useStrictParams<{spaceStatusesSetId: string}>();

	const {canView} = useSpaceStatusesSetPermissions();
	useEffect(() => {
		if (!canView) {
			addErrorToast('Отсутствие доступа', 'к просмотру страницы');
		}
	}, [canView]);

	const loadingState = useAsync(async () => {
		if (spaceStatusesSetId === 'empty') {
			return;
		}

		await dispatch(getSpaceStatusesSets());
		await dispatch(getSpaceStatuses());
	}, [spaceStatusesSetId]);

	const spaceStatusesSet = useAppSelector(s =>
		extractSpaceStatusesSetById(s, spaceStatusesSetId));
	const spaceStatuses = useAppSelector(s =>
		extractSpaceStatusesAsArrayBySetId(s, spaceStatusesSetId));

	useAppHeader(
		{
			title: spaceStatusesSet?.name || 'Новый набор статусов помещений',
			showBackBtn: true
		},
		[spaceStatusesSet]
	);

	if (!canView) {
		return null;
	}
	return (
		<LoadingPanel
			className="space-statuses-set__loading-panel"
			active={loadingState.loading}
		>
			<>
				{!loadingState.loading && (
					<div className="space-statuses-set">
						<SpaceStatusesSetContent
							spaceStatuses={spaceStatuses}
							spaceStatusesSet={spaceStatusesSet}
						/>
					</div>
				)}
			</>
		</LoadingPanel>
	);
};
