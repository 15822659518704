import {wsConnector} from '@src/api/backend/wsConnector';

export interface IDeleteManySpaceStatusesResponse {
	success: boolean;
}

/**
 * Удаляет статусы помещений по id набора
 *
 * @param spaceStatusesSetId id набора статусов помещений
 */
export const requestDeleteManySpaceStatuses = (spaceStatusesSetId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteManySpaceStatusesResponse>('deleteManySpaceStatuses', {
		spaceStatusesSetId
	});
