import {IReportsTableRow} from '@src/pages/SpacesPage/hooks/useReportsTable';
import React, {memo} from 'react';
import {Column, useTable, useExpanded} from 'react-table';

interface IReportsTableProps {
	columns: Array<Column<IReportsTableRow>>;
	data: IReportsTableRow[];
}

export const ReportsTable = memo(({columns, data}: IReportsTableProps) => {
	const {
		getTableProps,
		getTableBodyProps,
		headers,
		rows,
		prepareRow
	} = useTable<IReportsTableRow>(
		{
			columns,
			data
		},
		useExpanded
	);

	return (
		<table
			className="spaces-page__reports-table"
			{...getTableProps()}
		>
			<thead className="spaces-page__reports-table-header">
				<tr className="spaces-page__reports-table-header__row">
					{headers.map(column => (
						<th
							className="spaces-page__reports-table-header__row-cell"
							{...column.getHeaderProps()}
						>
							{column.render('Header')}
						</th>
					))}
				</tr>
			</thead>
			<tbody
				className="spaces-page__reports-table-body"
				{...getTableBodyProps()}
			>
				{rows.map(row => {
					prepareRow(row);
					return (
						<tr
							className="spaces-page__reports-table-body__row"
							{...row.getRowProps()}
						>
							{row.cells.map(cell => (
								<td
									className="spaces-page__reports-table-body__row-cell"
									{...cell.getCellProps()}
								>
									{cell.render('Cell')}
								</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
});