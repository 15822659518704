import {wsConnector} from '../wsConnector';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';

export type IAddCategoriesSetResponse = ICategoriesSet;

export const requestAddCategoriesSet = (categoriesSet: ISavingCategoriesSet) =>
	wsConnector.sendAuthorizedRequest<IAddCategoriesSetResponse>('addCategoriesSet', {
		...categoriesSet
	});
