import {
	requestProblemComments,
	IGetProblemCommentsResponse
} from '@src/api/backend/problemComments';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ProblemCommentsVisibility} from '@src/store/modules/settings/pages/problem/reducers/comments';

export type IGetProblemCommentsPayload = IGetProblemCommentsResponse;

const request = () => ({type: types.GET_COMMENTS_REQUEST});

const success = (response: IGetProblemCommentsResponse) => ({
	type: types.GET_COMMENTS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка комментариев и ответов');
	return {
		type: types.GET_COMMENTS_FAILURE,
		payload: error
	};
};

/**
 * Получает список комментариев и ответов для конкретного нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const getCommentsForProblem
	= (objectId: string, problemId: string): AppThunkAction<Promise<IGetProblemCommentsResponse>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetProblemCommentsResponse>(request, success, failure, () => {
				const s = getState();
				const {offset, limit} = s.pages.problem.comments;
				const {visibility} = s.settings.pages.problem.comments;
				const official
					= visibility.length === 1
						? visibility[0] === ProblemCommentsVisibility.REPLIES
						: undefined;
				return requestProblemComments(
					{
						objects: [objectId],
						problems: [problemId],
						official
					},
					{createdAt: false},
					offset,
					limit
				);
			})
		);
