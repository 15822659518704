import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {ITasksListQueryKey, ITasksScheduleQueryKey, tasksQueryKeys} from '../keys';
import {requestTasks} from '@src/api/backend/tasks';
import {IListTask} from '@tehzor/tools/interfaces/tasks/IListTask';
import {requestTask} from '@src/api/backend/task';

export const useTasksQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(tasksQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ITasksListQueryKey>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestTasks(filters, sort, offset, pageSize);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(tasksQueryKeys.schedule(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ITasksScheduleQueryKey>) => {
			const [, , filters, sort] = queryKey;
			return requestTasks(filters, sort, 0, 10000);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(tasksQueryKeys.details(), {
		queryFn: async ({queryKey}) => requestTask(queryKey[2] as string),
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(tasksQueryKeys.localList(), {
		queryFn: () => {
			const localProblems = queryClient.getQueriesData<IListTask>({
				queryKey: tasksQueryKeys.details()
			});
			return localProblems.map(([, task]) => task) as IListTask[];
		},
		staleTime: 0,
		cacheTime: Infinity,
		networkMode: 'always'
	});
};
