import {requestCheckItems} from '@src/api/backend/checkItems';
import {useQueryClient} from '@tanstack/react-query';
import {checkItemsQueryKeys} from '../keys';

export const useCheckItemsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(checkItemsQueryKeys.list(), {
		queryFn: requestCheckItems,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
