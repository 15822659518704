import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import React from 'react';
import {DesktopAddButton} from './DesktopAddButton';
import {useManageProblemTagsSetsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageProblemTagsSetsBreadcrumbs';
import {useProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';

export const PageBreadcrumbs = () => {
	const breadcrumbs = useManageProblemTagsSetsBreadcrumbs();
	const permissions = useProblemTagsSetPermissions();

	return (
		<AppBreadcrumbs
			className="manage-problem-tags-sets-page__breadcrumbs"
			items={breadcrumbs}
		>
			{permissions.canAdd ? <DesktopAddButton/> : null}
		</AppBreadcrumbs>
	);
};
