import React from 'react';
import './ExpandablePanel.less';
import {AnimatePresence, motion} from 'framer-motion';
import classNames from 'classnames';

const animationVariants = {
	opened: {opacity: 1, height: 'auto'},
	collapsed: {opacity: 0, height: 0}
};

const animationTransition = {type: 'tween', duration: 0.25};

interface IExpandablePanelProps {
	className?: string;
	children?: React.ReactNode;
	expanded?: boolean;
}

const ExpandablePanel = ({className, children, expanded}: IExpandablePanelProps) => (
	<div className={classNames('expandable-panel', className)}>
		{children !== undefined && (
			<AnimatePresence initial={false}>
				{expanded && (
					<motion.div
						className="expandable-panel__animation"
						initial="collapsed"
						animate="opened"
						exit="collapsed"
						variants={animationVariants}
						transition={animationTransition}
					>
						{children}
					</motion.div>
				)}
			</AnimatePresence>
		)}
	</div>
);

ExpandablePanel.displayName = 'ExpandablePanel';

export default ExpandablePanel;
