import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IProblemCommentStory} from '@tehzor/tools/interfaces/problemCommentsStories/IProblemCommentStory';
import {wsConnector} from '../wsConnector';

export type IGetProblemCommentsStoriesResponse = INormalizedData<IProblemCommentStory>;

/**
 * Получает историю комментариев нарушения по id
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const requestProblemCommentsStories = (objectId: string, problemId: string) => {
	const params = {objectId, problemId};
	return wsConnector.sendAuthorizedRequest<IGetProblemCommentsStoriesResponse>(
		'getProblemCommentsStories',
		params
	);
};
