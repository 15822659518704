import React, {useState, memo} from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

interface IPhotoProps {
	className?: string;
	style?: React.CSSProperties;
	url: string;
	suspend?: boolean;
}

const Photo = ({className, style, url, suspend}: IPhotoProps) => {
	const [visible, setVisible] = useState(!suspend);

	useUpdateEffect(() => {
		setVisible(s => s || !suspend);
	}, [suspend]);

	return (
		<div
			className={className}
			style={{...style, backgroundImage: visible ? `url(${url})` : undefined}}
		/>
	);
};

export default memo(Photo);