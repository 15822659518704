import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import {IGetWorkAcceptancePayload} from './actions/get';
import {
	IGetWorkAcceptanceProblemsPayload,
	IEditWorkAcceptanceStatusPayload,
	IEditWorkAcceptancePayload,
	IGetWorkAcceptanceProblemRepliesPayload
} from './actions';
import {
	IAddProblemPayload,
	IDeleteProblemPayload,
	IEditProblemStatusPayload
} from '@src/store/modules/pages/problem/actions';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {IAddProblemCommentPayload} from '@src/store/modules/entities/problemComment/actions';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ILoadedEntityState, getLoadedEntitiesInitialState, getLoadedEntitiesSuccess} from '@tehzor/tools/utils/reducersHandlers';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {IGetDelegationHistoriesByWorkAcceptancePayload} from './actions/delegationHistories';

const {entitiesCacheTime} = config;

export interface IWorkAcceptanceState {
	data?: IWorkAcceptance;
	expires?: number;
	problems: {
		byId: Record<string, IListProblem>;
		allIds: string[];
		expires?: number;
	};
	problemReplies: {
		byId: Record<string, IProblemComment>;
		allIds: string[];
		expires?: number;
	};
	delegationHistories: ILoadedEntityState<IDelegationHistory>;

}

export const getInitialState = (): IWorkAcceptanceState => ({
	problems: {
		byId: {},
		allIds: []
	},
	problemReplies: {
		byId: {},
		allIds: []
	},
	delegationHistories: {
		byId: {},
		allIds: [],
		loaded: false
	}
});

const isAnotherWorkAcceptance = (state: IWorkAcceptanceState, payload: IEditWorkAcceptancePayload) =>
	!(state.data?.id === payload.id);

const updateWorkAcceptance = (state: IWorkAcceptanceState, {payload}: { payload: IEditWorkAcceptancePayload }) => (
	isAnotherWorkAcceptance(state, payload)
	? state
	: {
		...state,
		data: {
			...payload
		},
		expires: Date.now() + entitiesCacheTime * 1000,
		delegationHistories: getInitialState().delegationHistories
	});

export const workAcceptance = createReducer<IWorkAcceptanceState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetWorkAcceptancePayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditWorkAcceptancePayload}) => {
		state.data = {...state.data, ...payload};
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_STATUS_SUCCESS]: (state, {payload}: {payload: IEditWorkAcceptanceStatusPayload}) => {
		state.data = {...state.data, ...payload};
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Загрузка сущностей
	[types.GET_PROBLEMS_SUCCESS]: (
		state,
		{payload}: {payload: IGetWorkAcceptanceProblemsPayload}
	) => {
		state.problems.byId = payload.byId;
		state.problems.allIds = payload.allIds;
		state.problems.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_PROBLEM_REPLIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetWorkAcceptanceProblemRepliesPayload}
	) => {
		state.problemReplies.byId = payload.byId;
		state.problemReplies.allIds = payload.allIds;
		state.problemReplies.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Нарушения
	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
		if (state.data?.id === payload.links?.workAcceptanceId) {
			state.problems.byId[payload.id] = payload;
			state.problems.allIds.unshift(payload.id);
		}
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemStatusPayload}
	) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state.problems.allIds.includes(payload.problemId)) {
			state.problems.allIds = state.problems.allIds.filter(id => id !== payload.problemId);
			delete state.problems.byId[payload.problemId];
		}
	},
	// Ответы на нарушения
	[problemReplyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
		if (state.data?.id === payload.links?.workAcceptanceId) {
			state.problemReplies.byId[payload.id] = payload;
			state.problemReplies.allIds.unshift(payload.id);
		}
	},
	[types.EDIT_RESPONSIBLE_SUCCESS]: updateWorkAcceptance,
	[types.GET_DELEGATION_HISTORIES_REQUEST]: state => {
		state.delegationHistories = getLoadedEntitiesInitialState();
	},
	[types.GET_DELEGATION_HISTORIES_SUCCESS]: (
		state,
		params: {payload: IGetDelegationHistoriesByWorkAcceptancePayload}
	) => {
		state.delegationHistories = getLoadedEntitiesSuccess<IDelegationHistory>()(
			state.delegationHistories,
			params
		);
	},
	[CLEAR_STORE]: getInitialState
});
