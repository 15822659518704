import {IGetContractResponse} from '@src/api/backend/contract';
import {IGetPaginateContractsResponse} from '@src/api/backend/contracts';
import {IGetListContractsResponse} from '@src/api/backend/contracts/getList';
import {contractsQueryKeys} from '@src/api/cache/contracts/keys';
import {IContractsFiltersState, IContractsSortState} from '@src/store/modules/settings/pages/contracts/reducers';
import {extractContractsPageSettings} from '@src/store/modules/settings/pages/contracts/selectors';
import {addErrorToast} from '@src/utils/toasts';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useMemo} from 'react';
import useAppSelector from '../../useAppSelector';

export const useContracts = () => {
	const {
		offset, filters, sort, pageSize
	} = useAppSelector(extractContractsPageSettings);

	const {data} = useQuery<IGetPaginateContractsResponse, IError>({
		queryKey: [
			...contractsQueryKeys.paginate(),
			filters,
			sort,
			offset,
			pageSize
		],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке списка договоров');
		}
	});
	return data;
}

export const useContractsAsArray = () => {
	const contractsData = useContracts();

	return useMemo(() => {
		if (!contractsData) {return [];}

		const {byId, allIds} = contractsData;
		return allIds.map(id => byId[id]);
	}, [contractsData]);
}

export const useContract = (contractId?: string) => {
	const {data} = useQuery<IGetContractResponse, IError>({
		queryKey: contractId ? contractsQueryKeys.detail(contractId) : undefined,
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке договора');
		}
	});
	return data;
}

export const useContractsList = (
	filters?: IContractsFiltersState, sort?: IContractsSortState
) => {
	const {data} = useQuery<
		IGetListContractsResponse, IError
	>({
		queryKey: [
			...contractsQueryKeys.list(),
			filters,
			sort
		],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке списка договоров');
		}
	});
	return data;
}

export const useContractsListAsArray = (
	filters?: IContractsFiltersState, sort?: IContractsSortState
) => {
	const contractsData = useContractsList(filters, sort);

	return useMemo(() => {
		if (!contractsData) return undefined;
		return contractsData.allIds.map(id => contractsData.byId[id]);
	}, [contractsData]);
}