import React from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import {useTasksSchedulePageSettings} from '@src/core/hooks/queries/tasks';
import {TasksScheduleCollectMode} from '@src/store/modules/settings/pages/tasks/interfaces';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeCollectMode} from '@src/store/modules/settings/pages/tasks/actions';

export const TasksScheduleCollectTabs = () => {
	const {collectBy} = useTasksSchedulePageSettings();
	const dispatch = useAppDispatch();
	const setActiveTab = (tab: number) => {
		dispatch(changeCollectMode(tab === 0
			? TasksScheduleCollectMode.OBJECT
			: TasksScheduleCollectMode.GROUP));
	};

	return (
		<div className="manage-task-schedule-page__tabs">
			<Tabs
				className={{
					fakeLink: 'manage-task-schedule-page__tabs-fake-link'
				}}
				activeTab={collectBy === TasksScheduleCollectMode.OBJECT ? 0 : 1}
				links={[
					<TabLink
						key="object"
						label="По объектам"
					/>,
					<TabLink
						key="group"
						label="По рабочим группам"
					/>
				]}
				onActiveTabChange={setActiveTab}
			/>
		</div>
	);
};
