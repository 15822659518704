import React, {useCallback} from 'react';
import {Checkbox} from '../../inputs';
import {UserInfo} from '../../various';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useTreeCtx} from '../DelegationTree/utils/TreeCtx';
import {DelegationSelectOption} from '../DelegationSelectOption';

interface IDelegationTreeUserProps {
	user: IBriefUser;
	groupId: string;
	rules?: string[];
	level: number;
	onlyFirstLevelSelectable?: boolean;
}

export const DelegationTreeUser = ({
	user,
	groupId,
	rules,
	level,
	onlyFirstLevelSelectable
}: IDelegationTreeUserProps) => {
	const {isUserSelected, changeUserSelected} = useTreeCtx();

	const selectable = !onlyFirstLevelSelectable || level === 0;
	const selected = isUserSelected(user.id, groupId);

	const handleSelect = useCallback(() => {
		changeUserSelected(user.id, groupId, !selected);
	}, [selected, user, groupId, changeUserSelected]);

	return (
		<DelegationSelectOption
			rules={rules}
			level={level}
			selectable={selectable}
			selected={selected}
			selectInput={<Checkbox checked={selected}/>}
			infoComponent={(
				<UserInfo
					className="delegation-select-option__info"
					user={user}
					avatarSize="40"
					avatarColoring="text"
				/>
			)}
			onClick={handleSelect}
		/>
	);
};

DelegationTreeUser.displayName = 'DelegationTreeUser';