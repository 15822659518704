import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {
	IAddResponsibilityRuleResponse,
	makeResponsibilityRuleAddRequest
} from '@src/api/backend/responsibilityRules';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';

export type IAddResponsibilityRulePayload = IAddResponsibilityRuleResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (workingGroupId: string, response: IAddResponsibilityRuleResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении правила');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет 1 правило за раз
 *
 * @param groupId id группы
 * @param rule данные для добавления
 */
export const addResponsibilityRule = (groupId: string, rule: ISavingResponsibilityRule) =>
	createApiAction<IAddResponsibilityRuleResponse>(
		request,
		(res: IAddResponsibilityRuleResponse) => success(res.groupId, res),
		failure,
		() => makeResponsibilityRuleAddRequest(groupId, rule)
	);
