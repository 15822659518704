import React, {useCallback} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {IconButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import {exportProblems} from '@src/store/modules/entities/problems/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsData} from '@src/store/modules/entities/problems/selectors';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

const availableDestinations = [ExportTemplatesDestinationId.PROBLEMS];

interface IProblemsExportDialog {
	objectId?: string;
}

const ProblemsExportDialog = ({objectId = 'all'}: IProblemsExportDialog) => {
	const {selectedRows} = useAppSelector(s => extractProblemsData(s, objectId));
	const {filters, sort} = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const [isOpen, toggleOpen] = useToggle(false);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await dispatch(
				exportProblems(
					template.id,
					objectId,
					createDocument,
					email,
					filters,
					sort,
					selectedRows
				)
			);
			toggleOpen(false);
		},
		[filters, sort, selectedRows, objectId]
	);

	const isDesktop = useIsDesktop();
	return (
		<>
			{!isDesktop ? (
				<IconButton onClick={toggleOpen}>
					<i className="tz-export-24" />
				</IconButton>
			) : (
				<IconButton
					type="accent-blue"
					onClick={toggleOpen}
				>
					<i className="tz-export-20" />
				</IconButton>
			)}

			<ExportDialog
				pageKey="problems"
				destinations={availableDestinations}
				isOpen={isOpen}
				onExport={handleExport}
				onClose={toggleOpen}
			/>
		</>
	);
};

export default ProblemsExportDialog;
