import {wsConnector} from '../wsConnector';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetCheckItemsResponse = INormalizedData<ICheckItem>;

/**
 * Возвращает список категорий доступных чек-листов
 */
export const requestCheckItems = () =>
	wsConnector.sendAuthorizedRequest<IGetCheckItemsResponse>('getCheckItems');
