import React, {useCallback, useState} from 'react';
import {ActionButtons, Button, Dialog, TreeSelect} from '@tehzor/ui-components';
import {makeTreeDataStructure} from '@src/components/editableFields/StructureTreeSelect/utils/makeTreeData';
import {extractStructureTypesAsArray} from '@src/store/modules/dictionaries/structureTypes/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface IStructureSelectDialogProps {
	structures: Array<{
		id: string;
		name: string;
		type: string;
	}>;
	isOpen: boolean;
	value?: string;
	onClose: () => void;
	onSuccess: (selectedStructure: string) => void | Promise<void>;
}

export const StructureSelectDialog = (props: IStructureSelectDialogProps) => {
	const match = useStrictParams<{
		objectId: string;
	}>();

	const {isOpen, structures, value, onClose, onSuccess} = props;
	const [selectedObject, setSelectedObject] = useState<string>(value ?? '');
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const structureTypes = useAppSelector(extractStructureTypesAsArray);

	const treeData = makeTreeDataStructure(
		structures.map(el => ({...el, objectId: match.objectId || ''})),
		structureTypes
	);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	const handleSuccess = () => {
		void onSuccess(selectedObject);
	};

	return (
		<Dialog
			onBeforeOpen={() => {
				if (structures.length === 1) {
					void onSuccess(structures[0].id);
					onClose();
				}
			}}
			isOpen={isOpen}
			title="Выберите структуру"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={onClose}
					/>
					<Button
						type="accent-blue"
						label="Выбрать"
						onClick={handleSuccess}
					/>
				</ActionButtons>
			)}
			onRequestClose={onClose}
		>
			<TreeSelect
				data={treeData}
				multiple={false}
				value={selectedObject}
				onChange={setSelectedObject}
				expandedValue={expandedObjects}
				onExpand={handleExpand}
			/>
		</Dialog>
	);
};
