import React, {useCallback, useState} from 'react';
import {DesktopPhotoViewer} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';

/**
 * Хук для базового просмотрщика изображений
 *
 * @param images изображения
 */
export function useViewer(images: string[]): [React.ReactNode, (i: number) => void] {
	// Состояния для активного фото и видимости просмотрщика
	const [isOpen, toggleOpen] = useToggle(false);
	const [activeIndex, setActiveIndex] = useState<number>();

	const openImage = useCallback((index: number) => {
		setActiveIndex(index);
		toggleOpen(true);
	}, []);

	const viewer = (
		<DesktopPhotoViewer
			data={images}
			value={activeIndex}
			isOpen={isOpen}
			onChange={setActiveIndex}
			onClose={toggleOpen}
		/>
	);

	return [viewer, openImage];
}
