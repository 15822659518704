import React, {memo} from 'react';
import classNames from 'classnames';
import './ToggleIcon.less';
import {ISwitchIconProps} from '../../SwitchBase';

export type IToggleIconProps = ISwitchIconProps;

export const ToggleIcon = memo(({className, style, checked, disabled}: IToggleIconProps) => (
	<div
		className={classNames(
			'toggle-icon',
			checked ? 'toggle-icon_checked' : 'toggle-icon_unchecked',
			{'toggle-icon_disabled': disabled},
			className
		)}
		style={style}
	/>
));
