import {IMigrateCategoriesSetData, IMigrateCategoriesSetResponse, requestMigrateCategoriesSet} from '@src/api/backend/categoriesSets/migrate';
import {createApiAction} from '@src/store/middlewares/api';
import {addSuccessToast, addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';

export type IMigrateCategoriesSetPayload = IMigrateCategoriesSetData;

const request = () => ({
	type: types.MIGRATE_REQUEST
});

const success = (id: string) => {
	addSuccessToast('Выполнено', 'Миграция выполнена успешно');
	return {
	type: types.MIGRATE_SUCCESS,
	payload: {id}
};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', error.message);
	return {
	type: types.MIGRATE_FAILURE,
	payload: error
};
};

export const migrateCategoriesSet = (migrateCategoriesSetData: IMigrateCategoriesSetData) =>
	createApiAction<IMigrateCategoriesSetResponse>(
		request,
		req => success(req),
		failure,
		() => requestMigrateCategoriesSet(migrateCategoriesSetData)
	);
