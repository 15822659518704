import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeProblemReplyAddRequest} from '@src/api/backend/problemReply';
import {IAddProblemCommentResponse} from '@src/api/backend/problemComment';
import ISavingProblemComment from '@tehzor/tools/interfaces/problemComments/ISavingProblemComment';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddProblemCommentResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => ({
	type: types.ADD_FAILURE,
	payload: error
});

/**
 * Добавляет ответ на нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const addProblemReply = (
	objectId: string,
	problemId: string,
	links: IProblemComment['links'] | undefined,
	fields: ISavingProblemComment
) =>
	createApiAction<IAddProblemCommentResponse>(request, success, failure, () => {
		void queryClient.invalidateQueries([...problemsQueryKeys.detail(problemId), objectId]);
		void queryClient.invalidateQueries(problemsQueryKeys.list());
		return makeProblemReplyAddRequest(objectId, problemId, links, fields);
	});
