import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceTypeDecorationSet} from '@src/store/modules/entities/spaceTypeDecorationSet/selectors';
import {useMemo} from 'react';

export const useManageSpaceTypeDecorationBreadcrumbs = () => {
	const typeDecoration = useAppSelector(extractSpaceTypeDecorationSet);

	return useMemo(() => {
		const items = [
			{
				label: 'Наборы типов отделки',
				url: '/manage/space-type-decoration',
				nonClickable: !typeDecoration
			}
		];

		if (typeDecoration) {
			items.push({
				label: `Набор "${typeDecoration.name}"`,
				url: `/manage/space-type-decoration/${typeDecoration.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [typeDecoration]);
};
