import {Container} from '@svgdotjs/svg.js';

/**
 * Трансформирум координаты с учетом масштаба
 *
 * @param svg SVG.js объект
 * @param positionX координаты по X
 * @param positionY координаты по Y
 * @return {SVGPoint}
 */
export default function transformSvgCoordinates(svg: Container, positionX: number, positionY: number): SVGPoint {
	// @ts-ignore
	const matrix = svg.node.getScreenCTM().inverse();
	// @ts-ignore
	let transform = svg.node.createSVGPoint();
	transform.x = positionX;
	transform.y = positionY;
	transform = transform.matrixTransform(matrix);
	return transform;
}
