import React, {useCallback} from 'react';
import {AuthForm, ErrorBoundary, Logo} from '@tehzor/ui-components';
import {login} from '@src/store/modules/auth/actions/login';
import './LoginPage.less';
import {CSSTransition} from 'react-transition-group';
import useMount from 'react-use/lib/useMount';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useLocation} from 'react-use';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {Location} from 'react-router-dom';

/**
 * Компонент формы ввода логина и пароля
 */
export const LoginPage = () => {
	const location = useLocation();
	const {pushPath} = useChangePath();
	const locationState = location.state as {usr: {redirect: string} | null};
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const dispatch = useAppDispatch();
	useMount(() => {
		if (isAuthenticated) {
			pushPath('/');
		}
	});

	const authenticateUser = useCallback(
		async (email: string, password: string) => {
			await dispatch(login(email, password));

			let redirect = '/';
			if (location.state && locationState.usr?.redirect) {
				const stateRedirect = locationState.usr.redirect;
				delete (locationState as Partial<Location>).key;
				if (stateRedirect !== '/login' && stateRedirect !== '/logout') {
					redirect = stateRedirect;
				}
			}
			pushPath(redirect);
		},
		[location]
	);

	return (
		<CSSTransition
			classNames="login-page"
			in
			appear
			timeout={300}
		>
			<div className="login-page">
				<Logo className="login-page__logo"/>
				<ErrorBoundary
					label="LoginPage"
					className="login-page error"
				>
					<div className="login-page__container">
						<AuthForm
							className="login-page__container-auth-form"
							onLogin={authenticateUser}
						/>
						<div className="login-page__container-background">
							<div className="login-page__container-background__left"/>
							<div className="login-page__container-background__right"/>
						</div>
					</div>
				</ErrorBoundary>
			</div>
		</CSSTransition>
	);
};
