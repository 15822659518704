import {isDisabled} from '../../BaseStatusSelect/utils/isDisabled';
import {getMenuItem} from './getMenuItem';
import {IStatus} from './IStatus';

interface IGetMenuItems <S extends IStatus> {
	selectedStatus?: S;
	statuses?: S[];
	availableStatuses?: string[];
}

export const getMenuItems = <S extends IStatus> (
	{selectedStatus, statuses, availableStatuses}: IGetMenuItems<S>
) => {
	if (!statuses) {
		return;
	}

	let disabledCount = 0;
	const menuItems = statuses.map(item => {
		const disabled = isDisabled(item, selectedStatus, availableStatuses);
		if (disabled) {
			disabledCount++;
		}

		return (
			getMenuItem(item, selectedStatus, disabled)
		);
	});

	if (menuItems.length === disabledCount) {
		return;
	}
	return menuItems;
};