import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractWarrantyClaimsStatusesAsArray} from '@src/store/modules/dictionaries/warrantyClaimStatuses/selectors';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

interface IWClaimStatusesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const WClaimStatusesFilterMobile = memo((props: IWClaimStatusesFilterMobileProps) => {
	const statuses = useAppSelector(extractWarrantyClaimsStatusesAsArray);

	return (
		<BaseListFilterMobile
			{...props}
			options={statuses}
			label="Статусы"
			filterName="statuses"
		/>
	);
});
