import {PrivateRoute} from '@src/containers/PrivateRoute';
import CategoriesSetPage from '@src/pages/CategoriesSetPage/CategoriesSetPage';
import {AllCategoriesSetsPage} from '@src/pages/CategoriesSetsPage';
import CategoriesSetsFiltersRoutingPage from '@src/pages/filters/CategoriesSetsFiltersRoutingPage/CategoriesSetsFiltersRoutingPage';
import {CategoriesSetMigrationPage} from '@src/pages/manage/categoriesSets/CategoriesSetMigrationPage';
import React from 'react';
import {categoriesSetsFilterRoutes} from './categoriesSetsFilterRoutes';

export const manageCategoriesSetsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllCategoriesSetsPage/>}/>
	},
	{
		path: ':categoriesSetId',
		element: <PrivateRoute element={<CategoriesSetPage/>}/>
	},
	{
		path: 'migration',
		element: <PrivateRoute element={<CategoriesSetMigrationPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<CategoriesSetsFiltersRoutingPage/>}/>,
		children: categoriesSetsFilterRoutes
	}
];
