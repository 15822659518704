import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObjectsAsTree} from '@src/store/modules/dictionaries/objects/selectors';
import {convertToStatsObject} from '../utils/convertToStatsObject';
import {statsObjectsSortFn} from '../utils/statsObjectsSortFn';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';
import {filterObjects} from '../utils/filterObjects';

export function useFilteredObjects<T>(
	statsByObjects: {[id: string]: T},
	filters: {
		companies?: string[];
		objects?: string[];
	},
	isFiltersSetUp: boolean
) {
	const objects = useAppSelector(extractObjectsAsTree);
	const loading = useAppSelector(extractSourcesLoading);

	return useMemo(() => {
		const source = objects.map(convertToStatsObject).sort(statsObjectsSortFn);

		return isFiltersSetUp ? filterObjects(source, statsByObjects, filters) : source;
	}, [objects, statsByObjects, isFiltersSetUp, filters, loading]);
}
