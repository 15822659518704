import {
	testInternalAcceptancePermission,
	testInternalAcceptanceRestrictions,
	testInternalAcceptanceRestrictionsList
} from '@src/utils/testRestrictions';
import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';

export interface IPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canMove?: boolean;
	canMoveRestrictions?: {[key: string]: boolean};
}

/**
 * Возвращает полномочия для внутренней приёмки
 *
 * @param objectId id объекта
 * @param internalAcceptance внутренняя приёмка
 * @param problems нарушения
 */
export const useInternalAcceptancePermissions = (
	objectId: string,
	internalAcceptance?: IInternalAcceptance,
	problems?: IProblem[]
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canEdit: testInternalAcceptanceRestrictions(
				user.id,
				user.roles,
				'internalAcceptancesEdit',
				objectId,
				internalAcceptance?.createdBy
			),
			canDelete: testInternalAcceptanceRestrictions(
				user.id,
				user.roles,
				'internalAcceptancesDelete',
				objectId,
				internalAcceptance?.createdBy
			),
			canExport: testInternalAcceptanceRestrictions(
				user.id,
				user.roles,
				'internalAcceptancesExport',
				objectId,
				internalAcceptance?.createdBy,
				problems
			),
			canMove: testInternalAcceptancePermission(
				user.roles,
				'internalAcceptanceProblemsMove',
				objectId
			),
			canMoveRestrictions: testInternalAcceptanceRestrictionsList(
				user.roles,
				'internalAcceptanceProblemsMove',
				objectId
			)
		};
	}, [objectId, internalAcceptance, user]);
};
