import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export const getTitle = (
	ids: string[],
	companiesMap: Record<string, ICompany>,
	objectsMap: Record<string, IObject>
) => {
	const companiesTitle = ids
		.reduce((acc: string[], id) => {
			if (companiesMap[id]?.name) {
				acc.push(companiesMap[id]?.name);
			}
			return acc;
		}, [])
		.join(', ');
	const objectsTitle = ids
		.reduce((acc: string[], id) => {
			const object = objectsMap[id];
			if (
				object?.name
				&& !ids.find(id => object.parentId === id || object.companyId === id)
			) {
				acc.push(objectsMap[id]?.name);
			}
			return acc;
		}, [])
		.join(', ');

	const titlesArr: string[] = [companiesTitle, objectsTitle];
	return titlesArr.filter(item => !!item).join('; ');
};
