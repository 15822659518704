import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {extractTargetObjects} from '@src/store/modules/dictionaries/objects/selectors';

/**
 * Возвращает id объектов, которые должны отображаться на шахматке,
 * исходя из фильтра по объектам и наличия в них помещений выбранного типа
 *
 * @param pageObjectId id объекта, на странице которого находится пользователь
 */
export function useVisibleObjects(pageObjectId: string) {
	const targetObjects = useAppSelector(s => extractTargetObjects(s, pageObjectId));
	const {filters, type} = useAppSelector(s => extractSpacesPageSettings(s, pageObjectId));
	const schemasPages = useAppSelector(s => s.entities.spaces.schemas);

	return useMemo(() => {
		const visibleObjects = filters.objects?.length
			? targetObjects.filter(id => filters.objects?.includes(id))
			: targetObjects;

		return visibleObjects.filter(objId =>
			schemasPages[objId]?.allIds?.some(
				spaceId => schemasPages[objId].byId[spaceId]?.type === type
			));
	}, [filters.objects, type, schemasPages, targetObjects]);
}
