import {formCheckListLink} from './formCheckListLink';

interface Args {
	objectId: string;
	stage: string;
	listId: string;
	itemId: string;
	spaceId?: string;
}

/**
 * Формирует ссылку на категорию чек-листа
 *
 * @param itemId id категории
 * @param params
 */
export const formCheckItemLink = ({itemId, ...params}: Args) => `${formCheckListLink(params)}/items/${itemId}`;
