import React from 'react';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {DesktopCreateCheck} from './CreateCheck.desktop';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';

interface IDesktopActionsProps {
	task?: IEnrichedTask;
}

export const ActionsDesktop = ({task}: IDesktopActionsProps) => {
	const permissions = useChecksPermissions(task?.objectId?.id);
	const problemsPermissions = useProblemsAddingPermissions(task?.objectId?.id);
	const inspectionsPermissions = useInspectionsAddingPermissions(task?.objectId?.id);

	if (task === undefined) return null;

	return (
		<>
			{(task.taskType?.id === TaskTypeId.AUDIT || task.taskType?.id === TaskTypeId.CHECK)
				&& permissions.canAdd
				&& (problemsPermissions.canAdd || inspectionsPermissions.canAdd)
				&& (
					<DesktopCreateCheck task={task}/>
				)}
		</>
	);
};
