import React, {forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState} from 'react';
import './ConfirmDialog.less';
import Button, {IButtonProps} from '../../buttons/Button';
import classNames from 'classnames';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import DialogBase from '../DialogBase';
import {convertClassNames} from '../../../utils/convertClassNames';
import {animations} from './utils/animations';

export interface IConfirmDialogProps {
	className?:
		| string
		| {
				root?: string;
				title?: string;
				message?: string;
				actions?: string;
		  };
	style?: React.CSSProperties;
	title?: string;
	message?: React.ReactNode;
	isOpen?: boolean;
	acceptBtnLabel?: string;
	rejectBtnLabel?: string;
	acceptBtnProps?: IButtonProps;
	rejectBtnProps?: IButtonProps;
	shouldCloseOnOverlayClick?: boolean;
	shouldCloseOnEsc?: boolean;
	shouldDisableScroll?: boolean;
	appRoot?: HTMLElement;

	onAccept?: () => void;
	onReject?: () => void;
	onRequestClose?: () => void;
}

export interface IConfirmDialogRefProps {
	open: () => Promise<boolean>;
}

const ConfirmDialog = (props: IConfirmDialogProps, ref?: Ref<IConfirmDialogRefProps>) => {
	const {
		className,
		style,
		title,
		message,
		isOpen: propsIsOpen,
		acceptBtnLabel,
		rejectBtnLabel,
		acceptBtnProps,
		rejectBtnProps,
		shouldCloseOnOverlayClick,
		shouldCloseOnEsc,
		shouldDisableScroll,
		appRoot,
		onAccept,
		onReject,
		onRequestClose
	} = props;

	const classes = convertClassNames(className);

	const [isOpen, setOpen] = useState(propsIsOpen || false);
	const resolveRef = useRef<(r: boolean) => void | undefined>();

	useUpdateEffect(() => {
		setOpen(propsIsOpen || false);
	}, [propsIsOpen]);

	const close = () => {
		if (onRequestClose) {
			onRequestClose();
		} else {
			setOpen(false);
		}
	};

	useImperativeHandle(
		ref,
		() => ({
			open: () =>
				new Promise<boolean>(resolve => {
					setOpen(true);
					resolveRef.current = resolve;
				})
		}),
		[]
	);

	const accept = useCallback(() => {
		if (onAccept) {
			onAccept();
		} else {
			close();
			if (resolveRef.current) {
				resolveRef.current(true);
			}
		}
	}, [onAccept]);

	const reject = useCallback(() => {
		if (onReject) {
			onReject();
		} else {
			close();
			if (resolveRef.current) {
				resolveRef.current(false);
			}
		}
	}, [onReject]);

	return (
		<DialogBase
			className={{
				dialog: classNames('confirm-dialog', classes.root),
				layer: 'confirm-dialog__layer',
				content: 'confirm-dialog__content'
			}}
			style={style}
			isOpen={isOpen}
			appRoot={appRoot}
			animations={animations}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
			shouldCloseOnEsc={shouldCloseOnEsc}
			shouldDisableScroll={shouldDisableScroll}
			onRequestClose={reject}
		>
			{title !== undefined && (
				<div className={classNames('confirm-dialog__title', classes.title)}>{title}</div>
			)}

			{message && <div className={classNames('confirm-dialog__message', classes.message)}>{message}</div>}

			<div className={classNames('confirm-dialog__actions', classes.actions)}>
				<Button
					label={rejectBtnLabel}
					outerTagType="button"
					{...rejectBtnProps}
					className={classNames('confirm-dialog__button', rejectBtnProps?.className)}
					onClick={reject}
				/>
				<Button
					label={acceptBtnLabel}
					outerTagType="button"
					{...acceptBtnProps}
					className={classNames('confirm-dialog__button', acceptBtnProps?.className)}
					onClick={accept}
				/>
			</div>
		</DialogBase>
	);
};

ConfirmDialog.displayName = 'ConfirmDialog';

export default forwardRef(ConfirmDialog);
