import React from 'react';
import classNames from 'classnames';

export interface ITabContentProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

const TabContent = ({className, style, children}: ITabContentProps) => (
	<div
		className={classNames('tab-content', className)}
		style={style}
	>
		{children}
	</div>
);

TabContent.displayName = 'TabContent';

export default TabContent;
