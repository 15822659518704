import React from 'react';
import {IconButton} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useAddingOwnerAcptDialog} from '@src/components/AddingOwnerAcptDialog/hooks/useAddingOwnerAcptDialog';
import {getOwnerAcceptances} from '@src/store/modules/entities/ownerAcceptances/actions/get';

interface IMobileEntityAddingProps {
	objectId: string;
}

export const MobileEntityAdding = ({objectId}: IMobileEntityAddingProps) => {
	const dispatch = useAppDispatch();

	const [, reloadList] = useAsyncFn(async () => {
		await dispatch(getOwnerAcceptances(objectId || 'all'));
	}, [objectId]);

	const [dialog, open] = useAddingOwnerAcptDialog({
		objectId,
		onSuccess: reloadList
	});

	return (
		<>
			<IconButton onClick={open}>
				<i className="tz-plus-24"/>
			</IconButton>
			{dialog}
		</>
	);
};
