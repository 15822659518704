import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useEditableStructureDialog} from '@src/components/EditableStructureDialog/hooks/useEditableStructureDialog';

interface IDesktopStructureAddBtnProps {
	objectId: string;
}

export const DesktopStructureAddBtn = ({objectId}: IDesktopStructureAddBtnProps) => {
	const [addingDialog, openAddingDialog] = useEditableStructureDialog(objectId);

	const handleAdding = useCallback(() => {
		openAddingDialog();
	}, [openAddingDialog]);

	return (
		<>
			<IconButton
				type="accent-blue"
				onClick={handleAdding}
			>
				<i className="tz-plus-20"/>
			</IconButton>
			{addingDialog}
		</>
	);
};
