import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {queryClient} from '@src/api/QueryClient';
import {deleteMutation} from '@src/cache/utils/deleteMutation';
import {deleteLinkedLocalProblems} from '../problem/deleteLinkedLocalProblems';
import {deleteLinkedLocalInspections} from '../inspection/deleteLinkedLocalInspections';
import {store} from '@src/store/appStore';
import {deleteCheckActions} from '@src/store/modules/entities/check/actions';

export const deleteLocalCheck = async (checkId: string, objectId?: string) => {
	queryClient.removeQueries(checksQueryKeys.detail(checkId));
	await queryClient.refetchQueries({
		queryKey: checksQueryKeys.localList()
	});
	if (objectId) {
		store.dispatch(deleteCheckActions.success(objectId, checkId, true));
	}
	await deleteLinkedLocalProblems('checkId', checkId);
	await deleteLinkedLocalInspections('checkId', checkId);
	deleteMutation(checkId);
};
