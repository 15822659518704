import {IResponsibilityRule} from '../../interfaces/responsibilityRules/IResponsibilityRule';
import {IProblemParams} from './IProblemParams';

const isEmptyRuleParam = (array: unknown[] | undefined) => !array?.length;

export const isMatch = (rule: IResponsibilityRule, {planId, categoryId, structureIds}: IProblemParams) => (
	// Сравнение Видов работ с правилами
	isEmptyRuleParam(rule.categories) || (categoryId ? rule.categories?.includes(categoryId) : false))
	// Если структуры не заданы, значит сравниваем планы
	&& ((structureIds === undefined
	&& (isEmptyRuleParam(rule.plans) || (planId ? rule.plans?.includes(planId) : false)))
	// Если планы не заданы, значит сравниваем структуры. 
	// Каждая структура из фронта работ должна быть в правилах
	|| (planId === undefined
	&& (isEmptyRuleParam(rule.structureIds) || (structureIds ? structureIds.every(structureId =>
		rule.structureIds?.includes(structureId)) : false))));
