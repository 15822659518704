import React, {useCallback} from 'react';
import {CloudButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleOfflineDialogVisibility} from '@src/store/modules/app/offlineDialog/actions';

const isWebPushSupported = 'serviceWorker' in navigator && 'PushManager' in window;

interface ICloudIndicatorProps {
	className?: string;
}

export const OfflineModeButton = ({className}: ICloudIndicatorProps) => {
	const {cacheStatus, networkStatus} = useAppSelector(s => s.offlineMode);
	const dispatch = useAppDispatch();

	const openDialog = useCallback(() => {
		dispatch(toggleOfflineDialogVisibility(true));
	}, []);

	return (
		<CloudButton
			className={className}
			cachingStatus={cacheStatus}
			online={networkStatus}
			notSupported={!isWebPushSupported}
			onClick={openDialog}
		/>
	);
};
