import {useEditableSpaceDialog} from '@src/components/EditableSpaceDialog/hooks/useEditableSpaceDialog';
import React, {memo} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';

interface IDesktopMenuProps {
	objectId: string;
	canAddSpace?: boolean;
}

const addIcon = <i className="tz-plus-24"/>;
export const DesktopMenu = memo((props: IDesktopMenuProps) => {
	const {objectId, canAddSpace} = props;
	// Диалог добавления помещения
	const [addDialog, openAddDialog] = useEditableSpaceDialog(objectId);
	// Диалог генерации помещений

	const items = [];
	if (canAddSpace) {
		items.push(
			<MenuItem
				key="add"
				icon={addIcon}
				onClick={openAddDialog}
			>
				Добавить помещение
			</MenuItem>
		);
	}
	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canAddSpace && addDialog}
		</>
	) : null;
});
