import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddCheckResponse, makeCheckAddRequest} from '@src/api/backend/check';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {addErrorToast} from '@src/utils/toasts';

export type IAddCheckPayload = IAddCheckResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddCheckResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении проверки');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

export const addCheckActions = {request, success, failure};

/**
 * Добавляет проверку
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 */
export const addCheck = (
	objectId: string,
	links: ICheck['links'] | undefined,
	stage?: ObjectStageIds
) =>
	createApiAction<IAddCheckResponse>(request, success, failure, () =>
		makeCheckAddRequest(objectId, links, stage));
