import {ReactNode} from 'react';

export enum ObjectStageIds {
	BUILDING = 'building',
	ACCEPTANCE = 'acceptance',
	TRANSFER = 'transfer',
	WARRANTY = 'warranty'
}

export default interface IObjectStage {
	id: ObjectStageIds;
	name: string;
	iconName: string;
	color: string;
}

export interface IObjectStageWithIcon extends Omit<IObjectStage, 'iconName'> {
	icon: ReactNode;
}