import React from 'react';
import {useToggle} from 'react-use';
import {PopupPlate, IconButton} from '@tehzor/ui-components';
import {LegendContent} from './LegendContent';

export const LegendPopup = () => {
	const [isOpen, toggle] = useToggle(false);

	return (
		<PopupPlate
			className={{
				root: 'spaces-page__legend-popup',
				content: 'spaces-page__legend-popup-content'
			}}
			trigger={({ref}) => (
				<IconButton
					ref={ref}
					type="transparent"
					active={isOpen}
					onClick={toggle}
				>
					<i className="tz-info-24"/>
				</IconButton>
			)}
			arrowVisible
			isOpen={isOpen}
			placement="bottom-end"
			onOutsideClick={toggle}
			onDisappear={toggle}
		>
			<LegendContent/>
		</PopupPlate>
	);
};
