import React, {useCallback, useEffect, useRef, useState} from 'react';
import './AddingWarrantyClaimDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IAddingWarrantyClaimRefProps} from './components/AddingWarrantyClaim';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {useClaimMaps} from './hooks/useClaimMaps';
import {saveClaim} from './utils/saveClaim';
import {getUserWarrantyClaim} from '@src/store/modules/dictionaries/latestUserWarrantyClaims/actions/getOne';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useLocalWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/useGetWarrantyClaims';

export type AddingWarrantyClaimView = 'claim' | 'problem' | undefined;

interface IAddingWarrantyClaimDialogProps {
	objectId: string;
	spaceId?: string;
	claimId?: string;
	links?: IWarrantyClaim['links'];
	defaultProblemData?: ISavingProblem;
	isOpen: boolean;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const AddingWarrantyClaimDialog = (props: IAddingWarrantyClaimDialogProps) => {
	const {objectId, spaceId, claimId, links, defaultProblemData, isOpen, onClose, onSuccess}
		= props;

	const [selectedWarrantyClaimId, setSelectedWarrantyClaimId] = useState<string | undefined>(
		claimId
	);
	const dispatch = useAppDispatch();
	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingWarrantyClaimRefProps>(null);

	const [view, setView] = useState<AddingWarrantyClaimView>();
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const localWarrantyClaims = useLocalWarrantyClaims();
	const localWarrantyClaimsIds = localWarrantyClaims.map(claim => claim.id);

	useEffect(() => {
		setView(!selectedWarrantyClaimId ? 'claim' : 'problem');
	}, [selectedWarrantyClaimId]);

	const stage = ObjectStageIds.WARRANTY;
	const stages = useAppSelector(extractObjectStagesAsArray);
	const [contentMap, entitiesSelectMapProps] = useClaimMaps({
		objectId,
		claimId,
		spaceId,
		stage,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedWarrantyClaimId,
		setSelectedWarrantyClaimId,
		defaultProblemData,
		onClose
	});

	const [, handleSave] = useAsyncFn(async () => {
		const updateLatest = async () => {
			if (selectedWarrantyClaimId) {
				await dispatch(getUserWarrantyClaim(objectId, selectedWarrantyClaimId));
			}
		};
		await saveClaim({
			addingEntityRef,
			localWarrantyClaimsIds,
			view,
			online,
			updateLatest,
			selectedWarrantyClaimId,
			success,
			onClose
		});
	}, [
		addingEntityRef,
		selectedWarrantyClaimId,
		localWarrantyClaimsIds,
		view,
		online,
		success,
		onClose
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<Dialog
			className={{
				root: 'adding-warranty-claim-dialog',
				header: 'adding-warranty-claim-dialog__header',
				body: 'adding-warranty-claim-dialog__body'
			}}
			isOpen={isOpen}
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			<EntitySelectContainer
				contentMap={contentMap}
				entitiesSelectMapProps={entitiesSelectMapProps}
				selectedStage={stage}
				stages={stages}
			/>
		</Dialog>
	);
};

export default AddingWarrantyClaimDialog;
