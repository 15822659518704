import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';

export type IGetDelegationHistoriesByWorkAcceptanceResponse = INormalizedData<IDelegationHistory>;

/**
 * Получает записи из истории делегирования приемки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id нарушения
 */
export const requestDelegationHistoriesByWorkAcceptance = (objectId: string, workAcceptanceId: string) =>
	wsConnector.sendAuthorizedRequest<IGetDelegationHistoriesByWorkAcceptanceResponse>(
		'getDelegationHistoriesByWorkAcceptance',
		{objectId, workAcceptanceId}
	);
