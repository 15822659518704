import React from 'react';
import './CloudButton.less';
import classNames from 'classnames';
import IconButton from '../IconButton';
import {CacheStatus} from '@tehzor/tools/enums/CacheStatus';
import {getIconClass} from './utils/getIconClass';

interface INotificationsButtonProps {
	className?: string;
	style?: React.CSSProperties;
	cachingStatus?: CacheStatus;
	online?: boolean;
	syncActive?: boolean;
	notSupported?: boolean;

	onClick?(): void;
}

const CloudButton = ({
	className,
	style,
	cachingStatus,
	online,
	syncActive,
	notSupported,
	onClick
}: INotificationsButtonProps) => (
	<IconButton
		className={classNames(
			'cloud-button',
			online ? 'cloud-button_online' : 'cloud-button_offline',
			{'cloud-button_not-supported': notSupported},
			className
		)}
		style={style}
		type="transparent"
		onClick={onClick}
	>
		<i className={getIconClass(cachingStatus, syncActive, notSupported)}/>
	</IconButton>
);

export default CloudButton;
