import React from 'react';
import './DesktopHeaderMenu.less';
import classNames from 'classnames';
import HeaderMenuItem from './components/DesktopHeaderMenuItem';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';
import IconMenu from '../IconMenu';
import MenuItem from '../MenuItem';
import Menu from '../Menu';

export interface IDesktopHeaderMenuItem {
	url: string;
	label: string;
	hidden?: boolean;
	serveSubPath?: boolean;
}

interface IDesktopHeaderMenuProps {
	className?: string;
	style?: React.CSSProperties;
	items?: IDesktopHeaderMenuItem[];
	value?: string;
	itemDeterminationClass: string;

	onChange?(value: string): void;
}

interface IDesktopHeaderMenuState {
	visibleCount: number;
	items?: IDesktopHeaderMenuItem[];
	potentiallyVisibleItems: IDesktopHeaderMenuItem[];
	alwaysHiddenItems: IDesktopHeaderMenuItem[];
}

class DesktopHeaderMenu extends React.PureComponent<IDesktopHeaderMenuProps, IDesktopHeaderMenuState> {
	static displayName = 'DesktopHeaderMenu';

	static defaultProps = {
		itemDeterminationClass: 'd-header-menu-item__label'
	};

	private ref?: HTMLDivElement;

	private removeResizeEventListener?: () => void;

	private handleResize = debounce(() => {
		this.determineVisibleItems();
	}, 200);

	constructor(props: IDesktopHeaderMenuProps) {
		super(props);

		this.state = {visibleCount: 0, potentiallyVisibleItems: [], alwaysHiddenItems: []};
	}

	static getDerivedStateFromProps(nextProps: IDesktopHeaderMenuProps, prevState: IDesktopHeaderMenuState) {
		if (nextProps.items !== prevState.items) {
			let visible: IDesktopHeaderMenuItem[] = [];
			let hidden: IDesktopHeaderMenuItem[] = [];
			if (nextProps.items) {
				for (const item of nextProps.items) {
					(item.hidden ? hidden : visible).push(item);
				}
			}
			if (!visible.length) {
				visible = hidden;
				hidden = [];
			}
			return {items: nextProps.items, potentiallyVisibleItems: visible, alwaysHiddenItems: hidden};
		}
		return null;
	}

	componentDidUpdate(prevProps: IDesktopHeaderMenuProps) {
		if (prevProps.items !== this.props.items) {
			this.determineVisibleItems();
		}
	}

	componentWillUnmount() {
		this.removeResizeListener();
	}

	render() {
		const {className, style, value, onChange} = this.props;
		const {visibleCount, potentiallyVisibleItems} = this.state;

		return (
			<div
				className={classNames('d-header-menu', className)}
				style={style}
				ref={this.saveRef}
			>
				<div className="d-header-menu__items">
					{potentiallyVisibleItems.slice(0, visibleCount).map(item => (
						<HeaderMenuItem
							key={item.url}
							className="d-header-menu__item"
							url={item.url}
							label={item.label}
							active={item.serveSubPath ? value?.startsWith(item.url) : item.url === value}
							onClick={onChange}
						/>
					))}
				</div>

				{this.createMenu()}
			</div>
		);
	}

	/**
	 * Создаёт меню из невлезающих элементов
	 */
	private createMenu = () => {
		const {value, onChange} = this.props;
		const {visibleCount, potentiallyVisibleItems, alwaysHiddenItems} = this.state;
		let result: JSX.Element[] = [];

		if (visibleCount < potentiallyVisibleItems.length) {
			result = potentiallyVisibleItems.slice(visibleCount).map(item => (
				<MenuItem
					key={item.url}
					itemKey={item.url}
				>
					{item.label}
				</MenuItem>
			));
		}
		if (alwaysHiddenItems.length > 0) {
			result = result.concat(
				alwaysHiddenItems.map(item => (
					<MenuItem
						key={item.url}
						itemKey={item.url}
					>
						{item.label}
					</MenuItem>
				))
			);
		}
		if (result.length) {
			return (
				<IconMenu
					className="d-header-menu__more-btn"
					icon={<i className="tz-more-24"/>}
				>
					<Menu
						value={value}
						onChange={onChange}
					>
						{result}
					</Menu>
				</IconMenu>
			);
		}
		return null;
	};

	/**
	 * Определяет количество элементов, вмещающихся на экран
	 */
	private determineVisibleItems = () => {
		if (this.ref) {
			const {potentiallyVisibleItems, alwaysHiddenItems} = this.state;
			const visibleCount = potentiallyVisibleItems.length;
			const hiddenCount = alwaysHiddenItems.length;

			const maxWidth = this.ref.clientWidth;
			let currentWidth = 0;
			let currentCount = 0;

			for (const item of potentiallyVisibleItems) {
				const itemWidth = this.determineElementWidth(item.label);
				if (
					currentWidth + itemWidth + currentCount * 48
					> maxWidth - (currentCount === visibleCount - 1 && hiddenCount === 0 ? 0 : 88)
				) {
					break;
				}
				currentWidth += itemWidth;
				currentCount++;
			}
			this.setState({visibleCount: currentCount});
		}
	};

	/**
	 * Определяет ширину элемента пункта меню
	 *
	 * @param content название пункта меню
	 */
	private determineElementWidth = (content: string) => {
		const element = document.createElement('div');
		element.classList.add(this.props.itemDeterminationClass);
		element.style.position = 'fixed';
		element.style.right = '100%';
		element.style.bottom = '100%';
		element.style.visibility = 'hidden';
		element.textContent = content;
		document.body.appendChild(element);
		const width = element.getBoundingClientRect().width;
		element.remove();
		return width;
	};

	private saveRef = (node: HTMLDivElement) => {
		this.ref = node;
		this.removeResizeListener();
		if (this.ref) {
			this.addResizeListener(this.ref);
		}
		this.determineVisibleItems();
	};

	private addResizeListener = (element: HTMLElement) => {
		const observer = new ResizeObserver(this.handleResize);
		observer.observe(element);
		this.removeResizeEventListener = () => observer.disconnect();
	};

	private removeResizeListener = () => {
		if (this.removeResizeEventListener) {
			this.removeResizeEventListener();
			this.removeResizeEventListener = undefined;
		}
	};
}

export default DesktopHeaderMenu;
