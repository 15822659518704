import React from 'react';
import {MenuItem, IconMenu} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteCategoriesSet} from '@src/store/modules/dictionaries/categoriesSets/actions/delete';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {useAsyncFn} from 'react-use';
import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';

interface ICategoriesSetMenuProps {
	set: ICategoriesSet;
}

const deleteIcon = <i className="tz-delete"/>;

const CategoriesSetMenu = ({set}: ICategoriesSetMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить набор',
		`Вы действительно хотите удалить набор ${set.name}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const {canDelete} = useCategoriesSetPermissions();

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteCategoriesSet(set.id));
			goBack();
		}
	}, [set.id]);

	const items = [];

	if (canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{deleteDialog}
		</>
	) : null;
};

export default CategoriesSetMenu;
