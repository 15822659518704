import React, {memo, useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import SelectSearch from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

interface IAction {
	id: string;
	name: string;
}

interface IActionsFilterMobileProps {
	value?: string[];
	onChange: (selectedIdArray: IChanges) => void;
	filteredElements: IAction[];
}

export const ActionsFilterMobile = memo(
	({value, onChange, filteredElements}: IActionsFilterMobileProps) => {
		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleChange = useCallback(
			(selectedIdArray: string[] | undefined) => {
				onChange({actions: selectedIdArray});
			},
			[onChange]
		);

		return (
			<MobileFilter
				label="Действия"
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
					/>
				)}
			/>
		);
	}
);
