import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useTasksCountByStatus} from '@src/pages/CheckListPage/hooks/useTasksCountByStatus';
import {TaskIndicator} from '@tehzor/ui-components';

interface TaskIndicatorsProps {
	checkListId: string;
	checkItemId?: string;
}

export const TaskIndicators = ({checkListId, checkItemId}: TaskIndicatorsProps) => {
	const taskStatuses = useAppSelector(s => s.dictionaries.taskStatuses);
	const taskCounts = useTasksCountByStatus(checkListId, checkItemId);
	return (
		<div className="check-list-page__indicators">
			{taskCounts.map(({status, count}) =>
				(count > 0 ? (
					<TaskIndicator
						key={status}
						statuses={taskStatuses}
						status={status}
						count={count}
					/>
				) : null))}
		</div>
	);
};
