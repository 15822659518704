import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useAsyncFn} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {deleteOwnerAcceptance} from '@src/store/modules/entities/ownerAcceptance/actions';

interface IMobileMenuProps {
	objectId: string;
	ownerAcceptance: ILinkedOwnerAcceptance;
	canDeleteOwnerAcceptance?: boolean;
}

const MobileMenu = ({objectId, ownerAcceptance, canDeleteOwnerAcceptance}: IMobileMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить заявление',
		`Вы действительно хотите удалить заявление №${ownerAcceptance.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteOwnerAcceptance(ownerAcceptance.objectId, ownerAcceptance.id));
			goBack();
		}
	}, [ownerAcceptance.objectId, ownerAcceptance.id]);

	const items = [];

	if (canDeleteOwnerAcceptance) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{canDeleteOwnerAcceptance && deleteDialog}
		</>
	) : null;
};

export default MobileMenu;
