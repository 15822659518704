import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {ExpandablePanel, LinkButton, LoadingPanel, Plate} from '@tehzor/ui-components';
import classNames from 'classnames';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckListLink} from '@tehzor/tools/utils/links';
import {RecordStatusSelect} from '@src/pages/CheckListPage/components/RecordStatusSelect';
import {useToggle, useUpdateEffect} from 'react-use';
import {isElementOutViewport} from '../utils/isElementOutViewport';
import {CheckListItems} from './list';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {DiagramIcon} from './DiagramIcon/DiagramIcon';
import {extractCheckRecordsIsChanging} from '@src/store/modules/pages/checkLists/selectors/records';
import {CheckListHeaderActions} from './actions/CheckListHeaderActions';
import {useEntityAddingPermission} from '../hooks/useEntityAddingPermission';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {TaskIndicators} from '@src/pages/CheckListPage/components/TaskIndicators';
import declination from '@tehzor/tools/utils/declination';
import {extractCheckItemsAsTree} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';

interface ICheckListPlateProps {
	checkList: ICheckList;
	defaultOpen?: boolean;
}

export const CheckListPlate = memo(({checkList, defaultOpen}: ICheckListPlateProps) => {
	const {replacePath} = useChangePath();
	const params = useStrictParams<{
		spaceId?: string;
		objectId: string;
		stage: string;
		listId: string;
	}>();
	const {objectId, listId} = params;
	const items = useAppSelector(s => extractCheckItemsAsTree(s, checkList.id));

	const canAddEntity = useEntityAddingPermission(objectId, checkList.id);
	const {canEdit} = useCheckListPermissions();
	const [changingState, setChangingState] = useState<boolean>(false);
	const [isOpen, toggle] = useToggle(checkList.id === listId || !!defaultOpen);

	const listsLoading = useAppSelector(s => s.dictionaries.checkLists.loading);
	const isChanging = useAppSelector(extractCheckRecordsIsChanging);

	const {changeCheckListRecord} = useChangeCheckRecord(objectId, checkList);
	const handleOpen = useCallback(() => {
		replacePath(formCheckListLink({...params, listId: checkList.id}));
	}, [checkList.id, params]);
	useUpdateEffect(() => {
		if (!isChanging) {
			setChangingState(false);
		}
	}, [isChanging]);

	useEffect(() => {
		if (isOpen) {
			const el = document.getElementById(checkList.id);
			if (el && isElementOutViewport(el)) {
				el.scrollIntoView();
			}
		}
	}, [isOpen, checkList]);

	return (
		<Plate
			id={checkList.id}
			className={{
				root: classNames('check-list-page__d-plate', {
					'check-list-page__d-plate_expanded': isOpen
				}),
				content: 'check-list-page__d-plate-content'
			}}
			header={
				<div
					className="check-list-page__d-plate-header"
					onClick={handleOpen}
				>
					<div className="check-list-page__d-plate-header-diagram">
						<DiagramIcon listId={checkList.id} />
					</div>
					<div className="check-list-page__d-plate-header-title">
						{checkList.name}
						<ClickPreventWrap className="check-list-page__float-actions">
							<CheckListHeaderActions listId={checkList.id} />
						</ClickPreventWrap>
					</div>
					<div />
					<div className="check-list-page__d-plate-header-status">
						<ClickPreventWrap className="check-list-page__d-plate-header-status-wrap">
							<RecordStatusSelect
								item={checkList}
								mutation={changeCheckListRecord}
								setChangingState={setChangingState}
								showLoader={changingState && isChanging}
								disabled={isChanging || !canEdit}
							/>
						</ClickPreventWrap>
					</div>
					<div className="check-list-page__d-plate-header-indicators">
						<ProblemIndicators checkListId={checkList.id} />
						<TaskIndicators checkListId={checkList.id} />
					</div>

					{items && items.length > 0 && (
						<div className="check-list-page__body-toggle">
							<LinkButton
								className={classNames('check-list-page__body-toggle-link', {
									'check-list-page__body-toggle-link_active': isOpen
								})}
								rightIcon={
									<i
										className={classNames(
											'tz-simple-arrow-24',
											'check-list-page__d-plate-header-title-arrow',
											{
												'check-list-page__d-plate-header-title-arrow_reversed':
													isOpen
											}
										)}
									/>
								}
								label={
									isOpen
										? 'Скрыть'
										: `Показать ${items.length} ${declination(items.length, [
												'категорию',
												'категории',
												'категорий'
										  ])}`
								}
								onClick={toggle}
							/>
						</div>
					)}
				</div>
			}
		>
			<ExpandablePanel expanded={isOpen}>
				<LoadingPanel active={listsLoading}>
					<CheckListItems
						canAddEntity={canAddEntity}
						checkList={checkList}
					/>
				</LoadingPanel>
			</ExpandablePanel>
		</Plate>
	);
});
