import {wsConnector} from '../wsConnector';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetCheckListsResponse = INormalizedData<ICheckList>;

/**
 * Получает список чек-листов
 */
export const requestCheckLists = () => wsConnector.sendAuthorizedRequest<IGetCheckListsResponse>('getCheckLists');
