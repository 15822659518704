import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {IGetMarkersResponse, requestMarkers} from '@src/api/backend/markers';

interface IGetMarkers {
	objectId?: string;
	planId?: string;
	problemIds?: string[];
	taskIds?: string[];
	inspectionIds?: string[];
	ownerAcceptanceIds?: string[];
}

export interface IGetMarkersPayload {
	data: IGetMarkersResponse;
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetMarkersResponse) => ({
		type: types.GET_SUCCESS,
		payload: {
			objectId,
			data: response
		}
	});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке маркеров нарушений');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает маркеры нарушений
 * @param params параметры запроса
 * @param params.objectId id объекта
 * @param params.planId id плана
 * @param params.problemIds ids нарушений
 * @param params.inspectionIds ids проверок
 * @param params.ownerAcceptanceIds ids передач собственникам
 */
export const getMarkers
	= (params: IGetMarkers): AppThunkAction<Promise<IGetMarkersResponse>> => {
	const {objectId = 'all', planId, problemIds, inspectionIds, ownerAcceptanceIds, taskIds} = params;

	return (dispatch, getState) =>
		dispatch(checkExpiration<IState, IGetMarkersResponse, ApiAction>(
				s => s.entities.markers[objectId],
				createApiAction<IGetMarkersResponse>(
					() => request(objectId),
					(res: IGetMarkersResponse) => success(objectId, res),
					failure,
					() => {
						const s = getState();
						if (
							!s.entities.markers[objectId]
						) {
							throw new StoreError('Empty store before getting internalAcceptances');
						}

						return requestMarkers(planId, problemIds, inspectionIds, ownerAcceptanceIds, taskIds);
					}
				)
			));
	};
