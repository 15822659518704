import {makeDefaultData} from '@src/core/hooks/states/useEditableObjectState';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editObject} from '@src/store/modules/entities/object/actions';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import React, {useCallback, useState} from 'react';
import {useEditableObject} from '../EditableObject/hooks/useEditableObject';
import {makeTitle} from './utils/makeTitle';
import './EditableObjectDialog.less';

interface IEditableObjectDialogProps {
	object: IObject;
	isOpen: boolean;

	onClose: () => void;
}

export const EditableObjectDialog = ({
	object, isOpen, onClose
}: IEditableObjectDialogProps) => {
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);

	const defaultData = makeDefaultData(object);
	const [fields, getSavingData, reset, isBlocking] = useEditableObject(
		object.id,
		object.companyId,
		defaultData,
		saving,
		false
	);

	const save = useCallback(async () => {
		if (!object) { return; }

		setSaving(true);

		const savingData = await getSavingData();

		if (savingData) {
			await dispatch(editObject(object.id, savingData));
			onClose();
		}

		setSaving(false);
	}, [object, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-object-dialog"
			isOpen={isOpen}
			title={makeTitle(object)}
			footer={(
				<ActionButtons className="editable-object-dialog-action-buttons">
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};