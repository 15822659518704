import React, {PureComponent} from 'react';
import classNames from 'classnames';

interface IExportTemplateOptionProps {
	id: string;
	name: string;
	outputFileType: string;
	selected: boolean;

	onSelect?(id: string): void;
}

class ExportTemplateOption extends PureComponent<IExportTemplateOptionProps> {
	static displayName = 'ExportTemplateOption';

	render() {
		const {name, outputFileType, selected} = this.props;
		const classes = classNames('et-select__option', {'et-select__option_selected': selected});
		const iconClasses = classNames(
			'et-select__option-file-icon',
			{[`et-select__option-file-icon_${outputFileType}`]: !!outputFileType}
		);

		return (
			<div
				className={classes}
				onClick={this._handleSelect}
			>
				<div className={iconClasses}>
					<div className="et-select__option-file-ext">
						.
						{outputFileType}
					</div>
				</div>

				<div className="et-select__option-name">{name}</div>
			</div>
		);
	}

	private _handleSelect = () => {
		const {id, onSelect} = this.props;
		if (onSelect) {
			onSelect(id);
		}
	};
}

export default ExportTemplateOption;
