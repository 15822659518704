import React from 'react';
import './DesktopPhotoEditorFooter.less';
import classNames from 'classnames';
import {ColorSelectButton, InlineButton, Button} from '../../../../buttons';

interface IPhotoEditorFooterProps {
	className?: string;
	style?: React.CSSProperties;
	type: 'edit' | 'view';
	editable?: boolean;
	hEdit(): void;
	title?: string;
	hChangeColor(color: string): void;
	hCancel(): void;
	hSave(): void;
	hClear?(): void;
	colors: string[];
	selectedColor?: string;
}

const editIcon = <i className="tz-brush-24"/>;
const eraseIcon = <i className="tz-eraser-24"/>;

const DesktopPhotoEditorFooter = ({
	className,
	editable,
	type,
	hEdit,
	hChangeColor,
	hCancel,
	hSave,
	hClear,
	title,
	colors,
	selectedColor
}: IPhotoEditorFooterProps) => (
	<div className={classNames('desktop-photo-viewer__footer', className)}>
		<div>{title || 'Фото нарушения'}</div>
		{editable && type === 'edit' && (
			<InlineButton
				leftIcon={eraseIcon}
				onClick={hClear}
			/>
)}
		{editable && type === 'edit' && (
			<div className="desktop-photo-viewer__footer_edit-color">
				{colors.map(color => (
					<ColorSelectButton
						key={color}
						onClick={() => hChangeColor(color)}
						color={`${color}`}
						selected={color === selectedColor}
					/>
					))}
			</div>
			)}
		{editable && type === 'edit' && (
			<div className="desktop-photo-viewer__footer_edit-actions">
				<Button
					type="accent-blue"
					label="Готово"
					onClick={hSave}
				/>
				<Button
					type="cancel"
					label="Отменить"
					onClick={hCancel}
				/>
			</div>
			)}
		{editable && type === 'view' && (
			<InlineButton
				leftIcon={editIcon}
				onClick={hEdit}
				labelClassName="desktop-photo-viewer__footer_edit-label"
				label="Редактировать"
			/>
			)}
	</div>
	);

export default DesktopPhotoEditorFooter;
