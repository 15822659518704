import {
	testCheckPermission,
	testCheckRestrictions,
	testCheckRestrictionsList
} from '@src/utils/testRestrictions';
import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';

export interface IPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canMove?: boolean;
	canProblemsMove?: boolean;
	canMoveRestrictions?: {[key: string]: boolean};
	canProblemsMoveRestrictions?: {[key: string]: boolean};
}

/**
 * Возвращает полномочия для проверки
 *
 * @param objectId id объекта
 * @param check проверка
 * @param problems нарушения
 */
export const useCheckPermissions = (
	objectId: string,
	check?: ICheck,
	problems?: IProblem[]
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canDelete: testCheckRestrictions(
				user.id,
				user.roles,
				'checksDelete',
				objectId,
				check?.createdBy
			),
			canExport: testCheckRestrictions(
				user.id,
				user.roles,
				'checksExport',
				objectId,
				check?.createdBy,
				problems
			),
			canEdit: testCheckRestrictions(
				user.id,
				user.roles,
				'checksEdit',
				objectId,
				check?.createdBy
			),
			canMove: testCheckRestrictions(
				user.id,
				user.roles,
				'checksMove',
				objectId,
				check?.createdBy
			),
			canMoveRestrictions: testCheckRestrictionsList(
				user.roles,
				'checksMove',
				objectId
			),
			canProblemsMove: testCheckPermission(user.roles, 'checkProblemsMove', objectId),
			canProblemsMoveRestrictions: testCheckRestrictionsList(
				user.roles,
				'checkProblemsMove',
				objectId
			)
		};
	}, [objectId, check, user]);
};
