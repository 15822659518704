import {IEditableContractState} from '@src/core/hooks/states/useEditableContractState';
import React, {memo} from 'react';
import {NumberContractField} from '../fields/NumberContractField';
import {TextContractField} from '../fields/TextContractField';

interface IPriceBlockProps {
	editingState: IEditableContractState;

	onChange: (
		value: string | number | string[] | null | undefined,
		field: keyof IEditableContractState
	) => void;
	onError: (field: keyof IEditableContractState) => void;
}

export const PriceBlock = memo(({
	editingState, onChange, onError
}: IPriceBlockProps) => (
	<div className="editable-contract__blocks-price">
		<div className="editable-contract__blocks-price-title">
			Стоимость
		</div>
		<div className="editable-contract__blocks-price-container">
			<NumberContractField
				label="Стоимость договора, числом"
				errorMessage="Укажите стоимость договора, числом"
				hasError={editingState.errors.priceNumber}
				value={editingState.priceNumber}
				onChange={value => onChange(value, 'priceNumber')}
				onError={() => onError('priceNumber')}
			/>

			<TextContractField
				label="Стоимость договора, текстом"
				errorMessage="Укажите стоимость договора, текстом"
				hasError={editingState.errors.priceText}
				value={editingState.priceText}
				elementType="textarea"
				onChange={value => onChange(value, 'priceText')}
				onError={() => onError('priceText')}
			/>

				<TextContractField
					label="Штраф"
					errorMessage="Укажите штраф"
					hasError={editingState.errors.fine}
					value={editingState.fine}
					elementType="textarea"
					onChange={value => onChange(value, 'fine')}
					onError={() => onError('fine')}
				/>
		</div>
	</div>
));