import {ConfirmDialog} from '../../dialogs';
import {PageLeavingBlockCtx} from './utils/PageLeavingBlockCtx';
import React, {ReactNode, useCallback, useMemo, useRef, useState} from 'react';
import {BrowserHistory, Action, Location} from 'history';

export interface IBlock {
	key: string;
	title?: string;
	message?: string;
	whitelist?: string[];
}

interface IPageLeavingBlockProps {
	children?: ReactNode;
	history: BrowserHistory;
}

interface IPageLeavingBlockState {
	blocks: IBlock[];
	activeBlock?: IBlock;
	dialogVisible: boolean;
}

export const PageLeavingBlock = ({children, history}: IPageLeavingBlockProps) => {
	const initialState = {blocks: [], dialogVisible: false};
	const [pageLeavingState, setPageLeavingState] = useState<IPageLeavingBlockState>(initialState);
	const [leavingParams, setLeavingState] = useState<{location: Location, action: Action}>();
	const unblockLeavingRef = useRef<() => void>();

	/**
	 * Обрабатывает уход со страницы через history
	 *
	 * @param location location
	 * @param action action
	 */
	const handleLeaving = useCallback(
		(location: Location, action: Action): false | void => {
			setLeavingState({location, action});
			if (pageLeavingState.activeBlock) {
				if (pageLeavingState.activeBlock.whitelist?.includes(location.pathname)) {
					return undefined;
				}
				setPageLeavingState(state => ({...state, dialogVisible: true}));
				return false;
			}
			if (unblockLeavingRef.current) {
				unblockLeavingRef.current();
				unblockLeavingRef.current = undefined;
			}
			return undefined;
		},
		[pageLeavingState]
	);

	/**
	 * Обрабатывает подтверждение перехода
	 */
	const handleLeavingAccepted = useCallback(() => {
		setPageLeavingState(state => ({...state, dialogVisible: false}));
		// Ожидание закрытия диалога
		setTimeout(() => {
			if (unblockLeavingRef.current) {
				unblockLeavingRef.current();
				unblockLeavingRef.current = undefined;
			}
			if (leavingParams) {
				switch (leavingParams.action) {
					case 'POP':
						history.back();
						break;
					case 'PUSH':
						history.push(leavingParams.location);
						break;
					case 'REPLACE':
						history.replace(leavingParams.location);
						break;
				}
			}
		}, 250);
	}, [history, leavingParams]);

	/**
	 * Обрабатывает отмену перехода
	 */
	const handleLeavingRejected = useCallback(() => {
		setPageLeavingState(state => ({...state, dialogVisible: false}));
	}, []);
	/**
	 * Добавляет блокировку
	 *
	 * @param block данные о блокировке
	 */
	const addBlock = useCallback((block: IBlock) => {
		unblockLeavingRef.current = history.block(() => handleLeaving);
		setPageLeavingState(state => ({
			...state,
			blocks: [...state.blocks.filter(item => item.key !== block.key), block],
			activeBlock: block,
			dialogVisible: false
		}));
	}, []);

	/**
	 * Удаляет блокировку
	 *
	 * @param key ключ блокировки
	 */
	const deleteBlock = useCallback((key: string) => {
		if (unblockLeavingRef.current) {
			unblockLeavingRef.current();
			unblockLeavingRef.current = undefined;
		}
		setPageLeavingState(state => {
			const blocks = state.blocks.filter(item => item.key !== key);
			return {
				...state,
				blocks,
				activeBlock: blocks.length > 0 ? blocks[blocks.length - 1] : undefined,
				dialogVisible: false
			};
		});
	}, []);
	const ctxValue = useMemo(
		() => ({
			add: addBlock,
			delete: deleteBlock
		}),
		[addBlock, deleteBlock]
	);

	return (
		<>
			<PageLeavingBlockCtx.Provider value={ctxValue}>{children}</PageLeavingBlockCtx.Provider>

			{pageLeavingState?.activeBlock !== undefined && (
				<ConfirmDialog
					isOpen={pageLeavingState.dialogVisible}
					title={pageLeavingState.activeBlock.title ?? 'Вы действительно хотите уйти?'}
					message={pageLeavingState.activeBlock.message ?? 'Все введенные данные будут потеряны'}
					acceptBtnLabel="Да"
					rejectBtnLabel="Нет"
					acceptBtnProps={{type: 'accent-red'}}
					onAccept={handleLeavingAccepted}
					onReject={handleLeavingRejected}
					onRequestClose={handleLeavingRejected}
				/>
			)}
		</>
	);
};
