import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {useCheckDelete} from '../../hooks/useCheckDelete';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {IPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import {useMovingCheckDialog} from '@src/components/MovingCheckDialog/hooks/useMovingCheckDialog';

interface IMobileMenuProps {
	objectId: string;
	check: ICheck;
	permissions: IPermissions;
}

const MobileMenu = ({objectId, check, permissions}: IMobileMenuProps) => {
	const [deleteDialog, handleDeleteClick] = useCheckDelete(objectId, check);
	const [moveDialog, openMoveDialog] = useMovingCheckDialog(check.objectId, check);

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();
	const items = [];

	items.push(updateData);
	if (permissions.canMove && permissions.canProblemsMove) {
		items.push(
			<MenuItem
				key="move"
				icon={<i className="tz-long-arrow-24"/>}
				onClick={openMoveDialog}
			>
				Перенести проверку
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
			{permissions.canMove && permissions.canProblemsMove && moveDialog}
		</>
	) : null;
};

export default MobileMenu;
