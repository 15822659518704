import React, {useCallback, useRef, useState} from 'react';
import './DateRangePicker.less';
import DatePickerLayer, {IDatePickerDialogProps} from '../DatePickerLayer';
import {IPopupMenuTriggerFnProps} from '../../menu/PopupMenu';
import classNames from 'classnames';
import InlineDateRangePicker from '../InlineDateRangePicker';
import {IDatesOptions} from '../Calendar';
import useToggle from 'react-use/lib/useToggle';
import DatePickerResult from '../DatePickerResult';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {ru} from 'date-fns/locale';

interface IDateRangePickerTriggerProps extends IPopupMenuTriggerFnProps {
	valueFrom?: Date;
	valueTo?: Date;
}

interface IDatePickerProps {
	className?: string;
	style?: React.CSSProperties;
	trigger: (props: IDateRangePickerTriggerProps) => React.ReactNode;
	dateFormat: string;
	datesOptions?: IDatesOptions;
	valueFrom?: Date;
	valueTo?: Date;
	dialogProps?: IDatePickerDialogProps['dialogProps'];
	popupProps?: IDatePickerDialogProps['popupProps'];
	useApplyButton?: boolean;

	onChange?(from: Date, to: Date): void;
}

const DateRangePicker = (props: IDatePickerProps) => {
	const {
		className,
		style,
		trigger,
		dateFormat,
		datesOptions,
		valueFrom: propsValueFrom,
		valueTo: propsValueTo,
		dialogProps,
		popupProps,
		useApplyButton,
		onChange
	} = props;

	const [isOpen, toggleOpen] = useToggle(false);
	const [valueFrom, setValueFrom] = useState<Date | undefined>(propsValueFrom);
	const [valueTo, setValueTo] = useState<Date | undefined>(propsValueTo);

	useUpdateEffect(() => {
		setValueFrom(propsValueFrom);
		setValueTo(propsValueTo);
	}, [propsValueFrom, propsValueTo]);

	const resolveFn = useRef<() => void | undefined>();

	const open = useCallback(() => {
		toggleOpen(true);
	}, []);

	const close = useCallback(() => {
		toggleOpen(false);
		setValueFrom(propsValueFrom);
		setValueTo(propsValueTo);
	}, [propsValueFrom, propsValueTo]);

	const applyValue = useCallback(
		(from: Date, to: Date) => {
			toggleOpen(false);
			// Применение нового значения только после закрытия окна/попапа
			new Promise<void>(resolve => {
				resolveFn.current = resolve;
			}).then(() => {
				resolveFn.current = undefined;
				if (onChange) {
					onChange(from, to);
				}
			});
		},
		[onChange]
	);

	const handleApply = useCallback(() => {
		if (valueFrom && valueTo) {
			applyValue(valueFrom, valueTo);
		}
	}, [valueFrom, valueTo, applyValue]);

	const handleChange = useCallback(
		(from: Date, to: Date) => {
			if (!useApplyButton) {
				applyValue(from, to);
			} else {
				setValueFrom(from);
				setValueTo(to);
			}
		},
		[applyValue, useApplyButton]
	);

	const handleClosingComplete = useCallback(() => {
		if (resolveFn.current) {
			resolveFn.current();
		}
	}, []);

	const getTrigger = useCallback(
		(triggerProps: IPopupMenuTriggerFnProps) =>
			trigger({
				...triggerProps,
				valueFrom: propsValueFrom,
				valueTo: propsValueTo
			}),
		[propsValueFrom, propsValueTo, trigger]
	);

	const result = useApplyButton && (
		<DatePickerResult
			className="date-picker__result"
			value1={valueFrom}
			value2={valueTo}
			placeholder1="Дата начала"
			placeholder2="Дата окончания"
			isRange
			dateFormat={dateFormat}
			dateOptions={datesOptions}
		/>
	);

	return (
		<DatePickerLayer
			trigger={getTrigger}
			result={result}
			isOpen={isOpen}
			isButtonsVisible={useApplyButton}
			dialogProps={dialogProps}
			popupProps={popupProps}
			onApply={handleApply}
			onOpen={open}
			onClose={close}
			onAfterClose={handleClosingComplete}
		>
			<InlineDateRangePicker
				className={classNames('date-picker__calendar', className)}
				style={style}
				datesOptions={datesOptions}
				valueFrom={valueFrom}
				valueTo={valueTo}
				onChange={handleChange}
			/>
		</DatePickerLayer>
	);
};

DateRangePicker.defaultProps = {
	dateFormat: 'dd MMMM yyyy',
	datesOptions: {locale: ru}
};

export default DateRangePicker;
