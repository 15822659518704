import React from 'react';
import {Button} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {getOwnerAcceptances} from '@src/store/modules/entities/ownerAcceptances/actions/get';
import {useAddingOwnerAcptDialog} from '@src/components/AddingOwnerAcptDialog/hooks/useAddingOwnerAcptDialog';

interface IDesktopEntityAddingProps {
	objectId: string;
}

export const DesktopEntityAdding = ({objectId}: IDesktopEntityAddingProps) => {
	const dispatch = useAppDispatch();

	const [, reloadList] = useAsyncFn(async () => {
		await dispatch(getOwnerAcceptances(objectId || 'all'));
	}, [objectId]);

	const [dialog, open] = useAddingOwnerAcptDialog({
		objectId,
		onSuccess: reloadList
	});

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-plus-20"/>}
				label="Добавить"
				onClick={open}
			/>
			{dialog}
		</>
	);
};
