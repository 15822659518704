import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';

/**
 * Возвращает полномочия для конкретного обращения
 *
 * @param claim обращение
 * @param networkStatus статус соединения
 * @param objectId id объекта
 * @param user текущий пользователь
 */
export const getPermissionsForClaim = (
	claim: IWarrantyClaim,
	networkStatus: boolean,
	objectId?: string,
	user?: IUser
) => {
	let canDelete = false;
	if (networkStatus && user) {
		const deletePermission = findPermission(
			user.roles || [],
			'warrantyClaimsDelete',
			UserRoleScopes.OBJECT,
			objectId
		);
		canDelete = !!deletePermission;
	}
	return {canDelete};
};
