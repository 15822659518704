/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import './ProblemsDynamicAreaChart.less';
import classNames from 'classnames';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import IDynamicChartDataItem from '@tehzor/tools/interfaces/statistics/IDynamicChartDataItem';
import CustomCursor from './components/CustomCursor';
import AreasGradients from './components/AreasGradients';
import DotShadowFilter from './components/DotShadowFilter';
import ProblemStatusesTooltip from '../ProblemStatusesTooltip';

interface IProblemsDynamicAreaChartProps {
	className?: string;
	style?: React.CSSProperties;
	data: IDynamicChartDataItem[];
	statuses: IProblemStatus[];
}

const chartMargin = {top: 30, right: 26, bottom: 20, left: 0};
const axisTick = {fill: 'rgba(23, 42, 65, 0.35)', fontSize: '14px', fontWeight: 400};
const activeDot = {r: 4, stroke: '#F4FBFF', strokeWidth: 2, filter: 'url(#active-dot-shadow)'};

const ProblemsDynamicAreaChart = ({className, style, data, statuses}: IProblemsDynamicAreaChartProps) => (
	<div
		className={classNames('problems-dynamic-area-chart', className)}
		style={style}
	>
		<ResponsiveContainer
			width="100%"
			height="100%"
		>
			<AreaChart
				data={data}
				margin={chartMargin}
			>
				<defs>
					<AreasGradients statuses={statuses}/>

					<DotShadowFilter/>
				</defs>

				<CartesianGrid
					className="problems-dynamic-area-chart__grid"
					vertical={false}
				/>
				<YAxis
					axisLine={false}
					tickSize={0}
					tick={axisTick}
					// @ts-ignore
					tickMargin={28}
					allowDecimals={false}
				/>
				<XAxis
					dataKey="name"
					axisLine={false}
					tickSize={0}
					tick={axisTick}
					// @ts-ignore
					tickMargin={16}
				/>
				<Tooltip
					offset={34}
					position={{y: 50}}
					cursor={<CustomCursor/>}
					content={ProblemStatusesTooltip}
				/>

				{statuses.map(status => (
					<Area
						key={status.id}
						type="monotone"
						dataKey={status.id}
						name={status.name}
						stroke={status.color}
						strokeWidth={2}
						fillOpacity={1}
						fill={`url(#area-${status.id})`}
						isAnimationActive={false}
						activeDot={activeDot}
					/>
				))}
			</AreaChart>
		</ResponsiveContainer>
	</div>
);

ProblemsDynamicAreaChart.displayName = 'ProblemsDynamicAreaChart';

export default ProblemsDynamicAreaChart;
