import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {extractProblemTagsSetById} from '@src/store/modules/dictionaries/problemTagsSets/selectors';
import {useParams} from 'react-router';

export const useManageProblemTagsSetsBreadcrumbs = () => {
	const {problemTagsSetId} = useParams<{problemTagsSetId?: string}>();
	const tagSet = useAppSelector(s => extractProblemTagsSetById(s, problemTagsSetId));

	return useMemo(() => {
		const items = [
			{
				label: 'Наборы меток нарушений',
				url: '/manage/problem-tags-sets',
				nonClickable: !tagSet
			}
		];

		if (problemTagsSetId && tagSet) {
			items.push({
				label: `Набор меток "${tagSet.name}"`,
				url: `/manage/problem-tags-sets/${tagSet.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [tagSet, problemTagsSetId]);
};
