import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import * as types from '../constants';
import {makeWarrantyClaimDeleteRequest} from '@src/api/backend/warrantyClaim';
import {queryClient} from '@src/api/QueryClient';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

export interface IDeleteWarrantyClaimPayload {
	objectId: string;
	claimId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, claimId: string, quietly?: boolean) => {
	if (!quietly) {
		addInfoToast('Удалено', 'Обращение успешно удалено');
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {objectId, claimId} as IDeleteWarrantyClaimPayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении обращения');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

export const deleteWarrantyClaimActions = {request, success, failure};

/**
 * Удаляет гарантийное обращение
 * @param objectId id объекта
 * @param claimId id обращения
 */
export const deleteWarrantyClaim = (objectId: string, claimId: string) =>
	createApiAction(
		request,
		() => success(objectId, claimId),
		failure,
		() => {
			void queryClient.invalidateQueries(warrantyClaimsQueryKeys.list());
			void queryClient.invalidateQueries(warrantyClaimsQueryKeys.latest());
			void queryClient.invalidateQueries(problemsQueryKeys.list());
			return makeWarrantyClaimDeleteRequest(objectId, claimId);
		}
	);
