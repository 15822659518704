import React from 'react';
import {Button} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

interface IDesktopChecksExportProps {
	task: IEnrichedTask;
}

export const DesktopCreateCheck = ({task}: IDesktopChecksExportProps) => {
	const object = useAppSelector(s => extractObject(s, task.objectId.id));

	const [dialog, openDialog] = useAddingCheckDialog({
		objectId: task.objectId.id,
		stage: object.stage,
		links: {taskId: task.id}
	});

	return (
		<>
			<Button
				type="accent-blue"
				label="Проверка"
				leftIcon={<i className="tz-check-24"/>}
				onClick={openDialog}
			/>
			{dialog}
		</>
	);
};