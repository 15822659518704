import {PrivateRoute} from '@src/containers/PrivateRoute';
import ProblemCommentsPage from '@src/pages/ProblemCommentsPage';
import ProblemPage from '@src/pages/ProblemPage/ProblemPage';
import {ProblemsPage} from '@src/pages/ProblemsPage';
import ProblemsFiltersRoutingPage from '@src/pages/filters/ProblemsFiltersRoutingPage';
import React from 'react';
import {problemsFilterRoutes} from './problemsFilterRoutes';

export const objectProblemsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<ProblemsPage/>}/>
	},
	{
		path: ':problemId',
		element: <PrivateRoute element={<ProblemPage/>}/>
	},
	{
		path: ':problemId/comments',
		element: <PrivateRoute element={<ProblemCommentsPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<ProblemsFiltersRoutingPage/>}/>,
		children: problemsFilterRoutes
	}
];
