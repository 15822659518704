import classNames from 'classnames';
import React, {useCallback} from 'react';
import './Breadcrumb.less';
import {LinkButton} from '../../../../buttons';
import {IBreadcrumb} from '../../interfaces/IBreadcrumb';

interface IBreadcrumbProps extends IBreadcrumb {
	className?: string;
	style?: React.CSSProperties;

	onClick?: (url: string) => void;
}

const Breadcrumb = (props: IBreadcrumbProps) => {
	const {className, style, label, url, inactive, onClick} = props;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(url);
		}
	}, [onClick, url]);

	return (
		<LinkButton
			className={classNames('breadcrumb', className, {breadcrumb_inactive: inactive})}
			labelClassName="breadcrumb__label"
			style={style}
			label={label}
			title={label}
			url={url}
			onClick={inactive ? undefined : handleClick}
		/>
	);
};

export default Breadcrumb;
