import React, {useCallback, useRef, useState} from 'react';
import './AddingSpaceOwnerDialog.less';
import {ActionButtons, Button, Dialog, IconButton, InlineButton} from '@tehzor/ui-components';
import OwnersBinding, {IOwnersBindingRefProps} from './components/OwnersBinding';
import AddingSpaceOwner, {
	IAddingSpaceOwnerRefProps
} from '@src/components/AddingSpaceOwner/AddingSpaceOwner';
import useAsyncFn from 'react-use/lib/useAsyncFn';

interface IAddingSpaceOwnerDialogProps {
	companyId?: string;
	spaceId?: string;
	isOpen: boolean;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

/**
 * Окно добавления собственника помещения
 */
const AddingSpaceOwnerDialog = (props: IAddingSpaceOwnerDialogProps) => {
	const {companyId, spaceId, isOpen, onClose, onSuccess} = props;

	const viewRef = useRef<IAddingSpaceOwnerRefProps | IOwnersBindingRefProps>(null);
	const [view, setView] = useState<'adding' | 'binding'>('adding');
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const [, handleSave] = useAsyncFn(async () => {
		if (viewRef.current) {
			await viewRef.current.save();
		} else {
			onClose();
		}
		success.current = true;
	}, [onClose]);

	const handleCancel = useCallback(() => {
		if (viewRef.current) {
			viewRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		setView('adding');
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<Dialog
			className={{
				root: 'adding-space-owner-dialog',
				title: 'adding-space-owner-dialog__title',
				body: 'adding-space-owner-dialog__body'
			}}
			isOpen={isOpen}
			title={
				view === 'adding' ? (
					'Новый собственник'
				) : (
					<>
						<IconButton
							className="adding-space-owner-dialog__back-btn"
							onClick={() => setView('adding')}
						>
							<i className="tz-long-arrow-24"/>
						</IconButton>
						Выберите собственника
					</>
			)
			}
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			{view === 'adding' ? (
				<>
					<AddingSpaceOwner
						companyId={companyId}
						spaceId={spaceId}
						saving={saving}
						onClose={onClose}
						setSaving={setSaving}
						ref={viewRef}
					/>
					<div className="adding-space-owner-dialog__bind-btn-wrap">
						<InlineButton
							label="Выбрать из списка"
							type="accent"
							rightIcon={<i className="tz-arrow-24"/>}
							onClick={() => setView('binding')}
						/>
					</div>
				</>
			) : (
				companyId !== undefined
				&& spaceId !== undefined && (
					<OwnersBinding
						companyId={companyId}
						spaceId={spaceId}
						onClose={onClose}
						setSaving={setSaving}
						ref={viewRef}
					/>
				)
			)}
		</Dialog>
	);
};

export default AddingSpaceOwnerDialog;
