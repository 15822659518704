import React from 'react';
import {useEditableProblemTags} from '@src/components/EditableProblemTag/hooks/useEditableProblemTags';
import {useProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';
import {IExtendedSavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IExtendedSavingProblemTag';

interface ITableProps {
	tags: IExtendedSavingProblemTag[];
	setArray: React.Dispatch<React.SetStateAction<IExtendedSavingProblemTag[]>>;
	problemTagsSetId?: string;
	disabledActions?: boolean;
}

export const Table = ({
	tags,
	setArray,
	problemTagsSetId,
	disabledActions
}:
ITableProps) => {
	const permissions = useProblemTagsSetPermissions();
	const fields = useEditableProblemTags(tags, setArray, permissions, disabledActions, problemTagsSetId);

	return <>{fields}</>;
};
