import React from 'react';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import SelectionActions from '@src/pages/TasksPage/components/selection/SelectionActions';

interface IMobileRightButtonsProps {
	selectedEntities?: IEnrichedTask[];
	selectedClearing: () => void;
}

const MobileRightButtons = ({
	selectedEntities,
	selectedClearing
}: IMobileRightButtonsProps) => (
	<div className="problems-page__mobile-right-buttons">
		{selectedEntities && selectedEntities.length > 0 ? (
			<SelectionActions
				selectedEntities={selectedEntities}
				selectedClearing={selectedClearing}
			/>
			) : null}
		<AppUpdateMenu/>
	</div>
	);

export default MobileRightButtons;
