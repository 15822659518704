import React from 'react';
import update from 'immutability-helper';
import IUploadingFile from '../interfaces/IUploadingFile';
import IRawFile from '../interfaces/IRawFile';
import GlobalUploader from '../core/GlobalUploader';
import generateKey from '../utils/generateKey';

const globalUploader = GlobalUploader.instance;

interface IFilesUploaderProps {
	/**
	 * Выгружаемые файлы
	 */
	files: IUploadingFile[];
	/**
	 * Множественный выбор
	 *
	 * @type {boolean}
	 */
	multiple?: boolean;
	/**
	 * Назначение, необходимое для выгрузки во временные файлы
	 *
	 * @type {string}
	 */
	destination: string;
	/**
	 * Компонент для отображения файла
	 */
	fileTemplate: React.ReactElement<any>;
	/**
	 * Свойство disabled у файлов
	 */
	fileDisabled?: boolean;

	/**
	 * Функция, вызываемая при изменении количества файлов
	 *
	 * @type {function}
	 */
	onFilesChange?(files: IUploadingFile[]): void;
}

/**
 * Компонент для отрисовки выгружаемых файлов и взаимодействия с GlobalUploader
 */
class FilesUploader extends React.PureComponent<IFilesUploaderProps> {
	static defaultProps: Partial<IFilesUploaderProps> = {
		multiple: false
	};

	private _namespace = generateKey('namespace');

	componentDidMount() {
		globalUploader.subscribeOnFileChange(this._handleFileChange, this._namespace);
	}

	componentDidUpdate(prevProps: IFilesUploaderProps) {
		if (prevProps.files.length > this.props.files.length && this.props.files.length === 0) {
			globalUploader.clearQueue(this._namespace);
		}
	}

	componentWillUnmount() {
		globalUploader.unsubscribeOnFileChange(this._handleFileChange, this._namespace);
	}

	/**
	 * Добавляет файлы на выгрузку
	 *
	 * @param {IRawFile[]} files файлы
	 */
	addFiles = (files: IRawFile[]) => {
		const {files: oldFiles, destination, multiple} = this.props;
		if (!multiple) {
			this.deleteAllFiles();
		}
		const newFiles = globalUploader.addToQueue(files, destination, this._namespace);
		this._emitFilesChange(multiple ? update(oldFiles, {$push: newFiles}) : newFiles);
	};

	/**
	 * Удаляет все файлы
	 */
	deleteAllFiles = () => {
		for (const file of this.props.files) {
			globalUploader.deleteFile(file.key);
		}
		this._emitFilesChange([]);
	};

	/**
	 * Очищает очередь на случай если файлы уже выгружены успешно и сохранены
	 */
	clearQueue = () => {
		globalUploader.clearQueue(this._namespace);
		this._emitFilesChange([]);
	};

	render() {
		const {files, fileTemplate, fileDisabled} = this.props;
		if (!files || !files.length) {
			return null;
		}

		return files.map((file: IUploadingFile) =>
			React.cloneElement(fileTemplate, {
				...file,
				id: file.key,
				disabled: fileDisabled,
				onDeleteClick: this._deleteFile,
				onReloadClick: this._reloadFile
			}));
	}

	/**
	 * Удаляет файл из очереди на выгрузку
	 *
	 * @param {string} key ключ файла
	 * @private
	 */
	private _deleteFile = (key: string) => {
		globalUploader.deleteFile(key);

		const {files} = this.props;
		const index = files.findIndex(item => item.key === key);

		if (index !== -1) {
			this._emitFilesChange(update(files, {$splice: [[index, 1]]}));
		}
	};

	/**
	 * Заново помещает файл в очередь на выгрузку
	 *
	 * @param {string} key ключ файла
	 * @private
	 */
	private _reloadFile = (key: string) => {
		globalUploader.reloadFile(key);
	};

	/**
	 * Обрабатывает изменение данных какого-либо файла
	 *
	 * @param {IUploadingFile} file файл
	 * @private
	 */
	private _handleFileChange = (file: IUploadingFile) => {
		const {files} = this.props;
		const index = files.findIndex(item => item.key === file.key);
		if (index === -1) {
			return;
		}
		this._emitFilesChange(update(files, {[index]: {$set: file}}));
	};

	/**
	 * Инициирует событие onFilesChange
	 *
	 * @param {IUploadingFile[]} files измененные файлы
	 * @private
	 */
	private _emitFilesChange = (files: IUploadingFile[]) => {
		const {onFilesChange} = this.props;
		if (onFilesChange) {
			onFilesChange(files);
		}
	};
}

export default FilesUploader;
