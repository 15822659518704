import React, {useMemo} from 'react';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {Tree} from 'array-to-tree';
import ProblemComment, {IProblemCommentProps} from '../ProblemComment';
import {flattenDescendants} from '../utils/flattenDescendants';
import {format} from 'date-fns';

interface IChildrenProps {
	className?: IProblemCommentProps['className'];
	style?: React.CSSProperties;
	data: Tree<IProblemComment>;
	level: number;
	isSelected?: (id: string) => boolean;
	controls?: (data: IProblemComment) => React.ReactNode;
	dateOptions?: Parameters<typeof format>[2];

	onSubCommentAdd?: (id: string) => void;
	onImageClick?: (id: string, index: number) => void;
}

const Children = (props: IChildrenProps) => {
	const {className, style, data, level, isSelected, controls, dateOptions, onSubCommentAdd, onImageClick} = props;

	const comments = useMemo(
		() => flattenDescendants(data)
			.sort((a, b) => (a.item.createdAt ?? 0) - (b.item.createdAt ?? 0)),
		[data]
	);

	return (
		<div className="problem-comment__children">
			{comments.map(({item, parent, showAppeal}) => (
				<ProblemComment
					key={item.id}
					className={className}
					style={style}
					data={item}
					parent={parent}
					controls={controls}
					level={level + 1}
					showAppeal={showAppeal}
					isSelected={isSelected}
					dateOptions={dateOptions}
					onSubCommentAdd={onSubCommentAdd}
					onImageClick={onImageClick}
				/>
			))}
		</div>
	);
};

export default Children;