import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';

export type IGetDocumentsResponse = INormalizedData<IDocument>;

/**
 * Возвращает документы
 */
export const requestDocuments = async () => {
	const response = await httpRequests.withToken.get<IGetDocumentsResponse>('documents');
	return response.data;
};
