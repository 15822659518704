import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useEditableProblemDialog} from '@src/components/EditableProblemDialog/hooks/useEditableProblemDialog';
import {useProblemHistoryDialog} from '@src/components/ProblemHistoryDialog/hooks/useProblemHistoryDialog';
import {getObjectStats} from '@src/store/modules/dictionaries/objects/actions';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IPermissions} from '../hooks/usePermissions';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';

interface IMenuProps {
	objectId: string;
	problem: IPreparedProblem;
	permissions: IPermissions;
}

const Menu = ({objectId, problem, permissions}: IMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	// Диалог добавления/редактирования ответа
	const [problemDialog, openEditDialog] = useEditableProblemDialog(objectId, problem);

	// Диалог истории изменений
	const [historyDialog, openHistoryDialog] = useProblemHistoryDialog(objectId, problem.id);
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить нарушение',
		`Вы действительно хотите удалить нарушение №${problem?.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteProblem(objectId, problem.id));
			await dispatch(getObjectStats([objectId]));
			goBack();
		}
	}, [objectId, problem.id]);

	const items = [];
	if (!isDesktop) {
		items.push(updateData);
	}

	if (permissions.canViewHistory) {
		items.push(
			<MenuItem
				key="history"
				icon={<i className="tz-history-24"/>}
				onClick={openHistoryDialog}
			>
				История изменений
			</MenuItem>
		);
	}

	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit"/>}
				onClick={openEditDialog}
			>
				Редактировать
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{permissions.canEdit && problemDialog}
			{permissions.canDelete && deleteDialog}
			{permissions.canViewHistory && historyDialog}
		</>
	) : null;
};

export default Menu;
