import React, {useCallback} from 'react';
import './StatsTreePanel.less';
import {ExpandablePanel} from '../../containers';
import classNames from 'classnames';
import useToggle from 'react-use/lib/useToggle';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import ExpandButton from '../../buttons/ExpandButton/ExpandButton';
import CirclePlaceholder from '../../placeholders/CirclePlaceholder';
import TextPlaceholder from '../../placeholders/TextPlaceholder';
import ButtonPlaceholder from '../../placeholders/ButtonPlaceholder';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IProblemsStatsProps {
	className?: string | {
		root?: string;
		title?: string;
	};
	style?: React.CSSProperties;
	id: string;
	title: string;
	subTitle?: string;
	image?: string;
	level?: number;
	loading?: boolean;
	isExpanded?: boolean;
	defaultIsExpanded?: boolean;
	showExpandBtnPlaceholder?: boolean;
	permanentContent?: React.ReactNode;
	hiddenContent?: React.ReactNode;
	tag?: React.ReactNode;

	onExpand?(id: string, value: boolean): void;
}

const StatsTreePanel = (props: IProblemsStatsProps) => {
	const {
		className,
		style,
		id,
		title,
		subTitle,
		image,
		level = 0,
		loading,
		isExpanded,
		defaultIsExpanded,
		showExpandBtnPlaceholder,
		permanentContent,
		hiddenContent,
		tag,
		onExpand
	} = props;

	const classes = convertClassNames(className);
	const [expanded, toggleExpanded] = useToggle(isExpanded ?? defaultIsExpanded ?? false);

	useUpdateEffect(() => {
		toggleExpanded(isExpanded);
	}, [isExpanded]);

	const handleExpandBtnClick = useCallback(() => {
		if (isExpanded === undefined) {
			toggleExpanded(!expanded);
		}
		if (onExpand) {
			onExpand(id, !expanded);
		}
	}, [expanded, isExpanded, id]);

	const hasChildren = hiddenContent !== undefined && (Array.isArray(hiddenContent) ? hiddenContent.length > 0 : true);

	return (
		<div
			className={classNames(
				'stats-tree-panel',
				{'stats-tree-panel_without-children': loading ? !showExpandBtnPlaceholder : !hasChildren},
				`stats-tree-panel_level_${level}`,
				{'stats-tree-panel_not-level-0': level > 0},
				classes.root
			)}
			style={style}
		>
			<div className={classNames('stats-tree-panel__header')}>
				{loading
					? showExpandBtnPlaceholder && (
						<ButtonPlaceholder className="stats-tree-panel__expand-btn-placeholder"/>
					  )
					: hasChildren && (
						<ExpandButton
							className="stats-tree-panel__expand-btn"
							label={Array.isArray(hiddenContent) ? hiddenContent.length : 1}
							isExpanded={expanded}
							onClick={handleExpandBtnClick}
						/>
					  )}

				{level !== 0 && <i className="tz-corner-arrow stats-tree-panel__corner-arrow-icon"/>}

				{!!image
					&& (loading ? (
						<CirclePlaceholder className="stats-tree-panel__image-placeholder"/>
					) : (
						<div
							className="stats-tree-panel__image"
							style={{backgroundImage: `url("${image}")`}}
						/>
					))}

				<div className="stats-tree-panel__title-block">
					<div className="stats-tree-panel__title-container">
						{loading ? (
							<TextPlaceholder
								className="stats-tree-panel__title-placeholder"
								width={(level === 0 ? 11 : 8) * title.length}
							/>
						) : (
							<div className={classNames('stats-tree-panel__title', classes.title)}>{title}</div>
						)}
						{!!subTitle
							&& (loading ? (
								<TextPlaceholder
									className="stats-tree-panel__subtitle-placeholder"
									width={(level === 0 ? 8 : 6.6) * subTitle.length}
								/>
							) : (
								<div className="stats-tree-panel__subtitle">{subTitle}</div>
							))}
					</div>
					{tag ? <div className="stats-tree-panel__tag-block">{tag}</div> : null}
				</div>
			</div>

			{permanentContent && <div className="stats-tree-panel__permanent-content">{permanentContent}</div>}

			{hiddenContent && <ExpandablePanel expanded={expanded}>{hiddenContent}</ExpandablePanel>}
		</div>
	);
};

export default StatsTreePanel;
