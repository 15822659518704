import React from 'react';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import BaseStatusSelect, {IBaseStatusSelectProps} from '../BaseStatusSelect/BaseStatusSelect';
import {getSelectedStatus} from '../BaseStatusSelect/utils/getSelectedStatus';
import {useSpaceStatusMenu} from './hooks/useSpaceStatusMenu';
import {getSpaceStatusColor} from './utils/getSpaceStatusColor';
import {ISpaceStatus} from './utils/ISpaceStatus';
import './SpaceStatusSelect.less';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {convertClassNames} from '../../../../utils/convertClassNames';
import classNames from 'classnames';

interface IStatusSelectProps extends Omit<
	IBaseStatusSelectProps, 'menu' | 'selectedStatus'
> {
	statuses?: INormalizedData<ISpaceStatus>;
	availableStatuses?: string[];
	value?: string;

	stagesMap: Record<ObjectStageIds, IObjectStage>;
	onChange?: (value: string) => void;
}

export const SpaceStatusSelect = (props: IStatusSelectProps) => {
	const {
		statuses,
		availableStatuses,
		value,
		stagesMap,
		onChange,
		className,
		...baseStatusSelectProps
	} = props;

	const classes = convertClassNames(className);

	const selectedStatus = getSelectedStatus<ISpaceStatus>(value, statuses);
	const menu = useSpaceStatusMenu<ISpaceStatus>(
		{selectedStatus, statuses, availableStatuses, stagesMap, onChange}
	);

	return (
		<BaseStatusSelect
			{...baseStatusSelectProps}
			className={{
				root: classNames('space-status-select', classes?.root),
				btn: classNames('space-status-select__btn', classes?.btn)
			}}
			selectedStatus={selectedStatus}
			menu={menu}
			color={getSpaceStatusColor(selectedStatus)}
		/>
	);
};