import React, {memo} from 'react';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useEditableWorkAcceptanceDialog} from '@src/components/EditableWorkAcceptanceDialog/hooks/useEditableWorkAcceptanceDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IActionsMobileProps {
	canAdd?: boolean;
	objectId: string;
}
const plusIcon = <i className="tz-plus-24" />;
export const ActionsMobile = memo((props: IActionsMobileProps) => {
	const {canAdd, objectId} = props;

	const [addingDialog, openAddingDialog] = useEditableWorkAcceptanceDialog(
		objectId,
		ObjectStageIds.BUILDING
	);

	const updateData = useAppUpdateMenuItem();
	const items = [updateData];

	if (canAdd) {
		items.push(
			<MenuItem
				key="add"
				icon={plusIcon}
				onClick={openAddingDialog}
			>
				Добавить приёмку работ
			</MenuItem>
		);
	}

	return (
		<>
			<IconMenu>{items}</IconMenu>
			{canAdd && addingDialog}
		</>
	);
});
