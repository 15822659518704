import {useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import {useEditSpaceIndicatorsMutation} from '@src/api/cache/spaces/mutations';
import {IEditSpaceIndicatorsParams} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {IGetSpaceResponse} from '@src/api/backend/space';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import IError from '@tehzor/tools/interfaces/IError';
import {editSpaceIndicatorsActions} from '@src/store/modules/entities/space/actions';
import {addWarningToast} from '@src/utils/toasts';
import useAppSelector from '../../useAppSelector';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {extractSpaceIndicatorsAsMap} from '@src/store/modules/dictionaries/spaceIndicatorsSets/selectors';

export interface IEditSpaceIndicatorsJournalData {
	objectName: string;
	locationName?: string;
	newIndicators: string[];
}

export const useEditSpaceIndicators = (objectId: string) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const mutation = useEditSpaceIndicatorsMutation();
	const object = useAppSelector(s => extractObject(s, objectId));
	const objectIndicators = useAppSelector(s => extractSpaceIndicatorsAsMap(s, objectId));

	return async (params: Omit<IEditSpaceIndicatorsParams, 'key'>) => {
		const {objectId, pageObjectId, spaceId, indicators} = params;
		const spaceDetailQueryKey = [...spacesQueryKeys.detail(spaceId), objectId];
		const space = queryClient.getQueryData<IGetSpaceResponse>(spaceDetailQueryKey);
		const key = Date.now().toString(10);
		const mutationCache = queryClient.getMutationCache();
		const journalData = {
			objectName: object.name,
			locationName: space?.name,
			newIndicators: indicators.length
				? indicators.map(indicator => objectIndicators.byId[indicator].name)
				: ['Все индикаторы удалены']
		};
		const currentMutation = mutationCache.find<
			ISpace,
			IError,
			Partial<IEditSpaceIndicatorsParams>,
			unknown
		>({
			predicate: mut => {
				const mutStatus = mut.state.status;
				const mutSpaceId = (mut.state.variables as IEditSpaceIndicatorsParams).spaceId;
				const newIndicators = (mut.state.variables as IEditSpaceIndicatorsParams)?.indicators;

				return !!newIndicators && mutSpaceId === spaceId && mutStatus !== ('success' || 'error');
			}
		});

		// Обновляем помещение в кеше, что бы отправить его в редакс
		const cashedSpace = queryClient.setQueryData(spaceDetailQueryKey, () => ({
			...space,
			indicators
		}));

		// Если нашли существующую мутацию (запрос), обновляем ее данные
		if (currentMutation) {
			currentMutation.setState({
				...currentMutation.state,
				isPaused: true,
				variables: {
					...currentMutation.state.variables,
					...params
				}
			});
		}
		// Если не нашли делаем запрос
		if (!currentMutation) {
			mutation.mutate({...params, key, journalData});
		}

		if (!params.online) {
			dispatch(editSpaceIndicatorsActions.success(cashedSpace as ISpace, pageObjectId));
			addWarningToast('Успех', 'индикаторы помещения сохранены локально');
		}
	};
};
