import classNames from 'classnames';
import React from 'react';
import './PieChartPlaceholder.less';

interface IPieChartPlaceholderProps {
	className?: string;
	style?: React.CSSProperties;
}

const PieChartPlaceholder = ({className, style}: IPieChartPlaceholderProps) => (
	<div
		className={classNames('pie-chart-placeholder', className)}
		style={style}
	>
		<div className="pie-chart-placeholder__aspect-ratio"/>

		<div className="pie-chart-placeholder__wrap">
			<div className="pie-chart-placeholder__circle"/>
			<div className="pie-chart-placeholder__hole"/>
		</div>
	</div>
);

export default PieChartPlaceholder;
