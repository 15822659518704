import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IDeleteCheckListResponse, makeCheckListDeleteRequest} from '@src/api/backend/checkList';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

export type IDeleteCheckListPayload = ICheckList;

const request = () => ({type: types.DELETE_REQUEST});

const success = (response: IDeleteCheckListResponse) => {
	addSuccessToast('Удалено', 'Чек-лист успешно удален');
	return {
		type: types.DELETE_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении чек-листа');
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет чек-лист
 *
 * @param checkListId id проверки
 */
export const deleteCheckList = (checkListId: string) =>
	createApiAction(request, success, failure, () => makeCheckListDeleteRequest(checkListId));
