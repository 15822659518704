import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {ActionButtons, Button, Dialog, Select2 as Select, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import React, {useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';

interface ICompanyFieldProps {
	value?: string | null;
	disabled?: boolean;
	hasError?: boolean;
	required?: boolean;
	isMobile?: boolean;

	onConfirm: (value?: string | null) => void;
	onError: () => void;
}

const getTitle = (
	companiesMap: Record<string, ICompany>,
	companyId?: string | null
) => (companyId ? companiesMap[companyId]?.name || '' : '');

const arrow = <i className="tz-simple-arrow-20"/>;

export const CompanyField = ({
	disabled, value, hasError, required, isMobile, onConfirm, onError
}: ICompanyFieldProps) => {
	const [checked, setChecked] = useState(value);
	const [isOpen, setOpen] = useState(false);

	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	useUpdateEffect(() => setChecked(value), [value]);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const {companies} = useAppSelector(extractUserProfile);
	const companiesMap = useAppSelector(s => s.dictionaries.companies.byId);

	const isSearchShown = useMemo(() => (companies?.length || 0) >= 8, [companies]);

	const filteredCompanies = useMemo(() =>
		(companies ? flatFilter(companies, 'name', search) : undefined),
	[companies, search]);

	const handleCancel = useCallback(() => {
		setChecked(value);
		clearSearch();
	}, [value, clearSearch]);

	const handleConfirm = useCallback(() => {
		onConfirm(checked);
		if (required) {
			onError();
		}
		clearSearch();
	}, [onConfirm, checked, required, onError, clearSearch]);

	const handleClearButton = useCallback(() => {
		onConfirm(null);
		if (required) {
			onError();
		}
		clearSearch();
	}, [onConfirm, required, onError, clearSearch]);

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			value={getTitle(companiesMap, value)}
			icon={arrow}
			error={hasError ? 'Выберите компанию' : undefined}
			disabled={disabled || !companies?.length}
			onClearClick={handleClearButton}
			onClick={isMobile ? open : undefined}
			cleanable={!!value}
		/>
	);

	const content = (
		<Select
			value={checked || undefined}
			onChange={setChecked}
		>
			{filteredCompanies?.map(company => (
				<SelectOption
					key={company.id}
					itemKey={company.id}
					content={company.name}
					inputType="radio"
				/>
			))}
		</Select>
	);

	const selectSearch = isSearchShown ? (
		<SelectSearch
			value={search}
			onChange={setSearch}
			onClear={clearSearch}
		/>
	) : null;

	return (
		<div className="editable-space-statuses-set__fields-company">
			{!isMobile ? (
				<SelectPopup
					search={selectSearch}
					footer
					onCancel={handleCancel}
					onApply={handleConfirm}
					trigger={trigger}
				>
					{content}
				</SelectPopup>
			) : trigger}
			{isMobile && (
				<Dialog
					isOpen={isOpen}
					className="editable-space-statuses-set__fields-company__dialog"
					title="Автоматическая смена статуса на:"
					onRequestClose={close}
					footer={(
						<div className="editable-space-statuses-set__fields-company__dialog">
							<ActionButtons
								className="editable-space-statuses-set__fields-company__dialog-btns"
							>
								<Button
									type="cancel"
									label="Отменить"
									onClick={handleCancel}
								/>
								<Button
									type="accent-blue"
									label="Сохранить"
									onClick={handleConfirm}
								/>
							</ActionButtons>
						</div>
					)}
				>
					{selectSearch}
					{content}
				</Dialog>
			)}
		</div>
	);
};