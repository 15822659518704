import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {Tree} from 'array-to-tree';
import {findResponsible} from './findResponsible';

export interface ISuggestions {
	[groupId: string]: string[];
}

/**
 * Возвращает предложения по отобранным с помощью правил группам и пользователям
 *
 * @param groups рабочие группы, предварительно отфильтрованные по текущему объекту, стадии, области
 * @param rules правила ответственности
 * @param categoryId id вида работ
 * @param planId id плана
 * @param structureIds Id структуры
 * @return возвращает:
 * 		объект с доступными для ручного выбора группами и пользователями
 * 		(ключи - id групп, значения - массивы пользователей);
 * 		выбранную активную группу;
 * 		выбранных пользователей.
 */
export const getSuggestionsByRules = (
	groups: Array<Tree<IWorkingGroup>>,
	rules: IResponsibilityRule[],
	categoryId?: string | null,
	planId?: string,
	structureIds?: string[]
): [ISuggestions, string | undefined, string[]] => {
	const params = {categoryId, planId, structureIds};

	const [suggestions, selectedGroups, selectedUsers] = findResponsible(groups, rules, params);

	if (selectedGroups.size === 1) {
		return [
			suggestions,
			selectedGroups.values().next().value as string,
			Array.from(selectedUsers.values())
		];
	}

	return [suggestions, undefined, []];
};
