import {wsConnector} from '../wsConnector';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';

export type IEditProblemTagResponse = IProblemTag;

/**
 * Обновляет одну метку
 *
 * @param tagId id метки
 * @param setId id набора
 * @param tag данные для изменения
 */
export const makeProblemTagEditRequest = (
	tagId: string,
	setId: string,
	tag: ISavingProblemTag
) =>
	wsConnector.sendAuthorizedRequest<IEditProblemTagResponse>('editProblemTag', {
		tagId,
		problemTagsSetId: setId,
		...tag
	});
