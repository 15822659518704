import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {StatusSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editTaskStatus} from '@src/store/modules/pages/task/actions/editTaskStatus';
import {getTasks} from '@src/store/modules/entities/tasks/actions';
import {useAsyncFn} from 'react-use';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

export const TaskStatusChangeableCell = (props: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'value'>) => {
	const {value} = props;
	const task = value;

	const statuses = useAppSelector(s => s.dictionaries.taskStatuses);
	const dispatch = useAppDispatch();
	const permissions = useTasksPermissions(task?.objectId?.id);

	const [, handleChange] = useAsyncFn(
		async (statusId: TaskStatusId) => {
			await dispatch(editTaskStatus(task.id, statusId));
			await dispatch(getTasks());
		},
		[task]
	);

	const availableStatuses = Object.values(TaskStatusId);

	return (
		<ClickPreventWrap>
			<StatusSelect
				className="tasks-page_status"
				statuses={statuses}
				availableStatuses={availableStatuses}
				disabled={!permissions.canEditStatus}
				value={task?.status?.id}
				onChange={handleChange}
				style={{maxWidth: '100%'}}
			/>
		</ClickPreventWrap>
	);
};
