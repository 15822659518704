import {useToggle} from 'react-use';
import declination from '@tehzor/tools/utils/declination';
import {ExpandablePanel} from '@tehzor/ui-components';
import {CheckListItem} from '@src/pages/CheckListPage/components/list/CheckListItems/components/CheckListItem';
import React, {useEffect} from 'react';
import {Tree} from 'array-to-tree';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface IAcceptedItemsProps {
	items: Array<Tree<ICheckItem>>;

	onItemClick: (item: ICheckItem) => void;
}

const hasActiveItem = (items: Array<Tree<ICheckItem>>, itemId: string | undefined) =>
	items.some(item => item.id === itemId);

export const AcceptedItems = ({items, onItemClick}: IAcceptedItemsProps) => {
	const {itemId} = useStrictParams<{itemId?: string}>();
	const [isExpanded, toggle] = useToggle(false);

	useEffect(() => {
		if (hasActiveItem(items, itemId)) {
			toggle(true);
		}
	}, [itemId, items]);

	return (
		<>
			<div
				className="check-list-items__accepted check-list-items__item_clickable"
				onClick={toggle}
			>
				<div className="check-list-items__item-header">
					<div className="check-list-items__item-header-bullet">
						<div className="check-list-items__item-header-bullet-circle">
							<i className="tz-check-mark"/>
						</div>
					</div>
					<div className="check-list-items__item-header-title">Принятые категории</div>
					<div className="check-list-items__item-header-subtitle">
						{items.length}
						{' '}
						{declination(items.length, ['категория', 'категории', 'категорий'])}
					</div>
					<div className="check-list-items__item-header-actions">
						<i
							className="tz-simple-arrow-28"
							style={{
								display: 'inline-block',
								transform: `rotate(${isExpanded ? 180 : 0}deg)`
							}}
						/>
					</div>
				</div>
			</div>

			<ExpandablePanel expanded={isExpanded}>
				{items.map(item => (
					<CheckListItem
						mode="main"
						key={item.id}
						item={item}
						onClick={onItemClick}
					/>
				))}
			</ExpandablePanel>
		</>
	);
};
