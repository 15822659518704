import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as inspectionTypes from '@src/store/modules/entities/inspection/constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import {IDeleteInspectionPayload} from '@src/store/modules/entities/inspection/actions';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {
	IGetCheckInspectionsPayload,
	IGetCheckPayload,
	IGetCheckProblemsPayload
} from '@src/store/modules/entities/check/actions';
import {
	IAddProblemPayload,
	IDeleteProblemPayload,
	IEditProblemStatusPayload
} from '@src/store/modules/pages/problem/actions';
import {IAddInspectionPayload} from '@src/store/modules/entities/inspection/actions/add';
import {IGetCheckProblemRepliesPayload} from './actions/problemReplies';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {IAddProblemCommentPayload} from '@src/store/modules/entities/problemComment/actions';
import {IMoveProblemsPayload} from '../../pages/problem/actions/move';
import {IEditCheckPayload} from './actions/edit';
import {IMoveCheckPayload} from './actions/move';

const {entitiesCacheTime} = config;

export interface ICheckState {
	data?: ILinkedCheck;
	expires?: number;
	problems: {
		byId: Record<string, IProblem>;
		allIds: string[];
		expires?: number;
	};
	inspections: {
		byId: Record<string, IInspection>;
		allIds: string[];
		expires?: number;
	};
	problemReplies: {
		byId: Record<string, IProblemComment>;
		allIds: string[];
		expires?: number;
	};
}

export const getInitialState = (): ICheckState => ({
	problems: {
		byId: {},
		allIds: []
	},
	inspections: {
		byId: {},
		allIds: []
	},
	problemReplies: {
		byId: {},
		allIds: []
	}
});

const updateCheck = (state: ICheckState, {payload}: {payload: IEditCheckPayload}) =>
	(state.data?.id !== payload.id
		? state
		: {
				...state,
				data: payload,
				expires: Date.now() + entitiesCacheTime * 1000
		  });

export default createReducer<ICheckState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetCheckPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_SUCCESS]: updateCheck,
	[types.MOVE_SUCCESS]: (state, {payload}: {payload: IMoveCheckPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Загрузка сущностей
	[types.GET_PROBLEMS_SUCCESS]: (state, {payload}: {payload: IGetCheckProblemsPayload}) => {
		state.problems.byId = payload.byId;
		state.problems.allIds = payload.allIds;
		state.problems.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_INSPECTIONS_SUCCESS]: (state, {payload}: {payload: IGetCheckInspectionsPayload}) => {
		state.inspections.byId = payload.byId;
		state.inspections.allIds = payload.allIds;
		state.inspections.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_PROBLEM_REPLIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetCheckProblemRepliesPayload}
	) => {
		state.problemReplies.byId = payload.byId;
		state.problemReplies.allIds = payload.allIds;
		state.problemReplies.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Нарушения
	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
		if (state.data?.id === payload.links?.checkId) {
			state.problems.byId[payload.id] = payload;
			state.problems.allIds.unshift(payload.id);
		}
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemStatusPayload}
	) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state.problems.allIds.includes(payload.problemId)) {
			state.problems.allIds = state.problems.allIds.filter(id => id !== payload.problemId);
			delete state.problems.byId[payload.problemId];
		}
	},
	[problemTypes.MOVE_SUCCESS]: (state, {payload}: {payload: IMoveProblemsPayload}) => {
		if (state.problems.allIds.includes(payload.problemId)) {
			state.problems.allIds = state.problems.allIds.filter(id => id !== payload.problemId);
			delete state.problems.byId[payload.problemId];
		}
	},
	// Осмотры
	[inspectionTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddInspectionPayload}) => {
		if (state.data?.id === payload.links?.checkId) {
			state.inspections.byId[payload.id] = payload;
			state.inspections.allIds.unshift(payload.id);
		}
	},
	[inspectionTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteInspectionPayload}) => {
		if (state.inspections.allIds.includes(payload.inspectionId)) {
			state.inspections.allIds = state.inspections.allIds.filter(
				id => id !== payload.inspectionId
			);
			delete state.inspections.byId[payload.inspectionId];
		}
	},
	// Ответы на нарушения
	[problemReplyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
		if (state.data?.id === payload.links?.checkId) {
			state.problemReplies.byId[payload.id] = payload;
			state.problemReplies.allIds.unshift(payload.id);
		}
	},
	[CLEAR_STORE]: getInitialState
});
