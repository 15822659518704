import React from 'react';
import './ChecksPage.less';
import {LoadingPanel, TabContent, Tabs} from '@tehzor/ui-components';
import classNames from 'classnames';
import ChecksTable from './components/table/ChecksTable';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsync from 'react-use/lib/useAsync';
import {getChecks} from '@src/store/modules/entities/checks/actions';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import ActionsDesktop from './components/actions/Actions.desktop';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import MobileEntityAdding from './components/actions/EntityAdding.mobile';
import {useParams} from 'react-router-dom';

interface IChecksPageProps {
	className?: string;
}

const ChecksPage = ({className}: IChecksPageProps) => {
	useScrollRestoration();
	const {state} = useEntitiesFiltersCtx();
	const {objectId} = useParams<{objectId?: string}>();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const loadingState = useAsync(async () => {
		if (networkStatus) {
			await dispatch(getChecks(objectId || 'all', state));
		}
	}, [objectId, networkStatus, state]);
	const actions = <ActionsDesktop objectId={objectId}/>;

	return (
		<LoadingPanel
			className="checks-page__loading-panel"
			active={loadingState.loading}
		>
			<div
				className={classNames(
					'page-cont',
					'checks-page',
					{'checks-page_small-margin': !objectId},
					className
				)}
			>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}>
						{isDesktop && actions}
					</ObjectPageBreadcrumbs>
				) : (
					isDesktop && <AllPageBreadcrumbs>{actions}</AllPageBreadcrumbs>
				)}

				<Tabs className={{links: 'checks-page__tabs-links'}}>
					<TabContent>
						<ChecksTable objectId={objectId}/>
					</TabContent>
				</Tabs>
				{objectId && !isDesktop && <MobileEntityAdding objectId={objectId}/>}
			</div>
		</LoadingPanel>
	);
};

export default ChecksPage;
