import format from 'date-fns/format';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';

interface IItem {
	id?: string;
	content?: string | number | {};
	name?: string;
	fullName?: string;
	uuid?: string;
}

const formatDate = (date: Date) => format(date, dateFormat);

/**
 * Формирует текст для кнопки фильтра
 *
 * @param str текст по умолчанию
 * @param arr массив фильтров
 * @param arr2 массив всех возможных значений
 * @param hideTitle параметр текста
 * @param isUUID если id компании UUID
 */
export function makeFilterLabel<T extends IItem>(
	str: string,
	arr: string[] | undefined,
	arr2: T[],
	hideTitle?: boolean,
	isUUID?: boolean
) {
	const findFirstName = (array: string[], array2: T[]) => {
		if (isUUID) {
			return array2.find((item: T) => item.uuid === array[0]);
		}

		return array2.find((item: T) => item.id === array[0]);
	};

	const getName = (item: T | undefined) => {
		if (item) {
			if (item.name) {
				return item.name;
			}
			if (item.fullName) {
				return item.fullName;
			}
			if (item.content) {
				return item.content;
			}
		}

		return '';
	};

	if (arr !== undefined && arr.length !== 0) {
		if (arr.length === 1) {
			if (hideTitle) {
				return `${getName(findFirstName(arr, arr2))}`;
			}
			return `${str}: ${getName(findFirstName(arr, arr2))}`;
		}
		if (hideTitle) {
			return `${getName(findFirstName(arr, arr2))} + ${arr.length - 1}`;
		}
		return `${str}: ${getName(findFirstName(arr, arr2))} + ${arr.length - 1}`;
	}
	return hideTitle ? undefined : str;
}

/**
 * Формирует текст для кнопки фильтра по датам
 *
 * @param str текст по умолчанию
 * @param from дата от
 * @param to дата до
 */
export function makeDateFilterLabel(str?: string, from?: Date, to?: Date) {
	if (from !== undefined && to !== undefined) {
		if (!str) {
			return `${formatDate(new Date(from))} - ${formatDate(new Date(to))}`;
		}
		return `${str}: ${formatDate(new Date(from))} - ${formatDate(new Date(to))}`;
	}
	return str;
}
