import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {
	IGetOwnerAcceptanceResponse,
	requestOwnerAcceptance
} from '@src/api/backend/ownerAcceptance';

export interface IGetOwnerAcptRequestPayload {
	acceptanceId: string;
}

export type IGetOwnerAcceptancePayload = IGetOwnerAcceptanceResponse;

const request = (acceptanceId: string) => ({type: types.GET_REQUEST, payload: {acceptanceId}});

const success = (response: IGetOwnerAcceptanceResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке передачи собственнику');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает передачу собственнику по id
 *
 * @param objectId id объекта
 * @param acceptanceId id передачи
 */
export const getOwnerAcceptance
	= (
		objectId: string,
		acceptanceId: string
	): AppThunkAction<Promise<IGetOwnerAcceptanceResponse>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<IGetOwnerAcceptanceResponse>(
			() => request(acceptanceId),
			success,
			failure,
			() => requestOwnerAcceptance(objectId, acceptanceId)
		);

		return state.entities.ownerAcceptance.data?.id === acceptanceId
			? dispatch(
					checkExpiration<IState, IGetOwnerAcceptanceResponse, ApiAction>(
						s => s.entities.ownerAcceptance,
						apiAction
					)
			  )
			: dispatch(apiAction);
	};
