import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IContractForm} from '@tehzor/tools/interfaces/spaces/IContractForm';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IContractFormsState = ILoadableEntitiesState<IContractForm>;

export const contractForms = createReducer<IContractFormsState>(
	getLoadableEntitiesInitialState<IContractForm>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IContractForm>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IContractForm>()
	}
);
