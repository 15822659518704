import React from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';

const CompaniesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const companies = useAppSelector(extractCompaniesAsArray);
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={companies}
			label="Компании"
			filterName="companies"
		/>
	);
};

export default CompaniesFilterPage;
