import ILocation from '@tehzor/tools/interfaces/ILocation';
import * as types from '../constants';

export interface IUpdateProblemLocation {
	objectId: string;
	problemId: string;
	location: ILocation | undefined | null;
}

/**
 * Обновляет location у определенного нарушения
 */
export const updateProblemLocation = (
	objectId: string,
	problemId: string,
	location: ILocation | undefined | null
) => ({
	type: types.UPDATE_PROBLEM_LOCATION,
	payload: {
		objectId,
		problemId,
		location
	}
});