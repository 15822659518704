import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {Tree} from 'array-to-tree';
import {ISuggestions} from '../utils/getSuggestionsByRules';
import {extractCategories} from '@src/store/modules/dictionaries/categories/selectors';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {extractPlans} from '@src/store/modules/dictionaries/plans/selectors';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {sortBy} from 'lodash';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {extractStructuresListData} from '@src/store/modules/entities/structures/selectors/lists';
import {flatFilterMultiple} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

const enrichRules = (
	rules: IResponsibilityRule[],
	categories: INormalizedData<ICategory>,
	plans: INormalizedData<IPlan>,
	structures: INormalizedData<IStructure>
) =>
	rules.reduce<GroupedRules>((prev, rule) => {
		if (!prev[rule.groupId]) {
			prev[rule.groupId] = [];
		}
		prev[rule.groupId].push({
			target: rule.target,
			categories: rule.categories?.reduce<string[]>((p, id) => {
				if (categories.byId[id]) {
					p.push(categories.byId[id].name);
				}
				return p;
			}, []),
			plans: rule.plans?.reduce<string[]>((p, id) => {
				if (plans.byId[id]) {
					p.push(plans.byId[id].name);
				}
				return p;
			}, []),
			structures: rule.structureIds?.reduce<string[]>((p, id) => {
				if (structures.byId[id]) {
					p.push(structures.byId[id].name);
				}
				return p;
			}, [])
		});
		return prev;
	}, {});

const filterBySuggestions = (groups: Array<Tree<IWorkingGroup>>, suggestions: ISuggestions) =>
	groups.reduce<Array<Tree<IWorkingGroup>>>((prev, group) => {
		if (suggestions[group.id]) {
			const {performers, children, ...rest} = group;
			prev.push({
				...rest,
				performers: suggestions[group.id],
				children: children ? filterBySuggestions(children, suggestions) : undefined
			});
		}
		return prev;
	}, []);

type GroupedRules = Record<
	string,
	Array<{
		target: IResponsibilityRule['target'];
		categories?: string[];
		plans?: string[];
		structures?: string[];
	}>
>;

const enrichGroups = (
	groups: Array<Tree<IWorkingGroup>>,
	rules: GroupedRules,
	users: INormalizedData<IBriefUser>
): IEnrichedWorkingGroup[] =>
	groups.map(group => {
		const {
			stages,
			type,
			leader,
			performers,
			createdAt,
			createdBy,
			modifiedAt,
			modifiedBy,
			children,
			...rest
		} = group;

		return {
			...rest,
			leader: users.byId[leader],
			performers: sortBy(
				performers?.map(userId => users.byId[userId]).filter(user => user),
				['displayName'],
				['asc']
			),
			rules: rules[group.id],
			children: children ? enrichGroups(children, rules, users) : undefined
		};
	});

/**
 * Фильтрует рабочие группы ответственных по поисковому запросу
 *
 * @param data рабочие группы ответственных (обогащенные данные)
 * @param searchValue поисковой запрос
 */
const filterBySearch = (
	data: IEnrichedWorkingGroup[],
	searchValue: string
): IEnrichedWorkingGroup[] => {
	const checkFilterByName = (item: string, searchValue: string) => {
		const upperContent = item.toUpperCase();
		const upperSearch = searchValue.toUpperCase();

		return searchValue === '' || upperContent.includes(upperSearch);
	};

	return data.reduce<IEnrichedWorkingGroup[]>((prev, groupItem) => {
		// Поиск по названию рабочей группы
		const isSearchedByName = groupItem.name
			? checkFilterByName(groupItem.name, searchValue)
			: false;

		// Поиск по руководителю рабочей группы
		const isSearchedByLeaderName = groupItem.leader?.displayName
			? checkFilterByName(groupItem.leader?.displayName, searchValue)
			: false;

		// Поиск по дочерним рабочим группам
		if (groupItem.children?.length) {
			groupItem.children = filterBySearch(groupItem.children, searchValue);
		}

		// Поиск по ответственным лицам рабочей группы
		if (groupItem.performers?.length) {
			groupItem.performers = flatFilterMultiple(
				groupItem.performers,
				['displayName', 'fullName'],
				searchValue
			);
		}

		if (
			isSearchedByName ||
			isSearchedByLeaderName ||
			groupItem.children?.length ||
			groupItem.performers?.length
		) {
			prev.push(groupItem);
		}

		return prev;
	}, []);
};

export function useEnrichedGroups(
	objectId: string,
	groups: Array<Tree<IWorkingGroup>>,
	rules: IResponsibilityRule[],
	suggestions: ISuggestions,
	activeGroup?: string,
	useSuggestions?: boolean,
	searchValue?: string
) {
	const users = useAppSelector(s => s.dictionaries.users);
	const categories = useAppSelector(extractCategories);
	const plans = useAppSelector(s => extractPlans(s, objectId));
	const structures = useAppSelector(s => extractStructuresListData(s, objectId));

	return useMemo(() => {
		const filteredRules = activeGroup
			? rules.filter(rule => rule.groupId === activeGroup)
			: rules;
		const rulesByGroup = enrichRules(filteredRules, categories, plans, structures);

		const filteredGroups = useSuggestions ? filterBySuggestions(groups, suggestions) : groups;

		const enrichedGroupsData = enrichGroups(filteredGroups, rulesByGroup, users);

		return searchValue ? filterBySearch(enrichedGroupsData, searchValue) : enrichedGroupsData;
	}, [
		activeGroup,
		suggestions,
		groups,
		rules,
		useSuggestions,
		users,
		categories,
		plans,
		structures,
		searchValue
	]);
}
