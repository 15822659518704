import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import useAppSelector from '@src/core/hooks/useAppSelector';

// TODO Заменить на ISpace после внесения стадии в помещение
const SpaceStageCell = ({row}: CellProps<IEnrichedSpace>) => {
	const space = row.original;
	const stages = useAppSelector(s => s.dictionaries.objectStages);

	return <>{(space.status?.stage && stages.byId[space.status.stage]?.name) ?? ''}</>;
};

export default SpaceStageCell;
