import {Plate} from '@tehzor/ui-components';
import React, {memo} from 'react';
import {CompanyBlock} from './blocks/CompanyBlock';
import {ContractorBlock} from './blocks/ContractorBlock';
import {MainBlock} from './blocks/MainBlock';
import {DatesBlock} from './blocks/DatesBlock';
import {PriceBlock} from './blocks/PriceBlock';
import {IPreparedContract} from '../interfaces/IPreparedContract';

interface IContractMainProps {
	contract: IPreparedContract;
}

export const ContractMain = memo((props: IContractMainProps) => (
	<Plate className={{content: 'contract__blocks'}}>
		<CompanyBlock {...props}/>
		<ContractorBlock {...props}/>
		<MainBlock {...props}/>
		<DatesBlock {...props}/>
		<PriceBlock {...props}/>
	</Plate>
));