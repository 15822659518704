import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {
	IEditTaskResponsibleResponse,
	makeTaskResponsibleEditRequest
} from '@src/api/backend/task/editResponsible';
import {queryClient} from '@src/api/QueryClient';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';

export type IEditTaskResponsiblePayload = IEditTaskResponsibleResponse;

const request = () => ({type: types.EDIT_RESPONSIBLE_REQUEST});

const success = (response: IEditTaskResponsibleResponse) => ({
	type: types.EDIT_RESPONSIBLE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении ответственных');
	return {
		type: types.EDIT_RESPONSIBLE_FAILURE,
		payload: error
	};
};

/**
 * Изменяет ответственных задачи
 *
 * @param objectId id объекта
 * @param taskId id задачи
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const editTaskResponsible = (
	objectId: string,
	taskId: string,
	respUsers?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditTaskResponsibleResponse>(request, success, failure, async () => {
		const data = await makeTaskResponsibleEditRequest(objectId, taskId, respUsers, activeGroup);
		await queryClient.invalidateQueries(tasksQueryKeys.all());
		return data;
	});
