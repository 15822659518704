import React, {useCallback} from 'react';
import {Button} from '@tehzor/ui-components';
import {useSpacesExport} from '@src/core/hooks/export/useSpacesExport';

interface ISpacesExportBProps {
	objectId: string;
}

const DesktopSpacesExportBtn = ({objectId}: ISpacesExportBProps) => {
	const [exportDialog, openExportDialog] = useSpacesExport(objectId);
	const handleExport = useCallback(() => {
		openExportDialog(objectId);
	}, [objectId, openExportDialog]);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-export-20"/>}
				label="Экспорт"
				onClick={handleExport}
			/>
			{exportDialog}
		</>
	);
};

export default DesktopSpacesExportBtn;
