import {
	IEditableCheckListAction,
	IEditableCheckListState
} from '@src/core/hooks/states/useEditableCheckListState';
import React, {Dispatch, useCallback, useMemo} from 'react';
import arrayToTree from 'array-to-tree';
import {generateId, ItemsLeaf} from '@src/components/EditableCheckList/components/ItemsLeaf';
import {LinkButton} from '@tehzor/ui-components';
import './EditableCheckItems.less';

interface IEditableCheckItemsProps {
	editingState: IEditableCheckListState;
	editingDispatch: Dispatch<IEditableCheckListAction>;
}

export const EditableCheckItems = ({editingState, editingDispatch}: IEditableCheckItemsProps) => {
	const itemsTree = useMemo(
		() =>
			arrayToTree(editingState.items || [], {
				parentProperty: 'parentId',
				customID: 'id'
			}),
		[editingState.items]
	);

	const handleAdd = useCallback(() => {
		editingDispatch({
			type: 'add-in-array',
			field: 'items',
			value: [
				{
					id: generateId(),
					name: '',
					description: '',
					parentId: undefined,
					dependencies: []
				}
			]
		});
	}, []);

	return (
		<div className="editable-check-items">
			<div className="editable-check-items__header">
				<h3>Категории</h3>
				<div className="editable-check-items__header-actions">
					<LinkButton
						label="Категория"
						onClick={handleAdd}
						leftIcon={<i className="tz-plus-20"/>}
					/>
				</div>
			</div>
			<div className="editable-check-items__body">
				{itemsTree.map(itemsLeaf => (
					<ItemsLeaf
						key={itemsLeaf.id}
						editingState={editingState}
						editingDispatch={editingDispatch}
						itemsLeaf={itemsLeaf}
					/>
				))}
			</div>
		</div>
	);
};
