import {IEditSpaceStatusResponse, requestEditSpaceStatus} from '@src/api/backend/spaceStatuses/edit';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import * as types from '../constants';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IEditSpaceStatusPayload = IEditSpaceStatusResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (spaceStatus: ISpaceStatus) => ({
	type: types.EDIT_SUCCESS,
	payload: {...spaceStatus} as IEditSpaceStatusPayload
});

const failure = (error: IError) => ({
	type: types.EDIT_FAILURE,
	payload: error
});

export const editSpaceStatus = (
	spaceStatusId: string,
	fields: ISavingSpaceStatus
) =>
	createApiAction(
		request, success, failure,
		() => {
			void queryClient.invalidateQueries(spaceStatusesQueryKeys.list());
			void queryClient.invalidateQueries(spacesQueryKeys.list());

			return requestEditSpaceStatus(spaceStatusId, fields);
		}
	);