import React, {useState} from 'react';
import {Button, TabContent, TabLink, Tabs} from '@tehzor/ui-components';
import {ProblemsTable} from '@src/pages/CheckListPage/components/detail/ProblemsTable';
import {TasksTable} from '@src/pages/CheckListPage/components/detail/TasksTable';
import {useAddingCheckListTaskDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListTaskDialog';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface ICheckListDetailTabsProps {
	list?: ICheckList;
	permissions: {
		canAddTask?: boolean;
        canAddProblem?: boolean;
	};
}
const iconPlus = <i className="tz-plus-16"/>;
export const CheckListDetailTabs = ({list, permissions}: ICheckListDetailTabsProps) => {
	const [activeTab, setActiveTab] = useState(0);
	const [taskDialog, handleOpenTaskDialog] = useAddingCheckListTaskDialog();

	return (
		<div className="check-list-detail__tabs">
			<Tabs
				className={{
					links: 'check-list-detail__tabs-links',
					link: 'check-list-detail__tabs-link'
				}}
				activeTab={activeTab}
				links={[
					<TabLink
						key="problems"
						label="Нарушения"
					/>,
					<TabLink
						key="tasks"
						label="Задачи"
					/>
				]}
				onActiveTabChange={setActiveTab}
			>
				<TabContent>
					<div className="check-list-detail__items">
						<ProblemsTable list={list}/>
					</div>
				</TabContent>
				<TabContent>
					<div className="check-list-detail__items">
						<TasksTable list={list}/>
						{permissions.canAddTask && taskDialog}
					</div>
				</TabContent>
			</Tabs>

			{permissions.canAddTask && activeTab === 1 && (
				<div className="check-list-detail__action">
					<Button
						className="check-list-detail__action-btn"
						label="Задача"
						type="accent-blue"
						leftIcon={iconPlus}
						onClick={handleOpenTaskDialog}
					/>
				</div>
			)}
		</div>
    );
};
