import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {
	IGetOwnerAcceptancePayload,
	IEditOwnerAcptPayload,
	IGetOwnerAcptRequestPayload,
	IDeleteOwnerAcptPayload,
	IGetOwnerAcptProblemsPayload,
	IEditOwnerAcceptanceStatusPayload,
	IGetOwnerAcceptanceProblemRepliesPayload
} from './actions';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import config from '@src/core/config';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {
	IAddProblemPayload,
	IDeleteProblemPayload,
	IEditProblemStatusPayload
} from '@src/store/modules/pages/problem/actions';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

const {entitiesCacheTime} = config;

export interface IOwnerAcceptanceState {
	data?: ILinkedOwnerAcceptance;
	expires?: number;
	problems: {
		byId: Record<string, IProblem>;
		allIds: string[];
		expires?: number;
	};
	problemReplies: {
		byId: Record<string, IProblemComment>;
		allIds: string[];
		expires?: number;
	};
}

const getInitialState = (): IOwnerAcceptanceState => ({
	problems: {
		byId: {},
		allIds: []
	},
	problemReplies: {
		byId: {},
		allIds: []
	}
});

const updateAcceptance = (
	state: IOwnerAcceptanceState,
	{payload}: {payload: IEditOwnerAcptPayload}
) =>
	(state.data?.id !== payload.id
		? state
		: {
				...state,
				data: payload,
				expires: Date.now() + entitiesCacheTime * 1000
		  });
export default createReducer<IOwnerAcceptanceState>(getInitialState(), {
	[types.GET_REQUEST]: (state, {payload}: {payload: IGetOwnerAcptRequestPayload}) => {
		if (state.data?.id === payload.acceptanceId) {
			return {...state, data: undefined};
		}
		return getInitialState();
	},
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetOwnerAcceptancePayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_FAILURE]: getInitialState,
	[types.EDIT_SUCCESS]: updateAcceptance,
	[types.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditOwnerAcceptanceStatusPayload}
	) => {
		state.data = {...state.data, ...payload};
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteOwnerAcptPayload}) => {
		if (!(state.data?.id === payload.acceptanceId)) {
			return state;
		}
		return getInitialState();
	},
	[types.GET_PROBLEMS_SUCCESS]: (state, {payload}: {payload: IGetOwnerAcptProblemsPayload}) => {
		state.problems.byId = payload.byId;
		state.problems.allIds = payload.allIds;
		state.problems.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_PROBLEM_REPLIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetOwnerAcceptanceProblemRepliesPayload}
	) => {
		state.problemReplies.byId = payload.byId;
		state.problemReplies.allIds = payload.allIds;
		state.problemReplies.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Нарушения
	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
		if (state.data?.id === payload.links?.ownerAcceptanceId) {
			state.problems.byId[payload.id] = payload;
			state.problems.allIds.unshift(payload.id);
		}
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemStatusPayload}
	) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state.problems.allIds.includes(payload.problemId)) {
			state.problems.allIds = state.problems.allIds.filter(id => id !== payload.problemId);
			delete state.problems.byId[payload.problemId];
		}
	},
	[CLEAR_STORE]: getInitialState
});
