import React, {useCallback} from 'react';
import {
	DesktopHeader,
	DesktopHeaderMenu,
	MenuItem,
	MenuSeparator,
	NotificationsButton,
	UserMenu
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import {useLocation} from 'react-router-dom';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleNotificationsVisible} from '@src/actions/settings/notifications';
import {OfflineModeButton} from '@src/components/OfflineModeButton';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';

interface IDesktopAppHeaderProps {
	title?: string;
	backBtnVisible?: boolean;
	sectionsMenu?: IHeaderSectionsMenuItem[];
}

const DesktopAppHeader = ({title, backBtnVisible, sectionsMenu}: IDesktopAppHeaderProps) => {
	const location = useLocation();
	const {goBack, replacePath, pushPath} = useChangePath();
	const user = useAppSelector(s => s.auth.profile);
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const notificationsCount = useAppSelector(s => s.notifications.messages.total);
	const dispatch = useAppDispatch();

	const goToMainPage = () => {
		if (location.pathname !== '/') {
			pushPath('/');
		}
	};

	const goToProfile = () => {
		if (location.pathname !== '/profile') {
			pushPath('/profile');
		}
	};

	const [exitDialog, getExitConfirmation] = useConfirmDialog(
		'Вы действительно хотите выйти?',
		undefined,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const toggleNotifications = useCallback(() => {
		dispatch(toggleNotificationsVisible());
	}, []);

	const logout = useCallback(async () => {
		if (await getExitConfirmation()) {
			replacePath('/logout');
		}
	}, []);

	const updateData = useAppUpdateMenuItem();

	return (
		<>
			<DesktopHeader
				className="app-header"
				title={title}
				sectionsMenu={
					!!sectionsMenu && (
						<DesktopHeaderMenu
							items={sectionsMenu}
							value={location.pathname}
							onChange={replacePath}
						/>
				)
				}
				indicators={(
					<>
						{isOfflineModeAvailable && <OfflineModeButton/>}
						{user.activated && (
							<NotificationsButton
								count={notificationsCount}
								onClick={toggleNotifications}
							/>
						)}
					</>
				)}
				userMenu={(
					<UserMenu
						user={user}
						avatarSize="34"
					>
						<MenuItem
							icon={<i className="tz-user-24"/>}
							onClick={goToProfile}
						>
							Перейти в профиль
						</MenuItem>
						{updateData}
						<MenuSeparator/>
						<MenuItem
							icon={<i className="tz-exit"/>}
							onClick={logout}
						>
							Выйти
						</MenuItem>
					</UserMenu>
				)}
				onLogoClick={goToMainPage}
				onBackBtnClick={backBtnVisible ? goBack : undefined}
			/>

			{exitDialog}
		</>
	);
};

export default DesktopAppHeader;
