import {createReducer} from '@reduxjs/toolkit';
import * as listTypes from './constants';
import * as detailTypes from '../workAcceptance/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {CHANGE_SORT, CLEAR_FILTERS} from '../../settings/pages/workAcceptances/constants';
import config from '@src/core/config';
import {IGetWorkAcceptancesPayload} from '@src/store/modules/entities/workAcceptances/actions';
import {IDeleteWorkAcceptancePayload} from '@src/store/modules/entities/workAcceptance/actions';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

const {entitiesCacheTime} = config;

export interface IWorkAcceptancesPageState {
	byId: {
		[id: string]: IListWorkAcceptance;
	};
	allIds: string[];
	offset: number;
	total: number;
	expires?: number;
	selectedRows: string[];
	isAllRowsSelected: boolean;
}

export interface IWorkAcceptancesPagesState {
	[objectId: string]: IWorkAcceptancesPageState;
}

export const getInitialStateForPage = (): IWorkAcceptancesPageState => ({
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	expires: undefined,
	selectedRows: [],
	isAllRowsSelected: false
});

const clearPage = (state: IWorkAcceptancesPagesState, {payload}: {payload: {objectId: string}}) => {
	if (payload.objectId !== 'all') {
		state[payload.objectId] = getInitialStateForPage();
	}
	state.all = getInitialStateForPage();
};

const deleteWorkAcceptance = (
	state: IWorkAcceptancesPagesState,
	objectId: string,
	workAcceptanceId: string
) => {
	const page = state[objectId];
	page.allIds = page.allIds.filter(id => id !== workAcceptanceId);
	delete page.byId[workAcceptanceId];
	page.expires = undefined;
};

export const workAcceptances = createReducer<IWorkAcceptancesPagesState>(
	{},
	{
		[listTypes.GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[listTypes.GET_SUCCESS]: (state, {payload}: {payload: IGetWorkAcceptancesPayload}) => {
			const page = state[payload.objectId];
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.offset = payload.offset;
			page.total = payload.total;
			page.expires = Date.now() + entitiesCacheTime * 1000;
		},
		[listTypes.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string, offset: number}}
		) => {
			const page = state[payload.objectId];
			if (page) {
				page.offset = payload.offset;
				page.expires = undefined;
			}
		},
		[listTypes.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: {objectId: string, selectedRows: string[]}}
		) => {
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		[listTypes.TOGGLE_ALL_ROWS_SELECTED]: (
			state,
			{payload}: {payload: {objectId: string, isAllRowsSelected?: boolean}}
		) => {
			const page = state[payload.objectId];
			page.isAllRowsSelected
				= payload.isAllRowsSelected !== undefined
					? payload.isAllRowsSelected
					: !page.isAllRowsSelected;
		},
		[CHANGE_SORT]: (state, {payload}: {payload: {objectId: string}}) => {
			state[payload.objectId].expires = undefined;
		},
		[CLEAR_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			state[payload.objectId].expires = undefined;
		},
		// При манипуляциях с затрагивающими данными необходимо обновить нарушения
		[detailTypes.ADD_SUCCESS]: clearPage,
		[detailTypes.EDIT_STATUS_SUCCESS]: clearPage,
		[detailTypes.EDIT_SUCCESS]: clearPage,
		[detailTypes.DELETE_SUCCESS]: (
			state,
			{payload}: {payload: IDeleteWorkAcceptancePayload}
		) => {
			if (payload.objectId !== 'all' && state[payload.objectId]) {
				deleteWorkAcceptance(state, payload.objectId, payload.workAcceptanceId);
			}
			if (state.all) {
				deleteWorkAcceptance(state, 'all', payload.workAcceptanceId);
			}
		},
		[problemTypes.EDIT_SUCCESS]: clearPage,
		[problemTypes.EDIT_STATUS_SUCCESS]: clearPage,
		[problemTypes.DELETE_SUCCESS]: clearPage,
		[CLEAR_STORE]: () => ({})
	}
);
