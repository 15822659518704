import {createSelector} from 'reselect';
import {extractProblemStatusesWithExpired} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import {IState} from '@src/store/modules';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

const extractSelected = (state: IState, selected: ProblemStatusId[] | undefined) => selected;

export const extractFilteredProblemStatuses = createSelector(
	[extractProblemStatusesWithExpired, extractSelected],
	(statuses, selected) =>
		(selected?.length ? statuses.filter(status => selected.includes(status.id)) : statuses)
);
