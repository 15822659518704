import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import classNames from 'classnames';
import React, {memo, useCallback} from 'react';

interface INumberContractFieldProps {
	className?: string;
	label?: string;
	errorMessage?: string;
	hasError?: boolean;
	required?: boolean;
	disabled?: boolean;
	value?: number | null;

	onChange: (value: number) => void;
	onError: () => void;
}

export const NumberContractField = memo(({
	className,
	label,
	errorMessage,
	hasError,
	required,
	disabled,
	value,
	onChange,
	onError
}: INumberContractFieldProps) => {
	const handleChange = useCallback((value: string) => {
		const numberValue = Number(value);
		if (!isNaN(numberValue)) {
			onChange(numberValue);
		}
		if (required) {
			onError();
		}
	}, [onChange, onError, required]);

	return (
		<div
			className={classNames(
				'editable-contract__fields-number', className
			)}
		>
			<EditableFieldLabel
				className="editable-contract__fields-number__label"
			>
				{required ? `${label} *` : label}
			</EditableFieldLabel>

			<TextField
				value={value ? `${value}` : undefined}
				onChange={handleChange}
				error={hasError ? errorMessage : undefined}
				elementType="input"
				elementProps={{type: 'number'}}
				disabled={disabled}
			/>
		</div>
	);
});