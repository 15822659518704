import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {extractObjectsCitiesAsArray} from '@src/store/modules/dictionaries/objects/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

interface ICitiesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const CitiesFilterMobile = memo((props: ICitiesFilterMobileProps) => {
	const cities = useAppSelector(extractObjectsCitiesAsArray);

	return (
		<BaseListFilterMobile
			{...props}
			options={cities}
			label="Город"
			filterName="cities"
		/>
);
});
