import {extractCategoriesSetById} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';

export const useCategoriesSetBreadcrumbs = (objectId?: string, categoriesSetId?: string) => {
	const categoriesSet = useAppSelector(s =>
		(categoriesSetId ? extractCategoriesSetById(s, categoriesSetId) : undefined));
	const objects = useAppSelector(s => s.dictionaries.objects);

	return useMemo(() => {
		let items: IBreadcrumb[];

		if (objectId) {
			items = formObjectsChain(objects.byId, objectId);
			items.push({
				label: 'Наборы видов работ',
				url: `/objects/${objectId}/categories-sets`
			});
		} else {
			items = [
				{
					label: 'Наборы видов работ',
					url: '/manage/categories-sets'
				}
			];
		}

		if (categoriesSet) {
			items.push({
				label: categoriesSet.name,
				url: objectId
					? `/objects/${objectId}/categories-sets/${categoriesSetId}`
					: `/manage/categories-sets/${categoriesSetId}`
			});
		}

		return items;
	}, [objects, categoriesSet, objectId]);
};
