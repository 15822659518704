import React, {useCallback, useMemo, useState} from 'react';
import './EditableInspectionDialog.less';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {makeTitle} from './utils/makeTitle';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableInspection} from '@src/components/EditableInspection/hooks/useEditableInspection';
import {makeDefaultData} from '@src/core/hooks/states/useEditableInspectionState';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {useEditInspection} from '@src/core/hooks/mutations/inspection/useEditInspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';

interface IEditableInspectionDialogProps {
	objectId: string;
	inspection: IInspection;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно редактирования осмотра
 */
const EditableInspectionDialog = ({
	objectId,
	inspection,
	isOpen,
	onClose
}: IEditableInspectionDialogProps) => {
	const [saving, setSaving] = useState(false);
	const object = useAppSelector(s => extractObject(s, objectId));
	const defaultData = useMemo(() => makeDefaultData(inspection), [inspection]);
	const [fields, getSavingData, reset, isBlocking] = useEditableInspection(
		objectId,
		defaultData,
		saving
	);
	const [editInspection] = useEditInspection(object);
	const save = useCallback(async () => {
		setSaving(true);
		if (inspection) {
			const savingData = await getSavingData(true);
			if (savingData) {
				editInspection({inspectionId: inspection.id, objectId, fields: savingData});
				onClose();
			}
		}
		setSaving(false);
	}, [objectId, inspection, getSavingData, editInspection, onClose]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-inspection-dialog"
			isOpen={isOpen}
			title={makeTitle(inspection)}
			footer={(
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};

export default EditableInspectionDialog;
