import IUser from '@tehzor/tools/interfaces/IUser';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IPreparedOwnerAcceptance} from '../interfaces/IPreparedOwnerAcceptance';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {getPermissionsForOwnerAcceptance} from './getPermissionsForOwnerAcceptance';
import {IOwnerAcceptanceResolution} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {IOwnerAcceptanceStatus} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {IMarker} from '@tehzor/tools/interfaces/markers/IMarker';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';

/**
 * Преобразовывает передачи собственникам в необходимый для вывода формат
 *
 * @param ownerAcceptances массив передач собственникам
 * @param resolutions массив решений передач
 * @param statuses массив статусов передач
 * @param markers массив маркеров
 * @param plans массив планов
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 */
export const convertOwnerAcceptances = (
	ownerAcceptances: IListOwnerAcceptance[],
	resolutions: IOwnerAcceptanceResolution[],
	statuses: IOwnerAcceptanceStatus[],
	markers: IMarker[],
	plans: IPlan[],
	networkStatus: boolean,
	user?: IUser
): IPreparedOwnerAcceptance[] =>
	ownerAcceptances.map(item => {
		const preparedMarkers = markers.filter(
			marker => marker.links?.ownerAcceptanceId === item.id
		);
		const preparedPlans = plans.find(plan => preparedMarkers[0]?.planId === plan.id);
		const preparedResolution = resolutions.find(
			resolution => resolution.id === item.resolution
		)?.name;

		return {
			id: item.id,
			object: item.object,
			number: item.number,
			localNumber: item.localNumber,
			status: statuses.find(status => status.id === item.status),
			resolution: preparedResolution || 'Нет решения',
			markers: preparedMarkers,
			plan: preparedPlans,
			spaceOwner: item?.spaceOwner,
			createdBy: item.createdBy,
			modifiedBy: item.modifiedBy,
			createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
			modifiedAt: item.modifiedAt
				? format(item.modifiedAt, dateTimeCommaSeparatedFormat)
				: '',
			...getPermissionsForOwnerAcceptance(item, networkStatus, item.objectId, user)
		};
	});
