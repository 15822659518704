import {wsConnector} from '../wsConnector';
import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';

export type IAddExportTemplateResponse = INormalizedData<IExportTemplate>;

/**
 * Создает шаблон
 *
 * @param fields данные для добавления
 */
export const makeExportTemplateAddRequest = (fields: ISavingSpaceTypeDecorationSet) =>
	wsConnector.sendAuthorizedRequest<IAddExportTemplateResponse>(
		'addExportTemplate',
		{...fields}
	);
