import React, {ReactChild, ReactFragment, useMemo, useState} from 'react';
import './Tree.less';
import DefaultTreeItem, {ITreeItemProps} from './components/TreeItem';
import classNames from 'classnames';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {makeTree} from '@tehzor/tools/utils/tree/makeTree';
import {updateCheckedKeys} from '@tehzor/tools/utils/tree/updateCheckedKeys';
import {filterOnlyParents} from '@tehzor/tools/utils/tree/filterOnlyParents';
import {formAllChecked} from '@tehzor/tools/utils/tree/formAllChecked';
import {formSemiChecked} from '@tehzor/tools/utils/tree/formSemiChecked';
import {formInitialExpanded} from '@tehzor/tools/utils/tree/formInitialExpanded';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';
import {TreeCtx} from './utils/TreeCtx';

export interface ITreeDataItem {
	id: string;
	content: ReactChild | ReactFragment;
	parentId?: string;
	disabled?: boolean;
	nonCheckable?: boolean;
	onlySemiCheckable?: boolean;
}

interface ITreeProps <T extends ITreeDataItem> {
	className?: string;
	style?: React.CSSProperties;
	expandedValue?: string[];
	defaultExpandedValue?: string[];
	isAllExpanded?: boolean;
	isAllCheckedExpanded?: boolean;
	isCheckable?: boolean;
	checkedValue?: string[];
	/**
	 * Выбирать без учёта дочерних/родительских элементов
	 */
	checkStrictly?: boolean;
	/**
	 * Выбирать только родительский элемент, если будут отмечены все его потомки (визуально будут выбраны все элементы)
	 */
	checkOnlyParents?: boolean;
	/**
	 * Выбирать только конечные объекты
	 */
	latestOnly?: boolean;
	multiple?: boolean;
	disabled?: boolean;
	data: T[];

	onCheck?: (keys: string[]) => void;
	onExpand?: (keys: string[]) => void;

	TreeItem?: React.ComponentType<ITreeItemProps<T>>;
}

const Tree = <T extends ITreeDataItem>(props: ITreeProps<T>) => {
	const {
		className,
		style,
		multiple = false,
		expandedValue,
		defaultExpandedValue,
		isAllExpanded,
		isAllCheckedExpanded,
		isCheckable,
		checkedValue = [],
		checkOnlyParents,
		disabled,
		data,
		latestOnly = false,
		onExpand,
		onCheck,
		TreeItem = DefaultTreeItem
	} = props;
	const checkStrictly = multiple ? props.checkStrictly : true;

	const treeData = useMemo(() => makeTree(data), [data]);

	const checked = useMemo(() => (checkOnlyParents ? formAllChecked(treeData, checkedValue) : checkedValue), [
		treeData,
		checkedValue,
		checkOnlyParents
	]);
	const semiChecked = useMemo(() => (checkStrictly ? [] : formSemiChecked(treeData, checkedValue)), [
		treeData,
		checkedValue,
		checkStrictly
	]);

	const [expanded, setExpanded] = useState(
		formInitialExpanded(
			treeData,
			expandedValue,
			defaultExpandedValue,
			checked,
			isAllExpanded,
			isAllCheckedExpanded
		)
	);
	useUpdateEffect(() => {
		setExpanded(expandedValue || []);
	}, [expandedValue]);

	const ctxValue = useMemo(
		() => ({
			isExpanded: (id: string) => expanded.includes(id),
			isChecked: (id: string) => checked.includes(id),
			isSemiChecked: (id: string) => semiChecked.includes(id),
			changeExpanded: (id: string, value: boolean) => {
				const newExpanded = value ? expanded.concat([id]) : expanded.filter(key => key !== id);
				if (onExpand) {
					onExpand(newExpanded);
				} else {
					setExpanded(newExpanded);
				}
			},
			changeChecked: (item: ITwoWayTreeItem<ITreeDataItem>, value: boolean) => {
				if (onCheck) {
					let newChecked = updateCheckedKeys(item, value, checked, checkStrictly, multiple);
					if (checkOnlyParents) {
						newChecked = filterOnlyParents(treeData, newChecked);
					}
					onCheck(newChecked);
				}
			}
		}),
		[
			treeData,
			expandedValue,
			expanded,
			checkedValue,
			checked,
			semiChecked,
			multiple,
			checkStrictly,
			checkOnlyParents,
			onExpand,
			onCheck
		]
	);

	return (
		<ul
			className={classNames('tree', className)}
			style={style}
		>
			<TreeCtx.Provider value={ctxValue}>
				{treeData.map(item => (
					<TreeItem
						key={item.id}
						data={item}
						level={0}
						checkable={isCheckable}
						latestOnly={latestOnly}
						multiple={checkStrictly ? multiple : true}
						disabled={disabled}
					/>
				))}
			</TreeCtx.Provider>
		</ul>
	);
};

Tree.displayName = 'Tree';

export default Tree;
