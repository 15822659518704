import React, {useMemo} from 'react';
import SelectionActions from '../selection/SelectionActions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ActionsMobile from './Actions.mobile';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {extractWarrantyClaimEntities} from '@src/store/modules/entities/warrantyClaim/selectors';

interface IMobileRightButtonsProps {
	objectId: string;
	warrantyClaimId: string;
	warrantyClaim?: ILinkedWarrantyClaim;
	selectedRows?: string[];
}

const MobileRightButtons = ({
	objectId,
	warrantyClaimId,
	warrantyClaim,
	selectedRows
}: IMobileRightButtonsProps) => {
	const entities = useAppSelector(extractWarrantyClaimEntities);

	const selectedEntities = useMemo(
		() => entities.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, entities]
	);

	if (!warrantyClaim) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				warrantyClaimId={warrantyClaimId}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			warrantyClaim={warrantyClaim}
		/>
);
};

export default MobileRightButtons;
