import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {useState} from 'react';
import {ProblemsTable} from '../ProblemsTable';
import './ItemDetail.desktop.less';
import {useAddingCheckListProblemDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListProblemDialog';
import {Button, TabContent, TabLink, Tabs} from '@tehzor/ui-components';
import {extractTreeCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {TasksTable} from '@src/pages/CheckListPage/components/detail/TasksTable';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import {TaskIndicators} from '@src/pages/CheckListPage/components/TaskIndicators';
import {useAddingCheckListTaskDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListTaskDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface IDesktopItemDetailProps {
	canAddEntity?: boolean;
	canAddTask?: boolean;
}

const iconPlus = <i className="tz-plus-16"/>;
export const DesktopItemDetail = ({canAddEntity, canAddTask}: IDesktopItemDetailProps) => {
	const {listId, itemId} = useStrictParams<{
		spaceId?: string;
		workAcceptanceId?: string;
		objectId: string;
		listId: string;
		itemId: string;
		stage: string;
	}>();
	const [activeTab, setActiveTab] = useState(0);
	const item = useAppSelector(s => extractTreeCheckItemById(s, listId, itemId));
	const [problemDialog, handleOpenProblemDialog] = useAddingCheckListProblemDialog(itemId);
	const [taskDialog, handleOpenTaskDialog] = useAddingCheckListTaskDialog();

	if (!item) {
		return null;
	}

	return (
		<div className="check-item-detail__container">
			<div className="check-item-detail__title">
				<div className="check-item-detail__title-text">{item.name}</div>
				<div className="check-item-detail__title-actions"/>
			</div>
			<div className="check-item-detail__indicators">
				<ProblemIndicators
					checkListId={listId}
					checkItemId={itemId}
				/>
				<TaskIndicators
					checkListId={listId}
					checkItemId={itemId}
				/>
			</div>
			<div className="check-item-detail__scrollable">
				<Tabs
					className={{
						links: 'check-item-detail__tabs-links',
						link: 'check-item-detail__tabs-link'
					}}
					activeTab={activeTab}
					links={[
						<TabLink
							key="problems"
							label="Нарушения"
						/>,
						<TabLink
							key="tasks"
							label="Задачи"
						/>
					]}
					onActiveTabChange={setActiveTab}
				>
					<TabContent>
						<div className="check-item-detail__items">
							<ProblemsTable item={item}/>
							{canAddEntity && problemDialog}
						</div>
					</TabContent>
					<TabContent>
						<div className="check-item-detail__items">
							<TasksTable item={item}/>
							{canAddTask && taskDialog}
						</div>
					</TabContent>
				</Tabs>

				{canAddTask && activeTab === 1 && (
					<div className="check-item-detail__action">
						<Button
							className="check-item-detail__action-btn"
							label="Задача"
							type="accent-blue"
							leftIcon={iconPlus}
							onClick={handleOpenTaskDialog}
						/>
					</div>
				)}

				{canAddEntity && activeTab === 0 && (
					<div className="check-item-detail__action">
						<Button
							className="check-item-detail__action-btn"
							label="Нарушение"
							type="accent-blue"
							leftIcon={iconPlus}
							onClick={handleOpenProblemDialog}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
