import {wsConnector} from '../wsConnector';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';

export type IAddSpaceStatusesSetResponse = ISpaceStatusesSet;

/**
 * Добавляет набор статусов помещений
 * 
 * @param spaceStatusesSet наборы статусов помещения
 */
export const requestAddSpaceStatusesSet = (spaceStatusesSet: ISavingSpaceStatusesSet) =>
	wsConnector.sendAuthorizedRequest<IAddSpaceStatusesSetResponse>('addSpaceStatusesSet', {
		...spaceStatusesSet
	});
