import {Column} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {CreatedCell} from '../cells/CreatedCell';
import {ModifiedCell} from '../cells/ModifiedCell';
import {MenuCell} from '../cells/MenuCell';
import {OwnerCell} from '../cells/OwnerCell';
import {LocationCell} from '../cells/LocationCell';
import {NumberCell} from '../cells/NumberCell';
import {StatusCell} from '@src/pages/OwnerAcceptancesPage/components/table/cells/StatusCell';

export const desktopColumns: Array<Column<IPreparedOwnerAcceptance>> = [
	{
		Header: '№',
		Cell: NumberCell,
		width: 60
	},
	{
		id: 'object',
		Header: 'Объект / Место',
		Cell: LocationCell,
		width: 100,
		disableSortBy: true,
		defaultCanSort: false
	},
	{
		id: 'status',
		Header: 'Статус',
		Cell: StatusCell,
		width: 100,
		disableSortBy: true,
		defaultCanSort: false
	},
	{
		id: 'resolution',
		Header: 'Решение',
		accessor: row => (row?.resolution),
		width: 100,
		disableSortBy: true,
		defaultCanSort: false
	},
	{
		id: 'spaceOwner',
		Header: 'Собственник',
		accessor: row => row.spaceOwner?.name,
		Cell: OwnerCell,
		width: 100,
		sortDescFirst: true
	},
	{
		id: 'createdAt',
		Header: 'Добавлено',
		accessor: row => row.createdAt,
		Cell: CreatedCell,
		width: 100,
		sortDescFirst: true
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: row => row.modifiedAt,
		Cell: ModifiedCell,
		width: 100,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'owner-acceptances-page__d-table-menu',
		width: 'auto',
		defaultCanSort: false,
		disableResizing: true
	}
];

export const desktopColumnsWithoutObject = desktopColumns.filter(item => item.id !== 'object');
