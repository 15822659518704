import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {normalizeData} from '@tehzor/tools/utils/normalizeData';

const extractAllIds = (state: IState): string[] =>
	state.dictionaries.problemTemplatesSets.allIds || [];
const extractById = (state: IState): Record<string, IProblemTemplatesSet> =>
	state.dictionaries.problemTemplatesSets.byId || {};

/**
 * Возвращает наборы шаблонов нарушений в виде массива
 */
export const extractProblemTemplatesSetsAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map(id => byId[id])
);

// const prepareTemplate = (item: IProblemTemplate): IPreparedProblemTemplate => ({
// 	id: item.id,
// 	description: item.description,
// 	categoryId: item.categoryId || '',
// 	reason: item.reason
// });

// const prepareTemplatesSet = (
// 	item: IProblemTemplatesSet,
// 	companies: ICompaniesState
// ): IPreparedProblemTemplatesSet => ({
// 	id: item.id,
// 	name: item.name,
// 	data: item.data.map(prepareTemplate),
// 	shared: item.shared || false,
// 	companies: (item.companies || []).reduce(
// 		(acc: string[], companyId) =>
// 			(companies.byId[companyId] ? acc.concat(companies.byId[companyId].name) : acc),
// 		[]
// 	),
// 	createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
// 	createdBy: item.createdBy?.fullName || '',
// 	modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeCommaSeparatedFormat) : '',
// 	modifiedBy: item.modifiedBy?.fullName || '',
// 	// TODO: getPermission
// 	canDelete: true,
// 	canEdit: true
// });

// export const extractPreparedTemplatesSetsAsArray = createSelector(
// 	[extractProblemTemplatesSetsAsArray, (state: IState) => state.entities.companies],
// 	(data, companies) => data.map(item => prepareTemplatesSet(item, companies))
// );

export const extractProblemTemplatesSetsForObject = createSelector(
	[extractProblemTemplatesSetsAsArray, extractObject],
	(templatesSets, object) =>
		templatesSets.filter(
			({companies, shared}) =>
				shared
				|| (companies && companies.some(id => id === object.id || id === object.companyId))
		)
);

export const extractProblemTemplatesForObject = createSelector(
	[extractProblemTemplatesSetsForObject],
	templatesSets =>
		templatesSets.reduce(
			(acc: IProblemTemplate[], templatesSet) => acc.concat(...templatesSet.data),
			[]
		)
);

/**
 * Возвращает шаблоны нарушений для конкретного объекта
 */
export const extractProblemTemplatesAsMap = createSelector(
	[extractById, extractObject],
	(byId, object) => {
		const setId = object.problemTemplatesSet;
		const data = (setId && byId[setId]?.data) || [];
		return normalizeData<IProblemTemplate>(data);
	}
);

/**
 * Возвращает шаблоны нарушений для конкретного объекта в виде массива
 */
export const extractProblemTemplatesAsArray = createSelector(
	[extractById, extractObject],
	(byId, object) => {
		const setId = object.problemTemplatesSet;
		return (setId && byId[setId]?.data) || [];
	}
);
