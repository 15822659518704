import {IAddCategoryResponse, requestAddCategories} from '@src/api/backend/categories';
import {createApiAction} from '@src/store/middlewares/api';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {queryClient} from '@src/api/QueryClient';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';

export type IAddCategoryPayload = IAddCategoryResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (category: ICategory) => ({
	type: types.ADD_SUCCESS,
	payload: {...category} as IAddCategoryPayload
});

const failure = (err: IError) => ({
	type: types.ADD_FAILURE,
	payload: err
});

export const addCategory = (category: ISavingCategory) =>
	createApiAction<IAddCategoryResponse>(
		request,
		req => success(req),
		failure,
		async () => {
			const data = await requestAddCategories(category);
			await queryClient.invalidateQueries(categoriesQueryKeys.list());
			return data;
		}
	);
