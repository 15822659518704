import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {Column} from 'react-table';
import RelationsCell from './cells/RelationsCell';
import DeleteCell from './cells/DeleteCell';
import NameCell from './cells/NameCell';
import UserCell from './cells/UserCell';
import StagesCell from './cells/StagesCell';

export const desktopColumns: Array<Column<IFullCategoriesSet>> = [
	{
		id: 'name',
		Header: 'Наименование',
		accessor: 'name',
		Cell: NameCell,
		width: 80
	},
	{
		Header: 'Компании/Объекты',
		id: 'companies',
		accessor: 'companies',
		Cell: RelationsCell,
		width: 90
	},
	{
		Header: 'Автор',
		id: 'createdBy.displayName',
		accessor: 'createdBy',
		Cell: UserCell,
		width: 90
	},
	{
		Header: 'Стадии',
		id: 'stages',
		Cell: StagesCell,
		width: 60
	},
	{
		id: 'createdAt',
		Header: 'Создано',
		accessor: row => (row.createdAt ? format(row.createdAt, dateTimeCommaSeparatedFormat) : ''),
		width: 40
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: row =>
			(row.modifiedAt ? format(row.modifiedAt, dateTimeCommaSeparatedFormat) : ''),
		width: 40
	},
	{
		id: '_delete',
		Cell: DeleteCell,
		width: 80,
		minWidth: 68,
		disableResizing: true,
		isNonClickable: true
	}
];

export const desktopColumnsWithoutObjects: Array<Column<IFullCategoriesSet>>
	= desktopColumns.filter(col => col.id !== 'companies');
