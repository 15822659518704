import {useMemo} from 'react';
import {useSpacesBreadcrumbs} from './useSpacesBreadcrumbs';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpace} from '@src/store/modules/entities/space/selectors';

/**
 * Хук для хлебных крошек от "всех объектов" до помещения
 *
 * @param objectId id конечного объекта
 * @param spaceId id помещения
 */
export function useSpaceBreadcrumbs(objectId: string, spaceId: string) {
	const res = useSpacesBreadcrumbs(objectId);
	const space = useAppSelector(extractSpace);
	const spaceTitle = useSpaceTitle(space);

	return useMemo(
		() =>
			res.concat({
				label: spaceTitle,
				url: formSpaceLink(objectId, spaceId),
				inactive: false
			}),
		[objectId, res, spaceTitle]
	);
}
