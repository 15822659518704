import React, {createContext, useCallback, useMemo} from 'react';
import {useIsDesktop, useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {
	EntitiesTable,
	Pagination,
	PaginationAndSize,
	PaginationPageSize,
	Plate
} from '@tehzor/ui-components';
import {
	extractInternalAcceptancesAsArray,
	extractInternalAcceptancesData
} from '@src/store/modules/entities/internalAcceptances/selectors';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';
import {
	changePageSize,
	changeSort
} from '@src/store/modules/settings/pages/internalAcceptances/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {desktopColumns, desktopColumnsWithoutObject} from './desktopColumns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertInternalAcceptances} from '../../utils/convertInternalAcceptance';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IPreparedInternalAcceptance} from '../../interfaces/IPreparedInternalAcceptance';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {mobileColumns, mobileColumnsWithoutObject} from './mobileColumns';
import {
	changeSelectedRows,
	getInternalAcceptances,
	changeOffset
} from '@src/store/modules/entities/internalAcceptances/actions';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {deleteInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/actions';
import {getInternalAcceptancesProblemsData} from '@src/store/modules/pages/internalAcceptances/actions/getInternalAcceptancesProblemsData';
import {getInternalAcceptancesInspectionsData} from '@src/store/modules/pages/internalAcceptances/actions/getInternalAcceptancesInspectionsData';
import {useLocalInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/useGetInternalAcceptances';

const pageSizes = [10, 20, 50, 100];

interface IInternalAcceptancesTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedInternalAcceptance>) => void
>(() => ({}));

export const InternalAcceptancesTable = ({objectId = 'all'}: IInternalAcceptancesTableProps) => {
	const {pushPath} = useChangePath();
	const localInternalAcceptances = useLocalInternalAcceptances(objectId);
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const internalAcceptances = useAppSelector(s => extractInternalAcceptancesAsArray(s, objectId));
	const problemStats = useAppSelector(s => s.pages.internalAcceptances.problemsData.data);
	const inspectionStats = useAppSelector(s => s.pages.internalAcceptances.inspectionsData.data);
	const problemStatuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const internalAcceptancesData = useAppSelector(s =>
		extractInternalAcceptancesData(s, objectId));
	const pageSettings = useAppSelector(s => extractInternalAcceptancesPageSettings(s, objectId));
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const pagesCount = Math.ceil(internalAcceptancesData.total / pageSettings.pageSize);
	const currentPage = Math.floor(internalAcceptancesData.offset / pageSettings.pageSize);
	const preparedInternalAcceptances = useMemo(
		() =>
			convertInternalAcceptances(
				[...localInternalAcceptances, ...internalAcceptances],
				problemStats,
				problemStatuses,
				inspectionStats,
				networkStatus,
				user
			),
		[
			internalAcceptances,
			networkStatus,
			user,
			problemStats,
			problemStatuses,
			inspectionStats,
			localInternalAcceptances
		]
	);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить внутреннюю приёмку?'
	);

	const [, loadInternalAcceptances] = useAsyncFn(async () => {
		const response = await dispatch(getInternalAcceptances(objectId));

		if (response && online) {
			const internalAcp = response.allIds.map(id => ({
				objectId: response.byId[id].objectId,
				internalAcceptanceId: response.byId[id].id
			}));
			void dispatch(getInternalAcceptancesProblemsData({internalAcceptances: internalAcp}));
			void dispatch(
				getInternalAcceptancesInspectionsData({internalAcceptances: internalAcp})
			);
		}
	}, [objectId, online, dispatch]);

	const handleRowClick = useCallback((internalAcceptance: IPreparedInternalAcceptance) => {
		if (internalAcceptance.object) {
			pushPath(
				`/objects/${internalAcceptance.object.id}/internal-acceptances/${internalAcceptance.id}`
			);
		}
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows(objectId, value)),
		[dispatch, objectId]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort(objectId, value));
			void loadInternalAcceptances();
		},
		[dispatch, objectId, loadInternalAcceptances]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(objectId, value));
			dispatch(changeOffset(objectId, Math.floor(internalAcceptancesData.offset / value)));
			void loadInternalAcceptances();
		},
		[objectId, internalAcceptancesData.offset, loadInternalAcceptances, dispatch]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (internalAcceptancesData.offset !== offset) {
				dispatch(changeOffset(objectId, offset));
				void loadInternalAcceptances();
			}
		},
		[
			pageSettings.pageSize,
			internalAcceptancesData.offset,
			dispatch,
			objectId,
			loadInternalAcceptances
		]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedInternalAcceptance>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await dispatch(
						deleteInternalAcceptance(action.payload.object.id, action.payload.id)
					);
					await loadInternalAcceptances();
				}
			}
		},
		[objectId, dispatch]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		desktopColumns,
		desktopColumnsWithoutObject
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={preparedInternalAcceptances}
						selectedRows={internalAcceptancesData.selectedRows}
						sort={pageSettings.sort}
						selectable={isDesktop}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
						headVisible={isDesktop}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>

			{deleteDialog}
		</>
	);
};
