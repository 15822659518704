import {ViewerType} from '../LocationSelect';

/**
 * Возвращает название вкладки в мобиьной версии
 *
 * @param {ViewerType} viewerType тип отображения
 */
export const getViewerTabTitle = (viewerType: ViewerType) => {
	switch (viewerType) {
		case 'plan':
			return 'План';
		case 'map':
			return 'Карта';
		default:
			return '';
	}
};
