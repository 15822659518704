import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {
	convertToSave,
	errorsFns,
	isEdited,
	useEditableStructureState
} from '@src/core/hooks/states/useEditableStructureState';
import {EditableStructure} from '../EditableStructure';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

/**
 * Логика редактирования и сохранения структуры (новой или существующей)
 */
export const useEditableStructure = (
	objectId: string,
	structure: IStructure | undefined,
	saving: boolean
): [React.ReactNode, () => Promise<ISavingStructure | undefined>, (
) => void, boolean] => {
	const fieldsSettings = useMemo(
		() => ({
			name: {fieldId: 'name', isRequired: true},
			type: {fieldId: 'type', isRequired: true},
			planId: {fieldId: 'planId', isRequired: false}
		}),
		[]
	);

	const [editingState, editingDispatch] = useEditableStructureState(structure);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, structure) || isFilesExist(uploadingFilesState.value));
	}, [editingState, uploadingFilesState.value, structure]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, structure)
			&& (!isFilesExist(files) || someFilesHaveError(files))
		) {
			return undefined;
		}
		const savingData = convertToSave(editingState, structure, true);

		return savingData;
	}, [
		editingState,
		structure,
		fieldsSettings,
		editingDispatch,
		uploadingFilesDispatch,
		waitUploading
	]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: structure
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [structure, editingDispatch, uploadingFilesDispatch]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [structure]);

	const fields = (
		<EditableStructure
			objectId={objectId}
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
