import {Column} from 'react-table';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import {CheckListSpaceCompanyCell} from './CheckListSpaceCompanyCell';
import {CheckListSpaceTypesCell} from './CheckListSpaceTypesCell';
import {MenuCell} from '@src/pages/manage/CheckListsPage/components/table/MenuCell';
import {ObjectStageCell} from '@src/pages/manage/CheckListsPage/components/table/ObjectStageCell';
import {CheckListObjectsCell} from '@src/pages/manage/CheckListsPage/components/table/CheckListObjectsCell';

export const desktopColumns: Array<Column<IPreparedCheckList>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Наименование',
		width: 80,
		minWidth: 80,
		sortDescFirst: true
	},
	{
		id: 'companyId',
		Header: 'Компания',
		accessor: 'companyId',
		Cell: CheckListSpaceCompanyCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'objects',
		Header: 'Объекты',
		accessor: 'objects',
		Cell: CheckListObjectsCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'stage',
		Header: 'Стадия',
		Cell: ObjectStageCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'spaceTypes',
		Header: 'Тип помещений',
		Cell: CheckListSpaceTypesCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'createdBy',
		Header: 'Автор',
		accessor: 'createdBy',
		width: 80,
		minWidth: 80
	},
	{
		id: 'createdAt',
		accessor: 'createdAt',
		Header: 'Создание',
		width: 80,
		minWidth: 80
	},
	{
		id: 'modifiedAt',
		accessor: 'modifiedAt',
		Header: 'Изменение',
		width: 80,
		minWidth: 80
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		className: 'checks-page__d-table-menu'
	}
];
