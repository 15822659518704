import React from 'react';
import SelectedEntitiesExport from './SelectedEntitiesExport';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckProblems} from '@src/store/modules/entities/check/selectors';
import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import SelectedProblemsMove from './SelectedProblemsMove';

interface ISelectionsActionsProps {
	objectId: string;
	checkId: string;
	check?: ILinkedCheck;
	selectedEntities: ICheckEntity[];
	clearSelection?: () => void;
}

const SelectionActions = ({
	objectId,
	checkId,
	check,
	selectedEntities,
	clearSelection
}: ISelectionsActionsProps) => {
	const problems = useAppSelector(extractCheckProblems);
	const permissions = useCheckPermissions(objectId, check, problems);

	return (
		<>
			{permissions.canProblemsMove && (
				<SelectedProblemsMove
					objectId={objectId}
					checkId={checkId}
					selectedEntities={selectedEntities}
					restrictions={permissions.canProblemsMoveRestrictions}
					clearSelection={clearSelection}
				/>
			)}
			{permissions.canExport && (
				<SelectedEntitiesExport
					objectId={objectId}
					checkId={checkId}
					selectedEntities={selectedEntities}
				/>
			)}
			{permissions.canDelete && (
				<SelectedEntitiesDeletion
					selectedEntities={selectedEntities}
					clearSelection={clearSelection}
				/>
			)}
		</>
	);
};

export default SelectionActions;
