import React from 'react';
import {PriorityView} from '@src/components/PriorityView/PriorityView';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {CellProps} from 'react-table';
import {IEnrichedTaskForScheduler} from '@src/pages/TasksPage/hooks/useEnrichedTasksForSchedule';

export const Priority = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;
	const prioritiesMap = useAppSelector(s => s.dictionaries.taskPriorities.byId);

	return (
		<PriorityView
			value={task?.taskPriority?.id || ''}
			prioritiesMap={prioritiesMap}
		/>
	);
};
