import {wsConnector} from '../wsConnector';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export type IAddWorkingGroupResponse = IWorkingGroup;

/**
 * Добавляет рабочую группу
 *
 * @param fields данные рабочей группы
 */
export const makeWorkingGroupAddRequest = (fields: ISavingWorkingGroup) =>
	wsConnector.sendAuthorizedRequest<IAddWorkingGroupResponse>('addWorkingGroup', {...fields});
