import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {
	IEditProblemResponsibleResponse,
	makeProblemResponsibleEditRequest
} from '@src/api/backend/problem/editResponsible';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

export type IEditProblemResponsiblePayload = IEditProblemResponsibleResponse;

const request = () => ({type: types.EDIT_RESPONSIBLE_REQUEST});

const success = (response: IEditProblemResponsibleResponse) => ({
	type: types.EDIT_RESPONSIBLE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении ответственных');
	return {
		type: types.EDIT_RESPONSIBLE_FAILURE,
		payload: error
	};
};

/**
 * Изменяет ответственных нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const editProblemResponsible = (
	objectId: string,
	problemId: string,
	respUsers?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditProblemResponsibleResponse>(request, 
		res => {
			void queryClient.invalidateQueries(problemsQueryKeys.list());
			void queryClient.invalidateQueries([...problemsQueryKeys.detail(problemId), objectId]);
			return success(res);
		},
		failure, () =>  makeProblemResponsibleEditRequest(objectId, problemId, respUsers, activeGroup)
	);
