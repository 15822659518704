import React from 'react';
import './OfflineDataItem.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {determineSubtitle} from './utils/determineSubtitle';
import {LinearLoader} from '../../loaders';

interface IOfflineDataItemProps {
	className?:
		| string
		| {
				root?: string;
				title?: string;
				subtitle?: string;
				icon?: string;
		  };
	style?: React.CSSProperties;
	title: string;
	icon: React.ReactNode;
	date?: number;
	error?: string;
	inProgress?: boolean;
	progressValue?: number;
	progressTitle?: string;
}

export const OfflineDataItem = (props: IOfflineDataItemProps) => {
	const {className, style, title, icon, date, error, inProgress, progressValue, progressTitle = 'Обновление...'} = props;

	const classes = convertClassNames(className);

	const subtitle = determineSubtitle(date, error, inProgress, progressTitle);

	return (
		<div
			className={classNames('offline-data-item', classes.root)}
			style={style}
		>
			<div className={classNames('offline-data-item__icon-wrap', classes.icon)}>
				{icon}
				{error && !inProgress ? <div className="offline-data-item__icon-error-mark"/> : null}
			</div>

			{title ? (
				<div className="offline-data-item__title-block">
					<div className={classNames('offline-data-item__title', classes.title)}>{title}</div>
					{subtitle !== undefined && (
						<div
							className={classNames(
								'offline-data-item__subtitle',
								{'offline-data-item__subtitle_error': error && !inProgress},
								classes.subtitle
							)}
						>
							{subtitle}
						</div>
					)}
					{inProgress && progressValue !== undefined ? (
						<LinearLoader
							className="offline-data-item__progress"
							percent={progressValue}
						/>
					) : null}
				</div>
			) : null}
		</div>
	);
};