import React, {useCallback} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {WorkAcceptanceDisplayMode} from '@src/interfaces/WorkAcceptanceDisplayMode';
import {changeWorkAcceptanceDisplayMode} from '@src/store/modules/settings/pages/workAcceptances/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';

const tabs = [
	<TabLink label="Описание"/>,
	<TabLink label="Нарушения"/>,
	<TabLink label="Чек-листы"/>
];
const tabsIndexes = {
	[WorkAcceptanceDisplayMode.DESCRIPTION]: 0,
	[WorkAcceptanceDisplayMode.PROBLEMS]: 1,
	[WorkAcceptanceDisplayMode.CHECK_LISTS]: 2
};
const tabsKeys = [
	WorkAcceptanceDisplayMode.DESCRIPTION,
	WorkAcceptanceDisplayMode.PROBLEMS,
	WorkAcceptanceDisplayMode.CHECK_LISTS
];

interface IWorkAcceptanceViewTabsProps {
	objectId: string;
}

export const WorkAcceptanceViewTabs = ({objectId}: IWorkAcceptanceViewTabsProps) => {
	const {pushPath} = useChangePath();
	const displayMode = useAppSelector(
		s => s.settings.pages.workAcceptances.byPage[objectId]?.displayMode
	);
	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		(index: number) => {
			dispatch(changeWorkAcceptanceDisplayMode(objectId, tabsKeys[index]));
			pushPath(tabsKeys[index]);
		},
		[objectId, dispatch]
	);

	return (
		<Tabs
			className={{
				root: 'work-acceptance-page__plate-header-tabs',
				links: 'work-acceptance-page__plate-header-tabs-links'
			}}
			links={tabs}
			activeTab={tabsIndexes[displayMode]}
			onActiveTabChange={handleChange}
		/>
	);
};
