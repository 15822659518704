import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

/**
 * Формирует массив дат помещения
 */
export const formSpaceDates = (space: ISpace) => {
	const dates = [];
	if (space.decorationWarrantyExpiredDate) {
		dates.push({
			value: space.decorationWarrantyExpiredDate,
			label: 'Отделка'
		});
	}
	if (space.technicalEquipmentWarrantyExpiredDate) {
		dates.push({
			value: space.technicalEquipmentWarrantyExpiredDate,
			label: 'Инженерное/техническое оборудование'
		});
	}
	if (space.constructiveWarrantyExpiredDate) {
		dates.push({
			value: space.constructiveWarrantyExpiredDate,
			label: 'Конструктив'
		});
	}
	return dates.length ? dates : undefined;
};
