import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';

export const createAttachmentsDifference = (
	prev: IAttachment[] | undefined,
	next: IAttachment[] | undefined
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.ATTACHMENTS,
	name: 'вложения'
});
