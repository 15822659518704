import {createSelector} from 'reselect';
import {getInitialStateForPage, IMarkersPagesState, IMarkersPageState} from './reducers';
import {IState} from '@src/store/modules';

/**
 * Возвращает маркеры для конкретного объекта
 */
export const extractMarkersData = createSelector(
	(state: IState) => state.entities.markers,
	(state: IState, objectId?: string) => objectId || 'all',
	(data: IMarkersPagesState, objectId: string): IMarkersPageState =>
		data[objectId] || getInitialStateForPage()
);

/**
 * Возвращает маркеры в виде массива
 */
export const extractMarkersAsArray = createSelector([extractMarkersData], data =>
	data.allIds.map(id => data.byId[id]));