import React from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {useCacheStates} from '../hooks/useCacheStates';

export const CategoriesCacheStatus = () => {
	const [isFetching, isError, isStale, isPaused, dataUpdatedAt] = useCacheStates(
		categoriesQueryKeys.list()
	);
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Виды работ"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={isError ? 'Ошибка' : isStale ? 'Данные устарели' : undefined}
			inProgress={!!isFetching || isPaused}
		/>
	);
};
