import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompaniesAsMap} from '@src/store/modules/dictionaries/companies/selectors';
import React from 'react';
import {IPreparedSpaceTypeDecoration} from '../../interfaces/IPreparedSpaceTypeDecoration';

export const CompanyCell = ({row}: CellProps<IPreparedSpaceTypeDecoration>) => {
	if (!row.original.companyId) return null;

	const companiesMap = useAppSelector(extractCompaniesAsMap);

	return <div>{companiesMap.get(row.original.companyId)?.name || null}</div>;
};
