import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

interface IDescriptionProps {
	className?: string;
	task: IEnrichedTask;
}

export const TaskType = ({className, task}: IDescriptionProps) => (
	<EntityGridItem
		className={className}
		label="Тип:"
		inline
	>
		<div>{task.taskType?.name}</div>
	</EntityGridItem>
);
