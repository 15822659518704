import React from 'react';
import {SidebarMenu, SidebarMenuItem, SidebarMenuSeparator} from '@tehzor/ui-components';
import {useNavPermissions} from '@src/core/hooks/useNavPermissions';
import {usePageKey} from '../utils/usePageKey';
import {ISidebarMenuItemProps} from '@tehzor/ui-components/src/components/sidebar/SidebarMenuItem/SidebarMenuItem';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IAppSidebarMenuProps {
	type: ISidebarMenuItemProps['type'];

	closeSidebar?(): Promise<void>;
}

const AppSidebarMenu = ({type, closeSidebar}: IAppSidebarMenuProps) => {
	const permissions = useNavPermissions();
	const pageKey = usePageKey();
	const {pushPath} = useChangePath();

	const [, handleClick] = useAsyncFn(
		async (path?: string) => {
			if (path) {
				if (closeSidebar) {
					await closeSidebar();
				}
				pushPath(`/${path}`);
			}
		},
		[closeSidebar]
	);

	return (
		<SidebarMenu>
			<SidebarMenuItem
				icon={<i className="tz-object-28"/>}
				label="Объекты"
				type={type}
				active={pageKey === 'objects'}
				data="objects"
				onClick={handleClick}
			/>
			{permissions.problemsView && (
				<SidebarMenuItem
					icon={<i className="tz-problem-28"/>}
					label="Нарушения"
					type={type}
					active={pageKey === 'problems'}
					data="problems"
					onClick={handleClick}
				/>
			)}
			{permissions.inspectionsView && (
				<SidebarMenuItem
					icon={<i className="tz-inspection-28"/>}
					label="Осмотры"
					type={type}
					active={pageKey === 'inspections'}
					data="inspections"
					onClick={handleClick}
				/>
			)}
			{permissions.tasksView && (
				<SidebarMenuItem
					icon={<i className="tz-task-28"/>}
					label="Задачи"
					type={type}
					active={pageKey === 'tasks'}
					data="tasks"
					onClick={handleClick}
				/>
			)}
			{permissions.checksView && (
				<SidebarMenuItem
					icon={<i className="tz-check-28"/>}
					label="Проверки"
					type={type}
					active={pageKey === 'checks'}
					data="checks"
					onClick={handleClick}
				/>
			)}
			{permissions.workAcceptancesView && (
				<SidebarMenuItem
					icon={<i className="tz-work-acceptance-28"/>}
					label="Приёмки работ"
					type={type}
					active={pageKey === 'workAcceptances'}
					data="work-acceptances"
					onClick={handleClick}
				/>
			)}
			{permissions.internalAcceptancesView && (
				<SidebarMenuItem
					icon={<i className="tz-internal-acceptance-28"/>}
					label="Внутренние приемки"
					type={type}
					active={pageKey === 'internalAcceptances'}
					data="internal-acceptances"
					onClick={handleClick}
				/>
			)}
			{permissions.ownerAcceptancesView && (
				<SidebarMenuItem
					icon={<i className="tz-owner-acceptance-28"/>}
					label="Передачи собственникам"
					type={type}
					active={pageKey === 'ownerAcceptances'}
					data="owner-acceptances"
					onClick={handleClick}
				/>
			)}
			{permissions.warrantyClaimsView && (
				<SidebarMenuItem
					icon={<i className="tz-warranty-claim-28"/>}
					label="Гарантийные обращения"
					type={type}
					active={pageKey === 'warrantyClaims'}
					data="warranty-claims"
					onClick={handleClick}
				/>
			)}
			{permissions.documentsView && (
				<>
					<SidebarMenuSeparator type={type}/>
					<SidebarMenuItem
						icon={<i className="tz-check-list-manage-28"/>}
						label="Документы"
						type={type}
						active={pageKey === 'documents'}
						data="documents"
						onClick={handleClick}
					/>
				</>
			)}
			{permissions.contractsView && (
				<SidebarMenuItem
					icon={<i className="tz-contract-28"/>}
					label="Договоры"
					type={type}
					active={pageKey === 'contracts'}
					data="contracts"
					onClick={handleClick}
				/>
			)}
			{permissions.legalsView && (
				<SidebarMenuItem
					icon={<i className="tz-legal-entity-28"/>}
					label="Юрлица"
					type={type}
					active={pageKey === 'legals'}
					data="legals"
					onClick={handleClick}
				/>
			)}
			{permissions.statsView && (
				<>
					<SidebarMenuSeparator type={type}/>
					<SidebarMenuItem
						icon={<i className="tz-statistics-28"/>}
						label="Статистика"
						type={type}
						active={pageKey === 'statistics'}
						data="statistics"
						onClick={handleClick}
					/>
				</>
			)}
			{permissions.checkListsEdit && (
				<>
					<SidebarMenuSeparator type={type}/>
					<SidebarMenuItem
						icon={<i className="tz-check-list-manage-28"/>}
						label="Управление чек-листами"
						type={type}
						active={pageKey === 'manage/check-lists'}
						data="manage/check-lists"
						onClick={handleClick}
					/>
				</>
			)}
			{permissions.workingGroupEdit && (
				<SidebarMenuItem
					icon={<i className="tz-group-manage-28"/>}
					label="Управление рабочими группами"
					type={type}
					active={pageKey === 'manage/working-groups'}
					data="manage/working-groups"
					onClick={handleClick}
				/>
			)}
			{permissions.spaceTypeDecorationEdit && (
				<SidebarMenuItem
					icon={<i className="tz-space-type-manage-28"/>}
					label="Управление наборами типов отделки"
					type={type}
					active={pageKey === 'manage/space-type-decoration'}
					data="manage/space-type-decoration"
					onClick={handleClick}
				/>
			)}
			{permissions.categoriesSetView && (
				<SidebarMenuItem
					icon={<i className="tz-category-manage-28"/>}
					label="Управление наборами видов работ"
					type={type}
					active={pageKey === 'categoriesSets'}
					data="manage/categories-sets"
					onClick={handleClick}
				/>
			)}

			{permissions.problemTagsSetsEdit && (
				<SidebarMenuItem
					icon={<i className="tz-tag-manage-28"/>}
					label="Управление наборами меток нарушений"
					type={type}
					active={pageKey === 'manage/problem-tags-sets'}
					data="manage/problem-tags-sets"
					onClick={handleClick}
				/>
			)}

			{permissions.exportTemplatesEdit && (
				<SidebarMenuItem
					icon={<i className="tz-export-template-manage-28"/>}
					label="Управление шаблонами экспорта"
					type={type}
					active={pageKey === 'manage/export-templates'}
					data="manage/export-templates"
					onClick={handleClick}
				/>
			)}
			{permissions.spaceStatusesSetsView && (
				<SidebarMenuItem
					icon={<i className="tz-space-status-manage-28"/>}
					label="Управление наборами статусов помещений"
					type={type}
					active={pageKey === 'manage/space-statuses-sets'}
					data="manage/space-statuses-sets"
					onClick={handleClick}
				/>
			)}
		</SidebarMenu>
	);
};

export default AppSidebarMenu;
