import {useQuery} from '@tanstack/react-query';
import {IGetSpaceOwnersResponse} from '@src/api/backend/spaceOwners';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';

export const useSpaceOwners = (): IGetSpaceOwnersResponse | undefined => {
	const {data} = useQuery<IGetSpaceOwnersResponse, IError>({
			queryKey: [...restDictionariesQueryKeys.spaceOwners()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке собственников');
		}
	});
	return data;
};

export const spaceOwnersAsArray = (spaceOwners?: IGetSpaceOwnersResponse) =>
	spaceOwners?.allIds.map((spaceOwnerId: string) => spaceOwners?.byId[spaceOwnerId]) || [];