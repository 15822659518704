import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {extractSpacesPageSettings} from '../../settings/pages/spaces/selectors';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {getObjectsData} from '../objects/selectors';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';

const extractAllIds = (state: IState) => state.dictionaries.checkLists.allIds || [];
const extractById = (state: IState) => state.dictionaries.checkLists.byId || {};
const getObjectId = (state: IState, objectId: string) => objectId;
const getListId = (state: IState, listId: string | undefined) => listId;

/**
 * Возвращает чек-листы в виде массива
 */
export const extractCheckListsAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map(id => byId[id])
);

/**
 * Возвращает чек-лист по id
 */
export const extractCheckListById = createSelector([extractById, getListId], (byId, listId) => {
	if (listId) {
		return byId[listId];
	}
	return undefined;
});

/**
 * Возвращаем чек листы в виде массива для объекта
 */
export const extractCheckListsAsArrayByObjectId = createSelector(
	[extractCheckListsAsArray, getObjectId, getObjectsData],
	(data, objectId, objectsData) =>
		data.filter(checklist => {
			if (checklist.objects && checklist.objects.length > 0) {
				const finalChecklistObjectIds: string[] = [];

				// Создаем массив объектов чеклиста, учитывая родительские объекты
				for (const checklistObjectId of checklist.objects) {
					const parentId = objectsData.byId[checklistObjectId]?.parentId;

					if (parentId && !finalChecklistObjectIds.includes(parentId)) {
						finalChecklistObjectIds.push(parentId);
					}

					finalChecklistObjectIds.push(checklistObjectId);
				}

				return finalChecklistObjectIds.includes(objectId);
			}

			return true;
		})
);

export const extractCheckListsAsArrayFilteredBySelectedSpaceType = createSelector(
	[extractCheckListsAsArrayByObjectId, extractSpacesPageSettings],
	(checkLists, {stage, type}) => {
		let result: ICheckList[] = [...checkLists];

		if (type) {
			result = result.filter(checkList =>
				checkList.spaceTypes?.includes(type as SpaceTypeId));
		}

		if (stage) {
			result = result.filter(checkList => checkList.stage === stage);
		}

		return result;
	}
);

/**
 * Возвращаем чек листы в виде массива для объекта для приёмок работ
 */
export const extractCheckListsAsArrayForWorkAcceptances = createSelector(
	[extractCheckListsAsArrayByObjectId],
	checkLists =>
		checkLists.filter(checkList => checkList.type === CheckListTypeId.WORK_ACCEPTANCES)
);

/**
 * Возвращаем чек листы в виде массива для объекта для конкретного типа структур
 */
export const extractCheckListsAsArrayFilteredByStructureType = createSelector(
	[extractCheckListsAsArrayByObjectId, (state: IState, objectId: string, type: string) => type],
	(checkLists, type) =>
		checkLists.filter(checkList => checkList.structureType && checkList.structureType === type)
);
