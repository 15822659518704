import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';

export type IObjectStagesState = ILoadableEntitiesState<IObjectStage>;

export const objectStages = createReducer<IObjectStagesState>(
	getLoadableEntitiesInitialState<IObjectStage>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IObjectStage>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IObjectStage>()
	}
);
