import React, {cloneElement, useCallback} from 'react';
import './DialogMenu.less';
import Dialog, {IDialogProps} from '../../dialogs/Dialog';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import useToggle from 'react-use/lib/useToggle';
import {convertClassNames} from '../../../utils/convertClassNames';
import classNames from 'classnames';
import {useClonedMenu} from '../../../hooks/useClonedMenu';
import {RefCallback} from 'react-laag/dist/types.d';

export interface IDialogMenuTriggerElProps {
	[key: string]: unknown;

	isOpen?: boolean;
	disabled?: boolean;

	onClick?: () => void;
}

export interface IDialogMenuTriggerFnProps {
	isOpen?: boolean;
	open?: () => void;
	close?: () => void;
	toggle?: () => void;
	ref?: RefCallback;
	disabled?: boolean;
}

export interface IDialogMenuProps<V, M extends IMenuComponentProps<V>> {
	className?: IDialogProps['className'];
	style?: React.CSSProperties;
	trigger: React.ReactElement<IDialogMenuTriggerElProps>
		| ((props: IDialogMenuTriggerFnProps) => React.ReactNode);
	children?: React.ReactElement<M> | Array<React.ReactElement<IMenuItemProps>>;
	dialogProps?: Pick<IDialogProps,
		'appRoot'
		| 'shouldFocusAfterRender'
		| 'shouldCloseOnOverlayClick'
		| 'shouldCloseOnEsc'
		| 'shouldDisableScroll'>;
}

const DialogMenu = <V, M extends IMenuComponentProps<V>>(props: IDialogMenuProps<V, M>) => {
	const {className, style, trigger, children, dialogProps} = props;

	const [isOpen, toggleOpen] = useToggle(false);

	const open = useCallback(() => {
		toggleOpen(true);
	}, []);

	const close = useCallback(() => {
		toggleOpen(false);
	}, []);

	// Клонирование children для отложенного запуска обработчиков onClick и onChange у MenuItem и Menu.
	// Без данного действия анимация закрытия Dialog'а происходит с подлагиванием
	const clonedChildren = useClonedMenu(children, close, 310);

	const classes = convertClassNames(className);
	classes.root = classNames('dialog-menu', classes.root);
	classes.layer = classNames('dialog-menu__layer', classes.layer);
	classes.body = classNames('dialog-menu__body', classes.body);

	return clonedChildren ? (
		<>
			{typeof trigger === 'function'
				? trigger({
					isOpen,
					open,
					close,
					toggle: toggleOpen
				})
				: cloneElement(trigger, {
					isOpen,
					onClick: toggleOpen
				})}

			<Dialog
				{...dialogProps}
				className={classes}
				style={style}
				isOpen={isOpen}
				hideCloseButton
				onRequestClose={close}
			>
				{clonedChildren}
			</Dialog>
		</>
	) : (
		<>{typeof trigger === 'function' ? trigger({disabled: true}) : cloneElement(trigger, {disabled: true})}</>
	);
};

DialogMenu.displayname = 'DialogMenu';

export default DialogMenu;