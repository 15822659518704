import React, {useCallback} from 'react';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ItemButton} from './ItemButton';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {extractTreeCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';

interface IItemFooterProps {
	active?: boolean;
	record?: ICheckRecord;
	item: ICheckItem;
	stage: ObjectStageIds;
	setChangingState: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
}

const getStatus = (record: ICheckRecord | undefined, nextStatus: CheckRecordStatusId) =>
	(record?.status === nextStatus ? CheckRecordStatusId.NOT_CHECKED : nextStatus);

export const ItemActions = ({
	active,
	record,
	item,
	stage,
	setChangingState,
	disabled
}: IItemFooterProps) => {
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId?: string;
	}>();
	const treeItem = useAppSelector(s => extractTreeCheckItemById(s, item.checkListId, item.id));
	const {changeCheckItemRecord} = useChangeCheckRecord(objectId, item);
	const buttonProps = {record, item};

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите принять категорию?',
		'Все дочерние категории будут переведены в статус "Принято", а связанные с ними нарушения - в статус "Устранено"'
	);

	const accept = useCallback(async () => {
		let confirmationResult = true;

		if (record?.status !== CheckRecordStatusId.ACCEPTED && treeItem?.children?.length) {
			confirmationResult = await getClosingConfirmation();
		}

		if (!confirmationResult) {
			return;
		}

		setChangingState(true);
		if (record) {
			changeCheckItemRecord({
				item,
				objectId: objectId || '',
				spaceId: undefined,
				workAcceptanceId,
				stage,
				status: getStatus(record, CheckRecordStatusId.ACCEPTED),
				record
			});
		}
	}, [objectId, stage, item, record, setChangingState, workAcceptanceId]);

	const reject = useCallback(() => {
		setChangingState(true);
		if (record) {
			changeCheckItemRecord({
				item,
				objectId: objectId || '',
				spaceId: undefined,
				workAcceptanceId,
				stage,
				status: getStatus(record, CheckRecordStatusId.REJECTED),
				record
			});
		}
	}, [objectId, stage, item, record, setChangingState, workAcceptanceId]);

	const partiallyAccept = useCallback(() => {
		setChangingState(true);
		if (record) {
			changeCheckItemRecord({
				item,
				objectId,
				spaceId: undefined,
				workAcceptanceId,
				stage,
				status: getStatus(record, CheckRecordStatusId.PARTIALLY_ACCEPTED),
				record
			});
		}
	}, [objectId, stage, item, record, setChangingState, workAcceptanceId]);

	return active || record?.status !== CheckRecordStatusId.ACCEPTED ? (
		<ClickPreventWrap className="check-list-items__item-actions">
			{/* TODO: переверстать кнопку */}
			<ItemButton
				onClick={reject}
				{...buttonProps}
				status={CheckRecordStatusId.REJECTED}
				icon={<i className="tz-close-16"/>}
				disabled={disabled}
			/>

			<ItemButton
				onClick={partiallyAccept}
				{...buttonProps}
				status={CheckRecordStatusId.PARTIALLY_ACCEPTED}
				icon={<i className="tz-percent"/>}
				disabled={disabled}
			/>

			<ItemButton
				onClick={accept}
				{...buttonProps}
				status={CheckRecordStatusId.ACCEPTED}
				icon={<i className="tz-mark-16"/>}
				disabled={disabled}
			/>

			{closingDialog}
		</ClickPreventWrap>
	) : null;
};
