import React, {useCallback, useEffect, useState} from 'react';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingSpace} from '@tehzor/tools/interfaces/spaces/ISavingSpace';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {
	convertToSave,
	errorsFns,
	hasAttachmentsError,
	isEdited,
	useEditableSpaceState
} from '@src/core/hooks/states/useEditableSpaceState';
import EditableSpace from '../EditableSpace';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

const makeFieldsSettings = (adding?: boolean): Record<string, IObjectFieldSetting> =>
	(adding
		? {
				name: {fieldId: 'name', isRequired: true},
				altName: {fieldId: 'altName', isRequired: false},
				type: {fieldId: 'type', isRequired: true},
				status: {fieldId: 'status', isRequired: true},
				floor: {fieldId: 'floor', isRequired: false},
				plannedArea: {fieldId: 'plannedArea', isRequired: true},
				actualArea: {fieldId: 'actualArea', isRequired: true},
				attachments: {fieldId: 'attachments', isRequired: false},
				typeDecoration: {fieldId: 'typeDecoration', isRequired: false},
				decorationWarrantyExpiredDate: {
					fieldId: 'decorationWarrantyExpiredDate',
					isRequired: false
				},
				constructiveWarrantyExpiredDate: {
					fieldId: 'decorationWarrantyExpiredDate',
					isRequired: false
				},
				technicalEquipmentWarrantyExpiredDate: {
					fieldId: 'decorationWarrantyExpiredDate',
					isRequired: false
				}
		  }
		: {
				name: {fieldId: 'name', isRequired: true},
				altName: {fieldId: 'altName', isRequired: false},
				type: {fieldId: 'type', isRequired: true},
				floor: {fieldId: 'floor', isRequired: false},
				plannedArea: {fieldId: 'plannedArea', isRequired: true},
				actualArea: {fieldId: 'actualArea', isRequired: true},
				attachments: {fieldId: 'attachments', isRequired: false},
				typeDecoration: {fieldId: 'typeDecoration', isRequired: false},
				decorationWarrantyExpiredDate: {
					fieldId: 'decorationWarrantyExpiredDate',
					isRequired: false
				},
				constructiveWarrantyExpiredDate: {
					fieldId: 'decorationWarrantyExpiredDate',
					isRequired: false
				},
				technicalEquipmentWarrantyExpiredDate: {
					fieldId: 'decorationWarrantyExpiredDate',
					isRequired: false
				}
		  });

/**
 * Логика редактирования и сохранения помещения (нового или существующего)
 */
export const useEditableSpace = (
	objectId: string,
	space: ISpace | undefined,
	saving: boolean
): [React.ReactNode, () => Promise<ISavingSpace | undefined>, (
) => void, boolean] => {
	const fieldsSettings = makeFieldsSettings(!space);

	const [editingState, editingDispatch] = useEditableSpaceState(space);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, space) || isFilesExist(uploadingFilesState.value));
	}, [editingState, uploadingFilesState.value, space]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, errorsFns, fieldsSettings)
			|| (hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (!isEdited(editingState, space) && (!isFilesExist(files) || someFilesHaveError(files))) {
			return undefined;
		}
		const savingData = convertToSave(editingState, space, true);
		savingData.newAttachments = convertFilesToSave(files);
		return savingData;
	}, [
		waitUploading,
		editingState,
		fieldsSettings,
		space,
		editingDispatch,
		uploadingFilesDispatch
	]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: space
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [editingDispatch, space, uploadingFilesDispatch]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [space]);

	const fields = (
		<EditableSpace
			objectId={objectId}
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
