import React from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

const CompanyNameCell = ({row}: CellProps<IObject>) => {
	const object = row.original;
	const companies = useAppSelector(extractCompaniesAsArray);
	const comp = companies.find(el => el.id === object.companyId);
	return comp?.name ? <div>{comp.name}</div> : null;
};

export default CompanyNameCell;
