import React, {useCallback, useState} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {exportCheck} from '@src/store/modules/entities/check/actions';

const availableDestinations = [ExportTemplatesDestinationId.CHECK];

export function useCheckExport(objectId: string): [React.ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const [isOpen, setOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (exportingId) {
				await dispatch(
					exportCheck(
						template.id,
						createDocument,
						email,
						objectId,
						exportingId,
						undefined,
						undefined
					)
				);
			}
			setOpen(false);
		},
		[objectId, exportingId]
	);

	const dialog = (
		<ExportDialog
			pageKey="report"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
