import React, {Dispatch, useCallback, useMemo} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {extractSpaceTypeDecorationsAsArray} from '@src/store/modules/entities/spaceTypeDecorations/selectors';
import './SpaceTypeDecorationSelect.less';
import {extractSpaceTypeDecorationSetsAsArray} from '@src/store/modules/entities/spaceTypeDecorationSets/selectors';
import {makeTreeDataSpaceTypeDecoration} from '../SpaceTypeDecorationTreeSelect/utils/makeTreeData';

interface ISpaceTypeDecorationSelectProps<S, E> {
	field?: keyof S;
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	externalTypeDecorations?: ISpaceTypeDecoration[];
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: string | undefined,
	typeDecorationsById: Record<string, ISpaceTypeDecoration>
) => {
	if (value !== undefined && typeDecorationsById?.[value]) {
		return typeDecorationsById[value]?.name;
	}

	return undefined;
};

const arrowIcon = <i className="tz-simple-arrow-20"/>;

/**
 * Возврашает поле одиночного выбора типа отделки помещения сгруппированные по наборам,
 * типы отделки по умолчанию получаются селектором из state.entities.spaceTypeDecorations
 * или пердается снаружи через пропс externalTypeDecorations
 */
export const SpaceTypeDecorationSelect = <S extends {typeDecoration?: 'typeDecoration'}, E>({
	className,
	style,
	field = 'typeDecoration',
	label = 'Тип отделки помещения',
	value,
	externalTypeDecorations,
	editingDispatch,
	required,
	disabled,
	hasError
}: ISpaceTypeDecorationSelectProps<S, E>) => {
	const typeDecorationsById = useAppSelector(s => s.entities.spaceTypeDecorations.byId);

	let typeDecorations = useAppSelector(extractSpaceTypeDecorationsAsArray);
	if (externalTypeDecorations) {
		typeDecorations = externalTypeDecorations;
	}

	const typeDecorationSets = useAppSelector(extractSpaceTypeDecorationSetsAsArray);

	const treeData = useMemo(() =>
		makeTreeDataSpaceTypeDecoration(typeDecorations, typeDecorationSets),
	[typeDecorations, typeDecorationSets]);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	const onClear = useCallback(() => {
		editingDispatch({type: 'update', field, value: undefined});
	}, []);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, typeDecorationsById)}
						cleanable={!!value}
						onClearClick={onClear}
						icon={arrowIcon}
						error={required && hasError ? 'Выберите тип отделки помещения' : undefined}
						disabled={disabled}
					/>
				)}
			>
				<TreeSelect
					data={treeData}
					value={value}
					onChange={handleChange}
				/>
			</SelectPopup>
		</div>
	);
};
