import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import _ from 'lodash';

export const extractAllLatestChecksAsArray = createSelector(
	(state: IState) => state.dictionaries.latestUserChecks,
	({byId, allIds}) => allIds.map(id => byId[id])
);

/**
 * Возвращает последние проверки для конкретного объекта в виде массива
 */
export const extractLatestChecksAsArray = createSelector(
	extractAllLatestChecksAsArray,
	(state: IState) => state.auth.profile.id,
	(state: IState, objectId: string) => objectId,
	(state: IState, objectId: string, spaceId?: string) => spaceId,
	(arr, userId, objectId, spaceId) =>
		arr.filter(item =>
			item.objectId === objectId
			&& item.createdBy?.id === userId
			&& (!spaceId || spaceId === item.links?.spaceId))
);

export const extractLocalChecksAsArray = createSelector(
	(state: IState) => state.localEntities.checks,
	(state: IState, objectId: string) => objectId,
	(state: IState, objectId: string, spaceId?: string) => spaceId,
	(data, objectId, spaceId) => {
		if (spaceId) {
			return data.filter(item => item.links?.spaceId === spaceId);
		}
		return data.filter(item => item.objectId === objectId);
	}
);

/**
 * Возвращает последнюю проверку для конкретного объекта
 */
export const extractLatestCheckId = createSelector(
	extractLatestChecksAsArray,
	extractLocalChecksAsArray,
	(data, local): string | undefined => {
		if (local.length) {
			return _.maxBy(local, 'modifiedAt')?.key;
		}
		if (data.length) {
			return _.maxBy(data, 'modifiedAt')?.id;
		}
		return undefined;
	}
);
