import {createReducer} from '@reduxjs/toolkit';
import {list as types} from '../constants/index';
import {GET_REQUEST} from '@src/store/modules/entities/tasks/constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {ITasksFiltersState, ITasksSortState} from '@src/store/modules/settings/pages/tasks/interfaces';

export interface ITasksPageSettingsListState {
	filters: ITasksFiltersState;
	sort: ITasksSortState;
	pageSize: number;
	offset: number;
	selectedRows: string[];
}

export const getInitialStateForTasksListPage = (): ITasksPageSettingsListState => ({
	filters: {},
	sort: {
		createdAt: false
	},
	pageSize: 20,
	offset: 0,
	selectedRows: []
});

export const list = createReducer<ITasksPageSettingsListState>(
	getInitialStateForTasksListPage(),
	{
		[GET_REQUEST]: state => {
			if (!state || !state.hasOwnProperty('pageSize')) {
				return getInitialStateForTasksListPage();
			}
			return state;
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<ITasksFiltersState>}
		) => ({
			...state,
			filters: payload.filters
		}),

		[types.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: {selectedRows: string[]}}
		) => ({
			...state,
			selectedRows: payload.selectedRows
		}),

		[types.CLEAR_FILTERS]: state => ({
				...state,
				filters: {} as ITasksFiltersState
			}),

		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<ITasksSortState>}
		) => ({
			...state,
			sort: payload.sort
		}),

		[types.CHANGE_PAGE_SIZE]: (
			state,
			{payload}: {payload: IChangePageSizePayload}
		) => ({
			...state,
			pageSize: payload.pageSize
		}),

		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {offset: number}}
		) => ({
			...state,
			offset: payload.offset
		})
	}
);
