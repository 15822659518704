import React, {useCallback} from 'react';
import {InfiniteScroller, ProblemComment} from '@tehzor/ui-components';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {Tree} from 'array-to-tree';
import classNames from 'classnames';
import CommentMenu from './CommentMenu';
import {useCommentDeleting} from '../hooks/useCommentDeleting';
import {useReplyExport} from '../hooks/useReplyExport';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';

interface IListProps {
	className?:
		| string
		| {
				root?: string;
				content?: string;
				item?: string;
		  };
	objectId: string;
	problemId: string;
	comments: Array<Tree<IProblemComment>>;
	hasMore: boolean;
	selectedId?: string;

	onLoad?: () => void;
	onSubCommentAdd?: (id: string) => void;
	onCommentEdit: (id: string) => void;
	onReplyEdit: (id: string) => void;
	onImageClick?: (id: string, index: number) => void;
}

const List = (props: IListProps) => {
	const {
		className,
		objectId,
		problemId,
		comments,
		hasMore,
		selectedId,
		onLoad,
		onSubCommentAdd,
		onCommentEdit,
		onReplyEdit,
		onImageClick
	} = props;

	const user = useAppSelector(s => s.auth.profile);
	const [deleteDialog, handleDelete] = useCommentDeleting(objectId, problemId);
	const [exportDialog, handleExport] = useReplyExport(objectId);

	const handleEdit = useCallback(
		(id: string, official?: boolean | undefined) => {
			(official ? onReplyEdit : onCommentEdit)(id);
		},
		[onCommentEdit, onReplyEdit]
	);

	const classes = convertClassNames(className);

	return (
		<>
			<InfiniteScroller
				className={{
					root: classNames('problem-comments__list', classes.root),
					content: classes.content
				}}
				loader={<div className="problem-comments__loader">Загрузка...</div>}
				enabled={hasMore}
				reversed
				onLoad={onLoad}
			>
				{comments.length > 0
					? comments.map(item => (
						<ProblemComment
							key={item.id}
							className={{plate: classes.item}}
							data={item}
							isSelected={id => id === selectedId}
							controls={(comment: IProblemComment) => (
								<CommentMenu
									user={user}
									comment={comment}
									onExport={handleExport}
									onEdit={handleEdit}
									onDelete={handleDelete}
								/>
							)}
							onSubCommentAdd={onSubCommentAdd}
							onImageClick={onImageClick}
						/>
					  ))
					: [
						<div
							key="empty"
							className={classNames('problem-comments__empty-placeholder')}
						>
							<i className="tz-comment-88 problem-comments__empty-placeholder-icon"/>
							<br/>
							<span className="problem-comments__empty-placeholder-text">
								Пока ответов нет.
								<br/>
								Ответьте первым!
							</span>
						</div>
					  ]}
			</InfiniteScroller>

			{deleteDialog}
			{exportDialog}
		</>
	);
};

export default List;
