import React, {forwardRef, Ref, useCallback, useImperativeHandle} from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useEditableProblem} from '@src/components/EditableProblem/hooks/useEditableProblem';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {useUpdateEffect} from 'react-use';
import {useAddNewProblem} from '@src/core/hooks/mutations/problem/useAddProblem';
import {onlineManager} from '@tanstack/react-query';
import {AddingEntities} from '@src/api/mutations';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';

interface IAddingProblemProps {
	objectId: string;
	links?: IProblem['links'];
	stage: ObjectStageIds;
	scope?: string;
	defaultData?: ISavingProblem;
	saving: boolean;
	problemToCopyId?: string;
	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingProblemRefProps {
	getSavingData: (
		useLocalFiles?: boolean
	) => Promise<(ISavingProblem & {templateId?: string}) | undefined>;
	save: (
		savingData?: ISavingProblem & {templateId?: string},
		extraLinks?: IProblem['links'],
		cacheKey?: string
	) => Promise<string | undefined | void> | string | void;
	cancel: () => void;
	saveCache?: (
		savingData?: ISavingProblem & {templateId?: string},
		extraLinks?: IProblem['links']
	) => Promise<
		| {
			cacheKey: string;
			type: AddingEntities;
			links: IProblem['links'];
		}
		| undefined
	>;
}

const AddingProblem = (
	{
		objectId,
		links,
		stage,
		scope,
		defaultData,
		saving,
		problemToCopyId,
		onClose,
		setSaving
	}: IAddingProblemProps,
	ref?: Ref<IAddingProblemRefProps>
) => {
	const networkStatus = onlineManager.isOnline();
	const object = useAppSelector(s => extractObject(s, objectId));
	const {createNewProblemCache, saveProblem} = useAddNewProblem(object);
	const [fields, getSavingData, , isBlocking] = useEditableProblem(
		objectId,
		stage,
		scope,
		defaultData,
		saving,
		true,
		!!problemToCopyId,
		undefined,
		undefined,
		undefined,
		undefined,
		links
	);

	useUpdateEffect(() => {
		if (!networkStatus) {
			setSaving(false);
		}
	}, [networkStatus]);
	const saveCache = useCallback(
		async (
			savingData?: ISavingProblem & {templateId?: string},
			extraLinks?: IProblem['links']
		) => {
			setSaving(true);
			if (savingData) {
				const {templateId, ...data} = savingData;
				const cache = await createNewProblemCache({
					objectId,
					links: {...links, ...extraLinks, templateId},
					stage,
					fields: data
				});
				onClose();
				setSaving(false);
				return {
					cacheKey: cache.id,
					links: cache.links,
					type: AddingEntities.PROBLEM
				};
			}
			setSaving(false);
			return undefined;
		},
		[setSaving, onClose, createNewProblemCache, objectId, links, stage]
	);
	const save = useCallback(
		(
			savingData?: ISavingProblem & {templateId?: string},
			extraLinks?: IProblem['links'],
			cacheKey?: string
		) => {
			if (savingData) {
				const {templateId, ...data} = savingData;
				const key = cacheKey || '';
				data.localCreatedAt = Number(key);

				saveProblem({
					objectId,
					links: {...links, ...extraLinks, templateId},
					stage,
					fields: data,
					key
				});
			}
		},
		[objectId, links, stage, saveProblem]
	);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [getClosingConfirmation, isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, saveCache, cancel, getSavingData}), [
		save,
		saveCache,
		cancel,
		getSavingData
	]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
};

export default forwardRef<IAddingProblemRefProps, IAddingProblemProps>(AddingProblem);
