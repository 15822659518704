import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IDeleteObjectResponse, makeObjectDeleteRequest} from '@src/api/backend/object';
import {addErrorToast} from '@src/utils/toasts';

export interface IDeleteObjectPayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => ({
	type: types.DELETE_SUCCESS,
	payload: {id}
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при удалении объекта');
	}
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет объект
 *
 * @param objectId id объекта
 */
export const deleteObject = (objectId: string) =>
	createApiAction<IDeleteObjectResponse>(
		request,
		() => success(objectId),
		failure,
		() => makeObjectDeleteRequest(objectId)
	);
