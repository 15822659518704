import React from 'react';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

interface IInspectionInfoProps {
	className?: string;
	style?: React.CSSProperties;
	data: IInspection;
	depth?: number;
}

const InspectionInfo = ({className, style, data, depth = 0}: IInspectionInfoProps) => (
	<EntityInfo
		className={className}
		style={style}
		title={`Осмотр №${data.number ?? data.localNumber}`}
		subTitle={depth === 0 ? <EntityInfoDate value={data.createdAt}/> : undefined}
		icon={<i className="tz-inspection-28"/>}
		iconColor="#8695A6"
		iconSize={depth > 0 ? 32 : 44}
	/>
);

export default InspectionInfo;
