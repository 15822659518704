import {useChangePath} from '@src/core/hooks/useChangePath';
import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';

interface IAddButtonProps {
	canAdd?: boolean;
}

const plusIcon = <i className="tz-plus-20"/>;

export const AddButton = ({canAdd = false}: IAddButtonProps) => {
	const {pathname} = useLocation();
	const {pushPath} = useChangePath();
	const isLargeTablet = useIsLargeTablet();
	const handleClick = useCallback(() => {
		pushPath(`${pathname}/empty`, {canGoBack: true});
	}, [pathname]);

	return canAdd ? (
		isLargeTablet ? (
			<Button
				className="space-statuses-sets__"
				type="accent-blue"
				leftIcon={plusIcon}
				label="Добавить набор"
				onClick={handleClick}
			/>
		) : (
			<FloatingActionButton
				icon={plusIcon}
				label="Добавить"
				minifyOnScroll
				scrollContainer="scroll-container"
				onClick={handleClick}
			/>
		)
	) : null;
};
