import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as planTypes from '../../entities/plan/constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {IGetPlansPayload, IEditPlansOrderPayload} from './actions';
import {
	IDeletePlanPayload,
	ICopyPlanPayload,
	IAddPlanPayload,
	IEditPlanPayload
} from '../../entities/plan/actions';
import * as ownerAcceptancesTypes from '../../entities/ownerAcceptances/constants';
import {
	ILoadableEntitiesState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export interface IPlansState {
	[objectId: string]: ILoadableEntitiesState<IPlan>;
}

const updatePlansState = (state: IPlansState, action: {payload: IGetPlansPayload}) => {
	if (!action.payload.objectIds) {
		state.all = handleLoadableEntitiesGetting<IPlan>()(state.all, action);
		return;
	}
	for (const objectId of action.payload.objectIds) {
		const byId = Object.keys(action.payload.byId)
			.filter(key => action.payload.byId[key].objectId === objectId)
			.reduce<INormalizedData<IPlan>['byId']>((result, key) => {
				result[key] = action.payload.byId[key];
				return result;
			}, {} as INormalizedData<IPlan>['byId']);
		const allIds = Object.keys(byId);
		state[objectId] = handleLoadableEntitiesGetting<IPlan>()(state[objectId], {
			payload: {byId, allIds}
		});
	}
};
export const plans = createReducer<IPlansState>(
	{},
	{
		[types.GET_SUCCESS]: updatePlansState,
		[types.EDIT_ORDER_SUCCESS]: (state, action: {payload: IEditPlansOrderPayload}) => {
			state[action.payload.objectId] = handleLoadableEntitiesGetting<IPlan>()(
				state[action.payload.objectId],
				action
			);
		},
		[planTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddPlanPayload}) => {
			const page = state[payload.objectId];
			if (page) {
				page.byId[payload.id] = payload;
				page.allIds.push(payload.id);
			}
		},
		[planTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditPlanPayload}) => {
			const page = state[payload.objectId];
			if (page) {
				page.byId[payload.id] = payload;
			}
		},
		[planTypes.COPY_SUCCESS]: (state, {payload}: {payload: ICopyPlanPayload}) => {
			const page = state[payload.objectId];
			if (page) {
				page.byId = {...page.byId, ...payload.byId};
				page.allIds = [...page.allIds, ...payload.allIds];
			}
		},
		[planTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeletePlanPayload}) => {
			const page = state[payload.objectId];
			if (page) {
				delete page.byId[payload.planId];
				page.allIds = page.allIds.filter(id => id !== payload.planId);
			}
		},
		// Нужно сбрасывать expires при изменении ownerAcceptances offset
		[ownerAcceptancesTypes.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string, offset: number}}
		) => {
			const page = state[payload.objectId];
			if (page) {
				page.loadedAt = undefined;
			}
		},
		[CLEAR_STORE]: () => ({})
	}
);
