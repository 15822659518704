import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {IStructuresProblemsStatsState} from '@src/store/modules/pages/structures/reducers/problemsData';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IConvertedProblemsData} from '@tehzor/tools/interfaces/structures/IConvertedProblemsData';
import {IConvertedWorkAcceptancesData} from '@tehzor/tools/interfaces/structures/IConvertedWorkAcceptancesData';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId, ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {getLatestModified} from '@src/utils/selectorUtils';
import {IStructuresFiltersState} from '@src/store/modules/settings/pages/structures/reducers/byPage';

/**
 * Возвращает количества нарушений по статусам для текущей стадии, если стадия не указана, то по всем стадиям
 *
 * @param problems данные по нарушениям из структуры
 * @param stage текущая стадия
 */
const getProblemsDataByStage = (
	problems: IEntityProblemsData['problems'],
	stage?: ObjectStageIds
): Record<string, { count: number, critical: boolean }> | undefined => {
	if (stage) {
		return problems[stage];
	}

	return Object.values(problems).reduce<Record<string, { count: number, critical: boolean }>>(
		(prev, item) => {
			for (const status in item) {
				if (item.hasOwnProperty(status)) {
					if (prev[status]) {
						prev[status].count += item[status].count;
						prev[status].critical = prev[status].critical || item[status].critical;
					} else {
						prev = {
							...prev,
							[status]: {count: item[status].count, critical: item[status].critical}
						};
					}
				}
			}
			return prev;
		},
		{}
	);
};

const convertProblemsData = (
	problems: IEntityProblemsData['problems'] | undefined,
	problemStatuses: INormalizedData<IProblemStatus>,
	selectedStage?: ObjectStageIds
): IConvertedProblemsData[] => {
	if (!problems) {
		return [];
	}

	let total = 0;
	const result = [];

	const stageProblems = getProblemsDataByStage(problems, selectedStage);

	if (stageProblems) {
		for (const statusId of problemStatuses.allIds) {
			if (stageProblems.hasOwnProperty(statusId) && stageProblems[statusId].count > 0) {
				result.push({
					key: statusId,
					value: stageProblems[statusId].count,
					percent: 0,
					color: problemStatuses.byId[statusId]?.color || '#00000000',
					startPoint: 0,
					critical:
						statusId !== ProblemStatusId.FIXED
							? stageProblems[statusId].critical
							: undefined
				});
				total += stageProblems[statusId].count;
			}
		}
		let offset = 0;
		for (const stat of result) {
			stat.percent = (stat.value / total) * 100;
			stat.startPoint = -offset;
			offset += stat.percent;
		}
	}
	return result;
};

const convertWorkAcceptancesData = (
	workAcceptances: IListWorkAcceptance[] | undefined,
	workAcceptanceStatuses: INormalizedData<IWorkAcceptanceStatus>
): IConvertedWorkAcceptancesData[] => {
	if (!workAcceptances) {
		return [];
	}

	const result = [];

	for (const statusId of workAcceptanceStatuses.allIds) {
		const workAcceptancesCount = workAcceptances.filter((wa: IListWorkAcceptance) => wa.status === statusId).length;

		if (workAcceptancesCount) {
			result.push({
				key: statusId,
				value: workAcceptancesCount,
				color: workAcceptanceStatuses.byId[statusId]?.color || '#00000000'
			});
		}
	}
	return result;
};

const categoriesFromWorkAcceptances = (
	workAcceptances: IListWorkAcceptance[] | undefined
): string[] => {
	if (!workAcceptances) {
		return [];
	}
	const result = new Set<string>();
	for (const workAcceptance of workAcceptances) {
		if (workAcceptance && workAcceptance.categoryId) {
			result.add(workAcceptance.categoryId);
		}
	}
	return Array.from(result);
};

export const convertStructures = (
	structures: IListStructure[],
	structuresStats: {
		problemsData: Record<string, IStructuresProblemsStatsState>;
	},
	workAcceptances: Record<string, IListWorkAcceptance[]>,
	objectId: string
): IListStructure[] =>
	structures.map(structure => ({
		...structure,
		problems: structuresStats.problemsData[objectId]?.data[structure.id],
		workAcceptances: workAcceptances[structure.id]
	}));

export const convertCheckLists = (
	structure: IListStructure,
	checkLists: Record<string, ICheckList[]>,
	checkListsRecords: ICheckRecord[],
	checkRecordStatuses: Record<string, ICheckRecordStatus>
): Array<{ key: string, name: string, status: ICheckRecordStatus }> => {
	const structureCheckLists = checkLists[structure.type];

	if (!structure.workAcceptances) {
		return [];
	}
	const workAcceptanceIds = structure.workAcceptances.map(wa => wa.id);

	return structureCheckLists.map(checkList => {
		const records = checkListsRecords.filter(record =>
			record.links.workAcceptanceId
			&& workAcceptanceIds.includes(record.links.workAcceptanceId)
			&& record.links.checkListId === checkList.id
			&& !record.links.checkItemId);
		const record = getLatestModified<ICheckRecord>(records);
		const resp = {
			key: checkList.id,
			name: checkList.name,
			status: checkRecordStatuses[record.status || CheckRecordStatusId.NOT_CHECKED]
		};
		return resp;
	});
};

export const enrichStructures = (
	structures: IListStructure[],
	problemStatuses: INormalizedData<IProblemStatus>,
	workAcceptanceStatuses: INormalizedData<IWorkAcceptanceStatus>,
	checkLists: Record<string, ICheckList[]>,
	checkListsRecords: ICheckRecord[],
	checkRecordStatuses: Record<string, ICheckRecordStatus>

): IEnrichedStructure[] => structures.map(structure => ({
	...structure,
	problemsStats: convertProblemsData(structure.problems?.problems, problemStatuses),
	workAcceptancesStats: convertWorkAcceptancesData(structure.workAcceptances, workAcceptanceStatuses),
	categories: categoriesFromWorkAcceptances(structure.workAcceptances),
	checkLists: convertCheckLists(structure, checkLists, checkListsRecords, checkRecordStatuses)
}));

export const filterStructures = (
	structures: IEnrichedStructure[],
	filters: IStructuresFiltersState
) => {
	const filteredStructures = structures.filter((structure: IEnrichedStructure) => {
		if (filters.types && filters.types.length) {
			if (!filters.types.includes(structure.type)) {
				return false;
			}
		}

		if (filters.problemStatuses && filters.problemStatuses.length) {
			if (!structure.problemsStats
				|| !structure.problemsStats.length
				|| !structure.problemsStats.some((statusStat: IConvertedProblemsData) =>
					filters.problemStatuses?.includes(statusStat.key))) {
				return false;
			}
		}

		if (filters.workAcceptanceStatuses && filters.workAcceptanceStatuses.length) {
			if (!structure.workAcceptancesStats
				|| !structure.workAcceptancesStats.length
				|| !structure.workAcceptancesStats.some((statusStat: IConvertedWorkAcceptancesData) =>
					filters.workAcceptanceStatuses?.includes(statusStat.key))) {
				return false;
			}
		}

		if (filters.categories && filters.categories.length) {
			if (!structure.categories) {
				return false;
			}

			const categories = Object.keys(structure.categories);
			if (!categories.length
				|| !categories.some((category: string) =>
					filters.categories?.includes(category))) {
				return false;
			}
		}

		if (filters.checkListStatuses && filters.checkListStatuses.length) {
			if (!structure.checkLists
				|| !structure.checkLists.length
				|| !structure.checkLists.some((checkList: {status: ICheckRecordStatus}) =>
					filters.checkListStatuses?.includes(checkList.status.id))) {
				return false;
			}
		}

		if (filters.checkListIds && filters.checkListIds.length) {
			if (!structure.checkLists
				|| !structure.checkLists.length
				|| !structure.checkLists.some((checkList: {key: string}) =>
					filters.checkListIds?.includes(checkList.key))) {
				return false;
			}
		}

		return true;
	});

	return filteredStructures;
};
