import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditSpaceIndicatorsResponse,
	makeSpaceIndicatorsEditRequest
} from '@src/api/backend/space/editIndicators';

export type IEditSpaceIndicatorsPayload = IEditSpaceIndicatorsResponse;

const request = () => ({type: types.EDIT_INDICATORS_REQUEST});

const success = (response: IEditSpaceIndicatorsResponse, pageObjectId: string) => ({
	type: types.EDIT_INDICATORS_SUCCESS,
	payload: {...response, pageObjectId}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении индикаторов помещения');
	return {
		type: types.EDIT_INDICATORS_FAILURE,
		payload: error
	};
};

export const editSpaceIndicatorsActions = {failure, success};

/**
 * Изменяет индикаторы помещения
 *
 * @param objectId id объекта
 * @param pageObjectId id объекта страницы
 * @param spaceId id помещения
 * @param indicators индикаторы
 */
export const editSpaceIndicators = (
	objectId: string, pageObjectId: string, spaceId: string, indicators: string[]
) =>
	createApiAction<IEditSpaceIndicatorsResponse>(
		request,
		response => success(response, pageObjectId),
		failure,
		() => makeSpaceIndicatorsEditRequest(objectId, spaceId, indicators)
	);
