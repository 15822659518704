import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IInspectionsPagesState, IInspectionsPageState, getInitialStateForPage} from './reducers';

/**
 * Возвращает осмотры для конкретного объекта
 */
export const extractInspectionsData = createSelector(
	(state: IState) => state.entities.inspections,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(data: IInspectionsPagesState, objectId: string): IInspectionsPageState =>
		data[objectId] || getInitialStateForPage()
);

/**
 * Возвращает осмотры в виде массива
 */
export const extractInspectionsAsArray = createSelector([extractInspectionsData], inspections =>
	inspections.allIds.map(id => inspections.byId[id]));

/**
 * Возвращает локально сохраненные осмотры
 */
export const extractLocalInspections = createSelector(
	[
		(state: IState) => state.localEntities.inspections,
		(state: IState, objectId: string | undefined) => objectId
	],
	(inspections, objectId) =>
		(objectId ? inspections.filter(inspection => inspection.objectId === objectId) : inspections)
);
