import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/ISavingWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {
	convertToSave,
	errorsFns,
	isEdited,
	useEditableResultWorkAcceptanceState
} from '@src/core/hooks/states/useEditableResultWorkAcceptanceState';
import {EditableResultWorkAcceptance} from '../EditableResultWorkAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

/**
 * Логика редактирования и сохранения приёмки работ (новой или существующей)
 */
export const useEditableResultWorkAcceptance = (
	objectId: string,
	stage: ObjectStageIds,
	workAcceptance: IWorkAcceptance | undefined,
	saving: boolean
): [React.ReactNode, () => Promise<ISavingWorkAcceptance | undefined>, (
) => void, boolean] => {
	const fieldsSettings = useMemo(
		() => ({
			comment: {fieldId: 'comment', isRequired: false},
			percent: {fieldId: 'percent', isRequired: true},
			unit: {fieldId: 'unit', isRequired: false},
			unitValue: {fieldId: 'unitValue', isRequired: false}
		}),
		[]
	);

	const [editingState, editingDispatch] = useEditableResultWorkAcceptanceState(workAcceptance);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(
			isEdited(editingState, workAcceptance) || isFilesExist(uploadingFilesState.value)
		);
	}, [editingState, uploadingFilesState.value, workAcceptance]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, workAcceptance)
			&& (!isFilesExist(files) || someFilesHaveError(files))
		) {
			return undefined;
		}
		const savingData = convertToSave(editingState, workAcceptance, true);

		return savingData;
	}, [
		editingState,
		workAcceptance,
		fieldsSettings,
		editingDispatch,
		uploadingFilesDispatch,
		waitUploading
	]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: workAcceptance
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [workAcceptance, editingDispatch, uploadingFilesDispatch]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [workAcceptance]);
	const fields = (
		<EditableResultWorkAcceptance
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
