import {IDeleteSpaceStatusResponse} from '@src/api/backend/spaceStatuses/delete';
import {IEditableSpaceStatusesSetAction} from '@src/core/hooks/states/useSpaceStatusesSetState';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {StatusButton} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {IExtendedSpaceStatus} from '../../interfaces/IExtendedSpaceStatus';
import {Menu} from '../menu/Menu';
import {EditableSpaceStatusDialog} from './EditableSpaceStatusDialog';

interface IMobileEditableSpaceStatusProps {
	status: IExtendedSpaceStatus;
	statuses?: ISpaceStatus[];
	setEdited: Dispatch<SetStateAction<string[]>>;

	disabled?: boolean;
	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;

	updateStatus: (index: number) => Promise<ISpaceStatus | undefined>;
	removeStatus: (id: string) => Promise<IDeleteSpaceStatusResponse | undefined>;
}

export const MobileEditableSpaceStatus = ({
	status, statuses, setEdited, disabled, editingDispatch, updateStatus, removeStatus
}: IMobileEditableSpaceStatusProps) => {
	const [isOpen, setOpen] = useState(false);
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить статус',
		'Удаление может привести к сбросу статусов помещений у объектов, Вы уверены, что хотите сохранить удалить статус?'
	);

	const handleCloseEditDialog = useCallback(() => setOpen(false), []);
	const handleOpenEditDialog = useCallback(() => setOpen(true), []);

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			await removeStatus(status.id);

			editingDispatch({
				type: 'delete-array-item', field: 'statuses', index: [status.index]
			});
		}
	}, [removeStatus, status.id, status.index]);

	const autoTransitionTo = useMemo(() =>
		statuses?.find(val => val.id === status?.autoTransitionTo),
	[status.autoTransitionTo, statuses]);

	const transitionTo = useMemo(() =>
		statuses?.find(val => status.transitions?.includes(val.id)),
	[status.transitions, statuses]);
	const numberOfRemainingTransitions = status.transitions?.length
		? status.transitions.length - 1
		: undefined;

	return (
		<div className="editable-space-statuses__status-mobile">
			<div className="editable-space-statuses__status-mobile__number">
				{status.number}
				.
			</div>
			<div className="editable-space-statuses__status-mobile__container">
				<StatusButton
					className="editable-space-statuses__status-mobile__container-name"
					color={status.color}
					label={status.name}
				/>
				{transitionTo && (
					<div>
						<div
							className="editable-space-statuses__status-mobile__container-title"
						>
							Статусы доступные к смене:
						</div>
						<div
							className="editable-space-statuses__status-mobile__container-auto"
						>
							<div>{transitionTo.name}</div>
							<div>
								+
								{numberOfRemainingTransitions}
							</div>
						</div>
					</div>
				)}
				{autoTransitionTo && (
					<div>
						<div
							className="editable-space-statuses__status-mobile__container-title"
						>
							Автоматическая смена на статус:
						</div>
						<div>{autoTransitionTo.name}</div>
					</div>
				)}
			</div>
			<div className="editable-space-statuses__status-mobile__container-menu">
				<Menu
					statuses={statuses}
					disabled={disabled}
					isDefault={status.default}
					isMobile
					onEdit={handleOpenEditDialog}
					onDelete={handleDelete}
				/>
			</div>
			{!disabled && (
				<>
					{deleteDialog}
					<EditableSpaceStatusDialog
						isOpen={isOpen}
						onClose={handleCloseEditDialog}
						status={status}
						statuses={statuses}
						setEdited={setEdited}
						editingDispatch={editingDispatch}
						updateStatus={updateStatus}
					/>
				</>
			)}
		</div>
	);
};