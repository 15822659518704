import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {UserInfo} from '@tehzor/ui-components';
import React from 'react';
import {CellProps} from 'react-table';

const UserCell = ({row}: CellProps<IEnrichedProblemTagsSet>) => {
	if (!row.original.createdBy) {
		return null;
	}

	return (
		<UserInfo user={row.original.createdBy}/>
	);
};

export default UserCell;
