import React from 'react';
import CitiesFilterPage from '@src/pages/filters/CitiesFilterPage';
import CompaniesFilterPage from '@src/pages/filters/CompaniesFilterPage';
import ObjectsFiltersPage from '@src/pages/filters/ObjectsFiltersPage/ObjectsFiltersPage';
import StagesFilterPage from '@src/pages/filters/StagesFilterPage';
import {PrivateRoute} from '@src/containers/PrivateRoute';

export const objectsFilterRoutes = [
	{
		path: 'stages',
		element: <PrivateRoute element={<StagesFilterPage/>}/>
	},
	{
		path: 'companies',
		element: <PrivateRoute element={<CompaniesFilterPage/>}/>
	},
	{
		path: 'cities',
		element: <PrivateRoute element={<CitiesFilterPage/>}/>
	},
	{
		path: '',
		element: <PrivateRoute element={<ObjectsFiltersPage/>}/>
	}
];
