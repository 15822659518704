import React, {useCallback, memo} from 'react';
import {IEntity} from '../EntitiesDesktopPhotoViewer';
import PhotoPreview from '../../PhotoPreview';
import classNames from 'classnames';
import {format} from 'date-fns';

interface IEntityProps {
	data: IEntity;
	active: boolean;
	activeIndex?: number;
	dateOptions?: Parameters<typeof format>[2];

	onChange: (id: string, index: number) => void;
}

const Entity = ({data, active, activeIndex, dateOptions, onChange}: IEntityProps) => {
	const handlePreviewClick = useCallback((index: number) => {
		onChange(data.id, index);
	}, [data, onChange]);

	return (
		<div className="ed-photo-viewer__entity">
			<div
				className={classNames(
					'ed-photo-viewer__entity-title',
					{'ed-photo-viewer__entity-title_active': active}
				)}
			/>

			<div className="ed-photo-viewer__entity-nav">
				{data.files.map((url, index) => (
					<PhotoPreview
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className="ed-photo-viewer__entity-nav-item"
						url={url}
						active={active && index === activeIndex}
						data={index}
						onClick={handlePreviewClick}
					/>
				))}
			</div>
		</div>
	);
};

export default memo(Entity);