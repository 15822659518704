import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {extractAllSpaceStatusesAsArray} from '@src/store/modules/dictionaries/spaceStatuses/selectors';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {IFullSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/IFullSpaceStatusesSet';
import {extractSpaceStatusesSetsPageSettings} from '../../settings/pages/manage/spaceStatusesSets/selectors';
import {ISpaceStatusesSetsFiltersState} from '../../settings/pages/manage/spaceStatusesSets/reducers';
import {handleSort} from './utils/handleSort';
import {handleFilter} from './utils/handleFilter';
import {handlePagination} from './utils/handlePagination';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {extractCompaniesAsMap} from '../companies/selectors';
import {extractObject} from '../objects/selectors';

const extractAllIds = (state: IState): string[] =>
	state.dictionaries.spaceStatusesSets.allIds || [];
const extractById = (state: IState): Record<string, ISpaceStatusesSet> =>
	state.dictionaries.spaceStatusesSets.byId || {};

/**
 * Возвращает наборы статусов помещений в виде массива
 */
export const extractSpaceStatusesSetsAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);

export const extractFullSpaceStatusesSetsData = createSelector(
	[extractById, extractAllIds, extractAllSpaceStatusesAsArray],
	(byId, allIds, allStatuses) => {
		const total = allIds.length;
		const fullById: {[id: string]: IFullSpaceStatusesSet} = {};

		allIds.forEach(id => {
			fullById[id] = {
				...byId[id],
				statuses: allStatuses[id] ?? []
			};
		});

		return {allIds, byId: fullById, total};
	}
);

export const extractFullSpaceStatusesSetsAsArray = createSelector(
	[extractFullSpaceStatusesSetsData],
	({allIds, byId}) => allIds.map(id => byId[id])
);

/**
 * Возвращает наборы статусов помещений для реестра
 */
export const extractSpaceStatusesSetsForPage = createSelector(
	[extractFullSpaceStatusesSetsAsArray, extractSpaceStatusesSetsPageSettings],
	(array, settings) => {
		const {offset, pageSize, sort, filters} = settings;

		const scopeFilters: ISpaceStatusesSetsFiltersState = {};

		const filteredArr = handleSort(array, sort)
			.filter(item => handleFilter(item, scopeFilters))
			.filter(item => handleFilter(item, filters));

		const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));

		return {
			data: paginatedArr,
			pageCount: Math.ceil(filteredArr.length / pageSize),
			currentPage: Math.floor(offset / pageSize),
			total: filteredArr.length
		};
	}
);

export const extractSpaceStatusesSetById = createSelector(
	[extractById, (s: IState, spaceStatusesSetId: string) => spaceStatusesSetId],
	(byId, id) => byId[id]
);

export const extractSpaceStatusesAsArrayByObjectId = createSelector(
	[
		extractSpaceStatusesSetsAsArray,
		extractAllSpaceStatusesAsArray,
		(s: IState) => s.dictionaries.objectStages.byId,
		extractCompaniesAsMap,
		extractObject
	],
	(sets, allStatuses, stagesMap, companiesMap, object) => {
		const companyId = companiesMap.get(object.companyId)?.id;
		const objectId = object.id;

		let set = sets.find(item => item.objects?.includes(objectId));
		if (!set) {
			set = sets.find(item => (item.companyId && !item.objects?.length
				? item.companyId === companyId : false));
		}
		if (!set) {
			set = sets.find(item => item.default);
		}

		const id = set?.id;

		const statuses: ISpaceStatus[] = id && allStatuses[id] ? allStatuses[id] : [];

		return statuses.sort((prev, next) => {
			const prevStage = stagesMap?.[prev.stage];
			const nextStage = stagesMap?.[next.stage];

			if (prevStage?.name < nextStage?.name) {
				return 1;
			}
			if (prevStage?.name > nextStage?.name) {
				return -1;
			}
			return 0;
		});
	}
);

export const extractSpaceStatusesDataByObjectId = createSelector(
	[extractSpaceStatusesAsArrayByObjectId],
	statuses => {
		const allIds: string[] = [];
		const byId: Record<string, ISpaceStatus> = {};

		for (const status of statuses) {
			allIds.push(status.id);
			byId[status.id] = status;
		}

		return {allIds, byId};
	}
);

export const extractSpaceStatusesAsArrayByStage = createSelector(
	[
		extractSpaceStatusesAsArrayByObjectId,
		(s: IState, objectId: string, stage: ObjectStageIds | undefined) => stage
	],
	(arr, stage) => (!stage ? arr : arr.filter(item => item.stage === stage))
);
