import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {problemTemplatesSetsQueryKeys} from '@src/api/cache/problemTemplatesSets/keys';
import {regulatoryStandardsQueryKeys} from '@src/api/cache/regulatoryStandards/keys';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

export const keysPermissionMapping = (roles?: IUserRole[]) => {
	if (!roles) {
		return [];
	}
	const {
		checkRecordStatuses,
		companies,
		objectStages,
		ownerAcceptanceResolutions,
		ownerAcceptanceStatuses,
		problemStatuses,
		spaceIndicatorsSets,
		spaceOwners,
		spaceTypes,
		users,
		warrantyClaimStatuses
	} = restDictionariesQueryKeys;
	const fullMap = [
		hasPermission(roles, 'categories-view') && categoriesQueryKeys.list(),
		hasPermission(roles, 'categories-view') && categoriesSetsQueryKeys.list(),
		hasPermission(roles, 'check-lists-view') && checkListsQueryKeys.list(),
		hasPermission(roles, 'check-items-view') && checkItemsQueryKeys.list(),
		hasPermission(roles, 'check-records-view') && checkRecordStatuses(),
		companies(),
		checksQueryKeys.latest(),
		internalAcceptancesQueryKeys.latest(),
		objectStages(),
		ownerAcceptanceResolutions(),
		ownerAcceptanceStatuses(),
		hasPermission(roles, 'plansView') && plansQueryKeys.lists(),
		problemStatuses(),
		hasPermission(roles, 'problem-templates-sets-view') && problemTemplatesSetsQueryKeys.list(),
		regulatoryStandardsQueryKeys.list(),
		hasPermission(roles, 'responsibility-rules-view') && responsibilityRulesQueryKeys.list(),
		hasPermission(roles, 'spaceIndicatorsSetsView') && spaceIndicatorsSets(),
		hasPermission(roles, 'space-owners-view') && spaceOwners(),
		hasPermission(roles, 'spaceStatusesSetsView') && spaceStatusesSetsQueryKeys.list(),
		hasPermission(roles, 'spaceStatusesSetsView') && spaceStatusesQueryKeys.list(),
		spaceTypes(),
		users(),
		warrantyClaimStatuses(),
		hasPermission(roles, 'working-groups-view') && workingGroupsQueryKeys.list()
	];
	return fullMap.filter(item => !!item) as string[][];
};
