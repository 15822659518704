import React, {Dispatch, useCallback} from 'react';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPlans} from '@src/store/modules/dictionaries/plans/selectors';
import {extractStructuresListData} from '@src/store/modules/entities/structures/selectors/lists';
import {EditableFieldLabel, LocationSelect, TextField} from '@tehzor/ui-components';
import {InputType, ViewerType} from '@tehzor/ui-components/src/components/LocationSelect';
import {changeLocDefaultInputType, changeLocDefaultViewerType} from '@src/store/modules/settings/locationSelect/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';

const locationIcon = <i className="tz-location-20"/>;

interface IStructureLocationSelectProps<S, E> {

	structureEl: {
		structureId: string;
		location: {
			planId: string;
			location: ILocation;
		} | undefined;
	};
	objectId: string;
	locations: Record<string, {
		planId: string;
		location: ILocation;
	}>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;

}

export const StructureLocationSelect = <S extends { locations?: ILocation | null }, E>(
	{
		structureEl,
		objectId,
		locations,
		editingDispatch
	}: IStructureLocationSelectProps<S, E>) => {
	const dispatch = useAppDispatch();

	const handleLocatoinChange = useCallback(
		(structureId: string, planId?: string, location?: ILocation | null) => {
			const value = locations || {};
			if (planId && location) {
				value[structureId] = {planId, location};
			}
			editingDispatch({type: 'update', field: 'locations', value});
		},
		[]
	);

	const handleViewerTypeChange = useCallback(
		(v: ViewerType) => {
			dispatch(changeLocDefaultViewerType(objectId, v));
		},
		[objectId]
	);

	const handleInputTypeChange = useCallback(
		(v: InputType) => {
			dispatch(changeLocDefaultInputType(objectId, v));
		},
		[objectId]
	);

	const plansMap = useAppSelector(s => extractPlans(s, objectId).byId);
	const structuresMap = useAppSelector(s => extractStructuresListData(s, objectId)).byId;
	const structure = structuresMap[structureEl.structureId];

	if (!structure) {
		return null;
	}
	const location = structureEl.location?.location;

	return (
		<div>
			<EditableFieldLabel>{structure.name}</EditableFieldLabel>
			<LocationSelect
				key={structureEl.structureId}
				mode="edit"
				planId={structure.plan?.id}
				location={location}
				plans={structure.plan ? [plansMap[structure.plan.id]] : []}
				multiplePoints
				path="structure"
				onLocationChange={structureLocation =>
					handleLocatoinChange(structureEl.structureId, structure.plan?.id, structureLocation)}
				onViewerTypeChange={handleViewerTypeChange}
				onInputTypeChange={handleInputTypeChange}
			>
				{(displayValue, open: () => void) => (
					<TextField
						className="text-field_interactive"
						disabled={!structure.plan}
						elementType="div"
						value={displayValue}
						icon={locationIcon}
						onClick={open}
					/>
				)}
			</LocationSelect>
		</div>

	);
};