import React, {useCallback} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import WarrantyClaimsPage from './WarrantyClaimsPage';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/reducers';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeFilters,
	clearFilters
} from '@src/store/modules/settings/pages/warrantyClaims/actions';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import {changeOffset} from '@src/store/modules/entities/warrantyClaims/actions';

/**
 * Обёртка над страницей обращений
 * необходима для того чтобы при переходе от одной страницы к другой отрабатывали все методы жизненного цикла
 *
 * @constructor
 */
const WarrantyClaimsPageWrap = (
	props: JSX.IntrinsicAttributes & {className?: string | undefined}
) => {
	const dispatch = useAppDispatch();
	const {objectId} = useStrictParams<{objectId: string}>();

	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {sectionsMenu});

	const {filters} = useAppSelector(s => extractWarrantyClaimsPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: IWarrantyClaimsFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId]);

	return (
		<EntitiesFiltersProvider
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<WarrantyClaimsPage
				key={objectId}
				className={sectionsMenu.length ? 'page-cont_extra-top-margin' : undefined}
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default WarrantyClaimsPageWrap;
