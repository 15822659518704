import {Button, FloatingActionButton} from '@tehzor/ui-components';
import React from 'react';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useChangePath} from '@src/core/hooks/useChangePath';

export const DesktopAddButton = () => {
	const {pushPath} = useChangePath();
	const add = () => {
		pushPath('/manage/problem-tags-sets/add');
	};

	return (
		<>
			<Desktop>
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-plus-20"/>}
					label="Добавить набор"
					onClick={add}
				/>
			</Desktop>

			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={add}
				/>
			</BeforeDesktop>
		</>
	);
};
