import {createReducer} from '@reduxjs/toolkit';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import * as types from '../constants/problemsData';
import {RESET} from '../constants/page';
import {ISpacesProblemsData, ISpacesStatsDataRequest} from '@src/interfaces/ISpacesStatsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import * as problemTypes from '@src/store/modules/pages/problem/constants';

export interface ISpacesProblemsStatsState {
	data: Record<string, IEntityProblemsData>;
	loading: boolean;
	loaded: boolean;
}

export const clearPage = (
	state: Record<string, ISpacesProblemsStatsState>,
	{payload}: {payload: {objectId: string}}
) => {
	if (state[payload.objectId]) {
		state[payload.objectId] = {
			...state[payload.objectId],
			loaded: false
		};
	}
};

export const getInitialState = (): Record<string, ISpacesProblemsStatsState> => ({});

export default createReducer<Record<string, ISpacesProblemsStatsState>>(getInitialState(), {
	[types.GET_REQUEST]: (state, {payload}: {payload: ISpacesStatsDataRequest}) => {
		state[payload.objectId] = {
			data: {},
			loading: true,
			loaded: false
		};
	},
	[types.GET_SUCCESS]: (state, {payload}: {payload: ISpacesProblemsData}) => {
		state[payload.objectId] = {
			data: payload.spacesProblemsData,
			loading: false,
			loaded: true
		};
	},
	[types.GET_FAILURE]: (state, {objectId}: {objectId: string}) => {
		state[objectId] = {
			data: {},
			loading: false,
			loaded: false
		};
	},
	[RESET]: getInitialState,
	[CLEAR_STORE]: getInitialState,

	[problemTypes.ADD_SUCCESS]: clearPage,
	[problemTypes.DELETE_SUCCESS]: clearPage,
	[problemTypes.EDIT_SUCCESS]: clearPage,
	[problemTypes.EDIT_STATUS_SUCCESS]: clearPage
});
