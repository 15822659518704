import React, {Dispatch} from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import './EditableTask.less';
import {IEditableTaskAction, IEditableTaskState} from '@src/core/hooks/states/useEditableTaskState';
import TaskTypeSelect from '../editableFields/TaskTypeSelect';
import Description from '../editableFields/Description';
import {ObjectSelect} from '../editableFields/ObjectSelect';
import {Responsible} from '../editableFields/Responsible';
import {TaskPrioritySelect} from '../editableFields/TaskPrioritySelect/TaskPrioritySelect';
import {Location} from '../editableFields/Location';
import Floors from '../editableFields/Floors';
import Attachments from '../editableFields/Attachments';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {useUpdateEffect} from 'react-use';
import DateTimeSelect from '../editableFields/DateTimeSelect';

const mobileDescProps = {
	minRows: 3,
	maxRows: 5
};

interface IEditableTaskProps {
	editingState: IEditableTaskState;
	editingDispatch: Dispatch<IEditableTaskAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	saving?: boolean;
}

const autoChangeTaskStatus = (
	editingState: IEditableTaskState,
	editingDispatch: Dispatch<IEditableTaskAction>
): void => {
	const {respUsers, activeGroup, status, taskIntervalStart, taskIntervalEnd} = editingState;

	if (
		taskIntervalStart
		&& taskIntervalEnd
		&& ((respUsers && respUsers.length > 0) || activeGroup)
		&& (status === undefined || status === TaskStatusId.OPENED)
	) {
		editingDispatch({type: 'update', field: 'status', value: TaskStatusId.ASSIGNED});
	}
};

export const EditableTask = (props: IEditableTaskProps) => {
	const {
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		fieldsSettings,
		saving
	} = props;

	useUpdateEffect(() => {
		autoChangeTaskStatus(editingState, editingDispatch);
	}, [
		editingState.status,
		editingState.taskIntervalStart,
		editingState.taskIntervalEnd,
		editingState.respUsers
	]);

	return (
		<div className="editable-task">
			<div>
				<div className="editable-task__grid">
					<Text
						field="name"
						label="Цель *"
						value={editingState.name}
						editingDispatch={editingDispatch}
						required
						disabled={saving}
						hasError={editingState.errors.name}
					/>

					{fieldsSettings.taskType !== undefined && (
						<TaskTypeSelect
							label={`Тип задачи ${fieldsSettings.taskType.isRequired ? '*' : ''}`}
							value={editingState.taskType}
							editingDispatch={editingDispatch}
							required={fieldsSettings.taskType.isRequired}
							disabled={saving}
							hasError={editingState.errors.taskType}
						/>
					)}

					{fieldsSettings.description !== undefined && (
						<Description
							className="editable-task__description"
							label="Описание"
							value={editingState.description ?? ''}
							editingDispatch={editingDispatch}
							required={fieldsSettings.description.isRequired}
							disabled={saving}
							hasError={editingState.errors.description}
							textAreaProps={mobileDescProps}
						/>
					)}

					{fieldsSettings.taskPriority !== undefined && (
						<TaskPrioritySelect
							label={`Приоритет ${fieldsSettings.taskPriority.isRequired ? '*' : ''}`}
							value={editingState.taskPriority}
							editingDispatch={editingDispatch}
							required={fieldsSettings.taskPriority.isRequired}
							disabled={saving}
							hasError={editingState.errors.taskPriority}
						/>
					)}

					{fieldsSettings.taskIntervalStart !== undefined && (
						<DateTimeSelect
							valueFrom={editingState.taskIntervalStart}
							valueTo={editingState.taskIntervalEnd}
							label="Срок исполнения"
							editingDispatch={editingDispatch}
							required={fieldsSettings.taskIntervalStart.isRequired}
							disabled={saving}
							hasErrorFrom={editingState.errors.taskIntervalStart}
							hasErrorTo={editingState.errors.taskIntervalEnd}
						/>
					)}

					<ObjectSelect
						field="objectId"
						label="Объект *"
						value={editingState.objectId}
						editingDispatch={editingDispatch}
						required
						disabled={saving}
						hasError={editingState.errors.objectId}
					/>

					{fieldsSettings.location !== undefined && (
						<Location
							planId={editingState?.planId || ''}
							location={editingState.location}
							objectId={editingState?.objectId || ''}
							editingDispatch={editingDispatch}
							required={fieldsSettings.location.isRequired}
							hasError={editingState.errors.location}
							disabled={saving}
						/>
					)}

					{fieldsSettings.floor !== undefined && editingState.objectId !== undefined && (
						<Floors
							value={editingState.floor || ''}
							editingDispatch={editingDispatch}
							required={fieldsSettings.floor.isRequired}
							disabled={saving}
							hasError={editingState.errors.floor}
						/>
					)}

					{fieldsSettings.respUsers !== undefined
						&& editingState.objectId !== undefined && (
							<Responsible
								objectId={editingState.objectId}
								respUsers={editingState.respUsers || []}
								activeGroup={editingState.activeGroup}
								editingDispatch={editingDispatch}
								required={fieldsSettings.respUsers.isRequired}
								disabled={editingState.objectId ? saving : false}
								hasError={editingState.errors.respUsers}
							/>
						)}
				</div>
			</div>
			<div>
				{fieldsSettings.attachments !== undefined && (
					<Attachments
						className={{
							root: 'editable-task__attachments',
							scrollArea: 'editable-task__attachments-scroll-area',
							files: 'editable-task__attachments-files',
							file: 'editable-task__attachments-file'
						}}
						attachments={editingState.attachments || []}
						uploadingFiles={uploadingFilesState.value}
						editingDispatch={editingDispatch}
						uploadingFilesDispatch={uploadingFilesDispatch}
						required={fieldsSettings.attachments.isRequired}
						disabled={saving}
						hasError={editingState.errors.attachments && uploadingFilesState.error}
						showAttachBtn
					/>
				)}
			</div>
		</div>
	);
};
