import React from 'react';
import {EntityLinkButton, TouchablePanel} from '@tehzor/ui-components';
import {useEntitiesLinks} from '../../hooks/useEntitiesLinks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';

interface IMobileLinksProps {
	objectId: string;
	inspection: ILinkedInspection;
}

const MobileLinks = (props: IMobileLinksProps) => {
	const {pushPath} = useChangePath();
	const {objectId, inspection} = props;
	const links = useEntitiesLinks(objectId, inspection);

	return (
		<TouchablePanel className="inspection-page__m-links">
			{links.map(item => (
				<EntityLinkButton
					key={item.key}
					className="inspection-page__m-link"
					leftIcon={item.icon}
					label={item.label}
					url={item.url}
					title={item.title}
					onClick={pushPath}
				/>
			))}
		</TouchablePanel>
	);
};

export default MobileLinks;
