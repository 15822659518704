import {useQueryClient} from '@tanstack/react-query';
import {problemViewsQueryKeys} from '../keys';
import {requestProblemViews} from '@src/api/backend/problemViews';

export const useProblemViewsQueryDefault = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(problemViewsQueryKeys.list(), {
		queryFn: async ({queryKey}) => requestProblemViews(queryKey[2] as string),
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 день
	});
	queryClient.setQueryDefaults(problemViewsQueryKeys.add(), {
		staleTime: Infinity
	});
};