import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import * as types from './constants';
import * as inspectionTypes from '../inspection/constants';
import * as checkTypes from '../check/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {IGetInspectionsPayload} from './actions';
import {CHANGE_SORT, CLEAR_FILTERS} from '@src/store/modules/settings/pages/inspections/constants';
import {IDeleteInspectionPayload, IAddInspectionPayload} from '../inspection/actions';
import * as networkTypes from '@src/store/modules/offlineMode/constants';

const {entitiesCacheTime} = config;

export interface IInspectionsPageState {
	byId: {
		[id: string]: IListInspection;
	};
	allIds: string[];
	offset: number;
	total: number;
	expires?: number;
	selectedRows: string[];
	isAllRowsSelected: boolean;
}

export interface IInspectionsPagesState {
	[objectId: string]: IInspectionsPageState;
}

export const getInitialStateForPage = (): IInspectionsPageState => ({
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	expires: undefined,
	selectedRows: [],
	isAllRowsSelected: false
});

const clearPage = (state: IInspectionsPagesState, {payload}: {payload: {objectId: string}}) => {
	if (payload.objectId !== 'all') {
		state[payload.objectId] = getInitialStateForPage();
	}
	state[payload.objectId] = getInitialStateForPage();
};

const deleteInspection = (
	state: IInspectionsPagesState,
	objectId: string,
	inspectionId: string
) => {
	const page = state[objectId];
	page.allIds = page.allIds.filter(id => id !== inspectionId);
	delete page.byId[inspectionId];
	page.expires = undefined;
};

export default createReducer<IInspectionsPagesState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetInspectionsPayload}) => {
			const page = state[payload.objectId];
			if (!page) {
				state[payload.objectId] = {
					...getInitialStateForPage(),
					byId: payload.byId,
					allIds: payload.allIds
				};
				return;
			}
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.offset = payload.offset;
			page.total = payload.total;
			page.expires = Date.now() + entitiesCacheTime * 1000;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string, offset: number}}
		) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			const page = state[payload.objectId];
			if (page) {
				page.offset = payload.offset;
				page.expires = undefined;
			}
		},
		[types.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: {objectId: string, selectedRows: string[]}}
		) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		[types.CLEAR_EXPIRATION]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state && state?.[payload.objectId]?.expires) {
				state[payload.objectId].expires = undefined;
			}
		},
		[types.TOGGLE_ALL_ROWS_SELECTED]: (
			state,
			{payload}: {payload: {objectId: string, isAllRowsSelected?: boolean}}
		) => {
			const page = state[payload.objectId];
			page.isAllRowsSelected
				= payload.isAllRowsSelected !== undefined
					? payload.isAllRowsSelected
					: !page.isAllRowsSelected;
		},
		// При сортировке нужно обнулить expires для игнорирования кеша
		[CHANGE_SORT]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			state[payload.objectId].expires = undefined;
		},
		// При сбрасывании фильтров нужно обнулить expires для игнорирования кеша
		[CLEAR_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			state[payload.objectId].expires = undefined;
		},
		// При манипуляциях с затрагивающими данными необходимо обновить список
		[inspectionTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddInspectionPayload}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].expires = undefined;
			}
			if (state.all) {
				state.all.expires = undefined;
			}
		},
		[inspectionTypes.EDIT_SUCCESS]: clearPage,
		[inspectionTypes.DELETE_SUCCESS]: (
			state,
			{payload}: {payload: IDeleteInspectionPayload}
		) => {
			if (payload.objectId !== 'all' && state[payload.objectId]) {
				deleteInspection(state, payload.objectId, payload.inspectionId);
			}
			if (state.all) {
				deleteInspection(state, 'all', payload.inspectionId);
			}
		},
		[checkTypes.ADD_SUCCESS]: clearPage,
		[checkTypes.DELETE_SUCCESS]: clearPage,
		[networkTypes.CHANGE_NETWORK_STATUS]: (state, {payload}: PayloadAction<boolean>) =>
			(payload === false ? {} : getInitialStateForPage()),
		[CLEAR_STORE]: () => ({})
	}
);
