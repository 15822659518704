import React, {Dispatch} from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import {
	IEditableRespUserAction,
	IEditableRespUserState
} from '@src/core/hooks/states/useEditableRespUserState/state';
import './EditableRespUser.less';
import {ObjectStageSelect} from '@src/components/editableFields/ObjectStageSelect';
import {ObjectPlansSelect} from '@src/components/editableFields/ObjectPlansSelect';
import CategoriesSet from '../editableFields/CategoriesSet';

interface IEditableRespUserProps {
	objectId: string;
	editingState: IEditableRespUserState;
	editingDispatch: Dispatch<IEditableRespUserAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving?: boolean;
}

export const EditableRespUser = (props: IEditableRespUserProps) => {
	const {editingState, editingDispatch, fieldsSettings, saving, objectId} = props;
	return (
		<div className="editable-resp-user">
			<div className="editable-resp-user-row">
				{fieldsSettings.fullName !== undefined && (
					<Text
						field="fullName"
						label="ФИО"
						value={editingState.fullName}
						editingDispatch={editingDispatch}
						required={fieldsSettings.fullName.isRequired}
						disabled={saving}
						hasError={editingState.errors.fullName}
					/>
				)}
				{fieldsSettings.email !== undefined && (
					<Text
						field="email"
						label="E-mail"
						value={editingState.email}
						editingDispatch={editingDispatch}
						required={fieldsSettings.email.isRequired}
						disabled={saving}
						hasError={editingState.errors.email}
					/>
				)}
			</div>

			<div className="editable-resp-user-row">
				{fieldsSettings.categories !== undefined && (
					<CategoriesSet
						label="Вид работ"
						objectId={objectId}
						value={editingState.categories}
						editingDispatch={editingDispatch}
						required={fieldsSettings.categories.isRequired}
						disabled={saving}
						hasError={editingState.errors.categories}
					/>
				)}

				{fieldsSettings.stage !== undefined && (
					<ObjectStageSelect
						field="stage"
						label="Стадия"
						errorMessage="Выберите стадию"
						value={editingState.stage}
						editingDispatch={editingDispatch}
						required={fieldsSettings.stage.isRequired}
						disabled={saving}
						hasError={editingState.errors.stage}
					/>
				)}
			</div>
			<div className="editable-resp-user-row">
				{fieldsSettings.planId !== undefined && (
					<ObjectPlansSelect
						label="План"
						objectId={objectId}
						value={editingState.planId}
						editingDispatch={editingDispatch}
						required={fieldsSettings.planId.isRequired}
						disabled={saving}
						hasError={editingState.errors.planId}
					/>
				)}
			</div>
		</div>
	);
};
