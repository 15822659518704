import React from 'react';
import EntitiesFilters, {
	CreatedByFilter,
	StatusesFilter,
	ObjectsFilter,
	PlansFilter,
	FixDateFilter,
	CreationDateFilter,
	BooleanFilter,
	RespUsersFilter
} from '@src/components/EntitiesFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {StagesFilter} from './StagesFilters';
import {extractProblemStatusesAsArray} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import {CategoriesSetFilter} from './CategoriesSetFilter';
import {ProblemsSearch} from '../ProblemsSearch';
import {ExpiredFilter} from '@src/components/EntitiesFilters/components/ExpiredFilter';
import {ProblemTagsSetFilter} from './ProblemTagsSetFilter';
import {InspectorsFilter} from '@src/components/EntitiesFilters/components/InspectorsFilter';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';

const criticalOptions = [
	{
		id: 'true',
		name: 'Критично'
	},
	{
		id: 'false',
		name: 'Не критично'
	}
];

interface IProblemsFiltersProps {
	objectId?: string;
	settings?: IProblemSettings;
}

const ProblemsFilters = ({objectId = 'all', settings}: IProblemsFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IProblemsFiltersState>();
	const allStages = useAppSelector(extractObjectStagesAsArray);
	const allStatuses = useAppSelector(extractProblemStatusesAsArray);

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="problems"
		>
			<div className="problems-page__search">
				<ProblemsSearch/>
			</div>

			{objectId === 'all' && <ObjectsFilter value={state?.objects}/>}

			<StatusesFilter
				allStatuses={allStatuses}
				statuses={state?.statuses}
			/>

			{settings?.plannedFixDate && (
				<FixDateFilter
					from={state?.plannedFixDateFrom}
					to={state?.plannedFixDateTo}
				/>
			)}

			{settings?.critical && (
				<BooleanFilter
					value={state.critical}
					label="Критичность"
					filterName="critical"
					options={criticalOptions}
				/>
			)}
			<ExpiredFilter value={state.expired}/>

			{settings?.problemTags && (
				<ProblemTagsSetFilter
					objectId={objectId}
					problemTags={state.problemTags}
				/>
			)}

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
			/>

			{settings?.categoryId && (
				<CategoriesSetFilter
					objectId={objectId}
					categories={state.categories}
				/>
			)}

			{objectId !== 'all' && settings?.location && (
				<PlansFilter
					objectId={objectId}
					plans={state?.plans}
				/>
			)}

			<StagesFilter
				allStages={allStages}
				stages={state?.stages}
			/>

			{settings?.respUsers && (
				<RespUsersFilter
					respUsers={state?.respUsers}
					objectId={objectId}
				/>
			)}

			{settings?.inspectors && (
				<InspectorsFilter
					inspectors={state.inspectors}
					objectId={objectId}
				/>
			)}
		</EntitiesFilters>
	);
};

export default ProblemsFilters;
