import React, {Dispatch} from 'react';
import './EditableResultWorkAcceptance.less';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {
	IEditableResultWorkAcceptanceAction,
	IEditableResultWorkAcceptanceState
} from '@src/core/hooks/states/useEditableResultWorkAcceptanceState';
import Text from '../editableFields/Text';
import {unitsAsArray, useUnits} from '@src/core/hooks/queries/units';
import {UnitSelect} from '@src/components/editableFields/UnitSelect';

interface IEditableResultWorkAcceptanceProps {
	editingState: IEditableResultWorkAcceptanceState;
	editingDispatch: Dispatch<IEditableResultWorkAcceptanceAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableResultWorkAcceptance = (props: IEditableResultWorkAcceptanceProps) => {
	const {
		editingState,
		editingDispatch,
		fieldsSettings,
		saving
	} = props;

	const units = useUnits();
	const unitsListArr = unitsAsArray(units);

	return (
		<div className="editable-result-work-acceptance">
			<div className="editable-result-work-acceptance__info-grid">
				<Text
					field="percent"
					label="Объём принятых работ (%)"
					value={editingState.percent?.toString()}
					editingDispatch={editingDispatch}
					required={fieldsSettings.percent.isRequired}
					disabled={saving}
					hasError={editingState.errors.percent}
					errorMessage="Введите значение от 0 до 100"
				/>

				<div className="editable-result-work-acceptance__info-grid-units">
					<Text
						field="unitValue"
						label="Физический объём работ"
						value={editingState.unitValue?.toString()}
						editingDispatch={editingDispatch}
						required={fieldsSettings.unitValue.isRequired}
						disabled={saving}
						hasError={editingState.errors.unitValue}
						errorMessage="Введите значение, длина которого не превышает 20 символов"
						className="editable-result-work-acceptance__info-grid-units-text-input"
					/>

					<UnitSelect
						field="unit"
						label="Единица измерения физических объемов"
						value={editingState.unit?.toString()}
						editingDispatch={editingDispatch}
						required={fieldsSettings.unit.isRequired}
						disabled={saving}
						hasError={editingState.errors.unit}
						unitsListArr={unitsListArr}
						className="editable-result-work-acceptance__info-grid-units-text-input"
						classNameSelectSearch="editable-result-work-acceptance__info-grid-units-select-search"
					/>
				</div>

				<Text
					field="comment"
					elementType="textarea"
					label="Комментарий"
					value={editingState.comment}
					editingDispatch={editingDispatch}
					required={fieldsSettings.comment.isRequired}
					disabled={saving}
					hasError={editingState.errors.comment}
				/>
			</div>
		</div>
	);
};
