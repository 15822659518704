import React, {useState} from 'react';
import {desktopColumns} from './columns.desktop';
import {Table} from './Table';
import {Plate} from '@tehzor/ui-components';


import {StructuresPageCounter} from '../StructuresPageCounter';

interface IDesktopTableProps {
	objectId: string;
}

export const DesktopTable = ({objectId}: IDesktopTableProps) => {

	const [count, setCount] = useState(0);

	return (
		<>
			<StructuresPageCounter
				objectId={objectId}
				count={count}
			/>
			<Plate
				className="structures-page__plate"
				withoutPadding
			>
				<Table
					objectId={objectId}
					columns={desktopColumns}
					selectable
					setCount={setCount}
				/>
			</Plate>
		</>
	);
};
