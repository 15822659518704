import React from 'react';
import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import Status from './Status';
import Indicators from './Indicators';
import IndicatorsSelect from './IndicatorsSelect';
import Area from './Area';
import SpaceOwners from './SpaceOwners';
import AdditionalName from './AdditionalName';
import {TypeDecor} from './TypeDecor';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {useSpaceOwning} from '@src/core/hooks/spaces/useSpaceOwning';

const statusIcon = <i className="tz-status-20"/>;
const indicatorIcon = <i className="tz-indicator-20"/>;
const spaceTypeIcon = <i className="tz-space-type-20"/>;
const areaIcon = <i className="tz-area-20"/>;

interface IDesktopInfoProps {
	objectId: string;
	space: ISpace;
}

const Common = ({objectId, space}: IDesktopInfoProps) => {
	const notBeOwned = useSpaceOwning(space);
	const permissions = useSpacesPermissions(objectId);

	return (
		<EntityGrid withBorders>
			{space.altName ? <AdditionalName space={space}/> : null}

			<EntityGridItem
				label="Статус"
				icon={statusIcon}
				fullRow
			>
				<Status
					objectId={objectId}
					spaceId={space.id}
					value={space.status}
					permissions={permissions}
				/>
			</EntityGridItem>

			<EntityGridItem
				label="Индикаторы"
				icon={indicatorIcon}
				fullRow
				buttons={(
					<IndicatorsSelect
						objectId={objectId}
						spaceId={space.id}
						value={space.indicators}
					/>
				)}
			>
				{space.indicators !== undefined && (
					<Indicators
						objectId={objectId}
						value={space.indicators}
					/>
				)}
			</EntityGridItem>

			{space.typeDecoration !== undefined && (
				<EntityGridItem
					label="Тип отделки"
					fullRow
					icon={spaceTypeIcon}
				>
					<TypeDecor name={space.typeDecoration}/>
				</EntityGridItem>
			)}

			{space.plannedArea || space.actualArea ? (
				<EntityGridItem
					label="Площадь"
					fullRow
					icon={areaIcon}
				>
					<Area
						planned={space.plannedArea}
						actual={space.actualArea}
					/>
				</EntityGridItem>
			) : null}

			{!notBeOwned && (
				<SpaceOwners
					objectId={objectId}
					spaceId={space.id}
				/>
			)}

		</EntityGrid>
	);
};

export default Common;
