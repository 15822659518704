import React, {useRef, useState} from 'react';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {DesktopInfo} from './info/Info.desktop';
import {Table} from './table/Table';
import {DesktopActions} from './actions/Actions.desktop';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {desktopColumns} from './table/columns.desktop';
import {workAcceptanceDesktopColumns} from './workAcceptances/columns.desktop';
import {LoadingPanel, Plate, AdaptiveHeightPanel} from '@tehzor/ui-components';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Outlet, Route, Routes} from 'react-router-dom';
import {CheckListPlate} from './CheckListPlate';
import {DesktopDetailColumn} from './DesktopDetailColumn';
import {WorkAcceptancesTable} from './workAcceptances/Table';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

interface IDesktopProps {
	objectId: string;
	structureId: string;
	structure?: IStructure;
	checkLists: ICheckList[];
	workAcceptances?: IListWorkAcceptance[];
}

export const Desktop = ({
	objectId,
	structureId,
	structure,
	checkLists,
	workAcceptances
}: IDesktopProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const pageRef = useRef(null);
	const listsLoading = useAppSelector(s => s.dictionaries.checkLists.loading);
	const sectionsMenu = [
		{
			url: `/objects/${objectId}/structures/${structureId}`,
			label: 'Нарушения'
		},
		{
			url: `/objects/${objectId}/structures/${structureId}/check-lists`,
			label: 'Чек-листы'
		},
		{
			url: `/objects/${objectId}/structures/${structureId}/work-acceptances`,
			label: 'Приёмки работ'
		}
	];

	useAppHeader(
		{
			title: structure?.name,
			showBackBtn: true,
			sectionsMenu
		},
		[structure]
	);

	return structure !== undefined ? (
		<div className="page-cont structure-page">
			<PageBreadcrumbs
				objectId={objectId}
				structureId={structureId}
			/>

			<div className="structure-page__d-columns">
				<div className="structure-page__d-column structure-page__d-column_info">
					<DesktopInfo structure={structure}/>
				</div>
				<Routes>
					<Route
						path="check-lists/:listId?/:pathParam?/:itemId?/"
						element={(
							<div className="check-list-page__d-main structure-page__check-lists">
								<div className="check-list-page__d-main-list">
									<LoadingPanel active={listsLoading}>
										<>
											{checkLists.map(checkList => (
												<CheckListPlate
													key={checkList.id}
													defaultOpen={checkLists.length === 1}
													checkList={checkList}
												/>
											))}
										</>
									</LoadingPanel>
								</div>

								<div className="check-list-page__d-main-detail">
									<AdaptiveHeightPanel pageRef={pageRef.current}>
										<DesktopDetailColumn/>
									</AdaptiveHeightPanel>
								</div>
							</div>
						)}
					/>
					<Route
						path="work-acceptances"
						element={(
							<div className="structure-page__d-column structure-page__d-column_entities">
								<Plate withoutPadding>
									<WorkAcceptancesTable
										objectId={objectId}
										headVisible
										columns={workAcceptanceDesktopColumns}
										workAcceptances={workAcceptances}
									/>
								</Plate>
							</div>
						)}
					/>

					<Route
						path=""
						element={(
							<div className="structure-page__d-column structure-page__d-column_entities">
								<DesktopActions structure={structure}/>
								<Plate withoutPadding>
									<Table
										objectId={objectId}
										columns={desktopColumns}
										structureId={structureId}
										selectedRows={selectedRows}
										onSelectedRowsChange={setSelectedRows}
									/>
								</Plate>
							</div>
						)}
					/>
				</Routes>
				<Outlet/>
			</div>
		</div>
	) : null;
};
