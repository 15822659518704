import {IGetTaskTypesResponse} from '@src/api/backend/taskTypes';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {addErrorToast} from '@src/utils/toasts';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useTaskTypes = (): IGetTaskTypesResponse | undefined => {
	const {data} = useQuery<IGetTaskTypesResponse, IError>({
		queryKey: [...restDictionariesQueryKeys.taskTypes()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке типов задач');
		}
	});
	return data;
};