import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type IUsersState = ILoadableEntitiesState<IBriefUser>;

export const users = createReducer<IUsersState>(getLoadableEntitiesInitialState<IBriefUser>(), {
	[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IBriefUser>(),
	[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IBriefUser>(),
	[CLEAR_BEFORE_UPDATE]: () => getLoadableEntitiesInitialState<IBriefUser>()
});
