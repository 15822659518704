import React, {Dispatch, SetStateAction} from 'react';
import classNames from 'classnames';
import {TextField} from '../inputs';

interface IItemNameProps<S> {
	item: S;
	onChange: (name: string, id: string) => void;
	onChangeEdit: (id: string, isEdited?: boolean) => void;
	setCurrent: Dispatch<SetStateAction<string | undefined>>;
	isEdited?: boolean;
	onChangeFocus?: (element: HTMLInputElement) => void;
}

export const ItemName = <S extends {id: string, name?: string }>(props: IItemNameProps<S>) => {
	const {
		item: {id, name}, onChange, onChangeEdit, isEdited, onChangeFocus, setCurrent
	} = props;
	return (
		<TextField
			placeholder="Название элемента"
			className={{
			root: 'editable-set__item-name',
			wrap: classNames(
				{'editable-set__item-name__view-wrap': !isEdited}
			),
			element: 'editable-set__item-name__element'
			}}
			value={name}
			onChange={value => onChange(value, id)}
			onClick={!isEdited ? () => onChangeEdit(id, isEdited) : () => setCurrent(id)}
			elementType={!isEdited ? 'div' : 'input'}
			elementRef={onChangeFocus}
		/>
);
};