import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {editPlansOrder, getPlans} from '@src/store/modules/dictionaries/plans/actions';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {DndTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import React, {useCallback, useEffect} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {columns} from './columns';

export const PlansTable = () => {
	const {pushPath} = useChangePath();
	const {objectId} = useStrictParams<{objectId?: string}>();
	const dispatch = useAppDispatch();
	const plans = useAppSelector(s => extractPlansAsArray(s, objectId || ''));

	const [loadingState, loadPlans] = useAsyncFn(async () => {
		if (objectId) {
			await dispatch(getPlans([objectId]));
		}
	}, [objectId]);

	useEffect(() => {
		void loadPlans();
	}, [objectId]);

	const handleRowClick = useCallback(
		(plan: IPlan) => pushPath(`/objects/${objectId}/plans/${plan.id}`),
		[objectId]
	);

	const handleSaveDrop = useCallback(
		(newPlans: IPlan[]) => {
			const newOrder = newPlans.map((item, index) => ({
				id: item.id,
				order: index
			}));
			if (objectId) {
				void dispatch(editPlansOrder(objectId, newOrder));
			}
		},
		[dispatch, objectId]
	);

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={loadPlans}>
				<LoadingPanel active={loadingState.loading}>
					<DndTable
						columns={columns}
						data={plans}
						onRowClick={handleRowClick}
						onSaveDrop={handleSaveDrop}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
