import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPlan} from '@src/store/modules/dictionaries/plans/selectors';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {CellProps} from 'react-table';

const LocationMobileCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const {planId, floor, objectId} = row.original;
	const plan = useAppSelector(s => extractPlan(s, objectId.id, planId || ''));
	const location = [];
	if (objectId) {
		location.push(objectId.name);
	}
	if (plan) {
		location.push(`План ${plan.name}`);
	}

	if (floor) {
		location.push(`Этаж ${floor}`);
	}

	const locationText = location.join(', ');
	return (
		<div className="tasks-page__m-table-location">
			{location.length > 0 && locationText}
		</div>
	);
};

export default LocationMobileCell;
