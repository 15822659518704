import React from 'react';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {DocumentsPage} from '@src/pages/DocumentsPage';
import {DocumentPage} from '@src/pages/DocumentPage';

export const documentsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<DocumentsPage/>}/>
	},
	{
		path: ':documentId',
		element: <PrivateRoute element={<DocumentPage/>}/>
	}
];