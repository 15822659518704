import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IconButton} from '@tehzor/ui-components';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getInternalAcceptances} from '@src/store/modules/entities/internalAcceptances/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';

interface IMobileEntityAddingProps {
	objectId: string;
}

const MobileEntityAdding = ({objectId}: IMobileEntityAddingProps) => {
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();

	const [, reloadList] = useAsyncFn(async () => {
		await dispatch(getInternalAcceptances(objectId));
	}, [objectId]);

	const [dialog, open] = useAddingInternalAcceptanceDialog({
		objectId,
		stage: object.stage,
		onSuccess: reloadList
	});

	return (
		<>
			<IconButton onClick={open}>
				<i className="tz-plus-24"/>
			</IconButton>
			{dialog}
		</>
	);
};

export default MobileEntityAdding;
