import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState, IInternalAcceptanceState} from './reducers';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

const extractInternalAcceptanceData = (state: IState): IInternalAcceptanceState =>
	state.entities.internalAcceptance || getInitialState();

/**
 * Извлекает текущую внутреннюю приёмку
 */
export const extractInternalAcceptance = createSelector(
	[extractInternalAcceptanceData], internalAcceptance => internalAcceptance.data
	);

/**
 * Извлекает нарушения внутренней приёмки
 */
export const extractInternalAcceptanceProblems = createSelector([extractInternalAcceptanceData], data =>
	data.problems.allIds.map(id => data.problems.byId[id]));

/**
 * Извлекает осмотры внутренней приёмки
 */
export const extractInternalAcceptanceInspections = createSelector([extractInternalAcceptanceData], data =>
	data.inspections.allIds.map(id => data.inspections.byId[id]));

/**
 * Извлекает ответы на нарушения внутренней приёмки
 */
export const extractInternalAcceptanceProblemReplies = createSelector([extractInternalAcceptanceData], data =>
	data.problemReplies.allIds.map(id => data.problemReplies.byId[id]));

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractInternalAcceptanceProblemCommentsByProblem = createSelector(
	[extractInternalAcceptanceProblemReplies],
	replies =>
		replies.reduce<Record<string, IProblemComment>>((prev, item) => {
			if (!prev[item.problemId]) {
				prev[item.problemId] = item;
			}
			return prev;
		}, {})
);

/**
 * Извлекает нарушения и осмотры единым списком с учетом фильтра
 */
export const extractInternalAcceptanceEntities = createSelector(
	[
		extractInternalAcceptanceProblems,
		extractInternalAcceptanceInspections,
		state => state.settings.pages.internalAcceptance.entitiesVisibility
	],
	(problems, inspections, visibility) => {
		let result: IInternalAcceptanceEntity[] = [];
		if (visibility.includes('problems') && problems.length) {
			result = problems.map(item => ({
				id: `p_${item.id}`,
				type: 'problem',
				data: item
			}));
		}
		if (visibility.includes('inspections') && inspections.length) {
			result = result.concat(
				inspections.map(item => ({
					id: `i_${item.id}`,
					type: 'inspection',
					data: item
				}))
			);
		}
		return result.sort((a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0));
	}
);
