import React, {useCallback, useContext} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {DispatchActionCtx} from '../table/ProblemsTable';

interface ISelectedEntitiesCopyingProps {
	selectedEntity: IProblemEntity;
}

const SelectedEntityCopying = ({selectedEntity}: ISelectedEntitiesCopyingProps) => {
	const dispatchAction = useContext(DispatchActionCtx);

	const copyProblem = useCallback(() => {
		dispatchAction({type: 'copy', payload: selectedEntity.data});
	}, [selectedEntity, dispatchAction]);

	return (
		<>
			<IconButton onClick={copyProblem}>
				<i className="tz-copy"/>
			</IconButton>
		</>
	);
};

export default SelectedEntityCopying;
