import * as types from './constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import {IGetDocumentTypesResponse, requestDocumentTypes} from '@src/api/backend/documentTypes/get';
import checkLoaded from '@tehzor/tools/core/checkLoaded';

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetDocumentTypesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке типов документов');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список типов документов
 */
export const getDocumentTypes = () =>
checkLoaded<IState, IGetDocumentTypesResponse, ApiAction>(
		s => s.dictionaries.documentTypes,
		createApiAction<IGetDocumentTypesResponse>(
			request,
			success,
			failure,
			requestDocumentTypes
		)
	);
