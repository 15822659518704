import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import React, {MouseEventHandler, useCallback, useState} from 'react';
import classNames from 'classnames';
import {ExpandablePanel, LinkButton} from '@tehzor/ui-components';
import {ItemHeader} from '@src/pages/WorkAcceptancePage/components/list/CheckListItems/components/ItemHeader';
import {ItemActions} from '@src/pages/WorkAcceptancePage/components/list/CheckListItems/components/ItemActions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Tree} from 'array-to-tree';
import {useToggle, useUpdateEffect} from 'react-use';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	extractCheckRecordByItemId,
	extractCheckRecordsIsChanging
} from '@src/store/modules/pages/checkLists/selectors/records';
import declination from '@tehzor/tools/utils/declination';
import {RecordStatusSelect} from '@src/pages/CheckListPage/components/RecordStatusSelect';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {extractCheckListById} from '@src/store/modules/dictionaries/checkLists/selectors';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';

interface ICheckListItemProps {
	item: Tree<ICheckItem>;
	mode: 'detail' | 'main';
	disabled?: boolean;
	padding?: number;
	acceptedItemsIds?: string[];
	onClick?: (item: ICheckItem) => void;
}

export const CheckListItem = ({
	padding = 24,
	disabled,
	item,
	onClick,
	mode,
	acceptedItemsIds
}: ICheckListItemProps) => {
	const {itemId, objectId} = useStrictParams<{itemId: string, objectId: string}>();

	const [changingState, setChangingState] = useState<boolean>(false);
	const [isExpanded, toggle] = useToggle(false);

	const isChanging = useAppSelector(extractCheckRecordsIsChanging);
	const checkList = useAppSelector(s => extractCheckListById(s, item.checkListId));
	const record = useAppSelector(s => extractCheckRecordByItemId(s, item.id));

	useUpdateEffect(() => {
		if (!isChanging) {
			setChangingState(false);
		}
	}, [isChanging]);

	const {changeCheckItemRecord} = useChangeCheckRecord(objectId, checkList);
	const handleClick = useCallback<MouseEventHandler>(
		e => {
			e.stopPropagation();
			onClick?.(item);
		},
		[item]
	);

	const isDisabledCheckItem = (item: ICheckItem): boolean => (
		!(item.dependencies?.length === 0 || item.dependencies?.every(depId => acceptedItemsIds?.includes(depId)))
	);

	return (
		<>
			<div
				className={classNames('check-list-items__item', {
					'check-list-items__item_active': mode === 'main' && itemId === item.id,
					'check-list-items__item_clickable': !!onClick,
					'check-list-items__item_disabled': disabled
				})}
				onClick={handleClick}
				style={padding ? {paddingLeft: `${padding}px`} : undefined}
			>
				<ItemHeader
					mode={mode}
					item={item}
					record={record}
				/>

				<div className="check-list-items__item-indicators">
					<ProblemIndicators
						checkListId={item.checkListId}
						checkItemId={item.id}
					/>
				</div>

				<div className="check-list-items__item-footer">
					<ClickPreventWrap className="check-list-items__item-state">
						<RecordStatusSelect
							item={item}
							mutation={changeCheckItemRecord}
							setChangingState={setChangingState}
							showLoader={changingState && isChanging}
							disabled={isChanging}
						/>
					</ClickPreventWrap>

					{mode === 'main' && (
						<ItemActions
							active={itemId === item.id}
							item={item}
							record={record}
							stage={checkList!.stage}
							setChangingState={setChangingState}
							disabled={isChanging}
						/>
					)}
				</div>

				{!disabled && mode === 'main' && item.children && item.children.length > 0 && (
					<div className="check-list-items__item-body">
						<LinkButton
							className={classNames('check-list-items__item-body-link', {
								'check-list-items__item-body-link_active': isExpanded
							})}
							rightIcon={<i className="tz-simple-arrow-16"/>}
							label={
								isExpanded
									? 'Скрыть'
									: `Показать ${item.children.length} ${declination(
										item.children.length,
										['подкатегорию', 'подкатегории', 'подкатегорий']
									)}`
							}
							onClick={toggle}
						/>
					</div>
				)}
			</div>

			{mode === 'main' && item.children && item.children.length > 0 && (
				<ExpandablePanel expanded={isExpanded}>
					<div className="check-list-items check-list-items__sub-items">
						{item.children.map(subitem => {
							const itemDisabled = isDisabledCheckItem(subitem);

							return itemDisabled ? (
								<div className="check-list-items__next">
									<div className="check-list-items__next_disabled"/>
									<CheckListItem
										key={subitem.id}
										mode="main"
										padding={padding + 32}
										item={subitem}
										onClick={onClick}
										disabled
									/>
								</div>
							) : (
								<CheckListItem
									key={subitem.id}
									mode="main"
									padding={padding + 32}
									item={subitem}
									onClick={onClick}
									acceptedItemsIds={acceptedItemsIds}
								/>
							);
						})}
					</div>
				</ExpandablePanel>
			)}
		</>
	);
};
