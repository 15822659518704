import React from 'react';
import {DelegationDialog} from '@src/components/DelegationDialog';
import {InlineButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {editProblemResponsible} from '@src/store/modules/pages/problem/actions/editResponsible';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {editWorkAcceptanceResponsible} from '@src/store/modules/entities/workAcceptance/actions/editResponsible';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';

interface IDelegationButtonProps {
	onlyActiveGroup?: boolean;
	inProgress?: boolean;
	entity: IPreparedProblem | IWorkAcceptance;
	type: 'problem' | 'work-acceptance';
	planId?: string;
}

export const DelegationButton = ({
	entity,
	onlyActiveGroup,
	inProgress,
	type,
	planId
}: IDelegationButtonProps) => {
	const {id, objectId, stage, categoryId, respUsers = [], activeGroup} = entity;
	const dispatch = useAppDispatch();
	const [isOpen, toggleOpen] = useToggle(false);

	const [, handleChange] = useAsyncFn(
		async (users: string[], group: string | undefined) => {
			if (type === 'problem') {
				await dispatch(editProblemResponsible(objectId, id, users, group));
			}
			if (type === 'work-acceptance') {
				await dispatch(editWorkAcceptanceResponsible(objectId, id, users, group));
			}
		},
		[entity, type]
	);

	let workingGroupType: WorkingGroupTypeId | undefined;

	if (type === 'problem') {
		workingGroupType = WorkingGroupTypeId.PERFORMERS;
	}

	if (type === 'work-acceptance') {
		workingGroupType = WorkingGroupTypeId.WORK_ACCEPTANCE_INSPECTORS;
	}

	return (
		<>
			<InlineButton
				className="responsible-view-field__btn"
				type="accent"
				label={inProgress ? 'Делегировать' : 'Переделегировать'}
				leftIcon={<i className="tz-delegate-20"/>}
				onClick={toggleOpen}
			/>

			<DelegationDialog
				objectId={objectId}
				stage={stage}
				categoryId={categoryId}
				planId={planId}
				activeGroup={activeGroup}
				selectedUsers={respUsers}
				selectedGroup={activeGroup}
				onlyInitialLevelSelectable={onlyActiveGroup}
				isOpen={isOpen}
				workingGroupType={workingGroupType}
				onChange={handleChange}
				onClose={toggleOpen}
			/>
		</>
	);
};
