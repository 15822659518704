const domain = 'check-items';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const CHANGE_SELECTED_ROWS = `${domain}/selected-rows/change`;

export const ADD_BATCH_REQUEST = `${domain}/add-batch/request`;
export const ADD_BATCH_SUCCESS = `${domain}/add-batch/success`;
export const ADD_BATCH_FAILURE = `${domain}/add-batch/failure`;

export const EDIT_BATCH_REQUEST = `${domain}/edit-batch/request`;
export const EDIT_BATCH_SUCCESS = `${domain}/edit-batch/success`;
export const EDIT_BATCH_FAILURE = `${domain}/edit-batch/failure`;