import React, {useState} from 'react';
import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {EditableAuthorizedPersonDialog} from '../EditableAuthorizedPersonDialog';

interface IUseEditableBankAccountDialogProps {
	authorizedPerson?: IAuthorizedPerson;
	legalEntityId?: string;
	companyId?: string;
}

interface IUseEditableBankAccountDialog {
	authorizedPersonDialog: JSX.Element;
	handleOpenAuthorizedPersonDialog: () => void;
}

export const useEditableAuthorizedPersonDialog = (
	props: IUseEditableBankAccountDialogProps
): IUseEditableBankAccountDialog => {
	const {authorizedPerson, legalEntityId, companyId} = props;
	const [isOpen, setOpen] = useState(false);

	const handleOpenAuthorizedPersonDialog = () => setOpen(true);
	const handleCloseAuthorizedPersonDialog = () => setOpen(false);

	const authorizedPersonDialog = (
		<EditableAuthorizedPersonDialog
			authorizedPerson={authorizedPerson}
			legalEntityId={legalEntityId}
			companyId={companyId}
			isOpen={isOpen}
			onClose={handleCloseAuthorizedPersonDialog}
		/>
	);

	return {authorizedPersonDialog, handleOpenAuthorizedPersonDialog};
};
