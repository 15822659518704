import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectPopup, FilterButton, SelectOption, Select2, SelectSearch} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface SelectOptionItem {
	id: string;
	name: string;
}

interface IStatusesFilterProps<T extends SelectOptionItem> {
	label?: string;
	statuses?: string[];
	allStatuses: T[];
	filterName?: string;
	resetOnApply?: string[];
}

export const StatusesFilter = memo(<T extends SelectOptionItem>(props: IStatusesFilterProps<T>) => {
	const {label = 'Статус', filterName = 'statuses', statuses, allStatuses, resetOnApply} = props;
	const [selectedStatuses, setSelectedStatuses] = useState(statuses);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const isSearchShown = useMemo(() => allStatuses.length >= 8, [allStatuses]);
	const filteredData = useMemo(() => flatFilter(allStatuses, 'name', search), [allStatuses, search]);

	const {dispatch} = useEntitiesFiltersCtx();

	const handleApply = useCallback(() => {
		const changes = {[filterName]: selectedStatuses};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		dispatch(changes);
		clearSearch();
	}, [filterName, selectedStatuses, resetOnApply, dispatch, clearSearch]);

	const handleChange = useCallback((v?: string[]) => {
		if (v && v.length === 0) {
			setSelectedStatuses(undefined);
		} else {
			setSelectedStatuses(v);
		}
	}, [clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedStatuses(undefined);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedStatuses(undefined);
		const changes = {[filterName]: undefined};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		dispatch(changes);
		clearSearch();
	}, [filterName, resetOnApply, dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedStatuses(statuses);
		clearSearch();
	}, [clearSearch, statuses]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedStatuses(allStatuses.map(item => item.id));
	}, [allStatuses, clearSearch]);

	useUpdateEffect(() => {
		setSelectedStatuses(statuses);
	}, [statuses]);

	return (
		<SelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedStatuses?.length}
			footer
			count={selectedStatuses?.length}
			noHeader={!isSearchShown}
			trigger={(
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel<T>(label, statuses, allStatuses)}
					active={!!(statuses && statuses.length != 0)}
					onClear={handleFullClear}
				/>
			)}
			search={isSearchShown && (
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
			onSelectAll={handleSelectAll}
		>
			<Select2
				multiple
				value={selectedStatuses}
				onChange={handleChange}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</SelectPopup>
	);
});
