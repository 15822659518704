import React, {useMemo} from 'react';
import declination from '@tehzor/tools/utils/declination';

interface ICategoriesSetsPageHeaderProps {
	total: number;
}

const CategoriesSetsPageHeader = ({total}: ICategoriesSetsPageHeaderProps) => {
	const amountOfSets = useMemo(() => {
		const words = ['набор', 'набора', 'наборов'];
		return `${total} ${declination(total, words)}`;
	}, [total]);

	return (
		<div className="categories-sets__header">
			<p>{amountOfSets}</p>
		</div>
	);
};

export default CategoriesSetsPageHeader;
