import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ISpaceState, getInitialState} from './reducers';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

const extractSpaceData = (state: IState): ISpaceState => state.entities.space || getInitialState();

/**
 * Возвращает данные помещения
 */
export const extractSpace = createSelector([extractSpaceData], space => space.data);
/**
 * Извлекает проверки помещения
 */
export const extractSpaceChecks = createSelector([extractSpaceData], data =>
	data.checks.allIds.map(id => data.checks.byId[id])
);

/**
 * Извлекает локальные проверки помещения
 */
export const extractSpaceLocalChecks = createSelector(
	[extractSpace, (state: IState) => state.localEntities.checks],
	(space, checks) => checks.filter(({links}) => links?.spaceId === space?.id) || []
);

/**
 * Извлекает локальные внутренние приемки помещения
 */
export const extractSpaceLocalInternalAcceptances = createSelector(
	[extractSpace, (state: IState) => state.localEntities.internalAcceptances],
	(space, internalAcceptances) =>
		internalAcceptances.filter(({links}) => links?.spaceId === space?.id) || []
);

/**
 * Извлекает локальные внутренние передачи собственнику
 */
export const extractSpaceLocalOwnerAcceptances = createSelector(
	[extractSpace, (state: IState) => state.localEntities.ownerAcceptances],
	(space, ownerAcceptances) =>
		ownerAcceptances.filter(({links}) => links?.spaceId === space?.id) || []
);

/**
 * Извлекает локальные гарантии
 */
export const extractSpaceLocalWarrantyClaims = createSelector(
	[extractSpace, (state: IState) => state.localEntities.warrantyClaims],
	(space, warrantyClaims) =>
		warrantyClaims.filter(({links}) => links?.spaceId === space?.id) || []
);

/**
 * Извлекает локальные нарушения помещения
 */
export const extractSpaceLocalProblems = createSelector(
	[extractSpace, (state: IState) => state.localEntities.problems],
	(space, problems) => problems.filter(({links}) => links?.spaceId === space?.id) || []
);

/**
 * Извлекает локальные осмотры помещения
 */
export const extractSpaceLocalInspections = createSelector(
	[extractSpace, (state: IState) => state.localEntities.inspections],
	(space, inspections) => inspections.filter(({links}) => links?.spaceId === space?.id) || []
);

/**
 * Извлекает внутренние приемки помещения
 */
export const extractSpaceInternalAcceptances = createSelector([extractSpaceData], data =>
	data.internalAcceptances.allIds.map(id => data.internalAcceptances.byId[id])
);

/**
 * Извлекает передачи помещения
 */
export const extractSpaceOwnerAcceptances = createSelector([extractSpaceData], data =>
	data.ownerAcceptances.allIds.map(id => data.ownerAcceptances.byId[id])
);

/**
 * Извлекает гарантийные обращения помещения
 */
export const extractSpaceWarrantyClaims = createSelector([extractSpaceData], data =>
	data.warrantyClaims.allIds.map(id => data.warrantyClaims.byId[id])
);

/**
 * Извлекает нарушения помещения
 */
export const extractSpaceProblems = createSelector([extractSpaceData], data =>
	data.problems.allIds.map(id => data.problems.byId[id])
);

/**
 * Извлекает осмотры помещения
 */
export const extractSpaceInspections = createSelector([extractSpaceData], data =>
	data.inspections.allIds.map(id => data.inspections.byId[id])
);

/**
 * Извлекает ответы на нарушения помещения
 */
export const extractSpaceProblemReplies = createSelector([extractSpaceData], data =>
	data.problemReplies.allIds.map(id => data.problemReplies.byId[id])
);

/**
 * Извлекает связанные задачи
 */

export const extractSpaceTasks = createSelector([extractSpaceData], data =>
	data.tasks.allIds.map(id => data.tasks.byId[id])
);

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractSpaceProblemCommentsByProblem = createSelector(
	[extractSpaceProblemReplies],
	replies =>
		replies.reduce<Record<string, IProblemComment>>((prev, item) => {
			if (!prev[item.problemId]) {
				prev[item.problemId] = item;
			}
			return prev;
		}, {})
);

const convert = <T extends {id: string}, E extends string, S>(
	data: T,
	entityName: E,
	subRows?: S[]
) => ({
	id: `${entityName}_${data.id}`,
	type: entityName,
	data,
	expanded: !!subRows?.length,
	subRows
});

const sortByCreation = (a: ISpaceEntity, b: ISpaceEntity) =>
	(b.data.createdAt ?? 0) - (a.data.createdAt ?? 0);

class EntitiesMap {
	private data: Record<string, ISpaceEntity[]> = {};

	get(key: string) {
		return this.data[key]?.sort(sortByCreation);
	}

	add(key: string, value: ISpaceEntity) {
		if (this.data[key]) {
			this.data[key].push(value);
		} else {
			this.data[key] = [value];
		}
	}
}

export const extractSpaceLocalEntities = createSelector(
	[
		extractSpaceLocalInternalAcceptances,
		extractSpaceLocalOwnerAcceptances,
		extractSpaceLocalChecks,
		extractSpaceLocalWarrantyClaims
	],
	(localInternalAcceptances, localAcceptances, localChecks, localWarrantyClaims) => ({
		localInternalAcceptances,
		localAcceptances,
		localChecks,
		localWarrantyClaims
	})
);

/**
 * Извлекает нарушения и осмотры единым списком с учетом фильтра
 */
export const extractSpaceEntities = createSelector(
	[
		extractSpaceLocalEntities,
		extractSpaceChecks,
		extractSpaceInternalAcceptances,
		extractSpaceOwnerAcceptances,
		extractSpaceWarrantyClaims,
		extractSpaceProblems,
		extractSpaceLocalProblems,
		extractSpaceInspections,
		extractSpaceLocalInspections,
		state => state.settings.pages.space.entitiesVisibility,
		state => state.entities.space.entitiesTab
	],
	(
		{localInternalAcceptances, localAcceptances, localChecks, localWarrantyClaims},
		checks,
		internalAcceptances,
		acceptances,
		claims,
		problems,
		localProblems,
		inspections,
		localInspections,
		visibility,
		entitiesTab
	) => {
		const checksSubRows = new EntitiesMap();
		const acceptancesSubRows = new EntitiesMap();
		const internalAcceptancesSubRows = new EntitiesMap();
		const claimsSubRows = new EntitiesMap();

		const allVisible = entitiesTab === 'all' || entitiesTab === undefined;
		const checksVisible = allVisible || entitiesTab === 'checks';
		const internalAcceptancesVisible = allVisible || entitiesTab === 'internal-acceptances';
		const acceptancesVisible = allVisible || entitiesTab === 'owner-acceptances';
		const claimsVisible = allVisible || entitiesTab === 'warranty-claims';
		const problemsVisible = visibility.includes('problems');
		const inspectionsVisible = visibility.includes('inspections');

		let result: ISpaceEntity[] = [];

		if (problemsVisible) {
			for (const problem of problems) {
				if (problem.links) {
					if (problem.links.checkId) {
						if (checksVisible) {
							checksSubRows.add(problem.links.checkId, convert(problem, 'problem'));
						}
					} else if (problem.links.ownerAcceptanceId) {
						if (acceptancesVisible) {
							acceptancesSubRows.add(
								problem.links.ownerAcceptanceId,
								convert(problem, 'problem')
							);
						}
					} else if (problem.links.internalAcceptanceId) {
						if (internalAcceptancesVisible) {
							internalAcceptancesSubRows.add(
								problem.links.internalAcceptanceId,
								convert(problem, 'problem')
							);
						}
					} else if (problem.links.warrantyClaimId) {
						if (claimsVisible) {
							claimsSubRows.add(
								problem.links.warrantyClaimId,
								convert(problem, 'problem')
							);
						}
					} else if (allVisible) {
						result.push(convert(problem, 'problem'));
					}
				}
			}
		}
		for (const localProblem of localProblems) {
			if (localProblem.localLinks?.checkId) {
				if (checksVisible) {
					checksSubRows.add(
						localProblem.localLinks.checkId,
						convert({id: localProblem.key, ...localProblem}, 'local-problem')
					);
				}
			}
			if (localProblem.links?.checkId) {
				if (checksVisible) {
					checksSubRows.add(
						localProblem.links.checkId,
						convert({id: localProblem.key, ...localProblem}, 'local-problem')
					);
				}
			}
			if (localProblem.localLinks?.internalAcceptanceId) {
				if (internalAcceptancesVisible) {
					internalAcceptancesSubRows.add(
						localProblem.localLinks.internalAcceptanceId,
						convert({id: localProblem.key, ...localProblem}, 'local-problem')
					);
				}
			}
			if (localProblem.links?.internalAcceptanceId) {
				if (internalAcceptancesVisible) {
					internalAcceptancesSubRows.add(
						localProblem.links.internalAcceptanceId,
						convert({id: localProblem.key, ...localProblem}, 'local-problem')
					);
				}
			}
			if (localProblem.localLinks?.ownerAcceptanceId) {
				if (acceptancesVisible) {
					acceptancesSubRows.add(
						localProblem.localLinks.ownerAcceptanceId,
						convert({id: localProblem.key, ...localProblem}, 'local-problem')
					);
				}
			}
			if (localProblem.links?.ownerAcceptanceId) {
				if (acceptancesVisible) {
					acceptancesSubRows.add(
						localProblem.links.ownerAcceptanceId,
						convert({id: localProblem.key, ...localProblem}, 'local-problem')
					);
				}
			}
			if (localProblem.localLinks?.warrantyClaimId) {
				if (claimsVisible) {
					claimsSubRows.add(
						localProblem.localLinks.warrantyClaimId,
						convert({id: localProblem.key, ...localProblem}, 'local-problem')
					);
				}
			}
			if (localProblem.links?.warrantyClaimId) {
				if (claimsVisible) {
					claimsSubRows.add(
						localProblem.links.warrantyClaimId,
						convert({id: localProblem.key, ...localProblem}, 'local-problem')
					);
				}
			}
		}
		for (const localInspection of localInspections) {
			if (localInspection.localLinks?.checkId) {
				if (checksVisible) {
					checksSubRows.add(
						localInspection.localLinks.checkId,
						convert({id: localInspection.key, ...localInspection}, 'local-inspection')
					);
				}
			}
			if (localInspection.links?.checkId) {
				if (checksVisible) {
					checksSubRows.add(
						localInspection.links.checkId,
						convert({id: localInspection.key, ...localInspection}, 'local-inspection')
					);
				}
			}
			if (localInspection.localLinks?.internalAcceptanceId) {
				if (internalAcceptancesVisible) {
					internalAcceptancesSubRows.add(
						localInspection.localLinks.internalAcceptanceId,
						convert({id: localInspection.key, ...localInspection}, 'local-inspection')
					);
				}
			}
			if (localInspection.links?.internalAcceptanceId) {
				if (internalAcceptancesVisible) {
					internalAcceptancesSubRows.add(
						localInspection.links.internalAcceptanceId,
						convert({id: localInspection.key, ...localInspection}, 'local-inspection')
					);
				}
			}
		}
		if (inspectionsVisible) {
			for (const inspection of inspections) {
				if (inspection.links) {
					if (inspection.links.checkId) {
						if (checksVisible) {
							checksSubRows.add(
								inspection.links.checkId,
								convert(inspection, 'inspection')
							);
						}
					} else if (inspection.links.internalAcceptanceId) {
						if (internalAcceptancesVisible) {
							internalAcceptancesSubRows.add(
								inspection.links.internalAcceptanceId,
								convert(inspection, 'inspection')
							);
						}
					} else if (allVisible) {
						result.push(convert(inspection, 'inspection'));
					}
				}
			}
		}
		if (checksVisible && checks.length) {
			result = result.concat(
				checks.map(item => convert(item, 'check', checksSubRows.get(item.id)))
			);
		}
		if (checksVisible && localChecks.length) {
			result = result.concat(
				localChecks.map(item =>
					convert({...item, id: item.key}, 'local-check', checksSubRows.get(item.key))
				)
			);
		}
		if (internalAcceptancesVisible && internalAcceptances.length) {
			result = result.concat(
				internalAcceptances.map(item =>
					convert(item, 'internal-acceptance', internalAcceptancesSubRows.get(item.id))
				)
			);
		}
		if (internalAcceptancesVisible && localInternalAcceptances.length) {
			result = result.concat(
				localInternalAcceptances.map(item =>
					convert(
						{...item, id: item.key},
						'local-internal-acceptance',
						internalAcceptancesSubRows.get(item.key)
					)
				)
			);
		}
		if (acceptancesVisible && acceptances.length) {
			result = result.concat(
				acceptances.map(item =>
					convert(item, 'owner-acceptance', acceptancesSubRows.get(item.id))
				)
			);
		}
		if (acceptancesVisible && localAcceptances.length) {
			result = result.concat(
				localAcceptances.map(item =>
					convert(
						{...item, id: item.key},
						'local-owner-acceptance',
						acceptancesSubRows.get(item.key)
					)
				)
			);
		}
		if (claimsVisible && claims.length) {
			result = result.concat(
				claims.map(item => convert(item, 'warranty-claim', claimsSubRows.get(item.id)))
			);
		}
		if (claimsVisible && localWarrantyClaims.length) {
			result = result.concat(
				localWarrantyClaims.map(item =>
					convert(
						{...item, id: item.key},
						'local-warranty-claim',
						claimsSubRows.get(item.key)
					)
				)
			);
		}
		return result.sort(sortByCreation);
	}
);
