import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {MobileFilter} from '@tehzor/ui-components';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface IPlansFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const PlansFilterMobile = memo(
	({objectId, value, entity, onChange}: IPlansFilterMobileProps) => {
		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');

		const plans = useAppSelector(s => extractPlansAsArray(s, objectId));
		const filteredData = useMemo(() => flatFilter(plans, 'name', search), [plans, search]);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'plans');

		const handleChange = useCallback((v: string[] | undefined) => {
			onChange({plans: v});
		}, []);

		return (
			<MobileFilter
				label="План"
				elements={filteredData}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
					/>
				)}
			/>
		);
	}
);
