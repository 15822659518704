import {IconMenu, MenuItem} from '@tehzor/ui-components';
import React, {useCallback, useContext} from 'react';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from '../DispatchActionCtx';
import {IPreparedCheckList} from '@src/pages/manage/CheckListsPage/interfaces/IPreparedCheckList';

export const MenuCell = ({row}: CellProps<IPreparedCheckList>) => {
	const menuActions = [];
	const perms = useCheckListPermissions();
	const dispatchAction = useContext(DispatchActionCtx);

	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [row.original.id]);

	if (perms.canDelete) {
		menuActions.push(
			<MenuItem
				key="edit"
				className="menu-item_red"
				icon={<i className="tz-delete"/>}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

	return menuActions.length ? <IconMenu>{menuActions}</IconMenu> : null;
};
