import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import _ from 'lodash';

export const extractAllLatestInternalAcceptancesAsArray = createSelector(
	(state: IState) => state.dictionaries.latestUserInternalAcceptances,
	({byId, allIds}) => allIds.map(id => byId[id])
);

/**
 * Возвращает последние internalAcceptances для конкретного объекта в виде массива
 */
export const extractLatestInternalAcceptancesAsArray = createSelector(
	extractAllLatestInternalAcceptancesAsArray,
	(state: IState) => state.auth.profile.id,
	(state: IState, objectId: string) => objectId,
	(state: IState, objectId: string, spaceId?: string) => spaceId,
	(arr, userId, objectId, spaceId) =>
		arr.filter(item =>
			item.objectId === objectId
			&& item.createdBy?.id === userId
			&& (!spaceId || spaceId === item.links?.spaceId))
);

export const extractLocalInternalAcceptancesAsArray = createSelector(
	(state: IState) => state.localEntities.internalAcceptances,
	(state: IState, objectId: string) => objectId,
	(state: IState, objectId: string, spaceId?: string) => spaceId,
	(data, objectId, spaceId) => {
		if (spaceId) {
			return data.filter(item => item.links?.spaceId === spaceId);
		}
		return data.filter(item => item.objectId === objectId);
	}
);

/**
 * Возвращает последнюю внутреннюю приемку для конкретного объекта
 */

export const extractLatestInternalAcceptanceId = createSelector(
	extractLatestInternalAcceptancesAsArray,
	extractLocalInternalAcceptancesAsArray,
	(data, local): string | undefined => {
		if (local.length) {
			return _.maxBy(local, 'modifiedAt')?.key;
		}
		if (data.length) {
			return _.maxBy(data, 'modifiedAt')?.id;
		}
		return undefined;
	}
);
