import React from 'react';
import {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';
import './ExportTemplatesInstruction.less';
import InstructionElement, {IItem} from './InstructionElement/InstructionElement';

const data = {
	fixed: {
		title: 'Общие правила заполнения шаблона',
		records: [
			{
				field: 'Редактируемые поля',
				children: [
					{
						field: '"Название"',
						description:
							'Обязатеьное поле! Это то название которое будет видеть пользователь приложения, когда будет делать выдбор шаблона для экспорта данных'
					},
					{
						field: '"Страница для экспорта"',
						description:
							'Обязатеьное поле! Варианты доступных шаблонов зависят от страницы приложения с которой пользователь хочет экспортировать данные, это один из ключевых параметров шаблона, от него зависят параметры которые можно использовать в файле шаблона'
					},
					{
						field: '"Название файла на выходе"',
						description:
							' Опциональное поле, по умолчанию ставится такое же значение как в поле "Название". То как будет назван файл, который будет отправлен пользователю после экспорта. Допускаются буквы латинского и русского алфавита. Здесь можно использовать параметры, для этого нужно вставить название параметра в двойных фигурных скобках. например {{check.number}}'
					},
					{
						field: '"Компания"',
						description: 'Опциональное поле. Определяет доступность шаблона для одной выбранной компании'
					},
					{
						field: '"Объекты"',
						description:
							'- временно не активно- Опциональное поле. Определяет доступность шаблона для конткретных объектов одной выбранной компании'
					},
					{
						field: '"Прикрепить файл"',
						description: 'Кнопка добавления файла шаблона'
					}
				]
			},
			{
				field: 'Формат загружаемого файла',
				children: [
					{
						field: 'расширение файла',
						description:
							'Расширение файла допускается .docx или .xlsx (только ворд и эксель), системма не даст загрузить файл с другим расширением'
					},
					{
						field: 'название файла',
						description:
							'В названии файла допускаются буквы латинского алфавита, цифры и знаки тире. Название файла должно буть уникальным, система не даст загрузить файл если уже существует файл шаблона с таким же названием'
					}
				]
			},
			{
				field: 'Форматирование файла',
				children: [
					{
						field: 'шрифт и цвет',
						description:
							'Все форматирование загружаемого файла сохранится и в итоговом файле, за исключением спецсимволов для работы с параметрами. Непосредственно названия параметров должны быть отформатированы. Если сделать название параметра красным и жирным то и в итоговом файле параметр будет красным и жирным.'
					}
				]
			},
			{
				field: 'Работа с параметрами в .docx',
				children: [
					{
						field: 'Вызов параметра',
						description:
							'Пишем название параметра в фигурных скобках например {parametr}, вызов вложенного поля в параметр пишем через точку например  {problem.number}'
					},
					{
						field: 'Условия',
						description:
							'перед вызовом парметра, ставится знак "#" и навзвание параметра в фигурных скобках, после вызова параметра стависят слеш в фигурных скобках. пример {#object.name}какой-то текст{object.name}какой-то текст {/}. Если условие не выполниось то все что записано внутри него проигнорируется и не будет выведено в итоговом файле'
					},
					{
						field: 'Перебор вложенных параметров',
						description: ''
					},
					{
						field: 'Циклы',
						description:
							'Циклы можно применять только к массивамю Для этого нужно поставить знак # перед названием массива в фиурных скобках и по окончании перебора поставить слеш в фигурных скобках. Например {#problems} ... {/}. Названия свойств перебераемых в чистом виде. Например{#problems} {number} {description} {#createdBy}{createdBy.fullName}{/} {/}. Внутри циклов можно использовать условия'
					},
					{
						field: 'Работа с изображениями',
						description:
							'Пример перебора вложений с выводом изображений {#attachments.length>0}Фотографии{#attachments}{%%attachmentImage}{/}{/}'
					},
					{
						field: 'Формат даты',
						description: 'Пример работы с датой {currentDate | date:timezone}'
					}
				]
			},
			{
				field: 'Работа с параметрами в .xlsx',
				children: [
					{
						field: 'Циклы',
						description:
							'Вся работа с параметрами в экселе стротся вокруг перебора массивов, вот пример перебора массива [[problems | repeat-rows:1]]  - пишется в первой ячейке строки и во всех других ячейках строки можно вызывать параметры, repeat-rows - количество строк которые будут выводится на каждой итерации'
					},
					{
						field: 'Вызов параметра',
						description:
							'Пишем название параметра в двойых фигурных скобках например {{parametr}},вложенный параметр вызывается через точку Пример: {{user.position}}'
					},
					{
						field: 'Работа с изображениями',
						description: 'Пример {{firstImage|image}}'
					}
				]
			}
		]
	},
	[ExportTemplatesDestinationId.PROBLEMS]: {
		title: 'Параметры доступные для шаблона на странице нарушений',
		records: [
			{
				field: 'object',
				description: 'Объект',
				children: [
					{
						field: 'name',
						description: 'название'
					},
					{
						field: 'fullName',
						description: 'полное название'
					},
					{
						field: 'city',
						description: 'город'
					},
					{
						field: 'address',
						description: 'адрес'
					}
				]
			},
			{
				field: 'user',
				description: 'Пользователь который экспортирует документ',
				children: [
					{
						field: 'position',
						description: 'должность'
					},
					{
						field: 'fullName',
						description: 'полное имя'
					},
					{
						field: 'email',
						description: 'почта'
					},
					{
						field: 'phone',
						description: 'телефон'
					}
				]
			},
			{
				field: 'currentDate',
				description: 'Текущая дата'
			},
			{
				field: 'problems',
				description: 'массив нарушений',
				children: [
					{
						field: 'objectName',
						description: 'имя объекта'
					},
					{
						field: 'number',
						description: 'номер нарушения'
					},
					{
						field: 'plannedFixDate',
						description: 'срок устранение'
					},
					{
						field: 'critical',
						description: 'критичность'
					},
					{
						field: 'category',
						description: 'название категрии нарушения'
					},
					{
						field: 'этаж',
						description: 'этаж'
					},
					{
						field: 'reason',
						description: 'основание'
					},
					{
						field: 'location',
						description: 'местоположение'
					},
					{
						field: 'hasLocation',
						description: 'наличие местоположения boolean'
					},
					{
						field: 'description',
						description: 'описание'
					},
					{
						field: 'prescription',
						description: 'предписание'
					},
					{
						field: 'status',
						description: 'статус нарушения'
					},
					{
						field: 'planName',
						description: 'название плана'
					},
					{
						field: 'planImage',
						description: 'изображение плана'
					},
					{
						field: 'attachments',
						description: 'массив вложений'
					},
					{
						field: 'firstImage',
						description: 'первое прикрепленное изображение'
					},
					{
						field: 'officialImage',
						description: 'изображение официального ответа'
					},
					{
						field: 'createdAt',
						description: 'дата создания'
					},
					{
						field: 'createdBy',
						description: 'автор нарушения',
						children: [
							{
								field: 'position',
								description: 'должность'
							},
							{
								field: 'fullName',
								description: 'полное имя'
							},
							{
								field: 'email',
								description: 'почта'
							}
						]
					},
					{
						field: 'createdByFullName',
						description: 'полное имя автора'
					},
					{
						field: 'modifiedBy',
						description: 'автор последних изменений',
						children: [
							{
								field: 'position',
								description: 'должность'
							},
							{
								field: 'fullName',
								description: 'полное имя'
							},
							{
								field: 'email',
								description: 'почта'
							}
						]
					},
					{
						field: 'modifiedAt',
						description: 'дата изменения'
					},
					{
						field: 'respUsers',
						description: 'строка с перечислением конечных отвественных (их полные имена)'
					},
					{
						field: 'respUsersWithContractorNameString',
						description: 'строка со перечислением всех ответственных с названием подрядчика'
					},
					{
						field: 'respUsersWithContractorName',
						description: 'массив ответственных с названием подрадчика'
					},
					{
						field: 'initialGroupLeader',
						description: 'руководитель первоначальной рабочей группы',
						children: [
							{
								field: 'position',
								description: 'должность'
							},
							{
								field: 'fullName',
								description: 'полное имя'
							},
							{
								field: 'email',
								description: 'почта'
							}
						]
					},
					{
						field: 'initialGroupLeaderContractor',
						description: 'компания подрядчик руководителя первоначальной рабочей группы'
					},
					{
						field: 'contractors',
						description: 'строка с перечислением всех подрядчиков для нарушения'
					},
					{
						field: 'altSpaceName',
						description: 'альтернативный номер помещения'
					},
					{
						field: 'comments',
						description: 'массив комментариев'
					},
					{
						field: 'index',
						description: 'порядковый номер для отображения в списке'
					}
				]
			},
			{
				field: 'problemsGroupedByObjects',
				description: 'массив нарушений сгруппированных по объектам',
				children: [
					{
						field: 'problemObjectName',
						description: 'название объекта'
					},
					{
						field: 'problems',
						description: 'массив нарушений со всеми вложенными полями'
					}
				]
			},
			{
				field: 'createdBySet',
				description: 'набор авторов нарушений'
			},
			{
				field: 'respUsersSet',
				description: 'набор ответственных из нарушений',
				children: [
					{
						field: 'respUser',
						description: 'ответственный'
					}
				]
			},
			{
				field: 'initialGroupLeadersSet',
				description: 'набор руководителей первоначальных рабочих групп',
				children: [
					{
						field: 'groupLeader',
						description: 'руководитель рабочей группы'
					}
				]
			},
			{
				field: 'timezone',
				description: 'временная зона'
			}
		]
	},
	[ExportTemplatesDestinationId.CHECKLIST]: {
		title: 'Параметры доступные для шаблона на странице одного чек-листа',
		records: [
			{
				field: 'user',
				description: 'Пользователь который экспортирует документ',
				children: [
					{
						field: 'fullName',
						description: 'полное имя'
					},
					{
						field: 'position',
						description: 'должность'
					},
					{
						field: 'email',
						description: 'почта'
					},
					{
						field: 'phone',
						description: 'телефон'
					}
				]
			},
			{
				field: 'object',
				description: 'объект',
				children: [
					{
						field: 'name',
						description: 'название'
					},
					{
						field: 'fullName',
						description: 'полное название'
					},
					{
						field: 'address',
						description: 'адресс'
					},
					{
						field: 'company',
						description: 'компания'
					},
					{
						field: 'city',
						description: 'город'
					},
					{
						field: 'generalContractor',
						description: 'генеральный подрядчик'
					},
					{
						fileld: 'contractors',
						description: 'массив подрядчиков',
						children: [
							{
								field: 'companyId',
								description: 'id компании'
							},
							{
								field: 'isGeneral',
								description: ' boolean, является ли генеральным подрядчиком'
							},
							{
								field: 'respRules',
								description: 'правила ответственности??',
								children: [
									{
										field: 'userId',
										description: 'id пользователя'
									},
									{
										field: 'categories',
										description: 'массив id категорий'
									},
									{
										field: 'plans',
										description: 'массив id планов'
									},

									{
										field: 'sectors',
										description: 'массив id секторов'
									},

									{
										field: 'autoSelect',
										description: 'boolean наличие автовыбора'
									}
								]
							}
						]
					}
				]
			},
			{
				field: 'space',
				description: 'помещение, опционально поле',
				children: [
					{
						field: 'name',
						description: 'номер помещения'
					},
					{
						field: 'floor',
						description: 'этаж'
					}
				]
			},
			{
				field: 'checkList',
				description: 'данные чеклиста',
				children: [
					{
						field: '_id',
						description: 'id чеклиста'
					},
					{
						field: 'name',
						description: 'название'
					},
					{
						field: 'companyId',
						description: 'id компании'
					},
					{
						field: 'objects',
						description: 'массив id объектов'
					},
					{
						field: 'stage',
						description: 'стадия building acceptance transfer warranty'
					},
					{
						field: 'spaceTypes',
						description: 'массив типов помещений'
					},
					{
						field: 'type',
						description: 'тип чеклиста  spaces objects work-acceptances'
					},
					{
						field: 'typeDecoration',
						description: 'id типа оделки'
					},
					{
						field: 'categories',
						description: 'массив id категорий'
					},
					{
						field: 'createdAt',
						description: 'дата создания'
					},
					{
						field: 'createdBy',
						description: 'id автора чеклиста'
					},
					{
						field: 'modifiedAt',
						description: 'дата последнего изменения'
					},
					{
						field: 'modifiedBy',
						description: 'id пользователя внесшего последние изменения'
					},
					{
						field: 'externalId',
						description: 'id во внешних системах'
					}
				]
			},
			{
				field: 'categories',
				description:
					'массив специальным образом сконфигурированных категорий чеклиста для отображения в таблице',
				children: [
					{
						field: 'category',
						description: 'имя категории чек-листа'
					},
					{
						field: 'index',
						description: 'порядковый номер для отображения в таблице'
					},
					{
						field: 'subCategories',
						description: 'массив подкатегорий',
						children: [
							{
								field: 'name',
								description: 'название подкатегории чек-листа'
							},
							{
								field: 'ready',
								description: ' boolean, готовность категории'
							}
						]
					}
				]
			},
			{
				field: 'problems',
				description: 'массив нарушений',
				children: [
					{
						field: 'id',
						description: 'id нарушения'
					},
					{
						field: 'number',
						description: 'номер нарушения'
					},
					{
						field: 'plannedFixDate',
						description: 'срок устранения'
					},
					{
						field: 'category',
						description: 'название категории'
					},
					{
						field: 'floor',
						description: 'этаж'
					},
					{
						field: 'reason',
						description: 'снование'
					},
					{
						field: 'reasonId',
						description: 'id основания'
					},
					{
						field: 'location',
						description: 'местоположение'
					},
					{
						field: 'hasLocation',
						description: 'boolean наличие местоположения'
					},
					{
						field: 'description',
						description: 'описание'
					},
					{
						field: 'status',
						description: 'статус'
					},
					{
						field: 'planName',
						description: 'название плана'
					},
					{
						field: 'planImage',
						description: 'изображение плана'
					},
					{
						field: 'attachments',
						description: 'массив вложений'
					},
					{
						field: 'respUsers',
						description: 'строка со всеми отвтественными'
					},
					{
						field: 'respUsersRaw',
						description: 'массив данных по ответственным',
						children: [
							{
								field: 'id',
								description: 'id пользователя'
							},
							{
								field: 'fullName',
								description: 'полное имя'
							},
							{
								field: 'position',
								description: 'должность'
							},
							{
								field: 'email',
								description: 'почта'
							}
						]
					},
					{
						field: 'activeGroup',
						description: 'название конечной рабочей группы'
					},
					{
						field: 'activeGroupLeader',
						description: 'руководитель конечной группы',
						children: [
							{
								field: 'id',
								description: 'id пользователя'
							},
							{
								field: 'fullName',
								description: 'полное имя'
							},
							{
								field: 'position',
								description: 'должность'
							},
							{
								field: 'email',
								description: 'почта'
							}
						]
					},
					{
						field: 'createdAt',
						description: 'дата создания нарушения'
					},
					{
						field: 'createdBy',
						description: 'автор нарушения',
						children: [
							{
								field: 'id',
								description: 'id пользователя'
							},
							{
								field: 'fullName',
								description: 'полное имя'
							},
							{
								field: 'position',
								description: 'должность'
							},
							{
								field: 'email',
								description: 'почта'
							}
						]
					},
					{
						field: 'modifiedAt',
						description: ''
					},
					{
						field: 'modifiedBy',
						description: 'автор последних изменений в нарушении',
						children: [
							{
								field: 'id',
								description: 'id пользователя'
							},
							{
								field: 'fullName',
								description: 'полное имя'
							},
							{
								field: 'position',
								description: 'должность'
							},
							{
								field: 'email',
								description: 'почта'
							}
						]
					},
					{
						field: 'index',
						description: 'порядковый номер для отображения в таблице'
					},
					{
						field: 'critical',
						description: 'критичность "Критично" : undefined'
					},
					{
						field: 'expired',
						description: '"Просрочено" : undefined'
					},

					{
						field: 'createdByFullName',
						description: 'полное имя автора нарушения'
					},

					{
						field: 'firstImage',
						description: 'первое из вложенных изображений'
					},

					{
						field: 'prescription',
						description: 'предписание'
					},

					{
						field: 'altSpaceName',
						description: 'альтернативный номер помещения'
					},

					{
						field: 'comments',
						description: 'массив комментариев',
						children: [
							{
								field: 'id',
								description: 'id комментария'
							},
							{
								field: 'parentId',
								description: 'id родительского комментария'
							},
							{
								field: 'description',
								description: 'описание'
							},

							{
								field: 'createdAt',
								description: 'дата создания'
							},

							{
								field: 'modifiedAt',
								description: 'дата изменения'
							},

							{
								field: 'createdBy',
								description: 'автор комментария',
								children: [
									{
										field: 'fullName',
										description: 'полное имя'
									},
									{
										field: 'position',
										description: 'должность'
									}
								]
							},

							{
								field: 'modifiedBy',
								description: 'автор последнего изменения',
								children: [
									{
										field: 'fullName',
										description: 'полное имя'
									},
									{
										field: 'position',
										description: 'должность'
									}
								]
							},

							{
								field: 'commentAttachments',
								description: 'массив вложений',
								children: [
									{
										field: 'attachmentImage',
										description: 'изобрадение приложенное к комментарию'
									}
								]
							}
						]
					},

					{
						field: 'initialGroupLeader',
						description: 'руководитель первоначальной рабочей группы',
						children: [
							{
								field: 'id',
								description: 'id пользователя'
							},
							{
								field: 'fullName',
								description: 'полное имя'
							},
							{
								field: 'position',
								description: 'должность'
							},
							{
								field: 'email',
								description: 'почта'
							}
						]
					}
				]
			},
			{
				field: 'timezone',
				description: 'временная зона'
			}
		]
	},
	[ExportTemplatesDestinationId.CHECKLISTS]: {
		title: 'Параметры доступные для шаблона на странице чек-листов',
		records: [
			{
				field: 'user',
				description: 'Пользователь который экспортирует документ',
				children: [
					{
						field: 'position',
						description: 'должность'
					},
					{
						field: 'fullName',
						description: 'полное имя'
					},
					{
						field: 'email',
						description: 'почта'
					},
					{
						field: 'phone',
						description: 'телефон'
					}
				]
			},
			{
				field: 'spaces',
				description: 'массив помещений',
				children: [
					{
						field: 'space',
						description: 'помещение',
						children: [
							{
								field: 'objectId',
								description: 'id объека'
							},
							{
								field: 'externalId',
								description: 'id во внешних системах'
							},
							{
								field: 'name',
								description: 'номер помещения'
							},
							{
								field: 'altName',
								description: 'альтернативный номер помещения'
							},
							{
								field: 'type',
								description: 'тип помещения'
							},
							{
								field: 'status',
								description: 'статус помещения'
							},
							{
								field: 'indicators',
								description: 'массив индикаторов'
							},
							{
								field: 'locations',
								description: 'массив местоположений',
								children: [
									{
										field: 'planId',
										description: 'id плана'
									},
									{
										field: 'sectorId',
										description: 'id сектора'
									}
								]
							},
							{
								field: 'floor',
								description: 'этаж'
							},
							{
								field: 'plannedArea',
								description: 'планируемая площадь'
							},
							{
								field: 'actualArea',
								description: 'акутальная площадь'
							},
							{
								field: 'typeDecoration',
								description: 'тип отделки помещения'
							},
							{
								field: 'attachments',
								description: 'массив вложений'
							},
							{
								field: 'areaBTI',
								description: 'площадь БТИ'
							},
							{
								field: 'numberBTI',
								description: 'номер БТИ'
							},
							{
								field: 'floorBTI',
								description: 'этаж БТИ'
							},
							{
								field: 'contractForm',
								description: 'форма контракта'
							},
							{
								field: 'markupForRegistration',
								description: 'наценка за регистрацию'
							},
							{
								field: 'decorationWarrantyExpiredDate',
								description: 'срок окончания гарантии на отделочные работы'
							},
							{
								field: 'constructiveWarrantyExpiredDate',
								description: 'срок окончания гарантии на конструктив'
							},
							{
								field: 'technicalEquipmentWarrantyExpiredDate',
								description: 'срок окончания гарантии на техническое оборудование'
							},
							{
								field: 'createdAt',
								description: 'дата создания помещения в системе'
							},
							{
								field: 'modifiedAt',
								description: 'дата последнего изменения помещения в системе'
							}
						]
					},
					{
						field: 'object',
						description: 'объект',
						children: [
							{
								field: 'externalId',
								description: ''
							},

							{
								field: 'name',
								description: ''
							},

							{
								field: 'fullName',
								description: ''
							},

							{
								field: 'ancestors',
								description: ''
							},

							{
								field: 'parentId',
								description: ''
							},

							{
								field: 'previewImage',
								description: ''
							},

							{
								field: 'fullImage',
								description: ''
							},

							{
								field: 'city',
								description: ''
							},

							{
								field: 'address',
								description: ''
							},

							{
								field: 'generalContractor',
								description: ''
							},

							{
								field: 'stats',
								description: ''
							},

							{
								field: '',
								description: ''
							},

							{
								field: '',
								description: ''
							},

							{
								field: '',
								description: ''
							},

							{
								field: '',
								description: ''
							},

							{
								field: '',
								description: ''
							},

							{
								field: '',
								description: ''
							}

						]
					},
					{
						field: 'checkLists',
						description: 'чек-листы',
						children: []
					}
				]
			},
			{
				field: 'timezone',
				description: 'временная зона'
			}
		]
	}
};

interface IExportTemplatesInstructionProps {
	destination?: ExportTemplatesDestinationId | 'fixed';
	hideSubtitle?: boolean;
}
const ExportTemplatesInstruction = (props: IExportTemplatesInstructionProps) => {
	const {destination, hideSubtitle} = props;

	if (!destination || !data[destination]) {
		return (
			<div className="instruction__title">
				Здесь будет перечень доступных параметров, после того как вы выберете страницу для экспорта
			</div>
		);
	}
	const records = data[destination].records as IItem[];
	const title = data[destination].title;

	return (
		<div className="instruction">
			<div className="instruction__title">{title}</div>
			{records.map(record =>
				InstructionElement({
					index: 0,
					field: record.field,
					description: record.description,
					children: record.children,
					hideSubtitle
				}))}
		</div>
	);
};

export default ExportTemplatesInstruction;
