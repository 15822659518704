import {CellProps, Column} from 'react-table';
import MenuCell from './MenuCell';
import LocationCell from './LocationCell';
import {FixDateCell} from './FixDateCell';
import DescriptionCell from './DescriptionCell';
import ResponsibleCell from './ResponsibleCell';
import ModifiedCell from './ModifiedCell';
import CreatedCell from './CreatedCell';
import {ProblemStatusCellWrap} from '@src/components/tableCells/ProblemStatusCell';
import CellWrap from '@src/components/tableCells/CellWrap';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import LocalProblemStatusCell from '@src/components/tableCells/LocalProblemStatusCell';
import {InspectorsCell} from './InspectorsCell';
import LocationCellWithoutObject from './LocationCellWithoutObject';
import {IProblemAvailableField} from '@src/utils/testRestrictions';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';

export const getDesktopColumns = (
	objectId: string,
	settings?: IProblemSettings
): Array<Column<IProblemEntity>> => {
	const numberColumn: Column<IProblemEntity> = {
		id: 'number',
		Header: '№',
		accessor: row => row.data.number || row.data.localNumber,
		Cell: (props: CellProps<IProblemEntity>) =>
			DescriptionCell({...props, settings}),
		width: 160,
		className: 'problems-page__d-table-description-cell'
	};

	const statusColumn: Column<IProblemEntity> = {
		id: 'status',
		Header: 'Статус',
		Cell: CellWrap({
			problem: ProblemStatusCellWrap,
			local_problem: LocalProblemStatusCell
		}),
		width: 110
	};

	const plannedFixDateColumn: Column<IProblemEntity> = {
		id: 'plannedFixDate',
		Header: 'Срок устранения',
		accessor: row => row.data.plannedFixDate,
		width: 120,
		Cell: FixDateCell,
		sortDescFirst: true
	};

	const responsibleColumn: Column<IProblemEntity> = {
		Header: 'Ответственные',
		Cell: ResponsibleCell,
		width: 100
	};

	const inspectorsColumn: Column<IProblemEntity> = {
		Header: 'Проверяющие',
		Cell: InspectorsCell,
		width: 100
	};

	const locationsColumn: Column<IProblemEntity> = {
		id: 'location',
		Header: 'Место',
		Cell: objectId === 'all' ? LocationCell : LocationCellWithoutObject,
		width: 100,
		defaultCanSort: false
	};

	const createdAtColumn: Column<IProblemEntity> = {
		id: 'createdAt',
		Header: 'Добавлено',
		accessor: row => row.data.createdAt,
		Cell: CreatedCell,
		width: 100,
		sortDescFirst: true
	};

	const modifiedAtColumn: Column<IProblemEntity> = {
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: row => row.data.modifiedAt,
		Cell: ModifiedCell,
		width: 100,
		sortDescFirst: true
	};

	const menuColumn: Column<IProblemEntity> = {
		id: '_menu',
		Cell: MenuCell,
		className: 'problems-page__d-table-menu',
		width: 120,
		defaultCanSort: false,
		disableResizing: true
	};

	const columns: Array<Column<IProblemEntity>> = [numberColumn, statusColumn];
	const availableColumnsMap: Partial<
		Record<IProblemAvailableField, Column<IProblemEntity>>
	> = {
		plannedFixDate: plannedFixDateColumn,
		respUsers: responsibleColumn,
		inspectors: inspectorsColumn,
		location: locationsColumn
	};

	if (settings) {
		for (const [key, value] of Object.entries(availableColumnsMap)) {
			if (settings[key]) {
				columns.push(value);
			}
		}
	}
	columns.push(createdAtColumn, modifiedAtColumn, menuColumn);

	return columns;
};