import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {transformLocations} from '@src/pages/CheckListPage/utils/transformLocations';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractShape} from '@src/store/modules/dictionaries/plans/selectors';
import {ReactNode} from 'react';
import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getSpaceTasks} from '@src/store/modules/entities/space/actions/tasks/get';

export const useAddingSpaceTaskDialog = (space?: ISpace): [ReactNode, () => void] => {
	const dispatch = useAppDispatch();
	const [planId, sectorId] = transformLocations(space?.locations);
	const sector = useAppSelector(s => extractShape(s, space?.objectId, planId, sectorId));
	const onSuccess = () => {
		void dispatch(getSpaceTasks(space?.id));
	};
	const sectors = sector ? [{sector: sector.id, name: sector.name}] : undefined;
	const link = sector ? {spaceId: space?.id} : undefined;

	const taskDialogArgs = {
		objectId: space?.objectId,
		taskType: TaskTypeId.ACCEPTANCE_CALL,
		floor: space?.floor,
		location: {sectors},
		planId,
		links: link,
		onSuccess
	};

	return useAddingTaskDialog(taskDialogArgs);
};
