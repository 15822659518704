import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

export const TaskDescriptionMobileCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const task = row.original;
	return (
		<div>
			<div className="tasks-page__m--task-description">
				<div className="tasks-page__task-description-name">
					{task.name}
				</div>
				{task?.attachments?.[0] && (
					<div className="tasks-page__m--task-description-preview">
						<i className="tz-document-20"/>
					</div>
				)}
			</div>

			{task?.description && (
				<div className="tasks-page__task-description-long">
					{task?.description}
				</div>
			)}
		</div>
	);
};
