import React, {useCallback, useState} from 'react';
import EditableSpaceOwnerDialog from '../EditableSpaceOwnerDialog';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export function useEditableSpaceOwnerDialog(
	spaceId: string
): [React.ReactNode, (owner?: ISpaceOwner) => void] {
	const [isOpen, setOpen] = useState<boolean>(false);
	const [activeOwner, setActiveOwner] = useState<ISpaceOwner | undefined>();

	const open = useCallback((owner: ISpaceOwner | undefined) => {
		setActiveOwner(owner);
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
		setActiveOwner(undefined);
	}, []);

	const dialog = (
		<EditableSpaceOwnerDialog
			spaceId={spaceId}
			owner={activeOwner}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
