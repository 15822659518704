import React from 'react';
import './TouchablePanel.less';
import classNames from 'classnames';
import anime, {AnimeInstance} from 'animejs';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';
import ResizeObserver from 'resize-observer-polyfill';
import TouchRecognizer from '../../various/TouchRecognizer';

const endMovingImpulse = 180;
const animMass = 1;
const animStiffness = 90;
const animNormalDumping = 100;
const animOutsideDumping = 14;

interface ITouchablePanelProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	frameRef?: (element: HTMLDivElement | null) => void;

	onClick?: (event: MouseEvent | TouchEvent) => void;
	onResize?: () => void;
}

class TouchablePanel extends React.PureComponent<ITouchablePanelProps> {
	static displayName = 'TouchablePanel';

	/**
	 * Корневой элемент, ограничивающий видимую область
	 */
	private frame: HTMLDivElement | null | undefined;

	/**
	 * Элемент-обёртка для перемещаемого контента
	 */
	private wrapper: HTMLDivElement | null | undefined;

	/**
	 * Контейнер для контента
	 */
	private content: HTMLDivElement | null | undefined;

	/**
	 * Ширина фрейма
	 */
	private frameWidth = 0;

	/**
	 * Ширина контента
	 */
	private contentWidth = 0;

	/**
	 * Текущее смещение wrapper'а
	 */
	private currentOffset = 0;

	/**
	 * Объект анимации
	 */
	private animation?: AnimeInstance;

	private removeContentResizeEventListener?: () => void;

	/**
	 * Обработчик изменения размера контента
	 */
	private handleContentResize = rafThrottle(() => {
		this.saveContentSize();
	});

	/**
	 * Размер видимой части по основной стороне
	 */
	get width() {
		return this.frameWidth;
	}

	/**
	 * Текущее смещение
	 */
	get offset() {
		return this.currentOffset;
	}

	/**
	 * Анимированно сдвигает в необходимую позицию
	 *
	 * @param offset смещение
	 * @param easing easing
	 * @param duration duration
	 */
	move = (offset: number, easing = 'cubicBezier(0.32, 0.72, 0.37, 0.95)', duration = 250) => {
		this.stopAnimation();
		if (this.wrapper) {
			this.animation = anime({
				targets: this.wrapper,
				translateX: offset,
				easing,
				duration,
				update: () => {
					if (this.wrapper) {
						// Извлечение из стилей реального смещения
						this.currentOffset = +this.wrapper.style.transform.slice(11, -3);
					}
				}
			});
		}
	};

	render() {
		const {className, style, children, onClick} = this.props;

		return (
			<TouchRecognizer
				className={classNames('touchable-panel', className)}
				style={style}
				scrollRatio={1.2}
				minDistanceMultiplier={0.2}
				// useMouseEvents={useMouseEvents}
				useMouseEvents
				onAfterRecognition={this.handleAfterRecognition}
				onMove={this.handleMove}
				onMoveEnd={this.handleMoveEnd}
				onResize={this.handleFrameResize}
				onClick={onClick}
				// onKeyDown={this.handleKeyDown}
				elementRef={this.saveFrameRef}
			>
				{children && (
					<div
						className="touchable-panel__wrapper"
						ref={this.saveWrapperRef}
					>
						<div
							className="touchable-panel__content"
							ref={this.saveContentRef}
						>
							{children}
						</div>
					</div>
				)}
			</TouchRecognizer>
		);
	}

	/**
	 * Обрабатывает событие завершения распознавания направления движения
	 */
	private handleAfterRecognition = (moving: boolean) => {
		if (moving) {
			this.stopAnimation();
		}
	};

	/**
	 * Обрабатывает событие движения пальца/мыши
	 *
	 * @param position позиция
	 * @param delta смещение
	 */
	private handleMove = (position: number, delta: number) => {
		this.currentOffset += delta;
		// this._position = position;
		let offset = this.currentOffset;

		if (this.currentOffset > 0) {
			offset = this.currentOffset ** 0.5 * 2;
		}
		if (this.currentOffset < this.frameWidth - this.contentWidth) {
			offset
				= this.frameWidth
				- this.contentWidth
				- (this.frameWidth - this.contentWidth - this.currentOffset) ** 0.5 * 2;
		}
		if (this.wrapper) {
			this.wrapper.style.transform = `translateX(${offset}px)`;
		}
	};

	/**
	 * Обрабатывает событие конца перемещения контента
	 *
	 * @param position
	 * @param delta
	 * @param velocity
	 */
	private handleMoveEnd = (position: number, delta: number, velocity: number) => {
		let nextOffset = this.currentOffset + endMovingImpulse * velocity;
		const prevOffset = this.currentOffset;
		const minOffset = this.frameWidth - this.contentWidth;

		if (nextOffset > 0) {
			nextOffset = 0;
		}
		if (nextOffset < minOffset) {
			nextOffset = minOffset;
		}
		if (nextOffset !== prevOffset && this.wrapper) {
			// За пределами границ используется иной dumping для визуального выхода за границу
			const dumping = nextOffset === 0 || nextOffset === minOffset ? animOutsideDumping : animNormalDumping;
			this.move(nextOffset, `spring(${animMass}, ${animStiffness}, ${dumping}, ${Math.abs(velocity)})`);
		}
	};

	/**
	 * Останавливает анимацию
	 */
	private stopAnimation = () => {
		if (this.wrapper && this.animation) {
			this.animation.pause();
			anime.remove(this.wrapper);
			this.animation = undefined;
		}
	};

	/**
	 * Обработчик изменения размеров фрейма
	 */
	private handleFrameResize = () => {
		this.saveFrameSize();
		this.saveContentSize();
		if (this.props.onResize) {
			this.props.onResize();
		}
	};

	/**
	 * Сохраняет размер фрейма по основной стороне
	 */
	private saveFrameSize = () => {
		this.frameWidth = this.frame ? this.frame.offsetWidth : 0;
	};

	/**
	 * Сохраняет размер контента по основной стороне
	 */
	private saveContentSize = () => {
		this.contentWidth = this.content
			? Math.max(this.frameWidth, this.content.scrollWidth)
			: 0;
	};

	/**
	 * Сохраняет ссылку на элемент фрейма
	 *
	 * @param element html-элемент
	 */
	private saveFrameRef = (element: HTMLDivElement | null) => {
		this.frame = element;
		this.saveFrameSize();
		if (this.props.frameRef) {
			this.props.frameRef(element);
		}
	};

	/**
	 * Сохраняет ссылку на элемент wrapper'а
	 *
	 * @param element html-элемент
	 */
	private saveWrapperRef = (element: HTMLDivElement | null) => {
		this.wrapper = element;
	};

	/**
	 * Сохраняет ссылку на элемент контента
	 *
	 * @param element html-элемент
	 */
	private saveContentRef = (element: HTMLDivElement | null) => {
		this.content = element;
		if (this.removeContentResizeEventListener) {
			this.removeContentResizeEventListener();
		}
		if (this.content) {
			this.addContentResizeEventListener(this.content);
		}
		this.saveContentSize();
	};

	private addContentResizeEventListener = (element: HTMLElement) => {
		const observer = new ResizeObserver(this.handleContentResize);
		observer.observe(element);
		this.removeContentResizeEventListener = () => observer.disconnect();
	};
}

export default TouchablePanel;
