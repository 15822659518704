import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import React, {useCallback} from 'react';
import {StatsBorderCircle} from '../../components/StatsBorderCircle';
import {CriticalMarker} from './CriticalMarker';

interface ISpaceCircleProps {
	space: IEnrichedSpace;
	width: number;
	height: number;

	onClick?: (space: IEnrichedSpace) => void;
}

const DEFAULT_COLOR = '#FFF';

export const SpaceCircle = ({space, width, height, onClick}: ISpaceCircleProps) => {
	const {name, status, problems} = space;
	const isCritical = problems?.some(problem => problem.critical);
	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [onClick, space]);

	return (
		<svg
			width={width}
			height={height}
			className="problems-space__status"
			onClick={handleClick}
		>
			{/* тень */}
			<circle
				cx={width / 2}
				cy={height / 2}
				r={(width - 20) / 2}
				className="problems-space__status-shadow"
			/>

			{/* заливка круга */}
			<circle
				cx={width / 2}
				cy={height / 2}
				r={(width - 20) / 2}
				fill={status?.color || DEFAULT_COLOR}
			/>

			{/* бордер */}
			{problems?.map(item => (
				<StatsBorderCircle
					key={item.key}
					width={width}
					height={height}
					radius={(width - 20) / 2}
					item={item}
				/>
			))}
			{isCritical && (
				<CriticalMarker
					x={width - 20 - 7}
					y={height - 20 - 7}
				/>
			)}
			<text
				className="problems-space__number"
				x={width / 2}
				y={height / 2 + 1}
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{name}
			</text>
		</svg>
	);
};
