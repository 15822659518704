import {useLocalChecks} from '@src/core/hooks/queries/checks/useGetChecks';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLatestUserChecks} from '@src/store/modules/dictionaries/latestUserChecks/actions/get';
import {getUserCheck} from '@src/store/modules/dictionaries/latestUserChecks/actions/getOne';
import {
	extractAllLatestChecksAsArray,
	extractLatestCheckId
} from '@src/store/modules/dictionaries/latestUserChecks/selectors';
import {extractChecksAsArray} from '@src/store/modules/entities/checks/selectors';
import {onlineManager} from '@tanstack/react-query';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {useAsync} from 'react-use';

export const useLatestChecks = (
	objectId: string,
	setSelectedCheckId: Dispatch<SetStateAction<string | undefined>>,
	selectedCheckId?: string,
	links?: ICheck['links']
) => {
	const dispatch = useAppDispatch();
	const latestCheckId = useAppSelector(s => extractLatestCheckId(s, objectId, links?.spaceId));
	const allChecks = useAppSelector(s => extractChecksAsArray(s, objectId));
	const allLatestChecks = useAppSelector(extractAllLatestChecksAsArray);
	const localChecks = useLocalChecks();
	const isCheck = !!allChecks.find(item => item.id === selectedCheckId);
	const isLatestCheck = !!allLatestChecks.find(item => item.id === selectedCheckId);
	const isLocalCheck = !!localChecks.find(item => item.id === selectedCheckId);

	useEffect(() => {
		setSelectedCheckId(latestCheckId);
	}, [latestCheckId]);
	useAsync(async () => {
		if (onlineManager.isOnline()) {
			await dispatch(getLatestUserChecks());
		}
	}, []);

	// Запрос нужен при создании нарушения/осмотра из уже созданной другим
	// пользователем проверки. В этом случае проверка добавляется в
	// "последние" проверки пользователя и автоматически выбирается, как основная
	useAsync(async () => {
		if (
			onlineManager.isOnline() &&
			selectedCheckId &&
			isCheck &&
			!isLatestCheck &&
			!isLocalCheck
		) {
			const check = await dispatch(getUserCheck(objectId, selectedCheckId));
			setSelectedCheckId(check.id);
		}
	}, [selectedCheckId, allLatestChecks, objectId, localChecks, allChecks]);
};
