import React from 'react';
import './EditorInfo.less';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import classNames from 'classnames';
import {useIsLargeTablet} from '../../../utils/mediaQueries';
import {EntityGridItem, IEntityGridItemProps} from '../../containers';
import {UserInfo} from '../../various';
import {dateTimeFormat, dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {Tag} from '../../tags';

const offlineIcon = <i className="tz-offline-mode-20 editor-info__offline_icon"/>;

interface IEditorInfoProps {
	className?: IEntityGridItemProps['className'];
	style?: React.CSSProperties;
	icon?: React.ReactNode;
	label: string;
	localLabel?: string;
	date?: number;
	localDate?: number;
	user?: IBriefUser;
	fullRow?: boolean;
}

const EditorInfo = ({className, style, icon, label, date, user, fullRow, localDate, localLabel}: IEditorInfoProps) => {
	const isDesktop = useIsLargeTablet();

	const formattedDate
		= date !== undefined ? format(date, dateTimeFormat, {locale: ru}) : undefined;

	const formattedLocalDate
		= localDate !== undefined ? format(localDate, dateTimeLetterSeparatedFormat, {locale: ru}) : undefined;

	return (
		<EntityGridItem
			className={classNames('editor-info', className)}
			style={style}
			icon={icon}
			label={label}
			fullRow={fullRow}
		>

			{formattedLocalDate && (
				<Tag
					className="editor-info__offline_tag"
					label={`${localLabel} ${formattedLocalDate}`}
					icon={offlineIcon}
				/>
				)}
			{formattedDate && <div className="semi-bold">{formattedDate}</div>}

			{user && (
				<UserInfo
					className="editor-info__user"
					user={user}
					avatarSize={isDesktop ? '40' : '34'}
					avatarColoring="text"
				/>
			)}
		</EntityGridItem>
	);
};

export default EditorInfo;
