import React from 'react';
import './DatePickerResult.less';
import classNames from 'classnames';
import {format} from 'date-fns';

interface IDatePickerResultProps {
	className?: string;
	style?: React.CSSProperties;
	value1?: Date;
	value2?: Date;
	placeholder1?: string;
	placeholder2?: string;
	isRange?: boolean;
	dateFormat: string;
	dateOptions?: Parameters<typeof format>[2];
}

const DatePickerResult = (props: IDatePickerResultProps) => {
	const {className, style, value1, value2, placeholder1, placeholder2, isRange, dateFormat, dateOptions} = props;

	return (
		<div
			className={classNames('date-picker-result', className)}
			style={style}
		>
			{value1 ? (
				<div className="date-picker-result__value">{format(value1, dateFormat, dateOptions)}</div>
			) : (
				<div className="date-picker-result__placeholder">{placeholder1}</div>
			)}

			{isRange && <i className="tz-simple-arrow-24 date-picker-result__separator"/>}

			{isRange
				&& (value2 ? (
					<div className="date-picker-result__value">{format(value2, dateFormat, dateOptions)}</div>
				) : (
					<div className="date-picker-result__placeholder">{placeholder2}</div>
				))}
		</div>
	);
};

export default DatePickerResult;
