import React, {forwardRef, Ref} from 'react';
import './ExternalLinkButton.less';
import classNames from 'classnames';
import LinkButton, {ILinkButtonProps} from '../LinkButton';

const defaultIcon = <i className="tz-external-link-16"/>;

type IExternalLinkButtonProps = Omit<
	ILinkButtonProps,
	'leftIcon' | 'leftIconClassName' | 'rightIcon' | 'rightIconClassName'
> & {
	icon?: React.ReactElement | false;
};

const ExternalLinkButton = (props: IExternalLinkButtonProps, ref?: Ref<HTMLAnchorElement>) => {
	const {className, wrapClassName, labelClassName, icon = defaultIcon, ...rest} = props;

	return (
		<LinkButton
			{...rest}
			className={classNames('external-link-button', className)}
			wrapClassName={classNames('external-link-button__wrap', wrapClassName)}
			labelClassName={classNames('external-link-button__label', labelClassName)}
			leftIconClassName="external-link-button__left-icon"
			leftIcon={icon}
			ref={ref}
		/>
	);
};

ExternalLinkButton.displayName = 'ExternalLinkButton';

export default forwardRef(ExternalLinkButton);
