import React, {memo} from 'react';
import {MobileFilter, TextField} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '../../EntitiesFilters/utils/makeFilterLabel';
import {useFilterPageTransition} from '../utils/pagesRouting';

interface IModifiedDateFilterMobileProps {
	objectId?: string;
	from?: Date;
	to?: Date;
	entity: string;
}

export const ModifiedDateFilterMobile = memo(
	({objectId, from, to, entity}: IModifiedDateFilterMobileProps) => {
		const handleExpand = useFilterPageTransition(objectId, entity, 'modifieddate');

		return (
			<MobileFilter label="Дата изменения">
				<TextField
					className="mobile-entities-filters__textfield"
					elementType="div"
					value={from && to ? makeDateFilterLabel('', from, to) : undefined}
					icon={<i className="tz-calendar-20"/>}
					onClick={handleExpand}
				/>
			</MobileFilter>
		);
	}
);
