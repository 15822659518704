import {useQueryClient} from '@tanstack/react-query';
import {legalsQueryKeys} from '../keys';
import {requestLegalsEntity} from '@src/api/backend/legals-entity/get';
import {addErrorToast} from '@src/utils/toasts';
import {requestLegalEntity} from '@src/api/backend/legal-entity';

export const useLegalsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(legalsQueryKeys.list(), {
		queryFn: async () => requestLegalsEntity(),
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке юридических лиц');
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(legalsQueryKeys.details(), {
		queryFn: async ({queryKey}) => requestLegalEntity(queryKey[2] as string),
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 5 // 5 Дней
	});
};
