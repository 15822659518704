import React, {useCallback, useMemo} from 'react';
import {useEntitiesViewer} from './useEntitiesViewer';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IAttachmentsImageData from '@tehzor/tools/interfaces/IAttachmentsImageData';
import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesMobilePhotoViewer/EntitiesMobilePhotoViewer';

/**
 * Хук создания просмотрщика изображений осмотра
 *
 * @param images изображения осмотра
 * @param inspectionId id инспекции
 * @param attachmentsData данные изображенйи осмотра
 */
export function useInspectionViewer(
	images: string[],
	inspectionId: string,
	attachmentsData: IAttachmentsImageData[]
): [React.ReactNode, (i: number) => void, (id: string, i: number) => void] {
	const data = useMemo(() => {
		const result: IEntity[] = [];
		if (images.length) {
			result.push({
				id: inspectionId,
				title: 'Фото осмотра',
				files: images,
				type: AttachmentFileDestination.INSPECTION,
				attachments: attachmentsData
			});
		}
		return result;
	}, [images, inspectionId]);

	const [viewer, openImage] = useEntitiesViewer(data, false);

	const openInspectionImage = useCallback(
		(index: number) => {
			openImage(inspectionId, index);
		},
		[openImage, inspectionId]
	);
	return [viewer, openInspectionImage, openImage];
}
