import React from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useCacheStates} from '../hooks/useCacheStates';

export const LastUserChecksCacheStatus = () => {
	const [isFetching, isError, isStale, isPaused, dataUpdatedAt] = useCacheStates(
		checksQueryKeys.latest()
	);
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Последние проверки"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={isError ? 'Ошибка' : isStale ? 'Данные устарели' : undefined}
			inProgress={isPaused || isFetching}
		/>
	);
};
