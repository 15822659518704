import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {
	IUnbindSpaceFromOwnerResponse,
	makeUnbindSpaceFromOwnerRequest
} from '@src/api/backend/spaceOwner';

export interface IUnbindSpaceFromOwnerPayload {
	owner: IUnbindSpaceFromOwnerResponse;
	ownerId: string;
}

const request = () => ({type: types.UNBIND_SPACE_REQUEST});

const success = (response: IUnbindSpaceFromOwnerResponse, ownerId: string) => ({
	type: types.UNBIND_SPACE_SUCCESS,
	payload: {owner: response, ownerId}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении из помещения');
	return {
		type: types.UNBIND_SPACE_FAILURE,
		payload: error
	};
};

/**
 * Отвязывает собственника от помещения
 *
 * @param ownerId id собственника
 * @param spaceId id помещения
 * @param removeIfLast удалять собственника, если у него не останется помещений
 */
export const unbindSpaceFromOwner = (ownerId: string, spaceId: string, removeIfLast?: boolean) =>
	createApiAction<IUnbindSpaceFromOwnerResponse>(
		request,
		res => success(res, ownerId),
		failure,
		() => makeUnbindSpaceFromOwnerRequest(ownerId, spaceId, removeIfLast)
	);
