import React, {memo, useMemo} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';

export type ViewTabsVariants = 'bankAccounts' | 'authorizedPersons';

const tabsIndexes: Record<ViewTabsVariants, number> = {
	bankAccounts: 0,
	authorizedPersons: 1
};

const tabsKeys: ViewTabsVariants[] = ['bankAccounts', 'authorizedPersons'];

interface IViewTabsProps {
	activeTab: ViewTabsVariants;
	setActiveTab: (variant: ViewTabsVariants) => void;
	bankAccountsCount: number;
	authorizedPersonsCount: number;
}

export const ViewTabs = memo((props: IViewTabsProps) => {
	const {activeTab, setActiveTab, bankAccountsCount, authorizedPersonsCount} = props;
	const handleChange = (index: number) => setActiveTab(tabsKeys[index]);

	const tabs = useMemo(() => {
		return [
			<TabLink
				label={
					<span className="legal-page__view-tab">
						Реквизиты{' '}
						<span className="legal-page__view-tab-title">{bankAccountsCount}</span>
					</span>
				}
			/>,
			<TabLink
				label={
					<span className="legal-page__view-tab">
						Уполномоченные лица{' '}
						<span className="legal-page__view-tab-title">{authorizedPersonsCount}</span>
					</span>
				}
			/>
		];
	}, [bankAccountsCount, authorizedPersonsCount]);

	return (
		<Tabs
			className="legal-page__view-tabs"
			links={tabs}
			activeTab={tabsIndexes[activeTab]}
			onActiveTabChange={handleChange}
		/>
	);
});
