import React from 'react';
import './FilterPage.less';
import classNames from 'classnames';
import {Button} from '../../buttons';
import {ErrorBoundary} from '../../containers';

export interface IFilterPageProps {
	className?: string;
	children?: React.ReactNode;
	applyDisabled?: boolean;
	search?: React.ReactNode;
	tabs?: React.ReactNode;

	onApplyClick?: () => void;
}

const FilterPage = ({
	className, children, applyDisabled, onApplyClick, search, tabs
}: IFilterPageProps) => (
	<ErrorBoundary
		label="FilterPage"
		className="filter-page error"
	>
		<div className={classNames('filter-page', className)}>
			<div className="filter-page__container">
				{tabs && (<div className="filter-page__container-tabs">{tabs}</div>)}
				{search && (<div className="filter-page__container-search">{search}</div>)}
			</div>
			<div className="filter-page__content">{children}</div>
			<div className="filter-page__buttons">
				<Button
					type="accent-blue"
					disabled={applyDisabled}
					style={{width: '100%'}}
					label="Применить"
					onClick={onApplyClick}
				/>
			</div>
		</div>
	</ErrorBoundary>
);

FilterPage.displayName = 'FilterPage';

export default FilterPage;
