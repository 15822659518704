import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import React, {useCallback, useMemo} from 'react';
import {changeStage, clearFilters} from '@src/store/modules/settings/pages/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {FilterMenu, Menu, MenuItem} from '@tehzor/ui-components';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import './StageVisibilityFilter.less';
import classNames from 'classnames';
import {useStagesTitlesMap} from '../../../hooks/useStagesTitlesMap';

interface StageVisibilityFilterProps {
	className?: string;
	objectId: string;
}

export const StageVisibilityFilter = ({className, objectId}: StageVisibilityFilterProps) => {
	const dispatch = useAppDispatch();
	const titlesMap = useStagesTitlesMap();
	const {stage} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const object = useAppSelector(s => extractObject(s, objectId));

	const label = useMemo(
		() =>
			(stage && titlesMap[stage] ? `Показать: ${titlesMap[stage]}` : 'Показать: все стадии'),
		[stage, titlesMap]
	);

	const handleChange = useCallback(
		(v: ObjectStageIds) => {
			dispatch(changeStage(objectId, v));
			dispatch(clearFilters(objectId));
		},
		[objectId]
	);

	return (
		<FilterMenu
			className={{
				root: classNames('stage-visibility-filter', className),
				label: 'stage-visibility-filter__label'
			}}
			label={label}
			active
		>
			<Menu
				value={stage || 'all'}
				onChange={handleChange}
			>
				{Object.entries<string>(titlesMap).map(([key, title]) => (
					<MenuItem
						key={key}
						itemKey={key}
					>
						{title}
						{object.stage === key && (
							<div className="stage-visibility-filter__current">
								текущая стадия объекта
							</div>
						)}
					</MenuItem>
				))}
			</Menu>
		</FilterMenu>
	);
};
