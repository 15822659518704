import {createSelector} from 'reselect';
import {getInitialStateForPage} from './reducers';
import {IState} from '@src/store/modules';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';

/**
 * Возвращает нарушения для конкретного объекта
 */
export const extractProblemsData = createSelector(
	[
		(state: IState) => state.entities.problems,
		(state: IState, objectId: string | undefined) => objectId || 'all'
	],
	(data, objectId) => data[objectId] || getInitialStateForPage()
);

export const extractOneProblem = createSelector(
	[
		extractProblemsData,
		(state: IState, objectId: string | undefined, problemId: string | undefined) => problemId
	],
	(data, problemId) => (problemId ? data.byId[problemId] : undefined)
);
/**
 * Возвращает нарушения в виде массива
 */
export const extractProblemsAsArray = createSelector([extractProblemsData], data =>
	data.allIds.map(id => data.byId[id]));

export const extractProblemsAsSpaceIdMap = createSelector([extractProblemsData], data =>
	data.allIds.reduce<{[id: string]: IListProblem[]}>((acc, problemId) => {
		const problem = data.byId[problemId];

		if (!problem || !problem.links?.spaceId) {
			return acc;
		}

		if (!Array.isArray(acc[problem.links.spaceId])) {
			acc[problem.links.spaceId] = [];
		}

		acc[problem.links.spaceId].push(problem);

		return acc;
	}, {}));
/**
 * Возвращает состояние загрузки
 */
export const extractProblemsLoadingState = createSelector(
	[extractProblemsData],
	data => data.isLoading || undefined
);

/**
 * Возвращает локально сохраненные нарушения
 */
export const extractLocalProblems = createSelector(
	[
		(state: IState) => state.localEntities.problems,
		(state: IState, objectId: string | undefined) => objectId
	],
	(problems, objectId) =>
		(objectId ? problems.filter(problem => problem.objectId === objectId) : problems)
);
