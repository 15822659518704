import React from 'react';
import {CellProps} from 'react-table';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';

const LocationCell = ({row}: CellProps<IInspectionEntity>) => {
	const {plan, floor, location} = row.original.data;
	return (
		<>
			{!!plan && (
				<div>
					<span className="inspections-page__d-table-location-title">План: </span>
					{plan}
				</div>
			)}
			{!!floor && (
				<div>
					<span className="inspections-page__d-table-location-title">Этаж: </span>
					{floor}
				</div>
			)}
			{!!location && (
				<div>
					<span className="inspections-page__d-table-location-title">Место: </span>
					{location}
				</div>
			)}
		</>
	);
};

export default LocationCell;
