import React, {useCallback, useEffect} from 'react';
import {TabButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeType} from '@src/store/modules/settings/pages/spaces/actions';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';

interface ISpaceTypeButtonsProps {
	objectId: string;
	types: Array<[ISpaceType, {spaces: number, filtred: number}]>;
}

export const SpaceTypeButtons = ({objectId, types}: ISpaceTypeButtonsProps) => {
	const selectedType = useAppSelector(s => extractSpacesPageSettings(s, objectId).type);
	const dispatch = useAppDispatch();

	// При переходе на страницу в первый раз (то есть нет сохраненного выбранного типа),
	// необходимо установить тип по умолчанию
	useEffect(() => {
		if (selectedType === undefined && types.length) {
			dispatch(changeType(objectId, types[0][0].id));
		}
	}, [types, objectId]);

	const handleTypeSelection = useCallback(
		(type: string) => {
			dispatch(changeType(objectId, type));
		},
		[objectId]
	);

	return types.length ? (
		<div className="spaces-page__space-type-list">
			{types.map(([type, count]) => (
				<TabButton
					key={type.id}
					value={type.id}
					active={selectedType === type.id}
					className="spaces-page__space-type-list-item"
					label={`${type.name} ${
						count.filtred !== count.spaces
							? `${count.filtred}/${count.spaces}`
							: count.filtred
					}`}
					onClick={handleTypeSelection}
				/>
			))}
		</div>
	) : null;
};
