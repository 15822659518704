import {wsConnector} from '../wsConnector';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export type IDeleteSpaceTypeDecorationSetResponse = ISpaceTypeDecorationSet;

/**
 * Удаляет набор типов отделки помещения
 *
 * @param id id набора типов отделки помещения
 */
export const deleteSpaceTypeDecorationSetRequest = (id: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteSpaceTypeDecorationSetResponse>(
		'deleteSpaceTypeDecorationSet',
		{id}
	);
