import {exportOwnerAcceptance} from './export';
import {sendExportedOwnerAcceptance} from './send';
import {ApiAction} from '@src/store/middlewares/api';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {ISendResponse} from '@src/interfaces/ISendResponse';

/**
 * Скачивает файл или отправляет на email передачу собственнику
 *
 * @param email email
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param acceptanceId id передачи
 * @param problems нарушения
 * @param createDocument флаг создания документа
 */
export const exportOwnerAcptUnified = (
	email: string | undefined,
	templateId: string,
	objectId: string,
	acceptanceId: string,
	problems?: string[],
	createDocument?: boolean
): ApiAction<IExportResponse | ISendResponse> =>
	(email !== undefined
		? sendExportedOwnerAcceptance(email, templateId, objectId, acceptanceId, problems, createDocument)
		: exportOwnerAcceptance(templateId, objectId, acceptanceId, problems, createDocument));
