import {Column} from 'react-table';
import CellWrap from '@src/components/tableCells/CellWrap';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import {extractWarrantyClaimProblemCommentsByProblem} from '@src/store/modules/entities/warrantyClaim/selectors';

export const mobileColumns: Array<Column<IWarrantyClaimEntity>> = [
	{
		id: 'info',
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(
				ProblemInfoMobileCell,
				extractWarrantyClaimProblemCommentsByProblem
			)
		})
	}
];
