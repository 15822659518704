import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IEntityState} from '@tehzor/tools/utils/reducersHandlers';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';

export const createCategoryDifference = (
	prev: string | undefined,
	next: string | undefined,
	categories: IEntityState<ICategory>
): IHistoryData => ({
	prev: prev ? categories.byId[prev]?.name : undefined,
	next: next ? categories.byId[next]?.name : undefined,
	type: HistoryTypeId.CATEGORY,
	name: 'категорию'
});
