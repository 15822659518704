import React, {useCallback} from 'react';
import {InspectionsPage} from './InspectionsPage';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/reducers';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/inspections/actions';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import {changeOffset, clearExpiration} from '@src/store/modules/entities/inspections/actions';
import ActionsMobile from './components/actions/Actions.mobile';

const AllInspectionsPageWrap = (props: Record<string, unknown>) => {
	const dispatch = useAppDispatch();

	useAppHeader({
		title: 'Осмотры',
		mobileRightButtons: <ActionsMobile/>
	});
	const {filters} = useAppSelector(s => extractInspectionsPageSettings(s, 'all'));

	const applyFilters = useCallback(
		(value: IInspectionsFiltersState) => {
			dispatch(changeFilters('all', value));
			dispatch(changeOffset('all', 0));
		},
		[dispatch]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters('all'));
	}, [dispatch]);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
			clearExpiration={clearExpiration}
		>
			<InspectionsPage
				key="all-inspections"
				className="page-cont_extra-bottom-margin"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default AllInspectionsPageWrap;
