import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {useCallback, useMemo} from 'react';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import {useAsyncFn} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {desktopColumns} from './columns.desktop';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {IPreparedExportTemplate} from '../../interfaces/IPreparedExportTemplate';
import {convertExportTemplates} from '../../utils/convertExportTemplates';
import {getExportTemplatesAsArrayWithoutPdf} from '@src/store/modules/dictionaries/exportTemplates/selectors';
import {getExportTemplates} from '@src/store/modules/dictionaries/exportTemplates/actions/get';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface ITableProps {
	loading?: boolean;
}

export const Table = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const data = useAppSelector(getExportTemplatesAsArrayWithoutPdf);
	const dispatch = useAppDispatch();
	const [, loadExportTemplates] = useAsyncFn(() => dispatch(getExportTemplates()));
	const preparedData = useMemo(() => convertExportTemplates(data), [data]);

	const handleRowClick = useCallback((item: IPreparedExportTemplate) => {
		pushPath(`/manage/export-templates/${item.id}`);
	}, []);

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={loadExportTemplates}>
				<LoadingPanel active={loading}>
					<EntitiesTable
						columns={desktopColumns}
						data={preparedData}
						onRowClick={handleRowClick}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
