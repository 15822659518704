import React from 'react';
import {Tag} from '../Tag';
import './Tags.less';
import {TagsContainer} from '../TagsContainer';
import {convertClassNames} from '../../../utils/convertClassNames';
import classNames from 'classnames';

export interface ITagDataItem {
	id: string;
	label: string;
	icon?: React.ReactNode;
}

export interface ITagDataItemWithColor extends ITagDataItem {
	color: string;
}

interface ITagsProps<T extends ITagDataItem> {
	className?: string | {
		root?: string;
		item?: string;
	};
	style?: React.CSSProperties;
	data: T[];
	inline?: boolean;
	color?: string;

	onDelete?: (data: string) => void;
}

export const Tags = <T extends ITagDataItem>(props: ITagsProps<T>) => {
	const {className, style, data, inline, color, onDelete} = props;

	const classes = convertClassNames(className);

	const tags = data.map(item => (
		<Tag
			key={item.id}
			className={classNames('tags__item', classes.item)}
			label={item.label}
			icon={item.icon}
			color={color}
			data={item.id}
			onDelete={onDelete}
		/>
	));

	return inline ? (
		<>{tags}</>
	) : (
		<TagsContainer
			className={classNames('tags', classes.root)}
			style={style}
		>
			{tags}
		</TagsContainer>
	);
};