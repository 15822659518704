import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {useMemo} from 'react';
import {useWorkAcceptancesBreadcrumbs} from './useWorkAcceptancesBreadcrumbs';

/**
 * Хук для хлебных крошек от "всех объектов" до приёмки работ
 *
 * @param objectId id конечного объекта
 * @param workAcceptanceId id приёмки работ
 * @param number номер приёмки работ
 */
export function useWorkAcceptanceBreadcrumbs(
	objectId: string,
	workAcceptanceId: string,
	number: number | undefined
) {
	const res = useWorkAcceptancesBreadcrumbs(objectId);
	return useMemo(
		() =>
			res.concat({
				label: number ? `Приёмка работ №${number}` : 'Приёмка работ',
				url: formWorkAcceptanceLink(objectId, workAcceptanceId),
				inactive: true
			}),
		[objectId, workAcceptanceId, res]
	);
}
