import React, {useCallback, useState} from 'react';
import {SelectSearch} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useUpdateEffect} from 'react-use';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/reducers';

export const InternalAcceptancesSearch = () => {
	const {state, dispatch} = useEntitiesFiltersCtx<IInternalAcceptancesFiltersState>();
	const [searchValue, setSearchValue] = useState<string | undefined>(state.searchString);
	const handleChange = useCallback((v: string | undefined) => {
		setSearchValue(v);
	}, []);

	const handleApplySearch = useCallback(() => {
		dispatch({searchString: searchValue});
	}, [dispatch, searchValue]);

	const handleClear = useCallback(() => {
		dispatch({searchString: undefined});
	}, [dispatch]);

	useUpdateEffect(() => {
		setSearchValue(state.searchString);
	}, [state.searchString]);

	return (
		<SelectSearch
			placeholder="Поиск по описанию"
			value={searchValue}
			type="filter"
			onClear={handleClear}
			onChange={handleChange}
			onSearch={searchValue ? handleApplySearch : undefined}
		/>
	);
};