import React from 'react';
import SpacePage from './SpacePage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

/**
 * Обёртка над страницей помещения,
 * необходимо для того чтобы при переходе от одного помещения к другому отрабатывали все методы жизненного цикла
 *
 * @param props свойства компонента
 * @constructor
 */
const SpacePageWrap = () => {
	const {spaceId} = useStrictParams<{spaceId: string}>();
	return <SpacePage key={spaceId}/>;
};

export default SpacePageWrap;
