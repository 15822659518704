import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {getScopeIds, hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

const getAllIds = (state: IState) => state.dictionaries.companies.allIds || [];
const getById = (state: IState) => state.dictionaries.companies.byId || {};
const getObjectId = (state: IState, objectId: string | undefined) => objectId ?? 'all';
const getCompanyId = (state: IState, companyId: string | undefined) => companyId ?? 'all';

/**
 * Возвращает компании в виде массива
 */
export const extractCompaniesAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map((id: string) => byId[id])
);

/**
 * Возвращает данные по компаниям подрядчикам
 */
export const extractContractorsData = createSelector(
	[getAllIds, getById],
	(companyIds, companiesMap) => {
		const allIds: Set<string> = new Set();
		const byId: Record<string, ICompany> = {};

		for (const companyId of companyIds) {
			const company = companiesMap[companyId];
			if (company.contractors) {
				company.contractors.forEach(item => allIds.add(item.subCompanyId));
			}
		}

		for (const contractorId of allIds) {
			const contractor = companiesMap[contractorId];
			if (contractor) {
				byId[contractorId] = contractor;
			}
		}

		return {allIds: [...allIds], byId};
	}
);

/**
 * Возвращает компании подрядчики в виде массива
 */
export const extractContractorsAsArray = createSelector(
	[extractContractorsData],
	({allIds, byId}) => allIds.map(id => byId[id])
);

/**
 * Возвращает главные компании и компании подрядчики
 */
export const extractMainAndSubCompaniesAsArray = createSelector(
	[extractCompaniesAsArray],
	companies => {
		const contractorIds = new Set(
			companies.flatMap(item => item.contractors?.map(el => el.subCompanyId))
		);
		return {
			mainCompanies: companies.filter(item => !contractorIds.has(item.id)),
			subCompanies: companies.filter(item => contractorIds.has(item.id))
		};
	}
);

/**
 * Возвращает компании в виде мапы: new Map(companyId: company)
 */
export const extractCompaniesAsMap = createSelector([getAllIds, getById], (allIds, byId) => {
	const companiesMapById = new Map() as Map<string, ICompany>;
	allIds.forEach(id => companiesMapById.set(id, byId[id]));
	return companiesMapById;
});

/**
 * Возвращает компанию по id
 */
export const extractCompany = createSelector(
	[getById, getCompanyId],
	(byId, companyId) => byId[companyId]
);

/**
 * Возвращает компании, которые могут использоваться при добавлении/редактировании объекта
 */
export const extractCompaniesForObject = createSelector(
	[extractCompaniesAsArray, extractUserRoles, getObjectId],
	(companies, userRoles, objectId) => {
		if (hasPermission(userRoles, objectId ? 'objectsEdit' : 'objectsAdd', UserRoleScopes.ALL)) {
			return companies;
		}
		const ids = getScopeIds(
			userRoles,
			UserRoleScopes.COMPANY,
			objectId ? 'objectsEdit' : 'objectsAdd'
		);
		return companies.filter((company: ICompany) => ids.includes(company.id));
	}
);

/**
 * Извлекает проверяющих для конкретного объекта или для всех компаний
 */
export const extractInspectors = createSelector(
	[
		(state: IState) => state.dictionaries.companies,
		(state: IState) => state.dictionaries.objects.byId,
		(state: IState) => state.dictionaries.users.byId,
		getObjectId
	],
	(companies, objects, users, objectId) => {
		const uniqueUsers = new Set<string>();

		if (objectId !== 'all') {
			const object = objects[objectId];
			const company = object && companies.byId[object.companyId];
			if (company?.employees) {
				company.employees.forEach(e => uniqueUsers.add(e.userId));
			}
		} else {
			companies.allIds.forEach(companyId => {
				const company = companies.byId[companyId];
				if (company?.employees) {
					company.employees.forEach(e => uniqueUsers.add(e.userId));
				}
			});
		}

		const result: IBriefUser[] = [];
		uniqueUsers.forEach(userId => {
			const user = users[userId];
			if (user) {
				result.push(user);
			}
		});
		return result.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
	}
);

/**
 * Возвращает пользователей компании включая подрядчиков
 */
export const extractCompanyWithContractorsUsersAsArray = createSelector(
	[getById, (s: IState) => s.dictionaries.users.byId, getCompanyId],
	(companiesMap, usersMap, companyId) => {
		if (!companyId || !companiesMap[companyId]) {
			return [];
		}
		const company = companiesMap[companyId];
		const contractors = company?.contractors;
		const contractorsIds = contractors?.map(item => item.subCompanyId);
		const contractorCompanies: ICompany[] = [];
		if (contractorsIds) {
			contractorsIds.forEach(id => {
				if (companiesMap[id]) {
					contractorCompanies.push(companiesMap[id]);
				}
			});
		}
		const employees = company?.employees;

		const usersSet = new Set<string>();

		contractorCompanies?.forEach(c => {
			c.employees?.forEach(e => {
				usersSet.add(e.userId);
			});
		});
		employees?.forEach(i => {
			usersSet.add(i.userId);
		});

		const result: IBriefUser[] = [];
		usersSet.forEach(id => {
			if (usersMap[id]) {
				result.push(usersMap[id]);
			}
		});
		return result.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
	}
);

/**
 * Возвращает пользователей компании (без подрядчиков)
 */
export const extractCompanyEmployeesAsArray = createSelector(
	[getById, (s: IState) => s.dictionaries.users.byId, getCompanyId],
	(companiesMap, usersMap, companyId) => {
		if (!companyId || !companiesMap[companyId]) {
			return [];
		}
		const company = companiesMap[companyId];
		const employees = company?.employees;

		const usersSet = new Set<string>();

		employees?.forEach(i => {
			usersSet.add(i.userId);
		});

		const result: IBriefUser[] = [];
		usersSet.forEach(id => {
			if (usersMap[id]) {
				result.push(usersMap[id]);
			}
		});
		return result.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
	}
);
