import {IAddProblemViewResponse} from '@src/api/backend/problemViews';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';

export interface IAddProblemViewRequestPayload {
	problemId: string;
}

export interface IAddProblemViewPayload extends IAddProblemViewResponse {
	problemId: string;
}

const request = (problemId: string) => ({
	type: types.ADD_REQUEST,
	payload: {problemId} as IAddProblemViewRequestPayload
});

const success = (problemId: string, response: IAddProblemViewResponse) => ({
	type: types.ADD_SUCCESS,
	payload: {
		...response,
		problemId
	} as IAddProblemViewPayload
});

const failure = (error: IError) => ({
	type: types.ADD_FAILURE,
	payload: {...error}
});

export const addProblemViewActions = {request, success, failure};
