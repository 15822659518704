import {useQuery} from '@tanstack/react-query';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {offlineJournalActionType} from '../enums/actionsTypes';

export const useLocalProblemsJournal = () => {
	const {data} = useQuery<IListProblem[], unknown, IOfflineJournalEntity[]>({
		queryKey: problemsQueryKeys.localList(),
		select: problems =>
			problems.map(problem => {
				let entityType = offlineJournalActionType.PROBLEM_ADD;
				if (problem.links?.spaceId) {
					entityType = offlineJournalActionType.PROBLEM_SPACE_ADD;
				}
				if (problem.links?.checkItemId || problem.links?.checkListId) {
					entityType = offlineJournalActionType.PROBLEM_CHECKLIST_ADD;
				}
				return {
					entity: {
						entityId: problem.id,
						entityLocalNumber: problem.localNumber,
						entityType,
						description: problem.description
					},
					location: {
						names: problem.location?.points?.map(point => point.name)
					},
					object: {
						objectId: problem.object?.id,
						objectName: problem.object?.name
					},
					transfer: {
						status: problem.transferStatus,
						statusMessage: problem.transferStatus
							? offlineModeTransferTitles[problem.transferStatus]
							: ''
					},
					createdAt: problem.localCreatedAt
				} as IOfflineJournalEntity;
			})
	});
	return data || [];
};
