import React, {useCallback} from 'react';
import {EntitiesTable} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<IListWorkAcceptance>>;
	workAcceptances?: IListWorkAcceptance[];
	selectedRows?: string[];
	headVisible?: boolean;
	onSelectedRowsChange?: (value: string[]) => void;
}

export const WorkAcceptancesTable = ({
	objectId,
	columns,
	workAcceptances,
	selectedRows,
	headVisible,
	onSelectedRowsChange
}: IDesktopTableProps) => {
	const {pushPath} = useChangePath();
	const handleRowClick = useCallback(
		(entity: IListWorkAcceptance) => {
			pushPath(formWorkAcceptanceLink(objectId, entity.id));
		},
		[objectId]
	);

	return (
		<EntitiesTable
			columns={columns}
			data={workAcceptances ?? []}
			selectedRows={selectedRows}
			headVisible={headVisible}
			onRowClick={handleRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
		/>
	);
};
