import React, {useCallback} from 'react';
import {EntityInnerTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formProblemLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import SelectionRow from './SelectionRow';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {extractOwnerAcceptanceEntities} from '@src/store/modules/entities/ownerAcceptance/selectors/acceptance';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';

interface ITableProps {
	objectId: string;
	ownerAcceptanceId: string;
	ownerAcceptance?: ILinkedOwnerAcceptance;
	columns: Array<Column<IOwnerAcceptanceEntity>>;
	hideHead?: boolean;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

const Table = ({
	objectId,
	ownerAcceptanceId,
	columns,
	hideHead,
	selectedRows,
	onSelectedRowsChange
}: ITableProps) => {
	const {pushPath} = useChangePath();
	const entities = useAppSelector(extractOwnerAcceptanceEntities);

	const handleRowClick = useCallback((entity: IOwnerAcceptanceEntity) => {
		switch (entity.type) {
			case 'problem':
				return pushPath(formProblemLink(objectId, entity.data.id));
			default:
				return undefined;
		}
	}, []);

	return (
		<EntityInnerTable
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable
			selectedRows={selectedRows}
			onRowClick={handleRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					objectId={objectId}
					ownerAcceptanceId={ownerAcceptanceId}
				/>
			)}
		/>
	);
};

export default Table;
