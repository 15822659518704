import React from 'react';
import './FixDate.less';
import {EntityGridItem} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {CriticalMarker} from '@src/components/CriticalMarker';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';

interface IFixDateProps {
	plannedFixDate: number;
	actualFixDate?: number;
	status: ProblemStatusId;
	critical?: boolean;
}

export const FixDate = ({plannedFixDate, status, critical, actualFixDate}: IFixDateProps) => {
	const formatted = format(plannedFixDate, longDateTimeFormat, {locale: ru});

	return (
		<EntityGridItem
			className="fix-date-view-field"
			icon={<i className="tz-calendar-20"/>}
			label="Срок устранения"
		>
			<div className="fix-date-view-field__value">
				{formatted}
				{critical && <CriticalMarker/>}
			</div>

			{(status !== ProblemStatusId.FIXED
				|| (actualFixDate && actualFixDate > plannedFixDate)) && (
					<FixDateTag
						className="fix-date-view-field__tag"
						plannedFixDate={plannedFixDate}
						actualFixDate={actualFixDate}
					/>
			)}
		</EntityGridItem>
	);
};
