import React from 'react';
import {CellProps} from 'react-table';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedInternalAcceptance} from '../../interfaces/IPreparedInternalAcceptance';
import {Stats} from './Stats';
import {MobileCellInfo} from './MobileCellInfo';

export const MobileInternalAcceptanceCellWithoutObject = ({
	row
}: CellProps<IPreparedInternalAcceptance>) => {
	const {number, localNumber, description, createdAt, modifiedAt, createdBy, modifiedBy, stats}
		= row.original;
	return (
		<div className="internal-acceptances-page__mobile-cell-info">
			<ClickPreventWrap className="internal-acceptances-page__mobile-cell-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()}/>
			</ClickPreventWrap>
			<div>
				<MobileCellInfo
					number={number}
					localNumber={localNumber}
					description={description}
					createdAt={createdAt}
					modifiedAt={modifiedAt}
					createdBy={createdBy}
					modifiedBy={modifiedBy}
				/>
				{stats?.problems?.length || stats?.inspections ? (
					<Stats
						stats={stats}
						size="small"
					/>
				) : null}
			</div>
		</div>
	);
};
