import useAppSelector from '@src/core/hooks/useAppSelector';
import {IconButton, PopupPlate} from '@tehzor/ui-components';
import React from 'react';
import {useToggle} from 'react-use';

export const LegendTasks = () => {
	const [isOpen, toggle] = useToggle(false);
	const tasksStatusesMap = useAppSelector(s => s.dictionaries.taskStatuses.byId);
	const tasksStatusesIds = useAppSelector(s => s.dictionaries.taskStatuses.allIds);

	const tasksTypesMap = useAppSelector(s => s.dictionaries.taskTypes.byId);
	const tasksTypesIds = useAppSelector(s => s.dictionaries.taskTypes.allIds);

	return (
		<PopupPlate
			className={{
				root: 'manage-task-schedule-page__legend-popup',
				content: 'manage-task-schedule-page__legend-popup-content'
			}}
			trigger={({ref}) => (
				<IconButton
					ref={ref}
					type="transparent"
					active={isOpen}
					onClick={toggle}
				>
					<i className="tz-info-24"/>
				</IconButton>
			)}
			arrowVisible
			isOpen={isOpen}
			placement="bottom-end"
			onOutsideClick={toggle}
			onDisappear={toggle}
		>
			<div className="manage-task-schedule-page__legend-content">
				<div>
					<div className="manage-task-schedule-page__legend-content-title">
						Статусы задач:
					</div>
					{tasksStatusesIds.map(id => (
						<div
							key={id}
							className="manage-task-schedule-page__legend-content-item"
						>
							<span
								className="manage-task-schedule-page__legend-content-item-status"
								style={{
									backgroundColor: tasksStatusesMap[id]?.scheduleTableColor
										? tasksStatusesMap[id]?.scheduleTableColor
										: '#C4CAD4'
								}}
							>
								{' '}
							</span>
							<span
								className="manage-task-schedule-page__legend-content-item-status-title"
							>
								{tasksStatusesMap[id]?.name}
							</span>
						</div>
					))}
				</div>

				<div>
					<div className="manage-task-schedule-page__legend-content-title">
						Типы задач:
					</div>
					<div className="manage-task-schedule-page__legend-content-items">
						{tasksTypesIds.map(id => (
							<div
								key={id}
								className="manage-task-schedule-page__legend-content-item"
							>
								<span
									className="manage-task-schedule-page__legend-content-item-type"
									style={{
									backgroundColor: tasksTypesMap[id]?.color
										? tasksTypesMap[id]?.color
										: '#C4CAD4'
									}}
								>
									{' '}
								</span>
								<span
									className="manage-task-schedule-page__legend-content-item-type-title"
								>
									{tasksTypesMap[id]?.name}
								</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</PopupPlate>
	);
};
