import {IState} from '@src/store/modules';
import {createSelector} from 'reselect';
import {extractProblemTagsSetsAsArrayForObject} from '../problemTagsSets/selectors';

const extractAllIds = (state: IState) => state.dictionaries.problemTags.allIds || [];
const extractById = (state: IState) => state.dictionaries.problemTags.byId || {};
const getSetId = (state: IState, setId: string | undefined) => setId;
const getObjectId = (state: IState, objectId: string) => objectId;

export const extractProblemTagsAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map(id => byId[id])
);

export const extractProblemTagsForSetAsArray = createSelector(
	[extractProblemTagsAsArray, getSetId],
	(tags, setId) => tags.filter(tag => tag.problemTagsSetId === setId)
);

export const extractProblemTagsAsArrayForObject = createSelector(
	[
		extractProblemTagsAsArray,
		getObjectId,
		(state: IState, objectId: string) => extractProblemTagsSetsAsArrayForObject(state, objectId)
	],
	(tags, objectId, sets) => tags.filter(tag => sets?.some(set => set.id === tag.problemTagsSetId))
);
