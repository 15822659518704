import {requestDeleteSpaceStatus} from '@src/api/backend/spaceStatuses/delete';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {queryClient} from '@src/api/QueryClient';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export interface IDeleteSpaceStatusPayload {
	id: string;
	spaceStatusesSetId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string, spaceStatusesSetId: string) => ({
	type: types.DELETE_SUCCESS,
	payload: {id, spaceStatusesSetId} as IDeleteSpaceStatusPayload
});

const failure = (error: IError) => ({
	type: types.DELETE_FAILURE,
	payload: error
});

export const deleteSpaceStatus = (id: string, spaceStatusesSetId: string) =>
	createApiAction(
		request,
		() => success(id, spaceStatusesSetId),
		failure,
		async () => {
			void queryClient.invalidateQueries(spaceStatusesQueryKeys.list());
			void queryClient.invalidateQueries(spacesQueryKeys.list());

			return requestDeleteSpaceStatus(id, spaceStatusesSetId);
		}
	);
