import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInspectionStory} from '@tehzor/tools/interfaces/inspectionStories/IInspectionStory';
import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import {IEntityState} from '@tehzor/tools/utils/reducersHandlers';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {extractInspectionStoriesAsArray} from '@src/store/modules/pages/inspection/selectors/stories';
import {extractPlans} from '@src/store/modules/dictionaries/plans/selectors';
import {IUsersState} from '@src/store/modules/dictionaries/users/reducers';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {
	createAttachmentsDifference,
	createLocationDifference,
	createDescriptionDifference
} from '@src/utils/createHistoryData';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';

function isAttachmentsEqual(arr1: IAttachment[], arr2: IAttachment[]) {
	let flag = true;
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i].id !== arr2[i].id) {
			flag = false;
		}
	}
	return flag;
}

function compare(
	next: IInspectionStory,
	prev: IInspectionStory,
	users: IUsersState,
	plans: IEntityState<IPlan>
): IHistoryDifference {
	const data: IHistoryData[] = [];
	if (next.data.description !== prev.data.description) {
		data.push(createDescriptionDifference(prev.data.description, next.data.description));
	}
	if (
		next.data.displayLocation !== prev.data.displayLocation
		|| next.data.floor !== prev.data.floor
		|| next.data.planId !== prev.data.planId
	) {
		data.push(
			createLocationDifference(
				prev.data.displayLocation,
				prev.data.floor,
				prev.data.planId,
				next.data.displayLocation,
				next.data.floor,
				next.data.planId,
				plans
			)
		);
	}
	if (
		next.data.attachments?.length !== prev.data.attachments?.length
		|| (next.data.attachments
			&& prev.data.attachments
			&& next.data.attachments.length === prev.data.attachments.length
			&& !isAttachmentsEqual(next.data.attachments, prev.data.attachments))
	) {
		data.push(createAttachmentsDifference(prev.data.attachments, next.data.attachments));
	}

	return {
		createdAt: next.createdAt,
		createdBy: next.createdBy ? users.byId[next.createdBy] : undefined,
		data
	};
}

export function useEnrichedHistories(objectId: string): IHistoryDifference[] {
	const histories = useAppSelector(extractInspectionStoriesAsArray);
	const users = useAppSelector(s => s.dictionaries.users);
	const plans = useAppSelector(s => extractPlans(s, objectId));

	return useMemo(() => {
		const result: IHistoryDifference[] = [];

		if (histories[0]) {
			result.push({
				createdAt: histories[0].createdAt,
				createdBy: histories[0].createdBy ? users.byId[histories[0].createdBy] : undefined
			});
		}
		for (let i = 0; i < histories.length - 1; i++) {
			result.push(compare(histories[i + 1], histories[i], users, plans));
		}
		return result;
	}, [histories, users, plans]);
}
