import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {IGetOwnerAcceptancesResponse, requestOwnerAcceptances} from '@src/api/backend/ownerAcceptances';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/reducers';
import preventExtraLoading from '@tehzor/tools/core/preventExtraLoading';

export interface IGetOwnerAcceptancesPayload extends IGetOwnerAcceptancesResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetOwnerAcceptancesResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка передач собственникам');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список передач собственникам
 */
export const getOwnerAcceptances
	= (objectId: string,
		state?: IOwnerAcceptancesFiltersState): AppThunkAction<Promise<IGetOwnerAcceptancesResponse>> =>
	(dispatch, getState) =>
		dispatch(
			preventExtraLoading(`${objectId}/owner-acceptances`, checkExpiration<IState, IGetOwnerAcceptancesResponse, ApiAction>(
				s => s.entities.ownerAcceptances[objectId],
				createApiAction<IGetOwnerAcceptancesResponse>(
					() => request(objectId),
					(res: IGetOwnerAcceptancesResponse) => success(objectId, res),
					failure,
					() => {
						const s = getState();
						if (!s.entities.ownerAcceptances[objectId] || !s.settings.pages.ownerAcceptances[objectId]) {
							throw new StoreError('Empty store before getting checks');
						}
						const {offset} = s.entities.ownerAcceptances[objectId];
						const {filters, sort, pageSize} = s.settings.pages.ownerAcceptances[objectId];
						return requestOwnerAcceptances(state || filters, sort, offset, pageSize);
					}
				)
			))
		);
