import React, {memo, useMemo} from 'react';
import {Tree} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useFiltersDialogDispatch} from '../utils/FiltersDialogDispatchCtx';
import Controls from './Controls';
import {makeUsersTreeData} from '../utils/makeUsersTreeData';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';

interface IUsersTabProps {
	checked: string[];
	expanded: {[key: string]: string[]};
}

const InspectorsTab = ({checked, expanded}: IUsersTabProps) => {
	const companies = useAppSelector(extractCompaniesAsArray);
	const users = useAppSelector(s => s.dictionaries.users);

	const treeData = useMemo(() => makeUsersTreeData(companies, users), [companies, users]);

	const dispatch = useFiltersDialogDispatch();

	const handleCheck = (value: string[]) => {
		dispatch({type: 'update', field: 'checkedUsers', value});
	};

	const handleExpand = (key: string, value: string[]) => {
		dispatch({
			type: 'update',
			field: 'expandedUsers',
			value: {
				...expanded,
				[key]: value
			}
		});
	};

	return (
		<>
			<Controls checkedField="checkedUsers"/>

			<div className="stats-filters-dialog__tree-wrap">
				<div className="stats-filters-dialog__tree">
					{treeData.map(item => (
						<Tree
							key={item.key}
							isCheckable
							checkStrictly
							checkedValue={checked}
							expandedValue={expanded[item.key]}
							isAllCheckedExpanded
							multiple
							data={item.data}
							onCheck={handleCheck}
							onExpand={(value: string[]) => handleExpand(item.key, value)}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default memo(InspectorsTab);
