import React, {ReactElement} from 'react';
import {ITextSearchProvider} from '../../interfaces';
import {IAnyEntity} from '@tehzor/tools/interfaces/IAnyEntity';
import classNames from 'classnames';
import {Select2} from '../../../inputs';
import {useMultipleSearchSelectHandlers} from '../../hooks/useMultipleSearchSelectHandlers';

interface IMultipleSearchSelectProps<T extends IAnyEntity> {
	className?: string;
	provider: ITextSearchProvider<T>;
	onSelect: (value: T[] | undefined) => void;
	renderItem: (item: T) => ReactElement;
}

export const MultipleSearchSelect = <T extends IAnyEntity>(props: IMultipleSearchSelectProps<T>) => {
	const {className, provider, onSelect, renderItem} = props;
	const [selectedMultiple, handleSelectionMultiple] = useMultipleSearchSelectHandlers(provider, onSelect);

	return (
		<Select2
			className={classNames('search-select__select', className)}
			value={selectedMultiple}
			onChange={handleSelectionMultiple}
			multiple
		>
			{provider.filteredList.map(renderItem)}
		</Select2>
	);
};