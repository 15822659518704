import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import './CategoriesCell.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCategories} from '@src/store/modules/dictionaries/categories/selectors';

export const CategoriesCell = ({row}: CellProps<IEnrichedStructure>) => {
	const categoriseMap = useAppSelector(extractCategories).byId;

	if (!row.original.categories) {
		return null;
	}
	const categories = Object.values(row.original.categories);
	if (!categories || categories?.length === 0) {
		return null;
	}
	return (
		<div>
			<div>{categoriseMap[categories[0]]?.name}</div>
			<div className="categories-cell">{categories.length > 1 && categoriseMap[categories[1]]?.name}</div>
			{categories.length > 2 && (
				<div className="categories-cell categories-cell-rest">
					+
					{categories.length - 2}
				</div>
			)}
		</div>
	);
};
