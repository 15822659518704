import useAppSelector from '@src/core/hooks/useAppSelector';
import React from 'react';
import './ListDetail.desktop.less';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import {TaskIndicators} from '@src/pages/CheckListPage/components/TaskIndicators';
import {extractCheckListById} from '@src/store/modules/dictionaries/checkLists/selectors';
import {CheckListDetailTabs} from '@src/pages/CheckListPage/components/detail/Tabs/CheckListDetailTabs';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface IDesktopListDetailProps {
	canAddTask?: boolean;
}

export const DesktopListDetail = ({canAddTask}: IDesktopListDetailProps) => {
	const {listId} = useStrictParams<{
		spaceId?: string;
		workAcceptanceId?: string;
		objectId: string;
		listId: string;
		stage: string;
	}>();
	const list = useAppSelector(s => extractCheckListById(s, listId));
	const permissions = {
		canAddTask
	};
	if (!list) {
		return null;
	}

	return (
		<div className="check-list-detail__container">
			<div className="check-list-detail__title">
				<div className="check-list-detail__title-text">{list.name}</div>
				<div className="check-list-detail__title-actions"/>
			</div>
			<div className="check-list-detail__indicators">
				<ProblemIndicators checkListId={listId}/>
				<TaskIndicators checkListId={listId}/>
			</div>
			<div className="check-list-detail__scrollable">
				<CheckListDetailTabs
					list={list}
					permissions={permissions}
				/>
			</div>
		</div>
	);
};
