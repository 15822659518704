import useAppSelector from '@src/core/hooks/useAppSelector';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import React from 'react';

interface ITypeDecorationProps {
	name?: string;
}

export const TypeDecor = ({name}: ITypeDecorationProps) => {
	const typeDecorations = useAppSelector(s => s.entities.spaceTypeDecorations.byId);
	const createLabel = (id: string | undefined, objectMap: Record<string, ISpaceTypeDecoration>) => {
		if (!id) return undefined;
		return (objectMap[id]?.name);
	};
	const label = createLabel(name, typeDecorations);

	return (
		<div className="space-page__area">
			{label ? (
				<>
					<div className="space-page__area-title">
						{label}
					</div>
				</>
			) : null}
		</div>
	);
};
