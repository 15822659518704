import React from 'react';
import {EntityLinkButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useEntitiesLinks} from '../../hooks/useEntitiesLinks';
import {IPermissions} from '../../hooks/usePermissions';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';

interface ILinksProps {
	objectId: string;
	problem: IPreparedProblem;
	permissions: IPermissions;
}

const DesktopLinks = ({objectId, problem, permissions}: ILinksProps) => {
	const links = useEntitiesLinks(permissions, objectId, problem);
	const {pushPath} = useChangePath();

	if (links.length === 0) {
		return null;
	}
	return (
		<>
			{links.map(item => (
				<EntityLinkButton
					key={item.key}
					type="inline"
					leftIcon={item.icon}
					label={item.label}
					url={item.url}
					title={item.title}
					onClick={pushPath}
				/>
			))}
		</>
	);
};

export default DesktopLinks;
