import React, {memo} from 'react';
import {CellProps} from 'react-table';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';

export const ModifiedCell = memo(({row}: CellProps<IPreparedLegal>) => {
	const {modifiedAt, modifiedBy} = row.original;

	return (
		<div className="legals__cell-modified">
			{modifiedAt && <div>{format(modifiedAt, dateTimeCommaSeparatedFormat)}</div>}
			{modifiedBy && <div>{modifiedBy.displayName}</div>}
		</div>
	);
});
