import {Column} from 'react-table';
import {DeadLine} from './DeadLine';
import {EditButton} from './EditButton';
import {Priority} from './Priority';
import {ResponsibleUsers} from './ResponsibleUsers';
import {TaskDescription} from './TaskDescription';
import {TaskStatusChangeable} from './TaskStatusChangeble';
import {IEnrichedTaskForScheduler} from '@src/pages/TasksPage/hooks/useEnrichedTasksForSchedule';

export const columns: Array<Column<IEnrichedTaskForScheduler>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Название',
		Cell: TaskDescription,
		// width: 150,
		minWidth: 100,
		disableSortBy: true
	},
	{
		id: 'status',
		Header: 'Статус',
		Cell: TaskStatusChangeable,
		accessor: item => item,
		className: 'tasks-schedule-popup__list-content_status-wrapper',
		width: 120,
		minWidth: 120,
		disableSortBy: true
	},
	{
		id: 'priority',
		Header: 'Приоритет',
		Cell: Priority,
		accessor: item => item,
		className: 'tasks-schedule-popup__list-content_priority-wrapper',
		width: 90,
		minWidth: 90,
		disableSortBy: true
	},
	{
		id: 'taskInterval',
		Header: 'Срок исполнения',
		Cell: DeadLine,
		accessor: (item: IEnrichedTaskForScheduler) => item,
		className: 'tasks-schedule-popup__list-content_deadline-wrapper',
		width: 120,
		minWidth: 120,
		disableSortBy: true
	},
	{
		id: 'respUsers',
		Header: 'Ответственные',
		Cell: ResponsibleUsers,
		accessor: (item: IEnrichedTaskForScheduler) => item,
		className: 'tasks-schedule-popup__list-content_responsible-wrapper',
		width: 110,
		minWidth: 110,
		disableSortBy: true
	},
	{
		id: '_menu',
		Header: '',
		Cell: EditButton,
		accessor: (item: IEnrichedTaskForScheduler) => item,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		className: 'tasks-schedule-popup__list-content_button',
		width: 30,
		minWidth: 30
	}
];
