import ExpandableMobileInfo from '@src/components/ExpandableMobileInfo';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {EditorInfo, EntityGrid, EntityGridItem, Plate} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import React from 'react';

interface ICategoriesSetInfoProps {
	set?: ICategoriesSet;
	nameEl: React.ReactNode;
	relationsEl: React.ReactNode;
	stagesEl: React.ReactNode;
	saveButtonsEl: React.ReactNode;
	className?: string;
}

const nameIcon = <i className="tz-name-20"/>;
const relationsIcon = <i className="tz-description-20"/>;
const calendarIcon = <i className="tz-calendar-20"/>;

const CategoriesSetInfo = ({
	set,
	nameEl,
	relationsEl,
	stagesEl,
	saveButtonsEl,
	className
}: ICategoriesSetInfoProps) => {
	const genClassName = (block: string, elem?: string) =>
		(elem ? `${className}__${block}-${elem}` : `${className}__${block}`);

	const isLargeTablet = useIsLargeTablet();
	const addInfo = (
		<>
			{set?.createdAt && (
				<EditorInfo
					className={genClassName('created')}
					icon={calendarIcon}
					label="Создано"
					user={set?.createdBy}
					date={set?.createdAt}
					fullRow
				/>
			)}
			{set?.modifiedAt && (
				<EditorInfo
					className={genClassName('modified')}
					icon={calendarIcon}
					label="Изменено"
					user={set?.modifiedBy}
					date={set?.modifiedAt}
					fullRow
				/>
			)}
		</>
	);

	return (
		<Plate className={className}>
			<EntityGrid withBorders>
				<EntityGridItem
					className={genClassName('name')}
					icon={nameIcon}
					label="Наименование"
					fullRow
				>
					{nameEl}
				</EntityGridItem>

				<EntityGridItem
					className={genClassName('relations')}
					icon={relationsIcon}
					label="Компании/Объекты"
					fullRow
				>
					{relationsEl}
				</EntityGridItem>

				<EntityGridItem
					className={genClassName('stages')}
					icon={relationsIcon}
					label="Стадии"
					fullRow
				>
					{stagesEl}
				</EntityGridItem>

				{saveButtonsEl && (
					<EntityGridItem
						className={genClassName('save')}
						buttons={saveButtonsEl}
						fullRow
					/>
				)}

				{isLargeTablet && (
					<>{addInfo}</>
				)}
			</EntityGrid>

			{!isLargeTablet && (
				<ExpandableMobileInfo noBorder>
					<EntityGrid withBorders>
						{addInfo}
					</EntityGrid>
				</ExpandableMobileInfo>
			)}
		</Plate>
	);
};
export default CategoriesSetInfo;
