import {createSelector} from 'reselect';
import {IState} from '../..';
import {extractAllCategoriesAsArray} from '../categories/selectors';
import {extractCategoriesSetsPageSettings} from '../../settings/pages/manage/categoriesSets/selectors';
import handleSort from './utils/handleSort';
import handleFilter from './utils/handleFilter';
import handlePagination from './utils/handlePagination';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ICategoriesSetsFiltersState} from '../../settings/pages/manage/categoriesSets/reducers';
import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';

const getState = (state: IState) => state;
const getCategoriesSets = (state: IState) => state.dictionaries.categoriesSets;
const getCategoriesSetId = (state: IState, categoriesSetId: string) => categoriesSetId;
const getObjectId = (state: IState, objectId: string) => objectId;
const getStage = (state: IState, objectId: string, stage?: ObjectStageIds) => stage;
const getFilters = (state: IState, filters: ICategoriesSetsFiltersState) =>
	filters;

export const extractCategoriesSetsData = createSelector(getCategoriesSets, data => data);

export const extractFullCategoriesSetsData = createSelector(
	[extractCategoriesSetsData, extractAllCategoriesAsArray],
	(data, allCategories) => {
		const {allIds, byId} = data;
		const total = allIds.length;
		const fullById: {[id: string]: IFullCategoriesSet} = {};

		allIds?.forEach(id => {
			fullById[id] = {
				...byId[id],
				categories: allCategories[id] ?? []
			};
		});

		return {allIds, byId: fullById, total};
	}
);

export const extractFullCategoriesSetsAsArray = createSelector(
	[extractFullCategoriesSetsData],
	data => data.allIds?.map(id => ({...data.byId[id]}))
);

export const extractFullCategoriesSetsAsArrayByObjectId = createSelector(
	[extractFullCategoriesSetsAsArray, getObjectId],
	(sets, objectId) => sets?.filter(set => set.objects.includes(objectId) || set.shared)
);

export const extractCategoriesSetsForPage = createSelector(
	[
		extractFullCategoriesSetsAsArray,
		getObjectId,
		(state: IState, objectId: string) => extractCategoriesSetsPageSettings(state, objectId)
	],
	(array, objectId, settings) => {
		const {offset, pageSize, sort, filters} = settings;

		const scopeFilters: ICategoriesSetsFiltersState = {
			objects: objectId !== 'all' ? [objectId] : []
		};

		const filteredArr = handleSort(array, sort)
			.filter(item => handleFilter(item, scopeFilters))
			.filter(item => handleFilter(item, filters));

		const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));

		return {
			data: paginatedArr,
			pageCount: Math.ceil(filteredArr.length / pageSize),
			currentPage: Math.floor(offset / pageSize),
			total: filteredArr.length
		};
	}
);

export const extractFilteredCategoriesSetsAsArray = createSelector(
	[
		extractFullCategoriesSetsAsArray,
		getFilters
	],
	(array, filters) => array.filter(item => handleFilter(item, filters))
);

export const extractCategoriesSetsAsArray = createSelector(
	[getState, getObjectId, getStage],
	(state, objectId, stage) => {
		const filters = {
			objects: objectId !== 'all' ? [objectId] : [],
			stages: stage ? [stage] : undefined
		};

		return extractFilteredCategoriesSetsAsArray(state, filters)
	}
);

export const extractCategoriesSetById = createSelector(
	[getCategoriesSets, getCategoriesSetId],
	(data, id) => data.byId?.[id]
);
