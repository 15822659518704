import React, {PureComponent} from 'react';
import './Table.less';
import ReactTable, {TableProps} from 'react-table-6';
import TablePagination from './components/TablePagination';
import classNames from 'classnames';
import NoData from './components/NoData';

interface ITableProps<D> extends Partial<TableProps<D>> {
	highlight?: boolean;
	pageRangeDisplayed?: number;
	marginPagesDisplayed?: number;
}

class Table<D> extends PureComponent<ITableProps<D>> {
	static displayName = 'Table';

	render() {
		const {className, highlight, pageRangeDisplayed, marginPagesDisplayed, ...rest} = this.props;
		const classes = classNames('table', {table_highlight: highlight}, className);

		return (
			<ReactTable
				className={classes}
				PaginationComponent={TablePagination}
				NoDataComponent={NoData}
				getPaginationProps={() => ({pageRangeDisplayed, marginPagesDisplayed})}
				minRows={0}
				{...rest}
			/>
		);
	}
}

export {ITableProps};
export default Table;
