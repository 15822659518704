import {wsConnector} from '../wsConnector';
import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IEditExportTemplateResponse = INormalizedData<IExportTemplate>;

/**
 * Редактирует шаблон
 * @param exportTemplateId id рабочей группы
 * @param fields данные для добавления
 */
export const makeExportTemplateEditRequest = (exportTemplateId: string, fields: ISavingSpaceTypeDecorationSet) =>
	wsConnector.sendAuthorizedRequest<IEditExportTemplateResponse>(
		'editExportTemplate',
		{exportTemplateId, ...fields}
	);
