import {
	IInternalAcceptancesFiltersState,
	IInternalAcceptancesSortState
} from '@src/store/modules/settings/pages/internalAcceptances/reducers';

export type IInternalAcceptancesListQueryKey = [] & {
	0: string;
	1: string;
	2: IInternalAcceptancesFiltersState;
	3: IInternalAcceptancesSortState;
	4: number;
	5: number;
};

export const internalAcceptancesQueryKeys = {
	all: () => ['internal-acceptances'],
	one: () => ['internal-acceptance'],
	latest: () => ['latest-internal-acceptances'],
	list: () => [...internalAcceptancesQueryKeys.all(), 'list'],
	localList: () => [...internalAcceptancesQueryKeys.all(), 'local-list'],
	add: () => [...internalAcceptancesQueryKeys.one(), 'add'],
	details: () => [...internalAcceptancesQueryKeys.one(), 'detail'],
	detail: (id: string) => [...internalAcceptancesQueryKeys.one(), 'detail', id]
};
