import React from 'react';
import {InternalAcceptancesFiltersPage} from '@src/pages/filters/InternalAcceptancesFiltersPage/InternalAcceptancesFiltersPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';

export const internalAcceptancesFilterRoutes = [
	{path: 'objects', element: <FiltersPageObjects/>},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage/>
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage/>
	},
	{
		path: '',
		element: <InternalAcceptancesFiltersPage/>
	}
];
