export enum CheckListTypeId {
	SPACES = 'spaces',
	OBJECTS = 'objects',
	WORK_ACCEPTANCES = 'work-acceptances'
}

export interface ICheckListType {
	id: CheckListTypeId;
	name: string;
	color?: string;
}
