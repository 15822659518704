import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useAsyncFn} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {deleteSpace} from '@src/store/modules/entities/space/actions';
import {useEditableSpaceDialog} from '@src/components/EditableSpaceDialog/hooks/useEditableSpaceDialog';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';

interface IMobileMenuProps {
	space: ISpace;
	canDeleteSpace?: boolean;
	canEditSpace?: boolean;
}

const editIcon = <i className="tz-edit" />;
const deleteIcon = <i className="tz-delete" />;

const MobileMenu = (props: IMobileMenuProps) => {
	const {space, canDeleteSpace, canEditSpace} = props;
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить помещение',
		`Вы действительно хотите удалить помещение №${space.name}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteSpace(space.objectId, space.id));
			goBack();
		}
	}, [space.objectId, space.id]);

	// Диалог редактирования помещения
	const [editDialog, openEditDialog] = useEditableSpaceDialog(space.objectId, space.id);
	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const items = [];

	items.push(updateData);

	if (canEditSpace) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openEditDialog}
			>
				Редактировать
			</MenuItem>
		);
	}
	if (canDeleteSpace) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDeleteSpace && deleteDialog}
			{canEditSpace && editDialog}
		</>
	) : null;
};

export default MobileMenu;
