import {Column} from 'react-table';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {MobileDocumentCell} from './cells/mobileDocumentCell';

export const mobileColumns: Array<Column<IDocument>> = [
	{
		id: 'number',
		width: 65,
		Cell: MobileDocumentCell
	}
];
