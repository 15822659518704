import React from 'react';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptanceProblems} from '@src/store/modules/entities/internalAcceptance/selectors';
import {useInternalAcceptancePermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';
import MobileInternalAcceptanceExport from './InternalAcceptanceExport.mobile';
import MobileMenu from './Menu.mobile';

interface IMobileActionsProps {
	objectId: string;
	internalAcceptance: ILinkedInternalAcceptance;
}

const ActionsMobile = ({objectId, internalAcceptance}: IMobileActionsProps) => {
	const problems = useAppSelector(extractInternalAcceptanceProblems);
	const permissions = useInternalAcceptancePermissions(objectId, internalAcceptance, problems);

	return (
		<>
			{permissions.canExport && (
				<MobileInternalAcceptanceExport
					objectId={objectId}
					internalAcceptanceId={internalAcceptance.id}
				/>
)}

			<MobileMenu
				objectId={objectId}
				internalAcceptance={internalAcceptance}
				canDeleteInternalAcceptance={permissions.canDelete}
			/>
		</>
	);
};

export default ActionsMobile;
