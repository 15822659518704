import React, {useCallback, useState} from 'react';
import './EditableSpaceOwnerDialog.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog, InlineButton} from '@tehzor/ui-components';
import {useEditableSpaceOwner} from '../EditableSpaceOwner/hooks/useEditableSpaceOwner';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {useAsyncFn} from 'react-use';
import {editSpaceOwner, unbindSpaceFromOwner} from '@src/store/modules/entities/spaceOwner/actions';

interface IEditableSpaceOwnerDialogProps {
	spaceId: string;
	owner?: ISpaceOwner;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно редактирования собственника помещения
 */
const EditableSpaceOwnerDialog = ({
	spaceId,
	owner,
	isOpen,
	onClose
}: IEditableSpaceOwnerDialogProps) => {
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableSpaceOwner(owner, saving);

	const [, save] = useAsyncFn(async () => {
		if (!owner) {
			return;
		}
		setSaving(true);
		const savingData = getSavingData();
		if (savingData) {
			try {
				await dispatch(editSpaceOwner(owner.id, savingData));
				onClose();
			} catch (error) {
				setSaving(false);
				throw error;
			}
		}
		setSaving(false);
	}, [owner, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	const [deletingDialog, getDeletengConfirmation] = useConfirmDialog(
		'Открепить собственника',
		'Вы действительно хотите открепить собственника от помещения?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (!owner) {
			return;
		}
		if (await getDeletengConfirmation()) {
			setSaving(true);
			try {
				await dispatch(unbindSpaceFromOwner(owner.id, spaceId, false));
				onClose();
			} finally {
				setSaving(false);
			}
		}
	}, [owner, spaceId]);

	return (
		<Dialog
			className="editable-space-owner-dialog"
			isOpen={isOpen}
			title="Редактирование данных собственника"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			<InlineButton
				label="Открепить собственника"
				className="editable-space-owner-dialog__delete-button"
				onClick={handleDelete}
				type="cancel"
				leftIcon={<i className="tz-delete"/>}
				disabled={saving}
			/>
			{closingDialog}
			{deletingDialog}
		</Dialog>
	);
};

export default EditableSpaceOwnerDialog;
