import {Column} from 'react-table';
import {IPreparedClaim} from '../../interfaces/IPreparedClaim';
import MenuCell from './MenuCell';
import OwnerCell from './OwnerCell';

export const mobileColumns: Array<Column<IPreparedClaim>> = [
	{
		id: 'number',
		Header: '',
		accessor: row => `№${row.number ?? row.localNumber}`,
		width: 65,
		className: 'w-claims-page__m-table-number'
	},
	{
		Header: 'Id',
		accessor: 'publicId',
		width: 100,
		disableSortBy: true
	},
	{
		Header: 'Объект',
		id: 'object',
		accessor: row => row.object?.name,
		width: 100
	},
	{
		id: 'status',
		Header: 'Статус',
		accessor: row => row.status,
		width: 100
	},
	{
		Header: 'Заявитель',
		id: 'spaceOwner',
		accessor: row => row.spaceOwner?.name,
		Cell: OwnerCell,
		width: 100
	},
	{
		Header: 'Добавлено',
		accessor: 'createdAt',
		width: 100,
		sortDescFirst: true
	},
	{
		Header: 'Изменено',
		accessor: 'modifiedAt',
		width: 100,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		className: 'w-claims-page__m-table-menu'
	}
];

export const mobileColumnsWithoutObject = mobileColumns.filter(item => item.id !== 'object');
