import React, {memo} from 'react';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import {makeDateFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {longDateFormat} from '@tehzor/tools/utils/dateFormats';

interface IModifiedDateFilterProps {
	label?: string;
	from?: Date;
	to?: Date;
}

export const ModifiedDateFilter = memo((props: IModifiedDateFilterProps) => {
	const {label = 'Дата изменения', from, to} = props;

	const dateFrom = from ? new Date(from) : undefined;
	const dateTo = to ? new Date(to) : undefined;

	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = (f: Date | null, t: Date | null) => {
		const modifiedAtFrom = f ? new Date(f.getFullYear(), f.getMonth(), f.getDate()) : undefined;
		const modifiedAtTo = t
			? new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
			: undefined;
		dispatch({modifiedAtFrom, modifiedAtTo});
	};

	const handleClear = () => {
		dispatch({modifiedAtFrom: undefined, modifiedAtTo: undefined});
	};

	return (
		<DateRangePicker
			trigger={triggerProps => (
				<FilterButton
					className="entities-filters__item"
					label={makeDateFilterLabel(label, dateFrom, dateTo)}
					active={dateFrom !== undefined || dateTo !== undefined}
					onClick={triggerProps.toggle}
					onClear={handleClear}
					ref={triggerProps.ref}
				/>
			)}
			valueFrom={dateFrom}
			valueTo={dateTo}
			dateFormat={longDateFormat}
			useApplyButton
			onChange={handleChange}
		/>
	);
});
