import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {useAsyncFn} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';

interface IMobileMenuProps {
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
}

export const MobileMenu = ({workAcceptance, permissions}: IMobileMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить приёмку работ',
		`Вы действительно хотите приёмку работ №${workAcceptance.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteWorkAcceptance(workAcceptance.objectId, workAcceptance.id));
			goBack();
		}
	}, [workAcceptance.objectId, workAcceptance.id]);

	const items = [];

	items.push(updateData);

	if (permissions?.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions?.canDelete && deleteDialog}
		</>
	) : null;
};
