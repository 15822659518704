import React from 'react';
import './TasksSchedulePopup.less';
import {TasksListPopup} from './components/TasksListPopup';
import {Dialog} from '@tehzor/ui-components';
import {IEnrichedTaskForScheduler} from '@src/pages/TasksPage/hooks/useEnrichedTasksForSchedule';

interface ITasksSchedulePopupProps {
	tasks: IEnrichedTaskForScheduler[];
	isOpen: boolean;
	title?: string;
	objectId?: string;
	startTime?: number;
	endTime?: number;
	onClose: (e: React.SyntheticEvent) => void;
}

export const TasksSchedulePopup = (props: ITasksSchedulePopupProps) => {
	const {tasks, isOpen, onClose, title = 'Задачи', objectId, startTime, endTime} = props;

	return (
		<Dialog
			className={{
				root: 'tasks-schedule-popup',
				content: 'tasks-schedule-popup__content'
			}}
			isOpen={isOpen}
			title={title}
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<TasksListPopup
				data={tasks}
				objectId={objectId}
				startTime={startTime}
				endTime={endTime}
			/>
		</Dialog>
	);
};
