import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const extractAllIds = (state: IState) => state.dictionaries.users.allIds || [];
const extractById = (state: IState) => state.dictionaries.users.byId || {};

/**
 * Извлекает пользователей в виде массива
 */
export const extractUsersAsArray = createSelector([extractAllIds, extractById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));

/**
 * Извлекает пользователей в виде map'ы
 */
export const extractUsersAsMap = createSelector(extractById, byId => byId);