import {Column} from 'react-table';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import ObjectStatsCell from '@src/components/tableCells/ObjectStatsCell';
import ObjectNameDesktopCell from '@src/components/tableCells/ObjectNameDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import CompanyNameCell from '@src/components/tableCells/CompanyNameCell';
import MenuCell from './MenuCell';
import {ObjectStagesCell} from './ObjectStagesCell';

export const desktopColumns: Array<Column<IObject>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: HeaderCellWithRowSelectWrap('Наименование'),
		Cell: ObjectNameDesktopCell,
		width: 210,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'company',
		accessor: 'companyId',
		Header: 'Компания',
		Cell: CompanyNameCell,
		width: 110,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'city',
		accessor: 'city',
		Header: 'Город',
		width: 80,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'stage',
		accessor: 'stage',
		Header: 'Стадии объекта',
		Cell: ObjectStagesCell,
		width: 110,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'problems',
		Header: 'Нарушения',
		Cell: ObjectStatsCell,
		width: 130,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'menu',
		Header: '',
		Cell: MenuCell,
		width: 54,
		className: 'objects-page__table-menu',
		isNonClickable: true
	}
];
