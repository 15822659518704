import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISavingObject} from '@tehzor/tools/interfaces/objects/ISavingObject';
import {IEditObjectResponse, makeObjectEditRequest} from '@src/api/backend/object';
import {addErrorToast} from '@src/utils/toasts';

export type IEditObjectPayload = IEditObjectResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditObjectResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении объекта');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет объект
 *
 * @param objectId id объекта
 * @param object данные объекта
 */
export const editObject = (objectId: string, object: ISavingObject) =>
	createApiAction<IEditObjectResponse>(request, success, failure, () =>
		makeObjectEditRequest(objectId, object));
