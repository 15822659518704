import React from 'react';
import './Responsible.less';
import {EntityGridItem, UserInfo, WorkingGroupInfo} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {DelegationButton} from './components/DelegationButton';
import {DelegationHistoryButton} from './components/DelegationHistoryButton';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {useResponsiblePermissions} from '@src/core/hooks/permissions/useResponsiblePermissions';
import {useDelegationHistoriesPermissions} from '@src/core/hooks/permissions/useDelegationHistoriesPermissions';
import {Progress} from './components/Progress';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';

interface IProblemResponsibleProps {
	type: 'problem';
	entity: IPreparedProblem;
}

interface IWorkAcceptanceResponsibleProps {
	type: 'work-acceptance';
	entity: IWorkAcceptance;
}

export const Responsible = ({entity, type}: IProblemResponsibleProps | IWorkAcceptanceResponsibleProps) => {
	const {respUsers, activeGroup, initialGroup} = entity;

	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const groupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);

	const {canEditResponsible, onlyActiveGroup} = useResponsiblePermissions(
		entity.objectId,
		entity,
		type
	);
	const {canView: canViewHistory} = useDelegationHistoriesPermissions();

	const initial: IWorkingGroup | undefined = initialGroup ? groupsMap[initialGroup] : undefined;
	const active: IWorkingGroup | undefined = activeGroup ? groupsMap[activeGroup] : undefined;

	const delegationIsUsed = !!(initialGroup && activeGroup);
	const delegationInProgress = !respUsers?.length && activeGroup !== undefined;

	return (
		<EntityGridItem
			className="responsible-view-field"
			icon={<i className="tz-user-20"/>}
			label="Ответственные"
			fullRow
		>
			<div className="responsible-view-field__body">
				{delegationIsUsed && (activeGroup !== initialGroup || respUsers?.length) ? (
					<Progress inProgress={delegationInProgress}/>
				) : null}

				<div className="responsible-view-field__list">
					{initialGroup !== undefined
						&& (activeGroup !== initialGroup || respUsers?.length) ? (
							<WorkingGroupInfo
								className="responsible-view-field__group"
								name={initial?.name ?? ''}
								leader={initial?.leader ? usersMap[initial?.leader] : undefined}
							/>
					) : null}

					{respUsers?.length ? (
						respUsers.map(userId => (
							<UserInfo
								key={userId}
								className="responsible-view-field__user"
								user={usersMap[userId]}
								avatarSize="40"
								avatarColoring="text"
							/>
						))
					) : activeGroup ? (
						<WorkingGroupInfo
							className="responsible-view-field__group"
							name={active?.name ?? ''}
							leader={active?.leader ? usersMap[active?.leader] : undefined}
						/>
					) : null}
				</div>
			</div>

			{delegationIsUsed && (canEditResponsible || canViewHistory) ? (
				<div className="responsible-view-field__buttons">
					{canEditResponsible ? (
						<DelegationButton
							entity={entity}
							onlyActiveGroup={onlyActiveGroup}
							inProgress={delegationInProgress}
							type={type}
							planId={type === 'problem' ? entity.plan?.id : undefined}
						/>
					) : null}

					{canViewHistory ? (
						<DelegationHistoryButton
							objectId={entity.objectId}
							entityId={entity.id}
							type={type}
						/>
					) : null}
				</div>
			) : null}
		</EntityGridItem>
	);
};
