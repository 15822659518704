import React, {useCallback} from 'react';
import {EntityInnerTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formProblemLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {extractWorkAcceptanceEntities} from '@src/store/modules/entities/workAcceptance/selectors';
import {SelectionRow} from './SelectionRow';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface ITableProps {
	objectId: string;
	workAcceptanceId: string;
	checkLists: ICheckList[];
	columns: Array<Column<IWorkAcceptanceEntity>>;
	hideHead?: boolean;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

export const Table = ({
	objectId,
	workAcceptanceId,
	checkLists,
	columns,
	hideHead,
	selectedRows,
	onSelectedRowsChange
}: ITableProps) => {
	const entities = useAppSelector(extractWorkAcceptanceEntities);
	const {pushPath} = useChangePath();

	const handleRowClick = useCallback(
		(entity: IWorkAcceptanceEntity) => {
			switch (entity.type) {
				case 'problem':
					return pushPath(formProblemLink(objectId, entity.data.id));
				default:
					return undefined;
			}
		},
		[objectId]
	);

	return (
		<EntityInnerTable
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable
			selectedRows={selectedRows}
			onRowClick={handleRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					workAcceptanceId={workAcceptanceId}
					checkLists={checkLists}
					objectId={objectId}
				/>
			)}
		/>
	);
};
