import {
	convertToSave,
	isEdited,
	makeDefaultData,
	useEditableContractState
} from '@src/core/hooks/states/useEditableContractState';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {getPlans} from '@src/store/modules/dictionaries/plans/actions';
import {IContract, ISavingContract} from '@tehzor/tools/interfaces/contracts';
import React, {useState} from 'react';
import {useAsync, useUpdateEffect} from 'react-use';
import {EditableContract} from '../EditableContract';
import {checkErrors} from '../utils/checkErrors';
import {reset} from '../utils/reset';
import {
	useUploadingFilesState,
	isEdited as isFilesEdited,
	convertToSave as convertFilesToSave
} from '@src/core/hooks/states/useUploadingFilesState';
import {getUnloadedObjectIdsForPlans} from '@src/store/modules/dictionaries/plans/utils/getUnloadedObjectIdsForPlans';

export const useEditableContract = (contract?: IContract) => {
	const companies = useAppSelector(extractCompaniesAsArray);
	const defaultData = makeDefaultData(contract, companies);
	const [editingState, editingDispatch] = useEditableContractState(defaultData);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();

	const dispatch = useAppDispatch();

	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useUpdateEffect(() => {
		setIsBlocking(isEdited(editingState, contract));
	}, [editingState, contract]);

	const fields = (
		<EditableContract
			contractId={contract?.id}
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesDispatch={uploadingFilesDispatch}
			uploadingFilesState={uploadingFilesState}
		/>
	);

	const allPlans = useAppSelector(s => s.dictionaries.plans);
	useAsync(async () => {
		if (!editingState.objectIds?.length) {
			return;
		}
		const unloadedObjectIds = getUnloadedObjectIdsForPlans(editingState.objectIds, allPlans);

		await dispatch(getPlans(unloadedObjectIds));
	}, [editingState.objectIds, allPlans]);

	const handleReset = () => reset(
		editingDispatch,
		uploadingFilesDispatch,
		defaultData
	);

	const getSavingData = async (): Promise<ISavingContract | undefined> => {
		if (checkErrors(editingState, editingDispatch)) {
			return undefined;
		}

		const files = await waitUploading();
		const file = files.length ? files[0] : undefined;

		if (file?.sizeError) {
			return undefined;
		}

		if (!isEdited(editingState, contract) && !isFilesEdited(files)) {
			return undefined;
		}

		const savingData = convertToSave(editingState, contract, companies);
		const savingFiles = convertFilesToSave(files);

		return {
			...savingData,
			newAttachments: savingFiles
		};
	};

	return {fields, reset: handleReset, getSavingData, isBlocking};
};
