import React from 'react';
import './LocationChange.less';
import classNames from 'classnames';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';

interface ILocationChangeProps extends IChangeComponentProps {
	value?: {
		location?: string;
		floor?: string;
		planName?: string;
	};
}

export const LocationChange = ({className, style, value}: ILocationChangeProps) => (
	<div
		className={classNames('history-location-change', className)}
		style={style}
	>
		<div>
			<span className="history-location-change__text">План</span>
			:&nbsp;
			{value?.planName ? value?.planName : null}
		</div>
		<div>
			<span className="history-location-change__text">Этаж</span>
			:&nbsp;
			{value?.floor || null}
		</div>
		<div>
			<span className="history-location-change__text">Место</span>
			:&nbsp;
			{value?.location ? value?.location : null}
		</div>
	</div>
);

LocationChange.displayName = 'LocationChange';
