import {IDeleteCategoryResponse} from '@src/api/backend/categories';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	convertToSave,
	IEditableCategoriesSetAction,
	IEditableCategoriesSetState,
	makeDefaultData
} from '@src/core/hooks/states/useCategoriesSetState';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	addCategory,
	deleteCategories,
	deleteCategory,
	editCategory
} from '@src/store/modules/dictionaries/categories/actions';
import {
	addCategoriesSet,
	deleteCategoriesSet,
	editCategoriesSet
} from '@src/store/modules/dictionaries/categoriesSets/actions';
import {addErrorToast} from '@src/utils/toasts';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {IDeleteCategoriesSetResponse} from '@src/api/backend/categoriesSets';
import {Dispatch, useCallback} from 'react';
import {useParams} from 'react-router';
import {extractFullCategoriesSetsAsArray} from '@src/store/modules/dictionaries/categoriesSets/selectors';

interface IUseUpdateContent {
	updateCategoriesSet: (isCreatable?: boolean) => Promise<ICategoriesSet | undefined>;
	removeCategoriesSet: (id: string) => Promise<IDeleteCategoriesSetResponse | undefined>;
	updateCategory: (id: string, index: number) => Promise<ICategory | undefined>;
	removeCategory: (id: string) => Promise<IDeleteCategoryResponse | undefined>;
}

const useUpdateContent = (
	editingState?: IEditableCategoriesSetState,
	editingDispatch?: Dispatch<IEditableCategoriesSetAction>,
	categoriesSet?: ICategoriesSet,
	categories?: ICategory[]
): IUseUpdateContent => {
	const {replacePath} = useChangePath();
	const dispatch = useAppDispatch();
	const defaultData = makeDefaultData(categoriesSet, categories);
	const {objectId, categoriesSetId} = useParams<{
		objectId: string;
		categoriesSetId: string;
	}>();
	const fullCategoriesSets = useAppSelector(extractFullCategoriesSetsAsArray);

	const checkErrors = useCallback((
		state: IEditableCategoriesSetState, dispatch: Dispatch<IEditableCategoriesSetAction>
	) => {
		if (!state.errors) {
			return false;
		}

		let hasError = false;
		const {shared, companies, objects, name} = state;

		if (!shared) {
			dispatch({type: 'update-error', field: 'companies'});
			dispatch({type: 'update-error', field: 'objects'});
			hasError = !companies.length && !objects.length;
		}

		if (!name) {
			dispatch({type: 'update-error', field: 'name'});
			hasError = true;
		}

		return hasError;
	}, []);

	const updateCategoriesSet = useCallback(async (isCreatable = false) => {
		if (
			!editingState
			|| !editingDispatch
			|| (categoriesSetId === 'empty' && !isCreatable)
			|| checkErrors(editingState, editingDispatch)
		) {
			return undefined;
		}

		if (categoriesSetId === 'empty') {
			const {categories: editingCategories, ...rest} = editingState;

			try {
				const createdSet = await dispatch(addCategoriesSet(rest));

				replacePath(
					objectId
						? `/objects/${objectId}/categories-sets/${createdSet.id}`
						: `/manage/categories-sets/${createdSet.id}`
				);

				return createdSet;
			} catch (e) {
				addErrorToast(
					'Наборы видов работ',
					'Не удалось создать новый набор видов работ'
				);

				return undefined;
			}
		}

		const [savingCategoriesSet] = convertToSave(editingState, defaultData);

		try {
			const createdSet = await dispatch(editCategoriesSet(savingCategoriesSet));

			replacePath(
				objectId
					? `/objects/${objectId}/categories-sets/${createdSet.id}`
					: `/manage/categories-sets/${createdSet.id}`
			);

			return createdSet;
		} catch (e) {
			addErrorToast('Наборы видов работ', 'Не удалось изменить набор видов работ');

			return undefined;
		}
	}, [editingState]);

	const removeCategoriesSet = useCallback(async (id: string) => {
		try {
			const res = await dispatch(deleteCategoriesSet(id));
			const set = fullCategoriesSets.find(item => item.id === id);

			if (set?.categories?.length) {
				await dispatch(deleteCategories(id));
			}

			return res;
		} catch (e) {
			addErrorToast('Наборы видов работ', 'Не удалось удалить набор видов работ');

			return undefined;
		}
	}, []);

	const updateCategory = useCallback(async (id: string, index: number) => {
		if (categoriesSetId === 'empty' || !editingState) {
			return undefined;
		}

		const {categories: editingCategories} = editingState;

		if (index === -1) {
			return undefined;
		}
		const category = editingCategories[index];

		if (category.isLocal) {
			try {
				return await dispatch(addCategory(category));
			} catch (e) {
				addErrorToast('Наборы видов работ', 'Не удалось создать вид работ');
				return undefined;
			}
		}

		try {
			return await dispatch(editCategory(category));
		} catch (e) {
			addErrorToast('Наборы видов работ', 'Не удалось изменить вид работ');
			return undefined;
		}
	}, [editingState]);

	const removeCategory = useCallback(async (id: string) => {
		if (!categoriesSetId || categoriesSetId === 'empty' || !editingState) {
			return undefined;
		}

		const {categories: editingCategories} = editingState;
		const categoryIndex = editingCategories.findIndex(item => item.id === id);

		if (categoryIndex === -1 || id[0] === '_') {
			return undefined;
		}

		try {
			return await dispatch(deleteCategory(id, categoriesSetId));
		} catch (e) {
			addErrorToast('Наборы видов работ', 'Не удалось удалить вид работ');
			return undefined;
		}
	}, [editingState, categoriesSetId]);

	return {
		updateCategoriesSet,
		removeCategoriesSet,
		updateCategory,
		removeCategory
	};
};

export default useUpdateContent;
