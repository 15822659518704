import React from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useStructureBreadcrumbs} from '@src/core/hooks/breadcrumbs/useStructureBreadcrumbs';
import {MainActionsDesktop} from './actions/MainActions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IPageBreadcrumbsProps {
	objectId: string;
	structureId: string;
}

export const PageBreadcrumbs = ({objectId, structureId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useStructureBreadcrumbs(objectId, structureId);
	const isDesktop = useIsDesktop();
	return (
		<AppBreadcrumbs
			className="structure-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && (
				<MainActionsDesktop
					objectId={objectId}
					structureId={structureId}
				/>
			)}
		</AppBreadcrumbs>
	);
};
