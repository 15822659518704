import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {RESET} from '@src/store/modules/entities/spaceTypeDecorationSets/constants';

const {entitiesCacheTime} = config;

export interface ISpaceTypeDecorationState {
	data?: ISpaceTypeDecorationSet;
	expires?: number;
}

export const getInitialState = (): ISpaceTypeDecorationState => ({});

export default createReducer<ISpaceTypeDecorationState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: ISpaceTypeDecorationSet}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.ADD_SUCCESS]: (state, {payload}: {payload: ISpaceTypeDecorationSet}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: ISpaceTypeDecorationSet}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: ISpaceTypeDecorationSet}) => {
		if (state.data?.id !== payload.id) {
			return state;
		}
		return getInitialState();
	},
	[CLEAR_STORE]: getInitialState,
	[RESET]: getInitialState
});
