import React from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {queryClient} from '@src/api/QueryClient';
import {useQuery} from '@tanstack/react-query';
import useAppSelector from '@src/core/hooks/useAppSelector';

export const CheckListsRecordsCacheStatus = () => {
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const settingsStatuses = queryClient.getQueryDefaults(checkRecordsQueryKeys.list());
	const {dataUpdatedAt, isError, isFetching, isPaused, isStale} = useQuery({
		queryKey: [...checkRecordsQueryKeys.list(), undefined, objects],
		staleTime: settingsStatuses?.staleTime
	});
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Статусы чек листов"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={isError ? 'Ошибка' : isStale ? 'Данные устарели' : undefined}
			inProgress={!!isFetching || isPaused}
		/>
	);
};
