import React, {useCallback} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/reducers';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {
	changeOffset,
	getInternalAcceptances
} from '@src/store/modules/entities/internalAcceptances/actions';
import {
	changeFilters,
	clearFilters
} from '@src/store/modules/settings/pages/internalAcceptances/actions';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';

export const InternalAcceptancesFiltersRoutingPage = () => {
	const {objectId} = useParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractInternalAcceptancesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await dispatch(getInternalAcceptances(objectId || 'all'));
	}, [objectId]);

	const applyFilters = useCallback(
		(value: IInternalAcceptancesFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId || 'all', 0));
		},
		[objectId, dispatch]
	);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={clearFilters}
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};
