import React from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {useCacheStates} from '../hooks/useCacheStates';

export const GroupsAndRulesCacheStatus = () => {
	const [isGroupsFetching, isGroupsError, isGroupsStale, isGroupsPaused, groupsDataUpdatedAt]
		= useCacheStates(workingGroupsQueryKeys.list());
	const [isRulesFetching, isRulesError, isRulesStale, isRulesPaused, rulesDataUpdatedAt]
		= useCacheStates(responsibilityRulesQueryKeys.list());
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Рабочие группы и правила"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={Math.max(groupsDataUpdatedAt ?? 0, rulesDataUpdatedAt ?? 0)}
			error={
				isGroupsError || isRulesError
					? 'Ошибка'
					: isGroupsStale || isRulesStale
					? 'Данные устарели'
					: undefined
			}
			inProgress={isGroupsPaused || isGroupsFetching || isRulesPaused || isRulesFetching}
		/>
	);
};
