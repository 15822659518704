import React, {useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Toggle} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/actions';
import {startCachingResources} from '@src/core/offlineMode/startCachingResources';

export const OfflineModeSettings = () => {
	const {available} = useAppSelector(s => s.settings.offlineMode);
	const roles = useAppSelector(s => s.auth.profile.roles);
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const dispatch = useAppDispatch();

	const handleChange = useCallback(async () => {
		dispatch(changeOfflineModeAvailability(!available));
		if (!available && objects && objects.length) {
			await startCachingResources(roles, false, objects);
		}
	}, [available, roles, objects]);

	return (
		<div>
			<Toggle
				checked={available}
				onChange={handleChange}
			>
				Возможность автономной работы
			</Toggle>
		</div>
	);
};
