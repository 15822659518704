import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IWorkAcceptanceState, getInitialState} from './reducers';

import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';

const extractWorkAcceptanceData = (state: IState): IWorkAcceptanceState =>
	state.entities.workAcceptance || getInitialState();

/**
 * Возвращает данные приёмки работ
 */
export const extractWorkAcceptance = createSelector(
	[extractWorkAcceptanceData],
	workAcceptance => workAcceptance.data
);

/**
 * Извлекает нарушения приёмки работ
 */
export const extractWorkAcceptanceProblems = createSelector([extractWorkAcceptanceData], data =>
	data.problems.allIds.map(id => data.problems.byId[id]));

export const extractWorkAcceptanceProblemsData = createSelector([extractWorkAcceptanceData], data =>
	data.problems);

/**
 * Извлекает ответы на нарушения приёмки работ
 */
export const extractWorkAcceptanceProblemReplies = createSelector([extractWorkAcceptanceData], data =>
	data.problemReplies.allIds.map(id => data.problemReplies.byId[id]));

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractWorkAcceptanceProblemCommentsByProblem = createSelector(
	[extractWorkAcceptanceProblemReplies],
	replies =>
		replies.reduce<Record<string, IProblemComment>>((prev, item) => {
			if (!prev[item.problemId]) {
				prev[item.problemId] = item;
			}
			return prev;
		}, {})
);

export const extractProblemsAndSubProblemsByWorkAcceptanceIdAsArray = createSelector(
	[extractWorkAcceptanceProblemsData, (state: IState, workAcceptanceId: string) => workAcceptanceId],
	(data, workAcceptanceId) =>
		data.allIds.reduce((acc: IProblem[], id) => {
			const problem = data.byId[id];

			if (problem?.links?.workAcceptanceId === workAcceptanceId) {
				acc.push(problem);
			}

			return acc;
		}, [])
);

/**
 * Извлекает сущности гарантийного обращения
 */
export const extractWorkAcceptanceEntities = createSelector(
	[extractWorkAcceptanceProblemsData],
	problems =>
	problems.allIds.map(id => problems.byId[id])
			.map<IWorkAcceptanceEntity>(item => ({
				id: `p_${item.id}`,
				type: 'problem',
				data: item
			}))
			.sort((a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0))
);

/**
 * Возвращает записи истории делегирования в виде массива
 */
export const extractWorkAcceptanceDelegationHistoriesAsArray = createSelector(
	[extractWorkAcceptanceData],
	workAcceptance => workAcceptance.delegationHistories.allIds.map(id => workAcceptance.delegationHistories.byId[id])
);
