import React, {useMemo} from 'react';
import './ProblemPage.less';
import {useAsync} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getProblem} from '@src/store/modules/pages/problem/actions';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblem} from '@src/store/modules/pages/problem/selectors/problem';
import {useIsDesktop, useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Tablet from './components/Tablet';
import Mobile from './components/Mobile';
import Menu from './components/Menu';
import {getTask} from '@src/store/modules/pages/task/actions';
import {getProblemViews} from '@src/store/modules/entities/problemViews/actions';
import {usePermissions} from './hooks/usePermissions';
import {useAddProblemView} from '@src/core/hooks/queries/problemViews';
import {useProblemSettings} from '../ProblemsPage/utils/useProblemSettings';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {convertProblem} from './utils/convertProblem';

const ProblemPage = () => {
	useScrollRestoration();

	const {objectId, problemId} = useStrictParams<{objectId: string, problemId: string}>();
	const settings = useProblemSettings(objectId);
	const extractedProblem = useAppSelector(extractProblem);
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const problem = useMemo(() => 
		convertProblem(extractedProblem, usersMap),
	[extractedProblem, usersMap]);
	const dispatch = useAppDispatch();
	const permissions = usePermissions(objectId, problem);

	useAppHeader(
		{
			title: problem ? `Нарушение №${problem.number || problem.localNumber}` : '',
			showBackBtn: true,
			mobileRightButtons: problem ? (
				<Menu
					objectId={objectId}
					problem={problem}
					permissions={permissions}
				/>
			) : null
		},
		[problem]
	);

	useAddProblemView(objectId, problemId);
	useAsync(async () => {
		await dispatch(getProblem(objectId, problemId));
		if (permissions.canSeeViews) {
			await dispatch(getProblemViews(problemId));
		}
	});

	useAsync(async () => {
		if (problem?.links?.taskId !== undefined) {
			await dispatch(getTask(problem.links.taskId));
		}
	}, [problem?.links?.taskId]);

	const isTablet = useIsTablet();
	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				problemId={problemId}
				problem={problem}
				permissions={permissions}
				settings={settings}
			/>
		);
	}
	if (isTablet) {
		return (
			<Tablet
				objectId={objectId}
				problemId={problemId}
				problem={problem}
				permissions={permissions}
				settings={settings}
			/>
		);
	}
	return (
		<Mobile
			objectId={objectId}
			problemId={problemId}
			problem={problem}
			permissions={permissions}
			settings={settings}
		/>
	);
};

export default ProblemPage;
