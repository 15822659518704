import React, {memo} from 'react';
import {IDatesOptions} from '../Calendar';
import {useWeekNames} from '../hooks/useWeekNames';

interface IWeekNamesProps {
	datesOptions?: IDatesOptions;
}

const WeekNames = ({datesOptions}: IWeekNamesProps) => {
	const names = useWeekNames(datesOptions);

	return (
		<div className="calendar__week-names">
			{names.map(name => (
				<span
					key={name}
					className="calendar__week-name"
				>
					{name}
				</span>
			))}
		</div>
	);
};

export default memo(WeekNames);
