import * as types from './constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetTasksResponse, requestTasks} from '@src/api/backend/tasks';

export type IGetTasksPayload = IGetTasksResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetTasksResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка задач');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список задач
 */
export const getTasks = () =>
	createApiAction<IGetTasksResponse>(request, success, failure, () => requestTasks());
