import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import IShape from '@tehzor/tools/interfaces/plans/IShape';

const getAllPlans = (state: IState) => state.dictionaries.plans;
const getObjectId = (state: IState, objectId: string) => objectId;
const getObjectIds = (
	state: IState, objectIds: string[] | undefined
): string[] => objectIds || [];
const getPlanId = (state: IState, objectId: string, planId: string | undefined) => planId;
const getShapeId = (state: IState, objectId?: string, planId?: string, shapeId?: string) => shapeId;

/**
 * Извлекает планы для конкретного объекта
 */
export const extractPlans = createSelector(
	[getAllPlans, getObjectId],
	(allPlans, objectId) => allPlans[objectId] || {byId: {}, allIds: []}
);

/**
 * Извлекает планы для конкретного объекта в виде массива
 */
export const extractPlansAsArray = createSelector([extractPlans], plans =>
	plans.allIds.map((id: string) => plans.byId[id])
);

export const extractPlansByObjectIds = createSelector(
	[
		getAllPlans,
		getObjectIds
	],
	(allPlans, objectIds) => {
		const allIds: string[] = [];
		let byId: Record<string, IPlan> = {};

		for (const objectId of objectIds) {
			const plans = allPlans[objectId];
			if (!plans) { continue; }

			allIds.push(...plans.allIds);
			byId = {...byId, ...plans.byId};
		}

		return {allIds, byId};
	}
);

export const extractPlansByObjectIdsAsArray = createSelector(
	[extractPlansByObjectIds],
	({allIds, byId}) => allIds.map(id => byId[id])
);

/**
 * Извлекает план
 */
export const extractPlan = createSelector(
	[extractPlans, getPlanId],
	(data, planId): IPlan | undefined => (planId ? data.byId[planId] : undefined)
);

export const extractShapes = createSelector([extractPlan], (plan): IShape[] | undefined => {
	if (!plan || !plan?.layers) {
		return undefined;
	}
	const layers = Object.values(plan.layers);
	return layers.reduce((acc: IShape[], layer) => {
		if (layer.shapes) {
			acc.push(...layer.shapes);
		}
		return acc;
	}, []);
});

export const extractShape = createSelector(
	[extractShapes, getShapeId],
	(shapes, shapeId): IShape | undefined => shapes?.find(shape => shape.id === shapeId)
);
