import React from 'react';
import DesktopEntityAdding from './EntityAdding.desktop';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import DesktopChecksExport from './ChecksExport.desktop';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';

interface IDesktopActionsProps {
	objectId?: string;
}

const ActionsDesktop = ({objectId}: IDesktopActionsProps) => {
	const permissions = useChecksPermissions(objectId);
	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return (
		<>
			{objectId !== undefined
				&& permissions.canAdd
				&& (problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<DesktopEntityAdding objectId={objectId}/>
				)}
			{permissions.canExport && <DesktopChecksExport objectId={objectId}/>}
		</>
	);
};

export default ActionsDesktop;
