import React, {forwardRef, Ref} from 'react';
import ButtonBase from '../ButtonBase';
import './FilterButton.less';
import classNames from 'classnames';
import IconButton from '../IconButton';
import {convertClassNames} from '../../../utils/convertClassNames';

export interface IFilterButtonProps {
	className?: string | {
		root?: string;
		button?: string;
		clear?: string;
		bntWrap?: string;
		btnLabel?: string;
		btnIcon?: string;
	};
	style?: React.CSSProperties;
	label?: React.ReactNode;
	icon?: React.ReactNode;
	disabled?: boolean;
	active?: boolean;

	onClick?: (event: React.MouseEvent) => void;
	onClear?: (event: React.MouseEvent) => void;
}

const FilterButton = (props: IFilterButtonProps, ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>) => {
	const {className, style, label, icon, disabled, onClick, onClear, active} = props;

	const classes = convertClassNames(className);

	return (
		<div
			className={classNames('filter-button-root', classes.root)}
			style={style}
		>
			<ButtonBase
				className={classNames(classes.button, {
					'filter-button_active': active && !disabled,
					'filter-button_with-clearing': onClear && !disabled,
					'filter-button_with-icon': icon
				})}
				wrapClassName={classes.bntWrap}
				labelClassName={classes.btnLabel}
				rightIconClassName={classes.btnIcon}
				label={label}
				rightIcon={icon}
				disabled={disabled}
				classNamePrefix="filter-button"
				outerTagType="button"
				onClick={onClick}
				ref={ref}
			/>
			{active && onClear && !disabled ? (
				<IconButton
					className={classNames('filter-button-clear', classes.clear)}
					onClick={onClear}
				>
					<i className="tz-close-16"/>
				</IconButton>
			) : null}
		</div>
	);
};

FilterButton.displayName = 'FilterButton';

export default forwardRef(FilterButton);
