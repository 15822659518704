import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {requestLastProblemReply, IGetLastProblemReplyResponse} from '@src/api/backend/problemReply';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

export interface IGetLastProblemReplyPayload {
	problemId: string;
	reply: IProblemComment | undefined;
}

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetLastProblemReplyResponse, problemId: string) => ({
	type: types.GET_SUCCESS,
	payload: {
		problemId,
		reply: response
	} as IGetLastProblemReplyPayload
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при получении ответа');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Возвращает последний ответ на нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const getLastProblemReply
	= (objectId: string, problemId: string): AppThunkAction<Promise<IGetLastProblemReplyResponse>> =>
	(dispatch, getState) => {
		const data = getState().entities.problemsLastReplies;
		if (data.hasOwnProperty(problemId)) {
			return Promise.resolve(data[problemId]);
		}
		return dispatch(
			createApiAction<IGetLastProblemReplyResponse>(
				request,
				res => success(res, problemId),
				failure,
				() => requestLastProblemReply(objectId, problemId)
			)
		);
	};
