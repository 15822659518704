import {makeOwnerAcptAddRequest} from '@src/api/backend/ownerAcceptance';
import {
	addOwnerAcceptanceActions,
	deleteOwnerAcceptanceActions
} from '@src/store/modules/entities/ownerAcceptance/actions';
import {useQueryClient} from '@tanstack/react-query';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {ISavingOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ISavingOwnerAcceptance';
import {useEffect} from 'react';
import {useAddChildren} from '../../hooks/useAddChildren';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {addTempFiles} from '../../utils/addTempFiles';
import {ownerAcceptancesQueryKeys} from '../keys';
import {updateCachedState} from '@src/utils/updateCachedState';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';

export interface IAddOwnerAcceptanceParams {
	key: string;
	objectId: string;
	links: IOwnerAcceptance['links'] | undefined;
	fields: ISavingOwnerAcceptance;
}
/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useOwnerAcceptancesMutationDefaults = () => {
	const queryClient = useQueryClient();
	const [addProblems] = useAddChildren();
	const {updateEntity} = useUpdateEntity(ownerAcceptancesQueryKeys);
	useEffect(() => {
		queryClient.setMutationDefaults(ownerAcceptancesQueryKeys.add(), {
			mutationFn: async (params: IAddOwnerAcceptanceParams) => {
				const {objectId, links, fields, key} = params;
				await updateCachedState<IOwnerAcceptance>(
					ownerAcceptancesQueryKeys,
					key,
					OfflineDataTransferStatus.TRANSFER
				);
				return makeOwnerAcptAddRequest(objectId, links, {
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments)
				});
			},
			onSuccess: async (
				newOwnerAcceptance: IOwnerAcceptance,
				{key}: IAddOwnerAcceptanceParams
			) => {
				await updateCachedState<IOwnerAcceptance>(
					ownerAcceptancesQueryKeys,
					key,
					OfflineDataTransferStatus.TRANSFER_COMPLETE
				);
				addProblems(key, newOwnerAcceptance.id, 'ownerAcceptanceId');
				await queryClient.invalidateQueries(ownerAcceptancesQueryKeys.list());
				updateEntity<IOwnerAcceptance>(
					newOwnerAcceptance,
					deleteOwnerAcceptanceActions.success,
					addOwnerAcceptanceActions.success,
					key
				);
				await queryClient.refetchQueries({
					queryKey: ownerAcceptancesQueryKeys.localList()
				});
				await queryClient.invalidateQueries({
					queryKey: ownerAcceptancesQueryKeys.latest()
				});
			},
			onError: async (_, {key}: IAddOwnerAcceptanceParams) => {
				await updateCachedState<IOwnerAcceptance>(
					ownerAcceptancesQueryKeys,
					key,
					OfflineDataTransferStatus.TRANSFER_ERROR
				);
			}
		});
	}, []);
};
