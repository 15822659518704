import {wsConnector} from '../wsConnector';
import {ISavingCheck} from '@tehzor/tools/interfaces/checks/ISavingCheck';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';

export type IEditCheckResponse = ICheck;

/**
 * Изменяет проверку
 *
 * @param objectId id объекта
 * @param checkId id проверки
 * @param fields данные проверки
 */
export const makeCheckEditRequest = (
	objectId: string,
	checkId: string,
	fields: ISavingCheck
) =>
	wsConnector.sendAuthorizedRequest<IEditCheckResponse>('editCheck', {
		objectId,
		checkId,
		...fields
	});
