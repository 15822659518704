import React, {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {exportChecks} from '@src/store/modules/entities/checks/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractChecksData} from '@src/store/modules/entities/checks/selectors';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import useToggle from 'react-use/lib/useToggle';

const availableDestinations = [
	ExportTemplatesDestinationId.CHECKS,
	ExportTemplatesDestinationId.CHECKS_PROBLEMS
];

export function useChecksExport(objectId = 'all'): [React.ReactNode, () => void] {
	const {selectedRows} = useAppSelector(s => extractChecksData(s, objectId));
	const {filters, sort} = useAppSelector(s => extractChecksPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const [isOpen, toggleOpen] = useToggle(false);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await dispatch(
				exportChecks(
					template.id,
					createDocument,
					email,
					objectId !== 'all' ? objectId : undefined,
					filters,
					sort,
					selectedRows
				)
			);
			toggleOpen(false);
		},
		[filters, sort, selectedRows]
	);

	const dialog = (
		<ExportDialog
			pageKey="checks"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={toggleOpen}
		/>
	);

	return [dialog, toggleOpen];
}
