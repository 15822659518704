import React, {memo} from 'react';
import {IEditableContractState} from '@src/core/hooks/states/useEditableContractState';
import {DateContractField} from '../fields/DateContractField';

interface IDatesBlockProps {
	editingState: IEditableContractState;

	onChange: (
		value: string | number | string[] | null | undefined,
		field: keyof IEditableContractState
	) => void;
	onError: (field: keyof IEditableContractState) => void;
}

export const DatesBlock = memo(({
	editingState, onChange, onError
}: IDatesBlockProps) => (
	<div className="editable-contract__blocks-dates">
		<div className="editable-contract__blocks-dates-title">
			Даты
		</div>
		<div className="editable-contract__blocks-dates-container">
			<DateContractField
				label="Заключение договора"
				dialogTitle="Заключение договора"
				errorMessage="Укажите заключение договора"
				hasError={editingState.errors.signingDate}
				value={editingState.signingDate}
				onChange={value => onChange(value, 'signingDate')}
				onError={() => onError('signingDate')}
			/>

			<DateContractField
				label="Начало работ"
				dialogTitle="Начало работ"
				errorMessage="Укажите начало работ"
				hasError={editingState.errors.startDate}
				value={editingState.startDate}
				onChange={value => onChange(value, 'startDate')}
				onError={() => onError('startDate')}
			/>
			<DateContractField
				label="Окончание работ"
				dialogTitle="Окончание работ"
				errorMessage="Укажите окончание работ"
				value={editingState.endDate}
				hasError={editingState.errors.endDate}
				onChange={value => onChange(value, 'endDate')}
				onError={() => onError('endDate')}
			/>
		</div>
	</div>
));