import React from 'react';
import './NoData.less';
import {VerticalTabContent} from '@tehzor/ui-components';

interface INoDataProps {
	asTabContent?: boolean;
}

const NoData = ({asTabContent}: INoDataProps) => {
	if (asTabContent) {
		return (
			<VerticalTabContent className="statistics-page__tabs-content-panel stats-no-data__tab-content">
				<div className="stats-no-data">Нет данных за выбранный период</div>
			</VerticalTabContent>
		);
	}
	return <div className="stats-no-data">Нет данных за выбранный период</div>;
};

export default NoData;
