import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';
import * as types from '@src/store/modules/settings/pages/tasks/constants/index';
import {ChangeScheduleValueMode,
	ITasksFiltersState, ITasksSettingsMode,
	ITasksSortState,
	TasksScheduleCollectMode} from '@src/store/modules/settings/pages/tasks/interfaces';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';

/**
 * Изменяет способ отображения страницы задач
 *
 * @param displayMode способ отображения
 */
export const changeDisplayMode = (displayMode: TasksDisplayMode) => ({
	type: types.general.CHANGE_DISPLAY_MODE,
	payload: {
		displayMode
	}
});

/**
 * Изменяет фильтр для реестра задач
 *
 * @param filters свойства фильтра
 */
export const changeFilters = (filters: ITasksFiltersState) => ({
	type: types.list.CHANGE_FILTERS,
	payload: {
		filters
	} as IChangeFiltersPayload<ITasksFiltersState>
});

/**
 * Очищает фильтр для реестра задач
 *
 */
export const clearFilters = () => ({
	type: types.list.CLEAR_FILTERS,
	payload: {}
});

/**
 * Изменяет фильтр в графике задач
 *
 * @param filters свойства фильтра
 */
export const changeScheduleFilters = (filters: ITasksFiltersState) => ({
	type: types.schedule.CHANGE_FILTERS,
	payload: {
		filters
	} as IChangeFiltersPayload<ITasksFiltersState>
});

/**
 * Очищает фильтр в графике задач
 *
 */
export const clearScheduleFilters = () => ({
	type: types.schedule.CLEAR_FILTERS,
	payload: {}
});

/**
 * Меняет вид таблицы в графике задач (год, месяц)
 *
 */
export const changeScheduleMode = (mode: ITasksSettingsMode) => ({
	type: types.schedule.CHANGE_SETTINGS_CURRENT_MODE,
	payload: {mode}
});

/**
 * Меняет вид таблицы в графике задач (текущая дата)
 *
 */

export const changeScheduleValue = (mode: ChangeScheduleValueMode) => ({
	type: types.schedule.CHANGE_SETTINGS_VALUE,
	payload: {mode}
});

/**
 * Изменяет сортировку в реестре задач
 *
 * @param sort параметры сортировки
 */
export const changeSort = (sort: ITasksSortState) => ({
	type: types.list.CHANGE_SORT,
	payload: {
		sort
	} as IChangeSortPayload<ITasksSortState>
});

/**
 * Изменяет количество элементов на странице реестра задач
 *
 * @param pageSize количество элементов на странице
 */
export const changePageSize = (pageSize: number) => ({
	type: types.list.CHANGE_PAGE_SIZE,
	payload: {
		pageSize
	} as IChangePageSizePayload
});

/**
 * Изменяет смещение списка
 *
 * @param offset смещение
 */
export const changeOffset = (offset: number) => ({
	type: types.list.CHANGE_OFFSET,
	payload: {
		offset
	}
});

/**
 * Изменяет выбранные строки в таблице
 *
 * @param selectedRows массив id выбранных строк
 */
export const changeSelectedRows = (selectedRows: string[]) => ({
	type: types.list.CHANGE_SELECTED_ROWS,
	payload: {
		selectedRows
	}
});

/**
 * Изменяет вид графика задач (рабочие группы или объекты)
 *
 * @param collectMode способ отображения
 */
export const changeCollectMode = (collectMode: TasksScheduleCollectMode) => ({
	type: types.schedule.CHANGE_COLLECT_MODE,
	payload: {
		collectMode
	}
});