import {Container, Element, G} from '@svgdotjs/svg.js';
import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import IShape from '@tehzor/tools/interfaces/plans/IShape';
import {ISelectableSector} from './SelectableHandler';

export interface IHandlerShape {
	element: Element;
}

/**
 * Общий класс для работы со слоями просмотрщика планов
 */
abstract class AbstractHandler<T> {
	// SVG.js объект
	protected _svg: Container;

	// SVG группа слоя
	protected _group: G;

	// Массив фигур
	protected _shapes: Array<IHandlerShape & IShape & T> = [];

	//
	protected _visible = true;

	// Активность перемещения плана
	protected _isPlanMovingActive = false;

	// Активность перемещения плана
	protected _sectorsData: Array<ILocationMarker & ISelectableSector>;

	// Функция для оповещения при изменении состояния
	protected _emitFunc?: (shapes: T[]) => void;

	// Переопределяемая функция, которая будет передавать в _emitFunc необходимые данные
	protected abstract _emit: () => void;

	/**
	 * Очищает ресурсы, удаляет слой
	 */
	destroy() {
		this._group.remove();
	}

	/**
	 * Переключает видимость слоя
	 *
	 * @param visible видимость слоя
	 */
	toggleVisible(visible: boolean) {
		if (this._visible !== visible) {
			this._visible = visible;
			this._group.css('display', visible ? 'block' : 'none');
		}
	}

	/**
	 * Переключает фдаг активности перемещения плана
	 *
	 * @param active флаг
	 */
	togglePlanMoving(active: boolean) {
		this._isPlanMovingActive = active;
	}

	/**
	 * Устанавливает функцию для оповещения при изменении состояния.
	 *
	 * @param emit функция
	 */
	protected _setEmitFunction = (emit: (shapes: []) => void) => {
		if (emit) {
			this._emitFunc = emit;
		}
	};
}

export default AbstractHandler;
