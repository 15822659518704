import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {LinkButton, Plate} from '@tehzor/ui-components';
import React from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface ISpaceTasksDesktopProps {
	tasks: ITask[];
}
const externalLinkIcon = <i className="tz-external-link-16" />;

export const SpaceTasksLinksDesktop = (props: ISpaceTasksDesktopProps) => {
	const {tasks} = props;
	const {pushPath} = useChangePath();
	return (
		<Plate className={{content: 'space-page__info-plate'}}>
			<div className="space-page__info-title">Ссылки</div>
			<div className="space-page__tasks">
				{tasks.map(task => (
					<LinkButton
						type="filled"
						className="space-page__tasks-link"
						leftIcon={externalLinkIcon}
						onClick={() => pushPath(`/tasks/${task.id}`)}
						label={task.name}
					/>
				))}
			</div>
		</Plate>
	);
};
