import React from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IPreparedSpaceStatusesSet} from '@src/pages/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';

export const ObjectsCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) => {
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);

	const isTablet = useIsTablet();
	const numberOfShownObjects = isTablet ? 3 : 2;

	const objects = row.original.objects || [];
	const shownObjects = objects.filter((objectId, i) => (i + 1) <= numberOfShownObjects);
	const numberOfRemainingObjects = objects.length > numberOfShownObjects
		? objects.length - numberOfShownObjects
		: undefined;

	return (
		<div className="space-statuses-sets__cell-objects">
			{shownObjects.map(objectId => (
				<div
					className="space-statuses-sets__cell-objects-text"
					key={objectId}
				>
					{objectsMap[objectId]?.name}
					<br/>
				</div>
			))}
			{numberOfRemainingObjects && (
				<div className="space-statuses-sets__cell-objects-number">
					+
					{numberOfRemainingObjects}
				</div>
			)}
		</div>
	);
};