import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetWorkAcceptancesResponse, requestWorkAcceptances} from '@src/api/backend/workAcceptances';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';

export interface IGetWorkAcceptancesPayload extends IGetWorkAcceptancesResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetWorkAcceptancesResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка приёмок работ');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список приёмок работ
 */
export const getWorkAcceptances
	= (
		objectId: string,
		state?: IWorkAcceptancesFiltersState
	): AppThunkAction<Promise<IGetWorkAcceptancesResponse>> =>
	(dispatch, getState) =>
		dispatch(
			checkExpiration<IState, IGetWorkAcceptancesResponse, ApiAction>(
				s => s.entities.workAcceptances[objectId],
				createApiAction<IGetWorkAcceptancesResponse>(
					() => request(objectId),
					(res: IGetWorkAcceptancesResponse) => success(objectId, res),
					failure,
					() => {
						const s = getState();
						const {offset} = s.entities.workAcceptances[objectId];
						const {filters, sort, pageSize} = s.settings.pages.workAcceptances.byPage[objectId];

						return requestWorkAcceptances(state
							|| filters,
								sort,
								offset,
								pageSize);
					}
				)
			)
		);
