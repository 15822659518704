import React from 'react';
import './WarrantyClaimInfoDesktopCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import WarrantyClaimInfo from '../WarrantyClaimInfo';

const WarrantyClaimInfoDesktopCell = (props: CellProps<{data: IWarrantyClaim}>) => {
	const {row} = props;
	const {data} = row.original;

	return (
		<div className="d-wclaim-info-cell">
			<ClickPreventWrap>
				<TableRowSelect
					{...row.getToggleRowSelectedProps()}
					style={{marginTop: '12px'}}
				/>
			</ClickPreventWrap>

			<div className="d-wclaim-info-cell__info">
				<WarrantyClaimInfo
					className="d-wclaim-info-cell__title"
					data={data}
				/>

				{data.spaceOwner !== undefined
					&& (data.claimerName === '' || data.claimerName === undefined) && (
						<div className="d-wclaim-info-cell__extra-info">{data.spaceOwner.name}</div>
					)}

				{data.claimerName !== undefined && (
					<div className="d-wclaim-info-cell__extra-info">{data.claimerName}</div>
				)}

				{data.replyEmail !== undefined && (
					<ClickPreventWrap className="d-wclaim-info-cell__extra-info">
						<a href={`mailto:${data.replyEmail}`}>{data.replyEmail}</a>
					</ClickPreventWrap>
				)}
				{data.replyPhone !== undefined && (
					<ClickPreventWrap className="d-wclaim-info-cell__extra-info">
						<a href={`tel:${data.replyPhone}`}>{data.replyPhone}</a>
					</ClickPreventWrap>
				)}
			</div>
		</div>
	);
};

export default WarrantyClaimInfoDesktopCell;
