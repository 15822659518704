import React, {useCallback, useEffect, useState} from 'react';
import './EditableProblemReply.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	convertToSave,
	isEdited,
	useEditableProblemCommentState,
	hasErrors,
	hasAttachmentsError
} from '@src/core/hooks/states/useEditableProblemCommentState';
import {
	convertToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {addProblemReply, editProblemReply} from '@src/store/modules/entities/problemReply/actions';
import Description from '@src/components/editableFields/Description';
import Attachments from '@src/components/editableFields/Attachments';
import {useIsDesktopHeight} from '@tehzor/ui-components/src/utils/mediaQueries';
import {makeTitle} from './utils/makeTitle';
import StatusTransition from './components/StatusTransition';
import {useFieldsSettings} from '@src/core/hooks/useFieldsSettings';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {getProblem} from '@src/store/modules/pages/problem/actions';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

interface IEditableProblemReplyProps {
	objectId: string;
	problemId: string;
	commentId?: string;
	links?: IProblemComment['links'];
	isOpen: boolean;

	onClose: () => void;
	onAfterClose: () => void;
}

/**
 * Окно для добавления/редактирования ответа на нарушение
 */
const EditableProblemReply = (props: IEditableProblemReplyProps) => {
	const {objectId, problemId, commentId, links, isOpen, onClose, onAfterClose} = props;

	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const comment = useAppSelector(s =>
		(commentId ? s.pages.problem.comments.byId[commentId] : undefined));
	const fieldsSettings = useFieldsSettings(objectId, 'problemReply');
	const dispatch = useAppDispatch();

	const [editingState, editingDispatch] = useEditableProblemCommentState(comment);
	const [uploadingFiles, upFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, comment) || isFilesExist(uploadingFiles.value));
	}, [editingState, uploadingFiles.value, comment]);

	useUpdateEffect(() => {
		editingDispatch({type: 'reset', entity: comment});
		upFilesDispatch({type: 'reset'});
	}, [comment]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	const handleAfterClose = useCallback(() => {
		editingDispatch({type: 'reset'});
		upFilesDispatch({type: 'reset'});
		if (onAfterClose) {
			onAfterClose();
		}
	}, [onAfterClose]);

	const [savingState, save] = useAsyncFn(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, fieldsSettings)
			|| (hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			upFilesDispatch({type: 'update-error'});
			return;
		}
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, comment)
			&& (!isFilesExist(files) || someFilesHaveError(files))
		) {
			return;
		}

		if (!networkStatus) {
			// Локальное сохранение
			const savingData = convertToSave(editingState, comment, true);
			savingData.newAttachments = convertToLocalSave(files);
			// TODO Локальное сохранение
		} else {
			const savingData = convertToSave(editingState, comment, true);
			savingData.newAttachments = convertFilesToSave(files);

			try {
				if (commentId === undefined) {
					await dispatch(addProblemReply(objectId, problemId, links, savingData));
					await dispatch(getProblem(objectId, problemId));
				} else {
					await dispatch(editProblemReply(objectId, commentId, savingData));
				}

				onClose();
			} catch (error) {
				console.log(error);
				// TODO Локальное сохранение
			}
		}
	}, [editingState, commentId, networkStatus, objectId, problemId, links, fieldsSettings]);

	const isDesktopHeight = useIsDesktopHeight();

	return (
		<Dialog
			className="editable-problem-reply"
			isOpen={isOpen}
			title={makeTitle(commentId, comment)}
			footer={(
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={savingState.loading}
						onClick={save}
					/>
					<Button
						type="cancel"
						label="Отменить"
						disabled={savingState.loading}
						onClick={handleClose}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={handleAfterClose}
		>
			<StatusTransition
				objectId={objectId}
				commentId={commentId}
			/>

			{fieldsSettings.description !== undefined && (
				<Description
					className="editable-problem-reply__description"
					value={editingState.description}
					editingDispatch={editingDispatch}
					required={fieldsSettings.description.isRequired}
					disabled={savingState.loading}
					hasError={editingState.errors.description}
					textAreaProps={
						isDesktopHeight ? {minRows: 3, maxRows: 7} : {minRows: 3, maxRows: 5}
					}
				/>
			)}

			{fieldsSettings.attachments !== undefined && (
				<Attachments
					className={{
						root: 'editable-problem-reply__attachments',
						scrollArea: 'editable-problem-reply__attachments-scroll-area',
						files: 'editable-problem-reply__attachments-files',
						file: 'editable-problem-reply__attachments-file'
					}}
					imagesTitle="Фото ответа"
					attachmentsDestination={AttachmentFileDestination.COMMENT_OR_REPLY}
					entityId={commentId}
					attachments={editingState.attachments}
					uploadingFiles={uploadingFiles.value}
					editingDispatch={editingDispatch}
					uploadingFilesDispatch={upFilesDispatch}
					required={fieldsSettings.attachments.isRequired}
					disabled={savingState.loading}
					canDraw
					hasError={editingState.errors.attachments && uploadingFiles.error}
					showAttachBtn
				/>
			)}

			{Object.keys(fieldsSettings).length === 0 && (
				<div className="editable-problem-comment__empty-fields-msg">Все поля отключены</div>
			)}

			{closingDialog}
		</Dialog>
	);
};

export default EditableProblemReply;
