import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetCheckListsResponse} from '@src/api/backend/checkLists';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {queryClient} from '@src/api/QueryClient';

export type IGetCheckListsPayload = IGetCheckListsResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetCheckListsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка чек-листов');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список чек-листов
 */
export const getCheckLists = () =>
	createApiAction<IGetCheckListsResponse>(request, success, failure, () =>
		queryClient.fetchQuery(checkListsQueryKeys.list(), {staleTime: Infinity}));
