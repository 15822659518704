import {wsConnector} from '../wsConnector';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export type IBindSpaceToOwnersResponse = ISpaceOwner[];

/**
 * Привязывает собственников к помещению
 *
 * @param owners id собственников
 * @param spaceId id помещения
 */
export const makeBindSpaceToOwnersRequest = (owners: string[], spaceId: string) =>
	wsConnector.sendAuthorizedRequest<IBindSpaceToOwnersResponse>('bindSpaceToOwners', {
		owners,
		spaceId
	});
