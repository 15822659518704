import {useQuery} from '@tanstack/react-query';
import {bankAccountsQueryKeys} from '@src/api/cache/bankAccounts/keys';
import {addErrorToast} from '@src/utils/toasts';
import {IGetBankAccountsResponse} from '@src/api/backend/bankAccounts/get';
import IError from '@tehzor/tools/interfaces/IError';
import {useMemo} from 'react';

export const useBankAccounts = () => {
	const {data} = useQuery<IGetBankAccountsResponse, IError>({
		queryKey: [...bankAccountsQueryKeys.list()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке реквизитов');
		}
	});
	return data;
};

export const useAllBankAccountsArray = () => {
	const bankAccounts = useBankAccounts();
	return useMemo(() => bankAccounts?.allIds?.map(id => bankAccounts.byId[id]) || [], [bankAccounts])
};

export const useBankAccountsArray = (legalId?: string, bankAccounts?: IGetBankAccountsResponse) => {
	if (!bankAccounts?.allIds?.length) {
		return [];
	}

	return bankAccounts.allIds
		.map(id => bankAccounts.byId[id])
		.filter(el => el.legalEntityId === legalId);
};
	

export const useBankAccountsMap = () => {
	const bankAccounts = useBankAccounts();
	return useMemo(() => bankAccounts?.byId || {}, [bankAccounts]);
}

export const useFilteredBankAccounts = (
	companyId?: string, legalEntityId?: string
) => {
	const bankAccountsArray = useAllBankAccountsArray();

	return useMemo(() => {
		if (!companyId || !legalEntityId) { return []; }
		return bankAccountsArray.filter(item =>
			item.legalEntityId === legalEntityId && item.companyId === companyId);
	}, [bankAccountsArray, companyId, legalEntityId]);
}
