import React, {useCallback} from 'react';
import {LinkButton, Plate, ProblemComment} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {formProblemCommentsLink} from '@tehzor/tools/utils/links';
import {ICommentReferenceState} from '@src/components/ProblemComments/hooks/useCommentReference';
import {getLastProblemReply} from '@src/store/modules/entities/problemsLastReplies/actions';
import {extractProblemLastReply} from '@src/store/modules/entities/problemsLastReplies/selectors';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMobileLastCommentProps {
	objectId: string;
	problemId: string;

	onImageClick?: (i: number) => void;
}

const MobileLastReply = ({objectId, problemId, onImageClick}: IMobileLastCommentProps) => {
	const {data: problem} = useAppSelector(s => s.pages.problem);
	const {pushPath} = useChangePath();
	const reply = useAppSelector(s => extractProblemLastReply(s, problemId));
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		if (online && !problem?.transferStatus) {
			await dispatch(getLastProblemReply(objectId, problemId));
		}
	}, [online]);

	const goToComments = useCallback(() => {
		pushPath(formProblemCommentsLink(objectId, problemId));
	}, [objectId, problemId]);

	const goToCommentsWithAdding = useCallback(
		(id: string) => {
			pushPath(formProblemCommentsLink(objectId, problemId), {
				state: {
					reference: {
						type: 'add',
						id
					} as ICommentReferenceState
				}
			});
		},
		[objectId, problemId]
	);

	const handleImageClick = useCallback(
		(id: string, index: number) => {
			if (onImageClick) {
				onImageClick(index);
			}
		},
		[onImageClick]
	);

	return problem?.id === problemId && reply ? (
		<Plate
			className="problem-page__m-plate"
			title={reply.number !== undefined ? `Ответ №${reply.number}` : undefined}
			headerExtra={(
				<LinkButton
					label="Все ответы"
					onClick={goToComments}
				/>
			)}
		>
			<ProblemComment
				className={{
					root: 'problem-page__last-reply',
					plate: 'problem-page__last-reply-plate'
				}}
				data={reply}
				hideTitle
				onSubCommentAdd={goToCommentsWithAdding}
				onImageClick={handleImageClick}
			/>
		</Plate>
	) : null;
};

export default MobileLastReply;
