import React from 'react';
import './WarrantyClaimsPage.less';
import classNames from 'classnames';
import useAsync from 'react-use/lib/useAsync';
import {LoadingPanel} from '@tehzor/ui-components';
import ClaimsPageCounter from './components/ClaimsPageHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getWarrantyClaims} from '@src/store/modules/entities/warrantyClaims/actions';
import ClaimsFilters from './components/filters/ClaimsFilters';
import ClaimsTable from './components/table/ClaimsTable';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import {useParams} from 'react-router-dom';

const WarrantyClaimsPage = ({className}: {className?: string}) => {
	useScrollRestoration();

	const {objectId} = useParams<{objectId?: string}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {state} = useEntitiesFiltersCtx();

	const loadingState = useAsync(async () => {
		await dispatch(getWarrantyClaims(objectId || 'all', state));
	}, [objectId, state]);

	return (
		<LoadingPanel
			className="w-claims-page__loading-panel"
			active={loadingState.loading}
		>
			<div
				className={classNames(
					'page-cont',
					'w-claims-page',
					{'w-claims-page_small-margin': !objectId},
					className
				)}
			>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}/>
				) : (
					isDesktop && <AllPageBreadcrumbs/>
				)}
				<div className="w-claims-page__filters-bar">
					<ClaimsFilters objectId={objectId}/>
				</div>
				<ClaimsPageCounter objectId={objectId}/>
				<ClaimsTable objectId={objectId}/>
			</div>
		</LoadingPanel>
	);
};

export default WarrantyClaimsPage;
