import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetProblemsResponse extends INormalizedData<IListProblem> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список нарушений
 */
export async function requestProblems(
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	return wsConnector.sendAuthorizedRequest<IGetProblemsResponse>('getProblems', params, {
		timeout: 300
	});
}
