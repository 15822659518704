import {IGetChecksResponse, requestChecks} from '@src/api/backend/checks';
import {getChecksActions} from '@src/store/modules/entities/checks/actions';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/reducers';
import {useQuery} from '@tanstack/react-query';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import IError from '@tehzor/tools/interfaces/IError';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useMemo} from 'react';

export const useGetChecksQuery = <T>(
	objectId: string,
	state?: IChecksFiltersState,
	select?: (data: IGetChecksResponse) => T
) => {
	const entities = useAppSelector(s => s.entities);
	const settings = useAppSelector(s => s.settings);
	const dispatch = useAppDispatch();
	const offset = entities?.checks[objectId]?.offset || 0;
	const {filters, sort, pageSize} = settings?.pages.checks[objectId];
	return useQuery<IGetChecksResponse, IError, T>({
		queryKey: [...checksQueryKeys.list(), state || filters, sort, offset, pageSize],
		queryFn: () => {
			dispatch(getChecksActions.request(objectId));
			return requestChecks(state || filters, sort, offset, pageSize);
		},
		onError: (error: IError) => {
			dispatch(getChecksActions.failure(error));
		},
		select,
		enabled: !!entities && !!settings
	});
};

export const useChecks = (
	objectId: string,
	state?: IChecksFiltersState
): IListCheck[] | undefined =>
	useGetChecksQuery(objectId, state, (data): IListCheck[] => data.allIds.map(id => data.byId[id]))
		?.data;

export const useChecksData = (
	objectId: string,
	state?: IChecksFiltersState
): IGetChecksResponse | undefined => useGetChecksQuery<IGetChecksResponse>(objectId, state)?.data;

export const useLocalChecks = (objectId = 'all') => {
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const {data} = useQuery<IListCheck[], IError>({
		queryKey: [...checksQueryKeys.localList(), objectId],
		staleTime: Infinity,
		cacheTime: Infinity,
		networkMode: 'always',
		enabled: isOfflineModeAvailable
	});
	return useMemo(() => data || [], [data]);
};
