import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const extractAllIds = (state: IState) => state.dictionaries.structureTypes.allIds || [];
const extractById = (state: IState) => state.dictionaries.structureTypes.byId || {};

/**
 * Возвращает типы структур в виде массива
 */
export const extractStructureTypesAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);