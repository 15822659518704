import React from 'react';
import {EntityGridItem, StatusSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editProblemStatus} from '@src/store/modules/pages/problem/actions';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useProblemStatusPermissions} from '@src/core/hooks/permissions/useProblemStatusPermissions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IPopupBasicProps} from '@tehzor/ui-components/src/components/containers/Popup';
import {getObjectStats} from '@src/store/modules/dictionaries/objects/actions';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';

const popupProps: IPopupBasicProps = {placement: 'bottom-end', preferX: 'left'};

interface IStatusProps {
	objectId: string;
	problem: IPreparedProblem;
}

const Status = ({objectId, problem}: IStatusProps) => {
	const statuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const dispatch = useAppDispatch();
	const permissions = useProblemStatusPermissions(objectId, problem);

	const [, handleChange] = useAsyncFn(
		async (v: ProblemStatusId) => {
			await dispatch(editProblemStatus(objectId, problem.id, v));
			await dispatch(getObjectStats([objectId]));
		},
		[objectId, problem.id]
	);

	return (
		<EntityGridItem
			className="problem-page__status"
			icon={<i className="tz-status-20"/>}
			label="Статус"
			inline
			fullRow
		>
			<StatusSelect
				statuses={statuses}
				availableStatuses={permissions.availableStatuses}
				disabled={!permissions.canEditStatus}
				value={problem.status}
				popupProps={popupProps}
				onChange={handleChange}
			/>
		</EntityGridItem>
	);
};

export default Status;
