import React from 'react';
import {MessageReference} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import classNames from 'classnames';

interface ICommentReferenceProps {
	className?: string;
	commentId: string;
	type: 'add' | 'edit';

	onClearReference?: () => void;
}

const CommentReference = ({
	className,
	commentId,
	type,
	onClearReference
}: ICommentReferenceProps) => {
	const comment = useAppSelector(s => s.pages.problem.comments.byId[commentId]);

	return comment ? (
		<MessageReference
			className={classNames('problem-comments__reference', className)}
			title={type === 'add' ? 'Добавление комментария' : 'Редактирование комментария'}
			description={comment.description}
			onDelete={onClearReference}
		/>
	) : null;
};

export default CommentReference;
