import React from 'react';
import './Dates.less';
import {EntityGridItem} from '@tehzor/ui-components';
import {IDateProps, SingleDate} from './components/SingleDate';

interface IDatesProps {
	dates?: IDateProps[];
}

export const Dates = ({dates}: IDatesProps) =>
	(dates ? (
		<EntityGridItem
			className="fix-date-view-field"
			icon={<i className="tz-calendar-20"/>}
			label="Срок гарантии"
			fullRow
		>
			{dates.map((date, index) => (
				<SingleDate
					key={index}
					value={date.value}
					label={date.label}
				/>
			))}
		</EntityGridItem>
	) : null);
