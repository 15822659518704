import React from 'react';
import {UserAvatar} from '../../../various';
import {format} from 'date-fns';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

interface IUserActionInfoProps {
	createdBy?: IBriefUser;
	createdAt?: number;
}

export const UserActionInfo = (props: IUserActionInfoProps) => {
	const {createdBy, createdAt} = props;

	return (
		<div className="delegation-history-item__info">
			<UserAvatar
				className="delegation-history-item__info-avatar"
				user={createdBy}
				size="34"
				coloring="text"
			/>
			<div className="delegation-history-item__info-text">
				<div className="delegation-history-item__info-author">
					{createdBy?.displayName}
				</div>
				<div className="delegation-history-item__info-action">
					назначил(а) ответственного
					{createdAt !== undefined ? (
						<>
							{' в '}
							{format(createdAt, 'HH:mm')}
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};