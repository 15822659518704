import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetDelegationHistoriesByWorkAcceptanceResponse,
	requestDelegationHistoriesByWorkAcceptance
} from '@src/api/backend/delegationHistories';
import {IState} from '@src/store/modules';
import checkLoaded from '@tehzor/tools/core/checkLoaded';

export type IGetDelegationHistoriesByWorkAcceptancePayload = IGetDelegationHistoriesByWorkAcceptanceResponse;

const request = () => ({type: types.GET_DELEGATION_HISTORIES_REQUEST});

const success = (response: IGetDelegationHistoriesByWorkAcceptanceResponse) => ({
	type: types.GET_DELEGATION_HISTORIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке истории делегирования');
	return {
		type: types.GET_DELEGATION_HISTORIES_FAILURE,
		payload: error
	};
};

/**
 * Получает записи из истории делегирования приемки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приемки работ
 */
export const getDelegationHistoriesByWorkAcceptance = (objectId: string, workAcceptanceId: string) =>
	checkLoaded<IState, IGetDelegationHistoriesByWorkAcceptanceResponse, ApiAction>(
		s => s.pages.problem.delegationHistories,
		createApiAction<IGetDelegationHistoriesByWorkAcceptanceResponse>(request, success, failure, () =>
			requestDelegationHistoriesByWorkAcceptance(objectId, workAcceptanceId))
	);
