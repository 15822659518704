import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import * as types from '../constants';
import {IGenerateSpacesResponse, makeGenerateSpacesRequest} from '@src/api/backend/spaces';

export type IGenerateSpacesPayload = IGenerateSpacesResponse;

const request = () => ({
	type: types.GENERATE_REQUEST
});

const success = (response: IGenerateSpacesPayload) => {
	addSuccessToast('Успех', 'Помещения были успешно сгенерированы');
	return {
		type: types.GENERATE_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при генерации помещений');
	return {
		type: types.GENERATE_FAILURE,
		payload: error
	};
};

/**
 * Генерирует помещения из планов
 *
 * @param objectId id объекта
 * @param plans массив выбранных планов, по которым будут генерироваться помещения
 */
export const generateSpaces = (objectId: string, plans?: string[]) =>
	createApiAction<IGenerateSpacesResponse>(request, success, failure, () =>
		makeGenerateSpacesRequest(objectId, plans));
