import React from 'react';
import IconButton from '../../buttons/IconButton';
import MenuLayer from '../MenuLayer';
import './IconMenu.less';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import classNames from 'classnames';
import {IPopupBasicProps} from '../../containers/Popup';

const defaultIcon = <i className="tz-menu"/>;

interface IIconMenuProps<V, M extends IMenuComponentProps<V>> {
	className?: string;
	style?: React.CSSProperties;
	icon?: React.ReactElement;
	children?: React.ReactElement<M> | Array<React.ReactElement<IMenuItemProps>>;
	popupArrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
}

const IconMenu = <V, M extends IMenuComponentProps<V>>(props: IIconMenuProps<V, M>) => {
	const {className, style, icon = defaultIcon, children, popupArrowVisible = true, popupProps} = props;

	return (
		<MenuLayer
			trigger={(
				<IconButton
					className={classNames('icon-menu__button', className)}
					style={style}
				>
					{icon}
				</IconButton>
			)}
			popupArrowVisible={popupArrowVisible}
			popupProps={{placement: 'bottom-end', preferX: 'left', ...popupProps}}
		>
			{children}
		</MenuLayer>
	);
};

IconMenu.displayName = 'IconMenu';

export default IconMenu;
