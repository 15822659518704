import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetProblemsResponse} from '@src/api/backend/problems';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

export interface IGetProblemsPayload extends IGetProblemsResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetProblemsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	} as IGetProblemsPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка нарушений');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

export const getProblemsActions = {request, success, failure};

/**
 * Получает список нарушений
 */
export const getProblems
	= (
		objectId: string,
		state?: IProblemsFiltersState
	): AppThunkAction<Promise<IGetProblemsResponse>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetProblemsResponse>(
				() => request(objectId),
				(res: IGetProblemsResponse) => success(objectId, res),
				failure,
				() => {
					const s = getState();
					if (!s.entities.problems[objectId] || !s.settings.pages.problems[objectId]) {
						throw new StoreError('Empty store before getting problems');
					}
					const {offset} = s.entities.problems[objectId];
					const {filters, sort, pageSize} = s.settings.pages.problems[objectId];
					return queryClient.fetchQuery({
						queryKey: [
							...problemsQueryKeys.list(),
							state || filters,
							sort,
							offset,
							pageSize
						]
					});
				}
			)
		);
