import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export interface ISpacesPagesSharedSettingsState {
	altNamesVisible?: boolean;
}

export const getInitialState = (): ISpacesPagesSharedSettingsState => ({
	altNamesVisible: false
});

export default createReducer<ISpacesPagesSharedSettingsState>(getInitialState(), {
	[types.TOGGLE_ALT_NAMES_VISIBILITY]: (state, {payload}: {payload?: boolean}) => {
		state.altNamesVisible = payload === undefined ? !state.altNamesVisible : payload;
	}
});
