import React, {memo, useMemo, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {extractSpaceTypesAsArray} from '@src/store/modules/dictionaries/spaceTypes/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface ISpaceTypesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpaceTypesFilterMobile = memo((props: ISpaceTypesFilterMobileProps) => {
	const [search, setSearch] = useState('');

	const spaceTypes = useAppSelector(extractSpaceTypesAsArray);
	const filteredData = useMemo(() => flatFilter(spaceTypes, 'name', search), [spaceTypes, search]);

	return (
		<BaseListFilterMobile
			{...props}
			options={filteredData}
			label="Типы помещений"
			filterName="types"
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
		/>
	);
});
