import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {toListOwnerAcceptance, toOwnerAcceptance} from './utils/convertToLocalSave';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useAddOwnerAcceptanceMutation} from '@src/api/cache/ownerAcceptances/mutations';
import {IAddOwnerAcceptanceParams} from '@src/api/cache/ownerAcceptances/defaults/useOwnerAcceptancesMutationDefaults';
import {addOwnerAcceptanceActions} from '@src/store/modules/entities/ownerAcceptance/actions';
import {useLocalOwnerAcceptances} from '../../queries/ownerAcceptances/useGetOwnerAcceptances';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {useNextLocalNumber} from '../../useNextLocalNumber';

export const useAddOwnerAcceptance = (object: IObject) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const user = useAppSelector(s => s.auth.profile);
	const nextNumber = useNextLocalNumber<IListOwnerAcceptance>(
		useLocalOwnerAcceptances(),
		'createdAt'
	);
	const mutation = useAddOwnerAcceptanceMutation();
	return async ({
		objectId,
		links,
		fields
	}: Omit<
		IAddOwnerAcceptanceParams,
		'key' | 'currentQueryFilter'
	>): Promise<IOwnerAcceptance> => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}
		return new Promise(resolve => {
			const converted = toOwnerAcceptance(user, objectId, links, fields, key, nextNumber);
			const convertedToList = toListOwnerAcceptance(
				object,
				user,
				links,
				fields,
				key,
				nextNumber
			);

			queryClient.setQueryData(
				[...ownerAcceptancesQueryKeys.detail(key), objectId],
				convertedToList
			);

			if (!online) {
				addWarningToast('Локальное сохранение', 'передача собственнику добавлена локально');
				void queryClient.refetchQueries(ownerAcceptancesQueryKeys.localList());
				dispatch(addOwnerAcceptanceActions.success(converted));
			}
			mutation.mutate({
				key,
				objectId,
				links,
				fields
			});
			resolve(converted);
		});
	};
};
