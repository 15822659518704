import React from 'react';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getCurrentCheckItemIndex} from '@src/pages/CheckListPage/selectors/getCurrentCheckItemIndex';

const getBackgroundColor = (record?: ICheckRecord) => {
	switch (record?.status) {
		case CheckRecordStatusId.WAITING:
			return '#FFF3B3';
		case CheckRecordStatusId.PARTIALLY_ACCEPTED:
			return '#CCE3FF';
		case CheckRecordStatusId.REJECTED:
			return '#FFCCD2';
		case CheckRecordStatusId.ACCEPTED:
			return '#EFF9E6';
		default:
			return '#EDF2F6';
	}
};

const renderBulletContent = (index: string | undefined) => (
	<div className="check-list-items__item-header-bullet-circle-content">{index}</div>
);

interface IItemBulletProps {
	item: ICheckItem;
	record?: ICheckRecord;
}

export const ItemBullet = ({item, record}: IItemBulletProps) => {
	const currentIndex = useAppSelector(s => getCurrentCheckItemIndex(s, item.checkListId, item));
	return (
		<div className="check-list-items__item-header-bullet">
			<div
				className="check-list-items__item-header-bullet-circle"
				style={{backgroundColor: getBackgroundColor(record)}}
			>
				{renderBulletContent(currentIndex)}
			</div>
		</div>
	);
};
