import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import React from 'react';
import {useManageWorkingGroupBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageWorkingGroupBreadcrumbs';

export const EditPageBreadcrumbs = () => {
	const breadcrumbs = useManageWorkingGroupBreadcrumbs();

	return (
		<AppBreadcrumbs
			className="manage-working-group-edit-page__breadcrumbs"
			items={breadcrumbs}
		/>
);
};
