import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export type IGetSpaceOwnersResponse = INormalizedData<ISpaceOwner>;

/**
 * Возвращает список собственников помещений
 */
export const requestSpaceOwners = () =>
	wsConnector.sendAuthorizedRequest<IGetSpaceOwnersResponse>('getSpaceOwners');
