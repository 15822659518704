import React from 'react';
import './ExportTemplatesPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {Table} from './components/table/Table.desktop';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

export const ExportTemplatesPage = () => {
	useAppHeader({
		title: 'Шаблоны экспорта',
		mobileRightButtons: <AppUpdateMenu/>
	});
	return (
		<div className="page-cont manage-export-templates-page">
			<PageBreadcrumbs/>
			<Table/>
		</div>
	);
};
