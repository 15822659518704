import {wsConnector} from '@src/api/backend/wsConnector';
import {ISpacesStatsDataRequest} from '@src/interfaces/ISpacesStatsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export type IGetSpacesProblemsResponse = IEntityProblemsData;

/**
 * Возвращает статистику нарушений по помещениям
 */
export const requestSpacesProblemsData = (requestData: ISpacesStatsDataRequest) =>
	wsConnector.sendAuthorizedRequest<IGetSpacesProblemsResponse>('getSpacesProblemsData', {
		objectId: requestData.objectId,
		spacesIds: requestData.spacesIds
	});
