import React from 'react';
import {UploadingFileStatus} from '@tehzor/tools/enums/UploadingFileStatus';
import {CircleLoader} from '../../../loaders';

export function makeIcon(progress: number, status: UploadingFileStatus) {
	if ((status === UploadingFileStatus.WAITING || status === UploadingFileStatus.STARTED)) {
		return (
			<CircleLoader
				className="uploading-file__loader"
				percent={status === UploadingFileStatus.STARTED ? progress : undefined}
			/>
		);
	}
	if (status === UploadingFileStatus.ERROR) {
		return <i className="tz-circle-warning-32 uploading-file__error-icon"/>;
	}
	if (status === UploadingFileStatus.LOCAL) {
		return <i className="tz-circle-warning-32 uploading-file__warning-icon"/>;
	}
	return undefined;
}