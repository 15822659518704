import {wsConnector} from '@src/api/backend/wsConnector';
import getEmailsArray from '@src/utils/getEmailsArray';
import getTimezone from '@tehzor/tools/utils/getTimezone';

/**
 * Отправляет запрос на отправку экспортированного чек-листа
 */
export const makeExportedCheckListSendRequest = (
	email: string,
	templateId: string,
	objectId: string,
	spaceId: string,
	checkListId: string,
	problems?: string[],
	createDocument?: boolean
) => {
	const params = {
		email: getEmailsArray(email),
		templateId,
		objectId,
		spaceId,
		checkListId,
		timezone: getTimezone(),
		problems,
		createDocument
	};
	return wsConnector.sendAuthorizedRequest<void>('sendExportedCheckList', params);
};
