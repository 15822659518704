import React from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useUpdateContent} from '@src/components/EditableBankAccount/hooks/useUpdateContent';

export function useBankAccountDelete(bankAccountId: string): [React.ReactNode, () => void] {
	const {removeBankAccount} = useUpdateContent({});

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить реквизиты',
		'Вы действительно хотите удалить реквизиты?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await removeBankAccount(bankAccountId);
		}
	}, [bankAccountId]);

	return [deleteDialog, handleDelete];
}
