import {combineReducers} from 'redux';
import {authSession} from './authSession/reducers';
import {isAuthenticated} from './isAuthenticated/reducers';
import {profile} from './profile/reducers';
import {persistReducer} from 'redux-persist';
import {formPersistConfig} from '@src/store/persistentStorage/formPersistConfig';

const authReducer = combineReducers({authSession, isAuthenticated, profile});

export const auth = persistReducer(
	formPersistConfig<ReturnType<typeof authReducer>>('auth'),
	authReducer
);
