import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

interface ICreateCheckProps {
	task: ITask;
}

export const MobileCreateCheck = ({task}: ICreateCheckProps) => {
	const object = useAppSelector(s => extractObject(s, task.objectId));

	const [dialog, openDialog] = useAddingCheckDialog({
		objectId: task.objectId,
		stage: object.stage,
		links: {taskId: task.id}
	});

	const handleClick = useCallback(() => {
		openDialog();
	}, [task]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-check-24"/>
			</IconButton>
			{dialog}
		</>
	);
};
