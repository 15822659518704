import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAsync from 'react-use/lib/useAsync';
import React from 'react';
import {getPlans} from '@src/store/modules/dictionaries/plans/actions';
import {Outlet} from 'react-router-dom';

export const ObjectsPageRouteWrapper = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const dispatch = useAppDispatch();
	useAsync(async () => {
		if (objectId && objectId !== 'filters') {
			await dispatch(getPlans([objectId]));
		}
	}, [objectId]);
	return (
		<>
			<Outlet />
		</>
	);
};
