import {wsConnector} from '../../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';

export type IGetInternalAcceptanceProblemsResponse = INormalizedData<IProblem>;

/**
 * Возвращает нарушения для конкретной внутренней приёмки
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 */
export const requestInternalAcceptanceProblems = (objectId: string, internalAcceptanceId: string) =>
	wsConnector.sendAuthorizedRequest<IGetInternalAcceptanceProblemsResponse>('getInternalAcceptanceProblems', {
		objectId,
		internalAcceptanceId
	});
