import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {CreationDateFilterMobile, ObjectsFilterMobile} from '@src/components/MobileEntitiesFilters';
import {WorkAcceptanceStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/WorkAcceptanceStatusesFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';

export const WorkAcceptancesFiltersPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();
	const location = useLocation();
	const {canGoBack} = location.state as {canGoBack: boolean};
	const {state, isStateChanged, change, mobileClear, apply}
		= useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			goBack();
		}
	}, [location, apply]);

	useAppHeader(
		{
			title: 'Фильтры',
			showBackBtn: true,
			mobileRightButtons: isCleanable<IWorkAcceptancesFiltersState>(state, objectId) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[state, objectId]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			{objectId === undefined && entity && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
				/>
			)}

			<WorkAcceptanceStatusesFilterMobile
				objectId={objectId}
				value={state?.statuses}
				onChange={change}
				entity={entity || ''}
				filterName="statuses"
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity || ''}
			/>
		</FilterPage>
	);
};
