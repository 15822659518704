import React from 'react';
import './TasksPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {AddButton} from './components/AddButton';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import classNames from 'classnames';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';
import {TasksTablePage} from '@src/pages/TasksPage/components/tabs/TasksTablePage/TasksTablePage';
import {TaskSchedulePage} from '@src/pages/TasksPage/components/tabs/TasksSchedulePage/TasksSchedulePage';
import {useTasksPageDisplayMode} from '@src/core/hooks/queries/tasks';

export const TasksPage = () => {
	useAppHeader({
		title: 'Управление задачами',
		mobileRightButtons: <AppUpdateMenu/>
	});

	const isDesktop = useIsDesktop();
	const permissions = useTasksPermissions();
	const displayMode = useTasksPageDisplayMode();

	if (!permissions?.canView) {
		return null;
	}

	return (
		<div
			className={classNames(
				'page-cont',
				isDesktop ? 'tasks-page' : 'tasks-page__mobile'
			)}
		>
			<AddButton/>
			{displayMode === TasksDisplayMode.LIST && (<TasksTablePage/>)}
			{displayMode === TasksDisplayMode.SCHEDULE && (<TaskSchedulePage/>)}
		</div>
	);
};
