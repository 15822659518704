import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formTaskLink} from '@tehzor/tools/utils/links';
import {EntityInnerTable} from '@tehzor/ui-components';
import {mobileColumns} from '@src/pages/CheckListPage/components/columns.mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckDetailsEntity} from '@src/interfaces/ICheckDetailsEntity';
import {
	extractTasksEntitiesByItem,
	extractTasksEntitiesByList
} from '@src/store/modules/pages/checkLists/selectors/tasks';
import {EmptyPlate} from '@src/pages/CheckListPage/components/detail/EmptyPlate/EmptyPlate';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface IItem extends ICheckItem {
	children?: any;
}

interface ITableProps {
	item?: IItem;

	list?: ICheckList;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

export const TasksTable = ({item, list, selectedRows, onSelectedRowsChange}: ITableProps) => {
	const {pushPath} = useChangePath();
	const params = useStrictParams<{itemId: string, objectId: string, spaceId?: string}>();
	const tasks = useAppSelector(s => {
		if (item) {
			return extractTasksEntitiesByItem(s, item.checkListId, item.id);
		}
		if (list) {
			return extractTasksEntitiesByList(s, list.id);
		}
		return null;
	}) as ICheckDetailsEntity[];

	const handleRowClick = useCallback(
		(entity: ICheckDetailsEntity) => {
			pushPath(formTaskLink(entity.id));
		},
		[params.objectId]
	);

	return (
		<div className="check-item-detail__list">
			{tasks.length > 0 ? (
				<>
					<div className="check-item-detail__list-body">
						<EntityInnerTable
							columns={mobileColumns}
							hideHead
							selectedRows={selectedRows}
							onSelectedRowsChange={onSelectedRowsChange}
							data={tasks}
							selectable
							onRowClick={handleRowClick}
						/>
					</div>
				</>
			) : (
				<EmptyPlate
					text="Нет задач"
					icon={<i className="tz-task-28"/>}
				/>
			)}
		</div>
	);
};
