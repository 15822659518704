import {wsConnector} from '@src/api/backend/wsConnector';

export interface IDeleteCategoryResponse {
	success: boolean;
}

/**
 * Удаляет вид работ по id
 *
 * @param categoryId id вида работа
 */
export const requestDeleteCategory = (categoryId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteCategoryResponse>('deleteCategory', {
		id: categoryId
	});
