import React, {memo} from 'react';
import classNames from 'classnames';
import './RadioButtonIcon.less';
import {ISwitchIconProps} from '../../../../SwitchBase';

const RadioButtonIcon = ({className, style, checked, disabled}: ISwitchIconProps) => {
	const classes = classNames(
		'radio-button-icon',
		{'radio-button-icon_checked': checked},
		{'radio-button-icon_disabled': disabled},
		className
	);

	return (
		<div
			className={classes}
			style={style}
		/>
	);
};

export default memo(RadioButtonIcon);
