import {IDeleteSpaceStatusResponse} from '@src/api/backend/spaceStatuses/delete';
import {IEditableSpaceStatusesSetAction, IEditableSpaceStatusesSetState} from '@src/core/hooks/states/useSpaceStatusesSetState';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import React, {Dispatch} from 'react';
import {StageStatuses} from './components/StageStatuses';
import './EditableSpaceStatuses.less';

interface IEditableSpaceStatusesProps {
	statuses?: ISpaceStatus[];
	canModify?: boolean;
	spaceStatusesSetId: string;

	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;
	editingState: IEditableSpaceStatusesSetState;

	updateStatus: (index: number) => Promise<ISpaceStatus | undefined>;
	removeStatus: (id: string) => Promise<IDeleteSpaceStatusResponse | undefined>;
}

export const EditableSpaceStatuses = ({
	statuses,
	canModify,
	spaceStatusesSetId,
	editingDispatch,
	editingState,
	updateStatus,
	removeStatus
}: IEditableSpaceStatusesProps) => {
	const stages = useAppSelector(extractObjectStagesAsArray);

	return (
		<div className="editable-space-statuses">
			{!editingState.isLocal ? (stages.map(stage => (
				<StageStatuses
					key={stage.id}
					statuses={statuses}
					spaceStatusesSetId={spaceStatusesSetId}
					stage={stage}
					editingDispatch={editingDispatch}
					editingState={editingState}
					disabled={!canModify}
					updateStatus={updateStatus}
					removeStatus={removeStatus}
				/>
			))) : 'Нет статусов'}
		</div>
	);
};