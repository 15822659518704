import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import React, {useCallback} from 'react';
import {Diagram} from './Diagram';

interface ISpaceDiagramProps {
	space: IEnrichedSpace;
	width: number;
	height: number;

	onClick?: (space: IEnrichedSpace) => void;
}

export const SpaceDiagram = ({space, width, height, onClick}: ISpaceDiagramProps) => {
	const {name, checkLists} = space;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [space]);

	return (
		<svg
			width={width}
			height={height}
			className="check-lists-space__status"
			onClick={handleClick}
		>
			{/* тень */}
			<circle
				cx={width / 2}
				cy={height / 2}
				r={(width - 20) / 2}
				className="check-lists-space__status-shadow"
			/>
			{/* круговая диаграмма */}
			<Diagram
				width={width}
				height={height}
				radius={(width - 20) / 2}
				checkLists={checkLists}
				spaceId={space.id}
			/>

			<text
				className="check-lists-space__number"
				x="50%"
				y="50%"
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{name}
			</text>
		</svg>
	);
};
