import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertStructures, enrichStructures} from '@src/store/modules/entities/structures/utils/convertStructures';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {extractWorkAcceptancesByStructuresAsArray} from '@src/store/modules/entities/workAcceptances/selectors';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';

export function useEnrichedList(
	objectId: string,
	structures: IListStructure[],
	structureIds: string[],
	checkLists: Record<string, ICheckList[]>,
	checkListsRecords: ICheckRecord[]

) {
	const structuresStats = useAppSelector(s => s.pages.structures);
	const object = useAppSelector(s => extractObject(s, objectId));
	const workAcceptances = useAppSelector(s => extractWorkAcceptancesByStructuresAsArray(s, objectId, structureIds));
	const convertStructuresData = convertStructures(structures, structuresStats, workAcceptances, object.id);
	const problemStatuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const workAcceptanceStatuses = useAppSelector(s => s.dictionaries.workAcceptanceStatuses);
	const checkRecordStatuses = useAppSelector(s => s.dictionaries.checkRecordStatuses.byId);

	return useMemo(
		() =>
			enrichStructures(
				convertStructuresData,
				problemStatuses,
				workAcceptanceStatuses,
				checkLists,
				checkListsRecords,
				checkRecordStatuses
			),
		[
			problemStatuses,
			objectId,
			structuresStats,
			checkLists,
			checkListsRecords,
			workAcceptances
		]
	);
}
