import React, {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton, TreeSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {extractSpaceTypeDecorationsAsArray} from '@src/store/modules/entities/spaceTypeDecorations/selectors';
import {extractSpaceTypeDecorationSetsAsArray} from '@src/store/modules/entities/spaceTypeDecorationSets/selectors';
import {makeTreeDataSpaceTypeDecoration} from '@src/components/editableFields/SpaceTypeDecorationTreeSelect/utils/makeTreeData';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const SpaceTypeDecorationsFilterPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{typeDecoration?: string[]}>();
	const [selected, setSelected] = useState(state.typeDecoration);
	const [expanded, setExpanded] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const typeDecorations = useAppSelector(extractSpaceTypeDecorationsAsArray);
	const typeDecorationSets = useAppSelector(extractSpaceTypeDecorationSetsAsArray);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const treeData = useMemo(
		() => makeTreeDataSpaceTypeDecoration(typeDecorations, typeDecorationSets, true),
		[typeDecorations, typeDecorationSets]
	);

	const {filteredData, expanded: expandedValue} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpanded(expandedValue.map(item => item.id));
	}, [expandedValue]);

	const handleApply = useCallback(() => {
		change({typeDecoration: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: 'Типы отделки',
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label="Сбросить"
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={isLocalStateEqual(state.typeDecoration, selected)}
			onApplyClick={handleApply}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selected}
				onChange={setSelected}
				expandedValue={expanded}
				onExpand={setExpanded}
			/>
		</FilterPage>
	);
};
