import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';
import {makeSpaceTypeDecorationAddRequest} from '@src/api/backend/spaceTypeDecoration';

export type IAddSpaceTypeDecorationPayload = ISpaceTypeDecoration[];

const request = (typeDecorationSetId: string, items: ISavingSpaceTypeDecoration[]) => ({
	type: types.ADD_REQUEST,
	payload: {typeDecorationSetId, items}
});

const success = (response: ISpaceTypeDecoration[]) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении типов отделки');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Создает массово типы отделки помещения в наборе
 *
 * @param typeDecorationSetId id набора типов отделки помещения
 * @param items массив типов отделки для добавления
 */
export const addSpaceTypeDecoration = (
	typeDecorationSetId: string,
	items: ISavingSpaceTypeDecoration[]
) =>
	createApiAction<IAddSpaceTypeDecorationPayload>(
		() => request(typeDecorationSetId, items),
		success,
		failure,
		() => makeSpaceTypeDecorationAddRequest(typeDecorationSetId, items)
	);
