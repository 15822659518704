import {createReducer} from '@reduxjs/toolkit';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import * as types from '../constants/problemsData';
import {RESET} from '../constants/page';
import {IStructuresProblemsData, IStructuresStatsDataRequest} from '@src/interfaces/IStructuresStatsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import {IAddProblemPayload, IDeleteProblemPayload, IEditProblemPayload, IEditProblemStatusPayload} from '../../problem/actions';

export interface IStructuresProblemsStatsState {
	data: Record<string, IEntityProblemsData>;
	loading: boolean;
	loaded: boolean;
}

export const getInitialState = (): Record<string, IStructuresProblemsStatsState> => ({});

export const problemsData = createReducer<Record<string, IStructuresProblemsStatsState>>(getInitialState(), {
	[types.GET_REQUEST]: (state, {payload}: {payload: IStructuresStatsDataRequest}) => {
		state[payload.objectId] = {
			data: {},
			loading: true,
			loaded: false
		};
	},
	[types.GET_SUCCESS]: (state, {payload}: {payload: IStructuresProblemsData}) => {
		state[payload.objectId] = {
			data: payload.structuresProblemsData,
			loading: false,
			loaded: true
		};
	},
	[types.GET_FAILURE]: (state, {objectId}: {objectId: string}) => {
		state[objectId] = {
			data: {},
			loading: false,
			loaded: false
		};
	},
	[RESET]: getInitialState,
	[CLEAR_STORE]: getInitialState,

	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
		if (state[payload.objectId]) {
			state[payload.objectId] = {
				...state[payload.objectId],
				loaded: false
			};
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state[payload.objectId]) {
			state[payload.objectId] = {
				...state[payload.objectId],
				loaded: false
			};
		}
	},
	[problemTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditProblemPayload}) => {
		if (state[payload.objectId]) {
			state[payload.objectId] = {
				...state[payload.objectId],
				loaded: false
			};
		}
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (state, {payload}: {payload: IEditProblemStatusPayload}) => {
		if (state[payload.objectId]) {
			state[payload.objectId] = {
				...state[payload.objectId],
				loaded: false
			};
		}
	}
});
