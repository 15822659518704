import React from 'react';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {Button, FloatingActionButton, MenuItem} from '@tehzor/ui-components';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {useAddingSpaceTaskDialog} from '@src/pages/SpacePage/hooks/useAddingSpaceTaskDialog';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import MenuLayer from '@tehzor/ui-components/src/components/menu/MenuLayer';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IOwnerAcceptanceAddingProps {
	objectId: string;
	spaceId?: string;
	stage: ObjectStageIds;
	space?: ISpace;
	defaultData?: {
		planId?: string | null;
		location?: ILocation | null;
		floor?: string | null;
	};
}

const plusIcon = <i className="tz-plus-20" />;
const problemIcon = <i className="tz-problem-24" />;
const taskIcon = <i className="tz-task-24" />;
const GlobalEntityAdding = ({
	objectId,
	spaceId,
	stage,
	defaultData,
	space
}: IOwnerAcceptanceAddingProps) => {
	const checksPermissions = useChecksPermissions(objectId, 'space');
	const ownersAcceptancesPermissions = useOwnerAcceptancesPermissions(objectId, 'space');
	const warrantyClaimPermissions = useWarrantyClaimsPermissions(objectId, 'space');
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId, 'space');
	const spacePermissions = useSpacesPermissions(objectId);

	const permissions = {
		checks: checksPermissions.canAdd,
		acceptances: ownersAcceptancesPermissions.canAdd,
		claims: warrantyClaimPermissions.canAdd,
		internalAcceptances: internalAcceptancePermissions.canAdd
	};
	const [addingTaskDialog, openAddingTaskDialog] = useAddingSpaceTaskDialog(space);
	const [dialog, openDialog] = useGlobalAddingEntityDialog(
		objectId,
		spaceId,
		{spaceId},
		stage,
		permissions,
		defaultData
	);

	const items = [];

	if (
		checksPermissions.canAdd ||
		ownersAcceptancesPermissions.canAdd ||
		warrantyClaimPermissions.canAdd
	) {
		items.push(
			<MenuItem key="add-problem" icon={problemIcon} onClick={openDialog}>
				Нарушение
			</MenuItem>
		);
	}
	if (spacePermissions.canAddTask) {
		items.push(
			<MenuItem key="add-task" icon={taskIcon} onClick={openAddingTaskDialog}>
				Задача
			</MenuItem>
		);
	}

	return (
		<>
			<BeforeDesktop>
				<MenuLayer
					trigger={
						<FloatingActionButton
							icon={<i className="tz-plus-16" />}
							label="Добавить"
							minifyOnScroll
							scrollContainer="scroll-container"
						/>
					}
				>
					{items}
				</MenuLayer>
			</BeforeDesktop>
			<Desktop>
				<MenuLayer
					trigger={<Button type="accent-blue" label="Добавить" leftIcon={plusIcon} />}
				>
					{items}
				</MenuLayer>
			</Desktop>
			{(checksPermissions.canAdd ||
				ownersAcceptancesPermissions.canAdd ||
				warrantyClaimPermissions.canAdd) &&
				dialog}
			{spacePermissions.canAddTask && addingTaskDialog}
		</>
	);
};

export default GlobalEntityAdding;
