import React, {useCallback} from 'react';
import classNames from 'classnames';
import './FloorSort.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeFloorSort} from '@src/store/modules/settings/pages/spaces/actions';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';

interface IFloorSortProps {
	objectId: string;
}

export const FloorSort = ({objectId}: IFloorSortProps) => {
	const {reverseFloorSort} = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const dispatch = useAppDispatch();

	const toggleReverseFloorSort = useCallback(() => {
		dispatch(changeFloorSort(objectId));
	}, []);

	return (
		<i
			className={classNames(
				'tz-table-sort-20',
				'spaces-sort-icon',
				`spaces-sort-icon_${reverseFloorSort ? '' : 'asc'}`
			)}
			onClick={toggleReverseFloorSort}
		/>
	);
};
