import {IconMenu, MenuItem} from '@tehzor/ui-components';
import React, {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {transformLocations} from '../../utils/transformLocations';
import {extractShape} from '@src/store/modules/dictionaries/plans/selectors';
import {useEntityAddingPermission} from '../../hooks/useEntityAddingPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useAddingCheckListTaskDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListTaskDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface ICheckListHeaderActionsProps {
	listId: string;
}

const plusIcon = <i className="tz-plus-24"/>;
const taskIcon = <i className="tz-task-24"/>;

export const CheckListHeaderActions = (props: ICheckListHeaderActionsProps) => {
	const {objectId, spaceId} = useStrictParams<{objectId: string, spaceId: string}>();
	const space = useAppSelector(extractSpace);
	const {listId: checkListId} = props;

	const [planId, sectorId] = transformLocations(space?.locations);
	const roles = useAppSelector(extractUserRoles);

	const canAddEntity = useEntityAddingPermission(objectId, checkListId);
	const permissions = {
		canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
		canAddEntity
	};

	const sector = useAppSelector(s =>
		(planId && sectorId ? extractShape(s, objectId, planId, sectorId) : undefined));

	const location = useMemo(
		() =>
			(sector
				? {
						sectors: [
							{
								sector: sector?.id,
								name: sector.name
							}
						]
				  }
				: undefined),
		[sector]
	);
	const [taskDialog, handleOpenAddTaskDialog] = useAddingCheckListTaskDialog({
		objectId,
		floor: space?.floor,
		planId,
		location,
		spaceId,
		checkListId
	});

	const menuItems = [];
	if (permissions.canAddTask) {
		menuItems.push(
			<MenuItem
				icon={taskIcon}
				onClick={handleOpenAddTaskDialog}
				key={`task-${checkListId}`}
			>
				Добавить задачу
			</MenuItem>
		);
	}

	return menuItems.length ? (
		<div>
			<IconMenu icon={plusIcon}>{menuItems}</IconMenu>
			{taskDialog}
		</div>
	) : null;
};
