import {wsConnector} from '../wsConnector';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ISavingWarrantyClaim';

export type IEditWarrantyClaimResponse = IWarrantyClaim;

/**
 * Изменяет гарантийное обращение
 */
export const makeWarrantyClaimEditRequest = (
	objectId: string,
	claimId: string,
	data: ISavingWarrantyClaim
) =>
	wsConnector.sendAuthorizedRequest<IEditWarrantyClaimResponse>('editWarrantyClaim', {
		objectId,
		claimId,
		...data
	});
