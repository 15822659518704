import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import './YesNoSelect.less';

interface IYesNoSelectProps<S, E> {
	field: keyof S;
	value?: boolean;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
}

const arrowIcon = <i className="tz-simple-arrow-20"/>;

const YesNoSelect = <S, E>({
	className,
	style,
	field,
	label = 'Выберите',
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage = 'Необходимо указать это поле'
}: IYesNoSelectProps<S, E>) => {
	const handleChange = useCallback(
		(v: string) => {
			const selected = v === 'true';
			editingDispatch({type: 'update', field, value: selected});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	const onClear = useCallback(() => {
		editingDispatch({type: 'update', field, value: undefined});
	}, []);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={value === undefined ? '' : value ? 'да' : 'нет'}
						icon={arrowIcon}
						cleanable={value !== undefined}
						onClearClick={onClear}
						error={required && hasError ? errorMessage : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					value={value?.toString()}
					onChange={handleChange}
				>
					<SelectOption
						itemKey="true"
						content="да"
						inputType="radio"
					/>
					<SelectOption
						itemKey="false"
						content="нет"
						inputType="radio"
					/>
				</Select2>
			</SelectPopup>
		</div>
	);
};

export default YesNoSelect;
