import {wsConnector} from '../wsConnector';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';

export type IEditProblemResponsibleResponse = ILinkedProblem;

/**
 * Изменяет ответственных нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const makeProblemResponsibleEditRequest = (
	objectId: string,
	problemId: string,
	respUsers?: string[],
	activeGroup?: string
) =>
	wsConnector.sendAuthorizedRequest<IEditProblemResponsibleResponse>('editProblemResponsible', {
		objectId,
		problemId,
		respUsers,
		activeGroup
	});
