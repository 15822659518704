import React from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {extractTaskTypesAsArray} from '@src/store/modules/dictionaries/taskTypes/selectors';

export const TasksTypesFilterPage = () => {
	const {entity} = useStrictParams<{entity: string}>();
	const types = useAppSelector(extractTaskTypesAsArray);

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={types}
			filterName="types"
			label="Тип задачи"
		/>
	);
};
