import * as types from '../constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetExportTemplatesResponse,
	requestExportTemplates
} from '@src/api/backend/exportTemplates/get';
import {checkExpiration} from '@tehzor/tools/core/storeHelpers/actions';

export type IGetExportTemplatesPayload = IGetExportTemplatesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetExportTemplatesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке шаблонов экспорта');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список шаблонов экспорта
 */
export const getExportTemplates = () =>
	checkExpiration<IState, IGetExportTemplatesResponse, ApiAction>(
		s => s.dictionaries.exportTemplates,
		createApiAction<IGetExportTemplatesResponse>(
			request,
			success,
			failure,
			requestExportTemplates
		),
		86400000 // 1 день
	);
