import React, {memo, useCallback, useState} from 'react';
import {
	convertToSave,
	isEdited,
	useEditableResponsibilityRuleState
} from '@src/core/hooks/states/useEditableResponsibilityRuleState';
import Text from '@src/components/editableFields/Text';
import {PlansSelect} from '../editableFields/PlansSelect';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';
import './EditableResponsibilityRule.less';
import {BooleanSelect} from '@src/components/editableFields/BooleanSelect';
import {Button} from '@tehzor/ui-components';
import {IExtendedSavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IExtendedSavingResponsibilityRule';
import {ObjectsSelect} from '../editableFields/ObjectsSelect';
import {IEditableWorkingGroupState} from '@src/core/hooks/states/useEditableWorkingGroupState';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync, useUpdateEffect} from 'react-use';
import CategoriesSet from '../editableFields/CategoriesSet';
import {addResponsibilityRule} from '@src/store/modules/dictionaries/responsibilityRules/actions/add';
import {deleteResponsibilityRule} from '@src/store/modules/dictionaries/responsibilityRules/actions/delete';
import {addSuccessToast} from '@src/utils/toasts';
import classNames from 'classnames';
import {editResponsibilityRule} from '@src/store/modules/dictionaries/responsibilityRules/actions';
import {IResponsibilityRulesPermissions} from '@src/core/hooks/permissions/useResponsibilityRulesPermissions';
import {extractObjectsAsArrayByIds} from '@src/store/modules/dictionaries/objects/selectors';
import isOneObject from './utils/isOneObject';
import getLastChild from './utils/getLastChild';
import arrayToTree from 'array-to-tree';
import {StructureTreeSelect} from '../editableFields/StructureTreeSelect';
import {getStructuresList} from '@src/store/modules/entities/structures/actions';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {useQueryClient} from '@tanstack/react-query';

interface IEditableResponsibilityRuleProps {
	setArray: React.Dispatch<React.SetStateAction<IExtendedSavingResponsibilityRule[]>>;
	rules: IExtendedSavingResponsibilityRule[];
	rule: IExtendedSavingResponsibilityRule;
	groupState?: IEditableWorkingGroupState;
	permissions: IResponsibilityRulesPermissions;
}

export const EditableResponsibilityRule = memo(
	({setArray, rules, rule, groupState, permissions}: IEditableResponsibilityRuleProps) => {
		const [isBlocking, setIsBlocking] = useState(false);
		const [editingState, editingDispatch] = useEditableResponsibilityRuleState(rule);
		const queryClient = useQueryClient();
		const dispatch = useAppDispatch();
		const objectsArray = useAppSelector(s =>
			extractObjectsAsArrayByIds(s, editingState.objects)
		);
		const objectsTree = arrayToTree(objectsArray, {parentProperty: 'parentId', customID: 'id'});

		const objectId = getLastChild(objectsTree[0]).id;

		const plans = useAppSelector(s =>
			extractPlansAsArray(s, editingState.objects ? objectId : '')
		);

		// сбрасываем поля при изменении объекта
		useUpdateEffect(() => {
			editingDispatch({type: 'update', field: 'plans', value: null});
			editingDispatch({type: 'update', field: 'categories', value: null});
			editingDispatch({type: 'update', field: 'structureIds', value: []});
		}, [editingState.objects]);

		useAsync(async () => {
			const isSingleEditableObject =
				editingState.objects &&
				(editingState.objects.length === 1 || isOneObject(objectsTree));

			if (isSingleEditableObject) {
				if (groupState?.type === WorkingGroupTypeId.WORK_ACCEPTANCE_INSPECTORS) {
					await dispatch(getStructuresList(objectId));
				}
			}
		}, [editingState.objects]);

		const handleQueryInvalidate = async () => {
			await queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
		};

		const handleSave = async () => {
			const result = await dispatch(
				addResponsibilityRule(editingState.groupId, convertToSave(editingState))
			);
			await handleQueryInvalidate();
			const filteredRules = rules.filter(el => el.id !== editingState.id);
			setArray([...filteredRules, result]);
			addSuccessToast('Успешно', 'Добавлено новое правило');
		};

		const handleEdit = async () => {
			await dispatch(
				editResponsibilityRule(
					editingState.id,
					editingState.groupId,
					convertToSave(editingState, undefined, true)
				)
			);
			await handleQueryInvalidate();

			const updatedRules = rules.map(rule => {
				if (rule.id !== editingState.id) {
					return rule;
				}
				return editingState;
			});
			setArray(updatedRules);
			addSuccessToast('Успешно', 'Правило обновлено');
		};

		const handleDelete = async () => {
			if (!rule?.id || !editingState.groupId) return;

			if (!rule.isDraft) {
				await dispatch(deleteResponsibilityRule(editingState.id));
				await handleQueryInvalidate();
			}

			setArray(rules.filter(el => el.id !== editingState.id));
		};

		const save = async () => {
			setIsBlocking(true);
			if (rule?.isDraft) {
				await handleSave();
			} else {
				await handleEdit();
			}
			setIsBlocking(false);
		};

		const reset = useCallback(() => {
			editingDispatch({
				type: 'reset',
				entity: rule
			});
		}, [rule]);

		const isSaveVisible = () =>
			(rule?.isDraft && permissions.canAdd) ||
			(!rule?.isDraft && permissions.canEdit && isEdited(editingState, rule));

		return (
			<div
				className={classNames('editable-responsibility-rule', {
					'editable-responsibility-rule_draft': rule?.isDraft
				})}
			>
				<div className="editable-responsibility-rule__grid">
					<Text
						field="scope"
						className="editable-responsibility-rule__item"
						label="Область"
						value={editingState.scope}
						editingDispatch={editingDispatch}
						required={false}
						hasError={editingState.errors.scope}
					/>

					{groupState?.objects && (
						<ObjectsSelect
							field="objects"
							className="editable-responsibility-rule__item"
							label="Объекты"
							value={editingState.objects}
							availableObjects={groupState?.objects}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.objects}
							disabled={!permissions.canEdit}
						/>
					)}

					{editingState.objects &&
					(editingState.objects.length === 1 || isOneObject(objectsTree)) ? (
						<CategoriesSet
							className="editable-responsibility-rule__item"
							value={editingState.categories}
							objectId={objectId}
							editingDispatch={editingDispatch}
							stages={groupState?.stages}
							required={false}
							hasError={editingState.errors.categories}
							disabled={!permissions.canEdit}
						/>
					) : null}

					{editingState.objects &&
					(editingState.objects.length === 1 || isOneObject(objectsTree)) &&
					groupState?.type === WorkingGroupTypeId.PERFORMERS ? (
						<PlansSelect
							field="plans"
							className="editable-responsibility-rule__item"
							data={plans}
							value={editingState.plans}
							editingDispatch={editingDispatch}
							required={false}
							hasError={editingState.errors.plans}
							disabled={!permissions.canEdit}
						/>
					) : null}

					{editingState.objects &&
					(editingState.objects.length === 1 || isOneObject(objectsTree)) &&
					groupState?.type === WorkingGroupTypeId.WORK_ACCEPTANCE_INSPECTORS ? (
						<StructureTreeSelect
							objectId={objectId}
							className="editable-responsibility-rule__item"
							field="structureIds"
							label="Структуры"
							value={editingState.structureIds || []}
							editingDispatch={editingDispatch}
							required={false}
							hasError={editingState.errors.structureIds}
						/>
					) : null}
					<BooleanSelect
						field="autoSelect"
						className="editable-responsibility-rule__item"
						label="Автомат. выбор"
						value={editingState.autoSelect}
						editingDispatch={editingDispatch}
						required={false}
						hasError={editingState.errors.autoSelect}
						disabled={!permissions.canEdit}
					/>

					<div className="editable-responsibility-rule__buttons">
						{isSaveVisible() && (
							<Button
								label="Cохранить"
								disabled={isBlocking}
								type="accent-blue"
								onClick={save}
							/>
						)}

						{isEdited(editingState, rule) ? (
							<Button
								label="Отменить"
								type="cancel"
								onClick={reset}
							/>
						) : (
							permissions.canDelete && (
								<Button
									label="Удалить"
									type="cancel"
									onClick={handleDelete}
								/>
							)
						)}
					</div>
				</div>
			</div>
		);
	}
);
