import React from 'react';
import './Pagination.less';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

const arrowIcon = (
	<i className="tz-simple-arrow-20 pagination__arrow-btn-icon"/>
);

interface IPaginationProps {
	className?: string;

	/**
	 * The total number of pages.
	 */
	pageCount: number;

	/**
	 * The range of pages displayed.
	 */
	pageRangeDisplayed: number;

	/**
	 * The number of pages to display for margins.
	 */
	marginPagesDisplayed: number;

	/**
	 * The initial page selected.
	 */
	initialPage?: number;

	/**
	 * To override selected page with parent prop.
	 */
	forcePage?: number;

	/**
	 * Disable onPageChange callback with initial page. Default: false
	 */
	disableInitialCallback?: boolean;

	/**
	 * Extra context to add to the aria-label HTML attribute.
	 */
	extraAriaContext?: string;

	/**
	 * The method to call when a page is clicked. Exposes the current page object as an argument.
	 */
	onPageChange?(selectedItem: {selected: number}): void;

	/**
	 * The method is called to generate the href attribute value on tag a of each page element.
	 */
	hrefBuilder?(pageIndex: number): void;
}

export const Pagination = ({className, ...rest}: IPaginationProps) =>
	(rest.pageCount > 1 ? (
		<ReactPaginate
			previousLabel={arrowIcon}
			nextLabel={arrowIcon}
			containerClassName={classNames('pagination', className)}
			pageClassName="pagination__page"
			pageLinkClassName="pagination__page-link"
			activeClassName="pagination__page_selected"
			previousClassName="pagination__arrow-btn pagination__arrow-btn_prev"
			nextClassName="pagination__arrow-btn pagination__arrow-btn_next"
			previousLinkClassName="pagination__arrow-btn-link"
			nextLinkClassName="pagination__arrow-btn-link"
			disabledClassName="pagination__disabled-btn"
			breakClassName="pagination__break"
			breakLinkClassName="pagination__break-link"
			disableInitialCallback
			{...rest}
		/>
	) : null);

Pagination.displayName = 'Pagination';
