import React, {memo} from 'react';
import {IDatesOptions} from '../Calendar';
import {useDaysGrid} from '../hooks/useDaysGrid';
import WeekRow, {IWeekRowProps} from './WeekRow';
import Day, {IDayProps} from './Day';
import {makeElement} from '../../../../utils/makeElement';

interface IDaysGridProps {
	month: number;
	year: number;
	datesOptions?: IDatesOptions;
	weekRowComponent?: React.ReactElement<IWeekRowProps> | ((props: IWeekRowProps) => JSX.Element);
	dayComponent?: React.ReactElement<IDayProps> | ((props: IDayProps) => JSX.Element);
}

const DaysGrid = (props: IDaysGridProps) => {
	const {month, year, datesOptions, weekRowComponent = WeekRow, dayComponent = Day} = props;

	const weeks = useDaysGrid(month, year, datesOptions);

	return (
		<div
			key={month}
			className="calendar__days-grid"
		>
			{weeks.map(week =>
				makeElement(
					weekRowComponent,
					{
						...week,
						key: week.weekIndex
					} as object,
					week.days.map((day, i) =>
						makeElement(dayComponent, {
							...day,
							activeMonth: month,
							activeYear: year,
							key: i
						} as object))
				))}
		</div>
	);
};

export default memo(DaysGrid);
