import {
	extractOwnerAcceptanceResolutionsAsArray
} from '@src/store/modules/dictionaries/ownerAcceptanceResolutions/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {memo, useState} from 'react';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption, SelectPopup} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';

interface IResolutionFilterProps {
	resolutions?: string[];
}

export const ResolutionsFilter = memo(({resolutions}: IResolutionFilterProps) => {
	const [selectedResolutions, setSelectedResolutions] = useState(resolutions);
	const allResolutions = useAppSelector(extractOwnerAcceptanceResolutionsAsArray);
	const {dispatch} = useEntitiesFiltersCtx();

	const handleApply = () => {
		dispatch({resolutions: selectedResolutions});
	};

	const handleClear = () => {
		setSelectedResolutions([]);
	};

	const handleFullClear = () => {
		setSelectedResolutions([]);
		dispatch({resolutions: undefined});
	};

	const handleCancel = () => {
		setSelectedResolutions(resolutions);
	};

	useUpdateEffect(() => {
		setSelectedResolutions(resolutions);
	}, [resolutions]);

	return (
		<div>
			<SelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedResolutions?.length}
				count={selectedResolutions?.length}
				footer
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel('Решение', resolutions, allResolutions)}
						active={!!resolutions?.length}
						onClear={handleFullClear}
					/>
				)}
			>
				<Select2
					multiple
					value={selectedResolutions}
					onChange={setSelectedResolutions}
				>
					{allResolutions.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
});