import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetObjectsResponse} from '@src/api/backend/objects';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';

export type IGetObjectsPayload = IGetObjectsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetObjectsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка объектов');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список объектов
 */
export const getObjects = () =>
	createApiAction<IGetObjectsResponse>(request, success, failure, () =>
		queryClient.fetchQuery(objectsQueryKeys.list(), {staleTime: Infinity}));
