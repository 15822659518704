import React from 'react';
import {DownloadableFile, EntityGridItem, InlineButton} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

interface IMobileDocumentsProps {
	space: ISpace;
	openDialog: () => void;
	canEdit?: boolean;
}

const MobileDocuments = ({space, openDialog, canEdit}: IMobileDocumentsProps) => (
	<EntityGridItem
		label="Документы"
		icon={<i className="tz-photo-20"/>}
		fullRow
		buttons={
				canEdit ? (
					<InlineButton
						type="accent"
						leftIcon={<i className="tz-edit-20"/>}
						onClick={openDialog}
						className="space-page__info-edit-icon"
					/>
				) : null
			}
	>
		{space.attachments?.length
				? space.attachments.map(item =>
						(item.full ? (
							<div
								key={item.id}
								className="space-page__documents-file"
							>
								<DownloadableFile
									name={item.full.name}
									url={item.full.url}
									type={item.full.type}
									size={item.full.size}
								/>
							</div>
						) : null))
				: null}
	</EntityGridItem>
);

export default MobileDocuments;
