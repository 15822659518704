import React, {MutableRefObject, ReactElement, useCallback, useEffect, useState} from 'react';
import useToggle from 'react-use/lib/useToggle';
import {UseLayerOptions} from 'react-laag';
import {SearchSelectPopup} from '../SearchSelectPopup';
import {IAnyEntity} from '@tehzor/tools/interfaces/IAnyEntity';
import {ITextSearchProvider} from '../interfaces';

interface IUserSearchSelectPopupOptions<T extends IAnyEntity> {
	noCheckbox?: boolean;
	provider: ITextSearchProvider<T>;
	inputRef: MutableRefObject<HTMLElement | null>;

	renderItem: (item: T) => ReactElement;
	onSelect: (value: T | undefined) => void;
	onTextChange?: (value: string | undefined) => void;
}

/**
 * Хук для интеграции поиска в любой инпут
 *
 * @param inputRef инпут
 * @param onSelect колбэк при совершении выбора
 * @param provider провайдер поиска
 * @param onTextChange колбек при изменении текста в инпуте
 * @param renderItem render-prop для кастомизации Select2
 * @param noCheckbox флаг отключения чекбоксов
 */
export const useSearchSelectPopup = <T extends IAnyEntity>({
	inputRef,
	onSelect,
	provider,
	onTextChange,
	renderItem,
	noCheckbox
}: IUserSearchSelectPopupOptions<T>): [ReactElement, (value: string) => void, (value?: any) => void] => {
	const [isOpen, toggle] = useToggle(false);
	const [trigger, setTrigger] = useState<UseLayerOptions['trigger']>();

	useEffect(() => {
		if (!trigger && inputRef.current) {
			setTrigger({
				getBounds: () => inputRef.current!.getBoundingClientRect(),
				getParent: () => inputRef.current!
			});
		}
	});

	const handleChange = useCallback(
		(value: string) => {
			toggle(value !== '');
			provider.handleSearch(value);

			if (onTextChange) {
				onTextChange(value);
			}
		},
		[provider.filteredList, onTextChange]
	);

	const popup = (
		<SearchSelectPopup
			trigger={trigger}
			toggle={toggle}
			isOpen={provider.filteredList.length > 0 && isOpen}
			provider={provider}
			onSelect={onSelect}
			renderItem={renderItem}
			noCheckbox={noCheckbox}
		/>
	);

	return [popup, handleChange, toggle];
};
