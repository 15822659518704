import React from 'react';
import {CellProps} from 'react-table';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';

const ModifiedCell = ({row}: CellProps<IProblemEntity>) =>
	 (
		<div className="problems-page__d-table-created">
			<div className="problems-page__d-table-created-date">{row.original.data.modifiedAt}</div>
			<div className="problems-page__d-table-created-name">{row.original.data.modifiedBy}</div>
		</div>
	);
export default ModifiedCell;
