import {IconButton} from '@tehzor/ui-components';
import React, {useCallback, useContext} from 'react';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from '../../../DispatchActionCtx';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {useTasksListPageSettings} from '@src/core/hooks/queries/tasks';

const deleteIcon = <i className="tz-delete"/>;

export const MenuCell = ({row}: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'row'>) => {
	const menuActions = [];
	const perms = useTasksPermissions();
	const dispatchAction = useContext(DispatchActionCtx);
	const pageSettings = useTasksListPageSettings();

	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [row.original.id]);

	if (pageSettings.selectedRows.length > 0) {
		return null;
	}

	if (perms.canDelete) {
		menuActions.push(
			<IconButton
				key="delete"
				type="inline-blue-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
		);
	}

	return menuActions.length ? (
		<ClickPreventWrap className="tasks-page__d-table-menu-wrap">
			{menuActions}
		</ClickPreventWrap>
	) : null;
};
