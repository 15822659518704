import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as checkTypes from '../check/constants';
import config from '@src/core/config';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IGetChecksPayload} from '@src/store/modules/entities/checks/actions';
import {CHANGE_SORT, CLEAR_FILTERS} from '@src/store/modules/settings/pages/checks/constants';
import {IAddCheckPayload, IDeleteCheckPayload} from '@src/store/modules/entities/check/actions';

const {entitiesCacheTime} = config;

export interface IChecksPageState {
	byId: {
		[id: string]: ICheck;
	};
	allIds: string[];
	offset: number;
	total: number;
	expires?: number;
	selectedRows: string[];
}

export type IChecksPagesState = Record<string, IChecksPageState>;

export const getInitialStateForPage = (): IChecksPageState => ({
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	expires: undefined,
	selectedRows: []
});

const deleteCheck = (state: IChecksPagesState, objectId: string, checkId: string) => {
	const page = state[objectId];
	page.allIds = page.allIds.filter(id => id !== checkId);
	delete page.byId[checkId];
	page.expires = undefined;
};

export default createReducer<IChecksPagesState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetChecksPayload}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			const page = state[payload.objectId];
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.offset = payload.offset;
			page.total = payload.total;
			page.expires = Date.now() + entitiesCacheTime * 1000;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string, offset: number}}
		) => {
			const page = state[payload.objectId];
			page.offset = payload.offset;
			page.expires = undefined;
		},
		[types.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: {objectId: string, selectedRows: string[]}}
		) => {
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		[types.CLEAR_EXPIRATION]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state && state?.[payload.objectId]?.expires) {
				state[payload.objectId].expires = undefined;
			}
		},
		// При сортировке нужно обнулить expires для игнорирования кеша
		[CHANGE_SORT]: (state, {payload}: {payload: {objectId: string}}) => {
			state[payload.objectId].expires = undefined;
		},
		// При сбрасывании фильтров нужно обнулить expires для игнорирования кеша
		[CLEAR_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			state[payload.objectId].expires = undefined;
		},
		[checkTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddCheckPayload}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].expires = undefined;
			}
			if (state.all) {
				state.all.expires = undefined;
			}
		},
		[checkTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteCheckPayload}) => {
			if (payload.objectId !== 'all' && state[payload.objectId]) {
				deleteCheck(state, payload.objectId, payload.checkId);
			}
			if (state.all) {
				deleteCheck(state, 'all', payload.checkId);
			}
		},
		[CLEAR_STORE]: () => ({})
	}
);
