import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {addPlan, editPlan} from '@src/store/modules/entities/plan/actions';
import {getPlans} from '@src/store/modules/dictionaries/plans/actions';
import {withRouter} from '../../core/withRouter';
import PlanEditor from '../../components/PlanEditor';
import Button from '../../components/Button';
import {togglePlanBusy} from '../../actions/ui/planUIActions';
import {addTempFile, deleteTempFile} from '@src/store/modules/entities/tempFile/actions';
import PlanImage from '../../components/PlanImage';
import './PlanPage.less';
import {EditableShapeDialog} from './components/EditableShapeDialog';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {CircleLoader, TextField} from '@tehzor/ui-components';

const ShapeListItem = props => {
	const editPermission = true;
	return (
		<div
			className={classNames('edit-block__list-item', {
				'edit-block__list-item_selected': props.selected
			})}
		>
			<div
				className="edit-block__list-item-title"
				onClick={editPermission ? props.onSelect : null}
			>
				{props.index}
				.
				{props.layerType === 'custom' ? `[${props.spaceType.name}] ` : null}
				{props.name}
			</div>
			<div className="edit-block__list-item-actions">
				{editPermission && (
					<div>
						<Button
							className="btn_small-circle edit-block__list-item-btn"
							onClick={props.onEdit}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="10"
								height="11"
								viewBox="0 0 10 11"
							>
								<path
									d="M10 1.63A2.39 2.39 0 0 0 7.74 0 .31 .31 0 0 0 7.5 .1L.61 7.88V8L0 10.66a.28 .28 0 0 0 .11 .28A.31 .31 0 0 0 .3 11h.13L2.94 9.82H3L9.93 1.91A.28 .28 0 0 0 10 1.63M1.08 8.37a1.64 1.64 0 0 1 1.35 1l-1.72.79"
									fill="#050505"
								/>
							</svg>
						</Button>
						<Button
							className="btn_small-circle edit-block__list-item-btn"
							onClick={props.onDelete}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="12"
								viewBox="0 0 12 12"
							>
								<g
									stroke="#050505"
									strokeLinecap="round"
									strokeWidth="2"
									strokeMiterlimit="10"
								>
									<path d="m1 1l10 10"/>
									<path d="M 11,1 1,11"/>
								</g>
							</svg>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

/**
 * Страница редактирования плана
 */
class PlanPage extends Component {
	_isNew = true;

	_editor = null;

	_shapeEditDone = null;

	constructor(props) {
		super(props);
		this._isNew = !props.router.params.planId;
		this.state = {
			name: '',
			previewImage: null,
			fullImage: null,
			newImage: null,
			floor: '',
			layers: [],
			activeLayer: -1,
			drawingMode: null,
			nameError: undefined,
			floorError: undefined,
			imageError: '',
			currentShape: {
				editing: false,
				layerIndex: undefined,
				shapeIndex: undefined,
				layerType: undefined,
				name: '',
				spaceType: ''
			}
		};
	}

	componentDidMount() {
		if (!this._isNew) {
			const {getPlans, toggleBusy} = this.props;
			const {objectId} = this.props.router.params;

			toggleBusy(true);
			getPlans([objectId])
				.then(data => this._copyPlanToState(data))
				.catch(() => toggleBusy(false));
		}
	}

	/**
	 * Копирует свойства плана во внутреннее состояние
	 *
	 * @param {Object} plans планы
	 */
	_copyPlanToState = plans => {
		const {planId} = this.props.router.params;

		if (plans.byId.hasOwnProperty(planId)) {
			const plan = plans.byId[planId];

			this.setState({
				id: plan.id,
				name: plan.name,
				previewImage: plan.previewImage,
				fullImage: plan.fullImage,
				floor: plan.floor,
				layers: plan.layers
			});
			this._editor.initialize(plan.fullImage ? plan.fullImage.url : null, plan.layers);
		}
	};

	/**
	 * Сохранение
	 */
	save = () => {
		const {name, floor, newImage} = this.state;
		const layers = this._editor.getLayersForSaving();
		let hasErrors = false;

		if (!name) {
			this.setState({
				nameError: 'Поле "Название" обязательно для заполнения'
			});
			hasErrors = true;
		} else {
			this.setState({
				nameError: undefined
			});
		}

		if (this._isNew) {
			if (!newImage) {
				this.setState({
					fileError: 'Поле "Изображение" обязательно для заполнения'
				});
				hasErrors = true;
			} else {
				this.setState({
					fileError: undefined
				});
			}

			if (!hasErrors) {
				const {objectId} = this.props.router.params;
				const {navigate} = this.props.router;
				const image = {
					id: newImage.id
				};
				this.props.addPlan(objectId, {
					name,
					image,
					floor,
					layers,
					order: 0
				});
				navigate(`/objects/${objectId}/plans`, {replace: true});
			}
		} else if (!hasErrors) {
			const {objectId, planId} = this.props.router.params;
			const image = newImage
				? {
						id: newImage.id
				  }
				: undefined;
			this.props.editPlan(objectId, {
				planId,
				name,
				image,
				floor,
				layers
			});
		}
	};

	/**
	 * Отмена
	 */
	cancel = () => {
		const { deleteTempFile } = this.props;
		const { navigate } = this.props.router;
		const {newImage} = this.state;

		if (newImage) {
			deleteTempFile(newImage.id);
		}

		navigate(`/objects/${this.props.match.params.objectId}/plans`);
	};

	/**
	 * Назад
	 */
	back = () => {
		this.props.router.navigate(-1);
	};

	/**
	 * Обрабатывает изменение значения свойства name
	 * @param value значение
	 */
	_handleNameChange = value => {
		this.setState({name: value, nameError: undefined});
	};

	/**
	 * Обрабатывает изменение значения свойства floorFrom
	 * @param e событие
	 * @param value значение
	 */
	_handleFloorChange = value => {
		this.setState({floor: value, floorError: undefined});
	};

	/**
	 * Обрабатывает изменение значения свойства newImage
	 *
	 * @param {File} file файл
	 * @private
	 */
	_handleImageChange = file => {
		this.setState({
			previewImage: null,
			fullImage: null,
			newImage: file,
			layers: [],
			activeLayer: -1,
			fileError: ''
		});

		if (file) {
			this._editor.initialize(file.full ? file.full.url : null);
		} else {
			// Сбросить редактор
		}
	};

	/**
	 * Устанавливает режим рисования
	 *
	 * @param {String} mode режим рисования
	 */
	_setDrawingMode = mode => {
		this._editor.setDrawingMode(mode);
	};

	/**
	 * Обрабатывает оси и находит секторы
	 */
	_generateShapes = () => {
		this._editor.generateShapes();
	};

	_toggleLayerVisible = index => {
		this._editor.toggleLayerVisible(index);
	};

	_setActiveLayer = index => {
		this._editor.setActiveLayer(index);
	};

	_clearLayer = index => {
		this._editor.clearLayer(index);
	};

	_deleteLayer = index => {
		this._editor.deleteLayer(index);
	};

	_handleLayersChanged = layers => {
		this.setState({
			layers
		});
	};

	_handleLayerChanged = (index, layer) => {
		this.setState(state => ({
			layers: state.layers.map((l, i) =>
				(index === i
					? {
							name: layer.name,
							type: layer.type,
							visible: layer.visible,
							shapes: l._points
					  }
					: l))
		}));
	};

	_handleShapesChanged = (index, shapes) => {
		this.setState(state => ({
			layers: state.layers.map((l, i) =>
				(index === i
					? {
							name: l.name,
							type: l.type,
							visible: l.visible,
							shapes
					  }
					: l))
		}));
	};

	_handleCurrentLayerChanged = index => {
		this.setState({
			activeLayer: index
		});
	};

	_handleDrawingModeChanged = mode => {
		this.setState({
			drawingMode: mode
		});
	};

	/**
	 * Открывает диалог редактирования фигуры
	 * @param {Number} layerIndex
	 * @param {Number} shapeIndex
	 */
	handleShapeDialogOpen = (layerIndex, shapeIndex) => {
		this.setState(state => ({
			currentShape: {
				editing: true,
				name: state.layers[layerIndex].shapes[shapeIndex].name,
				spaceType: state.layers[layerIndex].shapes[shapeIndex].spaceType,
				layerType: state.layers[layerIndex].type,
				layerIndex,
				shapeIndex
			}
		}));
	};

	/**
	 * Закрывает диалог редактирования фигуры
	 */
	handleShapeDialogClose = () => {
		this.setState({
			currentShape: {
				editing: false,
				layerIndex: undefined,
				shapeIndex: undefined,
				layerType: undefined,
				spaceType: '',
				name: ''
			}
		});
	};

	/**
	 * Обновляет данные о выбранной фигуре
	 * @param {ICurrentShape} shape выбранная фигура
	 */
	handleShapeDialogEdit = shape => {
		this._editor.editShapeName(shape.layerIndex, shape.shapeIndex, shape.name);
		this._editor.editShapeSpaceType(shape.layerIndex, shape.shapeIndex, shape.spaceType);

		this.handleShapeDialogClose();
	};

	/**
	 * Удаляет фигуру
	 * @param layerIndex
	 * @param shapeIndex
	 */
	_deleteShape = (layerIndex, shapeIndex) => {
		this._editor.deleteShape(layerIndex, shapeIndex);
	};

	/**
	 * Переключает выделение фигуры
	 * @param layerIndex
	 * @param shapeIndex
	 * @param {Event} e событие
	 * @param {Event} e.shiftKey
	 */
	_toggleShapeSelection = (layerIndex, shapeIndex, e) => {
		this._editor.toggleShapeSelection(layerIndex, shapeIndex, e.shiftKey);
	};

	/**
	 * Удаляет выбранные фигуры
	 * @param layerIndex
	 * @param {Event} e событие типа React.KeyboardEvent
	 */
	_handleLayerKeyDown = (layerIndex, e) => {
		if (e.code === 'Delete') {
			this._editor.deleteSelectedShapes(layerIndex);
		}
	};

	render() {
		const {isBusy, toggleBusy, addTempFile, deleteTempFile, spaceTypesMap} = this.props;
		const {
			name,
			previewImage,
			fullImage,
			floor,
			layers,
			activeLayer,
			drawingMode,
			currentShape,
			nameError,
			floorError
		} = this.state;
		const editPermission = true;

		return (
			<div className="app-tmp">
				<div className="app-bar1">
					<div className="app-bar1__left">
						<Button
							label="Назад"
							className="btn_white"
							onClick={this.back}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="15"
								viewBox="0 0 17 15"
							>
								<g
									fill="#fff"
									stroke="#fff"
									strokeLinecap="round"
									strokeWidth="1"
									strokeMiterlimit="10"
								>
									<path d="M 1,7.59 7.37,1"/>
									<path d="m16 7.59h-14.93"/>
									<path d="M 7.21,14 1,7.59"/>
								</g>
							</svg>
						</Button>
					</div>
					{editPermission && (
						<div className="app-bar1__right">
							<div
								className="app-bar1__label"
								style={{paddingLeft: '15px'}}
							>
								Инструменты
							</div>
							<div className="app-bar1__separator"/>

							<Button
								label="Выбор"
								className="btn_white"
								active={drawingMode === 'select'}
								onClick={() => this._setDrawingMode('select')}
							/>
							<Button
								label="Ладошка"
								className="btn_white app-bar1__btn"
								active={drawingMode === 'palm'}
								onClick={() => this._setDrawingMode('palm')}
							/>
							<Button
								className="btn_circle btn_no-border app-bar1__btn"
								active={drawingMode === 'ellipse'}
								onClick={() => this._setDrawingMode('ellipse')}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="22"
									height="22"
									viewBox="0 0 22 22"
								>
									<circle
										cx="11"
										cy="11"
										r="10"
										fill="none"
										stroke="#fff"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Button>
							<Button
								className="btn_circle btn_no-border app-bar1__btn"
								active={drawingMode === 'rect'}
								onClick={() => this._setDrawingMode('rect')}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="21"
									height="21"
									viewBox="0 0 21 21"
								>
									<path
										fill="none"
										stroke="#fff"
										strokeLinecap="round"
										strokeLinejoin="round"
										d="m.5.5h20v20h-20z"
									/>
								</svg>
							</Button>
							<Button
								className="btn_circle btn_no-border app-bar1__btn"
								active={drawingMode === 'polygon'}
								onClick={() => this._setDrawingMode('polygon')}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="28"
									height="21"
									viewBox="0 0 28 21"
								>
									<path
										fill="none"
										stroke="#fff"
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M5.8 20.5.5.5 13.38 6.02 27.5.5 24.71 18.91 14 14.76 5.8 20.5z"
									/>
								</svg>
							</Button>
							<Button
								label="Ось"
								className="btn_white app-bar1__btn"
								active={drawingMode === 'axis'}
								onClick={() => this._setDrawingMode('axis')}
							/>
							<Button
								label="Обработать оси"
								className="btn_white app-bar1__btn"
								onClick={this._generateShapes}
							/>
						</div>
					)}
				</div>

				<div className="app-content1">
					<div className="app-sidebar1">
						<div className="edit-block">
							<div className="edit-block__title">Информация о плане</div>

							<div>Название:</div>
							<TextField
								className="edit-block__title-name"
								elementType="input"
								value={name}
								onChange={this._handleNameChange}
								error={nameError}
								disabled={!editPermission}
							/>
							<div>Этаж:</div>
							<TextField
								className="edit-block__title-floor"
								elementType="input"
								value={floor}
								onChange={this._handleFloorChange}
								error={floorError}
								disabled={!editPermission}
							/>

							<PlanImage
								previewImage={previewImage}
								fullImage={fullImage}
								addTempFile={addTempFile}
								deleteTempFile={deleteTempFile}
								onChange={this._handleImageChange}
							/>

							{layers
								&& layers.map((layer, layerIndex) => (
									<div
										key={layerIndex}
										tabIndex={layerIndex + 1}
										onKeyDown={e => this._handleLayerKeyDown(layerIndex, e)}
									>
										<div className="edit-block__title">{layer.name}</div>
										{editPermission && (
											<div>
												<Button
													label="В"
													className="btn_small-circle edit-block__list-item-btn"
													active={layer.visible}
													onClick={() =>
														this._toggleLayerVisible(layerIndex)}
												/>
												<Button
													label="А"
													className="btn_small-circle edit-block__list-item-btn"
													active={layerIndex === activeLayer}
													onClick={() => this._setActiveLayer(layerIndex)}
												/>
												<Button
													label="О"
													className="btn_small-circle edit-block__list-item-btn"
													onClick={() => this._clearLayer(layerIndex)}
												/>
												<Button
													label="У"
													className="btn_small-circle edit-block__list-item-btn"
													onClick={() => this._deleteLayer(layerIndex)}
												/>
											</div>
										)}
										<div className="edit-block__list">
											{layer.shapes.map((shape, shapeIndex) => (
												<ShapeListItem
													key={shapeIndex}
													index={shapeIndex + 1}
													layerType={layer.type}
													name={shape.name}
													spaceType={
														spaceTypesMap[
															shape.spaceType
																|| SpaceTypeId.APARTMENTS
													]
													}
													selected={shape.selected}
													onEdit={() =>
														this.handleShapeDialogOpen(
															layerIndex,
															shapeIndex
													)}
													onDelete={() =>
														this._deleteShape(layerIndex, shapeIndex)}
													onSelect={e =>
														this._toggleShapeSelection(
															layerIndex,
															shapeIndex,
															e
													)}
												/>
											))}
										</div>
									</div>
								))}
						</div>
					</div>

					<div className="app-main">
						<div className="app-main-area">
							<PlanEditor
								frameClass="plan-editor"
								wrapperClass="plan-editor__wrapper"
								onLayersChanged={this._handleLayersChanged}
								onLayerChanged={this._handleLayerChanged}
								onShapesChanged={this._handleShapesChanged}
								onCurrentLayerChanged={this._handleCurrentLayerChanged}
								onDrawingModeChanged={this._handleDrawingModeChanged}
								toggleBusy={toggleBusy}
								ref={el => (this._editor = el)}
							/>
						</div>

						<div className="app-main-actions">
							{editPermission && (
								<Button
									label="Сохранить"
									className="btn_blue btn_no-border"
									onClick={this.save}
								/>
							)}
							<Button
								label="Отмена"
								className="btn_red btn_no-border"
								onClick={this.cancel}
							/>
						</div>
					</div>
				</div>

				<EditableShapeDialog
					editing={currentShape.editing}
					shapeIndex={currentShape.shapeIndex}
					layerIndex={currentShape.layerIndex}
					layerType={currentShape.layerType}
					name={currentShape.name}
					spaceType={currentShape.spaceType}
					onSave={this.handleShapeDialogEdit}
					onClose={this.handleShapeDialogClose}
				/>

				{isBusy && (
					<CircleLoader/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isBusy: state.ui.plan.isBusy,
	networkStatus: state.offlineMode.networkStatus,
	user: state.auth.user,
	spaceTypesMap: state.dictionaries.spaceTypes.byId
});

const mapDispatchToProps = dispatch => ({
	getPlans: bindActionCreators(getPlans, dispatch),
	addPlan: bindActionCreators(addPlan, dispatch),
	editPlan: bindActionCreators(editPlan, dispatch),
	addTempFile: bindActionCreators(addTempFile, dispatch),
	deleteTempFile: bindActionCreators(deleteTempFile, dispatch),
	toggleBusy: bindActionCreators(togglePlanBusy, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlanPage));
