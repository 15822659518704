import {Store} from 'redux';
import EventEmitter from 'events';
import queue from 'async-es/queue';
import {QueueObject} from 'async';
import IRawFile from '../interfaces/IRawFile';
import ITempFile from '../interfaces/ITempFile';
import IUploadingFile from '../interfaces/IUploadingFile';
import {UploadingFileStatus} from '../enums/UploadingFileStatus';

const singleton = Symbol();
const singletonEnforcer = Symbol();

const TASK_CHANGE = 'TASK_CHANGE';

interface ITask extends IUploadingFile {
	namespace: string;

	abortRequest?(): void;
}

/**
 * Класс для централизованной выгрузки во временные файлы
 */
class GlobalUploader {
	private _store: Store;

	private _addTempFile: any;

	private _deleteTempFile: any;

	/**
	 * Объект QueueObject для управления очередью
	 */
	private _queueHandler: QueueObject<ITask>;

	private _eventEmitter = new EventEmitter();

	private _tasks: ITask[] = [];

	/**
	 * Возвращает единственный экземпляр класса GlobalUploader
	 */
	static get instance() {
		if (!this[singleton]) {
			this[singleton] = new GlobalUploader(singletonEnforcer);
		}
		return this[singleton];
	}

	constructor(enforcer: symbol) {
		if (enforcer !== singletonEnforcer) {
			throw new Error("Cannot construct 'GlobalUploader' class");
		}
		this._queueHandler = queue(this._uploadFile, 1);
	}

	/**
	 * Инициализирует экземпляр класса
	 *
	 * @param store Redux store
	 * @param addTempFile action для добавления файла
	 * @param deleteTempFile action для удаления файла
	 */
	initialize = (store: Store, addTempFile: any, deleteTempFile: any) => {
		if (!store || !addTempFile || !deleteTempFile) {
			throw new Error("Param 'store' cannot be null");
		}
		this._store = store;
		this._addTempFile = addTempFile;
		this._deleteTempFile = deleteTempFile;
	};

	/**
	 * Добавляет файлы в очередь на выгрузку
	 *
	 * @param files файлы
	 * @param destination назначение для выгрузки
	 * @param namespace пространство имен файла
	 */
	addToQueue = (files: IRawFile[], destination: string, namespace = ''): IUploadingFile[] => {
		const addedFiles = [];
		for (const file of files) {
			const task = {
				...file,
				status: 'waiting',
				destination,
				progress: 0,
				namespace
			} as ITask;

			this._tasks.push(task);
			this._queueHandler.push(task);
			addedFiles.push(this._cloneTaskToFile(task));
		}
		return addedFiles;
	};

	/**
	 * Заново помещает файл в очередь на выгрузку
	 *
	 * @param key ключ файла
	 */
	reloadFile = (key: string) => {
		const task = this._tasks.find(item => item.key === key);
		if (task === undefined) {
			return;
		}
		this._queueHandler.push(task);
	};

	/**
	 * Удаляет файл из очереди, неважно в каком состоянии он находится
	 *
	 * @param key ключ файла
	 */
	deleteFile = (key: string) => {
		const index = this._tasks.findIndex(item => item.key === key);
		if (index === -1) {
			return;
		}
		const task = this._tasks[index];
		if (task.status === 'waiting' || task.status === 'started') {
			this._queueHandler.remove(({data}: {data: ITask}) => data.key === key);
			if (task.abortRequest) {
				task.abortRequest();
			}
		}
		if (task.status === 'finished' && task.tempFile !== undefined) {
			const action = this._deleteTempFile(task.tempFile.id);
			this._store.dispatch<any>(action).catch((error: Error) => console.log(error));
		}
		this._tasks.splice(index, 1);
	};

	/**
	 * Очищает очередь на выгрузку
	 *
	 * @param namespace пространство имен файла
	 */
	clearQueue = (namespace = '') => {
		this._queueHandler.pause();
		this._queueHandler.remove(({data}: {data: ITask}) => data.namespace === namespace);
		for (const task of this._tasks) {
			if (task.namespace === namespace && task.abortRequest) {
				task.abortRequest();
			}
		}
		this._tasks = this._tasks.filter(task => task.namespace !== namespace);
		this._queueHandler.resume();
	};

	/**
	 * Пожписывает на обновления о изменении файла
	 *
	 * @param listener функция-обработчик
	 * @param namespace пространство имен файла
	 */
	subscribeOnFileChange = (listener: (file: IUploadingFile) => void, namespace = '') => {
		this._eventEmitter.addListener(`${namespace}:${TASK_CHANGE}`, listener);
	};

	/**
	 * Отписывает от обновлений о изменении файла
	 *
	 * @param listener функция-обработчик
	 * @param namespace пространство имен файла
	 */
	unsubscribeOnFileChange = (listener: (file: IUploadingFile) => void, namespace = '') => {
		this._eventEmitter.removeListener(`${namespace}:${TASK_CHANGE}`, listener);
	};

	/**
	 * Выгружает файл
	 *
	 * @param task задача
	 * @param callback функция при завершении
	 */
	private _uploadFile = (task: ITask, callback: (error?: {}) => void) => {
		task.status = UploadingFileStatus.STARTED;
		task.progress = 0;
		this._emitTaskChange(task);

		const action = this._addTempFile(
			task.original,
			task.destination,
			(abortRequest: () => void) => this._handleRequestInitialized(task, abortRequest),
			(progress: number) => this._updateTaskProgress(task, progress)
		);

		this._store
			.dispatch(action)
			.then((tempFile: ITempFile) => {
				task.status = UploadingFileStatus.FINISHED;
				task.tempFile = tempFile;
				task.progress = 100;
				task.abortRequest = undefined;
				this._emitTaskChange(task);
				callback();
			})
			.catch((error: {code: number}) => {
				if (error.code !== 920) {
					task.status = UploadingFileStatus.ERROR;
					task.abortRequest = undefined;
					this._emitTaskChange(task);
				}
				callback(error);
			});
	};

	/**
	 * Обрабатывает событие инициализации запроса, необходим для получения функции abort
	 *
	 * @param task задача
	 * @param abortRequest функция, прерывающая отправленный запрос
	 */
	private _handleRequestInitialized = (task: ITask, abortRequest: () => void) => {
		task.abortRequest = abortRequest;
	};

	/**
	 * Обновляет процент выгрузки файла
	 *
	 * @param task задача
	 * @param progress процент выгрузки
	 */
	private _updateTaskProgress = (task: ITask, progress: number) => {
		task.progress = progress;
		this._emitTaskChange(task);
	};

	/**
	 * Создаёт копию ITask в формате IUploadingFile
	 *
	 * @param task файл на выгрузку
	 */
	private _cloneTaskToFile = (task: ITask): IUploadingFile => ({
		key: task.key,
		name: task.name,
		size: task.size,
		type: task.type,
		url: task.url,
		original: task.original,
		status: task.status,
		destination: task.destination,
		progress: task.progress,
		tempFile: task.tempFile
	});

	/**
	 * Инициирует событие TASK_CHANGE
	 *
	 * @param task файл на выгрузку
	 */
	private _emitTaskChange = (task: ITask) => {
		this._eventEmitter.emit(`${task.namespace}:${TASK_CHANGE}`, this._cloneTaskToFile(task));
	};
}

export default GlobalUploader;
