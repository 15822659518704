import {wsConnector} from '@src/api/backend/wsConnector';
import {ISpacesStatsDataRequest} from '@src/interfaces/ISpacesStatsData';
import {ISpaceCheckListsData} from '@tehzor/tools/interfaces/entititesComputedData/ISpaceCheckListsData';

export type IGetSpacesCheckListsResponse = ISpaceCheckListsData;

/**
 * Возвращает статистику Чек-листов по помещениям
 */
export const requestSpacesCheckListsData = (requestData: ISpacesStatsDataRequest) =>
	wsConnector.sendAuthorizedRequest<IGetSpacesCheckListsResponse>('getSpacesCheckListsData', {
		objectId: requestData.objectId,
		spacesIds: requestData.spacesIds
	});
