import React from 'react';
import {StatusSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useProblemStatusPermissions} from '@src/core/hooks/permissions/useProblemStatusPermissions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {editProblemStatus} from '@src/store/modules/pages/problem/actions';
import ClickPreventWrap from './ClickPreventWrap';
import {getObjectStats} from '@src/store/modules/dictionaries/objects/actions';
import {IPreparedProblem} from '@src/pages/ProblemsPage/interfaces/IPreparedProblem';

interface IProblemStatusProps {
	problem: Omit<
		IListProblem,
		'createdBy' | 'modifiedBy' | 'activeGroupLeader' | 'initialGroupLeader'
	>;
}

export const ProblemStatus = ({problem}: IProblemStatusProps) => {
	const {id, objectId, status} = problem;
	const statuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const dispatch = useAppDispatch();
	const permissions = useProblemStatusPermissions(objectId, problem);

	const [, handleChange] = useAsyncFn(
		async (v: ProblemStatusId) => {
			await dispatch(editProblemStatus(objectId, id, v));
			await dispatch(getObjectStats([objectId]));
		},
		[objectId, id]
	);

	return (
		<ClickPreventWrap>
			<StatusSelect
				style={{maxWidth: '100%'}}
				statuses={statuses}
				availableStatuses={permissions.availableStatuses}
				disabled={!permissions.canEditStatus}
				value={status}
				onChange={handleChange}
			/>
		</ClickPreventWrap>
	);
};

const ProblemStatusCell = ({row}: CellProps<{data: IListProblem}>) => (
	<ProblemStatus problem={row.original.data}/>
);

export const ProblemStatusCellWrap = (
	{row}: CellProps<{data: IPreparedProblem}>
) => row.original.data.data ? (
	<ProblemStatus problem={row.original.data.data}/>
) : null;

export default ProblemStatusCell;
