import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {ISavingOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ISavingOwnerAcceptance';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export const toOwnerAcceptance = (
	user: IUser,
	objectId: string,
	links: IOwnerAcceptance['links'] | undefined,
	fields: ISavingOwnerAcceptance,
	key: string,
	nextNumber: number
): IOwnerAcceptance => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};
	const newOwnerAcceptance: IOwnerAcceptance = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId,
		links,
		attachments: fields.newAttachments
			? (fields.newAttachments?.map(att => ({
					id: att.key,
					full: att.file
						? {url: URL.createObjectURL(att.file), type: att.file?.type}
						: undefined,
					preview: att.file
						? {url: URL.createObjectURL(att.file), type: att.file?.type}
						: undefined
			  })) as IAttachment[])
			: undefined,
		status: OwnerAcceptanceStatusId.WAITING,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	return newOwnerAcceptance;
};

export const toListOwnerAcceptance = (
	object: IObject,
	user: IUser,
	links: IOwnerAcceptance['links'] | undefined,
	fields: ISavingOwnerAcceptance,
	key: string,
	nextNumber: number
): IListOwnerAcceptance => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};
	const newOwnerAcceptance: IListOwnerAcceptance = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId: object.id,
		links,
		status: OwnerAcceptanceStatusId.WAITING,
		object: {
			id: object.id,
			name: object.name
		},
		attachments: fields.newAttachments
			? (fields.newAttachments?.map(att => ({
					id: att.key,
					full: att.file
						? {url: URL.createObjectURL(att.file), type: att.file?.type}
						: undefined,
					preview: att.file
						? {url: URL.createObjectURL(att.file), type: att.file?.type}
						: undefined
			  })) as IAttachment[])
			: undefined,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	return newOwnerAcceptance;
};
