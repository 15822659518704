import generateKey from '@tehzor/tools/utils/generateKey';
import AbstractHandler from './AbstractHandler';
import IShape from '@tehzor/tools/interfaces/plans/IShape';
import {Container, G} from '@svgdotjs/svg.js';
import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import {ISelectableSector} from './SelectableHandler';

/**
 * Класс для отображения слоя с фигурами
 */
class CommonHandler extends AbstractHandler<ILocationMarker> {
	protected _emit: () => void;

	/**
	 * Создаёт слой
	 *
	 * @param svg SVG.js объект
	 * @param group группа, где необходимо создать слой
	 * @param layerId id слоя
	 * @param visible видимость слоя
	 * @param shapes фигуры для первоначальной отрисовки
	 * @param visibleShapes
	 * @param layerType тип слоя
	 */
	constructor(
		svg: Container,
		group: G,
		layerId: string,
		visible: boolean,
		shapes: IShape[],
		visibleShapes: string[],
		sectorsData: ILocationMarker[],
		layerType?: string,
		emit?: (editedectorsData: ILocationMarker[]) => void
	) {
		super();

		this._svg = svg;
		this._group = group
			.group()
			.id(layerId)
			.back();
		this._group.css('display', visible ? 'block' : 'none');
		this._visible = visible;
		this._emitFunc = emit;
		this._sectorsData = sectorsData;
		for (const shape of shapes) {
			if (!visibleShapes.includes(shape.id)) {
				continue;
			}
			const shapeElement = this._group
				.svg(shape.svg)
				.last()
				.id(generateKey('shape'));
			if (layerType !== 'entity') {
				shapeElement.on('mouseup', this._selectShape);
			}
			if (layerType === 'axes') {
				shapeElement.addClass('plan-viewer__axis');
			}
			if (layerType === 'generated' || layerType === 'custom') {
				shapeElement.addClass('plan-viewer__sector').addClass('plan-viewer__sector_semi-selected');
			}
			if (layerType === 'entity') {
				shapeElement.addClass('plan-viewer__sector').addClass('plan-viewer__sector_entity');
			}
			if (this._sectorsData?.filter(el => el.description).map(el => el.sector).includes(shape.id)) {
				shapeElement.addClass('plan-viewer__sector_commented');
			}
			this._shapes.push({
				...shape,
				element: shapeElement
			});
		}
	}

	private _selectShape = (event: MouseEvent) => {
		event.stopPropagation();
		if (!event.target || event.button !== 0) {
			return;
		}

		for (const shape of this._shapes) {
			// @ts-ignore
			if (shape.element.attr('id') === event.target.id) {
				shape.element.addClass('plan-viewer__sector_selected');

				if (this._sectorsData?.map(el => el.sector).includes(shape.id)) {
					this._sectorsData = this._sectorsData
						.map(el => (el.sector === shape.id ? {...el, selected: true} : {...el, selected: false}));
				}
				break;
			}
		}
		if (this._emitFunc) {
			const sectorsData: Array<ILocationMarker & ISelectableSector> = this._sectorsData.map(el => ({

				selected: el.selected,
				sector: el.sector,
				name: el.name,
				description: el.description
			}));

			this._emitFunc(sectorsData);
		}
	};
}

export default CommonHandler;
