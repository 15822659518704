import React, {useCallback} from 'react';
import Checkbox from '../inputs/checkbox/Checkbox';

interface IReasonSelectCheckboxProps {
	id: string;
	label: string;
	checked: boolean;

	onChange: (regStandardId: string, label: string) => void;
}

const ReasonSelectCheckbox = ({id, label, checked, onChange}: IReasonSelectCheckboxProps) => {
	const handleChange = useCallback(() => {
		onChange(id, label);
	}, [id, label, onChange]);

	return (
		<Checkbox
			className={{
				root: 'reason-select__standard-item-cb',
				icon: 'reason-select__standard-item-cb-input',
				content: 'reason-select__standard-item-cb-label'
			}}
			id={id}
			checked={checked}
			onChange={handleChange}
		>
			{label}
		</Checkbox>
	);
};

export default ReasonSelectCheckbox;
