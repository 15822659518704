import React, {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
// TZ-2070 Разкомментить после исправления фильтров по стадии объектов
// import {StagesFilterMobile} from '@src/components/MobileEntitiesFilters/components/StagesFilterMobile';
import {CompaniesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CompaniesFilterMobile';
import {CitiesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CitiesFilterMobile';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';

const ObjectsFiltersPage = () => {
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};
	const {state, isStateChanged, change, mobileClear, apply}
		= useEntitiesFiltersCtx<IObjectsFiltersState>();
	const navigate = useNavigate();

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			navigate(-1);
		}
	}, [canGoBack, apply]);

	useAppHeader(
		{
			title: 'Фильтры объектов',
			showBackBtn: true,
			mobileRightButtons: isCleanable<IObjectsFiltersState & {objects?: []}>(state) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[state]
	);

	useRouting(!canGoBack, 'all', 'objects');

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<CompaniesFilterMobile
				value={state?.companies}
				onChange={change}
				entity="objects"
			/>
			<CitiesFilterMobile
				value={state?.cities}
				onChange={change}
				entity="objects"
			/>
			{/* // TZ-2070 Разкомментить после исправления фильтров по стадии объектов 
			<StagesFilterMobile
				value={state?.stages}
				onChange={change}
				entity="objects"
			/> */}
		</FilterPage>
	);
};

export default ObjectsFiltersPage;
