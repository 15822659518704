import React, {useCallback, useEffect, useState} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {exportInternalAcceptanceUnified} from '@src/store/modules/entities/internalAcceptance/actions';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {exportProblems} from '@src/store/modules/entities/problems/actions';
import {exportInspections} from '@src/store/modules/entities/inspections/actions';
import {exportInspection} from '@src/store/modules/entities/inspection/actions';

function useDestinations(selectedEntities: IInternalAcceptanceEntity[]) {
	const [destinations, setDestinations] = useState<ExportTemplatesDestinationId[]>([
		ExportTemplatesDestinationId.CHECK
	]);

	useEffect(() => {
		let hasProblems = false;
		let hasInspections = false;
		for (const item of selectedEntities) {
			if (item.type === 'problem') {
				hasProblems = true;
				if (hasInspections) {
					break;
				}
			}
			if (item.type === 'inspection') {
				hasInspections = true;
				if (hasProblems) {
					break;
				}
			}
		}
		// Только экспорт внутренней приёмки
		if (hasProblems && hasInspections) {
			return setDestinations([ExportTemplatesDestinationId.CHECK]);
		}
		// Экспорт внутренней приёмки и списка нарушений
		if (hasProblems) {
			return setDestinations([
				ExportTemplatesDestinationId.CHECK,
				ExportTemplatesDestinationId.PROBLEMS
			]);
		}
		// Экспорт внутренней приёмки и списка осмотров
		if (hasInspections && selectedEntities.length > 1) {
			return setDestinations([
				ExportTemplatesDestinationId.CHECK,
				ExportTemplatesDestinationId.INSPECTIONS
			]);
		}
		if (hasInspections && selectedEntities.length === 1) {
			return setDestinations([
				ExportTemplatesDestinationId.CHECK,
				ExportTemplatesDestinationId.INSPECTION
			]);
		}
		return undefined;
	}, [selectedEntities]);

	return destinations;
}

const splitSelectedEntities = (selectedEntities: IInternalAcceptanceEntity[]) =>
	selectedEntities.reduce<{
		problems: string[];
		inspections: string[];
	}>(
		(prev, item) => {
			switch (item.type) {
				case 'problem':
					prev.problems.push(item.data.id);
					break;
				case 'inspection':
					prev.inspections.push(item.data.id);
			}
			return prev;
		},
		{problems: [], inspections: []}
	);

/**
 * Хук для экспорта внутренней приёмки или его нарушений/осмотров в зависимости от того, что выбрано
 *
 * @param objectId id объекта
 * @param selectedEntities выбранные нарушения/осмотры
 */
export function useInternalAcceptanceCombinedExport(
	objectId: string,
	selectedEntities: IInternalAcceptanceEntity[]
): [React.ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const destinations = useDestinations(selectedEntities);

	const [isOpen, setOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const [, handleExport] = useAsyncFn(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (template.destination === ExportTemplatesDestinationId.CHECK) {
				if (exportingId) {
					const {problems, inspections} = splitSelectedEntities(selectedEntities);
					await dispatch(
						exportInternalAcceptanceUnified(
							email,
							template.id,
							objectId,
							exportingId,
							problems,
							inspections,
							createDocument
						)
					);
				}
			} else if (template.destination === ExportTemplatesDestinationId.PROBLEMS) {
				const selected = selectedEntities.map(item => item.data.id);
				await dispatch(
					exportProblems(
						template.id,
						objectId,
						createDocument,
						email,
						undefined,
						undefined,
						selected
					)
				);
			} else if (template.destination === ExportTemplatesDestinationId.INSPECTIONS) {
				const selected = selectedEntities.map(item => item.data.id);
				await dispatch(
					exportInspections(
						template.id,
						createDocument,
						email,
						objectId,
						undefined,
						undefined,
						selected
					)
				);
			} else if (template.destination === ExportTemplatesDestinationId.INSPECTION) {
				await dispatch(
					exportInspection(
						template.id,
						createDocument,
						email,
						objectId,
						selectedEntities[0].data.id
					)
				);
			}
			setOpen(false);
		},
		[objectId, exportingId, selectedEntities]
	);

	const dialog = (
		<ExportDialog
			pageKey="report"
			destinations={destinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
