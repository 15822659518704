import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {IExtendedSavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IExtendedSavingProblemTag';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';

export type IEditableProblemTagState = IEditableEntityState<{
	id: string;
	name?: string;
	color?: string;
	isDraft?: boolean;
}>;

export type IEditableProblemTagAction = IEditableEntityAction<
	IEditableProblemTagState,
	IExtendedSavingProblemTag
>;

const makeEmptyState = (): IEditableProblemTagState => ({
	id: '',
	name: undefined,
	color: undefined,
	isDraft: undefined,
	errors: {
		name: false,
		color: false
	}
});

export const init = (
	original?: IExtendedSavingProblemTag
): IEditableProblemTagState => {
	const empty = makeEmptyState();
	return original
		? {
				id: original.id,
				name: original.name,
				color: original.color,
				isDraft: original.isDraft,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (
	state: IEditableProblemTagState,
	original?: IExtendedSavingProblemTag
): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'color')
	);

export const errorsFns = {
	name: (state: IEditableProblemTagState) => !state.name,
	color: (state: IEditableProblemTagState) => !state.color
};

export const convertToSave = (
	state: IEditableProblemTagState,
	original?: IProblemTag,
	onlyEdited?: boolean
): IExtendedSavingProblemTag => {
	if (!onlyEdited) {
		return state;
	}

	const tag: IExtendedSavingProblemTag = {id: state.id};

	if (isPropEdited('name', state, original)) {
		tag.name = state.name;
	}
	if (isPropEdited('color', state, original)) {
		tag.color = state.color;
	}
	return tag;
};
