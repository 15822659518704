import React, {useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {
	DatePickerResult,
	FilterPage,
	InlineDateRangePicker,
	LinkButton
} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const IntervalStartFilterPage = () => {
	const {goBack} = useChangePath();
	const {entity} = useStrictParams<{entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{
		taskIntervalStartFrom?: Date;
		taskIntervalStartTo?: Date;
	}>();
	const [from, setFrom] = useState(state.taskIntervalStartFrom);
	const [to, setTo] = useState(state.taskIntervalStartTo);
	const location = useLocation();
	const {canGoBack} = location.state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({taskIntervalStartFrom: from, taskIntervalStartTo: to});
		goBack();
	}, [from, to, change]);

	const handleChange = useCallback((f: Date | null, t: Date | null) => {
		if (f) {
			setFrom(new Date(f.getFullYear(), f.getMonth(), f.getDate()));
		}
		if (t) {
			setTo(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999));
		}
	}, []);

	const handleClear = useCallback(() => {
		setTo(undefined);
		setFrom(undefined);
	}, []);

	useAppHeader(
		{
			title: 'Дата начала',
			showBackBtn: true,
			mobileRightButtons:
				from && to ? (
					<LinkButton
						label="Сбросить"
						onClick={handleClear}
					/>
) : null
		},
		[from, to]
	);

	useRouting(!canGoBack, 'all', entity);

	return (
		<FilterPage
			applyDisabled={
				isLocalStateEqual(state.taskIntervalStartFrom, from)
				&& isLocalStateEqual(state.taskIntervalStartTo, to)
			}
			onApplyClick={handleApply}
		>
			<DatePickerResult
				value1={from}
				value2={to}
				placeholder1="Дата начала"
				placeholder2="Дата окончания"
				isRange
				dateFormat="dd.MM.yyyy"
			/>
			<InlineDateRangePicker
				valueFrom={from}
				valueTo={to}
				onChange={handleChange}
			/>
		</FilterPage>
	);
};
