import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from './BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {extractWorkAcceptancesStatusesAsArray} from '@src/store/modules/dictionaries/workAcceptanceStatuses/selectors';

interface IWorkAcceptanceStatusesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	filterName?: string;
	onChange: (changes: IChanges) => void;
}

export const WorkAcceptanceStatusesFilterMobile = memo((props: IWorkAcceptanceStatusesFilterMobileProps) => {
	const statuses = useAppSelector(extractWorkAcceptancesStatusesAsArray);

	return (
		<BaseListFilterMobile
			{...props}
			options={statuses}
			label={props.label ?? 'Статусы приёмок работ'}
			filterName={props.filterName ?? 'workAcceptanceStatuses'}
		/>
	);
});
