import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ProblemIndicator} from '@tehzor/ui-components';
import {useProblemsCountByStatus} from '../hooks/useProblemsCountByStatus';

interface ProblemIndicatorsProps {
	checkListId: string;
	checkItemId?: string;
}

export const ProblemIndicators = ({checkListId, checkItemId}: ProblemIndicatorsProps) => {
	const problemStatuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const problemCounts = useProblemsCountByStatus(checkListId, checkItemId);

	return (
		<div className="check-list-page__indicators">
			{problemCounts.map(({status, count}) =>
				(count > 0 ? (
					<ProblemIndicator
						key={status}
						statuses={problemStatuses}
						status={status}
						count={count}
					/>
				) : null))}
		</div>
	);
};
