import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractCheckRecordStatusesByEntityTypeAsArray} from '@src/store/modules/dictionaries/checkRecordStatuses/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const CheckListStatusesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const CheckListsRecordStatuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityTypeAsArray(s, 'check-list'));
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={CheckListsRecordStatuses}
			label="Статусы чек-листов"
			filterName="checkListStatuses"
		/>
	);
};
