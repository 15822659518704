import React, {useMemo, useRef} from 'react';
import {useLocalSearchProvider, useSearchSelectPopup} from '@tehzor/ui-components';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemTemplatesAsArray} from '@src/store/modules/dictionaries/problemTemplatesSets/selectors';
import {useTemplateSelectHandler} from '../hooks/useTemplateSelectHandler';
import {renderItem} from '../utils/renderItem';
import Description from '@src/components/editableFields/Description';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {IDescriptionProps} from '@src/components/editableFields/Description/IDescriptionProps';
import {TemplateSelectDialog} from './TemplateSelectDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface ISearchableProblemDescriptionProps
	extends Omit<IDescriptionProps<{ description: string }, IProblem>, 'onSelect' | 'addon'> {
	stage: ObjectStageIds;
	objectId: string;
}

const SearchableProblemDescription = ({
	objectId,
	editingDispatch,
	stage,
	...props
}: ISearchableProblemDescriptionProps) => {
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const onSelect = useTemplateSelectHandler({objectId, editingDispatch, stage});
	const templates = useAppSelector(s => extractProblemTemplatesAsArray(s, objectId));
	const filteredTemplates = useMemo(() => templates.filter(el => el.stageId === stage), [templates, stage]);
	const provider = useLocalSearchProvider({initialList: filteredTemplates, keys: ['description']});

	const [popup, handleSearchChange] = useSearchSelectPopup<IProblemTemplate>({
		inputRef,
		onSelect,
		provider,
		renderItem,
		noCheckbox: true
	});

	return (
		<>
			<Description
				ref={inputRef}
				editingDispatch={editingDispatch}
				onChange={handleSearchChange}
				addon={(
					<TemplateSelectDialog
						objectId={objectId}
						editingDispatch={editingDispatch}
						stage={stage}
					/>
				)}
				{...props}
			/>
			{popup}
		</>
	);
};

export default SearchableProblemDescription;
