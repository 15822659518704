import {IState} from '@src/store/modules';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetTaskResponse, requestTask} from '@src/api/backend/task';

export type IGetTaskPayload = IGetTaskResponse;

const request = (taskId: string) => ({
	type: types.GET_REQUEST,
	payload: {taskId}
});

const success = (response: IGetTaskResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке задачи');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает задачу по Id
 */
export const getTask
	= (taskId: string): AppThunkAction<Promise<IGetTaskResponse>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<IGetTaskResponse>(
			() => request(taskId),
			success,
			failure,
			() => requestTask(taskId)
		);

		return state.pages.task.data?.id === taskId
			? dispatch(
					checkExpiration<IState, IGetTaskResponse, ApiAction>(
						s => s.pages.task,
						apiAction
					)
			  )
			: dispatch(apiAction);
	};
