import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditProblemCommentResponse,
	makeProblemCommentEditRequest
} from '@src/api/backend/problemComment';
import {addErrorToast} from '@src/utils/toasts';
import ISavingProblemComment from '@tehzor/tools/interfaces/problemComments/ISavingProblemComment';

export type IEditProblemCommentPayload = IEditProblemCommentResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditProblemCommentResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении комментария');
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет комментарий нарушения
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const editProblemComment = (
	objectId: string,
	commentId: string,
	fields: ISavingProblemComment
) =>
	createApiAction<IEditProblemCommentResponse>(request, success, failure, () =>
		makeProblemCommentEditRequest(objectId, commentId, fields));
