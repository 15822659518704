import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import useAppSelector from '@src/core/hooks/useAppSelector';

export const ResponsibleUsersCell = ({value: task}: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'value'>) => {
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const groupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);

	const getLeaderWorkGroupName = (workGroupId?: string): string | undefined => {
		if (!workGroupId) return undefined;
		const leaderId = groupsMap[workGroupId]?.leader;
		return usersMap[leaderId]?.displayName;
	};

	return (
		<div>
			{task?.respUsers && task?.respUsers?.length > 0 ? (
				task.respUsers.map(user => <div key={user?.id}>{user?.displayName}</div>)
			) : task?.activeGroup ? (
				<div>{getLeaderWorkGroupName(task.activeGroup)}</div>
			) : null}
		</div>
	);
};
