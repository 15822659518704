import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {IGetChecksResponse} from '@src/api/backend/checks';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/reducers';
import {queryClient} from '@src/api/QueryClient';
import {checksQueryKeys} from '@src/api/cache/checks/keys';

export interface IGetChecksPayload extends IGetChecksResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetChecksResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка проверок');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

export const getChecksActions = {request, success, failure};
/**
 * Получает список проверок
 */

export const getChecks
	= (objectId: string, state?: IChecksFiltersState): AppThunkAction<Promise<IGetChecksResponse>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetChecksResponse>(
				() => request(objectId),
				(res: IGetChecksResponse) => success(objectId, res),
				failure,
				() => {
					const s = getState();
					if (!s.entities.checks[objectId] || !s.settings.pages.checks[objectId]) {
						throw new StoreError('Empty store before getting checks');
					}
					const {offset} = s.entities.checks[objectId];
					const {filters, sort, pageSize} = s.settings.pages.checks[objectId];
					const data = queryClient.fetchQuery<IGetChecksResponse>({
						queryKey: [
							...checksQueryKeys.list(),
							state || filters,
							sort,
							offset,
							pageSize
						],
						staleTime: Infinity
					});
					return data;
				}
			)
		);
