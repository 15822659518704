import * as types from './constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetStructureTypesResponse, requestStructureTypes} from '@src/api/backend/structureTypes';
import {checkExpiration} from '@tehzor/tools/core/storeHelpers/actions';

export type IGetStructureTypesPayload = IGetStructureTypesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetStructureTypesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке типов структур');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список типов структур
 */
export const getStructureTypes = () =>
	checkExpiration<IState, IGetStructureTypesResponse, ApiAction>(
		state => state.dictionaries.structureTypes,
		createApiAction<IGetStructureTypesResponse>(request, success, failure, requestStructureTypes),
		1209600000 // 2 недели
	);
