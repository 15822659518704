import React, {useCallback} from 'react';
import './DelegationTreeGroup.less';
import {Tree} from 'array-to-tree';
import classNames from 'classnames';
import {DelegationTreeGroupChildren} from '../DelegationTreeGroupChildren';
import {RadioButton} from '../../inputs';
import {WorkingGroupInfo} from '../../various';
import {InlineButton} from '../../buttons';
import {AnimatePresence, motion} from 'framer-motion';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {useTreeCtx} from '../DelegationTree/utils/TreeCtx';
import {DelegationSelectOption} from '../DelegationSelectOption';

const animationVariants = {
	opened: {opacity: 1, height: 'auto'},
	collapsed: {
		opacity: 0,
		height: 0,
		transition: {
			duration: 0.25,
			// Анимация до своих потомков необходима для избежания бага с резким
			// сворачиванием всех потомков при сворачивании родителя
			when: 'beforeChildren',
			staggerChildren: 0.5
		}
	}
};

const animationTransition = {type: 'tween', duration: 0.25};

interface IDelegationTreeGroupProps {
	data: Tree<IEnrichedWorkingGroup>;
	rules?: string[];
	level: number;
	onlyFirstLevelSelectable?: boolean;
}

export const DelegationTreeGroup = ({data, rules, level, onlyFirstLevelSelectable}: IDelegationTreeGroupProps) => {
	const {isGroupSelected, isGroupExpanded, changeGroupSelected, changeGroupExpanded} = useTreeCtx();

	const selectable = !onlyFirstLevelSelectable || level === 0;
	const selected = isGroupSelected(data.id);
	const expanded = isGroupExpanded(data.id);

	const childrenCount = (data.children?.length ?? 0) + (data.performers?.length ?? 0);

	const handleSelect = useCallback(() => {
		changeGroupSelected(data.id, !selected);
	}, [data, selected, changeGroupSelected]);

	const handleExpand = useCallback(() => {
		changeGroupExpanded(data.id, !expanded);
	}, [data, expanded, changeGroupExpanded]);

	return (
		<div className={classNames('delegation-tree-group', `delegation-tree-group_level_${level}`)}>
			<DelegationSelectOption
				rules={rules}
				level={level}
				selectable={selectable}
				selected={selected}
				selectInput={(
					<RadioButton
						checked={selected}
					/>
				)}
				infoComponent={(
					<WorkingGroupInfo
						className="delegation-select-option__info"
						name={data.name}
						leader={data.leader}
					/>
				)}
				onClick={handleSelect}
			/>

			{childrenCount > 0 ? (
				<InlineButton
					className="delegation-tree-group__expand-btn"
					leftIconClassName={classNames('delegation-tree-group__expand-btn-icon', {
						'delegation-tree-group__expand-btn-icon_reversed': expanded
					})}
					label={`${expanded ? 'Скрыть' : 'Показать'} участников (${childrenCount})`}
					leftIcon={<i className="tz-simple-arrow-16"/>}
					type="accent"
					onClick={handleExpand}
				/>
			) : null}

			{childrenCount > 0 ? (
				<AnimatePresence initial={false}>
					{expanded && (
						<motion.div
							className="delegation-tree-group__children"
							initial="collapsed"
							animate="opened"
							exit="collapsed"
							variants={animationVariants}
							transition={animationTransition}
						>
							<DelegationTreeGroupChildren
								data={data}
								level={level + 1}
								onlyFirstLevelSelectable={onlyFirstLevelSelectable}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			) : null}
		</div>
	);
};

DelegationTreeGroup.displayName = 'DelegationTreeGroup';