import {wsConnector} from '../wsConnector';
import ISavingProblemComment from '@tehzor/tools/interfaces/problemComments/ISavingProblemComment';
import {IEditProblemCommentResponse} from '../problemComment';

/**
 * Изменяет ответ на нарушение
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const makeProblemReplyEditRequest = (
	objectId: string,
	commentId: string,
	fields: ISavingProblemComment
) =>
	wsConnector.sendAuthorizedRequest<IEditProblemCommentResponse>('editProblemReply', {
		objectId,
		commentId,
		...fields
	});
