import React from 'react';
import classNames from 'classnames';

interface ITableBodyProps {
	className?: string;
	style?: React.CSSProperties;
	role?: string;
	children?: React.ReactNode;
}

const TableBody = ({className, style, role, children}: ITableBodyProps) => (
	<div
		className={classNames('table2__body', className)}
		style={style}
		role={role}
	>
		{children}
	</div>
);

export default TableBody;