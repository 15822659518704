import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {TaskIndicator} from '@tehzor/ui-components';
import {useTasksCountByStatus} from '../hooks/useTasksCountByStatus';

export const TaskIndicators = () => {
	const taskStatuses = useAppSelector(s => s.dictionaries.taskStatuses);
	const taskCounts = useTasksCountByStatus();

	return (
		<div className="check-list-page__indicators">
			{taskCounts.map(({status, count}) =>
				(count > 0 ? (
					<TaskIndicator
						key={status}
						statuses={taskStatuses}
						status={status}
						count={count}
					/>
				) : null))}
		</div>
	);
};
