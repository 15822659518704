import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

export function makeTitle(commentId?: string, comment?: IProblemComment) {
	if (commentId) {
		if (comment?.number !== undefined) {
			return `Ответ №${comment.number}`;
		}
		return 'Ответ';
	}
	return 'Новый ответ';
}
