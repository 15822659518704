import React, {useCallback, useContext, useMemo} from 'react';
import './ProblemTagsSetInfoMobileCell.less';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from '../DispatchActionCtx';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IconButton} from '@tehzor/ui-components/src/components/buttons';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {useProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';
import declination from '@tehzor/tools/utils/declination';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Tag} from '@tehzor/ui-components';
import {extractObjectsAsArrayByIds} from '@src/store/modules/dictionaries/objects/selectors';

const ProblemTagsSetInfoMobileCell = ({row}: CellProps<IEnrichedProblemTagsSet>) => {
	const set = row.original;
	const {company, objects, tagsCount, stages, allObjectsAvailable} = row.original;
	const perms = useProblemTagsSetPermissions();
	const objectsById = useAppSelector(s => extractObjectsAsArrayByIds(s, objects));
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);
	const dispatchAction = useContext(DispatchActionCtx);
	const data = useMemo(
		() =>
			stages.map(id => {
				const label = stagesMap[id]?.name || '';

				return {id, label};
			}),
		[stagesMap, stages]
	);
	const stagesText = data.map(i => i.label).join(', ');
	const tagsText
		= tagsCount && tagsCount > 0
			? `${tagsCount} ${declination(tagsCount || 0, ['метка', 'метки', 'меток'])}`
			: undefined;

	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [dispatchAction, row.original.id]);

	return (
		<div className="m-problem-tags-set-info-cell">
			<div className="m-problem-tags-set-info-cell__wrapper">
				<div className="m-problem-tags-set-info-cell__text">
					<div className="m-problem-tags-set-info-cell__name">{set.name}</div>
					{tagsText ? (
						<div className="m-problem-tags-set-info-cell__tags">{tagsText}</div>
					) : null}
					<div className="m-problem-tags-set-info-cell__stages">
						{stagesText}
					</div>
					<div className="m-problem-tags-set-info-cell__company">
						<strong>Компания:</strong>
						&nbsp;
						{company?.name}
					</div>
					<div className="m-problem-tags-set-info-cell__objects">
						{objectsById?.map((obj, i, arr) => {
								if (i < 2) {
									return (
										<Tag
											className="m-problem-tags-set-info-cell__objects-tag"
											size="small"
											key={obj.id}
											label={obj.name}
										/>
									);
								}
								if (i === 2) {
									return (
										<Tag
											className="m-problem-tags-set-info-cell__objects-tag"
											size="small"
											key={obj.id}
											label={`+${arr?.length - 2}`}
										/>
									);
								}
								return null;
								})}
					</div>
				</div>
			</div>
			{perms.canDelete && allObjectsAvailable && (
				<ClickPreventWrap style={{marginLeft: 'auto', padding: '0 25px'}}>
					<IconButton
						type="transparent"
						onClick={handleDelete}
					>
						<i className="tz-delete"/>
					</IconButton>
				</ClickPreventWrap>
			)}
		</div>
	);
};

export default ProblemTagsSetInfoMobileCell;
