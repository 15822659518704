import React from 'react';
import {differenceInDays, isBefore} from 'date-fns';
import classNames from 'classnames';

const FixDateIcon = ({
	plannedFixDate,
	actualFixDate
}: {
	plannedFixDate: number;
	actualFixDate?: number;
}) => {
	const nowDate = actualFixDate ?? new Date();
	if (differenceInDays(plannedFixDate, nowDate) > 5) {
		return null;
	}
	const isExpired = isBefore(plannedFixDate, nowDate);

	return (
		<i
			className={classNames(
				'fix-date-display-value__icon',
				isExpired
					? 'tz-alarm-clock fix-date-display-value__icon_expired'
					: 'tz-clock fix-date-display-value__icon_warning'
			)}
		/>
	);
};

export default FixDateIcon;
