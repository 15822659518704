import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {queryClient} from '@src/api/QueryClient';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';

export type IGetLatestUserInternalAcceptancesPayload = IGetInternalAcceptancesResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetInternalAcceptancesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке последних проверок');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список последних проверок текущего пользователя
 */
export const getLatestUserInternalAcceptances = () =>
	createApiAction<IGetInternalAcceptancesResponse>(request, success, failure, () =>
		queryClient.fetchQuery(internalAcceptancesQueryKeys.latest(), {staleTime: Infinity}));
