import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IStatus} from '@tehzor/ui-components/src/components/inputs/statuses/StatusSelect/utils/IStatus';
import {getSelectedStatus} from '@tehzor/ui-components/src/components/inputs/statuses/BaseStatusSelect/utils/getSelectedStatus';

export const TaskStatusMobileCell = (props: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'value'>) => {
	const {value} = props;
	const task = value;

	const statuses = useAppSelector(s => s.dictionaries.taskStatuses);

	const selectedStatus = getSelectedStatus<IStatus>(task?.status?.id, statuses);

	return (
		<div
			className="tasks-page__m-table-cell-status"
			style={{color: selectedStatus?.color || '#000'}}
		>
			{selectedStatus?.name}
		</div>
	);
};
