import React, {memo} from 'react';
import {SideProp} from 'react-laag/dist/Sides.d';

interface IArrowProps {
	style?: React.CSSProperties;
	layerSide: SideProp;
}

function getTransformationStyle(layerSide: SideProp) {
	switch (layerSide) {
		case 'top':
			return {transform: 'translate(-24px, -1px) rotate(180deg)'};
		case 'bottom':
			return {transform: 'translate(-24px, 1px)'};
		case 'left':
			return {transform: 'translate(-17px, -8px) rotate(90deg)'};
		case 'right':
			return {transform: 'translate(17px, -8px) rotate(-90deg)'};
		default:
			return {display: 'none'};
	}
}

const Arrow = ({style, layerSide}: IArrowProps) => (
	<svg
		style={{
			...getTransformationStyle(layerSide),
			...style
		}}
		width="48"
		height="16"
		viewBox="0 0 48 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_4_8)">
			<g filter="url(#filter0_dd_4_8)">
				<path
					d="M26.166 6.26014L33.1379 13.5352C34.6468 15.1097 36.7331 16 38.9138 16L9.08615 16C11.2669 16 13.3532 15.1097 14.8621 13.5352L21.834 6.26014C23.0151 5.02776 24.9849 5.02776 26.166 6.26014Z"
					fill="white"
				/>
			</g>
			<path
				d="M26.166 6.26014L33.1379 13.5352C34.6468 15.1097 36.7331 16 38.9138 16L9.08615 16C11.2669 16 13.3532 15.1097 14.8621 13.5352L21.834 6.26014C23.0151 5.02776 24.9849 5.02776 26.166 6.26014Z"
				fill="white"
			/>
		</g>
		<defs>
			<filter
				id="filter0_dd_4_8"
				x="-12"
				y="1.33585"
				width="72"
				height="34.6641"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood
					floodOpacity="0"
					result="BackgroundImageFix"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="8"/>
				<feGaussianBlur stdDeviation="6"/>
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.121569 0 0 0 0 0.203922 0 0 0 0 0.309804 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_4_8"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset/>
				<feGaussianBlur stdDeviation="1"/>
				<feComposite
					in2="hardAlpha"
					operator="out"
				/>
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="effect1_dropShadow_4_8"
					result="effect2_dropShadow_4_8"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect2_dropShadow_4_8"
					result="shape"
				/>
			</filter>
			<clipPath id="clip0_4_8">
				<rect
					width="48"
					height="16"
					fill="white"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default memo(Arrow);
