import * as types from '../constants';
import {addErrorToast, addInfoToast, addWarningToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeExportedInternalAcceptancesSendRequest} from '@src/api/backend/internalAcceptances';
import {ISendResponse} from '@src/interfaces/ISendResponse';

const request = () => ({type: types.SEND_REQUEST});

const success = (response: ISendResponse) => {
	addInfoToast('Отправлено', 'Документ успешно отправлен');
	if (response.warnings?.itemsLimit !== undefined) {
		addWarningToast(
			'Внимание',
			`превышен лимит записей (${String(response.warnings.itemsLimit)})`
		);
	}
	return {type: types.SEND_SUCCESS};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при отправке на email');
	}
	return {
		type: types.SEND_FAILURE,
		payload: error
	};
};

/**
 * Отправляет список проверок на email
 *
 * @param email email
 * @param templateId id шаблона экспорта
 * @param filters фильтры
 * @param sort сортировка
 * @param selected выбранные элементы
 * @param createDocument флаг создания документа
 */
export const sendExportedInternalAcceptances = (
	email: string,
	templateId: string,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[],
	createDocument?: boolean
) =>
	createApiAction<ISendResponse>(request, success, failure, () =>
		makeExportedInternalAcceptancesSendRequest(
			email,
			templateId,
			objectId,
			filters,
			sort,
			selected,
			createDocument
		));
