import {Column} from 'react-table';
import {IPreparedWorkAcceptance} from '../../interfaces/IPreparedWorkAcceptance';
import {MenuCell} from './MenuCell';

export const desktopColumns: Array<Column<IPreparedWorkAcceptance>> = [
	{
		Header: '№',
		accessor: 'number',
		minWidth: 60,
		width: 60
	},
	{
		Header: 'Объект',
		id: 'object',
		accessor: row => row.object?.name,
		width: 100
	},
	{
		id: 'status',
		Header: 'Статус',
		accessor: row => row.status,
		width: 100
	},
	{
		Header: 'Добавлено',
		accessor: 'createdAt',
		width: 80,
		sortDescFirst: true
	},
	{
		Header: 'Изменено',
		accessor: 'modifiedAt',
		width: 80,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'work-acceptances-page__d-table-menu',
		width: 68,
		minWidth: 68,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const desktopColumnsWithoutObject = desktopColumns.filter(item => item.id !== 'object');
