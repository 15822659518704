import React from 'react';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import ChecksFiltersRoutingPage from '@src/pages/filters/ChecksFiltersRoutingPage';
import {AllChecksPage} from '@src/pages/ChecksPage';
import {checksFilterRoutes} from './checksFilterRoutes';

export const checksRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllChecksPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<ChecksFiltersRoutingPage/>}/>,
		children: checksFilterRoutes
	}
];
