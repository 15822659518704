import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState, ISpaceTypeDecorationState} from './reducers';

const extractSpaceTypeDecorationData = (state: IState): ISpaceTypeDecorationState =>
	state.entities.spaceTypeDecorationSet || getInitialState();

/**
 * Извлекает текущий наборов типов отделки помещений
 */
export const extractSpaceTypeDecorationSet = createSelector(
	[extractSpaceTypeDecorationData],
	spaceTypeDecoration => spaceTypeDecoration.data
);
