import {createSelector} from 'reselect';
import {getInitialStateForPage, IOwnerAcceptancesPagesState, IOwnerAcceptancesPageState} from './reducers';
import {IState} from '@src/store/modules';

/**
 * Возвращает передачи собственникам для конкретного объекта
 */
export const extractOwnerAcceptancesData = createSelector(
	(state: IState) => state.entities.ownerAcceptances,
	(state: IState, objectId?: string) => objectId || 'all',
	(data: IOwnerAcceptancesPagesState, objectId: string): IOwnerAcceptancesPageState =>
		data[objectId] || getInitialStateForPage()
);

/**
 * Возвращает внутренние приёмки в виде массива
 */
export const extractOwnerAcceptancesAsArray = createSelector([extractOwnerAcceptancesData], data =>
	data.allIds.map(id => data.byId[id]));