import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetChecksResponse} from '@src/api/backend/checks';
import {queryClient} from '@src/api/QueryClient';
import {checksQueryKeys} from '@src/api/cache/checks/keys';

export type IGetLatestUserChecksPayload = IGetChecksResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetChecksResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке последних проверок');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список последних проверок текущего пользователя
//  */
export const getLatestUserChecks = () =>
	createApiAction<IGetChecksResponse>(request, success, failure, () =>
		queryClient.fetchQuery(checksQueryKeys.latest(), {staleTime: Infinity}));
