import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectsDisplayMode} from '@src/interfaces/ObjectsDisplayMode';
import {changeDisplayMode} from '@src/store/modules/settings/pages/objects/actions';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {SwitchMenu, SwitchMenuButton, SwitchMenuOption} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import React, {useCallback} from 'react';
import {LegendButton} from '../legend/LegendButton';

const options = [
	{key: ObjectsDisplayMode.SCHEMA, content: <i className="tz-schema-24"/>},
	{key: ObjectsDisplayMode.LIST, content: <i className="tz-list-24"/>}
];

interface IObjectsControlsProps {
	text: string;
}

export const ObjectsControls = ({text}: IObjectsControlsProps) => {
	const {displayMode} = useAppSelector(extractObjectsPageSettings);
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();

	const handleChange = useCallback((value: ObjectsDisplayMode) => {
		dispatch(changeDisplayMode(value));
	}, []);

	return (
		<div className="objects-page__controls">
			<div className="objects-page__controls-amount">
				{text}
			</div>
			<div className="objects-page__controls-actions">
				<LegendButton/>
				{isDesktop ? (
					<SwitchMenu
						value={displayMode}
						onChange={handleChange}
						className="objects-page__controls-actions__mode-switch"
					>
						{options.map(option => (
							<SwitchMenuOption
								value={option.key}
								key={option.key}
							>
								{option.content}
							</SwitchMenuOption>
						))}
					</SwitchMenu>
				) : (
					<SwitchMenuButton
						className="objects-page__controls-actions__mode-switch"
						options={options}
						value={displayMode}
						onChange={handleChange}
					/>
				)}
			</div>
		</div>
	);
};