import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {
	makeSpaceTypeDecorationGetRequest,
	makeSpaceTypeDecorationsGetAllRequest
} from '@src/api/backend/spaceTypeDecoration';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';

export type IGetSpaceTypeDecorationPayload = ISpaceTypeDecorationSet[];

const request = (decorationSetId: string) => ({
	type: types.GET_REQUEST,
	payload: {decorationSetId}
});

const success = (response: ISpaceTypeDecorationSet) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке набора типов отделки');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает массив типов отделки помещений по Id набора
 *
 * @param typeDecorationSetId id набора типов отделки помещения
 */
export const getSpaceTypeDecorationsById = (typeDecorationSetId: string) =>
	createApiAction<INormalizedData<ISpaceTypeDecoration[]>>(
		() => request(typeDecorationSetId),
		success,
		failure,
		() => makeSpaceTypeDecorationGetRequest(typeDecorationSetId)
	);

/**
 * Получает массив всех типов отделки помещений
 */
export const getSpaceTypeDecorationsAll = () =>
	createApiAction<INormalizedData<ISpaceTypeDecoration[]>>(request, success, failure, () =>
		makeSpaceTypeDecorationsGetAllRequest());
