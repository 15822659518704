import React from 'react';
import './ObjectNameDesktopCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import classNames from 'classnames';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {CellProps} from 'react-table';

const ObjectNameDesktopCell = ({row}: CellProps<IParentObject>) => {
	const object = row.original;

	return (
		<div className="object-name-info-cell">
			<div
				className="object-name-info-cell__name"
				style={{paddingLeft: `${row.depth * 40}px`}}
			>
				<ClickPreventWrap>
					<div style={{display: 'flex'}}>
						<TableRowSelect {...row.getToggleRowSelectedProps()}/>

						{row.canExpand ? (
							<div
								{...row.getToggleRowExpandedProps({
									style: {
										marginLeft: '16px'
									}
								})}
							>
								{row.isExpanded ? (
									<div style={{color: '#3391FF'}}>
										<i
											className={classNames(
												'tz-simple-arrow-24',
												'object-name-info-cell__arrow',
												'object-name-info-cell__arrow_expanded'
											)}
										/>
									</div>
								) : (
									<div>
										<i
											className={classNames(
												'tz-simple-arrow-24',
												'object-name-info-cell__arrow'
											)}
										/>
									</div>
								)}
							</div>
						) : null}
					</div>
				</ClickPreventWrap>

				<div className="object-name-info-cell__info">
					<div className="object-name-info-cell__info-text">{object.name}</div>
				</div>
			</div>
		</div>
	);
};

export default ObjectNameDesktopCell;
