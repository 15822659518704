import {IGetTasksResponse, requestTasks} from '@src/api/backend/tasks';
import * as types from '@src/store/modules/pages/checkLists/constants/tasks';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';

export type ICheckGetTasksPayload = IGetTasksResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetTasksResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response
	} as ICheckGetTasksPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка задач');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список задач для чек-листов
 */
export const getTasks
	= (
		checkListIds?: string[],
		spaceId?: string,
		checkItemId?: string,
		workAcceptanceIds?: string[]
	): AppThunkAction<Promise<IGetTasksResponse>> =>
	dispatch =>
		dispatch(
			createApiAction<IGetTasksResponse>(request, success, failure, () => {
				const filters: Record<string, unknown> = {checkListIds};
				if (spaceId) {
					filters.spaces = [spaceId];
				}

				if (checkItemId) {
					filters.checkItemIds = [checkItemId];
				}

				if (workAcceptanceIds?.length) {
					filters.workAcceptanceIds = workAcceptanceIds;
				}

				return requestTasks(filters, {createdAt: false}, 0, 1000);
			})
		);
