import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useReducer} from 'react';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {errorsFns, init} from '@src/core/hooks/states/useEditableCheckListState/state';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';

export const useEditableCheckListState = (data: {
	checkList?: ICheckList;
	checkItems?: ICheckItem[];
}) => useReducer(createReducer(init, errorsFns), data, init);
