import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import '../ProblemHistoryDialog.less';
import {IWorkingGroupsState} from '@src/store/modules/dictionaries/workingGroups/reducers';
import {IProblemStory} from '@tehzor/tools/interfaces/problemStories/IProblemStory';
import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {IEntityState} from '@tehzor/tools/utils/reducersHandlers';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {IProblemStatusesState} from '@src/store/modules/dictionaries/problemStatuses/reducers';
import {extractProblemStoriesAsArray} from '@src/store/modules/pages/problem/selectors/stories';
import {extractCategories} from '@src/store/modules/dictionaries/categories/selectors';
import {extractPlans} from '@src/store/modules/dictionaries/plans/selectors';
import {IUsersState} from '@src/store/modules/dictionaries/users/reducers';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {
	createStatusDifference,
	createCategoryDifference,
	createAttachmentsDifference,
	createRespUsersDifference,
	createDateDifference,
	createReasonDifference,
	createLocationDifference,
	createDescriptionDifference,
	createCriticalDifference,
	createPrescriptionDifference,
	createProblemTagsDifference,
	createMarkerCommentDifference
} from '@src/utils/createHistoryData';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {extractProblemCommentsStoriesAsArray} from '@src/store/modules/pages/problem/selectors/commentsStories';
import {IProblemCommentStory} from '@tehzor/tools/interfaces/problemCommentsStories/IProblemCommentStory';
import {
	createCommentCreation,
	createCommentDeletion,
	createCommentDifference
} from '@src/utils/createHistoryData/createCommentDifference';
import {extractProblemTagsAsArray} from '@src/store/modules/dictionaries/problemTags/selectors';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {extractProblemMarkersStoriesAsArray} from '@src/store/modules/pages/problem/selectors/markerStories';
import {IMarkerStory} from '@tehzor/tools/interfaces/markers/IMarkerStory';
// import {createMarkerCommentDifference} from '@src/utils/createHistoryData/createMarkerCommentDifference';

function isAttachmentsEqual(arr1: IAttachment[], arr2: IAttachment[]) {
	let flag = true;
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i].id !== arr2[i].id) {
			flag = false;
		}
	}
	return flag;
}

function isArrayEqual(arr1: string[] | undefined, arr2: string[] | undefined) {
	let flag = true;
	if (arr1 && arr2) {
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				flag = false;
			}
		}
	}

	return flag;
}

function compareMarkersStories(markersHistories: IMarkerStory[], users: IUsersState) {
	const result: IHistoryDifference[] = [];
	markersHistories.forEach(story => {
		const isFinished =
			story.current && !!story?.dateEnd && story?.dateEnd < new Date().getTime();
		const latestItem = !isFinished
			? markersHistories.reduce((prev, current) => {
					if (
						current.markerId === story.markerId &&
						current.id !== story.id &&
						current.createdAt &&
						story.createdAt &&
						current.createdAt < story.createdAt &&
						!prev
					) {
						return current;
					}
					return prev;
			  }, undefined)
			: story;

		// запись об изменении комментария к точке ТОЛЬКО в том случае если менялся текст комментария
		if (story.data.description || latestItem?.data.description) {
			result.push({
				data: [
					createMarkerCommentDifference(
						latestItem
							? {
									name: latestItem?.data.name,
									description: latestItem?.data.description
							  }
							: undefined,
						isFinished
							? undefined
							: {
									name: story.data.name,
									description: story.data.description
							  }
					)
				],
				isCreated: !latestItem && !isFinished,
				isDeleted: !story.data.description || isFinished,
				createdAt: story.createdAt,
				createdBy: story.createdBy ? users.byId[story.createdBy] : undefined
			});
		}
	});
	return result;
}

function compareCommentsStories(commentsHistories: IProblemCommentStory[], users: IUsersState) {
	const result: IHistoryDifference[] = [];

	commentsHistories.forEach(story => {
		if (result.some(item => item.id === story.data.commentId)) {
			const latestItem = commentsHistories.reduce((prev, current) => {
				if (
					current.data.commentId === story.data.commentId &&
					current.id !== story.id &&
					current.createdAt &&
					story.createdAt &&
					current.createdAt < story.createdAt &&
					(!prev.createdAt || current.createdAt > prev.createdAt)
				) {
					return current;
				}
				return prev;
			});

			// запись об удалении комментария
			if (story.data.deleted) {
				result.push({
					data: [
						createCommentDeletion(
							{
								description: latestItem.data.description,
								attachments: latestItem.data.attachments
							},
							story.data.official
						)
					],
					id: story.data.commentId,
					createdAt: story.createdAt,
					createdBy: story.createdBy ? users.byId[story.createdBy] : undefined,
					isDeleted: true
				});
			} else {
				// запись об изменении комментарий
				result.push({
					data: [
						createCommentDifference(
							{
								description: latestItem.data.description,
								attachments: latestItem.data.attachments
							},
							{
								description: story.data.description,
								attachments: story.data.attachments
							},
							story.data.official
						)
					],
					id: story.data.commentId,
					createdAt: story.createdAt,
					createdBy: story.createdBy ? users.byId[story.createdBy] : undefined
				});
			}
		} else {
			// запись о создании комментария
			result.push({
				data: [
					createCommentCreation(
						{
							description: story.data.description,
							attachments: story.data.attachments
						},
						story.data.official
					)
				],
				id: story.data.commentId,
				createdAt: story.createdAt,
				createdBy: story.createdBy ? users.byId[story.createdBy] : undefined,
				isCreated: true
			});
		}
	});
	return result;
}
function compare(
	next: IProblemStory,
	prev: IProblemStory,
	users: IUsersState,
	workingGroups: IWorkingGroupsState,
	categories: IEntityState<ICategory>,
	plans: IEntityState<IPlan>,
	problemStatuses: IProblemStatusesState,
	problemTags: IProblemTag[]
): IHistoryDifference {
	const data: IHistoryData[] = [];
	if (next.data.critical !== prev.data.critical) {
		data.push(createCriticalDifference(prev.data.critical, next.data.critical));
	}
	if (next.data.description !== prev.data.description) {
		data.push(createDescriptionDifference(prev.data.description, next.data.description));
	}
	if (next.data.prescription !== prev.data.prescription) {
		data.push(createPrescriptionDifference(prev.data.prescription, next.data.prescription));
	}
	if (next.data.status !== prev.data.status) {
		data.push(createStatusDifference(prev.data.status, next.data.status, problemStatuses));
	}
	if (next.data.categoryId !== prev.data.categoryId) {
		data.push(createCategoryDifference(prev.data.categoryId, next.data.categoryId, categories));
	}
	if (next.data.plannedFixDate !== prev.data.plannedFixDate) {
		data.push(createDateDifference(prev.data.plannedFixDate, next.data.plannedFixDate));
	}
	if (next.data.reason !== prev.data.reason) {
		data.push(createReasonDifference(prev.data.reason, next.data.reason));
	}
	if (
		next.data.displayLocation !== prev.data.displayLocation ||
		next.data.floor !== prev.data.floor ||
		next.data.planId !== prev.data.planId
	) {
		data.push(
			createLocationDifference(
				prev.data.displayLocation,
				prev.data.floor,
				prev.data.planId,
				next.data.displayLocation,
				next.data.floor,
				next.data.planId,
				plans
			)
		);
	}
	if (
		next.data.activeGroup !== prev.data.activeGroup ||
		next.data.respUsers?.length !== prev.data.respUsers?.length ||
		(next.data.respUsers?.length === prev.data.respUsers?.length &&
			!isArrayEqual(next.data.respUsers, prev.data.respUsers))
	) {
		data.push(
			createRespUsersDifference(
				prev.data.activeGroup,
				prev.data.respUsers,
				next.data.activeGroup,
				next.data.respUsers,
				users,
				workingGroups
			)
		);
	}
	if (
		next.data.attachments?.length !== prev.data.attachments?.length ||
		(next.data.attachments &&
			prev.data.attachments &&
			next.data.attachments.length === prev.data.attachments.length &&
			!isAttachmentsEqual(next.data.attachments, prev.data.attachments))
	) {
		data.push(createAttachmentsDifference(prev.data.attachments, next.data.attachments));
	}

	if (
		next.data.problemTags?.length !== prev.data.problemTags?.length ||
		(next.data.problemTags?.length === prev.data.problemTags?.length &&
			!isArrayEqual(next.data.problemTags, prev.data.problemTags))
	) {
		data.push(
			createProblemTagsDifference(prev.data.problemTags, next.data.problemTags, problemTags)
		);
	}

	return {
		data,
		createdAt: next.createdAt,
		createdBy: next.createdBy ? users.byId[next.createdBy] : undefined
	};
}

export function useEnrichedHistories(objectId: string): IHistoryDifference[] {
	const histories = useAppSelector(extractProblemStoriesAsArray);
	const commentsHistories = useAppSelector(extractProblemCommentsStoriesAsArray);
	const problemTags = useAppSelector(extractProblemTagsAsArray);
	const markersHistories = useAppSelector(extractProblemMarkersStoriesAsArray);
	const problemStatuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const categories = useAppSelector(extractCategories);
	const users = useAppSelector(s => s.dictionaries.users);
	const workingGroups = useAppSelector(s => s.dictionaries.workingGroups);
	const plans = useAppSelector(s => extractPlans(s, objectId));

	return useMemo(() => {
		const result: IHistoryDifference[] = [];

		for (let i = 0; i < histories.length - 1; i++) {
			result.push(
				compare(
					histories[i + 1],
					histories[i],
					users,
					workingGroups,
					categories,
					plans,
					problemStatuses,
					problemTags
				)
			);
		}
		// убираем из истории изменений нарушения пустые записи появившиеся из-за изменений комменатриев
		const filteredProblemStories = result.filter(item => item.data && item.data?.length > 0);

		// добавляем запись о создании сущности
		if (histories[0]) {
			filteredProblemStories.push({
				createdAt: histories[0].createdAt,
				createdBy: histories[0].createdBy ? users.byId[histories[0].createdBy] : undefined
			});
		}

		const commentsStoriesResult = compareCommentsStories(commentsHistories, users);
		const markersStoriesResult = compareMarkersStories(markersHistories, users);

		const sum = [...filteredProblemStories, ...commentsStoriesResult, ...markersStoriesResult];
		return sum;
	}, [
		histories,
		users,
		workingGroups,
		categories,
		plans,
		problemStatuses,
		commentsHistories,
		markersHistories
	]);
}
