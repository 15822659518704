import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeProblemTagDeleteRequest} from '@src/api/backend/problemTags';

export interface IDeleteProblemtagPayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => {
	addSuccessToast('Удалено', 'Метка успешно удалена');
	return {
		type: types.DELETE_SUCCESS,
		payload: {id}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении метки');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет метку нарушения
 *
 * @param id id метки
 * @param problemTagsSetId id набора
 */
export const deleteProblemTag = (id: string, problemTagsSetId: string) =>
	createApiAction(
		request,
		() => success(id),
		failure,
		() => makeProblemTagDeleteRequest(id, problemTagsSetId)
	);
