import {wsConnector} from '../wsConnector';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

export type IEditProblemStatusResponse = ILinkedProblem;

/**
 * Изменяет статус нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param status статус
 */
export const makeProblemStatusEditRequest = (
	objectId: string,
	problemId: string,
	status: ProblemStatusId
) =>
	wsConnector.sendAuthorizedRequest<IEditProblemStatusResponse>('editProblemStatus', {
		objectId,
		problemId,
		status
	});
