import {wsConnector} from '../wsConnector';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

export type IGetTaskResponse = ITask;

/**
 * Получает рабочую группу по id
 *
 * @param taskId id рабочей группы
 */
export const requestTask = (taskId: string) =>
	wsConnector.sendAuthorizedRequest<IGetTaskResponse>('getTask', {taskId});
