import React from 'react';
import {SpaceStatusSelect} from '@tehzor/ui-components';
import {IPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceStatusesDataByObjectId} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {SpaceStatusHook} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useEditSpaceStatus} from '@src/core/hooks/mutations/spaces/useEditSpaceStatus';

interface IStatusProps {
	objectId: string;
	spaceId: string;
	value: string;
	permissions: IPermissions;
}

const Status = ({objectId, spaceId, value, permissions}: IStatusProps) => {
	const statuses = useAppSelector(s => extractSpaceStatusesDataByObjectId(s, objectId));
	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);
	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const editSpaceStatus = useEditSpaceStatus(objectId);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите изменить статус помещения?',
		'Все текущие чек-листы будут приняты, а связанные с ними нарушения - отмечены как "Исправлены"'
	);

	const [, handleChange] = useAsyncFn(
		async (v: string) => {
			const nextStatus = statuses.byId[v];

			// если указан хук - запрашиваем подтверждение действия
			if (
				nextStatus.hooks?.includes(SpaceStatusHook.ACCEPT_CHECK_LISTS)
				&& !(await getClosingConfirmation())
			) {
				return;
			}
			await editSpaceStatus({objectId, pageObjectId, spaceId, status: v, online});
		},
		[objectId, spaceId, statuses, online]
	);

	return (
		<>
			<SpaceStatusSelect
				stagesMap={stagesMap}
				statuses={statuses}
				disabled={!permissions.canEditStatus}
				value={value}
				onChange={handleChange}
			/>

			{closingDialog}
		</>
	);
};

export default Status;
