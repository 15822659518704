import React, {memo} from 'react';
import {IOfflineJournalEntity} from '../../OfflineJournalPage';
import './OfflineJournalItem.less';

interface IOfflineJournalItemProps {
	offlineJournalEntity: IOfflineJournalEntity;
}

export const OfflineJournalItem = memo((props: IOfflineJournalItemProps) => {
	const {offlineJournalEntity} = props;
	const {user, object, entity, location} = offlineJournalEntity;

	return (
		<div className="offline-journal-item">
			{object?.objectName && (
				<div className="offline-journal-item__info">
					<span>Название объекта:</span>
					{object.objectName}
				</div>
			)}
			{object?.locationName && (
				<div className="offline-journal-item__info">
					<span>Название места:</span>
					{object.locationName}
				</div>
			)}
			{entity?.entityId && (
				<div className="offline-journal-item__info">
					<span>Локальный номер:</span>
					{entity.entityLocalNumber}
				</div>
			)}
			{entity?.checkListName && (
				<div className="offline-journal-item__info">
					<span>Название чек листа:</span>
					{entity.checkListName}
				</div>
			)}
			{entity?.checkItemName && (
				<div className="offline-journal-item__info">
					<span>Название категории:</span>
					{entity.checkItemName}
				</div>
			)}
			{entity?.newRecordStatus && (
				<div className="offline-journal-item__info">
					<span>Новый статус:</span>
					{entity.newRecordStatus}
				</div>
			)}
			{entity?.newIndicators && (
				<div className="offline-journal-item__info">
					<span>Новые индикаторы:</span>
					{entity.newIndicators.join(', ')}
				</div>
			)}
			{entity?.description && (
				<div className="offline-journal-item__info">
					<span>Описание:</span>
					{entity.description}
				</div>
			)}
			{location?.names && location?.names.length && (
				<div className="offline-journal-item__info">
					<span>Место:</span>
					{location?.names.join(', ')}
				</div>
			)}
			{user?.userName && (
				<div className="offline-journal-item__info">
					<span>Имя собственника:</span>
					{user.userName}
				</div>
			)}
			{user?.phone && (
				<div className="offline-journal-item__info">
					<span>Номер телефона:</span>
					{user.phone}
				</div>
			)}
			{user?.email && (
				<div className="offline-journal-item__info">
					<span>E-mail:</span>
					{user.email}
				</div>
			)}
		</div>
	);
});
