import {createSelector} from '@reduxjs/toolkit';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {IState} from '../..';
import {ISpaceStatusesState} from './reducers';
import {getLoadableEntitiesInitialState} from '@tehzor/tools/core/storeHelpers/reducers';

export const extractSpaceStatusesData = createSelector(
	(s: IState) => s.dictionaries.spaceStatuses,
	data => data || getLoadableEntitiesInitialState<ISpaceStatus>()
);

/**
 * Извлекает все статусы помещений в виде массива
 */
export const extractSpaceStatusesAsArray = createSelector(
	extractSpaceStatusesData,
	({allIds, byId}) => allIds.map(id => byId[id])
);

/**
 * Извелекает сгруппированные статусы помещений по id набора
 */
export const extractAllSpaceStatuses = createSelector(
	extractSpaceStatusesAsArray, statuses => {
		const allStatuses: {[setId: string]: ISpaceStatusesState} = {};

		for (const status of statuses) {
			if (!allStatuses[status.spaceStatusesSetId]?.allIds.length) {
				allStatuses[status.spaceStatusesSetId] = {
					allIds: [],
					byId: {},
					loaded: true
				};
			}

			allStatuses[status.spaceStatusesSetId].allIds.push(status.id);
			allStatuses[status.spaceStatusesSetId].byId[status.id] = status;
		}

		return allStatuses;
	}
);

/**
 * Извелекает сгруппированные статусы помещений по id набора в виде массива
 */
export const extractAllSpaceStatusesAsArray = createSelector(
	extractSpaceStatusesAsArray, statuses => {
		const allStatuses: {[setId: string]: ISpaceStatus[]} = {};

		for (const status of statuses) {
			if (!allStatuses[status.spaceStatusesSetId]?.length) {
				allStatuses[status.spaceStatusesSetId] = [];
			}

			allStatuses[status.spaceStatusesSetId].push(status);
		}

		return allStatuses;
	}
);

/**
 * Извлекает статусы помещений для конкретного набора
 */
export const extractSpaceStatusesBySetId = createSelector(
	[
		extractAllSpaceStatuses,
		(s: IState, spaceStatusesSetId: string) => spaceStatusesSetId
	],
	(allStatuses, spaceStatusesSetId) => {
		let allIds: string[] = [];
		let byId: {[id: string]: ISpaceStatus} = {};

		if (allStatuses[spaceStatusesSetId]) {
			allIds = allStatuses[spaceStatusesSetId].allIds;
			byId = allStatuses[spaceStatusesSetId].byId;
		}

		const total = allIds.length;
		return {allIds, byId, total};
	}
);

/**
 * Извлекает статусы помещений для конкретного набора в виде массива
 */
export const extractSpaceStatusesAsArrayBySetId = createSelector(
	[
		extractSpaceStatusesBySetId,
		(s: IState) => s.dictionaries.objectStages.byId
	],
	({allIds, byId}, stagesMap) => {
		const statuses = allIds.map(id => byId[id]);
		return statuses.sort((prev, next) => {
			const prevStage = stagesMap?.[prev.stage];
			const nextStage = stagesMap?.[next.stage];

			if (prevStage?.name < nextStage?.name) {
				return 1;
			}
			if (prevStage?.name > nextStage?.name) {
				return -1;
			}
			return 0;
		});
	}
);
