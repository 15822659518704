import React from 'react';
import {Dialog, IconButton} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {LegendContent} from './LegendContent';

export const LegendDialog = () => {
	const [isOpen, toggle] = useToggle(false);

	return (
		<>
			<IconButton
				className="spaces-page__m-legend-button"
				type="transparent"
				active={isOpen}
				onClick={toggle}
			>
				<i className="tz-info-24"/>
			</IconButton>

			<Dialog
				className={{root: 'spaces-page__legend-dialog'}}
				isOpen={isOpen}
				onRequestClose={toggle}
			>
				<LegendContent/>
			</Dialog>
		</>
	);
};
