import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetCategoriesResponse} from '@src/api/backend/categories';
import {queryClient} from '@src/api/QueryClient';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';

export type IGetCategoriesPayload = IGetCategoriesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetCategoriesResponse, categoriesSetId: string) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		categoriesSetId
	} as IGetCategoriesPayload
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке видов работ');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список видов работ по id набора
 *
 */
export const getCategories = () =>
	createApiAction<IGetCategoriesResponse>(request, success, failure, () =>
		queryClient.fetchQuery(categoriesQueryKeys.list(), {staleTime: Infinity}));
