import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeInternalAcceptancesExportRequest} from '@src/api/backend/internalAcceptances';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addExportToast} from "@src/components/ExportToast/addExportToast";

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse) => {
	if (response.link) {
		addExportToast(
			'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document,
			undefined,
			undefined
		);
	}
	if (response.warnings?.itemsLimit !== undefined) {
		addWarningToast(
			'Внимание',
			`превышен лимит записей (${String(response.warnings.itemsLimit)})`
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при экспорте');
	}
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует список проверок
 *
 * @param templateId id шаблона экспорта
 * @param filters фильтры
 * @param sort сортировка
 * @param selected выбранные элементы
 * @param createDocument флаг создания документа
 *
 */
export const exportInternalAcceptances = (
	templateId: string,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[],
	createDocument?: boolean
) =>
	createApiAction<IExportResponse>(request, success, failure, () =>
		makeInternalAcceptancesExportRequest(templateId, objectId, filters, sort, selected, createDocument));
