import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import './ActualFixDate.less';

interface IFixDateProps {
	value: number;
}

export const ActualFixDate = ({value}: IFixDateProps) => {
	const formatted = format(value, longDateTimeFormat, {locale: ru});

	return (
		<EntityGridItem
			icon={<i className="tz-calendar-done-20"/>}
			label="Дата устранения"
		>
			<div className="actual-fix-date-view-field-value">{formatted}</div>
		</EntityGridItem>
	);
};
