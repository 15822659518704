import React from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {useCacheStates} from '../hooks/useCacheStates';

export const CheckListsCacheStatus = () => {
	const [isListsFetching, isListsError, isListsStale, isListsPaused, listsDataUpdatedAt]
		= useCacheStates(checkListsQueryKeys.list());
	const [isItemsFetching, isItemsError, isItemsStale, isItemsPaused, itemsDataUpdatedAt]
		= useCacheStates(checkItemsQueryKeys.list());
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Чек-листы"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={Math.max(listsDataUpdatedAt ?? 0, itemsDataUpdatedAt ?? 0)}
			error={
				isListsError || isItemsError
					? 'Ошибка'
					: isListsStale || isItemsStale
					? 'Данные устарели'
					: undefined
			}
			inProgress={isListsPaused || isListsFetching || isItemsPaused || isItemsFetching}
		/>
	);
};
