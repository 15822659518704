export enum SpaceTypeId {
	APARTMENTS = 'apartments',
	COMMERCIAL_SPACES = 'commercial-spaces',
	COMMON_AREAS = 'common-areas',
	UNDERGROUND_PARKING = 'underground-parking',
	GROUND_PARKING = 'ground-parking',
	STOREROOMS = 'storerooms',
	TECHNICAL_SPACES = 'technical-spaces',
	TECHNICAL_UNDERGROUND = 'technical-underground',
	SYSTEMS = 'systems',
	REST = 'rest',
	FLATS = 'flats',
	BKT = 'bkt',
	PON = 'pon',
	IMPROVEMENT = 'improvement',
	LINEAR_OBJECTS = 'linear-objects',
	CABINETS = 'cabinets',
	AUXILIARY_SPACES = 'auxiliary-spaces',
	OFFICES = 'offices',

}

export interface ISpaceType {
	id: SpaceTypeId;
	name: string;
	singularName: string;
	useInTitle?: boolean;
	notBeOwned?: boolean;
}
