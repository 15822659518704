import {PrivateRoute} from '@src/containers/PrivateRoute';
import {TasksPage} from '@src/pages/TasksPage/TasksPage';
import {TasksFiltersRoutingPage} from '@src/pages/filters/TasksFiltersRoutingPage';
import React from 'react';
import {tasksFilterRoutes} from './tasksFilterRoutes';
import TaskViewPage from '@src/pages/TaskViewPage';

export const tasksRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<TasksPage/>}/>
	},
	{
		path: ':taskId',
		element: <PrivateRoute element={<TaskViewPage />} />
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<TasksFiltersRoutingPage/>}/>,
		children: tasksFilterRoutes
	}
];
