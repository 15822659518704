import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from "@tehzor/tools/interfaces/IExportResponse";

/**
 * Отправляет запрос на экспорт чеклиста
 */
export const makeCheckListExportRequest = async (
	templateId: string,
	objectId: string,
	spaceId: string,
	checkListId: string,
	problems?: string[],
	createDocument?: boolean
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		objectId,
		spaceId,
		problems
	};
	const response = await httpRequests.withToken.get<IExportResponse>(
		`check-lists/${checkListId}/export`,
		{
			params
		}
	);
	return response.data;
};
