import {IEditableWorkingGroupState} from '@src/core/hooks/states/useEditableWorkingGroupState';
import React, {useCallback, useState} from 'react';
import {EditableResponsibilityRulesDialog} from '../EditableResponsibilityRulesDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractResponsibilityRulesForWorkingGroupAsArray
} from '@src/store/modules/dictionaries/responsibilityRules/selectors';
import {extractCompanyWithContractorsUsersAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {extractWorkingGroupsByParentIdAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {extractObjectsAsArrayByIds} from '@src/store/modules/dictionaries/objects/selectors';

interface IUserTarget {
	userId: string;
	groupId?: undefined;
}

interface IGroupTarget {
	groupId: string;
	userId?: undefined;
}
export function useEditableResponsibilityRulesDialog(
	editingState: IEditableWorkingGroupState,
	workingGroupId?: string
): [React.ReactNode, (target: IUserTarget | IGroupTarget) => void] {
	const rules = useAppSelector(s =>
		extractResponsibilityRulesForWorkingGroupAsArray(s, workingGroupId)
	);
	const users = useAppSelector(s =>
		extractCompanyWithContractorsUsersAsArray(s, editingState?.companyId)
	);
	const groups = useAppSelector(s => extractWorkingGroupsByParentIdAsArray(s, workingGroupId));
	const objectsArray = useAppSelector(s => extractObjectsAsArrayByIds(s, editingState?.objects));

	const [isOpen, setOpen] = useState<boolean>(false);
	const [target, setTarget] = useState<IUserTarget | IGroupTarget | undefined>(undefined);

	const open = useCallback((target: IUserTarget | IGroupTarget) => {
		setTarget(target);
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const objectsIds = objectsArray.map(object => object.id);

	const dialog = target ? (
		<EditableResponsibilityRulesDialog
			target={target}
			rules={rules}
			groups={groups}
			users={users}
			objectsIds={objectsIds}
			isOpen={isOpen}
			onClose={close}
			editingState={editingState}
		/>
	) : null;

	return [dialog, open];
}
