import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {
	IGetInspectionPayload,
	IEditInspectionPayload,
	IGetInspectionRequestPayload,
	IDeleteInspectionPayload
} from './actions';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import config from '@src/core/config';

const {entitiesCacheTime} = config;

export interface IInspectionState {
	data?: ILinkedInspection;
	expires?: number;
}

const getInitialState = (): IInspectionState => ({expires: undefined});

const isAnotherInspection = (state: IInspectionState, payload: IEditInspectionPayload) =>
	!(state.data?.id === payload.id);

const updateInspection = (state: IInspectionState, {payload}: {payload: IEditInspectionPayload}) =>
	(isAnotherInspection(state, payload)
		? state
		: {
				...state,
				data: {...state.data, ...payload},
				expires: Date.now() + entitiesCacheTime * 1000
		  });

export default createReducer<IInspectionState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: {payload: IGetInspectionRequestPayload}) => {
			if (state.data?.id === payload.inspectionId) {
				return {...state, data: undefined};
			}
			return getInitialState();
		},
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetInspectionPayload}) => {
			state.data = payload;
			state.expires = Date.now() + entitiesCacheTime * 1000;
		},
		[types.GET_FAILURE]: getInitialState,
		[types.EDIT_SUCCESS]: updateInspection,
		[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteInspectionPayload}) => {
			if (!(state.data?.id === payload.inspectionId)) {
				return state;
			}
			return getInitialState();
		},
		[CLEAR_STORE]: getInitialState
	}
);
