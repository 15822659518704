import React, {useCallback} from 'react';
import {ExpandablePanel, IconButton} from '@tehzor/ui-components';
import {IObjectsGroup} from '../../hooks/useObjectsGroups';
import classNames from 'classnames';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ObjectItem} from '../item/ObjectItem';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {changeGroupVisible} from '@src/store/modules/settings/pages/objects/actions';
import './ObjectsGroup.less';

const arrowIcon = <i className="tz-simple-arrow-24 objects-group__arrow-btn-icon"/>;

interface IObjectsGroupProps {
	data: IObjectsGroup;
	isSubPlates?: boolean;
}

export const ObjectsGroup = ({data, isSubPlates}: IObjectsGroupProps) => {
	const {companyId, companyName, objects} = data;
	const dispatch = useAppDispatch();

	const {visibleGroups} = useAppSelector(extractObjectsPageSettings);
	const visible = visibleGroups[companyId] === undefined ? true : visibleGroups[companyId];

	const toggle = useCallback(() => {
		dispatch(changeGroupVisible(companyId, !visible));
	}, [companyId, visible]);

	return (
		<div className={classNames('objects-group', {'objects-group_visible': visible})}>
			{!isSubPlates && (
				<div className="objects-group__header">
					<div className="objects-group__title">{companyName}</div>

					<div className="objects-group__buttons">
						<div className="objects-group__arrow-btn-wrap">
							<IconButton
								className="objects-group__arrow-btn"
								onClick={toggle}
							>
								{arrowIcon}
							</IconButton>
						</div>
					</div>
				</div>
			)}

			<ExpandablePanel expanded={visible}>
				<div className="objects-group__list">
					{objects.map(object => (
						<div
							key={object.id}
							className="objects-group__list-item"
						>
							<ObjectItem object={object}/>
						</div>
					))}
				</div>
			</ExpandablePanel>
		</div>
	);
};
