import React, {useCallback, useState} from 'react';
import './EditableCheckAttachmentsDialog.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {editCheck} from '@src/store/modules/entities/check/actions/edit';
import {useEditableCheckAttachments} from '../EditableCheckAttachments/hooks/useEditableCheckAttachments';

interface IEditableOwnerAcceptanceAttachmentsDialogProps {
	objectId: string;
	checkId: string;
	check: ICheck;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования вложений проверки
 */
export const EditableCheckAttachmentsDialog = ({
	objectId,
	checkId,
	check,
	isOpen,
	onClose
}: IEditableOwnerAcceptanceAttachmentsDialogProps) => {
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);

	const [fields, getSavingData, reset, isBlocking] = useEditableCheckAttachments(
		objectId,
		check,
		saving
	);
	const save = useCallback(async () => {
		setSaving(true);
		if (checkId) {
			const savingData = await getSavingData();
			if (savingData) {
				if (!networkStatus) {
					// Локальное сохранение
					// const savingData = convertToSave(editingState, problem, true);
					// savingData.newAttachments = convertToLocalSave(files);
					// TODO Локальное сохранение
				} else {
					try {
						await dispatch(
							editCheck(objectId, checkId, savingData)
						);
						onClose();
					} catch (error) {
						console.log(error);
						// TODO Локальное сохранение
					}
				}
			}
		}
		setSaving(false);
	}, [networkStatus, objectId, checkId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-check-attachments-dialog"
			isOpen={isOpen}
			title="Редактировать вложения"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};