import React from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import useAppSelector from '@src/core/hooks/useAppSelector';

export const SpacesCacheStatus = () => {
	const queryClient = useQueryClient();
	const settings = queryClient.getQueryDefaults(spacesQueryKeys.list());
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const {isError, isStale, isFetching, dataUpdatedAt, isPaused} = useQuery({
		queryKey: [...spacesQueryKeys.list(), {objects}, null, null, 1000000],
		staleTime: settings?.staleTime,
		cacheTime: settings?.cacheTime,
		enabled: !!objects && objects.length > 0
	});
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Помещения"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={isError ? 'Ошибка' : isStale ? 'Данные устарели' : undefined}
			inProgress={isPaused || isFetching}
		/>
	);
};
