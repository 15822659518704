import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {format} from 'date-fns';
import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedTaskForScheduler} from '@src/pages/TasksPage/hooks/useEnrichedTasksForSchedule';

export const DeadLine = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;
	return (
		<>
			{task?.taskIntervalStart && task?.taskIntervalEnd ? (
				<div className="tasks-schedule-popup__list-content_time">
					<div className="tasks-schedule-popup__list-content_time-grid">
						<div>С</div>
						<div>{format(task.taskIntervalStart, 'HH:mm dd.MM.yyyy')}</div>
						<div>По</div>
						<div>{format(task.taskIntervalEnd, 'HH:mm dd.MM.yyyy')}</div>
					</div>
					{task.status !== undefined
						&& task.status?.id !== TaskStatusId.ACCEPTED
						&& task.status?.id !== TaskStatusId.REJECTED && (
							<FixDateTag
								className="tasks-schedule-popup__list-content_time-tag"
								plannedFixDate={task.taskIntervalEnd}
								size="small"
							/>
						)}
				</div>
			) : (
				<div className="tasks-schedule-popup__list-content_notime">
					Нет срока исполнения
				</div>
			)}
		</>
	);
};
