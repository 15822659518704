import React, {useCallback} from 'react';
import './PhotoNavigation.less';
import classNames from 'classnames';
import DraggableMenu from '../../navigation/DraggableMenu';
import PhotoPreview from '../PhotoPreview';
import {IconButton} from '../../buttons';

interface IPhotoNavigationProps {
	className?: string;
	style?: React.CSSProperties;
	data: string[];
	value: number;

	onChange(index: number): void;
}

const PhotoNavigation = ({className, style, data, value, onChange}: IPhotoNavigationProps) => {
	const handlePrev = useCallback(() => {
		if (value !== 0) {
			onChange(value - 1);
		}
	}, [value, onChange]);

	const handleNext = useCallback(() => {
		if (value !== data.length - 1) {
			onChange(value + 1);
		}
	}, [value, data, onChange]);

	return (
		<div
			className={classNames('photo-navigation', className)}
			style={style}
		>
			<IconButton
				className="photo-navigation__prev"
				disabled={value <= 0}
				onClick={handlePrev}
			>
				<i className="tz-simple-arrow-24 photo-navigation__prev-icon"/>
			</IconButton>

			<DraggableMenu
				className="photo-navigation__items"
				itemClassName="photo-navigation__item"
				value={value}
				items={data.map(url => (
					<PhotoPreview
						className="photo-navigation__item-photo"
						url={url}
					/>
				))}
				disableFakeItem
				onSelect={onChange}
			/>

			<IconButton
				className="photo-navigation__next"
				disabled={value >= data.length - 1}
				onClick={handleNext}
			>
				<i className="tz-simple-arrow-24 photo-navigation__next-icon"/>
			</IconButton>
		</div>
	);
};

export default PhotoNavigation;