import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IWarrantyClaimStatus} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IWarrantyClaimsStatusesState = ILoadableEntitiesState<IWarrantyClaimStatus>;

export const warrantyClaimStatuses = createReducer<IWarrantyClaimsStatusesState>(
	getLoadableEntitiesInitialState<IWarrantyClaimStatus>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IWarrantyClaimStatus>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IWarrantyClaimStatus>()
	}
);
