import React, {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Button} from '@tehzor/ui-components';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {getInspections} from '@src/store/modules/entities/inspections/actions';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';

const types: ICheckAddingEntityType[] = ['inspection'];

interface IInspectionAddingDesktopProps {
	objectId: string;
}

export const InspectionAddingDesktop = ({objectId}: IInspectionAddingDesktopProps) => {
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();

	const [, reloadList] = useAsyncFn(async () => {
		await dispatch(getInspections(objectId));
	}, [objectId]);

	const checksPermissions = useChecksPermissions(objectId);
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId);

	const permissions = useMemo(
		() => ({
			checks: checksPermissions.canAdd,
			internalAcceptances: internalAcceptancePermissions.canAdd
		}),
		[checksPermissions, internalAcceptancePermissions]
	);

	const [globalDialog, openGlobalDialog] = useGlobalAddingEntityDialog(
		objectId,
		undefined,
		undefined,
		object.stage,
		permissions,
		undefined,
		reloadList,
		types
	);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-plus-20"/>}
				label="Добавить"
				onClick={openGlobalDialog}
			/>
			{globalDialog}
		</>
	);
};
