const domain = 'space-type-decorations';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;
