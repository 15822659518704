import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {CreationDateFilterMobile} from '@src/components/MobileEntitiesFilters';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {ILegalsFiltersState} from '@src/store/modules/settings/pages/legals/reducers';
import {CompaniesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CompaniesFilterMobile';

export const LegalsFiltersPage = () => {
	const {goBack} = useChangePath();
	const {entity} = useStrictParams<{entity: string}>();

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};
	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<ILegalsFiltersState>();

	const handleApply = useCallback(() => {
		apply();
		if (canGoBack) {
			goBack();
		}
	}, [canGoBack, apply]);

	useAppHeader(
		{
			title: 'Фильтры',
			showBackBtn: true,
			mobileRightButtons: isCleanable(state) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[state]
	);

	useRouting(!canGoBack, undefined, entity);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<CompaniesFilterMobile
				value={state.companies}
				entity={entity}
				onChange={change}
				isUUID
			/>

			{/*  Todo: сделать чтобы работало  */}
			{/* <CreatedByFilterMobile value={state.createdBy} entity={entity} onChange={change} /> */}

			<CreationDateFilterMobile
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity}
			/>
		</FilterPage>
	);
};
