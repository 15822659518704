import {PrivateRoute} from '@src/containers/PrivateRoute';
import {SpaceCheckListPageWrap} from '@src/pages/CheckListPage';
import SpacePage from '@src/pages/SpacePage';
import SpacesPage from '@src/pages/SpacesPage';
import SpacesFiltersRoutingPage from '@src/pages/filters/SpacesFiltersRoutingPage';
import React from 'react';
import {spacesFilterRoutes} from './spacesFilterRoutes';

export const objectSpacesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<SpacesPage/>}/>
	},
	{
		path: ':spaceId',
		element: <PrivateRoute element={<SpacePage/>}/>
	},
	{
		path: ':spaceId/check-lists/:stage/*',
		element: <PrivateRoute element={<SpaceCheckListPageWrap/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<SpacesFiltersRoutingPage/>}/>,
		children: spacesFilterRoutes
	}
];
