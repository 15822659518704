import React from 'react';
import './EditableFieldLabel.less';
import classNames from 'classnames';

interface IEditableFieldLabelProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	addon?: React.ReactNode;
}

const EditableFieldLabel = ({className, style, children, addon}: IEditableFieldLabelProps) => (
	<div
		className={classNames('editable-field-label', className)}
		style={style}
	>
		{children}
		{addon && <div className="editable-field-label__addon">{addon}</div>}
	</div>
);

export default EditableFieldLabel;
