import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {EditableTask} from '../EditableTask';
import {
	convertTaskToSave,
	useEditableTaskState,
	errorsFns,
	isEdited,
	hasAttachmentsError
} from '@src/core/hooks/states/useEditableTaskState';
import {ISavingTask} from '@tehzor/tools/interfaces/tasks/ISavingTask';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {
	convertToSave as convertFilesToSave,
	convertToLocalSave as convertFilesToLocalSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {TaskPriorityId} from '@tehzor/tools/interfaces/tasks/ITaskPriority';
import {useFieldsSettings} from '@src/core/hooks/useFieldsSettings';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {getPlans} from '@src/store/modules/dictionaries/plans/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

const defaultFieldsSettings: Record<string, IObjectFieldSetting> = {
	objectId: {fieldId: 'objectId', isRequired: true},
	name: {fieldId: 'name', isRequired: true},
	description: {fieldId: 'description', isRequired: false},
	taskType: {fieldId: 'taskType', isRequired: true},
	taskPriority: {fieldId: 'taskPriority', isRequired: true},
	taskIntervalStart: {fieldId: 'taskIntervalStart', isRequired: false},
	respUsers: {fieldId: 'respUsers', isRequired: false},
	attachments: {fieldId: 'attachments', isRequired: false},
	location: {fieldId: 'location', isRequired: false},
	floor: {fieldId: 'floor', isRequired: false}
};

interface IHookArgs {
	task?: ITask;
	objectId?: string;
	startTime?: string;
	endTime?: string;
	floor?: string;
	saving?: boolean;
	planId?: string;
	location?: ILocation;
	status?: TaskStatusId;
	taskType?: TaskTypeId;
	taskPriority?: TaskPriorityId;
	activeGroup?: string;
	respUsers?: IBriefUser[];
	links?: {
		spaceId?: string;
		checkListId?: string;
		checkItemId?: string;
	};
}

interface IPredefinedTask {
	objectId?: string;
	startTime?: string;
	endTime?: string;
	floor?: string;
	planId?: string;
	location?: ILocation;
	status?: TaskStatusId;
	taskType?: TaskTypeId;
	taskPriority?: TaskPriorityId;
	activeGroup?: string;
	respUsers?: IBriefUser[];
	links?: {
		spaceId?: string;
		checkListId?: string;
		checkItemId?: string;
	};
}

export const useEditableTask = ({
	task,
	objectId,
	startTime,
	endTime,
	floor,
	planId,
	location,
	status,
	taskType,
	taskPriority,
	links,
	activeGroup,
	respUsers,
	saving
}: IHookArgs): [
	React.ReactNode,
	(useLocalFiles?: boolean) => Promise<ISavingTask | undefined>,
	boolean,
	() => void
] => {
	const dispatch = useAppDispatch();
	const createPredefinedTask = useCallback((predefinedTask: IPredefinedTask) => {
		const {
			objectId,
			startTime,
			endTime,
			status = TaskStatusId.OPENED,
			taskType = TaskTypeId.TASK,
			taskPriority = TaskPriorityId.MEDIUM,
			...rest
		} = predefinedTask;
		const startTimeNumber = startTime ? Number(startTime) : undefined;
		const endTimeNumber = startTime ? Number(endTime) : undefined;

		return {
			...rest,
			objectId,
			status,
			taskType,
			taskPriority,
			taskIntervalStart: startTimeNumber,
			taskIntervalEnd: endTimeNumber
		} as ITask;
	}, []);

	const [localTask, setLocalTask] = useState(
		() =>
			task
			|| createPredefinedTask({
				objectId,
				startTime,
				endTime,
				floor,
				planId,
				location,
				status,
				taskType,
				taskPriority,
				links,
				activeGroup,
				respUsers
			})
	);

	useEffect(() => {
		if (task) {
			setLocalTask(task);
		}

		if (startTime && endTime) {
			setLocalTask({
				...localTask,
				taskIntervalStart: Number(startTime),
				taskIntervalEnd: Number(endTime)
			});
		}
	}, [task, startTime, endTime]);

	const [editingState, editingDispatch] = useEditableTaskState(localTask);

	const fieldsSettings: Record<string, IObjectFieldSetting> = useFieldsSettings(
		editingState?.objectId || '',
		'task'
	);

	const chosenFieldsSettings = useMemo(
		() =>
			(Object.keys(fieldsSettings).length < 1
				? defaultFieldsSettings
				: {...defaultFieldsSettings, ...fieldsSettings}),
		[fieldsSettings]
	);
	const plans = useAppSelector(s => s.dictionaries.plans);
	useEffect(() => {
		if (editingState.objectId && !plans[editingState.objectId]) {
			void dispatch(getPlans([editingState.objectId]));
		}
	}, [dispatch, editingState?.objectId, plans]);

	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, localTask) || isFilesExist(uploadingFilesState.value));
	}, [editingState, chosenFieldsSettings, localTask, uploadingFilesState.value]);

	const getSavingData = useCallback(
		async (useLocalFiles?: boolean) => {
			const files = await waitUploading();
			if (
				hasErrors(editingState, errorsFns, chosenFieldsSettings)
				|| (hasAttachmentsError(editingState, chosenFieldsSettings) && !isFilesExist(files))
			) {
				editingDispatch({type: 'update-errors'});
				uploadingFilesDispatch({type: 'update-error'});
				return undefined;
			}

			if (
				!isEdited(editingState, localTask)
				&& (!isFilesExist(files) || someFilesHaveError(files))
			) {
				return undefined;
			}
			// Здесь используется task, а не localTask, для того чтобы обнаруживать изменение поля
			// если были предзаполнены поля при помощи createPredefinedTask
			const savingTask = convertTaskToSave(editingState, task, true);

			return {
				...savingTask,
				newAttachments: useLocalFiles
					? convertFilesToLocalSave(files)
					: convertFilesToSave(files)
			};
		},
		[
			waitUploading,
			editingState,
			chosenFieldsSettings,
			task,
			localTask,
			editingDispatch,
			uploadingFilesDispatch
		]
	);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: localTask
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [editingDispatch, localTask, uploadingFilesDispatch]);

	useUpdateEffect(reset, [localTask]);

	const taskFields = (
		<EditableTask
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={chosenFieldsSettings}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving}
		/>
	);

	return [taskFields, getSavingData, isBlocking, reset];
};
