import React, {memo} from 'react';
import {format} from 'date-fns';
import {DatePicker, EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';

const calendarIcon = <i className="tz-calendar-20"/>;

interface IEditableDateFieldProps {
	label?: string;
	value?: Date;
	onChange?: (date: Date) => void;
	changeDateFormat?: string;
	disabled?: boolean;
	className?: string;
}

export const EditableDateField = memo((props: IEditableDateFieldProps) => {
	const {value, className, label, disabled, changeDateFormat = dateFormat, onChange} = props;
	return (
		<div>
			{label && <EditableFieldLabel>{label}</EditableFieldLabel>}
			<DatePicker
				useApplyButton
				value={value}
				onChange={onChange}
				trigger={((triggerProps: IDatePickerTriggerProps) => (
					<TextFieldWithForwardedRef
						elementType="div"
						className={className}
						value={triggerProps?.value !== undefined ? format(triggerProps.value, changeDateFormat) : ''}
						icon={calendarIcon}
						onClick={triggerProps?.toggle}
						ref={triggerProps?.ref}
						disabled={disabled}
					/>
				))}
			/>
		</div>
	);
});
