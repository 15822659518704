import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddProblemResponse = IProblem;

/**
 * Добавляет нарушение
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 * @param fields данные нарушения
 */

export const makeProblemAddRequest = async (
	objectId: string,
	links: IProblem['links'] | undefined,
	stage: ObjectStageIds = ObjectStageIds.BUILDING,
	fields: ISavingProblem
) => {
	const response = await httpRequests.withToken.post<IAddProblemResponse>('/problems/add', {
		objectId,
		links,
		stage,
		...fields
	});
	return response.data;
};
