import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckListsAsArray} from '@src/store/modules/dictionaries/checkLists/selectors';
import React, {useCallback, useMemo, useState} from 'react';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import {convertChecklists} from '../../utils/convertCheckLists';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeSort} from '@src/store/modules/settings/pages/manage/checkLists/actions';
import {extractCheckListsPageSettings} from '@src/store/modules/settings/pages/manage/checkLists/selectors';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {mobileColumns} from './columns.mobile';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface ITableProps {
	loading?: boolean;
}

export const MobileTable = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const data = useAppSelector(extractCheckListsAsArray);
	const pageSettings = useAppSelector(extractCheckListsPageSettings);
	const updateButtonLoading = useAppSelector(s => s.app.updateData.status);
	const dispatch = useAppDispatch();

	const [, loadCheckLists] = useAsyncFn(() => dispatch(getCheckLists()));

	const handleSortChange = useCallback((value: {[key: string]: boolean}) => {
		dispatch(changeSort(value));
		void loadCheckLists();
	}, []);

	const preparedData = useMemo(() => convertChecklists(data), [data]);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const handleRowClick = useCallback((item: IPreparedCheckList) => {
		pushPath(`/manage/check-lists/${item.id}`);
	}, []);

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={loadCheckLists}>
				<LoadingPanel active={loading || updateButtonLoading}>
					<EntitiesTable
						columns={mobileColumns}
						data={preparedData}
						selectedRows={selectedRows}
						sort={pageSettings.sort}
						onRowClick={handleRowClick}
						onSelectedRowsChange={setSelectedRows}
						onSortChange={handleSortChange}
						headVisible={false}
						noRowBorder
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
