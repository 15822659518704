import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import useAppSelector from '@src/core/hooks/useAppSelector';

export const ResponsibleUsersMobileCell = (props: Pick<CellProps<IEnrichedTask, IEnrichedTask>, 'value'>) => {
	const {value} = props;
	const task = value;
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const groupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);

	const getLeaderWorkGroupName = (workGroupId?: string): string|undefined => {
		if (!workGroupId) return undefined;
		const leaderId = groupsMap[workGroupId]?.leader;
		return usersMap[leaderId]?.displayName;
	};

	if (!((task?.respUsers && task?.respUsers?.length > 0) || task?.activeGroup)) {
		return null;
	}
	return (
		<div className="tasks-page__m-table-cell-responsible">
			<div className="tasks-page__m-table-cell-responsible--label">
				Ответственный:
			</div>
			<div className="tasks-page__m-table-cell-responsible--text">

				{
					task?.respUsers && task?.respUsers?.length > 0
						? task.respUsers.map(user => user?.displayName)
						: getLeaderWorkGroupName(task.activeGroup)
				}
			</div>

		</div>
	);
};
