import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import IExportTemplate, {OutputFileType} from '@tehzor/tools/interfaces/IExportTemplate';

const summaryTemplates = ['companiesSummary', 'companySummary', 'objectsSummary', 'objectSummary'];

const getAllIds = (state: IState) => state.dictionaries.exportTemplates.allIds || [];
const getById = (state: IState) => state.dictionaries.exportTemplates.byId || {};
const getExportTemplateId = (state: IState, exportTemplateId: string | undefined) =>
	exportTemplateId;
/**
 * Возвращает шаблоны в виде массива
 */
export const getExportTemplatesAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));

/**
 * Возвращает шаблоны в виде массива для таблицы без PDF
 */
export const getExportTemplatesAsArrayWithoutPdf = createSelector(
	[getExportTemplatesAsArray],
	array => array.filter(item => item.outputFileType !== OutputFileType.PDF)
);

/**
 * Возвращает шаблоны для сводной статистики
 */
export const getSummaryTemplates = createSelector([getExportTemplatesAsArray], templates =>
	templates.filter((item: IExportTemplate) => summaryTemplates.includes(item.destination)));

export const extractExportTemplateById = createSelector(
	[getById, getExportTemplateId],
	(byId, exportTemplateId) => {
		if (exportTemplateId) {
			return byId[exportTemplateId];
		}
		return undefined;
	}
);
