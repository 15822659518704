import React, {useMemo} from 'react';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';

interface IDependencySelectProps {
	dependencies: Array<{id: string, name?: string, dependencies?: string[], parentId?: string}>;
	value?: string[];
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;

	onChange(value: string[]): void;
}

const getInputLabel = (
	value: string[] | undefined,
	types: Array<{id: string, name?: string, dependencies?: string[], parentId?: string}>,
	typesMap: Record<
		string,
		{id: string, name?: string, dependencies?: string[], parentId?: string}
	>
) => {
	if (!value) {
		return undefined;
	}

	if (Array.isArray(value)) {
		return value.length ? makeFilterLabel('Выбрано', value, types) : undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

const DependencySelect = ({
	className,
	style,
	label = 'Зависимости',
	onChange,
	value,
	dependencies,
	required,
	disabled,
	hasError
}: IDependencySelectProps) => {
	const dependencyMap = useMemo(
		() =>
			dependencies.reduce((acc, dep) => {
				acc[dep.id] = dep;

				return acc;
			}, {}),
		[dependencies]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, dependencies, dependencyMap)}
						icon={<i className="tz-simple-arrow-20"/>}
						error={required && hasError ? 'Выберите зависимости' : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					multiple
					value={value}
					onChange={onChange}
				>
					{dependencies.map(dependencies => (
						<SelectOption
							key={dependencies.id}
							itemKey={dependencies.id}
							content={dependencies.name}
							inputType="checkbox"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};

export default DependencySelect;
