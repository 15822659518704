import {combineReducers} from 'redux';
import ownerAcceptances from './ownerAcceptances/reducers';
import ownerAcceptance from './ownerAcceptance/reducers';
import checks from './checks/reducers';
import check from './check/reducers';
import problems from './problems/reducers';
import problemsLastReplies from './problemsLastReplies/reducers';
import inspection from './inspection/reducers';
import inspections from './inspections/reducers';
import space from './space/reducers';
import spaces from './spaces/reducers';
import spaceTypeDecorations from './spaceTypeDecorations/reducers';
import spaceTypeDecorationSet from './spaceTypeDecorationSet/reducers';
import spaceTypeDecorationSets from './spaceTypeDecorationSets/reducers';
import warrantyClaim from './warrantyClaim/reducers';
import warrantyClaims from './warrantyClaims/reducers';
import checkList from './checkList/reducers';
import checkListTypes from './checkListTypes/reducers';
import {internalAcceptance} from './internalAcceptance/reducers';
import {internalAcceptances} from './internalAcceptances/reducers';
import {tasks} from './tasks/reducers';
import devices from './devices/reducers';
import {structure} from './structure/reducers';
import {structures} from './structures/reducers';
import {workAcceptance} from './workAcceptance/reducers';
import {workAcceptances} from './workAcceptances/reducers';
import {markers} from './markers/reducers';
import {problemViews} from './problemViews/reducers';

export const entities = combineReducers({
	ownerAcceptances,
	ownerAcceptance,
	checks,
	check,
	checkList,
	checkListTypes,
	devices,
	problems,
	problemViews,
	problemsLastReplies,
	inspection,
	inspections,
	space,
	spaces,
	spaceTypeDecorations,
	spaceTypeDecorationSet,
	spaceTypeDecorationSets,
	warrantyClaim,
	warrantyClaims,
	internalAcceptance,
	internalAcceptances,
	tasks,
	structure,
	structures,
	workAcceptance,
	workAcceptances,
	markers
});
