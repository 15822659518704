import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetChecksResponse, requestChecks} from '@src/api/backend/checks';

const request = (objectId: string) => ({
	type: types.GET_SELECTION_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetChecksResponse) => {
	if (response) {
		addSuccessToast('Успех', 'список подгружен');
	}
	return {
		type: types.GET_SELECTION_SUCCESS,
		payload: {
			...response,
			objectId
		}
	};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка проверок');
	}
	return {
		type: types.GET_SELECTION_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список проверок для отображения в select
 */

export const getSelectionChecks = (
	objects: string[],
	createdBy: string[] | undefined,
	offset: number,
	limit: number
) =>
	createApiAction<IGetChecksResponse>(request, success, failure, () =>
		requestChecks({objects, createdBy}, undefined, offset, limit));
