import React, {useCallback, memo} from 'react';
import {IOfflineJournalEntity} from '../../OfflineJournalPage';
import {OfflineJournalStatus} from '../OfflineJournalStatus/OfflineJournalStatus';
import {format} from 'date-fns';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import './OfflineJournalTitle.less';
import {EntityLink} from '../EntityLink';
import {offlineJournalActionType} from '../../enums/actionsTypes';
import {deleteLocalCheck} from '@src/cache/actions/check/deleteLocalCheck';
import {deleteLocalOwnerAcceptance} from '@src/cache/actions/ownerAcceptance/deleteLocalOwnerAcceptance';
import {deleteLocalInternalAcceptance} from '@src/cache/actions/internalAcceptance/deleteLocalInternalAcceptance';
import {deleteLocalWarrantyClaim} from '@src/cache/actions/warrantyClaim/deleteLocalWarrantyClaim';
import {deleteLocalProblem} from '@src/cache/actions/problem/deleteLocalProblem';
import {deleteLocalInspection} from '@src/cache/actions/inspection/deleteLocalInspection';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IOfflineJournalTitleProps {
	offlineJournalEntity: IOfflineJournalEntity;
}

export const OfflineJournalTitle = memo((props: IOfflineJournalTitleProps) => {
	const {offlineJournalEntity} = props;
	const {entity, createdAt} = offlineJournalEntity;
	const {pushPath} = useChangePath();
	const formatedDate = format(createdAt || 0, dateTimeCommaSeparatedFormat);

	const handleGoToButton = useCallback((url: string) => {
		pushPath(url);
	}, []);
	const [deletingDialog, getDeletengConfirmation] = useConfirmDialog(
		'Удалить автономные данные',
		'Вы действительно хотите удалить локально сохраненные данные?',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const handleDeleteButton = useCallback(async (offlineJournalEntity: IOfflineJournalEntity) => {
		const {entityId, entityType} = offlineJournalEntity.entity;
		const objectId = offlineJournalEntity.object?.objectId;
		if (await getDeletengConfirmation()) {
			switch (entityType) {
				case offlineJournalActionType.CHECK_ADD:
					await deleteLocalCheck(entityId, objectId);
					break;
				case offlineJournalActionType.INTERNAL_ACCEPTANCE_ADD:
					await deleteLocalInternalAcceptance(entityId, objectId);
					break;
				case offlineJournalActionType.OWNER_ACCEPTANCE_ADD:
					await deleteLocalOwnerAcceptance(entityId, objectId);
					break;
				case offlineJournalActionType.WARRANTY_CLAIM_ADD:
					await deleteLocalWarrantyClaim(entityId, objectId);
					break;
				case offlineJournalActionType.PROBLEM_ADD
					|| offlineJournalActionType.PROBLEM_CHECKLIST_ADD
					|| offlineJournalActionType.PROBLEM_SPACE_ADD:
					await deleteLocalProblem(entityId);
					break;
				case offlineJournalActionType.INSPECTION_ADD
					|| offlineJournalActionType.INSPECTION_CHECKLIST_ADD
					|| offlineJournalActionType.INSPECTION_SPACE_ADD:
					await deleteLocalInspection(entityId);
					break;
			}
		}
	}, []);

	const handleIconMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	}, []);

	return (
		<div className="offline-journal-title">
			<div className="offline-journal-title__content">
				<span className="offline-journal-title__content-title">{entity.entityType}</span>
				<span className="offline-journal-title__content-date">{formatedDate}</span>
				<OfflineJournalStatus
					className="offline-journal-title__content-status"
					offlineJournalEntity={offlineJournalEntity}
				/>
			</div>
			<div onClick={handleIconMenu}>
				{offlineJournalEntity.entity.entityType
					!== offlineJournalActionType.CHECK_ITEM_STATUS_CHANGE
					&& offlineJournalEntity.entity.entityType
					!== offlineJournalActionType.CHECK_LIST_STATUS_CHANGE
					&& offlineJournalEntity.entity.entityType
					!== offlineJournalActionType.SPACE_INDICATORS_CHANGE
					&& offlineJournalEntity.entity.entityType
					!== offlineJournalActionType.SPACE_STATUS_CHANGE && (
						<IconMenu className="offline-journal-title__menu">
							<EntityLink
								item={offlineJournalEntity}
								goTo={handleGoToButton}
							/>

							<MenuItem
								key="delete"
								icon={<i className="tz-delete"/>}
								onClick={() => handleDeleteButton(offlineJournalEntity)}
							>
								Удалить
							</MenuItem>
						</IconMenu>
					)}
				{deletingDialog}
			</div>
		</div>
	);
});
