import {IconButton} from '@tehzor/ui-components';
import React, {memo, useCallback} from 'react';

interface ISelectionClearingProps {
	onSelectedRowsChange: () => void;
}

const closeIcon = <i className="tz-close-24" />;

export const SelectionClearing = memo(({onSelectedRowsChange}: ISelectionClearingProps) => {
	const handleClick = useCallback(onSelectedRowsChange, [onSelectedRowsChange]);

	return <IconButton onClick={handleClick}>{closeIcon}</IconButton>;
});
