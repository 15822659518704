import React, {useMemo} from 'react';
import {StatusSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {editOwnerAcceptanceStatus} from '@src/store/modules/entities/ownerAcceptance/actions';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import ClickPreventWrap from './ClickPreventWrap';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';

const OwnerAcceptanceStatusCell = ({row}: CellProps<{data: IOwnerAcceptance}>) => {
	const acceptance = row.original.data;
	const {id, objectId, status, resolution} = acceptance;
	const statuses = useAppSelector(s => s.dictionaries.ownerAcceptanceStatuses);
	const dispatch = useAppDispatch();
	const permissions = useOwnerAcceptancesPermissions(objectId);

	const isStatusHidden = useMemo(() =>
		resolution === OwnerAcceptanceResolutionId.ACCEPTED, [resolution]);

	const [, handleChange] = useAsyncFn(
		async (v: OwnerAcceptanceStatusId) => {
			await dispatch(editOwnerAcceptanceStatus(objectId, id, v));
		},
		[objectId, id]
	);

	return !isStatusHidden ? (
		<ClickPreventWrap>
			<StatusSelect
				style={{maxWidth: '100%'}}
				statuses={statuses}
				disabled={!permissions.canEditStatus}
				value={status}
				onChange={handleChange}
			/>
		</ClickPreventWrap>
	) : null;
};

export default OwnerAcceptanceStatusCell;
