import React from 'react';
import {CellProps} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';

export const CreatedCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => (
	<div className="owner-acceptances-page__d-table-created">
		<div className="owner-acceptances-page__d-table-created-date">{row.original.createdAt}</div>
		<div className="owner-acceptances-page__d-table-created-name">
			{row.original.createdBy?.fullName}
		</div>
	</div>
);
