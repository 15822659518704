import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {IExportResponse} from "@tehzor/tools/interfaces/IExportResponse";
import getEmailsArray from "@src/utils/getEmailsArray";

/**
 * Отправляет запрос на экспорт осмотров
 */
export const makeInspectionsExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string | undefined,
	filters: Record<string, unknown> | undefined,
	sort: Record<string, boolean> | undefined,
	selected: string[] | undefined
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId: objectId === 'all' ? undefined : objectId, // TODO Выпилить этот костыль
		filters: {
			...filters,
			selected
		},
		sort: convertSortParam(sort)
	};
	const response = await httpRequests.withToken.get<IExportResponse>('inspections/export', {
		params
	});
	return response.data;
};
