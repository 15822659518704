import {IconButton} from '@tehzor/ui-components';
import React from 'react';
import {useAddingCheckListProblemDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingCheckListProblemDialog';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

interface IDesktopItemHeaderActions {
	itemId: string;
}

export const DesktopItemHeaderActions = (props: IDesktopItemHeaderActions) => {
	const {itemId} = props;
	const [dialog, open] = useAddingCheckListProblemDialog(itemId);

	return (
		<div>
			<ClickPreventWrap>
				<IconButton onClick={open}>
					<i className="tz-plus-24"/>
				</IconButton>

				{dialog}
			</ClickPreventWrap>
		</div>
	);
};
