import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ITaskStatus} from '@tehzor/tools/interfaces/tasks/ITaskStatus';

export type ITaskTypesState = ILoadableEntitiesState<ITaskStatus>;

export const taskStatuses = createReducer<ITaskTypesState>(
	getLoadableEntitiesInitialState<ITaskStatus>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ITaskStatus>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ITaskStatus>()
	}
);
