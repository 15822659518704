import React from 'react';
import './WorkingGroupsPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import {Table} from './components/table/Table.desktop';
import {MobileTable} from './components/table/Table.mobile';
import {DesktopAddButton} from './components/DesktopAddButton';
import {useWorkingGroupPermissions} from '@src/core/hooks/permissions/useWorkingGroupPermissions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

export const WorkingGroupsPage = () => {
	useAppHeader({
		title: 'Рабочие группы',
		mobileRightButtons: <AppUpdateMenu/>
	});
	const permissions = useWorkingGroupPermissions();
	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<div className="page-cont manage-working-groups-page">
				<PageBreadcrumbs/>
				<Table/>
			</div>
		);
	}
	return (
		<div className="page-cont manage-working-groups-page_mobile">
			<PageBreadcrumbs/>
			<MobileTable/>
			{permissions.canAdd && <DesktopAddButton/>}
		</div>
	);
};
