const domain = 'pages/task';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const EDIT_RESPONSIBLE_REQUEST = `${domain}/responsible/edit/request`;
export const EDIT_RESPONSIBLE_SUCCESS = `${domain}/responsible/edit/success`;
export const EDIT_RESPONSIBLE_FAILURE = `${domain}/responsible/edit/failure`;

export const EDIT_TASK_STATUS_REQUEST = `${domain}/task-status/edit/request`;
export const EDIT_TASK_STATUS_SUCCESS = `${domain}/task-status/edit/success`;
export const EDIT_TASK_STATUS_FAILURE = `${domain}/task-status/edit/failure`;
