/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState} from 'react';
import {IEnrichedTaskForScheduler} from './useEnrichedTasksForSchedule';
import {useTasksSchedulePopup} from '@src/components/TasksSchedulePopup/hooks/useTasksSchedulePopup';
import declination from '@tehzor/tools/utils/declination';
import {ITasksDayCell} from '../utils/convertTasksToScheduleFormat';

interface IUseDayTasksProps {
	tasks: ITasksDayCell;
	objectId?: string;
	popupTitle?: string;
	rowIndex: number;
	startTime?: number;
	endTime?: number;
}

interface IUseDayTasks {
  dayTasks: JSX.Element;
}

export const useDayTasks = (props: IUseDayTasksProps): IUseDayTasks => {
  const {tasks, objectId, popupTitle, startTime, endTime} = props;
	const [currentTasks, setCurrentTasks] = useState<IEnrichedTaskForScheduler[]>([]);

	const visibleTasks = tasks?.visibleTasks || [];
	const unvisibleTasks = tasks?.unvisibleTasks || [];

	const [TasksSchedulePopup, openTasksSchedulePopup] = useTasksSchedulePopup(
		currentTasks,
		popupTitle,
		objectId,
		startTime,
		endTime
	);

	const handleOpenTasksSchedulePopup = (tasks: IEnrichedTaskForScheduler[]) => {
		setCurrentTasks(tasks);
		openTasksSchedulePopup();
	};

	const finalTasks = visibleTasks.map(t => {
		const width = t.duration * 123 - 6;
		const topHeigth = (t.rowSpan - 1) * 28;

		return (
			<div
				key={t.id}
				className="manage-task-schedule-page__day-cell-tasks-item"
				style={{
					width,
					backgroundColor: t.status?.scheduleTableColor,
					borderLeft: `4px solid ${t.taskType?.color}`,
					top: topHeigth
				}}
				onClick={() => handleOpenTasksSchedulePopup([t])}
			>
				{t.name}
			</div>
		);
	});

	const tasksCountStr = !!unvisibleTasks?.length
		&& `+ ${unvisibleTasks?.length} ${declination(unvisibleTasks?.length, ['задача', 'задачи', 'задач'])}`;

	const handleOpenNotVisibleTasksSchedulePopup = () => {
		setCurrentTasks(unvisibleTasks);
		openTasksSchedulePopup();
	};

  return {
		dayTasks: (
			<>
				{finalTasks}
				{tasksCountStr && (
					<span
						onClick={handleOpenNotVisibleTasksSchedulePopup}
						className="manage-task-schedule-page__day-cell-tasks-item-hidden"
					>
						{tasksCountStr}
					</span>
				)}
				{TasksSchedulePopup}
			</>
		)
	};
};