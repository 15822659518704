import {IGetInspectionsResponse} from '@src/api/backend/inspections';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {
	IInspectionsFiltersState,
	IInspectionsPageSettingsState
} from '@src/store/modules/settings/pages/inspections/reducers';
import {convertLocalAttachments} from '@src/utils/convertLocalAttachments';
import {addErrorToast} from '@src/utils/toasts';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {useMemo} from 'react';
import {ISavingInspectionQuery} from '../../mutations/inspection/useAddInspection';
import useAppSelector from '../../useAppSelector';

export const useInspectionsPageSettings = (objectId: string) => {
	const entities = useAppSelector(s => s.entities.inspections);
	const settings = useAppSelector(s => s.settings.pages.inspections);

	const pageSettings: IInspectionsPageSettingsState & {offset: number} = useMemo(() => {
		const offset = entities[objectId]?.offset || 0;
		const filters = settings[objectId]?.filters;
		const sort = settings[objectId]?.sort;
		const pageSize = settings[objectId]?.pageSize;
		return {offset, filters, sort, pageSize};
	}, [objectId, entities, settings]);
	return pageSettings;
};
export const useInspections = (objectId: string, state?: IInspectionsFiltersState) => {
	const {offset, filters, sort, pageSize} = useInspectionsPageSettings(objectId);
	const {data} = useQuery<IGetInspectionsResponse, IError>({
		queryKey: [...inspectionsQueryKeys.list(), state || filters, sort, offset, pageSize],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке списка осмотров');
		}
	});
	return data;
};

export const useLocalInspections = (objectId = 'all') => {
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const {data} = useQuery<IListInspection[], IError>({
		queryKey: [...inspectionsQueryKeys.localList(), objectId],
		staleTime: Infinity,
		cacheTime: Infinity,
		networkMode: 'always',
		enabled: isOfflineModeAvailable
	});
	return useMemo(() => data || [], [data]);
};

export const inspectionsList = (data?: IGetInspectionsResponse) =>
	useMemo(
		() =>
			data?.allIds.map(id => {
				if (data.byId[id].localNumber) {
					return {
						...data.byId[id],
						attachments: convertLocalAttachments<ISavingInspectionQuery>(
							id,
							inspectionsQueryKeys
						)
					};
				}
				return data.byId[id];
			}),
		[data]
	);
