import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractStructureTypesAsArray} from '@src/store/modules/dictionaries/structureTypes/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const StructureTypesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const structureTypes = useAppSelector(extractStructureTypesAsArray);
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={structureTypes}
			label="Типы структур"
			filterName="types"
		/>
	);
};
