import * as types from '../constants';
import saveOrOpenBlob from '@tehzor/tools/utils/saveOrOpenBlob';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeGetExportTemplateRequest} from '@src/api/backend/exportTemplates/getOne';

interface IExportResponse {
	fileName: string;
	fileContent: Blob;
	warnings?: Record<string, unknown>;
}

const request = () => ({type: types.GET_ONE_REQUEST});

const success = (response: IExportResponse) => {
	saveOrOpenBlob(response.fileContent, response.fileName);
	if (response.warnings?.itemsLimit !== undefined) {
		addWarningToast(
			'Внимание',
			`превышен лимит записей (${String(response.warnings.itemsLimit)})`
		);
	}
	return {type: types.GET_ONE_SUCCESS};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке шаблона');
	}
	return {
		type: types.GET_ONE_FAILURE,
		payload: error
	};
};

/**
 * Получает файл шаблона экспорта
 *
 * @param id идентификатор шаблона
 */
export const getExportTemplate = (
	id: string
) =>
	createApiAction<IExportResponse>(request, success, failure, () =>
	makeGetExportTemplateRequest(id));
