import React from 'react';
import {format} from 'date-fns';

interface IAutomaticActionInfoProps {
	createdAt?: number;
}

export const AutomaticActionInfo = (props: IAutomaticActionInfoProps) => {
	const {createdAt} = props;

	return (
		<div className="delegation-history-item__info">
			<div className="delegation-history-item__info-automatic-action-icon">
				<i className="tz-reload-20"/>
			</div>
			<div className="delegation-history-item__info-action">
				Автоматически назначен ответственный
				{createdAt !== undefined ? (
					<>
						{' в '}
						{format(createdAt, 'HH:mm')}
					</>
				) : null}
			</div>
		</div>
	);
};