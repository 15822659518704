import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {changeSelected} from '@src/store/modules/entities/structures/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

interface IMobileSelectionClearProps {
	objectId: string;
}

export const MobileSelectionClearing = ({objectId}: IMobileSelectionClearProps) => {
	const dispatch = useAppDispatch();

	const handleClick = useCallback(() => {
		dispatch(changeSelected(objectId, []));
	}, [objectId, dispatch]);

	return (
		<IconButton onClick={handleClick}>
			<i className="tz-close-24"/>
		</IconButton>
	);
};
