import {combineReducers} from 'redux';
import records from '@src/store/modules/pages/checkLists/reducers/records';
import problems from '@src/store/modules/pages/checkLists/reducers/problems';
import tasks from '@src/store/modules/pages/checkLists/reducers/tasks';
import entitiesVisibility from '@src/store/modules/pages/checkLists/reducers/entitiesVisibility';

export default combineReducers({
	records,
	problems,
	tasks,
	entitiesVisibility
});
