import React, {Dispatch, CSSProperties, useMemo, useEffect} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUsersAsMap} from '@src/store/modules/dictionaries/users/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {extractProblemInspectorsGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

interface IInspectorProps<S, E> {
	className?: string;
	style?: CSSProperties;
	label?: string;
	value: string[];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	objectId: string;
	stage: ObjectStageIds;

	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const formDisplayValue = (
	value: string[], usersMap: Record<string, IBriefUser>
): string => {
	if (!value.length) { return ''; }

	const lastUserId = value[value.length - 1];
	const lastUser = usersMap[lastUserId];

	return lastUser ? lastUser?.displayName : '';
};

const getFirstInspector = (
	inspectors: string[],
	inspectorsGroups: IWorkingGroup[],
	currentUser: IBriefUser
): string | undefined => {
	if (inspectors.length) { return undefined; }

	const groups: IWorkingGroup[] = [];
	const userId = currentUser.id;

	for (const group of inspectorsGroups) {
		if (group.performers?.includes(userId)) {
			groups.push(group);
		} else if (group.leader === userId) {
			groups.push(group);
		}
	}

	if (groups.length <= 1) {
		return userId;
	}
	return undefined;
};

const inspectorIcon = <i className="tz-inspector-20"/>;

export const Inspectors = <S extends {inspectors?: string[]}, E> ({
	className,
	style,
	label = 'Проверяющие',
	value,
	editingDispatch,
	objectId,
	stage,
	required,
	disabled,
	hasError
}: IInspectorProps<S, E>) => {
	const inspectorsGroups = useAppSelector(s =>
		extractProblemInspectorsGroupsAsArray(s, objectId, stage));
	const usersMap = useAppSelector(extractUsersAsMap);
	const user = useAppSelector(extractUserProfile);

	const displayValue = useMemo(() =>
		formDisplayValue(value, usersMap), [usersMap, value]);

	useEffect(() => {
		const newInspector = getFirstInspector(value, inspectorsGroups, user);
		if (newInspector) {
			editingDispatch({type: 'update', field: 'inspectors', value: [newInspector]});
		}
	}, [inspectorsGroups, user, value, editingDispatch]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<TextField
				value={displayValue}
				elementType="div"
				error={required && hasError ? 'Выберите проверяющего' : undefined}
				disabled={disabled}
				icon={inspectorIcon}
			/>
		</div>
	);
};