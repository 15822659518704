import React, {memo} from 'react';
import './CloseButton.less';
import ButtonBase from '../../../../buttons/ButtonBase';

interface ICloseButtonProps {
	onClick?: (event: React.MouseEvent) => void;
}

const CloseButton = ({onClick}: ICloseButtonProps) => (
	<ButtonBase
		classNamePrefix="mpv-close-button"
		leftIcon={<i className="tz-close-20"/>}
		outerTagType="button"
		onClick={onClick}
	/>
);

export default memo(CloseButton);