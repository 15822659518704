import {TextField} from '@tehzor/ui-components';
import React from 'react';

interface INameFieldProps {
	value?: string;
	disabled?: boolean;

	onChange: (name: string) => void;
}

export const NameField = ({value, disabled, onChange}: INameFieldProps) => (
	<div className="editable-space-statuses__status-fields__name">
		<div className="editable-space-statuses__status-fields__name-title">
			Название статуса
		</div>
		<TextField
			elementType={disabled ? 'div' : 'input'}
			value={value}
			disabled={disabled}
			onChange={onChange}
		/>
	</div>
);