import {
	IAddCategoriesSetResponse,
	requestAddCategoriesSet
} from '@src/api/backend/categoriesSets/add';
import {createApiAction} from '@src/store/middlewares/api';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';

export type IAddCategoriesSetPayload = IAddCategoriesSetResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (categoriesSet: IAddCategoriesSetResponse) => ({
	type: types.ADD_SUCCESS,
	payload: {...categoriesSet} as IAddCategoriesSetPayload
});

const failure = (err: IError) => ({
	type: types.ADD_FAILURE,
	payload: err
});

export const addCategoriesSet = (categoriesSet: ISavingCategoriesSet) =>
	createApiAction<IAddCategoriesSetResponse>(
		request,
		req => success(req),
		failure,
		() => requestAddCategoriesSet(categoriesSet)
	);
