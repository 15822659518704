import React, {useCallback, useState} from 'react';
import {useEditableObject} from '../EditableObject/hooks/useEditableObject';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {addObject} from '@src/store/modules/entities/object/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {makeDefaultData} from '@src/core/hooks/states/useEditableObjectState';
import {makeTitle} from '../EditableObjectDialog/utils/makeTitle';
import './AddingObjectDialog.less';

interface IAddingObjectDialogProps {
	objectId?: string;
	companyId?: string;

	isOpen: boolean;
	onClose: () => void;
	onAfterClose?: () => void;
}

export const AddingObjectDialog = ({
	objectId, companyId, isOpen, onClose, onAfterClose
}: IAddingObjectDialogProps) => {
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);

	const defaultData = makeDefaultData();
	const [fields, getSavingData, reset, isBlocking] = useEditableObject(
		objectId,
		companyId,
		defaultData,
		saving,
		true
	);

	const save = useCallback(async () => {
		setSaving(true);

		try {
			const savingData = await getSavingData();

			if (savingData) {
				if (objectId) {
					savingData.parentId = objectId;
				}
				await dispatch(addObject(savingData));

				onClose();
			}
		} catch (e) {
			throw new Error(e);
		} finally {
			setSaving(false);
		}
	}, [getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	const handleAfterClose = useCallback(() => {
		reset();
		if (onAfterClose) {
			onAfterClose();
		}
	}, [reset, onAfterClose]);

	return (
		<Dialog
			className="adding-object-dialog"
			isOpen={isOpen}
			title={makeTitle()}
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={handleAfterClose}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
