import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {ResponsibilityChange} from '../../ResponsibilityChange';
import {TextChange} from '../../TextChange';
import {LocationChange} from '../../LocationChange';
import {StatusChange} from '../../StatusChange';
import {AttachmentsChange} from '../../AttachmentsChange';
import {CommentChange} from '../../CommentChange/CommentChange';
import {IChangeComponent} from '../../interfaces/IChangeComponent';
import {TagsChange} from '../../TagsChange';
import {MarkerCommentChange} from '../../MarkerCommentChange';

export const changeComponentByType: Record<HistoryTypeId, IChangeComponent> = {
	[HistoryTypeId.STATUS]: StatusChange,
	[HistoryTypeId.PLANNED_FIX_DATE]: TextChange,
	[HistoryTypeId.CATEGORY]: TextChange,
	[HistoryTypeId.LOCATION]: LocationChange,
	[HistoryTypeId.REASON]: TextChange,
	[HistoryTypeId.DESCRIPTION]: TextChange,
	[HistoryTypeId.PRESCRIPTION]: TextChange,
	[HistoryTypeId.ATTACHMENTS]: AttachmentsChange,
	[HistoryTypeId.RESP_USERS]: ResponsibilityChange,
	[HistoryTypeId.CRITICAL]: TextChange,
	[HistoryTypeId.COMMENT]: CommentChange,
	[HistoryTypeId.MARKER_COMMENT]: MarkerCommentChange,
	[HistoryTypeId.TAG]: TagsChange
};
