import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useSpaceExport} from '@src/core/hooks/export/useSpaceExport';

interface IDesktopSpaceExportProps {
	objectId: string;
	spaceId: string;
}

const DesktopSpaceExport = ({objectId, spaceId}: IDesktopSpaceExportProps) => {
	const [exportDialog, openExportDialog] = useSpaceExport(objectId, spaceId);

	const handleExport = useCallback(() => {
		openExportDialog(objectId, spaceId);
	}, [objectId, spaceId, openExportDialog]);

	return (
		<>
			<IconButton
				type="accent-blue"
				onClick={handleExport}
			>
				<i className="tz-export-20"/>
			</IconButton>
			{exportDialog}
		</>
	);
};

export default DesktopSpaceExport;
