import React from 'react';
import {DownloadableFile} from '@tehzor/ui-components';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';

interface IDocumentsProps {
	check?: ICheck;
}

const Documents = ({check}: IDocumentsProps) => (
	<div className="check-page__documents">
		{check?.attachments?.map(item =>
			(item.full ? (
				<div
					key={item.id}
					className="check-page__documents-file"
				>
					<DownloadableFile
						name={item.full.name}
						url={item.full.url}
						type={item.full.type}
						size={item.full.size}
					/>
				</div>
			) : null))}
	</div>
);
export default Documents;
