const domain = 'check-lists';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const EXPORT_REQUEST = `${domain}/export/request`;
export const EXPORT_SUCCESS = `${domain}/export/success`;
export const EXPORT_FAILURE = `${domain}/export/failure`;

export const SEND_REQUEST = `${domain}/export/request`;
export const SEND_SUCCESS = `${domain}/export/success`;
export const SEND_FAILURE = `${domain}/export/failure`;

export const CHANGE_SELECTED_ROWS = `${domain}/selected-rows/change`;
