import {wsConnector} from '../wsConnector';
import {ISavingTask} from '@tehzor/tools/interfaces/tasks/ISavingTask';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

export type IEditTaskResponse = ITask;

/**
 * Изменяет рабочую группу
 *
 * @param taskId id рабочей группы
 * @param fields данные рабочей группы
 */
export const makeTaskEditRequest = (taskId: string, fields: ISavingTask) =>
	wsConnector.sendAuthorizedRequest<IEditTaskResponse>('editTask', {
		taskId,
		...fields
	});
