import React, {useMemo} from 'react';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import SelectionActions from '../selection/SelectionActions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckEntities} from '@src/store/modules/entities/check/selectors';
import ActionsMobile from './Actions.mobile';

interface IMobileRightButtonsProps {
	objectId: string;
	checkId: string;
	check?: ILinkedCheck;
	selectedRows: string[];
}

const MobileRightButtons = ({objectId, checkId, check, selectedRows}: IMobileRightButtonsProps) => {
	const entities = useAppSelector(extractCheckEntities);

	const selectedEntities = useMemo(
		() => entities.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, entities]
	);

	if (!check) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				checkId={checkId}
				check={check}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			check={check}
		/>
);
};

export default MobileRightButtons;
