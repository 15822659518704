import useAppSelector from '@src/core/hooks/useAppSelector';
import {getSiblings} from '@src/pages/CheckListPage/selectors/getSiblings';
import React, {useCallback, useState} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureCheckItemLink} from '@tehzor/tools/utils/links';
import {FloatingActionButton, LoadingPanel, MobilePagination, Plate} from '@tehzor/ui-components';
import {CheckListItem} from '../list/CheckListItems/components/CheckListItem';
import {ProblemsTable} from '@src/pages/CheckListPage/components/detail/ProblemsTable';
import {useAddingCheckListProblemDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingCheckListProblemDialog';
import {extractCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const MobileItemDetail = () => {
	const {replacePath} = useChangePath();
	const params = useStrictParams<{
		listId: string;
		itemId: string;
		objectId: string;
		stage: string;
		structureId: string;
	}>();
	const item = useAppSelector(s => extractCheckItemById(s, params.itemId));
	const siblings = useAppSelector(s => getSiblings(s, item));
	const itemsLoading = useAppSelector(s => s.dictionaries.checkItems.loading);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [dialog, handleOpen] = useAddingCheckListProblemDialog(params.itemId);

	const handlePageChange = useCallback(
		(nextIndex: number) => {
			const next = siblings[nextIndex];
			replacePath(formStructureCheckItemLink({...params, itemId: next.id}));
		},
		[siblings]
	);

	if (!item) {
		return null;
	}

	const currentIndex = siblings.findIndex(sibling => sibling.id === item?.id);

	return (
		<div className="check-list-page__m-item-detail">
			<Plate
				withoutPadding
				className="check-list-page__m-nav"
			>
				<MobilePagination
					className="spaces-page__m-pagination"
					page={currentIndex}
					pageCount={siblings.length}
					renderTitle={({page}) => `${page + 1} категория`}
					onPageChange={handlePageChange}
				/>
			</Plate>

			<LoadingPanel active={itemsLoading}>
				<Plate className="check-item-detail">
					<CheckListItem
						mode="detail"
						item={item}
					/>
					<hr
						className="check-list-page__divider"
						style={{margin: '20px 0'}}
					/>
					<ProblemsTable
						item={item}
						selectedRows={selectedRows}
						onSelectedRowsChange={setSelectedRows}
					/>
					<FloatingActionButton
						icon={<i className="tz-plus-16"/>}
						label="Нарушение"
						minifyOnScroll
						scrollContainer="scroll-container"
						onClick={handleOpen}
					/>
					{dialog}
				</Plate>
			</LoadingPanel>
		</div>
	);
};
