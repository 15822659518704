import * as types from './constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {checkExpiration} from '@tehzor/tools/core/storeHelpers/actions';
import {IGetContractFormsResponse, requestContractForms} from '@src/api/backend/contractForms';

export type IGetContractFormsPayload = IGetContractFormsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetContractFormsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке форм договоров');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список форм договоров
 */
export const getContractForms = () =>
	checkExpiration<IState, IGetContractFormsResponse, ApiAction>(
		state => state.dictionaries.spaceTypes,
		createApiAction<IGetContractFormsResponse>(request, success, failure, requestContractForms),
		1209600000 // 2 недели
	);
