import React, {useCallback} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {useObjectsPermissions} from '@src/core/hooks/permissions/useObjectsPermissions';
import {useAddingObjectDialog} from '@src/components/AddingObjectDialog/hooks/useAddingObjectDialog';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteObject} from '@src/store/modules/entities/object/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';

const openIcon = <i className="tz-object-24"/>;
const addIcon = <i className="tz-plus-20"/>;
const deleteIcon = <i className="tz-delete"/>;

const MenuCell = ({row}: CellProps<IParentObject>) => {
	const {pushPath} = useChangePath();
	const object = row.original;
	const permissions = useObjectsPermissions();
	const dispatch = useAppDispatch();

	const [addingDialog, openAddingDialog] = useAddingObjectDialog(object.id, object.companyId);
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить объект?'
	);

	const handleView = useCallback(() => {
		pushPath(`/objects/${object.id}/view`);
	}, [object.id]);

	const handleAdd = useCallback(() => {
		openAddingDialog();
	}, [openAddingDialog]);

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteObject(object.id));
		}
	}, [object.id]);

	const menuActions = [];
	if (permissions.canEdit) {
		menuActions.push(
			<MenuItem
				key="view"
				className="menu-item_red"
				icon={openIcon}
				onClick={handleView}
			>
				Открыть
			</MenuItem>
		);
	}
	if (permissions.canAdd) {
		menuActions.push(
			<MenuItem
				key="add"
				className="menu-item_red"
				icon={addIcon}
				onClick={handleAdd}
			>
				Добавить объект
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		menuActions.push(
			<MenuItem
				key="delete"
				className="menu-item_red"
				icon={deleteIcon}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

	return menuActions.length ? (
		<>
			<IconMenu>{menuActions}</IconMenu>
			{permissions.canAdd && addingDialog}
			{permissions.canDelete && deleteDialog}
		</>
	) : null;
};

export default MenuCell;
