import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {DelegationDialog} from '@src/components/DelegationDialog';
import useToggle from 'react-use/lib/useToggle';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useResponsibleDisplayValue} from '@src/core/hooks/useResponsibleDisplayValue';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

interface IResponsibleProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	categoryId?: string | null;
	planId?: string;
	structureIds?: string[];
	respUsers: string[];
	activeGroup?: string;
	workingGroupType?: WorkingGroupTypeId;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const Responsible = <S extends {respUsers: string[], activeGroup?: string}, E>({
	className,
	style,
	label = 'Ответственные',
	objectId,
	stage,
	scope,
	categoryId,
	planId,
	structureIds,
	respUsers,
	activeGroup,
	workingGroupType,
	editingDispatch,
	required,
	disabled,
	hasError
}: IResponsibleProps<S, E>) => {
	const [isOpen, toggleOpen] = useToggle(false);

	const displayValue = useResponsibleDisplayValue(respUsers, activeGroup);

	const handleChange = useCallback(
		(users: string[], group: string | undefined) => {
			editingDispatch({type: 'update', field: 'respUsers', value: users});
			if (required) {
				editingDispatch({type: 'update-error', field: 'respUsers'});
			}

			editingDispatch({type: 'update', field: 'activeGroup', value: group});
			if (required) {
				editingDispatch({type: 'update-error', field: 'activeGroup'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<TextField
				value={displayValue}
				elementType="div"
				error={required && hasError ? 'Выберите ответственных' : undefined}
				disabled={disabled}
				icon={<i className="tz-user-20"/>}
				onClick={toggleOpen}
			/>

			<DelegationDialog
				objectId={objectId}
				stage={stage}
				scope={scope}
				categoryId={categoryId}
				planId={planId}
				structureIds={structureIds}
				selectedUsers={respUsers}
				selectedGroup={activeGroup}
				title="Выбор ответственных"
				autoApplySuggestions
				isOpen={isOpen}
				workingGroupType={workingGroupType}
				onChange={handleChange}
				onClose={toggleOpen}
			/>
		</div>
	);
};
