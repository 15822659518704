import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {
	IEditSpaceTypeDecorationResponse,
	makeSpaceTypeDecorationEditRequest
} from '@src/api/backend/spaceTypeDecoration';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';

export type IEditSpaceTypeDecorationPayload = ISpaceTypeDecoration[];

const request = (typeDecorationSetId: string, items: ISavingSpaceTypeDecoration[]) => ({
	type: types.EDIT_REQUEST,
	payload: {typeDecorationSetId, items}
});

const success = (response: IEditSpaceTypeDecorationResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при обновлении набора типов отделки');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет типы отделки в заданном наборе
 *
 * @param typeDecorationSetId id набора типов отделки помещения
 * @param items массив типов отделки для изменения
 */
export const editSpaceTypeDecoration = (
	typeDecorationSetId: string,
	items: ISavingSpaceTypeDecoration[]
) =>
	createApiAction<IEditSpaceTypeDecorationResponse>(
		() => request(typeDecorationSetId, items),
		success,
		failure,
		() => makeSpaceTypeDecorationEditRequest(typeDecorationSetId, items)
	);
