import React from 'react';
import MobileFilterButton from '../../buttons/MobileFilterButton';
import './MobileFilter.less';
import classNames from 'classnames';
import {LinkButton} from '../../buttons';
import {useSortedElements} from './utils/useSortedElements';
import {isActive} from './utils/isActive';
import {ErrorBoundary, ErrorMessage} from '../../containers';

export interface IMobileFilterItem {
	id: string;
	name: string;
}

export interface IMobileFilterProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	label?: React.ReactNode;
	elements?: IMobileFilterItem[];
	selected?: string[];
	fullSize?: boolean;
	search?: React.ReactNode;

	onChange?: (value: string[] | undefined) => void;
	onExpand?: () => void;
}

const MobileFilter = ({
	className,
	style,
	label,
	elements,
	selected = [],
	onChange,
	onExpand,
	children,
	fullSize,
	search
}: IMobileFilterProps) => {
	const sortedElements = useSortedElements(elements, selected);

	const handleClick = (item: IMobileFilterItem) => {
		if (onChange) {
			if (isActive(item, selected)) {
				onChange(selected.filter(id => id !== item.id));
			} else {
				onChange([...selected, item.id]);
			}
		}
	};

	return (
		<ErrorBoundary
			label="MobileFilter"
			component={(
				<div className="mobile-filter">
					<ErrorMessage/>
				</div>
			)}
		>
			<div
				className={classNames('mobile-filter', className)}
				style={style}
			>
				<div className="mobile-filter__info">
					<div className="mobile-filter__label">{label}</div>
					{onExpand ? (
						<LinkButton
							label="Все"
							onClick={onExpand}
						/>
					) : null}
				</div>
				{search && (
					<div className="mobile-filter__search">
						{search}
					</div>
				)}
				{children ?? (
					<div className={classNames('mobile-filter__tabs', {'mobile-filter__tabs_fullsize': fullSize})}>
						{sortedElements.map(item => (
							<MobileFilterButton
								key={item.id}
								className="mobile-filter__item"
								label={item.name}
								active={isActive(item, selected)}
								onClick={() => handleClick(item)}
							/>
						))}
					</div>
				)}
			</div>
		</ErrorBoundary>
	);
};

MobileFilter.displayName = 'MobileFilter';

export default MobileFilter;
