import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import React from 'react';
import {CellProps} from 'react-table';


export const NameCell = ({row}: CellProps<IPreparedContract>) => {
	const {name, number} = row.original;

	return (
		<div className="contracts__cell-name">
			<div className="contracts__cell-name-text">{name}</div>
			{number && (
				<div className="contracts__cell-name-number">{`№${number}`}</div>
			)}
		</div>
	);
};