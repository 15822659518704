import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {requestProblems} from '@src/api/backend/problems';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';

export type IGetOwnerAcptProblemsPayload = INormalizedData<IListProblem>;

const request = () => ({type: types.GET_PROBLEMS_REQUEST});

const success = (response: IGetOwnerAcptProblemsPayload) => ({
	type: types.GET_PROBLEMS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке нарушений');
	return {
		type: types.GET_PROBLEMS_FAILURE,
		payload: error
	};
};

/**
 * Получает список нарушений для конкретной передачи
 */
export const getOwnerAcceptanceProblems = (objectId: string, acceptanceId: string) =>
	checkExpiration<IState, IGetOwnerAcptProblemsPayload, ApiAction>(
		s => s.entities.ownerAcceptance.problems,
		createApiAction<IGetOwnerAcptProblemsPayload>(request, success, failure, () =>
			requestProblems(
				{
					objects: [objectId],
					ownerAcceptances: [acceptanceId]
				},
				{createdAt: false},
				0,
				1000
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);
