import React, {memo, useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SelectPopup, Select2, SelectOption} from '@tehzor/ui-components';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeScheduleMode} from '@src/store/modules/settings/pages/tasks/actions';
import {ITasksSettingsMode} from '@src/store/modules/settings/pages/tasks/interfaces';
import {generateModeData} from '@src/store/modules/settings/pages/tasks/utils/scheduleUtils';
import classNames from 'classnames';

const data = generateModeData();

const arrowIcon = <i className="tz-simple-arrow-16"/>;

export const SelectMode = memo(() => {
  const {settings} = useAppSelector(extractTasksScheduleSettings);
  const dispatch = useAppDispatch();

  const handleChange = useCallback((mode: ITasksSettingsMode) => {
    dispatch(changeScheduleMode(mode));
  }, [dispatch]);

  const currentModeName = data.find(item => item.id === settings.currentMode)?.name;

  return (
	<SelectPopup
		className="tasks-page__select-mode"
		trigger={props => {
			const handleClick = () => {
				props.toggle && props.toggle();
			};

			return (
				<button
					type="button"
					onClick={handleClick}
					ref={props.ref}
					className="tasks-page__select-mode-btn"
					disabled={props.disabled}
				>
					<div className="tasks-page__select-mode-text">
						Показать:
						{' '}
						<span className="tasks-page__select-mode-btn-title">
							{currentModeName}
						</span>
					</div>
					<div className={
						classNames('tasks-page__select-mode-btn-icon',
						{'tasks-page__select-mode-btn-icon_open': props.isOpen})
					}
					>
						{arrowIcon}
					</div>
				</button>
			);
		}}
	>
		<Select2
			value={settings?.currentMode}
			onChange={handleChange}
		>
			{data.map(mode => (
				<SelectOption
					key={mode.id}
					itemKey={mode.id}
					content={mode.name}
				/>
      ))}
		</Select2>
	</SelectPopup>
);
});