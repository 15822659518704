import React, {Dispatch, useCallback, useMemo} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import DateTimeRangePicker from '@tehzor/ui-components/src/components/dates/DateTimeRangePicker';
import {format} from 'date-fns';

const formatString = 'dd.MM.yyyy HH:mm';

interface IDateTimeSelectProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	valueFrom?: number | null;
	valueTo?: number | null;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasErrorFrom?: boolean;
	hasErrorTo?: boolean;
	fieldFrom?: keyof S;
	fieldTo?: keyof S;
}

export const DateTimeSelect = <S extends Record<string, unknown>, E>({
	className,
	style,
	label = 'Срок исполнения',
	valueFrom,
	valueTo,
	editingDispatch,
	required,
	disabled = false,
	hasErrorFrom = false,
	hasErrorTo = false,
	fieldFrom = 'taskIntervalStart',
	fieldTo = 'taskIntervalEnd'
}: IDateTimeSelectProps<S, E>) => {
	const handleChange = useCallback(
		(start: Date | null, end: Date | null) => {
			editingDispatch({
				type: 'update',
				field: fieldFrom,
				value: start ? start.getTime() : null
			});
			editingDispatch({
				type: 'update',
				field: fieldTo,
				value: end ? end.getTime() : null
			});

			if (required) {
				editingDispatch({type: 'update-error', field: fieldFrom});
				editingDispatch({type: 'update-error', field: fieldTo});
			}
		},
		[editingDispatch, fieldFrom, fieldTo, required]
	);

	const formatValue = useCallback(
		(start?: Date | null, end?: Date | null) =>
			(start && end
				? `${format(start, formatString)} - ${format(end, formatString)}`
				: 'Выберите диапазон'),
		[]
	);

	const dateFrom = useMemo(
		() => (valueFrom ? new Date(valueFrom) : undefined),
		[valueFrom]
	);

	const dateTo = useMemo(
		() => (valueTo ? new Date(valueTo) : undefined),
		[valueTo]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<DateTimeRangePicker
				trigger={props => (
					<TextFieldWithForwardedRef
						elementType="div"
						value={formatValue(props.valueFrom, props.valueTo)}
						icon={<i className="tz-calendar-20"/>}
						error={required && (hasErrorFrom || hasErrorTo)
							? 'Укажите диапазон'
							: undefined
						}
						disabled={disabled}
						ref={props.ref}
						onClick={props.toggle}
					/>
				)}
				dialogProps={{title: 'Выберите дату'}}
				valueFrom={dateFrom}
				valueTo={dateTo}
				onChange={handleChange}
			/>
		</div>
	);
};
