import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import ILocationMapPoint from '@tehzor/tools/interfaces/ILocationMapPoint';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import {useEffect, useState} from 'react';
import {getSectorsDisplayValue} from '../utils/getSectorsDisplayValue';
import {InputType, ViewerType} from '../LocationSelect';
import {getPointsDisplayValue} from '../utils/getPointsDisplayValue';

export const useDisplayValueState = (
	viewerType: ViewerType,
	inputType: InputType,
	planSectors: ILocationMarker[],
	planPoints: ILocationMarker[],
	mapPoints: ILocationMapPoint[],
	layers: ILayer[]
) => {
	const [value, setValue] = useState<string[]>([]);

	useEffect(() => {
		let newValue = [] as string[];
		if (viewerType === 'plan') {
			if (inputType === 'sectors') {
				newValue = getSectorsDisplayValue(planSectors, layers);
			} else if (inputType === 'points') {
				newValue = getPointsDisplayValue(planPoints);
			}
		} else if (viewerType === 'map') {
			if (inputType === 'points') {
				newValue = getPointsDisplayValue(mapPoints);
			}
		}
		setValue(newValue);
	}, [viewerType, inputType, planSectors, planPoints, mapPoints, layers]);

	return value;
};
