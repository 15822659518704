import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import React, {useEffect} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {Desktop} from './components/Desktop';
import {
	extractCurrentTreeObject,
	extractObject
} from '@src/store/modules/dictionaries/objects/selectors';
import './ObjectPage.less';
import {Mobile} from './components/Mobile';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {Menu} from './components/Menu';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import usePermissionsForObject from '../ObjectsPage/utils/usePermissionsForObject';
import {useAsync} from 'react-use';
import {getObject} from '@src/store/modules/entities/object/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {addErrorToast} from '@src/utils/toasts';
import {getPlans} from '@src/store/modules/dictionaries/plans/actions';

export const ObjectPage = () => {
	const {objectId} = useStrictParams<{objectId: string}>();
	useScrollRestoration();

	const dispatch = useAppDispatch();

	const object = useAppSelector(
		s => extractCurrentTreeObject(s, objectId) || extractObject(s, objectId)
	);
	const stages = useAppSelector(extractObjectStagesAsArray);
	useAsync(async () => {
		await dispatch(getObject(objectId));
		await dispatch(getPlans([objectId]));
	}, []);

	const user = useAppSelector(extractUserProfile);
	const permissions = usePermissionsForObject(object.id, user);

	useEffect(() => {
		if (!permissions.canViewObject) {
			addErrorToast('Отсутствие доступа', 'к просмотру страницы');
		}
	}, [permissions.canViewObject]);

	useAppHeader(
		{
			title: object ? object.name : '',
			showBackBtn: true,
			mobileRightButtons: (
				<Menu
					object={object}
					permissions={permissions}
				/>
			)
		},
		[object, permissions]
	);

	const isDesktop = useIsDesktop();

	if (!permissions.canViewObject) {
		return null;
	}
	if (isDesktop) {
		return (
			<Desktop
				object={object}
				stages={stages}
				permissions={permissions}
			/>
		);
	}

	return (
		<Mobile
			object={object}
			stages={stages}
		/>
);
};
