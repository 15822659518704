import {useQueryClient} from '@tanstack/react-query';
import {authorizedPersonsQueryKeys} from '../keys';
import {addErrorToast} from '@src/utils/toasts';
import {requestAuthorizedPersons} from '@src/api/backend/authorizedPersons';

export const useAuthorizedPersonsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(authorizedPersonsQueryKeys.list(), {
		queryFn: async () => requestAuthorizedPersons(),
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке уполномоченных лиц');
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
};