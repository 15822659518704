import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@src/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTaskPrioritiesAsArray} from '@src/store/modules/dictionaries/taskPriorities/selectors';

interface ITaskPrioritySelectProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const TaskPrioritySelect = <S extends {taskPriority?: string}, E>({
	className,
	style,
	label = 'Приоритет задачи',
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: ITaskPrioritySelectProps<S, E>) => {
	const priorities = useAppSelector(s => extractTaskPrioritiesAsArray(s));
	const prioritiesMap = useAppSelector(s => s.dictionaries.taskPriorities.byId);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'taskPriority', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'taskPriority'});
			}
		},
		[editingDispatch, required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={value ? prioritiesMap[value]?.name : ''}
						icon={<i className="tz-star-priority-20"/>}
						error={required && hasError ? 'Выберите приоритет задачи' : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{priorities.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
