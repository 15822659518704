import React, {useState, useCallback} from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {ActionButtons, Button, Dialog, Option, Select, TextField} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractObjectsAsArray} from '@src/store/modules/dictionaries/objects/selectors';
import {copyPlan} from '@src/store/modules/entities/plan/actions';

// TODO удалить
const SelectControlRenderer = (items: Array<{label: string}>) => {
	const label = items.map(item => item.label).join(', ');

	return (
		<div
			className="entities-filters__select-value"
			title={label}
		>
			{label}
		</div>
	);
};

interface IMovingDialogProps {
	objectId: string;
	planId: string;
	open: boolean;
	onClose: () => void;
}

export const PlanMovingDialog = (props: IMovingDialogProps) => {
	const dispatch = useAppDispatch();
	const {objectId, planId, open, onClose} = props;
	const objects = useAppSelector(extractObjectsAsArray);
	const [toObjectId, setToObjectId] = useState(objectId);
	const [numberOfCopies, setNumberOfCopies] = useState('1');

	const handleObjectChange = useCallback((value: string) => {
		setToObjectId(value);
	}, []);

	const handleClose = useCallback(() => {
		setNumberOfCopies('1');
		onClose();
	}, [onClose]);

	const [, handleCopy] = useAsyncFn(async () => {
		await dispatch(copyPlan(objectId, planId, toObjectId, Number(numberOfCopies)));
		onClose();
	}, [objectId, planId, toObjectId, numberOfCopies]);

	const handleChange = useCallback((value: string) => {
		setNumberOfCopies(value);
	}, []);

	return (
		<Dialog
			className="space-page__claim-moving"
			isOpen={open}
			footer={(
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Копировать"
						onClick={handleCopy}
					/>
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleClose}
					/>
				</ActionButtons>
			)}
			onRequestClose={handleClose}
		>
			<div>
				<div>Выберете объект</div>
				<Select
					style={{display: 'block', margin: '10px 0 20px'}}
					allowClear={false}
					multiple={false}
					placeholder="Объект"
					menuMaxHeight="300px"
					controlRender={SelectControlRenderer}
					value={toObjectId}
					onChange={handleObjectChange}
				>
					{objects.map(item => (
						<Option
							key={item.id}
							value={item.id}
						>
							{item.name}
						</Option>
					))}
				</Select>

				<div>
					<div>Введите количество копий</div>
					<TextField
						value={numberOfCopies}
						elementType="input"
						onChange={handleChange}
						elementProps={{type: 'number', max: 30, maxLength: 2}}
					/>
				</div>
			</div>
		</Dialog>
	);
};
