import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import React from 'react';
import {CellProps} from 'react-table';

interface ICompanyCellProps extends CellProps<IPreparedContract> {
	isMobile?: boolean;
}

export const CompanyCell = ({row, isMobile}: ICompanyCellProps) => (
	<div className="contracts__cell-company">
		{isMobile && (
			<div className="contracts__cell-company-header">Заказчик:</div>
		)}
		<div className="contracts__cell-company-name">
			{row.original.company?.name}
		</div>

		{
			// ! TODO (contracts) Подназвание у компаний
		}
	</div>
);