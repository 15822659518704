import React, {createContext, PropsWithChildren} from 'react';

import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteCheckList} from '@src/store/modules/entities/checkList/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IGetCheckListsResponse} from '@src/api/backend/checkLists';
import {IPreparedCheckList} from '../interfaces/IPreparedCheckList';

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedCheckList>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	reloadList
}: PropsWithChildren<{reloadList: () => Promise<IGetCheckListsResponse | undefined>}>) => {
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить чек-лист?',
		'Все связанные данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedCheckList>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await dispatch(deleteCheckList(action.payload.id));
				await reloadList();
			}
		}
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
