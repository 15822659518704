import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeProblemDeleteRequest} from '@src/api/backend/problem';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {deleteLocalProblem} from '@src/cache/actions/problem/deleteLocalProblem';

export interface IDeleteProblemPayload {
	objectId: string;
	problemId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, problemId: string, quietly?: boolean) => {
	if (!quietly) {
		addInfoToast('Удалено', 'Нарушение успешно удалено');
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			problemId
		} as IDeleteProblemPayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении нарушения');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */

export const deleteProblemActions = {request, success, failure};
export const deleteProblem = (objectId: string, problemId: string) =>
	createApiAction(
		request,
		() => success(objectId, problemId),
		failure,
		async () => {
			if (queryClient.getQueryData(problemsQueryKeys.savingData(problemId))) {
				await deleteLocalProblem(problemId);
				return;
			}
			await makeProblemDeleteRequest(objectId, problemId);
			void queryClient.invalidateQueries(problemsQueryKeys.list());
		}
	);
