import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {extractSpaceIndicatorsAsArray} from '@src/store/modules/dictionaries/spaceIndicatorsSets/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

interface ISpacesIndicatorsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpacesIndicatorsFilterMobile = memo((props: ISpacesIndicatorsFilterMobileProps) => {
	const indicators = useAppSelector(s => extractSpaceIndicatorsAsArray(s, props.objectId));

	return (
		<BaseListFilterMobile
			{...props}
			options={indicators}
			label="Индикаторы"
			filterName="indicators"
		/>
	);
});
