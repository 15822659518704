import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoriesState} from './reducers';

const getCategories = (state: IState) => state.dictionaries.categories;
const getCategoriesSetId = (state: IState, categoriesSetId?: string) => categoriesSetId;

/**
 * Извлекает все виды работ
 */
export const extractCategories = createSelector(getCategories, data => data);

/**
 * Извлекает все виды работ в виде массив
 */
export const extractCategoriesAsArray = createSelector(getCategories, data =>
	data.allIds?.map(id => data.byId[id]));

/**
 * Извлекает отсортированные виды работ по id наборов
 */
export const extractAllCategories = createSelector(extractCategoriesAsArray, categories => {
	const allCategories: { [setId: string]: ICategoriesState } = {};
	categories?.forEach(category => {
		if (!allCategories[category.categoriesSetId]?.allIds.length) {
			allCategories[category.categoriesSetId] = {
				allIds: [],
				byId: {},
				loaded: true
			};
		}

		allCategories[category.categoriesSetId].allIds.push(category.id);
		allCategories[category.categoriesSetId].byId[category.id] = category;
	});

	return allCategories;
});

/**
 * Извлекает отсортированные виды работ по id наборов в виде массив
 */
export const extractAllCategoriesAsArray = createSelector(extractCategoriesAsArray, categories => {
	const allCategories: { [setId: string]: ICategory[] } = {};

	categories?.forEach(category => {
		if (!allCategories[category.categoriesSetId]?.length) {
			allCategories[category.categoriesSetId] = [];
		}

		allCategories[category.categoriesSetId].push(category);
	});

	return allCategories;
});

/**
 * Извлекает виды работ для конкретного набора
 */
export const extractCategoriesBySetId = createSelector(
	[extractAllCategories, getCategoriesSetId],
	(allCategories, categoriesSetId) => {
		let allIds: string[] = [];
		let byId: { [id: string]: ICategory } = {};
		if (!categoriesSetId) {
			return undefined;
		}
		if (allCategories[categoriesSetId]) {
			allIds = allCategories[categoriesSetId].allIds || [];
			byId = allCategories[categoriesSetId].byId || [];
		}

		const total = allIds.length;

		return {byId, allIds, total};
	}
);

/**
 * Извлекает виды работ для конкретного набора в виде массива
 */
export const extractCategoriesAsArrayBySetId = createSelector(
	[extractCategoriesBySetId],
	categories => categories?.allIds?.map((id: string) => categories.byId[id])
);

/**
 * Извлекает вид работ по Id
 */
export const extractCategoryById = createSelector(
	[getCategories, (state, categoryId?: string) => categoryId],
	(categories, categoryId) => {
		if (!categoryId) {
			return undefined;
		}
		return categories?.byId[categoryId];
	}
);
