import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetCategoriesSetsResponse} from '@src/api/backend/categoriesSets';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {queryClient} from '@src/api/QueryClient';

export type IGetCategoriesSetsPayload = IGetCategoriesSetsResponse;

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (response: IGetCategoriesSetsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {...response} as IGetCategoriesSetsPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка наборов видов работ');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

export const getCategoriesSets = (objectId = 'all') =>
	createApiAction<IGetCategoriesSetsResponse>(
		() => request(objectId),
		success,
		failure,
		() => queryClient.fetchQuery(categoriesSetsQueryKeys.list(), {staleTime: Infinity})
	);
