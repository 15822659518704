import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetSpacesResponse} from '@src/api/backend/spaces';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {getCachedSpacesByObject} from '@src/store/persistentStorage/offlineActions/spaces';

export interface IGetSpacesSchemaPayload extends IGetSpacesResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_SCHEMA_REQUEST,
	payload: {objectId}
});

const success = (response: IGetSpacesResponse, objectId: string) => ({
	type: types.GET_SCHEMA_SUCCESS,
	payload: {...response, objectId}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка помещений');
	return {
		type: types.GET_SCHEMA_FAILURE,
		payload: error
	};
};

/**
 * Получает список помещений для шахматки конкретного объекта
 *
 * @param objectId id объекта
 */
export const getSpacesSchema
	= (objectId: string): AppThunkAction<Promise<IGetSpacesResponse | undefined>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetSpacesResponse>(
				() => request(objectId),
				r => success(r, objectId),
				failure,
				async () => {
					const s = getState();
					if (s.settings.offlineMode.available && !s.offlineMode.networkStatus) {
						const offlineData = await getCachedSpacesByObject(objectId);
						return offlineData;
					}
					const data = await queryClient.fetchQuery<IGetSpacesResponse>(
						[...spacesQueryKeys.list(), {objects: [objectId]}, undefined, 0, 10000],
						{staleTime: Infinity}
					);
					return data;
				}
			)
		);
