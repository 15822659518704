import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {QueryClient} from '@tanstack/react-query';
import {contractsQueryKeys} from '../../keys';

export const invalidateQueries = async (queryClient: QueryClient) => {
	await queryClient.invalidateQueries({
		queryKey: contractsQueryKeys.localList()
	});
	await queryClient.invalidateQueries({
		queryKey: contractsQueryKeys.list()
	});
	await queryClient.invalidateQueries({
		queryKey: contractsQueryKeys.paginate()
	});
	await queryClient.invalidateQueries({
		queryKey: problemsQueryKeys.details()
	});
}

export const invalidateEditQueries = async (
	queryClient: QueryClient, contractId: string
) => {
	await queryClient.invalidateQueries({
		queryKey: contractsQueryKeys.detail(contractId)
	});
	await queryClient.invalidateQueries({
		queryKey: contractsQueryKeys.paginate()
	});
	await queryClient.invalidateQueries({
		queryKey: contractsQueryKeys.list()
	});
	await queryClient.invalidateQueries({
		queryKey: problemsQueryKeys.details()
	});
}