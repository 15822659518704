import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';

export type IGetCategoriesResponse = INormalizedData<ICategory>;

/**
 * Возвращает список видов работ
 */
export const requestGetCategories = () =>
	wsConnector.sendAuthorizedRequest<IGetCategoriesResponse>('getCategories', {}, {timeout: 180});
