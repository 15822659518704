import React, {memo, useMemo} from 'react';
import {Tags, TagsPlaceholder} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractStatsContractors,
	extractStatsSources
} from '@src/store/modules/statistics/sources/selectors';
import {makeTagsData} from '@src/components/StatsFiltersDialog/utils/makeTagsData';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	deleteFiltersItem,
	deleteFiltersItems
} from '@src/store/modules/settings/pages/statistics/warranty/actions';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';

const placeholderProps = {inline: true, minWidth: 150, maxWidth: 250};

const StatsFiltersTags = () => {
	const sources = useAppSelector(extractStatsSources);
	const objects = useAppSelector(s => s.dictionaries.objects);
	const companies = useAppSelector(s => s.dictionaries.companies);
	const contractorsSources = useAppSelector(extractStatsContractors);
	const users = useAppSelector(s => s.dictionaries.users);
	const filters = useAppSelector(s => s.settings.pages.statistics.warranty.filters);
	const loading = useAppSelector(extractSourcesLoading);
	const dispatch = useAppDispatch();

	const value = useMemo(
		() => makeTagsData(filters, sources, objects, companies, contractorsSources, users),
		[filters, sources, objects, companies, users]
	);

	const handleCompanyDelete = (id: string) => {
		const items = objects.allIds.reduce<string[]>((prev, objectId) => {
			if (objects.byId[objectId].companyId === id) {
				prev.push(objectId);
			}
			return prev;
		}, []);
		if (items.length) {
			dispatch(deleteFiltersItems('objects', items));
		}
		dispatch(deleteFiltersItem('companies', id));
	};

	const handleObjectDelete = (id: string) => {
		let ids = [id];
		const descendants = objects.allIds.reduce<string[]>((prev, objectId) => {
			if (objects.byId[objectId].ancestors?.includes(id)) {
				prev.push(objectId);
			}
			return prev;
		}, []);
		if (descendants.length) {
			ids = ids.concat(descendants);
		}
		dispatch(deleteFiltersItems('objects', ids));
	};

	const handleDelete = (field: string, id: string) => dispatch(deleteFiltersItem(field, id));

	const tags = [];

	if (loading) {
		if (value.companies?.length) {
			tags.push(
				<TagsPlaceholder
					{...placeholderProps}
					key="companies"
					count={Math.min(value.companies.length, 5)}
					color="#EDDDFA"
				/>
			);
		}
		if (value.objects?.length) {
			tags.push(
				<TagsPlaceholder
					{...placeholderProps}
					key="objects"
					count={Math.min(value.objects.length, 5)}
					color="#FAF2DD"
				/>
			);
		}
		if (value.users?.length) {
			tags.push(
				<TagsPlaceholder
					{...placeholderProps}
					key="users"
					count={Math.min(value.users.length, 5)}
					color="#DDFAF8"
				/>
			);
		}
		if (value.contractors?.length) {
			tags.push(
				<TagsPlaceholder
					{...placeholderProps}
					key="contractors"
					count={Math.min(value.contractors.length, 5)}
					color="#FAE7DD"
				/>
			);
		}
		if (value.contractorsUsers?.length) {
			tags.push(
				<TagsPlaceholder
					{...placeholderProps}
					key="contractorsUsers"
					count={Math.min(value.contractorsUsers.length, 5)}
					color="#FAE7DD"
				/>
			);
		}
	} else {
		if (value.companies?.length) {
			tags.push(
				<Tags
					key="companies"
					data={value.companies}
					color="#EDDDFA"
					inline
					onDelete={handleCompanyDelete}
				/>
			);
		}
		if (value.objects?.length) {
			tags.push(
				<Tags
					key="objects"
					data={value.objects}
					color="#FAF2DD"
					inline
					onDelete={handleObjectDelete}
				/>
			);
		}
		if (value.users?.length) {
			tags.push(
				<Tags
					key="users"
					data={value.users}
					color="#DDFAF8"
					inline
					onDelete={id => handleDelete('users', id)}
				/>
			);
		}
		if (value.contractors?.length) {
			tags.push(
				<Tags
					key="contractors"
					data={value.contractors}
					color="#FAE7DD"
					inline
					onDelete={id => handleDelete('contractors', id)}
				/>
			);
		}
		if (value.contractorsUsers?.length) {
			tags.push(
				<Tags
					key="contractorsUsers"
					data={value.contractorsUsers}
					color="#FAE7DD"
					inline
					onDelete={id => handleDelete('contractorsUsers', id)}
				/>
			);
		}
	}
	return tags.length ? (
		<div className="statistics-page__filters-tags">
			<div className="statistics-page__filters-tags-wrap">{tags}</div>
		</div>
	) : null;
};

export default memo(StatsFiltersTags);
