import {IGetSpaceResponse} from '@src/api/backend/space';
import {IGetSpacesResponse} from '@src/api/backend/spaces';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {queryClient} from '@src/api/QueryClient';
import {store} from '@src/store/appStore';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';

export const getCachedSpacesByObject = async (
	objectId: string,
	offset = 0,
	pageSize?: number,
	filters?: ISpacesFiltersState
) => {
	const state = store.getState();
	const selectedObjects = state.settings.offlineMode.cache.objects;
	if (selectedObjects) {
		const spacesCache = queryClient.getQueryData<IGetSpacesResponse>([
			...spacesQueryKeys.list(),
			{objects: selectedObjects},
			undefined,
			undefined,
			1000000
		]);
		if (spacesCache) {
			const asArray = Object.entries(spacesCache.byId);
			// Фильтруем по объектам
			const filteredByObjects
				= filters && filters.objects
					? asArray.filter(([, value]) => filters.objects?.includes(value.objectId))
					: asArray.filter(([, value]) => value.objectId === objectId);

			// Фильтруем по типам помещений
			const filterByTypes
				= filters && filters.types
					? filteredByObjects.filter(([, value]) => filters.types?.includes(value.type))
					: filteredByObjects;

			// Фильтруем по статусам помещений
			const filterByStatuses
				= filters && filters.statuses
					? filterByTypes.filter(([, value]) => filters.statuses?.includes(value.status))
					: filterByTypes;

			// Фильтруем по индикаторам
			const filterByIndicators
				= filters && filters.indicators
					? filterByStatuses.filter(([, value]) =>
							filters.indicators?.every(el => value.indicators?.includes(el)))
					: filterByStatuses;

			const filteredByOffset
				= Number.isInteger(offset) && pageSize
					? filterByIndicators.slice(offset, offset + pageSize)
					: filterByIndicators;

			const byId = Object.fromEntries(filteredByOffset);
			const allIds = spacesCache.allIds.filter(id => Object.keys(byId).includes(id));
			const spaces = Promise.resolve({
				...spacesCache,
				byId,
				allIds,
				offset: offset || 0,
				limit: 1000000,
				total: filterByIndicators.length
			} as IGetSpacesResponse);
			return spaces;
		}
	}
	return Promise.resolve({
		allIds: [],
		byId: {},
		offset: 0,
		total: 0,
		limit: 0
	}) as Promise<IGetSpacesResponse>;
};

export const getCachedSpace = async (spaceId: string) => {
	const state = store.getState();
	const selectedObjects = state.settings.offlineMode.cache.objects;
	if (selectedObjects) {
		const spacesCache = queryClient.getQueryData<IGetSpacesResponse>([
			...spacesQueryKeys.list(),
			{objects: selectedObjects},
			undefined,
			undefined,
			1000000
		]);
		if (spacesCache) {
			const space = spacesCache.byId[spaceId] as IGetSpaceResponse;
			return Promise.resolve(space);
		}
	}
	return {} as Promise<IGetSpaceResponse>;
};
