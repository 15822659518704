import {requestChecks} from '@src/api/backend/checks';
import {QueryFunctionContext, QueryKey, useQueryClient} from '@tanstack/react-query';
import {checksQueryKeys, IChecksListQueryKey} from '../keys';
import {endOfDay, getTime} from 'date-fns';
import {requestCheck} from '@src/api/backend/check';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';

export const useChecksQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(checksQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IChecksListQueryKey>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestChecks(filters, sort, offset, pageSize);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(checksQueryKeys.latest(), {
		queryFn: () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestChecks({createdAtFrom: date}, {createdAt: false}, 0, 100);
		},
		staleTime: getTime(endOfDay(new Date())),
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
	queryClient.setQueryDefaults(checksQueryKeys.details(), {
		queryFn: async ({queryKey}) => requestCheck(queryKey[3] as string, queryKey[2] as string),
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(checksQueryKeys.localList(), {
		queryFn: async ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localChecks = await new Promise<Array<[QueryKey, IListCheck | undefined]>>(
				resolve => {
					const local = queryClient.getQueriesData<IListCheck>({
						queryKey: checksQueryKeys.details(),
						predicate: ({state}) => {
							const {data} = state;
							if ((data as IListCheck).transferStatus) {
								if (!objectId || objectId === 'all') {
									return true;
								}
								if (objectId === (data as IListCheck).objectId) {
									return true;
								}
							}
							return false;
						}
					});
					resolve(local);
				}
			);
			return localChecks.map(([, check]) => check) as IListCheck[];
		},
		staleTime: 0,
		cacheTime: Infinity,
		networkMode: 'always'
	});
};
