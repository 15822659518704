import {wsConnector} from '../wsConnector';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

export type IGetLastProblemReplyResponse = IProblemComment | false | undefined;

/**
 * Возвращает последний ответ на нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const requestLastProblemReply = (objectId: string, problemId: string) =>
	wsConnector.sendAuthorizedRequest<IGetLastProblemReplyResponse>('getLastProblemReply', {
		objectId,
		problemId
	});
