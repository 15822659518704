import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IEditProblemTagResponse, makeProblemTagEditRequest} from '@src/api/backend/problemTags';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';

export type IEditProblemTagPayload = IEditProblemTagResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditProblemTagResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении правила');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Обновляет 1 метку за раз
 *
 * @param tagId id метки
 * @param setId id набора
 * @param tag данные для добавления
 */
export const editProblemTag = (
	tagId: string,
	setId: string,
	tag: ISavingProblemTag
) =>
	createApiAction<IEditProblemTagResponse>(
		request,
		(res: IEditProblemTagResponse) => success(res),
		failure,
		() => makeProblemTagEditRequest(tagId, setId, tag)
	);
