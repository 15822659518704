import {requestGetContract} from '@src/api/backend/contract';
import {requestGetPaginateContracts} from '@src/api/backend/contracts';
import {requestGetListContracts} from '@src/api/backend/contracts/getList';
import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IGetContractQueryKey, IGetListContractsQueryKey, IGetPaginateContractsQueryKey, contractsQueryKeys} from '../keys';

export const useContractsQueryDefaults = () => {
	const queryClient = useQueryClient();

	// Получение пагинированного списка договоров
	queryClient.setQueryDefaults(contractsQueryKeys.paginate(), {
		queryFn: async ({queryKey}: QueryFunctionContext<
			IGetPaginateContractsQueryKey
		>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestGetPaginateContracts(filters, sort, offset, pageSize);
		}
	});

	// Получение договоров для нарушения
	queryClient.setQueryDefaults(contractsQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<
			IGetListContractsQueryKey
		>) => {
			const [, , filters, sort] = queryKey;
			return requestGetListContracts(filters, sort);
		}
	});

	// Получение одного договора
	queryClient.setQueryDefaults(contractsQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<
			IGetContractQueryKey
		>) => {
			const [, , contractId] = queryKey;
			return requestGetContract(contractId);
		}
	});
};