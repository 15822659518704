import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';

export const createCommentDifference = (
	prev: {
		description: string | undefined;
		attachments: IAttachment[] | undefined;
	},
	next: {
		description: string | undefined;
		attachments: IAttachment[] | undefined;
	},
	official?: boolean
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.COMMENT,
	name: official ? 'официальный ответ' : 'комментарий'
});

export const createCommentDeletion = (
	prev: {
		description: string | undefined;
		attachments: IAttachment[] | undefined;
	},
	official?: boolean
): IHistoryData => ({
	prev,
	next: undefined,
	type: HistoryTypeId.COMMENT,
	name: official ? 'официальный ответ' : 'комментарий'
});

export const createCommentCreation = (
	next: {
		description: string | undefined;
		attachments: IAttachment[] | undefined;
	},
	official?: boolean
): IHistoryData => ({
	prev: undefined,
	next,
	type: HistoryTypeId.COMMENT,
	name: official ? 'официальный ответ' : 'комментарий'
});
