import React from 'react';
import './WorkAcceptanceDateCell.less';
import {CellProps} from 'react-table';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {EntityInfo, UserInfo} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {AcceptanceDateDisplayValue} from '@src/components/AcceptanceDateDisplayValue/AcceptanceDateDisplayValue';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

export const WorkAcceptanceDateCell = ({row}: CellProps<IListWorkAcceptance>) => {
	const {status, acceptanceDate, respUsers, activeGroup} = row.original;
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const groupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);
	const isDesktop = useIsDesktop();

	return (
		<>
			{acceptanceDate ? (
				<AcceptanceDateDisplayValue
					className="problem-fix-cell-date"
					value={acceptanceDate}
					status={status}
				/>
			) : null}

			{respUsers?.length ? (
				respUsers.map(userId => (
					<UserInfo
						key={userId}
						className="problem-fix-cell-user"
						user={usersMap[userId]}
						avatarSize={isDesktop ? '40' : '34'}
						avatarColoring="text"
					/>
				))
			) : activeGroup ? (
				<EntityInfo
					className="problem-fix-cell-user"
					title={groupsMap[activeGroup]?.name}
					icon={<i className="tz-users-24"/>}
					iconColor="#718198"
					backgroundColor="#FFDEB8"
				/>
			) : null}
		</>
	);
};
