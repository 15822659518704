import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import React, {useCallback, useState} from 'react';
import EditableOwnerAcceptanceAttachmentsDialog from '../EditableOwnerAcceptanceAttachmentsDialog';

export function useEditableOwnerAcceptanceAttachmentsDialog(
	objectId: string,
	ownerAcceptanceId: string,
	ownerAcceptance: IOwnerAcceptance
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<EditableOwnerAcceptanceAttachmentsDialog
			objectId={objectId}
			ownerAcceptanceId={ownerAcceptanceId}
			ownerAcceptance={ownerAcceptance}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
