import {wsConnector} from '../wsConnector';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetWorkAcceptanceStatusesResponse = INormalizedData<IWorkAcceptanceStatus>;

/**
 * Возвращает список статусов приёмок работ
 */
export const requestWorkAcceptanceStatuses = () =>
	wsConnector.sendAuthorizedRequest<IGetWorkAcceptanceStatusesResponse>(
		'getWorkAcceptanceStatuses'
	);
