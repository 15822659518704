import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import React from 'react';
import './ProblemIndicator.less';

interface ProblemIndicatorProps {
	statuses: INormalizedData<IProblemStatus>;
	status: ProblemStatusId;
	count: number;
}

export const ProblemIndicator = ({statuses, status, count}: ProblemIndicatorProps) => {
	const statusEntity = statuses.byId[status];

	return (
		<div className="problem-indicator">
			<i
				className="tz-problem-16"
				style={{color: statusEntity.color}}
			/>
			<div className="problem-indicator__counter">{count}</div>
		</div>
	);
};
