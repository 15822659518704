import CellWrap from '@src/components/tableCells/CellWrap';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {Column} from 'react-table';
import LocalMobileProblemCell from './LocalMobileProblemCell';
import MobileProblemCell from './MobileProblemCell';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';

const getMobileCell = (objectId: string, settings?: IProblemSettings) =>
	CellWrap({
		problem: props =>
			MobileProblemCell({
				...props,
				settings,
				withObject: objectId === 'all'
			}),
		local_problem: props =>
			LocalMobileProblemCell({
				...props,
				settings,
				withObject: objectId === 'all'
			})
	});

export const getMobileColumns = (objectId: string, settings?: IProblemSettings) => {
	const mobileColumn = {
		id: 'object',
		Cell: getMobileCell(objectId, settings),
		className: 'problems-page__mobile-cell'
	};

	const columns = [mobileColumn];

	return columns as Array<Column<IProblemEntity>>;
};
