import React from 'react';
import classNames from 'classnames';

interface IProgressProps {
	inProgress?: boolean;
}

export const Progress = ({inProgress}: IProgressProps) => (
	<div className="responsible-view-field__progress">
		<div className="responsible-view-field__progress-start"/>
		<div className="responsible-view-field__progress-line"/>
		<div
			className={classNames(
				'responsible-view-field__progress-icon',
				inProgress
					? 'responsible-view-field__progress-icon_blue'
					: 'responsible-view-field__progress-icon_green'
			)}
		>
			<i className={inProgress ? 'tz-more-16' : 'tz-mark-2-16'}/>
		</div>
	</div>
);
