import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';

export interface IGetWorkingGroupsResponse extends INormalizedData<IWorkingGroup> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список рабочих групп
 */
export const requestWorkingGroups = async () =>
	wsConnector.sendAuthorizedRequest<IGetWorkingGroupsResponse>(
		'getWorkingGroups',
		{},
		{timeout: 180}
	);
