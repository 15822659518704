import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.pages.problem.delegationHistories.allIds || [];
const getById = (state: IState) => state.pages.problem.delegationHistories.byId || {};

/**
 * Возвращает записи истории делегирования в виде массива
 */
export const extractProblemDelegationHistoriesAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map(id => byId[id])
);
