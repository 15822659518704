import {ISpaceType} from '../interfaces/spaces/ISpaceType';

/**
 * Формирует заголовок помещения исходя из его типа
 *
 * @param name название помещения
 * @param altName дополнительное название
 * @param type тип помещения
 * @param useAltName использовать ли дополнительное название
 */
export function formSpaceTitle(name: string, altName?: string, type?: ISpaceType, useAltName?: boolean) {
	const n = useAltName && altName ? altName : name;
	if (type?.useInTitle) {
		return `${type.singularName} №${n}`;
	}
	return n;
}
