import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddWorkingGroupResponse, makeWorkingGroupAddRequest} from '@src/api/backend/workingGroup';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';

export type IAddWorkingGroupPayload = IAddWorkingGroupResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddWorkingGroupResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении рабочей группы');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет рабочую группу
 *
 * @param fields данные для добавления
 */
export const addWorkingGroup = (fields: ISavingWorkingGroup) =>
	createApiAction<IAddWorkingGroupResponse>(request, success, failure, () =>
		makeWorkingGroupAddRequest(fields));
