import React, {memo, useCallback} from 'react';
import {ITreeDataItem} from '../Tree';
import Checkbox from '../../inputs/checkbox/Checkbox';
import IconButton from '../../buttons/IconButton';
import classNames from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';
import {useTreeCtx} from '../utils/TreeCtx';

export interface ITreeItemProps<T extends ITreeDataItem> {
	data: ITwoWayTreeItem<T>;
	level: number;
	checkable?: boolean;
	multiple?: boolean;
	latestOnly?: boolean;
	disabled?: boolean;
}

const animationVariants = {
	opened: {opacity: 1, height: 'auto'},
	collapsed: {
		opacity: 0,
		height: 0,
		transition: {
			duration: 0.25,
			// Анимация до своих потомков необходима для избежания бага с резким
			// сворачиванием всех потомков при сворачивании родителя
			when: 'beforeChildren',
			staggerChildren: 0.5
		}
	}
};

const animationTransition = {type: 'tween', duration: 0.25};

const TreeItem = <T extends ITreeDataItem>(props: ITreeItemProps<T>) => {
	const {data, checkable, disabled: allDisabled, level} = props;
	const {id, content, children, disabled} = data;

	const {isExpanded, isChecked, isSemiChecked, changeExpanded, changeChecked} = useTreeCtx();

	const expanded = isExpanded(id);
	const checked = isChecked(id);
	const semiChecked = isSemiChecked(id);

	const handleExpand = useCallback(() => {
		changeExpanded(id, !expanded);
	}, [id, expanded, changeExpanded]);

	const handleCheck = useCallback(() => {
		changeChecked(data, !checked);
	}, [data, checked, changeChecked]);

	return (
		<li className={classNames('tree__item', `tree__item_level_${level}`)}>
			<div className={classNames('tree__item-row', {'tree__item-row_padding': children === undefined})}>
				{children !== undefined && (
					<IconButton
						className="tree__item-expand-btn"
						type="transparent"
						onClick={handleExpand}
					>
						<i
							className={classNames('tz-simple-arrow-24', 'tree__item-expand-btn-icon', {
								'tree__item-expand-btn-icon_expanded': expanded
							})}
						/>
					</IconButton>
				)}

				{checkable && !data.nonCheckable ? (
					<Checkbox
						className={{
							root: 'tree__item-checkbox',
							content: classNames('tree__item-label', `tree__item-label_level_${level}`)
						}}
						checked={checked || semiChecked}
						semiChecked={semiChecked}
						disabled={disabled || allDisabled}
						onChange={handleCheck}
					>
						{content}
					</Checkbox>
				) : (
					<span className={classNames('tree__item-label', `tree__item-label_level_${level}`)}>{content}</span>
				)}
			</div>

			{children !== undefined && (
				<AnimatePresence initial={false}>
					{expanded && (
						<motion.div
							className="tree__item-children-wrap"
							initial="collapsed"
							animate="opened"
							exit="collapsed"
							variants={animationVariants}
							transition={animationTransition}
						>
							<ul className={classNames('tree__item-children', `tree__item-children_level_${level}`)}>
								{children.map(item => (
									<TreeItem
										key={item.id}
										data={item}
										checkable={checkable}
										disabled={allDisabled}
										level={level + 1}
									/>
								))}
							</ul>
						</motion.div>
					)}
				</AnimatePresence>
			)}
		</li>
	);
};

TreeItem.displayName = 'TreeItem';

export default memo(TreeItem);
