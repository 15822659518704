import {IPreparedSpaceStatusesSet} from '@src/pages/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {IconButton} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import React, {useContext} from 'react';
import {CellProps} from 'react-table';
import {useAsyncFn} from 'react-use';
import {DispatchActionCtx} from '../SpaceStatusesSetsTable';

const deleteIcon = <i className="tz-delete"/>;

export const DeleteCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) => {
	const canDelete = row.original.canDelete;

	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить набор',
		'Удаление приведет к сбросу статусов помещений у объектов, Вы уверены, что хотите удалить набор?'
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			dispatchAction({type: 'delete', payload: row.original});
		}
	}, [dispatchAction, row.original]);

	return canDelete ? (
		<>
			<IconButton
				className="space-statuses-sets__cell-delete"
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	) : null;
};