import React from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {getStructuresList} from '@src/store/modules/entities/structures/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractFiltredStructuresListAsArray} from '@src/store/modules/entities/structures/selectors/lists';
import {extractCategoryById} from '@src/store/modules/dictionaries/categories/selectors';

export const WorkAcceptanceStructuresCell = ({row}: CellProps<IListWorkAcceptance>) => {
	const workAcceptance: IListWorkAcceptance = row.original;
	const dispatch = useAppDispatch();
	void dispatch(getStructuresList(workAcceptance.objectId));

	const category = useAppSelector(state => extractCategoryById(state, workAcceptance.categoryId));
	const structures: IListStructure[] = useAppSelector(state =>
		extractFiltredStructuresListAsArray(
			state,
			workAcceptance.objectId,
			workAcceptance.structureIds
		));

	return (
		<div>
			<div>{category?.name}</div>
			<div>{structures.map(str => str.name).join(', ')}</div>
		</div>
	);
};
