import React, {Dispatch, useCallback, useMemo, useState} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useUpdateEffect} from 'react-use';
import {extractCategoriesAsArray} from '@src/store/modules/dictionaries/categories/selectors';
import {extractFullCategoriesSetsAsArray} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import {makeTreeDataCategory} from './utils/makeTreeData';

interface ICategoryTreeSelectProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: string;
	value?: string[];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const CategoryTreeSelect = <S extends {categories?: 'categories'}, E>({
	className,
	style,
	field = 'categories',
	label = 'Виды работ',
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: ICategoryTreeSelectProps<S, E>) => {
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);

	const categoriesById = useAppSelector(s => s.dictionaries.categories.byId);

	const categories = useAppSelector(extractCategoriesAsArray);

	const categorySets = useAppSelector(extractFullCategoriesSetsAsArray);

	const treeData = useMemo(() =>
		makeTreeDataCategory(categories, categorySets, true),
	[categories, categorySets]);

	const handleChange = useCallback(() => {
		editingDispatch({type: 'update', field, value: selectedObjects});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [required, selectedObjects, field, editingDispatch]);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
	}, []);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
	}, [value]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	useUpdateEffect(() => {
		setSelectedObjects(value);
	}, [value]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={
							value && value?.length > 0
								? value.length > 1
									? `${categoriesById[value[0]]?.name} + ${value.length - 1}`
									: `${categoriesById[value[0]]?.name}`
								: undefined
						}
						icon={<i className="tz-simple-arrow-20"/>}
						error={required && hasError ? 'Выберите вид работ' : undefined}
						disabled={disabled}
					/>
				)}
				clearButton={!!selectedObjects?.length}
				footer
				noHeader
				count={selectedObjects?.length}
				onApply={handleChange}
				onCancel={handleCancel}
				onClear={handleClear}
			>
				<TreeSelect
					data={treeData}
					multiple
					value={selectedObjects}
					onChange={setSelectedObjects}
					expandedValue={expandedObjects}
					onExpand={handleExpand}
				/>
			</SelectPopup>
		</div>
	);
};
