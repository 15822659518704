import {IState} from '@src/store/modules';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetSpacesResponse} from '@src/api/backend/spaces';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {getCachedSpacesByObject} from '@src/store/persistentStorage/offlineActions/spaces';

export interface IGetSpacesPayload extends IGetSpacesResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_LIST_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetSpacesResponse) => ({
	type: types.GET_LIST_SUCCESS,
	payload: {
		...response,
		objectId
	} as IGetSpacesPayload
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка помещений');
	return {
		type: types.GET_LIST_FAILURE,
		payload: error
	};
};

/**
 * Получает список помещений для таблицы конкретного объекта
 *
 * @param objectId id объекта
 * @param targetObjects
 */
export const getSpacesList
	= (
		objectId: string,
		targetObjects: string[]
	): AppThunkAction<Promise<IGetSpacesResponse | undefined>> =>
	(dispatch, getState) =>
		dispatch(
			checkLoaded<IState, IGetSpacesResponse, ApiAction>(
				s => s.entities.spaces.lists[objectId],
				createApiAction<IGetSpacesResponse>(
					() => request(objectId),
					(response: IGetSpacesResponse) => success(objectId, response),
					failure,
					async () => {
						const s = getState();
						if (
							!s.entities.spaces.lists[objectId]
							|| !s.settings.pages.spaces.byPage[objectId]
						) {
							throw new StoreError('Empty store before getting spaces');
						}
						const {offset} = s.entities.spaces.lists[objectId];
						const {filters, sort, pageSize} = s.settings.pages.spaces.byPage[objectId];

						const f = {...filters};
						if (!f.objects?.length) {
							// Если в фильтре не выбраны объекты, то передаём целевые
							// (id объекта, на странице которого находимся, или id всех дочерних объектов)
							f.objects = targetObjects;
						}
						if (s.settings.offlineMode.available && !s.offlineMode.networkStatus) {
							const offlineData = await getCachedSpacesByObject(
								objectId,
								offset,
								pageSize,
								f
							);
							return offlineData;
						}
						const data = await queryClient.fetchQuery<IGetSpacesResponse>(
							[...spacesQueryKeys.list(), f, sort, offset, pageSize],
							{staleTime: Infinity}
						);
						return data;
					}
				)
			)
		);
