import React, {useCallback} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import './StructuresPage.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {Desktop} from './components/Desktop';
import {Mobile} from './components/Mobile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/structures/actions';
import {extractStructuresPageSettings} from '@src/store/modules/settings/pages/structures/selectors';
import {changeOffset} from '@src/store/modules/entities/structures/actions';
import {IStructuresFiltersState} from '@src/store/modules/settings/pages/structures/reducers/byPage';
import {LoadingPanel} from '@tehzor/ui-components';
import {extractStructuresListLoadingState} from '@src/store/modules/entities/structures/selectors/lists';

export const StructuresPage = () => {
	useScrollRestoration();

	const {objectId} = useStrictParams<{objectId: string}>();
	const {filters} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const isListLoading = useAppSelector(s => extractStructuresListLoadingState(s, objectId));

	const dispatch = useAppDispatch();

	const applyFilters = useCallback(
		(value: IStructuresFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId, dispatch]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId, dispatch]);

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<EntitiesFiltersProvider
				objectId={objectId}
				onApply={applyFilters}
				filters={filters}
				onClear={onClear}
			>
				<LoadingPanel
					className="structures-page__loading-panel"
					active={isListLoading}
				>
					<Desktop objectId={objectId}/>
				</LoadingPanel>
			</EntitiesFiltersProvider>
		);
	}
	return (
		<EntitiesFiltersProvider
			objectId={objectId}
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
		>
			<LoadingPanel className="structures-page__loading-panel">
				<Mobile objectId={objectId}/>
			</LoadingPanel>
		</EntitiesFiltersProvider>
	);
};
