import {Column} from 'react-table';
import {StatsCell} from './StatsCell';
import {MenuCell} from './MenuCell';
import {IPreparedInternalAcceptance} from '../../interfaces/IPreparedInternalAcceptance';
import {CreatedCell} from './CreatedCell';
import {ModifiedCell} from './ModifiedCell';

export const desktopColumns: Array<Column<IPreparedInternalAcceptance>> = [
	{
		Header: '№',
		accessor: row => row.number ?? row.localNumber,
		width: 60
	},
	{
		id: 'object',
		Header: 'Объект',
		accessor: row => row.object?.name,
		width: 100,
		disableSortBy: true,
		defaultCanSort: false
	},
	{
		id: 'description',
		Header: 'Описание',
		accessor: row => row.description,
		width: 200,
		disableSortBy: true,
		defaultCanSort: false
	},
	{
		id: 'createdAt',
		Header: 'Добавлено',
		accessor: row => row.createdAt,
		Cell: CreatedCell,
		width: 100,
		sortDescFirst: true
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: row => row.modifiedAt,
		Cell: ModifiedCell,
		width: 100,
		sortDescFirst: true
	},
	{
		id: 'stats',
		Header: 'Нарушения, осмотры',
		Cell: StatsCell,
		width: 120
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'internalAcceptances-page__d-table-menu',
		width: 68,
		minWidth: 68,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const desktopColumnsWithoutObject = desktopColumns.filter(item => item.id !== 'object');
