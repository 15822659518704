import React, {memo, useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectOption, Select2, FilterButton, SelectPopup} from '@tehzor/ui-components';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {expiredOptions} from '@tehzor/tools/interfaces/expiration/IExpirationFilter';

interface IExpiredFilterProps {
	label?: string;
	value?: string[];
}

export const ExpiredFilter = memo((props: IExpiredFilterProps) => {
	const {label = 'Просрочено', value} = props;
	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedValues, setSelectedValues] = useState(value);

	const handleApply = useCallback(() => {
		dispatch({expired: selectedValues});
	}, [selectedValues, dispatch]);

	const handleClear = useCallback(() => {
		setSelectedValues([]);
	}, []);

	const handleFullClear = useCallback(() => {
		setSelectedValues([]);
		dispatch({expired: undefined});
	}, [dispatch]);

	const handleCancel = useCallback(() => {
		setSelectedValues(value);
	}, [value]);

	useUpdateEffect(() => {
		setSelectedValues(value);
	}, [value]);

	return (
		<div>
			<SelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedValues?.length}
				count={selectedValues?.length}
				footer
				noHeader
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel<typeof expiredOptions[0]>(label, value, expiredOptions)}
						active={!!(value && value.length > 0)}
						onClear={handleFullClear}
					/>
				)}
			>
				<Select2
					multiple
					value={selectedValues}
					onChange={setSelectedValues}
				>
					{expiredOptions.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
});
