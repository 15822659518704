import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {FloatingActionButton} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getChecks} from '@src/store/modules/entities/checks/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';

interface IMobileEntityAddingProps {
	objectId: string;
}

const MobileEntityAdding = ({objectId}: IMobileEntityAddingProps) => {
	const permissions = useChecksPermissions(objectId);
	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();

	const [, reloadList] = useAsyncFn(async () => {
		await dispatch(getChecks(objectId));
	}, [objectId]);

	const [dialog, open] = useAddingCheckDialog({
		objectId,
		stage: object.stage,
		onSuccess: reloadList
	});

	if (permissions.canAdd
		&& (problemsPermissions.canAdd || inspectionsPermissions.canAdd)) {
		return (
			<>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Проверка"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={open}
				/>
				{dialog}
			</>
		);
	}
	return null;
};

export default MobileEntityAdding;
