import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	ObjectsFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {StagesFilterMobile} from '@src/components/MobileEntitiesFilters/components/StagesFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ICategoriesSetsFiltersState} from '@src/store/modules/settings/pages/manage/categoriesSets/reducers';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';

const CategoriesSetsFiltersPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();

	const canGoBack = (useLocation().state as {canGoBack?: boolean} | null)?.canGoBack;
	const {state, isStateChanged, change, clear, apply}
		= useEntitiesFiltersCtx<ICategoriesSetsFiltersState>();

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			goBack();
		}
	}, [canGoBack, apply]);

	useAppHeader(
		{
			title: 'Фильтры',
			showBackBtn: true,
			mobileRightButtons: isCleanable<ICategoriesSetsFiltersState>(state, objectId) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={clear}
				/>
			) : null
		},
		[state, objectId]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			{objectId === undefined && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
				/>
			)}

			<CreatedByFilterMobile
				objectId={objectId}
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
				entity={entity}
			/>

			<StagesFilterMobile
				entity={entity}
				onChange={change}
				objectId={objectId}
				value={state?.stages}
			/>
		</FilterPage>
	);
};

export default CategoriesSetsFiltersPage;
