import React from 'react';
import {SpaceStatusSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {extractSpaceStatusesDataByObjectId} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {editSpaceStatus} from '@src/store/modules/entities/space/actions';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {SpaceStatusHook} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const SpaceStatusCell = ({row}: CellProps<IEnrichedSpace>) => {
	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const dispatch = useAppDispatch();

	const space = row.original;

	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);
	const statuses = useAppSelector(s => extractSpaceStatusesDataByObjectId(s, space.objectId));

	const permissions = useSpacesPermissions(space.objectId);
	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите изменить статус помещения?',
		'Все текущие чек-листы будут приняты, а связанные с ними нарушения - отмечены как "Исправлены"'
	);

	const [, handleChange] = useAsyncFn(
		async (v: string) => {
			const nextStatus = statuses.byId[v];

			// если указан хук - запрашиваем подтверждение действия
			if (
				nextStatus.hooks?.includes(SpaceStatusHook.ACCEPT_CHECK_LISTS)
				&& !(await getClosingConfirmation())
			) {
				return;
			}

			await dispatch(editSpaceStatus(space.objectId, pageObjectId, space.id, v));
		},
		[space]
	);

	return (
		<ClickPreventWrap>
			<SpaceStatusSelect
				stagesMap={stagesMap}
				style={{maxWidth: '100%'}}
				statuses={statuses}
				disabled={!permissions.canEditStatus}
				value={space.status?.id}
				onChange={handleChange}
			/>
			{closingDialog}
		</ClickPreventWrap>
	);
};

export default SpaceStatusCell;
