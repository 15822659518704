import {requestUsers} from '@src/api/backend/users';
import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';

export const useUsersQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.users(), {
		queryFn: requestUsers,
		staleTime: 1000 * 60 * 60 * 24, // 1 день
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
