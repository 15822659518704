import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IEditSpaceOwnerResponse, makeSpaceOwnerEditRequest} from '@src/api/backend/spaceOwner';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {queryClient} from '@src/api/QueryClient';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';

export type IEditSpaceOwnerPayload = IEditSpaceOwnerResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditSpaceOwnerResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении собственника');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет собственника помещения
 *
 * @param ownerId id собственника
 * @param fields измененные поля
 */
export const editSpaceOwner = (ownerId: string, fields: ISavingSpaceOwner) =>
	createApiAction<IEditSpaceOwnerResponse>(request, success, failure, () => {
		void queryClient.invalidateQueries(restDictionariesQueryKeys.spaceOwners());
		return makeSpaceOwnerEditRequest(ownerId, fields);
	});
