import {wsConnector} from '@src/api/backend/wsConnector';

export type IGenerateSpacesResponse = void;

/**
 * Генерирует помещения из планов
 *
 * @param objectId id объекта
 * @param plans массив выбранных планов, по которым будут генерироваться помещения
 */
export const makeGenerateSpacesRequest = (objectId: string, plans?: string[]) =>
	wsConnector.sendAuthorizedRequest<IGenerateSpacesResponse>('generateSpaces', {objectId, plans});
