import {wsConnector} from '../wsConnector';
import {ISavingSpace} from '@tehzor/tools/interfaces/spaces/ISavingSpace';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

export type IAddSpaceResponse = ISpace;

/**
 * Добавляет помещение
 *
 * @param objectId id объекта
 * @param fields данные помещения
 */
export const makeSpaceAddRequest = (objectId: string, fields: ISavingSpace) =>
	wsConnector.sendAuthorizedRequest<IAddSpaceResponse>('addSpace', {
		objectId,
		...fields
	});
