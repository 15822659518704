import React, {Dispatch, useCallback, useState} from 'react';
import {
	convertToSave,
	errorsFns,
	isEdited,
	IEditableProblemTagAction,
	IEditableProblemTagState
} from '@src/core/hooks/states/useEditableProblemTagState';
import Text from '@src/components/editableFields/Text';
import './EditableProblemTag.less';
import {Button, IconButton, PopupColorPicker, Tag} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {addSuccessToast} from '@src/utils/toasts';
import classNames from 'classnames';
import {IProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';
import {
	addProblemTag,
	deleteProblemTag,
	editProblemTag
} from '@src/store/modules/dictionaries/problemTags/actions';
import {IExtendedSavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IExtendedSavingProblemTag';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	color: {fieldId: 'color', isRequired: true}
};
interface IEditableProblemTagProps {
	problemTagsSetId: string;
	editingState: IEditableProblemTagState;
	editingDispatch: Dispatch<IEditableProblemTagAction>;
	setArray: React.Dispatch<React.SetStateAction<IExtendedSavingProblemTag[]>>;
	tags: IExtendedSavingProblemTag[];
	tag: IExtendedSavingProblemTag;
	index: number;
	activeTagId?: string;
	setActiveTagId: React.Dispatch<React.SetStateAction<string | undefined>>;
	permissions: IProblemTagsSetPermissions;
	disabledActions?: boolean;
}

export const EditableProblemTag = ({
	problemTagsSetId,
	editingState,
	editingDispatch,
	setArray,
	tags,
	tag,
	index,
	activeTagId,
	setActiveTagId,
	permissions,
	disabledActions
}: IEditableProblemTagProps) => {
	const [isBlocking, setIsBlocking] = useState(false);

	const dispatch = useAppDispatch();

	const handleSave = async () => {
		const result = await dispatch(addProblemTag(problemTagsSetId, convertToSave(editingState)));
		setArray([...tags.filter(el => el.id !== editingState.id), result]);
		addSuccessToast('Успешно', 'Добавлена новая метка');
	};
	const handleEdit = async () => {
		await dispatch(
			editProblemTag(
				editingState.id,
				problemTagsSetId,
				convertToSave(editingState, undefined, true)
			)
		);
		setArray(
			tags.map(t => {
				if (t.id !== editingState.id) {
					return t;
				}
				return editingState;
			})
		);
		addSuccessToast('Успешно', 'Метка обноалена');
	};

	const handleDelete = async () => {
		if (tag?.id && problemTagsSetId) {
			if (tag.isDraft) {
				setArray(tags.filter(el => el.id !== editingState.id));
			} else {
				await dispatch(deleteProblemTag(editingState.id, problemTagsSetId));
				setArray(tags.filter(el => el.id !== editingState.id));
			}
		}
	};

	const save = async () => {
		setIsBlocking(true);
		if (tag?.isDraft) {
			await handleSave();
		} else {
			await handleEdit();
		}
		setIsBlocking(false);
		setActiveTagId(undefined);
	};

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: tag
		});
	}, [tag]);

	const isSaveVisible = () =>
		(tag?.isDraft && permissions.canAdd) || (!tag?.isDraft && permissions.canEdit);

	const handleChangeColor = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'color', value: v});
			if (fieldsSettings.color.isRequired) {
				editingDispatch({type: 'update-error', field: 'color'});
			}
		},
		[fieldsSettings]
	);
	return (
		<div
			className={classNames('editable-problem-tag', {
				'editable-problem-tag_draft': tag?.isDraft
			})}
		>
			<div className="editable-problem-tag__grid">
				<div className="editable-problem-tag__item">{index + 1}</div>
				{tag.id === activeTagId || tag.isDraft ? (
					<>
						<div className="editable-problem-tag__item">
							<Text
								field="name"
								label={null}
								value={editingState.name}
								editingDispatch={editingDispatch}
								required
								hasError={editingState.errors.name}
							/>
						</div>
						<div className="editable-problem-tag__item">
							<PopupColorPicker
								value={editingState.color}
								onChange={handleChangeColor}
								presetColors
							/>
						</div>
						<div className="editable-problem-tag__buttons">
							{isSaveVisible() && (
								<Button
									label="Cохранить"
									disabled={
										isBlocking
										|| (tag
											? !isEdited(editingState, tag)
											  || hasErrors(editingState, errorsFns, fieldsSettings)
											: hasErrors(editingState, errorsFns, fieldsSettings))
									}
									type="accent-blue"
									onClick={save}
								/>
							)}
							{tag.isDraft ? (
								isEdited(editingState, tag) ? (
									<Button
										label="Отменить"
										type="cancel"
										onClick={reset}
									/>
								) : (
									<Button
										label="Удалить"
										type="cancel"
										onClick={handleDelete}
									/>
								)
							) : (
								<Button
									label="Отменить"
									type="cancel"
									onClick={
										isEdited(editingState, tag)
											? reset
											: () => setActiveTagId(undefined)
									}
								/>
							)}
						</div>
					</>
				) : (
					<>
						<div className="editable-problem-tag__item">
							<Tag
								dotColor={tag.color}
								label={tag.name || ''}
							/>
						</div>
						<div className="editable-problem-tag__buttons">
							{permissions.canEdit && !disabledActions && (
								<IconButton
									type="inline-blue-accent"
									onClick={() => setActiveTagId(tag.id)}
								>
									<i className="tz-edit-20"/>
								</IconButton>
							)}
							{permissions.canDelete && !disabledActions && (
								<IconButton
									type="inline-red-accent"
									onClick={handleDelete}
								>
									<i className="tz-delete"/>
								</IconButton>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
