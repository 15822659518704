import {ActualFixDate} from '@src/components/viewFields/ActualFixDate/ActualFixDate';
import {Category} from '@src/components/viewFields/Category';
import Description from '@src/components/viewFields/Description';
import {FixDate} from '@src/components/viewFields/FixDate/FixDate';
import {Inspectors} from '@src/components/viewFields/Inspectors';
import {Links} from '@src/components/viewFields/Links';
import Location from '@src/components/viewFields/Location';
import Prescription from '@src/components/viewFields/Prescription';
import MobileImages from '@src/pages/ProblemPage/components/attachments/Images.mobile';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import React, {memo} from 'react';
import {Responsible} from '../../../../components/viewFields/Responsible/Responsible';
import {IPermissions} from '../../hooks/usePermissions';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import ProblemTags from './ProblemTags';
import {ProblemViews} from './ProblemViews';
import Reason from './Reason';
import Status from './Status';
import {ContractLink} from '@src/components/EntityLinks/components/ContractLink';
import {MobileEntityDocs} from '@src/components/EntityDocs';

interface IMobileInfoProps {
	objectId: string;
	problem: IPreparedProblem;
	images?: string[];
	hasLocationComments?: boolean;
	permissions: IPermissions;
	settings?: IProblemSettings;

	onImageClick?: (index: number) => void;
}

const MobileInfo = ({
	objectId,
	problem,
	images,
	hasLocationComments,
	onImageClick,
	settings,
	permissions
}: IMobileInfoProps) => (
	<EntityGrid withBorders>
		<Status
			objectId={objectId}
			problem={problem}
		/>
		{problem.description && settings?.description && (
			<Description value={problem.description} />
		)}
		{images !== undefined && images.length > 0 && settings?.attachments && (
			<MobileImages
				images={images}
				onClick={onImageClick}
			/>
		)}
		{problem.plannedFixDate && settings?.plannedFixDate && (
			<FixDate
				plannedFixDate={problem.plannedFixDate}
				status={problem.status}
				critical={problem.critical}
				actualFixDate={problem.actualFixDate}
			/>
		)}
		{problem.actualFixDate && <ActualFixDate value={problem.actualFixDate} />}
		{(problem.respUsers?.length || problem.activeGroup) && settings?.respUsers ? (
			<Responsible
				entity={problem}
				type="problem"
			/>
		) : null}

		<MobileEntityDocs id={problem.id} />

		{problem.inspectors?.length && settings?.inspectors ? (
			<Inspectors
				inspectors={problem.inspectors}
				objectId={problem.objectId}
				stage={problem.stage}
			/>
		) : null}

		{problem.problemTags && problem.problemTags?.length > 0 && settings?.problemTags ? (
			<ProblemTags
				tags={problem.problemTags}
				fullRow
			/>
		) : null}
		<Links
			links={problem.links ? {objectId, ...problem.links} : {}}
			fullRow
		>
			{problem.contractId && permissions.canViewContract && (
				<ContractLink contractId={problem.contractId} />
			)}
		</Links>
		{(!!problem.plan || !!problem.floor || !!problem.location) && settings?.location && (
			<Location
				objectId={objectId}
				planId={problem.plan?.id}
				floor={problem.floor}
				location={problem.location}
				hasComments={hasLocationComments}
			/>
		)}
		{problem.categoryId && settings?.categoryId && <Category categoryId={problem.categoryId} />}
		{problem.reason && settings?.reason && <Reason value={problem.reason} />}
		<EditorInfo
			icon={<i className="tz-document-20" />}
			label="Создано"
			date={problem.createdAt}
			user={problem.createdBy}
			localDate={problem.localCreatedAt}
			localLabel="сохранено локально"
		/>
		{problem.createdAt !== problem.modifiedAt && (
			<EditorInfo
				icon={<i className="tz-edit-20" />}
				label="Изменено"
				date={problem.modifiedAt}
				user={problem.modifiedBy}
			/>
		)}
		{permissions.canSeeViews && <ProblemViews problem={problem} />}
		{problem.prescription && settings?.prescription && (
			<Prescription value={problem.prescription} />
		)}
	</EntityGrid>
);

export default memo(MobileInfo);
