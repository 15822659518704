import React from 'react';
import EntitiesFilters, {
	// NumberFilter,
	ObjectsFilter,
	// TextFilter,
	CreationDateFilter
} from '@src/components/EntitiesFilters';
import {StatusesFilter} from './StatusesFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/reducers';

interface IClaimsFiltersProps {
	objectId?: string;
}

const ClaimsFilters = ({objectId = 'all'}: IClaimsFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IWarrantyClaimsFiltersState>();

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="warranty-claims"
		>
			{/* <NumberFilter
				value={state?.number}
				placeholder="№ обращения"
			/>

			<TextFilter
				field="publicId"
				value={state?.publicId}
				placeholder="Id"
			/> */}

			{objectId === 'all' && <ObjectsFilter value={state?.objects}/>}

			<StatusesFilter statuses={state?.statuses}/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
			/>
		</EntitiesFilters>
	);
};

export default ClaimsFilters;
