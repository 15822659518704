import {wsConnector} from '../wsConnector';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';

export type IEditProblemTagsSetResponse = IProblemTagsSet;

/**
 * Изменяет набор меток нарушений
 *
 * @param id id набора меток нарушений
 * @param fields данные набора меток
 */
export const makeProblemTagsSetEditRequest = (id: string, fields: ISavingProblemTagsSet) =>
	wsConnector.sendAuthorizedRequest<IEditProblemTagsSetResponse>('editProblemTagsSet', {
		id,
		...fields
	});
