import {Column} from 'react-table';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import SpaceInfoMobileCell from '@src/components/tableCells/SpaceInfoMobileCell';

export const mobileColumns: Array<Column<IEnrichedSpace>> = [
	{
		id: 'info',
		Cell: SpaceInfoMobileCell,
		className: 'spaces-page__mobile-cell'
	}
];
