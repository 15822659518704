import React, {useMemo} from 'react';
import {
	Plate,
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	InlineButton,
	StatusSelect
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import SpaceOwners from './SpaceOwners';
import Documents from './Documents';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {editOwnerAcceptanceStatus} from '@src/store/modules/entities/ownerAcceptance/actions';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useEditableOwnerAcceptanceAttachmentsDialog} from '@src/components/EditableOwnerAcceptanceAttachmentsDialog/hooks/useEditableOwnerAcceptanceAttachmentsDialog';
import {extractOwnerAcceptanceResolutionsAsArray} from '@src/store/modules/dictionaries/ownerAcceptanceResolutions/selectors';
import Resolution from './Resolution';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';

interface IDesktopInfoProps {
	objectId: string;
	ownerAcceptance: IOwnerAcceptance;
}

const DesktopInfo = ({objectId, ownerAcceptance}: IDesktopInfoProps) => {
	const statuses = useAppSelector(s => s.dictionaries.ownerAcceptanceStatuses);
	const resolutions = useAppSelector(extractOwnerAcceptanceResolutionsAsArray);
	const permissions = useOwnerAcceptancesPermissions(objectId);
	const dispatch = useAppDispatch();

	const isStatusHidden = useMemo(() =>
		ownerAcceptance.resolution === OwnerAcceptanceResolutionId.ACCEPTED,
	[ownerAcceptance.resolution]);

	// Диалог редактирования вложений
	const [editingAttachmentsDialog, openEditAttachmentsDialog]
		= useEditableOwnerAcceptanceAttachmentsDialog(objectId, ownerAcceptance.id, ownerAcceptance);

	const [, handleChange] = useAsyncFn(
		async (v: OwnerAcceptanceStatusId) => {
			if (ownerAcceptance) {
				await dispatch(editOwnerAcceptanceStatus(objectId, ownerAcceptance.id, v));
			}
		},
		[objectId, ownerAcceptance]
	);
	return (
		<Plate className={{content: 'owner-acceptance-page__info-plate'}}>
			<EntityGrid withBorders>
				{!isStatusHidden && (
					<EntityGridItem
						label="Статус"
						icon={<i className="tz-status-20"/>}
						fullRow
					>
						<StatusSelect
							className="owner-acceptance-page__d-entities-status"
							statuses={statuses}
							disabled={!permissions.canEditStatus}
							value={ownerAcceptance?.status}
							onChange={handleChange}
						/>
					</EntityGridItem>
				)}

				<Resolution
					objectId={objectId}
					ownerAcceptanceId={ownerAcceptance.id}
					value={ownerAcceptance.resolution}
					resolutions={resolutions}
					canEditResolution={permissions.canEdit}
				/>

				{ownerAcceptance?.spaceOwner ? (
					<SpaceOwners
						name={ownerAcceptance.spaceOwner.name}
						phone={ownerAcceptance.spaceOwner.phone}
						email={ownerAcceptance.spaceOwner.email}
					/>
				) : null}
				<EntityGridItem
					label="Акт"
					icon={<i className="tz-document-20"/>}
					fullRow
					buttons={
						permissions.canEdit ? (
							<InlineButton
								className="owner-acceptance-page__documents-edit-icon"
								type="accent"
								leftIcon={<i className="tz-edit-16"/>}
								onClick={openEditAttachmentsDialog}
							/>
						) : null
					}
				>
					<Documents ownerAcceptance={ownerAcceptance}/>
				</EntityGridItem>
				<EditorInfo
					icon={<i className="tz-calendar-20"/>}
					label="Создано"
					date={ownerAcceptance?.createdAt}
					user={ownerAcceptance?.createdBy}
					fullRow
				/>

				{ownerAcceptance?.createdAt !== ownerAcceptance?.modifiedAt && (
					<EditorInfo
						icon={<i className="tz-calendar-20"/>}
						label="Изменено"
						date={ownerAcceptance?.modifiedAt}
						user={ownerAcceptance?.modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default DesktopInfo;
