import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

export interface IPermissions {
	canViewProblems?: boolean;
	canViewInspections?: boolean;
	canViewReports?: boolean;
	canViewSpaces?: boolean;
	canViewWarrantyClaims?: boolean;
	canViewCategories?: boolean;
	canViewPlans?: boolean;
	canViewCheckLists?: boolean;
	canViewInternalAcceptances?: boolean;
	canViewOwnerAcceptances?: boolean;
	canViewStructures?: boolean;
	canViewWorkAcceptances?: boolean;
	canViewContracts?: boolean;
}

const getPermissions = (objectId: string, hasChildren: boolean, user?: IUser): IPermissions => {
	if (!user || !user.roles) {
		return {};
	}
	const result = {
		canViewSpaces: hasPermission(user.roles, 'spaces-view', UserRoleScopes.OBJECT, objectId)
	} as IPermissions;
	if (!hasChildren) {
		result.canViewProblems = hasPermission(
			user.roles,
			'problemsView',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewInspections = hasPermission(
			user.roles,
			'inspectionsView',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewReports = hasPermission(
			user.roles,
			'checksView',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewWarrantyClaims = hasPermission(
			user.roles,
			'warranty-claims-view',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewWorkAcceptances = hasPermission(
			user.roles,
			'work-acceptances-view',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewCategories = hasPermission(
			user.roles,
			'categories-view',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewPlans = hasPermission(
			user.roles,
			'plansView',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewCheckLists = hasPermission(
			user.roles,
			'check-lists-view',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewInternalAcceptances = hasPermission(
			user.roles,
			'internal-acceptances-view',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewOwnerAcceptances = hasPermission(
			user.roles,
			'owner-acceptances-view',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewStructures = hasPermission(
			user.roles,
			'structures-view',
			UserRoleScopes.OBJECT,
			objectId
		);
		result.canViewContracts = hasPermission(
			user.roles,
			'contracts-view',
			UserRoleScopes.OBJECT,
			objectId
		);
	}
	return result;
};

export function useObjectSectionsMenu(objectId: string, flag?: boolean): IHeaderSectionsMenuItem[] {
	const user = useAppSelector(s => s.auth.profile);
	const currentObject = useAppSelector(s => extractCurrentTreeObject(s, objectId));
	const hasSpaces = currentObject?.hasSpaces;

	return useMemo(() => {
		const hasChildrenObjects = !!currentObject?.children?.length;
		const permissions = getPermissions(objectId, hasChildrenObjects, user);

		const result = [];
		if (hasChildrenObjects) {
			result.push({
				url: `/objects/${objectId}`,
				label: 'Вложенные объекты'
			});
		}
		if (permissions.canViewProblems) {
			result.push({
				url: `/objects/${objectId}/problems`,
				label: 'Нарушения',
				serveSubPath: flag
			});
		}
		if (permissions.canViewInspections) {
			result.push({
				url: `/objects/${objectId}/inspections`,
				label: 'Осмотры'
			});
		}
		if (permissions.canViewReports) {
			result.push({
				url: `/objects/${objectId}/checks`,
				label: 'Проверки'
			});
		}
		if (permissions.canViewSpaces && (hasSpaces || hasChildrenObjects)) {
			result.push({
				url: `/objects/${objectId}/spaces`,
				label: 'Помещения'
			});
		}
		if (permissions.canViewStructures) {
			result.push({
				url: `/objects/${objectId}/structures`,
				label: 'Структуры'
			});
		}
		if (permissions.canViewInternalAcceptances) {
			result.push({
				url: `/objects/${objectId}/internal-acceptances`,
				label: 'Внутренние приёмки'
			});
		}
		if (permissions.canViewWorkAcceptances) {
			result.push({
				url: `/objects/${objectId}/work-acceptances`,
				label: 'Приёмки работ'
			});
		}
		if (permissions.canViewWorkAcceptances) {
			result.push({
				url: `/objects/${objectId}/owner-acceptances`,
				label: 'Передачи собственникам'
			});
		}
		if (permissions.canViewWarrantyClaims) {
			result.push({
				url: `/objects/${objectId}/warranty-claims`,
				label: 'Гарантийные обращения'
			});
		}
		if (permissions.canViewCategories) {
			result.push({
				url: `/objects/${objectId}/categories-sets`,
				label: 'Виды работ',
				hidden: true
			});
		}
		if (permissions.canViewContracts) {
			result.push({
				url: `/objects/${objectId}/contracts`,
				label: 'Договоры',
				hidden: true
			});
		}
		if (permissions.canViewPlans) {
			result.push({
				url: `/objects/${objectId}/plans`,
				label: 'Планы',
				hidden: true
			});
		}
		return result;
	}, [objectId, currentObject, user, hasSpaces]);
}
