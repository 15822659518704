import {Column} from 'react-table';
import StatsCell from './StatsCell';
import MenuCell from './MenuCell';
import {IPreparedCheck} from '../../interfaces/IPreparedCheck';

export const mobileColumns: Array<Column<IPreparedCheck>> = [
	{
		id: 'number',
		Header: '',
		accessor: row => `№${row.number ?? row.localNumber}`,
		width: 65,
		className: 'checks-page__m-table-number'
	},
	{
		id: 'object',
		Header: 'Объект',
		accessor: row => row.object?.name,
		width: 100
	},
	{
		id: 'createdBy',
		Header: 'Автор',
		accessor: row => row.createdBy?.fullName,
		width: 100
	},
	{
		Header: 'Добавлено',
		accessor: 'createdAt',
		width: 100,
		sortDescFirst: true
	},
	{
		Header: 'Изменено',
		accessor: 'modifiedAt',
		width: 100,
		sortDescFirst: true
	},
	{
		id: 'stats',
		Cell: StatsCell,
		width: 100,
		defaultCanSort: false
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		className: 'checks-page__m-table-menu'
	}
];

export const mobileColumnsWithoutObject = mobileColumns.filter(item => item.id !== 'object');
