import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IWorkingGroupType} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

export type IGetWorkingGroupTypesResponse = INormalizedData<IWorkingGroupType>;

/**
 * Возвращает список типов рабочих групп
 */
export const requestWorkingGroupTypes = () =>
	wsConnector.sendAuthorizedRequest<IGetWorkingGroupTypesResponse>('getWorkingGroupTypes');
