import React, {memo} from 'react';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {Image} from '../Image';
import {ObjectStages} from './ObjectStages';
import {ObjectManager} from './ObjectManager';
import {ObjectProblems} from './ObjectProblems';

interface IMobileInfoProps {
	object: IObject;
	stages: IObjectStage[];
}

const nameIcon = <i className="tz-name-20"/>;
const photoIcon = <i className="tz-photo-20"/>;
const cityIcon = <i className="tz-location-20"/>;
const contractorIcon = <i className="tz-company-20"/>;

export const MobileInfo = memo(({object, stages}: IMobileInfoProps) => (
	<>
		<EntityGrid
			className={{wrap: 'object-page__d-entities-grid'}}
		>
			<EntityGridItem
				label="Название"
				icon={nameIcon}
			>
				{object.name}
			</EntityGridItem>

			<EntityGridItem
				className={{content: 'object-page__d-entities-grid-image'}}
				label="Фото"
				icon={photoIcon}
			>
				<Image
					title={object.name}
					fullImage={object.fullImage?.url}
					previewImage={object.previewImage?.url}
				/>
			</EntityGridItem>

			{object.city && (
				<EntityGridItem
					label="Город"
					icon={cityIcon}
				>
					{object.city}
				</EntityGridItem>
			)}

			{object.generalContractor && (
				<EntityGridItem
					label="Ген. Подрядчик"
					icon={contractorIcon}
				>
					{object.generalContractor}
				</EntityGridItem>
			)}

			<ObjectManager
				label="Рук. проекта"
				value={object.projectManager}
			/>

			<ObjectManager
				label="Рук. строительства"
				value={object.constructionManager}
			/>
		</EntityGrid>

		<ObjectStages
			object={object}
			stages={stages}
		/>

		<ObjectProblems object={object}/>
	</>
));