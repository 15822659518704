import React, {Dispatch, useCallback} from 'react';
import EntitiesFilters, {StatusesFilter} from '@src/components/EntitiesFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
// TZ-2070 Разкомментить после исправления фильтров по стадии объектов
// import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors'; 
import {extractObjectsCitiesAsArray} from '@src/store/modules/dictionaries/objects/selectors';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';
import {SelectSearch} from '@tehzor/ui-components';

interface IObjectsFiltersProps {
	search?: string;
	setSearch: Dispatch<string | undefined>;
}

const ObjectsFilters = (
	{search, setSearch}: IObjectsFiltersProps
) => {
	const handleChange = useCallback((val: string) => setSearch(val), []);
	const handleClear = useCallback(() => setSearch(undefined), []);

	const {state} = useEntitiesFiltersCtx<IObjectsFiltersState>();
	// TZ-2070 Разкомментить после исправления фильтров по стадии объектов
	// const stages = useAppSelector(extractObjectStagesAsArray);
	const cities = useAppSelector(extractObjectsCitiesAsArray);
	const companies = useAppSelector(extractCompaniesAsArray);

	return (
		<div className="objects-page__filters">
			<SelectSearch
				className="objects-page__filters-search"
				type="filter"
				value={search}
				onChange={handleChange}
				onClear={handleClear}
			/>
			<EntitiesFilters
				className="objects-page__filters-entities"
				entity="objects"
			>
				<StatusesFilter
					label="Компания"
					filterName="companies"
					statuses={state?.companies}
					allStatuses={companies}
				/>
				<StatusesFilter
					label="Город"
					filterName="cities"
					statuses={state?.cities}
					allStatuses={cities}
				/>
				{/*  // TZ-2070  Разкомментить после исправления фильтров по стадии объектов
					<StatusesFilter
					label="Стадия объекта"
					filterName="stages"
					statuses={state?.stages}
					allStatuses={stages}
				/> */}
			</EntitiesFilters>
		</div>
	);
};

export default ObjectsFilters;
