import generateKey from '@tehzor/tools/utils/generateKey';
import AbstractHandler from './AbstractHandler';
import IShape from '@tehzor/tools/interfaces/plans/IShape';
import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import {Container, G} from '@svgdotjs/svg.js';

export interface ISelectableSector extends ILocationMarker {
	key?: string;
	selected?: boolean;
}

/**
 * Класс для отображения слоя с фигурами с возможностью их выбора
 */
class SelectableHandler extends AbstractHandler<ILocationMarker> {
	// Доступен ли выбор секторов
	private _isSelectionActive = true;

	/**
	 * Создаёт слой
	 *
	 * @param {Object} svg SVG.js объект
	 * @param {Object} group SVG.js группа
	 * @param {string} layerId id слоя
	 * @param {Boolean} visible видимость слоя
	 * @param {Array} shapes фигуры для первоначальной отрисовки
	 * @param {ILocationMarker[]} sectorsData выбранные фигуры
	 * @param {Function} emit функция для оповещения при изменении состояния
	 */
	constructor(
		svg: Container,
		group: G,
		layerId: string,
		visible: boolean,
		shapes: IShape[],
		sectorsData: ILocationMarker[],
		emit: (editedectorsData: ILocationMarker[]) => void
	) {
		super();

		this._svg = svg;
		this._group = group
			.group()
			.id(layerId)
			.back();
		this._group.css('display', visible ? 'block' : 'none');
		this._visible = visible;
		this._sectorsData = sectorsData;
		this._emitFunc = emit;
		const commentedSectors = sectorsData.filter(el => el.description).map(el => el.sector);

		for (let i = 0, shapeElement; i < shapes.length; i++) {
			const key = generateKey('shape');
			const selected = sectorsData.map(el => el.sector).includes(shapes[i].id);
			const commented = commentedSectors.includes(shapes[i].id);
			shapeElement = this._group
				.svg(shapes[i].svg)
				.last()
				.id(key)
				.addClass('plan-viewer__sector')
				.addClass('plan-viewer__sector_hoverable')
				.addClass(selected ? 'plan-viewer__sector_selected' : '')
				.addClass(commented ? 'plan-viewer__sector_commented' : '')
				.attr('title', shapes[i].name)
				.on('touchend', this._preSelectShape)
				.on('mouseup', this._selectShape);

			this._shapes.push({
				...shapes[i],
				element: shapeElement
			});
		}

		this._enableSelection();
	}

	/**
	 * Очищает ресурсы, удаляет слой
	 */
	destroy = () => {
		super.destroy();
		this._disableSelection();
	};

	/**
	 * Переключает фдаг активности перемещения плана
	 *
	 * @param {Boolean} active флаг
	 */
	togglePlanMoving = (active: boolean) => {
		super.togglePlanMoving(active);
		if (active) {
			this._isSelectionActive = false;
		}
	};

	/**
	 * Оповещает об изменении состояния.
	 *
	 * @private
	 */
	protected _emit = () => {
		if (this._emitFunc) {
			const sectorsData: Array<ILocationMarker & ISelectableSector> = this._sectorsData.map(el => ({
				id: el.id,
				selected: el.selected,
				sector: el.sector,
				name: el.name,
				description: el.description
			}));
			this._emitFunc(sectorsData);
		}
	};

	/**
	 * Включает режим выбора секторов
	 *
	 * @private
	 */
	private _enableSelection = () => {
		this._svg.on('mouseup', this._preSelectShape);
	};

	/**
	 * Выключает режим выбора секторов
	 *
	 * @private
	 */
	private _disableSelection = () => {
		this._svg.off('mouseup', this._preSelectShape);
	};

	/**
	 * Снятие флага перемещения при тач событии
	 *
	 * @private
	 */
	private _preSelectShape = () => {
		if (!this._isSelectionActive) {
			this._isSelectionActive = true;
		}
	};

	/**
	 * Выделяет фигуру при клике на неё
	 *
	 * @param {MouseEvent} event событие
	 * @private
	 */
	private _selectShape = (event: MouseEvent) => {
		if (!this._isSelectionActive) {
			this._isSelectionActive = true;
			return;
		}
		event.stopPropagation();
		if (!event.target || event.button !== 0) {
			return;
		}
		for (const shape of this._shapes) {
			// @ts-ignore
			if (shape.element.attr('id') === event.target.id) {
				shape.element.addClass('plan-viewer__sector_selected');

				if (this._sectorsData.map(el => el.sector).includes(shape.id)) {
					this._sectorsData = this._sectorsData
					.map(el => (el.sector === shape.id ? {...el, selected: true} : {...el, selected: false}));
				} else {
				const newSector = {
					name: shape.name,
					sector: shape.id?.toString()
				};
				this._sectorsData = [...this._sectorsData, newSector];
			}
				break;
			}
		}
		this._emit();
	};
}

export default SelectableHandler;
