import React from 'react';
import './ProblemsSpace.less';
import classNames from 'classnames';
import {Indicators} from '../Indicators/Indicators';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {ISpaceComponent} from '../interfaces/ISpaceComponent';
import {SpaceCircle} from './components/SpaceCircle';
import {SpaceRectangle} from './components/SpaceRectangle';

const getPropsByType = (type: SpaceTypeId) => {
	switch (type) {
		case SpaceTypeId.COMMERCIAL_SPACES:
		case SpaceTypeId.UNDERGROUND_PARKING:
		case SpaceTypeId.GROUND_PARKING:
		case SpaceTypeId.STOREROOMS:
			return {
				width: 74 + 12 + 12,
				height: 46 + 12 + 12,
				radius: 20
			};
		case SpaceTypeId.COMMON_AREAS:
		case SpaceTypeId.TECHNICAL_SPACES:
		case SpaceTypeId.TECHNICAL_UNDERGROUND:
		case SpaceTypeId.SYSTEMS:
		case SpaceTypeId.REST:
			return {
				width: 298 + 12 + 12,
				height: 46 + 12 + 12,
				radius: 20
			};
		case SpaceTypeId.APARTMENTS:
		case SpaceTypeId.FLATS:
		default:
			return {
				width: 52 + 10 + 10,
				height: 52 + 10 + 10,
				radius: 50
			};
	}
};

const declaredTypes = Object.values(SpaceTypeId);

export const ProblemsSpace = ({className, disabled, space, onClick}: ISpaceComponent) => {
	const classes = classNames(className, 'problems-space', `problems-space_${space.type}`, {
		'problems-space_disabled': disabled,
		'problems-space_default': !declaredTypes.includes(space.type)
	});

	const props = getPropsByType(space.type);

	return (
		<div className={classes}>
			{space.type === SpaceTypeId.APARTMENTS || space.type === SpaceTypeId.FLATS ? (
				<SpaceCircle
					key={space.id}
					{...props}
					space={space}
					onClick={onClick}
				/>
			) : (
				<SpaceRectangle
					key={space.id}
					{...props}
					space={space}
					onClick={onClick}
				/>
			)}

			<div className="space__indicators">
				{space.indicators?.length ? <Indicators indicators={space.indicators}/> : null}
			</div>
		</div>
	);
};
