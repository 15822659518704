import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import React, {useCallback, useEffect, useState} from 'react';
import {EditableCheckList} from '@src/components/EditableCheckList/EditableCheckList';
import {useEditableCheckListState} from '@src/core/hooks/states/useEditableCheckListState/hook';
import {
	convertCheckItemsToSave,
	convertCheckListToSave,
	errorsFns,
	isEdited
} from '@src/core/hooks/states/useEditableCheckListState/state';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {EditableCheckItems} from '@src/components/EditableCheckList/components';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	companyId: {fieldId: 'companyId', isRequired: true},
	spaceTypes: {fieldId: 'spaceTypes', isRequired: false},
	structureType: {fieldId: 'structureType', isRequired: false},
	type: {fieldId: 'type', isRequired: true},
	stage: {fieldId: 'stage', isRequired: true},
	objects: {fieldId: 'objects', isRequired: false},
	typeDecoration: {fieldId: 'typeDecoration', isRequired: false},
	categories: {fieldId: 'categories', isRequired: false}
};

interface IHookArgs {
	checkList?: ICheckList;
	checkItems?: ICheckItem[];
	saving?: boolean;
}

export const useEditableCheckList = ({
	checkList,
	checkItems,
	saving
}: IHookArgs): [
	React.ReactNode,
	React.ReactNode,
	() => {checkList?: ISavingCheckList, checkItems?: ISavingCheckItem[]} | undefined,
	() => void,
	boolean
] => {
	const [editingState, editingDispatch] = useEditableCheckListState({checkList, checkItems});
	const [isBlocking, setIsBlocking] = useState(false);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, checkList));
	}, [editingState, checkList]);

	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}

		if (!isEdited(editingState, checkList)) {
			return undefined;
		}

		const savingCheckList = convertCheckListToSave(editingState, checkList, true);
		const savingCheckItems = convertCheckItemsToSave(editingState, checkItems, true);

		return {
			checkList: savingCheckList,
			checkItems: savingCheckItems
		};
	}, [editingState, checkList]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: {checkList, checkItems}
		});
	}, [checkList, checkItems]);

	const checkListFields = (
		<EditableCheckList
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving}
		/>
	);

	const checkItemsFields = (
		<EditableCheckItems
			editingState={editingState}
			editingDispatch={editingDispatch}
		/>
	);

	return [checkListFields, checkItemsFields, getSavingData, reset, isBlocking];
};
