import React, {memo, useCallback} from 'react';
import {Button} from '../../../buttons';

export interface IProvider {
	id: string;
	name: string;
	icon?: React.ReactNode;

	onClick?: (id: string) => void;
}

interface IProviders {
	providers?: IProvider[];
	className?: string;
}

const Provider = ({id, name, icon, onClick}: IProvider) => {
	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(id);
		}
	}, [id, onClick]);

	return (
		<Button
			label={name}
			leftIcon={icon}
			onClick={handleClick}
		/>
	);
};

const Providers = ({providers, className}: IProviders) => (
	<div className={className}>
		{providers?.map(provider => (
			<Provider
				{...provider}
				key={provider.id}
			/>
		))}
	</div>
);

export default memo(Providers);