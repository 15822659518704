import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ISavingWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/ISavingWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {isEqual} from 'lodash';

export type IEditableWorkAcceptanceState = IEditableEntityState<{
	description: string;
	categoryId?: string | null;
	acceptanceDate?: number;
	respUsers: string[];
	activeGroup?: string;
	structureIds: string[];
	locations: Record<string, {planId: string, location: ILocation}>;
}>;

export type IEditableWorkAcceptanceAction = IEditableEntityAction<
	IEditableWorkAcceptanceState,
	ISavingWorkAcceptance
>;

const makeEmptyState = (): IEditableWorkAcceptanceState => ({
	description: '',
	categoryId: undefined,
	acceptanceDate: undefined,
	respUsers: [],
	activeGroup: undefined,
	structureIds: [],
	locations: {},
	errors: {
		description: false,
		categoryId: false,
		acceptanceDate: false,
		respUsers: false,
		activeGroup: false,
		structureIds: false,
		locations: false
	}
});

const locationsFromStructures = (structures: Array<{
    id: string | undefined;
	planId?: string;
    location?: ILocation | undefined;
}> | undefined): Record<string, {planId: string, location: ILocation}> => {
	if (!structures) {
		return {};
	}
	return structures.reduce((acc: Record<string, {planId: string, location: ILocation}>, structure) => {
		if (structure.id && structure.planId && structure.location) {
			acc[structure.id] = {planId: structure.planId, location: structure.location};
		}
		return acc;
	}, {});
};

export const init = (original?: IWorkAcceptance): IEditableWorkAcceptanceState => {
	const empty = makeEmptyState();

	return original
		? {
			categoryId: original.categoryId ?? undefined,
			description: original.description ?? '',
			acceptanceDate: original.acceptanceDate ?? undefined,
			respUsers: original.respUsers ?? [],
			activeGroup: original.activeGroup ?? undefined,
			structureIds: original.structures?.map(str => str.id) ?? [],
			locations: locationsFromStructures(original.structures),
			errors: empty.errors
		}
		: empty;
};

const isDescriptionEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	(original?.description ? original.description !== state.description : !!state.description);

const isCategoryEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	(original?.categoryId ? original.categoryId !== state.categoryId : !!state.categoryId);

const isAcceptanceDateEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
(original?.acceptanceDate
	? original.acceptanceDate !== state.acceptanceDate
	: !!state.acceptanceDate);

const areRespUsersEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
(original?.respUsers
	? original.respUsers.length !== state.respUsers.length
	|| original.respUsers.some(id => !state.respUsers.includes(id))
	: !!state.respUsers.length);

const isActiveGroupEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	(original?.activeGroup ? original.activeGroup !== state.activeGroup : !!state.activeGroup);

const areStructuresEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
(original?.structures
	? original.structures.length !== state.structureIds.length
	|| original.structures.some(structure => !state.structureIds.includes(structure.id))
	: !!state.structureIds.length);

const areLocationsEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) => (
	!isEqual(locationsFromStructures(original?.structures), state.locations)
);

/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (
	state: IEditableWorkAcceptanceState,
	original?: IWorkAcceptance
): boolean =>
	isEntityEdited(
		state,
		original,
		isDescriptionEdited,
		isCategoryEdited,
		isAcceptanceDateEdited,
		areRespUsersEdited,
		isActiveGroupEdited,
		areStructuresEdited,
		areLocationsEdited
	);

/**
 * Функции проверки полей на ошибки
 */

export const errorsFns = {
	description: (state: IEditableWorkAcceptanceState) => !state.description,
	categoryId: (state: IEditableWorkAcceptanceState) => !state.categoryId,
	acceptanceDate: (state: IEditableWorkAcceptanceState) => !state.acceptanceDate,
	respUsers: (state: IEditableWorkAcceptanceState) => !state.respUsers.length,
	activeGroup: (state: IEditableWorkAcceptanceState) => !state.activeGroup,
	structureIds: (state: IEditableWorkAcceptanceState) => !state.structureIds.length
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableWorkAcceptanceState,
	original?: IWorkAcceptance,
	onlyEdited?: boolean
): ISavingWorkAcceptance => {
	if (!onlyEdited) {
		return {
			description: state.description,
			categoryId: state.categoryId,
			acceptanceDate: state.acceptanceDate,
			respUsers: state.respUsers,
			activeGroup: state.activeGroup,
			structures: state.structureIds.map(id => ({
				id,
				planId: state.locations[id]?.planId,
				location: state.locations[id]?.location
			}))
		};
	}
	const acceptance: ISavingWorkAcceptance = {};
	if (isDescriptionEdited(state, original)) {
		acceptance.description = state.description;
	}
	if (isCategoryEdited(state, original)) {
		acceptance.categoryId = state.categoryId;
	}
	if (isAcceptanceDateEdited(state, original)) {
		acceptance.acceptanceDate = state.acceptanceDate;
	}
	if (areRespUsersEdited(state, original)) {
		acceptance.respUsers = state.respUsers;
	}
	if (isActiveGroupEdited(state, original)) {
		acceptance.activeGroup = state.activeGroup;
	}
	if (areStructuresEdited(state, original) || areLocationsEdited(state, original)) {
		acceptance.structures = state.structureIds.map(id => ({
			id,
			planId: state.locations[id]?.planId,
			location: state.locations[id]?.location
		}));
	}
	return acceptance;
};
