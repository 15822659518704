import {IState} from '@src/store/modules';
import * as types from '../constants/problemsData';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {requestStructuresProblemsData} from '@src/api/backend/structures/problemsData';
import {IStructuresStatsDataRequest} from '@src/interfaces/IStructuresStatsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

const request = (requestData: IStructuresStatsDataRequest) => ({
	type: types.GET_REQUEST,
	payload: requestData
});

const success = (response: Record<string, IEntityProblemsData>, objectId: string) => ({
	type: types.GET_SUCCESS,
	payload: {structuresProblemsData: response, objectId}
});

const failure = (error: IError, objectId: string) => {
	addErrorToast('Ошибка', 'при загрузке нарушений структур');
	return {
		type: types.GET_FAILURE,
		payload: error,
		objectId
	};
};

/**
 * Получает статстику нарушений для структур Объекта
 *
 * @param requestData данные запроса
 */
export const getStructuresProblemsData = (requestData: IStructuresStatsDataRequest) =>
	checkLoaded<IState, Record<string, IEntityProblemsData>, ApiAction>(
		s => s.pages.structures.problemsData[requestData.objectId],

		createApiAction(
			() => request(requestData),
			result => success(result, requestData.objectId),
			error => failure(error, requestData.objectId),
			() => requestStructuresProblemsData(requestData)
		)
	);
