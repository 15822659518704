import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import React, {useCallback, useState} from 'react';
import {EditableInternalAcceptanceAttachmentsDialog} from '../EditableInternalAcceptanceAttachmentsDialog';

export function useEditableInternalAcceptanceAttachmentsDialog(
	objectId: string,
	internalAcceptanceId: string,
	internalAcceptance: IInternalAcceptance
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<EditableInternalAcceptanceAttachmentsDialog
			objectId={objectId}
			internalAcceptance={internalAcceptance}
			internalAcceptanceId={internalAcceptanceId}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
