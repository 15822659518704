import React from 'react';
import './ScheduleTable.less';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useBodyColumns, useInfoColumns, useInfoColumnsForGroupMode} from './columns';
import {MenuActionCtxProvider} from '../../../DispatchActionCtx';
import {TasksScheduleTable} from '@src/components/TasksScheduleTable/TasksScheduleTable';
import {useEnrichedTasksForScheduler} from '../../../../hooks/useEnrichedTasksForSchedule';
import {
	convertTasksToScheduleFormat
} from '@src/pages/TasksPage/utils/convertTasksToScheduleFormat';
import {tasksList, useTasksForSchedule, useTasksScheduleCollectMode} from '@src/core/hooks/queries/tasks';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ITasksFiltersState, TasksScheduleCollectMode} from '@src/store/modules/settings/pages/tasks/interfaces';
import {useIsFetching, useQueryClient} from '@tanstack/react-query';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';

export const ScheduleTable = () => {
	const {state} = useEntitiesFiltersCtx<ITasksFiltersState>();
	const tasksData = useTasksForSchedule(state);
	const tasks = tasksList(tasksData);
	const collectMode = useTasksScheduleCollectMode();
	const {settings} = useAppSelector(extractTasksScheduleSettings);
	const isFetching = useIsFetching({queryKey: tasksQueryKeys.schedule()});

	const enrichedTasks = useEnrichedTasksForScheduler(tasks, !!isFetching);
	const preparedData = convertTasksToScheduleFormat(settings, collectMode, enrichedTasks);

	const queryClient = useQueryClient();
	const [, reloadSchedule] = useAsyncFn(async () => {
		await queryClient.invalidateQueries({
			queryKey: tasksQueryKeys.schedule()
		});
	}, []);

	const infoColumns = useInfoColumns();
	const infoColumnsForGroupMode = useInfoColumnsForGroupMode();
	const bodyColumns = useBodyColumns(settings);

	const finalInfoColumns = collectMode === TasksScheduleCollectMode.GROUP
		? infoColumnsForGroupMode
		: infoColumns;

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={reloadSchedule}>
				<LoadingPanel active={!!isFetching}>
					<TasksScheduleTable
						data={preparedData}
						columns={finalInfoColumns}
						bodyColumns={bodyColumns}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
