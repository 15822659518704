import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {IWorkingGroupType} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

export type IWorkingGroupTypesState = ILoadedEntityState<IWorkingGroupType>;

export const workingGroupTypes = createReducer<IWorkingGroupTypesState>(
	getLoadedEntitiesInitialState<IWorkingGroupType>(),
	{
		[types.GET_REQUEST]: getLoadedEntitiesInitialState,
		[types.GET_SUCCESS]: getLoadedEntitiesSuccess<IWorkingGroupType>()
	}
);
