import React, {useCallback, useMemo} from 'react';
import './ObjectMobileCell.less';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompany} from '@src/store/modules/dictionaries/companies/selectors';
import ProblemsCount from '../ProblemsCount';
import {useObjectsPermissions} from '@src/core/hooks/permissions/useObjectsPermissions';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import declination from '@tehzor/tools/utils/declination';
import {flattenTree} from '@src/utils/tree';
import {useAddingObjectDialog} from '@src/components/AddingObjectDialog/hooks/useAddingObjectDialog';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteObject} from '@src/store/modules/entities/object/actions';
import {ObjectStagesCell} from '@src/pages/ObjectsPage/components/table/ObjectStagesCell';
import {useChangePath} from '@src/core/hooks/useChangePath';

const openIcon = <i className="tz-object-24"/>;
const addIcon = <i className="tz-plus-20"/>;
const deleteIcon = <i className="tz-delete"/>;

export const ObjectMobileCell = (props: CellProps<IParentObject>) => {
	const {row} = props;
	const object = row.original;
	const {pushPath} = useChangePath();
	const company = useAppSelector(s => extractCompany(s, object.companyId));

	const text = useMemo(() => {
		const count = object?.children ? flattenTree(object.children).length : 0;
		return `${count} ${declination(count, ['подобъект', 'подобъекта', 'подобъектов'])}`;
	}, [object.children]);

	const permissons = useObjectsPermissions();
	const dispatch = useAppDispatch();

	const [addingDialog, openAddingDialog] = useAddingObjectDialog(object.id, company.id);

	const handleView = useCallback((object: IObject) => {
		pushPath(`/objects/${object.id}/view`);
	}, []);

	const handleAdd = useCallback(() => {
		openAddingDialog();
	}, [openAddingDialog]);

	const handleDelete = useCallback(async () => {
		await dispatch(deleteObject(object.id));
	}, [object.id]);

	const menuActions = [];
	if (permissons.canEdit) {
		menuActions.push(
			<MenuItem
				key="view"
				className="menu-item_red"
				icon={openIcon}
				onClick={() => handleView(object)}
			>
				Открыть
			</MenuItem>
		);
	}
	if (permissons.canAdd) {
		menuActions.push(
			<MenuItem
				key="add"
				className="menu-item_red"
				icon={addIcon}
				onClick={handleAdd}
			>
				Добавить объект
			</MenuItem>
		);
	}
	if (permissons.canDelete) {
		menuActions.push(
			<MenuItem
				key="delete"
				className="menu-item_red"
				icon={deleteIcon}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

	return (
		<div className="object-mobile-cell">
			<div className="object-mobile-cell__info">
				<div className="object-mobile-cell__info-header">
					<div className="object-mobile-cell__info-name">{object.name}</div>
					<ClickPreventWrap>
						{menuActions.length ? (
							<IconMenu className="object-mobile-cell__info-menu">
								{menuActions}
							</IconMenu>
						) : null}
					</ClickPreventWrap>
				</div>

				<div className="object-mobile-cell__info-city">
					{`${object.city ? object.city : null}, ${company?.name ? company.name : null}`}
					{object.children ? (
						<>
							&nbsp;
							<div className="object-mobile-cell__info-separator"/>
							&nbsp;
							{text}
						</>
					) : null}
				</div>
				<div className="object-mobile-cell__info-stages">
					<ObjectStagesCell {...props}/>
				</div>
				<div className="object-mobile-cell__info-problems">
					<ProblemsCount object={object}/>
				</div>
			</div>
			{addingDialog}
		</div>
	);
};
