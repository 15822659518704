import React from 'react';
import classNames from 'classnames';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {LoadingPanel} from '@tehzor/ui-components';
import {extractStructuresPageSettings} from '@src/store/modules/settings/pages/structures/selectors';
import {MobileTable} from './table/Table.mobile';
import {MobileFiltersLinkBtn} from '@src/components/MobileFiltersLinkBtn';
import {extractStructuresListData} from '@src/store/modules/entities/structures/selectors/lists';
import {MobileSelectionClearing} from './selection/SelectionClearing.mobile';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {PageBreadcrumbs} from './PageBreadcrumbs';

interface IMobileProps {
	objectId: string;
	loading?: boolean;
}

export const Mobile = ({objectId, loading}: IMobileProps) => {
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const {selected} = useAppSelector(s => extractStructuresListData(s, objectId));

	const {state} = useEntitiesFiltersCtx();
	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(
		objectId,
		{
			sectionsMenu,
			mobileLeftButton:
				selected.length > 0 ? <MobileSelectionClearing objectId={objectId}/> : undefined
		},
		[objectId, selected]
	);

	return (
		<div
			className={classNames('page-cont', 'structures-page', {
				'page-cont_extra-top-margin': sectionsMenu.length
			})}
		>
			<PageBreadcrumbs objectId={objectId}/>
			<div className="structures-page__m-controls-bar">
				<MobileFiltersLinkBtn
					state={state}
					objectId={objectId}
					entity="structures"
				/>
			</div>

			<LoadingPanel
				className="structures-page__loading-panel"
				active={loading}
			>
				<>
					{displayMode === StructuresDisplayMode.LIST && <MobileTable objectId={objectId}/>}
				</>
			</LoadingPanel>
		</div>
	);
};
