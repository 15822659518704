import React from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {getStructuresList} from '@src/store/modules/entities/structures/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractFiltredStructuresListAsArray} from '@src/store/modules/entities/structures/selectors/lists';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {ru} from 'date-fns/locale';
import {WorkAcceptanceStatusCell} from './WorkAcceptanceStatusCell';
import {EntityInfo} from '@tehzor/ui-components';
import {extractCategoryById} from '@src/store/modules/dictionaries/categories/selectors';

export const MobileWorkAcceptanceCell = (props: CellProps<IListWorkAcceptance>) => {
	const {row} = props;
	const workAcceptance: IListWorkAcceptance = row.original;
	const dispatch = useAppDispatch();
	void dispatch(getStructuresList(workAcceptance.objectId));
	const structures: IListStructure[] = useAppSelector(state =>
		extractFiltredStructuresListAsArray(
			state,
			workAcceptance.objectId,
			workAcceptance.structureIds
		));

	const statusesMap = useAppSelector(state => state.dictionaries.workAcceptanceStatuses);
	const category = useAppSelector(state => extractCategoryById(state, workAcceptance.categoryId));

	return (
		<div className="structure-page__work-acceptance-cell">
			<EntityInfo
				title={`Приёмка работ №${workAcceptance.number}`}
				subTitle={
					workAcceptance.acceptanceDate
					&& `срок до ${format(workAcceptance.acceptanceDate, dateTimeCommaSeparatedFormat, {
						locale: ru
					})}`
				}
				icon={<i className="tz-work-acceptance-28"/>}
				iconColor={statusesMap.byId[workAcceptance.status]?.color}
			/>
			<div className="structure-page__work-acceptance-cell_data">
				<div>{category?.name}</div>
				<div>{structures.map(str => str.name).join(', ')}</div>
			</div>

			<div className="structure-page__work-acceptance-cell_data">
				<WorkAcceptanceStatusCell {...props}/>
			</div>
		</div>
	);
};
