import React, {memo} from 'react';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface ICheckListsFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	checkLists: ICheckList[];
	onChange: (changes: IChanges) => void;
}

export const CheckListsFilterMobile = memo((props: ICheckListsFilterMobileProps) => (
	<BaseListFilterMobile
		{...props}
		options={props.checkLists}
		label="Чек-листы"
		filterName="checkListIds"
		resetOnApply={['checkListCategory']}
	/>
	));
