import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTask} from '@src/store/modules/pages/task/selectors';
import {useMemo} from 'react';

export const useManageTaskBreadcrumbs = () => {
	const task = useAppSelector(extractTask);

	return useMemo(() => {
		const items = [
			{
				label: 'Задачи',
				url: '/tasks',
				nonClickable: !task
			}
		];

		if (task) {
			items.push({
				label: `Задача "${task.name}"`,
				url: `/tasks/${task.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [task]);
};
