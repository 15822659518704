import React, {Dispatch, useCallback, useMemo, useState} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useUpdateEffect} from 'react-use';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {extractSpaceTypeDecorationsAsArray} from '@src/store/modules/entities/spaceTypeDecorations/selectors';
import {extractSpaceTypeDecorationSetsAsArray} from '@src/store/modules/entities/spaceTypeDecorationSets/selectors';
import {makeTreeDataSpaceTypeDecoration} from './utils/makeTreeData';

interface ISpaceTypeDecorationTreeSelectProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: string;
	value?: string[];
	externalTypeDecorations?: ISpaceTypeDecoration[];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const SpaceTypeDecorationTreeSelect = <S extends {typeDecoration?: 'typeDecoration'}, E>({
	className,
	style,
	field = 'typeDecoration',
	label = 'Тип отделки помещения',
	value,
	externalTypeDecorations,
	editingDispatch,
	required,
	disabled,
	hasError
}: ISpaceTypeDecorationTreeSelectProps<S, E>) => {
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);

	const typeDecorationsById = useAppSelector(s => s.entities.spaceTypeDecorations.byId);

	let typeDecorations = useAppSelector(extractSpaceTypeDecorationsAsArray);
	if (externalTypeDecorations) {
		typeDecorations = externalTypeDecorations;
	}

	const typeDecorationSets = useAppSelector(extractSpaceTypeDecorationSetsAsArray);

	const treeData = useMemo(() =>
		makeTreeDataSpaceTypeDecoration(typeDecorations, typeDecorationSets, true),
	[typeDecorations, typeDecorationSets]);

	const handleChange = useCallback(() => {
		editingDispatch({type: 'update', field, value: selectedObjects});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [required, selectedObjects, field]);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
	}, []);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
	}, [value]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	useUpdateEffect(() => {
		setSelectedObjects(value);
	}, [value]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={
							value && value?.length > 0
								? value.length > 1
									? `${typeDecorationsById[value[0]]?.name} + ${value.length - 1}`
									: `${typeDecorationsById[value[0]]?.name}`
								: undefined
						}
						icon={<i className="tz-simple-arrow-20"/>}
						error={required && hasError ? 'Выберите тип отделки' : undefined}
						disabled={disabled}
					/>
				)}
				clearButton={!!selectedObjects?.length}
				footer
				noHeader
				count={selectedObjects?.length}
				onApply={handleChange}
				onCancel={handleCancel}
				onClear={handleClear}
			>
				<TreeSelect
					data={treeData}
					multiple
					value={selectedObjects}
					onChange={setSelectedObjects}
					expandedValue={expandedObjects}
					onExpand={handleExpand}
				/>
			</SelectPopup>
		</div>
	);
};
