import React, {useCallback, useState} from 'react';
import {useAsyncFn} from 'react-use';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import {useEnrichedGroups} from '../../hooks/useEnrichedGroups';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {desktopColumns} from './columns.desktop';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {extractWorkingGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {getResponsibilityRules} from '@src/store/modules/dictionaries/responsibilityRules/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface ITableProps {
	loading?: boolean;
}

export const Table = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const data = useAppSelector(extractWorkingGroupsAsArray);
	const dispatch = useAppDispatch();

	const [, loadResponsibilityRules] = useAsyncFn(() => dispatch(getResponsibilityRules()));

	const preparedData = useEnrichedGroups(data);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const handleRowClick = useCallback((item: IEnrichedWorkingGroup) => {
		pushPath(`/manage/working-groups/${item.id}`);
	}, []);
	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={loadResponsibilityRules}>
				<LoadingPanel active={loading}>
					<EntitiesTable
						columns={desktopColumns}
						data={preparedData}
						selectedRows={selectedRows}
						selectable
						onRowClick={handleRowClick}
						onSelectedRowsChange={setSelectedRows}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
