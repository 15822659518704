import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import React from 'react';
import MobileActions from './Actions.mobile';

interface IMobileRightButtonsProps {
	task: ITask;
}

const MobileRightButtons = ({task}: IMobileRightButtonsProps) => {
	if (!task) return null;

	return <MobileActions task={task}/>;
};

export default MobileRightButtons;
