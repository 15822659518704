import React, {CSSProperties, Dispatch, ReactNode, useCallback} from 'react';
import {IEditableEntityAction} from '@src/core/states/editableEntityState';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';

interface INameFieldProps<S, E> {
	className?: string;
	style?: CSSProperties;
	label?: ReactNode;
	field?: keyof S;
	addon?: ReactNode;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
	onChange?: (value: string) => void;
}

export const NameField = <S, E>({
	className,
	style,
	label = 'Название',
	field = ('name' as keyof S),
	addon,
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage = 'Заполните название',
	onChange
}: INameFieldProps<S, E>) => {
	const handleChange = useCallback((v: string) => {
		editingDispatch({type: 'update', field, value: v});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
		if (onChange) {
			onChange(v);
		}
	}, [required, onChange]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel addon={addon}>{label}</EditableFieldLabel>

			<TextField
				value={value}
				elementType="input"
				error={required && hasError ? errorMessage : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};