import React, {useCallback, memo} from 'react';
import {EntitiesTable} from '@tehzor/ui-components';
import {Column} from 'react-table';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useDocumentsArray} from '@src/core/hooks/queries/documents';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';

interface IDesktopTableProps {
	columns: Array<Column<IDocument>>;
	hideHead?: boolean;
	noRowBorder?: boolean;
}

export const Table = memo((props: IDesktopTableProps) => {
	const {columns, hideHead, noRowBorder} = props;
	const documents = useDocumentsArray();
	const {pushPath} = useChangePath();

	const handleRowClick = useCallback(
		(document: IDocument) => pushPath(`/documents/${document.id}`),
		[]
	);

	return (
		<EntitiesTable<IDocument>
			columns={columns}
			data={documents}
			noRowBorder={noRowBorder}
			headVisible={!hideHead}
			onRowClick={handleRowClick}
		/>
	);
});
