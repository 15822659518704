import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ICheckRecordStatusesState} from '@src/store/modules/dictionaries/checkRecordStatuses/reducers';

const extractAllIds = (state: IState) => state.dictionaries.checkRecordStatuses.allIds || [];
const extractById = (state: IState) => state.dictionaries.checkRecordStatuses.byId || {};

/**
 * Возвращает статусы в виде массива
 */
export const extractCheckRecordStatusesAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);

/**
 * Возвращает статусы по типу
 */
export const extractCheckRecordStatusesByEntityType = createSelector(
	[extractById, (s: IState, type: 'check-list' | 'check-item') => type],
	(statusesMap, type) =>
		Object.values(statusesMap).reduce<ICheckRecordStatusesState>(
			(acc, status) => {
				if (status.for.includes(type)) {
					acc.byId[status.id] = status;
					acc.allIds.push(status.id);
				}

				return acc;
			},
			{allIds: [], byId: {}, loaded: true}
		)
);

/**
 * Возвращает статусы по типу в виде массива
 */
export const extractCheckRecordStatusesByEntityTypeAsArray = createSelector(
	[
		extractCheckRecordStatusesByEntityType,
		(s: IState, type: 'check-list' | 'check-item') => type
	],
	statuses => statuses.allIds.map((id: string) => statuses.byId[id])
);
