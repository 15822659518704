import React, {useMemo} from 'react';
import {usePermissions} from './usePermissions';
import {formCheckLink, formInternalAcceptanceLink, formSpaceLink, formTaskLink} from '@tehzor/tools/utils/links';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';

interface IEntityLink {
	key: string;
	icon: React.ReactNode;
	label: string;
	url?: string;
	title: string;
}

export function useEntitiesLinks(objectId: string, inspection?: ILinkedInspection): IEntityLink[] {
	const permissions = usePermissions(objectId, inspection);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const task = useAppSelector(s => s.pages.task.data);

	return useMemo((): IEntityLink[] => {
		if (!inspection?.links) {
			return [];
		}
		const result: IEntityLink[] = [];
		if (inspection.space && permissions.canViewSpace) {
			result.push({
				key: 'space',
				icon: <i className="tz-space-24"/>,
				label: formSpaceTitle(
					inspection.space.name,
					inspection.space.altName,
					spaceTypes.byId[inspection.space.type],
					altNamesVisible
				),
				url: formSpaceLink(objectId, inspection.space.id),
				title: 'Перейти к помещению'
			});
		}
		if (inspection.check && permissions.canViewCheck) {
			result.push({
				key: 'check',
				icon: <i className="tz-check-24"/>,
				label: `Проверка №${inspection.check.number}`,
				url: formCheckLink(objectId, inspection.check.id),
				title: 'Перейти к проверке'
			});
		}

		if (inspection.internalAcceptance && permissions.canViewInternalAcceptance) {
			result.push({
				key: 'internalAcceptance',
				icon: <i className="tz-internal-acceptance-24"/>,
				label: `Внутренняя приёмка №${inspection.internalAcceptance.number}`,
				url: formInternalAcceptanceLink(objectId, inspection.internalAcceptance.id),
				title: 'Перейти к внутренней приёмке'
			});
		}

		if (inspection?.links?.taskId !== undefined && permissions.canViewTask) {
			result.push({
				key: 'task',
				icon: <i className="tz-internal-acceptance-24"/>,
				label: `Задача ${task?.name?.slice(0, 9)}`,
				url: formTaskLink(inspection.links.taskId),
				title: 'Перейти к задаче'
			});
		}
		return result;
	}, [objectId, inspection, permissions, spaceTypes, task]);
}
