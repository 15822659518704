import {Column} from 'react-table';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectMobileCell} from '@src/components/tableCells/ObjectMobileCell/ObjectMobileCell';

export const mobileColumns: Array<Column<IObject>> = [
	{
		id: 'name',
		accessor: 'name',
		Cell: ObjectMobileCell,
		className: 'objects-page__mobile-cell'
	}
];
