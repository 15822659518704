import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import PageBreadcrumbs from './PageBreadcrumbs';
import Info from './info/Info';
import DesktopActions from './actions/Actions.desktop';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {Plate} from '@tehzor/ui-components';
import {desktopColumns} from './table/columns.desktop';
import Table from './table/Table';
import FromSpacePageBreadcrumbs from './FromSpacePageBreadcrumbs';

interface IDesktopProps {
	objectId: string;
	internalAcceptanceId: string;
	internalAcceptance?: ILinkedInternalAcceptance;
}

const Desktop = ({objectId, internalAcceptanceId, internalAcceptance}: IDesktopProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const location = useLocation();
	const spaceId = (location.state as {spaceId?: string} | null)?.spaceId;
	useAppHeader(
		{
			title: internalAcceptance ? `Внутренняя приёмка №${internalAcceptance.number}` : '',
			showBackBtn: true
		},
		[internalAcceptance]
	);

	return internalAcceptance !== undefined ? (
		<div className="page-cont internal-acceptance-page">
			{spaceId ? (
				<FromSpacePageBreadcrumbs
					objectId={objectId}
					internalAcceptance={internalAcceptance}
				/>
			) : (
				<PageBreadcrumbs
					objectId={objectId}
					internalAcceptance={internalAcceptance}
				/>
			)}

			<div className="internal-acceptance-page__d-columns">
				<div className="internal-acceptance-page__d-column internal-acceptance-page__d-column_info">
					<Info
						objectId={objectId}
						internalAcceptance={internalAcceptance}
					/>
				</div>
				<div className="internal-acceptance-page__d-column internal-acceptance-page__d-column_entities">
					{internalAcceptance && (
						<DesktopActions
							objectId={objectId}
							internalAcceptance={internalAcceptance}
						/>
					)}

					<Plate withoutPadding>
						<Table
							objectId={objectId}
							internalAcceptanceId={internalAcceptanceId}
							columns={desktopColumns}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
						/>
					</Plate>
				</div>
			</div>
		</div>
	) : null;
};

export default Desktop;
