import React, {memo, useState} from 'react';
import {MobileFilter} from '@tehzor/ui-components';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

const defaultOptions = [
	{
		id: 'true',
		name: 'Критично'
	},
	{
		id: 'false',
		name: 'Не критично'
	}
];

interface ICriticalFilterMobileProps {
	value?: boolean;
	label?: string;
	filterName?: string;
	options?: Array<{id: string, name: string}>;
	onChange: (changes: IChanges) => void;
}

export const CriticalFilterMobile = memo(
	({
		value,
		label = 'Критичность',
		filterName = 'critical',
		options = defaultOptions,
		onChange
	}: ICriticalFilterMobileProps) => {
		const valueAsArray = value === undefined ? undefined : value ? ['true'] : ['false'];

		const [selected, setSelected] = useState(valueAsArray);

		useUpdateEffect(() => {
			setSelected(valueAsArray);
		}, [value]);

		const handleChange = (v: string[] | undefined) => {
			const newValue = v ? (v.length !== 1 ? undefined : v[0] === 'true') : undefined;
			onChange({[filterName]: newValue});
		};

		return (
			<MobileFilter
				label={label}
				elements={options}
				selected={selected}
				onChange={handleChange}
			/>
		);
	}
);
