import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IEditCategoriesOrderResponse, makeCategoriesEditOrderRequest} from '@src/api/backend/categories';
import {ICategoriesOrder} from '@tehzor/tools/interfaces/categories/ICategoriesOrder';
import {queryClient} from '@src/api/QueryClient';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';

export type IEditCategoriesOrderPayload = IEditCategoriesOrderResponse;

const request = () => ({type: types.EDIT_ORDER_REQUEST});

const success = (response: IEditCategoriesOrderResponse) => ({
	type: types.EDIT_ORDER_SUCCESS,
	payload: {
		...response
	}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении');
	return {
		type: types.EDIT_ORDER_FAILURE,
		payload: error
	};
};

/**
 * Менят порядок наборов видов работ
 *
 * @param orders порядок наборов видов работ
 */
export const editCategoriesOrder = (orders: ICategoriesOrder[]) =>
	createApiAction<IEditCategoriesOrderResponse>(
		request,
		res => success(res),
		failure,
		async () => {
			const data = await makeCategoriesEditOrderRequest(orders);
			await queryClient.invalidateQueries(categoriesQueryKeys.list());
			return data;
		}
	);
