import React, {useCallback, useContext} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from './ProblemsTable';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

const deleteIcon = <i className="tz-delete"/>;
const copyIcon = <i className="tz-copy"/>;

const MenuCell = ({row}: CellProps<IProblemEntity>) => {
	const {canDelete, canCopy} = row.original.data;
	const dispatchAction = useContext(DispatchActionCtx);

	const deleteCheck = useCallback(() => {
		dispatchAction({type: 'delete', payload: row.original.data});
	}, [row.original, dispatchAction]);

	const copyProblem = useCallback(() => {
		dispatchAction({type: 'copy', payload: row.original.data});
	}, [row.original, dispatchAction]);

	const menuActions = [];
	if (
		canCopy
		// TODO убрать строку по завершению TZ-1787 и TZ-1059
		&& row.original.data.stage !== ObjectStageIds.ACCEPTANCE
	) {
		menuActions.push(
			<IconButton
				key="copy"
				type="inline-blue-accent"
				onClick={copyProblem}
			>
				{copyIcon}
			</IconButton>
		);
	}
	if (canDelete) {
		menuActions.push(
			<IconButton
				key="delete"
				type="inline-blue-accent"
				onClick={deleteCheck}
			>
				{deleteIcon}
			</IconButton>
		);
	}
	return menuActions.length ? (
		<ClickPreventWrap className="problems-page__d-table-menu-wrap">
			{menuActions}
		</ClickPreventWrap>
	) : null;
};

export default MenuCell;
