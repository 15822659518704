import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {SpaceIndicatorTag, TagsContainer} from '@tehzor/ui-components';

const SpaceIndicatorsCell = ({row}: CellProps<IEnrichedSpace>) => {
	const space = row.original;

	return space.indicators ? (
		<TagsContainer>
			{space.indicators.map(item => (
				<SpaceIndicatorTag
					key={item.id}
					color={item.color}
					label={item.name}
				/>
			))}
		</TagsContainer>
	) : null;
};

export default SpaceIndicatorsCell;
