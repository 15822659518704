import {createSelector} from 'reselect';
import {IState} from '../..';
import {IProblemViewsState, getInitialState} from './reducers';
import {orderBy} from 'lodash';

export const extractProblemViewsData = createSelector(
	(state: IState) => state.entities.problemViews,
	(state: IState, problemId: string) => problemId,
	(data, problemId): IProblemViewsState =>
		data[problemId] || getInitialState()
);

export const extractProblemViewsAsArray = createSelector(
	[extractProblemViewsData],
	({allIds, byId}) => allIds.map(id => byId[id])
);

export const extractSortedProblemViewsAsArray = createSelector(
	[extractProblemViewsAsArray],
	arr => orderBy(arr, ['createdAt'], ['desc'])
);