import React, {Dispatch} from 'react';
import {
	IEditableBankAccountAction,
	IEditableBankAccountState
} from '@src/core/hooks/states/useEditableBankAccountState';
import './EditableBankAccount.less';
import Text from '@src/components/editableFields/Text';

interface IEditableBankAccountProps {
	editingDispatch: Dispatch<IEditableBankAccountAction>;
	editingState: IEditableBankAccountState;
}

export const EditableBankAccount = (props: IEditableBankAccountProps) => {
	const {editingState, editingDispatch} = props;

	return (
		<div className="editable-bank-account">
			<Text
				field="bankName"
				label="Наименование банка*"
				value={editingState.bankName}
				editingDispatch={editingDispatch}
				required
				hasError={editingState.errors.bankName}
			/>

			<Text
				field="accountNumber"
				label="Номер расчетного счета*"
				value={editingState.accountNumber}
				editingDispatch={editingDispatch}
				required
				hasError={editingState.errors.accountNumber}
			/>

			<Text
				field="correspondentAccountNumber"
				label="Номер корреспондентского счета*"
				value={editingState.correspondentAccountNumber}
				editingDispatch={editingDispatch}
				required
				hasError={editingState.errors.correspondentAccountNumber}
			/>

			<Text
				field="bik"
				label="БИК*"
				value={editingState.bik}
				editingDispatch={editingDispatch}
				required
				hasError={editingState.errors.bik}
			/>

			<Text
				field="comment"
				label="Комментарий"
				value={editingState.comment}
				editingDispatch={editingDispatch}
				hasError={editingState.errors.comment}
			/>
		</div>
	);
};
