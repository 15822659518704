import React, {useCallback, useState} from 'react';
import GlobalAddingEntityDialog, {IGlobalAddingLinks} from '../GlobalAddingEntityDialog';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';

export function useGlobalAddingEntityDialog(
	objectId: string,
	spaceId: string | undefined,
	links: IGlobalAddingLinks | undefined,
	stage: ObjectStageIds,
	permissions: {
		checks?: boolean;
		internalAcceptances?: boolean;
		acceptances?: boolean;
		claims?: boolean;
	},
	defaultProblemData?: ISavingProblem,
	onSuccess?: () => void | Promise<void>,
	types?: ICheckAddingEntityType[]
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<GlobalAddingEntityDialog
			objectId={objectId}
			spaceId={spaceId}
			links={links}
			defaultProblemData={defaultProblemData}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
			stage={stage}
			permissions={permissions}
			types={types}
		/>
	);

	return [dialog, open];
}
