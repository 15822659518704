import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as typeDecorationSetTypes from '../spaceTypeDecorationSet/constants';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {RESET} from '@src/store/modules/entities/spaceTypeDecorationSets/constants';

export type ISpaceTypeDecorationSetsState = ILoadedEntityState<ISpaceTypeDecorationSet>;

export default createReducer<ISpaceTypeDecorationSetsState>(
	getLoadedEntitiesInitialState<ISpaceTypeDecorationSet>(),
	{
		[types.GET_REQUEST]: getLoadedEntitiesInitialState,
		[types.GET_SUCCESS]: getLoadedEntitiesSuccess<ISpaceTypeDecorationSet>(),
		[typeDecorationSetTypes.ADD_SUCCESS]: (
			state,
			{payload}: {payload: ISpaceTypeDecorationSet}
		) => {
			state.byId[payload.id] = payload;
			state.allIds.push(payload.id);
		},
		[typeDecorationSetTypes.EDIT_SUCCESS]: (
			state,
			{payload}: {payload: ISpaceTypeDecorationSet}
		) => {
			state.byId[payload.id] = payload;
		},
		[typeDecorationSetTypes.DELETE_SUCCESS]: (state, {payload}: {payload: {id: string}}) => {
			state.allIds = state.allIds.filter(id => id !== payload.id);
			delete state.byId[payload.id];
		},
		[CLEAR_STORE]: getLoadedEntitiesInitialState,
		[RESET]: getLoadedEntitiesInitialState
	}
);
