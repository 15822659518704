import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as claimTypes from '../../entities/warrantyClaim/constants';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {
	IAddWarrantyClaimPayload,
	IDeleteWarrantyClaimPayload
} from '@src/store/modules/entities/warrantyClaim/actions';
import {IGetOneUserWarrantyClaimPayload} from './actions/getOne';

export type ILatestUserWarrantyClaimsState = ILoadedEntityState<IWarrantyClaim>;

export default createReducer<ILatestUserWarrantyClaimsState>(
	getLoadedEntitiesInitialState<IWarrantyClaim>(),
	{
		[types.GET_SUCCESS]: getLoadedEntitiesSuccess<IWarrantyClaim>(),
		[types.GET_ONE_SUCCESS]: (state, {payload}: {payload: IGetOneUserWarrantyClaimPayload}) => {
			if (payload && !state.allIds.includes(payload.id)) {
				state.allIds.push(payload.id);
			}
			state.byId[payload.id] = payload;
		},
		[claimTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddWarrantyClaimPayload}) => {
			state.allIds.unshift(payload.id);
			state.byId[payload.id] = payload;
		},
		[claimTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteWarrantyClaimPayload}) => {
			if (state.allIds.includes(payload.claimId)) {
				const byId = {...state.byId};
				delete byId[payload.claimId];
				state.byId = byId;
				state.allIds = state.allIds.filter(id => id !== payload.claimId);
			}
		}
	}
);
