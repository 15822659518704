import React, {memo, useCallback} from 'react';
import {Button, IconButton} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	clearFilters,
	changeFilters,
	changeFiltersDialogTabIndex
} from '@src/store/modules/settings/pages/statistics/acceptance/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {isAcceptanceFiltersSetUp} from '@src/store/modules/settings/pages/statistics/acceptance/selectors/filters';
import StatsFiltersDialog from '../../../../components/StatsFiltersDialog';
import useToggle from 'react-use/lib/useToggle';

const exportIcon = <i className="tz-export-24 statistics-page__export-btn-icon"/>;

const Controls = () => {
	const {filtersDialogTabIndex, filters} = useAppSelector(
		s => s.settings.pages.statistics.acceptance
	);
	const isFiltersSetUp = useAppSelector(isAcceptanceFiltersSetUp);
	const dispatch = useAppDispatch();

	const [isFiltersOpen, toggleFiltersOpen] = useToggle(false);

	const handleFiltersReset = useCallback(() => {
		dispatch(clearFilters());
	}, []);

	return (
		<div className="statistics-page__controls">
			<div className="statistics-page__controls-left">
				<IconButton
					className="statistics-page__filters-btn"
					type="accent-blue"
					onClick={toggleFiltersOpen}
				>
					<i className="tz-filters-24 statistics-page__filters-btn-icon"/>
				</IconButton>

				{isFiltersSetUp && (
					<Button
						className="statistics-page__reset-btn"
						type="cancel"
						label="Сбросить фильтры"
						onClick={handleFiltersReset}
					/>
				)}
			</div>

			<div className="statistics-page__controls-right">
				<Button
					className="statistics-page__export-btn"
					type="accent-blue"
					leftIcon={exportIcon}
					label="Экспорт"
					disabled
				/>
			</div>

			<StatsFiltersDialog
				filters={filters}
				filtersDialogTabIndex={filtersDialogTabIndex}
				isOpen={isFiltersOpen}
				changeFilters={changeFilters}
				changeFiltersDialogTabIndex={changeFiltersDialogTabIndex}
				onRequestClose={toggleFiltersOpen}
			/>
		</div>
	);
};

export default memo(Controls);
