import React, {Dispatch, useCallback} from 'react';
import './Attachments.less';
import {EditableAttachments, FilesDropArea, Scrollbar} from '@tehzor/ui-components';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {addTempFile, deleteTempFile} from '@src/store/modules/entities/tempFile/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IUploadingFilesAction} from '@src/core/hooks/states/useUploadingFilesState';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import classNames from 'classnames';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import {convertRawFiles} from './utils/convertRawFiles';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useAttachmentsAndUploadsViewer} from '@src/core/hooks/imagesViewers/desktop/useAttachmentsAndUploadsViewer';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {useUploadsImages} from '@src/core/hooks/useUploadsImages';
import {useUploadsImagesObjects} from '@src/core/hooks/useUploadsImagesObject';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAttachmentsAndUploadsViewer as useMobileAttachmentsAndUploadsViewer} from '@src/core/hooks/imagesViewers/mobile/useAttachmentsAndUploadsViewer';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IEditImage from '@tehzor/tools/interfaces/IEditImage';
import {convertFileToUpload, convertToUpload} from './utils/convertToUpload';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import {isEqual} from 'lodash';
import {TempFileLocation} from '@tehzor/tools/enums/TempFileLocation';
import {AddTempFileFn} from '@tehzor/tools/components/FilesUploader2';

const simpleBarProps = {autoHide: false};

interface IAttachmentsProps<S, E> {
	className?:
		| string
		| {
				root?: string;
				attachBtn?: string;
				scrollArea?: string;
				images?: string;
				files?: string;
				image?: string;
				file?: string;
		  };
	style?: React.CSSProperties;
	attachments: IAttachment[];
	uploadingFiles: IUploadingFile[];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	waitForUploading?: boolean;
	label?: string;
	attachmentsDestination?: AttachmentFileDestination;
	imagesTitle?: string;
	entityId?: string;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	showAttachBtn?: boolean;
	icon?: React.ReactNode;
	canDraw?: boolean;
	saveToS3?: boolean;
	errorText?: string;
}

const Attachments = <S extends {attachments: IAttachment[]}, E>({
	waitForUploading = true,
	...props
}: IAttachmentsProps<S, E>) => {
	const {
		className,
		style,
		attachments,
		uploadingFiles,
		editingDispatch,
		uploadingFilesDispatch,
		label,
		required,
		disabled,
		hasError,
		showAttachBtn,
		icon,
		attachmentsDestination,
		imagesTitle,
		entityId,
		canDraw,
		saveToS3,
		errorText
	} = props;
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	const classes = convertClassNames(className);
	const tempFileLocation = saveToS3 ? TempFileLocation.S3 : TempFileLocation.FILE_SYSTEM;

	const updateErrors = useCallback(() => {
		if (required) {
			editingDispatch({type: 'update-error', field: 'attachments'});
			uploadingFilesDispatch({type: 'update-error'});
		}
	}, [required]);

	const handleFilesPick = useCallback(
		(files: IRawFile[]) => {
			uploadingFilesDispatch({
				type: 'add',
				value: convertRawFiles(files, networkStatus, waitForUploading)
			});
			updateErrors();
		},
		[networkStatus, waitForUploading, updateErrors]
	);

	const addTemporaryFile = useCallback(
		(...args: Parameters<AddTempFileFn>) =>
			dispatch(addTempFile(...args, undefined, undefined, tempFileLocation)),
		[tempFileLocation]
	);

	const deleteTemporaryFile = useCallback(
		async (fileId: string) => {
			await dispatch(deleteTempFile(fileId, tempFileLocation));
		},
		[tempFileLocation]
	);

	const handleUploadingFilesChange = useCallback(
		(value: IUploadingFile[]) => {
			uploadingFilesDispatch({type: 'update', value});
			updateErrors();
		},
		[updateErrors]
	);

	const handleAttachmentsChange = useCallback(
		(value: IAttachment[]) => {
			editingDispatch({type: 'update', field: 'attachments', value});
			updateErrors();
		},
		[updateErrors]
	);

	const images = useAttachmentsImages(attachments);
	const imagesObject = useAttachmentsImagesObjects(attachments);
	const uploads = useUploadsImages(uploadingFiles);
	const uploadsObject = useUploadsImagesObjects(uploadingFiles);

	const isDesktop = useIsDesktop();

	const updateTempFile = useCallback(
		async (editedFile: IEditImage, fromAttachments = false) => {
			const upl = convertFileToUpload(
				editedFile.editedBlob,
				editedFile.drawData,
				editedFile.originalBlob
			);
			let filteredUploads: IUploadingFile[] = [];

			if (!editedFile.attachmentId) return;
			// Удаляем старый файл если редактировали темп файл
			if (!fromAttachments) {
				await dispatch(deleteTempFile(editedFile.attachmentId, tempFileLocation));
			}
			// Формируем новый список темп файлов если редактировали не темп файл
			if (fromAttachments) {
				filteredUploads = uploadingFiles.filter(
					file => file.tempFile?.id !== editedFile.attachmentId
				);
			}
			// Добавляем отредактированный файл на сервер
			const editedTemp = await dispatch(
				addTempFile(
					upl.original,
					TempFileDestination.ATTACHMENT,
					undefined,
					undefined,
					upl.canvas?.originalFile,
					JSON.stringify(upl.canvas?.drawedData),
					tempFileLocation
				)
			);
			const tempToUpl = convertToUpload(editedTemp, editedFile.editedBlob);
			if (!tempToUpl) return;
			uploadingFilesDispatch({
				type: 'update',
				value: [...filteredUploads, tempToUpl]
			});
		},
		[uploadingFiles, tempFileLocation]
	);

	const updateEditedUpload = useCallback(
		async (editedFile: IEditImage) => {
			if (!editedFile) return;
			const currentFile = uploadingFiles.find(u => u.key === editedFile.attachmentId);

			if (isEqual(currentFile, currentFile?.canvas?.drawedData)) {
				return;
			}

			// Если currentFile отсутствует, значит что этот файл не локальный,
			// а tempFile созданный через сервер, а значит отменяем offline first и прогоняем через сервер
			if (!currentFile) {
				await updateTempFile(editedFile);
				return;
			}
			const sFiles = uploadingFiles.map(file => {
				if (file.key === editedFile.attachmentId) {
					const newEditedOriginal = new File(
						[editedFile.editedBlob],
						file.original.name,
						{
							type: file.original.type
						}
					);
					const originalFile = new File([editedFile.originalBlob], file.original.name, {
						type: file.original.type
					});
					file.url = URL.createObjectURL(editedFile.editedBlob);
					file.original = newEditedOriginal;
					file.canvas = {
						drawedData: editedFile.drawData,
						originalFile
					};
					return file;
				}
				return file;
			});
			uploadingFilesDispatch({type: 'update', value: sFiles});
		},
		[uploadingFiles]
	);

	const moveToUploads = useCallback(
		async (data: IEditImage) => {
			const attachment = attachments.find(item => item.id === data.attachmentId);
			// Проверяем, рисовал ли юзер, если нет, то прерываем
			if (attachment?.full?.url && isEqual(attachment.canvas, data.drawData)) {
				return;
			}

			// Удаляем Attachment из сущности
			const value = attachments.filter(item => item.id !== data.attachmentId);

			// т.к. у нас комменты и ответы не оффлан-фёрст, их прогоняем через сервер
			if (data.entityType === AttachmentFileDestination.COMMENT_OR_REPLY) {
				await updateTempFile(data, true);
				// обновляем сразу после, чтоб не словить анимацию переключения фото
				editingDispatch({type: 'update', field: 'attachments', value});
				return;
			}
			// Обновляем список локальных файлов
			const upl = convertFileToUpload(data.editedBlob, data.drawData, data.originalBlob);
			if (upl) {
				uploadingFilesDispatch({type: 'add', value: upl});
				editingDispatch({type: 'update', field: 'attachments', value});
			}
		},
		[attachments]
	);

	const [desktopImagesViewer, openAttachmentImage, openUploadsImage] =
		useAttachmentsAndUploadsViewer(
			images,
			uploads,
			imagesObject,
			uploadsObject,
			entityId,
			attachmentsDestination,
			imagesTitle || '',
			canDraw,
			updateEditedUpload,
			moveToUploads
		);

	const [mobileImagesViewer, openMobileAttachmentImage, openMobileUploadsImage] =
		useMobileAttachmentsAndUploadsViewer(
			images,
			uploads,
			imagesObject,
			uploadsObject,
			entityId,
			attachmentsDestination,
			imagesTitle || '',
			canDraw,
			updateEditedUpload,
			moveToUploads
		);

	return (
		<div
			className={classNames('editable-attachments-field', classes.root)}
			style={style}
		>
			{attachments.length > 0 || uploadingFiles.length > 0 ? (
				<Scrollbar
					className={classNames(
						'editable-attachments-field__scroll-area',
						classes.scrollArea
					)}
					simpleBarProps={simpleBarProps}
				>
					<EditableAttachments
						className={{
							images: classes.images,
							files: classes.files,
							image: classes.image,
							file: classes.file
						}}
						online={networkStatus}
						onSavedAttachmentImageClick={
							isDesktop ? openAttachmentImage : openMobileAttachmentImage
						}
						onUploadingImageClick={
							isDesktop ? openUploadsImage : openMobileUploadsImage
						}
						uploadingFiles={uploadingFiles}
						savedAttachments={attachments}
						disabled={disabled}
						onUploadingFilesChange={handleUploadingFilesChange}
						onSavedAttachmentsChange={handleAttachmentsChange}
						onAddTempFile={addTemporaryFile}
						onDeleteTempFile={deleteTemporaryFile}
					/>
					{isDesktop ? desktopImagesViewer : mobileImagesViewer}
				</Scrollbar>
			) : null}

			{uploadingFiles.some(file => file.sizeError) && (
				<div className="editable-attachments-field__error">
					Размер файла не должен привышать 50мб
				</div>
			)}

			{showAttachBtn && (
				<FilesDropArea
					className={classNames(
						'editable-attachments-field__attach-btn',
						classes.attachBtn
					)}
					label={label}
					icon={icon}
					multiple
					disabled={disabled}
					onPick={handleFilesPick}
				/>
			)}

			{required && hasError && (
				<div className="editable-attachments-field__error">
					{errorText ?? 'Прикрепите файлы или фотографии'}
				</div>
			)}
		</div>
	);
};

export default Attachments;
