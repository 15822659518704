import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Plate} from '@tehzor/ui-components';
import './EditingProblemTagsSet.less';
import {extractProblemTagsSetById} from '@src/store/modules/dictionaries/problemTagsSets/selectors';
import {useEditableProblemTagsSet} from '../EditableProblemTagsSet/hooks/useEditableProblemTagsSet';

interface IEditingProblemTagsSetProps {
	problemTagsSetId?: string;
}

export const EditingProblemTagsSet = ({problemTagsSetId}: IEditingProblemTagsSetProps) => {
	const problemTagsSet = useAppSelector(s => extractProblemTagsSetById(s, problemTagsSetId));

	const [setFields, tagFields]
		= useEditableProblemTagsSet({
			problemTagsSet
		});

	return (
		<div className="editing-problem-tags-set__container">
			<Plate className="editing-problem-tags-set__info-grid">{setFields}</Plate>
			<Plate className="editing-problem-tags-set__items-grid">{tagFields}</Plate>
		</div>
	);
};
