import React, {useCallback, useEffect, useState} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {exportProblems} from '@src/store/modules/entities/problems/actions';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {exportSpace} from '@src/store/modules/entities/space/actions';
import {exportInspections} from '@src/store/modules/entities/inspections/actions';
import {exportChecks} from '@src/store/modules/entities/checks/actions';
import {exportInspection} from '@src/store/modules/entities/inspection/actions';
import {exportCheck} from '@src/store/modules/entities/check/actions';
import {exportWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';

function useDestinations(selectedEntities: ISpaceEntity[]) {
	const [destinations, setDestinations] = useState<ExportTemplatesDestinationId[]>([
		ExportTemplatesDestinationId.SPACE
	]);

	useEffect(() => {
		const problemsArr: ISpaceEntity[] = [];
		const warrantyClaimsArr: ISpaceEntity[] = [];
		const checksArr: ISpaceEntity[] = [];
		const internalAcceptancesArr: ISpaceEntity[] = [];
		const ownerAcceptancesArr: ISpaceEntity[] = [];
		const inspectionsArr: ISpaceEntity[] = [];

		function allProblemsFromClaim(problems: ISpaceEntity[], claimId: string) {
			let result = true;
			problems.forEach(problem => {
				if (!problem.data.links) {
					result = false;
				} else if (problem.type === 'problem' && !problem.data.links.warrantyClaimId) {
					result = false;
				} else if (
					problem.type === 'problem' &&
					problem.data.links.warrantyClaimId !== claimId
				) {
					result = false;
				}
			});
			return result;
		}

		function allInspectionsFromCheck(inspections: ISpaceEntity[], checkId: string) {
			let result = true;
			inspections.forEach(inspection => {
				if (!inspection.data.links) {
					result = false;
				} else if (inspection.type === 'inspection' && !inspection.data.links.checkId) {
					result = false;
				} else if (
					inspection.type === 'inspection' &&
					inspection.data.links.checkId !== checkId
				) {
					result = false;
				}
			});
			return result;
		}

		selectedEntities.forEach(item => {
			switch (item.type) {
				case 'problem':
					problemsArr.push(item);
					break;
				case 'warranty-claim':
					warrantyClaimsArr.push(item);
					break;
				case 'inspection':
					inspectionsArr.push(item);
					break;
				case 'check':
					checksArr.push(item);
					break;
				case 'internal-acceptance':
					internalAcceptancesArr.push(item);
					break;
				case 'owner-acceptance':
					ownerAcceptancesArr.push(item);
			}
		});

		if (problemsArr.length === 1 && warrantyClaimsArr.length === 0) {
			return setDestinations([
				ExportTemplatesDestinationId.SPACE,
				ExportTemplatesDestinationId.PROBLEM
			]);
		}
		if (problemsArr.length > 0 && warrantyClaimsArr.length > 0) {
			if (warrantyClaimsArr.length > 1) {
				return setDestinations([
					ExportTemplatesDestinationId.SPACE,
					ExportTemplatesDestinationId.PROBLEMS
				]);
			}
			if (allProblemsFromClaim(problemsArr, warrantyClaimsArr[0].data.id)) {
				return setDestinations([
					ExportTemplatesDestinationId.SPACE,
					ExportTemplatesDestinationId.WARRANTY_CLAIM
				]);
			}
			return setDestinations([
				ExportTemplatesDestinationId.SPACE,
				ExportTemplatesDestinationId.PROBLEMS
			]);
		}
		if (problemsArr.length === 0 && warrantyClaimsArr.length === 1) {
			return setDestinations([
				ExportTemplatesDestinationId.SPACE,
				ExportTemplatesDestinationId.WARRANTY_CLAIM
			]);
		}
		if (inspectionsArr.length > 0 && checksArr.length > 1) {
			return setDestinations([
				ExportTemplatesDestinationId.SPACE,
				ExportTemplatesDestinationId.CHECKS
			]);
		}
		if (inspectionsArr.length > 0 && checksArr.length === 1) {
			if (allInspectionsFromCheck(inspectionsArr, checksArr[0].data.id)) {
				return setDestinations([
					ExportTemplatesDestinationId.SPACE,
					ExportTemplatesDestinationId.CHECK
				]);
			}
			return setDestinations([
				ExportTemplatesDestinationId.SPACE,
				ExportTemplatesDestinationId.INSPECTIONS
			]);
		}
		if (inspectionsArr.length === 1 && checksArr.length === 0) {
			return setDestinations([
				ExportTemplatesDestinationId.SPACE,
				ExportTemplatesDestinationId.INSPECTION
			]);
		}

		if (inspectionsArr.length === 0 && checksArr.length === 1) {
			return setDestinations([
				ExportTemplatesDestinationId.SPACE,
				ExportTemplatesDestinationId.CHECK
			]);
		}
		if (inspectionsArr.length > 1 && checksArr.length === 0) {
			return setDestinations([
				ExportTemplatesDestinationId.SPACE,
				ExportTemplatesDestinationId.INSPECTIONS
			]);
		}
		return undefined;
	}, [selectedEntities]);

	return destinations;
}

const splitSelectedEntities = (selectedEntities: ISpaceEntity[]) =>
	selectedEntities.reduce<{
		problems: string[];
		inspections: string[];
		warrantyClaims: string[];
		checks: string[];
		ownerAcceptances: string[];
		internalAcceptances: string[];
	}>(
		(prev, item) => {
			switch (item.type) {
				case 'problem':
					prev.problems.push(item.data.id);
					break;
				case 'inspection':
					prev.inspections.push(item.data.id);
					break;
				case 'warranty-claim':
					prev.warrantyClaims.push(item.data.id);
					break;
				case 'check':
					prev.checks.push(item.data.id);
					break;
				case 'internal-acceptance':
					prev.internalAcceptances.push(item.data.id);
					break;
				case 'owner-acceptance':
					prev.ownerAcceptances.push(item.data.id);
					break;
			}
			return prev;
		},
		{
			problems: [],
			inspections: [],
			warrantyClaims: [],
			checks: [],
			ownerAcceptances: [],
			internalAcceptances: []
		}
	);

/**
 * Хук для экспорта помещения или его проверок/приёмок/обращений/нарушений/осмотров в зависимости от того, что выбрано
 *
 * @param objectId id объекта
 * @param selectedEntities выбранные сущности
 */
export function useSpaceCombinedExport(
	objectId: string,
	selectedEntities: ISpaceEntity[]
): [React.ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const destinations = useDestinations(selectedEntities);
	const [isOpen, setOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const [, handleExport] = useAsyncFn(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			const {problems, inspections, warrantyClaims, checks, ownerAcceptances} =
				splitSelectedEntities(selectedEntities);

			if (template.destination === ExportTemplatesDestinationId.SPACE) {
				if (exportingId) {
					await dispatch(
						exportSpace(
							template.id,
							createDocument,
							email,
							objectId,
							exportingId,
							problems,
							inspections,
							warrantyClaims,
							checks,
							ownerAcceptances
						)
					);
				}
			} else if (template.destination === ExportTemplatesDestinationId.PROBLEMS) {
				const selected = selectedEntities
					.filter(item => item.type === 'problem')
					.map(item => item.data.id);
				await dispatch(
					exportProblems(
						template.id,
						objectId,
						createDocument,
						email,
						undefined,
						undefined,
						selected
					)
				);
			} else if (template.destination === ExportTemplatesDestinationId.CHECKS) {
				const selected = selectedEntities
					.filter(item => item.type === 'check')
					.map(item => item.data.id);
				await dispatch(
					exportChecks(
						template.id,
						createDocument,
						email,
						objectId !== 'all' ? objectId : undefined,
						undefined,
						undefined,
						selected
					)
				);
			} else if (template.destination === ExportTemplatesDestinationId.CHECK) {
				await dispatch(
					exportCheck(
						template.id,
						createDocument,
						email,
						objectId,
						checks[0],
						undefined,
						inspections
					)
				);
			} else if (template.destination === ExportTemplatesDestinationId.INSPECTIONS) {
				const selected = selectedEntities
					.filter(item => item.type === 'inspection')
					.map(item => item.data.id);
				await dispatch(
					exportInspections(
						template.id,
						createDocument,
						email,
						objectId,
						undefined,
						undefined,
						selected
					)
				);
			} else if (template.destination === ExportTemplatesDestinationId.INSPECTION) {
				await dispatch(
					exportInspection(template.id, createDocument, email, objectId, inspections[0])
				);
			} else if (template.destination === ExportTemplatesDestinationId.WARRANTY_CLAIM) {
				await dispatch(
					exportWarrantyClaim(
						template.id,
						createDocument,
						email,
						objectId,
						warrantyClaims[0],
						problems
					)
				);
			}
			setOpen(false);
		},
		[objectId, exportingId, selectedEntities]
	);

	const dialog = (
		<ExportDialog
			pageKey="space"
			destinations={destinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
