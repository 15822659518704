import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {IContract} from '@tehzor/tools/interfaces/contracts';
import {IPreparedContract} from '../interfaces/IPreparedContract';
import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

export const convertContract = (
	contract: IContract | undefined,
	companies: ICompany[],
	legalsMap: Record<string, ILegalEntity>,
	bankAccountsMap: Record<string, IBankAccount>,
	authorizedPersonsMap: Record<string, IAuthorizedPerson>
): IPreparedContract | undefined =>
	contract
		? {
				...contract,
				company: companies?.find(item => item.uuid === contract.companyId),
				contractor: companies?.find(item => item.uuid === contract.contractorId),
				companyLegalEntity: contract.companyLegalEntityId
					? legalsMap[contract.companyLegalEntityId]
					: undefined,
				contractorLegalEntity: contract.contractorLegalEntityId
					? legalsMap[contract.contractorLegalEntityId]
					: undefined,
				companyBankAccount: contract.companyBankAccountId
					? bankAccountsMap[contract.companyBankAccountId]
					: undefined,
				contractorBankAccount: contract.contractorBankAccountId
					? bankAccountsMap[contract.contractorBankAccountId]
					: undefined,
				companyAuthorizedPerson: contract.companyAuthorizedPersonId
					? authorizedPersonsMap[contract.companyAuthorizedPersonId]
					: undefined,
				contractorAuthorizedPerson: contract.contractorAuthorizedPersonId
					? authorizedPersonsMap[contract.contractorAuthorizedPersonId]
					: undefined
		  }
		: undefined;
