import React, {memo, useRef} from 'react';
import {useVirtualizer} from '@tanstack/react-virtual';
import ReasonSelectCheckbox from './ReasonSelectCheckbox';
import ReasonSelectLink from './ReasonSelectLink';
import classNames from 'classnames';

interface IRegStandard {
	id: string;
	number: number;
	name: string;
}

interface IEnhancedRegStandard extends IRegStandard {
	fullName: string;
}

interface IReasonSelectListProps {
	items: IEnhancedRegStandard[];
	regStandardId?: string;
	externalLink?: string;

	onItemSelect(regStandardId: string, label: string): void;
}

export const ReasonSelectList = memo((props: IReasonSelectListProps) => {
	const {items, regStandardId, externalLink, onItemSelect} = props;
	const parentRef = useRef<HTMLDivElement>(null);

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 45
	});

	const virtualItems = rowVirtualizer.getVirtualItems();

	/**
	 * Обработка выбора элемента списка
	 *
	 * @param regStandardId
	 * @param label
	 */
	const handleSelect = (regStandardId: string, label: string) => {
		onItemSelect(regStandardId, label);
	};

	/**
	 * Рендер варианта отсутствия строк
	 */
	const noRowsRenderer = () => (
		<div className="reason-select__not-found">В реестре сводов правил ничего не найдено</div>
	);

	if (!items.length) {
		return noRowsRenderer();
	}

	return (
		<div
			ref={parentRef}
			className="reason-select__standards-list"
		>
			<div
				style={{height: rowVirtualizer.getTotalSize()}}
				className="reason-select__standards-list-virtualized"
			>
				<div
					style={{transform: `translateY(${virtualItems[0].start}px)`}}
					className="reason-select__standards-list-virtualized-content"
				>
					{virtualItems.map(virtualRow => {
						const item = items[virtualRow.index];

						return (
							<div
								key={virtualRow.key}
								data-index={virtualRow.index}
								ref={rowVirtualizer.measureElement}
								className={classNames('reason-select__standard-item', {
									'reason-select__standard-item_active': item.id === regStandardId
								})}
							>
								<ReasonSelectCheckbox
									id={item.id}
									label={item.fullName}
									checked={item.id === regStandardId}
									onChange={handleSelect}
								/>
								<ReasonSelectLink
									externalLink={externalLink}
									num={item.number}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
});
