import React, {useCallback} from 'react';
import './OfflineModeDialog.less';
import {Dialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineStatus} from '@src/store/modules/offlineMode/selectors';
import {offlineModeTitles} from '@src/core/offlineMode/utils/offlineModeTitles';
import {offlineModeIcons} from '@src/core/offlineMode/utils/offlineModeIcons';
import {offlineModeDescriptions} from '@src/core/offlineMode/utils/offlineModeDescriptions';
import {EntitiesCache} from './components/EntitiesCache';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleOfflineDialogVisibility} from '@src/store/modules/app/offlineDialog/actions';
import {cacheStatus} from '../OfflineModeBar/utils/cacheStatus';
import {OfflineModeSettings} from './components/OfflineModeSettings';

export const OfflineModeDialog = () => {
	const visible = useAppSelector(s => s.app.offlineDialog.visible);
	const status = useAppSelector(extractOfflineStatus);
	const settings = useAppSelector(s => s.settings.offlineMode.cache);
	const queriesFetchStatus = cacheStatus();
	const dispatch = useAppDispatch();

	const description = offlineModeDescriptions[status];

	const handleClose = useCallback(() => {
		dispatch(toggleOfflineDialogVisibility(false));
	}, []);

	return (
		<Dialog
			className="offline-mode-dialog"
			title={(
				<div className="offline-mode-dialog__title-bar">
					<i
						className={offlineModeIcons[queriesFetchStatus] || offlineModeIcons[status]}
					/>
					<div className="offline-mode-dialog__title">
						{offlineModeTitles[queriesFetchStatus] || offlineModeTitles[status]}
					</div>
				</div>
			)}
			fullScreenOnMobile
			isOpen={visible}
			onRequestClose={handleClose}
		>
			{description ? (
				<div className="offline-mode-dialog__description">{description}</div>
			) : null}
			<OfflineModeSettings/>
			{settings.objects?.length ? (
				<EntitiesCache/>
			) : (
				<div className="offline-mode-dialog__info">
					Выберите объекты, для которых хотите закешировать данные
				</div>
			)}
		</Dialog>
	);
};
