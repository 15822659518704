import {requestProblem} from '@src/api/backend/problem';
import {requestProblems} from '@src/api/backend/problems';
import {QueryFunctionContext, QueryKey, useQueryClient} from '@tanstack/react-query';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import {IProblemsListQueryKey, problemsQueryKeys} from '../keys';

export const useProblemsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(problemsQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IProblemsListQueryKey>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestProblems(filters, sort, offset, pageSize);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(problemsQueryKeys.details(), {
		queryFn: async ({queryKey}) => requestProblem(queryKey[3] as string, queryKey[2] as string),
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 5 // 5 Дней
	});
	queryClient.setQueryDefaults(problemsQueryKeys.localList(), {
		queryFn: async ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localProblems = await new Promise<Array<[QueryKey, IListProblem | undefined]>>(
				resolve => {
					const local = queryClient.getQueriesData<IListProblem>({
						queryKey: problemsQueryKeys.details(),
						predicate: ({state}) => {
							const {data} = state;
							if ((data as IListProblem).transferStatus) {
								if (!objectId || objectId === 'all') {
									return true;
								}
								if (objectId === (data as IListProblem).objectId) {
									return true;
								}
							}
							return false;
						}
					});
					resolve(local);
				}
			);
			return localProblems.map(([, problem]) => problem) as IListProblem[];
		},
		staleTime: 0,
		cacheTime: Infinity,
		networkMode: 'always'
	});
};
