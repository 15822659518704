import React from 'react';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

interface ISelectionsActionsProps {
	selectedEntities: IEnrichedTask[];
	selectedClearing: () => void;
}

const SelectionActions = ({selectedEntities, selectedClearing}: ISelectionsActionsProps) => {
	const perms = useTasksPermissions();

	return (
		<>
			{perms.canDelete && (
				<SelectedEntitiesDeletion
					selectedEntities={selectedEntities}
					selectedClearing={selectedClearing}
				/>
			)}
		</>
	);
};

export default SelectionActions;
