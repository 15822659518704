import {requestInspection} from '@src/api/backend/inspection';
import {requestInspections} from '@src/api/backend/inspections';
import {QueryKey, useQueryClient} from '@tanstack/react-query';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {inspectionsQueryKeys} from '../keys';

export const useInspectionsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(inspectionsQueryKeys.list(), {
		queryFn: async ({queryKey}) =>
			requestInspections(
				queryKey[2] as Record<string, unknown>,
				queryKey[3] as Record<string, boolean>,
				queryKey[4] as number,
				queryKey[5] as number
			),
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(inspectionsQueryKeys.details(), {
		queryFn: async ({queryKey}) =>
			requestInspection(queryKey[3] as string, queryKey[2] as string),
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(inspectionsQueryKeys.localList(), {
		queryFn: async ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localInspections = await new Promise<
				Array<[QueryKey, IListInspection | undefined]>
			>(resolve => {
				const local = queryClient.getQueriesData<IListInspection>({
					queryKey: inspectionsQueryKeys.details(),
					predicate: ({state}) => {
						const {data} = state;
						if ((data as IListInspection).transferStatus) {
							if (!objectId || objectId === 'all') {
								return true;
							}
							if (objectId === (data as IListInspection).objectId) {
								return true;
							}
						}
						return false;
					}
				});
				resolve(local);
			});
			return localInspections.map(([, inspection]) => inspection) as IListInspection[];
		},
		staleTime: 0,
		cacheTime: Infinity,
		networkMode: 'always'
	});
};
