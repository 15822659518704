import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {ICheckListType} from '@tehzor/tools/interfaces/checkLists/ICheckListType';

export type ICheckListTypesState = ILoadedEntityState<ICheckListType>;

export default createReducer<ICheckListTypesState>(
	getLoadedEntitiesInitialState<ICheckListType>(),
	{
		[types.GET_REQUEST]: getLoadedEntitiesInitialState,
		[types.GET_SUCCESS]: getLoadedEntitiesSuccess<ICheckListType>()
	}
);
