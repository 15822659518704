import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';

interface IPageBreadcrumbsProps {
	children?: React.ReactNode;
}

export const AllObjectPageBreadcrumbs = memo(({children}: IPageBreadcrumbsProps) => (
	<AppBreadcrumbs
		className="internal-acceptances-page__breadcrumbs"
		items={[]}
	>
		{children}
	</AppBreadcrumbs>
));
