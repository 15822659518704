import React from 'react';
import {EntityGridItem, StatusSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IPopupBasicProps} from '@tehzor/ui-components/src/components/containers/Popup';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {editTaskStatus} from '@src/store/modules/pages/task/actions/editTaskStatus';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {getTasks} from '@src/store/modules/entities/tasks/actions';

const popupProps: IPopupBasicProps = {placement: 'bottom-end', preferX: 'left'};

interface IStatusProps {
	task: IEnrichedTask;
}

export const TaskStatusGrid = ({task}: IStatusProps) => {
	const statuses = useAppSelector(s => s.dictionaries.taskStatuses);
	const dispatch = useAppDispatch();
	const permissions = useTasksPermissions(task.objectId?.id);

	const [, handleChange] = useAsyncFn(
		async (statusId: TaskStatusId) => {
			await dispatch(editTaskStatus(task.id, statusId));
			await dispatch(getTasks());
		},
		[task]
	);

	const availableStatuses = Object.values(TaskStatusId);

	return (
		<EntityGridItem
			className="task-view-page__status"
			icon={<i className="tz-status-20"/>}
			label="Статус"
			inline
			fullRow
		>
			<StatusSelect
				statuses={statuses}
				availableStatuses={availableStatuses}
				disabled={!permissions.canEditStatus}
				value={task?.status?.id}
				popupProps={popupProps}
				onChange={handleChange}
			/>
		</EntityGridItem>
	);
};
