import * as types from './constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetExportTemplatesDestinationsResponse,
	requestExportTemplatesDestinations
} from '@src/api/backend/exportTemplatesDestinations';
import {checkExpiration} from '@tehzor/tools/core/storeHelpers/actions';

export type IGetExportTemplatesDestinationsPayload = IGetExportTemplatesDestinationsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetExportTemplatesDestinationsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке типов шаблонов экспорта');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список типов шаблонов экспорта
 */
export const getExportTemplatesDestinations = () =>
	checkExpiration<IState, IGetExportTemplatesDestinationsResponse, ApiAction>(
		state => state.dictionaries.exportTemplatesDestinations,
		createApiAction<IGetExportTemplatesDestinationsResponse>(
			request,
			success,
			failure,
			requestExportTemplatesDestinations
		),
		1209600000 // 2 недели
	);
