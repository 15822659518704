import React, {useCallback} from 'react';
import './PaginationPageSize.less';
import PopupMenu from '../../menu/PopupMenu';
import LinkButton from '../../buttons/LinkButton';
import Menu from '../../menu/Menu';
import MenuItem from '../../menu/MenuItem';
import classNames from 'classnames';
import {IPopupBasicProps} from '../../containers/Popup';

const popupProps: IPopupBasicProps = {
	placement: 'top-center',
	preferX: 'left',
	possiblePlacements: ['top-center', 'bottom-center', 'left-center', 'left-start', 'left-end']
};

interface IPaginationPageSizeProps {
	className?: string;
	style?: React.CSSProperties;
	pageSize?: number;
	pageSizeOptions: number[];

	onPageSizeChange: (pageSize: number) => void;
}

export const PaginationPageSize = (props: IPaginationPageSizeProps) => {
	const {
		className, style, pageSize = 20, pageSizeOptions, onPageSizeChange
	} = props;

	const handleChange = useCallback(
		(size: string) => {
			onPageSizeChange(+size);
		},
		[onPageSizeChange]
	);

	return (
		<div
			className={classNames('pagination-page-size', className)}
			style={style}
		>
			Показывать по
			{' '}

			<PopupMenu
				trigger={(
					<LinkButton
						className="pagination-page-size__link"
						label={pageSize}
					/>
				)}
				popupProps={popupProps}
				arrowVisible
			>
				<Menu
					value={pageSize?.toString()}
					onChange={handleChange}
				>
					{pageSizeOptions.map(num => (
						<MenuItem
							key={num.toString()}
							itemKey={num.toString()}
						>
							{num}
						</MenuItem>
					))}
				</Menu>
			</PopupMenu>

			{' '}
			на страницу
		</div>
	);
};
