import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditSpaceStatusResponse,
	makeSpaceStatusEditRequest
} from '@src/api/backend/space/editStatus';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IEditSpaceStatusPayload = IEditSpaceStatusResponse;

const request = () => ({type: types.EDIT_STATUS_REQUEST});

const success = (response: IEditSpaceStatusResponse, pageObjectId: string) => ({
	type: types.EDIT_STATUS_SUCCESS,
	payload: {...response, pageObjectId}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса помещения');
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

export const editSpaceStatusActions = {failure, success};

/**
 * Изменяет статус помещения
 *
 * @param objectId id объекта
 * @param pageObjectId id объекта страницы
 * @param spaceId id помещения
 * @param status статус
 */
export const editSpaceStatus = (
	objectId: string, pageObjectId: string, spaceId: string, status: string
) =>
	createApiAction<IEditSpaceStatusResponse>(
		request,
		response => success(response, pageObjectId),
		failure,
		() => {
			void queryClient.invalidateQueries(spacesQueryKeys.list());
			void queryClient.invalidateQueries([...spacesQueryKeys.detail(spaceId), objectId]);

			return makeSpaceStatusEditRequest(objectId, spaceId, status);
		}
	);
