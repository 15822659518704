import React from 'react';
import EntitiesFilters, {CreationDateFilter} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ILegalsFiltersState} from '@src/store/modules/settings/pages/legals/reducers';
import {CompaniesFilter} from '@src/components/EntitiesFilters/components/CompaniesFilter';

export const LegalsFilters = () => {
	const {state} = useEntitiesFiltersCtx<ILegalsFiltersState>();

	return (
		<div className="legals__filters">
			<EntitiesFilters objectId="all" entity="legals">
				<CompaniesFilter companies={state.companies} isUUID />

				{/*  Todo: сделать чтобы работало  */}
				{/* <CreatedByFilter objectId="all" createdBy={state.createdBy} /> */}

				<CreationDateFilter from={state.createdAtFrom} to={state.createdAtTo} />
			</EntitiesFilters>
		</div>
	);
};
