import React, {useCallback, useState} from 'react';
import './EditableWorkAcceptanceDialog.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {addWorkAcceptance, editWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableWorkAcceptance} from '@src/components/EditableWorkAcceptance/hooks/useEditableWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IEditableWorkAcceptanceDialogProps {
	objectId: string;
	stage: ObjectStageIds;
	workAcceptance?: IWorkAcceptance;
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

/**
 * Окно редактирования приёмки работ
 */
 export const EditableWorkAcceptanceDialog = (
	{objectId, stage, workAcceptance, isOpen, onClose, onSuccess}: IEditableWorkAcceptanceDialogProps
) => {
	const workAcceptanceId = workAcceptance?.id;
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableWorkAcceptance(
		objectId,
		stage,
		workAcceptance || undefined,
		saving
	);

	const save = useCallback(async () => {
		setSaving(true);
		const savingData = await getSavingData();
		if (savingData && workAcceptanceId) {
			try {
				await dispatch(editWorkAcceptance(objectId, workAcceptanceId, savingData));
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		if (savingData && !workAcceptanceId) {
			try {
				await dispatch(addWorkAcceptance(objectId, savingData));
				if (onSuccess) {
					void onSuccess();
				}
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		setSaving(false);
	}, [objectId, workAcceptanceId, getSavingData, dispatch, onClose]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation]);

	return (
		<Dialog
			className="editable-work-acceptance-dialog"
			isOpen={isOpen}
			title={workAcceptanceId ? 'Редактирование приёмки работ' : 'Добавление приёмки работ'}
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
