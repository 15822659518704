import {wsConnector} from '../wsConnector';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';

export type IEditSpaceStatusResponse = ISpaceStatus;

/**
 * Изменяет статус помещения
 * 
 * @param spaceStatusId id статуса помещения
 * @param fields поля для статуса помещения
 */
export const requestEditSpaceStatus = (
	spaceStatusId: string,
	fields: ISavingSpaceStatus
) =>
	wsConnector.sendAuthorizedRequest<IEditSpaceStatusResponse>('editSpaceStatus', {
		...fields,
		id: spaceStatusId
	});
