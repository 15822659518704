import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState, ICheckState} from './reducers';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {ILocalEntitiesState} from '@src/reducers/local-entities';

const extractCheckData = (state: IState): ICheckState => state.entities.check || getInitialState();

const extractLocalCheckData = (state: IState): ILocalEntitiesState => state.localEntities;

/**
 * Извлекает текущую проверку
 */
export const extractCheck = createSelector([extractCheckData], check => check.data);

/**
 * Извлекает нарушения проверки
 */
export const extractCheckProblems = createSelector([extractCheckData], data =>
	data.problems.allIds.map(id => data.problems.byId[id]));

/**
 * Извлекает локальные нарушения проверки
 */
export const extractLocalCheckProblems = createSelector([extractLocalCheckData], data =>
	data.problems);

/**
 * Извлекает локальные осмотры проверки
 */
export const extractLocalCheckInspections = createSelector([extractLocalCheckData], data =>
	data.inspections);

/**
 * Извлекает осмотры проверки
 */
export const extractCheckInspections = createSelector([extractCheckData], data =>
	data.inspections.allIds.map(id => data.inspections.byId[id]));

/**
 * Извлекает ответы на нарушения проверки
 */
export const extractCheckProblemReplies = createSelector([extractCheckData], data =>
	data.problemReplies.allIds.map(id => data.problemReplies.byId[id]));

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractCheckProblemCommentsByProblem = createSelector(
	[extractCheckProblemReplies],
	replies =>
		replies.reduce<Record<string, IProblemComment>>((prev, item) => {
			if (!prev[item.problemId]) {
				prev[item.problemId] = item;
			}
			return prev;
		}, {})
);

/**
 * Извлекает нарушения и осмотры единым списком с учетом фильтра
 */
export const extractCheckEntities = createSelector(
	[
		extractCheckProblems,
		extractLocalCheckProblems,
		extractCheckInspections,
		extractLocalCheckInspections,
		state => state.settings.pages.check.entitiesVisibility
	],
	(problems, localProblems, inspections, localInspections, visibility) => {
		let result: ICheckEntity[] = [];
		if (visibility.includes('problems') && problems.length) {
			result = problems.map(item => ({
				id: `p_${item.id}`,
				type: 'problem',
				data: item
			}));
		}
		if (visibility.includes('problems') && localProblems.length > 0) {
			result = result.concat(
				localProblems.map(item => ({
						id: `p_${item.key}`,
						type: 'local_problem',
						data: item
					}))
			);
		}
		if (visibility.includes('inspections') && inspections.length) {
			result = result.concat(
				inspections.map(item => ({
					id: `i_${item.id}`,
					type: 'inspection',
					data: item
				}))
			);
		}
		if (visibility.includes('inspections') && localInspections.length > 0) {
			result = result.concat(
				localInspections.map(item => ({
					id: `i_${item.key}`,
					type: 'local_inspection',
					data: item
				}))
			);
		}
		return result.sort((a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0));
	}
);
