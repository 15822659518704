import {
	DownloadableFile,
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	Plate
} from '@tehzor/ui-components';
import React, {memo} from 'react';
import {IPreparedContract} from '../interfaces/IPreparedContract';
import {requestGetContractAttachment} from '@src/api/backend/contract/getAttachment';
import saveOrOpenBlob from '@tehzor/tools/utils/saveOrOpenBlob';

interface IContractInfoProps {
	contract: IPreparedContract;
}

const nameIcon = <i className="tz-name-20" />;
const numberIcon = <i className="tz-number-20" />;
const attachmentIcon = <i className="tz-attach-20" />;
const createdIcon = <i className="tz-created-20" />;
const modifiedIcon = <i className="tz-modified-20" />;

export const ContractInfo = memo(({contract}: IContractInfoProps) => {
	const handleClick = async () => {
		if (contract.fileKey) {
			const res = await requestGetContractAttachment(contract.id, contract.fileKey);

			saveOrOpenBlob(res.fileContent, res.fileName);
		}
	};

	return (
		<Plate className="contract__info">
			<EntityGrid withBorders>
				<EntityGridItem
					className={{
						root: 'contract__info-item',
						header: 'contract__info-item-title'
					}}
					label="Название"
					icon={nameIcon}
					fullRow
				>
					{contract.name}
				</EntityGridItem>

				{contract.number && (
					<EntityGridItem
						className={{
							root: 'contract__info-item',
							header: 'contract__info-item-title'
						}}
						label="Номер"
						icon={numberIcon}
						fullRow
					>
						{contract.number}
					</EntityGridItem>
				)}

				{contract?.fileKey && contract?.fileName && contract?.fileType && (
					<EntityGridItem
						className={{
							root: 'contract__info-item',
							header: 'contract__info-item-title'
						}}
						label="Вложение"
						icon={attachmentIcon}
						fullRow
					>
						<DownloadableFile
							name={contract.fileName}
							type={contract.fileType}
							size={Number(contract.fileSize)}
							onClick={handleClick}
						/>
					</EntityGridItem>
				)}

				<EditorInfo
					className={{
						root: 'contract__info-item',
						header: 'contract__info-item-title'
					}}
					date={contract.createdAt}
					user={contract.createdBy}
					label="Создано"
					icon={createdIcon}
					fullRow
				/>

				{(contract.modifiedAt || contract.modifiedBy) && (
					<EditorInfo
						className={{
							root: 'contract__info-item',
							header: 'contract__info-item-title'
						}}
						date={contract.modifiedAt}
						user={contract.modifiedBy}
						label="Изменено"
						icon={modifiedIcon}
						fullRow
					/>
				)}
			</EntityGrid>
		</Plate>
	);
});
