import React, {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {
	FilterPage,
	LinkButton,
	Select2,
	SelectOption,
	SelectSearch,
	TabLink,
	Tabs,
	TreeSelect,
	TreeSelectOption
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	flatFilter,
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {
	extractUsersAsArray,
	extractUsersAsMap
} from '@src/store/modules/dictionaries/users/selectors';
import './RespUsersFilterPage.less';
import {useUpdateEffect} from 'react-use';
import {extractPerformersGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {
	ITreeUserGroupsItem,
	ITreeUserGroupsItemType,
	makeUserGroupsTreeData
} from '@src/components/EntitiesFilters/utils/makeUserGroupsTreeData';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangePath} from '@src/core/hooks/useChangePath';

const links = [<TabLink label="По пользователям"/>, <TabLink label="По рабочим группам"/>];

const getContent = (data: ITreeUserGroupsItem) => (
	<div className="resp-users-filter-page__item">
		<div className="resp-users-filter-page__item-content">{data.content}</div>
		<div className="resp-users-filter-page__item-additional-content">
			{data.additionalContent}
		</div>
		{data.type === ITreeUserGroupsItemType.LEADER && (
			<div className="resp-users-filter-page__item-leader">Руководитель</div>
		)}
	</div>
);

const RespUsersFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId?: string, entity?: string}>();
	const {goBack} = useChangePath();
	const canGoBack = (useLocation().state as {canGoBack?: boolean} | null)?.canGoBack;

	const {state, change} = useEntitiesFiltersCtx<{respUsers?: string[]}>();
	const [selectedUsers, setSelectedUsers] = useState(state.respUsers);
	const [expandedUsers, setExpandedUsers] = useState<string[]>([]);
	const [search, setSearch] = useState('');
	useUpdateEffect(() => setSelectedUsers(state.respUsers), [state.respUsers]);

	const [tabIndex, setTabIndex] = useState(0);
	const clearSearch = useCallback(() => setSearch(''), []);

	const users = useAppSelector(extractUsersAsArray);
	const filteredData = useMemo(() => {
		if (tabIndex !== 0) {
			return undefined;
		}

		return flatFilter(users, 'fullName', search);
	}, [tabIndex, users, search]);

	const usersMap = useAppSelector(extractUsersAsMap);
	const groups = useAppSelector(s => extractPerformersGroupsAsArray(s, objectId));
	const treeData = useMemo(() => {
		if (tabIndex !== 1) {
			return [];
		}

		return makeUserGroupsTreeData(groups, usersMap);
	}, [tabIndex, groups, usersMap]);
	const {treeFilteredData, expanded} = useMemo(() => {
		if (tabIndex !== 1) {
			return {};
		}

		const {filteredData: treeFilteredData, expanded} = treeFilter(treeData, 'name', search);
		return {treeFilteredData, expanded};
	}, [tabIndex, treeData, search]);

	useUpdateEffect(() => {
		if (expanded) {
			setExpandedUsers(expanded.map(item => item.id));
		}
	}, [expanded]);

	const handleApply = useCallback(() => {
		change({respUsers: selectedUsers});
		goBack();
	}, [selectedUsers, change]);

	const handleChange = useCallback((v?: string[]) => {
		setSelectedUsers(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedUsers([]);
		clearSearch();
	}, []);

	const handleChangeTab = useCallback(
		(index: number) => {
			handleClear();
			setTabIndex(index);
		},
		[handleClear]
	);

	useAppHeader(
		{
			title: 'Ответственные',
			showBackBtn: true,
			mobileRightButtons: selectedUsers?.length ? (
				<LinkButton
					label="Сбросить"
					onClick={handleClear}
				/>
			) : null
		},
		[selectedUsers]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={isLocalStateEqual(state.respUsers, selectedUsers)}
			onApplyClick={handleApply}
			tabs={(
				<Tabs
					links={links}
					activeTab={tabIndex}
					onActiveTabChange={handleChangeTab}
				/>
			)}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
		>
			<>
				{tabIndex === 0 && filteredData !== undefined && (
					<Select2
						multiple
						value={selectedUsers}
						onChange={handleChange}
					>
						{filteredData?.map(item => (
							<SelectOption
								key={item.id}
								itemKey={item.id}
								content={item.fullName}
							/>
						))}
					</Select2>
				)}
				{tabIndex === 1 && treeFilteredData !== undefined && (
					<TreeSelect
						data={treeFilteredData}
						multiple
						value={selectedUsers}
						onChange={handleChange}
						expandedValue={expandedUsers}
						onExpand={setExpandedUsers}
						TreeItem={props => (
							<TreeSelectOption
								{...props}
								getContent={getContent}
							/>
						)}
					/>
				)}
			</>
		</FilterPage>
	);
};

export default RespUsersFilterPage;
