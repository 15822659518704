import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {IEditCheckItemsResponse, makeCheckItemsEditRequest} from '@src/api/backend/checkItems';

export type IEditCheckItemsPayload = IEditCheckItemsResponse;

const request = () => ({type: types.EDIT_BATCH_REQUEST});

const success = (checkListId: string, response: IEditCheckItemsResponse) => ({
	type: types.EDIT_BATCH_SUCCESS,
	payload: {items: response, checkListId}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении категории');
	return {
		type: types.EDIT_BATCH_FAILURE,
		payload: error
	};
};

/**
 * Обновляет несколько категорий за раз
 *
 * @param checkListId идентификатор чек-листа
 * @param fields данные для добавления
 */
export const editCheckItems = (checkListId: string, fields: ISavingCheckItem[]) =>
	createApiAction<IEditCheckItemsResponse>(
		request,
		(res: IEditCheckItemsResponse) => success(checkListId, res),
		failure,
		() => makeCheckItemsEditRequest(checkListId, fields)
	);
