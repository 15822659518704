import {
	IDeleteCategoriesSetResponse,
	requestDeleteCategoriesSet
} from '@src/api/backend/categoriesSets';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';

export interface IDeleteCategoriesSetPayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => {
	addSuccessToast('Удалено', 'Набор работ успешно удален');
	return {
	type: types.DELETE_SUCCESS,
	payload: {id}
};
};

const failure = (err: IError) => {
	addErrorToast('Ошибка', err.message);
	return {
	type: types.DELETE_FAILURE,
	payload: err
};
};

export const deleteCategoriesSet = (categoriesSetId: string) =>
	createApiAction<IDeleteCategoriesSetResponse>(
		request,
		() => success(categoriesSetId),
		failure,
		() => requestDeleteCategoriesSet(categoriesSetId)
	);
