import {EntityGrid, EntityGridItem, InlineButton, Plate} from '@tehzor/ui-components';
import React from 'react';
import {ProblemIndicators} from '../ProblemIndicators';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {TaskIndicators} from '@src/pages/WorkAcceptancePage/components/TaskIndicators';

const editIcon = <i className="tz-edit-20"/>;
const percentIcon = <i className="tz-percent-20"/>;
const unitIcon = <i className="tz-unit-20"/>;
const problemIcon = <i className="tz-problem-20"/>;
const taskIcon = <i className="tz-task-24"/>;
const commentIcon = <i className="tz-comment-20"/>;

interface IDesktopInfoProps {
	openDialog: () => void;
	unitString?: string;
	workAcceptance: IWorkAcceptance;
}

export const DesktopInfo = ({
	openDialog, unitString, workAcceptance
}: IDesktopInfoProps) => (
	<div className="work-acceptance-page__d-column work-acceptance-page__d-column_info">
		<Plate className={{content: 'work-acceptance-page__info-plate'}}>
			<EntityGrid withBorders>
				<EntityGridItem
					label="Результат"
					className="work-acceptance-page__d-column_result-title"
					fullRow
					buttons={(
						<InlineButton
							type="accent"
							leftIcon={editIcon}
							onClick={openDialog}
						/>
					)}
				/>

				<EntityGridItem
					icon={percentIcon}
					label="Объем принятых работ"
					fullRow
				>
					<div
						className="semi-bold"
						style={{whiteSpace: 'pre-wrap'}}
					>
						{workAcceptance.percent ?? 0}
						{' '}
						%
					</div>
				</EntityGridItem>

				{unitString ? (
					<EntityGridItem
						icon={unitIcon}
						label="Физический объем работ"
						fullRow
					>
						<div
							className="semi-bold"
							style={{whiteSpace: 'pre-wrap'}}
						>
							{unitString}
						</div>
					</EntityGridItem>
				) : null}

				<EntityGridItem
					icon={problemIcon}
					label="Выявлено нарушений"
					fullRow
				>
					<ProblemIndicators workAcceptanceId={workAcceptance.id}/>
				</EntityGridItem>
				<EntityGridItem
					icon={taskIcon}
					label="Создано задач"
					fullRow
				>
					<TaskIndicators/>
				</EntityGridItem>

				<EntityGridItem
					icon={commentIcon}
					label="Комментарий"
					fullRow
				>
					<div style={{whiteSpace: 'pre-wrap'}}>{workAcceptance.comment}</div>
				</EntityGridItem>
			</EntityGrid>
		</Plate>
	</div>
);