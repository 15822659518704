import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

export const TaskTypeMobileCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const task = row.original;

	if (!task?.taskType) {
		return null;
	}
	return (
		<div className="tasks-page__m-table-cell-type">
			{task?.taskType?.name}
		</div>
	);
};
