import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {extractCheckRecordStatusesByEntityTypeAsArray} from '@src/store/modules/dictionaries/checkRecordStatuses/selectors';

interface ICheckListsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const CheckListStatusesFilterMobile = memo((props: ICheckListsFilterMobileProps) => {
	const CheckListsRecordStatuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityTypeAsArray(s, 'check-list'));

	return (
		<BaseListFilterMobile
			{...props}
			options={CheckListsRecordStatuses}
			label="Статусы чек-листов"
			filterName="checkListStatuses"
		/>
	);
});
