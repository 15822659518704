export enum WorkingGroupTypeId {
	PERFORMERS = 'performers',
	PROBLEM_INSPECTORS = 'problemInspectors',
	WORK_ACCEPTANCE_INSPECTORS = 'workAcceptanceInspectors'
}

export interface IWorkingGroupType {
	id: WorkingGroupTypeId;
	name: string;
}
