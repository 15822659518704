import React, {useMemo} from 'react';
import {CellProps} from 'react-table';
import {CriticalMarker} from '@src/components/CriticalMarker';
import {PhotoPreview, StatusSelect, TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useProblemStatusPermissions} from '@src/core/hooks/permissions/useProblemStatusPermissions';
import {editProblemStatus} from '@src/store/modules/pages/problem/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import {EntityTransfer} from '@src/components/EntityTransfer/EntityTransfer';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {extractProblemInspectorsGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IProblemSettings} from '../../utils/useProblemSettings';

interface IMobileProblemCell extends CellProps<IProblemEntity> {
	settings?: IProblemSettings;
	withObject?: boolean;
}

const MobileProblemCell = ({row, settings, withObject}: IMobileProblemCell) => {
	const {
		objectId,
		stage,
		id,
		status,
		plannedFixDate,
		number,
		localNumber,
		transferStatus,
		critical,
		description,
		plannedFixDateOriginal,
		inspectors,
		respUsers,
		activeGroup,
		object,
		attachment,
		problemTags,
		actualFixDateOriginal
	} = row.original.data;

	const statuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const inspectorsGroups = useAppSelector(s =>
		extractProblemInspectorsGroupsAsArray(s, objectId, stage));

	const dispatch = useAppDispatch();
	const permissions = useProblemStatusPermissions(objectId, row.original.data.data);
	const [, handleChange] = useAsyncFn(
		async (v: ProblemStatusId) => {
			await dispatch(editProblemStatus(objectId, id, v));
		},
		[objectId, id]
	);

	const firstInspector = inspectors?.[0];
	const userGroup = useMemo(() => {
		if (!firstInspector) { return undefined; }

		const groups: IWorkingGroup[] = [];
		for (const group of inspectorsGroups) {
			if (group.performers?.includes(firstInspector.id)) {
				groups.push(group);
			} else if (group.leader === firstInspector.id) {
				groups.push(group);
			}
		}

		return groups.length === 1 ? groups[0] : undefined;
	}, [inspectorsGroups, firstInspector]);

	const numberEl = (
		<div className="problems-page__m-table-description-number">
			№
			{number ?? localNumber}
		</div>
	);

	const criticalEl = !!critical && <CriticalMarker/>;

	const locationCommentsEl = !!row?.original?.data?.hasLocationComments && (
		<i className="tz-comment-on-plan-16"/>
	);

	const problemTagsEl = !!problemTags?.length && (
		<ProblemTagsMarker
			className="problems-page__m-table-tags"
			colors={problemTags}
		/>
	);

	const descriptionEl = (
		<div className="problems-page__m-table-description-text">{description}</div>
	);

	const plannedFixDateEl = !!plannedFixDate && (
		<div className="problems-page__m-table-plannedFixDate">
			<div>
				Срок устранения: &nbsp;
				{plannedFixDate}
			</div>
			{compareFixDate(actualFixDateOriginal, plannedFixDateOriginal) && (
				<FixDateTag
					plannedFixDate={plannedFixDateOriginal!}
					actualFixDate={actualFixDateOriginal}
				/>
			)}
		</div>
	);

	const respUsersEl = !!(activeGroup || respUsers?.length) && (
		<div className="problems-page__m-table-responsible">
			<div className="problems-page__m-table-responsible-title">
				Ответственные:
			</div>
			<div className="problems-page__m-table-responsible-text">
				{activeGroup && (
					<div className="problems-page__m-table-responsible-group">
						{activeGroup}
					</div>
				)}
				{respUsers?.[0] && (
					<div className="problems-page__m-table-responsible-user">
						{respUsers[0]}
					</div>
				)}
				{!!respUsers?.length && respUsers?.length > 1 && (
					<div className="problems-page__m-table-responsible-rest">
						+
						{respUsers.length - 1}
					</div>
				)}
			</div>
		</div>
	);

	const inspectorsEl = !!inspectors?.length && (
		<div className="problems-page__m-table-inspectors">
			<div className="problems-page__m-table-inspectors-title">
				Проверяющие:
			</div>
			<div className="problems-page__m-table-inspectors-text">
				{userGroup && (
					<div className="problems-page__m-table-inspectors-group">
						{userGroup.name}
					</div>
				)}
				{inspectors?.[0] && (
					<div className="problems-page__m-table-inspectors-user">
						{inspectors[0]?.displayName}
					</div>
				)}
				{!!inspectors?.length && inspectors?.length > 1 && (
					<div className="problems-page__m-table-inspectors-rest">
						+
						{inspectors.length - 1}
					</div>
				)}
			</div>
		</div>
	);

	const statusEl = (
		<StatusSelect
			className="problems-page__m-table-status-select"
			statuses={statuses}
			availableStatuses={permissions.availableStatuses}
			disabled={!permissions.canEditStatus}
			value={status.id}
			onChange={handleChange}
		/>
	);

	const transferEl = transferStatus && (
		<div className="problems-page__m-table-transfer-status">
			<EntityTransfer
				transferStatus={transferStatus}
				itemKey={id}
			/>
		</div>
	);

	const objectEl = (
		<div className="problems-page__m-table-object">{object?.name}</div>
	);

	const attachmentsEl = (
		<div className="problems-page__m-table-description-image">
			{attachment?.preview && (
				<PhotoPreview
					url={attachment.preview.url}
					data={0}
				/>
			)}
		</div>
	);

	return (
		<div className="problems-page__mobile-cell-info">
			<ClickPreventWrap className="problems-page__mobile-cell-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()}/>
			</ClickPreventWrap>
			<div>
				<div className="problems-page__m-table-description-header">
					{numberEl}
					{settings?.critical && criticalEl}
					{settings?.location && locationCommentsEl}
					{settings?.problemTags && problemTagsEl}
				</div>

				{settings?.description && descriptionEl}
				{settings?.plannedFixDate && plannedFixDateEl}
				{settings?.respUsers && respUsersEl}
				{settings?.inspectors && inspectorsEl}

				<ClickPreventWrap>
					{statusEl}
				</ClickPreventWrap>

				{transferEl}
				{!!withObject && objectEl}

			</div>
			{settings?.attachments && attachmentsEl}
		</div>
	);
};

export default MobileProblemCell;
