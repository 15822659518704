import {combineReducers} from 'redux';
import {categories} from './categories/reducers';
import {categoriesSets} from './categoriesSets/reducers';
import {checkLists} from './checkLists/reducers';
import {checkItems} from './checkItems/reducers';
import {checkRecordStatuses} from './checkRecordStatuses/reducers';
import {exportTemplates} from './exportTemplates/reducers';
import {exportTemplatesDestinations} from './exportTemplatesDestinations/reducers';
import {companies} from './companies/reducers';
import {objects} from './objects/reducers';
import {objectStages} from './objectStages/reducers';
import {ownerAcceptanceResolutions} from './ownerAcceptanceResolutions/reducers';
import {ownerAcceptanceStatuses} from './ownerAcceptanceStatuses/reducers';
import {plans} from './plans/reducers';
import {problemStatuses} from './problemStatuses/reducers';
import {problemTemplatesSets} from './problemTemplatesSets/reducers';
import {regulatoryStandards} from './regulatoryStandards/reducers';
import {responsibilityRules} from './responsibilityRules/reducers';
import {spaceIndicatorsSets} from './spaceIndicatorsSets/reducers';
import {spaceOwners} from './spaceOwners/reducers';
import {spaceStatusesSets} from './spaceStatusesSets/reducers';
import {spaceTypes} from './spaceTypes/reducers';
import {users} from './users/reducers';
import {warrantyClaimStatuses} from './warrantyClaimStatuses/reducers';
import {workingGroups} from './workingGroups/reducers';
import {persistReducer} from 'redux-persist';
import {formPersistConfig} from '@src/store/persistentStorage/formPersistConfig';
import {contractForms} from './contractForms/reducers';
import {taskTypes} from './taskTypes/reducers';
import {taskStatuses} from './taskStatuses/reducers';
import {taskPriorities} from './taskPriorities/reducers';
import latestUserChecks from './latestUserChecks/reducers';
import latestUserInternalAcceptances from './latestUserInternalAcceptances/reducers';
import latestUserOwnerAcceptances from './latestUserOwnerAcceptances/reducers';
import latestUserWarrantyClaims from './latestUserWarrantyClaims/reducers';
import {structureTypes} from './structureTypes/reducers';
import {problemTagsSets} from './problemTagsSets/reducers';
import {problemTags} from './problemTags/reducers';
import {workAcceptanceStatuses} from './workAcceptanceStatuses/reducers';
import {spaceStatuses} from './spaceStatuses/reducers';
import {workingGroupTypes} from './workingGroupTypes/reducers';
import {documentTypes} from './documentTypes/reducers';

const dictionariesReducer = combineReducers({
	categories,
	categoriesSets,
	checkLists,
	checkItems,
	checkRecordStatuses,
	companies,
	exportTemplates,
	exportTemplatesDestinations,
	latestUserChecks,
	latestUserInternalAcceptances,
	latestUserOwnerAcceptances,
	latestUserWarrantyClaims,
	objects,
	objectStages,
	ownerAcceptanceResolutions,
	ownerAcceptanceStatuses,
	plans,
	problemStatuses,
	problemTemplatesSets,
	problemTags,
	problemTagsSets,
	regulatoryStandards,
	responsibilityRules,
	spaceIndicatorsSets,
	spaceOwners,
	spaceStatuses,
	spaceStatusesSets,
	spaceTypes,
	contractForms,
	users,
	warrantyClaimStatuses,
	workingGroups,
	taskTypes,
	taskStatuses,
	taskPriorities,
	structureTypes,
	workAcceptanceStatuses,
	workingGroupTypes,
	documentTypes
});

export type IDictionariesState = ReturnType<typeof dictionariesReducer>;

export const dictionaries = persistReducer(
	formPersistConfig<ReturnType<typeof dictionariesReducer>>('dictionaries'),
	dictionariesReducer
);
