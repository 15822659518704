import React from 'react';
import './TextChange.less';
import classNames from 'classnames';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';

interface ITextChangeProps extends IChangeComponentProps {
	value?: React.ReactNode;
}

export const TextChange = ({className, style, value}: ITextChangeProps) => (
	<div
		className={classNames('history-text-change', className)}
		style={style}
	>
		{value}
	</div>
);

TextChange.displayName = 'TextChange';
