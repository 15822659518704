import {
	IAddProblemParams,
	IEditProblemParams
} from '@src/api/cache/problems/defaults/useProblemsMutationDefaults';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {useEditProblemMutation} from '@src/api/cache/problems/mutations';
import {editProblemActions} from '@src/store/modules/pages/problem/actions';
import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {useCallback} from 'react';
import useAppDispatch from '../../useAppDispatch';
import {ISavingProblemQuery} from './useAddProblem';
import {toListProblem} from './utils/convertToLocalSave';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export const useEditProblem = (object: IObject) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const mutation = useEditProblemMutation();
	const online = onlineManager.isOnline();
	// Проверяем локальное нарушение или нет,
	// если да, то изменяем кэш
	const editProblem = useCallback((params: IEditProblemParams) => {
		const {problemId, fields, objectId} = params;
		const payloadToSave = queryClient.getQueryData(problemsQueryKeys.savingData(problemId));
		if (payloadToSave) {
			const newPayload = queryClient.setQueryData<ISavingProblemQuery>(
				problemsQueryKeys.savingData(problemId),
				data => {
					if (data) {
						if (fields.attachments) {
							data.savingData.newAttachments = data.savingData.newAttachments?.filter(
								att => fields.attachments?.some(item => item.id === att.key)
							);
						}
						if (fields.newAttachments) {
							if (
								!data.savingData.newAttachments
								|| !data.savingData.newAttachments.length
							) {
								data.savingData.newAttachments = [];
							}
							data.savingData.newAttachments = [
								...data.savingData.newAttachments,
								...fields.newAttachments
							];
						}

						return {
							...data,
							savingData: {
								...data.savingData,
								...fields,
								newAttachments: data.savingData.newAttachments
							}
						};
					}
					return data;
				}
			);
			const cachedProblem = queryClient.setQueryData<IProblem>(
				[...problemsQueryKeys.detail(problemId), objectId],
				(data: IProblem) => {
					if (data) {
						return {
							...data,
							...toListProblem(object, data.links, data.stage, fields, problemId)
						};
					}
					return data;
				}
			);
			void queryClient.refetchQueries({
				queryKey: problemsQueryKeys.localList()
			});
			const mutationCache = queryClient.getMutationCache();
			const currentMutation = mutationCache.find<
				IProblem,
				IError,
				IAddProblemParams,
				unknown
			>({
				predicate: mut => {
					const key = (mut.state.variables as IAddProblemParams).key;
					return problemId === key;
				}
			});

			if (currentMutation && newPayload && currentMutation.state.variables) {
				currentMutation.setState({
					...currentMutation.state,
					isPaused: true,
					status: 'loading',
					failureCount: 0,
					error: null,
					failureReason: null,
					variables: {
						...currentMutation.state.variables,
						fields: newPayload.savingData
					}
				});
				if (online) {
					void currentMutation.execute();
				}
			}
			dispatch(editProblemActions.success(cachedProblem as ILinkedProblem));
			addWarningToast('Успех', 'локальное нарушение изменено');
		}
		// Секция изменения нормального нарушения
		if (!payloadToSave) {
			mutation.mutate({
				...params
			});
		}
	}, []);
	return [editProblem];
};
