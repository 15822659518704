import React, {Dispatch} from 'react';
import './EditableWorkAcceptance.less';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {
	IEditableWorkAcceptanceAction,
	IEditableWorkAcceptanceState
} from '@src/core/hooks/states/useEditableWorkAcceptanceState';
import Text from '../editableFields/Text';
import Category from '../editableFields/Category';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {EditableDate} from '../editableFields/EditableDate';
import {Responsible} from '../editableFields/Responsible';
import {StructureTreeSelect} from '../editableFields/StructureTreeSelect';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';

interface IEditableWorkAcceptanceProps {
	objectId: string;
	editingState: IEditableWorkAcceptanceState;
	editingDispatch: Dispatch<IEditableWorkAcceptanceAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
	stage: ObjectStageIds;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableWorkAcceptance = (props: IEditableWorkAcceptanceProps) => {
	const {objectId, editingState, editingDispatch, fieldsSettings, saving, stage} = props;

	return (
		<div className="editable-work-acceptance">
			<div className="editable-work-acceptance__info-grid">
				<StructureTreeSelect
					objectId={objectId}
					field="structureIds"
					label="Фронт работ"
					value={editingState.structureIds || []}
					locations={editingState.locations}
					editingDispatch={editingDispatch}
					required={fieldsSettings.structureIds.isRequired}
					disabled={saving}
					hasError={editingState.errors.structureIds}
				/>

				<Category
					value={editingState.categoryId}
					objectId={objectId}
					editingDispatch={editingDispatch}
					required={fieldsSettings.categoryId.isRequired}
					disabled={saving}
					hasError={editingState.errors.categoryId}
					stage={stage}
				/>

				<EditableDate
					field="acceptanceDate"
					value={editingState.acceptanceDate}
					label="Срок приемки работ"
					editingDispatch={editingDispatch}
					required={fieldsSettings.acceptanceDate.isRequired}
					disabled={saving}
					hasError={editingState.errors.acceptanceDate}
					errorMessage="Укажите дату"
					showTimeSelect
				/>

				<Responsible
					objectId={objectId}
					stage={stage}
					categoryId={editingState.categoryId}
					respUsers={editingState.respUsers}
					activeGroup={editingState.activeGroup}
					editingDispatch={editingDispatch}
					required={fieldsSettings.respUsers.isRequired}
					disabled={saving}
					structureIds={editingState.structureIds}
					hasError={editingState.errors.respUsers}
					workingGroupType={WorkingGroupTypeId.WORK_ACCEPTANCE_INSPECTORS}
				/>

				<Text
					field="description"
					label="Описание"
					elementType="textarea"
					value={editingState.description}
					editingDispatch={editingDispatch}
					required={fieldsSettings.description.isRequired}
					disabled={saving}
					hasError={editingState.errors.description}
				/>
			</div>
		</div>
	);
};
