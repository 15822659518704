import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeSpaceExportRequest} from '@src/api/backend/space';
import {addExportToast} from "@src/components/ExportToast/addExportToast";
import {IExportResponse} from "@tehzor/tools/interfaces/IExportResponse";

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse, sending: boolean) => {
	if (response.link) {
		addExportToast(
			sending ? 'Документ отправлен' : 'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document,
			undefined,
			undefined
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при экспорте');
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует помещение вместе с нарушениями и осмотрами
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param problems список нарушений
 * @param inspections список проверок
 */
export const exportSpace = (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	spaceId: string,
	// problems?: Record<string, unknown>,
	// inspections?: Record<string, unknown>
	problems?: string[],
	inspections?: string[],
	warrantyClaims?: string[],
	checks?: string[],
	ownerAcceptances?: string[]
) =>
	createApiAction<IExportResponse>(request, (r: IExportResponse) => success(r, !!email), failure, () =>
		makeSpaceExportRequest(
			templateId,
			createDocument,
			email,
			objectId,
			spaceId,
			problems,
			inspections,
			warrantyClaims,
			checks,
			ownerAcceptances
		));
