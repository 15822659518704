import React, {useState} from 'react';
import {FloatingActionButton, IconButton} from '@tehzor/ui-components';
import {useAddingProblemDialog} from '@src/components/AddingProblemDialog/hooks/useAddingProblemDialog';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {useStructureSelectDialog} from '../StructureSelectDialog/useStructureSelectDialog';

interface IEntityAddingProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
}

export const EntityAdding = ({objectId, workAcceptance}: IEntityAddingProps) => {
	const [links, setLinks] = useState<IProblem['links'] | IInspection['links']>({
		workAcceptanceId: workAcceptance.id
	});
	const selectedStructure = workAcceptance.structures?.find(str => str.id === links?.structureId);

	const [structureChangeDialog, openStructureChangeDialog] = useStructureSelectDialog(
		workAcceptance.structures ?? [],
		(value: string) => {
			setLinks({...links, structureId: value});
		},
		undefined,
		links?.structureId
	);

	const problemStructure = (
		<div>
			{selectedStructure && (
				<div
					role="presentation"
					onClick={openStructureChangeDialog as () => void}
					className="work-acceptance-page__selected-structure work-acceptance-page__selected-structure_offset"
				>
					<div className="work-acceptance-page__selected-structure_icon">
						<i className="tz-structure"/>
					</div>
					{selectedStructure.name}
				</div>
			)}
			{structureChangeDialog as JSX.Element}
		</div>
	);

	const [dialog, open] = useAddingProblemDialog(
		objectId,
		links,
		workAcceptance.stage,
		undefined,
		undefined,
		undefined,
		problemStructure
	);

	const onSuccess = (value: string) => {
		setLinks({...links, structureId: value});
		open();
	};

	const [structureSelectDialog, openStructureSelectDialog] = useStructureSelectDialog(
		workAcceptance.structures ?? [],
		onSuccess,
		dialog ?? null
	);

	return (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={openStructureSelectDialog as () => void}
				/>
			</BeforeDesktop>
			<Desktop>
				<IconButton
					type="accent-blue"
					onClick={openStructureSelectDialog as () => void}
				>
					<i className="tz-plus-20"/>
				</IconButton>
			</Desktop>
			{structureSelectDialog}
		</>
	);
};
