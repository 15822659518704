import React from 'react';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {useCheckRecordsPermissions} from '@src/core/hooks/permissions/useCheckRecordsPermissions';

interface ISelectionsActionsProps {
	objectId: string;
	spaceId: string;
	selectedEntities: IProblem[];
}

const SelectionActions = ({objectId, spaceId, selectedEntities}: ISelectionsActionsProps) => {
	const permissions = useCheckRecordsPermissions(objectId);

	return (
		<>
			{permissions.canDelete && (
				<SelectedEntitiesDeletion selectedEntities={selectedEntities}/>
			)}
		</>
	);
};

export default SelectionActions;
