import React from 'react';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

interface IInternalAcceptanceInfoProps {
	data: IInternalAcceptance;
}

const InternalAcceptanceInfo = ({data}: IInternalAcceptanceInfoProps) => (
	<EntityInfo
		title={`Внутренняя приемка №${data.number ?? data.localNumber}`}
		subTitle={<EntityInfoDate value={data.createdAt}/>}
		icon={<i className="tz-internal-acceptance-28"/>}
		iconColor="#57A4FF"
		iconSize={44}
	/>
);

export default InternalAcceptanceInfo;
