import React, {forwardRef, Ref} from 'react';
import ButtonBase, {OuterTagProps, OuterTagType} from '../ButtonBase';
import './IconButton.less';
import classNames from 'classnames';

export type ButtonType =
	'common'
	| 'accent-blue'
	| 'transparent'
	| 'inline'
	| 'inline-blue'
	| 'inline-blue-accent'
	| 'inline-red'
	| 'inline-red-accent';

interface IIconButtonProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	type?: ButtonType;
	disabled?: boolean;
	active?: boolean;
	outerTagType?: OuterTagType;
	outerTagProps?: OuterTagProps;

	onClick?: (event: React.MouseEvent) => void;
}

const IconButton = (props: IIconButtonProps, ref?: Ref<HTMLButtonElement>) => {
	const {
		className,
		style,
		type = 'transparent',
		disabled,
		active,
		outerTagType = 'button',
		outerTagProps,
		children,
		onClick
	} = props;
	const typedClass = type && (disabled ? `icon-button_${type}-disabled` : `icon-button_${type}`);

	return (
		<ButtonBase
			className={classNames(typedClass, {'icon-button_active': active}, className)}
			style={style}
			leftIcon={children}
			disabled={disabled}
			classNamePrefix="icon-button"
			outerTagType={outerTagType}
			outerTagProps={outerTagProps}
			onClick={onClick}
			ref={ref}
		/>
	);
};

IconButton.displayName = 'IconButton';

export default forwardRef(IconButton);
