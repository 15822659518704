import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import './CheckListsCell.less';
import {Tag, TagsContainer} from '@tehzor/ui-components';

const checkListIcon = <i className="tz-check-list-16 "/>;

export const CheckListsCell = ({row}: CellProps<IEnrichedStructure>) => {
	const checkLists = row.original.checkLists;
	if (!checkLists || checkLists?.length === 0) {
		return null;
	}

	return (
		<div>
			<TagsContainer>
				{checkLists.map(checkList => (

					<Tag
						key={checkList.key}
						className={{
								root: 'check-list-cell',
								label: 'check-list-cell__label',
								icon: 'check-list-cell__icon'
						}}
						color={`${checkList.status.color}`}
						icon={checkListIcon}
						label={checkList.name.toString()}
						size="large"
					/>

					))}
			</TagsContainer>
		</div>
	);
};