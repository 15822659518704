import {wsConnector} from '../wsConnector';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export type IGetSpaceTypeDecorationSetResponse = ISpaceTypeDecorationSet;

/**
 * Возвращает один набор типов отделки помещений по Id
 *  *
 * @param id id набора
 */
export const requestSpaceTypeDecorationSet = (id: string) =>
	wsConnector.sendAuthorizedRequest<IGetSpaceTypeDecorationSetResponse>(
		'getSpaceTypeDecorationSet',
		{id}
	);
