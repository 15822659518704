import React, {useMemo} from 'react';
import {IPermissions} from './usePermissions';
import {formCheckLink, formInternalAcceptanceLink, formOwnerAcceptanceLink, formSpaceLink, formTaskLink, formWarrantyClaimLink, formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';

interface IEntityLink {
	key: string;
	icon: React.ReactNode;
	label: string;
	url?: string;
	title: string;
}

export function useEntitiesLinks(
	permissions: IPermissions,
	objectId: string,
	problem?: IPreparedProblem
): IEntityLink[] {
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const task = useAppSelector(s => s.pages.task.data);

	return useMemo((): IEntityLink[] => {
		if (!problem?.links) {
			return [];
		}
		const result: IEntityLink[] = [];
		if (problem.space && permissions.canViewSpace) {
			result.push({
				key: 'space',
				icon: <i className="tz-space-24"/>,
				label: formSpaceTitle(
					problem.space.name,
					problem.space.altName,
					spaceTypes.byId[problem.space.type],
					altNamesVisible
				),
				url: formSpaceLink(objectId, problem.space.id),
				title: 'Перейти к помещению'
			});
		}
		if (problem.check && permissions.canViewCheck) {
			result.push({
				key: 'check',
				icon: <i className="tz-check-24"/>,
				label: `Проверка №${problem.check.number}`,
				url: formCheckLink(objectId, problem.check.id),
				title: 'Перейти к проверке'
			});
		}
		if (problem.internalAcceptance && permissions.canViewInternalAcceptance) {
			result.push({
				key: 'internalAcceptance',
				icon: <i className="tz-internal-acceptance-24"/>,
				label: `Внутренняя приёмка №${problem.internalAcceptance.number}`,
				url: formInternalAcceptanceLink(objectId, problem.internalAcceptance.id),
				title: 'Перейти к внутренней приёмке'
			});
		}
		if (problem.ownerAcceptance && permissions.canViewOwnerAcceptance) {
			result.push({
				key: 'ownerAcceptance',
				icon: <i className="tz-owner-acceptance-24"/>,
				label: `Передача собственнику №${problem.ownerAcceptance.number}`,
				url: formOwnerAcceptanceLink(objectId, problem.ownerAcceptance.id),
				title: 'Перейти к передаче собственнику'
			});
		}
		if (problem.warrantyClaim && permissions.canViewWClaim) {
			result.push({
				key: 'w-claim',
				icon: <i className="tz-warranty-claim-24"/>,
				label: `Обращение №${problem.warrantyClaim.number}`,
				url: problem.warrantyClaim
					? formWarrantyClaimLink(objectId, problem.warrantyClaim.id)
					: undefined,
				title: 'Перейти к обращению'
			});
		}

		if (problem?.links?.taskId !== undefined && permissions.canViewTask) {
			result.push({
				key: 'task',
				icon: <i className="tz-internal-acceptance-24"/>,
				label: `Задача ${task?.name?.slice(0, 9)}`,
				url: formTaskLink(problem.links.taskId),
				title: 'Перейти к задаче'
			});
		}
		if (problem.workAcceptance !== undefined && permissions.canViewWorkAcceptance) {
			result.push({
				key: 'work-acceptance',
				icon: <i className="tz-internal-acceptance-24"/>,
				label: `Приёмка работ №${problem.workAcceptance.number}`,
				url: formWorkAcceptanceLink(objectId, problem.workAcceptance.id),
				title: 'Перейти к приёмке'
			});
		}
		return result;
	}, [objectId, problem, permissions, spaceTypes, task, altNamesVisible]);
}
