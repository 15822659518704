import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceTypesAsArray} from '@src/store/modules/dictionaries/spaceTypes/selectors';
import React, {useMemo} from 'react';
import {Tag} from '@tehzor/ui-components';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';

export const CheckListSpaceTypesCell = ({row}: CellProps<IPreparedCheckList>) => {
	const rowSpaceTypes = row.original.spaceTypes || [];
	const spaceTypes = useAppSelector(extractSpaceTypesAsArray);

	const selectedTypes = useMemo(
		() => spaceTypes.filter(spaceType => rowSpaceTypes.includes(spaceType.id)),
		[spaceTypes, row.original.spaceTypes]
	);

	return (
		<div>
			{selectedTypes.length < 3 ? (
				<div>
					{selectedTypes.map(selectedType => (
						<Tag
							key={selectedType.id}
							label={selectedType.name}
						/>
					))}
				</div>
			) : (
				<div>
					{selectedTypes.slice(0, 3).map(selectedType => (
						<Tag
							key={selectedType.id}
							label={selectedType.name}
						/>
					))}
					<Tag
						color="#EEF5FB"
						label={`+${selectedTypes.length - 3}`}
					/>
				</div>
			)}
		</div>
	);
};
