import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {PriorityCell} from '../PriorityCell';
import {TaskStatusMobileCell} from '@src/pages/TasksPage/components/tabs/TasksTablePage/components/mobile/TaskStatusMobileCell';
import {TaskDescriptionMobileCell} from '@src/pages/TasksPage/components/tabs/TasksTablePage/components/mobile/TaskDescriptionMobileCell';
import {TaskTypeMobileCell} from './TaskTypeMobileCell';
import {ResponsibleUsersMobileCell} from './ResponsibleUsersMobileCell';
import {DeadLineMobileCell} from './DeadLineMobileCell';
import LocationMobileCell from '@src/pages/TasksPage/components/tabs/TasksTablePage/components/mobile/LocationMobileCell';

export const TaskMobileCell = (props: CellProps<IEnrichedTask, IEnrichedTask>) => {
	const {value} = props;
	const {row} = props;

	return (
		<div className="tasks-page__m-table-cell">
			<div className="tasks-page__m-table-cell-wrapper">
				<TaskDescriptionMobileCell row={row}/>
				<div className="tasks-page__m-table-cell-info">
					<TaskStatusMobileCell value={value}/>
					<TaskTypeMobileCell row={row}/>
				</div>
				<ResponsibleUsersMobileCell value={value}/>
				<DeadLineMobileCell row={row}/>
				<div className="tasks-page__m-table-cell-wrapper-grid">
					<PriorityCell row={row}/>
				</div>
				<LocationMobileCell row={row}/>
			</div>
		</div>
	);
};
