import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import React from 'react';
import {CellProps} from 'react-table';

interface ISigningDateCellProps extends CellProps<IPreparedContract> {
	isMobile?: boolean;
}

export const SigningDateCell = ({row, isMobile}: ISigningDateCellProps) =>
	(row.original.signingDate ? (
		<div className="contracts__cell-signing-date">
			{isMobile && (
				<div className="contracts__cell-signing-date-header">
					Дата заключения:
				</div>
			)}
			<div className="contracts__cell-signing-date-date">
				{format(row.original.signingDate, dateFormat)}
			</div>
		</div>
	) : null);