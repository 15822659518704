import {wsConnector} from '../wsConnector';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ISavingObject} from '@tehzor/tools/interfaces/objects/ISavingObject';

export type IEditObjectResponse = IObject;

/**
 * Изменяет объект
 *
 * @param objectId id объекта
 * @param object данные объекта
 */
export const makeObjectEditRequest = (objectId: string, object: ISavingObject) =>
	wsConnector.sendAuthorizedRequest<IEditObjectResponse>('editObject', {objectId, ...object});
