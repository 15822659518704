import {QueryClient} from '@tanstack/react-query';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: 1000 * 60 * 60 * 24 * 7,
			staleTime: 1000 * 60 * 60 * 24
		},
		mutations: {
			cacheTime: Infinity
		}
	}
});
