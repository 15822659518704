import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {extractCheckListById} from '@src/store/modules/dictionaries/checkLists/selectors';
import {useMemo} from 'react';

export function useEntityAddingPermission(objectId: string, listId: string) {
	const checkList = useAppSelector(s => extractCheckListById(s, listId));
	const checksPermissions = useChecksPermissions(objectId, 'check-list');
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId, 'check-list');
	const ownersAcceptancesPermissions = useOwnerAcceptancesPermissions(objectId, 'check-list');
	const warrantyClaimPermissions = useWarrantyClaimsPermissions(objectId, 'check-list');

	return useMemo(
		() =>
			(checkList?.stage === ObjectStageIds.BUILDING && checksPermissions.canAdd) ||
			(checkList?.stage === ObjectStageIds.TRANSFER && ownersAcceptancesPermissions.canAdd) ||
			(checkList?.stage === ObjectStageIds.ACCEPTANCE &&
				internalAcceptancePermissions.canAdd) ||
			(checkList?.stage === ObjectStageIds.WARRANTY && warrantyClaimPermissions.canAdd),
		[
			checkList?.stage,
			checksPermissions,
			ownersAcceptancesPermissions,
			internalAcceptancePermissions,
			warrantyClaimPermissions
		]
	);
}
