export const restDictionariesQueryKeys = {
	all: () => ['dictionary'],
	users: () => [...restDictionariesQueryKeys.all(), 'users'],
	companies: () => [...restDictionariesQueryKeys.all(), 'companies'],
	objectStages: () => [...restDictionariesQueryKeys.all(), 'object-stages'],
	ownerAcceptanceResolutions: () => [
		...restDictionariesQueryKeys.all(),
		'owner-acceptance-resolutions'
	],
	ownerAcceptanceStatuses: () => [
		...restDictionariesQueryKeys.all(),
		'owner-acceptance-statuses'
	],
	problemStatuses: () => [...restDictionariesQueryKeys.all(), 'problem-statuses'],
	spaceIndicatorsSets: () => [...restDictionariesQueryKeys.all(), 'space-indicators-sets'],
	spaceOwners: () => [...restDictionariesQueryKeys.all(), 'space-owners'],
	spaceTypes: () => [...restDictionariesQueryKeys.all(), 'space-types'],
	warrantyClaimStatuses: () => [...restDictionariesQueryKeys.all(), 'warranty-claim-statuses'],
	checkRecordStatuses: () => [...restDictionariesQueryKeys.all(), 'check-record-statuses'],
	taskStatuses: () => [...restDictionariesQueryKeys.all(), 'task-statuses'],
	taskTypes: () => [...restDictionariesQueryKeys.all(), 'task-types']
};
