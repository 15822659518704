import {createSelector} from 'reselect';
import {getInitialStateForPage} from '@src/store/modules/entities/workAcceptances/reducers';
import {IState} from '@src/store/modules';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

/**
 * Возвращает данные по приёмкам работ для конкретного объекта
 */
export const extractWorkAcceptancesData = createSelector(
	(state: IState) => state.entities.workAcceptances,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(rootData, objectId) => rootData[objectId] || getInitialStateForPage()
);

/**
 * Возвращает приёмки работ в виде массива
 */
export const extractWorkAcceptancesAsArray = createSelector(extractWorkAcceptancesData, data =>
	data.allIds.map((id: string) => data.byId[id]));

/**
 * Возвращает данные приёмки работ по id
 */
export const extractWorkAcceptanceById = createSelector(
	[
		extractWorkAcceptancesData,
		(state, objectId, workAcceptanceId: string | undefined) => workAcceptanceId
	],
	(data, workAcceptanceId) => (workAcceptanceId ? data.byId[workAcceptanceId] : undefined)
);

/**
 * Возвращает приёмки работ для структуры в виде массива
 */
export const extractWorkAcceptancesForStructureAsArray = createSelector(
	[extractWorkAcceptancesAsArray, (s, _, structureId: string | undefined) => structureId],
	(data, structureId) =>
		data.filter(
			(workAcceptance: IListWorkAcceptance) =>
				structureId && workAcceptance.structureIds?.includes(structureId)
		)
);

/**
 * Возвращает приёмки работ по структурам в виде массива
 */
export const extractWorkAcceptancesByStructuresAsArray = createSelector(
	[extractWorkAcceptancesAsArray, (s, _, structureIds: string[] | undefined) => structureIds],
	(data, structureIds) => {
		const response: Record<string, IListWorkAcceptance[]> = {};
		structureIds?.forEach((structureId: string) => {
			response[structureId] = data.filter(
				(workAcceptance: IListWorkAcceptance) => workAcceptance.structureIds?.includes(structureId)
			);
		});
		return response;
	}
);

/**
 * Возвращает id приёмок работ для структур в виде массива
 */
export const extractWorkAcceptanceIdsForStructuresAsArray = createSelector(
	[extractWorkAcceptancesAsArray, (s, _, structureIds: string[] | undefined) => structureIds],
	(data, structureIds) => {
		if (!structureIds) {
			return [];
		}
		return data.filter(
			(workAcceptance: IListWorkAcceptance) =>
				structureIds.some(structureId => workAcceptance.structureIds?.includes(structureId))
		).map((workAcceptance: IListWorkAcceptance) => workAcceptance.id);
	}
);