import React, {useState} from 'react';
import {AddingLegalDialog} from '../AddingLegalDialog';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface AddingLegalDialogProps {
	legal?: ILegalEntity;
	onSuccess?: () => void | Promise<void>;
}

export const useAddingLegalDialog = (
	props: AddingLegalDialogProps
): [React.ReactNode, () => void] => {
	const {legal, onSuccess} = props;
	const [isOpen, setOpen] = useState(false);

	const open = () => setOpen(true);
	const close = () => setOpen(false);

	const dialog = (
		<AddingLegalDialog legal={legal} isOpen={isOpen} close={close} onSuccess={onSuccess} />
	);

	return [dialog, open];
};
