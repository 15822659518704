import React, {useCallback} from 'react';
import {IDecadeData} from '../hooks/useDecadesGrid';
import CalendarButton from '../../../buttons/CalendarButton';

export interface IDecadeProps extends IDecadeData {
	active?: boolean;
	onClick?(value: number): void;
}

const Decade = (props: IDecadeProps) => {
	const {value, name, differentPeriod, active, onClick} = props;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(value);
		}
	}, [value]);

	return (
		<CalendarButton
			key={value}
			className="calendar__decade"
			active={active}
			inactive={differentPeriod}
			onClick={handleClick}
		>
			{name}
		</CalendarButton>
	);
};

export default Decade;
