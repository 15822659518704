import {wsConnector} from '../wsConnector';

/**
 * Удаляет помещение
 *
 * @param objectId id объекта
 * @param spaceId id нарушения
 */
export const makeSpaceDeleteRequest = (objectId: string, spaceId: string) =>
	wsConnector.sendAuthorizedRequest<void>('deleteSpace', {
		objectId,
		spaceId
	});
