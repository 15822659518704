import React from 'react';
import {CellProps} from 'react-table';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';

const SpaceNameCell = ({row}: CellProps<IEnrichedSpace>) => {
	const space = row.original;
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return <>{formSpaceTitle(space.name, space.altName, spaceTypes.byId[space.type], altNamesVisible)}</>;
};

export default SpaceNameCell;
