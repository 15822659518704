import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetProblemTemplatesSetsResponse} from '@src/api/backend/problemTemplatesSets';
import {problemTemplatesSetsQueryKeys} from '@src/api/cache/problemTemplatesSets/keys';
import {queryClient} from '@src/api/QueryClient';

export type IGetProblemTemplatesSetsPayload = IGetProblemTemplatesSetsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetProblemTemplatesSetsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке наборов шаблонов нарушений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список наборов шаблонов нарушений
 */
export const getProblemTemplatesSets = () =>
	createApiAction<IGetProblemTemplatesSetsResponse>(request, success, failure, () =>
		queryClient.fetchQuery(problemTemplatesSetsQueryKeys.list(), {staleTime: Infinity}));
