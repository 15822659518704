import React, {Context, useCallback, useContext, useMemo} from 'react';
import './SwitchBase.less';
import generateKey from '../../../utils/generateKey';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';

export interface ISwitchIconProps {
	className?: string;
	style?: React.CSSProperties;
	checked?: boolean;
	disabled?: boolean;
}

export interface ISwitchGroupContext<G, I> {
	name?: string;
	value?: G;

	isChecked: (itemValue?: I) => boolean;
	onChange?: (value?: I) => void;
}

interface ISwitchBaseProps<G, I> {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				icon?: string;
				content?: string;
		  };
	style?: React.CSSProperties;
	children?: React.ReactNode;
	id?: string;
	name?: string;
	type: string;
	value?: I;
	checked?: boolean;
	disabled?: boolean;
	switchIcon: React.ReactElement<ISwitchIconProps>;
	GroupContext: Context<ISwitchGroupContext<G, I> | undefined>;

	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
	onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
	event.stopPropagation();
};

const SwitchBase = <GroupValueType, ItemValueType extends string | number>(
	props: ISwitchBaseProps<GroupValueType, ItemValueType>
) => {
	const {className, style, children, type, value, disabled, switchIcon, GroupContext, onChange, onClick, onKeyPress}
		= props;
	let {name, checked} = props;
	const classes = convertClassNames(className);

	const id = useMemo(() => props.id || generateKey('switch'), []);

	const group = useContext(GroupContext);
	if (group) {
		if (name === undefined) {
			name = group.name;
		}
		if (checked === undefined) {
			checked = group.isChecked(value);
		}
	}

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (onChange) {
				onChange(event);
			}
			if (group?.onChange) {
				group.onChange(value);
			}
		},
		// eslint-disable-next-line @typescript-eslint/unbound-method
		[value, onChange, group?.onChange]
	);

	const {className: iconClass, ...restIconProps} = switchIcon.props;
	const iconProps = {
		...restIconProps,
		className: classNames('switch__icon', iconClass, classes.icon),
		checked,
		disabled
	};
	const icon = React.cloneElement(switchIcon, iconProps);

	return (
		<div
			className={classNames('switch', {switch_checked: checked}, {switch_disabled: disabled}, classes.root)}
			style={style}
			role="checkbox"
			aria-checked={checked}
			tabIndex={0}
			onClick={onClick}
			onKeyPress={onKeyPress}
		>
			<input
				id={id}
				name={name}
				className="switch__input"
				type={type}
				value={value}
				checked={!!checked}
				disabled={disabled}
				onChange={handleChange}
				onClick={handleInputClick}
			/>
			<label
				className={classNames('switch__label-wrap', classes.wrap)}
				htmlFor={id}
			>
				{icon}
				{children !== undefined && (
					<div className={classNames('switch__content', classes.content)}>{children}</div>
				)}
			</label>
		</div>
	);
};

export default SwitchBase;
