import React, {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {
	useTasksListPageSettings,
	useTasksPageDisplayMode,
	useTasksSchedulePageSettings
} from '@src/core/hooks/queries/tasks';
import {
	changeFilters,
	changeOffset,
	changeScheduleFilters,
	clearFilters,
	clearScheduleFilters
} from '@src/store/modules/settings/pages/tasks/actions';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';

export const TasksFiltersRoutingPage = () => {
	const {filters} = useTasksListPageSettings();
	const {filters: scheduleFilters} = useTasksSchedulePageSettings();
	const displayMode = useTasksPageDisplayMode();
	const dispatch = useAppDispatch();

	const applyFilters = useCallback(
		(value: ITasksFiltersState) => {
			if (displayMode === TasksDisplayMode.LIST) {
				dispatch(changeFilters(value));
				dispatch(changeOffset(0));
			}

			if (displayMode === TasksDisplayMode.SCHEDULE) {
				dispatch(changeScheduleFilters(value));
			}
		},
		[dispatch]
	);
	const onClear = useCallback(() => {
		if (displayMode === TasksDisplayMode.LIST) {
			dispatch(clearFilters());
		}
		if (displayMode === TasksDisplayMode.SCHEDULE) {
			dispatch(clearScheduleFilters());
		}
	}, [dispatch, displayMode]);

	return (
		<MobileEntitiesFilters
			filters={displayMode === TasksDisplayMode.LIST ? filters : scheduleFilters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};
