import React from 'react';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Tag} from '@tehzor/ui-components';
import './ProblemTagsCount.less';

interface IProblemTagsCountProps {
	object: IParentObject;
}

const getProblemTags = (obj: IParentObject, result: Record<string, number>) => {
	if (obj.stats?.problemTags) {
		for (const [key, value] of Object.entries(obj.stats.problemTags)) {
			result[key] = value.count;
		}
	}
	if (obj.children) {
		for (const child of obj.children) {
			getProblemTags(child, result);
		}
	}
};

export const ProblemTagsCount = ({object}: IProblemTagsCountProps) => {
	const tagMap = useAppSelector(s => s.dictionaries.problemTags.byId);

	const result: Record<string, number> = {};
	getProblemTags(object, result);

	return (
		<div className="problem-tags-count">
			{Object.entries(result).map(([tagId, count]) =>
				(count && tagMap[tagId] ? (
					<div
						className="problem-tags-count__container"
						key={tagId}
					>
						<Tag
							className="problem-tags-count__container-tag"
							label={tagMap[tagId].name}
							dotColor={tagMap[tagId].color}
							size="small"
						/>
						<div className="problem-tags-count__container-count">
							{count}
						</div>
					</div>
				) : null))}
		</div>
	);
};