import React, {memo, useCallback, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectPopup, FilterButton, SelectOption, Select2} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';

interface SelectOptionItem {
	id: string;
	name: string;
}

interface IStatusesFilterProps<T extends SelectOptionItem> {
	label?: string;
	statuses?: string[];
	allStatuses: T[];
	filterName?: string;
	resetOnApply?: string[];
}

export const StatusesFilter = memo(<T extends SelectOptionItem>(props: IStatusesFilterProps<T>) => {
	const {label = 'Статус', filterName = 'statuses', statuses, allStatuses, resetOnApply} = props;
	const [selectedStatuses, setSelectedStatuses] = useState(statuses);
	const {dispatch} = useEntitiesFiltersCtx();

	const handleApply = useCallback(() => {
		const changes = {[filterName]: selectedStatuses};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		dispatch(changes);
	}, [filterName, selectedStatuses, dispatch, resetOnApply]);

	const handleChange = useCallback((v?: string[]) => {
		if (v && v.length === 0) {
			setSelectedStatuses(undefined);
		} else {
			setSelectedStatuses(v);
		}
	}, []);

	const handleClear = useCallback(() => {
		setSelectedStatuses(undefined);
	}, []);

	const handleFullClear = useCallback(() => {
		setSelectedStatuses(undefined);
		const changes = {[filterName]: undefined};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		dispatch(changes);
	}, [filterName, dispatch, resetOnApply]);

	const handleCancel = useCallback(() => {
		setSelectedStatuses(statuses);
	}, [statuses]);

	useUpdateEffect(() => {
		setSelectedStatuses(statuses);
	}, [statuses]);

	return (
		<SelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedStatuses?.length}
			footer
			count={selectedStatuses?.length}
			noHeader
			trigger={(
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel<T>(label, statuses, allStatuses)}
					active={!!(statuses && statuses.length != 0)}
					onClear={handleFullClear}
				/>
			)}
		>
			<Select2
				multiple
				value={selectedStatuses}
				onChange={handleChange}
			>
				{allStatuses.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</SelectPopup>
	);
});
