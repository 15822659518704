import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {extractContractorsAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import React from 'react';
import {useParams} from 'react-router-dom';

export const ContractorsFilterPage = () => {
	const {entity = 'contracts'} = useParams<{entity?: string}>();
	const contractors = useAppSelector(extractContractorsAsArray);

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={contractors}
			label="Подрядчики"
			filterName="contractorIds"
		/>
	);
};