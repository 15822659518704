import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {PriorityView} from '../PriorityView/PriorityView';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IDescriptionProps {
	className?: string;
	task: IEnrichedTask;
}

export const TaskPriority = ({className, task}: IDescriptionProps) => {
	const prioritiesMap = useAppSelector(s => s.dictionaries.taskPriorities.byId);

	return (
		<EntityGridItem
			className={className}
			icon={<i className="tz-priority-highest-16"/>}
			label="Приоритет"
			fullRow
		>
			<PriorityView
				value={task.taskPriority?.id || ''}
				prioritiesMap={prioritiesMap}
			/>
		</EntityGridItem>
	);
};