import {IEditSpaceStatusesSetResponse, requestEditSpaceStatusesSet} from '@src/api/backend/spaceStatusesSets/edit';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import * as types from '../constants';
import {queryClient} from '@src/api/QueryClient';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IEditSpaceStatusesSetPayload = IEditSpaceStatusesSetResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (spaceStatusesSet: ISpaceStatusesSet) => ({
	type: types.EDIT_SUCCESS,
	payload: {...spaceStatusesSet} as IEditSpaceStatusesSetPayload
});

const failure = (error: IError) => ({
	type: types.EDIT_FAILURE,
	payload: error
});

export const editSpaceStatusesSet = (
	spaceStatusesSetId: string,
	fields: ISavingSpaceStatusesSet
) =>
	createApiAction(
		request, success, failure,
		() => {
			void queryClient.invalidateQueries(spaceStatusesSetsQueryKeys.list());
			void queryClient.invalidateQueries(spacesQueryKeys.list());

			return requestEditSpaceStatusesSet(spaceStatusesSetId, fields);
		}
	);