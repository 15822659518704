export function makeCommentTitle(comments?: boolean, replies?: boolean) {
	if (comments && replies) {
		return 'Ответы и комментарии';
	}
	if (comments) {
		return 'Комментарии';
	}
	if (replies) {
		return 'Ответы';
	}
	return undefined;
}
