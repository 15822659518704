/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeScheduleValue} from '@src/store/modules/settings/pages/tasks/actions';
import {createScheduleHeaderValue} from '@src/pages/TasksPage/utils';

const arrowIcon = <i className="tz-simple-arrow-20"/>;

export const ScheduleHeader = () => {
	const {settings} = useAppSelector(extractTasksScheduleSettings);
	const dispatch = useAppDispatch();

	const handleYearBack = () => dispatch(changeScheduleValue('decrement'));
	const handleYearForward = () => dispatch(changeScheduleValue('increment'));

	const resultValueStr = createScheduleHeaderValue(settings);

	return (
		<div
			className="manage-task-schedule-page__header"
		>
			<div className="manage-task-schedule-page__header-year">
				<span
					className="manage-task-schedule-page__header-year_arrow-left"
					onClick={handleYearBack}
					role="button"
				>
					{arrowIcon}
				</span>
				<span className="manage-task-schedule-page__header-year_title">{resultValueStr}</span>
				<span
					className="manage-task-schedule-page__header-year_arrow-right"
					onClick={handleYearForward}
					role="button"
				>
					{arrowIcon}
				</span>
			</div>
		</div>
	);
};