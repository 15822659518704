import {Route, Routes} from 'react-router-dom';
import React from 'react';
import {LoadingPanel} from '@tehzor/ui-components';
import {ListDetailMobile, MobileItemDetail} from '@src/pages/CheckListPage/components/detail';
import {CheckLists} from '@src/pages/CheckListPage/components/list';

interface IMobileProps {
	loading: boolean;
}

export const Mobile = ({loading}: IMobileProps) => (
	<div className="page-cont check-list-page check-list-page__m-container">
		<LoadingPanel active={loading}>
			<Routes>
				<Route
					path=":listId"
					element={<ListDetailMobile/>}
				/>
				<Route
					path=":listId/items/:itemId"
					element={<MobileItemDetail/>}
				/>
				<Route
					path="/"
					element={<CheckLists/>}
				/>
			</Routes>
		</LoadingPanel>
	</div>
	);
