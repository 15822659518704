import React from 'react';
import SelectionActions from '../selection/SelectionActions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {usePermissions} from '../../utils/usePermissions';
import ProblemsExportDialog from '../ProblemsExportDialog';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

interface IMobileRightButtonsProps {
	objectId?: string;
	selectedEntities?: IProblemEntity[];
	selectedClearing: () => void;
}

const MobileRightButtons = ({
	objectId,
	selectedEntities,
	selectedClearing
}: IMobileRightButtonsProps) => {
	const user = useAppSelector(s => s.auth.profile);
	const permissions = usePermissions(user, objectId);
	return (
		<div className="problems-page__mobile-right-buttons">
			{selectedEntities && selectedEntities.length > 0 ? (
				<SelectionActions
					selectedEntities={selectedEntities}
					selectedClearing={selectedClearing}
				/>
			) : null}
			{permissions.canExport ? <ProblemsExportDialog objectId={objectId}/> : null}
			<AppUpdateMenu/>
		</div>
	);
};

export default MobileRightButtons;
