import React, {useCallback} from 'react';
import classNames from 'classnames';
import './Notifications.less';
import NotificationsDialog from '../NotificationsDialog';
import IconButton from '../buttons/IconButton';
import Notification from './Notification';
import INotificationMessage from '@tehzor/tools/interfaces/INotificationMessage';
import InfiniteScroll from 'react-infinite-scroller';
import Button from '../buttons/Button';
import InlineButton from '../buttons/InlineButton';
import {ScrollLock} from '../various';
import {ErrorBoundary} from '../containers';

const messagesLoader = (
	<div
		className="notifications__messages-loader"
		key={0}
	>
		Загрузка...
	</div>
);

const closeAllIcon = <i className="tz-delete notifications__delete-all-icon"/>;
const closeIcon = <i className="tz-close-24 notifications__close-dialog-btn-icon"/>;

const isWebPushSupported = 'serviceWorker' in navigator && 'PushManager' in window;

interface INotificationsProps {
	className?: string;
	data: INotificationMessage[];
	messagesCount: number;
	initialMessagesPage?: number;
	hasMoreMessages?: boolean;
	isVisible: boolean;
	isWebPushEnabled?: boolean;

	onMessageDelete(id: string): void;

	onMessageClick?(event: React.MouseEvent): void;

	loadNextMessagesPage(page: number): void;

	onAllMessagesDelete?(): void;

	onVisibilityChange(value?: boolean): void;

	onWebPushEnableRequest?(): void;
}

const Notifications = ({
	className,
	data,
	initialMessagesPage,
	messagesCount,
	hasMoreMessages,
	isVisible,
	isWebPushEnabled,
	onMessageDelete,
	onMessageClick,
	loadNextMessagesPage,
	onAllMessagesDelete,
	onVisibilityChange,
	onWebPushEnableRequest
}: INotificationsProps) => {
	const classes = classNames('notifications', className);

	const toggleOpen = useCallback(() => {
		onVisibilityChange(false);
	}, []);

	return (
		<NotificationsDialog
			className={classes}
			isOpen={isVisible}
			onRequestClose={toggleOpen}
		>
			<ErrorBoundary label="Notifications">
				<div className="notifications__wrap">
					<div className="notifications__header">
						<IconButton
							className="notifications__close-dialog-btn"
							onClick={toggleOpen}
						>
							{closeIcon}
						</IconButton>
					</div>

					<ScrollLock className="notifications__messages">
						<InfiniteScroll
							pageStart={initialMessagesPage}
							hasMore={hasMoreMessages}
							loadMore={loadNextMessagesPage}
							loader={messagesLoader}
							initialLoad={false}
							useWindow={false}
						>
							{data.map(item => (
								<Notification
									key={item.id}
									data={item}
									onDelete={onMessageDelete}
									onClick={onMessageClick}
								/>
							))}
						</InfiniteScroll>
					</ScrollLock>

					{messagesCount > 1 && (
						<InlineButton
							className="notifications__delete-all-btn"
							label="Удалить все уведомления"
							leftIcon={closeAllIcon}
							onClick={onAllMessagesDelete}
						/>
					)}

					{!isWebPushEnabled && isWebPushSupported && (
						<Button
							className="notifications__enable-web-push-btn"
							type="accent-blue"
							label="Включить push-уведомления"
							onClick={onWebPushEnableRequest}
						/>
					)}

					{!isWebPushSupported && (
						<div className="notifications__web-push-unavailable">
							Push-уведомления не поддерживаются на данном устройстве
						</div>
					)}
				</div>
			</ErrorBoundary>
		</NotificationsDialog>
	);
};

export default Notifications;
