import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {GET_REQUEST} from '@src/store/modules/dictionaries/workingGroups/constants';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';

export interface IWorkingGroupsFiltersState extends Record<string, unknown> {
	companies?: string[];
	createdBy?: string[];
	spaceTypes?: string[];
	types?: string;
}

export type IWorkingGroupsSortState = Record<string, boolean>;

export interface IWorkingGroupsPageSettingsState {
	filters: IWorkingGroupsFiltersState;
	sort?: IWorkingGroupsSortState;
	pageSize: number;
	offset: number;
}

export const getInitialState = (): IWorkingGroupsPageSettingsState => ({
	filters: {},
	offset: 0,
	sort: {
		modifiedAt: true
	},
	pageSize: 20
});

export default createReducer<IWorkingGroupsPageSettingsState>(getInitialState(), {
	[GET_REQUEST]: () => getInitialState(),
	[types.CHANGE_SORT]: (
		state,
		{payload}: {payload: IChangeSortPayload<IWorkingGroupsSortState>}
	) => {
		state.sort = payload.sort;
	}
});
