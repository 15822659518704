import React from 'react';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import PageBreadcrumbs from './PageBreadcrumbs';
import SpacesFilters from './filters/SpacesFilters';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {LoadingPanel} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import DesktopSchema from './schema/Schemas.desktop';
import DesktopTable from './table/Table.desktop';
import SettingsPopup from './settings/SettingsPopup';
import {DesktopDisplayModeSwitch} from './DisplayModeSwitch.desktop';
import {useTemporaryDisablingSchemas} from '../hooks/useTemporaryDisablingSchemas';
import {StageVisibilityFilter} from './filters/StageVisibilityFilter';
import {FloorSort} from './sort/FloorSort';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';

interface IDesktopProps {
	objectId: string;
	loading?: boolean;
}

const Desktop = ({objectId, loading}: IDesktopProps) => {
	const {displayMode, schemaView} = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {sectionsMenu});

	// TODO Убрать после ускорения бэкенда и оптимизации загрузки большого кол-ва шахматок
	const needHideSchema = useTemporaryDisablingSchemas(objectId);

	return (
		<div className="page-cont spaces-page">
			<PageBreadcrumbs objectId={objectId}/>

			<SpacesFilters objectId={objectId}/>

			<div className="spaces-page__d-controls-bar">
				<StageVisibilityFilter objectId={objectId}/>
				{(displayMode === SpacesDisplayMode.SCHEMA && schemaView !== SpacesSchemaVariants.REPORTS) && (
					<>
						<FloorSort objectId={objectId}/>
						<SettingsPopup/>
					</>
				)}
				{!needHideSchema && <DesktopDisplayModeSwitch objectId={objectId}/>}
			</div>

			<LoadingPanel
				className="spaces-page__loading-panel"
				active={loading}
			>
				<>
					{displayMode === SpacesDisplayMode.SCHEMA && (
						<DesktopSchema objectId={objectId}/>
					)}
					{displayMode === SpacesDisplayMode.LIST && (
						<DesktopTable objectId={objectId}/>
					)}
				</>
			</LoadingPanel>
		</div>
	);
};

export default Desktop;
