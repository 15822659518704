import {mutations as mutationFns} from '@src/api/mutations';
import {useCallback} from 'react';
import {ISavingInspectionQuery} from '@src/core/hooks/mutations/inspection/useAddInspection';
import {ISavingProblemQuery} from '@src/core/hooks/mutations/problem/useAddProblem';
import {useQueryClient} from '@tanstack/react-query';
import {inspectionsQueryKeys} from '../inspections/keys';
import {problemsQueryKeys} from '../problems/keys';
import {checkPropertyValue} from '@src/utils/checkPropertyValue';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export const useAddChildren = () => {
	const mutations = mutationFns();
	const queryClient = useQueryClient();

	const addProblems = useCallback(
		(
			linkCacheValue: string,
			newLinkId: string,
			linkKey: keyof NonNullable<IProblem['links']>
		) => {
			const savingProblems = queryClient
				.getQueriesData<ISavingProblemQuery>({
					queryKey: problemsQueryKeys.savingDatas(),
					predicate: ({state}) => {
						const data = state.data as ISavingProblemQuery;
						if (data && data.links && checkPropertyValue(data.links, linkCacheValue)) {
							return true;
						}
						return false;
					}
				})
				.filter(([, i]) => !!i)
				.map(([, data]) => data) as ISavingProblemQuery[];
			if (savingProblems.length) {
				savingProblems.forEach(query => {
					mutations.problem.mutate({
						objectId: query.objectId,
						links: {...query.links, [linkKey]: newLinkId},
						stage: query.stage,
						fields: query.savingData,
						key: query.key
					});
				});
			}
		},
		[queryClient]
	);

	const addInspections = useCallback(
		(
			linkCacheValue: string,
			newLinkId: string,
			linkKey: keyof NonNullable<IInspection['links']>
		) => {
			const savingInspections = queryClient
				.getQueriesData<ISavingInspectionQuery>({
					queryKey: inspectionsQueryKeys.savingDatas(),
					predicate: ({state}) => {
						const data = state.data as ISavingInspectionQuery;
						if (data && data.links && checkPropertyValue(data.links, linkCacheValue)) {
							return true;
						}
						return false;
					}
				})
				.filter(([, i]) => !!i)
				.map(([, data]) => data) as ISavingInspectionQuery[];
			if (savingInspections.length) {
				savingInspections.forEach(query => {
					mutations.inspection.mutate({
						objectId: query.objectId,
						links: {...query.links, [linkKey]: newLinkId},
						stage: query.stage,
						fields: query.savingData,
						key: query.key
					});
				});
			}
		},
		[queryClient]
	);
	return [addProblems, addInspections];
};
