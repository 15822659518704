import React, {HtmlHTMLAttributes, useCallback, memo} from 'react';
import {IOfflineJournalEntity} from '../../OfflineJournalPage';
import classNames from 'classnames';
import {CircleLoader} from '@tehzor/ui-components/src/components/loaders/CircleLoader';
import CircleButton from '@tehzor/ui-components/src/components/buttons/CircleButton';
import './OfflineJournalStatus.less';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {useQueryClient} from '@tanstack/react-query';

interface IOfflineJournalStatusProps extends HtmlHTMLAttributes<HTMLDivElement> {
	offlineJournalEntity: IOfflineJournalEntity;
}

export const OfflineJournalStatus = memo((props: IOfflineJournalStatusProps) => {
	const {offlineJournalEntity, className, ...restProps} = props;
	const {transfer, entity} = offlineJournalEntity;
	const {status, statusMessage} = transfer;
	const queryClient = useQueryClient();

	let statusModeClassName = null;
	let iconClassName = null;
	let currentStatusIcon = null;
	const handleReload = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			event.stopPropagation();
			const mutationCache = queryClient.getMutationCache();
			const currentMutation = mutationCache.find<
				unknown,
				unknown,
				Record<string, unknown> & {key: string},
				unknown
			>({
				predicate: mut => {
					const key = (mut.state.variables as Record<string, unknown> & {key: string})
						.key;
					return entity.entityId === key;
				}
			});
			if (currentMutation) {
				currentMutation.setState({
					...currentMutation.state,
					isPaused: true,
					status: 'loading',
					failureCount: 0,
					error: null,
					failureReason: null
				});
				void currentMutation.execute();
			}
		},
		[entity, queryClient]
	);

	switch (status) {
		case OfflineDataTransferStatus.SAVED_LOCAL:
			statusModeClassName = 'offline-journal-status__content_status_saved';
			iconClassName = 'tz-offline-mode-20';
			currentStatusIcon = null;
			break;
		case OfflineDataTransferStatus.TRANSFER:
			statusModeClassName = 'offline-journal-status__content_status_progress';
			iconClassName = 'tz-data-transfer-20';
			currentStatusIcon = <CircleLoader/>;
			break;
		case OfflineDataTransferStatus.TRANSFER_COMPLETE:
			statusModeClassName = 'offline-journal-status__content_status_transferred';
			iconClassName = 'tz-toast-success';
			currentStatusIcon = null;
			break;
		case OfflineDataTransferStatus.TRANSFER_ERROR:
			statusModeClassName = 'offline-journal-status__content_status_error';
			iconClassName = 'tz-data-transfer-error-20';
			currentStatusIcon = (
				<CircleButton
					className="offline-journal-status__circle-button"
					onClick={handleReload}
				>
					<i className="tz-reload-24"/>
				</CircleButton>
			);
			break;
		default:
			statusModeClassName = 'offline-journal-status__content_status_error';
			iconClassName = 'tz-data-transfer-error-20';
			currentStatusIcon = (
				<CircleButton className="offline-journal-status__circle-button">
					<i className="tz-reload-24"/>
				</CircleButton>
			);
	}

	return (
		<div
			className={classNames('offline-journal-status', className)}
			{...restProps}
		>
			<div className={classNames('offline-journal-status__content', statusModeClassName)}>
				<i className={iconClassName}/>
				{statusMessage}
			</div>
			{currentStatusIcon}
		</div>
	);
});
