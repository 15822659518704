import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteCheck} from '@src/store/modules/entities/check/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import React from 'react';

export function useCheckDelete(objectId: string, check: ICheck): [React.ReactNode, () => void] {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить проверку',
		`Вы действительно хотите удалить проверку №${check.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteCheck(objectId, check.id));
			goBack();
		}
	}, [objectId, check.id]);

	return [deleteDialog, handleDelete];
}
