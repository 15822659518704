import React from 'react';
import {changeEntitiesVisibility} from '@src/store/modules/settings/pages/space/actions';
import VisibilityFilter from '@src/components/VisibilityFilter';
import useAppSelector from '@src/core/hooks/useAppSelector';

const visibilityTitles = {
	problems: 'Нарушения',
	inspections: 'Осмотры'
};

interface IEntitiesVisibilityProps {
	className?: string;
	style?: React.CSSProperties;
}

const EntitiesVisibility = ({className, style}: IEntitiesVisibilityProps) => {
	const visibility = useAppSelector(s => s.settings.pages.space.entitiesVisibility);

	return (
		<VisibilityFilter
			className={className}
			style={style}
			value={visibility}
			titlesMap={visibilityTitles}
			onChange={changeEntitiesVisibility}
		/>
	);
};

export default EntitiesVisibility;
