import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByListId} from '@src/store/modules/pages/checkLists/selectors/records';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureCheckListLink} from '@tehzor/tools/utils/links';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {DiagramIcon} from '../DiagramIcon/DiagramIcon';
import {extractProblemsByCheckListIdAsArray} from '@src/store/modules/pages/checkLists/selectors/problems';
import declination from '@tehzor/tools/utils/declination';
import {format} from 'date-fns';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';

interface ICheckListProps {
	checkList: ICheckList;
	showDivider?: boolean;
}

// TODO Разнести компоненты по разным файлам
const CheckList = ({checkList, showDivider}: ICheckListProps) => {
	const {pushPath} = useChangePath();
	const {objectId, structureId} = useStrictParams<{objectId: string, structureId: string}>();
	const record = useAppSelector(s => extractCheckRecordByListId(s, checkList.id));
	const problems = useAppSelector(s => extractProblemsByCheckListIdAsArray(s, checkList.id));

	const handleClick = useCallback(() => {
		pushPath(
			formStructureCheckListLink({
				objectId,
				structureId,
				listId: checkList.id
			})
		);
	}, [checkList.id]);

	return (
		<div className="structure-page__check-lists">
			<div
				className="check-list-page__m-list"
				onClick={handleClick}
				role="presentation"
			>
				<DiagramIcon listId={checkList.id}/>
				<div className="check-list-page__m-list-text">
					<div className="check-list-page__m-list-text-title">{checkList.name}</div>
					<div className="check-list-page__m-list-text-subtitle">
						{record?.status === CheckRecordStatusId.ACCEPTED && record.modifiedAt
							? `принял ${format(record.modifiedAt, dateTimeLetterSeparatedFormat)} ${
									record.modifiedBy?.displayName
							  }`
							: problems.length > 0
							? `${problems.length} ${declination(problems.length, [
									'нарушение',
									'нарушения',
									'нарушений'
							  ])}`
							: null}
					</div>
				</div>
			</div>
			<ProblemIndicators checkListId={checkList.id}/>

			{showDivider && <hr className="check-list-page__divider"/>}
		</div>
	);
};

interface ICheckListsProps {
	checkLists: ICheckList[];
}

export const CheckLists = ({checkLists}: ICheckListsProps) => {
	const listsLoading = useAppSelector(s => s.dictionaries.checkLists.loading);

	return (
		<LoadingPanel active={listsLoading}>
			<Plate
				withoutPadding
				className="check-list-page__m-lists"
			>
				{checkLists.map((checkList, index) => (
					<CheckList
						key={checkList.id}
						checkList={checkList}
						showDivider={index + 1 !== checkLists.length}
					/>
				))}
			</Plate>
		</LoadingPanel>
	);
};
