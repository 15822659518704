import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IEditPlansOrderResponse, makePlansEditOrderRequest} from '@src/api/backend/plans';
import IPlanOrder from '@tehzor/tools/interfaces/plans/IPlanOrder';

export interface IEditPlansOrderPayload extends IEditPlansOrderResponse {
	objectId: string;
}

const request = () => ({type: types.EDIT_ORDER_REQUEST});

const success = (objectId: string, response: IEditPlansOrderResponse) => ({
	type: types.EDIT_ORDER_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении');
	return {
		type: types.EDIT_ORDER_FAILURE,
		payload: error
	};
};

/**
 * Менят порядок планов
 *
 * @param objectId id объекта
 * @param orders порядок планов
 */
export const editPlansOrder = (objectId: string, orders: IPlanOrder[]) =>
	createApiAction<IEditPlansOrderResponse>(
		request,
		res => success(objectId, res),
		failure,
		() => makePlansEditOrderRequest(objectId, orders)
	);
