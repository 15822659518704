import React, {useCallback} from 'react';
import './CheckListsLink.less';
import {Plate} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckListsLink} from '@tehzor/tools/utils/links';
import classNames from 'classnames';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

const classes = {root: 'check-lists-link', content: 'check-lists-link__content'};

interface ICheckListsLinkProps {
	objectId: string;
	stage: ObjectStageIds;
	spaceId?: string;
}

export const CheckListsLink = ({objectId, spaceId, stage}: ICheckListsLinkProps) => {
	const checkLists = useAvailableCheckLists(objectId, stage);
	const count = checkLists.length;
	const {pushPath} = useChangePath();
	const handleClick = useCallback(() => {
		pushPath(formCheckListsLink({objectId, spaceId, stage}));
	}, [stage, objectId, spaceId]);

	return (
		<button
			className={classNames('check-lists-link-wrapper', {
				'check-lists-link-wrapper__disabled': count === 0
			})}
			onClick={handleClick}
			type="button"
		>
			<Plate className={classes}>
				<i className="check-lists-link__icon tz-check-mark"/>
				<div className="check-lists-link__text">Чек-листы</div>
				<div className="check-lists-link__count">{count}</div>
				<div className="check-lists-link__arrow">
					{count > 0 && <i className="tz-simple-arrow-16"/>}
				</div>
			</Plate>
		</button>
	);
};
