import React, {useCallback, useState} from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {addResponsible} from '@src/store/modules/entities/users/actions/addResponsible';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import './AddingRespUserDialog.less';
import {useEditableRespUser} from '@src/components/EditableRespUser/hooks/useEditableRespUser';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCategories} from '@src/store/modules/dictionaries/categories/selectors';
import {extractPlans} from '@src/store/modules/dictionaries/plans/selectors';
import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';

interface IAddingProblemResponsibleDialogProps {
	isOpen: boolean;
	objectId?: string;
	defaultData?: IAddingRespUser;

	close(): void;
}

export const AddingRespUserDialog = ({
	isOpen,
	close,
	objectId,
	defaultData
}: IAddingProblemResponsibleDialogProps) => {
	if (!objectId) return null;
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const stages = useAppSelector(s => s.dictionaries.objectStages.byId);
	const categoriesAll = useAppSelector(extractCategories);
	const plans = useAppSelector(s => extractPlans(s, objectId));
	const [respUserFields, getSavingData, reset, isBlocking] = useEditableRespUser({
		saving,
		objectId,
		defaultData
	});

	const handleSave = useCallback(async () => {
		setSaving(true);
		const savingData = getSavingData();
		try {
			if (savingData) {
				const {categories, planId, ...rest} = savingData;
				// Временный код для отправки Имен вместо Id на почту
				const sendingData: IAddingRespUser = {...rest};

				if (categories) {
					// sendingData.categoryId = categories.byId[categoryId]?.name;
					sendingData.categories = categories.map(id => categoriesAll.byId[id]?.name);
				}

				if (planId) {
					sendingData.planId = plans.byId[planId]?.name;
				}

				await dispatch(addResponsible({...sendingData, objectId}));

				reset();
				close();
			}
		} finally {
			setSaving(false);
		}
	}, [getSavingData, categoriesAll, stages, plans]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, close]);

	return (
		<Dialog
			className={{root: 'adding-resp-user', content: 'adding-resp-user__container'}}
			isOpen={isOpen}
			title="Добавить ответственного"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={cancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{respUserFields}
			{closingDialog}
		</Dialog>
	);
};
