import {useMemo} from 'react';
import {IGetDocumentsResponse} from '@src/api/backend/documents';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {addErrorToast} from '@src/utils/toasts';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetDocumentResponse} from '@src/api/backend/document';

export const useDocuments = (): IGetDocumentsResponse | undefined => {
	const {data} = useQuery<IGetDocumentsResponse, IError>({
		queryKey: documentsQueryKeys.list(),
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке документов');
		}
	});
	return data;
};

export const useDocumentsArray = () => {
	const documents = useDocuments();

	if (!documents) return [];

	return Object.values(documents.byId);
};

/**
 * Функция фильтрует документы по ids и возвращает результат
 *
 * @param id Id сущности к которой привязан документ
 */
export const useFilteredDocumentsArray = (id?: string) => {
	const documentsArray = useDocumentsArray();

	return useMemo(() => {
		if (!documentsArray.length || !id) return [];

		return documentsArray.filter(
			document => document.links?.some(link => link?.entityObjectId === id)
		);
	}, [documentsArray, id]);
};

export const useDocument = (documentId?: string) => {
	if (!documentId) return undefined;

	const {data} = useQuery<IGetDocumentResponse, IError>({
		queryKey: [...documentsQueryKeys.details(), documentId],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке документа');
		}
	});

	return data;
};
