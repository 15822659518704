import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import declination from '@tehzor/tools/utils/declination';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/reducers';
import {extractOwnerAcceptancesData} from '@src/store/modules/entities/ownerAcceptances/selectors';

interface IOwnerAcceptancesPageHeaderProps {
	objectId?: string;
}

export const OwnerAcceptancesPageCounter = ({
	objectId = 'all'
}: IOwnerAcceptancesPageHeaderProps) => {
	const ownerAcceptancesData = useAppSelector(s =>
		extractOwnerAcceptancesData(s, objectId));

	const {state} = useEntitiesFiltersCtx<IOwnerAcceptancesFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${isFiltersActive ? 'Найдено:' : 'Всего:'} ${
		ownerAcceptancesData.total
	} ${declination(ownerAcceptancesData.total || 0, [
		'передача собственникам',
		'передачи собственникам',
		'передач собственникам'
	])}`;

	return (
		<PageHeader
			className="owner-acceptances-page__header"
			title=""
			subTitle={text}
			wrapClassName="owner-acceptances-page__header-wrap"
			leftSideClassName="owner-acceptances-page__header-left"
			rightSideClassName="owner-acceptances-page__header-right"
		/>
	);
};
