import React from 'react';
import {StatsTreePanel} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ProblemsStatsChart from './ProblemsStatsChart';
import {StatsByStatus} from '@src/utils/statsAggregators/problemsByStatuses';
import {useNavigateProblems} from '@src/pages/StatisticsPage/hooks/useNavigateProblems';
import {sumStatsValues} from '@src/pages/StatisticsPage/utils/sumStatsValues';

interface IWorkerStatsProps {
	userId: string;
	stats?: StatsByStatus;
	level?: number;
}

export const WorkerStats = ({userId, stats, level}: IWorkerStatsProps) => {
	const totalCount = stats?.expired
		? sumStatsValues(stats) - stats?.expired
		: sumStatsValues(stats);

	const user = useAppSelector(s => s.dictionaries.users.byId[userId]);
	if (!user) {
		return null;
	}

	const navigate = useNavigateProblems(
		{
			navProps: [
				{
					type: 'respUsers',
					prop: user.id
				}
			],
			page: 'warranty'
		},
		undefined
	);

	const handleClick = (status: string) => {
		navigate(status);
	};

	return (
		<StatsTreePanel
			id={user.id}
			title={user.fullName}
			subTitle={user.position}
			level={level}
			permanentContent={(
				<ProblemsStatsChart
					onClick={handleClick}
					stats={stats}
					totalCount={totalCount}
					level={level}
				/>
			)}
		/>
	);
};
