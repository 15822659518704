import React from 'react';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {HistoryItem, IHistoryItemProps} from '../../HistoryItem';
import {changeComponentByType} from '../utils/changeComponentByType';

interface IUniversalHistoryItemProps {
	className?: IHistoryItemProps['className'];
	data: IHistoryData;
}

export const UniversalHistoryItem = ({data, className}: IUniversalHistoryItemProps) => {
	const Component = changeComponentByType[data.type];

	return Component ? (
		<HistoryItem
			className={className}
			prev={<Component value={data.prev}/>}
			next={<Component value={data.next}/>}
		/>
	) : null;
};
