import {wsConnector} from '../wsConnector';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ISavingObject} from '@tehzor/tools/interfaces/objects/ISavingObject';

export type IAddObjectResponse = IObject;

/**
 * Добавляет объект
 *
 * @param object данные объекта
 */
export const makeObjectAddRequest = (object: ISavingObject) =>
	wsConnector.sendAuthorizedRequest<IAddObjectResponse>('addObject', {...object});
