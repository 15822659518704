import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export type IEditableStructureState = IEditableEntityState<{
	name?: string;
	type?: string;
	planId?: string;
}>;

export type IEditableStructureAction = IEditableEntityAction<IEditableStructureState, IStructure>;

const makeEmptyState = (): IEditableStructureState => ({
	name: '',
	type: undefined,
	planId: undefined,

	errors: {
		name: false,
		type: false,
		planId: false,
	}
});

export const init = (structure?: IStructure): IEditableStructureState => {
	const empty = makeEmptyState();
	return structure
		? {
				name: structure.name || '',
				type: structure.type || undefined,
				planId: structure.planId || undefined,
				errors: empty.errors
		  }
		: empty;
};

const isNameEdited = (state: IEditableStructureState, original?: IStructure) =>
	(original?.name ? original.name !== state.name : !!state.name);

const isTypeEdited = (state: IEditableStructureState, original?: IStructure) =>
	(original?.type ? original.type !== state.type : !!state.type);

const isPlanIdEdited = (state: IEditableStructureState, original?: IStructure) =>
	(original?.planId ? original.planId !== state.planId : !!state.planId);

/**
 * Возвращает значение, показывающее были ли отредактированы поля нарушения
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableStructureState, original?: IStructure): boolean =>
	isEntityEdited(state, original, isNameEdited, isTypeEdited, isPlanIdEdited);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	name: (state: IEditableStructureState) => !state.name,
	type: (state: IEditableStructureState) => !state.type,
	planId: (state: IEditableStructureState) => !state.planId

};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableStructureState,
	original?: IStructure,
	onlyEdited?: boolean
): ISavingStructure => {
	if (!onlyEdited) {
		return {
			name: state.name,
			type: state.type
		};
	}
	const structure: ISavingStructure = {};
	if (isNameEdited(state, original)) {
		structure.name = state.name;
	}
	if (isTypeEdited(state, original)) {
		structure.type = state.type;
	}
	if (isPlanIdEdited(state, original)) {
		structure.planId = state.planId;
	}

	return structure;
};
