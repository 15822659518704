import {PrivateRoute} from '@src/containers/PrivateRoute';
import React from 'react';
import {AllInspectionsPage} from '@src/pages/InspectionsPage';
import InspectionsFiltersRoutingPage from '@src/pages/filters/InspectionsFiltersRoutingPage';
import {inspectionsFilterRoutes} from './inspectionsFilterRoutes';

export const inspectionsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllInspectionsPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<InspectionsFiltersRoutingPage/>}/>,
		children: inspectionsFilterRoutes
	}
];
