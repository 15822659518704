import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {getObjects} from '@src/store/modules/dictionaries/objects/actions';
import useAsync from 'react-use/lib/useAsync';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractCurrentObjectByExternalId} from '@src/store/modules/dictionaries/objects/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
// @ts-ignore
import NotFoundPage from '../../pages/NotFoundPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const ExternalObjectRoot = () => {
	const {externalId} = useStrictParams<{externalId: string}>();
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await dispatch(getObjects());
	}, []);

	const {pathname} = useLocation();
	const object = useAppSelector(s => extractCurrentObjectByExternalId(s, externalId));
	if (object) {
		return <Navigate to={pathname.replace(`external/${externalId}`, object.id)}/>;
	}
	return <NotFoundPage/>;
};
