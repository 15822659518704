import {IState} from '@src/store/modules';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetStructuresResponse, requestStructures} from '@src/api/backend/structures';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {AppThunkAction} from '@src/store/interfaces/thunk';

export interface IGetStructuresPayload extends IGetStructuresResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_LIST_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetStructuresResponse) => ({
	type: types.GET_LIST_SUCCESS,
	payload: {
		...response,
		objectId
	} as IGetStructuresPayload
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка структур');
	return {
		type: types.GET_LIST_FAILURE,
		payload: error
	};
};

/**
 * Получает список структур для таблицы конкретного объекта
 *
 * @param objectId id объекта
 */
export const getStructuresList
	= (objectId: string): AppThunkAction<Promise<IGetStructuresResponse | undefined>> =>
	(dispatch, getState) =>
		dispatch(
			checkLoaded<IState, IGetStructuresResponse, ApiAction>(
				s => s.entities.structures.lists[objectId],
				createApiAction<IGetStructuresResponse>(
					() => request(objectId),
					(response: IGetStructuresResponse) => success(objectId, response),
					failure,
					async () => {
						const s = getState();
						if (
							!s.entities.structures.lists[objectId]
							|| !s.settings.pages.structures.byPage[objectId]
						) {
							throw new StoreError('Empty store before getting structures');
						}
					
						const {sort} = s.settings.pages.structures.byPage[objectId];

						const f = {
							objects: objectId !== 'all' && [objectId]
						};

						return requestStructures(f, sort);
					}
				)
			)
		);
