import React, {memo, useMemo, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {extractStructureTypesAsArray} from '@src/store/modules/dictionaries/structureTypes/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface IStructureTypesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const StructureTypesFilterMobile = memo((props: IStructureTypesFilterMobileProps) => {
	const [search, setSearch] = useState('');

	const structureTypes = useAppSelector(extractStructureTypesAsArray);
	const filteredData = useMemo(() => flatFilter(structureTypes, 'name', search), [structureTypes, search]);

	return (
		<BaseListFilterMobile
			{...props}
			options={filteredData}
			label="Типы структур"
			filterName="types"
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
		/>
	);
});
