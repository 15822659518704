import React, {Dispatch, useCallback, useMemo, useState} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useUpdateEffect} from 'react-use';
import {makeTreeDataStructure} from './utils/makeTreeData';
import {extractStructuresListAsArray} from '@src/store/modules/entities/structures/selectors/lists';
import {extractStructureTypesAsArray} from '@src/store/modules/dictionaries/structureTypes/selectors';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import './StructureTreeSelect.less';
import {StructureLocationSelect} from './StructureLocationSelect';

interface IStructureTreeSelectProps<S, E> {
	objectId: string;
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: string;
	value?: string[];
	locations?: Record<string, { planId: string, location: ILocation }>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const StructureTreeSelect = <S extends { structureIds?: string[], locations?: ILocation | null }, E>({
	objectId,
	className,
	style,
	field = 'structureIds',
	label = 'Структуры',
	value,
	locations,
	editingDispatch,
	required,
	disabled,
	hasError
}: IStructureTreeSelectProps<S, E>) => {
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);

	const structuresById = useAppSelector(s => s.entities.structures.lists[objectId]?.byId);

	const structures = useAppSelector(s => extractStructuresListAsArray(s, objectId));

	const structureTypes = useAppSelector(extractStructureTypesAsArray);

	const treeData = useMemo(
		() => makeTreeDataStructure(structures, structureTypes),
		[structures, structureTypes]
	);

	const locationByStructure = locations
		? value?.map(id => ({structureId: id, location: locations ? locations[id] : undefined})) : undefined;

	const handleChange = useCallback(() => {
		editingDispatch({type: 'update', field, value: selectedObjects});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [required, selectedObjects, field, editingDispatch]);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
	}, []);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
	}, [value]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	useUpdateEffect(() => {
		setSelectedObjects(value);
	}, [value]);

	return (
		<div
			className={`${className} structure-tree-select_structure-select-area`}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			{structuresById && (
				<SelectPopup
					trigger={(
						<TextFieldWithForwardedRef
							elementType="div"
							value={
								value && value?.length > 0
									? value.length > 1
										? `${structuresById[value[0]]?.name} + ${value.length - 1}`
										: `${structuresById[value[0]]?.name}`
									: undefined
							}
							icon={<i className="tz-simple-arrow-20"/>}
							error={required && hasError ? 'Выберите структуры' : undefined}
							disabled={disabled}
						/>
					)}
					clearButton={!!selectedObjects?.length}
					footer
					noHeader
					count={selectedObjects?.length}
					onApply={handleChange}
					onCancel={handleCancel}
					onClear={handleClear}
				>
					<TreeSelect
						data={treeData}
						multiple
						value={selectedObjects}
						onChange={setSelectedObjects}
						expandedValue={expandedObjects}
						onExpand={handleExpand}
					/>
				</SelectPopup>
			)}
			{(locations && !!locationByStructure?.length) && (
				<div className="structure-tree-select_locations">
					<EditableFieldLabel className="structure-tree-select_locations_label">Области приёмки</EditableFieldLabel>
					<div className="structure-tree-select structure-tree-select_location-select-area structure-tree-select__info-grid">
						{locationByStructure && locationByStructure.map(structureEl => (
							<StructureLocationSelect
								locations={locations}
								objectId={objectId}
								structureEl={structureEl}
								editingDispatch={editingDispatch}

							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};
