import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {
	getInitialStateForPage,
	ISpacesSchemaPagesState,
	ISpacesSchemaPageState
} from '../reducers/schemas';
import {
	ISpacesFiltersState,
	ISpacesPageSettingsState
} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {extractSpaceIndicatorsForObjectsAsMap} from '@src/store/modules/dictionaries/spaceIndicatorsSets/selectors';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IConvertedProblemsData} from '@tehzor/tools/interfaces/spaces/IConvertedProblemsData';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpacesPagesSharedSettingsState} from '@src/store/modules/settings/pages/spaces/reducers/shared';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {ISpaceCheckListsData} from '@tehzor/tools/interfaces/entititesComputedData/ISpaceCheckListsData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	IConvertedCheckItemData,
	IConvertedCheckListData
} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';
import {
	CheckRecordStatusId,
	ICheckRecordStatus
} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {
	ICheckItemStats,
	ICheckListsStats,
	ICheckListStats
} from '@tehzor/tools/interfaces/checkLists/ICheckListStats';
import {ISpacesCheckListsStatsState} from '@src/store/modules/pages/spaces/reducers/checkListsData';
import {ISpacesProblemsStatsState} from '@src/store/modules/pages/spaces/reducers/problemsData';
import {extractCheckItemsGroupedByLists} from '@src/store/modules/dictionaries/checkItems/selectors';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {extractSpaceStatusesData} from '@src/store/modules/dictionaries/spaceStatuses/selectors';

const getSpacesPages = (state: IState): ISpacesSchemaPagesState => state.entities.spaces.schemas;
const getObjectIds = (state: IState, objectIds: string[]): string[] => objectIds || [];

/**
 * Возвращает количества нарушений по статусам для текущей стадии, если стадия не указана, то по всем стадиям
 *
 * @param problemsStats данные по нарушениям из помещения
 * @param stage текущая стадия
 */
const getProblemsDataByStage = (
	problemsStats: IEntityProblemsData,
	stage?: ObjectStageIds
): Record<string, {count: number, critical: boolean}> | undefined => {
	if (stage) {
		return problemsStats.problems?.[stage];
	}
	const problems = problemsStats.problems;
	return problems
		? Object.values(problems).reduce<Record<string, {count: number, critical: boolean}>>(
				(prev, item) => {
					for (const status in item) {
						if (item.hasOwnProperty(status)) {
							if (prev[status]) {
								prev[status].count += item[status].count;
								prev[status].critical
									= prev[status].critical || item[status].critical;
							} else {
								prev = {
									...prev,
									[status]: {
										count: item[status].count,
										critical: item[status].critical
									}
								};
							}
						}
					}
					return prev;
				},
				{}
		  )
		: undefined;
};

const isEveryCheckListIncludesTypeDecoration = (
	checkListIds: string[],
	checkLists?: INormalizedData<ICheckList>
): boolean =>
	checkListIds.every(checkListId => {
		const checkList = checkLists?.byId?.[checkListId];

		return !!(checkList && checkList.typeDecoration && checkList.typeDecoration?.length > 0);
	});

const isAnyCheckListIncludesTypeDecorationAsInSpace = (
	checkListIds: string[],
	space: IListSpace,
	checkLists?: INormalizedData<ICheckList>
): boolean =>
	checkListIds?.some(checkListId => {
		const checkList = checkLists?.byId?.[checkListId];

		return !!(
			checkList?.typeDecoration
			&& space?.typeDecoration
			&& checkList.typeDecoration?.includes(space.typeDecoration)
		);
	});

const filterCheckLists = (
	checkLists: INormalizedData<ICheckList>,
	companyId: string,
	objectId: string,
	spaceType: SpaceTypeId,
	selectedStage: ObjectStageIds | undefined,
	selectedCheckLists: string[] | undefined,
	spaceTypeDecoration: string | undefined = undefined
): ICheckList[] =>
	checkLists.allIds.reduce<ICheckList[]>((prev, id) => {
		if (!selectedCheckLists || selectedCheckLists.includes(id)) {
			const checkList = checkLists.byId[id];
			if (checkList) {
				if (
					checkList.companyId === companyId
					&& checkList.objects?.includes(objectId)
					&& checkList.stage === selectedStage
					&& checkList.spaceTypes?.includes(spaceType)
					&& (checkList.typeDecoration?.length === 0
						|| (spaceTypeDecoration
							&& checkList.typeDecoration?.includes(spaceTypeDecoration)))
				) {
					prev.push(checkList);
					return prev;
				}
				if (
					checkList.companyId === companyId
					&& (!checkList.objects
						|| checkList.objects.length === 0
						|| checkList.objects.includes(objectId))
					&& (!selectedStage || checkList.stage === selectedStage)
					&& (!checkList.spaceTypes || checkList.spaceTypes.includes(spaceType))
					&& (checkList.typeDecoration?.length === 0
						|| (spaceTypeDecoration
							&& checkList.typeDecoration?.includes(spaceTypeDecoration)))
				) {
					prev.push(checkList);
				}
			}
		}
		return prev;
	}, []);

export const filterSpaces = (
	spaces: IListSpace[],
	filters: ISpacesFiltersState,
	statusesMap?: Record<string, ISpaceStatus>,
	selectedStage?: ObjectStageIds,
	checkLists?: INormalizedData<ICheckList>,
	companyId?: string,
	selectedCheckLists?: string[],
	checkItemsByLists?: Record<string, ICheckItem[]>
) => {
	const {
		statuses,
		indicators,
		problemStatuses,
		types,
		objects,
		checkListStatuses,
		checkListCategoryStatuses,
		checkListCategory,
		checkListIds,
		typeDecoration
	} = filters;

	return spaces.filter(space => {
		let result: boolean | undefined = true;
		if (statuses) {
			result &&= statuses.includes(space.status);
		}
		if (indicators) {
			result &&= space.indicators?.some(indicator => indicators.includes(indicator));
		}
		if (problemStatuses && space.problems) {
			const problemsStats = getProblemsDataByStage(space.problems, selectedStage);
			result
				&&= problemsStats && problemStatuses?.some(status => problemsStats[status]?.count > 0);
		}
		if (types) {
			result &&= types.includes(space.type);
		}

		if (objects && objects.length > 0) {
			result &&= objects.includes(space.objectId);
		}
		if (typeDecoration) {
			result &&= space.typeDecoration ? typeDecoration.includes(space.typeDecoration) : false;
		}
		// Если выбраны чек-листы, и все из них имеют тип отделки, то оставить
		// только те помещения у которых совпадают типы отделки с чек-листами
		if (checkListIds && checkListIds?.length > 0) {
			const isEvery = isEveryCheckListIncludesTypeDecoration(checkListIds, checkLists);

			if (isEvery) {
				const isAny = isAnyCheckListIncludesTypeDecorationAsInSpace(
					checkListIds,
					space,
					checkLists
				);

				if (!isAny) {
					result &&= false;
				}
			}
		}
		// TODO: (Ilya - 09.09.2022) описать что происходит внутри этого монстра
		if (checkListStatuses && checkListStatuses.length > 0 && checkLists) {
			const checkListsStats: ICheckListsStats = space.checkLists
				? selectedStage
					? space.checkLists[selectedStage]
					: Object.keys(space.checkLists).reduce(
							(acc: ICheckListsStats, stage: string) => ({
								...acc,
								...space.checkLists![stage]
							}),
							{} as ICheckListsStats
					  )
				: ({} as ICheckListsStats);

			const filteredCheckLists = filterCheckLists(
				checkLists,
				companyId!,
				space.objectId,
				space.type,
				selectedStage,
				undefined,
				space.typeDecoration
			);

			const checkListsStatsValues: ICheckListStats[] = checkListsStats
				? Object.keys(checkListsStats)
						.filter(cl =>
							(checkListIds && checkListIds.length > 0
								? checkListIds.includes(cl)
								: filteredCheckLists.some(
										filteredCheckList => filteredCheckList.id === cl
								  )))
						.reduce((acc: ICheckListStats[], key: string) => {
							acc.push(checkListsStats[key]);
							return acc;
						}, [] as ICheckListStats[])
				: ([] as ICheckListStats[]);

			result &&= checkListStatuses?.some(
				(status: string) =>
					checkListsStatsValues?.some(value => status === value.status)
					|| (status === 'not-checked'
						&& filteredCheckLists.filter(fcl =>
							(checkListIds && checkListIds.length > 0
								? checkListIds.includes(fcl.id)
								: true)).length > checkListsStatsValues.length)
			);
		}
		// TODO описать что происходит внутри этого монстра
		if (checkListCategoryStatuses && checkListCategoryStatuses.length > 0 && checkLists) {
			const checkListsStats: ICheckListsStats = space.checkLists
				? selectedStage
					? space.checkLists[selectedStage]
					: Object.keys(space.checkLists).reduce(
							(acc: ICheckListsStats, stage: string) => ({
								...acc,
								...space.checkLists![stage]
							}),
							{} as ICheckListsStats
					  )
				: ({} as ICheckListsStats);

			const filteredCheckLists = filterCheckLists(
				checkLists,
				companyId!,
				space.objectId,
				space.type,
				selectedStage,
				selectedCheckLists,
				space.typeDecoration
			);

			const checkItemStats: ICheckItemStats[] = checkListsStats
				? Object.keys(checkListsStats)
						.filter(cl =>
							(checkListIds && checkListIds.length > 0
								? checkListIds.includes(cl)
								: filteredCheckLists.some(
										filteredCheckList => filteredCheckList.id === cl
								  )))

						.reduce((acc: ICheckItemStats[], key: string) => {
							const checkList: ICheckListStats = checkListsStats[key];
							if (checkList !== undefined) {
								acc.push(checkList.items);
							}
							return acc;
						}, [] as ICheckItemStats[])
				: ([] as ICheckItemStats[]);

			const items: CheckRecordStatusId[] = checkItemStats
				? checkItemStats
						.map(
							(
								el: ICheckItemStats
							): Array<{key: string, value: CheckRecordStatusId}> =>
								Object.keys(el).map(key => ({key, value: el[key]}))
						)
						.flat()
						.filter(el =>
							(checkListCategory && checkListCategory.length > 0
								? checkListCategory.includes(el.key)
								: true))
						.map((el: {key: string, value: CheckRecordStatusId}) => el.value)
				: ([] as CheckRecordStatusId[]);

			const totalItems: ICheckItem[] = filteredCheckLists
				? filteredCheckLists
						.reduce((acc: ICheckItem[][], key) => {
							const items = checkItemsByLists ? checkItemsByLists[key.id] : undefined;
							if (items !== undefined) {
								acc.push(items);
							}
							return acc;
						}, [] as ICheckItem[][])
						.flat()
						.filter(
							el =>
								!el.parentId
								&& (checkListCategory && checkListCategory.length > 0
									? checkListCategory.includes(el.id)
									: true)
						)
				: [];

			result
				&&= checkListCategoryStatuses?.some((el: string) => items?.some(val => val === el))
				|| (totalItems
					&& totalItems.length > items.length
					&& checkListCategoryStatuses.includes('not-checked'));
		}

		return result;
	});
};

const convertProblemsData = (
	problems: IEntityProblemsData | undefined,
	problemStatuses: INormalizedData<IProblemStatus>,
	selectedStage?: ObjectStageIds
): IConvertedProblemsData[] => {
	if (!problems) {
		return [];
	}

	let total = 0;
	const result = [];

	const stageProblems = getProblemsDataByStage(problems, selectedStage);
	if (stageProblems) {
		for (const statusId of problemStatuses.allIds) {
			if (stageProblems.hasOwnProperty(statusId) && stageProblems[statusId].count > 0) {
				result.push({
					key: statusId,
					value: stageProblems[statusId].count,
					percent: 0,
					color: problemStatuses.byId[statusId]?.color || '#00000000',
					startPoint: 0,
					critical:
						statusId !== ProblemStatusId.FIXED
							? stageProblems[statusId].critical
							: undefined
				});
				total += stageProblems[statusId].count;
			}
		}
		let offset = 0;
		for (const stat of result) {
			stat.percent = (stat.value / total) * 100;
			stat.startPoint = -offset;
			offset += stat.percent;
		}
	}
	return result;
};

const getCheckRecordStatus = (
	statusId: CheckRecordStatusId,
	statusesMap: Record<string, ICheckRecordStatus>
) => statusesMap[statusId];

const convertCheckItemsData = (
	items: ICheckItem[] | undefined,
	itemsData: Record<string, CheckRecordStatusId> | undefined,
	checkRecordStatusesMap: Record<string, ICheckRecordStatus>,
	selectedCheckListCategory?: string[]
) =>
	(items
		? items.reduce<IConvertedCheckItemData[]>((prev, item) => {
				if (!item.parentId) {
					const statusId = itemsData?.[item.id] ?? CheckRecordStatusId.NOT_CHECKED;
					const status = getCheckRecordStatus(statusId, checkRecordStatusesMap);
					if (
						selectedCheckListCategory === undefined
						|| selectedCheckListCategory?.length === 0
						|| selectedCheckListCategory?.includes(item.id)
					) {
						prev.push({
							id: item.id,
							name: item.name,
							status: statusId,
							statusName: status.name,
							color: status.color
						});
					}
				}
				return prev;
		  }, [])
		: []);

const convertCheckListsData = (
	spaceId: string,
	checkLists: ICheckList[],
	checkListsData: ISpaceCheckListsData | undefined = {},
	checkItemsByLists: Record<string, ICheckItem[]>,
	checkRecordStatuses: INormalizedData<ICheckRecordStatus>,
	selectedCheckListCategory?: string[]
): IConvertedCheckListData[] => {
	let total = 0;
	const result: IConvertedCheckListData[] = [];

	for (const checkList of checkLists) {
		// Если будет множественная привязка чек-листа к стадиям, то здесь надо будет брать
		// данные по чек-листу в каждой его стадии (в тех стадиях, которые выбраны в фильтре).
		const checkListData = checkListsData[checkList.stage]?.[checkList.id];
		// В случае множественной привязки чек-листа к стадиям,
		// надо будет определиться какой статус брать из нескольких,
		// либо дублировать чек-листы с разными статусами.
		const statusId = checkListData?.status ?? CheckRecordStatusId.NOT_CHECKED;
		const status = getCheckRecordStatus(statusId, checkRecordStatuses.byId);
		const problems: IConvertedCheckListData['problems'] = checkListData?.problems ?? [];

		const checkListsItems = checkItemsByLists[checkList.id];

		const value = checkListsItems
			? checkListsItems.filter(el => el.parentId === undefined).length
			: 0;
		total += value;

		result.push({
			id: checkList.id,
			key: checkList.id,
			name: checkList.name,
			value,
			percent: 0,
			startPoint: 0,
			status: statusId,
			statusName: status.name,
			color: status.color,
			items: convertCheckItemsData(
				checkItemsByLists[checkList.id],
				checkListData?.items,
				checkRecordStatuses.byId,
				selectedCheckListCategory
			),
			problems
		});
	}
	let offset = 0;
	for (const stat of result) {
		stat.percent = ((stat?.value || 0) / total) * 100;
		stat.startPoint = -offset;
		offset += stat.percent;
	}

	return result;
};

export const convertSpaces = (
	spaces: IListSpace[],
	spacesStats: {
		checkListsData: Record<string, ISpacesCheckListsStatsState>;
		problemsData: Record<string, ISpacesProblemsStatsState>;
	},
	objectId: string
) =>
	spaces.map(space => ({
		...space,
		problems: spacesStats.problemsData[objectId]?.data[space.id],
		checkLists: spacesStats.checkListsData[objectId]?.data[space.id]
	}));

export const enrichSpaces = (
	spaces: IListSpace[],
	problemStatuses: INormalizedData<IProblemStatus>,
	spaceStatuses: Record<string, ISpaceStatus>,
	spaceIndicators: Record<string, ISpaceIndicator>,
	checkLists: INormalizedData<ICheckList>,
	checkItemsByLists: Record<string, ICheckItem[]>,
	checkRecordStatuses: INormalizedData<ICheckRecordStatus>,
	selectedStage: ObjectStageIds | undefined,
	selectedCheckLists: string[] | undefined,
	altNamesVisible: boolean | undefined,
	companyId: string,
	selectedCheckListCategory?: string[]
): IEnrichedSpace[] =>
	spaces.map(space => {
		const {name, altName, status, indicators, ...restData} = space;

		return {
			...restData,
			name: altNamesVisible ? altName || '' : name,
			sortBase: name,
			status: spaceStatuses[status],
			indicators: indicators?.map(id => spaceIndicators[id]).filter(item => item),
			problems: convertProblemsData(space.problems, problemStatuses, selectedStage),
			checkLists: convertCheckListsData(
				space.id,
				filterCheckLists(
					checkLists,
					companyId,
					space.objectId,
					space.type,
					selectedStage,
					selectedCheckLists,
					space.typeDecoration
				),
				space.checkLists,
				checkItemsByLists,
				checkRecordStatuses,
				selectedCheckListCategory
			)
		};
	});

/**
 * Извлекает данные для шахматки помещений конкретного объекта
 */
export const extractSpacesSchemaData = createSelector(
	getSpacesPages,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(rootData, objectId) => rootData[objectId] || getInitialStateForPage()
);

/**
 * Возвращает помещения для конкретного объекта в виде массива
 */
export const extractSpacesAsArray = createSelector([extractSpacesSchemaData], data =>
	data.allIds.map((id: string) => data.byId[id]).sort((a, b) => +a.name - +b.name));

/**
 * Возвращает помещения для нескольких объектов
 */
export const extractSpacesPagesForObjects = createSelector(
	[getSpacesPages, getObjectIds],
	(pages, objectIds) =>
		objectIds.reduce<ISpacesSchemaPageState[]>((acc, objectId) => {
			if (pages[objectId]) {
				acc.push(pages[objectId]);
			}
			return acc;
		}, [])
);

/**
 * Возвращает помещения для нескольких объектов в виде массива
 */
export const extractSpacesPagesForObjectsAsArray = createSelector(
	[extractSpacesPagesForObjects],
	(data: ISpacesSchemaPageState[]) =>
		data
			.reduce((acc: IListSpace[], el: ISpacesSchemaPageState) => {
				el.allIds.forEach((id: string) => {
					acc.push(el.byId[id]);
				});
				return acc;
			}, [] as IListSpace[])
			.sort((a, b) => +a.name - +b.name)
);

/**
 * Возвращает помещения с расширенной информацией в виде массива, отфильтрованные по переданным фильтрам
 */
export const extractSpacesAsArrayWithFiltered = createSelector(
	[
		extractSpacesPagesForObjectsAsArray,
		(a1, objectIds: string[]) => objectIds,
		s => s.dictionaries.problemStatuses,
		extractSpaceStatusesData,
		extractSpaceIndicatorsForObjectsAsMap,
		s => s.dictionaries.checkLists,
		extractCheckItemsGroupedByLists,
		s => s.dictionaries.checkRecordStatuses,
		s => s.pages.spaces,
		(a1, a2, object: IObject) => object,
		(a1, a2, a3, pageSettings: ISpacesPageSettingsState) => pageSettings,
		(a1, a2, a3, a4, sharedSettings: ISpacesPagesSharedSettingsState) => sharedSettings
	],
	(
		spaces,
		objectIds,
		problemStatuses,
		spaceStatuses,
		indicators,
		checkLists,
		checkItems,
		checkRecordStatuses,
		spacesStats,
		pageObject,
		{stage, filters},
		{altNamesVisible}
	) => objectIds.reduce((
		acc: Record<
			string,
			{
				spaces: IEnrichedSpace[];
				filtered: IListSpace[];
			}
		>,
		objectId: string
	) => {
		const convertedSpaces = convertSpaces(
			spaces.filter(space => space.objectId === objectId),
			spacesStats,
			objectId
		);
		const filtered = filterSpaces(
			convertedSpaces,
			filters,
			spaceStatuses.byId,
			stage,
			checkLists,
			pageObject.companyId,
			filters.checkListIds,
			checkItems
		);
		const enrichedSpaces = enrichSpaces(
			convertedSpaces,
			problemStatuses,
			spaceStatuses.byId,
			indicators.byId,
			checkLists,
			checkItems,
			checkRecordStatuses,
			stage,
			filters.checkListIds,
			altNamesVisible,
			pageObject.companyId,
			filters.checkListCategory
		);
		acc[objectId] = {spaces: enrichedSpaces, filtered};
		return acc;
	}, {})
);

export const extractSpacesGroupedByTypes = createSelector(
	[
		(s: IState) => s.dictionaries.spaceTypes.allIds,
		(s: IState) => s.pages.spaces,
		extractSpacesPagesForObjectsAsArray,
		(s: IState, objectIds: string[]) => objectIds
	],
	(spaceTypes, spaceStats, spaces, objectIds) => {
		const spacesGroupedByTypes = {} as Record<
			string, Record<string, IListSpace[]>
		>;
		for (const type of spaceTypes) {
			let haveSpaces = false;

			const spacesGroupedByObjects = objectIds.reduce((
				acc: Record<string, IListSpace[]>,
				objectId: string
			) => {
				const filteredSpaces = spaces.filter(space =>
					space.objectId === objectId && space.type === type);
				const convertedSpaces = convertSpaces(
					filteredSpaces, spaceStats, objectId
				);

				haveSpaces = haveSpaces || !!convertedSpaces.length;

				acc[objectId] = convertedSpaces;
				return acc;
			}, {});

			if (haveSpaces) {
				spacesGroupedByTypes[type] = spacesGroupedByObjects;
			}
		}
		return spacesGroupedByTypes;
	}
);

/**
 * Извлекает статус загрузки
 */
export const extractSpacesSchemasLoadingState = createSelector([getSpacesPages], data => {
	let result = false;
	for (const key of Object.keys(data)) {
		if (!data[key].loaded) {
			result = true;
		}
	}
	return result;
});
