import React from 'react';
import './FilterMenu.less';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {IPopupBasicProps} from '../../containers/Popup';
import MenuLayer from '../MenuLayer';
import {FilterButton} from '../../buttons';

interface IFilterMenuProps<V, M extends IMenuComponentProps<V>> {
	className?:
		| string
		| {
				root?: string;
				button?: string;
				wrap?: string;
				label?: string;
				icon?: string;
		  };
	style?: React.CSSProperties;
	children?: React.ReactElement<M> | Array<React.ReactElement<IMenuItemProps>>;
	label?: React.ReactNode;
	active?: boolean;
	disabled?: boolean;
	popupArrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
}

const FilterMenu = <V, M extends IMenuComponentProps<V>>(props: IFilterMenuProps<V, M>) => {
	const {className, style, children, label, active, disabled, popupArrowVisible, popupProps} = props;

	const classes = convertClassNames(className);

	return (
		<MenuLayer
			trigger={({isOpen, toggle, ref, disabled: triggerDisabled}) => (
				<FilterButton
					className={{
						root: classNames('filter-menu', classes.root),
						button: classNames('filter-menu__button', classes.button),
						bntWrap: classes.wrap,
						btnLabel: classNames('filter-menu__button-label', classes.label),
						btnIcon: classNames(
							'filter-menu__button-icon',
							{'filter-menu__button-icon_open': isOpen},
							classes.icon
						)
					}}
					style={style}
					label={label}
					icon={<i className="tz-simple-arrow-16"/>}
					active={active || isOpen}
					disabled={disabled || triggerDisabled || !children}
					onClick={toggle}
					ref={ref}
				/>
			)}
			popupArrowVisible={popupArrowVisible}
			popupProps={{placement: 'bottom-start', preferX: 'left', ...popupProps}}
		>
			{children}
		</MenuLayer>
	);
};

FilterMenu.displayName = 'FilterMenu';

export default FilterMenu;
