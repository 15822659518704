import React, {useMemo} from 'react';
import {SelectPopup, TextFieldWithForwardedRef, TreeSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';

interface IObjectSelectProps {
	selectObjectsIds: string[];
	onSelect: (ObjectsIds: string[]) => void;
}

export const ObjectSelect = (props: IObjectSelectProps) => {
	const objectsData = useAppSelector(s => s.dictionaries.objects);
	const objectsMap = objectsData.byId;
	const objectsIds = objectsData.allIds;

	const treeData = useMemo(() => {
		const objects = objectsIds.map(id => objectsData.byId[id]);
		return makeObjectsTreeData(objects);
	}, [objectsData, objectsIds]);

	const {selectObjectsIds, onSelect} = props;

	return (
		<SelectPopup
			trigger={(
				<TextFieldWithForwardedRef
					className="categories-set-migration-page__select"
					elementType="div"
					value={
					selectObjectsIds && selectObjectsIds.length > 0
						? selectObjectsIds.length > 1
							? `${objectsMap[selectObjectsIds[0]]?.name} + ${selectObjectsIds.length - 1}`
							: `${objectsMap[selectObjectsIds[0]]?.name}`
						: undefined
				}
					icon={<i className="tz-simple-arrow-20"/>}
				/>
			)}
			clearButton={!!selectObjectsIds?.length}
			footer
			noHeader
			count={selectObjectsIds?.length}
			onClear={() => onSelect([])}
		>
			<TreeSelect
				data={treeData}
				multiple
				value={selectObjectsIds}
				onChange={onSelect}
			/>
		</SelectPopup>
);
};