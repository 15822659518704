import React, {useState} from 'react';
import PageBreadcrumbs from './PageBreadcrumbs';
import DesktopInfo from './info/Info.desktop';
import Table from './table/Table';
import DesktopActions from './actions/Actions.desktop';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {desktopColumns} from './table/columns.desktop';
import {Plate} from '@tehzor/ui-components';
import EntitiesTabs from '@src/pages/SpacePage/components/EntitiesTabs';
import DesktopDocuments from './info/Documents.desktop';
import {CheckListsLink} from '@src/components/CheckListsLink';
import {useSpaceStage} from '@src/core/hooks/spaces/useSpaceStage';
import {AdditionalInfo} from './info/AdditionalInfo';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceTasks} from '@src/store/modules/entities/space/selectors';
import {SpaceTasksLinksDesktop} from '@src/pages/SpacePage/components/info/SpaceTasksLinks.desktop';

interface IDesktopProps {
	objectId: string;
	spaceId: string;
	space?: ISpace;
}

const Desktop = ({objectId, spaceId, space}: IDesktopProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const spaceTitle = useSpaceTitle(space);
	const stage = useSpaceStage(objectId, space);
	const tasks = useAppSelector(extractSpaceTasks);
	useAppHeader(
		{
			title: spaceTitle,
			showBackBtn: true
		},
		[spaceTitle]
	);

	return space !== undefined ? (
		<div className="page-cont space-page">
			<PageBreadcrumbs objectId={objectId} spaceId={spaceId} />
			<div className="space-page__d-columns">
				<div className="space-page__d-column space-page__d-column_info">
					<DesktopInfo objectId={objectId} space={space} />

					{stage && (
						<CheckListsLink stage={stage} objectId={objectId} spaceId={spaceId} />
					)}

					<DesktopDocuments objectId={objectId} space={space} />

					{tasks.length > 0 && <SpaceTasksLinksDesktop tasks={tasks} />}

					{space.areaBTI ||
					space.floorBTI ||
					space.numberBTI ||
					space.contractForm ||
					space.markupForRegistration ||
					space.decorationWarrantyExpiredDate ||
					space.technicalEquipmentWarrantyExpiredDate ||
					space.constructiveWarrantyExpiredDate ? (
						<AdditionalInfo space={space} />
					) : undefined}
				</div>

				<div className="space-page__d-column space-page__d-column_entities">
					<DesktopActions space={space} />

					<Plate withoutPadding>
						<EntitiesTabs />

						<Table
							objectId={objectId}
							columns={desktopColumns}
							spaceId={spaceId}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
						/>
					</Plate>
				</div>
			</div>
		</div>
	) : null;
};

export default Desktop;
