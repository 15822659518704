import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import React from 'react';
import {CellProps} from 'react-table';

interface IContractorCellProps extends CellProps<IPreparedContract> {
	isMobile?: boolean;
}

export const ContractorCell = ({row, isMobile}: IContractorCellProps) => (
	<div className="contracts__cell-contractor">
		{isMobile && (
			<div className="contracts__cell-contractor-header">Подрядчик:</div>
		)}
		<div className="contracts__cell-contractor-name">
			{row.original.contractor?.name}
		</div>

		{
			// ! TODO (contracts) Подназвание у подрядчика
		}
	</div>
);