import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetSpaceResponse} from '@src/api/backend/space/get';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {getCachedSpace} from '@src/store/persistentStorage/offlineActions/spaces';

export type IGetSpacePayload = IGetSpaceResponse;

const request = (objectId: string, spaceId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId, spaceId}
});

const success = (response: IGetSpaceResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке помещения');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Возвращает помещение по id
 */
export const getSpace
	= (objectId: string, spaceId: string): AppThunkAction<Promise<IGetSpaceResponse | undefined>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<IGetSpaceResponse | undefined>(
			() => request(objectId, spaceId),
			success,
			failure,
			async () => {
				const isOfflineModeAvailable = state.settings.offlineMode.available;
				const online = state.offlineMode.networkStatus;
				const spaceDetailQueryKey = [...spacesQueryKeys.detail(spaceId), objectId];

				// Если оффлайн, то сетаем помещение из кеша реестра помещений
				if (isOfflineModeAvailable && !online) {
					const cashedDetailPage = queryClient.getQueryData<IGetSpaceResponse>(spaceDetailQueryKey);
					const cashedSpace = cashedDetailPage || await getCachedSpace(spaceId);
					return queryClient.setQueryData(spaceDetailQueryKey, () => cashedSpace);
				}

				return queryClient.fetchQuery<IGetSpaceResponse>({
					queryKey: spaceDetailQueryKey,
					staleTime: Infinity
				});
			}
		);

		return dispatch(apiAction);
	};
