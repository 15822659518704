import React, {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {
	useTasksListPageSettings,
	useTasksPageDisplayMode,
	useTasksSchedulePageSettings
} from '@src/core/hooks/queries/tasks';
import {
	changeFilters,
	changeOffset,
	changeScheduleFilters,
	clearFilters,
	clearScheduleFilters
} from '@src/store/modules/settings/pages/tasks/actions';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';

export const FiltersContextWrap = ({children}: {children: React.ReactNode}) => {
	const dispatch = useAppDispatch();

	const {filters} = useTasksListPageSettings();
	const {filters: scheduleFilters} = useTasksSchedulePageSettings();
	const displayMode = useTasksPageDisplayMode();

	const applyFilters = useCallback(
		(value: IProblemsFiltersState) => {
			if (displayMode === TasksDisplayMode.LIST) {
				dispatch(changeFilters(value));
				dispatch(changeOffset(0));
			}

			if (displayMode === TasksDisplayMode.SCHEDULE) {
				dispatch(changeScheduleFilters(value));
			}
		},
		[dispatch, displayMode]
	);

	const onClear = useCallback(() => {
		if (displayMode === TasksDisplayMode.LIST) {
			dispatch(clearFilters());
		}
		if (displayMode === TasksDisplayMode.SCHEDULE) {
			dispatch(clearScheduleFilters());
		}
	}, [dispatch, displayMode]);

	return (
		<EntitiesFiltersProvider
			filters={displayMode === TasksDisplayMode.LIST ? filters : scheduleFilters}
			onApply={applyFilters}
			onClear={onClear}
		>
			{children}
		</EntitiesFiltersProvider>
	);
};