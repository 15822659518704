import React from 'react';
import {CellProps} from 'react-table';
import {IPreparedInternalAcceptance} from '../../interfaces/IPreparedInternalAcceptance';

export const ModifiedCell = ({row}: CellProps<IPreparedInternalAcceptance>) => (
	<div className="internal-acceptances-page__d-table-created">
		<div className="internal-acceptances-page__d-table-created-date">{row.original.modifiedAt}</div>
		<div className="internal-acceptances-page__d-table-created-name">
			{row.original.modifiedBy?.fullName}
		</div>
	</div>
);
