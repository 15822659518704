import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/reducers';
import {
	IGetInternalAcceptancesResponse,
	requestInternalAcceptances
} from '@src/api/backend/internalAcceptances';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {getInternalAcceptancesActions} from '@src/store/modules/entities/internalAcceptances/actions';
import {useMemo} from 'react';

export const useGetInternalAcceptancesQuery = <T>(
	objectId: string,
	state?: IInternalAcceptancesFiltersState,
	select?: (data: IGetInternalAcceptancesResponse) => T
) => {
	const entities = useAppSelector(s => s.entities);
	const settings = useAppSelector(s => s.settings);
	const dispatch = useAppDispatch();
	const offset = entities?.internalAcceptances[objectId]?.offset || 0;
	const {filters, sort, pageSize} = settings?.pages.internalAcceptances[objectId];
	return useQuery<IGetInternalAcceptancesResponse, IError, T>({
		queryKey: [
			...internalAcceptancesQueryKeys.list(),
			state || filters,
			sort,
			offset,
			pageSize
		],
		queryFn: () => {
			dispatch(getInternalAcceptancesActions.request(objectId));
			return requestInternalAcceptances(state || filters, sort, offset, pageSize);
		},
		onError: (error: IError) => {
			dispatch(getInternalAcceptancesActions.failure(error));
		},
		select,
		enabled: !!entities && !!settings
	});
};

export const useInternalAcceptances = (
	objectId: string,
	state?: IInternalAcceptancesFiltersState
): IListInternalAcceptance[] | undefined =>
	useGetInternalAcceptancesQuery(objectId, state, (data): IListInternalAcceptance[] =>
		data.allIds.map(id => data.byId[id]))?.data;

export const useInternalAcceptancesData = (
	objectId: string,
	state?: IInternalAcceptancesFiltersState
): IGetInternalAcceptancesResponse | undefined =>
	useGetInternalAcceptancesQuery<IGetInternalAcceptancesResponse>(objectId, state)?.data;

export const useLocalInternalAcceptances = (objectId = 'all') => {
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const {data} = useQuery<IListInternalAcceptance[], IError>({
		queryKey: [...internalAcceptancesQueryKeys.localList(), objectId],
		staleTime: Infinity,
		cacheTime: Infinity,
		networkMode: 'always',
		enabled: isOfflineModeAvailable
	});
	return useMemo(() => data || [], [data]);
};
