import React from 'react';
import {HeaderContext} from '@tanstack/react-table';
import classNames from 'classnames';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {isCurrentDay} from '@src/pages/TasksPage/utils/timeDayUtils';
import {IScheduleData} from '@src/pages/TasksPage/utils/convertTasksToScheduleFormat';

export const DayHeader = (props: HeaderContext<IScheduleData, IScheduleData>) => {
	const dayId = props.column.id;

	const dayNumber = dayId.slice(0, 2);
	const dayName = dayId.slice(2);

	const {settings} = useAppSelector(extractTasksScheduleSettings);

	const isCurrentDayFlag = isCurrentDay(settings.year, settings.month, dayId);

	return (
		<div
			className={classNames('manage-task-schedule-page__day-header-wrapper',
			{'manage-task-schedule-page__day-header-wrapper_active': isCurrentDayFlag})}
		>
			<div className="manage-task-schedule-page__day-header">
				{dayNumber}
				<span className={classNames('manage-task-schedule-page__day-header-name',
			{'manage-task-schedule-page__day-header-name_active': isCurrentDayFlag})}
				>
					{dayName}
				</span>
			</div>
		</div>
	);
};