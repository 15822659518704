import React, {useMemo} from 'react';
import {
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	LinkButton,
	LocationSelect,
	Plate,
	StatusSelect
} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useAsyncFn} from 'react-use';
import {editWorkAcceptanceStatus} from '@src/store/modules/entities/workAcceptance/actions';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import Description from '@src/components/viewFields/Description';
import {Category} from '@src/components/viewFields/Category';
import {useEditableResultWorkAcceptanceDialog} from '@src/components/EditableResultWorkAcceptanceDialog/hooks/useEditableResultWorkAcceptanceDialog';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {Responsible} from '@src/components/viewFields/Responsible';
import {makeUnitStr, unitsAsArray, useUnits} from '@src/core/hooks/queries/units';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';
import {MobileInfo} from '../info/Info.mobile';

const locationIcon = <i className="tz-location-20"/>;
const calendarIcon = <i className="tz-calendar-20"/>;
const statusIcon = <i className="tz-status-20"/>;

interface IMobileInfoProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
}

export const MobileDescription = ({objectId, workAcceptance}: IMobileInfoProps) => {
	const {pushPath} = useChangePath();
	const statuses = useAppSelector(s => s.dictionaries.workAcceptanceStatuses);
	const dispatch = useAppDispatch();
	const permissions = useWorkAcceptancesPermissions(objectId);
	const plans = useAppSelector(s => extractPlansAsArray(s, objectId));

	const [resultWorkAcceptanceDialog, openEditResultDialog]
		= useEditableResultWorkAcceptanceDialog(
			workAcceptance.objectId,
			workAcceptance.stage,
			workAcceptance
		);

	const navigate = (structureId: string) => {
		pushPath(formStructureLink(objectId, structureId));
	};
	useAppHeader(
		{
			title: workAcceptance ? `Приёмка работ №${workAcceptance.number}` : 'Приёмка работ',
			showBackBtn: true
		},
		[workAcceptance]
	);

	const units = useUnits();
	const unitsArr = unitsAsArray(units);
	const unitValue = workAcceptance?.physicalWorkScope?.value;
	const unitId = workAcceptance?.physicalWorkScope?.unitId;

	const finalUnitStr = useMemo(
		() => makeUnitStr(unitsArr, unitValue, unitId),
		[unitsArr, unitValue, unitId]
	);

	const [, handleChange] = useAsyncFn(
		async (v: WorkAcceptanceStatusIds) => {
			if (workAcceptance) {
				await dispatch(editWorkAcceptanceStatus(objectId, workAcceptance.id, v));
			}
		},
		[objectId, workAcceptance]
	);

	const formattedDate = workAcceptance?.acceptanceDate
		? format(workAcceptance.acceptanceDate, dateTimeFormat, {locale: ru})
		: undefined;

	return (
		<>
			<MobileInfo
				openDialog={openEditResultDialog}
				unitString={finalUnitStr}
				workAcceptance={workAcceptance}
			/>
			<Plate
				className={{
					content: 'work-acceptance-page__info-plate',
					root: 'work-acceptance-page__info-content'
				}}
			>
				<EntityGrid withBorders>
					<EntityGridItem
						label="Статус"
						icon={statusIcon}
						fullRow
					>
						<StatusSelect
							className="work-acceptance-page__d-entities-status"
							statuses={statuses}
							availableStatuses={statuses.allIds}
							disabled={!permissions.canEditStatus}
							value={workAcceptance?.status}
							onChange={handleChange}
						/>
					</EntityGridItem>

					{workAcceptance.description && (
						<Description value={workAcceptance.description}/>
					)}

					<EntityGridItem
						className="fix-date-view-field"
						icon={calendarIcon}
						label="Срок приёмки"
						fullRow
					>
						<div className="fix-date-view-field__value">{formattedDate}</div>
					</EntityGridItem>

					{workAcceptance.categoryId && (
						<Category
							categoryId={workAcceptance.categoryId}
							fullRow
						/>
					)}

					{!!(workAcceptance.activeGroup || workAcceptance.respUsers?.length) && (
						<Responsible
							entity={workAcceptance}
							type="work-acceptance"
						/>
					)}

					<EntityGridItem
						icon={locationIcon}
						label="Фронт работ"
						fullRow
					>
						{workAcceptance.structures?.map(structure => (
							<div
								key={structure.id}
								className="semi-bold work-acceptance-page__structure"
							>
								<div className="work-acceptance-page__structure_name">
									<div
										role="presentation"
										onClick={() => {
											navigate(structure.id);
										}}
										className="work-acceptance-page__selected-structure "
									>
										<div className="work-acceptance-page__selected-structure_icon">
											<i className="tz-structure"/>
										</div>
										{structure.name}
									</div>
								</div>
								{structure.location && structure.planId && (
									<div className="work-acceptance-page__structure_markers">
										<LocationSelect
											mode="view"
											planId={structure.planId}
											location={structure.location}
											plans={plans}
											path="structure"
											multiplePoints
										>
											{(displayLocation: string, open: () => void) => (
												<div className="problem-page__d-entities-grid-display-place">
													<LinkButton
														label={displayLocation}
														onClick={open}
													/>
												</div>
											)}
										</LocationSelect>
									</div>
								)}
								<br/>
							</div>
						))}
					</EntityGridItem>

					<EditorInfo
						icon={calendarIcon}
						label="Создано"
						date={workAcceptance?.createdAt}
						user={workAcceptance?.createdBy}
						fullRow
					/>

					{workAcceptance?.createdAt !== workAcceptance?.modifiedAt && (
						<EditorInfo
							icon={calendarIcon}
							label="Изменено"
							date={workAcceptance?.modifiedAt}
							user={workAcceptance?.modifiedBy}
							fullRow
						/>
					)}
				</EntityGrid>
			</Plate>
			{permissions.canEdit && resultWorkAcceptanceDialog}
		</>
	);
};
