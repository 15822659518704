import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	makeWarrantyClaimMoveRequest,
	IMoveWarrantyClaimResponse
} from '@src/api/backend/warrantyClaim';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

export interface IMoveWarrantyClaimPayload extends ILinkedWarrantyClaim {
	objectId: string;
	claimId: string;
}

const request = () => ({type: types.MOVE_REQUEST});

const success = (response: IMoveWarrantyClaimResponse) => ({
	type: types.MOVE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при перемещении обращения');
	return {
		type: types.MOVE_FAILURE,
		payload: error
	};
};

/**
 * Перемещает гарантийное обращение
 */
export const moveWarrantyClaim = (
	objectId: string,
	claimId: string,
	toObjectId: string,
	toSpaceId: string
) =>
	createApiAction<IMoveWarrantyClaimResponse>(request, success, failure, async () => {
		await queryClient.invalidateQueries(problemsQueryKeys.list());
		await queryClient.invalidateQueries(problemsQueryKeys.details());

		await queryClient.invalidateQueries(warrantyClaimsQueryKeys.list());
		await queryClient.invalidateQueries(warrantyClaimsQueryKeys.detail(claimId));

		return makeWarrantyClaimMoveRequest(objectId, claimId, toObjectId, toSpaceId);
	});
