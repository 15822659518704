import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {offlineJournalActionType} from '../enums/actionsTypes';

export const useLocalOwnerAcceptancesJournal = () => {
	const {data} = useQuery<IListOwnerAcceptance[], unknown, IOfflineJournalEntity[]>({
		queryKey: ownerAcceptancesQueryKeys.localList(),
		select: ownerAcceptances =>
			ownerAcceptances.map(
				acceptance =>
					({
						entity: {
							entityId: acceptance.id,
							entityLocalNumber: acceptance.localNumber,
							entityType: offlineJournalActionType.OWNER_ACCEPTANCE_ADD
						},
						object: {
							objectId: acceptance.object?.id,
							objectName: acceptance.object?.name
						},
						transfer: {
							status: acceptance.transferStatus,
							statusMessage: acceptance.transferStatus
								? offlineModeTransferTitles[acceptance.transferStatus]
								: ''
						},
						createdAt: acceptance.createdAt
					} as IOfflineJournalEntity)
			)
	});
	return data || [];
};
