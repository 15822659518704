import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IMoveProblemsResponse, IProblemTarget, makeProblemsMoveRequest} from '@src/api/backend/problem';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';

export type IMoveProblemsPayload = IMoveProblemsResponse;

const request = () => ({type: types.MOVE_REQUEST});

const success = (objectId: string, problemId: string) => {
	addInfoToast('Перемещены', 'Нарушения успешно перемещены');
	return {
		type: types.MOVE_SUCCESS,
		payload: {
			objectId,
			problemId
		} as IMoveProblemsPayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при перемещении нарушений');
	return {
		type: types.MOVE_FAILURE,
		payload: error
	};
};

/**
 * Изменяет нарушение
 *
 * @param objectId id объекта
 * @param target id целевой сущности
 * @param problemId id нарушения
 */
export const moveProblem = (objectId: string, target: IProblemTarget, problemId: string) =>
	createApiAction(
		request,
		() => success(objectId, problemId),
		failure,
		() => makeProblemsMoveRequest(objectId, target, problemId)
	);
