import useAppDispatch from '@src/core/hooks/useAppDispatch';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {useCallback} from 'react';
import _ from 'lodash';
import {AnyAction} from 'redux';
import {useQueryClient} from '@tanstack/react-query';

/**
 * Хук обновления кэша и стора сущностей
 * @param queryKey - queryKey используемый для работы с кэшем сущности
 */
export const useUpdateEntity = (queryKey: {
	detail: (key?: string) => string[];
	list: () => string[];
}) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	/**
	 * Функция обновления кэша и стора списка сущностей
	 * @param newItem - созданная сущность из ответа сервера
	 * @param key - key локальной сущности
	 * @param getAction - redux action для обновления списка в store
	 */
	const updateEntityList = useCallback(
		<
			L extends {id: string, object?: {id: string}, objectId: string},
			R extends INormalizedData<L>
		>(
			newItem: Record<string, any> & {id: string, objectId: string},
			key: string,
			getAction?: (objectId: string, list: R) => AnyAction,
			invalidateList?: boolean
		) => {
			queryClient.setQueriesData(
				{
					queryKey: queryKey.list(),
					predicate: ({state}) => {
						const {data: current} = state;
						if (current && (current as R).byId[key]) {
							return true;
						}
						return false;
					}
				},
				(current: R) => {
					_.set(current, 'byId', _.omit(current.byId, key));
					const newList = {
						...current,
						byId: {
							[newItem.id]: {
								...newItem,
								object: {id: newItem.objectId}
							} as L,
							...current.byId
						},
						allIds: [newItem.id, ...current.allIds].filter(id => id !== key)
					};
					if (getAction) {
						void dispatch(getAction(newItem.objectId, newList));
					}
					return newList;
				}
			);
			if (invalidateList) {
				void queryClient.invalidateQueries(queryKey.list());
			}
		},
		[dispatch, queryClient]
	);

	/**
	 * Функция обновления кэша и стора сущности
	 * @param newItem - созданная сущность из ответа сервера
	 * @param deleteAction - redux action для удаления сущности (удалит локально сохраненную сущность)
	 * @param addAction - redux action для добавления сущности в store
	 * @param key - key локальной сущности
	 */
	const updateEntity = useCallback(
		<E extends {id: string, objectId: string}>(
			newItem: E,
			deleteAction: (objectId: string, id: string, quietly: boolean) => AnyAction,
			addAction: (item: E) => AnyAction,
			key: string
		) => {
			dispatch(deleteAction(newItem.objectId, key, true));

			dispatch(addAction(newItem));
			queryClient.removeQueries(queryKey.detail(key));
		},
		[dispatch, queryClient]
	);

	return {updateEntityList, updateEntity};
};
