import {wsConnector} from '../wsConnector';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';

export type IUnbindSpaceFromOwnerResponse = ISpaceOwner | undefined;

/**
 * Отвязывает собственника от помещения
 *
 * @param ownerId id собственника
 * @param spaceId id помещения
 * @param removeIfLast удалять собственника, если у него не останется помещений
 */
export const makeUnbindSpaceFromOwnerRequest = (
	ownerId: string,
	spaceId: string,
	removeIfLast?: boolean
) =>
	wsConnector.sendAuthorizedRequest<IUnbindSpaceFromOwnerResponse>('unbindSpaceFromOwner', {
		ownerId,
		spaceId,
		removeIfLast
	});
