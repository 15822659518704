import React from 'react';
import './ExportTemplateSelect.less';
import classNames from 'classnames';
import ExportTemplateOption from './ExportTemplateOption';
import IExportTemplate, {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';

interface ITemplateSelectProps {
	className?: string;
	style?: {};
	data?: IExportTemplate[];
	value?: string;
	defaultValue?: string;
	availableDestinations?: ExportTemplatesDestinationId[];

	onChange?(value: string): void;
}

interface ITemplateSelectState {
	data: IExportTemplate[];
	originalData?: IExportTemplate[];
	value?: string;
}

class ExportTemplateSelect extends React.PureComponent<ITemplateSelectProps, ITemplateSelectState> {
	static displayName = 'ExportTemplateSelect';

	static getDerivedStateFromProps(nextProps: ITemplateSelectProps, prevState: ITemplateSelectState) {
		const state: Partial<ITemplateSelectState> = {};

		if (nextProps.data !== prevState.originalData) {
			if (!nextProps.data) {
				state.data = [];
				state.originalData = undefined;
			} else {
				const available = nextProps.availableDestinations;
				if (available) {
					state.data = nextProps.data.filter(item => available.includes(item.destination));
					state.originalData = nextProps.data;
				} else {
					state.data = nextProps.data;
					state.originalData = nextProps.data;
				}
			}
		}
		if (nextProps.value !== undefined && nextProps.value !== prevState.value) {
			state.value = nextProps.value;
		}
		return Object.keys(state).length ? state : null;
	}

	constructor(props: ITemplateSelectProps) {
		super(props);

		this.state = {
			data: [],
			originalData: undefined,
			value: props.defaultValue
		};
	}

	getValue = () => this.state.value;

	render() {
		const {className, style} = this.props;
		const {data, value} = this.state;
		const classes = classNames('et-select', className);

		const list = data.map(item => (
			<ExportTemplateOption
				key={item.id}
				id={item.id}
				name={item.name}
				outputFileType={item.outputFileType}
				selected={item.id === value}
				onSelect={this._handleSelect}
			/>
		));

		return (
			<div
				className={classes}
				style={style}
			>
				{list}
			</div>
		);
	}

	private _handleSelect = (id: string) => {
		const {value, onChange} = this.props;
		if (onChange) {
			onChange(id);
		}
		if (value === undefined) {
			this.setState({value: id});
		}
	};
}

export default ExportTemplateSelect;
