import React from 'react';
import {StatusSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {editTaskStatus} from '@src/store/modules/pages/task/actions/editTaskStatus';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {getTasks} from '@src/store/modules/pages/checkLists/actions';

interface IStatusProps {
	task: IEnrichedTask;
}

export const TaskStatus = ({task}: IStatusProps) => {
	const statuses = useAppSelector(s => s.dictionaries.taskStatuses);
	const dispatch = useAppDispatch();
	const permissions = useTasksPermissions(task.objectId.id);

	const [, handleChange] = useAsyncFn(
		async (statusId: TaskStatusId) => {
			await dispatch(editTaskStatus(task.id, statusId));
			const listIds = task.links?.checkListId ? [task.links.checkListId] : [];
			await dispatch(getTasks(listIds, task.links?.spaceId));
		},
		[task]
	);

	const availableStatuses = Object.values(TaskStatusId);

	return (
		<ClickPreventWrap>
			<StatusSelect
				statuses={statuses}
				availableStatuses={availableStatuses}
				disabled={!permissions.canEditStatus}
				value={task?.status?.id}
				onChange={handleChange}
			/>
		</ClickPreventWrap>
	);
};
