import React from 'react';
import SelectedEntitiesExport from './SelectedEntitiesExport';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptanceProblems} from '@src/store/modules/entities/internalAcceptance/selectors';
import {useInternalAcceptancePermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import SelectedProblemsMove from './SelectedProblemsMove';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface ISelectionsActionsProps {
	objectId: string;
	internalAcceptanceId: string;
	internalAcceptance?: ILinkedInternalAcceptance;
	selectedEntities: IInternalAcceptanceEntity[];
}

const SelectionActions = ({
	objectId,
	internalAcceptanceId,
	internalAcceptance,
	selectedEntities
}: ISelectionsActionsProps) => {
	const problems = useAppSelector(extractInternalAcceptanceProblems);
	const permissions = useInternalAcceptancePermissions(objectId, internalAcceptance, problems);

	// TODO убрать 2 строки по завершению TZ-1787 и TZ-1059
	const isStageAcceptance = selectedEntities.some(entity =>
		entity.data.stage === ObjectStageIds.ACCEPTANCE);

	return (
		<>
			{permissions.canMove
			// TODO убрать строку по завершению TZ-1787 и TZ-1059
			&& !isStageAcceptance
			&& (
				<SelectedProblemsMove
					objectId={objectId}
					internalAcceptanceId={internalAcceptanceId}
					selectedEntities={selectedEntities}
					restrictions={permissions.canMoveRestrictions}
				/>
			)}
			{permissions.canExport && (
				<SelectedEntitiesExport
					objectId={objectId}
					internalAcceptanceId={internalAcceptanceId}
					selectedEntities={selectedEntities}
				/>
			)}
			{permissions.canDelete && (
				<SelectedEntitiesDeletion selectedEntities={selectedEntities}/>
			)}
		</>
	);
};

export default SelectionActions;
