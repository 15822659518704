import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {extractProblem} from '@src/store/modules/pages/problem/selectors/problem';
import {
	formCheckLink,
	formInternalAcceptanceLink,
	formOwnerAcceptanceLink,
	formProblemLink,
	formSpaceLink,
	formWarrantyClaimLink
} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';

/**
 * Хук для хлебных крошек от "всех объектов" до нарушения
 *
 * @param objectId id конечного объекта
 */
export function useProblemBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	const problem = useAppSelector(extractProblem);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId);
		if (problem) {
			if (problem.space) {
				items.push({
					label: formSpaceTitle(
						problem.space.name,
						problem.space.altName,
						spaceTypes.byId[problem.space.type],
						altNamesVisible
					),
					url: formSpaceLink(objectId, problem.space.id),
					inactive: false
				});
			}
			if (problem.check) {
				items.push({
					label: `Проверка №${problem.check.number}`,
					url: formCheckLink(objectId, problem.check.id),
					inactive: false
				});
			}
			if (problem.warrantyClaim && problem.space) {
				items.push({
					label: `Гарантийное обращение №${problem.warrantyClaim.number}`,
					url: formWarrantyClaimLink(objectId, problem.warrantyClaim.id),
					inactive: false
				});
			}
			if (problem.ownerAcceptance && problem.space) {
				items.push({
					label: `Передача собственнику №${problem.ownerAcceptance.number}`,
					url: formOwnerAcceptanceLink(objectId, problem.ownerAcceptance.id),
					inactive: false
				});
			}
			if (problem.internalAcceptance) {
				items.push({
					label: `Внутренняя приемка №${problem.internalAcceptance.number}`,
					url: formInternalAcceptanceLink(objectId, problem.internalAcceptance.id),
					inactive: false
				});
			}
			items.push({
				label: `Нарушение №${problem.number || problem.localNumber}`,
				url: formProblemLink(objectId, problem.id),
				inactive: false
			});
		}
		return items;
	}, [objectId, problem, objects, spaceTypes]);
}
