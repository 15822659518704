import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {
	extractOwnerAcceptanceResolutionsAsArray
} from '@src/store/modules/dictionaries/ownerAcceptanceResolutions/selectors';

interface IOwnerAcceptancesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const OwnerAcceptancesResolutionsFilterMobile = memo((props: IOwnerAcceptancesFilterMobileProps) => {
	const resolutions = useAppSelector(extractOwnerAcceptanceResolutionsAsArray);

	return (
		<BaseListFilterMobile
			{...props}
			options={resolutions}
			label="Решения"
			filterName="resolutions"
		/>
	);
});