import React, {memo} from 'react';
import MobileInfo from './info/Info.mobile';
import {ExpandablePlate} from '@tehzor/ui-components';
import MobileLinks from './links/Links.mobile';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import Documents from '@src/pages/ProblemPage/components/attachments/Documents';
import MobileLastReply from '@src/pages/ProblemPage/components/comments/LastReply.mobile';
import {useProblemAndReplyViewer} from '@src/core/hooks/imagesViewers/mobile/useProblemAndReplyViewer';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemLastReply} from '@src/store/modules/entities/problemsLastReplies/selectors';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import PageBreadcrumbs from './PageBreadcrumbs';
import {extractHasLocationComments} from '@src/store/modules/pages/problem/selectors/problem';
import {IPermissions} from '../hooks/usePermissions';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';

interface ITabletProps {
	objectId: string;
	problemId: string;
	problem?: IPreparedProblem;
	permissions: IPermissions;
	settings?: IProblemSettings;
}

const Tablet = memo(({
	objectId, problemId, problem, permissions, settings
}: ITabletProps) => {
	const reply = useAppSelector(s => extractProblemLastReply(s, problemId));
	const [problemImagesAttachments, docs] = useSplitAttachments(problem);
	const problemImages = useAttachmentsImages(problemImagesAttachments);
	const problemImagesData = useAttachmentsImagesObjects(problemImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(reply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);
	const hasLocationComments = useAppSelector(extractHasLocationComments);

	const [imagesViewer, openProblemImage, openReplyImage] = useProblemAndReplyViewer(
		problemImages,
		replyImages,
		problemImagesData,
		replyImagesData,
		reply?.number,
		problemId,
		reply?.id
	);

	return (
		<div className="page-cont problem-page">
			<PageBreadcrumbs objectId={objectId}/>
			{problem !== undefined && (
				<MobileLinks
					objectId={objectId}
					problem={problem}
					permissions={permissions}
				/>
			)}

			{problem !== undefined && (
				<ExpandablePlate
					className="problem-page__m-plate"
					title="Информация"
				>
					<MobileInfo
						objectId={objectId}
						problem={problem}
						images={problemImages}
						onImageClick={openProblemImage}
						hasLocationComments={hasLocationComments}
						permissions={permissions}
						settings={settings}
					/>
				</ExpandablePlate>
			)}

			{docs.length > 0 && (
				<ExpandablePlate
					className="problem-page__m-plate"
					title="Вложения"
				>
					<Documents
						className="problem-page__m-docs"
						files={docs}
					/>
				</ExpandablePlate>
			)}

			<MobileLastReply
				objectId={objectId}
				problemId={problemId}
				onImageClick={openReplyImage}
			/>

			{imagesViewer}
		</div>
	);
});

export default Tablet;
