import React from 'react';
import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import {SubObjectStats} from './SubObjectStats';
import ProblemsStatsChart from './ProblemsStatsChart';
import {sumStatsValues} from '../../../utils/sumStatsValues';
import {StatsByStatus, StatsMap} from '@src/utils/statsAggregators/problemsByStatuses';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {useNavigateProblems} from '@src/pages/StatisticsPage/hooks/useNavigateProblems';
import {extractObjectIdsForCompany} from '@src/store/modules/dictionaries/objects/selectors';

interface ICompanyStatsProps {
	company: ICompany;
	companyStats: StatsByStatus;
	companyObjects?: IStatsObject[];
	objectsStats: StatsMap;
}

export const CompanyStats = ({
	company,
	companyStats,
	companyObjects,
	objectsStats
}: ICompanyStatsProps) => {
	const sourcesLoading = useAppSelector(extractSourcesLoading);
	const totalCompanyCount = companyStats?.expired
		? sumStatsValues(companyStats) - companyStats?.expired
		: sumStatsValues(companyStats);

	const companyObj = useAppSelector(s => extractObjectIdsForCompany(s, company.id));
	const navigate = useNavigateProblems(
		{
			navProps: [
				{
					type: 'companies',
					prop: company.id
				}
			],
			page: 'warranty'
		},
		[...companyObj, company.id]
	);

	const handleClick = (status: string) => {
		navigate(status);
	};

	return (
		<VerticalTabContent className="statistics-page__tabs-content-panel">
			<StatsTreePanel
				id={company.id}
				title={company.name}
				loading={sourcesLoading}
				showExpandBtnPlaceholder
				permanentContent={(
					<ProblemsStatsChart
						stats={companyStats}
						totalCount={totalCompanyCount}
						onClick={handleClick}
					/>
				)}
				hiddenContent={companyObjects?.map(object => (
					<SubObjectStats
						key={object.id}
						data={object}
						stats={objectsStats}
						level={1}
					/>
				))}
			/>
		</VerticalTabContent>
	);
};
