import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosStatic} from 'axios';
import {localAuthStorage} from './localAuthStorage';
import {StatusCode} from '../enums/StatusCode';
import IError from '../interfaces/IError';
import {CancellationError} from '../errors/CancellationError';
import {MissingTokenError} from '../errors/MissingTokenError';
import {tokenUpdater} from './updateTokens';

async function addAccessToken(axiosConfig: AxiosRequestConfig) {
	const accessToken = await localAuthStorage.getAccessToken();
	if (!accessToken) {
		throw new MissingTokenError();
	}
	axiosConfig.headers.Authorization = `Bearer ${accessToken}`;
	if (process.env.NODE_ENV === 'production') {
		axiosConfig.withCredentials = true;
	}
	return axiosConfig;
}

async function updateTokensAndRetry(error: AxiosError, instance: AxiosStatic | AxiosInstance) {
	const originalRequest = error.config as AxiosRequestConfig & {_retry: boolean};
	if (!originalRequest._retry) {
		originalRequest._retry = true;
		await tokenUpdater.updateToken();
		await addAccessToken(originalRequest);
		return instance(originalRequest);
	}
	return Promise.reject(error);
}

async function handleResponseError(
	error: AxiosError,
	instance: AxiosStatic | AxiosInstance,
	logout?: () => Promise<void>
) {
	if (error.response?.status === 401) {
		if (error.response.data.error === 'WrongRefreshToken' && logout) {
			await logout();
			return Promise.reject({
				statusCode: error.response.status,
				error: error.response.statusText
			});
		}
		return updateTokensAndRetry(error, instance);
	}
	// Обработка ручной отмены запроса
	if (axios.isCancel(error)) {
		throw new CancellationError();
	}
	if (error.response) {
		if (error.response.data?.statusCode) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject({
			statusCode: error.response.status,
			error: error.response.statusText
		});
	}
	return Promise.reject({
		statusCode: StatusCode.DEFAULT,
		error: 'Error',
		message: error.message
	});
}

const fixFileError = async (error: AxiosError, instance: AxiosStatic | AxiosInstance, logout?: () => Promise<void>) => {
	if (error.response?.status === 401) {
		if (error.response.data.error === 'WrongRefreshToken' && logout) {
			await logout();
		}
		return updateTokensAndRetry(error, instance);
	}
	return new Promise(resolve => {
		if (
			error.request.responseType === 'blob'
			&& error.response
			&& error.response.data instanceof Blob
			&& error.response.data.type.toLowerCase().includes('json')
		) {
			const reader = new FileReader();
			reader.onload = () => resolve(typeof reader.result === 'string' ? JSON.parse(reader.result) : error);
			reader.onerror = () => resolve(error);
			reader.readAsText(error.response.data);
		} else {
			resolve(error);
		}
	}).then((err: IError | AxiosError) => {
		if ((err as IError).statusCode) {
			return Promise.reject(err);
		}
		const {response} = err as AxiosError;
		if (response) {
			return Promise.reject({
				statusCode: response.status,
				error: response.statusText
			});
		}
		return Promise.reject({
			statusCode: StatusCode.DEFAULT,
			error: 'Error',
			message: err.message
		});
	});
};
class HttpRequests {
	logout?: () => Promise<void>;

	/**
	 * Axios-объект с предварительными настройками
	 */
	default: AxiosStatic;

	/**
	 * Axios-объект для аутентифицированного запроса
	 */
	withToken: AxiosInstance;

	/**
	 * Axios-объект для аутентифицированного запроса файла
	 */
	fileWithToken: AxiosInstance;

	initialize = (url: string, logout?: () => Promise<void>) => {
		this.logout = logout;
		this.default = axios;
		this.default.defaults.baseURL = `${url}/api`;
		this.default.defaults.timeout = 120000;
		this.default.defaults.headers.Accept = 'application/json';
		this.default.interceptors.response.use(undefined, err => handleResponseError(err, this.default, this.logout));
		this.withToken = this.default.create();
		this.withToken.interceptors.request.use(addAccessToken);
		this.withToken.interceptors.response.use(undefined, err =>
			handleResponseError(err, this.withToken, this.logout));

		this.fileWithToken = this.default.create();
		this.fileWithToken.defaults.timeout = 240000;
		this.fileWithToken.defaults.responseType = 'blob';
		this.fileWithToken.interceptors.request.use(addAccessToken);
		// При использовании responseType = 'blob' ответ в случае ошибки всё равно конвертируется в blob.
		// Поэтому используется костыль с обратным преобразованием.
		this.fileWithToken.interceptors.response.use(undefined, err =>
			fixFileError(err, this.fileWithToken, this.logout));
	};
}

export const httpRequests = new HttpRequests();
