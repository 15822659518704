import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

interface ICompaniesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	isUUID?: boolean;

	onChange: (changes: IChanges) => void;
}

export const CompaniesFilterMobile = memo((props: ICompaniesFilterMobileProps) => {
	let companies = useAppSelector(extractCompaniesAsArray);

	if (props.isUUID) {
		companies = companies.map(el => {
			if (el?.uuid) {
				const newCompany = {...el};
				newCompany.id = el.uuid;
				return newCompany;
			}
			return el;
		});
	}

	return (
		<BaseListFilterMobile
			{...props}
			options={companies}
			label="Компания"
			filterName="companies"
		/>
	);
});
