import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

interface IStagesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const StagesFilterMobile = memo((props: IStagesFilterMobileProps) => {
	const objectStages = useAppSelector(extractObjectStagesAsArray);

	return (
		<BaseListFilterMobile
			{...props}
			options={objectStages}
			label="Стадии"
			filterName="stages"
		/>
	);
});
