import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeInternalAcceptanceDeleteRequest} from '@src/api/backend/internalAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';

export interface IDeleteInternalAcceptancePayload {
	objectId: string;
	internalAcceptanceId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, internalAcceptanceId: string, quietly?: boolean) => {
	if (!quietly) {
		addInfoToast('Удалено', 'Внутренняя приёмка успешно удалена');
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			internalAcceptanceId
		}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении внутренней приёмки');
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

export const deleteInternalAcceptanceActions = {request, success, failure};

/**
 * Удаляет внутреннюю приёмку
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 */
export const deleteInternalAcceptance = (objectId: string, internalAcceptanceId: string) =>
	createApiAction(
		request,
		() => success(objectId, internalAcceptanceId),
		failure,
		() => {
			void queryClient.invalidateQueries(internalAcceptancesQueryKeys.list());
			void queryClient.invalidateQueries(internalAcceptancesQueryKeys.latest());
			void queryClient.invalidateQueries(problemsQueryKeys.list());
			void queryClient.invalidateQueries(inspectionsQueryKeys.list());
			return makeInternalAcceptanceDeleteRequest(objectId, internalAcceptanceId);
		}
	);
