import {IEditableEntityState} from '@src/core/states/editableEntityState';
import {
	IEditableEntityAction,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {ISavingLegalEntity} from '@tehzor/tools/interfaces/legals-entity/ISavingLegalEntity';

export type IEditableLegalState = IEditableEntityState<{
	name?: string;
	shortName?: string;
	companyId?: string;
	inn?: string;
	kpp?: string;
	ogrn?: string;
	legalAddress?: string;
	mailingAddress?: string;
	phone?: string;
	email?: string;
	registrationCertificateDate?: number;
	registrationCertificateNumber?: string;
	okpo?: string;
	okved?: string;
	buildingSroNumber?: string;
	buildingSroDate?: number;
	designSroNumber?: string;
	designSroDate?: number;
	comment?: string;
}>;

export type IEditableLegalAction = IEditableEntityAction<IEditableLegalState, ILegalEntity>;

const makeEmptyState = (): IEditableLegalState => ({
	name: '',
	shortName: '',
	companyId: '',
	inn: '',
	kpp: '',
	ogrn: '',
	legalAddress: '',
	mailingAddress: '',
	phone: '',
	email: '',
	registrationCertificateDate: undefined,
	registrationCertificateNumber: '',
	okpo: '',
	okved: '',
	buildingSroNumber: '',
	buildingSroDate: undefined,
	designSroNumber: '',
	designSroDate: undefined,
	comment: '',
	errors: {
		name: false,
		shortName: false,
		companyId: false,
		inn: false,
		kpp: false,
		ogrn: false,
		legalAddress: false,
		mailingAddress: false,
		phone: false,
		email: false,
		registrationCertificateDate: false,
		registrationCertificateNumber: false,
		okpo: false,
		okved: false,
		buildingSroNumber: false,
		buildingSroDate: false,
		designSroNumber: false,
		designSroDate: false,
		comment: false
	}
});

export const init = (legal?: ILegalEntity): IEditableLegalState => {
	const empty = makeEmptyState();
	return legal
		? {
				name: legal.name,
				shortName: legal.shortName,
				companyId: legal.companyId,
				inn: legal.inn,
				kpp: legal.kpp,
				ogrn: legal.ogrn,
				legalAddress: legal.legalAddress,
				mailingAddress: legal.mailingAddress,
				phone: legal.phone,
				email: legal.email,
				registrationCertificateDate: legal.registrationCertificateDate,
				registrationCertificateNumber: legal.registrationCertificateNumber,
				okpo: legal.okpo,
				okved: legal.okved?.join(','),
				buildingSroNumber: legal.buildingSroNumber,
				buildingSroDate: legal.buildingSroDate,
				designSroNumber: legal.designSroNumber,
				designSroDate: legal.designSroDate,
				comment: legal.comment,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (state: IEditableLegalState, original?: ILegalEntity): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'shortName'),
		isPropEdited.bind(null, 'companyId'),
		isPropEdited.bind(null, 'inn'),
		isPropEdited.bind(null, 'kpp'),
		isPropEdited.bind(null, 'ogrn'),
		isPropEdited.bind(null, 'legalAddress'),
		isPropEdited.bind(null, 'mailingAddress'),
		isPropEdited.bind(null, 'phone'),
		isPropEdited.bind(null, 'email'),
		isPropEdited.bind(null, 'registrationCertificateDate'),
		isPropEdited.bind(null, 'registrationCertificateNumber'),
		isPropEdited.bind(null, 'okpo'),
		isPropEdited.bind(null, 'okved'),
		isPropEdited.bind(null, 'buildingSroNumber'),
		isPropEdited.bind(null, 'buildingSroDate'),
		isPropEdited.bind(null, 'designSroNumber'),
		isPropEdited.bind(null, 'designSroDate'),
		isPropEdited.bind(null, 'comment')
	);

export const errorsFns = {
	name: (state: IEditableLegalState) => !state.name,
	shortName: (state: IEditableLegalState) => !state.shortName,
	companyId: (state: IEditableLegalState) => !state.companyId,
	inn: (state: IEditableLegalState) => !state.inn,
	kpp: (state: IEditableLegalState) => !state.kpp,
	ogrn: (state: IEditableLegalState) => !state.ogrn,
	legalAddress: (state: IEditableLegalState) => !state.legalAddress,
	mailingAddress: (state: IEditableLegalState) => !state.mailingAddress,
	phone: (state: IEditableLegalState) => !state.phone,
	email: (state: IEditableLegalState) => !state.email,
	registrationCertificateDate: (state: IEditableLegalState) => !state.registrationCertificateDate,
	registrationCertificateNumber: (state: IEditableLegalState) =>
		!state.registrationCertificateNumber,
	okpo: (state: IEditableLegalState) => !state.okpo,
	okved: (state: IEditableLegalState) => !state.okved,
	buildingSroNumber: (state: IEditableLegalState) => !state.buildingSroNumber,
	buildingSroDate: (state: IEditableLegalState) => !state.buildingSroDate,
	designSroNumber: (state: IEditableLegalState) => !state.designSroNumber,
	designSroDate: (state: IEditableLegalState) => !state.designSroDate,
	comment: (state: IEditableLegalState) => !state.comment
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const convertLegalToSave = (
	state: IEditableLegalState,
	original?: ISavingLegalEntity,
	onlyEdited?: boolean
): ISavingLegalEntity => {
	if (!onlyEdited) {
		return {
			...state,
			okved: state?.okved?.split(',')
		};
	}

	const legal: ISavingLegalEntity = {};

	if (isPropEdited('name', state, original)) {
		legal.name = state.name;
	}
	if (isPropEdited('shortName', state, original)) {
		legal.shortName = state.shortName;
	}
	legal.companyId = state.companyId;
	if (isPropEdited('inn', state, original)) {
		legal.inn = state.inn;
	}
	if (isPropEdited('kpp', state, original)) {
		legal.kpp = state.kpp;
	}
	if (isPropEdited('ogrn', state, original)) {
		legal.ogrn = state.ogrn;
	}
	if (isPropEdited('legalAddress', state, original)) {
		legal.legalAddress = state.legalAddress;
	}
	if (isPropEdited('mailingAddress', state, original)) {
		legal.mailingAddress = state.mailingAddress;
	}
	if (isPropEdited('phone', state, original)) {
		legal.phone = state.phone;
	}
	if (isPropEdited('email', state, original)) {
		legal.email = state.email;
	}
	if (isPropEdited('registrationCertificateDate', state, original)) {
		legal.registrationCertificateDate = state.registrationCertificateDate;
	}
	if (isPropEdited('registrationCertificateNumber', state, original)) {
		legal.registrationCertificateNumber = state.registrationCertificateNumber;
	}
	if (isPropEdited('okpo', state, original)) {
		legal.okpo = state.okpo;
	}
	if (isPropEdited('okved', state, {original, okved: original?.okved?.join(',')})) {
		legal.okved = state?.okved?.split(',');
	}
	if (isPropEdited('buildingSroNumber', state, original)) {
		legal.buildingSroNumber = state.buildingSroNumber;
	}
	if (isPropEdited('buildingSroDate', state, original)) {
		legal.buildingSroDate = state.buildingSroDate;
	}
	if (isPropEdited('designSroNumber', state, original)) {
		legal.designSroNumber = state.designSroNumber;
	}
	if (isPropEdited('designSroDate', state, original)) {
		legal.designSroDate = state.designSroDate;
	}
	if (isPropEdited('comment', state, original)) {
		legal.comment = state.comment;
	}

	return legal;
};
