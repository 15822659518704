import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckListById} from '@src/store/modules/dictionaries/checkLists/selectors';
import {useManageCheckListsBreadcrumbs} from './useManageCheckListsBreadcrumbs';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';

export const useManageCheckListBreadcrumbs = (checkListId: string): IBreadcrumb[] => {
	const breadcrumbs = useManageCheckListsBreadcrumbs();
	const checkList = useAppSelector(s => extractCheckListById(s, checkListId));

	return useMemo(() => {
		if (checkList) {
			return breadcrumbs.concat({
				label: checkList.name,
				url: `/manage/check-lists/${checkList.id}`
			});
		}
		return breadcrumbs;
	}, [checkList, breadcrumbs]);
};
