import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IGetTasksResponse} from '@src/api/backend/tasks';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {
	extractTasksCollectMode,
	extractTasksDisplayMode,
	extractTasksListSettings,
	extractTasksScheduleSettings
} from '@src/store/modules/settings/pages/tasks/selectors';
import {ITasksPageSettingsListState} from '@src/store/modules/settings/pages/tasks/reducers/list';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {ITasksPageSettingsScheduleState} from '@src/store/modules/settings/pages/tasks/reducers/schedule';

export const useTasksListPageSettings = () => {
	const settings = useAppSelector(extractTasksListSettings);
	const pageSettings: ITasksPageSettingsListState & {offset: number} = useMemo(() => {
		const offset = settings.offset;
		const filters = settings?.filters;
		const sort = settings?.sort;
		const pageSize = settings?.pageSize;
		const selectedRows = settings?.selectedRows;
		return {offset, filters, sort, pageSize, selectedRows};
	}, [settings]);
	return pageSettings;
};

export const useTasksSchedulePageSettings = () => {
	const settings = useAppSelector(extractTasksScheduleSettings);
	const pageSettings: ITasksPageSettingsScheduleState = useMemo(() => {
		const filters = settings?.filters;
		const collectBy = settings?.collectBy;
		return {filters, collectBy, settings: settings.settings};
	}, [settings]);
	return pageSettings;
};

export const useTasks = (state?: ITasksFiltersState) => {
	const {offset, filters, sort, pageSize} = useTasksListPageSettings();
	const {data} = useQuery<IGetTasksResponse, IError>({
		queryKey: [...tasksQueryKeys.list(), state || filters, sort, offset, pageSize],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке списка задач');
		}
	});
	return data;
};

export const useTasksForSchedule = (state?: ITasksFiltersState) => {
	const {filters} = useTasksSchedulePageSettings();
	const {data} = useQuery<IGetTasksResponse, IError>({
		queryKey: [...tasksQueryKeys.schedule(), state || filters, {}],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке графика задач');
		}
	});
	return data;
};

export const useTasksPageDisplayMode = () => useAppSelector(extractTasksDisplayMode);
export const useTasksScheduleCollectMode = () => useAppSelector(extractTasksCollectMode);

export const tasksList = (data?: IGetTasksResponse) =>
	data?.allIds.map(id => data.byId[id]) || [];