import {useCallback, useState} from 'react';

export interface ICommentReferenceState {
	id: string;
	type: 'add' | 'edit';
}

export function useCommentReference(
	defaultReference?: ICommentReferenceState
): [ICommentReferenceState | undefined, () => void, (id: string) => void, (id: string) => void] {
	const [reference, setReference] = useState<ICommentReferenceState | undefined>(
		defaultReference
	);

	const clear = useCallback(() => {
		setReference(undefined);
	}, []);

	const setAddRef = useCallback((id: string) => {
		setReference({id, type: 'add'});
	}, []);

	const setEditRef = useCallback((id: string) => {
		setReference({id, type: 'edit'});
	}, []);

	return [reference, clear, setAddRef, setEditRef];
}
