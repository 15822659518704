import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {FloatingActionButton, IconButton} from '@tehzor/ui-components';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getSpace} from '@src/store/modules/entities/space/actions';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';

interface IEntityAddingProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
}

const EntityAdding = ({objectId, internalAcceptance}: IEntityAddingProps) => {
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();

	const defaultData = useSpaceLocationForEntities(objectId, internalAcceptance.links?.spaceId);
	const [dialog, open] = useAddingInternalAcceptanceDialog({
		objectId,
		internalAcceptanceId: internalAcceptance.id,
		links: internalAcceptance.links,
		stage: object.stage,
		defaultProblemData: defaultData,
		defaultInspectionData: defaultData,
		createdBy: internalAcceptance.createdBy?.id
	});

	const [, handleClick] = useAsyncFn(async () => {
		if (internalAcceptance.links?.spaceId) {
			await dispatch(getSpace(objectId, internalAcceptance.links.spaceId));
		}
		open();
	}, [objectId, internalAcceptance, open]);

	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return problemsPermissions.canAdd || inspectionsPermissions.canAdd ? (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<IconButton
					type="accent-blue"
					onClick={handleClick}
				>
					<i className="tz-plus-20"/>
				</IconButton>
			</Desktop>
			{dialog}
		</>
	) : null;
};

export default EntityAdding;
