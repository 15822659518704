import {ITasksSettingsState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {getMonthName} from './timeMonthUtils';

export const createTasksString = (numOfTasks: number) => {
	if (numOfTasks === 1) return '1 задача';
	if (numOfTasks > 1 && numOfTasks < 5) return `${numOfTasks} задачи`;
	if (numOfTasks > 20) {
		if (numOfTasks % 10 === 1) return `${numOfTasks} задача`;
		if (numOfTasks % 10 === 2 || numOfTasks % 10 === 3 || numOfTasks % 10 === 4) {
			return `${numOfTasks} задачи`;
		}
	}
	return `${numOfTasks} задач`;
};

export const createScheduleHeaderValue = (settings: ITasksSettingsState): string => {
	const {currentMode, year, month} = settings;

	if (currentMode === 'year') {
		return year.toString();
	}

	if (currentMode === 'month') {
		return `${getMonthName(month).toLowerCase()} ${year}`;
	}

	return year.toString();
};