import React, {useMemo} from 'react';
import {ITaskPriority, TaskPriorityId} from '@tehzor/tools/interfaces/tasks/ITaskPriority';
import './PriorityView.less';

export const PriorityView: React.FC<{
	value: string;
	prioritiesMap: Record<string, ITaskPriority>;
	short?: boolean;
}> = ({value, prioritiesMap, short}) => {
	const iconsMap = useMemo(
		() => ({
			[TaskPriorityId.HIGHEST]: <i className="tz-priority-highest-16 highest"/>,
			[TaskPriorityId.HIGH]: <i className="tz-priority-high-16 high"/>,
			[TaskPriorityId.MEDIUM]: <i className="tz-priority-medium-16 medium"/>,
			[TaskPriorityId.LOW]: <i className="tz-priority-high-16 low"/>,
			[TaskPriorityId.LOWEST]: <i className="tz-priority-highest-16 lowest"/>
		}),
		[]
	);

	return (
		<div
			className="priority"
			style={{backgroundColor: `${prioritiesMap?.[value]?.color}`}}
		>
			{iconsMap?.[value]}
			{!short && (
				<span className="priority-text">
					{prioritiesMap?.[value]?.name}
				</span>
			)}
		</div>
	);
};
