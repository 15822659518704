import React, {useCallback, useState} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useEditableCheckList} from '@src/components/EditableCheckList/hooks/useEditableCheckList';
import {addCheckList} from '@src/store/modules/entities/checkList/actions';
import {addCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import './AddingCheckListDialog.less';

interface IAddingCheckListDialogProps {
	isOpen: boolean;

	close(): void;
}

export const AddingCheckListDialog = ({isOpen, close}: IAddingCheckListDialogProps) => {
	const dispatch = useAppDispatch();

	const [saving, setSaving] = useState(false);
	const [checkListFields, checkItemsFields, getSavingData, reset, isBlocking]
		= useEditableCheckList({saving});

	const handleSave = useCallback(async () => {
		setSaving(true);

		const savingData = getSavingData();

		try {
			if (savingData && savingData.checkList && savingData.checkItems) {
				const {checkList, checkItems} = savingData;

				const result = await dispatch(addCheckList(checkList));
				await dispatch(addCheckItems(result.id, checkItems));
			}
		} finally {
			setSaving(false);
			reset();
			close();
		}
	}, [getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, close, getClosingConfirmation]);

	return (
		<Dialog
			className={{root: 'adding-check-list', content: 'adding-check-list__container'}}
			isOpen={isOpen}
			title="Создать шаблон чек-листа"
			footer={(
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={cancel}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{checkListFields}
			{checkItemsFields}
			{closingDialog}
		</Dialog>
	);
};
