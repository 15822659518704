import React from 'react';
import {DelegationDialog} from '@src/components/DelegationDialog';
import {InlineButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {editTaskResponsible} from '@src/store/modules/pages/task/actions/editResponsible';

interface IDelegationButtonProps {
	task: IEnrichedTask;
	onlyActiveGroup?: boolean;
}

export const DelegationButton = ({task, onlyActiveGroup}: IDelegationButtonProps) => {
	const {id, objectId, respUsers = [], activeGroup} = task;

	const usersIdsArr = respUsers?.map(user => user.id);

	const dispatch = useAppDispatch();

	const [isOpen, toggleOpen] = useToggle(false);

	const [, handleChange] = useAsyncFn(
		async (users: string[], group: string | undefined) => {
			await dispatch(editTaskResponsible(objectId.id, id, users, group));
		},
		[task]
	);

	return (
		<>
			<InlineButton
				className="responsible-view-field__btn"
				type="accent"
				label="Переделегировать"
				leftIcon={<i className="tz-delegate-20"/>}
				onClick={toggleOpen}
			/>

			<DelegationDialog
				objectId={objectId.id}
				activeGroup={activeGroup}
				selectedUsers={usersIdsArr}
				selectedGroup={activeGroup}
				onlyInitialLevelSelectable={onlyActiveGroup}
				isOpen={isOpen}
				onChange={handleChange}
				onClose={toggleOpen}
			/>
		</>
	);
};
