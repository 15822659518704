import {useCategoriesCacheDefaults} from './categories/defaults/useCategoriesCacheDefaults';
import {useCategoriesSetsCacheDefaults} from './categoriesSets/defaults/useCategoriesSetsCacheDefaults';
import {useCheckItemsCacheDefaults} from './checkItems/defaults/useCheckItemsCacheDefaults';
import {useCheckListsCacheDefaults} from './checkLists/defaults/useCheckListsCacheDefaults';
import {useCheckRecordsCacheDefaults} from './checkRecords/defaults/useCheckRecordsCacheDefaults';
import {useChecksCacheDefaults} from './checks/defaults/useChecksCacheDefaults';
import {useInspectionsCacheDefaults} from './inspections/defaults/useInspectionsCacheDefaults';
import {useInternalAcceptancesCacheDefaults} from './internalAcceptances/defaults/useInternalAcceptancesCacheDefaults';
import {useObjectsCacheDefaults} from './objects/defaults/useObjectsCacheDefaults';
import {useOwnerAcceptancesCacheDefaults} from './ownerAcceptances/defaults/useOwnerAcceptancesCacheDefaults';
import {usePlansCacheDefaults} from './plans/defaults/usePlansCacheDefaults';
import {useProblemsCacheDefaults} from './problems/defaults/useProblemsCacheDefaults';
import {useProblemTemplatesSetsCacheDefaults} from './problemTemplatesSets/defaults/useProblemTemplatesSetsCacheDefaults';
import {useProblemViewsCacheDefaults} from './problemViews/defaults/useProblemViewsCacheDefaults';
import {useRegulatoryStandardsCacheDefaults} from './regulatoryStandards/defaults/useRegulatoryStandardsCacheDefaults';
import {useResponsibilityRulesCacheDefaults} from './responsibilityRules/defaults/useResponsibilityRulesCacheDefaults';
import {useRestDictionariesCacheDefaults} from './restDictionaries/defaults';
import {useSpacesCacheDefaults} from './spaces/defaults/useSpacesCacheDefaults';
import {useSpaceStatusesCacheDefaults} from './spaceStatuses/defaults/useSpaceStatusesCacheDefaults';
import {useSpaceStatusesSetsCacheDefaults} from './spaceStatusesSets/defaults/useSpaceStatusesSetsCacheDefaults';
import {useWarrantyClaimsCacheDefaults} from './warrantyClaims/defaults/useWarrantyClaimsCacheDefaults';
import {useWorkingGroupsCacheDefaults} from './workingGroups/defaults/useWorkingGroupsCacheDefaults';
import {useUnitsCacheDefaults} from '@src/api/cache/units/defaults/useUnitsCacheDefaults';
import {useTasksCacheDefaults} from '@src/api/cache/tasks/defaults/useTasksCacheDefaults';
import {useContractsQueryCacheDefaults} from './contracts/defaults/useContractsQueryCacheDefaults';
import {useLegalsCacheDefaults} from './legals/defaults/useLegalsCacheDefaults';
import {useBankAccountsCacheDefaults} from './bankAccounts/defaults/useBankAccountsCacheDefaults';
import {useAuthorizedPersonsCacheDefaults} from './authorizedPersons/defaults/useAuthorizedPersonsCacheDefaults';
import {useDocumentsCacheDefaults} from './documents/defaults/useDocumentsCacheDefaults';

export const useCacheDefaults = () => {
	usePlansCacheDefaults();
	useObjectsCacheDefaults();
	useCategoriesCacheDefaults();
	useCategoriesSetsCacheDefaults();
	useSpaceStatusesSetsCacheDefaults();
	useSpaceStatusesCacheDefaults();
	useCheckListsCacheDefaults();
	useCheckItemsCacheDefaults();
	useRegulatoryStandardsCacheDefaults();
	useWorkingGroupsCacheDefaults();
	useResponsibilityRulesCacheDefaults();
	useProblemTemplatesSetsCacheDefaults();
	useChecksCacheDefaults();
	useProblemsCacheDefaults();
	useTasksCacheDefaults();
	useInspectionsCacheDefaults();
	useInternalAcceptancesCacheDefaults();
	useOwnerAcceptancesCacheDefaults();
	useWarrantyClaimsCacheDefaults();
	useSpacesCacheDefaults();
	useRestDictionariesCacheDefaults();
	useCheckRecordsCacheDefaults();
	useUnitsCacheDefaults();
	useProblemViewsCacheDefaults();
	useContractsQueryCacheDefaults();
	useLegalsCacheDefaults();
	useBankAccountsCacheDefaults();
	useAuthorizedPersonsCacheDefaults();
	useDocumentsCacheDefaults();
};
