import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeWorkingGroupDeleteRequest} from '@src/api/backend/workingGroup';
import {queryClient} from '@src/api/QueryClient';
import { workingGroupsQueryKeys } from '@src/api/cache/workingGroups/keys';

export interface IDeleteWorkingGroupPayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => {
	addSuccessToast('Удалено', 'Рабочая группа успешно удалена');
	return {
		type: types.DELETE_SUCCESS,
		payload: {id}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении рабочей группы');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет рабочую группу
 *
 * @param groupId id рабочей группы
 */
export const deleteWorkingGroup = (groupId: string) =>
	createApiAction(
		request,
		() => success(groupId),
		failure,
		async () => {
			void queryClient.invalidateQueries(workingGroupsQueryKeys.list());
			return makeWorkingGroupDeleteRequest(groupId);
		}
	);