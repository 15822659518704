import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeChecksExportRequest} from '@src/api/backend/checks';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addExportToast} from '@src/components/ExportToast/addExportToast';

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse, sending: boolean) => {
	if (response.link) {
		addExportToast(
			sending ? 'Документ отправлен' : 'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document,
			undefined,
			undefined
		);
	}
	if (response.warnings?.itemsLimit !== undefined) {
		addWarningToast(
			'Внимание',
			`превышен лимит записей (${String(response.warnings.itemsLimit)})`
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при экспорте');
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует список проверок
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param objectId id объекта
 * @param filters фильтры
 * @param sort сортировка
 * @param selected выбранные элементы
 */
export const exportChecks = (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[]
) =>
	createApiAction<IExportResponse>(request, (r: IExportResponse) => success(r, !!email), failure, () =>
		makeChecksExportRequest(templateId, createDocument, email, objectId, filters, sort, selected, ));
