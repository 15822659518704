import React, {memo} from 'react';
import classNames from 'classnames';
import './CheckboxIcon.less';
import {ICheckboxIconProps} from '../../Checkbox';
import {motion, AnimatePresence} from 'framer-motion';

const animationVariants = {
	visible: {
		opacity: 1,
		transition: {duration: 0.2}
	},
	invisible: {
		opacity: 0,
		transition: {duration: 0.08}
	}
};

const animationTransition = {type: 'tween'};

const CheckboxIcon = ({className, style, checked, semiChecked = false, disabled}: ICheckboxIconProps) => {
	const classes = classNames(
		'checkbox-icon',
		{'checkbox-icon_checked': checked && !semiChecked},
		{'checkbox-icon_semi-checked': checked && semiChecked},
		{'checkbox-icon_disabled': disabled},
		className
	);

	return (
		<div
			className={classes}
			style={style}
		>
			<AnimatePresence
				initial={false}
				mode="wait"
			>
				{checked && (
					<motion.div
						key={+semiChecked}
						className="checkbox-icon__icon-wrap"
						variants={animationVariants}
						initial="invisible"
						animate="visible"
						exit="invisible"
						transition={animationTransition}
					>
						{semiChecked ? (
							<div className="checkbox-icon__semi-check-icon"/>
						) : (
							<div className="checkbox-icon__check-icon-bg">
								<i className="tz-mark-16 checkbox-icon__check-icon"/>
							</div>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default memo(CheckboxIcon);
