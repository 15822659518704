import {IState} from '@src/store/modules';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {createSelector} from 'reselect';

const extractAllIds = (state: IState) => state.dictionaries.problemTagsSets.allIds || [];
const extractById = (state: IState) => state.dictionaries.problemTagsSets.byId || {};
const getSetId = (state: IState, setId: string | undefined) => setId;
const getObjectId = (state: IState, objectId: string) => objectId;
const getStage = (state: IState, objectId: string, stage: ObjectStageIds) => stage;

export const extractProblemTagsSetsAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map(id => byId[id])
);
export const extractProblemTagsSetsAsArrayForObject = createSelector(
	[extractProblemTagsSetsAsArray, getObjectId],
	(array, objectId) => (objectId !== 'all' ? array?.filter(item => item.objects.includes(objectId)) : array)
);

export const extractProblemTagsSetsAsFilteredArray = createSelector(
	[extractProblemTagsSetsAsArray, getObjectId, getStage],
	(array, objectId, stage) =>
		 array.filter(item => item.objects.includes(objectId) && item.stages.includes(stage))

);

export const extractProblemTagsSetById = createSelector([extractById, getSetId], (byId, setId) => {
	if (setId) {
		return byId[setId];
	}
	return undefined;
});
