import React, {forwardRef, memo, Ref} from 'react';
import './UserButton.less';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import classNames from 'classnames';
import Button, {IButtonProps} from '../Button';
import UserInfo from '../../various/UserInfo';
import {UserAvatarSize} from '../../various/UserAvatar';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IUserInfoProps extends Omit<IButtonProps, 'type' | 'label'> {
	user?: IBriefUser;
	avatarSize?: UserAvatarSize;
}

const UserButton = (props: IUserInfoProps, ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>) => {
	const {className, user, avatarSize, ...rest} = props;
	const classes = convertClassNames(className);

	return (
		<Button
			{...rest}
			className={{
				...classes,
				root: classNames('user-button', classes.root),
				wrap: classNames('user-button__wrap', classes.wrap)
			}}
			type="common"
			label={(
				<UserInfo
					className={{position: 'user-button__position'}}
					user={user}
					avatarSize={avatarSize}
				/>
			)}
			ref={ref}
		/>
	);
};

UserButton.displayName = 'UserButton';

export default memo(forwardRef(UserButton));
