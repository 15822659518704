import React, {useCallback} from 'react';
import ProblemsPage from './ProblemsPage';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/problems/actions';
import {changeOffset, clearExpiration} from '@src/store/modules/entities/problems/actions';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
/**
 * Обёртка над страницей нарушений
 * необходима для того чтобы при переходе от одной страницы к другой отрабатывали все методы жизненного цикла
 *
 * @constructor
 */
const AllProblemsPageWrap = (props: object) => {
	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(s => extractProblemsPageSettings(s, 'all'));

	const applyFilters = useCallback((value: IProblemsFiltersState) => {
		dispatch(changeFilters('all', value));
		dispatch(changeOffset('all', 0));
	}, []);

	const onClear = useCallback(() => {
		dispatch(clearFilters('all'));
	}, []);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
			clearExpiration={clearExpiration}
		>
			<ProblemsPage
				key="all-problems"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default AllProblemsPageWrap;
