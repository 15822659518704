import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const extractAllIds = (state: IState) => state.dictionaries.spaceOwners.allIds || [];
const extractById = (state: IState) => state.dictionaries.spaceOwners.byId || {};

/**
 * Извлекает собственников помещения в виде массива
 */
export const extractSpaceOwnersAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);

/**
 * Извлекает собственников для конкретной компании
 */
export const extractSpaceOwnersForCompany = createSelector(
	[extractSpaceOwnersAsArray, (_: unknown, companyId: string) => companyId],
	(owners, companyId) => owners.filter(owner => owner.companyId === companyId)
);

/**
 * Извлекает собственников для конкретного помещения
 */
export const extractSpaceOwnersForSpace = createSelector(
	[extractSpaceOwnersAsArray, (_: unknown, spaceId: string) => spaceId],
	(owners, spaceId) => owners.filter(owner => owner.spaces?.includes(spaceId))
);