import * as helpers from './helpers';

/**
 * Проверяет ограничения полномочия
 *
 * @param restrictions ограничения конкретной роли
 * @param params дополнительные данные, необходимые при проверке
 */
export function checkRestrictions(restrictions: Record<string, unknown> | undefined, params: Record<string, unknown>) {
	// Если нет ограничений, то доступ разрешён
	if (!restrictions || !Object.keys(restrictions).length) {
		return true;
	}
	// Массивы для разных логических операторов
	const or: boolean[] = [];
	const and: boolean[] = [];

	for (const key in restrictions) {
		if (!restrictions.hasOwnProperty(key)) {
			continue;
		}
		// Проверка на наличие соответствующего обработчика ограничения
		if (!helpers[key]) {
			throw new Error('Empty restriction handler');
		}
		// Добавление в массивы результата проверки конкретного ограничения
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
		(helpers[key].logicalOperator === 'and' ? and : or).push(helpers[key](restrictions[key], params));
	}
	// Доступ предоставляется при следующем условии:
	// (or1 || or2 || ...) && (and1 && and2 && ...)
	return (or.length === 0 || or.includes(true)) && (and.length === 0 || !and.includes(false));
}
