import React, {memo} from 'react';
import {CellProps} from 'react-table';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {excelXmlType, pdfType, wordXmlType} from '@tehzor/tools/utils/mimeTypes';

const getIcon = (fileType: string | undefined) => {
	switch (fileType) {
		case wordXmlType:
			return <i className="tz-file-type-doc" />;
		case excelXmlType:
			return <i className="tz-file-type-xls" />;
		case pdfType:
			return <i className="tz-file-type-pdf" />;
		default:
			return null;
	}
};

export const NameCell = memo(({row}: CellProps<IDocument>) => {
	const {number, type, fileName, fileType} = row.original;

	const icon = getIcon(fileType);

	const fallbackName = `${type?.name || 'Документ'} №${number}`;

	return (
		<>
			<div>
				{!!icon && <span className="documents-page__cell_icon">{icon}</span>}
				<span className="documents-page__cell_name">{fileName || fallbackName}</span>
			</div>
			<div>{fileName ? fallbackName : null}</div>
		</>
	);
});
