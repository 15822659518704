import React, {useCallback} from 'react';
import {ObjectsFilter} from '@src/components/EntitiesFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {IOfflineModeSettingsState} from '@src/store/modules/settings/offlineMode/reducers/cache';
import {changeSettings} from '@src/store/modules/settings/offlineMode/actions/changeSettings';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {startCachingResources} from '@src/core/offlineMode/startCachingResources';
import classNames from 'classnames';

export const OfflineModeSettings = () => {
	const dispatch = useAppDispatch();
	const state = useAppSelector(s => s.settings.offlineMode.cache) || {};
	const roles = useAppSelector(s => s.auth.profile.roles);

	const applyFilters = useCallback(
		(value: IOfflineModeSettingsState) => {
			dispatch(changeSettings(value));
			if (value.objects?.length) {
				void startCachingResources(roles, false, value.objects);
			}
		},
		[roles]
	);
	return (
		<EntitiesFiltersProvider
			filters={state}
			onApply={applyFilters}
		>
			<div className="offline-mode-dialog__objects-selection">
				<ObjectsFilter
					value={state?.objects}
					className={classNames('offline-mode-dialog__objects-selection-popup')}
				/>
			</div>
		</EntitiesFiltersProvider>
	);
};
