import React, {useCallback} from 'react';
import ChecksPage from './ChecksPage';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/checks/actions';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import {changeOffset, clearExpiration} from '@src/store/modules/entities/checks/actions';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/reducers';
import ActionsMobile from './components/actions/Actions.mobile';

const AllChecksPageWrap = (props: object) => {
	const dispatch = useAppDispatch();
	useAppHeader({title: 'Проверки', mobileRightButtons: <ActionsMobile/>});

	const {filters} = useAppSelector(s => extractChecksPageSettings(s, 'all'));

	const applyFilters = useCallback((value: IChecksFiltersState) => {
		dispatch(changeFilters('all', value));
		dispatch(changeOffset('all' || 'all', 0));
	}, []);

	const onClear = useCallback(() => {
		dispatch(clearFilters('all'));
	}, []);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
			clearExpiration={clearExpiration}
		>
			<ChecksPage
				key="all-checks"
				className="page-cont_extra-bottom-margin"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default AllChecksPageWrap;
