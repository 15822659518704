/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import './MobilePhotoViewer.less';
import classNames from 'classnames';
import TouchRecognizer, {
	PanEvent,
	PinchEvent,
	SwipeDirection,
	TapEvent
} from '../../various/TouchRecognizer/TouchRecognizer2';
import anime, {AnimeInstance} from 'animejs';
import debounce from 'lodash/debounce';
import Photo from './components/Photo';
import {Dialog} from '../../dialogs';
import CloseButton from './components/CloseButton';
import {Canvas, ICanvasRefObject} from '../../Canvas/Canvas';
import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';

interface IMobilePhotoViewerProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	data: string[];
	value?: number;
	isOpen: boolean;
	editing?: boolean;
	brushColor?: string;
	canvasBaseData?: {canvas: string, original?: string};

	onCanvasChange?: (data: ICanvasRefObject) => void;
	onChange?: (index: number) => void;
	onClose?: () => void;
}

interface IMobilePhotoViewerState {
	value: number;
	viewMode: boolean;
}

class MobilePhotoViewer extends React.PureComponent<IMobilePhotoViewerProps, IMobilePhotoViewerState> {
	static defaultProps = {
		data: []
	};

	private static doubleTapScale = 2.5;

	private static imageTransformRegexp =
		/^translate\(([+-]?\d+\.?\d*)px, ([+-]?\d+\.?\d*)px\) scale\(([+-]?\d+\.?\d*)\)$/;

	private static wrapperAnimationDuration = 250;

	private static imageAnimationDuration = 200;

	/**
	 * Корневой элемент
	 */
	private frameRef?: HTMLDivElement | null;

	/**
	 * Обёртка изображений
	 */
	private wrapperRef?: HTMLDivElement | null;

	/**
	 * Активное изображение
	 */
	private imageRef?: HTMLImageElement | null;

	/**
	 * Предыдущее изображение
	 */
	private prevIimageRef?: HTMLImageElement | null;

	/**
	 * Объект текущей wrapper'а
	 */
	private wrapperAnimation?: AnimeInstance;

	/**
	 * Объект анимации активного изображения
	 */
	private imageAnimation?: AnimeInstance;

	/**
	 * Ширина корневого элемента
	 */
	private frameWidth = 0;

	/**
	 * Высота корневого элемента
	 */
	private frameHeight = 0;

	/**
	 * Смещение wrapper'а
	 */
	private wrapperX = 0;

	/**
	 * Текущая ширина изображения
	 */
	private imageWidth = 0;

	/**
	 * Текущая высота изображения
	 */
	private imageHeight = 0;

	/**
	 * Реаьная ширина изображения
	 */
	private imageNaturalWidth = 0;

	/**
	 * Реаьная высота изображения
	 */
	private imageNaturalHeight = 0;

	/**
	 * Положение изображения по y
	 */
	private imageX = 0;

	/**
	 * Положение изображения по x
	 */
	private imageY = 0;

	/**
	 * Текущее увеличение активного изображения
	 */
	private imageScale = 1;

	/**
	 * Точка по x, относительно которой происходит увеличение (относительно центра фрейма при увеличении 1)
	 */
	private scalePointX = 0;

	/**
	 * Точка по y, относительно которой происходит увеличение (относительно центра фрейма при увеличении 1)
	 */
	private scalePointY = 0;

	/**
	 * Расстояние по x между центром изображения и точкой увеличения (при увеличении 1)
	 */
	private scaleDistanceX = 0;

	/**
	 * Расстояние по y между центром изображения и точкой увеличения (при увеличении 1)
	 */
	private scaleDistanceY = 0;

	/**
	 * Значение предыдущего увеличения до жеста
	 */
	private initialScale = 1;

	/**
	 * Максимальное увеличение текущего изображения
	 */
	private maxScale = 1;

	/**
	 * Обрабатывает событие изменения размера элемента
	 */
	private handleResize = debounce(() => {
		this.saveFrameSize();
		this.wrapperX = -this.state.value * this.frameWidth;
		this.setWrapperWidth();
		this.setWrapperOffset();
	}, 150);

	constructor(props: IMobilePhotoViewerProps) {
		super(props);

		this.state = {value: props.value ?? 0, viewMode: false};
	}

	static getDerivedStateFromProps(nextProps: IMobilePhotoViewerProps, prevState: IMobilePhotoViewerState) {
		if (nextProps.value !== undefined && nextProps.value !== prevState.value) {
			return {value: nextProps.value, viewMode: false};
		}
		return null;
	}

	/**
	 * Переход к следующему изображению
	 */
	next = () => {
		const {value} = this.state;
		if (value < this.props.data.length - 1) {
			this.changeValue(value + 1);
		}
	};

	/**
	 * Переход к предыдущему изображению
	 */
	prev = () => {
		const {value} = this.state;
		if (value > 0) {
			this.changeValue(value - 1);
		}
	};

	componentDidUpdate(prevProps: IMobilePhotoViewerProps, prevState: IMobilePhotoViewerState) {
		if (this.state.value !== prevState.value) {
			this.startWrapperAnimation();
			if (this.state.viewMode !== prevState.viewMode) {
				this.imageX = 0;
				this.imageY = 0;
				this.imageScale = 1;
			}
		}
	}

	componentWillUnmount() {
		this.stopWrapperAnimation();
		this.stopImageAnimation();
	}

	render() {
		const {className, children, data, isOpen, editing, brushColor, canvasBaseData} = this.props;
		const {value, viewMode} = this.state;

		return (
			<Dialog
				className={{
					root: 'mobile-photo-viewer',
					layer: 'mobile-photo-viewer__layer',
					body: 'mobile-photo-viewer__body'
				}}
				closeButton={<CloseButton/>}
				isOpen={isOpen}
				useContentOpenAnimation
				fullScreen
				onRequestClose={this.handleClose}
				onAfterOpen={this.handleAfterOpen}
			>
				<TouchRecognizer
					className={classNames('mobile-photo-viewer__frame', className)}
					dragOptions={{startAxis: viewMode ? 'xy' : 'x'}}
					onPanStart={this.handlePanStart}
					onPanMove={this.handlePanMove}
					onPanEnd={this.handlePanEnd}
					onPinchStart={this.handlePinchStart}
					onPinchMove={this.handlePinchMove}
					onPinchEnd={this.handlePinchEnd}
					onSwipe={this.handleSwipe}
					onDoubleTap={this.handleDoubleTap}
					onResize={this.handleResize}
					onKeyDown={this.handleKeyDown}
					elementRef={this.saveFrameRef}
				>
					<div
						className="mobile-photo-viewer__wrapper"
						onDragStart={this.preventDrag}
						ref={this.saveWrapperRef}
					>
						{data.map((url, index) => (
							<div
								key={index}
								className={classNames('mobile-photo-viewer__image-wrap')}
							>
								{editing && index === value ? (
									<Canvas
										canvasWidth={this.imageWidth}
										canvasHeight={this.imageHeight}
										brushRadius={6}
										imageUrl={canvasBaseData?.original || url}
										savedDrawData={
											canvasBaseData?.canvas
												? (JSON.parse(canvasBaseData.canvas) as ICanvasData)
												: undefined
										}
										brushColor={brushColor}
										onChange={this.props.onCanvasChange}
									/>
								) : (
									<Photo
										className={classNames('mobile-photo-viewer__image')}
										url={url}
										ref={index === value ? this.saveImageRef : undefined}
									/>
								)}
							</div>
						))}
					</div>
				</TouchRecognizer>

				{children}
			</Dialog>
		);
	}

	/**
	 * Обрабатывает событие после открытия диалога
	 */
	private handleAfterOpen = () => {
		this.saveFrameSize();
		this.setWrapperWidth();
		this.saveImageSize();
		this.setMaxScale();
	};

	/**
	 * Обрабатывает событие начала движения
	 */
	private handlePanStart = () => {
		if (!this.state.viewMode) {
			this.stopWrapperAnimation();
		}
	};

	/**
	 * Обрабатывает событие движения
	 *
	 * @param event событие
	 */
	private handlePanMove = (event: PanEvent) => {
		if (!this.state.viewMode && !this.props.editing) {
			const {data} = this.props;
			const {value} = this.state;

			this.wrapperX += event.deltaX;
			let offset: number;

			if (this.wrapperX > 0) {
				// Натянутое движение за границей слева
				offset = this.wrapperX ** 0.5 * 2;
			} else if (this.wrapperX < -this.frameWidth * (data.length - 1)) {
				// Натянутое движение за границей справа
				const a = -this.frameWidth * (data.length - 1);
				offset = a - (a - this.wrapperX) ** 0.5 * 2;
			} else {
				// Проверка нахождения смещения в пределах диапазона от предыдущего изображения до следующего
				offset = Math.max(
					Math.min(this.wrapperX, -this.frameWidth * (value - 1)),
					-this.frameWidth * (value + 1)
				);
				this.wrapperX = offset;
			}
			this.setWrapperOffset(offset);
		} else {
			const {x, y} = this.ensureImagePosition(
				this.imageX + event.deltaX,
				this.imageY + event.deltaY,
				this.imageScale
			);
			this.imageX = x;
			this.imageY = y;
			this.setImageTransform();
		}
	};

	/**
	 * Обрабатывает событие завершения движения
	 *
	 * @param event событие
	 */
	private handlePanEnd = (event: PanEvent) => {
		if (!this.state.viewMode && !this.props.editing) {
			const {value} = this.state;
			const distance = event.initialClientX - event.clientX;

			if (Math.abs(distance) > this.frameWidth * 0.25) {
				if (distance < 0 && value > 0) {
					return this.changeValue(value - 1);
				}
				if (distance > 0 && value < this.props.data.length - 1) {
					return this.changeValue(value + 1);
				}
			}
			this.startWrapperAnimation();
		}
	};

	/**
	 * Обрабатывает событие swipe
	 *
	 * @param event событие
	 * @param direction направление жеста
	 */
	private handleSwipe = (event: PanEvent, direction?: SwipeDirection) => {
		if (!this.state.viewMode && !this.props.editing) {
			const {value} = this.state;
			if (direction) {
				if (direction === 'right' && value > 0) {
					return this.changeValue(value - 1);
				}
				if (direction === 'left' && value < this.props.data.length - 1) {
					return this.changeValue(value + 1);
				}
			}
			this.startWrapperAnimation();
		}
	};

	/**
	 * Обрабатывает событие начала увеличения
	 *
	 * @param event событие
	 */
	private handlePinchStart = (event: PinchEvent) => {
		this.stopWrapperAnimation();
		this.stopImageAnimation();
		this.enterViewMode();

		this.scalePointX = event.clientX - this.frameWidth / 2;
		this.scalePointY = event.clientY - this.frameHeight / 2;
		this.scaleDistanceX = (this.imageX - this.scalePointX) / this.imageScale;
		this.scaleDistanceY = (this.imageY - this.scalePointY) / this.imageScale;
		this.initialScale = this.imageScale;
	};

	/**
	 * Обрабатывает событие увеличения
	 *
	 * @param event событие
	 */
	private handlePinchMove = (event: PinchEvent) => {
		this.imageScale += event.deltaScale * this.initialScale;
		if (this.imageScale < 1) {
			this.imageScale = 1;
		}
		if (this.imageScale > this.maxScale) {
			this.imageScale = this.maxScale;
		}
		const {x, y} = this.ensureImagePosition(
			this.scaleDistanceX * this.imageScale + this.scalePointX,
			this.scaleDistanceY * this.imageScale + this.scalePointY,
			this.imageScale
		);
		this.imageX = x;
		this.imageY = y;
		this.setImageTransform();
	};

	/**
	 * Обрабатывает событие завершения увеличения
	 *
	 * @param event событие
	 */
	private handlePinchEnd = () => {
		if (this.imageX === 0 && this.imageY === 0) {
			this.exitViewMode();
		}
	};

	/**
	 * Обрабатывает событие двойного тапа
	 *
	 * @param event событие
	 */
	private handleDoubleTap = (event: TapEvent) => {
		if (
			event.target === this.imageRef
			&& (this.imageNaturalWidth > this.frameWidth || this.imageNaturalHeight > this.frameHeight)
		) {
			this.stopImageAnimation();
			let x;
			let y;
			let scale = 1;
			if (this.state.viewMode) {
				({x, y} = this.ensureImagePosition(0, 0, scale));
				this.exitViewMode();
			} else {
				scale = Math.min(MobilePhotoViewer.doubleTapScale, this.maxScale);
				// Координаты точки тапа относительно центра фрейма
				const pointX = this.frameWidth / 2 - event.clientX;
				const pointY = this.frameHeight / 2 - event.clientY;
				({x, y} = this.ensureImagePosition(pointX * (scale - 1), pointY * (scale - 1), scale));
				this.enterViewMode();
			}
			this.startImageAnimation(x, y, scale);
		}
	};

	/**
	 * Обрабатывает событие keyDown для перелистывания стрелками
	 */
	private handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'ArrowLeft') {
			this.prev();
		}
		if (event.key === 'ArrowRight') {
			this.next();
		}
	};

	/**
	 * Включает режим просмотра одного изображения
	 */
	private enterViewMode = () => {
		this.setState({viewMode: true});
	};

	/**
	 * Выключает режим просмотра одного изображения
	 */
	private exitViewMode = () => {
		this.setState({viewMode: false});
	};

	/**
	 * Сохраняет ширину корневого элемента
	 */
	private saveFrameSize = () => {
		this.frameWidth = this.frameRef ? this.frameRef.offsetWidth : 0;
		this.frameHeight = this.frameRef ? this.frameRef.offsetHeight : 0;
	};

	/**
	 * Устанавливает ширину wrapper'а
	 */
	private setWrapperWidth = () => {
		if (this.wrapperRef) {
			this.wrapperRef.style.width = `${this.frameWidth * this.props.data.length}px`;
		}
	};

	/**
	 * Устанавливает позицию wrapper'а
	 *
	 * @param offset смещение, если не задано, то берётся this.wrapperX
	 */
	private setWrapperOffset = (offset?: number) => {
		if (this.wrapperRef) {
			this.wrapperRef.style.transform = `translateX(${offset ?? this.wrapperX}px)`;
		}
	};

	/**
	 * Сохраняет размер активного изображения
	 */
	private saveImageSize = () => {
		if (this.imageRef) {
			this.imageWidth = this.imageRef.offsetWidth;
			this.imageHeight = this.imageRef.offsetHeight;
			this.imageNaturalWidth = this.imageRef.naturalWidth;
			this.imageNaturalHeight = this.imageRef.naturalHeight;
		}
	};

	/**
	 * Проверяет позицию активного изображению, чтобы оно не выходило за рамки
	 *
	 * @param x координата по x
	 * @param y координата по y
	 * @param scale масштаб
	 */
	private ensureImagePosition = (x: number, y: number, scale: number) => {
		const result = {x, y};

		const currentImageWidth = this.imageWidth * scale;
		if (currentImageWidth > this.frameWidth) {
			const maxXOffset = Math.abs(this.frameWidth - currentImageWidth) / 2;
			if (x > maxXOffset) {
				result.x = maxXOffset;
			}
			if (x < -maxXOffset) {
				result.x = -maxXOffset;
			}
		} else {
			result.x = 0;
		}

		const currentImageHeight = this.imageHeight * scale;
		if (currentImageHeight > this.frameHeight) {
			const maxYOffset = Math.abs(this.frameHeight - currentImageHeight) / 2;
			if (y > maxYOffset) {
				result.y = maxYOffset;
			}
			if (y < -maxYOffset) {
				result.y = -maxYOffset;
			}
		} else {
			result.y = 0;
		}
		return result;
	};

	/**
	 * Устанавливает transform активному изображению
	 */
	private setImageTransform = (ref = this.imageRef) => {
		if (ref) {
			ref.style.transform = `translate(${this.imageX}px, ${this.imageY}px) scale(${this.imageScale})`;
		}
	};

	/**
	 * Определяет максимальное увеличение для активного изображения
	 */
	private setMaxScale = () => {
		if (this.imageRef) {
			this.maxScale = Math.max(
				this.imageRef.naturalWidth / this.frameWidth,
				this.imageRef.naturalHeight / this.frameHeight
			);
			if (this.maxScale < 1) {
				this.maxScale = 1;
			}
		}
	};

	/**
	 * Анимирует движение к текущему элементу
	 */
	private startWrapperAnimation = () => {
		this.stopWrapperAnimation();
		if (this.wrapperRef) {
			const targets = {offset: +this.wrapperRef.style.transform.slice(11, -3)};
			this.wrapperAnimation = anime({
				targets,
				offset: -this.state.value * this.frameWidth,
				easing: 'cubicBezier(0.32, 0.72, 0.37, 0.95)',
				duration: MobilePhotoViewer.wrapperAnimationDuration,
				update: () => {
					this.wrapperX = targets.offset;
					this.setWrapperOffset();
				},
				complete: () => {
					// Сброс трансформации предыдущего изображения
					if (this.prevIimageRef) {
						this.imageX = 0;
						this.imageY = 0;
						this.imageScale = 1;
						this.setImageTransform(this.prevIimageRef);
					}
				}
			});
		}
	};

	/**
	 * Останавливает анимацию wrapper'а
	 */
	private stopWrapperAnimation = () => {
		if (this.wrapperAnimation) {
			this.wrapperAnimation.pause();
			this.wrapperAnimation = undefined;
		}
	};

	/**
	 * Запускает анимацию активого изображения к указанным координатам и масштабу
	 *
	 * @param x координата по x
	 * @param y координата по y
	 * @param scale масштаб
	 */
	private startImageAnimation = (x: number, y: number, scale: number) => {
		this.stopImageAnimation();
		if (this.imageRef) {
			const targets = {x: 0, y: 0, scale: 1};
			// Извлечение текущих координат и масштабирования из стилей
			const match = MobilePhotoViewer.imageTransformRegexp.exec(this.imageRef.style.transform);
			if (match && match.length === 4) {
				targets.x = +match[1];
				targets.y = +match[2];
				targets.scale = +match[3];
			}
			this.imageAnimation = anime({
				targets,
				x,
				y,
				scale,
				easing: 'easeInOutCubic',
				duration: MobilePhotoViewer.imageAnimationDuration,
				update: () => {
					this.imageX = targets.x;
					this.imageY = targets.y;
					this.imageScale = targets.scale;
					this.setImageTransform();
				}
			});
		}
	};

	/**
	 * Останавливает анимацию активого изображения
	 */
	private stopImageAnimation = () => {
		if (this.imageAnimation) {
			this.imageAnimation.pause();
			this.imageAnimation = undefined;
		}
	};

	/**
	 * Инициирует изменение значения
	 *
	 * @param value новое значение
	 */
	private changeValue = (value: number) => {
		if (this.props.onChange) {
			this.props.onChange(value);
		} else {
			this.setState({value, viewMode: false});
		}
	};

	/**
	 * Обрабатывает закрытие диалога
	 */
	private handleClose = () => {
		this.exitViewMode();
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	/**
	 * Предотвращает перетаскивание
	 *
	 * @param event событие
	 */
	private preventDrag = (event: React.DragEvent) => {
		event.preventDefault();
		return false;
	};

	/**
	 * Сохраняет ссылку на крневой элемент
	 *
	 * @param element dom-элемент
	 */
	private saveFrameRef = (element: HTMLDivElement | null) => {
		this.frameRef = element;
		this.saveFrameSize();
		this.setWrapperWidth();
		this.saveImageSize();
		this.setMaxScale();
	};

	/**
	 * Сохраняет ссылку на wrapper
	 *
	 * @param element dom-элемент
	 */
	private saveWrapperRef = (element: HTMLDivElement | null) => {
		this.wrapperRef = element;
	};

	/**
	 * Сохраняет ссылку на активное изображение
	 *
	 * @param element dom-элемент
	 */
	private saveImageRef = (element: HTMLImageElement | null) => {
		if (this.imageRef) {
			this.prevIimageRef = this.imageRef;
		}
		this.imageRef = element;
		this.saveImageSize();
		this.setMaxScale();
	};
}

export default MobilePhotoViewer;
