/* eslint-disable @typescript-eslint/ban-types */
import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import classNames from 'classnames';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMobileOfflineJournalFiltersBtnProps {
	className?: string;
	style?: React.CSSProperties;
	isFiltersActive?: boolean;
}

export const MobileOfflineJournalFiltersBtn = (props: IMobileOfflineJournalFiltersBtnProps) => {
	const {className, style, isFiltersActive} = props;
	const {pushPath} = useChangePath();
	const goToFilters = useCallback(() => {
		pushPath('filters');
	}, []);

	return (
		<IconButton
			className={classNames('mobile-filters-link-btn', className)}
			style={style}
			type="common"
			onClick={goToFilters}
		>
			<i className="tz-filters-24"/>
			{isFiltersActive && <div className="mobile-filters-link-btn__mark"/>}
		</IconButton>
	);
};
