import {Column} from 'react-table';
import MenuCell from './MenuCell';
import LocationCell from './LocationCell';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';

export const desktopColumns: Array<Column<IInspectionEntity>> = [
	{
		id: 'number',
		Header: '№',
		accessor: row => row.data.number || row.data.localNumber,
		width: 60
	},
	{
		id: 'object',
		Header: 'Объект',
		accessor: row => row.data.object?.name,
		width: 100
	},
	{
		id: 'location',
		Header: 'Место',
		Cell: LocationCell,
		width: 100,
		defaultCanSort: false
	},
	{
		id: 'createdBy',
		Header: 'Автор',
		accessor: row => row.data.createdBy,
		width: 100
	},
	{
		id: 'createdAt',
		Header: 'Добавлено',
		accessor: row => row.data.createdAt,
		width: 80,
		sortDescFirst: true
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: row => row.data.modifiedAt,
		width: 80,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'inspections-page__d-table-menu',
		width: 68,
		minWidth: 68,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const desktopColumnsWithoutObject = desktopColumns.filter(item => item.id !== 'object');
