import React from 'react';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';

interface IOwnerAcceptanceInfoProps {
	className?: string;
	data: IOwnerAcceptance;
	title?: string;
}

const OwnerAcceptanceInfo = ({
	className,
	data,
	title = 'Передача собственнику'
}: IOwnerAcceptanceInfoProps) => (
	<EntityInfo
		className={className}
		title={`${title} №${data.number ?? data.localNumber}`}
		subTitle={<EntityInfoDate value={data.createdAt}/>}
		icon={<i className="tz-owner-acceptance-28"/>}
		iconColor="#2BC697"
		iconSize={44}
	/>
);

export default OwnerAcceptanceInfo;
