import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {UserInfo} from '@tehzor/ui-components';
import React from 'react';
import {CellProps} from 'react-table';

const UserCell = ({row}: CellProps<IFullCategoriesSet>) => {
	if (!row.original.createdBy) {
		return null;
	}

	return (
		<div className="categories-sets__cell-user">
			<UserInfo user={row.original.createdBy}/>
		</div>
	);
};

export default UserCell;
