import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {useMemo} from 'react';
import {Tag} from '@tehzor/ui-components';
import {extractObjectsAsArray} from '@src/store/modules/dictionaries/objects/selectors';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';

export const CheckListObjectsCell = ({row}: CellProps<IPreparedCheckList>) => {
	const rowObjects = row.original.objects || [];
	const objects = useAppSelector(extractObjectsAsArray);

	const selectedObjects = useMemo(
		() => objects.filter(object => rowObjects.includes(object.id)),
		[objects, row.original.objects]
	);

	return (
		<>
			{selectedObjects.length < 3 ? (
				selectedObjects.map(selectedObject => (
					<Tag
						key={selectedObject.id}
						styles={{maxWidth: '100%'}}
						label={selectedObject.name}
					/>
				))
			) : (
				<>
					{selectedObjects.slice(0, 3).map(selectedObject => (
						<Tag
							key={selectedObject.id}
							styles={{maxWidth: '100%'}}
							label={selectedObject.name}
						/>
					))}
					<Tag
						color="#EEF5FB"
						label={`+${selectedObjects.length - 3}`}
					/>
				</>
			)}
		</>
	);
};
