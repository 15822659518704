import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';
import {wsConnector} from '../wsConnector';

export type IAddProblemRespUserResponse = IAddingRespUser;

/**
 * Отправка письма с данными нового ответственного пользователя
 *
 * @param fields данные ответственного пользователя
 */
export const makeAddRespUserRequest = (fields: IAddingRespUser) =>
	wsConnector.sendAuthorizedRequest<IAddProblemRespUserResponse>('addRespUser', {
		...fields
	});
