import useAppSelector from '@src/core/hooks/useAppSelector';
import {getSiblings} from '@src/pages/CheckListPage/selectors/getSiblings';
import React, {useCallback, useState} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckItemLink} from '@tehzor/tools/utils/links';
import {
	FloatingActionButton,
	LoadingPanel,
	MobilePagination,
	Plate,
	TabContent,
	TabLink,
	Tabs
} from '@tehzor/ui-components';
import {CheckListItem} from '@src/pages/CheckListPage/components/list/CheckListItems/components/CheckListItem';
import {ProblemsTable} from '@src/pages/CheckListPage/components/detail/ProblemsTable';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import TitleButtons from '@src/pages/CheckListPage/components/TitleButtons.mobile';
import TitleObjectButtons from '@src/pages/CheckListPage/components/TitleObjectButtons.mobile';
import MobileRightButtons from '../actions/RightButtons.mobile';
import {useAddingCheckListProblemDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListProblemDialog';
import {extractCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {TasksTable} from '@src/pages/CheckListPage/components/detail/TasksTable';
import {useAddingCheckListTaskDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListTaskDialog';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useEntityAddingPermission} from '@src/pages/CheckListPage/hooks/useEntityAddingPermission';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const iconPlus = <i className="tz-plus-16"/>;

export const MobileItemDetail = () => {
	const {replacePath} = useChangePath();
	const params = useStrictParams<{
		listId: string;
		itemId: string;
		objectId: string;
		stage: string;
		spaceId?: string;
	}>();
	const {spaceId, objectId, listId, itemId} = params;
	const [activeTab, setActiveTab] = useState(0);
	const item = useAppSelector(s => extractCheckItemById(s, itemId));
	const siblings = useAppSelector(s => getSiblings(s, item));
	const itemsLoading = useAppSelector(s => s.dictionaries.checkItems.loading);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [problemDialog, handleOpenProblemDialog] = useAddingCheckListProblemDialog(itemId);
	const [taskDialog, handleOpenTaskDialog] = useAddingCheckListTaskDialog();
	const handlePageChange = useCallback(
		(nextIndex: number) => {
			const next = siblings[nextIndex];
			replacePath(formCheckItemLink({...params, itemId: next.id}));
		},
		[siblings, params]
	);
	const roles = useAppSelector(extractUserRoles);

	const canAddEntity = useEntityAddingPermission(objectId, listId);
	const permissions = {
		canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
		canAddEntity
	};

	const handleTabChange = (tabIndex: number) => {
		setActiveTab(tabIndex);
		setSelectedRows([]);
	};
	useAppHeader(
		{
			title: item?.parentId ? 'Подкатегория' : 'Категория чек-листа',
			showBackBtn: true,
			titleButtons: spaceId ? (
				<TitleButtons
					objectId={objectId}
					spaceId={spaceId}
				/>
			) : (
				<TitleObjectButtons objectId={objectId}/>
			),
			mobileRightButtons: spaceId && activeTab === 0 && (
				<MobileRightButtons
					objectId={objectId}
					spaceId={spaceId}
					selectedRows={selectedRows}
					listId={listId}
				/>
			)
		},
		[item, selectedRows]
	);

	if (!item) {
		return null;
	}

	const currentIndex = siblings.findIndex(sibling => sibling.id === item?.id);

	return (
		<div className="check-list-page__m-item-detail">
			<Plate
				withoutPadding
				className="check-list-page__m-nav"
			>
				<MobilePagination
					className="spaces-page__m-pagination"
					page={currentIndex}
					pageCount={siblings.length}
					renderTitle={({page}) => `${page + 1} категория`}
					onPageChange={handlePageChange}
				/>
			</Plate>

			<LoadingPanel active={itemsLoading}>
				<Plate className="check-item-detail">
					<CheckListItem
						mode="detail"
						item={item}
						canAddEntity={canAddEntity}
					/>
				</Plate>
			</LoadingPanel>

			<Plate withoutPadding>
				<Tabs
					className={{
						links: 'check-item-detail__tabs-links',
						link: 'check-item-detail__tabs-link'
					}}
					activeTab={activeTab}
					links={[
						<TabLink
							key="problems"
							label="Нарушения"
						/>,
						<TabLink
							key="tasks"
							label="Задачи"
						/>
					]}
					onActiveTabChange={handleTabChange}
				>
					<TabContent>
						<div className="check-item-detail__scrollable">
							<div className="check-item-detail__items">
								<ProblemsTable
									item={item}
									selectedRows={selectedRows}
									onSelectedRowsChange={setSelectedRows}
								/>
								{permissions.canAddEntity && problemDialog}
							</div>
						</div>
					</TabContent>
					<TabContent>
						<div className="check-item-detail__scrollable">
							<div className="check-item-detail__items">
								<TasksTable
									item={item}
									selectedRows={selectedRows}
									onSelectedRowsChange={setSelectedRows}
								/>
								{permissions.canAddTask && taskDialog}
							</div>
						</div>
					</TabContent>
				</Tabs>

				{permissions.canAddTask && activeTab === 1 && (
					<FloatingActionButton
						icon={iconPlus}
						label="Задача"
						minifyOnScroll
						scrollContainer="scroll-container"
						onClick={handleOpenTaskDialog}
					/>
				)}

				{permissions.canAddEntity && activeTab === 0 && (
					<FloatingActionButton
						icon={iconPlus}
						label="Нарушение"
						minifyOnScroll
						scrollContainer="scroll-container"
						onClick={handleOpenProblemDialog}
					/>
				)}
			</Plate>
		</div>
	);
};
