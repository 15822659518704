import React from 'react';
import './CommonStatsCards.less';
import {StatsByFields} from '@src/utils/statsAggregators/creation';
import NoData from '@src/pages/StatisticsPage/components/NoData';
import {BlockPlaceholder, StatsCard} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {areCreationStatsLoading} from '@src/store/modules/statistics/ui/selectors/warranty';

interface ICommonStatsCardsProps {
	stats?: StatsByFields;
	hideChecks?: boolean;
	hideProblems?: boolean;
	hideProblemReplies?: boolean;
	hideInspections?: boolean;
	hideSpaces?: boolean;
	hideWarrantyClaims?: boolean;
}

const CommonStatsCards = ({
	hideSpaces,
	stats,
	hideChecks,
	hideProblems,
	hideProblemReplies,
	hideInspections,
	hideWarrantyClaims
}: ICommonStatsCardsProps) => {
	const {contractors} = useAppSelector(s => s.settings.pages.statistics.warranty.filters);
	const loading = useAppSelector(areCreationStatsLoading);

	const isEmptyContractorsFilters = !(contractors && Object.keys(contractors).length);
	const checksVisible = !hideChecks && isEmptyContractorsFilters;
	const problemsVisible = !hideProblems;
	const problemRepliesVisible = !hideProblemReplies;
	const inspectionsVisible = !hideInspections && isEmptyContractorsFilters;
	const spacesVisible = !hideSpaces;
	const warrantyClaimsVisible = !hideWarrantyClaims;

	const cards = [];

	if (loading) {
		const count
			= +spacesVisible
			+ +checksVisible
			+ +problemsVisible
			+ +problemRepliesVisible
			+ +inspectionsVisible;
		for (let i = 0; i < count; i++) {
			cards.push(
				<BlockPlaceholder
					key={i}
					className="common-stats-cards__card-placeholder"
				/>
			);
		}
	} else if (stats) {
		if (spacesVisible) {
			cards.push(
				<StatsCard
					key="spaces"
					className="common-stats-cards__card"
					icon={<i className="tz-space-40"/>}
					label="Пройдено помещений"
					value={stats.spaces ?? 0}
					baseColor="#0E72E3"
					iconColor="#0E72E3"
					iconBackgroundColor="transparent"
				/>
			);
		}
		if (checksVisible) {
			cards.push(
				<StatsCard
					key="checks"
					className="common-stats-cards__card"
					icon={<i className="tz-check-28 tz-check-40"/>}
					label="Проверок"
					value={stats.checks ?? 0}
					baseColor="#0E72E3"
					iconColor="#0E72E3"
					iconBackgroundColor="transparent"
				/>
			);
		}
		if (warrantyClaimsVisible) {
			cards.push(
				<StatsCard
					key="claims"
					className="common-stats-cards__card"
					icon={<i className="tz-warranty-claim-28 tz-warranty-claim-40"/>}
					label="Гарантийных обращений"
					value={stats.warrantyClaims ?? 0}
					baseColor="#0E72E3"
					iconColor="#0E72E3"
					iconBackgroundColor="transparent"
				/>
			);
		}
		if (problemsVisible) {
			cards.push(
				<StatsCard
					key="problems"
					className="common-stats-cards__card"
					icon={<i className="tz-problem tz-problem-40"/>}
					label="Нарушений"
					value={stats.problems ?? 0}
					baseColor="#0E72E3"
					iconColor="#0E72E3"
					iconBackgroundColor="transparent"
				/>
			);
		}
		if (problemRepliesVisible) {
			cards.push(
				<StatsCard
					key="problem-replies"
					className="common-stats-cards__card"
					icon={<i className="tz-comment tz-comment-40"/>}
					label="Ответов на нарушения"
					value={stats.problemReplies ?? 0}
					baseColor="#0E72E3"
					iconColor="#0E72E3"
					iconBackgroundColor="transparent"
				/>
			);
		}
		if (inspectionsVisible) {
			cards.push(
				<StatsCard
					key="inspections"
					className="common-stats-cards__card"
					icon={<i className="tz-inspection tz-inspection-40"/>}
					label="Осмотров"
					value={stats.inspections ?? 0}
					baseColor="#0E72E3"
					iconColor="#0E72E3"
					iconBackgroundColor="transparent"
				/>
			);
		}
	} else {
		return <NoData/>;
	}
	return <div className="common-stats-cards">{cards}</div>;
};

export default CommonStatsCards;
