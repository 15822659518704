import {Column} from 'react-table';
import SpaceStatusCell from '@src/components/tableCells/SpaceStatusCell';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import SpaceNameCell from '@src/components/tableCells/SpaceNameCell';
import SpaceIndicatorsCell from '@src/components/tableCells/SpaceIndicatorsCell';
import SpaceTypeCell from '@src/components/tableCells/SpaceTypeCell';
import SpaceStageCell from '@src/components/tableCells/SpaceStageCell';
import ProblemsStatsCell from '@src/components/tableCells/ProblemsStatsCell/ProblemsStatsCell';

export const desktopColumns: Array<Column<IEnrichedSpace>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Название',
		Cell: SpaceNameCell,
		width: 110,
		minWidth: 80
	},
	{
		id: 'status',
		accessor: 'status',
		Header: 'Статус помещения',
		Cell: SpaceStatusCell,
		width: 110,
		minWidth: 80
	},
	{
		id: 'type',
		accessor: 'type',
		Header: 'Тип помещения',
		Cell: SpaceTypeCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'stage',
		Header: 'Стадия',
		Cell: SpaceStageCell,
		width: 70,
		minWidth: 70
	},
	{
		id: 'object',
		accessor: s => s.object?.name,
		Header: 'Объект',
		width: 110,
		minWidth: 80
	},
	{
		id: 'indicators',
		Header: 'Индикаторы',
		Cell: SpaceIndicatorsCell,
		width: 100,
		minWidth: 80
	},
	{
		id: 'problems',
		Header: 'Нарушения',
		Cell: ProblemsStatsCell,
		width: 100,
		minWidth: 80
	}
];
