import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as checkTypes from '../../entities/check/constants';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {IAddCheckPayload, IDeleteCheckPayload} from '@src/store/modules/entities/check/actions';
import {IGetOneUserCheckPayload} from './actions/getOne';

export type ILatestUserChecksState = ILoadedEntityState<ICheck>;

export default createReducer<ILatestUserChecksState>(getLoadedEntitiesInitialState<ICheck>(), {
	[types.GET_SUCCESS]: getLoadedEntitiesSuccess<ICheck>(),
	[types.GET_ONE_SUCCESS]: (state, {payload}: {payload: IGetOneUserCheckPayload}) => {
		if (payload && !state.allIds.includes(payload.id)) {
			state.allIds.push(payload.id);
		}
		state.byId[payload.id] = payload;
	},
	[checkTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddCheckPayload}) => {
		state.allIds.unshift(payload.id);
		state.byId[payload.id] = payload;
	},
	[checkTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteCheckPayload}) => {
		if (state.allIds.includes(payload.checkId)) {
			const byId = {...state.byId};
			delete byId[payload.checkId];
			state.byId = byId;
			state.allIds = state.allIds.filter(id => id !== payload.checkId);
		}
	}
});
