import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckListsAsArray} from '@src/store/modules/dictionaries/checkLists/selectors';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';

/**
 * Возвращает доступные чек-листы для определённых условий (объект, помещение, стадия)
 *
 * @param objectId id объекта
 * @param stage стадия
 */
export function useAvailableCheckLists(
	objectId: string,
	stage: ObjectStageIds | 'all' = 'all',
	type: CheckListTypeId = CheckListTypeId.SPACES,
	structureTypes?: string[],
	categoryId?: string
) {
	const object = useAppSelector(s => extractObject(s, objectId));
	// TODO Брать помещение из общего списка по spaceId (как объект)
	const space = useAppSelector(extractSpace);
	const lists = useAppSelector(extractCheckListsAsArray);

	return useMemo(() => {
		if (!object) {
			return [];
		}

		return lists.filter(checklist => {
			// У чек-листа должна совпадать компания
			if (object.companyId !== checklist.companyId) {
				return false;
			}
			// Должны совпадать объекты (если указаны)
			if (checklist.objects?.length && !checklist.objects.includes(object.id)) {
				return false;
			}
			// Должна совпадать выбранная стадия
			if (stage !== 'all' && checklist.stage !== stage) {
				return false;
			}

			// Проверка дополнительных условий для чек-листов помещений
			if (checklist.type === CheckListTypeId.SPACES && type === CheckListTypeId.SPACES) {
				if (!space) {
					return false;
				}
				// Должен совпадать тип помещения (если указан)
				if (checklist.spaceTypes?.length && !checklist.spaceTypes.includes(space.type)) {
					return false;
				}
				// Если указан тип отделки у помещения
				if (space.typeDecoration) {
					return (
						// Возвращаем чек-листы без типов отделки
						!checklist.typeDecoration?.length
						// и чек-листы с типом отделки помещения
						|| checklist.typeDecoration.includes(space.typeDecoration)
					);
				}
				// Если не указан тип отделки у помещения, то у чек-листа также не должно быть типа отделки
				return !checklist.typeDecoration?.length;
			}

			// TODO Проверка дополнительных условий для чек-листов объектов
			if (checklist.type === CheckListTypeId.OBJECTS && type === CheckListTypeId.OBJECTS) {
				return true;
			}

			if (
				checklist.type === CheckListTypeId.WORK_ACCEPTANCES
				&& type === CheckListTypeId.WORK_ACCEPTANCES
			) {
				if (
					categoryId
					&& !(checklist.categories?.length && checklist.categories.includes(categoryId))
				) {
					return false;
				}
				if (
					!(checklist.structureType && structureTypes?.includes(checklist.structureType))
				) {
					return false;
				}
				return true;
			}
			return false;
		});
	}, [object, space, lists, stage]);
}
