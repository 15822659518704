import React from 'react';

interface IAreaProps {
	planned?: number;
	actual?: number;
}

const Area = ({planned, actual}: IAreaProps) => (
	<div className="space-page__area">
		{planned ? (
			<>
				<div className="space-page__area-title">Строительная площадь</div>
				<div className="space-page__area-value">
					{planned}
					&nbsp;м
					<sup>2</sup>
				</div>
			</>
		) : null}
		{actual ? (
			<>
				<div className="space-page__area-title">Фактическая площадь</div>
				<div className="space-page__area-value">
					{actual}
					&nbsp;м
					<sup>2</sup>
				</div>
			</>
		) : null}
	</div>
);

export default Area;
