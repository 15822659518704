import {
	IEditCategoriesSetResponse,
	requestEditCategoriesSet
} from '@src/api/backend/categoriesSets/edit';
import {createApiAction} from '@src/store/middlewares/api';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';

export type IEditCategoriesSetPayload = IEditCategoriesSetResponse;

const request = () => ({
	type: types.EDIT_REQUEST
});

const success = (categoriesSet: IEditCategoriesSetResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: {...categoriesSet} as IEditCategoriesSetPayload
});

const failure = (error: IError) => ({
	type: types.EDIT_FAILURE,
	payload: error
});

export const editCategoriesSet = (categoriesSet: ISavingCategoriesSet) =>
	createApiAction<IEditCategoriesSetResponse>(
		request,
		req => success(req),
		failure,
		() => requestEditCategoriesSet(categoriesSet)
	);
