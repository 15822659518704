import React from 'react';
import {
	Location,
	NavigateOptions,
	Params,
	useLocation,
	useNavigate,
	useParams
} from 'react-router-dom';

interface RouterProps {
	location: Location;
	navigate: (to: string, options?: NavigateOptions) => void;
	params: Readonly<Params<string>>;
}
export const withRouter = <P extends Record<string, unknown>>(
	Component: React.ComponentType<P & {router: RouterProps}>
): React.ComponentType<P> => {
	function ComponentWithRouterProp(props: P) {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();

		const routerProps: RouterProps = {
			location,
			navigate,
			params
		};
		return (
			<Component
				{...props}
				router={routerProps}
			/>
		);
	}
	return ComponentWithRouterProp;
};
