import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';

import {CellProps} from 'react-table';
import {IEnrichedTaskForScheduler} from '@src/pages/TasksPage/hooks/useEnrichedTasksForSchedule';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export const ResponsibleUsers = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;

	const usersMap = useAppSelector(s => s.dictionaries.users.byId);

	const getLeaderWorkGroupName = (workGroup?: IWorkingGroup): string | undefined => {
		if (!workGroup) return undefined;
		const leaderId = workGroup?.leader;
		return usersMap[leaderId]?.displayName;
	};

	return (
		<span>
			{task?.respUsers && task?.respUsers?.length > 0 ? (
				task?.respUsers?.map(user => (
					<div
						className="tasks-schedule-popup__list-content_user"
						key={user.id}
					>
						{user.displayName}
					</div>
				))
			) : task?.activeGroup ? (
				<div className="tasks-schedule-popup__list-content_user">
					{getLeaderWorkGroupName(task.activeGroup)}
				</div>
			) : (
				<div className="tasks-schedule-popup__list-content_nouser">
					Ответственные не назначены
				</div>
			)}
		</span>
	);
};
