import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import {SpaceStatusesSetsFiltersPage} from '@src/pages/filters/SpaceStatusesSetsFiltersPage';
import React from 'react';

export const spaceStatusesSetsFilterRoutes = [
	{
		path: 'objects',
		element: <FiltersPageObjects/>
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage/>
	},
	{
		path: 'creationDate',
		element: <CreationDateFilterPage/>
	},
	{
		path: '',
		element: <SpaceStatusesSetsFiltersPage/>
	}
];
