import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetResponsibilityRulesResponse} from '@src/api/backend/responsibilityRules';
import {addErrorToast} from '@src/utils/toasts';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {queryClient} from '@src/api/QueryClient';

export type IGetResponsibilityRulesPayload = IGetResponsibilityRulesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetResponsibilityRulesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке правил делегирования');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список правил ответственности
 */
export const getResponsibilityRules = () =>
	createApiAction<IGetResponsibilityRulesResponse>(request, success, failure, () =>
		queryClient.fetchQuery(responsibilityRulesQueryKeys.list(), {staleTime: Infinity}));
