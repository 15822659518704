import {Store} from 'redux';
import ISavingNewAttachment from '@tehzor/tools/interfaces/ISavingNewAttachment';
import LocalStorage from '@src/api/LocalStorage';
import {ILocalProblem} from '@src/interfaces/ILocalProblem';
import {ILocalInspection} from '@src/interfaces/ILocalInspection';
import {addLocalProblem, deleteLocalProblem} from '@src/actions/local-entities/problem';
import {addLocalInspection, deleteLocalInspection} from '@src/actions/local-entities/inspection';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {ILocalCheck} from '@src/interfaces/ILocalCheck';
import {addLocalCheck, changeLocalCheckModifiedAt} from '@src/actions/local-entities/checks';
import {IState} from '@src/store/modules';
import {ILocalInternalAcceptance} from '@src/interfaces/ILocalInternalAcceptance';
import {
	addLocalInternalAcceptance,
	changeLocalInternalAcceptanceModifiedAt
} from '@src/actions/local-entities/internalAcceptance';
import {
	addLocalOwnerAcceptance,
	changeLocalOwnerAcceptanceModifiedAt
} from '@src/actions/local-entities/ownerAcceptance';
import {LocalEntitiesDestination} from '@src/interfaces/LocalEntitiesDestination';
import {ILocalOwnerAcceptance} from '@src/interfaces/ILocalOwnerAcceptance';
import {ILocalWarrantyClaim} from '@src/interfaces/ILocalWarrantyClaim';
import {
	addLocalWarrantyClaim,
	changeLocalWarrantyClaimModifiedAt
} from '@src/actions/local-entities/warrantyClaim';

const localStorage = LocalStorage.instance;

export interface ICheckEntity extends ILocalCheck {
	type: 'check';
}

export interface IProblemEntity extends ILocalProblem {
	type: 'problem';
}

export interface IInspectionEntity extends ILocalInspection {
	type: 'inspection';
}

export interface IInternalAcceptanceEntity extends ILocalInternalAcceptance {
	type: 'internal-acceptance';
}

export interface IOwnerAcceptanceEntity extends ILocalOwnerAcceptance {
	type: 'owner-acceptance';
}

export interface IWarrantyClaimEntity extends ILocalWarrantyClaim {
	type: 'warranty-claim';
}

export type Entity =
	| IProblemEntity
	| IInspectionEntity
	| ICheckEntity
	| IInternalAcceptanceEntity
	| IOwnerAcceptanceEntity
	| IWarrantyClaimEntity;

/**
 * Сохраняет новые вложения у списка нарушений или осмотров
 *
 * @param {Array<{ newAttachments: ISavingNewAttachment[] }>} items список нарушений или осмотров
 */
async function saveLocalAttachments(item: {newAttachments?: ISavingNewAttachment[]}) {
	const modifiedItem = {...item};
	if (item.newAttachments) {
		const modifiedNewAttachments = [];

		for (const attach of item.newAttachments) {
			if (attach.file) {
				const modifiedAttach = {
					id: attach.id,
					key: Date.now().toString(10),
					file: attach.file
				};
				await localStorage.setItem('attachments', modifiedAttach.key, attach.file);
				modifiedNewAttachments.push(modifiedAttach);
			} else {
				modifiedNewAttachments.push({...attach});
			}
		}
		modifiedItem.newAttachments = modifiedNewAttachments;
	}

	return modifiedItem;
}

const singletonEnforcer = Symbol('LocalEntitiesManager');

class LocalEntitiesManager {
	private static singleton: LocalEntitiesManager;

	private _store: Store;

	/**
	 * Возвращает единственный экземпляр класса
	 *
	 * @returns {LocalEntitiesManager}
	 */

	static get instance(): LocalEntitiesManager {
		if (!this.singleton) {
			this.singleton = new this(singletonEnforcer);
		}
		return this.singleton;
	}

	constructor(enforcer: symbol) {
		if (enforcer !== singletonEnforcer) {
			throw new Error("Cannot construct 'LocalEntitiesManager' class");
		}
	}

	/**
	 * Инициализирует экземпляр класса
	 *
	 * @param {Store} store Redux store
	 */
	initialize = (store: Store) => {
		if (!store) {
			throw new Error("Param 'store' cannot be null");
		}
		this._store = store;
	};

	addEntity = async (entity: Entity) => {
		if (entity.type === 'problem') {
			entity = {
				...entity,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				data: await saveLocalAttachments(entity.data)
			};
			const action = addLocalProblem(entity);
			this._store.dispatch(action);
			if (entity.localLinks) {
				this.changeParentModifiedAt(entity);
			}
		}
		if (entity.type === 'inspection') {
			entity = {
				...entity,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				data: await saveLocalAttachments(entity.data)
			};
			const action = addLocalInspection(entity);
			this._store.dispatch(action);
			if (entity.localLinks) {
				this.changeParentModifiedAt(entity);
			}
		}
		if (entity.type === 'check') {
			entity = {
				...entity,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL
			};
			const action = addLocalCheck(entity);
			this._store.dispatch(action);
		}
		if (entity.type === 'internal-acceptance') {
			entity = {
				...entity,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL
			};
			const action = addLocalInternalAcceptance(entity);
			this._store.dispatch(action);
		}
		if (entity.type === 'owner-acceptance') {
			entity = {
				...entity,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				data: await saveLocalAttachments(entity.data)
			};
			const action = addLocalOwnerAcceptance(entity);
			this._store.dispatch(action);
		}
		if (entity.type === 'warranty-claim') {
			entity = {
				...entity,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				data: await saveLocalAttachments(entity.data)
			};
			const action = addLocalWarrantyClaim(entity);
			this._store.dispatch(action);
		}
	};

	changeParentModifiedAt = (entity: Entity) => {
		const modifiedAt = Number(Date.now().toString(10));
		if (entity.type === 'inspection' || entity.type === 'problem') {
			if (entity.localLinks?.checkId) {
				this._store.dispatch(
					changeLocalCheckModifiedAt(entity.localLinks.checkId, modifiedAt)
				);
			}
			if (entity.localLinks?.internalAcceptanceId) {
				this._store.dispatch(
					changeLocalInternalAcceptanceModifiedAt(
						entity.localLinks.internalAcceptanceId,
						modifiedAt
					)
				);
			}
		}
		if (entity.type === 'problem') {
			if (entity.localLinks?.ownerAcceptanceId) {
				this._store.dispatch(
					changeLocalOwnerAcceptanceModifiedAt(
						entity.localLinks.ownerAcceptanceId,
						modifiedAt
					)
				);
			}
			if (entity.localLinks?.warrantyClaimId) {
				this._store.dispatch(
					changeLocalWarrantyClaimModifiedAt(
						entity.localLinks.warrantyClaimId,
						modifiedAt
					)
				);
			}
		}
	};

	/**
	 * Добавляет локальную проверку
	 *
	 * @param {ILocalCheck} check локальная проверка
	 */
	addCheck = async (check: ILocalCheck) => {
		const {localEntities} = (await this._store.getState()) as IState;
		check.localNumber = `АВТ-${localEntities.checks.length + 1}`;
		await this.addEntity({
			...check,
			type: 'check'
		});
	};

	/**
	 * Добавляет локальную внутреннюю приемку
	 *
	 * @param {ILocalInternalAcceptance} internalAcceptance локальная внутренняя приемка
	 */
	addInternalAcceptance = async (internalAcceptance: ILocalInternalAcceptance) => {
		const {localEntities} = (await this._store.getState()) as IState;
		internalAcceptance.localNumber = `АВТ-${localEntities.internalAcceptances.length + 1}`;
		await this.addEntity({
			...internalAcceptance,
			type: 'internal-acceptance'
		});
	};

	/**
	 * Добавляет локальную передачу собственнику
	 *
	 * @param {ILocalOwnerAcceptance} ownerAcceptance локальная передача собственнику
	 */
	addOwnerAcceptance = async (ownerAcceptance: ILocalOwnerAcceptance) => {
		const {localEntities} = (await this._store.getState()) as IState;
		ownerAcceptance.localNumber = `АВТ-${localEntities.ownerAcceptances.length + 1}`;
		await this.addEntity({
			...ownerAcceptance,
			type: 'owner-acceptance'
		});
	};

	/**
	 * Добавляет локальную гарантию
	 *
	 * @param {ILocalWarrantyClaim} warrantyClaim локальная гарантия
	 */
	addWarrantyClaim = async (warrantyClaim: ILocalWarrantyClaim) => {
		const {localEntities} = (await this._store.getState()) as IState;
		warrantyClaim.localNumber = `АВТ-${localEntities.warrantyClaims.length + 1}`;
		await this.addEntity({
			...warrantyClaim,
			type: 'warranty-claim'
		});
	};

	/**
	 * Добавляет нарушение и сохраняет его при необходимости
	 *
	 * @param {ILocalProblems} problem локальное нарушение
	 */
	addProblem = async (problem: ILocalProblem, destination?: LocalEntitiesDestination) => {
		const linkedProblem = await this.addParentEntity(problem, destination);
		await this.addEntity({
			...linkedProblem,
			type: 'problem'
		});
	};

	/**
	 * Добавляет осмотр в очередь и сохраняет его при необходимости
	 *
	 * @param {ILocalInspection} inspection локальный осмотр
	 */
	addInspection = async (
		inspection: ILocalInspection,
		destination?: LocalEntitiesDestination
	) => {
		const linkedInspection = await this.addParentEntity(inspection, destination);
		await this.addEntity({
			...linkedInspection,
			type: 'inspection'
		});
	};

	/**
	 * Добавление родительской сущности
	 * @param child
	 * @param destination
	 * @returns
	 */

	addParentEntity = async (
		child: ILocalProblem | ILocalInspection,
		destination?: LocalEntitiesDestination
	) => {
		if (destination) {
			if (destination === 'check') {
				if (!child.links?.checkId && !child.localLinks?.checkId) {
					const newCheck: ILocalCheck = {
						key: Date.now().toString(10),
						transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
						stage: child.stage,
						objectId: child.objectId,
						objectName: child.objectName,
						createdAt: child.createdAt,
						modifiedAt: child.createdAt,
						createdBy: child.createdBy
					};
					if (child.links?.spaceId) {
						newCheck.links = {spaceId: child.links.spaceId};
					}
					await this.addCheck(newCheck);
					child.localLinks = {};
					child.localLinks.checkId = newCheck.key;
				}
			}
			if (destination === 'internal-acceptance') {
				if (!child.links?.internalAcceptanceId && !child.localLinks?.internalAcceptanceId) {
					const newInternalAcceptance: ILocalInternalAcceptance = {
						key: Date.now().toString(10),
						transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
						stage: child.stage,
						objectId: child.objectId,
						objectName: child.objectName,
						createdAt: child.createdAt,
						modifiedAt: child.createdAt,
						createdBy: child.createdBy
					};
					if (child.links?.spaceId) {
						newInternalAcceptance.links = {spaceId: child.links.spaceId};
					}
					await this.addInternalAcceptance(newInternalAcceptance);
					child.localLinks = {};
					child.localLinks.internalAcceptanceId = newInternalAcceptance.key;
				}
			}
		}
		return child;
	};

	/**
	 * Удаляет локальную сущность
	 *
	 * @param {string} key id локальной сущности
	 * @param {string} type тип локальной сущности
	 */
	deleteLocalEntity = (key: string, type: string) => {
		if (type === 'problem') {
			this._store.dispatch(deleteLocalProblem(key));
		}
		if (type === 'inspection') {
			this._store.dispatch(deleteLocalInspection(key));
		}
	};

	deleteLocalFiles = async (localFilesKeys: string[]) => {
		for (const key of localFilesKeys) {
			await localStorage.deleteItem('attachments', key);
		}
	};
}

export default LocalEntitiesManager;
