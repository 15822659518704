import {wsConnector} from '../wsConnector';
import {IOwnerAcceptanceStatus} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetOwnerAcceptanceStatusesResponse = INormalizedData<IOwnerAcceptanceStatus>;

/**
 * Возвращает список статусов приёмок собственниками
 */
export const requestOwnerAcceptanceStatuses = () =>
	wsConnector.sendAuthorizedRequest<IGetOwnerAcceptanceStatusesResponse>(
		'getOwnerAcceptanceStatuses'
	);
