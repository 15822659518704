import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useInternalAcceptanceDelete} from '../../hooks/useInternalAcceptanceDelete';
// import {useEntitiesVisibilityFiltering} from '../../hooks/useEntitiesVisibilityFiltering';

interface IMobileMenuProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
	canDeleteInternalAcceptance?: boolean;
}

const MobileMenu = ({objectId, internalAcceptance, canDeleteInternalAcceptance}: IMobileMenuProps) => {
	// const [menu, label] = useEntitiesVisibilityFiltering();
	const [deleteDialog, handleDeleteClick] = useInternalAcceptanceDelete(objectId, internalAcceptance);

	const items = [
		// <MenuItem
		// 	key="filter"
		// 	icon={<i className="tz-delete"/>}
		// 	onClick={handleDeleteClick}
		// >
		// 	Фильтровать
		// </MenuItem>
	];
	if (canDeleteInternalAcceptance) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDeleteInternalAcceptance && deleteDialog}
		</>
	) : null;
};

export default MobileMenu;
