import React, {memo} from 'react';
import {useFilteredDocumentsArray} from '@src/core/hooks/queries/documents';
import {DOCUMENTS_COUNT, EntityDocsItems} from './components/EntityDocsItems';
import {InlineButton, Plate} from '@tehzor/ui-components';
import {useDocumentsDialog} from '../DocumentsDialog';
import './EntityDocs.less';

interface IDesktopEntityDocsProps {
	id: string;
}

export const DesktopEntityDocs = memo((props: IDesktopEntityDocsProps) => {
	const {id} = props;

	const documents = useFilteredDocumentsArray(id);
	const totalCount = documents.length;
	const {documentsDialog, openDocumentsDialog} = useDocumentsDialog(documents);

	if (!totalCount) {
		return null;
	}

	const showButton = totalCount > DOCUMENTS_COUNT;

	return (
		<Plate
			className={{root: 'entity-docs-wrapper'}}
			withoutPadding
		>
			<div className="entity-docs__header">Документы</div>

			<div className="entity-docs__content">
				<EntityDocsItems documents={documents} />

				{showButton && (
					<div className="entity-docs__more">
						<InlineButton
							type="accent"
							label={`Показать все (${totalCount})`}
							onClick={openDocumentsDialog}
						/>
					</div>
				)}

				{documentsDialog}
			</div>
		</Plate>
	);
});
