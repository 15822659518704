import React from 'react';
import './MobileBottomMenu.less';
import classNames from 'classnames';
import {useScrollingReveal} from '../../../hooks/useScrollingReveal';

interface IMobileBottomMenuProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	scrollContainerId?: string;
}

const MobileBottomMenu = ({className, style, children, scrollContainerId}: IMobileBottomMenuProps) => {
	const [{hidden}, ref] = useScrollingReveal<HTMLDivElement>({scrollContainerId});

	return (
		<div
			className={classNames(
				'm-bottom-menu',
				{'m-bottom-menu_hidden': hidden},
				`m-bottom-menu_count_${Array.isArray(children) ? children.length : 1}`,
				className
			)}
			style={style}
			ref={ref}
		>
			{children}
		</div>
	);
};

export default MobileBottomMenu;
