import * as types from '../constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetSpaceTypeDecorationSetsResponse,
	requestSpaceTypeDecorationSets
} from '@src/api/backend/spaceTypeDecorationSets/get_Sets';

export type IGetSpaceTypeDecorationSetsPayload = IGetSpaceTypeDecorationSetsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetSpaceTypeDecorationSetsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке наборов типов отделки помещений');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список наборов типов отделки помещений
 */
export const getSpaceTypeDecorationSets = () =>
	checkLoaded<IState, IGetSpaceTypeDecorationSetsResponse, ApiAction>(
		state => state.entities.spaceTypeDecorationSets,
		createApiAction<IGetSpaceTypeDecorationSetsResponse>(
			request,
			success,
			failure,
			requestSpaceTypeDecorationSets
		)
	);
