import {Column} from 'react-table';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell/InspectionInfoMobileCell';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import {extractInternalAcceptanceProblemCommentsByProblem} from '@src/store/modules/entities/internalAcceptance/selectors';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';

export const mobileColumns: Array<Column<IInternalAcceptanceEntity>> = [
	{
		id: 'info',
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(
				ProblemInfoMobileCell,
				extractInternalAcceptanceProblemCommentsByProblem
			),
			inspection: InspectionInfoMobileCell
		})
	}
];
