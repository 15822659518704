import IRawFile from '../interfaces/IRawFile';
import IUploadingFile from '../interfaces/IUploadingFile';
import {TempFileDestination} from '../enums/TempFileDestination';
import {UploadingFileStatus} from '../enums/UploadingFileStatus';
import ISavingNewAttachment from '../interfaces/ISavingNewAttachment';

export const convertRawToUploading = (
	raw: IRawFile,
	destination: TempFileDestination,
	status = UploadingFileStatus.WAITING,
	sizeError: boolean
): IUploadingFile => ({
	...raw,
	status,
	sizeError,
	destination,
	progress: 0
});

export const convertUploadingToSaving = (item: IUploadingFile): ISavingNewAttachment => ({
	id: item.tempFile?.id
});

export const convertUploadingToLocalSaving = (item: IUploadingFile): ISavingNewAttachment => ({
	id: item.tempFile?.id,
	file: item.original,
	originalFile: item.canvas?.originalFile,
	drawData: item.canvas?.drawedData,
	key: item.key
});
