import {requestWarrantyClaims} from '@src/api/backend/warrantyClaims';
import {QueryFunctionContext, QueryKey, useQueryClient} from '@tanstack/react-query';
import {IWarrantyClaimsListQueryKey, warrantyClaimsQueryKeys} from '../keys';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';

export const useWarrantyClaimsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IWarrantyClaimsListQueryKey>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestWarrantyClaims(filters, sort, offset, pageSize);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(warrantyClaimsQueryKeys.localList(), {
		queryFn: async ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localClaim = await new Promise<Array<[QueryKey, IListWarrantyClaim | undefined]>>(
				resolve => {
					const local = queryClient.getQueriesData<IListWarrantyClaim>({
						queryKey: warrantyClaimsQueryKeys.details(),
						predicate: ({state}) => {
							const {data} = state;
							if ((data as IListWarrantyClaim).transferStatus) {
								if (!objectId || objectId === 'all') {
									return true;
								}
								if (objectId === (data as IListWarrantyClaim).objectId) {
									return true;
								}
							}
							return false;
						}
					});
					resolve(local);
				}
			);
			return localClaim.map(([, claim]) => claim) as IListWarrantyClaim[];
		},
		staleTime: 0,
		cacheTime: Infinity,
		networkMode: 'always'
	});
};
