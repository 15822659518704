import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useProblemBreadcrumbs} from '@src/core/hooks/breadcrumbs/useProblemBreadcrumbs';

interface IPageBreadcrumbsProps {
	objectId: string;
}

const PageBreadcrumbs = ({objectId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useProblemBreadcrumbs(objectId);

	return (
		<AppBreadcrumbs
			className="problem-page__breadcrumbs"
			items={breadcrumbs}
		/>
);
};

export default memo(PageBreadcrumbs);
