import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission, hasPermission} from '@tehzor/tools/utils/findPermission';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';

/**
 * Возвращает полномочия для конкретного нарушения
 *
 * @param problem нарушение
 * @param networkStatus статус соединения
 * @param objectId id объекта
 * @param user текущий пользователь
 */
export const getPermissionsForProblem = (
	problem: IListProblem,
	networkStatus: boolean,
	objectId?: string,
	user?: IUser
) => {
	let canDelete = false;
	const canCopy
		= hasPermission(user?.roles || [], 'problems-add', UserRoleScopes.OBJECT, objectId)
		&& (!!problem.links?.checkId || !!problem.links?.internalAcceptanceId);
	if (user) {
		const deletePermission = findPermission(
			user.roles || [],
			'problemsDelete',
			UserRoleScopes.OBJECT,
			objectId
		);
		if (deletePermission) {
			canDelete = deletePermission.restrictions
				? !!deletePermission.restrictions.problemsCreatedByUser
				  && !!problem.createdBy
				  && user.id === problem.createdBy
				: true;
		}
	}
	return {canDelete, canCopy};
};
