import React, {PureComponent} from 'react';
import Hammer from 'hammerjs';
import {rafThrottle} from '@src/utils/throttle';
import SVG from 'svg.js';

interface IPlanBaseProps {
	frameClass?: string;
	wrapperClass?: string;
	svgClass?: string;

	onScaleChange?(value: number): void;
}

/**
 * Основа для компонетов, манипулирующих планом
 */
class PlanBase<T> extends PureComponent<IPlanBaseProps & T> {
	// Dom элемент фрейма
	protected _frameElement: React.RefObject<HTMLDivElement> = React.createRef();

	// Dom элемент wrapper'а
	protected _wrapperElement: React.RefObject<HTMLDivElement> = React.createRef();

	// Dom элемент svg
	protected _svgElement?: SVG.LinkedHTMLElement;

	// SVG.js объект
	protected _svg?: SVG.Container;

	// Svg группа со слоями
	protected _layersGroup: SVG.G;

	// Hammer.js объект
	protected _hammer: HammerManager;

	// Изначальная ширина фрейма
	private _frameWidth = 0;

	// Изначальная высота фрейма
	private _frameHeight = 0;

	// Позиция фрейма по горизонтали относительно окна
	private _frameLeft = 0;

	// Позиция фрейма по вертикали относительно окна
	private _frameTop = 0;

	// Ширина wrapper'а
	private _wrapperWidth = 0;

	// Высота wrapper'а
	private _wrapperHeight = 0;

	// Изначальная ширина svg
	private _svgInitialWidth = 0;

	// Изначальная высота svg
	private _svgInitialHeight = 0;

	// Положение svg элемента в wrapper'e
	private _svgLeft = 0;

	// Положение svg элемента в wrapper'e
	private _svgTop = 0;

	// Позиция зума
	private _zoomPosition = 0;

	// Текущее увеличение
	private _zoomValue = 1;

	// Увеличение при начале touch события
	private _touchZoomStartValue = 1;

	/**
	 * Обработка события touch увеличения
	 *
	 * @param {Event} e событие
	 * @private
	 */
	private _handlePinch = rafThrottle((event: HammerInput) => {
		this._touchScaleElements(
			event.center.x - this._frameLeft,
			event.center.y - this._frameTop,
			event.scale
		);
	});

	componentDidMount() {
		if (!this._frameElement.current) {
			return;
		}
		this._frameWidth = this._frameElement.current.clientWidth;
		this._frameHeight = this._frameElement.current.clientHeight;

		const frameRect = this._frameElement.current.getBoundingClientRect();
		this._frameLeft = frameRect.left;
		this._frameTop = frameRect.top;

		this._frameElement.current.addEventListener('wheel', this._onMouseWheel);

		this._hammer = new Hammer(this._frameElement.current /* , {scale: 2} */);
		this._hammer.get('pinch').set({enable: true});
		this._hammer.get('pan').set({direction: Hammer.DIRECTION_ALL});
		this._hammer.on('pinchstart', this._handlePinchStart);
		this._hammer.on('pinchin', this._handlePinch);
		this._hammer.on('pinchout', this._handlePinch);
		window.addEventListener('resize', this._onResize);
	}

	componentWillUnmount() {
		if (this._frameElement.current) {
			this._frameElement.current.removeEventListener('wheel', this._onMouseWheel);
		}
		this._hammer.off('pinchstart', this._handlePinchStart);
		this._hammer.off('pinchin', this._handlePinch);
		this._hammer.off('pinchout', this._handlePinch);
		this._hammer.destroy();
		window.removeEventListener('resize', this._onResize);
	}

	/**
	 * Инициализирует DOM элементы редактора
	 *
	 * @param {string} imagePath путь к изображению пдана
	 * @param {function} callback функция, выполняющаяся после завершения инициализации
	 */
	initDOMElements = (imagePath: string, callback?: () => void) => {
		if (!this._wrapperElement.current) {
			return;
		}
		if (this._svg) {
			this._svg.remove();
		}
		this._svg = SVG(this._wrapperElement.current);
		if (this.props.svgClass) {
			// @ts-ignore
			this._svg.addClass(this.props.svgClass);
		}
		this._svgElement = this._svg.node;
		const imageGroup = this._svg.group().attr({id: 'imageGroup'});
		this._layersGroup = this._svg.group().attr({id: 'layersGroup'});

		const imageElement = imageGroup.image(imagePath).loaded(image => {
			this._svgInitialWidth = image.width;
			this._svgInitialHeight = image.height;

			if (this._svg) {
				this._svg.viewbox(0, 0, this._svgInitialWidth, this._svgInitialHeight);
			}
			imageElement
				.size(this._svgInitialWidth, this._svgInitialHeight)
				.on('mousedown', (event: MouseEvent) => {
					event.preventDefault();
				});

			this._setInitialZoom();
			this._scaleElements(0, 0, 0, 0);
			this._setInitialScroll();

			if (callback && typeof callback === 'function') {
				callback();
			}
		});
	};

	render() {
		const {frameClass, wrapperClass} = this.props;

		return (
			<div
				className={frameClass}
				tabIndex={1}
				ref={this._frameElement}
			>
				<div
					className={wrapperClass}
					ref={this._wrapperElement}
				/>
			</div>
		);
	}

	/**
	 * Событие изменения размера окна браузера.
	 *
	 * @private
	 */
	private _onResize = () => {
		if (this._frameElement.current) {
			const frameRect = this._frameElement.current.getBoundingClientRect();
			this._frameLeft = frameRect.left;
			this._frameTop = frameRect.top;
		}
	};

	/**
	 * Событие при прокрутке колесом мыши
	 *
	 * @param {MouseWheelEvent} event событие
	 * @param event.ctrlKey
	 * @param event.clientX
	 * @param event.clientY
	 * @param event.deltaY
	 * @private
	 */
	private _onMouseWheel = (event: WheelEvent) => {
		if (!event.ctrlKey || !this._svg) {
			return;
		}
		event.preventDefault();
		event.stopPropagation();

		this._wheelScaleElements(
			event.clientX - this._frameLeft,
			event.clientY - this._frameTop,
			event.deltaY < 0
		);
	};

	/**
	 * Начало события touch увеличения
	 *
	 * @private
	 */
	private _handlePinchStart = () => {
		this._touchZoomStartValue = this._zoomValue;
	};

	/**
	 * Вычисляет значение зума
	 *
	 * @param {number} pos позиция зума
	 * @returns {number}
	 * @private
	 */
	private _computeZoomValue = (pos: number): number => {
		if (pos <= -24) {
			// @ts-ignore
			return Math.round10(0.01 * pos + 0.29, -2);
		}
		if (pos === 28) {
			return 30;
		}
		// @ts-ignore
		return Math.round10(Math.pow(Math.E, 0.1196 * pos + 0.0014), -2);
	};

	/**
	 * Вычисляет позицию зума
	 *
	 * @param {number} value значение зума
	 * @returns {number}
	 * @private
	 */
	private _computeZoomPosition = (value: number): number => {
		if (value <= 0.05) {
			return Math.round(100 * value - 29);
		}
		if (value === 30) {
			return 28;
		}
		return Math.round(Math.log(value) / 0.1196 - 0.011705685618729096);
	};

	/**
	 * Устанавливает первоначальный зум
	 *
	 * @private
	 */
	private _setInitialZoom = () => {
		const widthZoom = this._frameWidth / this._svgInitialWidth;
		const heightZoom = this._frameHeight / this._svgInitialHeight;
		const minZoom = widthZoom > heightZoom ? heightZoom : widthZoom;
		const minSize
			= this._svgInitialWidth > this._svgInitialHeight
				? this._svgInitialHeight
				: this._svgInitialWidth;

		// @ts-ignore
		this._zoomValue = Math.floor10(minZoom, -2); // minZoom < 1 ? Math.floor10(minZoom, -2) : 1;
		// @ts-ignore
		this._svg.attr('zoom', Math.floor10(minSize / 35, -2));
		this._zoomPosition = this._computeZoomPosition(this._zoomValue);

		const {onScaleChange} = this.props;
		if (onScaleChange) {
			onScaleChange(this._zoomValue);
		}
	};

	/**
	 * Устанавливает первоначальное положение svg элемента
	 *
	 * @private
	 */
	private _setInitialScroll = () => {
		if (this._frameElement.current) {
			this._frameElement.current.scrollLeft = (this._wrapperWidth - this._frameWidth) / 2;
			this._frameElement.current.scrollTop = (this._wrapperHeight - this._frameHeight) / 2;
		}
	};

	/**
	 * Масштабирует элементы поля при скролле мышью
	 *
	 * @param {number} centerX позиция курсора по x
	 * @param {number} centerY позиция курсора по y
	 * @param {boolean | null} zoomDirection направление скролла
	 * @private
	 */
	private _wheelScaleElements = (
		centerX: number,
		centerY: number,
		zoomDirection: boolean | null = null
	) => {
		if (!this._frameElement.current) {
			return;
		}
		// Вычисляем позицию курсора относительно svg элемента
		const relativeX
			= (centerX + this._frameElement.current.scrollLeft - this._svgLeft)
			/ (this._svgInitialWidth * this._zoomValue);
		const relativeY
			= (centerY + this._frameElement.current.scrollTop - this._svgTop)
			/ (this._svgInitialHeight * this._zoomValue);

		// Устанавливаем новый зум
		if (zoomDirection !== null) {
			this._zoomPosition += zoomDirection ? 1 : -1;
			if (this._zoomPosition < -28) {
				this._zoomPosition = -28;
			}
			if (this._zoomPosition > 28) {
				this._zoomPosition = 28;
			}
			this._zoomValue = this._computeZoomValue(this._zoomPosition);
		}
		this._scaleElements(centerX, centerY, relativeX, relativeY);
	};

	/**
	 * Масштабирует элементы поля при touch событии
	 *
	 * @param {number} centerX позиция курсора по x
	 * @param {number} centerY позиция курсора по y
	 * @param {number} touchZoomValue масштаб относительно начала touch события
	 * @private
	 */
	private _touchScaleElements = (centerX: number, centerY: number, touchZoomValue: number) => {
		if (!this._frameElement.current) {
			return;
		}
		// Вычисляем позицию курсора относительно svg элемента
		const relativeX
			= (centerX + this._frameElement.current.scrollLeft - this._svgLeft)
			/ (this._svgInitialWidth * this._zoomValue);
		const relativeY
			= (centerY + this._frameElement.current.scrollTop - this._svgTop)
			/ (this._svgInitialHeight * this._zoomValue);

		// Устанавливаем новый зум
		this._zoomValue = this._touchZoomStartValue * touchZoomValue;
		if (this._zoomValue < 0.01) {
			this._zoomValue = 0.01;
		}
		if (this._zoomValue > 30) {
			this._zoomValue = 30;
		}
		this._zoomPosition = this._computeZoomPosition(this._zoomValue);
		this._scaleElements(centerX, centerY, relativeX, relativeY);
	};

	/**
	 * Масштабирует элементы поля
	 *
	 * @param {number} centerX позиция курсора по x
	 * @param {number} centerY позиция курсора по y
	 * @param {number} relativeX позиция курсора по x относительно svg элемента
	 * @param {number} relativeY позиция курсора по x относительно svg элемента
	 * @private
	 */
	private _scaleElements = (
		centerX: number,
		centerY: number,
		relativeX: number,
		relativeY: number
	) => {
		// Вычисляем размеры wrapper'а
		const scaledWidth = this._frameWidth / this._zoomValue;
		const scaledHeight = this._frameHeight / this._zoomValue;
		this._wrapperWidth
			= (this._svgInitialWidth * this._zoomValue < this._frameWidth
				? scaledWidth * 2
				: scaledWidth + this._svgInitialWidth) * this._zoomValue;
		this._wrapperHeight
			= (this._svgInitialHeight * this._zoomValue < this._frameHeight
				? scaledHeight * 2
				: scaledHeight + this._svgInitialHeight) * this._zoomValue;

		// Вычиляем размеры и позицию svg элемента
		const svgWidth = this._svgInitialWidth * this._zoomValue;
		const svgHeight = this._svgInitialHeight * this._zoomValue;
		this._svgLeft = (this._wrapperWidth - svgWidth) / 2;
		this._svgTop = (this._wrapperHeight - svgHeight) / 2;

		// Вычиляем новое положение скролла во фремйме
		const newScrollLeft = relativeX * svgWidth - centerX + this._svgLeft;
		const newScrollTop = relativeY * svgHeight - centerY + this._svgTop;

		// Устанавливаем значения DOM элементам
		if (this._wrapperElement.current) {
			this._wrapperElement.current.style.width = `${this._wrapperWidth}px`;
			this._wrapperElement.current.style.height = `${this._wrapperHeight}px`;
		}
		if (this._svgElement) {
			this._svgElement.style.width = `${svgWidth}px`;
			this._svgElement.style.height = `${svgHeight}px`;
			this._svgElement.style.left = `${this._svgLeft}px`;
			this._svgElement.style.top = `${this._svgTop}px`;
		}
		if (this._frameElement.current) {
			this._frameElement.current.scrollLeft = newScrollLeft;
			this._frameElement.current.scrollTop = newScrollTop;
		}

		const {onScaleChange} = this.props;
		if (onScaleChange && typeof onScaleChange === 'function') {
			onScaleChange(this._zoomValue);
		}
	};
}

export {IPlanBaseProps};
export default PlanBase;
