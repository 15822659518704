import {IconMenu, MenuItem} from '@tehzor/ui-components';
import React, {useCallback, useContext} from 'react';
import {CellProps} from 'react-table';
// import {DispatchActionCtx} from '../DispatchActionCtx';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {DispatchActionCtx} from '../DispatchActionCtx';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

export const MenuCell = ({row}: CellProps<IPlan>) => {
	const menuActions = [];
	const perms = useTasksPermissions();
	const dispatchAction = useContext(DispatchActionCtx);

	const handleDelete = useCallback(() => {
		void dispatchAction({type: 'delete', payload: row.original});
	}, [dispatchAction, row.original]);

	const handleCopy = useCallback(() => {
		void dispatchAction({type: 'copy', payload: row.original});
	}, [dispatchAction, row.original]);

	if (perms.canDelete) {
		menuActions.push(
			<MenuItem
				key="edit"
				className="menu-item_red"
				icon={<i className="tz-delete"/>}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

	if (perms.canEdit) {
		menuActions.push(
			<MenuItem
				key="copy"
				className="menu-item_red"
				icon={<i className="tz-copy"/>}
				onClick={handleCopy}
			>
				Копировать
			</MenuItem>
		);
	}

	return menuActions.length
		? (
			<ClickPreventWrap>
				<IconMenu>
					{menuActions}
				</IconMenu>
			</ClickPreventWrap>
		)
		: null;
};
