import React from 'react';
import {EntityLinkButton, TouchablePanel} from '@tehzor/ui-components';
import {useEntitiesLinks} from '../../hooks/useEntitiesLinks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useCommentsPermissions} from '../../hooks/useCommentsPermissions';
import {makeCommentTitle} from '../../utils/makeCommentTitle';
import {formProblemCommentsLink} from '@tehzor/tools/utils/links/formProblemCommentsLink';
import {IPermissions} from '../../hooks/usePermissions';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';

interface IMobileLinksProps {
	objectId: string;
	problem: IPreparedProblem;
	permissions: IPermissions;
}

const MobileLinks = (props: IMobileLinksProps) => {
	const {pushPath} = useChangePath();
	const {objectId, problem, permissions} = props;

	const commentsPermissions = useCommentsPermissions(objectId, problem);
	const links = useEntitiesLinks(permissions, objectId, problem);

	return (
		<TouchablePanel className="problem-page__m-links">
			{(commentsPermissions.canViewComments || commentsPermissions.canViewReplies) && (
				<EntityLinkButton
					key="comments"
					className="problem-page__m-link"
					type="accent"
					leftIcon={<i className="tz-comment"/>}
					label={makeCommentTitle(
						commentsPermissions.canViewComments,
						commentsPermissions.canViewReplies
					)}
					url={formProblemCommentsLink(objectId, problem.id)}
					title="Перейти к ответам и комментариям"
					onClick={pushPath}
				/>
			)}

			{links.map(item => (
				<EntityLinkButton
					key={item.key}
					className="problem-page__m-link"
					leftIcon={item.icon}
					label={item.label}
					url={item.url}
					title={item.title}
					onClick={pushPath}
				/>
			))}
		</TouchablePanel>
	);
};

export default MobileLinks;
