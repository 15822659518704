import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type ISpaceTypesState = ILoadableEntitiesState<ISpaceType>;

export const spaceTypes = createReducer<ISpaceTypesState>(
	getLoadableEntitiesInitialState<ISpaceType>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ISpaceType>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ISpaceType>()
	}
);
