import {wsConnector} from '../wsConnector';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

export type IGetCheckListResponse = ICheckList;

/**
 * Возвращает чек-лист
 *
 * @param id id чек-листа
 */
export const requestCheckList = (id: string) =>
	wsConnector.sendAuthorizedRequest<IGetCheckListResponse>('getCheckList', {id});
