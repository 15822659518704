import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IContractForm} from '@tehzor/tools/interfaces/spaces/IContractForm';

export type IGetContractFormsResponse = INormalizedData<IContractForm>;

/**
 * Возвращает список форм договоров
 */
export const requestContractForms = () =>
	wsConnector.sendAuthorizedRequest<IGetContractFormsResponse>('getContractForms');
