import React, {CSSProperties, Dispatch, useCallback} from 'react';
import {IEditableObjectState} from '@src/core/hooks/states/useEditableObjectState';
import {IEditableEntityAction} from '@src/core/states/editableEntityState';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {StageField} from './StageField';

interface IStageFieldsProps<S, E> {
	className?:
		| string
		| {
				root?: string;
				title?: string;
				wrap?: string;
				stage?: string;
			};
	style?: CSSProperties;
	value?: IEditableObjectState['stages'];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	title?: string;
	disabled?: boolean;
	required?: boolean;
	hasError?: boolean;
}

export const StageFields = <S, E>({
	className,
	style,
	value,
	editingDispatch,
	title,
	disabled,
	required,
	hasError
}: IStageFieldsProps<S, E>) => {
	const classes = convertClassNames(className);

	const stages = useAppSelector(extractObjectStagesAsArray);

	const handleChange = useCallback((dateFrom: Date, dateTo: Date, stageId: ObjectStageIds) => {
		editingDispatch({
			field: 'stages' as keyof S,
			type: 'update',
			value: {...value, [stageId]: {from: dateFrom.getTime(), to: dateTo.getTime()}}
		});
	}, [value]);

	const handleClear = useCallback((stageId: ObjectStageIds) => {
		editingDispatch({
			field: 'stages' as keyof S,
			type: 'update',
			value: {...value, [stageId]: null}
		});
	}, [value]);

	return (
		<div
			className={classes.root}
			style={style}
		>
			<div className={classes.title}>{title}</div>
			<div className={classes.wrap}>
				{stages.map(stage => (
					<StageField
						className={classes.stage}
						onChange={handleChange}
						onClear={handleClear}
						stage={stage}
						from={value?.[stage.id]?.from}
						to={value?.[stage.id]?.to}
						error={required && hasError}
						disabled={disabled}
						key={stage.id}
					/>
				))}
			</div>
		</div>
	);
};