import {EntityInnerTable} from '@tehzor/ui-components';
import React, {ReactElement, useCallback} from 'react';
import {Column} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckEntities} from '@src/store/modules/entities/check/selectors';
import SelectionRow from './SelectionRow';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import {formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface ITableProps {
	objectId: string;
	checkId: string;
	columns: Array<Column<ICheckEntity>>;
	hideHead?: boolean;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

const Table = ({
	objectId,
	checkId,
	columns,
	hideHead,
	selectedRows,
	onSelectedRowsChange
}: ITableProps): ReactElement => {
	const {pushPath} = useChangePath();
	const entities = useAppSelector(extractCheckEntities);

	const handleRowClick = useCallback(
		(entity: ICheckEntity) => {
			switch (entity.type) {
				case 'problem':
					return pushPath(formProblemLink(objectId, entity.data.id));
				case 'inspection':
					return pushPath(formInspectionLink(objectId, entity.data.id));
				default:
					return undefined;
			}
		},
		[objectId]
	);

	return (
		<EntityInnerTable
			className="check-page__d-entities"
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable
			selectedRows={selectedRows}
			onRowClick={handleRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					objectId={objectId}
					checkId={checkId}
				/>
			)}
		/>
	);
};

export default Table;
