import React from 'react';
import {StatsTreePanel} from '@tehzor/ui-components';
import {Tree} from 'array-to-tree';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import CommonStatsCards from './CommonStatsCards';
import {StatsMap} from '@src/utils/statsAggregators/creation';

interface ISubObjectStatsProps {
	data: Tree<IStatsObject>;
	stats: StatsMap;
	totalCount?: number;
	level?: number;
}

const SubObjectStats = (props: ISubObjectStatsProps) => {
	const {data, stats, totalCount, level = 0} = props;
	const objectStats = stats[data.id];

	return (
		<StatsTreePanel
			id={data.id}
			title={data.name}
			image={data.image?.url}
			level={level}
			permanentContent={<CommonStatsCards stats={objectStats}/>}
			hiddenContent={data.children?.map(item => (
				<SubObjectStats
					key={item.id}
					data={item}
					stats={stats}
					totalCount={totalCount}
					level={level + 1}
				/>
			))}
		/>
	);
};

export default SubObjectStats;
