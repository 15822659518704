/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, {useMemo} from 'react';
import './ProblemsPieChart.less';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import classNames from 'classnames';
import {Cell, PieChart, Pie, ResponsiveContainer} from 'recharts';
import {prepareData} from './utils/prepareData';
import PieChartLabel from '../PieChartLabel';

interface IProblemsPieChartProps {
	className?: string;
	style?: React.CSSProperties;
	statuses: IProblemStatus[];
	counts: Record<ProblemStatusId, number | undefined>;
	totalCount?: number;
}

export interface IChartDataItem {
	id: string;
	name: string;
	value: number;
	color: string;
}

const chartMargin = {top: 16, right: 16, bottom: 16, left: 16};

const ProblemsPieChart = (props: IProblemsPieChartProps) => {
	const {className, style, statuses, counts, totalCount} = props;
	const data = useMemo(() => prepareData(statuses, counts, totalCount), [statuses, counts, totalCount]);

	return (
		<div
			className={classNames('problems-pie-chart', className)}
			style={style}
		>
			<ResponsiveContainer
				width="100%"
				aspect={1}
			>
				<PieChart margin={chartMargin}>
					<Pie
						data={data}
						dataKey="value"
						nameKey="label"
						startAngle={90}
						endAngle={-270}
						innerRadius="64%"
						outerRadius="100%"
						isAnimationActive={false}
						// @ts-ignore
						label={PieChartLabel}
						labelLine={false}
					>
						{data.map(item => (
							<Cell
								key={item.id}
								fill={item.color}
							/>
						))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>

			{data.length > 0 && <div className="problems-pie-chart__circle"/>}
		</div>
	);
};

ProblemsPieChart.displayName = 'ProblemsPieChart';

export default ProblemsPieChart;
