import React, {useMemo} from 'react';
import {CellProps} from 'react-table';
import {CriticalMarker} from '@src/components/CriticalMarker';
import {EntityLoadingStatus, PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {LocalProblemStatus} from '@src/components/tableCells/LocalProblemStatusCell';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {offlineModeTransferIcons} from '@src/core/offlineMode/utils/offlineModeTransferIcons';
import {offlineModeTransferColors} from '@src/core/offlineMode/utils/offlineModeTransferColors';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import {extractProblemInspectorsGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblemSettings} from '../../utils/useProblemSettings';

interface ILocalMobileProblemCellProps extends CellProps<IProblemEntity> {
	settings?: IProblemSettings;
	withObject?: boolean;
}

const LocalMobileProblemCell = ({
	row, settings, withObject
}: ILocalMobileProblemCellProps) => {
	const {
		stage,
		status,
		plannedFixDate,
		localNumber,
		critical,
		description,
		plannedFixDateOriginal,
		inspectors,
		respUsers,
		activeGroup,
		object,
		attachment,
		transferStatus
	} = row.original.data;

	const inspectorsGroups = useAppSelector(s => (object?.id
		? extractProblemInspectorsGroupsAsArray(s, object?.id, stage)
		: undefined));

	const statusTitle
		= offlineModeTransferTitles[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];
	const statusIcon
		= offlineModeTransferIcons[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];
	const statusColor
		= offlineModeTransferColors[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];

	const firstInspector = inspectors?.[0];
	const userGroup = useMemo(() => {
		if (!firstInspector || !inspectorsGroups?.length) { return undefined; }

		const groups: IWorkingGroup[] = [];
		for (const group of inspectorsGroups) {
			if (group.performers?.includes(firstInspector.id)) {
				groups.push(group);
			} else if (group.leader === firstInspector.id) {
				groups.push(group);
			}
		}

		return groups.length === 1 ? groups[0] : undefined;
	}, [inspectorsGroups, firstInspector]);

	const numberEl = (
		<div className="problems-page__m-table-description-number">
			№
			{localNumber?.toUpperCase()}
		</div>
	);

	const criticalEl = !!critical && <CriticalMarker/>;

	const problemTagsEl = row.original.data.problemTags?.length && (
		<ProblemTagsMarker
			className="problems-page__m-table-tags"
			colors={row.original.data.problemTags}
		/>
	);

	const descriptionEl = (
		<div className="problems-page__m-table-description-text">{description}</div>
	);

	const plannedFixDateEl = plannedFixDate && (
		<div className="problems-page__m-table-plannedFixDate">
			<div>
				Срок устранения:
				{plannedFixDate}
			</div>
			{status.id !== ProblemStatusId.FIXED && plannedFixDateOriginal && (
				<FixDateTag plannedFixDate={plannedFixDateOriginal}/>
			)}
		</div>
	);

	const respUsersEl = !!(activeGroup || respUsers?.length) && (
		<div className="problems-page__m-table-responsible">
			<div className="problems-page__m-table-responsible-title">
				Ответственные:
			</div>
			<div className="problems-page__m-table-responsible-text">
				{activeGroup && (
					<div className="problems-page__m-table-responsible-group">
						{activeGroup}
					</div>
				)}
				{respUsers?.[0] && (
					<div className="problems-page__m-table-responsible-user">
						{respUsers[0]}
					</div>
				)}
				{!!respUsers?.length && respUsers?.length > 1 && (
					<div className="problems-page__m-table-responsible-rest">
						+
						{respUsers.length - 1}
					</div>
				)}
			</div>
		</div>
	);

	const inspectorsEl = !!inspectors?.length && (
		<div className="problems-page__m-table-inspectors">
			<div className="problems-page__m-table-inspectors-title">
				Проверяющие:
			</div>
			<div className="prbolems-page__m-table-inspectors-text">
				{userGroup && (
					<div className="problems-page__m-table-inspectors-group">
						{userGroup.name}
					</div>
				)}
				{inspectors?.[0] && (
					<div className="problems-page__m-table-inspectors-user">
						{inspectors[0]?.displayName}
					</div>
				)}
				{!!inspectors?.length && inspectors?.length > 1 && (
					<div className="problems-page__m-table-inspectors-rest">
						+
						{inspectors.length - 1}
					</div>
				)}
			</div>
		</div>
	);

	const localStatusEl = (
		<div className="problems-page__m-table-local-status">
			<LocalProblemStatus/>
		</div>
	);

	const transferEl = (
		<div className="problems-page__m-table-transfer-status">
			<EntityLoadingStatus
				title={statusTitle}
				icon={statusIcon}
				color={statusColor}
			/>
		</div>
	);

	const objectEl = (
		<div className="problems-page__m-table-object">{object?.name}</div>
	);

	const attachmentsEl = (
		<div className="problems-page__m-table-description-image">
			{attachment?.preview && (
				<PhotoPreview
					url={attachment.preview.url}
					data={0}
				/>
			)}
		</div>
	);

	return (
		<div className="problems-page__mobile-cell-info">
			<ClickPreventWrap className="problems-page__mobile-cell-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()}/>
			</ClickPreventWrap>
			<div>
				<div className="problems-page__m-table-description-header">
					{numberEl}
					{settings?.critical && criticalEl}
					{settings?.problemTags && problemTagsEl}
				</div>

				{settings?.description && descriptionEl}
				{settings?.plannedFixDate && plannedFixDateEl}
				{settings?.respUsers && respUsersEl}
				{settings?.inspectors && inspectorsEl}

				{localStatusEl}
				{transferEl}
				{!!withObject && objectEl}

			</div>
			{settings?.attachments && attachmentsEl}
		</div>
	);
};

export default LocalMobileProblemCell;
