import React from 'react';
import {IConvertedCheckListData} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';
import {Hatch} from './Hatch';
import '../CheckListsSpace.less';
import {isHatchVisible} from '../utils/isHatchVisible';
import {getBorderColor} from '../utils/getBorderColor';
import {getSegments} from '../utils/getSegments';
import {StatsBorderRectangle} from '../../components/StatsBorderRectangle';

interface IBlockProps {
	width: number;
	spaceId: string;
	checkLists: IConvertedCheckListData[] | undefined;
}

export const Block = ({checkLists, width, spaceId}: IBlockProps) => {
	const segments = getSegments(checkLists);
	const segmentsCount = segments.length === 0 ? 1 : segments.length;
	// начальное смещения
	const Lx = 12;
	const Ly = 12;
	// ширина рамки
	const M = width - 2 * Lx;
	// ширина элемента заливки
	const L = (width - 2 * Lx - 3.2) / segmentsCount;

	return (
		<>
			{/* цвет штриховки */}
			<Hatch id="hatch"/>

			<rect
				x={Lx}
				y={Ly}
				width={M}
				rx="9"
				height="46px"
				stroke="E8EAEC"
				strokeWidth="3"
				fill="#E8EAEC"
			/>

			{segments.map((item, index) => (
				<rect
					// eslint-disable-next-line react/no-array-index-key
					key={`${spaceId}${index}`}
					x={1.5 + Lx + index * L}
					y={1.5 + Ly}
					width={L}
					height="43px"
					fill={isHatchVisible(item) ? 'url(#hatch)' : item.color ? item.color : '#ffffff00'}
				/>
			))}
			{checkLists?.map(item => (
				<StatsBorderRectangle
					key={item.key}
					width={M}
					height={46}
					radius={0}
					item={item}
					color={getBorderColor(item.status)}
				/>
			))}
		</>
	);
};
