import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import declination from '@tehzor/tools/utils/declination';
import React, {useMemo} from 'react';
import {CellProps} from 'react-table';

const NameCell = ({row}: CellProps<IFullCategoriesSet>) => {
	const total = useMemo(() => row.original.categories?.length ?? 0, [row.original.categories]);
	const words = ['вид работ', 'вида работ', 'видов работ'];
	const amountOfCategories = useMemo(() => `${total} ${declination(total, words)}`, [total]);

	const name = row.original.name || 'Имя набора не указано';

	return (
		<div className="categories-sets__cell-name">
			<p className="categories-sets__cell-name__header">{name}</p>
			<p className="categories-sets__cell-name__subtext">{amountOfCategories}</p>
		</div>
	);
};

export default NameCell;
