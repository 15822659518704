import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {extractProblemsAndSubProblemsByWorkAcceptanceIdAsArray} from '@src/store/modules/entities/workAcceptance/selectors';

/**
 * Вычисляет количество нарушений по каждому статусу для приёмки работ
 *
 * @param workAcceptanceId id чек-листа
 */
export function useProblemsCountByStatus(
	workAcceptanceId: string

): Array<{status: ProblemStatusId, count: number}> {
	const problems = useAppSelector(state =>
		extractProblemsAndSubProblemsByWorkAcceptanceIdAsArray(state, workAcceptanceId));

	return useMemo(() => {
		const countByStatus = problems.reduce<Record<string, number>>((prev, problem) => {
			if (!prev[problem.status]) {
				prev[problem.status] = 0;
			}
			prev[problem.status] += 1;
			return prev;
		}, {});

		return Object.values(ProblemStatusId)
			.filter(status => status !== ProblemStatusId.EXPIRED)
			.map(status => ({
				status,
				count: countByStatus[status] ?? 0
			}));
	}, [workAcceptanceId, problems]);
}
