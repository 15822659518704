import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeWarrantyClaimExportRequest} from '@src/api/backend/warrantyClaim';
import {addExportToast} from "@src/components/ExportToast/addExportToast";
import {IExportResponse} from "@tehzor/tools/interfaces/IExportResponse";

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse, sending: boolean) => {
	if (response.link) {
		addExportToast(
			sending ? 'Документ отправлен' : 'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document,
			undefined,
			undefined
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при экспорте');
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует гарантийное обращение
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param objectId id объекта
 * @param claimId id обращения
 * @param problems нарушения
 */
export const exportWarrantyClaim = (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	claimId: string,
	problems?: string[]
) =>
	createApiAction<IExportResponse>(request, (r: IExportResponse) => success(r, !!email), failure, () =>
		makeWarrantyClaimExportRequest(templateId, createDocument, email, objectId, claimId, problems));
