import React, {useCallback, useMemo} from 'react';
import SingleSchema from './SingleSchema';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {MobilePagination, Plate} from '@tehzor/ui-components';
import {changeCurrentObject} from '@src/store/modules/entities/spaces/actions';
import {useVisibleObjects} from '../../hooks/useVisibleObjects';
import {extractSpacesSchemaData} from '@src/store/modules/entities/spaces/selectors/schemas';
import {useSchemasLoad} from '../../hooks/useSchemasLoad';
import {DesktopSchemaViewTabs} from '@src/pages/SpacesPage/components/SchemaViewTabs.desktop';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {Table} from '../reportsTable/Table';

interface IMobileSchemasProps {
	objectId: string;
	data: Record<
		string,
		{
			spaces: IEnrichedSpace[];
			filtered: IListSpace[];
		}
	>;
}

const MobileSchemas = ({objectId, data}: IMobileSchemasProps) => {
	const {currentObject} = useAppSelector(s => extractSpacesSchemaData(s, objectId));
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	useSchemasLoad(objectId);

	const objects = useVisibleObjects(objectId);

	const currentIndex = useMemo(() => {
		if (currentObject) {
			const index = objects.indexOf(currentObject);
			return index === -1 ? 0 : index;
		}
		return 0;
	}, [currentObject, objects]);

	const getObjectTitle = useCallback(
		() => objectsMap[currentObject ?? objects[0]]?.name ?? '',
		[currentObject, objects, objectsMap]
	);

	const handlePageChange = useCallback(
		(value: number) => {
			dispatch(changeCurrentObject(objectId, objects[value]));
		},
		[objectId, objects, dispatch]
	);

	const isReportsView = pageSettings.schemaView === SpacesSchemaVariants.REPORTS;

	return (
		<Plate
			withoutPadding
			className="spaces-page__plate"
		>
			<div className="spaces-page__plate-header">
				<DesktopSchemaViewTabs objectId={objectId}/>
			</div>

			{!isReportsView && (
				<>
					<MobilePagination
						className="spaces-page__m-pagination"
						page={currentIndex}
						pageCount={objects.length}
						renderTitle={getObjectTitle}
						onPageChange={handlePageChange}
					/>
					<SingleSchema
						objectId={objects[currentIndex]}
						hideTitle
						spaces={data[objects[currentIndex]]?.spaces.filter(
							space => space.type === pageSettings.type
						)}
						filtered={data[objects[currentIndex]]?.filtered.filter(
							space => space.type === pageSettings.type
						)}
					/>
				</>
			)}
			{isReportsView && (
				<Table objectId={objectId}/>
			)}
		</Plate>
	);
};

export default MobileSchemas;
