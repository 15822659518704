import React, {useEffect} from 'react';
import './WorkAcceptancePage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {Desktop} from './components/Desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {extractWorkAcceptance} from '@src/store/modules/entities/workAcceptance/selectors';
import {getWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions/get';
import {Mobile} from './components/Mobile';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {getCheckRecords, getProblems, getTasks} from '@src/store/modules/pages/checkLists/actions';
import {getStructuresList} from '@src/store/modules/entities/structures/actions';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import {getCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import {getWorkAcceptanceProblems} from '@src/store/modules/entities/workAcceptance/actions';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {setInitialState} from '@src/store/modules/settings/pages/workAcceptances/actions';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const WorkAcceptancePage = () => {
	useScrollRestoration();
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
	}>();
	const workAcceptance = useAppSelector(extractWorkAcceptance);
	const permissions = useWorkAcceptancesPermissions(objectId);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setInitialState(objectId));
	}, [objectId, dispatch]);

	useAsync(async () => {
		await Promise.all<unknown>([
			dispatch(getWorkAcceptance(objectId, workAcceptanceId)),
			dispatch(getWorkAcceptanceProblems(objectId, workAcceptanceId)),
			dispatch(getCheckLists()),
			dispatch(getCheckItems()),
			dispatch(getStructuresList(objectId))
		]);
	});

	const isDesktop = useIsDesktop();
	const structureTypes = Array.from(new Set(workAcceptance?.structures?.map(str => str.type)));
	const checkLists = useAvailableCheckLists(
		objectId,
		workAcceptance?.stage,
		CheckListTypeId.WORK_ACCEPTANCES,
		structureTypes,
		workAcceptance?.categoryId
	);

	useAsync(async () => {
		if (checkLists.length) {
			const ids = checkLists.map(l => l.id);
			await dispatch(getProblems(ids, undefined, [workAcceptanceId]));
			await dispatch(getTasks(ids, undefined, undefined, [workAcceptanceId]));
			await dispatch(getCheckRecords(ids, [objectId], undefined, [workAcceptanceId]));
		}
	}, [objectId, workAcceptanceId, checkLists]);

	if (!workAcceptance) {
		return null;
	}
	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				checkLists={checkLists}
				workAcceptance={workAcceptance}
				permissions={permissions}
			/>
		);
	}
	return (
		<Mobile
			objectId={objectId}
			checkLists={checkLists}
			workAcceptance={workAcceptance}
			permissions={permissions}
		/>
	);
};
