import classNames from 'classnames';
import React from 'react';
import './EntityGrid.less';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IEntityGridProps {
	className?: string | {
		root?: string;
		wrap?: string;
	};
	style?: React.CSSProperties;
	children?: React.ReactNode;
	withBorders?: boolean;
}

export const EntityGrid = ({className, style, children, withBorders}: IEntityGridProps) => {
	const classes = convertClassNames(className);

	return (
		<div
			className={classNames('entity-grid', {'entity-grid_with-borders': withBorders}, classes.root)}
			style={style}
		>
			<div className={classNames('entity-grid__wrap', classes.wrap)}>
				{children}
			</div>
		</div>
	);
};
