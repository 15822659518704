import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export interface ILinksState {
	spaceId?: Record<string, ISpace | undefined>;
	structureId?: Record<string, IStructure | undefined>;
	workAcceptanceId?: Record<string, IWorkAcceptance | undefined>;
	objectId?: Record<string, IObject | undefined>;
	checkListId?: Record<string, ICheckList | undefined>;
	problemId?: Record<string, IProblem | undefined>;
	checkId?: Record<string, ICheck | undefined>;
	internalAcceptanceId?: Record<string, ICheck | undefined>;
}

export const getInitialState = (): ILinksState => ({});

export interface IEntityLinkPayload<T = any> {
	field: keyof ILinksState;
	data: T;
}

export default createReducer<ILinksState>(getInitialState(), {
	[types.RESET]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IEntityLinkPayload}) => {
		const {data, field} = payload;

		if (!state[field]) {
			state[field] = {};
		}

		state[field]![data.id] = data;
	},
	[CLEAR_STORE]: getInitialState
});
