import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {IGetWarrantyClaimsResponse, requestWarrantyClaims} from '@src/api/backend/warrantyClaims';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/reducers';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {getWarrantyClaimsActions} from '@src/store/modules/entities/warrantyClaims/actions';

export const useGetWarrantyClaimsQuery = <T>(
	objectId: string,
	state?: IWarrantyClaimsFiltersState,
	select?: (data: IGetWarrantyClaimsResponse) => T
) => {
	const entities = useAppSelector(s => s.entities);
	const settings = useAppSelector(s => s.settings);
	const dispatch = useAppDispatch();
	const offset = entities?.warrantyClaims[objectId]?.offset || 0;
	const {filters, sort, pageSize} = settings?.pages.warrantyClaims[objectId];
	return useQuery<IGetWarrantyClaimsResponse, IError, T>({
		queryKey: [...warrantyClaimsQueryKeys.list(), state || filters, sort, offset, pageSize],
		queryFn: () => {
			dispatch(getWarrantyClaimsActions.request(objectId));
			return requestWarrantyClaims(state || filters, sort, offset, pageSize);
		},
		onError: (error: IError) => {
			dispatch(getWarrantyClaimsActions.failure(error));
		},
		select,
		enabled: !!entities && !!settings
	});
};

export const useWarrantyClaims = (
	objectId: string,
	state?: IWarrantyClaimsFiltersState
): IListWarrantyClaim[] | undefined =>
	useGetWarrantyClaimsQuery(objectId, state, (data): IListWarrantyClaim[] =>
		data.allIds.map(id => data.byId[id]))?.data;

export const useWarrantyClaimsData = (
	objectId: string,
	state?: IWarrantyClaimsFiltersState
): IGetWarrantyClaimsResponse | undefined =>
	useGetWarrantyClaimsQuery<IGetWarrantyClaimsResponse>(objectId, state)?.data;

export const useLocalWarrantyClaims = (objectId = 'all') => {
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const {data} = useQuery<IListWarrantyClaim[], IError>({
		queryKey: [...warrantyClaimsQueryKeys.localList(), objectId],
		staleTime: Infinity,
		cacheTime: Infinity,
		networkMode: 'always',
		enabled: isOfflineModeAvailable
	});
	return data || [];
};
