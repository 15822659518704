import React from 'react';
import './EyeLoader.less';
import classNames from 'classnames';

interface IEyeLoaderProps {
	className?: string;
	size?: string;
}

export const EyeLoader = ({size = '2em', className}: IEyeLoaderProps) => (
	<svg
		width={size}
		height={`calc(${size} / 2)`}
		fontSize="48px"
		viewBox="0 0 256 111"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={classNames(className, 'eye-loader')}
	>
		<path
			className="eye-loader__lower-base"
			d="M197.314 88.1473C201.415 91.0438 206.809 91.7168 211.187 89.2586C218.344 85.2397 218.99 75.4694 212.358 70.6322C202.877 63.7169 192.519 57.9318 181.482 53.4747C176.069 51.2888 170.335 55.5828 168.56 61.1435C166.775 66.7319 169.206 73.2451 174.61 75.5266C182.659 78.9246 190.264 83.1686 197.314 88.1473Z"
		/>
		<path
			className="eye-loader__lower-base"
			d="M90.8392 57.3715C89.047 52.0746 83.7354 47.8763 78.4853 49.8013C65.1355 54.6962 50.7363 62.323 38.8195 70.8838C32.2678 75.5904 32.9179 85.1163 39.9435 89.0809C44.4694 91.6348 50.0782 90.8104 54.3352 87.8298C63.1064 81.6885 74.678 75.7204 85.0618 71.4581C90.41 69.2628 92.6921 62.8478 90.8392 57.3715Z"
		/>
		<path
			className="eye-loader__back"
			d="M115.98 111C151.878 111 180.98 81.8986 180.98 46C180.98 37.1242 179.201 28.6638 175.98 20.9561H55.98C52.759 28.6638 50.98 37.1242 50.98 46C50.98 81.8986 80.0815 111 115.98 111Z"
		/>
		<path
			className="eye-loader__apple"
			d="M115.98 89C139.728 89 158.98 69.7482 158.98 46C158.98 36.2298 155.721 27.2206 150.232 20L173.658 16C178.336 24.9751 180.98 35.1787 180.98 46C180.98 81.8985 151.878 111 115.98 111C80.0815 111 50.98 81.8985 50.98 46C50.98 35.1787 53.6243 24.9751 58.3021 16L81.728 20C76.2385 27.2206 72.98 36.2298 72.98 46C72.98 69.7482 92.2317 89 115.98 89Z"
		/>
		<circle
			className="eye-loader__pupil"
			cx="96.998"
			cy="40"
			r="24"
		/>
		<path
			className="eye-loader__upper-base"
			d="M248.036 44.5427C215.845 17.2618 174.265 0.0722613 128.865 0.0722613C83.5887 -1.30025 37.0667 17.0016 4.02815 44.5182C-1.06242 48.7579 -1.37335 56.3715 3.35335 61.0134C7.87461 65.4536 15.034 65.2018 19.9664 61.2233C48.875 37.9056 89.5883 23.5723 128.865 23.5723C168.105 23.5723 204.087 37.9351 232.12 61.2247C236.994 65.2742 244.155 65.5219 248.676 61.0818C253.403 56.4398 253.09 48.8259 248.036 44.5427Z"
		/>
	</svg>
);

export default EyeLoader;
