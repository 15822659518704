import {useQuery} from '@tanstack/react-query';
import {IGetUnitsResponse} from '@src/api/backend/units';
import IError from '@tehzor/tools/interfaces/IError';
import {unitsQueryKeys} from '@src/api/cache/units/keys';
import {addErrorToast} from '@src/utils/toasts';
import {IUnits} from '@tehzor/tools/interfaces/units/IUnits';
import {IUnit} from '@tehzor/tools/interfaces/units/IUnit';

export interface IListUnit extends IUnit {
	fullListName: string;
}

export const useUnits = (): IUnits | undefined => {
	const {data} = useQuery<IGetUnitsResponse, IError>({
		queryKey: [...unitsQueryKeys.list()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке единиц измерений');
		}
	});
	return data;
};

export const unitsAsArray = (units?: IUnits): IListUnit[] => units?.allIds?.map(unitId => {
	const unit = units.byId[unitId];
	return {...unit, fullListName: `${unit.fullName} (${unit.name})`};
}) || [];

/**
 * Функция создающая строку с единицами измерения
 * Пример использования: makeUnitStr([IUnit, IUnit], 10)
 * @param  unitsArr массив IUnit[]
 * @param  unitValue строка значения IUnit
 * @param  unitId id нужного IUnit
 */
export const makeUnitStr = (
	unitsArr?: IUnit[],
	unitValue?: string,
	unitId?: string
): string | undefined => {
	if (!unitValue) return undefined;
	const unit = unitsArr?.find(unit => unit.id === unitId);

	let finalStr;

	if (unit) {
		finalStr = `${unitValue} (${unit.name})`;
	} else {
		finalStr = `${unitValue}`;
	}

	return finalStr.toLowerCase();
};