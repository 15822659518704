import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.dictionaries.workAcceptanceStatuses.allIds || [];
const getById = (state: IState) => state.dictionaries.workAcceptanceStatuses.byId || {};

/**
 * Возвращает статусы приёмок работ в виде массива
 */
export const extractWorkAcceptancesStatusesAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);
