import {requestSpaceIndicatorsSets} from '@src/api/backend/spaceIndicatorsSets';
import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';

export const useSpaceIndicatorsSetsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.spaceIndicatorsSets(), {
		queryFn: requestSpaceIndicatorsSets,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
