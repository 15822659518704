import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {extractSpaceStatusesAsArrayByObjectId} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

interface ISpacesStatusesFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpacesStatusesFilterMobile = memo((props: ISpacesStatusesFilterMobileProps) => {
	const statuses = useAppSelector(s => extractSpaceStatusesAsArrayByObjectId(s, props.objectId));

	return (
		<BaseListFilterMobile
			{...props}
			options={statuses}
			label="Статусы помещений"
			filterName="statuses"
		/>
	);
});
