import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';

export type IGetSpaceStatusesResponse = INormalizedData<ISpaceStatus>;

/**
 * Возвращает статусы помещений
 */
export const requestGetSpaceStatuses = () =>
	wsConnector.sendAuthorizedRequest<IGetSpaceStatusesResponse>('getSpaceStatuses');
