import CategoriesSetFilterPage from '@src/pages/filters/CategoriesSetFilterPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import FixDateFilterPage from '@src/pages/filters/FixDateFilterPage';
import {IInspectorsFilterPage} from '@src/pages/filters/InspectorsFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import PlansFilterPage from '@src/pages/filters/PlansFilterPage/PlansFilterPage';
import ProblemStatusesFilterPage from '@src/pages/filters/ProblemStatusesFilterPage';
import ProblemTagsSetFilterPage from '@src/pages/filters/ProblemTagSetFilterPage';
import ProblemsFiltersPage from '@src/pages/filters/ProblemsFiltersPage';
import RespUsersFilterPage from '@src/pages/filters/RespUsersFilterPage';
import StagesFilterPage from '@src/pages/filters/StagesFilterPage';
import React from 'react';

export const problemsFilterRoutes = [
	{
		path: 'plans',
		element: <PlansFilterPage/>
	},
	{
		path: 'problemTags',
		element: <ProblemTagsSetFilterPage/>
	},
	{
		path: 'respusers',
		element: <RespUsersFilterPage/>
	},
	{
		path: 'categories',
		element: <CategoriesSetFilterPage/>
	},
	{
		path: 'objects',
		element: <FiltersPageObjects/>
	},
	{
		path: 'statuses',
		element: <ProblemStatusesFilterPage/>
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage/>
	},
	{
		path: 'inspectors',
		element: <IInspectorsFilterPage/>
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage/>
	},
	{
		path: 'fixdate',
		element: <FixDateFilterPage/>
	},
	{
		path: 'stages',
		element: <StagesFilterPage/>
	},
	{
		path: '',
		element: <ProblemsFiltersPage/>
	}
];
