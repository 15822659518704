import React from 'react';
import {DesktopEntityAdding} from './EntityAdding.desktop';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';

interface IDesktopActionsProps {
	objectId?: string;
}

export const ActionsDesktop = ({objectId}: IDesktopActionsProps) => {
	const permissions = useOwnerAcceptancesPermissions(objectId);
	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return (
		<>
			{objectId !== undefined
				&& 	permissions.canAdd
				&& (problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<DesktopEntityAdding objectId={objectId}/>
				)}
		</>
	);
};
