import {IPlansState} from '@src/store/modules/dictionaries/plans/reducers';

export const getUnloadedObjectIdsForPlans = (
	objectIds: string[],
	allPlans: IPlansState
): string[] => {
	const unloadedObjectIds: string[] = [];
	for (const objectId of objectIds) {
		if (objectId in allPlans) {
			continue;
		}
		unloadedObjectIds.push(objectId);
	}

	return unloadedObjectIds;
}