import React from 'react';
import './DelegationTreeGroupChildren.less';
import {DelegationTreeUser} from '../DelegationTreeUser';
import {Tree} from 'array-to-tree';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {DelegationTreeGroup} from '../DelegationTreeGroup';
import {usePreparedRules} from './hooks/usePreparedRules';

interface IDelegationTreeGroupChildrenProps {
	data: Tree<IEnrichedWorkingGroup>;
	level: number;
	onlyFirstLevelSelectable?: boolean;
}

export const DelegationTreeGroupChildren = (props: IDelegationTreeGroupChildrenProps) => {
	const {data, level, onlyFirstLevelSelectable} = props;

	const [rulesByUsers, rulesByGroups] = usePreparedRules(data.rules);

	return (
		<>
			{data.performers?.map(user => (
				<DelegationTreeUser
					key={user.id}
					user={user}
					groupId={data.id}
					rules={rulesByUsers[user.id]}
					level={level}
					onlyFirstLevelSelectable={onlyFirstLevelSelectable}
				/>
			))}

			{data.children?.map(childGroup => (
				<DelegationTreeGroup
					key={childGroup.id}
					data={childGroup}
					rules={rulesByGroups[childGroup.id]}
					level={level}
					onlyFirstLevelSelectable={onlyFirstLevelSelectable}
				/>
			))}
		</>
	);
};

DelegationTreeGroupChildren.displayName = 'DelegationTreeGroupChildren';