import React from 'react';
import {IconButton, PopupPlate} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {Settings} from './Settings';

const SettingsPopup = () => {
	const [isOpen, toggle] = useToggle(false);

	return (
		<PopupPlate
			className={{
				root: 'spaces-page__settings-popup',
				content: 'spaces-page__settings-popup-content'
			}}
			trigger={({ref}) => (
				<IconButton
					className="spaces-page__settings-btn"
					type="common"
					active={isOpen}
					onClick={toggle}
					ref={ref}
				>
					<i className="tz-settings-24"/>
				</IconButton>
			)}
			arrowVisible
			isOpen={isOpen}
			placement="bottom-end"
			onOutsideClick={toggle}
			onDisappear={toggle}
		>
			<div className="spaces-page__settings-popup-title">Настройки отображения</div>

			<Settings/>
		</PopupPlate>
	);
};

export default SettingsPopup;
