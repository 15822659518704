import {useContractsList, useContractsListAsArray} from '@src/core/hooks/queries/contracts';
import {IEditableProblemAction} from '@src/core/hooks/states/useEditableProblemState';
import {IContractsFiltersState} from '@src/store/modules/settings/pages/contracts/reducers';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import React, {CSSProperties, Dispatch, memo, useCallback, useMemo, useState} from 'react';

interface IContractsProps {
	className?: string;
	style?: CSSProperties;
	label?: string;
	value?: string | null;
	objectId?: string;
	stage?: ObjectStageIds;
	planId?: string;
	categoryId?: string | null;
	editingDispatch: Dispatch<IEditableProblemAction>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const Contracts = memo(
	({
		className,
		style,
		label = 'Договор',
		value,
		objectId,
		stage,
		planId,
		categoryId,
		editingDispatch,
		required,
		disabled,
		hasError
	}: IContractsProps) => {
		const filters: IContractsFiltersState = useMemo(
			() => ({
				categoryIds: categoryId ? [categoryId] : undefined,
				objectIds: objectId ? [objectId] : undefined,
				stageIds: stage ? [stage] : undefined,
				planIds: planId ? [planId] : undefined
			}),
			[objectId, stage, planId, categoryId]
		);

		const contractsData = useContractsList(filters);
		const contract = value ? contractsData?.byId[value] : undefined;
		const contracts = useContractsListAsArray(filters);

		const [search, setSearch] = useState('');
		const clearSearch = () => setSearch('');
		const isSearchShown = (contracts?.length || 0) >= 8;

		const filteredContracts = useMemo(
			() => (contracts ? flatFilter(contracts, 'name', search) : undefined),
			[contracts, search]
		);

		const handleChange = useCallback(
			(value: string) => {
				editingDispatch({type: 'update', field: 'contractId', value});
				if (required) {
					editingDispatch({type: 'update-error', field: 'contractId'});
				}
			},
			[required]
		);

		const handleClear = useCallback(() => {
			editingDispatch({type: 'update', field: 'contractId', value: null});
			if (required) {
				editingDispatch({type: 'update-error', field: 'contractId'});
			}
		}, [required]);

		if (!contracts) {
			return null;
		}

		const selectSearch = isSearchShown ? (
			<SelectSearch
				value={search}
				onChange={setSearch}
				onClear={clearSearch}
			/>
		) : null;

		return (
			<div
				className={className}
				style={style}
			>
				<EditableFieldLabel>{label}</EditableFieldLabel>
				<SelectPopup
					noHeader={!isSearchShown}
					search={selectSearch}
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={contract?.name}
							icon={<i className="tz-simple-arrow-20" />}
							error={required && hasError ? 'Выберите договор' : undefined}
							onClearClick={handleClear}
							disabled={disabled}
							cleanable={!!value}
						/>
					}
				>
					<Select2
						value={value || undefined}
						onChange={handleChange}
					>
						{filteredContracts?.map(contract => (
							<SelectOption
								key={contract.id}
								itemKey={contract.id}
								content={contract.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			</div>
		);
	}
);
