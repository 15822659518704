import React, {memo} from 'react';
import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useEditableBankAccountDialog} from '@src/components/EditableBankAccountDialog/hooks/useEditableBankAccountDialog';
import {useEditableAuthorizedPersonDialog} from '@src/components/EditableAuthorizedPersonDialog/hooks/useEditableAuthorizedPersonDialog';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IAddButtonProps {
	isAuthorizedPersonsMode?: boolean;
	companyId: string;
	legalEntityId: string;
}

const plusIcon = <i className="tz-plus-20" />;

export const AddButton = memo((props: IAddButtonProps) => {
	const {isAuthorizedPersonsMode, companyId, legalEntityId} = props;
	const isLargeTablet = useIsLargeTablet();

	const {bankAccountDialog, handleOpenBankAccountDialog} = useEditableBankAccountDialog({
		companyId,
		legalEntityId
	});
	const {authorizedPersonDialog, handleOpenAuthorizedPersonDialog} =
		useEditableAuthorizedPersonDialog({
			companyId,
			legalEntityId
		});

	if (isAuthorizedPersonsMode) {
		return (
			<>
				{isLargeTablet ? (
					<Button
						className="legal-page__main-sidebar-menu-add-button"
						type="accent-blue"
						leftIcon={plusIcon}
						label="Уполномоченное лицо"
						outerTagType="button"
						onClick={handleOpenAuthorizedPersonDialog}
					/>
				) : (
					<FloatingActionButton
						icon={plusIcon}
						label="Уп. лицо"
						minifyOnScroll
						scrollContainer="scroll-container"
						onClick={handleOpenAuthorizedPersonDialog}
					/>
				)}
				{authorizedPersonDialog}
			</>
		);
	}

	return (
		<>
			{isLargeTablet ? (
				<Button
					className="legal-page__main-sidebar-menu-add-button"
					leftIcon={plusIcon}
					type="accent-blue"
					label="Реквизиты"
					outerTagType="button"
					onClick={handleOpenBankAccountDialog}
				/>
			) : (
				<FloatingActionButton
					icon={plusIcon}
					label="Реквизиты"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleOpenBankAccountDialog}
				/>
			)}
			{bankAccountDialog}
		</>
	);
});
