import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IEditProblemStatusResponse, makeProblemStatusEditRequest} from '@src/api/backend/problem';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

export type IEditProblemStatusPayload = IEditProblemStatusResponse;

const request = () => ({type: types.EDIT_STATUS_REQUEST});

const success = (response: IEditProblemStatusResponse) => ({
	type: types.EDIT_STATUS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса нарушения');
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет статус нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param status статус
 */
export const editProblemStatus = (objectId: string, problemId: string, status: ProblemStatusId) =>
	createApiAction<IEditProblemStatusResponse>(
		request,
		res => {
			void queryClient.invalidateQueries(problemsQueryKeys.list());
			void queryClient.invalidateQueries([...problemsQueryKeys.detail(problemId), objectId]);
			return success(res);
		},
		failure,
		() => makeProblemStatusEditRequest(objectId, problemId, status)
	);
