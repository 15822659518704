import {wsConnector} from '../wsConnector';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';

export type IAddInspectionResponse = IInspection;

/**
 * Добавляет осмотр
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 * @param fields данные осмотра
 */
export const makeInspectionAddRequest = (
	objectId: string,
	links: IInspection['links'] | undefined,
	stage: ObjectStageIds = ObjectStageIds.BUILDING,
	fields: ISavingInspection
) =>
	wsConnector.sendAuthorizedRequest<IAddInspectionResponse>('addInspection', {
		objectId,
		links,
		stage,
		...fields
	});
