import React, {useCallback, useRef, useState} from 'react';
import './AddingProblemDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import AddingProblem, {IAddingProblemRefProps} from '../AddingProblem';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {getLatestUserChecks} from '@src/store/modules/dictionaries/latestUserChecks/actions/get';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {saveProblem} from './utils/saveProblem';

interface IAddingProblemDialogProps {
	objectId: string;
	links?: IProblem['links'];
	stage: ObjectStageIds;
	scope?: string;
	defaultData?: ISavingProblem;
	isOpen: boolean;
	header?: React.ReactElement;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const AddingProblemDialog = (props: IAddingProblemDialogProps) => {
	const {objectId, links, stage, scope, defaultData, isOpen, header, onSuccess, onClose} = props;
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const addingEntityRef = useRef<IAddingProblemRefProps>(null);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	useAsync(async () => {
		if (online) {
			await dispatch(getLatestUserChecks());
		}
	}, [online]);

	const [, handleSave] = useAsyncFn(async () => {
		await saveProblem({addingEntityRef, success, onClose});
	}, [onClose]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<Dialog
			className="adding-check-dialog"
			isOpen={isOpen}
			title="Добавить нарушение"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			{header}
			<AddingProblem
				objectId={objectId}
				links={links}
				stage={stage}
				scope={scope}
				defaultData={defaultData}
				saving={saving}
				onClose={onClose}
				setSaving={setSaving}
				ref={addingEntityRef}
			/>
		</Dialog>
	);
};

export default AddingProblemDialog;
