import {wsConnector} from '../wsConnector';
import {ISavingSpace} from '@tehzor/tools/interfaces/spaces/ISavingSpace';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

export type IEditSpaceResponse = ISpace;

/**
 * Изменяет помещение
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param fields данные помещения
 */
export const makeSpaceEditRequest = (objectId: string, spaceId: string, fields: ISavingSpace) =>
	wsConnector.sendAuthorizedRequest<IEditSpaceResponse>('editSpace', {
		objectId,
		spaceId,
		...fields
	});
