import {useQuery, useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {IAddProblemViewResponse, makeProblemViewAddRequest} from '@src/api/backend/problemViews';
import IError from '@tehzor/tools/interfaces/IError';
import {problemViewsQueryKeys} from '@src/api/cache/problemViews/keys';
import {extractProblemViewsAsArray} from '@src/store/modules/entities/problemViews/selectors';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {addProblemViewActions} from '@src/store/modules/entities/problemViews/actions';

export const useAddProblemView = (objectId: string, problemId: string) => {
	const views = useAppSelector(s => extractProblemViewsAsArray(s, problemId));
	const user = useAppSelector(extractUserProfile);
	const dispatch = useAppDispatch();

	const queryClient = useQueryClient();

	const {data} = useQuery<IAddProblemViewResponse, IError>({
		queryKey: [...problemViewsQueryKeys.add(), problemId],
		queryFn: async () => {
			dispatch(addProblemViewActions.request(problemId));
			return makeProblemViewAddRequest(objectId, problemId);
		},
		onError: (error: IError) => {
			dispatch(addProblemViewActions.failure(error));
		},
		onSuccess: async res => {
			dispatch(addProblemViewActions.success(problemId, res));
			await queryClient.invalidateQueries(problemViewsQueryKeys.list());
		},
		enabled: !!problemId && !views.find(view => view.user.id === user.id)
	});
	return data;
};