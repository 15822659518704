import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

const extractAllIds = (state: IState): string[] =>
	state.entities.spaceTypeDecorationSets.allIds || [];
const extractById = (state: IState): Record<string, ISpaceTypeDecorationSet> =>
	state.entities.spaceTypeDecorationSets.byId || {};
const extractObjectId = (state: IState, objectId: string): string => objectId;

/**
 * Возвращает наборы типов отделки помещений в виде массива
 */
export const extractSpaceTypeDecorationSetsAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);

/**
 * Возвращает наборы типов отделки помещений для конкретного объекта в виде массива
 */
export const extractSpaceTypeDecorationSetsForObjectAsArray = createSelector(
	[extractAllIds, extractById, extractObjectId],
	(allIds, byId, objectId) => allIds.map((id: string) =>
	byId[id]).filter((set: ISpaceTypeDecorationSet) => set.objects.includes(objectId))
);

/**
 * Возвращает наборы типов отделки помещений в виде объекта
 */
export const extractSpaceTypeDecorationSetsAsObject = createSelector([extractById], byId => byId);
