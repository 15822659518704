import {createSelector} from 'reselect';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import arrayToTree from 'array-to-tree';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.pages.problem.comments.allIds || [];
const getById = (state: IState) => state.pages.problem.comments.byId || {};

/**
 * Возвращает комментарии в виде массива
 */
export const extractProblemCommentsAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map(id => byId[id]));

/**
 * Возвращает комментарии в виде дерева
 */
export const extractProblemCommentsAsTree = createSelector(
	[extractProblemCommentsAsArray],
	comments =>
		arrayToTree<IProblemComment>(comments, {
			parentProperty: 'parentId',
			customID: 'id'
		}).filter(comment => !comment.parentId)
);
