import {useQueryClient} from '@tanstack/react-query';
import {addErrorToast} from '@src/utils/toasts';
import {documentsQueryKeys} from '../keys';
import {requestDocuments} from '@src/api/backend/documents';
import {requestDocument} from '@src/api/backend/document';

export const useDocumentsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(documentsQueryKeys.list(), {
		queryFn: async () => requestDocuments(),
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке документов');
		},
		staleTime: 0,
		cacheTime: 0,
		refetchOnWindowFocus: false
	});

	queryClient.setQueryDefaults(documentsQueryKeys.details(), {
		queryFn: async ({queryKey}) => requestDocument(queryKey[2] as string),
		staleTime: 0,
		cacheTime: 0,
		refetchOnWindowFocus: false
	});
};
