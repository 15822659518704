import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import React from 'react';
import {CellProps} from 'react-table';

export const ModifiedCell = ({row}: CellProps<IPreparedContract>) => {
	const {modifiedAt, modifiedBy} = row.original;

	return (
		<div className="contracts__cell-modified">
			{modifiedAt && (
				<div>{format(modifiedAt, dateTimeCommaSeparatedFormat)}</div>
			)}
			{modifiedBy && (
				<div>{modifiedBy.displayName}</div>
			)}
		</div>
	);
};