import React, {ComponentType, useMemo} from 'react';
import './SpacesBoard.less';
import classNames from 'classnames';
import {groupSpacesByFloor} from './utils/groupSpacesByFloor';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {ISpaceComponent} from '../interfaces/ISpaceComponent';
import InlineButton from '../../buttons/LinkButton';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';

export interface ISpacesBoardProps {
	className?: string;
	style?: React.CSSProperties;
	data: IEnrichedSpace[];
	dataFiltered: IListSpace[];
	title?: string;
	reverseFloorSort?: boolean;
	SpaceComponent: ComponentType<ISpaceComponent>;
	onTitleClick?: () => void;
	onSpaceClick?: (space: IEnrichedSpace) => void;
}

export const SpacesBoard = (props: ISpacesBoardProps) => {
	const {
		className,
		style,
		data,
		dataFiltered,
		title,
		onTitleClick,
		reverseFloorSort,
		SpaceComponent,
		onSpaceClick
	} = props;
	const [floors, groups] = useMemo(() => groupSpacesByFloor(data, reverseFloorSort), [data, reverseFloorSort]);

	return (
		<div
			className={classNames(className, 'spaces-board')}
			style={style}
		>
			<div className="spaces-board__building">
				{title !== undefined && (
					<div className="spaces-board__header">
						<InlineButton
							onClick={onTitleClick}
							label={title}
							labelClassName="spaces-board__title"
							disabled={!onTitleClick}
						/>
					</div>
				)}

				{floors.map(floor => (
					<div
						key={floor}
						className="spaces-board__floor"
					>
						<div className="spaces-board__floor-num-wrap">
							<div className="spaces-board__floor-num">{floor}</div>
						</div>

						<div className="spaces-board__spaces">
							{groups[floor].map(space => (
								<SpaceComponent
									key={space.id}
									space={space}
									disabled={dataFiltered.every(filteredSpace => filteredSpace.id !== space.id)}
									onClick={onSpaceClick}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
