import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObjectsAsTree} from '@src/store/modules/dictionaries/objects/selectors';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {convertToStatsObject} from '../utils/convertToStatsObject';
import {statsObjectsSortFn} from '../utils/statsObjectsSortFn';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';
import {filterObjects} from '@src/pages/StatisticsPage/utils/filterObjects';

export function useFilteredCompanies<T>(
	statsByCompanies: {[id: string]: T},
	statsByObjects: {[id: string]: T},
	filters: {
		companies?: string[];
		objects?: string[];
	},
	isFiltersSetUp: boolean
) {
	const companies = useAppSelector(extractCompaniesAsArray);
	const objectsTree = useAppSelector(extractObjectsAsTree);
	const loading = useAppSelector(extractSourcesLoading);

	// Во время загрузки справочников используется основные источники компаний и объектов,
	// чтобы отображать корректное количество элементов с анимацией загрузки.
	// Это необходимо, когда в фильтре выбраны компании/объекты.
	return useMemo(() => {
		const source = companies;

		const result = [];
		for (const company of source) {
			const companyStats = statsByCompanies[company.id];

			const objectsSource = objectsTree
				.map(convertToStatsObject)
				.sort(statsObjectsSortFn)
				.filter(item => item.companyId === company.id);

			const companyObjects = isFiltersSetUp
				? filterObjects(objectsSource, statsByObjects, filters)
				: objectsSource;

			// Компания отображается, если не установлены фильтры, или есть статистика,
			// или компания выбрана в фильтре или есть дочерние объекты со статистикой
			if (
				!isFiltersSetUp
				|| companyStats
				|| filters.companies?.includes(company.id)
				|| companyObjects?.length
			) {
				result.push({company, companyStats, objects: companyObjects});
			}
		}
		return result;
	}, [
		companies,
		objectsTree,
		statsByCompanies,
		statsByObjects,
		isFiltersSetUp,
		filters,
		loading
	]);
}
