import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
	ActionButtons,
	Button,
	Dialog,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useAsync, useAsyncFn, useUpdateEffect} from 'react-use';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesAsArray} from '@src/store/modules/entities/spaces/selectors/schemas';
import {getSpacesSchema} from '@src/store/modules/entities/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {moveWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import SelectSearch, {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';

const arrowIcon = <i className="tz-simple-arrow-20"/>;

interface IMovingWarrantyClaimDialogProps {
	objectId: string;
	claim: IWarrantyClaim;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно перемещения гарантийного обращения помещения
 */
export const MovingWarrantyClaimDialog = ({
	objectId,
	claim,
	isOpen,
	onClose
}: IMovingWarrantyClaimDialogProps) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);
	const objectIds = useAppSelector(s => s.dictionaries.objects.allIds);
	const spaceTypesMap = useAppSelector(s => s.dictionaries.spaceTypes.byId);
	const [objectSearch, setObjectSearch] = useState('');
	const [spaceSearch, setSpaceSearch] = useState('');
	const clearObjectSearch = useCallback(() => setObjectSearch(''), []);
	const clearSpaceSearch = useCallback(() => setSpaceSearch(''), []);

	const {filteredData, expanded} = useMemo(() => {
		const treeData = makeObjectsTreeData(objectIds.map(id => objectsMap[id]));
		return treeFilter(treeData, 'content', objectSearch);
	}, [objectsMap, objectIds, objectSearch]);

	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);

	useUpdateEffect(() => {
		setExpandedObjects(expanded.map(item => item.id));
	}, [expanded]);

	const [toObjectId, setToObjectId] = useState(objectId);
	const spaces = useAppSelector(s => extractSpacesAsArray(s, toObjectId));
	const spaceTypeIds = useMemo(
		() => Array.from(new Set(spaces.map(space => space.type))),
		[spaces]
	);
	const [toSpaceType, setToSpaceType] = useState<string | undefined>(undefined);

	const handleObjectChange = useCallback((value: string) => {
		setToObjectId(value);
	}, []);

	const handleSpaceTypeChange = useCallback((value: string) => {
		setToSpaceType(value);
	}, []);

	const [toSpaceId, setToSpaceId] = useState(claim.links?.spaceId);
	const handleSpaceChange = useCallback((value: string) => {
		setToSpaceId(value);
	}, []);

	useAsync(async () => {
		await dispatch(getSpacesSchema(toObjectId));
	}, [toObjectId]);

	const space = useAppSelector(extractSpace);

	const filtredSpace = spaces.filter(el =>
		toSpaceType
		&& el.type === toSpaceType
		&& (el.name.toLowerCase().indexOf(spaceSearch.toLowerCase()) > -1))
		.sort((a: IListSpace, b: IListSpace) => (parseInt(a.name) - parseInt(b.name)));

	useEffect(() => {
		if (spaceTypeIds.length) {
			setToSpaceType(spaceTypeIds[0]);
		} else {
			setToSpaceType(undefined);
		}
	}, [spaceTypeIds]);

	useEffect(() => {
		const newSpace = filtredSpace.find(item => item.name === space?.name);
		setToSpaceId(newSpace?.id ?? (filtredSpace.length ? filtredSpace[0].id : undefined));
	}, [spaces, space, toSpaceType]);

	const [, handleSave] = useAsyncFn(async () => {
		if (toSpaceId) {
			await dispatch(moveWarrantyClaim(objectId, claim.id, toObjectId, toSpaceId));
			pushPath(`/objects/${toObjectId}/warranty-claims/${claim.id}`);
			onClose();
		}
	}, [objectId, claim, toObjectId, toSpaceId]);

	return (
		<Dialog
			isOpen={isOpen}
			footer={(
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label="Отменить"
						onClick={onClose}
					/>
				</ActionButtons>
			)}
		>
			<div>
				<div>Новый объект:</div>
				<SelectPopup
					search={(
						<SelectSearch
							value={objectSearch}
							onChange={setObjectSearch}
							onClear={clearObjectSearch}
						/>
					)}
					trigger={(
						<TextFieldWithForwardedRef
							elementType="div"
							value={objectsMap[toObjectId]?.name}
							icon={arrowIcon}
						/>
					)}
				>
					<TreeSelect
						data={filteredData}
						value={toObjectId || undefined}
						onChange={handleObjectChange}
						latestOnly
						expandedValue={expandedObjects}
						onExpand={setExpandedObjects}
					/>
				</SelectPopup>
				<div>Тип помещения:</div>
				<SelectPopup
					noHeader
					trigger={(
						<TextFieldWithForwardedRef
							elementType="div"
							value={toSpaceType && spaceTypesMap[toSpaceType]?.name}
							icon={arrowIcon}
						/>
					)}
				>
					<Select2
						value={toSpaceType}
						onChange={handleSpaceTypeChange}
					>
						{spaceTypeIds.map(id => (
							<SelectOption
								inputType="radio"
								key={id}
								itemKey={id}
								content={spaceTypesMap[id]?.name}
							/>
						))}
					</Select2>
				</SelectPopup>

				<div>Новое помещение:</div>
				<SelectPopup
					search={(
						<SelectSearch
							value={spaceSearch}
							onChange={setSpaceSearch}
							onClear={clearSpaceSearch}
						/>
					)}
					trigger={(
						<TextFieldWithForwardedRef
							elementType="div"
							value={spaces.find(s => s.id === toSpaceId)?.name}
							icon={arrowIcon}
						/>
					)}
				>
					<Select2
						value={toSpaceId}
						onChange={handleSpaceChange}
					>
						{filtredSpace.map(item => (
							<SelectOption
								key={item.id}
								itemKey={item.id}
								content={item.name}
								inputType="radio"
							/>
							))}
					</Select2>
				</SelectPopup>
			</div>
		</Dialog>
	);
};
