import * as types from './constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetCheckListTypesResponse, requestCheckListTypes} from '@src/api/backend/checkListTypes';

export type IGetCheckListTypesPayload = IGetCheckListTypesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetCheckListTypesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке типов помещений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список типов помещений
 */
export const getCheckListTypes = () =>
	checkLoaded<IState, IGetCheckListTypesResponse, ApiAction>(
		state => state.entities.checkListTypes,
		createApiAction<IGetCheckListTypesResponse>(
			request,
			success,
			failure,
			requestCheckListTypes
		)
	);
