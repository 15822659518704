import React from 'react';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {LegalsPage} from '@src/pages/LegalsPage';
import {LegalPage} from '@src/pages/LegalPage/LegalPage';
import {legalsFilterRoutes} from '@src/core/routes/legalsFilterRoutes';
import {LegalsFiltersRoutingPage} from '@src/pages/filters/LegalsFiltersRoutingPage';

export const legalsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<LegalsPage/>}/>
	},
	{
		path: ':legalId',
		element: <PrivateRoute element={<LegalPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<LegalsFiltersRoutingPage/>}/>,
		children: legalsFilterRoutes
	}
];