import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {extractExportTemplateById} from '@src/store/modules/dictionaries/exportTemplates/selectors';
import {useParams} from 'react-router';

export const useManageExportTemplateBreadcrumbs = () => {
	const {exportTemplateId} = useParams<{exportTemplateId?: string}>();
	const exportTemplate = useAppSelector(s => extractExportTemplateById(s, exportTemplateId));
	return useMemo(() => {
		const items = [
			{
				label: 'Шаблоны экспорта',
				url: '/manage/export-templates',
				nonClickable: !exportTemplate
			}
		];

		if (exportTemplateId && exportTemplate) {
			items.push({
				label: `Шаблон "${exportTemplate.name}"`,
				url: `/manage/export-templates/${exportTemplate.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [exportTemplate, exportTemplateId]);
};
