import useAppSelector from '@src/core/hooks/useAppSelector';
import {EntityGrid, EntityGridItem, Tag} from '@tehzor/ui-components';
import React, {memo} from 'react';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {CategoriesCell} from '../cells/CategoriesCell';
import {ObjectsCell} from '../cells/ObjectsCell';
import {isBlockVisible} from '../../utils/isBlockVisible';
import {PlansCell} from '../cells/PlansCell';
import classNames from 'classnames';

interface IMainBlockProps {
	contract: IPreparedContract;
}

const visibilityFields: Array<keyof IPreparedContract> = [
	'stageId', 'categoryIds', 'objectIds', 'planIds'
];

const stageIcon = <i className="tz-stage-20"/>;

export const MainBlock = memo(({
	contract
}: IMainBlockProps) => {
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);
	const stage = stagesMap[contract.stageId];

	return isBlockVisible(contract, visibilityFields) ? (
		<div className="contract__blocks-main">
			<div className="contract__blocks-main__header">
				Основное
			</div>

			<EntityGrid
				className={{
					wrap: classNames(
						'contract__blocks-main__container', 'contract__cells'
					)
				}}
				withBorders
			>
				<EntityGridItem
					className={{
						root: 'contract__cells-item',
						header: 'contract__cells-item-header',
						content: 'contract__cells-item-content'
					}}
					label="Стадия"
					icon={stageIcon}
					inline
				>
					<Tag
						label={stage.name}
						color={stage.color}
					/>
				</EntityGridItem>

				<ObjectsCell objectIds={contract.objectIds}/>
				<CategoriesCell categoryIds={contract.categoryIds}/>
				<PlansCell
					objectIds={contract.objectIds}
					planIds={contract.planIds}
				/>
			</EntityGrid>
		</div>
	) : null;
});