import React, {memo, useState} from 'react';
import {format} from 'date-fns';
import {ActionButtons, Button, Dialog, EntityGridItem, InlineButton} from '@tehzor/ui-components';
import {useBoolean, useUpdateEffect} from 'react-use';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {EditableDateField} from '@src/components/EditableDateField';

interface IRegistrationDateMobileProps {
	value?: number;
	onChange?: (date: Date) => void;
	canEdit?: boolean;
}

const calendarIcon = <i className="tz-calendar-20"/>;
const editIcon = <i className="tz-edit-16"/>;

export const RegistrationDateMobile = memo((props: IRegistrationDateMobileProps) => {
	const {value, canEdit, onChange} = props;

	const initialState = value ? new Date(value) : undefined;

	const [registrationDate, setRegistrationDate] = useState<Date | undefined>(initialState);
	const [isEditable, setIsEditable] = useBoolean(false);

	const handleDateChange = (date: Date) => {
		setRegistrationDate(date);
	};

	const onClickSave = () => {
		if (registrationDate) {
			onChange?.(registrationDate);
			setIsEditable(false);
		}
	};
	const onClickCancel = () => {
		setIsEditable(false);
		setRegistrationDate(initialState);
	};

	useUpdateEffect(() => {
		setRegistrationDate(initialState);
	}, [value]);

	return (
		<EntityGridItem
			label="Дата регистрации обращения"
			icon={calendarIcon}
			fullRow
			buttons={canEdit && !isEditable && (
				<InlineButton
					type="accent"
					leftIcon={editIcon}
					onClick={() => setIsEditable(true)}
				/>
			)}
		>
			{!isEditable && registrationDate && (
				<span
					className="semi-bold"
				>
					{format(registrationDate, dateFormat)}
				</span>
			)}
			{isEditable && (
				<Dialog
					fullScreenOnTablet
					title="Редактировать дату регистрации обращения"
					isOpen={isEditable}
					onAfterClose={() => setRegistrationDate(initialState)}
					onRequestClose={() => setIsEditable(false)}
					footer={(
						<ActionButtons>
							<Button
								type="accent-blue"
								label="Сохранить"
								onClick={onClickSave}
							/>
							<Button
								type="cancel"
								label="Отменить"
								onClick={onClickCancel}
							/>
						</ActionButtons>
					)}
				>
					<EditableDateField
						className="warranty-claim-page__registration-date__field"
						value={registrationDate}
						onChange={handleDateChange}
					/>
				</Dialog>
			)}
		</EntityGridItem>
	);
});
