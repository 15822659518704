import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetInspectionStoriesResponse,
	requestInspectionStories
} from '@src/api/backend/inspectionStories';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {IState} from '@src/store/modules';

export type IGetInspectionStoriesPayload = IGetInspectionStoriesResponse;

const request = () => ({type: types.GET_STORIES_REQUEST});

const success = (response: IGetInspectionStoriesPayload) => ({
	type: types.GET_STORIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке истории осмотра');
	return {
		type: types.GET_STORIES_FAILURE,
		payload: error
	};
};

/**
 * Получает записи истории изменения осмотра
 *
 * @param objectId id объекта
 * @param inspectionId id нарушения
 */
export const getInspectionStories = (objectId: string, inspectionId: string) =>
	checkLoaded<IState, IGetInspectionStoriesResponse, ApiAction>(
		s => s.pages.inspection.stories,
		createApiAction<IGetInspectionStoriesResponse>(request, success, failure, () =>
			requestInspectionStories(objectId, inspectionId))
	);
