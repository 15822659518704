import React, {Dispatch, useCallback, useEffect} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';

interface IReplyPhoneProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value: string;
	defaultValue?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const ReplyPhone = <S extends {replyPhone: string}, E>({
	className,
	style,
	label = 'Телефон',
	value,
	defaultValue,
	editingDispatch,
	required,
	disabled,
	hasError
}: IReplyPhoneProps<S, E>) => {
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'replyPhone', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'replyPhone'});
			}
		},
		[required]
	);

	useEffect(() => {
		if (defaultValue) {
			editingDispatch({type: 'update', field: 'replyPhone', value: defaultValue});
		}
	}, [defaultValue]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<TextField
				value={value || defaultValue}
				elementType="input"
				error={required && hasError ? 'Введите телефон' : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default ReplyPhone;
