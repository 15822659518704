import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoriesOrder} from '@tehzor/tools/interfaces/categories/ICategoriesOrder';

export type IEditCategoriesOrderResponse = INormalizedData<ICategory>;

/**
 * Меняет порядок видов работ
 *
 * @param setId id объекта
 * @param orders порядок
 *
 */
export const makeCategoriesEditOrderRequest = (orders: ICategoriesOrder[]) =>
	wsConnector.sendAuthorizedRequest<IEditCategoriesOrderResponse>('editCategoriesOrder', {
		orders
	});
