import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemStatusesAsArray} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const SpacesProblemStatusesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();
	const statuses = useAppSelector(extractProblemStatusesAsArray);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={statuses}
		/>
);
};

export default SpacesProblemStatusesFilterPage;
