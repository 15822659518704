import React, {useCallback, useEffect, useState} from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import EditableSpaceOwner from '../EditableSpaceOwner';
import {
	convertToSave,
	errorsFns,
	isEdited,
	useEditableSpaceOwnerState
} from '@src/core/hooks/states/useEditableSpaceOwnerState';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	email: {fieldId: 'email', isRequired: true},
	additionalEmail: {fieldId: 'additionalEmail', isRequired: true},
	phone: {fieldId: 'phone', isRequired: true},
	additionalPhone: {fieldId: 'additionalPhone', isRequired: true},
	comment: {fieldId: 'comment', isRequired: false}
};

/**
 * Логика редактирования и сохранения собственника (нового или существующего)
 */
export const useEditableSpaceOwner = (
	owner: ISpaceOwner | undefined,
	saving: boolean
): [React.ReactNode, () => ISavingSpaceOwner | undefined, (
) => void, boolean] => {
	const [editingState, editingDispatch] = useEditableSpaceOwnerState(owner);
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, owner));
	}, [editingState, owner]);

	const getSavingData = useCallback(() => {
		// Проверка наличия ошибок в состоянии
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (!isEdited(editingState, owner)) {
			return undefined;
		}
		return convertToSave(editingState, owner, true);
	}, [editingState, owner]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: owner
		});
	}, [owner]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [owner]);

	const fields = (
		<EditableSpaceOwner
			editingState={editingState}
			editingDispatch={editingDispatch}
			saving={saving}
			fieldsSettings={fieldsSettings}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
