import React, {memo} from 'react';
import {IDatesOptions} from '../Calendar';
import IconButton from '../../../buttons/IconButton';
import {CalendarView, CalendarViewResult} from '../hooks/useCalendarView';
import InlineButton from '../../../buttons/InlineButton';
import {getMonthName} from '../utils/getMonthName';

interface IDaysNavProps {
	viewData: CalendarViewResult;
	datesOptions?: IDatesOptions;
}

const DaysNav = (props: IDaysNavProps) => {
	const {viewData, datesOptions} = props;
	const {month, year, changeView} = viewData;

	const monthName = getMonthName(month, datesOptions?.locale);

	const handleMonthClick = () => {
		changeView(CalendarView.YEAR);
	};

	const handleYearClick = () => {
		changeView(CalendarView.DECADE);
	};

	return (
		<div className="calendar__nav">
			<IconButton
				className="calendar__nav-arrow-btn"
				onClick={viewData.prevMonth}
			>
				<i className="tz-simple-arrow-24 calendar__nav-prev-icon"/>
			</IconButton>

			<div>
				<InlineButton
					className="calendar__nav-link"
					labelClassName="calendar__nav-link-label"
					type="accent"
					label={monthName}
					onClick={handleMonthClick}
				/>

				<InlineButton
					className="calendar__nav-link"
					labelClassName="calendar__nav-link-label"
					type="accent"
					label={year}
					onClick={handleYearClick}
				/>
			</div>

			<IconButton
				className="calendar__nav-arrow-btn"
				onClick={viewData.nextMonth}
			>
				<i className="tz-simple-arrow-24 calendar__nav-next-icon"/>
			</IconButton>
		</div>
	);
};

export default memo(DaysNav);
