import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import IReason from '@tehzor/tools/interfaces/IReason';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import isEqual from 'lodash/isEqual';

export type IEditableProblemState = IEditableEntityState<{
	templateId?: string;
	categoryId?: string | null;
	plannedFixDate?: number;
	reason?: IReason | null;
	planId?: string;
	location?: ILocation;
	floor: string;
	inspectors: string[];
	respUsers: string[];
	watchers: string[];
	activeGroup?: string;
	description: string;
	prescription: string;
	attachments: IAttachment[];
	critical: boolean;
	createdAt?: number;
	problemTags: string[];
	contractId?: string | null;
}>;

export type IEditableProblemAction = IEditableEntityAction<IEditableProblemState, ISavingProblem>;

const makeEmptyState = (): IEditableProblemState => ({
	categoryId: undefined,
	plannedFixDate: undefined,
	reason: undefined,
	planId: undefined,
	location: undefined,
	floor: '',
	inspectors: [],
	respUsers: [],
	watchers: [],
	activeGroup: undefined,
	description: '',
	prescription: '',
	attachments: [],
	problemTags: [],
	critical: false,
	createdAt: undefined,
	contractId: undefined,
	errors: {
		categoryId: false,
		plannedFixDate: false,
		reason: false,
		planId: false,
		location: false,
		floor: false,
		inspectors: false,
		respUsers: false,
		watchers: false,
		activeGroup: false,
		description: false,
		prescription: false,
		attachments: false,
		critical: false,
		problemTags: false,
		contractId: false
	}
});

export const makeDefaultData = (
	problem?: Omit<
		IProblem,
		'createdBy' | 'modifiedBy' | 'initialGroupLeader' | 'activeGroupLeader'
	>
): ISavingProblem | undefined => {
	if (problem) {
		return {
			categoryId: problem.categoryId,
			plannedFixDate: problem.plannedFixDate,
			reason: problem.reason,
			planId: problem.plan?.id,
			location: problem.location,
			floor: problem.floor,
			inspectors: problem.inspectors,
			respUsers: problem.respUsers,
			watchers: problem.watchers,
			activeGroup: problem.activeGroup,
			description: problem.description,
			prescription: problem.prescription,
			attachments: problem.attachments,
			critical: problem.critical,
			createdAt: problem.createdAt,
			problemTags: problem.problemTags,
			contractId: problem.contractId
		};
	}
	return undefined;
};

export const init = (original?: ISavingProblem): IEditableProblemState => {
	const empty = makeEmptyState();
	return original
		? {
				categoryId: original.categoryId ?? undefined,
				plannedFixDate: original.plannedFixDate ?? undefined,
				reason: original.reason ?? undefined,
				planId: original.planId ?? undefined,
				location: original.location ?? undefined,
				floor: original.floor ?? '',
				inspectors: original.inspectors ?? [],
				respUsers: original.respUsers ?? [],
				watchers: original.watchers ?? [],
				activeGroup: original.activeGroup ?? undefined,
				description: original.description ?? '',
				prescription: original.prescription ?? '',
				attachments: original.attachments ?? [],
				critical: original.critical ?? false,
				createdAt: original.createdAt ?? undefined,
				problemTags: original.problemTags ?? [],
				contractId: original.contractId ?? undefined,
				errors: empty.errors
		  }
		: empty;
};

const isCategoryEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.categoryId ? original.categoryId !== state.categoryId : !!state.categoryId;

const isFixDateEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.plannedFixDate
		? original.plannedFixDate !== state.plannedFixDate
		: !!state.plannedFixDate;

const isReasonEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.reason ? !isEqual(original.reason, state.reason) : !!state.reason;

const isPlanEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.planId ? original.planId !== state.planId : !!state.planId;

const isLocationEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.location ? !isEqual(original.location, state.location) : !!state.location;

const areFloorEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.floor ? original.floor !== state.floor : !!state.floor;

const areInspectorsEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.inspectors
		? original.inspectors.length !== state.inspectors.length ||
		  original.inspectors.some(id => !state.inspectors.includes(id))
		: !!state.inspectors.length;

const areRespUsersEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.respUsers
		? original.respUsers.length !== state.respUsers.length ||
		  original.respUsers.some(id => !state.respUsers.includes(id))
		: !!state.respUsers.length;

const areWatchersEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.watchers
		? original.watchers.length !== state.watchers.length ||
		  original.watchers.some(id => !state.watchers.includes(id))
		: !!state.watchers.length;

const isActiveGroupEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.activeGroup ? original.activeGroup !== state.activeGroup : !!state.activeGroup;

const isDescriptionEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.description ? original.description !== state.description : !!state.description;

const isPrescriptionEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.prescription ? original.prescription !== state.prescription : !!state.prescription;

const areAttachmentsEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.attachments ? original.attachments.length !== state.attachments.length : false;

const isCriticalEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.critical ? original.critical !== state.critical : state.critical;

const areProblemTagsEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.problemTags
		? original.problemTags.length !== state.problemTags.length ||
		  original.problemTags.some(id => !state.problemTags.includes(id))
		: !!state.problemTags.length;

const isContractEdited = (state: IEditableProblemState, original?: ISavingProblem) =>
	original?.contractId ? original.contractId !== state.contractId : !!state.contractId;

/**
 * Возвращает значение, показывающее были ли отредактированы поля нарушения
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableProblemState, original?: ISavingProblem): boolean =>
	isEntityEdited(
		state,
		original,
		isCategoryEdited,
		isFixDateEdited,
		isReasonEdited,
		isPlanEdited,
		isLocationEdited,
		areFloorEdited,
		// Закомментил для того, чтобы нормально работал isEdited
		// areInspectorsEdited,
		areRespUsersEdited,
		areWatchersEdited,
		isActiveGroupEdited,
		isDescriptionEdited,
		isPrescriptionEdited,
		isCriticalEdited,
		areAttachmentsEdited,
		areProblemTagsEdited,
		isContractEdited
	);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	categoryId: (state: IEditableProblemState) => !state.categoryId,
	plannedFixDate: (state: IEditableProblemState) => !state.plannedFixDate,
	reason: (state: IEditableProblemState) => !state.reason,
	planId: (state: IEditableProblemState) => !state.planId,
	location: (state: IEditableProblemState) => !state.location,
	floor: (state: IEditableProblemState) => !state.floor,
	inspector: (state: IEditableProblemState) => !state.inspectors.length,
	respUsers: (state: IEditableProblemState) => !state.respUsers.length && !state.activeGroup,
	watchers: (state: IEditableProblemState) => !state.watchers.length,
	activeGroup: (state: IEditableProblemState) => !state.activeGroup,
	description: (state: IEditableProblemState) => !state.description,
	prescription: (state: IEditableProblemState) => !state.prescription,
	critical: () => false,
	problemTags: (state: IEditableProblemState) => !state.problemTags.length,
	attachments: (state: IEditableProblemState) => !state.attachments.length
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableProblemState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableProblemState,
	original?: ISavingProblem,
	onlyEdited?: boolean
): ISavingProblem => {
	if (!onlyEdited) {
		return {
			categoryId: state.categoryId,
			plannedFixDate: state.plannedFixDate,
			reason: state.reason,
			planId: state.planId,
			location: state.location || null,
			floor: state.floor,
			inspectors: state.inspectors,
			respUsers: state.respUsers,
			watchers: state.watchers,
			activeGroup: state.activeGroup,
			description: state.description,
			prescription: state.prescription,
			attachments: state.attachments,
			critical: state.critical,
			createdAt: state.createdAt,
			problemTags: state.problemTags,
			contractId: state.contractId
		};
	}
	const problem: ISavingProblem = {};
	if (isCategoryEdited(state, original)) {
		problem.categoryId = state.categoryId;
	}
	if (isFixDateEdited(state, original)) {
		problem.plannedFixDate = state.plannedFixDate;
	}
	if (isReasonEdited(state, original)) {
		problem.reason = state.reason;
	}
	if (isPlanEdited(state, original)) {
		problem.planId = state.planId;
	}
	if (isLocationEdited(state, original)) {
		problem.location = state.location || null;
	}
	if (areFloorEdited(state, original)) {
		problem.floor = state.floor || null;
	}
	if (areInspectorsEdited(state, original)) {
		problem.inspectors = state.inspectors;
	}
	if (areRespUsersEdited(state, original)) {
		problem.respUsers = state.respUsers;
		problem.activeGroup = state.activeGroup;
	}
	if (areWatchersEdited(state, original)) {
		problem.watchers = state.watchers;
	}
	if (isActiveGroupEdited(state, original)) {
		problem.activeGroup = state.activeGroup;
	}
	if (isDescriptionEdited(state, original)) {
		problem.description = state.description;
	}
	if (isPrescriptionEdited(state, original)) {
		problem.prescription = state.prescription;
	}
	if (isCriticalEdited(state, original)) {
		problem.critical = state.critical;
	}
	if (areProblemTagsEdited(state, original)) {
		problem.problemTags = state.problemTags;
	}
	if (areAttachmentsEdited(state, original)) {
		problem.attachments = state.attachments.map(item => ({id: item.id}));
	}
	if (isContractEdited(state, original)) {
		problem.contractId = state.contractId;
	}
	return problem;
};
