import React from 'react';
import {EntityGridItem, ExternalLinkButton} from '@tehzor/ui-components';
import IReason from '@tehzor/tools/interfaces/IReason';
import useAppSelector from '@src/core/hooks/useAppSelector';
import config from '@src/core/config';

const externalLink = config.regStandardExternalLink;

interface IReasonProps {
	value: IReason;
}

const Reason = ({value}: IReasonProps) => {
	const byId = useAppSelector(s => s.dictionaries.regulatoryStandards.byId);

	let link;
	if (value.regulatoryStandard) {
		const item = byId[value.regulatoryStandard];
		if (item) {
			link = externalLink + item.number.toFixed(10);
		}
	}

	return (
		<EntityGridItem
			icon={<i className="tz-external-link-20"/>}
			label="Основание"
		>
			{link ? (
				<ExternalLinkButton
					label={value.value}
					url={link}
					icon={false}
					target="_blank"
					rel="noreferrer nofollow noopener"
				/>
			) : (
				<span className="problem-page__semi-bold">{value.value}</span>
			)}
		</EntityGridItem>
	);
};

export default Reason;
