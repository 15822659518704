import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IOwnerAcceptanceResolution} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IOwnerAcceptanceResolutionsState = ILoadableEntitiesState<IOwnerAcceptanceResolution>;

export const ownerAcceptanceResolutions = createReducer<IOwnerAcceptanceResolutionsState>(
	getLoadableEntitiesInitialState<IOwnerAcceptanceResolution>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IOwnerAcceptanceResolution>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IOwnerAcceptanceResolution>()
	}
);
