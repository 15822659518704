import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeOwnerAcptDeleteRequest} from '@src/api/backend/ownerAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

export interface IDeleteOwnerAcptPayload {
	objectId: string;
	acceptanceId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, acceptanceId: string, quietly?: boolean) => {
	if (!quietly) {
		addInfoToast('Удалено', 'Передача успешно удалена');
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			acceptanceId
		} as IDeleteOwnerAcptPayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении передачи');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

export const deleteOwnerAcceptanceActions = {request, success, failure};

/**
 * Удаляет передачу собственником
 *
 * @param objectId id объекта
 * @param acceptanceId id передачи
 */
export const deleteOwnerAcceptance = (objectId: string, acceptanceId: string) =>
	createApiAction(
		request,
		() => success(objectId, acceptanceId),
		failure,
		() => {
			void queryClient.invalidateQueries(ownerAcceptancesQueryKeys.list());
			void queryClient.invalidateQueries(ownerAcceptancesQueryKeys.latest());
			void queryClient.invalidateQueries(problemsQueryKeys.list());
			return makeOwnerAcptDeleteRequest(objectId, acceptanceId);
		}
	);
