import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {EntitiesFiltersCtx, IEntitiesFiltersCtx} from './entitiesFiltersCtx';
import {isStateEqual} from './isStateEqual';
import useFiltersQuery from './useFiltersQuery';
import {useUpdateEffect} from 'react-use';
import {AnyAction} from 'redux';
import {pickBy} from 'lodash';
import {predicate} from './predicate';

interface IEntitiesFiltersProviderProps<S extends Record<string, unknown>> {
	filters: S;
	onApply: (filters: S) => void;
	clearExpiration?: (objectId: string) => AnyAction;
	children?: React.ReactNode;
	objectId?: string;
	fullClear?: boolean;
	onClear?: () => void;
}

export type IChanges<S = Record<string, unknown>> = {
	[field in keyof S]: unknown | undefined;
};

const EntitiesFiltersProvider = <S extends Record<string, unknown> & {objects?: string[]}>({
	children,
	filters,
	objectId = 'all',
	onApply,
	fullClear,
	onClear,
	clearExpiration
}: IEntitiesFiltersProviderProps<S>) => {
	const {params, change: searchChange, allClear} = useFiltersQuery<S>();
	const appDispatch = useAppDispatch();
	// state с фильтрами из redux
	const [state, setState] = useState<S>(filters);
	// state c фильтрами из строки запроса
	const [localState, setLocalState] = useState<S>(params as S);
	// флаг разрешающий менять путь, нужен для перехода от страницы фильтров в мобилке к списочной странице
	const [canChangePath, setCanChangePath] = useState(false);
	const isLocal = params ? Object.values(params)?.some(val => !!val) : false;
	// обновляем состояние в частности после сброса фильтров через dispatch
	useUpdateEffect(() => {
		setState(filters);
	}, [filters]);

	const change = (changes: IChanges<S>) => {
		setState(s => ({...s, ...changes}));
	};
	const apply = useCallback((s: S) => {
		onApply(s);
	}, [onApply]);

	const dispatch = useCallback((changes: IChanges<S>) => {
		apply({...state, ...changes});
	}, [apply, state]);

	// функция для кнопки сбросить в десктопе
	const clear = useCallback(() => {
		if (onClear) {
			onClear();
		} else if (objectId === 'all') {
			setState({} as S);
			apply({} as S);
		} else {
			setState({objects: [objectId]} as S);
			apply({objects: [objectId]} as S);
		}
	}, [apply, objectId, onClear]);

	// кнопка сбросить в мобильном header
	const mobileClear = useCallback(() => {
		if (fullClear || objectId === 'all') {
			setState({} as S);
		} else {
			setState({objects: [objectId]} as S);
		}
		allClear();
	}, [allClear, fullClear, objectId]);

	const localChange = (changes: IChanges<S>) => {
		setLocalState(s => ({...s, ...changes}));
	};

	const localApply = () => null;

	const localDispatch = useCallback((changes: IChanges<S>) => {
		if (clearExpiration) {
			void appDispatch(clearExpiration(objectId));
		}
		setCanChangePath(true);
		localChange(changes);
	}, [appDispatch, clearExpiration, objectId]);

	const localClear = useCallback(() => {
		if (fullClear || objectId === 'all') {
			setLocalState(() => ({} as S));
		} else {
			setLocalState(() => ({objects: [objectId]} as S));
		}
		allClear();
		if (clearExpiration) {
			void appDispatch(clearExpiration(objectId));
		}
	}, [allClear, appDispatch, clearExpiration, fullClear, objectId]);

	// кнопка сбросить в мобильном header
	const localMobileClear = useCallback(() => {
		setLocalState({} as S);
	}, []);

	/* меняем путь в строке запроса согласно локальному стейту,
	 для того чтобы при возвращении на списочную страницу правильно отработали фильтры из пути */
	useEffect(() => {
		// Фильтруем объекты от falsy значений
		const filteredLocalState = pickBy(localState, predicate);
		const filteredParams = pickBy(params, predicate);

		if (
			canChangePath
			&& isLocal
			&& params
			&& !isStateEqual<Record<string, unknown>>(filteredLocalState, filteredParams)
			) {
			searchChange(localState);
		}
	}, [localState, params, isLocal, canChangePath, searchChange]);

	const entitiesFiltersLocalCtx = useMemo(
		() => ({
			state: localState,
			isStateChanged:
				objectId === 'all'
					? !isStateEqual<S>(localState, params || ({} as S))
					: !isStateEqual<S>(localState, params || ({objects: [objectId]} as S)),
			isLocal,
			change: localChange,
			clear: localClear,
			mobileClear: localMobileClear,
			apply: localApply,
			dispatch: localDispatch
		}),
		[localState, objectId, params, isLocal, localClear, localMobileClear, localDispatch]
	);

	const entitiesFiltersCtx = useMemo(
		() => ({
			state,
			isStateChanged: !isStateEqual<S>(state, filters),
			isLocal,
			change,
			clear,
			mobileClear,
			apply: () => apply(state),
			dispatch
		}),
		[state, filters, isLocal, clear, mobileClear, dispatch, apply]
	);
	const ctxValue: IEntitiesFiltersCtx<S> = useMemo(
		() => (isLocal ? entitiesFiltersLocalCtx : entitiesFiltersCtx),
		[isLocal, entitiesFiltersLocalCtx, entitiesFiltersCtx]
	);

	return <EntitiesFiltersCtx.Provider value={ctxValue}>{children}</EntitiesFiltersCtx.Provider>;
};

export default EntitiesFiltersProvider;
