import React from 'react';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {LoadingPanel} from '@tehzor/ui-components';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useAsync} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import './CategoriesSetPage.less';
import {useCategoriesSetBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCategoriesSetBreadcrumbs';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import classNames from 'classnames';
import CategoriesSetContent from './components/CategoriesSetContent';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {getCategories} from '@src/store/modules/dictionaries/categories/actions';
import {extractCategoriesAsArrayBySetId} from '@src/store/modules/dictionaries/categories/selectors';
import {getCategoriesSets} from '@src/store/modules/dictionaries/categoriesSets/actions';
import {extractCategoriesSetById} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';

const CategoriesSetPage = () => {
	useScrollRestoration();

	const dispatch = useAppDispatch();
	const {objectId, categoriesSetId} = useStrictParams<{
		objectId: string;
		categoriesSetId: string;
	}>();
	const breadcrumbs: IBreadcrumb[] = useCategoriesSetBreadcrumbs(objectId, categoriesSetId);
	const loadingState = useAsync(async () => {
		if (categoriesSetId === 'empty') {
			return;
		}

		await dispatch(getCategoriesSets());
		await dispatch(getCategories());
	}, [dispatch]);

	const categoriesSet = useAppSelector(s => extractCategoriesSetById(s, categoriesSetId));
	const categories = useAppSelector(s => extractCategoriesAsArrayBySetId(s, categoriesSetId));

	useAppHeader(
		{
			title: categoriesSet?.name || 'Новый набор видов работ',
			showBackBtn: true
		},
		[categoriesSet]
	);

	return (
		<LoadingPanel
			className={classNames('categories-set__loading-panel', 'page-cont')}
			active={loadingState.loading}
		>
			<>
				{!loadingState.loading && (
					<div className="categories-set">
						<AppBreadcrumbs
							className="categories-set__breadcrumbs"
							items={breadcrumbs}
						/>
						<CategoriesSetContent
							categories={categories}
							categoriesSet={categoriesSet}
						/>
					</div>
				)}
			</>
		</LoadingPanel>
	);
};

export default CategoriesSetPage;
