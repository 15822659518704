import {useQueryClient} from '@tanstack/react-query';
import {unitsQueryKeys} from '@src/api/cache/units/keys';
import {requestUnits} from '@src/api/backend/units';

export const useUnitsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(unitsQueryKeys.list(), {
		queryFn: requestUnits,
		staleTime: 1000 * 60 * 60 * 24, // 1 День
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
};