import React from 'react';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Button, FloatingActionButton} from '@tehzor/ui-components';

interface IEntityAddingBtnProps {
	onClick: () => void;
}

export const EntityAddingBtn = ({onClick}: IEntityAddingBtnProps) => (
	<>
		<BeforeDesktop>
			<FloatingActionButton
				icon={<i className="tz-plus-16"/>}
				label="Добавить"
				minifyOnScroll
				scrollContainer="scroll-container"
				onClick={onClick}
			/>
		</BeforeDesktop>
		<Desktop>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-plus-20"/>}
				label="Добавить"
				onClick={onClick}
			/>
		</Desktop>
	</>
);
