import {wsConnector} from '../wsConnector';
import {IOwnerAcceptanceResolution} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetOwnerAcceptanceResolutionsResponse = INormalizedData<IOwnerAcceptanceResolution>;

/**
 * Возвращает список решений приёмок собственниками
 */
export const requestOwnerAcceptanceResolutions = () =>
	wsConnector.sendAuthorizedRequest<IGetOwnerAcceptanceResolutionsResponse>(
		'getOwnerAcceptanceResolutions'
	);
