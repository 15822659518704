import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef,
	Checkbox
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceOwnersForSpace} from '@src/store/modules/dictionaries/spaceOwners/selectors';

import './SpaceOwner.less';

interface ISpaceOwnerProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	spaceId: string;
	label?: string;
	value?: string | null;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	ownerIsNotClaimer?: boolean;
	handleOwnerIsNotClaimer?: () => void;
}

const SpaceOwner = <S extends {spaceOwnerId?: string}, E>(props: ISpaceOwnerProps<S, E>) => {
	const {
		className,
		style,
		spaceId,
		value,
		editingDispatch,
		required,
		disabled,
		hasError,
		ownerIsNotClaimer,
		handleOwnerIsNotClaimer,
		label = 'Собственник'
	} = props;

	const owners = useAppSelector(s => extractSpaceOwnersForSpace(s, spaceId));
	const ownersMap = useAppSelector(s => s.dictionaries.spaceOwners.byId);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'spaceOwnerId', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'spaceOwnerId'});
			}
		},
		[required, editingDispatch]
	);

	const handleClear = useCallback(() => {
		editingDispatch({type: 'update', field: 'spaceOwnerId', value: ''});
	}, [editingDispatch]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<SelectPopup
				trigger={(
					<TextFieldWithForwardedRef
						value={(value && ownersMap[value]?.name) ?? ''}
						elementType="div"
						error={required && hasError ? 'Выберите собственника' : undefined}
						disabled={disabled}
						onClearClick={handleClear}
						cleanable
					/>
				)}
			>
				<Select2
					value={value || undefined}
					onChange={handleChange}
				>
					{owners.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</SelectPopup>

			{ownerIsNotClaimer !== undefined && handleOwnerIsNotClaimer !== undefined ? (
				<Checkbox
					className="editable-owner-is-not-claimer-checkbox"
					checked={ownerIsNotClaimer}
					onChange={handleOwnerIsNotClaimer}
				>
					<span className="editable-owner-is-not-claimer-checkbox__label">
						Заявитель не является собственником
					</span>
				</Checkbox>
			) : null}
		</div>
	);
};

export default SpaceOwner;
