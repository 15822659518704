import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetSpaceOwnersResponse} from '@src/api/backend/spaceOwners';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {queryClient} from '@src/api/QueryClient';

export type IGetSpaceOwnersPayload = INormalizedData<ISpaceOwner>;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetSpaceOwnersResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке собственников помещения');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список собственников помещений
 */
export const getSpaceOwners = () =>
	createApiAction<IGetSpaceOwnersPayload>(request, success, failure, () =>
		queryClient.fetchQuery(restDictionariesQueryKeys.spaceOwners(), {
			staleTime: Infinity
		}));
