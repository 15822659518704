import React, {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AddingRespUserDialog} from '../AddingRespUserDialog';

export const useAddingRespUserDialog = (): [React.ReactNode, () => void] => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const {objectId} = useParams<{objectId: string}>();
	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<AddingRespUserDialog
			isOpen={isOpen}
			close={close}
			objectId={objectId}
		/>
);

	return [dialog, open];
};
