import React from 'react';
import './ProblemReply.less';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import ClickPreventWrap from '../ClickPreventWrap';
import {PhotoPreview} from '@tehzor/ui-components';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import classNames from 'classnames';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';

interface IProblemReplyProps {
	className?: string;
	reply: IProblemComment;
	images?: string[];

	openImage: (i: number) => void;
}

const ProblemReply = (props: IProblemReplyProps) => {
	const {className, reply, images, openImage} = props;

	return (
		<div className={classNames('problem-reply-cell', className)}>
			<div className="problem-reply-cell__title-row">
				<div className="problem-reply-cell__title">
					Ответ №
					{reply.number}
				</div>

				<div className="problem-reply-cell__date">
					{reply.createdAt
						? format(reply.createdAt, dateTimeCommaSeparatedFormat, {locale: ru})
						: null}
				</div>
			</div>

			<div className="problem-reply-cell__desc">{reply.description}</div>

			{images?.length ? (
				<>
					<ClickPreventWrap className="problem-reply-cell__image">
						<PhotoPreview
							url={images[0]}
							data={0}
							onClick={openImage}
						/>
					</ClickPreventWrap>
				</>
			) : null}
		</div>
	);
};

export default ProblemReply;
