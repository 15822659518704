import {PersistConfig} from 'redux-persist/es/types';
import {createStorage} from './storage';

const version = 7;

export const formPersistConfig = <S = unknown>(
	key: string,
	extra?: Omit<Partial<PersistConfig<S>>, 'key'>
): PersistConfig<S> => ({
	key,
	keyPrefix: '',
	storage: createStorage(),
	version,
	serialize: false,
	// Без указания данного недокументированного свойства возникает ошибка при парсинге объекта
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	deserialize: false,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	timeout: null,
	debug: process.env.NODE_ENV === 'development',
	...extra
});
