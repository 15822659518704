import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceTypesAsArray} from '@src/store/modules/dictionaries/spaceTypes/selectors';
import {ISpaceType, SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';

interface ISpaceTypeSelectProps<S, E> {
	field?: keyof S;
	value?: SpaceTypeId | SpaceTypeId[];
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: SpaceTypeId | SpaceTypeId[] | undefined,
	types: ISpaceType[],
	typesMap: Record<string, ISpaceType>
) => {
	if (!value) {
		return undefined;
	}

	if (Array.isArray(value)) {
		return value.length ? makeFilterLabel('Выбрано', value, types) : undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

const SpaceTypeSelect = <S extends {type?: SpaceTypeId, spaceTypes?: SpaceTypeId[]}, E>({
	className,
	style,
	field = 'type',
	label = 'Тип помещения',
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: ISpaceTypeSelectProps<S, E>) => {
	const types = useAppSelector(extractSpaceTypesAsArray);
	const typesMap = useAppSelector(s => s.dictionaries.spaceTypes.byId);

	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, types, typesMap)}
						icon={<i className="tz-simple-arrow-20"/>}
						error={required && hasError ? 'Выберите тип помещения' : undefined}
						disabled={disabled}
					/>
				)}
			>
				{Array.isArray(value) ? (
					<Select2
						multiple
						value={value}
						onChange={handleChange}
					>
						{types.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="checkbox"
							/>
						))}
					</Select2>
				) : (
					<Select2
						value={value}
						onChange={handleChange}
					>
						{types.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="radio"
							/>
						))}
					</Select2>
				)}
			</SelectPopup>
		</div>
	);
};

export default SpaceTypeSelect;
