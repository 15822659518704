import React, {useCallback, useState} from 'react';
import {EntitiesMobilePhotoViewer} from '@tehzor/ui-components';
import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesMobilePhotoViewer/EntitiesMobilePhotoViewer';
import useToggle from 'react-use/lib/useToggle';
import IEditImage from '@tehzor/tools/interfaces/IEditImage';

/**
 * Хук для базового просмотрщика изображений сущностей
 *
 * @param entities изображения с разбивкой по сущностям
 * @param canDraw есть ли права на рисование у юзера
 * @param updateUploads функция для обновления списка загрузок
 * @param moveToUploads функция для перемещения изображения в temp при рисовании из редактирования
 * @param entityEditing статус редактирования, откуда
 */
export function useEntitiesViewer(
	entities: IEntity[],
	canDraw: boolean,
	updateUploads?: (value: IEditImage) => Promise<void>,
	moveToUploads?: (value?: IEditImage) => Promise<void>,
	entityEditing?: boolean
): [React.ReactNode, (id: string, i: number) => void] {
	// Состояния для активного фото и видимости просмотрщика
	const [isOpen, toggleOpen] = useToggle(false);
	const [activeId, setActiveId] = useState<string>();
	const [activeIndex, setActiveIndex] = useState<number>();

	const handleActiveChange = useCallback((id: string, index: number) => {
		setActiveId(id);
		setActiveIndex(index);
	}, []);

	const openImage = useCallback((id: string, index: number) => {
		setActiveId(id);
		setActiveIndex(index);
		toggleOpen(true);
	}, []);

	// Эта штука нужна для переключения отображения фото на отредактированное
	const handleChangeAfterEdition = useCallback(() => {
		for (const entity of entities) {
			if (entity.id === 'uploads') {
				if (entity.attachments?.length) {
					setActiveId('uploads');
					setActiveIndex(entity.attachments.length);
					break;
				}
			}
			setActiveId('uploads');
			setActiveIndex(0);
		}
	}, [entities]);

	const saveEditedImage = async (data: IEditImage) => {
		switch (data.entityType) {
			case 'problem':
				if (entityEditing && moveToUploads) {
					await moveToUploads(data);
					handleChangeAfterEdition();
				}
				break;
			case 'inspection':
				if (entityEditing && moveToUploads) {
					await moveToUploads(data);
					handleChangeAfterEdition();
				}
				break;
			case 'commentOrReply':
				if (entityEditing && moveToUploads) {
					await moveToUploads(data);
					handleChangeAfterEdition();
				}
				break;
			case 'temp':
				if (updateUploads) {
					await updateUploads(data);
				}
				break;
		}
	};

	const viewer = (
		<EntitiesMobilePhotoViewer
			entities={entities}
			activeId={activeId}
			activeIndex={activeIndex}
			isOpen={isOpen}
			onChange={handleActiveChange}
			onClose={toggleOpen}
			canDraw={canDraw}
			saveEditedImage={saveEditedImage}
		/>
	);

	return [viewer, openImage];
}
