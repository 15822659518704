import {useMemo} from 'react';
import keyBy from 'lodash/keyBy';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObjectFieldsSettings} from '@src/store/modules/dictionaries/objects/selectors';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';

/**
 * Хук для извлечения и преобразования в объект настроек полей
 *
 * @param objectId id объекта
 * @param entity сущность, для которой нужны настройки
 */
export function useFieldsSettings(objectId: string, entity: keyof IObjectFieldsSettings) {
	const settings = useAppSelector(s => extractObjectFieldsSettings(s, objectId))[entity];
	return useMemo(() => keyBy<IObjectFieldSetting>(settings, 'fieldId'), [settings]);
}
