import {IObjectStageWithIcon, ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ActionButtons, Button} from '../../../../buttons';
import {Dialog} from '../../../../dialogs';
import {RadioButton} from '../../../../inputs';
import {EntitySelect, IEntitySelectProps} from '../EntitySelect/EntitySelect';
import './StagesSelectDialog.less';

interface IStagesSelectProps {
	stages: IObjectStageWithIcon[];
	defaultStage?: ObjectStageIds;
	isOpen: boolean;
	entitiesSelectMapProps: Record<ObjectStageIds, IEntitySelectProps>;

	onConfirm?: (id: string) => void;
	onClose: () => void;
	onCancel?: () => void;
}

export const StagesSelectDialog = ({
	stages, defaultStage, isOpen, onConfirm, onClose, onCancel, entitiesSelectMapProps
}: IStagesSelectProps) => {
	const [checked, setChecked] = useState<ObjectStageIds>(() => defaultStage || stages[0].id);
	const handleChange = useCallback((id: ObjectStageIds) => setChecked(id), []);

	const checkedEntitiesSelectProps = useMemo(() =>
		entitiesSelectMapProps[checked], [checked, entitiesSelectMapProps]);

	const [selectedEntity, setSelectedEntity] = useState<string | undefined>(undefined);

	useEffect(() => {
		const defaultSelectedEntity = checkedEntitiesSelectProps.selected;
		setSelectedEntity(defaultSelectedEntity);
	}, [checkedEntitiesSelectProps]);

	const handleConfirm = useCallback(() => {
		if (onConfirm && checked) {
			onConfirm(checked);
		}
		if (checkedEntitiesSelectProps) {
			checkedEntitiesSelectProps.onEntityChange(selectedEntity);
		}
		onClose();
	}, [onConfirm, checked, selectedEntity, onClose]);

	const handleCancel = useCallback(() => {
		if (onCancel) {
			onCancel();
		}
		onClose();
	}, [onCancel, onClose]);

	return (
		<Dialog
			className="stages-select-dialog"
			isOpen={isOpen}
			onRequestClose={onClose}
			title="Стадия"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleConfirm}
					/>
				</ActionButtons>
			)}
		>
			<div className="stages-select-dialog__items">
				{stages.map(({id, icon, name}) => (entitiesSelectMapProps[id] ? (
					<div
						className="stages-select-dialog__items-item"
						key={id}
						onClick={() => handleChange(id)}
					>
						<div className="stages-select-dialog__items-item-icon">{icon}</div>
						<div className="stages-select-dialog__items-item-name">{name}</div>
						<RadioButton
							className="stages-select-dialog__items-item-radio"
							checked={checked === id}
						/>
					</div>
				) : null))}
			</div>
			{checked && checkedEntitiesSelectProps && (
				<EntitySelect
					{...checkedEntitiesSelectProps}
					onEntityChange={setSelectedEntity}
					selected={selectedEntity}
				/>
			)}
		</Dialog>
	);
};