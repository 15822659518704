import {IProblemStatusesState} from '@src/store/modules/dictionaries/problemStatuses/reducers';
import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';

export const createStatusDifference = (
	prev: string | undefined,
	next: string | undefined,
	statuses: IProblemStatusesState
): IHistoryData => ({
	prev: prev
		? {
				name: statuses.byId[prev].name,
				color: statuses.byId[prev].color
		  }
		: undefined,
	next: next
		? {
				name: statuses.byId[next].name,
				color: statuses.byId[next].color
		  }
		: undefined,
	type: HistoryTypeId.STATUS,
	name: 'статус'
});
