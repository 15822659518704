import React, {Dispatch, useMemo} from 'react';
import './EditableSpace.less';
// import Location from '@src/components/editableFields/Location';
import Attachments from '@src/components/editableFields/Attachments';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {
	IEditableSpaceAction,
	IEditableSpaceState
} from '@src/core/hooks/states/useEditableSpaceState';
import Text from '../editableFields/Text';
import {SpaceStatus} from '../editableFields/SpaceStatus';
import SpaceTypeSelect from '@src/components/editableFields/SpaceTypeSelect';
import {SpaceTypeDecorationSelect} from '../editableFields/SpaceTypeDecorationSelect/SpaceTypeDecorationSelect';
import YesNoSelect from '../editableFields/YesNoSelect';
import ContractFormSelect from '../editableFields/ContractFormSelect';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceTypeDecorationsAsArray} from '@src/store/modules/entities/spaceTypeDecorations/selectors';
import {extractSpaceTypeDecorationSetsAsArray} from '@src/store/modules/entities/spaceTypeDecorationSets/selectors';
import {filterTypeDecorationsForEditingspace} from './utils/filterTypeDecoration';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {useUpdateEffect} from 'react-use';
import {EditableDate} from '../editableFields/EditableDate';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

interface IEditableSpaceProps {
	objectId: string;
	editingState: IEditableSpaceState;
	editingDispatch: Dispatch<IEditableSpaceAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableSpace = (props: IEditableSpaceProps) => {
	const {
		objectId,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		fieldsSettings,
		saving
	} = props;

	const object = useAppSelector(s => extractObject(s, objectId));
	const user = useAppSelector(s => s.auth.profile);
	const typeDecorationsArray = useAppSelector(extractSpaceTypeDecorationsAsArray);
	const typeDecorationSets = useAppSelector(extractSpaceTypeDecorationSetsAsArray);

	const externalTypeDecorations = useMemo(
		() =>
			filterTypeDecorationsForEditingspace(
				object,
				editingState,
				typeDecorationsArray,
				typeDecorationSets
			),
		[editingState, object, typeDecorationSets, typeDecorationsArray]
	);

	// Сброс типа отделки при реактировании типа помещения
	useUpdateEffect(() => {
		editingDispatch({
			type: 'update',
			field: 'typeDecoration',
			value: undefined
		});
	}, [editingState.type]);

	const canEditExternalId = (!!user && !!user.roles) ? hasPermission(
		user.roles,
		'spaces-external-id-edit',
		UserRoleScopes.OBJECT,
		objectId
	) : false;

	return (
		<div className="editable-space">
			<div>
				<div className="editable-space__title">Основное</div>
				<div className="editable-space__info-grid">
					{fieldsSettings.name !== undefined && (
						<Text
							field="name"
							label="Номер/название"
							value={editingState.name}
							editingDispatch={editingDispatch}
							required={fieldsSettings.name.isRequired}
							disabled={saving}
							hasError={editingState.errors.name}
						/>
					)}

					{fieldsSettings.type !== undefined && (
						<SpaceTypeSelect
							value={editingState.type}
							editingDispatch={editingDispatch}
							required={fieldsSettings.type.isRequired}
							disabled={saving}
							hasError={editingState.errors.type}
						/>
					)}

					{fieldsSettings.status !== undefined && (
						<SpaceStatus
							value={editingState.status}
							editingDispatch={editingDispatch}
							required={fieldsSettings.status.isRequired}
							disabled={saving}
							hasError={editingState.errors.status}
							objectId={objectId}
						/>
					)}

					{fieldsSettings.altName !== undefined && (
						<Text
							field="altName"
							label="Альтернативный номер/название"
							value={editingState.altName}
							editingDispatch={editingDispatch}
							required={fieldsSettings.altName.isRequired}
							disabled={saving}
							hasError={editingState.errors.altName}
						/>
					)}

					{fieldsSettings.floor !== undefined && (
						<Text
							field="floor"
							label="Этаж"
							value={editingState.floor}
							editingDispatch={editingDispatch}
							required={fieldsSettings.floor.isRequired}
							disabled={saving}
							hasError={editingState.errors.floor}
						/>
					)}

					{fieldsSettings.plannedArea !== undefined && (
						<Text
							field="plannedArea"
							label={(
								<>
									Строительная площадь, м
									<sup>2</sup>
								</>
							)}
							value={editingState.plannedArea}
							editingDispatch={editingDispatch}
							required={fieldsSettings.plannedArea.isRequired}
							disabled={saving}
							hasError={editingState.errors.plannedArea}
							errorMessage="Введите число"
						/>
					)}

					{fieldsSettings.actualArea !== undefined && (
						<Text
							field="actualArea"
							label={(
								<>
									Фактическая площадь, м
									<sup>2</sup>
								</>
							)}
							value={editingState.actualArea}
							editingDispatch={editingDispatch}
							required={fieldsSettings.actualArea.isRequired}
							disabled={saving}
							hasError={editingState.errors.actualArea}
							errorMessage="Введите число"
						/>
					)}

					{fieldsSettings.typeDecoration !== undefined && (
						<SpaceTypeDecorationSelect
							value={editingState.typeDecoration}
							editingDispatch={editingDispatch}
							externalTypeDecorations={externalTypeDecorations}
							required={fieldsSettings.typeDecoration.isRequired}
							disabled={saving}
							hasError={editingState.errors.typeDecoration}
						/>
					)}
				</div>
				{/* Дополнительная информация */}
				<div className="editable-space__title">Дополнительное</div>
				<div className="editable-space__info-grid">
					<Text
						field="areaBTI"
						label={(
							<>
								Площадь квартиры БТИ, м
								<sup>2</sup>
							</>
						)}
						value={editingState.areaBTI}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>
					<Text
						field="numberBTI"
						label="Номер БТИ"
						value={editingState.numberBTI}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>
					<Text
						field="floorBTI"
						label="Этаж БТИ"
						value={editingState.floorBTI}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>
					{canEditExternalId && (
						<Text
							field="externalId"
							label="Внешний Id"
							value={editingState.externalId}
							editingDispatch={editingDispatch}
							disabled={saving}
						/>
					)}
					<ContractFormSelect
						field="contractForm"
						value={editingState.contractForm}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>

					<YesNoSelect
						field="markupForRegistration"
						label="Наценка за регистрацию"
						value={editingState.markupForRegistration}
						editingDispatch={editingDispatch}
						disabled={saving}
					/>
					{fieldsSettings.decorationWarrantyExpiredDate !== undefined && (
						<EditableDate
							value={editingState.decorationWarrantyExpiredDate}
							editingDispatch={editingDispatch}
							required={fieldsSettings.decorationWarrantyExpiredDate.isRequired}
							hasError={editingState.errors.decorationWarrantyExpiredDate}
							createdAt={0}
							label="Срок окончания гарантии по отделке"
							field="decorationWarrantyExpiredDate"
						/>
					)}
					{fieldsSettings.technicalEquipmentWarrantyExpiredDate !== undefined && (
						<EditableDate
							value={editingState.technicalEquipmentWarrantyExpiredDate}
							editingDispatch={editingDispatch}
							required={
								fieldsSettings.technicalEquipmentWarrantyExpiredDate.isRequired
							}
							hasError={editingState.errors.technicalEquipmentWarrantyExpiredDate}
							createdAt={0}
							label="Срок окончания гарантии по инженерному/техническому оборудованию"
							field="technicalEquipmentWarrantyExpiredDate"
						/>
					)}

					{fieldsSettings.constructiveWarrantyExpiredDate !== undefined && (
						<EditableDate
							value={editingState.constructiveWarrantyExpiredDate}
							editingDispatch={editingDispatch}
							required={fieldsSettings.constructiveWarrantyExpiredDate.isRequired}
							hasError={editingState.errors.constructiveWarrantyExpiredDate}
							createdAt={0}
							label="Срок окончания гарантии по конструктиву"
							field="constructiveWarrantyExpiredDate"
						/>
					)}
				</div>
			</div>

			<div>
				{fieldsSettings.attachments !== undefined && (
					<Attachments
						className={{
							root: 'editable-space__attachments',
							scrollArea: 'editable-space__attachments-scroll-area',
							files: 'editable-space__attachments-files',
							file: 'editable-space__attachments-file'
						}}
						attachments={editingState.attachments}
						uploadingFiles={uploadingFilesState.value}
						editingDispatch={editingDispatch}
						uploadingFilesDispatch={uploadingFilesDispatch}
						required={fieldsSettings.attachments.isRequired}
						disabled={saving}
						canDraw={false}
						hasError={editingState.errors.attachments && uploadingFilesState.error}
						showAttachBtn
					/>
				)}
			</div>
		</div>
	);
};

export default EditableSpace;
