import React, {cloneElement, useCallback, useState} from 'react';
import './SelectDialog.less';
import classNames from 'classnames';
import {RefCallback} from 'react-laag/dist/types.d';
import useToggle from 'react-use/lib/useToggle';
import Dialog, {IDialogProps} from '../../../dialogs/Dialog';
import {convertClassNames} from '../../../../utils/convertClassNames';
import {ActionButtons} from '../../../buttons';
import Button from '../../../buttons/Button';
import {useUpdateEffect} from 'react-use';

export interface ISelectDialogTriggerElProps {
	isOpen?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	ref?: RefCallback;
}

export interface ISelectDialogTriggerFnProps {
	isOpen?: boolean;
	disabled?: boolean;
	open?: () => void;
	close?: () => void;
	toggle?: () => void;
	ref?: RefCallback;
}

export interface ISelectDialogProps {
	className?: IDialogProps['className'];
	style?: React.CSSProperties;
	trigger: React.ReactElement<ISelectDialogTriggerElProps> |
		((props: ISelectDialogTriggerFnProps) => React.ReactNode);
	children?: React.ReactElement;
	title?: string;
	dialogProps?: Pick<IDialogProps,
		'appRoot'
		| 'shouldFocusAfterRender'
		| 'shouldCloseOnOverlayClick'
		| 'shouldCloseOnEsc'
		| 'shouldDisableScroll'>;
	value?: string | string[];

	onChange?: (value: string | string[] | undefined) => void;
	onClose?: () => void;
}

const SelectDialog = ({
	className,
	style,
	trigger,
	children,
	title,
	dialogProps,
	value: propsValue,
	onChange,
	onClose
}: ISelectDialogProps) => {
	const [value, setValue] = useState(propsValue);
	useUpdateEffect(() => {
		setValue(propsValue);
	}, [propsValue]);
	const handleChange = useCallback((v: string | string[] | undefined) => {
		setValue(v);
	}, []);

	const [isOpen, toggleOpen] = useToggle(false);

	const open = useCallback(() => {
		toggleOpen(true);
	}, []);

	const close = useCallback(() => {
		toggleOpen(false);

		if (onClose) {
			onClose();
		}
	}, [onClose]);

	const handleOkClick = useCallback(() => {
		close();
		if (onChange) {
			setTimeout(() => onChange(value), 300);
		}
	}, [value, onChange]);

	const handleCancelClick = useCallback(() => {
		close();
		setTimeout(() => setValue(propsValue), 300);
	}, [propsValue]);

	const classes = convertClassNames(className);
	classes.root = classNames('select-dialog', classes.root);
	classes.layer = classNames('select-dialog__layer', classes.layer);
	classes.body = classNames('select-dialog__body', classes.body);

	return children ? (
		<>
			{typeof trigger === 'function'
				? trigger({
					isOpen,
					open,
					close,
					toggle: toggleOpen
				})
				: cloneElement(trigger, {
					isOpen,
					onClick: toggleOpen
				})}

			<Dialog
				fullScreenOnMobile
				{...dialogProps}
				className={classes}
				style={style}
				isOpen={isOpen}
				title={title}
				footer={(
					<ActionButtons>
						<Button
							type="accent-blue"
							label="Подтвердить"
							onClick={handleOkClick}
						/>
						<Button
							type="cancel"
							label="Отменить"
							onClick={handleCancelClick}
						/>
					</ActionButtons>
				)}
				onRequestClose={handleCancelClick}
			>
				{React.cloneElement(children, {value, onChange: handleChange})}
			</Dialog>
		</>
	) : (
		<>{typeof trigger === 'function' ? trigger({disabled: true}) : cloneElement(trigger, {disabled: true})}</>
	);
};

SelectDialog.displayName = 'SelectDialog';

export default SelectDialog;