import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckProblems} from '@src/store/modules/entities/check/selectors';
import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import DesktopCheckExportBtn from './CheckExport.desktop';
import EntityAdding from './EntityAdding';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';

interface IDesktopMainActionsProps {
	objectId: string;
	check: ICheck;
}

const DesktopMainActions = ({objectId, check}: IDesktopMainActionsProps) => {
	const problems = useAppSelector(extractCheckProblems);
	const permissions = useCheckPermissions(objectId, check, problems);

	return (
		<>
			{permissions.canExport && (
				<DesktopCheckExportBtn
					objectId={objectId}
					checkId={check.id}
				/>
			)}
			<EntityAdding
				objectId={objectId}
				check={check}
			/>
		</>
	);
};

export default DesktopMainActions;
