import React, {useCallback} from 'react';
import {EntityGridItem, DottedLinkButton, LocationSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPlan, extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {getSelectedStatus} from '@tehzor/ui-components/src/components/inputs/statuses/BaseStatusSelect/utils/getSelectedStatus';
import {getStatusColor} from '@tehzor/ui-components/src/components/inputs/statuses/StatusSelect/utils/getStatusColor';

interface ILocationProps {
	objectId: string;
	planId?: string;
	floor?: string;
	location?: ILocation;
	fullRow?: boolean;
	status?: string;
	hasComments?: boolean;
}

const Location = (props: ILocationProps) => {
	const {objectId, planId, floor, location, fullRow, status, hasComments} = props;

	const plans = useAppSelector(s => extractPlansAsArray(s, objectId));
	const plan = useAppSelector(s => extractPlan(s, objectId, planId));

	const statuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const selectedStatus = getSelectedStatus(status, statuses);
	const pointsColor = getStatusColor(selectedStatus);

	const locationSelectCallback = useCallback((displayLocation: string, open: () => void) => (
		<div className="problem-page__d-entities-grid-display-place">
			<DottedLinkButton
				label={displayLocation}
				onClick={open}
			/>
			{hasComments && <i className="tz-comment-on-plan-16"/>}
		</div>
	), [hasComments]);

	return (
		<EntityGridItem
			icon={<i className="tz-location-20"/>}
			label="Место"
			fullRow={fullRow}
		>
			<div className="semi-bold">
				План:
				{' '}
				{plan?.name}
				{plan?.name && floor ? ', ' : undefined}
				<br/>
				Этаж:
				{' '}
				{floor || undefined}
			</div>

			<LocationSelect
				mode="view"
				planId={planId}
				location={location}
				plans={plans}
				multiplePoints
				pointsColor={pointsColor}
			>
				{locationSelectCallback}
			</LocationSelect>
		</EntityGridItem>
	);
};

export default Location;
