import {combineReducers} from '@reduxjs/toolkit';
import {offlineDialog} from './offlineDialog/reducers';
import links from './links/reducers';
import {sidebar} from './sidebar/reducers';
import {update} from './update/reducers';
import {loading} from './actionsStatus/reducers';
import {updateData} from './updateData/reducers';

export default combineReducers({
	links,
	offlineDialog,
	sidebar,
	update,
	loading,
	updateData
});
