import React, {Dispatch, useState} from 'react';
import './EditableWarrantyClaim.less';
import {
	IEditableWarrantyClaimAction,
	IEditableWarrantyClaimState
} from '@src/core/hooks/states/useEditableWarrantyClaimState';
import {IUploadingFilesAction, IUploadingFilesState} from '@src/core/hooks/states/useUploadingFilesState';
import Attachments from '@src/components/editableFields/Attachments';
import SpaceOwner from '@src/components/editableFields/SpaceOwner';
import ReplyEmail from './components/ReplyEmail';
import ReplyPhone from './components/ReplyPhone';
import ClaimerName from './components/ClaimerName';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {EditableDateField} from '@src/components/EditableDateField';

interface IEditableWarrantyClaimProps {
	spaceId?: string;
	editingState: IEditableWarrantyClaimState;
	editingDispatch: Dispatch<IEditableWarrantyClaimAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	// fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */

const EditableWarrantyClaim = (props: IEditableWarrantyClaimProps) => {
	const {
		spaceId,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		saving
	} = props;

	const ownersMap = useAppSelector(s => s.dictionaries.spaceOwners.byId);
	const space = useAppSelector(extractSpace);
	const spacesTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const notBeOwned = space ? spacesTypes.byId[space.type]?.notBeOwned : undefined;
	const [ownerIsNotClaimer, setOwnerIsNotClaimer] = useState(false);
	const selectedOwner = editingState.spaceOwnerId
		? ownersMap[editingState.spaceOwnerId]
		: undefined;

	const handleOwnerIsNotClaimer = () => {
		setOwnerIsNotClaimer(!ownerIsNotClaimer);
	};

	const handleDateChange = (date: Date) => {
		editingDispatch({type: 'update', field: 'registrationDate', value: date.getTime()});
	};

	return (
		<div className="editable-warranty-claim">
			<div>
				<div className="editable-warranty-claim__info-grid">
					{spaceId && !notBeOwned ? (
						<SpaceOwner
							spaceId={spaceId}
							value={editingState.spaceOwnerId}
							editingDispatch={editingDispatch}
							required
							// required={fieldsSettings.resolution.isRequired}
							disabled={saving}
							hasError={editingState.errors.spaceOwnerId}
							ownerIsNotClaimer={ownerIsNotClaimer}
							handleOwnerIsNotClaimer={handleOwnerIsNotClaimer}
							label={ownerIsNotClaimer ? 'Cобственник' : 'Cобственник (Заявитель)'}
						/>
					) : null}

					{!spaceId || notBeOwned || ownerIsNotClaimer ? (
						<ClaimerName
							value={editingState.claimerName}
							editingDispatch={editingDispatch}
							required
							disabled={saving}
							hasError={editingState.errors.claimerName}
							defaultValue={selectedOwner?.name}
						/>
					) : null}

					<ReplyEmail
						value={editingState.replyEmail}
						editingDispatch={editingDispatch}
						required
						// required={fieldsSettings.resolution.isRequired}
						disabled={saving}
						hasError={editingState.errors.replyEmail}
						defaultValue={selectedOwner?.email}
					/>

					<ReplyPhone
						value={editingState.replyPhone}
						editingDispatch={editingDispatch}
						required
						// required={fieldsSettings.resolution.isRequired}
						disabled={saving}
						hasError={editingState.errors.replyPhone}
						defaultValue={selectedOwner?.phone}
					/>
					<EditableDateField
						label="Дата регистрации обращения"
						value={editingState.registrationDate ? new Date(editingState.registrationDate) : undefined}
						disabled={saving}
						onChange={handleDateChange}
					/>
				</div>
			</div>

			<div>
				<Attachments
					className={{
						root: 'editable-warranty-claim__attachments',
						scrollArea: 'editable-warranty-claim__attachments-scroll-area',
						files: 'editable-warranty-claim__attachments-files',
						file: 'editable-warranty-claim__attachments-file'
					}}
					attachments={editingState.attachments}
					uploadingFiles={uploadingFilesState.value}
					editingDispatch={editingDispatch}
					uploadingFilesDispatch={uploadingFilesDispatch}
					// required={fieldsSettings.attachments.isRequired}
					waitForUploading={false}
					label="Прикрепить акт"
					disabled={saving}
					canDraw={false}
					hasError={editingState.errors.attachments && uploadingFilesState.error}
					showAttachBtn
				/>
			</div>
		</div>
	);
};

export default EditableWarrantyClaim;
