import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {queryClient} from '@src/api/QueryClient';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт нарушений
 */
export const makeProblemsExportRequest = async (
	templateId: string,
	objectId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	filters: Record<string, unknown> | undefined,
	sort: Record<string, boolean> | undefined,
	selected: string[] | undefined
): Promise<IExportResponse> => {
	const params = {
		templateId,
		objectId: objectId === 'all' ? undefined : objectId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		filters: {
			...filters,
			selected
		},
		sort: convertSortParam(sort)
	};
	void queryClient.invalidateQueries(documentsQueryKeys.list());
	const response = await httpRequests.withToken.get<IExportResponse>('problems/export', {params});
	return response.data;
};
