import React from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {StatusSelect} from '@tehzor/ui-components';

export const WorkAcceptanceStatusCell = ({row}: CellProps<IListWorkAcceptance>) => {
	const workAcceptance = row.original;
	const statuses = useAppSelector(state => state.dictionaries.workAcceptanceStatuses);

	return (
		<ClickPreventWrap>
			<StatusSelect
				className="work-acceptance-page__d-entities-status"
				statuses={statuses}
				disabled
				hideArrow
				value={workAcceptance?.status}
			/>
		</ClickPreventWrap>
	);
};
