import React from 'react';
import {IWeekData} from '../hooks/useDaysGrid';

export interface IWeekRowProps extends IWeekData {
	children?: React.ReactNode;
}

const WeekRow = ({children}: IWeekRowProps) => <div className="calendar__week-row">{children}</div>;

export default WeekRow;
