import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import {formInternalAcceptanceLink} from '@tehzor/tools/utils/links';
import {useMemo} from 'react';
import {useSpaceForWarrantyClaimBreadcrumbs} from './useSpaceForWarrantyClaimBreadcrumbs';

/**
 * Хук для хлебных крошек от "всех объектов" до внутренней приемки
 *
 * @param objectId id конечного объекта
 * @param internalAcceptanceId id внутренней приёмки
 * @param internalAcceptance внутренняя приемка
 */
export function useInternalAcceptanceFromSpaceBreadcrumbs(
	objectId: string,
	internalAcceptanceId: string,
	internalAcceptance: ILinkedInternalAcceptance
) {
	const res = useSpaceForWarrantyClaimBreadcrumbs(objectId, internalAcceptance.space);
	return useMemo(
		() =>
			res.concat({
				label: internalAcceptance.number
					? `Внутренняя приёмка №${internalAcceptance.number}`
					: 'Внутренняя приёмка',
				url: formInternalAcceptanceLink(objectId, internalAcceptanceId),
				inactive: false
			}),
		[objectId, res, internalAcceptance, internalAcceptanceId]
	);
}
