import * as types from '../constants/checkListsData';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {ISpacesStatsDataRequest} from '@src/interfaces/ISpacesStatsData';
import {ISpaceCheckListsData} from '@tehzor/tools/interfaces/entititesComputedData/ISpaceCheckListsData';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

const request = (requestData: ISpacesStatsDataRequest) => ({
	type: types.GET_REQUEST,
	payload: requestData
});

const success = (response: Record<string, ISpaceCheckListsData>, objectId: string) => ({
	type: types.GET_SUCCESS,
	payload: {spacesCheckListsData: response, objectId}
});

const failure = (error: IError, objectId: string) => {
	addErrorToast('Ошибка', 'при загрузке чек-листов помещений');
	return {
		type: types.GET_FAILURE,
		payload: error,
		objectId
	};
};

/**
 * Получает статстику чек-листов для помещений Объекта
 *
 * @param requestData данные запроса
 */
export const getSpacesCheckListsData = (requestData: ISpacesStatsDataRequest) =>
	createApiAction(
		() => request(requestData),
		result => success(result, requestData.objectId),
		error => failure(error, requestData.objectId),
		() =>
			queryClient.fetchQuery({
				queryKey: [
					...spacesQueryKeys.checkListsData(),
					requestData.objectId,
					requestData.spacesIds
				],
				staleTime: Infinity
			})
	);
