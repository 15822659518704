import React, {useCallback, useState} from 'react';
import {MobilePagination, Plate} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractStructuresPageSettings} from '@src/store/modules/settings/pages/structures/selectors';
import {Table} from './Table';
import {mobileColumns} from './columns.mobile';
import {extractStructuresListData} from '@src/store/modules/entities/structures/selectors/lists';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeOffset} from '@src/store/modules/entities/structures/actions';
import {StructuresPageCounter} from '../StructuresPageCounter';

interface IMobileTableProps {
	objectId: string;
}

export const MobileTable = ({objectId}: IMobileTableProps) => {
	const {total, offset} = useAppSelector(s => extractStructuresListData(s, objectId));
	const {pageSize} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const [count, setCount] = useState(0);
	const handlePageChange = useCallback(
		(value: number) => {
			dispatch(changeOffset(objectId, value * pageSize));
		},
		[objectId, pageSize, dispatch]
	);

	const pageCount = Math.ceil(total / pageSize);
	const currentPage = Math.floor(offset / pageSize);

	return (
		<>
			<StructuresPageCounter
				objectId={objectId}
				count={count}
			/>
			<Plate
				withoutPadding
				className="structures-page__plate"
			>

				<MobilePagination
					className="structures-page__m-pagination"
					page={currentPage}
					pageCount={pageCount}
					onPageChange={handlePageChange}
				/>
				<Table
					objectId={objectId}
					columns={mobileColumns}
					hideHead
					noRowBorder
					setCount={setCount}
				/>
			</Plate>
		</>

	);
};
