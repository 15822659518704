import React, {useCallback, useEffect, useState} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {exportProblems} from '@src/store/modules/entities/problems/actions';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';
import {exportOwnerAcptUnified} from '@src/store/modules/entities/ownerAcceptance/actions';

function useDestinations(selectedEntities: IOwnerAcceptanceEntity[]) {
	const [destinations, setDestinations] = useState<ExportTemplatesDestinationId[]>([
		ExportTemplatesDestinationId.OWNER_ACCEPTANCE
	]);

	useEffect(() => {
		let hasProblems = false;
		for (const item of selectedEntities) {
			if (item.type === 'problem') {
				hasProblems = true;
				break;
			}
		}

		// Экспорт заявления и списка нарушений
		if (hasProblems) {
			return setDestinations([
				ExportTemplatesDestinationId.OWNER_ACCEPTANCE,
				ExportTemplatesDestinationId.PROBLEMS
			]);
		}
		return undefined;
	}, [selectedEntities]);

	return destinations;
}

const splitSelectedEntities = (selectedEntities: IWarrantyClaimEntity[]) =>
	selectedEntities.reduce<{
		problems: string[];
	}>(
		(prev, item) => {
			switch (item.type) {
				case 'problem':
					prev.problems.push(item.data.id);
					break;
			}
			return prev;
		},
		{problems: []}
	);

/**
 * Хук для экспорта приёмки или ее нарушений в зависимости от того, что выбрано
 *
 * @param objectId id объекта
 * @param selectedEntities выбранные нарушения/осмотры
 */
export function useOwnerAcceptanceCombinedExport(
	objectId: string,
	selectedEntities: IOwnerAcceptanceEntity[]
): [React.ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const destinations = useDestinations(selectedEntities);

	const [isOpen, setOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const [, handleExport] = useAsyncFn(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (template.destination === ExportTemplatesDestinationId.OWNER_ACCEPTANCE) {
				if (exportingId) {
					const {problems} = splitSelectedEntities(selectedEntities);
					await dispatch(
						exportOwnerAcptUnified(
							email,
							template.id,
							objectId,
							exportingId,
							problems,
							createDocument
						)
					);
				}
			} else if (template.destination === ExportTemplatesDestinationId.PROBLEMS) {
				const selected = selectedEntities.map(item => item.data.id);
				await dispatch(
					exportProblems(
						template.id,
						objectId,
						createDocument,
						email,
						undefined,
						undefined,
						selected
					)
				);
			}
			setOpen(false);
		},
		[objectId, exportingId, selectedEntities]
	);

	const dialog = (
		<ExportDialog
			pageKey="warranty-claim"
			destinations={destinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
