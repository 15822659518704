import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import React from 'react';
import {CellProps} from 'react-table';
import declination from '@tehzor/tools/utils/declination';

const InfoCell = ({row}: CellProps<IEnrichedProblemTagsSet>) => {
	const {name, tagsCount} = row.original;
	const text
		= tagsCount && tagsCount > 0
			? `${tagsCount} ${declination(tagsCount || 0, ['метка', 'метки', 'меток'])}`
			: undefined;

	return (
		<div className="manage-problem-tags-sets-page__info-cell">
			<div className="manage-problem-tags-sets-page__info-cell-name">{name}</div>
			{text ? (
				<div className="manage-problem-tags-sets-page__info-cell-count">{text}</div>
			) : null}
		</div>
	);
};

export default InfoCell;
