import React from 'react';
import {SelectedEntitiesDeletion} from './SelectedEntitiesDeletion';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {SelectedEntitiesExport} from './SelectedEntitiesExport';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface ISelectionsActionsProps {
	objectId: string;
	workAcceptanceId: string;
	checkLists: ICheckList[];
	selectedEntities: IWorkAcceptanceEntity[];
}

export const SelectionActions = ({
	objectId, workAcceptanceId, checkLists, selectedEntities
}: ISelectionsActionsProps) => {
	const permissions = useWorkAcceptancesPermissions(objectId);

	return (
		<>
			{permissions.canExport && (
				<SelectedEntitiesExport
					objectId={objectId}
					workAcceptanceId={workAcceptanceId}
					checkLists={checkLists}
					selectedEntities={selectedEntities}
				/>
			)}
			{permissions.canDelete && (
				<SelectedEntitiesDeletion selectedEntities={selectedEntities}/>
			)}
		</>
	);
};
