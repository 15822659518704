import {wsConnector} from '../wsConnector';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';

export type IEditResponsibilityRuleResponse = IResponsibilityRule;

/**
 * Обновляет одно правило ответственности
 *
 * @param ruleId id правила
 * @param groupId id группы
 * @param rule данные для изменения
 */
export const makeResponsibilityRuleEditRequest = (
	ruleId: string,
	groupId: string,
	rule: ISavingResponsibilityRule
) =>
	wsConnector.sendAuthorizedRequest<IEditResponsibilityRuleResponse>('editResponsibilityRule', {
		ruleId,
		groupId,
		...rule
	});
