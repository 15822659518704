import {
	useCheckRecordStatusChangeItemMutation,
	useCheckRecordStatusChangeListMutation
} from '@src/api/cache/checkRecords/mutations';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import useAppSelector from '../../useAppSelector';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {useCallback} from 'react';

export interface IChangeCheckRecordJournalData {
	objectName: string;
	locationName?: string;
	checkItemName?: string;
	checkListName?: string;
	newStatus: string;
}
export interface IChangeCheckRecordStatusParams {
	item: ICheckList | ICheckItem;
	objectId: string;
	spaceId: string | undefined;
	workAcceptanceId: string | undefined;
	stage: ObjectStageIds;
	status: CheckRecordStatusId;
	record: ICheckRecord;
}
export const useChangeCheckRecord = (objectId: string, item?: ICheckList | ICheckItem) => {
	const space = useAppSelector(extractSpace);
	const object = useAppSelector(s => extractObject(s, objectId));
	const checkListMutation = useCheckRecordStatusChangeListMutation();
	const checkItemMutation = useCheckRecordStatusChangeItemMutation();
	const checklists = useAppSelector(s => s.dictionaries.checkLists);
	const statuses = useAppSelector(s => s.dictionaries.checkRecordStatuses);

	const changeCheckListRecord = useCallback(
		(params: IChangeCheckRecordStatusParams) => {
			const key = `local-${Date.now().toString(10)}`;
			const journalData = {
				objectName: object.name,
				locationName: space?.name,
				checkListName: params.item.name,
				newStatus: statuses.byId[params.status].name
			};
			checkListMutation.mutate({
				key,
				journalData,
				...params
			});
		},
		[objectId, item]
	);
	const changeCheckItemRecord = useCallback(
		(params: IChangeCheckRecordStatusParams) => {
			const key = `local-${Date.now().toString(10)}`;
			const checkItem = item as ICheckItem;
			const checkListName = checkItem.checkListId
				? checklists.byId[checkItem.checkListId].name
				: undefined;
			const journalData = {
				objectName: object.name,
				locationName: space?.name,
				checkListName,
				checkItemName: params.item.name,
				newStatus: statuses.byId[params.status].name
			};
			checkItemMutation.mutate({
				key,
				journalData,
				...params
			});
		},
		[objectId, item]
	);
	return {changeCheckListRecord, changeCheckItemRecord};
};
