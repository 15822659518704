import * as types from './constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {checkExpiration} from '@tehzor/tools/core/storeHelpers/actions';
import {IGetTaskPrioritiesResponse, requestTaskPriorities} from '@src/api/backend/taskPriorities';

export type IGetTaskPrioritiesPayload = IGetTaskPrioritiesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetTaskPrioritiesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке приоритетов задач');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список приоритетов задач
 */
export const getTaskPriorities = () =>
	checkExpiration<IState, IGetTaskPrioritiesResponse, ApiAction>(
		state => state.dictionaries.taskPriorities,
		createApiAction<IGetTaskPrioritiesResponse>(request, success, failure, requestTaskPriorities),
		1209600000 // 2 недели
	);
