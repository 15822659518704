import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {DesktopItemDetail} from './detail';
import React from 'react';

export const DesktopDetailColumn = () => {
	const itemsLoading = useAppSelector(s => s.dictionaries.checkItems.loading);
	const {itemId} = useStrictParams<{itemId?: string}>();
	return (
		<Plate
			withoutPadding
			className={{root: 'check-item-detail', content: 'check-item-detail__content'}}
		>
			{itemId && (
				<LoadingPanel
					active={itemsLoading}
					style={{height: '100%'}}
				>
					<DesktopItemDetail/>
				</LoadingPanel>
			)}

			{!itemId && (
				<div className="check-item-detail__no-content">
					<div className="check-item-detail__no-content-container">
						<div className="check-item-detail__no-content-icon">
							<i className="tz-inspection"/>
						</div>
						<div className="check-item-detail__no-content-text">
							Выберите категорию или подкатегорию
						</div>
					</div>
				</div>
			)}
		</Plate>
	);
};
