import React, {Dispatch, useCallback, useMemo, useState} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useUpdateEffect} from 'react-use';

interface IObjectsSelectProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: string;
	value?: string[];
	companyId?: string;
	availableObjects?: string[];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const ObjectsSelect = <S, E>({
	className,
	style,
	field,
	label = 'Объекты',
	value,
	companyId,
	availableObjects,
	editingDispatch,
	required,
	disabled,
	hasError
}: IObjectsSelectProps<S, E>) => {
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);

	const objectsData = useAppSelector(s => s.dictionaries.objects);

	const treeData = useMemo(() => {
		const ids = availableObjects ?? objectsData.allIds;
		const objects = companyId
			? ids.reduce<IObject[]>((prev, id) => {
					if (objectsData.byId[id]?.companyId === companyId) {
						prev.push(objectsData.byId[id]);
					}
					return prev;
			  }, [])
			: ids.map(id => objectsData.byId[id]);
		return makeObjectsTreeData(objects);
	}, [objectsData, companyId, availableObjects]);

	const handleChange = useCallback(() => {
		editingDispatch({type: 'update', field, value: selectedObjects});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [required, selectedObjects, field]);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
	}, []);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
	}, [value]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	useUpdateEffect(() => {
		setSelectedObjects(value);
	}, [value]);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={
							value && value.length > 0
								? value.length > 1
									? `${objectsData.byId[value[0]]?.name} + ${value.length - 1}`
									: `${objectsData.byId[value[0]]?.name}`
								: undefined
						}
						icon={<i className="tz-simple-arrow-20"/>}
						error={required && hasError ? 'Выберите объект' : undefined}
						disabled={disabled}
					/>
				)}
				clearButton={!!selectedObjects?.length}
				footer
				noHeader
				count={selectedObjects?.length}
				onApply={handleChange}
				onCancel={handleCancel}
				onClear={handleClear}
			>
				<TreeSelect
					data={treeData}
					multiple
					value={selectedObjects}
					onChange={setSelectedObjects}
					expandedValue={expandedObjects}
					onExpand={handleExpand}
				/>
			</SelectPopup>
		</div>
	);
};
