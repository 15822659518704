import React, {useMemo} from 'react';
import {CellProps} from 'react-table';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemInspectorsGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export const InspectorsCell = ({row}: CellProps<IProblemEntity>) => {
	const {inspectors, objectId, stage} = row.original.data;

	const inspectorsGroups = useAppSelector(s =>
		extractProblemInspectorsGroupsAsArray(s, objectId, stage));

	const firstInspector = inspectors?.[0];

	const userGroup = useMemo(() => {
		if (!firstInspector) { return undefined; }

		const groups: IWorkingGroup[] = [];
		for (const group of inspectorsGroups) {
			if (group.performers?.includes(firstInspector.id)) {
				groups.push(group);
			} else if (group.leader === firstInspector.id) {
				groups.push(group);
			}
		}

		return groups.length === 1 ? groups[0] : undefined;
	}, [inspectorsGroups, firstInspector]);

	return inspectors ? (
		<div className="problems-page__d-table-inspectors">
			{userGroup && (
				<div className="problems-page__d-table-inspectors-group">
					{userGroup.name}
				</div>
			)}
			<div className="problems-page__d-table-inspectors-user">
				{firstInspector?.displayName}
			</div>
			{inspectors.length > 1 && (
				<div className="problems-page__d-table-inspectors-rest">
					+
					{inspectors.length - 1}
				</div>
			)}
		</div>
	) : null;
};