import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IContractsFiltersState, contractsActions} from '@src/store/modules/settings/pages/contracts/reducers';
import React, {useCallback} from 'react';
import {ContractsPage} from '../ContractsPage';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractContractsPageSettings} from '@src/store/modules/settings/pages/contracts/selectors';

export const ContractsPageWrap = () => {
	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(extractContractsPageSettings);
	const {changeFilters, clearFilters, changeOffset} = contractsActions;

	const applyFilters = useCallback((value: IContractsFiltersState) => {
		dispatch(changeFilters(value));
		dispatch(changeOffset(0));
	}, []);

	const onClear = useCallback(() => {
		dispatch(clearFilters());
	}, []);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<ContractsPage/>
		</EntitiesFiltersProvider>
	);
};