import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as internalAcceptanceTypes from '@src/store/modules/entities/internalAcceptance/constants';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {
	IAddInternalAcceptancePayload,
	IDeleteInternalAcceptancePayload
} from '@src/store/modules/entities/internalAcceptance/actions';
import {IGetOneUserInternalAcceptancePayload} from './actions/getOne';

export type ILatestUserInternalAcceptancesState = ILoadedEntityState<IInternalAcceptance>;

export default createReducer<ILatestUserInternalAcceptancesState>(
	getLoadedEntitiesInitialState<IInternalAcceptance>(),
	{
		[types.GET_SUCCESS]: getLoadedEntitiesSuccess<IInternalAcceptance>(),
		[types.GET_ONE_SUCCESS]: (
			state,
			{payload}: {payload: IGetOneUserInternalAcceptancePayload}
		) => {
			if (payload && !state.allIds.includes(payload.id)) {
				state.allIds.push(payload.id);
			}
			state.byId[payload.id] = payload;
		},
		[internalAcceptanceTypes.ADD_SUCCESS]: (
			state,
			{payload}: {payload: IAddInternalAcceptancePayload}
		) => {
			state.allIds.unshift(payload.id);
			state.byId[payload.id] = payload;
		},
		[internalAcceptanceTypes.DELETE_SUCCESS]: (
			state,
			{payload}: {payload: IDeleteInternalAcceptancePayload}
		) => {
			if (state.allIds.includes(payload.internalAcceptanceId)) {
				const byId = {...state.byId};
				delete byId[payload.internalAcceptanceId];
				state.byId = byId;
				state.allIds = state.allIds.filter(id => id !== payload.internalAcceptanceId);
			}
		}
	}
);
