import {Column} from 'react-table';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import {extractWarrantyClaimProblemCommentsByProblem} from '@src/store/modules/entities/warrantyClaim/selectors';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';

export const mobileColumns: Array<Column<IOwnerAcceptanceEntity>> = [
	{
		id: 'info',
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(
				ProblemInfoMobileCell,
				extractWarrantyClaimProblemCommentsByProblem
			)
		})
	}
];
