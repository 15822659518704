import * as types from '../constants';

/**
 * Отслеживаем статусы работающих запросов
 *
 * @param value true/false - показать/скрыть, undefined - поменять на противоположное значение
 */
export const loadStatusChange = (value: string) => ({
	type: types.APP_LOADING_STATUS_CHANGE,
	payload: value
});