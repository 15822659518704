import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetObjectStatsResponse, requestObjectStats} from '@src/api/backend/objects';

export type IGetObjectStatsPayload = IGetObjectStatsResponse;

const request = () => ({type: types.GET_STATS_REQUEST});

const success = (response: IGetObjectStatsResponse) => ({
	type: types.GET_STATS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке статистики объекта');
	return {
		type: types.GET_STATS_FAILURE,
		payload: error
	};
};

/**
 * Получает stats объекта
 */
export const getObjectStats = (
	objectsIds: string[]
) =>
	createApiAction<IGetObjectStatsResponse>(request, success, failure, () => requestObjectStats(objectsIds))