import React, {createContext, Dispatch, PropsWithChildren} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IPreparedPerformer} from '../interfaces/IPreparedPerformer';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {useAsyncFn} from 'react-use';
import {editWorkingGroup} from '@src/store/modules/dictionaries/workingGroups/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {addSuccessToast} from '@src/utils/toasts';
import {getResponsibilityRules} from '@src/store/modules/dictionaries/responsibilityRules/actions';

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedPerformer>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	editingState,
	editingDispatch,
	workingGroupId
}: PropsWithChildren<{
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	workingGroupId?: string;
}>) => {
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить исполнителя?',
		'Все связанные правила будут удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const dispatch = useAppDispatch();
	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedPerformer>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				// удаляем исполнителя из группы
				if (editingState.performers?.includes(action.payload.id)) {
					if (workingGroupId) {
						await dispatch(
							editWorkingGroup(workingGroupId, {
								performers: editingState.performers.filter(
									id => id !== action.payload.id
								)
							})
						);
						await dispatch(getResponsibilityRules());
						editingDispatch({
							type: 'delete-array-item',
							field: 'performers',
							index: [action.payload.index]
						});
						addSuccessToast('Успешно', 'Исполнитель удален');
					} else {
						editingDispatch({
							type: 'delete-array-item',
							field: 'performers',
							index: [action.payload.index]
						});
					}
				}
			}
		},
		[editingState.performers, editingDispatch]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
