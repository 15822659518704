import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {extractInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/selectors';
import {formInternalAcceptanceLink, formSpaceLink} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';

/**
 * Хук для хлебных крошек от "всех объектов" до internalAcceptance
 *
 * @param objectId id конечного объекта
 */
export function useInternalAcceptanceBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	const internalAcceptance = useAppSelector(extractInternalAcceptance);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId);
		if (internalAcceptance) {
			if (internalAcceptance.space) {
				items.push({
					label: formSpaceTitle(
						internalAcceptance.space.name,
						internalAcceptance.space.altName,
						spaceTypes.byId[internalAcceptance.space.type],
						altNamesVisible
					),
					url: formSpaceLink(objectId, internalAcceptance.space.id),
					inactive: false
				});
			} else {
				items.push({
					label: 'Внутренние приёмки',
					url: `/objects/${objectId}/internal-acceptances`,
					inactive: false
				});
			}
			items.push({
				label: `Внутренняя приёмка №${internalAcceptance.number}`,
				url: formInternalAcceptanceLink(objectId, internalAcceptance.id),
				inactive: false
			});
		}
		return items;
	}, [objectId, internalAcceptance, objects, spaceTypes]);
}
