import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {ICheckRecordsListQueryKey, checkRecordsQueryKeys} from '../keys';
import {requestCheckRecords} from '@src/api/backend/checkRecords';
import {IChangedRecord} from './useCheckRecordsMutationDefaults';

export const useCheckRecordsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(checkRecordsQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ICheckRecordsListQueryKey>) => {
			const [, , checkListIds, objectsIds, spaceId, workAcceptanceIds] = queryKey;
			const additionalParams: Record<string, string[]> = {};
			if (spaceId) {
				additionalParams.spaceId = [spaceId];
			}
			if (workAcceptanceIds) {
				additionalParams.workAcceptanceId = workAcceptanceIds;
			}
			if (objectsIds) {
				additionalParams.objectsIds = objectsIds;
			}
			return requestCheckRecords({checkListId: checkListIds, ...additionalParams});
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
	queryClient.setQueryDefaults(checkRecordsQueryKeys.localList(), {
		queryFn: () => {
			const localChanges = queryClient.getQueriesData<IChangedRecord>(
				checkRecordsQueryKeys.details()
			);
			return localChanges.map(([, change]) => change) as IChangedRecord[];
		},
		staleTime: 0,
		cacheTime: Infinity,
		networkMode: 'always'
	});
};
