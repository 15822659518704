import {Column} from 'react-table';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import ProblemStatusCell from '@src/components/tableCells/ProblemStatusCell';
import ProblemFixCell from '@src/components/tableCells/ProblemFixCell';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell/InspectionInfoDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {extractInternalAcceptanceProblemCommentsByProblem} from '@src/store/modules/entities/internalAcceptance/selectors';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';

export const desktopColumns: Array<Column<IInternalAcceptanceEntity>> = [
	{
		id: 'number',
		Header: HeaderCellWithRowSelectWrap('№ нарушения/осмотра'),
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(
				ProblemInfoDesktopCell,
				extractInternalAcceptanceProblemCommentsByProblem
			),
			inspection: InspectionInfoDesktopCell
		}),
		width: 200,
		minWidth: 200
	},
	{
		id: 'status',
		Header: 'Статус',
		Cell: CellWrap({
			problem: ProblemStatusCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'category',
		Header: 'Вид работ',
		Cell: CellWrap({
			problem: CategoryCell
		}),
		width: 120,
		minWidth: 120
	},
	{
		id: 'fix',
		Header: 'Устранение',
		Cell: CellWrap({problem: ProblemFixCell}),
		width: 150,
		minWidth: 150
	}
];
