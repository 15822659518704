import {wsConnector} from '../wsConnector';

export interface IDeleteCategoriesSetResponse {
	success: boolean;
}
/**
 * Удаляет набор видов работ по id
 *
 * @param categoriesSetId id набора видов работ
 */
export const requestDeleteCategoriesSet = (categoriesSetId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteCategoriesSetResponse>('deleteCategoriesSet', {
		id: categoriesSetId
	});
