import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {ownerAcceptancesQueryKeys} from '../keys';
import {QueryKey, useQueryClient} from '@tanstack/react-query';

export const useOwnerAcceptancesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(ownerAcceptancesQueryKeys.localList(), {
		queryFn: async ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localAcpts = await new Promise<
				Array<[QueryKey, IListOwnerAcceptance | undefined]>
			>(resolve => {
				const local = queryClient.getQueriesData<IListOwnerAcceptance>({
					queryKey: ownerAcceptancesQueryKeys.details(),
					predicate: ({state}) => {
						const {data} = state;
						if ((data as IListOwnerAcceptance).transferStatus) {
							if (!objectId || objectId === 'all') {
								return true;
							}
							if (objectId === (data as IListOwnerAcceptance).objectId) {
								return true;
							}
						}
						return false;
					}
				});
				resolve(local);
			});
			return localAcpts.map(([, acpt]) => acpt) as IListOwnerAcceptance[];
		},
		staleTime: 0,
		cacheTime: Infinity,
		networkMode: 'always'
	});
};
