import {wsConnector} from '../wsConnector';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ISavingWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/ISavingWorkAcceptance';

export type IAddWorkAcceptanceResponse = IWorkAcceptance;

/**
 * Добавляет приёмку работ
 *
 * @param objectId id объекта
 * @param fields данные
 */
export const makeWorkAcceptanceAddRequest = (
	objectId: string,
	fields: ISavingWorkAcceptance
) =>
	wsConnector.sendAuthorizedRequest<IAddWorkAcceptanceResponse>('addWorkAcceptance', {
		objectId,
		...fields
	});
