/* eslint-disable @typescript-eslint/ban-types */
import React, {ComponentType} from 'react';
import {CellProps} from 'react-table';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {IState} from '@src/store/modules';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';

/**
 * Конструктор для ячеек с информацией о нарушении. Добавляет replies в props компонента.
 *
 * @param component компонент ячейки
 * @param selector селектор для извлечения ответов
 * @constructor
 */
export const ProblemInfoCellWrap
	= <D extends object>(
		component: ComponentType<IProblemCellProps<D>>,
		selector: (s: IState) => Record<string, IProblemComment>
	) =>
	(props: CellProps<D>) => {
		const replies = useAppSelector(selector);
		const allProblemTags = useAppSelector(s => s.dictionaries.problemTags.byId);
		return React.createElement(component, {...props, replies, allProblemTags});
	};
