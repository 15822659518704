import React from 'react';
import classNames from 'classnames';

interface ITableRowProps {
	className?: string;
	style?: React.CSSProperties;
	role?: string;
	children?: React.ReactNode;
	clickable?: boolean;
	noBorder?: boolean;
}

const TableRow = ({className, style, role, children, clickable, noBorder}: ITableRowProps) => (
	<div
		className={classNames(
			'table2__row',
			{table2__row_clickable: clickable, 'table2__row_no-border': noBorder},
			className
		)}
		style={style}
		role={role}
	>
		{children}
	</div>
);

export default TableRow;
