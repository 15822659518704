import {Column} from 'react-table';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import WarrantyClaimInfoCell from '@src/components/tableCells/WarrantyClaimInfoMobileCell';
import CheckInfoMobileCell from '@src/components/tableCells/CheckInfoMobileCell';
import OwnerAcceptanceInfoMobileCell from '@src/components/tableCells/OwnerAcceptanceInfoMobileCell';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import {extractSpaceProblemCommentsByProblem} from '@src/store/modules/entities/space/selectors';
import InternalAcceptanceInfoMobileCell from '@src/components/tableCells/InternalAcceptanceInfoMobileCell';

export const mobileColumns: Array<Column<ISpaceEntity>> = [
	{
		id: 'info',
		Cell: CellWrap({
			check: CheckInfoMobileCell,
			'internal-acceptance': InternalAcceptanceInfoMobileCell,
			'owner-acceptance': OwnerAcceptanceInfoMobileCell,
			'warranty-claim': WarrantyClaimInfoCell,
			problem: ProblemInfoCellWrap(
				ProblemInfoMobileCell,
				extractSpaceProblemCommentsByProblem
			),
			inspection: InspectionInfoMobileCell
		})
	}
];
