import React from 'react';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {getObjectStats} from '@src/store/modules/dictionaries/objects/actions';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: ICheckEntity[];
	clearSelection?: () => void;
}

const SelectedEntitiesDeletion = ({
	selectedEntities,
	clearSelection
}: ISelectedEntitiesDeletionProps) => {
	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить нарушения/осмотры',
		'Вы действительно хотите удалить выбранные нарушения/осмотры?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			const objectsIds: string[] = [];
			for (const {type, data} of selectedEntities) {
				switch (type) {
					case 'problem': {
						const {objectId, id} = data;
						await dispatch(deleteProblem(objectId, id));
						objectsIds.push(data.objectId);
						break;
					}
					case 'inspection': {
						const {objectId, id} = data;
						await dispatch(deleteInspection(objectId, id));
						break;
					}
				}
			}
			if (objectsIds.length > 0) {
				await dispatch(getObjectStats(objectsIds));
			}
			if (clearSelection) {
				clearSelection();
			}
		}
	}, [selectedEntities, clearSelection]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete"/>
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
