import React from 'react';
import EntitiesFilters, {
	CreatedByFilter,
	StatusesFilter,
	ObjectsFilter,
	CreationDateFilter
} from '@src/components/EntitiesFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {extractTaskStatusesAsArray} from '@src/store/modules/dictionaries/taskStatuses/selectors';
import {TasksSearch} from '@src/pages/TasksPage/components/filters/TasksSearch';
import {extractTaskTypesAsArray} from '@src/store/modules/dictionaries/taskTypes/selectors';
import {extractTaskPrioritiesAsArray} from '@src/store/modules/dictionaries/taskPriorities/selectors';
import {TasksIntervalFilter} from '@src/pages/TasksPage/components/filters/TasksIntervalFilter';
import {RespUsersFilter} from '@src/components/EntitiesFilters/components/RespUsersFilter';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {useTasksPageDisplayMode} from '@src/core/hooks/queries/tasks';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';


export const TasksFilters = () => {
	const {state} = useEntitiesFiltersCtx<ITasksFiltersState>();
	const allStatuses = useAppSelector(extractTaskStatusesAsArray);
	const allTypes = useAppSelector(extractTaskTypesAsArray);
	const allPriorities = useAppSelector(extractTaskPrioritiesAsArray);
	const displayMode = useTasksPageDisplayMode();
	const isList = displayMode === TasksDisplayMode.LIST;

	return (
		<EntitiesFilters entity="tasks">
			{isList && (
				<div className="problems-page__search">
					<TasksSearch/>
				</div>
			)}

			<ObjectsFilter value={state?.objects}/>

			<StatusesFilter
				allStatuses={allTypes}
				statuses={state?.types}
				filterName="types"
				label="Тип задачи"
			/>

			<StatusesFilter
				allStatuses={allStatuses}
				statuses={state?.statuses}
				label="Статус задачи"
			/>

			<StatusesFilter
				allStatuses={allPriorities}
				statuses={state?.priorities}
				filterName="priorities"
				label="Приоритет"
			/>

			{isList && (
				<>
					<TasksIntervalFilter
						from={state?.taskIntervalStartFrom}
						to={state?.taskIntervalStartTo}
						label="Дата начала"
						filterNameFrom="taskIntervalStartFrom"
						filterNameTo="taskIntervalStartTo"
					/>

					<TasksIntervalFilter
						from={state?.taskIntervalEndFrom}
						to={state?.taskIntervalEndTo}
						label="Дата завершения"
						filterNameFrom="taskIntervalEndFrom"
						filterNameTo="taskIntervalEndTo"
					/>

					<CreationDateFilter
						from={state?.createdAtFrom}
						to={state?.createdAtTo}
					/>
				</>
			)}

			<RespUsersFilter respUsers={state?.respUsers}/>

			<CreatedByFilter
				objectId="all"
				createdBy={state?.createdBy}
			/>

		</EntitiesFilters>
	);
};