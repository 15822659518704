interface Args {
	objectId: string;
	stage: string;
	spaceId?: string;
}
/**
 * Формирует ссылку на список чек-листов
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param stage стадия объекта или помещения
 */
export const formCheckListsLink = ({objectId, spaceId, stage}: Args) =>
	(spaceId
		? `/objects/${objectId}/spaces/${spaceId}/check-lists/${stage}`
		: `/objects/${objectId}/check-lists/${stage}`);
