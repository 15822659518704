import {useQuery} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IGetOwnerAcceptanceStatusesResponse} from '@src/api/backend/ownerAcceptanceStatuses';

export const useOwnerAcceptanceStatuses = (): IGetOwnerAcceptanceStatusesResponse | undefined => {
	const {data} = useQuery<IGetOwnerAcceptanceStatusesResponse, IError>({
			queryKey: [...restDictionariesQueryKeys.ownerAcceptanceStatuses()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке статусов передач собственникам');
		}
	});
	return data;
};

export const ownerAcceptanceStatusesAsArray = (ownerAcptStatuses?: IGetOwnerAcceptanceStatusesResponse) =>
  ownerAcptStatuses?.allIds.map((ownerAcptId: string) => ownerAcptStatuses?.byId[ownerAcptId]) || [];