import React, {memo, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {extractSpaceOwnersAsArray} from '@src/store/modules/dictionaries/spaceOwners/selectors';

interface ISpaceOwnersFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpaceOwnersFilterMobile = memo(
	({objectId, value, entity, onChange}: ISpaceOwnersFilterMobileProps) => {
		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');

		const spaceOwners = useAppSelector(extractSpaceOwnersAsArray);
		const elements = useMemo(() => spaceOwners.map(spaceOwner => ({id: spaceOwner.id, name: spaceOwner?.name})), [spaceOwners]);
		const filteredElements = useMemo(() => flatFilter(elements, 'name', search), [search, spaceOwners]);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'spaceowners');

		const handleChange = (v: string[] | undefined) => {
			onChange({spaceOwners: v});
		};

		return (
			<MobileFilter
				label="Собственник"
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
					/>
				)}
			/>
		);
	}
);
