import * as types from './constants';
import {IChecksFiltersState, IChecksSortState} from './reducers';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';

export interface IChangeChecksTabIndexPayload {
	objectId: string;
	tabIndex: number;
}

/**
 * Изменяет индекс активной вкладки
 *
 * @param objectId id объекта
 * @param tabIndex индекс активной вкладки
 */
export const changeTabIndex = (objectId = 'all', tabIndex: number) => ({
	type: types.CHANGE_TAB_INDEX,
	payload: {
		objectId,
		tabIndex
	} as IChangeChecksTabIndexPayload
});

/**
 * Изменяет фильтр
 *
 * @param objectId id объекта
 * @param filters свойства фильтра
 */
export const changeFilters = (objectId = 'all', filters: IChecksFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId,
		filters
	} as IChangeFiltersPayload<IChecksFiltersState>
});

/**
 * Очищает фильтр
 *
 * @param objectId id объекта
 */
export const clearFilters = (objectId = 'all') => ({
	type: types.CLEAR_FILTERS,
	payload: {objectId} as IClearFiltersPayload
});

/**
 * Изменяет сортировку
 *
 * @param objectId id объекта
 * @param sort параметры сортировки
 */
export const changeSort = (objectId = 'all', sort: IChecksSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		objectId,
		sort
	} as IChangeSortPayload<IChecksSortState>
});

/**
 * Изменяет количество элементов на странице
 *
 * @param objectId id объекта
 * @param pageSize количество элементов на странице
 */
export const changePageSize = (objectId = 'all', pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {
		objectId,
		pageSize
	} as IChangePageSizePayload
});
