import React, {Dispatch, SetStateAction, useCallback, ChangeEvent} from 'react';
import {IEditableSpaceStatusesSetAction} from '@src/core/hooks/states/useSpaceStatusesSetState';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {IExtendedSpaceStatus} from '../../interfaces/IExtendedSpaceStatus';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {NameField} from '../fields/NameField';
import {ColorField} from '../fields/ColorField';
import {StatusTransitionsField} from '../fields/StatusTransitionsField';
import {StatusAutoTransitionField} from '../fields/StatusAutoTransitionField';
import {addWarningToast} from '@src/utils/toasts';
import {DefaultField} from '../fields/DefaultField';

interface IEditableSpaceStatusDialogProps {
	status: IExtendedSpaceStatus;
	statuses?: ISpaceStatus[];
	setEdited: Dispatch<SetStateAction<string[]>>;

	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;

	updateStatus: (index: number) => Promise<ISpaceStatus | undefined>;

	isOpen: boolean;
	onClose: () => void;
}

export const EditableSpaceStatusDialog = ({
	status, statuses, setEdited, editingDispatch, updateStatus, isOpen, onClose
}: IEditableSpaceStatusDialogProps) => {
	const handleChangeName = useCallback((name: string) => {
		editingDispatch({
			type: 'update-array-item',
			field: 'statuses',
			index: status.index,
			value: {...status, name}
		});
	}, [status]);

	const handleChangeColor = useCallback((color: string) => {
		editingDispatch({
			type: 'update-array-item',
			field: 'statuses',
			index: status.index,
			value: {...status, color}
		});
	}, [status]);

	const handleChangeTransitions = useCallback((transitions: string[]) => {
		editingDispatch({
			type: 'update-array-item',
			field: 'statuses',
			index: status.index,
			value: {...status, transitions}
		});
	}, [status]);

	const handleChangeAutoTransition = useCallback((autoTransitionTo: string) => {
		editingDispatch({
			type: 'update-array-item',
			field: 'statuses',
			index: status.number,
			value: {...status, autoTransitionTo}
		});
	}, [status]);

	const handleChangeDefault = useCallback(
		(value: ChangeEvent<HTMLInputElement>) => {
			const hasDefault = statuses?.find(val => val.default);

			if (!hasDefault || status.default) {
				editingDispatch({
					type: 'update-array-item',
					field: 'statuses',
					index: status.index,
					value: {...status, default: value.target.checked}
				});
			} else {
				addWarningToast('Статус по умолчанию может быть только один');
			}
		}, [status, statuses]
	);

	const handleSave = useCallback(async () => {
		const res = await updateStatus(status.index);
		if (res) {
			setEdited(s => s.filter(item => item !== status.id));
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: res
			});
			onClose();
		}
	}, [updateStatus, status.index, status.id, onClose]);

	const handleCancel = useCallback(() => {
		if (status.isLocal) {
			editingDispatch({
				type: 'delete-array-item', field: 'statuses', index: [status.index]
			});
		} else if (statuses?.[status.index]) {
			editingDispatch({
				type: 'update-array-item',
				field: 'statuses',
				index: status.index,
				value: statuses[status.index]
			});
		}
		setEdited(s => s.filter(item => item !== status.id));
		onClose();
	}, [status]);

	return (
		<Dialog
			className={{body: 'editable-space-statuses__status-mobile__dialog'}}
			isOpen={isOpen}
			onRequestClose={onClose}
			fullScreenOnTablet
			title={status.isLocal ? 'Создание статуса' : 'Редактирование статуса'}
			footer={(
				<ActionButtons className="editable-space-statuses__status-btns">
					<Button
						className="editable-space-statuses__status-btns__cancel"
						label="Отменить"
						type="cancel"
						onClick={handleCancel}
					/>
					<Button
						className="editable-space-statuses__status-btns__save"
						label="Сохранить"
						type="accent-blue"
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
		>
			<NameField
				value={status.name}
				onChange={handleChangeName}
			/>
			<ColorField
				value={status.color}
				onChange={handleChangeColor}
			/>
			<StatusTransitionsField
				status={status}
				statuses={statuses}
				onChange={handleChangeTransitions}
				isMobile
			/>
			<StatusAutoTransitionField
				status={status}
				statuses={statuses}
				onChange={handleChangeAutoTransition}
				isMobile
			/>
			<DefaultField
				value={status.default}
				onChange={handleChangeDefault}
			/>
		</Dialog>
	);
};