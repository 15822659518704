import React from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractWarrantyClaimsStatusesAsArray} from '@src/store/modules/dictionaries/warrantyClaimStatuses/selectors';
import StatusesFilterPage from '../StatusesFilterPage';

const WClaimStatusesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();
	const statuses = useAppSelector(extractWarrantyClaimsStatusesAsArray);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={statuses}
		/>
);
};

export default WClaimStatusesFilterPage;
