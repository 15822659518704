import {combineReducers} from 'redux';
import ownerAcceptances from './ownerAcceptances/reducers';
import check from './check/reducers';
import checks from './checks/reducers';
import problems from './problems/reducers';
import problem from './problem/reducers';
import inspections from './inspections/reducers';
import spaces from './spaces/reducers';
import space from './space/reducers';
import warrantyClaims from './warrantyClaims/reducers';
import statistics from './statistics/reducers';
import manage from './manage';
import {tasks} from './tasks/reducers/index';
import {internalAcceptance} from './internalAcceptance/reducers';
import {internalAcceptances} from './internalAcceptances/reducers';
import {structures} from './structures/reducers';
import {structure} from './structure/reducers';
import {objects} from './objects/reducers';
import {workAcceptances} from './workAcceptances/reducers';
import offlineJournal from './offlineJournal/reducers';
import {contracts} from './contracts/reducers';
import {documents} from './documents/reducers';
import {legals} from './legals/reducers';

export default combineReducers({
	ownerAcceptances,
	check,
	checks,
	contracts,
	problems,
	problem,
	tasks,
	inspections,
	spaces,
	space,
	warrantyClaims,
	statistics,
	manage,
	internalAcceptance,
	internalAcceptances,
	structures,
	structure,
	objects,
	workAcceptances,
	offlineJournal,
	documents,
	legals
});
