import {testProblemRestrictions} from '@src/utils/testRestrictions';
import {useMemo} from 'react';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import useAppSelector from '@src/core/hooks/useAppSelector';

export interface IPermissions {
	canViewComments?: boolean;
	canViewReplies?: boolean;
	canAddComments?: boolean;
	canAddReplies?: boolean;
}

/**
 * Возвращает полномочия для списка комментариев к нарушению
 *
 * @param objectId id объекта
 * @param problem нарушение
 */
export const useProblemCommentsPermissions = (
	objectId: string,
	problem?: IProblem
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canViewComments: testProblemRestrictions(
				user.id,
				user.roles,
				'problemCommentsView',
				objectId,
				problem?.createdBy,
				problem?.respUsers
			),
			canViewReplies: testProblemRestrictions(
				user.id,
				user.roles,
				'problemRepliesView',
				objectId,
				problem?.createdBy,
				problem?.respUsers
			),
			canAddComments: testProblemRestrictions(
				user.id,
				user.roles,
				'problemCommentsAdd',
				objectId,
				problem?.createdBy,
				problem?.respUsers
			),
			canAddReplies: testProblemRestrictions(
				user.id,
				user.roles,
				'problemRepliesAdd',
				objectId,
				problem?.createdBy,
				problem?.respUsers
			)
		};
	}, [objectId, problem, user]);
};
