import {Reducer, useReducer} from 'react';
import {
	errorsFns,
	IEditableProblemCommentAction,
	IEditableProblemCommentState,
	init
} from './state';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';

export const useEditableProblemCommentState = (comment?: IProblemComment) =>
	useReducer<
		Reducer<IEditableProblemCommentState, IEditableProblemCommentAction>,
		IProblemComment | undefined
	>(createReducer(init, errorsFns), comment, init);
