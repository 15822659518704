import React from 'react';
import {EntityGridItem, PhotoFeed} from '@tehzor/ui-components';

interface IMobileImagesProps {
	images: string[];

	onClick?: (index: number) => void;
}

const MobileImages = ({images, onClick}: IMobileImagesProps) => (
	<EntityGridItem
		className={{content: 'inspection-page__m-images-content'}}
		icon={<i className="tz-photo-20"/>}
		label="Фото"
		fullRow
	>
		<PhotoFeed
			className={{root: 'inspection-page__m-images', photo: 'inspection-page__m-image'}}
			data={images}
			onClick={onClick}
		/>
	</EntityGridItem>
);

export default MobileImages;
