import * as types from './constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetObjectStagesResponse} from '@src/api/backend/objectStages';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {queryClient} from '@src/api/QueryClient';

export type IGetObjectStagesPayload = IGetObjectStagesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetObjectStagesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при получении стадий объекта');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает стадии объектов
 */
export const getObjectStages = () =>
	createApiAction<IGetObjectStagesResponse>(request, success, failure, () =>
		queryClient.fetchQuery(restDictionariesQueryKeys.objectStages(), {staleTime: Infinity}));
