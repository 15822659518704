import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingExportTemplate} from '@tehzor/tools/interfaces/exportTemplates/ISavingExportTemplate';
import {IAddExportTemplateResponse, makeExportTemplateAddRequest } from '@src/api/backend/exportTemplates/add';

export type IAddExportTempaltePayload = IAddExportTemplateResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddExportTemplateResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении шаблона');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет шаблон
 *
 * @param fields данные для добавления
 */
export const addExportTemplate = (fields: ISavingExportTemplate) =>
	createApiAction<IAddExportTemplateResponse>(request, success, failure, () =>
		makeExportTemplateAddRequest(fields));
