import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {extractTaskStatusesAsArray} from '@src/store/modules/dictionaries/taskStatuses/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const TasksStatusesFilterPage = () => {
	const {entity} = useStrictParams<{entity: string}>();
	const statuses = useAppSelector(extractTaskStatusesAsArray);

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={statuses}
			label="Статус задачи"
		/>
);
};
