import {Column} from 'react-table';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {WorkAcceptanceDateCell} from '@src/components/tableCells/WorkAcceptanceDateCell/WorkAcceptanceDateCell';
import {WorkAcceptanceStatusCell} from '@src/components/tableCells/WorkAcceptanceStatusCell';
import {WorkAcceptanceStructuresCell} from '@src/components/tableCells/WorkAcceptanceStructuresCell';

export const workAcceptanceDesktopColumns: Array<Column<IListWorkAcceptance>> = [
	{
		id: 'number',
		Header: '№, описание',
		accessor: row => `${row.description ? `${row.number}, ${row.description}` : row.number}`,
		width: 140,
		minWidth: 140
	},
	{
		id: 'status',
		Header: 'Статус',
		Cell: WorkAcceptanceStatusCell,
		width: 140,
		minWidth: 140
	},
	{
		id: 'category',
		Header: 'Вид работ, фронт',
		Cell: WorkAcceptanceStructuresCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'date',
		Header: 'Срок приёмки',
		Cell: WorkAcceptanceDateCell
	}
];
