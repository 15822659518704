import * as types from './constants';
import {IProblemsFiltersState, IProblemsSortState} from './reducers';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';

/**
 * Изменяет фильтр
 *
 * @param objectId id объекта
 * @param filters свойства фильтра
 */
export const changeFilters = (objectId = 'all', filters: IProblemsFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId,
		filters
	} as IChangeFiltersPayload<IProblemsFiltersState>
});

/**
 * Очищает фильтр
 *
 * @param objectId id объекта
 */
export const clearFilters = (objectId = 'all') => ({
	type: types.CLEAR_FILTERS,
	payload: {objectId} as IProblemsFiltersState
});

/**
 * Изменяет сортировку
 *
 * @param objectId id объекта
 * @param sort параметры сортировки
 */
export const changeSort = (objectId = 'all', sort: IProblemsSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		objectId,
		sort
	} as IChangeSortPayload<IProblemsSortState>
});

/**
 * Изменяет количество элементов на странице
 *
 * @param objectId id объекта
 * @param pageSize количество элементов на странице
 */
export const changePageSize = (objectId = 'all', pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {
		objectId,
		pageSize
	} as IChangePageSizePayload
});
