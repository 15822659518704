import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useMovingProblemsDialog} from '@src/components/MovingProblemsDialog/hooks/useMovingProblemsDialog';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';

interface IMoveSelectedProps {
	objectId: string;
	internalAcceptanceId: string;
	selectedEntities: IInternalAcceptanceEntity[];
	restrictions?: {[key: string]: boolean};
}

const SelectedProblemsMove = ({
	objectId,
	internalAcceptanceId,
	selectedEntities,
	restrictions
}: IMoveSelectedProps) => {
	const [movingDialog, openMoveDialog] = useMovingProblemsDialog(
		objectId,
		{internalAcceptanceId},
		selectedEntities,
		restrictions
	);

	const handleClick = useCallback(() => {
		openMoveDialog();
	}, [internalAcceptanceId, openMoveDialog]);

	return (
		<>
			<IconButton
				disabled={selectedEntities.some(i => i.type === 'inspection')}
				onClick={handleClick}
			>
				<i className="tz-long-arrow-24"/>
			</IconButton>

			{movingDialog}
		</>
	);
};

export default SelectedProblemsMove;
