import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import {useWarrantyClaimCombinedExport} from '../../hooks/useWarrantyClaimCombinedExport';

interface IExportSelectedProps {
	objectId: string;
	warrantyClaimId: string;
	selectedEntities: IWarrantyClaimEntity[];
}

const SelectedEntitiesExport = ({
	objectId,
	warrantyClaimId,
	selectedEntities
}: IExportSelectedProps) => {
	const [exportDialog, openExportDialog] = useWarrantyClaimCombinedExport(
		objectId,
		selectedEntities
	);

	const handleClick = useCallback(() => {
		openExportDialog(warrantyClaimId);
	}, [warrantyClaimId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-export-24"/>
			</IconButton>

			{exportDialog}
		</>
	);
};

export default SelectedEntitiesExport;
