import React from 'react';
import './EntityInfoLink.less';
import classNames from 'classnames';
import {LinkButton} from '../../../../buttons';
import {ILinkButtonProps} from '../../../../buttons/LinkButton';

type IEntityInfoLinkProps = Omit<
	ILinkButtonProps,
	'leftIcon' | 'leftIconClassName' | 'rightIcon' | 'rightIconClassName'
>;

export const EntityInfoLink = ({className, labelClassName, ...rest}: IEntityInfoLinkProps) => (
	<LinkButton
		{...rest}
		className={classNames('entity-info-link', className)}
		labelClassName={classNames('entity-info-link__label', labelClassName)}
		leftIconClassName="entity-info-link__icon-wrap"
		leftIcon={<i className={classNames('tz-external-link-16', 'entity-info-link__icon')}/>}
	/>
);