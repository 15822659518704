import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {
	CategoriesSetFilterMobile,
	ProblemStatusesFilterMobile
} from '@src/components/MobileEntitiesFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {CheckListsFilterMobile} from '@src/components/MobileEntitiesFilters/components/CheckListsFilterMobile';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {CheckListStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CheckListStatuses';
import {extractCheckListsAsArrayForWorkAcceptances} from '@src/store/modules/dictionaries/checkLists/selectors';
import {IStructuresFiltersState} from '@src/store/modules/settings/pages/structures/reducers/byPage';
import {StructureTypesFilterMobile} from '@src/components/MobileEntitiesFilters/components/StructureTypesFilterMobile';
import {WorkAcceptanceStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/WorkAcceptanceStatusesFilterMobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const StructuresFiltersPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};
	const checkLists = useAppSelector(s => extractCheckListsAsArrayForWorkAcceptances(s, objectId));
	const {state, isStateChanged, change, mobileClear, apply}
		= useEntitiesFiltersCtx<IStructuresFiltersState>();

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			goBack();
		}
	}, [canGoBack, apply]);

	useAppHeader(
		{
			title: 'Фильтры структур',
			showBackBtn: true,
			mobileRightButtons: isCleanable<IStructuresFiltersState>(state) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[state, objectId, mobileClear]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<StructureTypesFilterMobile
				objectId={objectId}
				value={state?.types}
				entity={entity}
				onChange={change}
			/>

			<ProblemStatusesFilterMobile
				objectId={objectId}
				value={state?.problemStatuses}
				entity={entity}
				onChange={change}
				label="Статусы нарушений"
				filterName="problemStatuses"
			/>

			<CheckListsFilterMobile
				objectId={objectId}
				checkLists={checkLists}
				value={state?.checkListIds}
				entity={entity}
				onChange={change}
			/>

			<CheckListStatusesFilterMobile
				objectId={objectId}
				value={state?.checkListStatuses}
				entity={entity}
				onChange={change}
			/>

			<CategoriesSetFilterMobile
				objectId={objectId}
				value={state?.categories}
				entity={entity}
				onChange={change}
			/>

			<WorkAcceptanceStatusesFilterMobile
				objectId={objectId}
				value={state?.workAcceptanceStatuses}
				entity={entity}
				onChange={change}
			/>
		</FilterPage>
	);
};
