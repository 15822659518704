import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IState} from '@src/store/modules';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetOwnerAcceptancesResponse,
	requestOwnerAcceptances
} from '@src/api/backend/ownerAcceptances';
import checkLoaded from '@tehzor/tools/core/checkLoaded';

export type IGetLatestUserOwnerAcptsPayload = IGetOwnerAcceptancesResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetOwnerAcceptancesResponse) => ({
		type: types.GET_SUCCESS,
		payload: response
	});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке последних приёмок');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список последних приёмок текущего пользователя
 */
export const getLatestUserOwnerAcceptances = () =>
	checkLoaded<IState, IGetOwnerAcceptancesResponse, ApiAction>(
		s => s.dictionaries.latestUserOwnerAcceptances,
		createApiAction<IGetOwnerAcceptancesResponse>(request, success, failure, () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestOwnerAcceptances({createdAtFrom: date}, {createdAt: false}, 0, 100);
		}),
		undefined,
		true
	);
