import React from 'react';
import {EntityGrid, EntityGridItem, Plate} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import Status from './Status';
import IndicatorsSelect from './IndicatorsSelect';
import Indicators from './Indicators';
import Area from './Area';
import SpaceOwners from './SpaceOwners';
import MobileDocuments from './Documents.mobile';
import ExpandableMobileInfo from '@src/components/ExpandableMobileInfo';
import AdditionalName from './AdditionalName';
import {TypeDecor} from './TypeDecor';
import {useEditableSpaceAttachmentsDialog} from '@src/components/EditableSpaceAttachmentsDialog/hooks/useEditableSpaceAttachmentsDialog';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {SpaceTasksLinksMobile} from '@src/pages/SpacePage/components/info/SpaceTasksLinks.mobile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceTasks} from '@src/store/modules/entities/space/selectors';

interface IMobileInfoProps {
	objectId: string;
	space: ISpace;
}

const statusIcon = <i className="tz-status-20" />;
const indicatorsIcon = <i className="tz-indicator-20" />;
const areaIcon = <i className="tz-area-20" />;
const spaceTypeIcon = <i className="tz-space-type-20" />;

const MobileInfo = ({objectId, space}: IMobileInfoProps) => {
	const [editingAttachmentsDialog, openEditAttachmentsDialog] = useEditableSpaceAttachmentsDialog(
		objectId,
		space.id,
		space
	);
	const permissions = useSpacesPermissions(objectId);
	const tasks = useAppSelector(extractSpaceTasks);

	return (
		<Plate>
			<EntityGrid withBorders>
				{space.altName ? <AdditionalName space={space} /> : null}

				<EntityGridItem
					icon={statusIcon}
					label="Статус"
					inline
					fullRow
				>
					<Status
						objectId={objectId}
						spaceId={space.id}
						value={space.status}
						permissions={permissions}
					/>
				</EntityGridItem>

				<EntityGridItem
					icon={indicatorsIcon}
					label="Индикаторы"
					buttons={
						<IndicatorsSelect
							objectId={objectId}
							spaceId={space.id}
							value={space.indicators}
						/>
					}
					fullRow
				>
					{space.indicators?.length ? (
						<Indicators
							objectId={objectId}
							value={space.indicators}
						/>
					) : null}
				</EntityGridItem>
			</EntityGrid>

			<ExpandableMobileInfo>
				<EntityGrid withBorders>
					{space.plannedArea || space.actualArea ? (
						<EntityGridItem
							label="Площадь"
							fullRow
							icon={areaIcon}
						>
							<Area
								planned={space.plannedArea}
								actual={space.actualArea}
							/>
						</EntityGridItem>
					) : null}

					{space.typeDecoration ? (
						<EntityGridItem
							label="Тип отделки"
							fullRow
							icon={spaceTypeIcon}
						>
							<TypeDecor name={space.typeDecoration} />
						</EntityGridItem>
					) : null}

					<SpaceOwners
						objectId={objectId}
						spaceId={space.id}
					/>

					<MobileDocuments
						space={space}
						openDialog={openEditAttachmentsDialog}
						canEdit={permissions.canEdit}
					/>
					{tasks.length > 0 && <SpaceTasksLinksMobile tasks={tasks} />}
				</EntityGrid>
			</ExpandableMobileInfo>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default MobileInfo;
