import {createSelector} from 'reselect';
import {getInitialStateForPage} from '../reducers/lists';
import {IState} from '@src/store/modules';

/**
 * Извлекает данные для списка структур конкретного объекта
 */
export const extractStructuresListData = createSelector(
	(state: IState) => state.entities.structures.lists,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(data, objectId) => data[objectId] || getInitialStateForPage()
);

/**
 * Извлекает структуры в виде массива
 */
export const extractStructuresListAsArray = createSelector([extractStructuresListData], data =>
	data.allIds.map(id => data.byId[id]));

/**
 * Извлекает статус загрузки
 */
export const extractStructuresListLoadingState = createSelector(
	[extractStructuresListData],
	data => !data.loaded
);

/**
 * Извлекает структуры с учетом фильтра в виде массива
 */
export const extractFiltredStructuresListAsArray = createSelector(
	[extractStructuresListAsArray, (s, _, structureIds?: string[]) => structureIds],
	(data, structureIds) => data.filter(str => structureIds && structureIds.includes(str.id))
);
