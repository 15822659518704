import React from 'react';
import './HistoryList.less';
import {useGroupedHistories} from './hooks/useGroupedHistories';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import {Header} from './components/Header';
import {UniversalHistoryItem} from './components/UniversalHistoryItem';
import {convertClassNames} from '../../../utils/convertClassNames';
import classNames from 'classnames';

const defaultDateOptions = {locale: ru};

interface IHistoryListProps {
	className?:
		| string
		| {
		root?: string;
		group?: string;
		date?: string;
		items?: string;
		item?: string;
		transition?: string;
		block?: string;
		arrow?: string;
	};
	data: IHistoryDifference[];
	entityName: string;
	dateOptions?: Parameters<typeof format>[2];
}

export const HistoryList = ({data, entityName, dateOptions = defaultDateOptions, className}: IHistoryListProps) => {
	const grouped = useGroupedHistories(data);
	const classes = convertClassNames(className);

	return (
		<div className={classNames('history-list', classes.root)}>
			{grouped.map(([date, histories], index) => (
				<div
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					className={classNames('history-list__group', classes.group)}
				>
					{date !== -1 ? (
						<div className={classNames('history-list__date', classes.date)}>
							{format(date, 'dd MMMM yyyy', dateOptions)}
						</div>
					) : null}

					<div className={classNames('history-list__items', classes.items)}>
						{histories.map((change, historyIndex) => (
							// eslint-disable-next-line react/no-array-index-key
							<React.Fragment key={historyIndex}>
								<Header
									entityName={entityName}
									change={change}
									dateOptions={dateOptions}
									isLast={index === grouped.length - 1 && historyIndex === histories.length - 1}
								/>

								{change.data?.map((item, changeIndex) => (
									<UniversalHistoryItem
										// eslint-disable-next-line react/no-array-index-key
										key={changeIndex}
										data={item}
										className={{
											root: classes.item,
											transition: classes.transition,
											block: classes.block,
											arrow: classes.arrow
										}}
									/>
								))}
							</React.Fragment>
						))}
					</div>
				</div>
			))}
		</div>
	);
};

HistoryList.displayName = 'HistoryList';
