import React from 'react';
import MobileInfo from './info/Info.mobile';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useViewer} from '@src/core/hooks/imagesViewers/desktop/useViewer';
import {MobileLinks} from './links/MobileLinks';

interface IMobileProps {
	task?: IEnrichedTask;
	loading?: boolean;
}

const Mobile = ({task, loading}: IMobileProps) => {
	const [imagesAttachments] = useSplitAttachments(task);
	const images = useAttachmentsImages(imagesAttachments);
	const [imagesViewer, openImage] = useViewer(images);

	return (
		<LoadingPanel active={loading}>
			<div className="page-cont task-view-page">
				{task !== undefined && (
					<>
						<MobileLinks task={task}/>
						<Plate className="task-view-page__m-plate task-view-page__m-info">
							<MobileInfo
								task={task}
								images={images}
								onImageClick={openImage}
							/>
						</Plate>
					</>
				)}
				{imagesViewer}
			</div>
		</LoadingPanel>
	);
};

export default Mobile;
