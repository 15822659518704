import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';

export const Menu = () => {
	const {pushPath} = useChangePath();
	const {canEdit} = useCategoriesSetPermissions();
	const goToMigration = () => {
		pushPath('/manage/categories-sets/migration');
	};
	const items = [];

	if (canEdit) {
		items.push(
			<MenuItem
				key="migration"
				icon={<i className="tz-history-24"/>}
				onClick={goToMigration}
			>
				Миграция видов работ
			</MenuItem>
		);
	}

	return items.length ? <IconMenu>{items}</IconMenu> : null;
};
