import React, {useCallback, useState} from 'react';
import EditableProblemDialog from '../EditableProblemDialog';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';

export function useEditableProblemDialog(
	objectId: string,
	problem: IPreparedProblem
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<EditableProblemDialog
			objectId={objectId}
			problem={problem}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
