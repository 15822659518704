import {useMemo} from 'react';
import {formOwnerAcceptanceLink} from '@tehzor/tools/utils/links/formOwnerAcceptanceLink';
import {useOwnerAcceptancesBreadcrumbs} from './useOwnerAcceptancesBreadcrumbs';
/**
 * Хук для хлебных крошек от "всех объектов" до передачи собственнику
 *
 * @param objectId id конечного объекта
 * @param ownerAcceptanceId id передачки
 * @param number номер помещения
 */
export function useOwnerAcceptanceBreadcrumbs(
	objectId: string,
	ownerAcceptanceId: string,
	number: number | undefined
) {
	const res = useOwnerAcceptancesBreadcrumbs(objectId);
	return useMemo(
		() =>
			res.concat({
				label: number ? `Передача собственнику №${number}` : 'Передача собственнику',
				url: formOwnerAcceptanceLink(objectId, ownerAcceptanceId),
				inactive: true
			}),
		[objectId, ownerAcceptanceId, res]
	);
}
