import {Column} from 'react-table';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell/InspectionInfoMobileCell';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import {extractCheckProblemCommentsByProblem} from '@src/store/modules/entities/check/selectors';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';

export const mobileColumns: Array<Column<ICheckEntity>> = [
	{
		id: 'info',
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(
				ProblemInfoMobileCell,
				extractCheckProblemCommentsByProblem
			),
			inspection: InspectionInfoMobileCell
		})
	}
];
