import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetPlansResponse, requestPlans} from '@src/api/backend/plans';
import {queryClient} from '@src/api/QueryClient';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {getCachedPlans} from '@src/store/persistentStorage/offlineActions/plans';

export interface IGetPlansPayload extends IGetPlansResponse {
	objectIds?: string[];
}

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetPlansResponse, objectIds?: string[]) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectIds
	}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке планов');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

export const getPlansActions = {request, success, failure};
/**
 * Получает список планов
 *
 * @param objectIds id объектов
 * @param planIds ids планов
 */
export const getPlans =
	(objectIds?: string[], planIds?: string[]): AppThunkAction<Promise<IGetPlansResponse>> =>
	(dispatch, getState) => {
		const state = getState();
		return dispatch(
			createApiAction<IGetPlansResponse>(
				request,
				res => success(res, objectIds),
				failure,
				async () => {
					if (state.settings.offlineMode.available && !state.offlineMode.networkStatus) {
						return getCachedPlans(objectIds, planIds);
					}

					return queryClient.fetchQuery({
						queryKey: plansQueryKeys.list(objectIds),
						queryFn: () => requestPlans(objectIds, planIds),
						staleTime: Infinity
					});
				}
			)
		);
	};
