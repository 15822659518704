import {wsConnector} from '../wsConnector';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

export type IDeleteCheckListResponse = ICheckList;

/**
 * Удаляет чек-лист
 *
 * @param id id чек-листа
 */
export const makeCheckListDeleteRequest = (id: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteCheckListResponse>('deleteCheckList', {id});
