import {IState} from '@src/store/modules';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {requestSpaceTypeDecorationSet} from '@src/api/backend/spaceTypeDecorationSets/getSet';

export type IGetSpaceTypeDecorationPayload = ISpaceTypeDecorationSet;

const request = (decorationSetId: string) => ({
	type: types.GET_REQUEST,
	payload: {decorationSetId}
});

const success = (response: ISpaceTypeDecorationSet) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке набора типов отделки');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает набор типов отделки помещений по Id
 */
export const getSpaceTypeDecorationSetById
	= (id: string): AppThunkAction<Promise<ISpaceTypeDecorationSet>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<ISpaceTypeDecorationSet>(
			() => request(id),
			success,
			failure,
			() => requestSpaceTypeDecorationSet(id)
		);

		return state.entities.spaceTypeDecorationSet?.data?.id === id
			? dispatch(
					checkExpiration<IState, ISpaceTypeDecorationSet, ApiAction>(
						s => s.entities.spaceTypeDecorationSet,
						apiAction
					)
			  )
			: dispatch(apiAction);
	};
