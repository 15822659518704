import React from 'react';
import './StatusChange.less';
import classNames from 'classnames';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';

interface IStatusChangeProps extends IChangeComponentProps {
	value: {
		name: string;
		color: string;
	};
}

export const StatusChange = ({className, style, value}: IStatusChangeProps) => (
	<div
		className={classNames('history-status-change', className)}
		style={style}
	>
		<div
			className="history-status-change__status-container"
			style={{backgroundColor: `${value.color}`}}
		>
			{value.name ? value.name : ''}
		</div>
	</div>
);

StatusChange.displayName = 'StatusChange';
