import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

/**
 * Возвращает полномочия для текущего пользователя
 */
export const useNavPermissions = () => {
	const roles = useAppSelector(s => s.auth.profile.roles);

	return useMemo(
		() =>
			roles
				? {
						checksView: hasPermission(roles, 'checksView'),
						internalAcceptancesView: hasPermission(roles, 'internal-acceptances-view'),
						ownerAcceptancesView: hasPermission(roles, 'owner-acceptances-view'),
						problemsView: hasPermission(roles, 'problemsView'),
						inspectionsView: hasPermission(roles, 'inspectionsView'),
						workAcceptancesView: hasPermission(roles, 'work-acceptances-view'),
						warrantyClaimsView: hasPermission(roles, 'warranty-claims-view'),
						legalsView: hasPermission(roles, 'legals-view'),
						statsView: hasPermission(roles, 'statisticsView'),
						documentsView: hasPermission(roles, 'documents-view'),
						checkListsEdit: hasPermission(roles, 'check-lists-edit'),
						workingGroupEdit: hasPermission(roles, 'working-groups-administration'),
						problemTagsSetsEdit: hasPermission(
							roles,
							'problem-tags-sets-administration'
						),
						spaceTypeDecorationEdit: hasPermission(
							roles,
							'space-type-decoration-sets-edit'
						),
						categoriesSetView: hasPermission(roles, 'categories-administration'),
						spaceStatusesSetsView: hasPermission(
							roles,
							'spaceStatusesSetsAdministration'
						),
						tasksView: hasPermission(roles, 'tasks-view'),
						exportTemplatesEdit: hasPermission(roles, 'export-templates-edit'),
						contractsView: hasPermission(roles, 'contracts-administration')
				  }
				: {},
		[roles]
	);
};
