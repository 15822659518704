import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {
	IEditTaskStatusResponse,
	makeTaskStatusEditRequest
} from '@src/api/backend/task/editTaskStatus';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {queryClient} from '@src/api/QueryClient';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';

export type IEditTaskStatusPayload = IEditTaskStatusResponse;

const request = () => ({type: types.EDIT_TASK_STATUS_REQUEST});

const success = (response: IEditTaskStatusPayload) => ({
	type: types.EDIT_TASK_STATUS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса задачи');
	return {
		type: types.EDIT_TASK_STATUS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет статуса задачи
 *
 * @param taskId id задачи
 * @param statusId id статуса
 */
export const editTaskStatus = (taskId: string, statusId: TaskStatusId) =>
	createApiAction<IEditTaskStatusResponse>(request, success, failure, async () => {
		const data = await makeTaskStatusEditRequest(taskId, statusId);
		void queryClient.invalidateQueries(tasksQueryKeys.all());
		return data;
	});
