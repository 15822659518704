import React, {useCallback} from 'react';
import ProblemsPage from './ProblemsPage';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/problems/actions';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {changeOffset, clearExpiration} from '@src/store/modules/entities/problems/actions';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

/**
 * Обёртка над страницей нарушений
 * необходима для того чтобы при переходе от одной страницы к другой отрабатывали все методы жизненного цикла
 *
 * @constructor
 */
const ProblemsPageWrap = () => {
	const dispatch = useAppDispatch();
	const {objectId} = useStrictParams<{objectId: string}>();

	const {filters} = useAppSelector(s => extractProblemsPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: IProblemsFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId, dispatch]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId, dispatch]);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			objectId={objectId}
			onApply={applyFilters}
			onClear={onClear}
			clearExpiration={clearExpiration}
		>
			<ProblemsPage key={objectId}/>
		</EntitiesFiltersProvider>
	);
};

export default ProblemsPageWrap;
