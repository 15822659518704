import React, {useMemo} from 'react';
import '../ObjectsPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Tree} from '@src/utils/tree';
import {IObjectTree} from '@src/interfaces/IObjectTree';
import {desktopColumns} from './table/columns.desktop';
import {mobileColumns} from './table/columns.mobile';
import {ObjectsTable} from './table/ObjectsTable';
import {Plate} from '@tehzor/ui-components';
import {ObjectsControls} from './controls/ObjectsControls';

interface IListPageProps {
	text: string;
	treeObjects: Array<Tree<IObjectTree>>;
	search?: string | undefined;
}

/**
 * Страница списка объектов
 */
export const ListPage = ({text, treeObjects, search}: IListPageProps) => {
	const isDesktop = useIsDesktop();

	const columns = useMemo(() => (isDesktop ? desktopColumns : mobileColumns), [isDesktop]);

	return (
		<div className="objects-page">
			<ObjectsControls text={text}/>

			<Plate
				className="objects-page__plate"
				withoutPadding
			>
				{treeObjects && (
					<ObjectsTable
						columns={columns}
						treeObjects={treeObjects}
						hideHead={!isDesktop}
						filterValue={search}
					/>
				)}
			</Plate>
		</div>
	);
};
