import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IWarrantyClaimState, getInitialState} from './reducers';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

const extractWarrantyClaimData = (state: IState): IWarrantyClaimState =>
	state.entities.warrantyClaim || getInitialState();

/**
 * Возвращает данные помещения
 */
export const extractWarrantyClaim = createSelector(
	[extractWarrantyClaimData],
	warrantyClaim => warrantyClaim.data
);

/**
 * Извлекает нарушения гарантийного обращения
 */
export const extractWarrantyClaimProblems = createSelector([extractWarrantyClaimData], data =>
	data.problems.allIds.map(id => data.problems.byId[id]));

/**
 * Извлекает ответы на нарушения гарантийного обращения
 */
export const extractWarrantyClaimProblemReplies = createSelector([extractWarrantyClaimData], data =>
	data.problemReplies.allIds.map(id => data.problemReplies.byId[id]));

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractWarrantyClaimProblemCommentsByProblem = createSelector(
	[extractWarrantyClaimProblemReplies],
	replies =>
		replies.reduce<Record<string, IProblemComment>>((prev, item) => {
			if (!prev[item.problemId]) {
				prev[item.problemId] = item;
			}
			return prev;
		}, {})
);

/**
 * Извлекает сущности гарантийного обращения
 */
export const extractWarrantyClaimEntities = createSelector(
	[extractWarrantyClaimProblems],
	problems =>
		problems
			.map<IWarrantyClaimEntity>(item => ({
				id: `p_${item.id}`,
				type: 'problem',
				data: item
			}))
			.sort((a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0))
);
