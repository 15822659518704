import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddProblemResponse, makeProblemAddRequest} from '@src/api/backend/problem';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';

export type IAddProblemPayload = IAddProblemResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddProblemResponse, quietly?: boolean) => {
	if (!quietly) {
		addSuccessToast('Успех', 'нарушение успешно добавлено');
	}
	return {
		type: types.ADD_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении нарушения');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

export const addProblemActions = {request, success, failure};

/**
 * Добавляет нарушение
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 * @param fields данные нарушения
 */
export const addProblem = (
	objectId: string,
	links: IProblem['links'] | undefined,
	stage: ObjectStageIds | undefined,
	fields: ISavingProblem
) =>
	createApiAction<IAddProblemResponse>(request, success, failure, () =>
		makeProblemAddRequest(objectId, links, stage, fields));
