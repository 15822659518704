import React from 'react';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import MobileChecksExport from './ChecksExport.mobile';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

interface IMobileActionsProps {
	objectId?: string;
}

const ActionsMobile = ({objectId}: IMobileActionsProps) => {
	const permissions = useChecksPermissions(objectId);
	return (
		<>
			{permissions.canExport && <MobileChecksExport objectId={objectId}/>}
			<AppUpdateMenu/>
		</>
	);
};

export default ActionsMobile;
