import {requestGetCategories} from '@src/api/backend/categories';
import {useQueryClient} from '@tanstack/react-query';
import {categoriesQueryKeys} from '../keys';

export const useCategoriesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(categoriesQueryKeys.list(), {
		queryFn: requestGetCategories,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		cacheTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
