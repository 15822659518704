import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import {extractWarrantyClaimsData} from '@src/store/modules/entities/warrantyClaims/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/reducers';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import declination from '@tehzor/tools/utils/declination';

interface IClaimsPageHeaderProps {
	objectId?: string;
}

const ClaimsPageCounter = ({objectId = 'all'}: IClaimsPageHeaderProps) => {
	const claimsData = useAppSelector(s => extractWarrantyClaimsData(s, objectId));
	const {state} = useEntitiesFiltersCtx<IWarrantyClaimsFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const total = claimsData.total || 0;
	const text = `${isFiltersActive ? 'Найдено:' : 'Всего:'} ${total} ${declination(
		total,
		['гарантийное обращение', 'гарантийных обращения', 'гарантийных обращений']
	)}`;

	return (
		<PageHeader
			className="w-claims-page__header"
			title=""
			subTitle={text}
			wrapClassName="w-claims-page__header-wrap"
			leftSideClassName="w-claims-page__header-left"
			rightSideClassName="w-claims-page__header-right"
		/>
	);
};

export default ClaimsPageCounter;
