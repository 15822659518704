import {ITaskStatus} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {IEnrichedTaskForScheduler} from '../hooks/useEnrichedTasksForSchedule';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';

export interface IGroupTask extends ITaskStatus {
	count: number;
	taskTypeId: TaskTypeId;
	taskTypeName: string;
	taskTypeColor: string;
	tasks: IEnrichedTaskForScheduler[];
}

export const groupTasksByStatusAndType
	= (tasks: IEnrichedTaskForScheduler[]): IGroupTask[] => tasks.reduce<IGroupTask[]>((acc, task) => {
	const taskStatusId = task.status?.id;
	const taskTypeId = task.taskType?.id;

	const groupOfTask: IGroupTask | undefined = acc.find(
		el => el.id === taskStatusId
		&& el.taskTypeId === taskTypeId
	);

	if (!groupOfTask && task.status && task.taskType) {
		return [
			...acc,
			{
				...task.status,
				taskTypeId: task.taskType.id,
				taskTypeName:	task.taskType.name,
				taskTypeColor: task.taskType.color,
				tasks: [task],
				count: 1
			}
		];
	}

	if (groupOfTask) {
		groupOfTask.tasks = [...groupOfTask.tasks, task];
		groupOfTask.count++;
		return acc;
	}

	return acc;
}, []);
