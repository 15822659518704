import React, {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {
	changeFilters,
	clearFilters
} from '@src/store/modules/settings/pages/ownerAcceptances/actions';
import {changeOffset} from '@src/store/modules/entities/ownerAcceptances/actions';
import {OwnerAcceptancesPage} from './OwnerAcceptancesPage';
import {extractOwnerAcceptancesPageSettings} from '@src/store/modules/settings/pages/ownerAcceptances/selectors';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/reducers';
import {ActionsMobile} from './components/actions/Actions.mobile';

export const AllOwnerAcceptancesPageWrap = (props: Record<string, unknown>) => {
	const dispatch = useAppDispatch();
	useAppHeader({title: 'Передачи собственникам', mobileRightButtons: <ActionsMobile/>});

	const {filters} = useAppSelector(s => extractOwnerAcceptancesPageSettings(s, 'all'));

	const applyFilters = useCallback(
		(value: IOwnerAcceptancesFiltersState) => {
			dispatch(changeFilters('all', value));
			dispatch(changeOffset('all', 0));
		},
		[dispatch]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters('all'));
	}, [dispatch]);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
		>
			<OwnerAcceptancesPage
				key="all-ownerAcceptances"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};
