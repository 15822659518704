import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IInspectionStory} from '@tehzor/tools/interfaces/inspectionStories/IInspectionStory';

export type IGetInspectionStoriesResponse = INormalizedData<IInspectionStory>;

/**
 * Получает историю осмотра по id
 *
 * @param objectId id объекта
 * @param inspectionId id нарушения
 */
export const requestInspectionStories = (objectId: string, inspectionId: string) => {
	const params = {objectId, inspectionId};
	return wsConnector.sendAuthorizedRequest<IGetInspectionStoriesResponse>(
		'getInspectionStories',
		params
	);
};
