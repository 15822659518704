import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ICopyPlanResponse, makePlanCopyRequest} from '@src/api/backend/plan';
import {addErrorToast} from '@src/utils/toasts';

export interface ICopyPlanPayload extends ICopyPlanResponse {
	objectId: string;
}

const request = () => ({type: types.COPY_REQUEST});

const success = (response: ICopyPlanResponse, objectId: string) => ({
	type: types.COPY_SUCCESS,
	payload: {...response, objectId}
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при копировании плана');
	}
	return {
		type: types.COPY_FAILURE,
		payload: error
	};
};

/**
 * Копирует план
 *
 * @param objectId id объекта
 * @param planId данные плана
 * @param objectDestinationId id объекта назначения
 * @param copies количество копий
 */
export const copyPlan = (
	objectId: string,
	planId: string,
	objectDestinationId: string,
	copies: number
) =>
	createApiAction<ICopyPlanResponse>(
		request,
		res => success(res, objectDestinationId),
		failure,
		() => makePlanCopyRequest(objectId, planId, objectDestinationId, copies)
	);
