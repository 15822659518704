import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {ITaskStatus} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {ITaskPriority} from '@tehzor/tools/interfaces/tasks/ITaskPriority';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ITaskType} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {useTaskStatuses} from '@src/core/hooks/queries/taskStatuses';
import {useTaskTypes} from '@src/core/hooks/queries/taskTypes';

export interface IEnrichedTaskForScheduler
	extends Omit<
		ITask,
		| 'location'
		| 'taskPriority'
		| 'taskType'
		| 'respUsers'
		| 'activeGroup'
		| 'activeGroupLeader'
		| 'status'
		| 'attachments'
		| 'objectId'
		| 'createdAt'
		| 'createdBy'
		| 'modifiedAt'
		| 'modifiedBy'
	> {
	objectId: IObject;
	taskPriority?: ITaskPriority;
	taskType?: ITaskType;
	status?: ITaskStatus;
	respUsers?: IBriefUser[];
	activeGroup?: IWorkingGroup;
	activeGroupLeader?: IBriefUser;
	floor?: string;
	location?: ILocation;
	attachment?: IAttachment;
	createdAt?: string;
	createdBy?: IBriefUser;
	modifiedAt?: string;
	modifiedBy?: IBriefUser;
}

/**
 * Преобразовывает задачи в необходимый для вывода формат
 *
 * @param data массив задач
 */
export const useEnrichedTasksForScheduler = (
	data: ITask[],
	loading?: boolean
): IEnrichedTaskForScheduler[] => {
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);
	const workingGroupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);
	const taskPrioritiesMap = useAppSelector(s => s.dictionaries.taskPriorities.byId);
	const taskStatusesMap = useTaskStatuses()?.byId || {};
	const taskTypesMap = useTaskTypes()?.byId || {};

	return useMemo(
		() =>
			(!loading
				? data.map(item => ({
					...item,
					objectId: objectsMap[item.objectId],
					status: item.status ? taskStatusesMap[item.status] : undefined,
					taskType: item.taskType ? taskTypesMap[item.taskType] : undefined,
					taskPriority: item.taskPriority
						? taskPrioritiesMap[item.taskPriority]
						: undefined,
					respUsers: item.respUsers
						?.map(userId => usersMap[userId])
						.filter(user => user),
					activeGroup: item.activeGroup ? workingGroupsMap[item.activeGroup] : undefined,
					activeGroupLeader: item.activeGroupLeader ? usersMap[item.activeGroupLeader] : undefined,
					attachment: item.attachments
						? item.attachments.find(att => att.preview)
						: undefined,
					floor: item.floor ? item.floor : undefined,
					location: item.location ? item.location : undefined,
					createdBy: item.createdBy ? usersMap[item.createdBy] : undefined,
					createdAt: item.createdAt
						? format(item.createdAt, dateTimeCommaSeparatedFormat)
						: '',
					modifiedBy: item.modifiedBy ? usersMap[item.modifiedBy] : undefined,
					modifiedAt: item.modifiedAt
						? format(item.modifiedAt, dateTimeCommaSeparatedFormat)
						: ''
				}))
				: []),
		[data, loading, usersMap, objectsMap, taskStatusesMap, taskPrioritiesMap, taskTypesMap, workingGroupsMap]
	);
};
