import React, {useCallback} from 'react';
import './EntityTransfer.less';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {offlineModeTransferIcons} from '@src/core/offlineMode/utils/offlineModeTransferIcons';
import {offlineModeTransferColors} from '@src/core/offlineMode/utils/offlineModeTransferColors';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {EntityLoadingStatus, IconButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useQueryClient} from '@tanstack/react-query';

interface IEntityTransferProps {
	transferStatus?: OfflineDataTransferStatus;
	itemKey: string;
}

export const EntityTransfer = (props: IEntityTransferProps) => {
	const {transferStatus, itemKey} = props;
	const queryClient = useQueryClient();

	const reloadIcon = <i className="tz-reload-24"/>;
	const statusTitle
		= offlineModeTransferTitles[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];
	const statusIcon
		= offlineModeTransferIcons[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];
	const statusColor
		= offlineModeTransferColors[transferStatus || OfflineDataTransferStatus.SAVED_LOCAL];

	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const handleReload = useCallback(() => {
		const mutationCache = queryClient.getMutationCache();
		const currentMutation = mutationCache.find<
			unknown,
			unknown,
			Record<string, unknown> & {key: string},
			unknown
		>({
			predicate: mut => {
				const key = (mut.state.variables as Record<string, unknown> & {key: string}).key;
				return itemKey === key;
			}
		});
		if (currentMutation) {
			currentMutation.setState({
				...currentMutation.state,
				isPaused: true,
				status: 'loading',
				failureCount: 0,
				error: null,
				failureReason: null
			});
			void currentMutation.execute();
		}
	}, [itemKey, queryClient]);

	return (
		<div className="entity-tansfer">
			<EntityLoadingStatus
				title={statusTitle}
				icon={statusIcon}
				color={statusColor}
			/>

			{transferStatus === OfflineDataTransferStatus.TRANSFER_ERROR && (
				<IconButton
					disabled={!online}
					onClick={handleReload}
				>
					{reloadIcon}
				</IconButton>
			)}
		</div>
	);
};
