import React from 'react';
import {EntityLinkButton, TouchablePanel} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTaskLinks} from '@src/components/TaskLinks/hooks/useTaskLinks';

interface IMobileLinksProps {
	task: IEnrichedTask;
}

export const MobileLinks = (props: IMobileLinksProps) => {
	const {pushPath} = useChangePath();
	const {task} = props;

	const links = useTaskLinks(task);

	if (links.length < 1) {
		return null;
	}

	return (
		<TouchablePanel className="task-view-page__m-links">
			{links.map(item => (
				<EntityLinkButton
					key={item.id}
					className="task-view-page__m-link"
					leftIcon={item.icon}
					label={item.label}
					url={item.url}
					title={item.title}
					onClick={pushPath}
				/>
			))}
		</TouchablePanel>
	);
};
