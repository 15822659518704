import React, {useCallback, useMemo} from 'react';
import './ProblemComment.less';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import UserInfo from '../../various/UserInfo/UserInfo';
import classNames from 'classnames';
import splitAttachments from '@tehzor/tools/utils/splitAttachments';
import PhotoPreview from '../../photos/PhotoPreview';
import {format} from 'date-fns';
import {InlineButton} from '../../buttons';
import {Tree} from 'array-to-tree';
import Description from './components/Description';
import Children from './components/Children';
import {DownloadableFile} from '../../files';
import {convertClassNames} from '../../../utils/convertClassNames';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';

export interface IProblemCommentProps {
	className?: string | {
		root?: string;
		plate?: string;
	};
	style?: React.CSSProperties;
	data: Tree<IProblemComment>;
	parent?: IProblemComment;
	level?: number;
	hideTitle?: boolean;
	showAppeal?: boolean;
	isSelected?: (id: string) => boolean;
	controls?: (data: IProblemComment) => React.ReactNode;
	dateOptions?: Parameters<typeof format>[2];

	onSubCommentAdd?: (id: string) => void;
	onImageClick?: (id: string, index: number) => void;
}

const ProblemComment = (props: IProblemCommentProps) => {
	const {
		className,
		style,
		data,
		parent,
		level = 0,
		hideTitle,
		showAppeal,
		controls,
		isSelected,
		dateOptions,
		onSubCommentAdd,
		onImageClick
	} = props;

	const classes = convertClassNames(className);

	const [images, files] = useMemo(() => {
		if (data.attachments) {
			return splitAttachments(data.attachments);
		}
		return [[], []];
	}, [data.attachments]);

	const previews = useMemo(
		() =>
			images.reduce<string[]>((prev, item) => {
				if (item.preview) {
					prev.push(item.preview.url);
				}
				return prev;
			}, []),
		[images]
	);

	const createdAt = data.createdAt;
	let modifiedAt = data.modifiedAt;
	if (createdAt && modifiedAt && Math.abs(createdAt - modifiedAt) < 1000) {
		modifiedAt = undefined;
	}

	const handleSubCommentAdd = useCallback(() => {
		if (onSubCommentAdd) {
			onSubCommentAdd(data.id);
		}
	}, [onSubCommentAdd, data.id]);

	const handleImageClick = useCallback((i: number) => {
		if (onImageClick) {
			onImageClick(data.id, i);
		}
	}, [onImageClick, data.id]);

	return (
		<div
			className={classNames(
				'problem-comment',
				`problem-comment_${data.official ? 'official' : 'not-official'}`,
				`problem-comment_${level === 0 ? 'root' : 'nested'}`,
				classes.root
			)}
			style={style}
		>

			<div
				className={classNames(
					'problem-comment__plate',
					isSelected?.(data.id) && 'problem-comment__plate_selected',
					classes.plate
				)}
			>
				{data.official && data.number !== undefined && !hideTitle && (
					<div className="problem-comment__title">
						Ответ №
						{data.number}
					</div>
				)}

				<div
					className={classNames(
						'problem-comment__content',
						`problem-comment__content_${data.official ? 'official' : 'not-official'}`,
						`problem-comment__content_${level === 0 ? 'root' : 'nested'}`
					)}
				>
					<div className="problem-comment__header">
						<div className="problem-comment__header-left">
							<UserInfo
								className={{
									root: 'problem-comment__author',
									name: 'problem-comment__author-name',
									position: 'problem-comment__author-position'
								}}
								user={data.createdBy}
								avatarSize={level === 0 ? '40' : '34'}
								avatarColoring="text"
							/>
						</div>

						{controls !== undefined && (
							<div className="problem-comment__header-right">
								{controls(data)}
							</div>
						)}
					</div>

					{(!!data.description || showAppeal) && (
						<div className="problem-comment__desc">
							<Description
								data={data}
								parent={parent}
								showAppeal={showAppeal}
							/>
						</div>
					)}

					{!!data.attachments?.length && (
						<div className="problem-comment__attachments">
							{previews.length > 0 && (
								<div className="problem-comment__images">
									{previews.map((url, index) => (
										<PhotoPreview
											// eslint-disable-next-line react/no-array-index-key
											key={index}
											className="problem-comment__image"
											url={url}
											data={index}
											onClick={handleImageClick}
										/>
									))}
								</div>
							)}

							{files.length > 0 && (
								<div className="problem-comment__files">
									{files.map(file => (
										<div
											key={file.id}
											className="problem-comment__file-wrap"
										>
											<DownloadableFile
												className="problem-comment__file"
												{...file}
											/>
										</div>
									))}
								</div>
							)}
						</div>
					)}

					<div className="problem-comment__footer">
						{createdAt !== undefined && (
							<span className="problem-comment__date">
								{modifiedAt !== undefined && 'Создан: '}
								{format(createdAt, dateTimeFormat, dateOptions)}
							</span>
						)}
						{modifiedAt !== undefined && (
							<span className="problem-comment__date">
								Изменён:
								{' '}
								{format(modifiedAt, dateTimeFormat, dateOptions)}
							</span>
						)}

						{!data.official && onSubCommentAdd !== undefined && (
							<InlineButton
								className="problem-comment__comment-btn"
								labelClassName="problem-comment__comment-btn-label"
								type="accent"
								label="Комментировать"
								outerTagType="button"
								onClick={handleSubCommentAdd}
							/>
						)}

						{data.official && onSubCommentAdd !== undefined && (
							<div className="problem-comment__footer-row">
								<InlineButton
									labelClassName="problem-comment__comment-btn-label"
									type="accent"
									leftIcon={<i className="tz-comment"/>}
									label="Комментировать"
									outerTagType="button"
									onClick={handleSubCommentAdd}
								/>
							</div>
						)}
					</div>
				</div>
			</div>

			{level === 0 && !!data.children?.length && (
				<Children
					className={className}
					style={style}
					data={data}
					level={level}
					isSelected={isSelected}
					controls={controls}
					dateOptions={dateOptions}
					onSubCommentAdd={onSubCommentAdd}
					onImageClick={onImageClick}
				/>
			)}
		</div>
	);
};

export default ProblemComment;