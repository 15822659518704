import React from 'react';
import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import ICompanyContractor from '@tehzor/tools/interfaces/companies/ICompanyContractor';
import {ProblemsStatsChart} from './ProblemsStatsChart';
import {ContractorsStatsMap} from '@src/utils/statsAggregators/problemsByStatuses';
import {WorkerStats} from './WorkerStats';
import {sumStatsValues} from '../../../utils/sumStatsValues';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';
import {useNavigateProblems} from '@src/pages/StatisticsPage/hooks/useNavigateProblems';

interface IContractorStatsProps {
	contractor: ICompanyContractor;
	contractorStats?: ContractorsStatsMap['id'];
	users: string[];
	deletedUsers: string[];
}

export const ContractorStats = ({
	contractor,
	contractorStats,
	users,
	deletedUsers
}: IContractorStatsProps) => {
	const sourcesLoading = useAppSelector(extractSourcesLoading);
	const totalContractorCount = contractorStats
		? contractorStats.stats?.expired
			? sumStatsValues(contractorStats.stats) - contractorStats.stats?.expired
			: sumStatsValues(contractorStats.stats)
		: 0;
	const content = users.map(userId => (
		<WorkerStats
			key={userId}
			userId={userId}
			stats={contractorStats?.usersStats[userId]}
			level={1}
		/>
	));

	const deletedContent = deletedUsers.map(userId => (
		<WorkerStats
			key={userId}
			userId={userId}
			stats={contractorStats?.usersStats[userId]}
			level={1}
			isDeleted
		/>
	));

	const navigate = useNavigateProblems(
		{
			navProps: [
				{
					type: 'respUsers',
					prop: [...users, ...deletedUsers]
				}
			],
			page: 'building'
		},
		undefined
	);
	const handleClick = (status: string) => {
		void navigate(status);
	};

	return (
		<VerticalTabContent className="statistics-page__tabs-content-panel">
			<StatsTreePanel
				id={contractor.id}
				title={contractor.name}
				loading={sourcesLoading}
				showExpandBtnPlaceholder
				permanentContent={(
					<ProblemsStatsChart
						onClick={handleClick}
						stats={contractorStats?.stats}
						totalCount={totalContractorCount}
					/>
				)}
				hiddenContent={(
					<>
						{content}
						{deletedContent}
					</>
				)}
			/>
		</VerticalTabContent>
	);
};
