import * as types from './constants';
import {IStructuresFiltersState, IStructuresSortState} from './reducers/byPage';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';

export interface IChangeStructuresDisplayModePayload {
	objectId: string;
	displayMode: StructuresDisplayMode;
}

/**
 * Изменяет способ отображения страницы списка структур
 *
 * @param objectId id объекта
 * @param displayMode способ отображения
 */
export const changeDisplayMode = (objectId: string, displayMode: StructuresDisplayMode) => ({
	type: types.CHANGE_DISPLAY_MODE,
	payload: {
		objectId,
		displayMode
	} as IChangeStructuresDisplayModePayload
});

export const changeType = (objectId: string, type: string) => ({
	type: types.CHANGE_TYPE,
	payload: {objectId, type}
});

/**
 * Изменяет фильтр
 *
 * @param objectId id объекта
 * @param filters свойства фильтра
 */
export const changeFilters = (objectId: string, filters: IStructuresFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId,
		filters
	} as IChangeFiltersPayload<IStructuresFiltersState>
});

/**
 * Очищает фильтр
 *
 * @param objectId id объекта
 */
export const clearFilters = (objectId: string) => ({
	type: types.CLEAR_FILTERS,
	payload: {objectId} as IClearFiltersPayload
});

/**
 * Изменяет сортировку
 *
 * @param objectId id объекта
 * @param sort параметры сортировки
 */
export const changeSort = (objectId: string, sort: IStructuresSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		objectId,
		sort
	} as IChangeSortPayload<IStructuresSortState>
});

/**
 * Изменяет количество элементов на странице
 *
 * @param objectId id объекта
 * @param pageSize количество элементов на странице
 */
export const changePageSize = (objectId: string, pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {
		objectId,
		pageSize
	} as IChangePageSizePayload
});