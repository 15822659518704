import React from 'react';
import EntitiesFilters, {
	CreatedByFilter,
	// NumberFilter,
	ObjectsFilter,
	CreationDateFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/reducers';

interface IChecksFiltersProps {
	objectId?: string;
}

const ChecksFilters = ({objectId = 'all'}: IChecksFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IChecksFiltersState>();

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="checks"
		>
			{/* <NumberFilter
				value={state.number}
				placeholder="№ проверки"
			/> */}

			{objectId === 'all' && <ObjectsFilter value={state?.objects}/>}

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
			/>
		</EntitiesFilters>
	);
};

export default ChecksFilters;
