import declination from '@tehzor/tools/utils/declination';
import React, {memo, useMemo} from 'react';

interface ISpaceStatusesSetsPageHeaderProps {
	total: number;
}

const words = ['юрлицо', 'юрлица', 'юрлиц'];

export const LegalsPageHeader = memo(({total}: ISpaceStatusesSetsPageHeaderProps) => {
	const amount = useMemo(() => `Всего: ${total} ${declination(total, words)}`, [total]);

	return (
		<div className="legals__page-header">
			<p className="legals__page-header-amount">{amount}</p>
		</div>
	);
});
