import React from 'react';
import './OwnerAcceptanceInfoMobileCell.less';
import {TableRowSelect, StatusSelect} from '@tehzor/ui-components';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import OwnerAcceptanceInfo from '../OwnerAcceptanceInfo';
import OwnerAcceptanceStatusCell from '../OwnerAcceptanceStatusCell';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOwnerAcceptanceResolutionsAsArray} from '@src/store/modules/dictionaries/ownerAcceptanceResolutions/selectors';

import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editOwnerAcceptance} from '@src/store/modules/entities/ownerAcceptance/actions';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {useAsyncFn} from 'react-use';

const OwnerAcceptanceInfoMobileCell = (props: CellProps<{data: IOwnerAcceptance}>) => {
	const {row} = props;
	const {data} = row.original;
	const permissions = useOwnerAcceptancesPermissions(data.objectId);
	const resolutions = useAppSelector(extractOwnerAcceptanceResolutionsAsArray);
	const statuses = useAppSelector(s => s.dictionaries.ownerAcceptanceResolutions);
	const dispatch = useAppDispatch();

	const [, handleResolutionChange] = useAsyncFn(
		async (v: OwnerAcceptanceResolutionId) => {
			await dispatch(editOwnerAcceptance(data.objectId, data.id, {resolution: v}));
		},
		[data.objectId, data.id]
	);
	return (
		<div className="m-owner-acpt-info-cell">
			<div className="m-owner-acpt-info-cell__header">
				<OwnerAcceptanceInfo
					data={data}
					title="Передача"
				/>

				<ClickPreventWrap style={{marginLeft: '8px'}}>
					<TableRowSelect
						{...row.getToggleRowSelectedProps()}
						style={{marginTop: '12px'}}
					/>
				</ClickPreventWrap>
			</div>

			<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
				<div style={{marginRight: '16px'}}>Решение:</div>
				{permissions.canEdit ? (
					<ClickPreventWrap>
						<StatusSelect
							style={{maxWidth: '100%'}}
							statuses={statuses}
							disabled={!permissions.canEditStatus}
							value={data.resolution !== 'undefined' ? data.resolution : undefined}
							onChange={handleResolutionChange}
							hideArrow
							label="решение"
						/>
					</ClickPreventWrap>
				) : (
					<div className="owner-acceptance-page__resolution">
						{resolutions.find(item => item.id === data.resolution)?.name}
					</div>
				)}
			</div>

			{data.spaceOwner !== undefined && (
				<div className="m-owner-acpt-info-cell__extra-info">{data.spaceOwner.name}</div>
			)}

			<div style={{marginTop: '16px'}}>
				<OwnerAcceptanceStatusCell {...props}/>
			</div>
		</div>
	);
};

export default OwnerAcceptanceInfoMobileCell;
