import React from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';

const StagesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const objectStages = useAppSelector(extractObjectStagesAsArray);
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={objectStages}
			label="Стадия"
			filterName="stages"
		/>
	);
};

export default StagesFilterPage;
