import React, {memo, useCallback, useMemo, useState} from 'react';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {useUpdateEffect} from 'react-use';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {FilterButton, SelectPopup, TreeSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObjectsAsArray} from '@src/store/modules/dictionaries/objects/selectors';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

export interface IObjectsFilterProps {
	label?: string;
	filterName?: string;
	value?: string[];
	targetObjects?: string[];
	className?: string;
}

export const ObjectsFilter = memo((props: IObjectsFilterProps) => {
	const {label = 'Объекты', value, targetObjects, className, filterName = 'objects'} = props;

	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const objects = useAppSelector(extractObjectsAsArray);
	const companies = useAppSelector(extractCompaniesAsArray);

	const treeData = useMemo(() => {
		if (targetObjects) {
			return makeObjectsTreeData(objects.filter(item => targetObjects.includes(item.id)));
		}
		return makeObjectsTreeData(objects, companies);
	}, [targetObjects, objects, companies]);

	const {filteredData, expanded} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpandedObjects(expanded.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		dispatch({[filterName]: selectedObjects});
		clearSearch();
	}, [selectedObjects, dispatch, clearSearch]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		dispatch({[filterName]: undefined});
		setSelectedObjects([]);
		setExpandedObjects([]);
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
		clearSearch();
	}, [value, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		const allObjects = objects.map(obj => obj.id);
		const allCompanies = companies.map(comp => comp.id);
		setSelectedObjects([...allObjects, ...allCompanies]);
	}, [objects, companies, clearSearch]);

	useUpdateEffect(() => {
		setExpandedObjects([]);
		setSelectedObjects(value);
	}, [value]);

	return (
		<SelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			className={className}
			clearButton={!!selectedObjects?.length}
			footer
			count={selectedObjects?.length}
			onSelectAll={handleSelectAll}
			search={
				<SelectSearch
					value={search}
					onChange={setSearch}
					type="popup"
				/>
			}
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(label, value, treeData)}
					active={!!value?.length}
					onClear={handleFullClear}
				/>
			}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedObjects}
				onChange={setSelectedObjects}
				expandedValue={expandedObjects}
				onExpand={handleExpand}
			/>
		</SelectPopup>
	);
});
