import {TabButton} from '@tehzor/ui-components';
import React from 'react';

const fields = [{id: 'status', name: 'Статусы помещений'}];

// Пока они просто для красоты
// TO-DO Нужно связать их со стором и таблицей
export const FieldButtons = () => (
	fields.length ? (
		<div className="spaces-page__reports-table-field-buttons">
			{fields.map(field => (
				<TabButton
					key={field.id}
					value={field.id}
					active
					className="spaces-page__reports-table-field-buttons-item"
					label={field.name}
				/>
			))}
		</div>
	) : null
);
