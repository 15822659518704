import * as types from '../constants';

/**
 * Изменяет смещение списка
 *
 * @param objectId id объекта
 * @param offset смещение
 */
export const changeOffset = (objectId = 'all', offset: number) => ({
	type: types.CHANGE_OFFSET,
	payload: {
		objectId,
		offset
	}
});

/**
 * Изменяет состяние загрузки
 *
 * @param objectId id объекта
 * @param loading состояние загрузки
 */
export const changeLoading = (objectId = 'all', loading: boolean) => ({
	type: types.CHANGE_LOADING,
	payload: {
		objectId,
		loading
	}
});

/**
 * Изменяет выбранные строки в таблице
 *
 * @param objectId id объекта
 * @param selectedRows массив id выбранных строк
 */
export const changeSelectedRows = (objectId = 'all', selectedRows: string[]) => ({
	type: types.CHANGE_SELECTED_ROWS,
	payload: {
		objectId,
		selectedRows
	}
});

/**
 * Очищает параметр expiration
 */
export const clearExpiration = (objectId = 'all') => ({
	type: types.CLEAR_EXPIRATION,
	payload: {objectId}
});

export * from './get';
export * from './export';
