import React, {useCallback, useState} from 'react';
import {useEditableWorkingGroupState} from '@src/core/hooks/states/useEditableWorkingGroupState/hook';
import {
	convertWorkingGroupToSave,
	errorsFns,
	isEdited
} from '@src/core/hooks/states/useEditableWorkingGroupState/state';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';
import {EditableWorkingGroup} from '../EditableWorkingGrop';
import {EditablePerformers} from '../components';
import {EditableSubGroups} from '../components/EditableSubGroups';
import {Button} from '@tehzor/ui-components';
import {addSuccessToast} from '@src/utils/toasts';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	addWorkingGroup,
	editWorkingGroup
} from '@src/store/modules/dictionaries/workingGroups/actions';
import {getResponsibilityRules} from '@src/store/modules/dictionaries/responsibilityRules/actions';
import {useUpdateEffect} from 'react-use';
import {useWorkingGroupPermissions} from '@src/core/hooks/permissions/useWorkingGroupPermissions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useQueryClient} from '@tanstack/react-query';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	parentId: {fieldId: 'parentId', isRequired: false},
	departmentId: {fieldId: 'departmentId', isRequired: false},
	companyId: {fieldId: 'companyId', isRequired: true},
	contractorId: {fieldId: 'contractorId', isRequired: false},
	objects: {fieldId: 'objects', isRequired: false},
	stages: {fieldId: 'stages', isRequired: true},
	scope: {fieldId: 'scope', isRequired: false},
	leader: {fieldId: 'leader', isRequired: true},
	performers: {fieldId: 'performers', isRequired: false},
	type: {fieldId: 'type', isRequired: true}
};

interface IHookArgs {
	workingGroup?: IWorkingGroup;
	saving?: boolean;
}

export const useEditableWorkingGroup = ({
	workingGroup,
	saving
}: IHookArgs): [
	React.ReactNode,
	React.ReactNode,
	React.ReactNode,
	() =>
		| {
				workingGroup?: ISavingWorkingGroup;
		  }
		| undefined,
	() => void,
	boolean
] => {
	const {pushPath} = useChangePath();
	const [editingState, editingDispatch] = useEditableWorkingGroupState({
		workingGroup
	});

	const perms = useWorkingGroupPermissions();
	const [isBlocking, setIsBlocking] = useState(false);
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}

		if (!isEdited(editingState, workingGroup)) {
			return undefined;
		}
		const savingWorkingGroup = convertWorkingGroupToSave(editingState, workingGroup, true);

		return {
			workingGroup: savingWorkingGroup
		};
	}, [editingState, workingGroup, errorsFns, fieldsSettings, isEdited]);

	useUpdateEffect(() => {
		editingDispatch({
			type: 'update',
			field: 'objects',
			value: []
		});
		editingDispatch({
			type: 'update',
			field: 'performers',
			value: undefined
		});
		editingDispatch({
			type: 'update',
			field: 'leader',
			value: undefined
		});
	}, [editingState.companyId]);

	const handleSave = useCallback(async () => {
		setIsBlocking(true);

		const savingData = getSavingData();

		try {
			if (savingData && savingData.workingGroup) {
				if (workingGroup?.id) {
					await Promise.all([
						dispatch(editWorkingGroup(workingGroup.id, savingData.workingGroup)),
						dispatch(getResponsibilityRules())
					]);
				} else {
					const result = await dispatch(addWorkingGroup(savingData.workingGroup));
					pushPath(`/manage/working-groups/${result.id}`);
				}

				await queryClient.invalidateQueries(workingGroupsQueryKeys.list());

				addSuccessToast(
					'Успешно',
					workingGroup?.id ? 'Группа была обновлена' : 'Группа создана'
				);
			}
		} finally {
			setIsBlocking(false);
		}
	}, [getSavingData, workingGroup?.id]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: {
				workingGroup
			}
		});
	}, [workingGroup]);

	const workingGroupFields = (
		<>
			<EditableWorkingGroup
				groupId={workingGroup?.id}
				editingState={editingState}
				editingDispatch={editingDispatch}
				fieldsSettings={fieldsSettings}
				disabled={saving || !perms.canEdit}
				workingGroup={workingGroup}
			/>
			{perms.canEdit && (
				<div className="editable-working-group__buttons">
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={
							isBlocking ||
							(workingGroup
								? !isEdited(editingState, workingGroup) ||
								  hasErrors(editingState, errorsFns, fieldsSettings)
								: hasErrors(editingState, errorsFns, fieldsSettings))
						}
						onClick={handleSave}
					/>

					<Button
						type="cancel"
						label="Отменить "
						onClick={reset}
						disabled={!isEdited(editingState, workingGroup)}
					/>
				</div>
			)}
		</>
	);

	const workingGroupPerformersFields = (
		<EditablePerformers
			editingState={editingState}
			editingDispatch={editingDispatch}
			workingGroupId={workingGroup?.id}
			forbiddenAdding={
				(workingGroup && isEdited(editingState, workingGroup)) || !perms.canEdit
			}
		/>
	);

	const workingGroupSubGroupsFields = (
		<EditableSubGroups
			editingState={editingState}
			editingDispatch={editingDispatch}
			workingGroupId={workingGroup?.id}
		/>
	);

	return [
		workingGroupFields,
		workingGroupPerformersFields,
		workingGroupSubGroupsFields,
		getSavingData,
		reset,
		isBlocking
	];
};
