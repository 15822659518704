import {requestDeleteSpaceStatusesSet} from '@src/api/backend/spaceStatusesSets/delete';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export interface IDeleteSpaceStatusesPayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => ({
	type: types.DELETE_SUCCESS,
	payload: {id}
});

const failure = (error: IError) => ({
	type: types.DELETE_FAILURE,
	payload: error
});

export const deleteSpaceStatusesSet = (id: string) =>
	createApiAction(
		request,
		() => success(id),
		failure,
		async () => {
			void queryClient.invalidateQueries(spaceStatusesSetsQueryKeys.list());
			void queryClient.invalidateQueries(spacesQueryKeys.list());

			return requestDeleteSpaceStatusesSet(id);
		}
	);