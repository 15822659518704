import React, {useCallback} from 'react';
import {useAsyncFn} from 'react-use';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {desktopColumns} from './columns.desktop';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {extractProblemTagsSetsAsArray} from '@src/store/modules/dictionaries/problemTagsSets/selectors';
import {useEnrichedProblemTagsSets} from '../../hooks/useEnrichedProblemTagsSets';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {getProblemTags} from '@src/store/modules/dictionaries/problemTags/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface ITableProps {
	loading?: boolean;
}

export const Table = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const data = useAppSelector(extractProblemTagsSetsAsArray);
	const dispatch = useAppDispatch();
	const [, loadProblemTags] = useAsyncFn(() => dispatch(getProblemTags()));
	const preparedData = useEnrichedProblemTagsSets(data);

	const handleRowClick = useCallback((item: IEnrichedProblemTagsSet) => {
		pushPath(`/manage/problem-tags-sets/${item.id}`);
	}, []);

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={loadProblemTags}>
				<LoadingPanel active={loading}>
					<EntitiesTable
						columns={desktopColumns}
						data={preparedData}
						onRowClick={handleRowClick}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
