import {makeProblemAddRequest, makeProblemEditRequest} from '@src/api/backend/problem';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	addProblemActions,
	deleteProblemActions,
	editProblemActions
} from '@src/store/modules/pages/problem/actions';
import {IProblemsPagesSettingsState} from '@src/store/modules/settings/pages/problems/reducers';
import {updateCachedState} from '@src/utils/updateCachedState';
import {useQueryClient} from '@tanstack/react-query';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import IError from '@tehzor/tools/interfaces/IError';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {addTempFiles} from '../../utils/addTempFiles';
import {problemsQueryKeys} from '../keys';

export interface IAddProblemParams {
	objectId: string;
	links: IProblem['links'] | undefined;
	stage: ObjectStageIds | undefined;
	fields: ISavingProblem;
	key: string;
	currentQueryFilter?: IProblemsPagesSettingsState;
}

export interface IEditProblemParams {
	problemId: string;
	objectId: string;
	fields: ISavingProblem;
}
/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useProblemsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const {updateEntity} = useUpdateEntity(problemsQueryKeys);
	queryClient.setMutationDefaults(problemsQueryKeys.add(), {
		onMutate: (variables: IAddProblemParams) => ({variables}),
		mutationFn: async (params: IAddProblemParams) => {
			await updateCachedState<IProblem>(
				problemsQueryKeys,
				params.key,
				OfflineDataTransferStatus.TRANSFER
			);
			const {objectId, links, stage, fields} = params;
			return makeProblemAddRequest(objectId, links, stage, {
				...fields,
				newAttachments: await addTempFiles(fields.newAttachments)
			});
		},
		onSuccess: async (newProblem: IProblem, {key, objectId}: IAddProblemParams) => {
			await updateCachedState<IProblem>(
				problemsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			// Удаляем кешированные данные локального нарушения
			queryClient.removeQueries(problemsQueryKeys.savingData(key));

			updateEntity<IProblem>(
				newProblem,
				deleteProblemActions.success,
				addProblemActions.success,
				key
			);
			await queryClient.refetchQueries({
				queryKey: problemsQueryKeys.localList()
			});
			await queryClient.invalidateQueries([
				...problemsQueryKeys.list(),
				{objects: [objectId]}
			]);
		},
		onError: async (_, {key}: IAddProblemParams) => {
			await updateCachedState<IProblem>(
				problemsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
		},
		retry: false
	});

	queryClient.setMutationDefaults(problemsQueryKeys.edit(), {
		mutationFn: async ({problemId, objectId, fields}: IEditProblemParams) => {
			await queryClient.cancelQueries({queryKey: problemsQueryKeys.detail(problemId)});
			return makeProblemEditRequest(objectId, problemId, {
				...fields,
				newAttachments: await addTempFiles(fields.newAttachments)
			});
		},
		onError: err => {
			dispatch(editProblemActions.failure(err as IError));
		},
		onSuccess: async (data: ILinkedProblem, {problemId}: IEditProblemParams) => {
			dispatch(editProblemActions.success(data));
			await queryClient.invalidateQueries(problemsQueryKeys.detail(problemId));
			await queryClient.invalidateQueries(problemsQueryKeys.list());
		}
	});
};
