import IObjectFieldSetting from '../../interfaces/objects/IObjectFieldSetting';
import {isEqual} from 'lodash';

/**
 * Тип состояния с ошибками
 */
export type IEditableEntityState<S> = S & {
	errors: {
		[k in keyof S]?: boolean;
	};
};

/**
 * Тип действия для reducer'а редактирования сущности
 */
export type IEditableEntityAction<S, E> =
	| {
			type: 'update';
			field: keyof S;
			value: unknown;
	  }
	| {
			type: 'add-in-array';
			field: keyof S;
			value: unknown[];
	  }
	| {
			type: 'update-array-item';
			field: keyof S;
			value: unknown;
			index: number;
	  }
	| {
			type: 'delete-array-item';
			field: keyof S;
			index: number[];
	  }
	| {
			type: 'reset';
			entity?: E;
	  }
	| {
			type: 'update-error';
			field: keyof S;
	  }
	| {
			type: 'update-errors';
	  };

export type IEditableEntityErrorFns<S> = {[k in keyof S]?: (s: IEditableEntityState<S>) => boolean};

/**
 * Функция проверки ошибок всех полей в state
 */
function updateErrors<S>(state: IEditableEntityState<S>, errorFns: IEditableEntityErrorFns<S>) {
	const errors: typeof state.errors = state.errors;
	for (const key in errorFns) {
		if (errorFns.hasOwnProperty(key)) {
			errors[key] = errorFns[key]?.(state);
		}
	}
	return {
		...state,
		errors
	};
}

/**
 * Создаёт reducer для редактирования сущности
 */
export const createReducer
	= <S, E>(init: (entity?: E) => IEditableEntityState<S>, errorFns: IEditableEntityErrorFns<S>) =>
	(state: IEditableEntityState<S>, action: IEditableEntityAction<S, E>): IEditableEntityState<S> => {
		switch (action.type) {
			case 'update':
				return {
					...state,
					[action.field]: action.value
				};
			case 'add-in-array':
				return {
					...state,
					[action.field]: (state[action.field] as unknown as unknown[]).concat(action.value)
				};
			case 'update-array-item':
				return {
					...state,
					[action.field]: (state[action.field] as unknown as unknown[]).map((item, i) =>
						(i === action.index ? action.value : item))
				};
			case 'delete-array-item':
				return {
					...state,
					[action.field]: (state[action.field] as unknown as unknown[]).filter(
						(item, i) => !action.index.includes(i)
					)
				};
			case 'reset':
				return init(action.entity);
			case 'update-error':
				return {
					...state,
					errors: {
						...state.errors,
						[action.field]: errorFns[action.field]?.(state)
					}
				};
			case 'update-errors':
				return updateErrors(state, errorFns);
			default:
				return state;
		}
	};

/**
 * Возвращает значение, показывающее были ли отредактированы поля сущности
 *
 * @param state состояние
 * @param original изначальные данные сущности
 * @param fns функции для проверки изменения в отдельных полях
 */
export const isEntityEdited = <S, E>(
	state: IEditableEntityState<S>,
	original?: E,
	...fns: Array<(s: IEditableEntityState<S>, o?: E) => boolean>
): boolean => fns.some(fn => fn(state, original));

/**
 * Проверяет, есть ли ошибки в полях сущности
 *
 * @param state состояние
 * @param errorFns функции проверок полей
 * @param settings настройки полей
 */
export const hasErrors = <S>(
	state: IEditableEntityState<S>,
	errorFns: IEditableEntityErrorFns<S>,
	settings: {[k: string]: IObjectFieldSetting}
) => {
	for (const key in errorFns) {
		if (
			errorFns.hasOwnProperty(key)
			&& settings.hasOwnProperty(key)
			&& settings[key].isRequired
			&& errorFns[key as keyof S]?.(state)
		) {
			return true;
		}
	}
	return false;
};

/**
 * Проверяет был-ли изменен ключ в state, сравнивая его с оригиналом
 *
 * @param prop ключ
 * @param state текущий state
 * @param original оригинальное значение
 */
export const isPropEdited = <
	State extends IEditableEntityState<unknown>,
	Key extends keyof State,
	Entity extends Partial<Record<Key, State[Key]>>
>(
	prop: Key,
	state: State,
	original?: Entity
) => {
	const currentVal: unknown = state[prop];

	if (!original) {
		if (currentVal === false) {
			return true;
		}
			return !!currentVal;
	}

	let result = true;
	const originalVal: unknown = original[prop];

	if (Array.isArray(currentVal) && Array.isArray(originalVal)) {
		result
			= result
			&& (currentVal.length !== originalVal.length
				|| JSON.stringify(currentVal) !== JSON.stringify(originalVal));
	} else if (typeof currentVal === 'object') {
		result = result && !isEqual(currentVal, originalVal);
	} else {
		result = result && currentVal != originalVal;
	}

	return result;
};
