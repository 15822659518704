import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import React from 'react';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';

export const CheckListSpaceCompanyCell = ({row}: CellProps<IPreparedCheckList>) => {
	const companies = useAppSelector(extractCompaniesAsArray);
	const company = companies.find(({id}) => id === row.original.companyId);

	if (!company) {
		return null;
	}

	return <div>{company.name}</div>;
};
