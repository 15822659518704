import {useCallback} from 'react';
import {Location, useNavigate} from 'react-router-dom';

export const useChangePath = () => {
	const navigate = useNavigate();
	const pushPath = useCallback(
		(path: string, state?: Location['state']) => {
			navigate(path, state);
		},
		[navigate]
	);

	const replacePath = useCallback(
		(path: string, state?: Location['state']) => {
			navigate(path, {state, replace: true});
		},
		[navigate]
	);

	const goBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	return {pushPath, replacePath, goBack};
};
