import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {
	IEditResponsibilityRuleResponse,
	makeResponsibilityRuleEditRequest
} from '@src/api/backend/responsibilityRules';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';

export type IEditResponsibilityRulePayload = IEditResponsibilityRuleResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditResponsibilityRuleResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении правила');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Обновляет 1 правило за раз
 *
 * @param ruleId id правила
 * @param groupId id группы
 * @param rule данные для добавления
 */
export const editResponsibilityRule = (
	ruleId: string,
	groupId: string,
	rule: ISavingResponsibilityRule
) =>
	createApiAction<IEditResponsibilityRuleResponse>(
		request,
		(res: IEditResponsibilityRuleResponse) => success(res),
		failure,
		() => makeResponsibilityRuleEditRequest(ruleId, groupId, rule)
	);
