import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';

const extractSpaceDecorationTypesAllIds = (state: IState): string[] => state.entities.spaceTypeDecorations.allIds || [];
const extractDecorationSetIds = (state: IState, decorationSetIds: string[]): string[] => decorationSetIds;

export const extractSpaceDecorationTypesById = (state: IState): Record<string, ISpaceTypeDecoration> =>
	state.entities.spaceTypeDecorations.byId || {};

/**
 * Возвращает типы отделки помещений в виде массива
 */
export const extractSpaceTypeDecorationsAsArray = createSelector(
	[extractSpaceDecorationTypesAllIds, extractSpaceDecorationTypesById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);

/**
 * Возвращает типы отделки помещений для наборов в виде массива
 */
export const extractSpaceTypeDecorationsForSetsAsArray = createSelector(
	[extractSpaceDecorationTypesAllIds, extractSpaceDecorationTypesById, extractDecorationSetIds],
	(allIds, byId, decorationSetIds) => allIds.map((id: string) =>
		byId[id]).filter((type: ISpaceTypeDecoration) => decorationSetIds.includes(type.typeDecorationSetId))
);