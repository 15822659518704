import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import React, {useCallback, useState} from 'react';
import {MovingWarrantyClaimDialog} from '../MovingWarrantyClaimDialog';

export function useMovingWarrantyClaimDialog(
	objectId: string,
	warrantyClaim: IWarrantyClaim
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<MovingWarrantyClaimDialog
			objectId={objectId}
			claim={warrantyClaim}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
