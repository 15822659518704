import {PrivateRoute} from '@src/containers/PrivateRoute';
import React from 'react';
import {ownerAcceptancesFilterRoutes} from './ownerAcceptancesFilterRoutes';
import {OwnerAcceptancesFiltersRoutingPage} from '@src/pages/filters/OwnerAcceptancesFiltersRoutingPage/OwnerAcceptancesFiltersRoutingPage';
import {AllOwnerAcceptancesPage} from '@src/pages/OwnerAcceptancesPage';

export const ownerAcceptancesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllOwnerAcceptancesPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<OwnerAcceptancesFiltersRoutingPage/>}/>,
		children: ownerAcceptancesFilterRoutes
	}
];
