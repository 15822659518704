import {createSelector} from 'reselect';
import {getInitialStateForPage} from '@src/store/modules/entities/warrantyClaims/reducers';
import {IState} from '@src/store/modules';

/**
 * Возвращает данные по обращениям для конкретного объекта
 */
export const extractWarrantyClaimsData = createSelector(
	(state: IState) => state.entities.warrantyClaims,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(rootData, objectId) => rootData[objectId] || getInitialStateForPage()
);

/**
 * Возвращает обращения в виде массива
 */
export const extractWarrantyClaimsAsArray = createSelector(extractWarrantyClaimsData, data =>
	data.allIds.map((id: string) => data.byId[id]));
