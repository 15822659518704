import React, {memo, useMemo} from 'react';
import {Tags} from '@tehzor/ui-components';
import {IStatsFiltersDialogState} from '../utils/state';
import {useFiltersDialogDispatch} from '../utils/FiltersDialogDispatchCtx';
import {convertToStore} from '../utils/convertToStore';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractStatsContractors,
	extractStatsSources
} from '@src/store/modules/statistics/sources/selectors';
import {makeTagsData} from '../utils/makeTagsData';

interface IFiltersTagsProps {
	state: IStatsFiltersDialogState;
}

const FiltersTags = ({state}: IFiltersTagsProps) => {
	const sources = useAppSelector(extractStatsSources);
	const objects = useAppSelector(s => s.dictionaries.objects);
	const companies = useAppSelector(s => s.dictionaries.companies);
	const contractorsSources = useAppSelector(extractStatsContractors);
	const users = useAppSelector(s => s.dictionaries.users);

	const value = useMemo(() => {
		const filters = convertToStore(state, sources, companies);
		return makeTagsData(filters, sources, objects, companies, contractorsSources, users);
	}, [state, sources, objects, companies, users]);

	const dispatch = useFiltersDialogDispatch();

	const handleObjectDelete = (id: string) => {
		dispatch({type: 'delete', field: 'checkedObjects', value: id});
	};

	const handleUserDelete = (id: string) => {
		dispatch({type: 'delete', field: 'checkedUsers', value: id});
	};

	const handleContractorDelete = (id: string) => {
		dispatch({type: 'delete', field: 'checkedContractors', value: id});
	};

	const handleContractorUserDelete = (id: string) => {
		dispatch({type: 'delete', field: 'checkedContractorsUsers', value: id});
	};

	return (
		<div className="stats-filters-dialog__tags">
			{value.companies && (
				<Tags
					data={value.companies}
					color="#EDDDFA"
					inline
					onDelete={handleObjectDelete}
				/>
			)}
			{value.objects && (
				<Tags
					data={value.objects}
					color="#FAF2DD"
					inline
					onDelete={handleObjectDelete}
				/>
			)}
			{value.users && (
				<Tags
					data={value.users}
					color="#DDFAF8"
					inline
					onDelete={handleUserDelete}
				/>
			)}
			{value.contractors && (
				<Tags
					data={value.contractors}
					color="#FAE7DD"
					inline
					onDelete={handleContractorDelete}
				/>
			)}
			{value.contractorsUsers && (
				<Tags
					data={value.contractorsUsers}
					color="#FAE7DD"
					inline
					onDelete={handleContractorUserDelete}
				/>
			)}
		</div>
	);
};

export default memo(FiltersTags);
