import {wsConnector} from '../wsConnector';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

export type IEditTaskResponsibleResponse = ITask;

/**
 * Изменяет ответственных задачи
 *
 * @param objectId id объекта
 * @param taskId id задачи
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const makeTaskResponsibleEditRequest = (
	objectId: string,
	taskId: string,
	respUsers?: string[],
	activeGroup?: string
) =>
	wsConnector.sendAuthorizedRequest<IEditTaskResponsibleResponse>('editTaskResponsible', {
		objectId,
		taskId,
		respUsers,
		activeGroup
	});
