import React, {memo, useCallback} from 'react';
import {CheckListItem} from '@src/pages/CheckListPage/components/list/CheckListItems/components/CheckListItem';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {AcceptedItems} from '@src/pages/CheckListPage/components/list/CheckListItems/components/AcceptedItems';
import './CheckListItems.less';
import {formCheckItemLink, formWorkAcceptanceCheckItemLink} from '@tehzor/tools/utils/links';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useSeparatedCheckItems} from '@src/pages/CheckListPage/hooks/useSeparatedCheckItems';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface ICheckListItemsProps {
	checkList: ICheckList;
	canAddEntity?: boolean;
}

export const CheckListItems = memo(({checkList, canAddEntity}: ICheckListItemsProps) => {
	const {pushPath, replacePath} = useChangePath();
	const params = useStrictParams<{
		objectId: string;
		stage: string;
		listId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();
	const isDesktop = useIsDesktop();
	const {accepted, current, next, acceptedItemsIds} = useSeparatedCheckItems(checkList.id);
	const handleClick = useCallback(
		(item: ICheckItem) => {
			const navigate = isDesktop ? replacePath : pushPath;
			if (params.workAcceptanceId) {
				navigate(
					formWorkAcceptanceCheckItemLink({
						...params,
						listId: item.checkListId,
						itemId: item.id,
						workAcceptanceId: params.workAcceptanceId
					})
				);
			} else {
				navigate(formCheckItemLink({...params, listId: item.checkListId, itemId: item.id}));
			}
		},
		[isDesktop, params]
	);

	return (
		<div className="check-list-items">
			{accepted.length > 0 && (
				<>
					<AcceptedItems
						items={accepted}
						onItemClick={handleClick}
					/>
					<hr className="check-list-page__divider check-list-page__divider_hr" />
				</>
			)}

			{current.map(item => (
				<CheckListItem
					mode="main"
					key={item.id}
					item={item}
					onClick={handleClick}
					canAddEntity={canAddEntity}
					acceptedItemsIds={acceptedItemsIds}
				/>
			))}

			{next.length > 0 && (
				<>
					<hr className="check-list-page__divider check-list-page__divider_hr" />

					<div className="check-list-items__next">
						<div className="check-list-items__next_disabled" />
						{next.map(item => (
							<CheckListItem
								disabled
								mode="main"
								key={item.id}
								item={item}
								onClick={handleClick}
								canAddEntity={canAddEntity}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
});
