import {wsConnector} from '../wsConnector';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export type IGetInspectionResponse = IInspection;

/**
 * Возвращает осмотр
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 */
export const requestInspection = (objectId: string, inspectionId: string) =>
	wsConnector.sendAuthorizedRequest<IGetInspectionResponse>('getInspection', {
		objectId,
		inspectionId
	});
