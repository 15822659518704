import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppSelector from '../../useAppSelector';
import {toListProblem, toProblem} from './utils/convertToLocalSave';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {useAddProblemMutation} from '@src/api/cache/problems/mutations';
import {IAddProblemParams} from '@src/api/cache/problems/defaults/useProblemsMutationDefaults';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {useCallback} from 'react';
import useAppDispatch from '../../useAppDispatch';
import {addProblemActions} from '@src/store/modules/pages/problem/actions';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import {useLocalProblems} from '../../queries/problems';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';

export interface ISavingProblemQuery {
	objectId: string;
	links: IProblem['links'];
	stage: ObjectStageIds | undefined;
	savingData: ISavingProblem;
	key: string;
}

export const useAddNewProblem = (object: IObject) => {
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const currentQueryFilter = useAppSelector(s => s.settings.pages.problems);
	const user = useAppSelector(extractUserProfile);
	const nextNumber = useNextLocalNumber<IListProblem>(useLocalProblems(), 'localCreatedAt');
	const mutation = useAddProblemMutation();
	const dispatch = useAppDispatch();
	const createNewProblemCache = useCallback(
		({
			objectId,
			links,
			stage,
			fields
		}: Omit<IAddProblemParams, 'key' | 'currentQueryFilter'>): Promise<IProblem> => {
			const key = Date.now().toString(10);
			if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
				links.checkRecordId = undefined;
			}
			return new Promise(resolve => {
				const converted = toProblem(object, links, stage, fields, key, nextNumber, user);
				const convertedToList = toListProblem(
					object,
					links,
					stage,
					fields,
					key,
					nextNumber,
					user
				);
				// Формируем кэш нарушения с данными для сохранения, для удобства
				// в дальнейшем мы используем его при сохранении локальных родительских сущностей
				setTimeout(
					() =>
						queryClient.setQueryData<ISavingProblemQuery>(
							problemsQueryKeys.savingData(key),
							{
								objectId,
								links,
								stage,
								savingData: fields,
								key
							}
						),
					0
				);
				// Складывем новое нарушение в кэш как отдельную сущность, чтоб можно было в неё перейти из списка
				// для локального редактирования и т.п.
				setTimeout(
					() =>
						queryClient.setQueryData(
							[...problemsQueryKeys.detail(key), objectId],
							convertedToList
						),
					0
				);
				if (!online) {
					addWarningToast('Локальное сохранение', 'нарушение добавлено локально');
					setTimeout(() => {
						void queryClient.refetchQueries(problemsQueryKeys.localList());
					}, 0);
					dispatch(addProblemActions.success(converted, true));
				}
				resolve(converted);
			});
		},
		[currentQueryFilter]
	);

	const saveProblem = ({
		objectId,
		links,
		stage,
		fields,
		key
	}: Omit<IAddProblemParams, 'currentQueryFilter'>) => {
		mutation.mutate({
			objectId,
			links,
			stage,
			fields,
			key,
			currentQueryFilter
		});
	};
	return {createNewProblemCache, saveProblem};
};
