import React from 'react';
import {FloatingActionButton, IconButton} from '@tehzor/ui-components';
import {useAddingProblemDialog} from '@src/components/AddingProblemDialog/hooks/useAddingProblemDialog';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {getSpace} from '@src/store/modules/entities/space/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IEntityAddingProps {
	objectId: string;
	warrantyClaim: IWarrantyClaim;
}

const EntityAdding = ({objectId, warrantyClaim}: IEntityAddingProps) => {
	const dispatch = useAppDispatch();

	const links = {...warrantyClaim.links, warrantyClaimId: warrantyClaim.id};
	const defaultData = useSpaceLocationForEntities(objectId, warrantyClaim.links?.spaceId);
	const [dialog, open] = useAddingProblemDialog(
		objectId,
		links,
		ObjectStageIds.WARRANTY,
		undefined,
		defaultData
	);

	const [, handleClick] = useAsyncFn(async () => {
		if (warrantyClaim.links?.spaceId) {
			await dispatch(getSpace(objectId, warrantyClaim.links.spaceId));
		}
		open();
	}, [objectId, warrantyClaim, open]);

	return (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<IconButton
					type="accent-blue"
					onClick={handleClick}
				>
					<i className="tz-plus-20"/>
				</IconButton>
			</Desktop>
			{dialog}
		</>
	);
};

export default EntityAdding;
