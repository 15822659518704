import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useCheckItemIndex} from '@src/pages/CheckListPage/hooks/useCheckItemIndex';
import React from 'react';
import {ProblemInfoMobile} from '@src/components/tableCells/ProblemInfoMobileCell/ProblemInfoMobileCell';
import {EntityInfoLink} from '@tehzor/ui-components';

export const CheckListProblemCellWrap = (props: IProblemCellProps<{data: IProblem}>) => {
	const {itemId} = useStrictParams<{itemId: string}>();

	const {row, replies} = props;
	const {data} = row.original;
	const {links} = data;

	const item = useAppSelector(s => extractCheckItemById(s, links?.checkItemId));
	const currentIndex = useCheckItemIndex(item);

	return (
		<ProblemInfoMobile
			problem={data}
			depth={row.depth}
			subTitle={
				links?.checkItemId !== itemId ? (
					<EntityInfoLink label={`${currentIndex} ${item?.name}`}/>
				) : undefined
			}
			replies={replies}
			getToggleRowSelectedProps={row.getToggleRowSelectedProps}
		/>
	);
};
