import React, {useCallback, useState} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import './AddingTaskDialog.less';
import {useEditableTask} from '../EditableTask/hooks/useEditableTask';
import {addTask} from '@src/store/modules/pages/task/actions';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {TaskPriorityId} from '@tehzor/tools/interfaces/tasks/ITaskPriority';
import {getTasks} from '@src/store/modules/entities/tasks/actions';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

interface IAddingTaskDialogProps {
	objectId?: string;
	floor?: string;
	planId?: string;
	location?: ILocation;
	status?: TaskStatusId;
	taskType?: TaskTypeId;
	taskPriority?: TaskPriorityId;
	startTime?: string;
	endTime?: string;
	activeGroup?: string;
	respUsers?: IBriefUser[];
	links?: {
		spaceId?: string;
		checkListId?: string;
		checkItemId?: string;
	};
	onSuccess?: () => void | Promise<void>;
	isOpen: boolean;
	close(): void;
}

export const AddingTaskDialog = ({
	objectId,
	floor,
	isOpen,
	planId,
	location,
	status,
	taskType,
	taskPriority,
	activeGroup,
	respUsers,
	links,
	startTime,
	endTime,
	close,
	onSuccess
}: IAddingTaskDialogProps) => {
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [taskFields, getSavingData, isBlocking, reset] = useEditableTask({
		objectId,
		floor,
		planId,
		location,
		status,
		taskType,
		taskPriority,
		links,
		activeGroup,
		respUsers,
		startTime,
		endTime
	});

	const handleSave = useCallback(async () => {
		const savingData = await getSavingData();
		if (!savingData) {
			return;
		}
		setSaving(true);

		try {
			await dispatch(addTask({...savingData, links}));
			await dispatch(getTasks());
			if (onSuccess) {
				void onSuccess();
			}
		} finally {
			setSaving(false);
			reset();
			close();
		}
	}, [close, dispatch, getSavingData, links, reset, onSuccess]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, getClosingConfirmation, close]);

	return (
		<Dialog
			className={{
				root: 'adding-task',
				content: 'adding-task__container'
			}}
			isOpen={isOpen}
			title="Создать задачу"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={cancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{taskFields}
			{closingDialog}
		</Dialog>
	);
};
