import React from 'react';
import './ResponsibilityChange.less';
import classNames from 'classnames';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';

interface IRespUser {
	name: string;
	position: string;
}

interface IRespUsersChangeProps extends IChangeComponentProps {
	value?: {
		activeGroup?: string;
		respUsers?: IRespUser[];
	};
}

export const ResponsibilityChange = ({className, style, value}: IRespUsersChangeProps) => (
	<div
		className={classNames('history-responsibility-change', className)}
		style={style}
	>
		{value?.activeGroup && (
			<div className="history-responsibility-change__header">
				<i className="tz-users-16 history-responsibility-change__icon"/>
				<div className="history-responsibility-change__group">{value.activeGroup}</div>
			</div>
		)}

		<div className="history-responsibility-change__users">
			{value?.respUsers
				? value.respUsers.map((item, index) => (
					<div
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className="history-responsibility-change__user"
					>
						<span className="history-responsibility-change__name">{item.name}</span>
							&nbsp;
						{item.position}
					</div>
				  ))
				: null}
		</div>
	</div>
);

ResponsibilityChange.displayName = 'ResponsibilityChange';
