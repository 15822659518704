import useAppSelector from '@src/core/hooks/useAppSelector';
import React from 'react';
import {CellProps} from 'react-table';
import {IReportsTableRow} from '../../hooks/useReportsTable';
import {extractSpaceStatusesAsArrayByStage} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const FieldCell = ({row, column}: CellProps<IReportsTableRow>) => {
	const data = row?.original.data;
	const count = data ? data?.[column.id] || 0 : 0;

	const {objectId} = useStrictParams<{objectId: string}>();
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const spaceStatuses = useAppSelector(s =>
		extractSpaceStatusesAsArrayByStage(s, objectId || '', pageSettings.stage));

	const status = spaceStatuses.find(item => item.id === column.id);

	return (
		<div style={{backgroundColor: data && status ? status.color : undefined}}>
			{data ? count : !objectId && status ? status?.name : ''}
		</div>
	);
};
