import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {
	handleLoadableEntitiesGetting,
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type ICompaniesState = ILoadableEntitiesState<ICompany>;

export const companies = createReducer<ICompaniesState>(
	getLoadableEntitiesInitialState<ICompany>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ICompany>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ICompany>(),
		[CLEAR_BEFORE_UPDATE]: () => getLoadableEntitiesInitialState<ICompany>()
	}
);
