import {wsConnector} from '../wsConnector';

/**
 * Удаляет осмотр
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 */
export const makeInspectionDeleteRequest = (objectId: string, inspectionId: string) =>
	wsConnector.sendAuthorizedRequest<void>('deleteInspection', {
		objectId,
		inspectionId
	});
