import React from 'react';
import {Tag, TagsContainer} from '@tehzor/ui-components';
import {IConvertedProblemsData} from '../../utils/convertInternalAcceptance';

interface IStatsProps {
	stats?: {
		problems?: IConvertedProblemsData[];
		inspections?: number;
	};
	size?: 'small' | 'medium' | 'large';
}
export const Stats = ({stats, size}: IStatsProps) => (
	<>
		{stats?.problems?.length || stats?.inspections ? (
			<TagsContainer className="internal-acceptances-page__stats">
				{stats.problems?.length
					? stats.problems.map(item => (
						<Tag
							key={item.key}
							className={{
									root: 'internal-acceptances-page__problems-stats-cell',
									label: 'internal-acceptances-page__problems-stats-cell-label',
									icon: 'internal-acceptances-page__problems-stats-cell-icon'
							}}
							icon={(
								<i
									className="tz-problem-16"
									style={{color: item.color}}
								/>
							)}
							label={item.value.toString()}
							size={size}
						/>
					  ))
					: null}
				{stats.inspections ? (
					<Tag
						className={{
							root: 'internal-acceptances-page__problems-stats-cell',
							label: 'internal-acceptances-page__problems-stats-cell-label',
							icon: 'internal-acceptances-page__problems-stats-cell-inspection-icon'
						}}
						icon={<i className="tz-inspection-16"/>}
						label={stats.inspections.toString()}
						size={size}
					/>
				) : null}
			</TagsContainer>
		) : null}
	</>
);
