import React from 'react';
import './StructureInfoMobileCell.less';
import {CellProps} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {TableRowSelect, Tag, TagsContainer} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import useAppSelector from '@src/core/hooks/useAppSelector';

const checkListIcon = <i className="tz-check-list-16 "/>;

export const StructureInfoMobileCell = ({row}: CellProps<IEnrichedStructure>) => {
	const data = row.original;
	const structureTypes = useAppSelector(s => s.dictionaries.structureTypes);
	const categories = data.categories ? Object.values(data.categories) : undefined;

	return (
		<div className="m-structure-info-cell">
			<ClickPreventWrap>
				<TableRowSelect
					className="m-structure-info-cell__row-select"
					{...row.getToggleRowSelectedProps()}
				/>
			</ClickPreventWrap>

			<div className="m-structure-info-cell__wrapper">
				<div className="m-structure-info-cell__text">
					<div className="m-structure-info-cell__title">
						{data.name}
					</div>
					<div className="m-structure-info-cell__type">
						{structureTypes.byId[data.type]?.name ?? ''}
					</div>

					{data.workAcceptancesStats
						&& (
							<TagsContainer className="m-structure-info-cell__stats">
								{data.workAcceptancesStats.map(item => (
									<Tag
										key={item.key}
										icon={(
											<i
												className="tz-work-acceptance-16"
												style={{color: item.color}}
											/>
										)}
										label={item.value.toString()}
									/>
								))}
							</TagsContainer>
						)}

					{(categories && categories.length > 0) && (
						<div className="m-structure-info-cell__category">
							{categories[0]}
							{' '}
							{categories.length > 1 && (
								<>
									+
									{categories.length - 1}
								</>
							)}

						</div>
					)}

					{data.checkLists && (
						<TagsContainer
							className="m-structure-info-cell__check-lists"
						>
							{data.checkLists.map(checkList => (

								<Tag
									key={checkList.key}
									color={`${checkList.status.color}`}
									icon={checkListIcon}
									label={checkList.name.toString()}
									size="large"
								/>

							))}
						</TagsContainer>
					)}

					{(data.problemsStats && data.problemsStats?.length > 0) && (
						<TagsContainer
							className="structures-name-container"
						>
							{data.problemsStats.map(item => (
								<Tag
									key={item.key}
									icon={(
										<i
											className="tz-problem-16"
											style={{color: item.color}}
										/>
									)}
									label={item.value.toString()}
								/>
					))}
						</TagsContainer>
			)}

				</div>
			</div>

		</div>
	);
};
