import {useQueryClient} from '@tanstack/react-query';
import {bankAccountsQueryKeys} from '../keys';
import {addErrorToast} from '@src/utils/toasts';
import {requestBankAccounts} from '@src/api/backend/bankAccounts';

export const useBankAccountsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(bankAccountsQueryKeys.list(), {
		queryFn: async () => requestBankAccounts(),
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке реквизитов');
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
};