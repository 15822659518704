import React from 'react';
import EntitiesFilters, {
	CreatedByFilter,
	ObjectsFilter,
	CreationDateFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/reducers';
import {StatusesFilter} from './filters/StatusesFilter';
import {ResolutionsFilter} from './filters/ResolutionsFilter';
import {SpaceOwnersFilter} from '../../../components/EntitiesFilters/components/SpaceOwnersFilter';
import {ModifiedDateFilter} from '@src/components/EntitiesFilters/components/ModifedDateFilter';

interface IOwnerAcceptancesFiltersProps {
	objectId?: string;
}

export const OwnerAcceptancesFilters = ({objectId = 'all'}: IOwnerAcceptancesFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IOwnerAcceptancesFiltersState>();

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="owner-acceptances"
		>
			{objectId === 'all' && <ObjectsFilter value={state?.objects}/>}

			<StatusesFilter statuses={state?.statuses}/>

			<ResolutionsFilter resolutions={state?.resolutions}/>

			<SpaceOwnersFilter owners={state?.spaceOwners}/>

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
			/>

			<ModifiedDateFilter
				from={state?.modifiedAtFrom}
				to={state?.modifiedAtTo}
			/>
		</EntitiesFilters>
	);
};
