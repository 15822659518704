import React, {useCallback} from 'react';
import classNames from 'classnames';
import './ObjectItem.less';
import {ObjectImage} from './components/ObjectImage';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useObjectsPermissions} from '@src/core/hooks/permissions/useObjectsPermissions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAddingObjectDialog} from '@src/components/AddingObjectDialog/hooks/useAddingObjectDialog';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteObject} from '@src/store/modules/entities/object/actions';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {ObjectStages} from './components/ObjectStages';
import ProblemsCount from '@src/components/tableCells/ProblemsCount';

const openIcon = <i className="tz-object-24"/>;
const addIcon = <i className="tz-plus-20"/>;
const deleteIcon = <i className="tz-delete"/>;

interface IObjectItemProps {
	className?: string;
	object: IParentObject;
}

export const ObjectItem = ({className, object}: IObjectItemProps) => {
	const {pushPath} = useChangePath();
	const {previewImage, fullImage} = object;
	const image = previewImage || fullImage;
	const dispatch = useAppDispatch();

	const permissions = useObjectsPermissions();

	const classes = classNames('object-item', className);
	const imgStyles = {} as React.CSSProperties;
	if (image) {
		imgStyles.backgroundImage = `url(${image.url})`;
	}

	const [addingDialog, openAddingDialog] = useAddingObjectDialog(object.id, object.companyId);
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить объект?'
	);

	const handleClick = useCallback(() => {
		if (object.children && object.children.length) {
			return pushPath(`/objects/${object.id}`);
		}
		return pushPath(`/objects/${object.id}/problems`);
	}, [object]);

	const handleView = useCallback(() => {
		pushPath(`/objects/${object.id}/view`);
	}, [object.id]);

	const handleAdd = useCallback(() => {
		openAddingDialog();
	}, [openAddingDialog]);

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteObject(object.id));
		}
	}, [object.id]);

	const menuActions = [];
	if (permissions.canView) {
		menuActions.push(
			<MenuItem
				key="view"
				icon={openIcon}
				onClick={handleView}
			>
				Открыть
			</MenuItem>
		);
	}
	if (permissions.canAdd) {
		menuActions.push(
			<MenuItem
				key="add"
				icon={addIcon}
				onClick={handleAdd}
			>
				Добавить объект
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		menuActions.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}
	let menuBtn;
	if (menuActions.length) {
		menuBtn = <IconMenu className="object-item__footer-menu">{menuActions}</IconMenu>;
	}

	return (
		<div className={classes}>
			<ObjectImage
				image={image?.url}
				title={object.name}
				onClick={handleClick}
			/>

			<div className="object-item__footer">
				<div
					className="object-item__footer-title"
					title={object.name}
				>
					{object.name}
				</div>

				{menuBtn}
				<ObjectStages object={object}/>
				<div className="object-item__footer-problems">
					<ProblemsCount object={object}/>
				</div>
			</div>
			{permissions.canAdd && addingDialog}
			{permissions.canDelete && deleteDialog}
		</div>
	);
};
