import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as acceptanceTypes from '../../entities/ownerAcceptance/constants';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {
	IAddOwnerAcptPayload,
	IDeleteOwnerAcptPayload
} from '@src/store/modules/entities/ownerAcceptance/actions';
import {IGetOneUserOwnerAcceptancePayload} from './actions/getOne';

export type ILatestUserOwnerAcptsState = ILoadedEntityState<IOwnerAcceptance>;

export default createReducer<ILatestUserOwnerAcptsState>(
	getLoadedEntitiesInitialState<IOwnerAcceptance>(),
	{
		[types.GET_SUCCESS]: getLoadedEntitiesSuccess<IOwnerAcceptance>(),
		[types.GET_ONE_SUCCESS]: (
			state,
			{payload}: {payload: IGetOneUserOwnerAcceptancePayload}
		) => {
			if (payload && !state.allIds.includes(payload.id)) {
				state.allIds.push(payload.id);
			}
			state.byId[payload.id] = payload;
		},
		[acceptanceTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddOwnerAcptPayload}) => {
			state.allIds.unshift(payload.id);
			state.byId[payload.id] = payload;
		},
		[acceptanceTypes.DELETE_SUCCESS]: (
			state,
			{payload}: {payload: IDeleteOwnerAcptPayload}
		) => {
			if (state.allIds.includes(payload.acceptanceId)) {
				const byId = {...state.byId};
				delete byId[payload.acceptanceId];
				state.byId = byId;
				state.allIds = state.allIds.filter(id => id !== payload.acceptanceId);
			}
		}
	}
);
