import React from 'react';
import {DownloadableFile} from '@tehzor/ui-components';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';

interface IDocumentsProps {
	// objectId: string;
	warrantyClaim?: IWarrantyClaim;
}

const Documents = ({warrantyClaim}: IDocumentsProps) => (
	<div className="warranty-claim-page__documents">
		{warrantyClaim?.attachments?.map(item =>
			(item.full ? (
				<div
					key={item.id}
					className="warranty-claim-page__documents-file"
				>
					<DownloadableFile
						name={item.full.name}
						url={item.full.url}
						type={item.full.type}
						size={item.full.size}
					/>
				</div>
			) : null))}
	</div>
);
export default Documents;
