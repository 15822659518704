import React from 'react';
import './TabletSidebar.less';
import classNames from 'classnames';
import {AnimatePresence, motion, Variants} from 'framer-motion';
import {Scrollbar} from '../../containers/Scrollbar';
import MenuCloseButton from '../../buttons/MenuCloseButton';
import Portal from '../../containers/Portal';
import Overlay from '../../containers/Overlay';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = !!md.mobile();

const sidebarVariants: Variants = {
	initial: {width: 56},
	expanded: {width: 272}
};

const sidebarTransition = {type: 'tween', duration: 0.2, ease: [0.32, 0.72, 0.37, 0.95]};

const overlayVariants: Variants = {
	initial: {opacity: 0},
	visible: {opacity: 1},
	hidden: {opacity: 0}
};

const overlayTransition = {type: 'tween', duration: 0.2, ease: 'easeInOut'};

const userInfoTransition = {
	layoutX: {duration: 0},
	layoutY: {duration: 0.2}
};

const appInfoVariants = {
	hidden: {opacity: 0},
	visible: {opacity: 1}
};

const appInfoTransition = {type: 'tween', duration: 0.2};

interface ITabletSidebarProps {
	className?: string;
	style?: React.CSSProperties;
	menu?: React.ReactNode;
	indicators?: React.ReactNode;
	userInfo?: React.ReactNode;
	appInfo?: React.ReactNode;
	appRoot?: HTMLElement;
	visible: boolean;

	onVisibilityChange(visible: boolean): void;
}

interface ITabletSidebarState {
	animating?: boolean;
}

class TabletSidebar extends React.PureComponent<ITabletSidebarProps, ITabletSidebarState> {
	static displayName = 'TabletSidebar';

	/**
	 * Dom-элемент container
	 */
	private container?: HTMLDivElement;

	private completedAnimations = 0;

	/**
	 * Resolve-функция promise'ов при открытии/закрытии
	 */
	private animResolveFn?: () => void;

	constructor(props: ITabletSidebarProps) {
		super(props);
		this.state = {animating: false};
	}

	componentDidUpdate(prevProps: ITabletSidebarProps) {
		if (prevProps.visible !== this.props.visible) {
			if (this.props.visible) {
				this.disableScroll();
			} else {
				this.enableScroll();
			}
		}
	}

	render() {
		const {className, style, menu, indicators, userInfo, appInfo, appRoot, visible} = this.props;
		const {animating} = this.state;

		return (
			<>
				<div
					className={classNames(
						't-sidebar',
						{'t-sidebar_expanded': visible},
						{'t-sidebar_on-top': visible || animating},
						className
					)}
					style={style}
				>
					<div className="t-sidebar__menu-btn-wrap">
						<MenuCloseButton
							active={visible}
							onClick={visible ? this.close : this.open}
						/>
					</div>

					<motion.div
						className="t-sidebar__anim-area"
						animate={visible ? 'expanded' : 'initial'}
						variants={sidebarVariants}
						transition={sidebarTransition}
						onAnimationComplete={this.handleAnimationComplete}
					>
						<Scrollbar
							className="t-sidebar__cont"
							ref={this.saveContRef}
						>
							<div className="t-sidebar__menu-wrap">{menu}</div>

							<motion.div
								className="t-sidebar__sliding-area"
								layout
								transition={userInfoTransition}
							>
								<div className="t-sidebar__indicators">{indicators}</div>
								<div className="t-sidebar__user-info">{userInfo}</div>
							</motion.div>

							{visible && (
								<motion.div
									className="t-sidebar__app-info-wrap"
									initial="hidden"
									animate="visible"
									variants={appInfoVariants}
									transition={appInfoTransition}
								>
									{appInfo}
								</motion.div>
							)}
						</Scrollbar>
					</motion.div>

					<div className="t-sidebar__toggle-area"/>
				</div>

				<Portal
					className="t-sidebar-portal"
					root={appRoot}
				>
					<AnimatePresence initial={false}>
						{visible && (
							<Overlay
								className="t-sidebar-overlay"
								variants={overlayVariants}
								transition={overlayTransition}
								onClick={this.close}
								onAnimationComplete={this.handleAnimationComplete}
							/>
						)}
					</AnimatePresence>
				</Portal>
			</>
		);
	}

	/**
	 * Открывает sidebar
	 */
	open = () => {
		const {visible, onVisibilityChange} = this.props;
		if (!visible) {
			this.completedAnimations = 0;
			this.setState({animating: true});
			onVisibilityChange(true);

			return new Promise<void>(resolve => {
				if (this.animResolveFn) {
					this.animResolveFn();
				}
				this.animResolveFn = resolve;
			}).then(() => {
				this.animResolveFn = undefined;
			});
		}
		return Promise.resolve();
	};

	/**
	 * Закрывает sidebar
	 */
	close = () => {
		const {visible, onVisibilityChange} = this.props;
		if (visible) {
			this.completedAnimations = 0;
			this.setState({animating: true});
			onVisibilityChange(false);

			return new Promise<void>(resolve => {
				if (this.animResolveFn) {
					this.animResolveFn();
				}
				this.animResolveFn = resolve;
			}).then(() => {
				this.animResolveFn = undefined;
			});
		}
		return Promise.resolve();
	};

	private handleAnimationComplete = () => {
		if (++this.completedAnimations === 2) {
			this.completedAnimations = 0;
			this.setState({animating: false});
			if (this.animResolveFn) {
				this.animResolveFn();
			}
		}
	};

	/**
	 * Блокирует скролл body
	 *
	 * @private
	 */
	private disableScroll = () => {
		if (isMobile && this.container) {
			disableBodyScroll(this.container);
		}
	};

	/**
	 * Разлокировывает скролл body
	 *
	 * @private
	 */
	private enableScroll = () => {
		if (isMobile && this.container) {
			enableBodyScroll(this.container);
		}
	};

	private saveContRef = (element: HTMLDivElement) => {
		this.container = element;
	};
}

export default TabletSidebar;
