/**
 * Возвращает индекс для рекурсивного дерева
 * 
 * @param string созданный ранее индекс
 * @param num номер объекта
 * @param isChildren является ли объект вложенным
 */
export const createRecursiveIndex = (string: string, num: number, isChildren?: boolean) =>
(!isChildren
	? `${num + 1}.`
	: `${string
			.split('')
			.filter((str, i, arr) => !(arr.length - 1 === i && str === '.'))
			.join('')}.${num + 1}`);
