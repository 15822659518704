import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {ITreeSetDataItem} from '@tehzor/ui-components/src/components/inputs/custom/CategorySelect';

const makeCategoriesTreeData = (
	treeData: ITreeSetDataItem[],
	categories?: ICategory[],
	categoriesSetId?: string
) => {
	if (!categories) {
		return treeData;
	}

	categories.forEach(category =>
		treeData.push({
			id: category.id,
			content: category.name,
			parentId: category.parentId || categoriesSetId,
			categoriesSetId
		}));

	return treeData;
};

const makeTreeData = (
	sets: ICategoriesSet[] = [],
	allCategories: {[setId: string]: ICategory[]},
	disabled = false
): ITreeSetDataItem[] => {
	let treeData: ITreeSetDataItem[] = [];

	if (sets?.length === 1) {
		const set = sets[0];
		const categories = allCategories[set.id];
		treeData = makeCategoriesTreeData(treeData, categories, set.id);
		return treeData;
	}

	for (const set of sets) {
		const categories = allCategories[set.id];
		const treeSetItem: ITreeSetDataItem = {
			id: set.id,
			content: set.name,
			parentId: undefined,
			nonCheckable: disabled,
			total: categories?.length || 0,
			onlySemiCheckable: true
		};
		treeData.push(treeSetItem);
		treeData = makeCategoriesTreeData(treeData, categories, set.id);
	}
	return treeData;
};

export default makeTreeData;
