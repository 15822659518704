import React, {Dispatch} from 'react';
import './EditableSpaceOwner.less';
import Text from '../editableFields/Text';
import {
	IEditableSpaceOwnerAction,
	IEditableSpaceOwnerState
} from '@src/core/hooks/states/useEditableSpaceOwnerState';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';

interface IEditableOwnerProps {
	editingState: IEditableSpaceOwnerState;
	editingDispatch: Dispatch<IEditableSpaceOwnerAction>;
	saving: boolean;
	fieldsSettings: Record<string, IObjectFieldSetting>;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableSpaceOwner = (props: IEditableOwnerProps) => {
	const {editingState, editingDispatch, saving, fieldsSettings} = props;

	return (
		<div className="editable-space-owner">
			{fieldsSettings.name !== undefined && (
				<Text
					field="name"
					label="ФИО"
					value={editingState.name}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.name.isRequired}
					hasError={editingState.errors.name}
					className="editable-space-owner__field"
				/>
			)}
			<div className="editable-space-owner__phone-wrapper">
				{fieldsSettings.phone !== undefined && (
					<Text
						field="phone"
						label="Номер телефона"
						value={editingState.phone}
						editingDispatch={editingDispatch}
						disabled={saving}
						required={fieldsSettings.phone.isRequired}
						hasError={editingState.errors.phone}
						className="editable-space-owner__field"
						errorMessage="Введите номер телефона"
					/>
				)}
				{fieldsSettings.additionalPhone !== undefined && (
					<Text
						field="additionalPhone"
						label="Дополнительный номер телефона"
						value={editingState.additionalPhone}
						editingDispatch={editingDispatch}
						disabled={saving}
						required={fieldsSettings.additionalPhone.isRequired}
						hasError={editingState.errors.additionalPhone}
						className="editable-space-owner__field"
						errorMessage="Введите дополнительный номер телефона"
					/>
				)}
			</div>
			{fieldsSettings.email !== undefined && (
				<Text
					field="email"
					label="Email"
					value={editingState.email}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.email.isRequired}
					hasError={editingState.errors.email}
					className="editable-space-owner__field"
					errorMessage="Введите email"
				/>
			)}
			{fieldsSettings.additionalEmail !== undefined && (
				<Text
					field="additionalEmail"
					label="Дополнительный Email"
					value={editingState.additionalEmail}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.additionalEmail.isRequired}
					hasError={editingState.errors.additionalEmail}
					className="editable-space-owner__field"
					errorMessage="Введите дополнительный email"
				/>
			)}
			{fieldsSettings.comment !== undefined && (
				<Text
					field="comment"
					label="Комментарий"
					value={editingState.comment}
					editingDispatch={editingDispatch}
					disabled={saving}
					required={fieldsSettings.comment.isRequired}
					hasError={editingState.errors.comment}
					className="editable-space-owner__field"
					errorMessage="Введите комментарий"
				/>
			)}
		</div>
	);
};

export default EditableSpaceOwner;
