import React, {useCallback, useState} from 'react';
import './EditableAttachmentsDialog.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {editWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import {useEditableWarrantyClaimAttachments} from '../EditableWarrantyClaimAttachments/hooks/useEditableWarrantyClaimAttachments';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';

interface IEditableWarrantyClaimAttachmentsDialogProps {
	objectId: string;
	warrantyClaimId: string;
	warrantyClaim: IWarrantyClaim;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования вложений обращения
 */
const EditableWarrantyClaimAttachmentsDialog = ({
	objectId,
	warrantyClaimId,
	warrantyClaim,
	isOpen,
	onClose
}: IEditableWarrantyClaimAttachmentsDialogProps) => {
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableWarrantyClaimAttachments(
		objectId,
		warrantyClaim,
		saving
	);
	const save = useCallback(async () => {
		setSaving(true);
		if (warrantyClaimId) {
			const savingData = await getSavingData();
			if (savingData) {
				if (!networkStatus) {
					// Локальное сохранение
					// const savingData = convertToSave(editingState, problem, true);
					// savingData.newAttachments = convertToLocalSave(files);
					// TODO Локальное сохранение
				} else {
					try {
						await dispatch(editWarrantyClaim(objectId, warrantyClaimId, savingData));
						onClose();
					} catch (error) {
						console.log(error);
						// TODO Локальное сохранение
					}
				}
			}
		}
		setSaving(false);
	}, [networkStatus, objectId, warrantyClaimId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-attachments-dialog"
			isOpen={isOpen}
			title="Редактировать вложения"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};

export default EditableWarrantyClaimAttachmentsDialog;
