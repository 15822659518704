import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IAddProblemTagResponse, makeProblemTagAddRequest} from '@src/api/backend/problemTags';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';

export type IAddProblemTagPayload = IAddProblemTagResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (setId: string, response: IAddProblemTagResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении метки');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет 1 метку за раз
 *
 * @param problemTagsSetId id набора
 * @param tag данные для добавления
 */
export const addProblemTag = (problemTagsSetId: string, tag: ISavingProblemTag) =>
	createApiAction<IAddProblemTagResponse>(
		request,
		(res: IAddProblemTagResponse) => success(res.problemTagsSetId, res),
		failure,
		() => makeProblemTagAddRequest(problemTagsSetId, tag)
	);
