import React from 'react';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MobileRightButtons from '@src/pages/SpacePage/components/actions/RightButtons.mobile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpace} from '@src/store/modules/entities/space/selectors';

export const useSpaceCheckListsHeader = (objectId: string) => {
	const space = useAppSelector(extractSpace);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes.byId);

	const isDesktop = useIsDesktop();

	const spaceTitle = useSpaceTitle(space);
	const title = isDesktop
		? space
			? `Чек-листы ${spaceTypes[space.type]?.name} №${space.name}`
			: 'Чек-листы'
		: spaceTitle;

	useAppHeader(
		{
			title,
			subTitle: isDesktop ? undefined : 'Чек-листы',
			showBackBtn: true,
			mobileRightButtons:
				!isDesktop && space ? (
					<MobileRightButtons
						objectId={objectId}
						spaceId={space.id}
						space={space}
					/>
				) : null
		},
		[space]
	);
};
