import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {offlineJournalActionType} from '../enums/actionsTypes';
import {IChangedRecord} from '@src/api/cache/checkRecords/defaults/useCheckRecordsMutationDefaults';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';

export const useLocalCheckRecordsChangesJournal = () => {
	const {data} = useQuery<IChangedRecord[], unknown, IOfflineJournalEntity[]>({
		queryKey: checkRecordsQueryKeys.localList(),
		select: records =>
			records.map(record => {
				let entityType = offlineJournalActionType.CHECK_LIST_STATUS_CHANGE;
				if (record.type === 'checkitem') {
					entityType = offlineJournalActionType.CHECK_ITEM_STATUS_CHANGE;
				}
				return {
					entity: {
						entityType,
						newRecordStatus: record.journalData.newStatus,
						checkListName: record.journalData.checkListName,
						checkItemName: record.journalData.checkItemName
					},
					object: {
						objectId: record.objectId,
						objectName: record.journalData.objectName,
						locationName: record.journalData.locationName
					},
					transfer: {
						status: record.transferStatus,
						statusMessage: record.transferStatus
							? offlineModeTransferTitles[record.transferStatus]
							: ''
					},
					createdAt: Number(record.key)
				} as IOfflineJournalEntity;
			})
	});
	return data || [];
};
