import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IObjectStageSelectProps<S, E> {
	field?: keyof S;
	value?: ObjectStageIds;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	errorMessage?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: ObjectStageIds | undefined,
	types: IObjectStage[],
	typesMap: Record<string, IObjectStage>
) => {
	if (!value) {
		return undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const ObjectStageSelect = <
	S extends {
		stage?: ObjectStageIds;
	},
	E
>({
	className,
	style,
	field = 'stage',
	label = 'Стадия объекта',
	errorMessage = 'Выберите стадию объекта',
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: IObjectStageSelectProps<S, E>) => {
	const types = useAppSelector(extractObjectStagesAsArray);
	const typesMap = useAppSelector(s => s.dictionaries.objectStages.byId);
	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, types, typesMap)}
						icon={<i className="tz-simple-arrow-20"/>}
						error={required && hasError ? errorMessage : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{types.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
