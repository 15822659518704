import {ISavingSpace} from '@tehzor/tools/interfaces/spaces/ISavingSpace';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IAddSpaceResponse, makeSpaceAddRequest} from '@src/api/backend/space';

export type IAddSpacePayload = IAddSpaceResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddSpaceResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении помещения');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет помещение
 *
 * @param objectId id объекта
 * @param fields измененные поля
 */
export const addSpace = (objectId: string, fields: ISavingSpace = {}) =>
	createApiAction<IAddSpaceResponse>(request, success, failure, () =>
		makeSpaceAddRequest(objectId, fields));
