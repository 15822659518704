import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeCheckDeleteRequest} from '@src/api/backend/check';
import {queryClient} from '@src/api/QueryClient';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';

export interface IDeleteCheckPayload {
	objectId: string;
	checkId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, checkId: string, quietly?: boolean) => {
	if (!quietly) {
		addInfoToast('Удалено', 'Проверка успешно удалена');
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			checkId
		}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении проверки');
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

export const deleteCheckActions = {request, success, failure};

/**
 * Удаляет проверку
 *
 * @param objectId id объекта
 * @param checkId id проверки
 */
export const deleteCheck = (objectId: string, checkId: string) =>
	createApiAction(
		request,
		() => success(objectId, checkId),
		failure,
		() => {
			void queryClient.invalidateQueries(checksQueryKeys.list());
			void queryClient.invalidateQueries(checksQueryKeys.latest());
			void queryClient.invalidateQueries(problemsQueryKeys.list());
			void queryClient.invalidateQueries(inspectionsQueryKeys.list());
			return makeCheckDeleteRequest(objectId, checkId);
		}
	);
