import {wsConnector} from '../wsConnector';

export type IDeleteObjectResponse = void;

/**
 * Удаляет объект
 *
 * @param objectId id объекта
 */
export const makeObjectDeleteRequest = (objectId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteObjectResponse>('deleteObject', {objectId});
