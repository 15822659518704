import {
	IChecksFiltersState,
	IChecksSortState
} from '@src/store/modules/settings/pages/checks/reducers';

export type IChecksListQueryKey = [] & {
	0: string;
	1: string;
	2: IChecksFiltersState;
	3: IChecksSortState;
	4: number;
	5: number;
};

export const checksQueryKeys = {
	all: () => ['checks'],
	one: () => ['check'],
	latest: () => ['latest-checks'],
	list: () => [...checksQueryKeys.all(), 'list'],
	localList: () => [...checksQueryKeys.all(), 'local-list'],
	add: () => [...checksQueryKeys.one(), 'add'],
	details: () => [...checksQueryKeys.one(), 'detail'],
	detail: (id: string) => [...checksQueryKeys.one(), 'detail', id]
};
