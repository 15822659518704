import React, {createContext, useCallback, useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractWorkAcceptancesAsArray,
	extractWorkAcceptancesData
} from '@src/store/modules/entities/workAcceptances/selectors';
import {
	EntitiesTable,
	PaginationPageSize,
	Pagination,
	Plate,
	PaginationAndSize
} from '@tehzor/ui-components';
import {extractWorkAcceptancesPageSettings} from '@src/store/modules/settings/pages/workAcceptances/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changePageSize,
	changeSort
} from '@src/store/modules/settings/pages/workAcceptances/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {
	changeOffset,
	getWorkAcceptances,
	changeSelectedRows
} from '@src/store/modules/entities/workAcceptances/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {mobileColumns, mobileColumnsWithoutObject} from './mobileColumns';
import {desktopColumns, desktopColumnsWithoutObject} from './desktopColumns';
import {convertWorkAcceptances} from '../../utils/convertWorkAcceptances';
import {IPreparedWorkAcceptance} from '../../interfaces/IPreparedWorkAcceptance';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions/delete';

const pageSizes = [10, 20, 50, 100];

interface IWorkAcceptancesTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedWorkAcceptance>) => void
>(() => ({}));

export const WorkAcceptancesTable = ({objectId = 'all'}: IWorkAcceptancesTableProps) => {
	const {pushPath} = useChangePath();
	const workAcceptances = useAppSelector(s => extractWorkAcceptancesAsArray(s, objectId));
	const workAcceptancesData = useAppSelector(s => extractWorkAcceptancesData(s, objectId));
	const pageSettings = useAppSelector(s => extractWorkAcceptancesPageSettings(s, objectId));
	const statusesMap = useAppSelector(s => s.dictionaries.workAcceptanceStatuses.byId);
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	const pagesCount = Math.ceil(workAcceptancesData.total / pageSettings.pageSize);
	const currentPage = Math.floor(workAcceptancesData.offset / pageSettings.pageSize);

	const preparedWorkAcceptances = useMemo(
		() => convertWorkAcceptances(workAcceptances, statusesMap, networkStatus, user),
		[workAcceptances, networkStatus, user, statusesMap]
	);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить приёмку работ?'
	);

	const [, loadWorkAcceptances] = useAsyncFn(
		() => dispatch(getWorkAcceptances(objectId)),
		[objectId]
	);

	const handleRowClick = useCallback((workAcceptance: IPreparedWorkAcceptance) => {
		if (workAcceptance.object?.id) {
			pushPath(`/objects/${workAcceptance.object.id}/work-acceptances/${workAcceptance.id}`);
		}
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => {
			dispatch(changeSelectedRows(objectId, value));
		},
		[objectId, dispatch]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort(objectId, value));
			void loadWorkAcceptances();
		},
		[objectId, loadWorkAcceptances, dispatch]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(objectId, value));
			dispatch(changeOffset(objectId, Math.floor(workAcceptancesData.offset / value)));
			void loadWorkAcceptances();
		},
		[objectId, workAcceptancesData.offset, dispatch, loadWorkAcceptances]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (workAcceptancesData.offset !== offset) {
				dispatch(changeOffset(objectId, offset));
				void loadWorkAcceptances();
			}
		},
		[objectId, workAcceptancesData.offset, pageSettings.pageSize, dispatch, loadWorkAcceptances]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedWorkAcceptance>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await dispatch(
						deleteWorkAcceptance(action.payload.object?.id, action.payload.id)
					);
					await loadWorkAcceptances();
				}
			}
		},
		[objectId]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		desktopColumns,
		desktopColumnsWithoutObject
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={preparedWorkAcceptances}
						selectedRows={workAcceptancesData.selectedRows}
						sort={pageSettings.sort}
						selectable
						responsive={!isLargeTablet}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>

			{deleteDialog}
		</>
	);
};
