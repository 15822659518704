import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';

interface IDescriptionProps {
	className?: string;
	start?: number;
	stop?: number;
}

const IntervalView = ({className, start, stop}: IDescriptionProps) => (
	<EntityGridItem
		className={className}
		icon={<i className="tz-clock"/>}
		label="Срок исполнения"
		fullRow
	>
		<div style={{display: 'grid', gridTemplateColumns: '30px 1fr'}}>
			<span>С</span>
			<span>{start && format(start, dateTimeCommaSeparatedFormat)}</span>
			<span>По</span>
			<span>{stop && format(stop, dateTimeCommaSeparatedFormat)}</span>
		</div>
	</EntityGridItem>
);

export default IntervalView;
