import {IProblemAvailableField} from '@src/utils/testRestrictions';
import {useEntitySettings} from '../../../core/hooks/useEntitySettings';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';

export type IProblemSettings = Partial<
	Record<IProblemAvailableField, IObjectFieldSetting>
>;

const objectFields: Array<keyof IObjectFieldsSettings> = [
	'buildingProblem',
	'acceptanceProblem',
	'acceptanceClaimProblem',
	'warrantyClaimProblem'
];

export const useProblemSettings = (
	objectId?: string
): IProblemSettings => useEntitySettings(objectId || 'all', objectFields);