import _ from 'lodash';
import {createSelector} from 'reselect';
import {IState} from '../..';

export const extractAllLatestWarrantyClaimsAsArray = createSelector(
	(state: IState) => state.dictionaries.latestUserWarrantyClaims,
	({byId, allIds}) => allIds.map(id => byId[id])
);

/**
 * Возвращает последние warrantyClaims для конкретного объекта в виде массива
 */
export const extractLatestWarrantyClaimsAsArray = createSelector(
	extractAllLatestWarrantyClaimsAsArray,
	(state: IState) => state.auth.profile.id,
	(state: IState, objectId: string | undefined) => objectId,
	(state: IState, objectId: string | undefined, spaceId?: string) => spaceId,
	(arr, userId, objectId, spaceId) =>
		arr.filter(item =>
			item.objectId === objectId
			&& item.createdBy?.id === userId
			&& (!spaceId || spaceId === item.links?.spaceId))
);

/**
 * Возвращает последнее гарантийное обращение для конкретного объекта
 */

/**
 * Возвращает Id последнего локального гарантийного обращения для конкретного помещения
 */
export const extractLatestLocalWarrantyClaim = createSelector(
	(state: IState) => state.localEntities.warrantyClaims,
	(state: IState, spaceId: string | undefined) => spaceId,
	(data, spaceId): string | undefined => {
		if (!data.length || !spaceId) {
			return undefined;
		}
		const warrantyForSpace = data.filter(({links}) => links?.spaceId === spaceId);
		if (warrantyForSpace.length) {
			return warrantyForSpace[warrantyForSpace.length - 1].key;
		}
		return undefined;
	}
);

export const extractLocalWarrantyClaimsAsArray = createSelector(
	(state: IState) => state.localEntities.warrantyClaims,
	(state: IState, objectId: string) => objectId,
	(state: IState, objectId: string, spaceId?: string) => spaceId,
	(data, objectId, spaceId) => {
		if (spaceId) {
			return data.filter(item => item.links?.spaceId === spaceId);
		}
		return data.filter(item => item.objectId === objectId);
	}
);
export const extractLatestWarrantyClaimId = createSelector(
	extractLatestWarrantyClaimsAsArray,
	extractLocalWarrantyClaimsAsArray,
	(data, local): string | undefined => {
		if (local.length) {
			return _.maxBy(local, 'modifiedAt')?.key;
		}
		if (data.length) {
			return _.maxBy(data, 'modifiedAt')?.id;
		}
		return undefined;
	}
);
