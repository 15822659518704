import React from 'react';
import './DocumentPage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from './components/Desktop';
import {useParams} from 'react-router-dom';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
// import {Mobile} from './components/Mobile';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {formDocumentTitle} from '@tehzor/tools/utils/formDocumentTitle';
import {useDocument} from '@src/core/hooks/queries/documents';

export const DocumentPage = () => {
	useScrollRestoration();
	const {documentId} = useParams<{documentId: string}>();
	const document = useDocument(documentId);
	useAppHeader({title: document ? formDocumentTitle(document) : undefined}, [document]);
	const isDesktop = useIsDesktop();

	return (
		<div className="page-cont document-page">
			<PageBreadcrumbs document={document} />

			{isDesktop ? <Desktop document={document} /> : <Desktop document={document} />}
		</div>
	);
};
