import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import SelectionActions from './SelectionActions';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

const SelectionRow = (props: ISelectionRowProps<IEnrichedTask>) => {
	const {selectedFlatRows, toggleAllRowsSelected} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				selectedEntities={selectedFlatRows.map(item => item.original)}
				selectedClearing={clearSelection}
			/>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;