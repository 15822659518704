import React, {memo, useCallback, useMemo, useState} from 'react';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption, SelectPopup, SelectSearch} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {spaceOwnersAsArray, useSpaceOwners} from '@src/core/hooks/queries/spaceOwners';

interface IOwnersFilterProps {
	owners?: string[];
}

export const SpaceOwnersFilter = memo(({owners}: IOwnersFilterProps) => {
	const [selectedOwners, setSelectedOwners] = useState(owners);
	const [search, setSearch] = useState('');

	const spaceOwners = useSpaceOwners();
	const allOwners = spaceOwnersAsArray(spaceOwners);

	const {dispatch} = useEntitiesFiltersCtx();

	const clearSearch = useCallback(() => setSearch(''), []);
	const filteredData = useMemo(() => flatFilter(allOwners, 'name', search), [allOwners, search]);

	const handleApply = useCallback(() => {
		dispatch({spaceOwners: selectedOwners});
		clearSearch();
	}, [selectedOwners, clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedOwners([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedOwners([]);
		dispatch({spaceOwners: undefined});
		clearSearch();
	}, [clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedOwners(owners);
		clearSearch();
	}, [owners, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedOwners(allOwners.map(val => val.id));
	}, [allOwners, clearSearch]);

	useUpdateEffect(() => {
		setSelectedOwners(owners);
	}, [owners]);

	return (
		<div>
			<SelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				onSelectAll={handleSelectAll}
				clearButton={!!selectedOwners?.length}
				count={selectedOwners?.length}
				footer
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel('Собственник', owners, allOwners)}
						active={!!owners?.length}
						onClear={handleFullClear}
					/>
				)}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
						type="popup"
					/>
				)}
			>
				<Select2
					multiple
					value={selectedOwners}
					onChange={setSelectedOwners}
				>
					{filteredData.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
});