import {wsConnector} from '@src/api/backend/wsConnector';
import {IStructuresStatsDataRequest} from '@src/interfaces/IStructuresStatsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export type IGetStructuresProblemsResponse = IEntityProblemsData;

/**
 * Возвращает статистику нарушений по структурам
 */
export const requestStructuresProblemsData = (requestData: IStructuresStatsDataRequest) =>
	wsConnector.sendAuthorizedRequest<IGetStructuresProblemsResponse>('getStructuresProblemsData', {
		objectId: requestData.objectId,
		structuresIds: requestData.structuresIds
	});
