import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import CompanyStats from './CompanyStats';
import {
	aggregateByCompanies,
	aggregateByObjects
} from '@src/store/modules/statistics/warranty/selectors/creation';
import {useFilteredCompanies} from '@src/pages/StatisticsPage/hooks/useFilteredCompanies';
import {areCreationStatsLoading} from '@src/store/modules/statistics/ui/selectors/warranty';
import StatsTreePlaceholder from '../../StatsTreePlaceholder';
import CommonStatsCards from './CommonStatsCards';
import {isWarrantyFiltersSetUp} from '@src/store/modules/settings/pages/statistics/warranty/selectors/filters';

const ByCompaniesStats = () => {
	const statsByCompanies = useAppSelector(aggregateByCompanies);
	const statsByObjects = useAppSelector(aggregateByObjects);
	const filters = useAppSelector(s => s.settings.pages.statistics.warranty.filters);
	const isFiltersSetUp = useAppSelector(isWarrantyFiltersSetUp);
	const companies = useFilteredCompanies(
		statsByCompanies,
		statsByObjects,
		filters,
		isFiltersSetUp
	);
	const loading = useAppSelector(areCreationStatsLoading);

	return (
		<>
			{companies.map(({company, companyStats, objects}) => (
				<CompanyStats
					key={company.id}
					company={company}
					companyStats={companyStats}
					companyObjects={objects}
					objectsStats={statsByObjects}
				/>
			))}

			{loading && companies.length === 0 && (
				<StatsTreePlaceholder
					includeChildren
					permanentContent={<CommonStatsCards/>}
				/>
			)}
		</>
	);
};

export default ByCompaniesStats;
