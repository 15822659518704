import React, {useMemo} from 'react';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useEditableResultWorkAcceptanceDialog} from '@src/components/EditableResultWorkAcceptanceDialog/hooks/useEditableResultWorkAcceptanceDialog';
import {makeUnitStr, unitsAsArray, useUnits} from '@src/core/hooks/queries/units';
import {DesktopInfo} from './info/Info.desktop';
import {DesktopRoutes} from './routes/Routes.desktop';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {DesktopControls} from './controls/Controls.desktop';

interface IDesktopProps {
	objectId: string;
	checkLists: ICheckList[];
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
}

export const Desktop = ({objectId, workAcceptance, checkLists, permissions}: IDesktopProps) => {
	// Диалог редактирования приёмки работ
	const [resultWorkAcceptanceDialog, openEditResultDialog]
		= useEditableResultWorkAcceptanceDialog(
			workAcceptance.objectId,
			workAcceptance.stage,
			workAcceptance
		);

	const units = useUnits();
	const unitsArr = unitsAsArray(units);
	const unitValue = workAcceptance?.physicalWorkScope?.value;
	const unitId = workAcceptance?.physicalWorkScope?.unitId;

	const finalUnitStr = useMemo(() => makeUnitStr(unitsArr, unitValue, unitId),
		[unitsArr, unitValue, unitId]);

	const sectionsMenu = [
		{
			url: `/objects/${objectId}/work-acceptances/${workAcceptance.id}`,
			label: 'Описание'
		},
		{
			url: `/objects/${objectId}/work-acceptances/${workAcceptance.id}/problems`,
			label: 'Нарушения'
		},
		{
			url: `/objects/${objectId}/work-acceptances/${workAcceptance.id}/check-lists`,
			label: 'Чек-листы'
		}
	];

	useAppHeader(
		{
			title: `Приёмка работ №${workAcceptance.number}`,
			showBackBtn: true,
			sectionsMenu
		},
		[workAcceptance]
	);

	return workAcceptance ? (
		<div className="page-cont work-acceptance-page">
			<div className="work-acceptance-page__d-controls">
				<div className="work-acceptance-page__d-controls-left">
					<PageBreadcrumbs
						objectId={objectId}
						workAcceptance={workAcceptance}
					/>
				</div>
				<div className="work-acceptance-page__d-controls-right">
					<DesktopControls
						workAcceptance={workAcceptance}
						checkLists={checkLists}
						objectId={objectId}
						permissions={permissions}
					/>
				</div>
			</div>

			<div className="work-acceptance-page__d-columns">
				<DesktopInfo
					openDialog={openEditResultDialog}
					workAcceptance={workAcceptance}
					unitString={finalUnitStr}
				/>
				<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
					<DesktopRoutes
						workAcceptance={workAcceptance}
						objectId={objectId}
						checkLists={checkLists}
					/>
				</div>
			</div>
			{permissions?.canEdit && resultWorkAcceptanceDialog}
		</div>
	) : null;
};
