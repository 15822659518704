import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants/index';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';

export interface ITasksPageSettingsState {
	displayMode: TasksDisplayMode;
}

export const getInitialStateForPage = (): ITasksPageSettingsState => ({
	displayMode: TasksDisplayMode.LIST
});

export const general = createReducer<ITasksPageSettingsState>(
	{displayMode: TasksDisplayMode.LIST},
	{
		[types.general.CHANGE_DISPLAY_MODE]: (
			state,
			{payload}: {payload: {displayMode: TasksDisplayMode}}
		) => ({
			displayMode: payload.displayMode
		})
	}
);
