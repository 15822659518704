import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, LocationSelect, TextField} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {InputType, ViewerType} from '@tehzor/ui-components/src/components/LocationSelect';
import {extractPlans, extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';
import {extractLocSelectDefaultSettings} from '@src/store/modules/settings/locationSelect/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeLocDefaultInputType,
	changeLocDefaultViewerType
} from '@src/store/modules/settings/locationSelect/actions';

interface ILocationProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	planId?: string;
	location?: ILocation;
	objectId: string;
	entityLocation?: ILocation;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const Location = <
	S extends {location?: ILocation | null, planId?: string | null, floor: string},
	E
>({
	className,
	style,
	label = 'Место',
	planId,
	location,
	objectId,
	entityLocation,
	editingDispatch,
	required,
	disabled,
	hasError
}: ILocationProps<S, E>) => {
	const plans = useAppSelector(s => extractPlansAsArray(s, objectId));
	const plansMap = useAppSelector(s => extractPlans(s, objectId).byId);
	const settings = useAppSelector(s => extractLocSelectDefaultSettings(s, objectId));
	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		(v: ILocation | null) => {
			editingDispatch({type: 'update', field: 'location', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'location'});
			}
		},
		[required]
	);

	const handleViewerTypeChange = useCallback(
		(v: ViewerType) => {
			dispatch(changeLocDefaultViewerType(objectId, v));
		},
		[objectId]
	);

	const handleInputTypeChange = useCallback(
		(v: InputType) => {
			dispatch(changeLocDefaultInputType(objectId, v));
		},
		[objectId]
	);

	const handlePlanChange = useCallback(
		(v?: string) => {
			editingDispatch({type: 'update', field: 'planId', value: v});
			// Автоматическая установка этажа из плана
			if (v) {
				const plan = plansMap[v];
				editingDispatch({
					type: 'update',
					field: 'floor',
					value: plan?.floor ? String(plan.floor) : ''
				});
			} else {
				editingDispatch({type: 'update', field: 'floor', value: ''});
			}
			if (required) {
				editingDispatch({type: 'update-error', field: 'planId'});
				editingDispatch({type: 'update-error', field: 'floor'});
			}
		},
		[required, plansMap]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<LocationSelect
				mode="edit"
				defaultViewerType={settings.defaultViewerType}
				defaultInputType={settings.defaultInputType}
				planId={planId}
				location={location}
				entityLocation={entityLocation}
				plans={plans}
				multiplePoints
				onLocationChange={handleChange}
				onViewerTypeChange={handleViewerTypeChange}
				onInputTypeChange={handleInputTypeChange}
				onPlanChange={handlePlanChange}
			>
				{(displayValue, open: () => void) => (
					<TextField
						className="text-field_interactive"
						disabled={disabled}
						elementType="div"
						value={displayValue}
						icon={<i className="tz-location-20"/>}
						error={required && hasError ? 'Укажите местоположение' : undefined}
						onClick={open}
					/>
				)}
			</LocationSelect>
		</div>
	);
};
