import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import {IAddExportTempaltePayload} from './actions/add';
import {
	getLoadableEntitiesInitialState,
	ILoadableEntitiesState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {IEditExportTemplatePayload} from './actions/edit';
import {IDeleteExportTemplatePayload} from './actions/delete';

export type IExportTemplatesState = ILoadableEntitiesState<IExportTemplate>;
export const exportTemplates = createReducer<IExportTemplatesState>(
	getLoadableEntitiesInitialState<IExportTemplate>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IExportTemplate>(),
		[types.ADD_SUCCESS]: (state, {payload}: {payload: IAddExportTempaltePayload}) => {
			payload.allIds.forEach(id => {
				state.byId[id] = payload.byId[id];
			});
			state.allIds.push(...payload.allIds);
			state.loaded = false;
			state.loadedAt = undefined;
		},
		[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditExportTemplatePayload}) => {
			payload.allIds.forEach(id => {
				state.byId[id] = payload.byId[id];
			});
			state.loaded = false;
			state.loadedAt = undefined;
		},
		[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteExportTemplatePayload}) => {
			const name = state.byId[payload.id].file?.s3Name;
			const templatesToDeleteIds = state.allIds
				.map(item => state.byId[item])
				.filter(el => el.file?.s3Name === name)
				.map(item => item.id);
			state.allIds = state.allIds.filter(item => !templatesToDeleteIds.includes(item));
			const byId = {...state.byId};
			templatesToDeleteIds.forEach(id => {
				delete byId[id];
			});
			state.byId = byId;
			state.loaded = false;
			state.loadedAt = undefined;
		},
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IExportTemplate>()
	}
);
