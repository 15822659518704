import generateKey from '@tehzor/tools/utils/generateKey';
import AbstractHandler, {IHandlerShape} from './AbstractHandler';
import transformSvgCoordinates from '@tehzor/tools/utils/transformSvgCoordinates';
import {Box, Circle, Container, Element, G} from '@svgdotjs/svg.js';
import '@svgdotjs/svg.draggable.js';
import {Mode, Path} from '../PlanViewer';
import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import IShape from '@tehzor/tools/interfaces/plans/IShape';

const unknownPointName = 'Место';

export interface ISelectablePoint extends ILocationMarker {
	key: string;
	selected: boolean;
	descriptionMarker?: Circle;
}

interface ICoordinates {
	x: number;
	y: number;
	rx?: number | undefined;
	ry?: number | undefined;
}

export type EntityMarkerType = 'structure' | undefined;

/**
 * Класс для отображения слоя с точками
 */
class PointsHandler extends AbstractHandler<ILocationMarker & ISelectablePoint> {
	// Текущее увеличение
	private _zoom: number;

	// Группа для скрытых фигур, необходимых для определения названия точки
	private _hiddenGroup: G;

	// Скрытые фигуры
	private _hiddenShapes: Array<IShape & IHandlerShape> = [];

	// Перетаскивание точек активно: true - вкл перетаскивание
	private _isPointDragActive = true;

	// Тип маркера родительской сущности
	private _entityMarkerType: EntityMarkerType;

	// SVG path маркера
	private _path: string;

	// ClassName маркера
	private _pointClassName: string;

	// смещенеи по Y
	private _yOffset = 0;

	private _pointsColor = '#FF8228';

	private _pointStartDragPos?: Box;

	// Доступно добавление точек
	private _isAddingActive = true;

	// Множественное или одиночное добавление точек
	private readonly _isMultiple: boolean = true;

	// Параметры ограничения области при перетаскивании точки
	private _dragConstraints: Box;

	// Интервал обводки маркера
	private _strokeDasharray = 0;

	// Callback для оповещения о перемещении точки
	private readonly _onPointMovingFunc?: (active: boolean) => void;

	/**
	 * Создаёт слой
	 *
	 * @param svg SVG.js объект
	 * @param mode режим редактирования
	 * @param isMultiple множественное или одиночное добавление точек
	 * @param shapes точки
	 * @param sectorsLayers
	 * @param singleSelectedPoint индекс выделенного маркера
	 * @param pointsColor цвет маркера
	 * @param emit функция оповещения
	 * @param onPointMoving функция для оповещения о перемещении точки
	 */
	constructor(
		svg: Container,
		mode: Mode,
		isMultiple: boolean,
		shapes: ILocationMarker[],
		sectorsLayers: ILayer[],
		singleSelectedPoint: number | undefined,
		pointsColor: string | undefined,
		entityMarkerType: EntityMarkerType,
		path: Path,
		zoom: number,
		emit?: (shapes: ILocationMarker[]) => void,
		onPointMoving?: (active: boolean) => void
	) {
		super();

		this._svg = svg;
		this._zoom = zoom;

		this._entityMarkerType = entityMarkerType;
			if (!this._entityMarkerType) {
				if (path === 'problem') {
					this._path = this.markerProblemPath(0, 0, 0.5 / this._zoom);
					this._pointClassName = 'plan-viewer__point_problem';
					this._yOffset = 20 / this._zoom;
					this._strokeDasharray = 0;
				}
				if (path === 'structure') {
					this._path = this.markerStructurePath(0, 0, 0.5 / this._zoom);
					this._pointClassName = 'plan-viewer__point_structure';
					this._strokeDasharray = 3.5 / this._zoom;
				}
			}
			if (this._entityMarkerType === 'structure') {
				this._path = this.markerStructurePath(0, 0, 0.5 / this._zoom);
				this._pointClassName = 'plan-viewer__point_structure';
				this._strokeDasharray = 3.5 / this._zoom;
			}
		this._group = this._svg.group().attr({id: 'pointsLayerGroup'});
		this._isMultiple = isMultiple;
		if (pointsColor) { this._pointsColor = pointsColor; }
		this._emitFunc = emit;
		this._onPointMovingFunc = onPointMoving;
		this._setDragConstraints();
		this._renderHiddenLayer(sectorsLayers);

		for (let i = 0, shape; i < shapes.length; i++) {
			shape = shapes[i];

			if (!Object.keys(shape).length || !shape.x || !shape.y) {
				continue;
			}

			const key = generateKey('point');
			const pointElement = this._group
				.path(this._path)
				.addClass('plan-viewer__point')
				.addClass(this._pointClassName)
				.addClass(singleSelectedPoint !== undefined
					? singleSelectedPoint === i ? 'plan-viewer__point_selected-point' : 'plan-viewer__point_disabled'
					: '')
				.addClass(this._entityMarkerType ? 'plan-viewer__point_entity' : '')
				.stroke({
					width: (3 / this._zoom),
					dasharray: `${this._strokeDasharray}px`
				})
				.fill(`${this._pointsColor}`)
				.cx(shape.x)
				.cy(shape.y - this._yOffset)
				.id(key);

			let pointElementDescriptionMarker: Circle | undefined;

			if (mode === 'edit') {
				pointElement
					.addClass('plan-viewer__point_hoverable')

					.on('contextmenu', this._openContextMenu)
					.draggable()
					.on('mousedown', this._handlePointMouseDown)
					.on('touchstart', this._handlePointMouseDown)
// @ts-ignore		
					.on('dragmove', e => {
						if (pointElementDescriptionMarker) {
// @ts-ignore
							this._handleDragMove(e, pointElementDescriptionMarker);
						}
					})

					.on('mouseup', this._handleMouseUp)
					.on('touchend', this._handleMouseUp);
			} else if (mode === 'view' && !entityMarkerType) {
				pointElement.on('mousedown', this._handleSelect);
			}
			this._shapes.push({
				...shape,
				key,
				descriptionMarker: shape.description ? pointElementDescriptionMarker : undefined,
				name: this._checkName(shape),
				element: pointElement,
				selected: false,
				type: 'point',
				svg: '',
				id: shape.id ?? ''
			});

			if (shape.description) {
				pointElementDescriptionMarker = this._group
					.circle(10 / this._zoom)
					.addClass('plan-viewer__point_description-marker')
					.cx(shape.x + 10 / this._zoom)
					.cy(shape.y - 35 / this._zoom)
					.stroke({
						width: (3 / this._zoom)
					})
					.id(key);

				this._shapes.push({
					...shape,
					key,
					name: '',
					element: pointElementDescriptionMarker,
					selected: false,
					type: 'description-marker',
					svg: '',
					id: shape.id ?? ''
				});
			}
		}

		if (mode === 'edit') {
			this._enableAdding();
			this._enableKeyEvent();
		}
	}

	// возвращает масштабированныый SVG path маркера Нарушения
	markerProblemPath = (x: number, y: number, s: number) => `M ${x - 21.8718 * s} ${y - 22.9678 * s} C ${x - 29.2966 * s} ${y - 29.2264 * s} ${x - 34 * s} ${y - 38.565 * s} ${x - 34 * s} ${y - 49 * s} C ${x - 34 * s} ${y - 67.7776 * s} ${x - 18.7776 * s} ${y - 83 * s} ${x} ${y - 83 * s} C ${x + 18.7776 * s} ${y - 83 * s} ${x + 34 * s} ${y - 67.7776 * s} ${x + 34 * s} ${y - 49 * s} C ${x + 34 * s} ${y - 38.4034 * s} ${x + 29.15 * s} ${y - 28.938 * s} ${x + 21.5562 * s} ${y - 22.7056 * s} L ${x + 21.5366 * s} ${y - 22.6896 * s} L ${x + 21.5164 * s} ${y - 22.6738 * s} L ${x + 11.8618 * s} ${y - 15.1538 * s} C ${x + 7.3028 * s} ${y - 11.9784 * s} ${x + 4.3628 * s} ${y - 6.8332 * s} ${x + 4.2888 * s} ${y - 0.9986 * s} C ${x + 4.2888 * s} ${y - 0.9982 * s} ${x + 4.268 * s} ${y + 1.008 * s} ${x + 2.958 * s} ${y + 1.072 * s} H ${x - 2.2662 * s} C ${x - 2.886 * s} ${y + 1.042 * s} ${x - 4.016 * s} ${y + 0.836 * s} ${x - 4.222 * s} ${y - 1.012 * s} C ${x - 4.564 * s} ${y - 7.07 * s} ${x - 6.86 * s} ${y - 11.712 * s} ${x - 14 * s} ${y - 17 * s} Z`;

		// возвращает масштабированныый SVG path маркераСтруктуры
	markerStructurePath = (x: number, y: number, s: number) => `M ${x + 30 * s} ${y} C ${x} ${y} ${x} ${y} ${x} ${y - 30 * s} C ${x} ${y - 60 * s} ${x} ${y - 60 * s} ${x + 30 * s} ${y - 60 * s} C ${x + 60 * s} ${y - 60 * s} ${x + 60 * s} ${y - 60 * s} ${x + 60 * s} ${y - 30 * s} C ${x + 60 * s} ${y} ${x + 60 * s} ${y} ${x + 30 * s} ${y} Z`;

	/**
	 * Очищает ресурсы, удаляет слой
	 */
	destroy = () => {
		super.destroy();
		this._disableAdding();
		this._disableKeyEvent();
		this._deleteHiddenLayer();
	};

	/**
	 * Переключает фдаг активности перемещения плана
	 *
	 * @param active флаг
	 */
	togglePlanMoving = (active: boolean) => {
		super.togglePlanMoving(active);
		if (active) {
			this._isAddingActive = false;
		}
	};

	/**
	 * Изменяет название точки
	 *
	 * @param index индекс
	 * @param name новое название
	 */
	editPointName = (index: number, name: string) => {
		if (index < 0 || index >= this._shapes.length) {
			return;
		}
		this._shapes[index].name = name;
		this._emit();
	};

	/**
	 * Удаляет точку
	 *
	 * @param index индекс
	 */
	deletePoint = (index: number) => {
		if (index < 0 || index >= this._shapes.length) {
			return;
		}
		this._shapes[index].element.off('contextmenu', this._openContextMenu).draggable(false).remove();

		this._shapes = this._shapes.filter((item, i) => i !== index);
		this._emit();
	};

	/**
	 * Включение/отключение других событий активации/деактивации перемещения мышью
	 *
	 * @param isMouseMovingActive флаг
	 */
	toggleMouseMoving = (isMouseMovingActive: boolean) => {
		if (isMouseMovingActive) {
			this._disableAdding();
			this._disableDrag();
		} else {
			this._enableDrag();
			this._enableAdding();
		}
	};

	/**
	 * Переключает видимость слоя
	 *
	 * @param visible видимость слоя
	 */
	toggleVisible = (visible: boolean) => {
		super.toggleVisible(visible);
		this._deselectAllShapes();
	};

	/**
	 * Оповещает об изменении состояния
	 */
	protected _emit = () => {
		if (!this._emitFunc) {
			return;
		}
		const countBySector: Record<string, number> = {};
		let unknownsCount = 0;
		const points: ISelectablePoint[] = [];

		for (const point of this._shapes) {
			if (point.type === 'description-marker') {
				continue;
			}
			let name = '';
			let sector;
			if (point.name === '' && point.x && point.y) {
				sector = this._findSector(point.x, point.y);
				if (sector) {
					if (countBySector[sector.id]) {
						name = `${sector.name} (${countBySector[sector.id]})`;
						countBySector[sector.id] += 1;
					} else {
						name = sector.name;
						countBySector[sector.id] = 1;
					}
				}
			}
			if (name === '') {
				name = unknownsCount > 0 ? `Место (${unknownsCount})` : 'Место';
				unknownsCount++;
			}
			points.push({
				key: point.key,
				name,
				id: point.id ? point.id : undefined,
				description: point.description,
				x: point.x,
				y: point.y,
				sector: sector && sector.id,
				selected: point.selected
			});
		}
		this._emitFunc(points);
	};

	/**
	 * Отрисовывает слой со скрытыми фигурами
	 *
	 * @param sectorsLayers слои
	 */
	private _renderHiddenLayer = (sectorsLayers: ILayer[]) => {
		this._hiddenGroup = this._svg.group().id('hiddenGroup').css('display', 'none');

		for (const layer of sectorsLayers) {
			if (!layer.shapes || (layer.type !== 'generated' && layer.type !== 'custom')) {
				continue;
			}
			for (const shape of layer.shapes) {
				const element = this._hiddenGroup.svg(shape.svg).last();

				this._hiddenShapes.push({
					...shape,
					element
				});
			}
		}
	};

	/**
	 * Удаляет слой со скрытыми фигурами
	 */
	private _deleteHiddenLayer = () => {
		this._hiddenGroup.remove();
	};

	/**
	 * Обрабатывает события клавиатуры
	 *
	 * @param event событие
	 */
	private _handleKeyDown = (event: KeyboardEvent) => {
		if (event.code === 'Delete' && this._shapes.length) {
			this._deleteAllSelectedPoints();
		}
	};

	/**
	 * Удаляет все выделенные точки
	 */
	private _deleteAllSelectedPoints = () => {
		this._shapes.forEach(item => {
			if (item.selected) {
				item.element.draggable(false).remove();
			}
		});
		this._shapes = this._shapes.filter(item => !item.selected);
		this._emit();
	};

	/**
	 * Включает режим добавления точек
	 */
	private _enableAdding = () => {
		this._svg.on('mouseup', this._handleMouseUp);
		this._svg.on('mousedown', this._handleMouseDown);
	};

	/**
	 * Выключает режим добавления точек
	 */
	private _disableAdding = () => {
		this._svg.off('mouseup', this._handleMouseUp);
		this._svg.off('mousedown', this._handleMouseDown);
	};

	/**
	 * Включает режим перемещения фигур
	 */
	private _enableDrag = () => {
		this._shapes.forEach(item => {
			item.element
				.draggable()
				.on('mousedown', this._handlePointMouseDown)
				.on('touchstart', this._handlePointMouseDown)
				.on('dragmove', this._handleDragMove);
		});
	};

	/**
	 * Выключает режим перемещения фигур
	 */
	private _disableDrag = () => {
		this._shapes.forEach(item => {
			item.element.draggable(false);
		});
	};

	/**
	 *
	 */
	private _handleMouseDown = () => {
		this._isPointDragActive = false;
	};

	/**
	 * Включает события клавиатуры
	 */
	private _enableKeyEvent = () => {
		window.addEventListener('keydown', this._handleKeyDown);
	};

	/**
	 * Выключает события клавиатуры
	 */
	private _disableKeyEvent = () => {
		window.removeEventListener('keydown', this._handleKeyDown);
	};

	/**
	 * Добавляет новую точку
	 *
	 * @param event событие
	 */
	private _handleMouseUp = (event: MouseEvent & TouchEvent) => {
		if (!this._isAddingActive) {
			this._isAddingActive = true;
		} else if (this._isPointDragActive) {
			if (this._onPointMovingFunc) {
				this._onPointMovingFunc(false);
			}
			if (!event.target) {
				return;
			}
			const x = event.changedTouches ? event.changedTouches[0].clientX : event.x;
			const y = event.changedTouches ? event.changedTouches[0].clientY : event.y;

			if (x === this._pointStartDragPos?.x && y === this._pointStartDragPos?.y) {
				// Если координаты начала и конца перетаскивания не изменились, то был произведён клик
				this._selectShape(event);
			} else {
				// Иначе - перетаскивание
				const point = this._shapes.find(item => item.key === (event.target as SVGCircleElement | null)?.id);
				if (point) {
					point.x = point.element.cx();
					point.y = point.element.cy() + this._yOffset;
				}
				this._emit();
			}
		} else {
			const someSelected = this._shapes.some(shape => shape.selected);
			if (someSelected) {
				event.preventDefault();
				event.stopPropagation();
				this._shapes.forEach(item => {
					item.selected = false;
					item.element.removeClass('plan-viewer__point_selected');
				});
				this._emit();
				return;
			}
			if (!this._isMultiple && this._shapes.length) {
				return;
			}

			const coords = transformSvgCoordinates(this._svg, event.clientX, event.clientY);
			const key = generateKey('point');
			const pointElement = this._group
				.path(this._path)
				.fill(this._pointsColor)
				.addClass(this._pointClassName)
				.addClass('plan-viewer__point')
				.addClass('plan-viewer__point_hoverable')
				.cx(coords.x)
				.cy(coords.y - this._yOffset)
				.id(key)
				.on('contextmenu', this._openContextMenu)
				.draggable()
				.on('mousedown', this._handlePointMouseDown)
				.on('touchstart', this._handlePointMouseDown)
				.on('dragmove', this._handleDragMove);

			this._shapes.push({
				key,
				name: '',
				x: coords.x,
				y: coords.y,
				element: pointElement,
				selected: false,
				type: 'point',
				svg: '',
				id: ''
			});
			this._emit();
		}
	};

	/**
	 * Вычисляет дропустимые границы перетаскивания точек
	 */
	private _setDragConstraints = () => {
		const imageGroup = this._svg.findOne('#imageGroup') as Element;
		this._dragConstraints = imageGroup.bbox();
	};

	/**
	 * Выключение ладошки, включение выделения и перетаскивание
	 *
	 * @param event событие
	 */
	private _handlePointMouseDown = (event: MouseEvent & TouchEvent) => {
		if (this._onPointMovingFunc) {
			this._onPointMovingFunc(true);
		}
		this._isPointDragActive = true;
		this._pointStartDragPos = event.changedTouches
			? new Box(event.changedTouches[0].clientX, event.changedTouches[0].clientY, 0, 0)
			: new Box(event.x, event.y, 0, 0);
	};

	private _handleSelect = (event: MouseEvent) => {
		this._selectShape(event);
	};

	/**
	 * Обработка события перетаскивания точки
	 *
	 * @param event событие
	 */
	private _handleDragMove = (event: CustomEvent, marker?: Circle) => {
		event.preventDefault();
		if (this._isPointDragActive) {
			const {handler, box} = event.detail;
			let {x, y} = box;
			// Отслеживание, чтобы точка не выходила за пределы плана
			if (x < this._dragConstraints.x) {
				x = this._dragConstraints.x;
			}
			if (y < this._dragConstraints.y) {
				y = this._dragConstraints.y;
			}
			if (box.x2 > this._dragConstraints.x2) {
				x = this._dragConstraints.x2 - box.w;
			}
			if (box.y2 > this._dragConstraints.y2) {
				y = this._dragConstraints.y2 - box.h;
			}
			if (marker) {
				marker.remove();
			}
			handler.move(x, y);
		}
	};

	/**
	 * Выделяет фигуру при клике на неё
	 *
	 * @param event событие
	 */
	private _selectShape = (event: MouseEvent) => {
		const target = event.target as SVGCircleElement | null;
		const {shiftKey} = event;

		if (target) {
			event.stopPropagation();
			if (!shiftKey) {
				this._deselectAllShapes();
			}
			for (const shape of this._shapes) {
				if (shape.element.attr('id') === target.id) {
					shape.selected = shiftKey ? !shape.selected : true;
					if (shape.selected) {
						shape.element.addClass('plan-viewer__point_selected');
					} else {
						shape.element.removeClass('plan-viewer__point_selected');
					}
					break;
				}
			}
			this._emit();
		}
	};

	/**
	 * Снимает выделение со всех фигур.
	 */
	private _deselectAllShapes = () => {
		for (const shape of this._shapes) {
			if (shape.selected) {
				shape.element.removeClass('plan-viewer__point_selected');
				shape.selected = false;
			}
		}
		this._emit();
	};

	/**
	 * Открывает контекстное меню.
	 *
	 * @param event событие
	 */
	private _openContextMenu = (event: MouseEvent) => {
		event.preventDefault();
	};

	/**
	 * Возвращает true если точка находится внутри многоугольника, возвращает false
	 * если точка находится вне многоугольника
	 *
	 * @param x координата x точки
	 * @param y координата y точки
	 * @param polygon массив координат точек многоугольника
	 */
	private _isPointInPolygon = (x: number, y: number, polygon: ICoordinates[]): boolean => {
		let inside = false;

		for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
			const xi = polygon[i].x;
			const yi = polygon[i].y;
			const xj = polygon[j].x;
			const yj = polygon[j].y;

			const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
			if (intersect) inside = !inside;
		}
		return inside;
	};

	/**
	 * Возвращает true если точка находится внутри эллипса, возвращает false
	 * если точка находится вне эллипса
	 *
	 * @param x координата x точки
	 * @param y координата y точки
	 * @param cx координата x центра эллипса
	 * @param cy координата y центра эллипса
	 * @param rx радиус по x эллипса
	 * @param ry радиус по y эллипса
	 */

	private _isPointInEllipse = (x: number, y: number, cx: number, cy: number, rx: number, ry: number): boolean => {
		let inside = false;

		const intersect = (x - cx) ** 2 / rx ** 2 + (y - cy) ** 2 / ry ** 2 <= 1;
		if (intersect) inside = !inside;
		return inside;
	};

	/**
	 * Определяет сектор по координатам точки
	 *
	 * @param x координата x
	 * @param y координата y
	 */
	private _findSector = (x: number, y: number): IShape | undefined => {
		for (const shape of this._hiddenShapes) {
			const attributes = shape.element.node.attributes;
			let points: ICoordinates[];

			switch (shape.element.type) {
				case 'polygon':
					points = attributes[0].value.split(' ').map((value: string) => {
						const splitVal: string[] = value.split(',');
						return {
							x: Number(splitVal[0]),
							y: Number(splitVal[1])
						};
					});
					if (this._isPointInPolygon(x, y, points)) {
						return shape;
					}
					break;

				case 'ellipse':
					if (
						this._isPointInEllipse(
							x,
							y,
							Number(attributes[2].value),
							Number(attributes[3].value),
							Number(attributes[0].value),
							Number(attributes[1].value)
						)
					) {
						return shape;
					}
					break;

				default:
					points = [
						{
							x: Number(attributes[2].value),
							y: Number(attributes[3].value)
						},
						{
							x: Number(attributes[2].value) + Number(attributes[0].value),
							y: Number(attributes[3].value)
						},
						{
							x: Number(attributes[2].value) + Number(attributes[0].value),
							y: Number(attributes[3].value) + Number(attributes[1].value)
						},
						{
							x: Number(attributes[2].value),
							y: Number(attributes[3].value) + Number(attributes[1].value)
						}
					];
					if (this._isPointInPolygon(x, y, points)) {
						return shape;
					}
					break;
			}
		}
		return undefined;
	};

	/**
	 * Проверяет имя точки на соответствие сектору.
	 * Это необходимо при создании слоя, чтобы была возможность изменять имя точки при перетасивании
	 * и не затирать введенные пользователем имена.
	 *
	 * @param point точка
	 */
	private _checkName = (point: ILocationMarker) => {
		if (point.x && point.y) {
			const sector = this._findSector(point.x, point.y);
			const regExp = new RegExp(`^${sector ? sector.name : unknownPointName}( \\(\\d+\\))?$`);
			return regExp.test(point.name) ? '' : point.name;
		}
		return '';
	};
}

export default PointsHandler;
