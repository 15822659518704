import React, {Dispatch} from 'react';
import './EditableInspection.less';
import {
	IEditableInspectionAction,
	IEditableInspectionState
} from '@src/core/hooks/states/useEditableInspectionState';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {useIsDesktopHeight} from '@tehzor/ui-components/src/utils/mediaQueries';
import Description from '../editableFields/Description';
import Attachments from '../editableFields/Attachments';
import {Location} from '../editableFields/Location';
import Floors from '../editableFields/Floors';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import useAppSelector from '@src/core/hooks/useAppSelector';

const desktopDescProps = {
	minRows: 3,
	maxRows: 7
};
const mobileDescProps = {
	minRows: 3,
	maxRows: 5
};

interface IEditableInspectionProps {
	objectId: string;
	editingState: IEditableInspectionState;
	editingDispatch: Dispatch<IEditableInspectionAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableInspection = (props: IEditableInspectionProps) => {
	const {
		objectId,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		fieldsSettings,
		saving
	} = props;

	const isDesktopHeight = useIsDesktopHeight();
	const inspection = useAppSelector(s => s.entities.inspection.data);

	return (
		<div className="editable-inspection">
			<div>
				{fieldsSettings.description !== undefined && (
					<Description
						className="editable-inspection__description"
						value={editingState.description}
						editingDispatch={editingDispatch}
						required={fieldsSettings.description.isRequired}
						disabled={saving}
						hasError={editingState.errors.description}
						textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
					/>
				)}

				<div className="editable-inspection__info-grid">
					{fieldsSettings.location !== undefined && (
						<Location
							planId={editingState.planId}
							location={editingState.location}
							objectId={objectId}
							hasError={editingState.errors.location}
							editingDispatch={editingDispatch}
							required={fieldsSettings.location.isRequired}
						/>
					)}

					{fieldsSettings.floor !== undefined && (
						<Floors
							value={editingState.floor}
							editingDispatch={editingDispatch}
							required={fieldsSettings.floor.isRequired}
							disabled={saving}
							hasError={editingState.errors.floor}
						/>
					)}
				</div>
			</div>

			<div>
				{fieldsSettings.attachments !== undefined && (
					<Attachments
						imagesTitle="Фото осмотра"
						attachmentsDestination={AttachmentFileDestination.INSPECTION}
						entityId={inspection?.id}
						attachments={editingState.attachments}
						uploadingFiles={uploadingFilesState.value}
						editingDispatch={editingDispatch}
						uploadingFilesDispatch={uploadingFilesDispatch}
						required={fieldsSettings.attachments.isRequired}
						disabled={saving}
						canDraw
						waitForUploading={false}
						hasError={editingState.errors.attachments && uploadingFilesState.error}
						showAttachBtn
					/>
				)}
			</div>

			{Object.keys(fieldsSettings).length === 0 && (
				<div className="editable-inspection__empty-fields-msg">Все поля отключены</div>
			)}
		</div>
	);
};

export default EditableInspection;
