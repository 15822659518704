import React from 'react';
import {CellProps} from 'react-table';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';

export const FixDateCell = ({row}: CellProps<IProblemEntity>) => (
	<div className="problems-page__d-table-plannedFixDate">
		{row.original.data.plannedFixDate}
		{compareFixDate(
			row.original.data.actualFixDateOriginal,
			row.original.data.plannedFixDateOriginal
		) && (
			<FixDateTag
				className="fix-date-view-field__tag"
				plannedFixDate={row.original.data.plannedFixDateOriginal!}
				actualFixDate={row.original.data.actualFixDateOriginal}
			/>
		)}
	</div>
);
