import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {usePermissions} from '@src/pages/InspectionPage/hooks/usePermissions';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {useEditableInspectionDialog} from '@src/components/EditableInspectionDialog/hooks/useEditableInspectionDialog';
import {useInspectionHistoryDialog} from '@src/components/InspectionHistoryDialog/hooks/useProblemHistoryDialog';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IMenuProps {
	objectId: string;
	inspection: ILinkedInspection;
}

const Menu = ({objectId, inspection}: IMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const permissions = usePermissions(objectId, inspection);
	const isDesktop = useIsDesktop();

	// Диалог редактирования осмотра
	const [inspectionDialog, openEditDialog] = useEditableInspectionDialog(objectId, inspection);
	// Диалог истории изменений
	const [historyDialog, openHistoryDialog] = useInspectionHistoryDialog(objectId, inspection.id);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить осмотр',
		`Вы действительно хотите удалить осмотр №${inspection?.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteInspection(objectId, inspection.id));
			goBack();
		}
	}, [objectId, inspection.id]);

	const items = [];
	if (!isDesktop) {
		items.push(updateData);
	}

	if (permissions.canViewHistory) {
		items.push(
			<MenuItem
				key="history"
				icon={<i className="tz-history-24"/>}
				onClick={openHistoryDialog}
			>
				История изменений
			</MenuItem>
		);
	}

	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit"/>}
				onClick={openEditDialog}
			>
				Редактировать
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{permissions.canEdit && inspectionDialog}
			{permissions.canDelete && deleteDialog}
			{permissions.canViewHistory && historyDialog}
		</>
	) : null;
};

export default Menu;
