import React, {Dispatch, useCallback, useMemo, useState} from 'react';
import {ActionButtons, Button, Dialog, EditableFieldLabel, TextField} from '@tehzor/ui-components';
import DependencySelect from '@src/components/EditableCheckList/components/DependencySelect';
import {
	IEditableCheckItem,
	IEditableCheckListAction,
	IEditableCheckListState
} from '@src/core/hooks/states/useEditableCheckListState';
import arrayToTree, {Tree} from 'array-to-tree';
import {topologicalSort} from '@tehzor/tools/utils/topologicalSort';
import {addErrorToast} from '@src/utils/toasts';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';

interface IEditableCheckItemDialogProps {
	isOpen: boolean;
	editingState: IEditableCheckListState;
	editingDispatch: Dispatch<IEditableCheckListAction>;
	itemsLeaf: Tree<IEditableCheckItem>;

	close(): void;
}

const checkIsCircular = (
	allItems: IEditableCheckItem[],
	currentItem: Tree<IEditableCheckItem>,
	value: string[]
) => {
	let parentItems: Array<Tree<IEditableCheckItem>>;

	const tree = arrayToTree(allItems || [], {
		parentProperty: 'parentId',
		customID: 'id'
	});

	if (currentItem.parentId) {
		const found = findTreeNode(tree, currentItem.parentId);
		parentItems = found ? found.children! : [];
	} else {
		parentItems = tree;
	}

	return topologicalSort([
		...parentItems.filter(item => item.id !== currentItem.id),
		{...currentItem, dependencies: value}
	]);
};

export const EditableCheckItemDialog = ({
	editingDispatch,
	editingState,
	itemsLeaf,
	isOpen,
	close
}: IEditableCheckItemDialogProps) => {
	const currentIndex = useMemo(
		() => editingState.items?.map(item => item.id).indexOf(itemsLeaf.id),
		[itemsLeaf.id]
	);

	const availableDependencies = useMemo(
		() =>
			editingState.items?.filter(
				item =>
					item.name !== ''
					&& item.id !== itemsLeaf.id
					&& item.parentId === itemsLeaf.parentId
			) || [],
		[editingState.items]
	);

	const [text, setText] = useState(itemsLeaf.name);
	const [description, setDescription] = useState(itemsLeaf.description);
	const [deps, setDeps] = useState<string[]>(itemsLeaf.dependencies!);

	const handleClose = useCallback(() => {
		setText(itemsLeaf.name);
		setDescription(itemsLeaf.description);
		setDeps(itemsLeaf.dependencies!);
		close();
	}, []);

	const handleSelectChange = useCallback(
		(value: string[]) => {
			const {edges} = checkIsCircular(editingState.items || [], itemsLeaf, value);

			if (edges.length) {
				const dependencies = availableDependencies.filter(dep =>
					edges.find(([, depId]) => depId === dep.id));

				addErrorToast(
					'Ошибка!',
					`Обнаружена циклическая зависимость категорий. Категория "${
						itemsLeaf.name
					}" не может быть зависима от ${dependencies
						.map(({name}) => `"${name}"`)
						.join(', ')}`
				);
			} else {
				setDeps(value);
			}
		},
		[itemsLeaf]
	);

	const handleSave = useCallback(() => {
		if (currentIndex !== undefined) {
			const {children, ...data} = itemsLeaf;
			editingDispatch({
				type: 'update-array-item',
				field: 'items',
				index: currentIndex,
				value: {...data, name: text, description, dependencies: deps}
			});
		}

		close();
	}, [text, description, deps]);

	return (
		<Dialog
			isOpen={isOpen}
			title="Редактирование категории"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
		>
			<div>
				<EditableFieldLabel>Название</EditableFieldLabel>

				<TextField
					value={text}
					elementType="input"
					elementProps={{autoFocus: true}}
					onChange={setText}
				/>

				<EditableFieldLabel>Описание</EditableFieldLabel>

				<TextField
					value={description}
					elementType="input"
					onChange={setDescription}
				/>
			</div>

			<DependencySelect
				label="Зависимость на категории"
				value={deps}
				dependencies={availableDependencies}
				onChange={handleSelectChange}
				disabled={availableDependencies.length === 0}
			/>
		</Dialog>
	);
};
