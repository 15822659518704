import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOwnerAcceptanceResolutionsAsArray} from '@src/store/modules/dictionaries/ownerAcceptanceResolutions/selectors';

interface IResolutionProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: OwnerAcceptanceResolutionId;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const Resolution = <S extends {resolution?: OwnerAcceptanceResolutionId}, E>(
	props: IResolutionProps<S, E>
) => {
	const {
		className,
		style,
		label = 'Решение (опционально)',
		value,
		editingDispatch,
		required,
		disabled,
		hasError
	} = props;

	const resolutions = useAppSelector(extractOwnerAcceptanceResolutionsAsArray);
	const resolutionsMap = useAppSelector(s => s.dictionaries.ownerAcceptanceResolutions.byId);

	const handleChange = useCallback(
		(v: OwnerAcceptanceResolutionId) => {
			editingDispatch({type: 'update', field: 'resolution', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'resolution'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<SelectPopup
				trigger={(
					<TextFieldWithForwardedRef
						value={value ? resolutionsMap[value]?.name : ''}
						elementType="div"
						// elementProps={textAreaProps}
						error={required && hasError ? 'Выберите решение' : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{resolutions.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};

export default Resolution;
