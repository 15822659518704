import React, {useCallback} from 'react';
import DesktopMenu from './Menu.desktop';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {InlineButton} from '@tehzor/ui-components';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IDesktopActionsProps {
	ownerAcceptance: ILinkedOwnerAcceptance;
}

const DesktopActions = ({ownerAcceptance}: IDesktopActionsProps) => {
	const spaceTitle = useSpaceTitle(ownerAcceptance?.space);
	const {pushPath} = useChangePath();

	const handleClick = useCallback(() => {
		if (ownerAcceptance) {
			if (ownerAcceptance.objectId && ownerAcceptance.links?.spaceId) {
				pushPath(
					`/objects/${ownerAcceptance.objectId}/spaces/${ownerAcceptance.links.spaceId}`
				);
			}
		}
	}, [ownerAcceptance]);

	return (
		<div className="owner-acceptance-page__d-controls">
			<div className="owner-acceptance-page__d-controls-left">
				{ownerAcceptance.space ? (
					<InlineButton
						label={spaceTitle}
						type="accent"
						onClick={handleClick}
						leftIcon={<i className="tz-space-24"/>}
					/>
				) : null}
			</div>
			<div className="owner-acceptance-page__d-controls-right">
				<DesktopMenu ownerAcceptance={ownerAcceptance}/>
			</div>
		</div>
	);
};
export default DesktopActions;
