import {Button, FloatingActionButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const AddButton = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {pushPath} = useChangePath();

	const add = useCallback(() => {
		pushPath(`/objects/${objectId}/plans/add`);
	}, [objectId]);

	return (
		<>
			<Desktop>
				<div className="plans-page_add-button">
					<Button
						type="accent-blue"
						leftIcon={<i className="tz-plus-20"/>}
						label="Добавить план"
						onClick={add}
					/>
				</div>
			</Desktop>

			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={add}
				/>
			</BeforeDesktop>
		</>
	);
};
