import React, {useCallback, useState} from 'react';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {CreationDateFilter} from '@src/pages/OfflineJournalPage';
import './OfflineJournalFilterPage.less';
import {ActionsFilterMobile} from './filters/ActionsFilterMobile';
import {StatusesFilterMobile} from './filters/StatusesFilterMobile';
import {entityStatuses} from './data/entityFilters';
import {entityActions} from '@src/pages/OfflineJournalPage/enums/actionsTypes';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {useChangePath} from '@src/core/hooks/useChangePath';

export const OfflineJournalFilterPage = () => {
	const [isCleanable, setIsCleanable] = useState(true);
	const {pushPath} = useChangePath();
	const {state, isStateChanged, change, mobileClear, apply}
		= useEntitiesFiltersCtx<IOfflineJournalFiltersState>();
	const handleApply = useCallback(() => {
		apply();
		setIsCleanable(false);
		pushPath('/profile/offline-journal');
	}, [apply]);

	useAppHeader(
		{
			title: 'Фильтры',
			showBackBtn: true,
			mobileRightButtons: isCleanable ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[]
	);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
			className="offline-journal-filter-page"
		>
			<ActionsFilterMobile
				value={state.actions}
				onChange={change}
				filteredElements={entityActions}
			/>
			<StatusesFilterMobile
				value={state.statuses}
				onChange={change}
				filteredElements={entityStatuses}
			/>
			<CreationDateFilter
				from={state.createdAtFrom}
				to={state.createdAtTo}
				className="offline-journal-filter-page__date-button"
				label="Дата"
			/>
		</FilterPage>
	);
};
