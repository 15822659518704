import React from 'react';
import './SidebarMenuSeparator.less';
import classNames from 'classnames';

interface ISidebarMenuSeparatorProps {
	className?: string;
	style?: React.CSSProperties;
	type?: 'mobile' | 'tablet' | 'desktop';
}

const SidebarMenuSeparator = ({className, style, type}: ISidebarMenuSeparatorProps) => (
	<div
		className={classNames('sidebar-menu-separator', `sidebar-menu-separator_${type}`, className)}
		style={style}
	/>
);

export default SidebarMenuSeparator;
