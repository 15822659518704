import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IEditCheckListResponse, makeCheckListEditRequest} from '@src/api/backend/checkList';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';

export type IEditCheckListPayload = IEditCheckListResponse;

const request = (id: string, fields: ISavingCheckList) => ({
	type: types.EDIT_REQUEST,
	id,
	fields
});

const success = (response: IEditCheckListResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при обновлении чек-листа');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет чек-лист
 *
 * @param id идентификатор чек-листа
 * @param fields данные для добавления
 */
export const editCheckList = (id: string, fields: ISavingCheckList) =>
	createApiAction<IEditCheckListResponse>(
		() => request(id, fields),
		success,
		failure,
		() => makeCheckListEditRequest(id, fields)
	);
