import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import React from 'react';

export function useInternalAcceptanceDelete(
	objectId: string,
	internalAcceptance: IInternalAcceptance
): [React.ReactNode, () => void] {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить внутреннюю приёмку',
		`Вы действительно хотите удалить внутреннюю приёмку №${internalAcceptance.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteInternalAcceptance(objectId, internalAcceptance.id));
			goBack();
		}
	}, [objectId, internalAcceptance.id]);

	return [deleteDialog, handleDelete];
}
