import React from 'react';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ItemBullet} from '@src/pages/CheckListPage/components/list/CheckListItems/components/ItemBullet';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {format} from 'date-fns';
import {DescriptionPopUp} from './DescriptionPopUp';
import {DescriptionDialog} from './DescriptionDialog';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {EntityInfoLink} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractWorkAcceptancesAsArray} from '@src/store/modules/entities/workAcceptances/selectors';
import {extractCheckRecordsByItemId} from '@src/store/modules/pages/checkLists/selectors/records';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';

interface IItemHeaderProps {
	item: ICheckItem;
	record?: ICheckRecord;
}

export const ItemHeader = ({item, record}: IItemHeaderProps) => {
	const isDesktop = useIsDesktop();
	const {pushPath} = useChangePath();
	const records = useAppSelector(state => extractCheckRecordsByItemId(state, item.id));
	const workAcceptances = useAppSelector(state =>
		extractWorkAcceptancesAsArray(state, record?.objectId));
	const itemWorkAcceptances = workAcceptances.filter(workAcceptance =>
		records.some(rec => rec.links.workAcceptanceId === workAcceptance.id));

	const navigate = (workAcceptanceId: string) => {
		if (record?.objectId) {
			pushPath(formWorkAcceptanceLink(record.objectId, workAcceptanceId));
		}
	};

	return (
		<div className="structure-check-list-items__item-header">
			<ItemBullet
				item={item}
				record={record}
			/>

			{item.description ? (
				isDesktop ? (
					<DescriptionPopUp item={item}/>
				) : (
					<DescriptionDialog item={item}/>
				)
			) : (
				<div className="structure-check-list-items__item-header-title">{item.name}</div>
			)}

			{record && record.modifiedAt ? (
				<div className="structure-check-list-items__item-header-subtitle">
					<div className="structure-check-list-items__item-header-subtitle_accepted">
						{record.status === CheckRecordStatusId.ACCEPTED ? 'Принято' : 'Изменено'}
						{' '}
						&nbsp;
						<div>{format(record.modifiedAt, dateTimeLetterSeparatedFormat)}</div>
						&nbsp;
						<div className="structure-check-list-items__item-header-subtitle-name">
							{record?.modifiedBy?.displayName}
						</div>
					</div>
					{itemWorkAcceptances.map(workAcceptance => (
						<EntityInfoLink
							key={workAcceptance.id}
							className="structure-check-list-items__item-header-links"
							label={`Приёмка работ № ${workAcceptance.number}`}
							onClick={() => {
								navigate(workAcceptance.id);
							}}
						/>
					))}
				</div>
			) : null}
		</div>
	);
};
