import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IEditProblemTagsSetResponse, makeProblemTagsSetEditRequest} from '@src/api/backend/problemTagsSet';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';

export type IEditProblemTagsSetPayload = IEditProblemTagsSetResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditProblemTagsSetResponse) =>
	 ({
		type: types.EDIT_SUCCESS,
		payload: response
	});
const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении набора меток');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет набор меток нарушений
 *
 * @param id идентификатор набора
 * @param fields данные для добавления
 */
export const editProblemTagsSet = (id: string, fields: ISavingProblemTagsSet) =>
	createApiAction<IEditProblemTagsSetResponse>(request, success, failure, () =>
		makeProblemTagsSetEditRequest(id, fields));
