import getRandomColor from '../../utils/getRandomColor';
import AbstractHandler from './AbstractHandler';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';

const shapeStyles={
	opacity: 0.5,
	stroke: '#ff0042',
	'stroke-width': 4
};

/**
 * Класс для работы с пользовательскими фигурами
 */
class CustomShapesHandler extends AbstractHandler {
	// Счётчик эллипсов
	_ellipsesCounter=1;

	// Счётчик прямоугольников
	_rectanglesCounter=1;

	// Счётчик полигонов
	_polygonsCounter=1;

	// Временный объект для рисования
	_tmpShape=null;

	// Доступные режимы рисования
	_availableModes=['select', 'ellipse', 'rect', 'polygon'];

	// Доступные фигуры для выделения
	_availableShapes=['ellipse', 'rect', 'polygon'];

	/**
	 * Добавляет эллипс
	 */
	addEllipse() {
		if (!this._svg||!this._group) {
			return;
		}
		this._tmpShape=this._group.ellipse().attr(shapeStyles).fill(getRandomColor()).draw();

		this._tmpShape.on('drawstop', () => {
			this._tmpShape.off('drawstop');
			const attrs=this._tmpShape.attr();

			if (attrs.rx<10&&attrs.ry<10) {
				this._tmpShape.remove();
				this._tmpShape=null;
			} else {
				this._shapes.push({
					element: this._tmpShape,
					name: `Эллипс ${this._ellipsesCounter++}`,
					spaceType: SpaceTypeId.APARTMENTS,
					selected: false
				});
				this._emit();
			}
			this.addEllipse();
		});
	}

	/**
	 * Добавляет прямоугольник
	 */
	addRect() {
		if (!this._svg||!this._group) {
			return;
		}
		this._tmpShape=this._group.rect().attr(shapeStyles).fill(getRandomColor()).draw();

		this._tmpShape.on('drawstop', () => {
			this._tmpShape.off('drawstop');
			const attrs=this._tmpShape.attr();

			if (attrs.width<10&&attrs.height<10) {
				this._tmpShape.remove();
				this._tmpShape=null;
			} else {
				this._shapes.push({
					element: this._tmpShape,
					name: `Прямоугольник ${this._rectanglesCounter++}`,
					spaceType: SpaceTypeId.APARTMENTS,
					selected: false
				});
				this._emit();
			}
			this.addRect();
		});
	}

	/**
	 * Добавляет многоугольник
	 */
	addPolygon() {
		if (!this._svg||!this._group) {
			return;
		}
		this._tmpShape=this._group.polyline().attr(shapeStyles).fill('none').draw();

		this._tmpShape.on('drawstop', () => {
			this._tmpShape.off('drawstop');
			const points=this._tmpShape.array().getPointsWithoutLast();
			this._tmpShape.clear();

			if (points.length<3) {
				this._tmpShape.remove();
				this._tmpShape=null;
			} else {
				const polygon=this._group
					.polygon(points)
					.attr(shapeStyles)
					.fill(getRandomColor());
				this._shapes.push({
					element: polygon,
					name: `Многоугольник ${this._polygonsCounter++}`,
					spaceType: SpaceTypeId.APARTMENTS,
					selected: false
				});
				this._tmpShape.remove();
				this._tmpShape=null;
				this._emit();
			}
			this.addPolygon();
		});
	}

	/**
	 * Отключает возможность добавления новой фигуры
	 */
	cancelShapeAdding=() => {
		if (this._svg&&this._tmpShape) {
			this._tmpShape.off('drawstop');
			this._tmpShape.draw('cancel');
			this._tmpShape.remove();
			this._tmpShape=null;
		}
	};

	/**
	 * Очищает ресурсы, удаляет слой
	 */
	destroy() {
		super.destroy();
		this.cancelShapeAdding();
	}

	/**
	 * Добавляет необходимые атрибуты фигуре
	 * @param {Object} shape SVG.js фигура
	 * @return {Object}SVG.js фигура
	 * @private
	 */
	_enhanceShape(shape) {
		return shape.fill(getRandomColor()).attr(shapeStyles);
	}
}

export default CustomShapesHandler;
