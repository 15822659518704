import {wsConnector} from '../wsConnector';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

export type IEditSpaceStatusResponse = ISpace;

/**
 * Изменяет статус помещения
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param status статус
 */
export const makeSpaceStatusEditRequest = (objectId: string, spaceId: string, status: string) =>
	wsConnector.sendAuthorizedRequest<IEditSpaceStatusResponse>('editStatusInSpace', {
		objectId,
		spaceId,
		status
	});
