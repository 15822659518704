import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {
	IBindSpaceToOwnersResponse,
	makeBindSpaceToOwnersRequest
} from '@src/api/backend/spaceOwners';

export type IBindSpaceToOwnersPayload = IBindSpaceToOwnersResponse;

const request = () => ({type: types.BIND_SPACE_REQUEST});

const success = (response: IBindSpaceToOwnersResponse) => ({
	type: types.BIND_SPACE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при привязке собственников');
	return {
		type: types.BIND_SPACE_FAILURE,
		payload: error
	};
};

/**
 * Привязывает собственников к помещению
 *
 * @param owners id собственников
 * @param spaceId id помещения
 */
export const bindSpaceToOwners = (owners: string[], spaceId: string) =>
	createApiAction<IBindSpaceToOwnersResponse>(request, success, failure, () =>
		makeBindSpaceToOwnersRequest(owners, spaceId));
