import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {useMemo} from 'react';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import IPermission from '@tehzor/tools/interfaces/IPermission';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {checkRestrictions} from '@tehzor/tools/utils/permissions/restrictions/checkRestrictions';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';

export interface IStatusPermissions {
	canEditStatus?: boolean;
	availableStatuses?: string[];
}

/**
 * Возвращает полномочие для редактирования статуса нарушения
 *
 * @param objectId id объекта
 * @param problem нарушение
 */
export const useProblemStatusPermissions = (
	objectId: string,
	problem?: Omit<
		IProblem,
		'createdBy' | 'modifiedBy' | 'initialGroupLeader' | 'activeGroupLeader'
	>
): IStatusPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		let canEditStatus: boolean | undefined;
		let availableStatuses;

		const editStatusPermission = findPermission(
			user.roles,
			'problemsStatusEdit',
			UserRoleScopes.OBJECT,
			objectId
		);
		if (editStatusPermission) {
			if (editStatusPermission.restrictions) {
				const r = {...editStatusPermission.restrictions} as NonNullable<
					IPermission['restrictions']
				>;
				if (r.availableStatuses) {
					if (Array.isArray(r.availableStatuses)) {
						availableStatuses = r.availableStatuses;
					}
					delete r.availableStatuses;
				}
				canEditStatus = checkRestrictions(r, {userId: user.id, problem});
			} else {
				canEditStatus = true;
			}
		}

		return {
			canEditStatus,
			availableStatuses
		};
	}, [objectId, user, problem]);
};
