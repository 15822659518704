import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';

interface IPageBreadcrumbsProps {
	objectId: string;
}

export const PageBreadcrumbs = memo(({objectId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);

	return (
		<AppBreadcrumbs
			className="object-page__breadcrumbs"
			items={breadcrumbs}
		/>
	);
});