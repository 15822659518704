import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IExportTemplatesDestination} from '@tehzor/tools/interfaces/IExportTemplate';

export type IExportTemplatesDestinationsState = ILoadableEntitiesState<IExportTemplatesDestination>;

export const exportTemplatesDestinations = createReducer<IExportTemplatesDestinationsState>(
	getLoadableEntitiesInitialState<IExportTemplatesDestination>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IExportTemplatesDestination>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IExportTemplatesDestination>()
	}
);
