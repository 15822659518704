import {wsConnector} from '../wsConnector';
import ISavingProblemComment from '@tehzor/tools/interfaces/problemComments/ISavingProblemComment';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

export type IEditProblemCommentResponse = IProblemComment;

/**
 * Изменяет комментарий нарушения
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const makeProblemCommentEditRequest = (
	objectId: string,
	commentId: string,
	fields: ISavingProblemComment
) =>
	wsConnector.sendAuthorizedRequest<IEditProblemCommentResponse>('editProblemComment', {
		objectId,
		commentId,
		...fields
	});
