import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import * as types from '../constants';
import {CacheStatus} from '@tehzor/tools/enums/CacheStatus';
import {
	IOfflineDataCachingStatuses,
	OfflineDataCachingStatus
} from '@tehzor/tools/contracts/dataCachingWebWorker/interfaces/IOfflineDataCachingStatuses';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export const cacheStatus = createReducer<CacheStatus>(CacheStatus.UNKNOWN, {
	[types.CHANGE_CACHE_STATUS]: (state, {payload}: PayloadAction<CacheStatus>) => payload,
	[types.CHANGE_DICTIONARIES_CACHE_STATE]: (
		state,
		{payload}: PayloadAction<IOfflineDataCachingStatuses>
	) => {
		const statuses = Object.values(payload);
		if (
			statuses.some(
				s =>
					s === OfflineDataCachingStatus.WAITING || s === OfflineDataCachingStatus.LOADING
			)
		) {
			return CacheStatus.CACHING;
		}
		if (statuses.includes(OfflineDataCachingStatus.ERROR)) {
			return CacheStatus.ERROR;
		}
		return CacheStatus.READY;
	},
	[types.CHANGE_DICTIONARIES_CACHE_PROGRESS]: () => CacheStatus.CACHING,
	[types.CHANGE_ENTITIES_CACHE_STATE]: (
		state,
		{payload}: PayloadAction<IOfflineDataCachingStatuses>
	) => {
		const statuses = Object.values(payload);
		if (
			statuses.some(
				s =>
					s === OfflineDataCachingStatus.WAITING || s === OfflineDataCachingStatus.LOADING
			)
		) {
			return CacheStatus.CACHING;
		}
		if (statuses.includes(OfflineDataCachingStatus.ERROR)) {
			return CacheStatus.ERROR;
		}
		return CacheStatus.READY;
	},
	[types.CHANGE_ENTITIES_CACHE_PROGRESS]: () => CacheStatus.CACHING,
	[CLEAR_STORE]: () => CacheStatus.UNKNOWN
});
