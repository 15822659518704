import React, {useCallback} from 'react';
import './SelectOption.less';
import classNames from 'classnames';
import {Checkbox} from '../../checkbox';
import RadioButton from '../../radioButton/RadioButton';
import {convertClassNames} from '../../../../utils/convertClassNames';
import {useChangeSelectionCtx} from '../utils/ChangeSelectionCtx';
import {useCheckSelectionCtx} from '../utils/CheckSelectionCtx';

export type SelectOptionInputType = 'checkbox' | 'radio' | null;

export interface ISelectOptionProps {
	className?:
		| string
		| {
				root?: string;
				content?: string;
		  };
	style?: React.CSSProperties;
	content?: React.ReactNode;
	itemKey?: string;
	inputType?: SelectOptionInputType;
	checked?: boolean;
	semiChecked?: boolean;
	disabled?: boolean;

	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const SelectOption = (props: ISelectOptionProps) => {
	const {className, style, itemKey, content, checked, semiChecked, inputType = 'checkbox', disabled, onChange, onClick} = props;
	const classes = convertClassNames(className);

	const changeSelection = useChangeSelectionCtx();
	const checkSelection = useCheckSelectionCtx();

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			if (changeSelection && itemKey) {
				changeSelection(itemKey);
			}
			if (onClick) {
				event.persist();
				onClick(event);
			}
		},
		[itemKey, changeSelection, onClick]
	);

	const inputProps = {
		className: {
			root: classNames('select-option', classes.root),
			wrap: 'select-option__wrap',
			content: classNames('select-option__content', classes.content)
		},
		style,
		children: content,
		disabled,
		onChange,
		onClick: !disabled ? handleClick : undefined
	};

	const checkedBySelect = itemKey !== undefined && checkSelection !== undefined && checkSelection(itemKey);

	return inputType === 'checkbox' ? (
		<Checkbox
			{...inputProps}
			checked={(checked ?? checkedBySelect) || semiChecked}
			semiChecked={semiChecked}
		/>
	) : inputType === 'radio' ? (
		<RadioButton
			{...inputProps}
			checked={checked ?? checkedBySelect}
		/>
	) : content ? (
		<span className={inputProps.className.content}>
			{content}
		</span>
	) : null;
};

SelectOption.displayName = 'SelectOption';

export default SelectOption;