import useAppDispatch from '@src/core/hooks/useAppDispatch';
import React, {useCallback, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {addSuccessToast} from '@src/utils/toasts';
import {ActionButtons, Button, Plate} from '@tehzor/ui-components';
import './EditingSpaceTypeDecoration.less';
import {useEditableSpaceTypeDecoration} from '../EditableSpaceTypeDecoration/hooks/useEditableSpaceTypeDecoration';
import {editSpaceTypeDecorationSet} from '@src/store/modules/entities/spaceTypeDecorationSet/actions/edit';
import {extractSpaceTypeDecorationSet} from '@src/store/modules/entities/spaceTypeDecorationSet/selectors';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {extractSpaceTypeDecorationsAsArray} from '@src/store/modules/entities/spaceTypeDecorations/selectors';
import {editSpaceTypeDecoration} from '@src/store/modules/entities/spaceTypeDecorations/actions';

interface IEditingSpaceTypeDecorationProps {
	typeDecorationSetId: string;
}

export const EditingSpaceTypeDecoration = ({
	typeDecorationSetId
}: IEditingSpaceTypeDecorationProps) => {
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);

	const typeDecorationSet = useAppSelector(extractSpaceTypeDecorationSet);
	const typeDecoration = useAppSelector(extractSpaceTypeDecorationsAsArray);

	const [typeDecorationSetFields, typeDecorationFields, getSavingData]
		= useEditableSpaceTypeDecoration({
			typeDecorationSet,
			typeDecoration,
			saving
		});

	const handleSave = useCallback(async () => {
		setSaving(true);
		const savingData = getSavingData();

		try {
			if (savingData && savingData.typeDecorationSet && savingData.typeDecoration) {
				await Promise.all([
					dispatch(
						editSpaceTypeDecorationSet({
							id: typeDecorationSetId,
							...savingData.typeDecorationSet
						} as ISpaceTypeDecorationSet)
					),
					dispatch(
						editSpaceTypeDecoration(typeDecorationSetId, savingData.typeDecoration)
					)
				]);

				addSuccessToast('Успешно', 'Набор типов отделки помещений был обновлен');
			}
		} finally {
			setSaving(false);
		}
	}, [getSavingData]);

	return (
		<div className="editing-space-type-decoration__container">
			<Plate className="editing-space-type-decoration__items-grid">
				{typeDecorationSetFields}
				{typeDecorationFields}
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			</Plate>
		</div>
	);
};
