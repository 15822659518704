import React from 'react';
import './ProblemCommentsPage.less';
import {Navigate} from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {getProblem} from '@src/store/modules/pages/problem/actions';
import {extractProblem} from '@src/store/modules/pages/problem/selectors/problem';
import {useCommentsPermissions} from '@src/pages/ProblemPage/hooks/useCommentsPermissions';
import {makeCommentTitle} from '@src/pages/ProblemPage/utils/makeCommentTitle';
import ProblemCommentsVisibilityFilter from '@src/components/ProblemCommentsVisibilityFilter/ProblemCommentsVisibilityFilter';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {formProblemLink} from '@tehzor/tools/utils/links/formProblemLink';
import ProblemComments from '@src/components/ProblemComments';
import {useProblemCommentsViewer} from '@src/core/hooks/imagesViewers/mobile/useProblemCommentsViewer';
import {extractProblemCommentsAsTree} from '@src/store/modules/pages/problem/selectors/comments';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {convertProblem} from '../ProblemPage/utils/convertProblem';

const ProblemCommentsPage = () => {
	useScrollRestoration();

	const {objectId, problemId} = useStrictParams<{objectId: string, problemId: string}>();
	const problem = useAppSelector(extractProblem);
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const preparedProblem = convertProblem(problem, usersMap);
	const comments = useAppSelector(extractProblemCommentsAsTree);
	const dispatch = useAppDispatch();

	const permissions = useCommentsPermissions(objectId, preparedProblem);

	const filter
		= permissions.canViewComments && permissions.canViewReplies ? (
			<ProblemCommentsVisibilityFilter
				popupProps={{placement: 'bottom-end', preferX: 'right'}}
			/>
		) : null;

	useAppHeader(
		{
			title: makeCommentTitle(permissions.canViewComments, permissions.canViewReplies),
			showBackBtn: true,
			mobileRightButtons: filter
		},
		[permissions]
	);

	useAsync(async () => {
		await dispatch(getProblem(objectId, problemId));
	});

	const [imagesViewer, openImage] = useProblemCommentsViewer(comments);

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return <Navigate to={formProblemLink(objectId, problemId)}/>;
	}
	return (
		<div className="page-cont problem-comments-page">
			<ProblemComments
				className={{
					root: 'problem-comments-page__comments',
					content: 'problem-comments-page__comments-content',
					item: 'problem-comments-page__comment',
					footer: 'problem-comments-page__comments-footer',
					replyBtn: 'problem-comments-page__comments-reply-btn',
					reference: 'problem-comments-page__comments-reference',
					input: 'problem-comments-page__comments-input'
				}}
				objectId={objectId}
				problemId={problemId}
				links={problem?.links}
				comments={comments}
				onImageClick={openImage}
			/>

			{imagesViewer}
		</div>
	);
};

export default ProblemCommentsPage;
