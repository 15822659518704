import {wsConnector} from '../wsConnector';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';

export type IAddSpaceOwnerResponse = ISpaceOwner;

/**
 * Добавляет собственника помещения
 *
 * @param companyId id компании
 * @param data данные собственника
 */
export const makeSpaceOwnerAddRequest = (companyId: string, data: ISavingSpaceOwner) =>
	wsConnector.sendAuthorizedRequest<IAddSpaceOwnerResponse>('addSpaceOwner', {
		companyId,
		...data
	});
