import {useIsFetching, useQueryClient} from '@tanstack/react-query';
import {useMemo} from 'react';
import {getStates} from '../utils/getStates';

export const useCacheStates = (
	queryKey: Array<string | null | number | string[]>
): [boolean, boolean, boolean, boolean, number | undefined] => {
	const queryClient = useQueryClient();
	const isFetching = useIsFetching(queryKey);
	const state = useMemo(() => queryClient.getQueryState(queryKey), [isFetching]);
	const settings = useMemo(() => queryClient.getQueryDefaults(queryKey), [isFetching]);
	const [isError, isStale, isPaused, dataUpdatedAt] = useMemo(
		() => getStates(state, settings),
		[state, settings]
	);
	return [!!isFetching, isError, isStale, isPaused, dataUpdatedAt];
};
