import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckItemLink} from '@tehzor/tools/utils/links';
import {getCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import {getCheckList} from '@src/store/modules/app/links/actions/getCheckList';
import {extractCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';

export const CheckItemEntityLink = ({
	checkItemId,
	objectId,
	spaceId,
	checkListId
}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const checkItem = useAppSelector(s => extractCheckItemById(s, checkItemId));
	const listId = checkListId || checkItem!.checkListId;
	const checkList = useAppSelector(s => s.app.links.checkListId?.[listId]);

	useAsync(async () => {
		await Promise.all([dispatch(getCheckItems()), dispatch(getCheckList(listId))]);
	}, [listId]);

	const handleClick = useCallback(() => {
		pushPath(
			formCheckItemLink({
				listId,
				itemId: checkItemId,
				objectId,
				spaceId,
				stage: checkList!.stage
			})
		);
	}, [checkItemId, checkList, listId, objectId, spaceId]);

	if (!checkList || !checkItem) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`Чек-лист "${checkList.name}"`}
			leftIcon={<i className="tz-external-link-16"/>}
			onClick={handleClick}
			type="filled"
		/>
	);
};
