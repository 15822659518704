import React, {forwardRef, Ref} from 'react';
import {OuterTagProps, OuterTagType} from './ButtonBase';

interface IButtonBaseOuterProps {
	className: string;
	style?: React.CSSProperties;
	tagType: OuterTagType;
	tagProps: OuterTagProps;
	children: React.ReactNode;

	onClick?: (event: React.MouseEvent) => void;
	onMouseEnter?: (event: React.MouseEvent) => void;
	onMouseLeave?: (event: React.MouseEvent) => void;
}

const ButtonBaseOuter = (
	{tagType, tagProps, children, ...rest}: IButtonBaseOuterProps,
	ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>
) => {
	const props = {
		...rest,
		...tagProps,
		tabIndex: 0,
		children,
		ref
	} as Record<string, unknown>;

	return React.createElement(tagType, props);
};

export default forwardRef(ButtonBaseOuter);
