import {wsConnector} from '../wsConnector';
import {ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetCheckRecordStatusesResponse = INormalizedData<ICheckRecordStatus>;

/**
 * Возвращает список статусов приёмок собственниками
 */
export const requestCheckRecordStatuses = () =>
	wsConnector.sendAuthorizedRequest<IGetCheckRecordStatusesResponse>('getCheckRecordStatuses');
