import React from 'react';
import {CellProps} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {Tag, TagsContainer} from '@tehzor/ui-components';
import './StructureNameCell.less';

export const StructureNameCell = ({row}: CellProps<IEnrichedStructure>) => {
	const structure = row.original;

	return (
		<>
			{structure.name}
			{structure.problemsStats?.length ? (
				<TagsContainer
					className="structures-name-container"
				>
					{structure.problemsStats.map(item => (
						<Tag
							key={item.key}
							className={{
								root: 'structures-name-cell',
								label: 'structures-name-cell__label',
								icon: 'structures-name-cell__icon'
							}}
							icon={(
								<i
									className="tz-problem-16"
									style={{color: item.color}}
								/>
							)}
							label={item.value.toString()}
							size="large"
						/>
					))}
				</TagsContainer>
			) : null}
		</>
	);
};
