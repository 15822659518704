import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	makeOwnerAcceptanceStatusEditRequest,
	IEditOwnerAcceptanceStatusResponse
} from '@src/api/backend/ownerAcceptance';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';

export type IEditOwnerAcceptanceStatusPayload = IEditOwnerAcceptanceStatusResponse;

const request = () => ({
	type: types.EDIT_STATUS_REQUEST
});

const success = (response: IEditOwnerAcceptanceStatusResponse) => ({
	type: types.EDIT_STATUS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса передачи');
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет статус передачи собственнику
 */
export const editOwnerAcceptanceStatus = (
	objectId: string,
	claimId: string,
	statusId: OwnerAcceptanceStatusId
) =>
	createApiAction<IEditOwnerAcceptanceStatusResponse>(request, success, failure, () =>
		makeOwnerAcceptanceStatusEditRequest(objectId, claimId, statusId));
