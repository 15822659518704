import React, {useCallback} from 'react';
import {IMonthData} from '../hooks/useMonthsGrid';
import CalendarButton from '../../../buttons/CalendarButton';

export interface IMonthProps extends IMonthData {
	active?: boolean;
	onClick?(value: number): void;
}

const Month = (props: IMonthProps) => {
	const {value, name, active, onClick} = props;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(value);
		}
	}, [value, onClick]);

	return (
		<CalendarButton
			key={value}
			className="calendar__month"
			active={active}
			onClick={handleClick}
		>
			{name}
		</CalendarButton>
	);
};

export default Month;
