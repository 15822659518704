/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React, {CSSProperties, useCallback} from 'react';
import '../ObjectItem.less';
import defaultImage from './defaultImage.png';

interface IObjectImageProps {
	className?: string;
	image: string | undefined;
	title?: string;
	onClick?: () => void;
}

export const ObjectImage = ({className, image, title, onClick}: IObjectImageProps) => {
	const imageStyles = {} as CSSProperties;

	if (image) {
		imageStyles.backgroundImage = `url(${image})`;
	} else {
		imageStyles.backgroundImage = `url(${defaultImage as string})`;
	}

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick();
		}
	}, [onClick]);

	return (
		<div
			className={classNames('object-item__img', className)}
			style={imageStyles}
			title={title}
			onClick={handleClick}
		/>
	);
};
