import React, {useCallback, useState} from 'react';
import {AddingCheckListDialog} from '../AddingCheckListDialog';

export const useAddingCheckListDialog = (): [React.ReactNode, () => void] => {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<AddingCheckListDialog
			isOpen={isOpen}
			close={close}
		/>
);

	return [dialog, open];
};
