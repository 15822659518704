import {wsConnector} from '@src/api/backend/wsConnector';

export interface IDeleteManyCategoriesResponse {
	success: boolean;
}

/**
 * Удаляет все виды работ по id набора
 *
 * @param categoriesSetId id набора видов работ
 */
export const requestDeleteManyCategories = (categoriesSetId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteManyCategoriesResponse>('deleteCategoriesBySetId', {
		id: categoriesSetId
	});
