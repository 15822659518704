import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useInspectionBreadcrumbs} from '@src/core/hooks/breadcrumbs/useInspectionBreadcrumbs';
import DesktopInspectionExportBtn from './actions/InspectionExportBtn.desktop';
import {usePermissions} from '../hooks/usePermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInspection} from '@src/store/modules/entities/inspection/selectors/inspection';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IPageBreadcrumbsProps {
	objectId: string;
	inspectionId: string;
}

const PageBreadcrumbs = ({objectId, inspectionId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useInspectionBreadcrumbs(objectId);
	const inspection = useAppSelector(extractInspection);
	const permissions = usePermissions(objectId, inspection);
	const isDesktop = useIsDesktop();

	return (
		<AppBreadcrumbs
			className="inspection-page__breadcrumbs"
			items={breadcrumbs}
		>
			{permissions.canExport && isDesktop ? (
				<DesktopInspectionExportBtn
					objectId={objectId}
					inspectionId={inspectionId}
				/>
			) : null}
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
