import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractSpaceStatusesAsArrayByObjectId} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const SpacesStatusesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();

	const statuses = useAppSelector(s => extractSpaceStatusesAsArrayByObjectId(s, objectId));
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={statuses}
			label="Статус помещений"
			filterName="statuses"
		/>
	);
};

export default SpacesStatusesFilterPage;
