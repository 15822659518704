import React from 'react';
import {CellProps} from 'react-table';
import {CriticalMarker} from '@src/components/CriticalMarker';
import {PhotoPreview} from '@tehzor/ui-components';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCategoryById} from '@src/store/modules/dictionaries/categories/selectors';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';

interface IDescriptionCellProps extends CellProps<IProblemEntity> {
	settings?: IProblemSettings;
}

const DescriptionCell = ({row, settings}: IDescriptionCellProps) => {
	const category = useAppSelector(s => extractCategoryById(s, row.original.data.categoryId));

	const numberEl = (
		<div className="problems-page__d-table-description-number">
			№
			{row.original.data.number || row.original.data.localNumber?.toUpperCase()}
		</div>
	);

	const criticalEl = row.original.data.critical && <CriticalMarker/>;

	const locationCommentsEl = row?.original?.data?.hasLocationComments && (
		<i className="tz-comment-on-plan-16"/>
	);

	const problemTagsEl = row.original.data.problemTags?.length && (
		<ProblemTagsMarker
			className="problems-page__d-table-tags"
			colors={row.original.data.problemTags}
		/>
	);

	const descriptionEl = (
		<div className="problems-page__d-table-description-text">
			{row.original.data.description}
		</div>
	);

	const categoryEl = category && (
		<div className="problems-page__d-table-description-category">
			{category?.name}
		</div>
	);

	const attachmentsEl = (
		<div className="problems-page__d-table-description-image">
			{row.original.data.attachment?.preview && (
				<PhotoPreview
					url={row.original.data.attachment.preview.url}
					data={0}
				/>
			)}
		</div>
	);

	return (
		<div className="problems-page__d-table-description">
			<div>
				<div className="problems-page__d-table-description-header">
					{numberEl}
					{settings?.critical && criticalEl}
					{settings?.location && locationCommentsEl}
					{settings?.problemTags && problemTagsEl}
				</div>

				{settings?.description && descriptionEl}
				{settings?.categoryId && categoryEl}
			</div>
			{settings?.attachments && attachmentsEl}
		</div>
	);
};

export default DescriptionCell;
