import {
	IAddInspectionParams,
	IEditInspectionParams
} from '@src/api/cache/inspections/defaults/useInspectionsMutationDefaults';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useEditInspectionMutation} from '@src/api/cache/inspections/mutations';
import {editInspectionsActions} from '@src/store/modules/entities/inspection/actions';
import {addWarningToast} from '@src/utils/toasts';
import {useQueryClient} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {useCallback} from 'react';
import useAppDispatch from '../../useAppDispatch';
import {ISavingInspectionQuery} from './useAddInspection';
import {toListInspection} from './utils/convertToLocalSave';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export const useEditInspection = (object: IObject) => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const mutation = useEditInspectionMutation();

	const editInspection = useCallback((params: IEditInspectionParams) => {
		const {inspectionId, fields, objectId} = params;
		const payloadToSave = queryClient.getQueryData(
			inspectionsQueryKeys.savingData(inspectionId)
		);
		if (payloadToSave) {
			const newPayload = queryClient.setQueryData<ISavingInspectionQuery>(
				inspectionsQueryKeys.savingData(inspectionId),
				data => {
					if (data) {
						if (fields.attachments) {
							data.savingData.newAttachments = data.savingData.newAttachments?.filter(
								att => fields.attachments?.some(item => item.id === att.key)
							);
						}
						if (fields.newAttachments) {
							if (
								!data.savingData.newAttachments
								|| !data.savingData.newAttachments.length
							) {
								data.savingData.newAttachments = [];
							}
							data.savingData.newAttachments = [
								...data.savingData.newAttachments,
								...fields.newAttachments
							];
						}

						return {
							...data,
							savingData: {
								...data.savingData,
								...fields,
								newAttachments: data.savingData.newAttachments
							}
						};
					}
					return data;
				}
			);
			const cachedInspection = queryClient.setQueryData<IInspection>(
				[...inspectionsQueryKeys.detail(inspectionId), objectId],
				(data: IInspection) => {
					if (data) {
						return {
							...data,
							...toListInspection(
								object,
								data.links,
								data.stage,
								fields,
								inspectionId
							)
						};
					}
					return data;
				}
			);
			void queryClient.refetchQueries({
				queryKey: inspectionsQueryKeys.localList()
			});
			const mutationCache = queryClient.getMutationCache();
			const currentMutation = mutationCache.find<
				IProblem,
				IError,
				IAddInspectionParams,
				unknown
			>({
				predicate: mut => {
					const key = (mut.state.variables as IAddInspectionParams).key;
					return inspectionId === key;
				}
			});

			if (currentMutation && newPayload && currentMutation.state.variables) {
				currentMutation.setState({
					...currentMutation.state,
					isPaused: true,
					status: 'loading',
					failureCount: 0,
					error: null,
					failureReason: null,
					variables: {
						...currentMutation.state.variables,
						fields: newPayload.savingData
					}
				});
			}
			dispatch(editInspectionsActions.success(cachedInspection as ILinkedInspection));
			addWarningToast('Успех', 'локальное нарушение изменено');
		}
		// Секция изменения нормального нарушения
		if (!payloadToSave) {
			mutation.mutate({
				...params
			});
		}
	}, []);
	return [editInspection];
};
