import React, {useState} from 'react';
import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {EditableBankAccountDialog} from '../EditableBankAccountDialog';

interface IUseEditableBankAccountDialogProps {
	bankAccount?: IBankAccount;
	legalEntityId?: string;
	companyId?: string;
}

export const useEditableBankAccountDialog = (props: IUseEditableBankAccountDialogProps) => {
	const {bankAccount, legalEntityId, companyId} = props;
	const [isOpen, setOpen] = useState(false);

	const handleOpenBankAccountDialog = () => setOpen(true);
	const handleCloseBankAccountDialog = () => setOpen(false);

	const bankAccountDialog = (
		<EditableBankAccountDialog
			bankAccount={bankAccount}
			legalEntityId={legalEntityId}
			companyId={companyId}
			isOpen={isOpen}
			onClose={handleCloseBankAccountDialog}
		/>
	);

	return {bankAccountDialog, handleOpenBankAccountDialog};
};
