import React from 'react';
import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import ICompanyContractor from '@tehzor/tools/interfaces/companies/ICompanyContractor';
import CommonStatsCards from './CommonStatsCards';
import {ContractorsStatsMap} from '@src/utils/statsAggregators/creation';
import WorkerStats from './WorkerStats';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';

interface IContractorStatsProps {
	contractor: ICompanyContractor;
	contractorStats?: ContractorsStatsMap['id'];
	users: string[];
}

const ContractorStats = ({contractor, contractorStats, users}: IContractorStatsProps) => {
	const sourcesLoading = useAppSelector(extractSourcesLoading);

	return (
		<VerticalTabContent className="statistics-page__tabs-content-panel">
			<StatsTreePanel
				id={contractor.id}
				title={contractor.name}
				loading={sourcesLoading}
				showExpandBtnPlaceholder
				permanentContent={(
					<CommonStatsCards
						stats={contractorStats?.stats}
						hideChecks
						hideInspections
						hideSpaces
						hideWarrantyClaims
					/>
				)}
				hiddenContent={users.map(userId => (
					<WorkerStats
						key={userId}
						userId={userId}
						stats={contractorStats?.usersStats[userId]}
						level={1}
					/>
				))}
			/>
		</VerticalTabContent>
	);
};

export default ContractorStats;
