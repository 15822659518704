import React, {memo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';

interface IBaseListFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	options: Array<{id: string, name: string}>;
	label: string;
	filterName: string;
	resetOnApply?: string[];
	search?: React.ReactNode;

	onChange: (changes: IChanges) => void;
}

export const BaseListFilterMobile = memo(
	({
		objectId,
		value,
		entity,
		options,
		label,
		filterName,
		resetOnApply,
		search,
		onChange
	}: IBaseListFilterMobileProps) => {
		const [selected, setSelected] = useState(value);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, filterName);

		const handleChange = (v: string[] | undefined) => {
			const changes = {[filterName]: v?.length ? v : undefined};
			resetOnApply?.forEach(el => {
				changes[el] = undefined;
			});
			onChange(changes);
		};

		return (
			<MobileFilter
				label={label}
				elements={options}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={search}
			/>
		);
	}
);
