import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {IGetInspectionsResponse} from '@src/api/backend/inspections/get';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/reducers';
import {queryClient} from '@src/api/QueryClient';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';

export interface IGetInspectionsPayload extends IGetInspectionsResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetInspectionsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	} as IGetInspectionsPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка осмотров');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

export const getInspectionsActions = {request, success, failure};

/**
 * Получает список осмотров
 */
export const getInspections
	= (
		objectId: string,
		state?: IInspectionsFiltersState
	): AppThunkAction<Promise<IGetInspectionsResponse>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetInspectionsResponse>(
				() => request(objectId),
				(res: IGetInspectionsResponse) => success(objectId, res),
				failure,
				() => {
					const s = getState();
					if (
						!s.entities.inspections[objectId]
						|| !s.settings.pages.inspections[objectId]
					) {
						throw new StoreError('Empty store before getting inspections');
					}
					const {offset} = s.entities.inspections[objectId];
					const {filters, sort, pageSize} = s.settings.pages.inspections[objectId];
					return queryClient.fetchQuery([
						...inspectionsQueryKeys.list(),
						state || filters,
						sort,
						offset,
						pageSize
					]);
				}
			)
		);
