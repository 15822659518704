import React, {createContext, useCallback, useMemo} from 'react';
import {useIsDesktop, useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {
	EntitiesTable,
	Pagination,
	PaginationAndSize,
	PaginationPageSize,
	Plate
} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {IPreparedOwnerAcceptance} from '../../interfaces/IPreparedOwnerAcceptance';
import {
	extractOwnerAcceptancesAsArray,
	extractOwnerAcceptancesData
} from '@src/store/modules/entities/ownerAcceptances/selectors';
import {extractOwnerAcceptancesPageSettings} from '@src/store/modules/settings/pages/ownerAcceptances/selectors';
import {getOwnerAcceptances} from '@src/store/modules/entities/ownerAcceptances/actions/get';
import {
	changePageSize,
	changeSort
} from '@src/store/modules/settings/pages/ownerAcceptances/actions';
import {convertOwnerAcceptances} from '../../utils/convertOwnerAcceptance';
import {
	changeOffset,
	changeSelectedRows
} from '@src/store/modules/entities/ownerAcceptances/actions';
import {deleteOwnerAcceptance} from '@src/store/modules/entities/ownerAcceptance/actions';
import {mobileColumns, mobileColumnsWithoutObject} from './columns/mobileColumns';
import {desktopColumns, desktopColumnsWithoutObject} from './columns/desktopColumns';
import {extractOwnerAcceptanceResolutionsAsArray} from '@src/store/modules/dictionaries/ownerAcceptanceResolutions/selectors';
import {extractMarkersAsArray} from '@src/store/modules/entities/markers/selectors';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';
import {
	ownerAcceptanceStatusesAsArray,
	useOwnerAcceptanceStatuses
} from '@src/core/hooks/queries/ownerAcceptanceStatuses';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useLocalOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/useGetOwnerAcceptances';

const pageSizes = [10, 20, 50, 100];

interface IOwnerAcceptancesTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedOwnerAcceptance>) => void
>(() => ({}));

export const OwnerAcceptancesTable = ({objectId = 'all'}: IOwnerAcceptancesTableProps) => {
	const {pushPath} = useChangePath();
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const ownerAcceptances = useAppSelector(s => extractOwnerAcceptancesAsArray(s, objectId));
	const localOwnerAcceptances = useLocalOwnerAcceptances(objectId);
	const ownerAcceptancesData = useAppSelector(s => extractOwnerAcceptancesData(s, objectId));
	const pageSettings = useAppSelector(s => extractOwnerAcceptancesPageSettings(s, objectId));
	const resolutions = useAppSelector(extractOwnerAcceptanceResolutionsAsArray);
	const ownerAcptStatuses = useOwnerAcceptanceStatuses();
	const statuses = ownerAcceptanceStatusesAsArray(ownerAcptStatuses);
	const markers = useAppSelector(s => extractMarkersAsArray(s, objectId));
	const plans = useAppSelector(s => extractPlansAsArray(s, objectId || 'all'));
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const pagesCount = Math.ceil(ownerAcceptancesData.total / pageSettings.pageSize);
	const currentPage = Math.floor(ownerAcceptancesData.offset / pageSettings.pageSize);

	const preparedOwnerAcceptances = useMemo(
		() =>
			convertOwnerAcceptances(
				[...localOwnerAcceptances, ...ownerAcceptances],
				resolutions,
				statuses,
				markers,
				plans,
				networkStatus,
				user
			),
		[
			ownerAcceptances,
			localOwnerAcceptances,
			networkStatus,
			user,
			markers,
			plans,
			resolutions,
			statuses
		]
	);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить передачу собственнику?'
	);

	const [, loadOwnerAcceptances] = useAsyncFn(async () => {
		await dispatch(getOwnerAcceptances(objectId || 'all'));
	}, [objectId, online, dispatch]);

	const handleRowClick = useCallback((ownerAcceptance: IPreparedOwnerAcceptance) => {
		if (ownerAcceptance.object) {
			pushPath(
				`/objects/${ownerAcceptance.object.id}/owner-acceptances/${ownerAcceptance.id}`
			);
		}
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows(objectId, value)),
		[dispatch, objectId]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort(objectId, value));
			void loadOwnerAcceptances();
		},
		[dispatch, objectId, loadOwnerAcceptances]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(objectId, value));
			dispatch(changeOffset(objectId, Math.floor(ownerAcceptancesData.offset / value)));
			void loadOwnerAcceptances();
		},
		[objectId, ownerAcceptancesData.offset, loadOwnerAcceptances, dispatch]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (ownerAcceptancesData.offset !== offset) {
				dispatch(changeOffset(objectId, offset));
				void loadOwnerAcceptances();
			}
		},
		[
			pageSettings.pageSize,
			ownerAcceptancesData.offset,
			dispatch,
			objectId,
			loadOwnerAcceptances
		]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedOwnerAcceptance>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await dispatch(
						deleteOwnerAcceptance(action.payload.object.id, action.payload.id)
					);
					await loadOwnerAcceptances();
				}
			}
		},
		[objectId, dispatch]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		desktopColumns,
		desktopColumnsWithoutObject
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={preparedOwnerAcceptances}
						selectedRows={ownerAcceptancesData.selectedRows}
						sort={pageSettings.sort}
						selectable={isDesktop}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
						hideLastHeaderCell={isDesktop}
						headVisible={isDesktop}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>

			{deleteDialog}
		</>
	);
};
