import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditWorkAcceptanceResponse,
	makeWorkAcceptanceEditRequest
} from '@src/api/backend/workAcceptance';
import {ISavingWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/ISavingWorkAcceptance';

export type IEditWorkAcceptancePayload = IEditWorkAcceptanceResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditWorkAcceptanceResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении приёмки работ');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет приёмку работ
 */
export const editWorkAcceptance = (objectId: string, workAcceptanceId: string, data: ISavingWorkAcceptance) =>
	createApiAction<IEditWorkAcceptanceResponse>(request, success, failure, () =>
	makeWorkAcceptanceEditRequest(objectId, workAcceptanceId, data));
