import {EditableSpaceStatuses} from '@src/components/EditableSpaceStatuses/EditableSpaceStatuses';
import {useSpaceStatusesSetPermissions} from '@src/core/hooks/permissions/useSpaceStatusesSetsPermissions';
import {
	IEditableSpaceStatusesSetState,
	makeDefaultData,
	useEditableSpaceStatusesSetState
} from '@src/core/hooks/states/useSpaceStatusesSetState';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import React, {ReactNode} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {Menu} from '../components/menu/Menu';
import {EditableSpaceStatusesSet} from '../EditableSpaceStatusesSet';
import {useUpdateContent} from './useUpdateContent';

export interface IEditableSpaceStatusesSet {
	setEl: ReactNode;
	setMenuEl: ReactNode;
	statusesEl: ReactNode;

	state: IEditableSpaceStatusesSetState;
}

export const useEditableSpaceStatusesSet = (
	spaceStatusesSet?: ISpaceStatusesSet,
	statuses?: ISpaceStatus[]
): IEditableSpaceStatusesSet => {
	const defaultData = makeDefaultData(spaceStatusesSet, statuses);
	const [editingState, editingDispatch] = useEditableSpaceStatusesSetState(defaultData);

	const {updateSpaceStatusesSet, removeSpaceStatusesSet, updateSpaceStatus, removeSpaceStatus} =
		useUpdateContent(editingState, editingDispatch, defaultData);

	const {spaceStatusesSetId} = useStrictParams<{spaceStatusesSetId: string}>();

	const permissions = useSpaceStatusesSetPermissions();
	const canAdd = permissions.canAdd;
	const canEdit =
		(permissions.canEdit && !spaceStatusesSet?.default) ||
		(permissions.canEditDefault && spaceStatusesSet?.default);
	const canModify = spaceStatusesSetId === 'empty' ? canAdd : canEdit;
	const canDelete = permissions.canDelete && !spaceStatusesSet?.default;

	const setEl = (
		<EditableSpaceStatusesSet
			defaultData={defaultData}
			canModify={canModify}
			editingDispatch={editingDispatch}
			editingState={editingState}
			updateSet={updateSpaceStatusesSet}
		/>
	);

	const setMenuEl = (
		<Menu
			spaceStatusesSetId={spaceStatusesSetId}
			canDelete={canDelete}
			removeSpaceStatusesSet={removeSpaceStatusesSet}
		/>
	);

	const statusesEl = (
		<EditableSpaceStatuses
			statuses={statuses}
			canModify={canModify}
			editingDispatch={editingDispatch}
			editingState={editingState}
			updateStatus={updateSpaceStatus}
			removeStatus={removeSpaceStatus}
			spaceStatusesSetId={spaceStatusesSetId}
		/>
	);

	return {
		setEl,
		setMenuEl,
		statusesEl,
		state: editingState
	};
};
