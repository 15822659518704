import React, {Ref, ReactNode, Dispatch, SetStateAction, useEffect} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import DialogTitle from '../components/DialogTitle';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import AddingAcceptance from '../components/AddingAcceptance';
import {AddingOwnerAcceptanceView} from '../AddingOwnerAcptDialog';
import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractLatestOwnerAcceptanceId,
	extractLatestOwnerAcceptancesAsArray
} from '@src/store/modules/dictionaries/latestUserOwnerAcceptances/selectors';
import {useAsync} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getLatestUserOwnerAcceptances} from '@src/store/modules/dictionaries/latestUserOwnerAcceptances/actions/get';
import {extractOwnerAcceptance} from '@src/store/modules/entities/ownerAcceptance/selectors/acceptance';

interface IUseAcceptanceMapsProps {
	objectId: string;
	acceptanceId?: string;
	spaceId?: string;
	stage: ObjectStageIds;
	links?: IOwnerAcceptance['links'];
	view: AddingOwnerAcceptanceView;
	addingEntityRef: Ref<IAddingProblemRefProps | IAddingInspectionRefProps>;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedOwnerAcceptanceId?: string;
	setSelectedOwnerAcceptanceId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;

	onClose: () => void;
}

export const useOwnerAcceptanceMaps = (
	props: IUseAcceptanceMapsProps
): [Record<ObjectStageIds, ReactNode>, Record<ObjectStageIds, IEntitySelectProps>] => {
	const {
		objectId,
		acceptanceId,
		spaceId,
		stage,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedOwnerAcceptanceId,
		setSelectedOwnerAcceptanceId,
		defaultProblemData,
		onClose
	} = props;

	const contentMap = {} as Record<ObjectStageIds, ReactNode>;
	const entitiesSelectMapProps = {} as Record<ObjectStageIds, IEntitySelectProps>;

	const latestOwnerAcceptanceId = useAppSelector(s =>
		extractLatestOwnerAcceptanceId(s, objectId, links?.spaceId));
	const latestOwnerAcceptances = useAppSelector(s =>
		extractLatestOwnerAcceptancesAsArray(s, objectId, links?.spaceId));

	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (latestOwnerAcceptanceId) {
			setSelectedOwnerAcceptanceId(latestOwnerAcceptanceId);
		}
	}, [latestOwnerAcceptanceId]);

	useAsync(async () => {
		if (online) {
			await dispatch(getLatestUserOwnerAcceptances());
		}
	}, [online]);

	contentMap[stage] = (
		<>
			<div className="adding-owner-acpt-dialog__title">
				<DialogTitle view={view}/>
			</div>
			{view === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={{...links, ownerAcceptanceId: selectedOwnerAcceptanceId}}
					stage={stage}
					defaultData={defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : view === 'acceptance' ? (
				<AddingAcceptance
					objectId={objectId}
					spaceId={spaceId}
					links={links}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	const ownerAcceptance = useAppSelector(s =>
		(acceptanceId ? extractOwnerAcceptance(s) : undefined));

	entitiesSelectMapProps[stage] = {
		name: 'Передача собственнику',
		newName: 'Новое передача собственнику',
		selected: selectedOwnerAcceptanceId,
		onEntityChange: setSelectedOwnerAcceptanceId,
		withoutNew: !!ownerAcceptance,
		entities: ownerAcceptance ? [ownerAcceptance] : latestOwnerAcceptances,
		localEntities: []
	};

	return [contentMap, entitiesSelectMapProps];
};
