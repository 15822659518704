import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {IEntityState} from '@tehzor/tools/utils/reducersHandlers';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';

export const createLocationDifference = (
	prevLocation: string | undefined,
	prevFloor: string | undefined,
	prevPlanId: string | undefined,
	nextLocation: string | undefined,
	nextFloor: string | undefined,
	nextPlanId: string | undefined,
	plans: IEntityState<IPlan>
): IHistoryData => ({
	prev: {
		location: prevLocation,
		floor: prevFloor,
		planName: prevPlanId ? plans.byId[prevPlanId]?.name : undefined
	},
	next: {
		location: nextLocation,
		floor: nextFloor,
		planName: nextPlanId ? plans.byId[nextPlanId]?.name : undefined
	},
	type: HistoryTypeId.LOCATION,
	name: 'место'
});
