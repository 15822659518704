import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {offlineJournalActionType} from '../enums/actionsTypes';

export const useLocalChecksJournal = () => {
	const {data} = useQuery<IListCheck[], unknown, IOfflineJournalEntity[]>({
		queryKey: checksQueryKeys.localList(),
		select: checks =>
			checks.map(
				check =>
					({
						entity: {
							entityId: check.id,
							entityLocalNumber: check.localNumber,
							entityType: offlineJournalActionType.CHECK_ADD
						},
						object: {
							objectId: check.object?.id,
							objectName: check.object?.name
						},
						transfer: {
							status: check.transferStatus,
							statusMessage: check.transferStatus
								? offlineModeTransferTitles[check.transferStatus]
								: ''
						},
						createdAt: check.createdAt
					} as IOfflineJournalEntity)
			)
	});
	return data || [];
};
