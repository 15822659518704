import React from 'react';
import {HeaderContext} from '@tanstack/react-table';
import {getMonthIndex, isCurrentMonth} from '@src/pages/TasksPage/utils/timeMonthUtils';
import classNames from 'classnames';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IScheduleData} from '@src/pages/TasksPage/utils/convertTasksToScheduleFormat';

export const MonthHeader = (props: HeaderContext<IScheduleData, IScheduleData>) => {
	const monthId = props.column.id;
	const monthIndex = getMonthIndex(monthId);
	const {settings} = useAppSelector(extractTasksScheduleSettings);

	const isCurrentMonthFlag = isCurrentMonth(monthIndex, settings.year);

	return (
		<div
			className={classNames('manage-task-schedule-page__month-header',
		{'manage-task-schedule-page__month-header_active': isCurrentMonthFlag})}
		>
			{monthId}
		</div>
	);
};
