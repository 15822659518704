import React, {Dispatch, useCallback, useMemo, useState} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel, Select2,
	SelectDialog, SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {IListUnit} from '@src/core/hooks/queries/units';

const unitIcon = <i className="tz-unit-20"/>;

interface IUnitSelectProps<S, E> {
	field?: keyof S;
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	unitsListArr: IListUnit[];
	classNameSelectSearch?: string;
}

const getInputLabel = (unitId: string | undefined, unitsArr: IListUnit[]) => {
	if (!unitId) {
		return undefined;
	}

	const unit = unitsArr.find(unit => unit.id === unitId);

	return unit ? unit?.fullListName : undefined;
};

export const UnitSelect = <S extends {unit?: string}, E>({
	className,
	style,
	field = 'unit',
	label = 'Единица измерения физического объёма',
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	unitsListArr,
	classNameSelectSearch
}: IUnitSelectProps<S, E>) => {
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	const filteredData = useMemo<IListUnit[]>(
		() => flatFilter(unitsListArr, 'fullListName', search),
		[search, unitsListArr]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectDialog
				value={value}
				onChange={handleChange}
				onClose={clearSearch}
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, unitsListArr)}
						icon={unitIcon}
						error={required && hasError ? 'Выберите единицу измерения' : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2>
					<SelectSearch
						value={search}
						onChange={setSearch}
						className={classNameSelectSearch}
					/>
					{filteredData && filteredData.map(unit => (
						<SelectOption
							key={unit.id}
							itemKey={unit.id}
							content={unit.fullListName}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectDialog>
		</div>
	);
};
