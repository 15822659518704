import React from 'react';
import EntitiesFilters, {
	// NumberFilter,
	ObjectsFilter,
	PlansFilter,
	// FloorsFilter,
	CreationDateFilter,
	CreatedByFilter
} from '@src/components/EntitiesFilters';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/reducers';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';

interface IFiltersProps {
	objectId?: string;
}

const InspectionsFilters = ({objectId = 'all'}: IFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IInspectionsFiltersState>();
	return (
		<EntitiesFilters
			objectId={objectId}
			entity="inspections"
		>
			{/* <NumberFilter
				value={state?.number}
				placeholder="№ нарушения"
			/> */}

			{objectId === 'all' && <ObjectsFilter value={state?.objects}/>}

			{objectId !== 'all' && (
				<PlansFilter
					objectId={objectId}
					plans={state?.plans}
				/>
)}

			{/* <FloorsFilter value={state?.floors} /> */}

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
			/>
		</EntitiesFilters>
	);
};

export default InspectionsFilters;
