import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {useAppHeader} from './useAppHeader';
import {AppHeaderCtxOptions} from '../utils/AppHeaderCtx';
import {DependencyList} from 'react';

export function useObjectAppHeader(
	objectId: string,
	options?: AppHeaderCtxOptions,
	deps?: DependencyList
) {
	const object = useAppSelector(s => extractCurrentTreeObject(s, objectId));

	useAppHeader(
		{title: object?.name, showBackBtn: true, ...options},
		deps ? deps.concat([object]) : [object]
	);
}
