import React from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import {DesktopActions} from './actions/Actions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IPageBreadcrumbsProps {
	objectId: string;
}

export const PageBreadcrumbs = ({objectId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);
	const isDesktop = useIsDesktop();

	return (
		<AppBreadcrumbs
			className="structures-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && (
				<>
					<DesktopActions objectId={objectId}/>
				</>
			)}
		</AppBreadcrumbs>
	);
};
