import React from 'react';
import classNames from 'classnames';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsync from 'react-use/lib/useAsync';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {LoadingPanel} from '@tehzor/ui-components';
import {getOwnerAcceptances} from '@src/store/modules/entities/ownerAcceptances/actions/get';
import {OwnerAcceptancesFilters} from './components/OwnerAcceptancesFilters';
import {OwnerAcceptancesPageCounter} from './components/OwnerAcceptancesPageCounter';
import {OwnerAcceptancesTable} from './components/table/OwnerAcceptancesTable';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {ObjectPageBreadcrumbs} from './components/ObjectPageBreadcrumbs';
import {AllObjectPageBreadcrumbs} from './components/AllObjectPageBreadcrumbs';
import {ActionsDesktop} from './components/actions/Actions.desktop';
import './OwnerAcceptancesPage.less';
import {extractOwnerAcceptancesData} from '@src/store/modules/entities/ownerAcceptances/selectors';
import {getMarkers} from '@src/store/modules/entities/markers/actions';
import {extractMarkersAsArray} from '@src/store/modules/entities/markers/selectors';
import {getPlans} from '@src/store/modules/dictionaries/plans/actions';
import {useParams} from 'react-router-dom';

export const OwnerAcceptancesPage = () => {
	useScrollRestoration();

	const {objectId} = useParams<{objectId?: string}>();

	const dispatch = useAppDispatch();
	const ownerAcceptances = useAppSelector(s => extractOwnerAcceptancesData(s, objectId));
	const markers = useAppSelector(s => extractMarkersAsArray(s, objectId));
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);

	const isDesktop = useIsDesktop();

	const {loading} = useAsync(async () => {
		if (networkStatus) {
			await dispatch(getOwnerAcceptances(objectId || 'all'));
		}
		if (ownerAcceptances.allIds.length) {
			await dispatch(getMarkers({objectId, ownerAcceptanceIds: ownerAcceptances?.allIds}));
		}
		if (markers.length) {
			const planIds = markers.reduce((acc: string[], marker) => {
				if (!acc.includes(marker.planId)) {
					return [...acc, marker.planId];
				}
				return acc;
			}, []);
			await dispatch(getPlans(objectId ? [objectId] : undefined, planIds));
		}
	}, [objectId, networkStatus, ownerAcceptances, markers]);

	const actions = isDesktop ? <ActionsDesktop objectId={objectId}/> : null;

	return (
		<LoadingPanel
			className="owner-acceptances-page__loading-panel"
			active={loading}
		>
			<div className={classNames('page-cont', 'owner-acceptances-page')}>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}>{actions}</ObjectPageBreadcrumbs>
				) : (
					isDesktop && <AllObjectPageBreadcrumbs>{actions}</AllObjectPageBreadcrumbs>
				)}
				<div className="owner-acceptances-page__filters">
					<div className="owner-acceptances-page__filters-bar">
						<OwnerAcceptancesFilters objectId={objectId}/>
					</div>
					<OwnerAcceptancesPageCounter objectId={objectId}/>
				</div>

				<OwnerAcceptancesTable objectId={objectId}/>
			</div>
		</LoadingPanel>
	);
};
