import React, {memo} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {useCommentPermissions} from '../hooks/useCommentPermissions';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IMenuItemProps} from '@tehzor/ui-components/src/components/menu/MenuItem';

interface ICommentMenuProps {
	user: IUser;
	comment: IProblemComment;

	onExport: (id: string) => void;
	onEdit: (id: string, official?: boolean) => void;
	onDelete: (id: string, official?: boolean) => void;
}

const CommentMenu = (props: ICommentMenuProps) => {
	const {comment, user, onExport, onEdit, onDelete} = props;

	const permissions = useCommentPermissions(comment.objectId, user, comment);

	const items: Array<React.ReactElement<IMenuItemProps>> = [];
	if (permissions.canExport) {
		items.unshift(
			<MenuItem
				key="export"
				icon={<i className="tz-export-24"/>}
				onClick={() => onExport(comment.id)}
			>
				Экспортировать
			</MenuItem>
		);
	}
	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit"/>}
				onClick={() => onEdit(comment.id, comment.official)}
			>
				Редактировать
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={() => onDelete(comment.id, comment.official)}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length > 0 ? <IconMenu>{items}</IconMenu> : null;
};

export default memo(CommentMenu);
