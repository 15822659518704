import {Tree} from 'array-to-tree';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import {merge} from 'lodash';
import {ISuggestions} from './getSuggestionsByRules';
import {isMatch} from '@tehzor/tools/utils/responsibilityRules/matchers';
import {IProblemParams} from '@tehzor/tools/utils/responsibilityRules/IProblemParams';

export const findResponsible = (
	groups: Array<Tree<IWorkingGroup>>,
	rules: IResponsibilityRule[],
	params: IProblemParams
): [ISuggestions, Set<string>, Set<string>] => {
	let suggestions: ISuggestions = {};
	let activeGroups = new Set<string>();
	let selectedUsers = new Set<string>();

	for (const group of groups) {
		const groupRules = rules.filter(rule => rule.groupId === group.id && isMatch(rule, params));

		for (const rule of groupRules) {
			if (!suggestions[rule.groupId]) {
				suggestions[rule.groupId] = [];
			}

			if (rule.target.userId) {
				if (!suggestions[rule.groupId].includes(rule.target.userId)) {
					suggestions[rule.groupId].push(rule.target.userId);
				}
				if (rule.autoSelect) {
					activeGroups.add(rule.groupId);
					selectedUsers.add(rule.target.userId);
				}
				continue;
			}

			if (rule.target.groupId) {
				if (!suggestions[rule.target.groupId]) {
					suggestions[rule.target.groupId] = [];
				}

				const found = findTreeNode(group.children || [], rule.target.groupId);
				if (found) {
					const [s, g, u] = findResponsible([found], rules, params);

					suggestions = merge(suggestions, s);

					// Если были выбраны правила с автоселектом, то в activeGroups вернётся непустой набор
					if (g.size) {
						activeGroups = new Set<string>([...activeGroups, ...g]);
						selectedUsers = new Set<string>([...selectedUsers, ...u]);
						continue;
					}
				}

				if (rule.autoSelect) {
					activeGroups.add(rule.target.groupId);
				}
			}
		}
	}

	return [suggestions, activeGroups, selectedUsers];
};
