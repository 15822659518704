import {EntityGridItem} from '@tehzor/ui-components';
import React, {ReactNode} from 'react';
import {EntityLinks} from '../EntityLinks/EntityLinks';

interface ILinksProps {
	links?: Record<string, string | undefined>;
	fullRow?: boolean;
	children?: ReactNode;
}

const linksIcon = <i className="tz-link-20" />;

export const Links = ({links, fullRow, children}: ILinksProps) => (
	<EntityGridItem
		icon={linksIcon}
		fullRow={fullRow}
		label="Ссылки"
	>
		<EntityLinks
			links={links}
			isMobile
		>
			{children}
		</EntityLinks>
	</EntityGridItem>
);
