import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import React, {useCallback} from 'react';
import {StatsBorderRectangle} from '../../components/StatsBorderRectangle';
import {CriticalMarker} from './CriticalMarker';

interface ISpaceRectangleProps {
	space: IEnrichedSpace;
	width: number;
	height: number;
	radius: number;
	onClick?: (space: IEnrichedSpace) => void;
}

const DEFAULT_COLOR = '#FFF';

export const SpaceRectangle = ({space, onClick, width, height, radius}: ISpaceRectangleProps) => {
	const {name, status, problems} = space;
	const isCritical = problems?.some(problem => problem.critical);
	// начальное смещение
	const Lx = 12;
	const Ly = 12;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [space]);

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			onClick={handleClick}
			className="problems-space__status"
		>
			{/* тень */}
			<rect
				x={Lx + 2}
				y={Ly + 2}
				width={width - 2 * Lx - 4}
				height={height - 2 * Ly - 4}
				rx="9"
				className="problems-space__status-shadow"
			/>
			{/* фон */}
			<rect
				x={1 + Lx}
				y={1 + Ly}
				width={width - 2 * Lx - 2}
				height={height - 2 * Ly - 2}
				rx="9"
				fill={status?.color || DEFAULT_COLOR}
			/>

			{/* бордер */}
			{problems?.map(item => (
				<StatsBorderRectangle
					key={item.key}
					width={width - 2 * Lx}
					height={height - 2 * Ly}
					radius={radius}
					item={item}
				/>
			))}
			{isCritical && (
				<CriticalMarker
					x={width - 2 * Lx - 4}
					y={height - 2 * Ly - 4}
				/>
			)}
			<text
				className="problems-space__number"
				x="50%"
				y="50%"
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{name}
			</text>
		</svg>
	);
};
