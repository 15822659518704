import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {MobileFilter, SelectSearch} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractCategoriesSetsAsArray,
	extractFullCategoriesSetsData
} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {extractAllCategoriesAsArray} from '@src/store/modules/dictionaries/categories/selectors';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface ICategoriesSetFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const CategoriesSetFilterMobile = memo(
	({objectId, value, entity, onChange}: ICategoriesSetFilterMobileProps) => {
		const [selected, setSelected] = useState<string[] | undefined>([]);
		const [search, setSearch] = useState('');

		const categoriesSets = useAppSelector(s =>
			extractCategoriesSetsAsArray(s, objectId || 'all'));
		const allCategories = useAppSelector(extractAllCategoriesAsArray);

		const elements = useMemo(() => {
			if (!categoriesSets.length) { return []; }
			if (categoriesSets.length > 1) {
				return categoriesSets.map(val => ({id: val.id, name: val.name}));
			}

			return allCategories[categoriesSets[0].id].map(val => ({id: val.id, name: val.name}));
		}, [categoriesSets, allCategories]);
		const filteredElements = useMemo(() => flatFilter(elements, 'name', search), [elements, search]);

		const {byId} = useAppSelector(extractFullCategoriesSetsData);

		useEffect(() => {
			if (categoriesSets.length > 1) {
				const selectedSets = categoriesSets.reduce((prev, current) => {
					if (
						!prev.includes(current.id)
						&& current.categories.some(category => value?.includes(category.id))
					) {
						return [...prev, current.id];
					}

					return prev;
				}, [] as string[]);

				setSelected(selectedSets);
				return;
			}

			setSelected(value);
		}, [categoriesSets, value]);

		const handleChange = useCallback((v: string[] | undefined) => {
			const categories: string[] | undefined = v ? [] : undefined;

			if (v && categories) {
				if (categoriesSets.length > 1) {
					v.forEach(id =>
						categories.push(...byId[id].categories.map(category => category.id)));
				}
				if (categoriesSets.length === 1) {
					categories.push(...v);
				}
			}

			setSelected(categories);
			onChange({categories});
		}, [byId, categoriesSets.length, onChange]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'categories');

		return (
			<MobileFilter
				label="Виды работ"
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
					/>
				)}
			/>
		);
	}
);
