import React, {useCallback} from 'react';
import {Toggle} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleAltNamesVisibility} from '@src/store/modules/settings/pages/spaces/actions';

export const Settings = () => {
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const dispatch = useAppDispatch();

	const handleCheck = useCallback(() => {
		dispatch(toggleAltNamesVisibility());
	}, []);

	return (
		<>
			<Toggle
				className="spaces-page__setting"
				checked={!!altNamesVisible}
				onChange={handleCheck}
			>
				Дополнительные названия помещений
			</Toggle>
		</>
	);
};
