import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {deleteSpaceTypeDecorationSetRequest} from '@src/api/backend/spaceTypeDecorationSets/deleteSet';

export type IDeleteSpaceTypeDecorationSetPayload = ISpaceTypeDecorationSet;

const request = () => ({type: types.DELETE_REQUEST});

const success = (response: {id: string}) => {
	addSuccessToast('Удалено', 'Набор типов отделки успешно удален');
	return {
		type: types.DELETE_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении набора типов отделки');
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет набор типов отделки
 *
 * @param spaceTypeDecorationSetId id набора
 */
export const deleteSpaceTypeDecorationSet = (spaceTypeDecorationSetId: string) =>
	createApiAction(request, success, failure, () =>
		deleteSpaceTypeDecorationSetRequest(spaceTypeDecorationSetId));
