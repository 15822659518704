import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {toInternalAcceptance, toListInternalAcceptance} from './utils/convertToLocalSave';
import {useAddInternalAcceptanceMutation} from '@src/api/cache/internalAcceptances/mutations';
import {IAddInternalAcceptanceParams} from '@src/api/cache/internalAcceptances/defaults/useInternalAcceptancesMutationDefaults';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {addInternalAcceptanceActions} from '@src/store/modules/entities/internalAcceptance/actions';
import {useLocalInternalAcceptances} from '../../queries/internalAcceptances/useGetInternalAcceptances';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';

export const useAddInternalAcceptance = (object: IObject) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const user = useAppSelector(s => s.auth.profile);
	const nextNumber = useNextLocalNumber<IListInternalAcceptance>(
		useLocalInternalAcceptances(),
		'createdAt'
	);
	const mutation = useAddInternalAcceptanceMutation();
	return ({objectId, links, stage}: Omit<IAddInternalAcceptanceParams, 'key'>) => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}
		const converted = toInternalAcceptance(user, objectId, links, stage, key, nextNumber);
		const convertedToList = toListInternalAcceptance(
			object,
			user,
			links,
			stage,
			key,
			nextNumber
		);

		setTimeout(
			() =>
				queryClient.setQueryData(internalAcceptancesQueryKeys.detail(key), convertedToList),
			0
		);
		if (!online) {
			addWarningToast('Локальное сохранение', 'приемка добавлена локально');
			setTimeout(() => {
				void queryClient.refetchQueries(internalAcceptancesQueryKeys.localList());
			}, 0);
			dispatch(addInternalAcceptanceActions.success(converted));
		}
		mutation.mutate({
			objectId,
			links,
			stage,
			key
		});
		return converted;
	};
};
