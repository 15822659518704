import React, {useMemo} from 'react';
import {formCheckLink, formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {usePermissions} from './usePermissions';

export interface ITaskLink {
	id: string;
	type: 'problem' | 'inspection' | 'check';
	icon: React.ReactNode;
	url?: string;
	title: string;
	label: string;
}

export function useTaskLinks(task: IEnrichedTask): ITaskLink[] {
	const permissions = usePermissions(task.objectId.id);
	const problems = useAppSelector(s => s.entities.problems);
	const inspections = useAppSelector(s => s.entities.inspections);
	const checks = useAppSelector(s => s.entities.checks);

	return useMemo((): ITaskLink[] => {
		const result = [] as ITaskLink[];

		if (problems?.[task.objectId.id]?.byId) {
			const problemsObj = problems[task.objectId.id].byId;
			const keys = Object.keys(problems[task.objectId.id].byId);

			keys.forEach(key => {
				const problem = problemsObj?.[key];

				if (
					problem?.links?.taskId !== undefined
					&& problem?.links?.taskId === task.id
					&& permissions.canViewProblem
				) {
					result.push({
						id: problem.id,
						type: 'problem',
						icon: <i className="tz-check-24"/>,
						url: formProblemLink(problem.objectId, problem.id),
						title: 'Перейти к нарушению',
						label: `Нарушение №${problem.number}`,
					});
				}
			});
		}

		if (inspections?.[task.objectId.id]?.byId) {
			const inspectionsObj = inspections[task.objectId.id].byId;
			const keys = Object.keys(inspections[task.objectId.id].byId);

			keys.forEach(key => {
				const inspection = inspectionsObj?.[key];

				if (
					inspection?.links?.taskId !== undefined
					&& inspection?.links?.taskId === task.id
					&& permissions.canViewInspection
				) {
					result.push({
						id: inspection.id,
						type: 'inspection',
						icon: <i className="tz-check-24"/>,
						url: formInspectionLink(inspection.objectId, inspection.id),
						label: `Осмотр №${inspection.number}`,
						title: 'Перейти к осмотру'
					});
				}
			});
		}

		if (checks?.[task.objectId.id]?.byId) {
			const checksObj = checks[task.objectId.id].byId;
			const keys = Object.keys(checks[task.objectId.id].byId);

			keys.forEach(key => {
				const check = checksObj?.[key];

				if (
					check?.links?.taskId !== undefined
					&& check?.links?.taskId === task.id
					&& permissions.canViewCheck
				) {
					result.push({
						id: check.id,
						type: 'check',
						icon: <i className="tz-check-24"/>,
						url: formCheckLink(check.objectId, check.id),
						label: `Проверка №${check.number}`,
						title: 'Перейти к проверке'
					});
				}
			});
		}

		return result;
	}, [task, problems, inspections, checks, permissions]);
}