import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {DatePicker} from '@tehzor/ui-components/src/components/dates';
import {format} from 'date-fns';
import {IDatePickerTriggerProps} from '@tehzor/ui-components/src/components/dates/DatePicker/DatePicker';
import {CriticalCheckbox} from '../CriticalCheckbox';

const dateFormat = 'dd.MM.yyyy HH:mm';
const dialogProps = {title: 'Выберите дату'};
const icon = <i className="tz-calendar-20"/>;

const disabledDate = (current?: Date, createdAt?: number) => {
	if (!current) {
		return false;
	}

	return current.valueOf() < (createdAt ?? new Date().setHours(0, 0, 0, 0));
};

interface IFixDateProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: number;
	criticalValue?: boolean;
	criticalFieldsSettings?: IObjectFieldSetting;
	criticalDisabled?: boolean;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
	createdAt?: number;
	showTimeSelect?: boolean;
	field?: keyof S;
}

export const EditableDate = <S extends Record<string, unknown>, E>({
	className,
	style,
	label = 'Срок устранения',
	value,
	criticalValue,
	criticalFieldsSettings,
	criticalDisabled,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage,
	createdAt,
	showTimeSelect,
	field = 'plannedFixDate'
}: IFixDateProps<S, E>) => {
	const handleChange = useCallback(
		(v: Date | null) => {
			editingDispatch({type: 'update', field, value: v ? v.getTime() : null});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[editingDispatch, field, required]
	);

	const onClear = useCallback(() => {
		editingDispatch({type: 'update', field, value: undefined});
	}, []);

	const date = value ? new Date(value) : undefined;

	const prepareDisabledDate = useCallback(
		(cur: Date) => disabledDate(cur, createdAt),
		[createdAt]
	);

	const getTriger = useCallback(
		(props: IDatePickerTriggerProps) => (
			<TextFieldWithForwardedRef
				elementType="div"
				value={props?.value !== undefined ? format(props.value, dateFormat) : ''}
				disabled={disabled}
				error={required && hasError ? errorMessage || 'Укажите срок устранения' : undefined}
				icon={icon}
				onClick={props?.toggle}
				ref={props?.ref}
				onClearClick={onClear}
				cleanable={!!props?.value}
			/>
		),
		[disabled, errorMessage, hasError, required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<DatePicker
				trigger={getTriger}
				value={date}
				disabledDate={prepareDisabledDate}
				dialogProps={dialogProps}
				useApplyButton
				showTimeSelect={showTimeSelect}
				onChange={handleChange}
			/>
			{criticalFieldsSettings !== undefined && (
				<CriticalCheckbox
					value={criticalValue}
					plannedFixDateValue={value}
					disabled={disabled || criticalDisabled}
					editingDispatch={editingDispatch}
				/>
			)}
		</div>
	);
};
