import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractProblemsEntitiesByItem,
	extractProblemsEntitiesByList
} from '@src/store/modules/pages/checkLists/selectors/problems';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formProblemLink} from '@tehzor/tools/utils/links';
import {EntityInnerTable} from '@tehzor/ui-components';
import {mobileColumns} from '@src/pages/CheckListPage/components/columns.mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckDetailsEntity} from '@src/interfaces/ICheckDetailsEntity';
import {EmptyPlate} from '@src/pages/CheckListPage/components/detail/EmptyPlate/EmptyPlate';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface IItem extends ICheckItem {
	children?: any;
}

interface ITableProps {
	item?: IItem;
	list?: ICheckList;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

export const ProblemsTable = ({item, list, selectedRows, onSelectedRowsChange}: ITableProps) => {
	const {pushPath} = useChangePath();
	const params = useStrictParams<{itemId: string, objectId: string, spaceId?: string}>();

	const problems = useAppSelector(s => {
		if (item) {
			return extractProblemsEntitiesByItem(s, item.checkListId, item.id, params.spaceId);
		}
		if (list) {
			return extractProblemsEntitiesByList(s, list.id);
		}
		return null;
	}) as ICheckDetailsEntity[];

	const handleRowClick = useCallback(
		(entity: ICheckDetailsEntity) => {
			if (entity.type === 'local_problem') {
				return;
			}
			pushPath(formProblemLink(params.objectId, entity.id));
		},
		[params.objectId]
	);

	return (
		<div className="check-item-detail__list">
			{problems.length > 0 ? (
				<>
					<div className="check-item-detail__list-body">
						<EntityInnerTable
							columns={mobileColumns}
							hideHead
							selectedRows={selectedRows}
							onSelectedRowsChange={onSelectedRowsChange}
							data={problems}
							selectable
							onRowClick={handleRowClick}
						/>
					</div>
				</>
			) : (
				<EmptyPlate
					text="Нет нарушений"
					icon={<i className="tz-inspection"/>}
				/>
			)}
		</div>
	);
};
