import {requestStructure} from '@src/api/backend/structure/get';
import {createEntityAction} from '@src/store/modules/app/links/actions/createEntityAction';
import {getStructureTypes} from '@src/store/modules/dictionaries/structureTypes/actions';

/**
 * Возвращает структуру по id
 */
export const getStructure = createEntityAction('structureId', requestStructure, {
	loadAdditionalData: () => dispatch => {
		void dispatch(getStructureTypes());
	}
});
