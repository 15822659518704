import {IGetPlansResponse} from '@src/api/backend/plans';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {queryClient} from '@src/api/QueryClient';
import {store} from '@src/store/appStore';

export const getCachedPlans = async (objectIds?: string[], planIds?: string[]): Promise<IGetPlansResponse> => {
	const state = store.getState();
	const selectedObjects = state.settings.offlineMode.cache.objects;
	if (selectedObjects) {
		const plansCache = queryClient.getQueryData<IGetPlansResponse>(
			plansQueryKeys.list(selectedObjects)
		);
		if (plansCache) {
			const asArray = Object.entries(plansCache.byId);

			// Фильтруем по объектам
			const filteredByObjects = objectIds ? asArray.filter(([, value]) =>
				objectIds.includes(value.objectId)) : asArray;

			// Фильтруем по типам помещений
			const filterByIds
				= planIds && planIds.length
					? filteredByObjects.filter(([, value]) => planIds?.includes(value.id))
					: filteredByObjects;

			const byId = Object.fromEntries(filterByIds);
			const allIds = plansCache.allIds.filter(id => Object.keys(byId).includes(id));
			return {byId, allIds};
		}
	}
	return {allIds: [], byId: {}};
};
