/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useCallback, useRef, useState} from 'react';
import './AuthForm.less';
import classNames from 'classnames';
import {Checkbox, TextField} from '../../inputs';
import Providers, {IProvider} from './components/Providers';
import {Button, InlineButton} from '../../buttons';
import LoadingPanel from '../../LoadingPanel';

interface IAuthFormProps {
	className?: string;
	providers?: IProvider[];

	isRemember?: boolean;
	onRemember?: (isRemember: boolean) => void;
	onLogin?: (email: string, password: string) => Promise<unknown>;
	onRecovery?: () => void;
	onRegister?: () => void;
}

const emailIcon = <i className="auth-form__form-icons tz-mail-20"/>;
const passwordVisibleIcon = <i className="auth-form__form-icons tz-password-visible-20"/>;
const passwordHiddenIcon = <i className="auth-form__form-icons tz-password-hidden-20"/>;
const warningIcon = <i className="auth-form__error-icon tz-circle-warning-32"/>;

const getErrorDescription = (code: string) => {
	switch (code) {
		case 'InvalidCredentials':
			return 'Неверный логин или пароль';
		case 'NoActiveConnection':
			return 'Отсутствует соединение с сервером';
		case 'TimeoutError':
			return 'Отсутствует соединение с сервером';
		default:
			return 'Ошибка при авторизации';
	}
};

const AuthForm = ({
	className, providers, onLogin, onRecovery, isRemember = false, onRegister, onRemember
}: IAuthFormProps) => {
	const classes = classNames('auth-form', className);

	const [isBusy, setBusy] = useState(false);
	const [error, setError] = useState<string | undefined>();
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);

	const handleLogin = useCallback(async () => {
		const email = emailRef.current;
		const password = passwordRef.current;

		if (!onLogin || !email || !password) { return; }

		const emailValue = email.value.trim();
		if (!emailValue) {
			email.focus();
			return;
		}

		const passwordValue = password.value;
		if (!passwordValue) {
			password.focus();
			return;
		}

		email.blur();
		password.blur();

		setBusy(true);
		try {
			const result = await onLogin(emailValue, passwordValue) as {error: string} | void;

			if (
				typeof result === 'object'
				&& result !== null
				&& !Array.isArray(result)
				&& result.hasOwnProperty('error')
			) {
				setError(getErrorDescription(result.error));
			}
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			setError(getErrorDescription(error.error));
		} finally {
			setBusy(false);
		}
	}, []);

	const handleRecovery = useCallback(() => {
		if (onRecovery) {
			onRecovery();
		}
	}, []);

	const handleRegistration = useCallback(() => {
		if (onRegister) {
			onRegister();
		}
	}, []);

	const handleRemember = useCallback(() => {
		if (onRemember) {
			onRemember(isRemember);
		}
	}, [isRemember]);

	const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			void handleLogin();
		}
	}, []);

	const emailProps = {
		id: 'login-email',
		type: 'email',
		onKeyDown: handleKeyDown
	} as React.InputHTMLAttributes<HTMLInputElement>;

	const passwordProps = {
		id: 'login-password',
		onKeyDown: handleKeyDown
	} as React.InputHTMLAttributes<HTMLInputElement>;

	return (
		<LoadingPanel
			className="auth-form__loading-panel"
			active={isBusy}
		>
			<div className={classes}>
				<div className="auth-form__header">
					<header className="auth-form__header-text">
						Вход в систему
					</header>
				</div>
				<form className="auth-form__form">
					<div className="auth-form__form-email">
						<label htmlFor={emailProps.id}>
							Email:
						</label>
						<TextField
							elementRef={emailRef}
							elementProps={emailProps}
							elementType="input"
							icon={emailIcon}
						/>
					</div>
					<div className="auth-form__form-password">
						<label htmlFor={passwordProps.id}>
							Пароль:
						</label>
						<TextField
							elementRef={passwordRef}
							elementProps={passwordProps}
							elementType="input"
							icon={passwordVisibleIcon}
							hiddenIcon={passwordHiddenIcon}
							hideable
							isHidden
						/>
						{onRecovery && (
							<InlineButton
								className={classNames('auth-form__form-recovery', 'auth-form__links')}
								label="Забыли пароль?"
								type="accent"
								onClick={handleRecovery}
							/>
						)}
						{onRemember && (
							<Checkbox
								checked={isRemember}
								onChange={handleRemember}
							>
								Запомнить меня
							</Checkbox>
						)}
					</div>
				</form>
				{error && (
					<div className="auth-form__error">
						{warningIcon}
						{error}
					</div>
				)}
				<Button
					className="auth-form__submit"
					label="Войти"
					type="accent-blue"
					onClick={handleLogin}
				/>
				{(onRegister || providers) && (
					<>
						<div className="auth-form__divider">
							<p className="auth-form__divider-text">или</p>
						</div>
						{providers && (
							<Providers
								className="auth-form__providers"
								providers={providers}
							/>
						)}
						{onRegister && (
							<div
								className={classNames('auth-form__registration', 'auth-form__links')}
							>
								<InlineButton
									className="auth-form__registration-btn"
									label="Зарегистрироваться"
									type="accent"
									onClick={handleRegistration}
								/>
							</div>
						)}
					</>
				)}
			</div>
		</LoadingPanel>
	);
};

AuthForm.displayName = 'AuthForm';

export default AuthForm;
