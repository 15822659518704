import React, {ReactNode, Dispatch, SetStateAction, RefObject} from 'react';
import AddingInspection, {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import EntityTypeSelect from '../components/EntityTypeSelect';
import {useSpaceLocation} from './useSpaceLocation';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheck} from '@src/store/modules/entities/check/selectors';
import {extractLatestChecksAsArray} from '@src/store/modules/dictionaries/latestUserChecks/selectors';
import {useLatestChecks} from './useLatestChecks';

interface IUseCheckMapsProps {
	objectId: string;
	checkId?: string;
	stage: ObjectStageIds;
	links?: ICheck['links'];
	availableTypes: ICheckAddingEntityType[];
	type?: ICheckAddingEntityType;
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	problemToCopyId?: string;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedCheckId?: string;
	setSelectedCheckId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;

	onClose: () => void;
}

export const useCheckMaps = (
	props: IUseCheckMapsProps
): [Record<ObjectStageIds, ReactNode>, Record<ObjectStageIds, IEntitySelectProps>] => {
	const {
		objectId,
		checkId,
		stage,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedCheckId,
		setSelectedCheckId,
		onClose
	} = props;

	const contentMap = {} as Record<ObjectStageIds, ReactNode>;
	const entitiesSelectMapProps = {} as Record<ObjectStageIds, IEntitySelectProps>;

	const [defaultProblemData, defaultInspectionData] = useSpaceLocation(
		objectId,
		selectedCheckId,
		props.defaultProblemData,
		props.defaultInspectionData
	);

	const latestChecks = useAppSelector(s =>
		extractLatestChecksAsArray(s, objectId, links?.spaceId)
	);
	useLatestChecks(objectId, setSelectedCheckId, selectedCheckId, links);

	contentMap[stage] = (
		<>
			<div className="adding-check-dialog__title">
				{type !== undefined && (
					<EntityTypeSelect
						type={type}
						types={availableTypes}
						disabled={saving}
					/>
				)}
			</div>
			{type === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={links}
					stage={stage}
					defaultData={problemToCopyId ? props.defaultProblemData : defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
					problemToCopyId={problemToCopyId}
				/>
			) : type === 'inspection' ? (
				<AddingInspection
					objectId={objectId}
					links={links}
					stage={stage}
					defaultData={defaultInspectionData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	const check = useAppSelector(s => (checkId ? extractCheck(s) : undefined));

	entitiesSelectMapProps[stage] = {
		name: 'Проверка',
		newName: 'Новая проверка',
		selected: selectedCheckId,
		onEntityChange: setSelectedCheckId,
		withoutNew: !!check,
		entities: check ? [check] : latestChecks,
		localEntities: []
	};

	return [contentMap, entitiesSelectMapProps];
};
