import useAppDispatch from '@src/core/hooks/useAppDispatch';
import React, {useCallback, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckItems} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useEditableCheckList} from '@src/components/EditableCheckList/hooks/useEditableCheckList';
import {editCheckList} from '@src/store/modules/entities/checkList/actions';
import {editCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import {addSuccessToast} from '@src/utils/toasts';
import {Button, Plate} from '@tehzor/ui-components';
import './EditingCheckList.less';
import {extractCheckListById} from '@src/store/modules/dictionaries/checkLists/selectors';

interface IEditingCheckListProps {
	checkListId: string;
}

export const EditingCheckList = ({checkListId}: IEditingCheckListProps) => {
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);

	const checkList = useAppSelector(s => extractCheckListById(s, checkListId));
	const checkItems = useAppSelector(s => extractCheckItems(s, checkListId));

	const [checkListFields, checkItemsFields, getSavingData] = useEditableCheckList({
		checkList,
		checkItems,
		saving
	});

	const handleSave = useCallback(async () => {
		setSaving(true);
		const savingData = getSavingData();

		try {
			if (savingData && savingData.checkList && savingData.checkItems) {
				await Promise.all([
					dispatch(editCheckList(checkListId, savingData.checkList)),
					dispatch(editCheckItems(checkListId, savingData.checkItems))
				]);

				addSuccessToast('Успешно', 'Чек-лист был обновлен');
			}
		} finally {
			setSaving(false);
		}
	}, [getSavingData]);

	return (
		<div className="editing-check-list__container">
			<Plate className="editing-check-list__info-grid">
				{checkListFields}

				<Button
					type="accent-blue"
					label="Сохранить"
					disabled={saving}
					onClick={handleSave}
				/>
			</Plate>

			<Plate className="editing-check-list__items-grid">{checkItemsFields}</Plate>
		</div>
	);
};
