import React, {useCallback, useRef} from 'react';
import {ConfirmDialog, IConfirmDialogProps, IConfirmDialogRefProps} from '../components/dialogs/ConfirmDialog';

/**
 * Хук для запроса подтверждения действия пользователя
 */
const useConfirmDialog = (
	title: string,
	message?: string,
	{acceptBtnLabel = 'Да', rejectBtnLabel = 'Нет', ...rest}: Omit<IConfirmDialogProps, 'title' | 'message'> = {}
): [React.ReactNode, (
) => Promise<boolean>] => {
	const ref = useRef<IConfirmDialogRefProps | null>(null);

	const dialog = (
		<ConfirmDialog
			{...rest}
			title={title}
			message={message}
			acceptBtnLabel={acceptBtnLabel}
			rejectBtnLabel={rejectBtnLabel}
			ref={ref}
		/>
	);

	const getConfirmation = useCallback(
		async (): Promise<boolean> => !!(ref.current && (await ref.current.open())),
		[]
	);

	return [dialog, getConfirmation];
};

export default useConfirmDialog;
