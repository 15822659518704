import React, {useCallback, useEffect} from 'react';
import {EntitiesTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SelectionRow} from './SelectionRow';
import {useEnrichedList} from '../../hooks/useEnrichedList';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getSpacesList, changeSelected} from '@src/store/modules/entities/spaces/actions';
import {extractTargetObjects} from '@src/store/modules/dictionaries/objects/selectors';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {extractSpacesListData} from '@src/store/modules/entities/spaces/selectors/lists';
import {changeSort} from '@src/store/modules/settings/pages/spaces/actions';
import {getSpacesProblemsData} from '@src/store/modules/pages/spaces/actions/getSpacesProblemsData';
import {getSpacesCheckListsData} from '@src/store/modules/pages/spaces/actions/getSpacesCheckListsData';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<IEnrichedSpace>>;
	hideHead?: boolean;
	noRowBorder?: boolean;
	selectable?: boolean;
}

const Table = (props: IDesktopTableProps) => {
	const {objectId, columns, hideHead, noRowBorder, selectable} = props;
	const {pushPath} = useChangePath();

	const objectsLoaded = useAppSelector(s => s.dictionaries.objects.loaded);
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const targetObjects = useAppSelector(s => extractTargetObjects(s, objectId));
	const {offset, selected} = useAppSelector(s => extractSpacesListData(s, objectId));
	const {filters, sort, pageSize} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const spaces = useEnrichedList(objectId);

	const [, loadSpaces] = useAsyncFn(
		() =>
			dispatch(getSpacesList(objectId, targetObjects)).then(resolve => {
				if (resolve && online) {
					void dispatch(getSpacesProblemsData({objectId, spacesIds: resolve.allIds}));
					void dispatch(getSpacesCheckListsData({objectId, spacesIds: resolve.allIds}));
				}
			}),
		[objectId, targetObjects, online]
	);

	useEffect(() => {
		if (objectsLoaded) {
			void loadSpaces();
		}
	}, [objectsLoaded]);

	useUpdateEffect(() => {
		void loadSpaces();
	}, [offset, filters, sort, pageSize]);

	const handleRowClick = useCallback(
		(space: IEnrichedSpace) => pushPath(formSpaceLink(space.objectId, space.id)),
		[objectId]
	);

	const handleSelection = useCallback(
		(value: string[]) => dispatch(changeSelected(objectId, value)),
		[objectId]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => dispatch(changeSort(objectId, value)),
		[objectId]
	);

	return (
		<EntitiesTable
			columns={columns}
			data={spaces}
			sort={sort}
			selectedRows={selected}
			selectable={selectable}
			noRowBorder={noRowBorder}
			headVisible={!hideHead}
			onRowClick={handleRowClick}
			onSelectedRowsChange={handleSelection}
			onSortChange={handleSortChange}
			renderSelectionRow={SelectionRow}
		/>
	);
};

export default Table;
