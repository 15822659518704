export const inspectionsQueryKeys = {
	all: () => ['inspections'],
	one: () => ['inspection'],
	list: () => [...inspectionsQueryKeys.all(), 'list'],
	localList: () => [...inspectionsQueryKeys.all(), 'local-list'],
	add: () => [...inspectionsQueryKeys.one(), 'add'],
	savingDatas: () => [...inspectionsQueryKeys.one(), 'saving-data'],
	savingData: (key: string) => [...inspectionsQueryKeys.one(), 'saving-data', key],
	details: () => [...inspectionsQueryKeys.one(), 'detail'],
	detail: (id: string) => [...inspectionsQueryKeys.one(), 'detail', id],
	edit: () => [...inspectionsQueryKeys.one(), 'edit']
};
