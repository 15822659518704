import React from 'react';
import EntitiesFilters, {
	ObjectsFilter,
	CreationDateFilter
} from '@src/components/EntitiesFilters';
import {StatusesFilter} from './StatusesFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';

interface IWorkAcceptancesFiltersProps {
	objectId?: string;
}

export const WorkAcceptancesFilters = ({objectId = 'all'}: IWorkAcceptancesFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="work-acceptances"
		>
			{objectId === 'all' && <ObjectsFilter value={state?.objects}/>}

			<StatusesFilter statuses={state?.statuses}/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
			/>
		</EntitiesFilters>
	);
};
