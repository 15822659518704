import {wsConnector} from '../wsConnector';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';

export type IAddCheckItemsResponse = ICheckItem[];

/**
 * Возвращает категорию
 *
 * @param checkListId id чек-листа
 * @param items данные для добавления
 */
export const makeCheckItemsAddRequest = (checkListId: string, items: ISavingCheckItem[]) =>
	wsConnector.sendAuthorizedRequest<IAddCheckItemsResponse>('batchAddCheckItem', {
		checkListId,
		items
	});
