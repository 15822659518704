import React, {useCallback} from 'react';
import {Row} from 'react-table';
import {useDrag, useDrop} from 'react-dnd';
import '../DndTable.less';

interface IRowProps<D extends {id: string, children?: D[]}> {
	row: Row<D>;
	index: number;
	moveRow: (dragIndex: number, hoverIndex: number) => void;
	onRowClick?: (data: D) => void;
	onSaveDrop: () => void;
}

export const RowTable = <D extends {id: string, children?: D[]}>(props: IRowProps<D>) => {
	const {row, index, moveRow, onRowClick, onSaveDrop} = props;
	const dropRef = React.useRef<HTMLTableRowElement>(null);
	const dragRef = React.useRef<HTMLTableCellElement>(null);

	const handleRowClick = useCallback(() => {
		if (onRowClick) {
			onRowClick(row.original);
		}
	}, [onRowClick, row.original]);

	const DND_ITEM_TYPE = 'row';
	const [, drop] = useDrop({
		accept: DND_ITEM_TYPE,
		hover(item: {index: number}, monitor) {
			if (!dropRef.current) return;

			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) return;

			const hoverBoundingRect = dropRef.current.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

			moveRow(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
		drop() {
			onSaveDrop();
		}
	});

	const [{isDragging}, drag, preview] = useDrag({
		type: DND_ITEM_TYPE,
		item: {type: DND_ITEM_TYPE, index},
		collect: (monitor: {isDragging: () => boolean}) => ({
			isDragging: monitor.isDragging()
		})
	});

	preview(drop(dropRef));
	drag(dragRef);

	return (
		<tr
			className={`dnd-table__row ${isDragging ? 'transparent' : ''}`}
			ref={dropRef}
		>
			<td
				className="dnd-table__move-cell"
				ref={dragRef}
			>
				<i className="tz-drag-and-drop-24"/>
			</td>
			{row.cells.map(cell => (
				<td
					onClick={handleRowClick}
					{...cell.getCellProps([
						{
							className: 'dnd-table__cell'
						},
						{
							className: cell.column.className
						}
					])}
				>
					{cell.render('Cell')}
				</td>
			))}
		</tr>
	);
};