import {IGetTaskStatusesResponse} from '@src/api/backend/taskStatuses';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {addErrorToast} from '@src/utils/toasts';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useTaskStatuses = (): IGetTaskStatusesResponse | undefined => {
	const {data} = useQuery<IGetTaskStatusesResponse, IError>({
		queryKey: [...restDictionariesQueryKeys.taskStatuses()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке статусов задач');
		}
	});
	return data;
};