import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';

interface IDescriptionProps {
	className?: string;
	start?: number;
	stop?: number;
}

export const IntervalView = ({className, start, stop}: IDescriptionProps) => (
	<EntityGridItem
		className={className}
		label="Срок исполнения:"
		inline
	>
		<div>
			<span>{start && format(start, dateTimeFormat)}</span>
			<span>{start && stop && ' – '}</span>
			<span>{stop && format(stop, dateTimeFormat)}</span>
		</div>
	</EntityGridItem>
);
