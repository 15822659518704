import {wsConnector} from '../../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export type IGetCheckInspectionsResponse = INormalizedData<IInspection>;

/**
 * Возвращает осмотры для конкретной проверки
 *
 * @param objectId id объекта
 * @param checkId id проверки
 */
export const requestCheckInspections = (objectId: string, checkId: string) =>
	wsConnector.sendAuthorizedRequest<IGetCheckInspectionsResponse>('getCheckInspections', {
		objectId,
		checkId
	});
