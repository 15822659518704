import {AppThunkAction} from '@src/store/interfaces/thunk';
import {IGetTasksResponse, requestTasks} from '@src/api/backend/tasks';
import {createApiAction} from '@src/store/middlewares/api';
import * as types from '../../constants';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {HttpStatus} from '@tehzor/tools/enums/HttpStatus';
import {createAction} from '@reduxjs/toolkit';

export type IGetSpaceTasksPayload = IGetTasksResponse;

const request = createAction(types.GET_TASKS_REQUEST);

const success = createAction<IGetSpaceTasksPayload>(types.GET_TASKS_SUCCESS);

const failure = createAction(types.GET_TASKS_FAILURE, (error: IError) => {
	if (error.statusCode !== HttpStatus.UNAUTHORIZED) {
		addErrorToast('Ошибка', 'при загрузке списка задач');
	}
	return {
		payload: {...error}
	};
});

/**
 * Получает список задач для помещения
 */
export const getSpaceTasks =
	(spaceId?: string): AppThunkAction<Promise<IGetTasksResponse>> =>
	dispatch =>
		dispatch(
			createApiAction<IGetTasksResponse>(request, success, failure, () =>
				requestTasks({spaces: [spaceId]}, {createdAt: false}, 0, 1000)
			)
		);
