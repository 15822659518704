import React, {Dispatch} from 'react';
import {IEditableObjectAction, IEditableObjectState} from '@src/core/hooks/states/useEditableObjectState';
import {NameField} from './components/NameField';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import './EditableObject.less';
import {ManagerFields} from './components/ManagerFields';
import {ImageField} from './components/ImageField/ImageField';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {IUploadingFilesAction, IUploadingFilesState} from '@src/core/hooks/states/useUploadingFilesState';
import {StageFields} from './components/StageFields';
import CompanySelect from '../editableFields/CompanySelect';

interface IEditableObjectProps {
	objectId?: string;
	companyId?: string;

	editingState: IEditableObjectState;
	editingDispatch: React.Dispatch<IEditableObjectAction>;

	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;

	saving: boolean;
}

export const fieldsSettings: {
	[key: string]: IObjectFieldSetting;
} = {
	name: {fieldId: 'name', isRequired: true},
	companyId: {fieldId: 'companyId', isRequired: true},
	city: {fieldId: 'city', isRequired: false},
	generalContractor: {fieldId: 'generalContractor', isRequired: false},
	constructionManager: {fieldId: 'constructionManager', isRequired: false},
	projectManager: {fieldId: 'projectManager', isRequired: false},
	stages: {fieldId: 'stages', isRequired: false},
	image: {fieldId: 'image', isRequired: false}
};

export const EditableObject = ({
	objectId, companyId, editingState, editingDispatch, uploadingFilesState, uploadingFilesDispatch, saving
}: IEditableObjectProps) => (
	<div className="editable-object">
		<div>
			<div className="editable-object__info-grid">
				<div className="editable-object__container">
					{fieldsSettings.name && (
						<NameField
							className="editable-object__container-name"
							value={editingState.name}
							editingDispatch={editingDispatch}
							required={fieldsSettings.name.isRequired}
							disabled={saving}
							hasError={editingState.errors.name}
						/>
					)}

					{(fieldsSettings.companyId && !companyId) && (
						<CompanySelect
							className="editable-object__container-company"
							field="companyId"
							label="Компания"
							value={editingState.companyId}
							editingDispatch={editingDispatch}
							required={fieldsSettings.companyId.isRequired}
							disabled={saving}
							hasError={editingState.errors.companyId}
						/>
					)}
				</div>

				{fieldsSettings.city && (
					<NameField
						className="editable-object__city"
						value={editingState.city}
						field="city"
						editingDispatch={editingDispatch}
						required={fieldsSettings.city.isRequired}
						disabled={saving}
						hasError={editingState.errors.city}
						label="Город"
						errorMessage="Заполните город"
					/>
				)}

				{fieldsSettings.generalContractor && (
					<NameField
						className="editable-object__general-contractor"
						value={editingState.generalContractor}
						field="generalContractor"
						editingDispatch={editingDispatch}
						required={fieldsSettings.generalContractor.isRequired}
						disabled={saving}
						hasError={editingState.errors.generalContractor}
						label="Ген. подрядчик"
						errorMessage="Заполните Ген. подрядчика"
					/>
				)}
			</div>

			{fieldsSettings.projectManager && (
				<ManagerFields
					field="projectManager"
					title="Руководитель проекта"
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					value={editingState.projectManager}
					required={fieldsSettings.projectManager.isRequired}
					disabled={saving}
					className={{
						root: 'editable-object__project-manager',
						title: 'editable-object__project-manager-title',
						wrap: 'editable-object__project-manager-wrap',
						fullName: 'editable-object__project-manager-wrap__fullName',
						phone: 'editable-object__project-manager-wrap__phone'
					}}
					errorMessage={{
						fullName: 'Заполните ФИО',
						phone: 'Неверно указан телефон'
					}}
					hasError={editingState.errors.projectManager}
					editingDispatch={editingDispatch}
				/>
			)}

			{fieldsSettings.constructionManager && (
				<ManagerFields
					field="constructionManager"
					title="Руководитель строительства"
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					value={editingState.constructionManager}
					required={fieldsSettings.constructionManager.isRequired}
					disabled={saving}
					className={{
						root: 'editable-object__construction-manager',
						title: 'editable-object__construction-manager-title',
						wrap: 'editable-object__construction-manager-wrap',
						fullName: 'editable-object__construction-manager-wrap__fullName',
						phone: 'editable-object__construction-manager-wrap__phone'
					}}
					errorMessage={{
						fullName: 'Заполните ФИО',
						phone: 'Неверно указан телефон'
					}}
					hasError={editingState.errors.constructionManager}
					editingDispatch={editingDispatch}
				/>
			)}

			{fieldsSettings.stages && (
				<StageFields
					title="Стадии"
					value={editingState.stages}
					editingDispatch={editingDispatch}
					required={fieldsSettings.stages.isRequired}
					disabled={saving}
					className={{
						root: 'editable-object__stages',
						title: 'editable-object__stages-title',
						wrap: 'editable-object__stages-wrap',
						stage: 'editable-object__stages-wrap__Stage'
					}}
					hasError={editingState.errors.stages}
				/>
			)}

		</div>

		{fieldsSettings.image && (
			<ImageField
				className={{
					root: 'editable-object__image-field',
					scrollArea: 'editable-object__image-field-scroll-area',
					attachBtn: 'editable-object__image-field-attach-btn'
				}}
				imageTitle="Фото объекта"
				label="Сменить фото"
				imageDestination={AttachmentFileDestination.OBJECT}
				entityId={objectId}
				image={editingState.image}
				editingDispatch={editingDispatch}
				uploadingFile={uploadingFilesState.value[0]}
				uploadingFilesDispatch={uploadingFilesDispatch}
				required={fieldsSettings.image.isRequired}
				disabled={saving}
				hasError={editingState.errors.image && uploadingFilesState.error}
				showAttachBtn
			/>
		)}
	</div>
);