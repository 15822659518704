import React, {useRef, useCallback, useMemo} from 'react';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {IReasonProps, Reason} from '@src/components/editableFields/Reason/Reason';
import IReason from '@tehzor/tools/interfaces/IReason';
import {ReasonSelect, useLocalSearchProvider, useSearchSelectPopup} from '@tehzor/ui-components';
import {extractRegStandardsAsArray} from '@src/store/modules/dictionaries/regulatoryStandards/selectors';
import config from '@src/core/config';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {reasonRenderItem} from '../utils/reasonRenderItem';
import {IEnhancedRegStandard} from '@tehzor/ui-components/src/components/ReasonSelect/ReasonSelect';

export interface IReasonEnhancedRegStandard extends IEnhancedRegStandard {
	externalLink?: string;
}

const externalLink = config.regStandardExternalLink;

type ISearchableProblemReasonProps = Omit<IReasonProps<{reason: IReason}, IProblem>, 'onSelect' | 'addon'>;

export const SearchableProblemReason = ({
	editingDispatch,
	value,
	required,
	disabled,
	hasError,
	...props
}: ISearchableProblemReasonProps) => {
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const regStandards = useAppSelector(extractRegStandardsAsArray);
	const filteredData = useMemo<IReasonEnhancedRegStandard[]>(() => regStandards.map(item => ({
		...item,
		fullName: `СП ${item.number}. «${item.name}»`,
		externalLink
	})), [regStandards]);
	const provider = useLocalSearchProvider<IReasonEnhancedRegStandard>({initialList: filteredData, keys: ['fullName']});

	const onSelect = useCallback((selected: IReasonEnhancedRegStandard) => {
		if (selected.fullName) {
			editingDispatch({
				type: 'update',
				field: 'reason',
				value: {regulatoryStandard: selected.id, value: selected.fullName}
			});
		}
	}, [editingDispatch]);

	const [popup, handleSearchChange] = useSearchSelectPopup<IReasonEnhancedRegStandard>({
		inputRef,
		onSelect,
		provider,
		renderItem: reasonRenderItem,
		noCheckbox: true
	});

  const handleChange = useCallback(
		(v: IReason | null) => {
			editingDispatch({type: 'update', field: 'reason', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'reason'});
			}
		},
		[required, editingDispatch]
	);

	return (
		<>
			<Reason
				ref={inputRef}
				editingDispatch={editingDispatch}
				value={value}
				required={required}
				disabled={disabled}
				hasError={hasError}
				onChange={handleSearchChange}
				select={(
					<ReasonSelect
						data={regStandards}
						externalLink={externalLink}
						value={value}
						error={required && hasError ? 'Укажите основание' : undefined}
						disabled={disabled}
						onChange={handleChange}
					/>
				)}
				search={popup}
				{...props}
			/>
		</>
	);
};
