import classNames from 'classnames';
import React, {memo} from 'react';
import TagPlaceholder, {ITagPlaceholderProps} from '../TagPlaceholder/TagPlaceholder';
import './TagsPlaceholder.less';

export interface ITagsPlaceholderProps {
	className?: string;
	style?: React.CSSProperties;
	count: number;
	inline?: boolean;
	size?: ITagPlaceholderProps['size'];
	color?: string;
	minWidth?: number;
	maxWidth?: number;
}

const TagsPlaceholder = ({
	className,
	style,
	count,
	inline,
	size,
	color,
	minWidth = 90,
	maxWidth = 210
}: ITagsPlaceholderProps) => {
	const tags = [];

	for (let i = 0; i < count; i++) {
		tags.push(
			<TagPlaceholder
				key={i}
				className="tags-placeholder__tag"
				style={{backgroundColor: color}}
				width={Math.floor(Math.random() * (maxWidth - minWidth)) + minWidth}
				size={size}
			/>
		);
	}

	return inline ? (
		<>{tags}</>
	) : (
		<div
			className={classNames('tags-placeholder', className)}
			style={style}
		>
			<div className="tags-placeholder__wrap">{tags}</div>
		</div>
	);
};

export default memo(TagsPlaceholder);
