import React from 'react';
import './TaskViewPage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useAsync} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import MobileRightButtons from './components/actions/RightButtons.mobile';
import {getTask} from '@src/store/modules/pages/task/actions';
import {extractTask} from '@src/store/modules/pages/task/selectors';
import {useEnrichedTask} from './hooks/useEnrichedTask';
import {
	clearExpiration as clearInspectionsExpiration,
	getInspections
} from '@src/store/modules/entities/inspections/actions';
import {
	clearExpiration as clearChecksExpiration,
	getChecks
} from '@src/store/modules/entities/checks/actions';
import {
	clearExpiration as clearProblemsExpiration,
	getProblems
} from '@src/store/modules/entities/problems/actions';
import {getObject} from '@src/store/modules/entities/object/actions';

const TaskViewPage = () => {
	const {taskId} = useStrictParams<{taskId: string}>();
	useScrollRestoration();

	const dispatch = useAppDispatch();

	const {loading} = useAsync(async () => {
		await dispatch(getTask(taskId));
	}, [taskId]);

	const task = useAppSelector(extractTask);

	const {loading: depLoading} = useAsync(async () => {
		if (task?.id) {
			await Promise.allSettled([
				dispatch(getObject(task.objectId)),
				dispatch(clearChecksExpiration(task.objectId)),
				dispatch(clearProblemsExpiration(task.objectId)),
				dispatch(clearInspectionsExpiration(task.objectId))
			]);
			await Promise.allSettled([
				dispatch(getInspections(task.objectId, {taskIds: [taskId]})),
				dispatch(getProblems(task.objectId, {taskIds: [taskId]})),
				dispatch(getChecks(task.objectId, {taskIds: [taskId]}))
			]);
		}
	}, [task, taskId]);

	const isLoading = loading || depLoading;
	const enrichedTask = useEnrichedTask(task);
	useAppHeader(
		{
			title: task ? `Задача ${task.name}` : '',
			showBackBtn: true,
			mobileRightButtons: task ? <MobileRightButtons task={task}/> : null
		},
		[task]
	);
	const isDesktop = useIsDesktop();

	if (enrichedTask === undefined) {
		return null;
	}

	if (isDesktop) {
		return (
			<Desktop
				task={enrichedTask}
				loading={isLoading}
			/>
);
	}

	return (
		<Mobile
			task={enrichedTask}
			loading={isLoading}
		/>
);
};

export default TaskViewPage;
