import React from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';

const SpaceTypeCell = ({row}: CellProps<IEnrichedSpace>) => {
	if (!row) return <> </>;
	const space = row.original;
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);

	return <>{spaceTypes.byId[space.type]?.name ?? ''}</>;
};

export default SpaceTypeCell;
