import React from 'react';
import './ProblemCommentsVisibilityFilter.less';
import {ButtonMenu, IconMenu} from '@tehzor/ui-components';
import classNames from 'classnames';
import {IPopupBasicProps} from '@tehzor/ui-components/src/components/containers/Popup';
import {useVisibilityFilterMenu} from './hooks/useVisibilityFilterMenu';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IProblemCommentsVFilterProps {
	className?: string;
	style?: React.CSSProperties;
	popupProps?: IPopupBasicProps;
}

const ProblemCommentsVisibilityFilter = ({
	className,
	style,
	popupProps
}: IProblemCommentsVFilterProps) => {
	const [menu, label] = useVisibilityFilterMenu();
	const isTablet = useIsTablet();

	return isTablet ? (
		<ButtonMenu
			className={{
				root: classNames('problem-comments-v-filter', className),
				label: 'problem-comments-v-filter__label'
			}}
			style={style}
			type="common-bordered"
			label={label}
			popupProps={popupProps}
		>
			{menu}
		</ButtonMenu>
	) : (
		<IconMenu
			icon={<i className="tz-filters-24"/>}
			popupProps={popupProps}
		>
			{menu}
		</IconMenu>
	);
};

export default ProblemCommentsVisibilityFilter;
