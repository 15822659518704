import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/reducers';
import {queryClient} from '@src/api/QueryClient';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

export interface IGetWarrantyClaimsPayload extends IGetWarrantyClaimsResponse {
	objectId: string;
}

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetWarrantyClaimsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка обращений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

export const getWarrantyClaimsActions = {request, success, failure};

/**
 * Получает список гарантийных обращений
 */
export const getWarrantyClaims
	= (
		objectId: string,
		state?: IWarrantyClaimsFiltersState
	): AppThunkAction<Promise<IGetWarrantyClaimsResponse>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetWarrantyClaimsResponse>(
				() => request(objectId),
				(res: IGetWarrantyClaimsResponse) => success(objectId, res),
				failure,
				() => {
					const s = getState();
					if (
						!s.entities.warrantyClaims[objectId]
						|| !s.settings.pages.warrantyClaims[objectId]
					) {
						throw new StoreError('Empty store before getting warranty claims');
					}
					const {offset} = s.entities.warrantyClaims[objectId];
					const {filters, sort, pageSize} = s.settings.pages.warrantyClaims[objectId];
					const data = queryClient.fetchQuery<IGetWarrantyClaimsResponse>({
						queryKey: [
							...warrantyClaimsQueryKeys.list(),
							state || filters,
							sort,
							offset,
							pageSize
						],
						staleTime: Infinity
					});
					return data;
				}
			)
		);
