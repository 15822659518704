import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

interface ICheckRecordsPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
}

export const useCheckRecordsPermissions = (objectId: string): ICheckRecordsPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		return {
			canView: hasPermission(
				user.roles,
				'check-records-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canAdd: hasPermission(user.roles, 'check-records-add', UserRoleScopes.OBJECT, objectId),
			canEdit: hasPermission(
				user.roles,
				'check-records-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canDelete: hasPermission(
				user.roles,
				'check-records-delete',
				UserRoleScopes.OBJECT,
				objectId
			)
		};
	}, [user, objectId]);
};
