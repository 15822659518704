import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useInternalAcceptanceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useInternalAcceptanceBreadcrumbs';
import DesktopMainActions from './actions/MainActions.desktop';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

interface IPageBreadcrumbsProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
}

const PageBreadcrumbs = ({objectId, internalAcceptance}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useInternalAcceptanceBreadcrumbs(objectId);

	return (
		<AppBreadcrumbs
			className="internal-acceptance-page__breadcrumbs"
			items={breadcrumbs}
		>
			<DesktopMainActions
				objectId={objectId}
				internalAcceptance={internalAcceptance}
			/>
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
