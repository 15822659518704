import {wsConnector} from '@src/api/backend/wsConnector';
import IProblemsByStatusesStatsItem from '@tehzor/tools/interfaces/statistics/IProblemsByStatusesStatsItem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IGetProblemsByStatusesStatsResponse = IProblemsByStatusesStatsItem[];

/**
 * Отправляет запрос на получение статистики нарушений по статусам
 *
 * @param objects фильтр по объектам
 * @param stage стадия объектов
 * @param users фильтр по проверяющим
 * @param contractors фильтр по подрядчикам
 */
export const requestProblemsByStatusesStats = (
	objects: string[] | undefined,
	stage: ObjectStageIds,
	users: string[] | undefined,
	contractors: Record<string, true | string[]> | undefined
) =>
	wsConnector.sendAuthorizedRequest<IGetProblemsByStatusesStatsResponse>(
		'getProblemsByStatusesStats',
		{
			objects,
			stage,
			users,
			contractors
		},
		{timeout: 120}
	);
