import React, {memo} from 'react';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import Location from '@src/components/viewFields/Location';
import Description from '@src/components/viewFields/Description';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';

interface IDesktopInfoProps {
	objectId: string;
	inspection: ILinkedInspection;
}

const DesktopInfo = ({objectId, inspection}: IDesktopInfoProps) => (
	<>
		<EntityGrid
			className={{wrap: 'inspection-page__d-entities-grid'}}
			withBorders
		>
			{inspection.description && <Description value={inspection.description}/>}
			{(!!inspection.plan || !!inspection.floor || !!inspection.location) && (
				<Location
					objectId={objectId}
					planId={inspection.plan?.id}
					floor={inspection.floor}
					location={inspection.location}
				/>
			)}
			<EditorInfo
				icon={<i className="tz-document-20"/>}
				label="Создано"
				date={inspection.createdAt}
				user={inspection.createdBy}
			/>
			{inspection.createdAt !== inspection.modifiedAt && (
				<EditorInfo
					icon={<i className="tz-edit-20"/>}
					label="Изменено"
					date={inspection.modifiedAt}
					user={inspection.modifiedBy}
				/>
			)}
		</EntityGrid>
	</>
);

export default memo(DesktopInfo);
