import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';

export type IGetSpaceTypeDecorationResponse = INormalizedData<ISpaceTypeDecoration[]>;

/**
 * Возвращает нормализованный список типов отделки помещений
 *
 * @param typeDecorationSetId id набора типов отделки помещений
 */
export const makeSpaceTypeDecorationGetRequest = (typeDecorationSetId: string) =>
	wsConnector.sendAuthorizedRequest<IGetSpaceTypeDecorationResponse>(
		'getSpaceTypeDecorationList',
		{id: typeDecorationSetId}
	);

/**
 * Возвращает нормализованный список всех типов отделки помещений
 */
export const makeSpaceTypeDecorationsGetAllRequest = () =>
	wsConnector.sendAuthorizedRequest<IGetSpaceTypeDecorationResponse>(
		'getSpaceTypeDecorationsAll'
	);
