import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import {checkExpiration} from '@tehzor/tools/core/storeHelpers/actions';
import {IGetProblemTagsSetResponse} from '@src/api/backend/problemTagsSet';
import {IGetProblemTagsSetsResponse, requestProblemTagsSets} from '@src/api/backend/problemTagsSets';

export type IGetProblemTagsSetsPayload = IGetProblemTagsSetsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetProblemTagsSetResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке наборов меток нарушений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список наборов меток нарушений
 */
export const getProblemTagsSets = () =>
	checkExpiration<IState, IGetProblemTagsSetsResponse, ApiAction>(
		s => s.dictionaries.problemTagsSets,
		createApiAction<IGetProblemTagsSetsResponse>(request, success, failure, requestProblemTagsSets),
		86400000 // 1 день
	);
