import React from 'react';
import './WorkAcceptancesStatsCell.less';
import {CellProps} from 'react-table';
import {Tag, TagsContainer} from '@tehzor/ui-components';
// import {IConvertedWorkAcceptancesData} from '@tehzor/tools/interfaces/structures/IConvertedWorkAcceptancesData';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';

export const WorkAcceptancesStatsCell = ({row}: CellProps<IEnrichedStructure>) => {
	const data = row.original;

	return data.workAcceptancesStats?.length ? (
		<TagsContainer>
			{data.workAcceptancesStats.map(item => (
				<Tag
					key={item.key}
					className={{
						root: 'work-acceptances-stats-cell',
						label: 'work-acceptances-stats-cell__label',
						icon: 'work-acceptances-stats-cell__icon'
					}}
					icon={(
						<i
							className="tz-work-acceptance-16"
							style={{color: item.color}}
						/>
					)}
					label={item.value.toString()}
					size="large"
				/>
			))}
		</TagsContainer>
	) : null;
};
