import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';

/**
 * Формирует заголовок текущего помещения (в store)
 */
export function useSpaceTitle(
	space: {name: string, altName?: string, type: SpaceTypeId} | undefined
) {
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(
		() =>
			(space
				? formSpaceTitle(
						space.name,
						space.altName,
						spaceTypes.byId[space.type],
						altNamesVisible
				  )
				: ''),
		[space, spaceTypes]
	);
}
