import React from 'react';
import './FixDateDisplayValue.less';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import FixDateIcon from './components/FixDateIcon';
import classNames from 'classnames';
import {CriticalMarker} from '@src/components/CriticalMarker';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';

interface IFixDateDisplayValueProps {
	className?: string;
	style?: React.CSSProperties;
	plannedFixDate: number;
	actualFixDate?: number;
	status: ProblemStatusId;
	critical?: boolean;
}

export const FixDateDisplayValue = ({
	className,
	style,
	plannedFixDate,
	status,
	critical,
	actualFixDate
}: IFixDateDisplayValueProps) => {
	const formatted = format(plannedFixDate, longDateTimeFormat, {locale: ru});

	return (
		<span
			className={classNames('fix-date-display-value', className)}
			style={style}
		>
			<span className="fix-date-display-value__text">{formatted}</span>

			{critical && <CriticalMarker/>}

			{(status !== ProblemStatusId.FIXED
				|| (actualFixDate && actualFixDate > plannedFixDate)) && (
					<>
						{'  '}
						<FixDateIcon
							plannedFixDate={plannedFixDate}
							actualFixDate={actualFixDate}
						/>
					</>
			)}
		</span>
	);
};
