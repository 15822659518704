import React from 'react';
import {Plate} from '@tehzor/ui-components';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {Common} from './Common';

interface IDesktopInfoProps {
	structure: IStructure;
}

export const DesktopInfo = ({structure}: IDesktopInfoProps) => (
	<Plate className={{content: 'structure-page__info-plate'}}>
		<div className="structure-page__info-title">Основное</div>
		<Common
			structure={structure}
		/>
	</Plate>
);
