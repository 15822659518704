import {requestOwnerAcceptanceStatuses} from '@src/api/backend/ownerAcceptanceStatuses';
import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';

export const useOwnerAcceptanceStatusesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.ownerAcceptanceStatuses(), {
		queryFn: requestOwnerAcceptanceStatuses,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		cacheTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
