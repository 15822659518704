import React from 'react';
import {createPortal} from 'react-dom';

interface IPortalProps {
	className: string;
	children?: React.ReactNode;
	root: HTMLElement;
}

class Portal extends React.Component<IPortalProps> {
	static defaultProps = {
		className: 'Portal',
		root: document.body
	};

	private portal?: HTMLElement;

	componentWillUnmount() {
		if (this.portal) {
			this.props.root.removeChild(this.portal);
		}
		this.portal = undefined;
	}

	render() {
		const {children} = this.props;

		if (!children) {
			return null;
		}

		const portal = this.createDomElement();

		return createPortal(children, portal);
	}

	private createDomElement() {
		if (!this.portal) {
			this.portal = document.createElement('div');
			this.portal.classList.add(this.props.className);
			this.props.root.appendChild(this.portal);
		}
		return this.portal;
	}
}

export default Portal;
