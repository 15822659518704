import React from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useDeleteLegal} from '@src/core/hooks/mutations/legals/useDeleteLegal';

export function useLegalDelete(legalId?: string): [React.ReactNode, () => void] {
	const {goBack} = useChangePath();
	const deleteLegal = useDeleteLegal();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить юрлицо',
		'Вы действительно хотите удалить юрлицо?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if ((await getDeleteConfirmation()) && legalId) {
			await deleteLegal(legalId);
			goBack();
		}
	}, [legalId]);

	return [deleteDialog, handleDelete];
}
