import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IStructureType} from '@tehzor/tools/interfaces/structures/IStructureType';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IStructureTypesState = ILoadableEntitiesState<IStructureType>;

export const structureTypes = createReducer<IStructureTypesState>(
	getLoadableEntitiesInitialState<IStructureType>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IStructureType>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IStructureType>()
	}
);
