import React, {useMemo} from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {useIsFetching, useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {getMaxDate} from '../utils/getMaxDate';
import {someHasError} from '../utils/someHasError';
import {someQueryInProgress} from '../utils/someQueryInProgress';

export const RestDictionariesCacheStatus = () => {
	const queryClient = useQueryClient();
	const isFetching = useIsFetching(restDictionariesQueryKeys.all());

	const restDictionriesCacheStates = useMemo(
		() =>
			Object.values(restDictionariesQueryKeys)
				.slice(1)
				.map(key => queryClient.getQueryState(key())),
		[isFetching]
	);
	const [withError, dataUpdatedAt, inProgress] = useMemo(
		() => [
			someHasError(restDictionriesCacheStates),
			getMaxDate(restDictionriesCacheStates),
			someQueryInProgress(restDictionriesCacheStates)
		],
		[restDictionriesCacheStates]
	);
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Справочники"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={withError ? 'Ошибка' : undefined}
			inProgress={inProgress}
		/>
	);
};
