import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useInspectionExport} from '@src/core/hooks/export/useInspectionExport';

interface IInspectionExportProps {
	objectId: string;
	inspectionId: string;
}

const MobileInspectionExport = ({objectId, inspectionId}: IInspectionExportProps) => {
	const [exportDialog, openExportDialog] = useInspectionExport(objectId, inspectionId);

	const handleExport = useCallback(() => {
		openExportDialog(objectId, inspectionId);
	}, [objectId, inspectionId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleExport}>
				<i className="tz-export-24"/>
			</IconButton>
			{exportDialog}
		</>
	);
};

export default MobileInspectionExport;
