import React, {memo} from 'react';
import './CheckListPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from '@src/pages/CheckListPage/components/Desktop';
import {Mobile} from '@src/pages/CheckListPage/components/Mobile';
import {useAsync, useUnmount} from 'react-use';
import {RESET} from '@src/store/modules/pages/checkLists/constants/page';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import {getCheckRecords, getProblems, getTasks} from '@src/store/modules/pages/checkLists/actions';
import {getCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {onlineManager} from '@tanstack/react-query';

interface CheckListPageProps {
	loading?: boolean;
}

export const CheckListPage = memo(({loading: externalLoading}: CheckListPageProps) => {
	const {objectId, spaceId, stage} = useStrictParams<{
		objectId: string;
		spaceId?: string;
		stage: ObjectStageIds | 'all';
	}>();

	const isDesktop = useIsDesktop();
	const dispatch = useAppDispatch();
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);

	const {loading: checkListsLoading} = useAsync(async () => {
		if (onlineManager.isOnline() || isOfflineModeAvailable) {
			await dispatch(getCheckLists());
			await dispatch(getCheckItems());
		}
	}, [isOfflineModeAvailable]);

	const checkLists = useAvailableCheckLists(objectId, stage);
	const {loading: problemsLoading} = useAsync(async () => {
		// Получение нарушений 	и записей только если загружены и есть чек-листы
		const ids = checkLists?.map(l => l.id) || [];
		if (checkLists.length && onlineManager.isOnline()) {
			await dispatch(getProblems(ids, spaceId));
			await dispatch(getTasks(ids, spaceId));
		}
		if (checkLists.length && (onlineManager.isOnline() || isOfflineModeAvailable)) {
			await dispatch(getCheckRecords(ids, [objectId], spaceId));
		}
	}, [objectId, spaceId, checkLists, isOfflineModeAvailable]);

	useUnmount(() => {
		dispatch({type: RESET});
	});

	const loading = externalLoading || checkListsLoading || problemsLoading;

	if (isDesktop) {
		return <Desktop loading={loading} />;
	}
	return <Mobile loading={loading} />;
});
