import ILocation from '@tehzor/tools/interfaces/ILocation';
import {TaskPriorityId} from '@tehzor/tools/interfaces/tasks/ITaskPriority';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import React, {useCallback, useState} from 'react';
import {AddingTaskDialog} from '../AddingTaskDialog';
import {PreventRenderWrapper} from '@src/components/PreventRenderWrapper/PreventRenderWrapper';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

interface AddingTaskDialogProps {
	objectId?: string;
	floor?: string;
	planId?: string;
	location?: ILocation;
	status?: TaskStatusId;
	taskType?: TaskTypeId;
	taskPriority?: TaskPriorityId;
	startTime?: string;
	endTime?: string;
	activeGroup?: string;
	respUsers?: IBriefUser[];
	links?: {
		spaceId?: string;
		checkListId?: string;
		checkItemId?: string;
	};
	onSuccess?: () => void|Promise<void>;
}

export const useAddingTaskDialog = (
	props: AddingTaskDialogProps
): [React.ReactNode, (
) => void] => {
	const {
		objectId,
		floor,
		planId,
		location,
		status,
		taskType,
		taskPriority,
		activeGroup,
		respUsers,
		links,
		onSuccess,
		startTime,
		endTime
	} = props;
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<PreventRenderWrapper render={isOpen}>
			<AddingTaskDialog
				isOpen={isOpen}
				close={close}
				objectId={objectId}
				floor={floor}
				planId={planId}
				location={location}
				status={status}
				taskType={taskType}
				taskPriority={taskPriority}
				startTime={startTime}
				endTime={endTime}
				onSuccess={onSuccess}
				links={links}
				activeGroup={activeGroup}
				respUsers={respUsers}
			/>
		</PreventRenderWrapper>
	);

	return [dialog, open];
};
