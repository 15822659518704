import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	ILoadableEntitiesState,
	handleLoadableEntitiesGetting,
	getLoadableEntitiesInitialState
} from '@tehzor/tools/core/storeHelpers/reducers/loadable';

import {IInspectionStory} from '@tehzor/tools/interfaces/inspectionStories/IInspectionStory';
import {IGetInspectionStoriesPayload} from './actions';

export interface IInspectionState {
	stories: ILoadableEntitiesState<IInspectionStory>;
}
const getInitialState = () => ({
	stories: getLoadableEntitiesInitialState<IInspectionStory>()
});

export const inspection = createReducer<IInspectionState>(getInitialState(), {
	[types.GET_STORIES_REQUEST]: state => {
		state.stories = getLoadableEntitiesInitialState<IInspectionStory>();
	},
	[types.GET_STORIES_SUCCESS]: (state, action: {payload: IGetInspectionStoriesPayload}) => {
		state.stories = handleLoadableEntitiesGetting<IInspectionStory>()(state.stories, action);
	},
	[CLEAR_STORE]: getInitialState
});
