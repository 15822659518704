import {exportInternalAcceptances} from './export';
import {sendExportedInternalAcceptances} from './send';
import {ApiAction} from '@src/store/middlewares/api';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {ISendResponse} from '@src/interfaces/ISendResponse';

/**
 * Скачивает файл или отправляет на email список проверок
 *
 * @param email email
 * @param templateId id шаблона экспорта
 * @param filters фильтры
 * @param sort сортировка
 * @param selected выбранные элементы
 * @param createDocument флаг создания документа
 */
export const exportInternalAcceptancesUnified = (
	email: string | undefined,
	templateId: string,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[],
	createDocument?: boolean
): ApiAction<IExportResponse | ISendResponse> =>
	(email !== undefined
		? sendExportedInternalAcceptances(email, templateId, objectId, filters, sort, selected, createDocument)
		: exportInternalAcceptances(templateId, objectId, filters, sort, selected, createDocument));
