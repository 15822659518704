import React, {useCallback, useEffect, useState} from 'react';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {
	makeDefaultData,
	useEditableAuthorizedPersonState,
	isEdited
} from '@src/core/hooks/states/useEditableAuthorizedPersonState';
import {useUpdateContent} from './useUpdateContent';
import {reset} from '../utils/reset';
import {EditableAuthorizedPerson} from '../EditableAuthorizedPerson';

interface IUseEditableAuthorizedPersonProps {
	authorizedPerson?: IAuthorizedPerson;
	legalEntityId?: string;
	companyId?: string;
}

export const useEditableAuthorizedPerson = (props: IUseEditableAuthorizedPersonProps) => {
	const {authorizedPerson, legalEntityId, companyId} = props;
	const defaultData = makeDefaultData(authorizedPerson);
	const [editingState, editingDispatch] = useEditableAuthorizedPersonState(defaultData);

	const {updateAuthorizedPerson} = useUpdateContent({
		editingState,
		editingDispatch,
		authorizedPerson,
		legalEntityId,
		companyId
	});

	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, authorizedPerson));
	}, [editingState, authorizedPerson]);

	const fields = (
		<EditableAuthorizedPerson editingState={editingState} editingDispatch={editingDispatch} />
	);

	const handleReset = useCallback(
		() => reset(editingDispatch, defaultData),
		[editingDispatch, defaultData]
	);

	const getSavingData = async () => updateAuthorizedPerson();

	return {fields, reset: handleReset, getSavingData, isBlocking};
};
