import React, {forwardRef, Ref, useCallback} from 'react';
import ButtonBase from '../ButtonBase';
import './TabButton.less';
import classNames from 'classnames';

export interface ITabButtonProps {
	label: React.ReactNode;
	value: string;
	className?: string;
	style?: React.CSSProperties;
	count?: number;
	disabled?: boolean;
	active?: boolean;
	onClick?: (type: string) => void;
}

interface ITabButtonLabelProps {
	label: React.ReactNode;
	count?: number;
}

const TabButtonLabel = ({label, count}: ITabButtonLabelProps) => {
	if (count === undefined) {
		return <>{label}</>;
	}

	return (
		<>
			<span className="tab-button__label-title">{label}</span>
			<span className="tab-button__label-count">{count}</span>
		</>
	);
};

const TabButton = (props: ITabButtonProps, ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>) => {
	const {className, style, label, count, disabled, onClick, active, value} = props;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(value);
		}
	}, [value]);

	return (
		<ButtonBase
			className={classNames('tab-button', {'tab-button_active': active}, className)}
			style={style}
			label={(
				<TabButtonLabel
					label={label}
					count={count}
				/>
			)}
			disabled={disabled}
			classNamePrefix="tab-button"
			outerTagType="button"
			onClick={active ? undefined : handleClick}
			ref={ref}
		/>
	);
};

TabButton.displayName = 'TabButton';

export default forwardRef(TabButton);
