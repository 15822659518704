const domain = 'structures';

export const GET_SCHEMA_REQUEST = `${domain}/schema/get/request`;
export const GET_SCHEMA_SUCCESS = `${domain}/schema/get/success`;
export const GET_SCHEMA_FAILURE = `${domain}/schema/get/failure`;

export const GET_LIST_REQUEST = `${domain}/list/get/request`;
export const GET_LIST_SUCCESS = `${domain}/list/get/success`;
export const GET_LIST_FAILURE = `${domain}/list/get/failure`;

export const CHANGE_OFFSET = `${domain}/offset/change`;
export const CHANGE_SELECTED = `${domain}/selected/change`;
export const CHANGE_CURRENT_OBJECT = `${domain}/current-object/change`;