import React, {Dispatch} from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import {
	IEditableLegalAction,
	IEditableLegalState
} from '@src/core/hooks/states/useEditableLegalState';
import {EditableDate} from '../editableFields/EditableDate';
import CompanySelect from '../editableFields/CompanySelect';
import './EditableLegal.less';

interface IEditableLegalProps {
	editingState: IEditableLegalState;
	editingDispatch: Dispatch<IEditableLegalAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
}

export const EditableLegal = (props: IEditableLegalProps) => {
	const {editingState, editingDispatch, fieldsSettings} = props;

	return (
		<div className="editable-legal">
			<div className="editable-legal__wrap">
				<div className="editable-legal__wrap-names">
					<Text
						field="name"
						label="Полное название юрлица*"
						value={editingState.name}
						editingDispatch={editingDispatch}
						required
						hasError={editingState.errors.name}
					/>

					<Text
						field="shortName"
						label="Краткое название юрлица*"
						value={editingState.shortName}
						editingDispatch={editingDispatch}
						required
						hasError={editingState.errors.shortName}
					/>

					<CompanySelect
						field="companyId"
						label="Компания*"
						value={editingState.companyId}
						editingDispatch={editingDispatch}
						required
						isUUID
						hasError={editingState.errors.companyId}
					/>
				</div>

				<span className="editable-legal__wrap-main-title">Основное</span>
				<div className="editable-legal__wrap-main">
					<Text
						field="legalAddress"
						label="Юридический адрес*"
						value={editingState.legalAddress}
						editingDispatch={editingDispatch}
						required
						hasError={editingState.errors.legalAddress}
					/>

					{fieldsSettings.mailingAddress !== undefined && (
						<Text
							field="mailingAddress"
							label="Почтовый адрес"
							value={editingState.mailingAddress}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.mailingAddress}
						/>
					)}

					<Text
						field="inn"
						label="ИНН*"
						value={editingState.inn}
						editingDispatch={editingDispatch}
						required
						hasError={editingState.errors.inn}
					/>

					{fieldsSettings.kpp !== undefined && (
						<Text
							field="kpp"
							label="КПП"
							value={editingState.kpp}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.kpp}
						/>
					)}

					<Text
						field="ogrn"
						label="ОГРН*"
						value={editingState.ogrn}
						editingDispatch={editingDispatch}
						required
						hasError={editingState.errors.ogrn}
					/>

					{fieldsSettings.okpo !== undefined && (
						<Text
							field="okpo"
							label="ОКПО"
							value={editingState.okpo}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.okpo}
						/>
					)}

					{fieldsSettings.okved !== undefined && (
						<Text
							field="okved"
							label="ОКВЭД*"
							value={editingState?.okved}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.okved}
							required
						/>
					)}

					{fieldsSettings.comment !== undefined && (
						<Text
							field="comment"
							label="Комментарий"
							value={editingState.comment}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.comment}
						/>
					)}
				</div>

				<span className="editable-legal__wrap-main-title">Контакты</span>
				<div className="editable-legal__wrap-main">
					{fieldsSettings.phone !== undefined && (
						<Text
							field="phone"
							label="Телефон"
							value={editingState.phone}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.phone}
						/>
					)}

					{fieldsSettings.email !== undefined && (
						<Text
							field="email"
							label="Е-mail"
							value={editingState.email}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.email}
						/>
					)}
				</div>

				<span className="editable-legal__wrap-main-title">
					Свидетельство о государственной регистрации
				</span>
				<div className="editable-legal__wrap-main">
					<Text
						field="registrationCertificateNumber"
						label="Номер свидетельства о государственной регистрации*"
						value={editingState.registrationCertificateNumber}
						editingDispatch={editingDispatch}
						required
						hasError={editingState.errors.registrationCertificateNumber}
					/>

					<EditableDate
						field="registrationCertificateDate"
						label="Дата свидетельства о государственной регистрации*"
						value={editingState.registrationCertificateDate}
						editingDispatch={editingDispatch}
						hasError={editingState.errors.registrationCertificateDate}
						required
						errorMessage="Укажите дату свидетельства"
						createdAt={0}
					/>
				</div>

				<span className="editable-legal__wrap-main-title">СРО строительства</span>
				<div className="editable-legal__wrap-main">
					{fieldsSettings.designSroNumber !== undefined && (
						<Text
							field="buildingSroNumber"
							label="Номер СРО строительства"
							value={editingState.buildingSroNumber}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.buildingSroNumber}
						/>
					)}

					{fieldsSettings.buildingSroDate !== undefined && (
						<EditableDate
							field="buildingSroDate"
							label="Дата СРО строительства"
							value={editingState.buildingSroDate}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.buildingSroDate}
							createdAt={0}
						/>
					)}
				</div>

				<span className="editable-legal__wrap-main-title">СРО проектирования</span>
				<div className="editable-legal__wrap-main">
					{fieldsSettings.designSroNumber !== undefined && (
						<Text
							field="designSroNumber"
							label="Номер СРО проектирования"
							value={editingState.designSroNumber}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.designSroNumber}
						/>
					)}

					{fieldsSettings.designSroDate !== undefined && (
						<EditableDate
							field="designSroDate"
							label="Дата СРО проектирования"
							value={editingState.designSroDate}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.designSroDate}
							createdAt={0}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
