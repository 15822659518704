import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractTasksAndSubTasksByItemIdAsArray,
	extractTasksByCheckListIdAsArray
} from '@src/store/modules/pages/checkLists/selectors/tasks';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';

/**
 * Вычисляет количество задач по каждому статусу для чек-листа или категории
 *
 * @param listId id чек-листа
 * @param itemId id категории
 */
export function useTasksCountByStatus(
	listId: string,
	itemId?: string
): Array<{status: TaskStatusId, count: number}> {
	const tasks = useAppSelector(state =>
		(itemId
			? extractTasksAndSubTasksByItemIdAsArray(state, listId, itemId)
			: extractTasksByCheckListIdAsArray(state, listId)));

	return useMemo(() => {
		const countByStatus = tasks.reduce<Record<string, number>>((prev, task) => {
			if (!prev[task.status]) {
				prev[task.status] = 0;
			}
			prev[task.status] += 1;
			return prev;
		}, {});

		return Object.values(TaskStatusId)
			.map(status => ({
				status,
				count: countByStatus[status] ?? 0
			}));
	}, [listId, itemId, tasks]);
}
