import React, {createContext, useCallback, useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractWarrantyClaimsAsArray,
	extractWarrantyClaimsData
} from '@src/store/modules/entities/warrantyClaims/selectors';
import {
	EntitiesTable,
	PaginationPageSize,
	Pagination,
	Plate,
	PaginationAndSize
} from '@tehzor/ui-components';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changePageSize, changeSort} from '@src/store/modules/settings/pages/warrantyClaims/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {
	changeOffset,
	getWarrantyClaims,
	changeSelectedRows
} from '@src/store/modules/entities/warrantyClaims/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {mobileColumns, mobileColumnsWithoutObject} from './mobileColumns';
import {desktopColumns, desktopColumnsWithoutObject} from './desktopColumns';
import {convertClaims} from '../../utils/convertClaims';
import {IPreparedClaim} from '../../interfaces/IPreparedClaim';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {deleteWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions/delete';
import {useLocalWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/useGetWarrantyClaims';

const pageSizes = [10, 20, 50, 100];

interface IClaimsTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedClaim>) => void
>(() => ({}));

const ClaimsTable = ({objectId = 'all'}: IClaimsTableProps) => {
	const {pushPath} = useChangePath();
	const claims = useAppSelector(s => extractWarrantyClaimsAsArray(s, objectId));
	const localClaims = useLocalWarrantyClaims(objectId);
	const claimsData = useAppSelector(s => extractWarrantyClaimsData(s, objectId));
	const pageSettings = useAppSelector(s => extractWarrantyClaimsPageSettings(s, objectId));
	const statusesMap = useAppSelector(s => s.dictionaries.warrantyClaimStatuses.byId);
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	const pagesCount = Math.ceil(claimsData.total / pageSettings.pageSize);
	const currentPage = Math.floor(claimsData.offset / pageSettings.pageSize);

	const preparedClaims = useMemo(
		() => convertClaims([...localClaims, ...claims], statusesMap, networkStatus, user),
		[claims, localClaims, networkStatus, user]
	);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить обращение?'
	);

	const [, loadClaims] = useAsyncFn(() => dispatch(getWarrantyClaims(objectId)), [objectId]);

	const handleRowClick = useCallback((claim: IPreparedClaim) => {
		if (claim.object?.id && claim.links?.spaceId) {
			pushPath(`/objects/${claim.object.id}/warranty-claims/${claim.id}`);
		}
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => {
			dispatch(changeSelectedRows(objectId, value));
		},
		[objectId]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort(objectId, value));
			void loadClaims();
		},
		[objectId, loadClaims]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(objectId, value));
			dispatch(changeOffset(objectId, Math.floor(claimsData.offset / value)));
			void loadClaims();
		},
		[objectId, claimsData.offset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (claimsData.offset !== offset) {
				dispatch(changeOffset(objectId, offset));
				void loadClaims();
			}
		},
		[objectId, claimsData.offset, pageSettings.pageSize]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedClaim>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await dispatch(
						deleteWarrantyClaim(action.payload.object?.id, action.payload.id)
					);
					await loadClaims();
				}
			}
		},
		[objectId]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		desktopColumns,
		desktopColumnsWithoutObject
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={preparedClaims}
						selectedRows={claimsData.selectedRows}
						sort={pageSettings.sort}
						selectable
						responsive={!isLargeTablet}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>

			{deleteDialog}
		</>
	);
};

export default ClaimsTable;
