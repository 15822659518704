import {IDeleteCategoryResponse, requestDeleteCategory} from '@src/api/backend/categories';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {queryClient} from '@src/api/QueryClient';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';

export interface IDeleteCategoryPayload {
	id: string;
	categoriesSetId: string;
}

const request = () => ({
	type: types.DELETE_REQUEST
});

const success = (id: string, categoriesSetId: string) => ({
	type: types.DELETE_SUCCESS,
	payload: {id, categoriesSetId} as IDeleteCategoryPayload
});

const failure = (error: IError) => ({
	type: types.DELETE_FAILURE,
	payload: error
});

export const deleteCategory = (categoryId: string, categoriesSetId: string) =>
	createApiAction<IDeleteCategoryResponse>(
		request,
		() => success(categoryId, categoriesSetId),
		failure,
		async () => {
			const data = await requestDeleteCategory(categoryId);
			await queryClient.invalidateQueries(categoriesQueryKeys.list());
			return data;
		}
	);
