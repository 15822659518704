import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {extractCheckRecordStatusesByEntityTypeAsArray} from '@src/store/modules/dictionaries/checkRecordStatuses/selectors';

interface ICheckListsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const CheckListCategoryStatusesFilterMobile = memo((props: ICheckListsFilterMobileProps) => {
	const CheckItemsRecordStatuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityTypeAsArray(s, 'check-item'));

	return (
		<BaseListFilterMobile
			{...props}
			options={CheckItemsRecordStatuses}
			label="Статусы категорий чек-листов"
			filterName="checkListCategoryStatuses"
		/>
	);
});
