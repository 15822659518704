import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractSpaceTypesAsArray} from '@src/store/modules/dictionaries/spaceTypes/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const SpaceTypesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const types = useAppSelector(extractSpaceTypesAsArray);
	return (
		<div>
			<StatusesFilterPage
				objectId={objectId}
				entity={entity}
				statuses={types}
				label="Тип помещений"
				filterName="types"
			/>
		</div>
	);
};

export default SpaceTypesFilterPage;
