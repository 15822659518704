import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type IProblemTemplatesSetsState = ILoadableEntitiesState<IProblemTemplatesSet>;

export const problemTemplatesSets = createReducer<IProblemTemplatesSetsState>(
	getLoadableEntitiesInitialState<IProblemTemplatesSet>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IProblemTemplatesSet>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IProblemTemplatesSet>(),
		[CLEAR_BEFORE_UPDATE]: () => getLoadableEntitiesInitialState<IProblemTemplatesSet>()
	}
);
