import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	ObjectsFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAppLocation} from '@src/core/hooks/useAppLocation';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {SigningDateFilterMobile} from '@src/pages/ContractsPage/components/filters/SigningDateFilterMobile';
import {
	extractCompaniesAsArray,
	extractContractorsAsArray
} from '@src/store/modules/dictionaries/companies/selectors';
import {IContractsFiltersState} from '@src/store/modules/settings/pages/contracts/reducers';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useParams} from 'react-router-dom';

export const ContractsFiltersPage = () => {
	const {entity = 'contracts'} = useParams<{entity?: string}>();
	const location = useAppLocation();

	const {goBack} = useChangePath();

	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IContractsFiltersState>();

	const handleApply = useCallback(() => {
		if (location.state.canGoBack) {
			apply();
			goBack();
		}
	}, [location, apply]);

	useAppHeader(
		{
			title: 'Фильтры',
			showBackBtn: true,
			mobileRightButtons: isCleanable<IContractsFiltersState>(state) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[state]
	);

	useRouting(!location.state.canGoBack, 'all', entity);

	const companies = useAppSelector(extractCompaniesAsArray);
	const contractors = useAppSelector(extractContractorsAsArray);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<SigningDateFilterMobile
				from={state.signingDateFrom}
				to={state.signingDateTo}
			/>

			{companies.length > 1 && (
				<BaseListFilterMobile
					entity={entity}
					filterName="companyIds"
					label="Заказчики"
					onChange={change}
					options={companies}
					value={state.companyIds}
				/>
			)}

			{contractors.length > 1 && (
				<BaseListFilterMobile
					entity={entity}
					filterName="contractorIds"
					label="Подрядчики"
					onChange={change}
					options={contractors}
					value={state.contractorIds}
				/>
			)}

			<ObjectsFilterMobile
				entity={entity}
				filterName="objectIds"
				value={state.objectIds}
			/>

			<CreatedByFilterMobile
				entity={entity}
				onChange={change}
				value={state.createdBy}
			/>

			<CreationDateFilterMobile
				entity={entity}
				from={state.createdAtFrom}
				to={state.createdAtTo}
			/>
		</FilterPage>
	);
};
