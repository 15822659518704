import React, {useEffect} from 'react';
import './SpaceTypeDecorationEditPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {LoadingPanel} from '@tehzor/ui-components';
import * as typeDecTypes from '@src/store/modules/entities/spaceTypeDecorationSets/constants';
import {EditPageBreadcrumbs} from '../SpaceTypeDecorationPage/components/EditPageBreadcrumbs';
import {EditingSpaceTypeDecoration} from '@src/components/EditingSpaceTypedecorationSet/EditingSpaceTypeDecoration';
import {getSpaceTypeDecorationSetById} from '@src/store/modules/entities/spaceTypeDecorationSet/actions';
import {getSpaceTypeDecorationsById} from '@src/store/modules/entities/spaceTypeDecorations/actions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

export const SpaceTypeDecorationEditPage = () => {
	useAppHeader({
		title: 'Редактирование типов отделки помещений',
		mobileRightButtons: <AppUpdateMenu/>,
		showBackBtn: true
	});

	const dispatch = useAppDispatch();
	const {typeDecorationSetId} = useStrictParams<{typeDecorationSetId: string}>();

	const loadingState = useAsync(async () => {
		if (!typeDecorationSetId) return;
		await Promise.all([
			dispatch(getSpaceTypeDecorationSetById(typeDecorationSetId)),
			dispatch(getSpaceTypeDecorationsById(typeDecorationSetId))
		]);
	}, [typeDecorationSetId]);

	useEffect(
		() => () => {
			// сбрасываем state
			dispatch({type: typeDecTypes.RESET});
		},
		[]
	);

	return (
		<div className="page-cont manage-space-type-decoration-edit-page">
			<EditPageBreadcrumbs/>
			<LoadingPanel active={loadingState.loading}>
				<>
					{!loadingState.loading && typeDecorationSetId && (
						<EditingSpaceTypeDecoration typeDecorationSetId={typeDecorationSetId}/>
					)}
				</>
			</LoadingPanel>
		</div>
	);
};
