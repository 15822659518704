import React, {memo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectPopup, FilterButton, SelectOption, Select2} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';

interface IBooleanFilterProps {
	label: string;
	filterName: string;
	options: Array<{id: string, name: string}>;

	value?: boolean;
}

export const BooleanFilter = memo(
	({
		label,
		value,
		filterName,
		options
	}: IBooleanFilterProps) => {
		const valueAsArray = value === undefined ? undefined : value ? ['true'] : ['false'];

		const [selectedOptions, setSelectedOptions] = useState(valueAsArray);

		const {dispatch} = useEntitiesFiltersCtx();

		const handleApply = () => {
			const newValue = selectedOptions
				? selectedOptions.length === 2
					? undefined
					: selectedOptions[0] === 'true'
				: undefined;
			dispatch({[filterName]: newValue});
		};

		const handleChange = (v?: string[]) => {
			if (v && v.length === 0) {
				setSelectedOptions(undefined);
			} else {
				setSelectedOptions(v);
			}
		};

		const handleClear = () => {
			setSelectedOptions(undefined);
		};

		const handleFullClear = () => {
			setSelectedOptions(undefined);
			dispatch({[filterName]: undefined});
		};

		const handleCancel = () => {
			setSelectedOptions(valueAsArray);
		};

		useUpdateEffect(() => {
			setSelectedOptions(valueAsArray);
		}, []);

		return (
			<SelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedOptions?.length}
				noHeader
				footer
				count={selectedOptions?.length}
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel(label, valueAsArray, options)}
						active={!!valueAsArray?.length}
						onClear={handleFullClear}
					/>
				)}
			>
				<Select2
					multiple
					value={selectedOptions}
					onChange={handleChange}
				>
					{options.map(item => (
						<SelectOption
							key={item.id.toString()}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</SelectPopup>
		);
	}
);
