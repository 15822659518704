import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';

export type IGetSpaceStatusesSetsResponse = INormalizedData<ISpaceStatusesSet>;

/**
 * Возвращает наборы статусов помещений
 */
export const requestGetSpaceStatusesSets = () =>
	wsConnector.sendAuthorizedRequest<IGetSpaceStatusesSetsResponse>('getSpaceStatusesSets');
