import {wsConnector} from '../wsConnector';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';

export type IEditCheckItemsResponse = ICheckItem[];

/**
 * Массово обноялвет категории
 * В метод должны передаваться все поля, связанные с чек-листом после редактирования.
 * Все не переданные поля считаются удаленными.
 *
 * @param checkListId id чек-листа
 * @param items данные для добавления
 */
export const makeCheckItemsEditRequest = (checkListId: string, items: ISavingCheckItem[]) =>
	wsConnector.sendAuthorizedRequest<IEditCheckItemsResponse>('batchEditCheckItem', {
		checkListId,
		items
	});
