import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {useSpaceStage} from '@src/core/hooks/spaces/useSpaceStage';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import GlobalEntityAdding from './GlobalEntityAdding';

interface IEntityAddingProps {
	objectId: string;
	spaceId: string;
}

const EntityAdding = ({objectId, spaceId}: IEntityAddingProps) => {
	const space = useAppSelector(extractSpace);
	const stage = useSpaceStage(objectId, space);

	const defaultData = useSpaceLocationForEntities(objectId, spaceId);

	return (
		<GlobalEntityAdding
			objectId={objectId}
			spaceId={spaceId}
			stage={stage}
			defaultData={defaultData}
			space={space}
		/>
	);
};

export default EntityAdding;
