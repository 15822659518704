import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractPlansByObjectIds,
	extractPlansByObjectIdsAsArray
} from '@src/store/modules/dictionaries/plans/selectors';
import {EntityGridItem, InlineButton, LocationSelect} from '@tehzor/ui-components';
import classNames from 'classnames';
import React, {memo, useState} from 'react';

interface IPlansCellProps {
	planIds?: string[];
	objectIds?: string[];
}

const NUMBER_OF_SHOWN = 4;

const locationIcon = <i className="tz-location-20" />;

export const PlansCell = memo(({planIds, objectIds}: IPlansCellProps) => {
	const [isShown, setShown] = useState(false);
	const handleClick = () => setShown(s => !s);

	const {byId: plansMap} = useAppSelector(s => extractPlansByObjectIds(s, objectIds));
	const plans = useAppSelector(s => extractPlansByObjectIdsAsArray(s, objectIds));

	if (!planIds?.length || !plans?.length || !objectIds?.length) {
		return null;
	}

	const shownPlans = planIds.filter((planId, i) => (!isShown ? i + 1 <= NUMBER_OF_SHOWN : true));

	const numberOfRemainingPlans = planIds.length > NUMBER_OF_SHOWN
		? planIds.length - NUMBER_OF_SHOWN
		: 0;

	const renderLocation = (id: string, open: () => void) => (
		<InlineButton
			label={plansMap[id].name}
			type="accent"
			onClick={open}
		/>
	);

	return (
		<EntityGridItem
			className={{
				root: 'contract__cells-item',
				header: 'contract__cells-item-header',
				content: classNames('contract__cells-item-content', 'contract__cells-plans')
			}}
			label="Планы"
			icon={locationIcon}
			inline
		>
			{shownPlans.map(id => (
				<LocationSelect
					key={id}
					mode="view"
					planId={id}
					plans={plans}
					className="contract__cells-plans-item"
				>
					{(_, open) => renderLocation(id, open)}
				</LocationSelect>
			))}
			{!!numberOfRemainingPlans && (
				<InlineButton
					className="contract__cells-plans-btn"
					label={!isShown ? `Показать все (${numberOfRemainingPlans})` : 'Скрыть'}
					type="accent"
					onClick={handleClick}
				/>
			)}
		</EntityGridItem>
	);
});
