import React from 'react';
import './CommentInput.less';
import classNames from 'classnames';
import {Scrollbar} from '../../containers';
import {getTextSelection} from '@tehzor/tools/utils/textSelection';
import Textarea from 'react-textarea-autosize';
import {setTextSelection} from '../../../utils/textAreaHelpers';
import {convertClassNames} from '../../../utils/convertClassNames';

interface ICommentInputProps {
	className?: string | {
		root?: string;
		textarea?: string;
		placeholder?: string;
	};
	style?: React.CSSProperties;
	value?: string;
	placeholder?: string;
	disabled?: boolean;

	onChange?: (value: string) => void;
	onSave?: () => void;
}

interface ICommentInputState {
	focused: boolean;
}

class CommentInput extends React.PureComponent<ICommentInputProps, ICommentInputState> {
	private textarea = React.createRef<HTMLTextAreaElement>();

	/*
	 Позиция курсора до вставки переноса
	 */
	private cursor?: number;

	constructor(props: ICommentInputProps) {
		super(props);

		this.state = {focused: false};
	}

	/**
	 * Переводит фокус на элемент
	 */
	focus = () => {
		if (this.textarea.current) {
			this.textarea.current.focus();
		}
	};

	/**
	 * Снимает фокус с элемента
	 */
	blur = () => {
		if (this.textarea.current) {
			this.textarea.current.blur();
		}
	};

	componentDidUpdate(prevProps: ICommentInputProps) {
		if (this.cursor !== undefined && this.props.value !== prevProps.value && this.textarea.current) {
			// Устанавливаем курсор на новую строку после вставки переноса
			setTextSelection(this.textarea.current, this.cursor + 1, this.cursor + 1);
			this.cursor = undefined;
		}
	}

	render() {
		const {className, style, value, placeholder, disabled} = this.props;
		const {focused} = this.state;

		const classes = convertClassNames(className);

		return (
			<div
				className={classNames(
					'comment-input',
					{'comment-input_focused': focused},
					{'comment-input_disabled': disabled},
					classes.root
				)}
				style={style}
			>
				<Scrollbar
					className="comment-input__scrollbar"
					simpleBarProps={{autoHide: false}}
				>
					<Textarea
						className={classNames('comment-input__textarea', classes.textarea)}
						value={value}
						disabled={disabled}
						onChange={this.handleChange}
						onKeyDown={this.handleKeyDown}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
						ref={this.textarea}
					/>

					{placeholder !== undefined && !value?.length && (
						<div className={classNames('comment-input__placeholder', classes.placeholder)}>
							{placeholder}
						</div>
					)}
				</Scrollbar>
			</div>
		);
	}

	/**
	 * Обрабатывает событие change
	 *
	 * @param event событие
	 */
	private handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		}
	};

	/**
	 * Обрабатывает событие keyDown
	 *
	 * @param event событие
	 */
	private handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): boolean | void => {
		if (event.code === 'Enter') {
			// Перенос строки при ctrl + enter
			const {value = '', onSave, onChange} = this.props;
			if (event.ctrlKey) {
				// Получаем текущее выделение
				const {start, end} = getTextSelection(event.currentTarget);
				// Вставляем перенос вместо выделенного текста
				if (onChange) {
					onChange(`${value.slice(0, start)}\n${value.slice(end)}`);
				}
				// Запоминаем позицию курсору для установки после рендеринга
				this.cursor = start;
			} else if (onSave) {
				// Сохранение по enter
				onSave();
			}
			event.preventDefault();
			return false;
		}
	};

	private handleFocus = () => {
		this.setState({focused: true});
	};

	private handleBlur = () => {
		this.setState({focused: false});
	};
}

export default CommentInput;