import React, {useCallback} from 'react';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {Block} from './Block';

interface ISpaceBlockProps {
	space: IEnrichedSpace;
	width: number;
	height: number;

	onClick?: (space: IEnrichedSpace) => void;
}

export const SpaceBlock = ({space, onClick, width, height}: ISpaceBlockProps) => {
	const {name, checkLists} = space;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [space]);

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			onClick={handleClick}
			className="check-lists-space__status"
		>
			{/* тень */}
			<rect
				x="14px"
				y="14px"
				width={width - 12 - 12 - 4}
				rx="9"
				height={height - 12 - 12 - 4}
				className="check-lists-space__status-shadow"
			/>
			{/* прямоугольная диаграмма */}
			<Block
				// key={item.key}
				width={width}
				checkLists={checkLists}
				spaceId={space.id}
			/>

			{/* счетчик повторов */}
			{/* {space.repeatCounter  && (
				<svg
					x="2px"
					y="2px"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						width="24"
						height="24"
						rx="7"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M13.5436 1.59793L15.8887 3.50331C16.2039 3.75943 16.2039 4.24061 15.8887 4.49673L13.5436 6.40211C13.1253 6.74194 12.5 6.4443 12.5 5.9054V4.70002H7C5.72975 4.70002 4.7 5.72976 4.7 7.00002V17C4.7 18.2703 5.72975 19.3 7 19.3H17C18.2703 19.3 19.3 18.2703 19.3 17V7.85002C19.3 7.38058 19.6806 7.00002 20.15 7.00002C20.6194 7.00002 21 7.38058 21 7.85002V17C21 19.2092 19.2091 21 17 21H7C4.79086 21 3 19.2092 3 17V7.00002C3 4.79088 4.79086 3.00002 7 3.00002H12.5V2.09464C12.5 1.55574 13.1253 1.2581 13.5436 1.59793Z"
						className="check-lists-space__counter-icon"
					/>
					<text
						className="check-lists-space__counter-text"
						x="12px"
						y="13px"
						dominantBaseline="middle"
						textAnchor="middle"
					>
						{space.repeatCounter < 10 && space.repeatCounter > 0 ? space.repeatCounter : '9+'}
					</text>
				</svg>
			)} */}

			<text
				className="check-lists-space__number"
				x="50%"
				y="50%"
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{name}
			</text>
		</svg>
	);
};
