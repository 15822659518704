import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IAddOwnerAcptResponse, makeOwnerAcptAddRequest} from '@src/api/backend/ownerAcceptance';
import {ISavingOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ISavingOwnerAcceptance';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';

export type IAddOwnerAcptPayload = IAddOwnerAcptResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddOwnerAcptResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении передачи собственнику');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

export const addOwnerAcceptanceActions = {request, success, failure};

/**
 * Добавляет передачу собственнику
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные осмотра
 */
export const addOwnerAcceptance = (
	objectId: string,
	links: IOwnerAcceptance['links'] | undefined,
	fields: ISavingOwnerAcceptance
) =>
	createApiAction<IAddOwnerAcptResponse>(request, success, failure, () =>
		makeOwnerAcptAddRequest(objectId, links, fields));
