import React, {memo} from 'react';
import {CellProps} from 'react-table';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';

export const CreatedCell = memo(({row}: CellProps<IPreparedLegal>) => {
	const {createdAt, createdBy} = row.original;

	return (
		<div className="legals__cell-created">
			{createdAt && <div>{format(createdAt, dateTimeCommaSeparatedFormat)}</div>}
			{createdBy && <div>{createdBy.displayName}</div>}
		</div>
	);
});
