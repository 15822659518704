import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import React from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import ObjectPageBreadcrumbs from '../ProblemsPage/components/ObjectPageBreadcrumbs';
import {AddButton} from './components/AddButton';
import {PlansTable} from './components/table/PlansTable';
import './PlansPage.less';

export const PlansPage = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const isDesktop = useIsDesktop();

	const sectionsMenu = useObjectSectionsMenu(objectId || '');
	useObjectAppHeader(objectId || '', {sectionsMenu});

	return (
		<div className="page-cont plans-page">
			{isDesktop ? (
				<ObjectPageBreadcrumbs objectId={objectId || ''}>
					<AddButton/>
				</ObjectPageBreadcrumbs>
			) : (
				<AddButton/>
			)}
			<PlansTable/>
		</div>
	);
};
