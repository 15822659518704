import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';

/**
 * Преобразовывает рабочую группу в необходимый для вывода формат
 *
 * @param data массив групп
 */
export const useEnrichedGroups = (data: IWorkingGroup[]): IEnrichedWorkingGroup[] => {
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const companiesMap = useAppSelector(s => s.dictionaries.companies.byId);
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);
	const typesMap = useAppSelector(s => s.dictionaries.workingGroupTypes.byId);
	return useMemo(
		() =>
			data.map(item => {
				const stages = item?.stages?.map(id => stagesMap[id]);
				return {
					...item,
					company: companiesMap[item.companyId],
					stage: stages,
					type: item.type !== undefined ? typesMap[item.type] : undefined,
					leader: usersMap[item.leader],
					performers: [],
					createdBy: item.createdBy ? usersMap[item.createdBy] : undefined,
					createdAt: item.createdAt
						? format(item.createdAt, dateTimeCommaSeparatedFormat)
						: '',
					modifiedBy: item.modifiedBy ? usersMap[item.modifiedBy] : undefined,
					modifiedAt: item.modifiedAt
						? format(item.modifiedAt, dateTimeCommaSeparatedFormat)
						: ''
				};
			}),
		[data, usersMap, companiesMap, stagesMap]
	);
};
