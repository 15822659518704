import {IAddSpaceStatusResponse, requestAddSpaceStatus} from '@src/api/backend/spaceStatuses/add';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {queryClient} from '@src/api/QueryClient';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import * as types from '../constants';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IAddSpaceStatusPayload = IAddSpaceStatusResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (spaceStatus: ISpaceStatus) => ({
	type: types.ADD_SUCCESS,
	payload: {...spaceStatus} as IAddSpaceStatusPayload
});

const failure = (error: IError) => ({
	type: types.ADD_FAILURE,
	payload: error
});

export const addSpaceStatus = (spaceStatus: ISavingSpaceStatus) =>
	createApiAction(
		request, success, failure,
		async () => {
			void queryClient.invalidateQueries(spaceStatusesQueryKeys.list());
			void queryClient.invalidateQueries(spacesQueryKeys.list());

			return requestAddSpaceStatus(spaceStatus);
		}
	);