import React from 'react';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {StructuresFilters} from './filters/StructuresFilters';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {LoadingPanel} from '@tehzor/ui-components';
import {extractStructuresPageSettings} from '@src/store/modules/settings/pages/structures/selectors';
import {DesktopTable} from './table/Table.desktop';

interface IDesktopProps {
	objectId: string;
	loading?: boolean;
}

export const Desktop = ({objectId, loading}: IDesktopProps) => {
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));

	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {sectionsMenu});

	return (
		<div className="page-cont structures-page">
			<PageBreadcrumbs objectId={objectId}/>

			<StructuresFilters objectId={objectId}/>

			<LoadingPanel
				className="structures-page__loading-panel"
				active={loading}
			>
				<>
					{displayMode === StructuresDisplayMode.LIST && <DesktopTable objectId={objectId}/>}
				</>
			</LoadingPanel>
		</div>
	);
};
