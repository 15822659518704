import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useCheckExport} from '@src/core/hooks/export/useCheckExport';

interface ICheckExportProps {
	objectId: string;
	checkId: string;
}

const MobileCheckExport = ({objectId, checkId}: ICheckExportProps) => {
	const [exportDialog, openExportDialog] = useCheckExport(objectId);

	const handleExport = useCallback(() => {
		openExportDialog(checkId);
	}, [checkId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleExport}>
				<i className="tz-export-24"/>
			</IconButton>
			{exportDialog}
		</>
	);
};

export default MobileCheckExport;
