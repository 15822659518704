import React from 'react';
import {IObjectManager} from '@tehzor/tools/interfaces/objects/IObject';
import {EntityGridItem} from '@tehzor/ui-components';

interface IObjectManagerProps {
	value?: IObjectManager;
	label?: string;
	isDesktop?: boolean;
}

const humanIcon = <i className="tz-user-20"/>;

export const ObjectManager = ({value, label, isDesktop}: IObjectManagerProps) => {
	const fullName = value?.fullName;
	const phone = value?.phone;

	return (fullName || phone) ? (
		<EntityGridItem
			className="object-page__d-entities-grid-manager"
			label={label}
			icon={humanIcon}
			inline={isDesktop}
		>
			{fullName && (
				<div className="object-page__d-entities-grid-manager__fullName">
					{fullName}
				</div>
			)}
			{phone && (
				<div className="object-page__d-entities-grid-manager__phone">
					<a href={`tel:${phone}`}>{phone}</a>
				</div>
			)}
		</EntityGridItem>
	) : null;
};