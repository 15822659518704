import {legalsQueryKeys} from '@src/api/cache/legals/keys';
import {
	ILegalsFiltersState,
	ILegalsPageSettingsState
} from '@src/store/modules/settings/pages/legals/reducers';
import {addErrorToast} from '@src/utils/toasts';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useMemo} from 'react';
import {handleFilter} from './utils/handleFilter';
import {handlePagination} from './utils/handlePagination';
import {handleSort} from './utils/handleSort';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {IGetLegalsEntityResponse} from '@src/api/backend/legals-entity/get';
import {IGetLegalEntityResponse} from '@src/api/backend/legal-entity/get';
import {queryClient} from '@src/api/QueryClient';
import {bankAccountsQueryKeys} from '@src/api/cache/bankAccounts/keys';
import {authorizedPersonsQueryKeys} from '@src/api/cache/authorizedPersons/keys';
import {useLegalsPermissions} from '../../permissions/useLegalsPermissions';

interface ILegalsData {
	data: ILegalEntity[];
	pageCount: number;
	currentPage: number;
	total: number;
}

export const useLegals = () => {
	const {data} = useQuery<IGetLegalsEntityResponse, IError>({
		queryKey: [...legalsQueryKeys.list()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке юридических лиц');
		}
	});

	return data;
};

export const useLegalsArray = (legals?: IGetLegalsEntityResponse) =>
	useMemo(() => legals?.allIds?.map(id => legals.byId[id]) || [], [legals]);

export const useLegalsMap = () => {
	const legals = useLegals();
	return useMemo(() => legals?.byId || {}, [legals]);
};

export const useFilteredLegalsArray = (companyId?: string) => {
	const legals = useLegals();
	const legalsArray = useLegalsArray(legals);

	return useMemo(() => {
		if (!companyId) {
			return [];
		}
		return legalsArray.filter(item => item.companyId === companyId);
	}, [legalsArray, companyId]);
};

export const useLegalsData = (
	settings: ILegalsPageSettingsState,
	legals?: IGetLegalsEntityResponse
): ILegalsData => {
	const array = useLegalsArray(legals);

	return useMemo(() => {
		const {offset, pageSize, sort, filters} = settings;
		const scopeFilters: ILegalsFiltersState = {};

		const filteredArr = handleSort(array, sort)
			.filter(item => handleFilter(item, scopeFilters))
			.filter(item => handleFilter(item, filters));

		const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));

		return {
			data: paginatedArr,
			pageCount: Math.ceil(filteredArr.length / pageSize),
			currentPage: Math.floor(offset / pageSize),
			total: filteredArr?.length
		};
	}, [array, settings]);
};

export const useLegal = (legalId: string) => {
	const {data} = useQuery<IGetLegalEntityResponse, IError>({
		queryKey: [...legalsQueryKeys.details(), legalId],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке юрлица');
		}
	});

	return data;
};

export const updateLegalEntities = async () => {
	const {canView: canLegalsView} = useLegalsPermissions();

	if (canLegalsView) {
		// Запрос юрлиц
		await queryClient.prefetchQuery(legalsQueryKeys.list());
		// Запрос банковских счетов
		await queryClient.prefetchQuery(bankAccountsQueryKeys.list());
		// Запрос уполномоченных лиц
		await queryClient.prefetchQuery(authorizedPersonsQueryKeys.list());
	}
};
