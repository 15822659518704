import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetDelegationHistoriesByProblemResponse,
	requestDelegationHistoriesByProblem
} from '@src/api/backend/delegationHistories';
import {IState} from '@src/store/modules';
import checkLoaded from '@tehzor/tools/core/checkLoaded';

export type IGetDelegationHistoriesByProblemPayload = IGetDelegationHistoriesByProblemResponse;

const request = () => ({type: types.GET_DELEGATION_HISTORIES_REQUEST});

const success = (response: IGetDelegationHistoriesByProblemResponse) => ({
	type: types.GET_DELEGATION_HISTORIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке истории делегирования');
	return {
		type: types.GET_DELEGATION_HISTORIES_FAILURE,
		payload: error
	};
};

/**
 * Получает записи из истории делегирования нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const getDelegationHistoriesByProblem = (objectId: string, problemId: string) =>
	checkLoaded<IState, IGetDelegationHistoriesByProblemResponse, ApiAction>(
		s => s.pages.problem.delegationHistories,
		createApiAction<IGetDelegationHistoriesByProblemResponse>(request, success, failure, () =>
			requestDelegationHistoriesByProblem(objectId, problemId))
	);
