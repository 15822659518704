import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {wsConnector} from '../wsConnector';

export type IGetCategoriesSetsResponse = INormalizedData<ICategoriesSet>;

/**
 * Возвращает список наборов видов работ
 */
export const requestGetCategoriesSets = () =>
	wsConnector.sendAuthorizedRequest<IGetCategoriesSetsResponse>(
		'getCategoriesSets',
		{},
		{timeout: 180}
	);
