import {IconMenu, MenuItem} from '@tehzor/ui-components';
import React, {memo, useMemo} from 'react';
import {useAddingCheckListProblemDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListProblemDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {transformLocations} from '../../utils/transformLocations';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractShape} from '@src/store/modules/dictionaries/plans/selectors';
import {useAddingCheckListTaskDialog} from '@src/pages/CheckListPage/hooks/useAddingCheckListTaskDialog';

interface IItemHeaderActionsProps {
	checkItemId: string;
	checkListId?: string;
	canAddEntity?: boolean;
	canEdit?: boolean;
}

const plusIcon = <i className="tz-plus-24" />;
const taskIcon = <i className="tz-task-24" />;

export const ItemHeaderActions = memo(
	({checkItemId, checkListId, canAddEntity}: IItemHeaderActionsProps) => {
		const {objectId, spaceId} = useStrictParams<{objectId: string; spaceId: string}>();
		const space = useAppSelector(extractSpace);
		const roles = useAppSelector(extractUserRoles);
		const [planId, sectorId] = transformLocations(space?.locations);
		const [problemDialog, handleOpenAddProblemDialog] =
			useAddingCheckListProblemDialog(checkItemId);

		const permissions = {
			canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
			canAddEntity
		};

		const sector = useAppSelector(s => extractShape(s, objectId, planId, sectorId));

		const location = useMemo(
			() =>
				sector
					? {
							sectors: [
								{
									sector: sector.id,
									name: sector.name
								}
							]
					  }
					: undefined,
			[sector]
		);
		const [taskDialog, handleOpenAddTaskDialog] = useAddingCheckListTaskDialog({
			objectId,
			floor: space?.floor,
			planId,
			location,
			spaceId,
			checkItemId,
			checkListId
		});
		const menuItems = [];

		if (permissions.canAddTask) {
			menuItems.push(
				<MenuItem
					icon={taskIcon}
					onClick={handleOpenAddTaskDialog}
					key={`task-${checkItemId}`}
				>
					Добавить задачу
				</MenuItem>
			);
		}
		if (permissions.canAddEntity) {
			menuItems.unshift(
				<MenuItem
					icon={plusIcon}
					onClick={handleOpenAddProblemDialog}
					key={`problem-${checkItemId}`}
				>
					Добавить нарушение
				</MenuItem>
			);
		}
		return menuItems.length ? (
			<div>
				<IconMenu icon={plusIcon}>{menuItems}</IconMenu>
				{permissions.canAddEntity && problemDialog}
				{permissions.canAddTask && taskDialog}
			</div>
		) : null;
	}
);
