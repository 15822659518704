import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useMemo} from 'react';

/**
 * Преобразовывает задачу в необходимый для вывода формат
 *
 * @param data задача
 */
export const useEnrichedTask = (data?: ITask, loading?: boolean): IEnrichedTask | undefined => {
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);
	const taskStatusesMap = useAppSelector(s => s.dictionaries.taskStatuses.byId);
	const taskTypesMap = useAppSelector(s => s.dictionaries.taskTypes.byId);
	const taskPrioritiesMap = useAppSelector(s => s.dictionaries.taskPriorities.byId);
	return useMemo(
		() =>
			(data && !loading
				? {
						...data,
						objectId: objectsMap[data.objectId],
						status: data.status ? taskStatusesMap[data.status] : undefined,
						taskPriority: data.taskPriority
							? taskPrioritiesMap[data.taskPriority]
							: undefined,
						taskType: data.taskType ? taskTypesMap[data.taskType] : undefined,
						location: data.location,
						floor: data.floor,
						respUsers: data.respUsers
							?.map(userId => usersMap[userId])
							.filter(user => user),
						taskIntervalStart: data.taskIntervalStart,
						taskIntervalEnd: data.taskIntervalEnd,
						createdBy: data.createdBy ? usersMap[data.createdBy] : undefined,
						createdAt: data.createdAt
							? format(data.createdAt, dateTimeCommaSeparatedFormat)
							: '',
						createdAtNumber: data.createdAt,
						modifiedBy: data.modifiedBy ? usersMap[data.modifiedBy] : undefined,
						modifiedAt: data.modifiedAt
							? format(data.modifiedAt, dateTimeCommaSeparatedFormat)
							: '',
						modifiedAtNumber: data.modifiedAt
				  }
				: undefined),
		[data, loading, usersMap, objectsMap, taskStatusesMap, taskTypesMap, taskPrioritiesMap]
	);
};
