import {wsConnector} from '../wsConnector';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';

export type IGetCheckResponse = ILinkedCheck;

/**
 * Возвращает проверку
 *
 * @param objectId id объекта
 * @param checkId id проверки
 */
export const requestCheck = (objectId: string, checkId: string) =>
	wsConnector.sendAuthorizedRequest<IGetCheckResponse>('getCheck', {objectId, checkId});
