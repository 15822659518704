import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IMarker} from '@tehzor/tools/interfaces/markers/IMarker';
import {IGetMarkersPayload} from './actions/get';
import config from '@src/core/config';
import {normalizeData} from '@tehzor/tools/utils/normalizeData';
import * as problemsTypes from '../problems/constants';
import * as ownerAcceptancesTypes from '../ownerAcceptances/constants';
import {CHANGE_SORT, CLEAR_FILTERS} from '@src/store/modules/settings/pages/problems/constants';
import {IUpdateProblemLocation} from '../problems/actions/updateProblemLocation';

const {entitiesCacheTime} = config;

export interface IMarkersPageState {
	byId: {
		[id: string]: IMarker;
	};
	allIds: string[];
	expires?: number;
}

export type IMarkersPagesState = Record<string, IMarkersPageState>;

export const getInitialStateForPage = (): IMarkersPageState => ({
	byId: {},
	allIds: [],
	expires: undefined
});

export const markers = createReducer<IMarkersPagesState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: { payload: { objectId: string } }) => {
			if (payload.objectId && !state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SUCCESS]: (state: IMarkersPagesState, {payload}: { payload: IGetMarkersPayload }) => {
			const markersAsArray: IMarker[] = Object.values(payload.data).reduce((acc: IMarker[], marker) => {
				acc.push(...Object.values(marker.byId));
				return acc;
			}, []);
			const normalizeMarkers = normalizeData<IMarker>(markersAsArray);

			if (payload.objectId) {
				const page = state[payload.objectId];
				page.byId = normalizeMarkers.byId;
				page.allIds = normalizeMarkers.allIds;
				page.expires = Date.now() + entitiesCacheTime * 1000;
			}
		},
		// Нужно сбрасывать expires при изменении problems offset
		[problemsTypes.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string, offset: number}}
		) => {
			const page = state[payload.objectId];
			if (page) {
				page.expires = undefined;
			}
		},
		// Нужно сбрасывать expires при изменении ownerAcceptances offset
		[ownerAcceptancesTypes.CHANGE_OFFSET]: (
			state,
			{payload}: { payload: { objectId: string, offset: number } }
		) => {
			const page = state[payload.objectId];
			page.expires = undefined;
		},
		// Нужно сбрасывать expires при изменении location
		[problemsTypes.UPDATE_PROBLEM_LOCATION]: (state, {payload}: PayloadAction<IUpdateProblemLocation>) => {
			const page = state[payload.objectId];
			if (page) {
				page.expires = undefined;
			}
		},
		// При сортировке нужно обнулить expires для игнорирования кеша
		[CHANGE_SORT]: (state, {payload}: {payload: {objectId: string}}) => {
			const page = state[payload.objectId];
			if (page) {
				page.expires = undefined;
			}
		},
		// При сбрасывании фильтров нужно обнулить expires для игнорирования кеша
		[CLEAR_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			const page = state[payload.objectId];
			if (page) {
				page.expires = undefined;
			}
		},
		[CLEAR_STORE]: () => ({})
	}
);