import {PrivateRoute} from '@src/containers/PrivateRoute';
import React from 'react';
import {InspectionsPage} from '@src/pages/InspectionsPage';
import InspectionPage from '@src/pages/InspectionPage/InspectionPage';
import InspectionsFiltersRoutingPage from '@src/pages/filters/InspectionsFiltersRoutingPage';
import {inspectionsFilterRoutes} from './inspectionsFilterRoutes';

export const objectInspectionsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<InspectionsPage/>}/>
	},
	{
		path: ':inspectionId',
		element: <PrivateRoute element={<InspectionPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<InspectionsFiltersRoutingPage/>}/>,
		children: inspectionsFilterRoutes
	}
];
