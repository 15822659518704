import React from 'react';
import EntitiesFilters, {ObjectsFilter} from '@src/components/EntitiesFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {StatusesFilter} from '@src/components/EntitiesFilters/components/StatusesFilter';
import {extractSpaceStatusesAsArrayByStage} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {extractProblemStatusesAsArray} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import {extractSpaceIndicatorsAsArray} from '@src/store/modules/dictionaries/spaceIndicatorsSets/selectors';
import {extractSpaceTypesAsArray} from '@src/store/modules/dictionaries/spaceTypes/selectors';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {extractTargetObjects} from '@src/store/modules/dictionaries/objects/selectors';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {extractSpaceTypeDecorationsForSetsAsArray} from '@src/store/modules/entities/spaceTypeDecorations/selectors';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {extractCheckRecordStatusesByEntityTypeAsArray} from '@src/store/modules/dictionaries/checkRecordStatuses/selectors';
import {extractCheckListsAsArrayFilteredBySelectedSpaceType} from '@src/store/modules/dictionaries/checkLists/selectors';
import {extractRootCheckItems} from '@src/store/modules/dictionaries/checkItems/selectors';
import {SpaceTypeDecorationFilter} from '@src/components/EntitiesFilters/components/SpaceTypeDecorationFilter';
import {extractSpaceTypeDecorationSetsForObjectAsArray} from '@src/store/modules/entities/spaceTypeDecorationSets/selectors';

interface ISpacesFiltersProps {
	objectId: string;
}

const SpacesFilters = ({objectId}: ISpacesFiltersProps) => {
	const targetObjects = useAppSelector(s => extractTargetObjects(s, objectId));
	const {state} = useEntitiesFiltersCtx<ISpacesFiltersState>();
	const {schemaView, displayMode, stage} = useAppSelector(s =>
		extractSpacesPageSettings(s, objectId));
	const spaceStatuses = useAppSelector(s =>
		extractSpaceStatusesAsArrayByStage(s, objectId, stage));
	const checkListRecordStatuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityTypeAsArray(s, 'check-list'));
	const checkItemRecordStatuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityTypeAsArray(s, 'check-item'));
	const problemStatuses = useAppSelector(extractProblemStatusesAsArray);
	const indicators = useAppSelector(s => extractSpaceIndicatorsAsArray(s, objectId));
	const types = useAppSelector(extractSpaceTypesAsArray);
	const checkLists = useAppSelector(s =>
		extractCheckListsAsArrayFilteredBySelectedSpaceType(s, objectId));
	const typeDecorationSets = useAppSelector(s => extractSpaceTypeDecorationSetsForObjectAsArray(s, objectId));
	const typeDecorationSetIds = typeDecorationSets.map(set => set.id);
	const typeDecorationsArray = useAppSelector(s =>
		extractSpaceTypeDecorationsForSetsAsArray(s, typeDecorationSetIds));

	const checkList
		= checkLists.length === 1
			? checkLists[0].id
			: state?.checkListIds?.length === 1
				? state.checkListIds[0]
				: '';
	const checkListCategory = useAppSelector(s => extractRootCheckItems(s, checkList));

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="spaces"
		>
			{targetObjects.length > 1 && (
				<ObjectsFilter
					value={state?.objects}
					targetObjects={targetObjects}
				/>
			)}

			<StatusesFilter
				label="Типы помещений"
				filterName="types"
				statuses={state?.types}
				allStatuses={types}
			/>

			{displayMode === SpacesDisplayMode.SCHEMA
				&& schemaView !== SpacesSchemaVariants.CHECK_LISTS && (
					<StatusesFilter
						label="Статусы помещений"
						filterName="statuses"
						statuses={state?.statuses}
						allStatuses={spaceStatuses}
					/>
				)}

			{displayMode === SpacesDisplayMode.SCHEMA && (
				<StatusesFilter
					label="Статусы нарушений"
					filterName="problemStatuses"
					statuses={state?.problemStatuses}
					allStatuses={problemStatuses}
				/>
			)}

			<StatusesFilter
				label="Индикаторы"
				filterName="indicators"
				statuses={state?.indicators}
				allStatuses={indicators}
			/>

			{displayMode === SpacesDisplayMode.SCHEMA && typeDecorationsArray.length > 0 && (
				<SpaceTypeDecorationFilter
					label="Типы отделки"
					filterName="typeDecoration"
					value={state?.typeDecoration}
					typeDecorations={typeDecorationsArray}
					typeDecorationSets={typeDecorationSets}
				/>
			)}

			{checkLists.length > 0 && schemaView === SpacesSchemaVariants.CHECK_LISTS && (
				<StatusesFilter
					label="Чек-листы"
					filterName="checkListIds"
					statuses={state?.checkListIds}
					allStatuses={checkLists}
					resetOnApply={['checkListCategory']}
				/>
			)}

			{displayMode === SpacesDisplayMode.SCHEMA
				&& schemaView === SpacesSchemaVariants.CHECK_LISTS && (
					<StatusesFilter
						label="Статусы чек-листов"
						filterName="checkListStatuses"
						statuses={state?.checkListStatuses ?? ([] as string[])}
						allStatuses={checkListRecordStatuses}
					/>
				)}

			{displayMode === SpacesDisplayMode.SCHEMA
				&& schemaView === SpacesSchemaVariants.CHECK_LISTS && (
					<StatusesFilter
						label="Статусы категорий чек-листов"
						filterName="checkListCategoryStatuses"
						statuses={state?.checkListCategoryStatuses}
						allStatuses={checkItemRecordStatuses}
					/>
				)}

			{displayMode === SpacesDisplayMode.SCHEMA
				&& schemaView === SpacesSchemaVariants.CHECK_LISTS
				&& (checkLists.length === 1 || state?.checkListIds?.length === 1) && (
					<StatusesFilter
						label="Категории чек-листов"
						filterName="checkListCategory"
						statuses={state?.checkListCategory}
						allStatuses={checkListCategory}
					/>
				)}
		</EntitiesFilters>
	);
};

export default SpacesFilters;
