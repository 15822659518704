import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddProblemRespUserResponse, makeAddRespUserRequest} from '@src/api/backend/users';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';

export type IEditProblemPayload = IAddProblemRespUserResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddProblemRespUserResponse) => {
	addSuccessToast(
		'Успех',
		`Заявка принята и отправлена на модерацию.
		 Новый пользователь будет добавлен в систему в течение 10 минут (в период с 8.00 до 22.00 по московскому времени) и автоматически назначен ответственным по данному нарушению.
		 Для выбора данного пользователя в качестве ответственного в следующем нарушении просим дождаться внесения изменений в систему`
	);
	return {
		type: types.ADD_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении ответственного');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет нового ответственного (пока только отправка письма)
 *
 * @param fields измененные поля
 */
export const addResponsible = (fields: IAddingRespUser) =>
	createApiAction<IAddProblemRespUserResponse>(request, success, failure, () =>
		makeAddRespUserRequest(fields));
