import * as types from './constants';
import {ISpacesFiltersState, ISpacesSortState} from './reducers/byPage';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IChangeFloorSortPayload} from '@src/store/interfaces/IChangeFloorSortPayload';

export interface IChangeSpacesDisplayModePayload {
	objectId: string;
	displayMode: SpacesDisplayMode;
}

/**
 * Изменяет способ отображения страницы списка помещений
 *
 * @param objectId id объекта
 * @param displayMode способ отображения
 */
export const changeDisplayMode = (objectId: string, displayMode: SpacesDisplayMode) => ({
	type: types.CHANGE_DISPLAY_MODE,
	payload: {
		objectId,
		displayMode
	} as IChangeSpacesDisplayModePayload
});

/**
 * Изменяет вариант отображения шахматке на странице списка помещений
 *
 * @param objectId id объекта
 * @param schemaView вариант отображения шахматки
 */
export const changeSchemaView = (objectId: string, schemaView: SpacesSchemaVariants) => ({
	type: types.CHANGE_SCHEMA_VIEW,
	payload: {objectId, schemaView}
});

/**
 * Изменяет стадию, для которой отображаются данные на странице списка помещений
 *
 * @param objectId id объекта
 * @param stage стадия
 */
export const changeStage = (objectId: string, stage: ObjectStageIds | undefined) => ({
	type: types.CHANGE_STAGE,
	payload: {objectId, stage}
});

export const changeType = (objectId: string, type: string) => ({
	type: types.CHANGE_TYPE,
	payload: {objectId, type}
});

/**
 * Изменяет фильтр
 *
 * @param objectId id объекта
 * @param filters свойства фильтра
 */
export const changeFilters = (objectId: string, filters: ISpacesFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId,
		filters
	} as IChangeFiltersPayload<ISpacesFiltersState>
});

/**
 * Очищает фильтр
 *
 * @param objectId id объекта
 */
export const clearFilters = (objectId: string) => ({
	type: types.CLEAR_FILTERS,
	payload: {objectId} as IClearFiltersPayload
});

/**
 * Изменяет сортировку
 *
 * @param objectId id объекта
 * @param sort параметры сортировки
 */
export const changeSort = (objectId: string, sort: ISpacesSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		objectId,
		sort
	} as IChangeSortPayload<ISpacesSortState>
});

/**
 * Изменяет количество элементов на странице
 *
 * @param objectId id объекта
 * @param pageSize количество элементов на странице
 */
export const changePageSize = (objectId: string, pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {
		objectId,
		pageSize
	} as IChangePageSizePayload
});

/**
 * Включает/выключает отображение альтернативных имён вместо обычных
 *
 * @param visibility видимость
 */
export const toggleAltNamesVisibility = (visibility?: boolean) => ({
	type: types.TOGGLE_ALT_NAMES_VISIBILITY,
	payload: visibility
});

/**
 * Включает/выключает отображение альтернативных имён вместо обычных
 *
 * @param objectId id объекта
 */
export const changeFloorSort = (objectId: string) => ({
	type: types.CHANGE_FLOOR_SORT,
	payload: {
		objectId
	} as IChangeFloorSortPayload
});
