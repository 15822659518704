import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedSpaceStatusesSet} from '@src/pages/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {TableRowSelect} from '@tehzor/ui-components';
import {format} from 'date-fns';
import React from 'react';
import {CellProps} from 'react-table';
import {CompanyCell} from './CompanyCell';
import {NameCell} from './NameCell';
import {ObjectsCell} from './ObjectsCell';

export const MobileCell = (props: CellProps<IPreparedSpaceStatusesSet>) => {
	const {row} = props;

	const {createdAt, createdBy, modifiedAt, modifiedBy} = row.original;
	const dates: Array<{name: string, at?: number, by?: IBriefUser}> = [];
	if (createdAt || createdBy) {
		dates.push({name: 'Добавлено:', at: createdAt, by: createdBy});
	}
	if (modifiedAt || modifiedBy) {
		dates.push({name: 'Изменено:', at: modifiedAt, by: modifiedBy});
	}

	return (
		<div className="space-statuses-sets__cell-mobile">
			<ClickPreventWrap
				className="space-statuses-sets__cell-mobile-select"
			>
				<TableRowSelect {...row.getToggleRowSelectedProps()}/>
			</ClickPreventWrap>
			<div className="space-statuses-sets__cell-mobile-container">
				<NameCell {...props}/>

				<CompanyCell {...props}/>

				<ObjectsCell {...props}/>

				{dates.map(({at, by, name}) => (
					<div className="space-statuses-sets__cell-mobile__date">
						<div className="space-statuses-sets__cell-mobile__date-name">
							{name}
						</div>
						<div className="space-statuses-sets__cell-mobile__date-info">
							{at && (
								<div>{format(at, dateTimeCommaSeparatedFormat)}</div>
							)}
							{by && (
								<div>{by.displayName}</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};