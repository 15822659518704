import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';

export const createPrescriptionDifference = (
	prev: string | undefined,
	next: string | undefined
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.PRESCRIPTION,
	name: 'предписание'
});
