import {ObjectImage} from '@src/pages/ObjectsPage/components/item/components/ObjectImage';
import {DesktopPhotoViewer, MobilePhotoViewer} from '@tehzor/ui-components';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import React, {useCallback, useState} from 'react';
import '../ObjectPage.less';

interface IImageProps {
	title: string;
	fullImage?: string;
	previewImage?: string;
}

export const Image = ({title, fullImage, previewImage}: IImageProps) => {
	const [isImgOpen, setImgOpen] = useState(false);

	const handleImgOpen = useCallback(() => setImgOpen(true), []);
	const handleImgClose = useCallback(() => setImgOpen(false), []);

	const isTablet = useIsTablet();

	return (
		<>
			<ObjectImage
				image={previewImage}
				className="object-page__image"
				onClick={fullImage ? handleImgOpen : undefined}
			/>
			{fullImage && (
				isTablet ? (
					<DesktopPhotoViewer
						title={title}
						isOpen={isImgOpen}
						onClose={handleImgClose}
						data={fullImage ? [fullImage] : undefined}
					/>
				) : (
					<MobilePhotoViewer
						isOpen={isImgOpen}
						onClose={handleImgClose}
						data={fullImage ? [fullImage] : undefined}
					/>
				)
			)}
		</>
	);
};