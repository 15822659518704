import {IStatsFiltersDialogState} from './state';
import {IBuildingStatisticsFiltersState} from '@src/store/modules/settings/pages/statistics/building/reducers/filters';
import {IStatsSourcesState} from '@src/store/modules/statistics/sources/reducers';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';

/**
 * Конвертирует выбранные фильтры из формата локального хранения компонента диалога в формат хранения в store
 *
 * @param state локальное состояние компонента
 * @param sources справочники статистики
 */
export function convertToStore(
	state: IStatsFiltersDialogState,
	sources: IStatsSourcesState,
	storeCompanies: INormalizedData<ICompany>
): IBuildingStatisticsFiltersState {
	const {companies, objects} = state.checkedObjects.reduce<{
		objects: string[];
		companies: string[];
	}>(
		(prev, id) => {
			if (storeCompanies.allIds.includes(id)) {
				prev.companies.push(id);
			} else {
				prev.objects.push(id);
			}
			return prev;
		},
		{companies: [], objects: []}
	);

	const users = state.checkedUsers;

	const contractors: {[key: string]: true | string[]} = {};
	for (const id in state.checkedContractors) {
		if (state.checkedContractors.hasOwnProperty(id)) {
			const checked = state.checkedContractors[id];
			if (checked.length) {
				contractors[id] = checked.length === 1 && checked[0] === id ? true : checked;
			}
		}
	}

	return {
		companies,
		objects,
		users,
		contractors
		// contractorsUsers
	};
}
