export const ownerAcceptancesQueryKeys = {
	all: () => ['owner-acceptances'],
	one: () => ['owner-acceptance'],
	latest: () => ['latest-owner-acceptances'],
	list: () => [...ownerAcceptancesQueryKeys.all(), 'list'],
	localList: () => [...ownerAcceptancesQueryKeys.all(), 'local-list'],
	add: () => [...ownerAcceptancesQueryKeys.one(), 'add'],
	details: () => [...ownerAcceptancesQueryKeys.one(), 'detail'],
	detail: (id: string) => [...ownerAcceptancesQueryKeys.one(), 'detail', id]
};
