import {wsConnector} from '../wsConnector';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';

export type IEditSpaceOwnerResponse = ISpaceOwner;

/**
 * Изменяет собственника помещения
 *
 * @param ownerId id собственника
 * @param data данные собственника
 */
export const makeSpaceOwnerEditRequest = (ownerId: string, data: ISavingSpaceOwner) =>
	wsConnector.sendAuthorizedRequest<IEditSpaceOwnerResponse>('editSpaceOwner', {
		ownerId,
		...data
	});
