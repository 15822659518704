import React from 'react';
import './TaskInfoMobileCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import {CellProps, TableToggleRowsSelectedProps} from 'react-table';
import {useResponsibleDisplayValue} from '@src/core/hooks/useResponsibleDisplayValue';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {ITaskType} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {useEnrichedTask} from '@src/pages/TaskViewPage/hooks/useEnrichedTask';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {TaskInfo} from '../TaskInfo';
import {IntervalView} from './components/IntervalView';
import {TaskType} from './components/TaskType';
import {TaskStatus} from './components/TaskStatus';

interface ITaskInfoMobileProps {
	task: ITask;
	depth: number;
	subTitle?: React.ReactNode;
	allTaskTypes?: Record<string, ITaskType>;
	getToggleRowSelectedProps: (
		props?: Partial<TableToggleRowsSelectedProps>
	) => TableToggleRowsSelectedProps;
}

export const TaskInfoMobile = ({
	task,
	depth,
	subTitle,
	getToggleRowSelectedProps
}: ITaskInfoMobileProps) => {
	const {respUsers, activeGroup, description} = task;

	const enrichedTask = useEnrichedTask(task);
	const responsibleDisplayValue = useResponsibleDisplayValue(respUsers, activeGroup);

	return (
		<div className="m-task-info-cell">
			<ClickPreventWrap className="m-task-info-cell__select">
				<TableRowSelect {...getToggleRowSelectedProps()}/>
			</ClickPreventWrap>
			<div className="m-task-info-cell__task">
				<div className="m-task-info-cell__task-header">
					<TaskInfo
						data={task}
						depth={depth}
						subTitle={subTitle}
					/>
				</div>

				{description !== undefined && (
					<div className="m-task-info-cell__task-desc">{description}</div>
				)}
				{(task.taskIntervalStart || task.taskIntervalEnd) && (
					<IntervalView
						className="m-task-info-cell__entity-info"
						start={task.taskIntervalStart}
						stop={task.taskIntervalEnd}
					/>
				)}
				{enrichedTask && task.taskType && (
					<TaskType
						className="m-task-info-cell__entity-info"
						task={enrichedTask}
					/>
				)}
				{responsibleDisplayValue ? (
					<div className="m-task-info-cell__entity-info">
						<div className="m-task-info-cell__entity-info-value">
							{responsibleDisplayValue}
						</div>
					</div>
				) : null}
				<div className="m-task-info-cell__task-status-row">
					{enrichedTask && <TaskStatus task={enrichedTask}/>}
				</div>
			</div>
		</div>
	);
};

const TaskInfoMobileCell = (props: CellProps<{data: ITask}>) => (
	<TaskInfoMobile
		task={props.row.original.data}
		depth={props.row.depth}
		getToggleRowSelectedProps={props.row.getToggleRowSelectedProps}
	/>
);

export default TaskInfoMobileCell;
