import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export const actions = createReducer<Array<{type: string, status: string}>>([], {
	[types.APP_ACTIONS_STATUS_CHANGE]: (state, {payload}: {payload: {type: string, status: string}}) => {
		const target = state.findIndex(item => item.type === payload.type);
		if (target !== -1) {
			state[target].status = payload.status;
			if (payload.status === 'loaded') {
				state.splice(target, 1);
			}
		} else if (payload.status !== 'loaded') {
				state.push({type: payload.type, status: payload.status});
		}
	}
});
