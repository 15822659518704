import React, {useCallback, useMemo, useState} from 'react';
import './EditableProblemDialog.less';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {makeTitle} from './utils/makeTitle';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableProblem} from '@src/components/EditableProblem/hooks/useEditableProblem';
import {makeDefaultData} from '@src/core/hooks/states/useEditableProblemState';
import {useEditProblem} from '@src/core/hooks/mutations/problem/useEditProblem';
import {updateProblemLocation} from '@src/store/modules/entities/problems/actions/updateProblemLocation';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';

interface IEditableProblemDialogProps {
	objectId: string;
	problem: IPreparedProblem;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно редактирования нарушения
 */
const EditableProblemDialog = ({
	objectId,
	problem,
	isOpen,
	onClose
}: IEditableProblemDialogProps) => {
	const [saving, setSaving] = useState(false);
	const dispatch = useAppDispatch();
	const object = useAppSelector(s => extractObject(s, objectId));
	const defaultData = useMemo(() => makeDefaultData(problem), [problem]);
	const [fields, getSavingData, reset, isBlocking] = useEditableProblem(
		objectId,
		problem.stage,
		undefined /* problem.scope */,
		defaultData,
		saving,
		undefined,
		undefined,
		problem.createdBy,
		problem.respUsers,
		problem.watchers,
		problem.activeGroupLeader
	);
	const [editProblem] = useEditProblem(object);
	const save = useCallback(async () => {
		setSaving(true);
		if (problem) {
			const savingData = await getSavingData(true);
			if (savingData) {
				editProblem({problemId: problem.id, objectId, fields: savingData});
				if (savingData?.location) {
					dispatch(updateProblemLocation(objectId, problem.id, savingData.location));
				}
				onClose();
			}
		}
		setSaving(false);
	}, [objectId, problem, getSavingData, editProblem, onClose]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-problem-dialog"
			isOpen={isOpen}
			title={makeTitle(problem)}
			footer={(
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};

export default EditableProblemDialog;
