import React from 'react';
import {IconButton, Dialog, Breadcrumbs} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import {EntityLinks} from '@src/components/EntityLinks/EntityLinks';

interface ITitleObjectButtonsProps {
	objectId: string;
}

export const TitleObjectButtons = ({objectId}: ITitleObjectButtonsProps) => {
	const {pushPath} = useChangePath();
	const breadcrumbs = useObjectBreadcrumbs(objectId);
	const [isOpen, toggle] = useToggle(false);

	return (
		<>
			<IconButton
				type="inline"
				onClick={toggle}
			>
				<i className="tz-simple-arrow-16"/>
			</IconButton>
			<Dialog
				onRequestClose={toggle}
				isOpen={isOpen}
			>
				<div className="check-list-page__links-dialog-breadcrumbs">
					<Breadcrumbs
						items={breadcrumbs}
						onNavigate={pushPath}
					/>
				</div>
				<EntityLinks links={{objectId}}/>
			</Dialog>
		</>
	);
};
