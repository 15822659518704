import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';
import {wsConnector} from '../wsConnector';

export type IEditCategoriesSetResponse = ICategoriesSet;

export const requestEditCategoriesSet = (categoriesSet: ISavingCategoriesSet) =>
	wsConnector.sendAuthorizedRequest<IEditCategoriesSetResponse>('editCategoriesSet', {
		...categoriesSet
	});
