import React, {useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractCheckListById,
	extractCheckListsAsArrayFilteredByStructureType
} from '@src/store/modules/dictionaries/checkLists/selectors';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureCheckListLink} from '@tehzor/tools/utils/links';
import {
	EntityGrid,
	EntityGridItem,
	LoadingPanel,
	MobilePagination,
	Plate
} from '@tehzor/ui-components';
import {CheckListItems} from '../list/CheckListItems/CheckListItems';
import {RecordStatusSelect} from '@src/pages/CheckListPage/components/RecordStatusSelect';
import {DiagramIcon} from '../DiagramIcon/DiagramIcon';
import {extractProblemsByCheckListIdAsArray} from '@src/store/modules/pages/checkLists/selectors/problems';
import declination from '@tehzor/tools/utils/declination';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';

interface IListDetailProps {
	structureType: string;
}

export const ListDetail = (props: IListDetailProps) => {
	const {structureType} = props;
	const {replacePath} = useChangePath();
	const params = useStrictParams<{
		objectId: string;
		listId: string;
		structureId: string;
	}>();
	const {objectId, listId} = params;
	const checkList = useAppSelector(s => extractCheckListById(s, params.listId));
	const checkLists = useAppSelector(s =>
		extractCheckListsAsArrayFilteredByStructureType(s, objectId, structureType));
	const itemsLoading = useAppSelector(s => s.dictionaries.checkItems.loading);
	const problems = useAppSelector(s => extractProblemsByCheckListIdAsArray(s, listId));

	const {changeCheckListRecord} = useChangeCheckRecord(objectId, checkList);
	const handlePageChange = useCallback(
		(nextIndex: number) => {
			const next = checkLists[nextIndex];
			replacePath(formStructureCheckListLink({...params, listId: next.id}));
		},
		[checkLists]
	);

	if (!checkList) {
		return null;
	}

	const currentIndex = checkLists.indexOf(checkList);

	return (
		<div>
			<Plate
				withoutPadding
				className="structure-page__info-content"
			>
				<MobilePagination
					className="spaces-page__m-pagination"
					page={currentIndex}
					pageCount={checkLists.length}
					renderTitle={({page}) => `${page + 1} чек-лист`}
					onPageChange={handlePageChange}
				/>
				<EntityGrid
					className="structure-page__info-content_check-list"
					withBorders
				>
					<EntityGridItem
						className={{
							content: 'check-list-page__m-list-detail-info-header'
						}}
						fullRow
					>
						<div className="check-list-page__m-list">
							<DiagramIcon listId={listId}/>
							<div className="check-list-page__m-list-text">
								<div className="check-list-page__m-list-text-title">
									{checkList.name}
								</div>
								{problems.length > 0 && (
									<div className="check-list-page__m-list-text-subtitle">
										{problems.length}
										{' '}
										{declination(problems.length, [
											'нарушение',
											'нарушения',
											'нарушений'
										])}
									</div>
								)}
							</div>
						</div>

						<div className="check-list-page__m-list-detail-info-tags">
							<ProblemIndicators checkListId={checkList.id}/>
						</div>
					</EntityGridItem>

					<EntityGridItem
						icon={<i className="tz-status-20"/>}
						label="Статус"
						inline
						fullRow
					>
						<RecordStatusSelect
							item={checkList}
							mutation={changeCheckListRecord}
							disabled
						/>
					</EntityGridItem>
				</EntityGrid>
			</Plate>

			<LoadingPanel active={itemsLoading}>
				<Plate
					withoutPadding
					className="check-list-page__m-list-detail-items"
				>
					<CheckListItems checkList={checkList}/>
				</Plate>
			</LoadingPanel>
		</div>
	);
};
