import React, {Dispatch, ReactNode, SetStateAction, useEffect} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	IGlobalAddingPermissions,
	IGlobalAddingLinks,
	IGlobalAddingRef,
	IGlobalAddingType
} from '../GlobalAddingEntityDialog';
import AddingCheckGlobal from '@src/components/AddingCheckDialog/AddingCheckGlobal';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import AddingInternalAcceptanceGlobal from '@src/components/AddingInternalAcceptanceDialog/AddingInternalAcceptanceGlobal';
import AddingOwnerAcptGlobal, {
	AddingOwnerAcceptanceView
} from '@src/components/AddingOwnerAcptDialog/AddingOwnerAcptGlobal';
import AddingWarrantyClaimGlobal from '@src/components/AddingWarrantyClaimDialog/AddingWarrantyClaimGlobal';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {extractLatestChecksAsArray} from '@src/store/modules/dictionaries/latestUserChecks/selectors';
import {extractLatestInternalAcceptancesAsArray} from '@src/store/modules/dictionaries/latestUserInternalAcceptances/selectors';
import {
	extractLatestOwnerAcceptanceId,
	extractLatestOwnerAcceptancesAsArray
} from '@src/store/modules/dictionaries/latestUserOwnerAcceptances/selectors';
import {
	extractLatestWarrantyClaimId,
	extractLatestWarrantyClaimsAsArray
} from '@src/store/modules/dictionaries/latestUserWarrantyClaims/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import {getLatestUserOwnerAcceptances} from '@src/store/modules/dictionaries/latestUserOwnerAcceptances/actions/get';
import {getLatestUserWarrantyClaims} from '@src/store/modules/dictionaries/latestUserWarrantyClaims/actions/get';
import {AddingWarrantyClaimView} from '@src/components/AddingWarrantyClaimDialog/AddingWarrantyClaimDialog';
import {useLatestChecks} from '@src/components/AddingCheckDialog/hooks/useLatestChecks';
import {useLatestInternalAcceptances} from '@src/components/AddingInternalAcceptanceDialog/hooks/useLatestInternalAcceptances';

interface IUseEntitiesMapsProps {
	objectId: string;
	spaceId?: string;
	links?: IGlobalAddingLinks;
	permissions: IGlobalAddingPermissions;
	addingEntityRef: IGlobalAddingRef;
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	types?: IGlobalAddingType[];

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;

	selectedCheckId?: string;
	selectedInternalAcceptanceId?: string;
	selectedOwnerAcceptanceId?: string;
	selectedWarrantyClaimId?: string;

	setSelectedCheckId: Dispatch<SetStateAction<string | undefined>>;
	setSelectedInternalAcceptanceId: Dispatch<SetStateAction<string | undefined>>;
	setSelectedOwnerAcceptanceId: Dispatch<SetStateAction<string | undefined>>;
	setSelectedWarrantyClaimId: Dispatch<SetStateAction<string | undefined>>;

	ownerAcceptanceView: AddingOwnerAcceptanceView;
	setOwnerAcceptanceView: Dispatch<SetStateAction<AddingOwnerAcceptanceView>>;

	warrantyClaimView: AddingWarrantyClaimView;
	setWarrantyClaimView: Dispatch<SetStateAction<AddingWarrantyClaimView>>;

	onClose: () => void;
}

export const useEntitiesMaps = ({
	objectId,
	spaceId,
	links,
	permissions,
	addingEntityRef,
	defaultProblemData,
	defaultInspectionData,
	types,
	saving,
	setSaving,
	selectedCheckId,
	selectedInternalAcceptanceId,
	selectedOwnerAcceptanceId,
	selectedWarrantyClaimId,
	setSelectedCheckId,
	setSelectedInternalAcceptanceId,
	setSelectedOwnerAcceptanceId,
	setSelectedWarrantyClaimId,
	ownerAcceptanceView,
	setOwnerAcceptanceView,
	warrantyClaimView,
	setWarrantyClaimView,
	onClose
}: IUseEntitiesMapsProps): [
	Record<ObjectStageIds, ReactNode>,
	Record<ObjectStageIds, IEntitySelectProps>
] => {
	const contentMap = {} as Record<ObjectStageIds, ReactNode>;
	const entitiesSelectMapProps = {} as Record<ObjectStageIds, IEntitySelectProps>;

	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const latestChecks = useAppSelector(s =>
		extractLatestChecksAsArray(s, objectId, links?.spaceId)
	);

	useLatestChecks(objectId, setSelectedCheckId, selectedCheckId, links);

	if (permissions.checks) {
		contentMap[ObjectStageIds.BUILDING] = (
			<AddingCheckGlobal
				stage={ObjectStageIds.BUILDING}
				objectId={objectId}
				onClose={onClose}
				links={links}
				saving={saving}
				setSaving={setSaving}
				ref={addingEntityRef}
				selectedCheckId={selectedCheckId}
				defaultProblemData={defaultProblemData}
				defaultInspectionData={defaultInspectionData}
				types={types}
			/>
		);
		entitiesSelectMapProps[ObjectStageIds.BUILDING] = {
			name: 'Проверка',
			newName: 'Новая проверка',
			selected: selectedCheckId,
			onEntityChange: setSelectedCheckId,
			entities: latestChecks,
			localEntities: []
		};
	}

	const latestInternalAcceptances = useAppSelector(s =>
		extractLatestInternalAcceptancesAsArray(s, objectId, links?.spaceId)
	);

	useLatestInternalAcceptances(
		objectId,
		setSelectedInternalAcceptanceId,
		selectedInternalAcceptanceId,
		links
	);

	if (permissions.internalAcceptances) {
		contentMap[ObjectStageIds.ACCEPTANCE] = (
			<AddingInternalAcceptanceGlobal
				stage={ObjectStageIds.ACCEPTANCE}
				objectId={objectId}
				onClose={onClose}
				links={links}
				ref={addingEntityRef}
				selectedInternalAcceptanceId={selectedInternalAcceptanceId}
				defaultProblemData={defaultProblemData}
				defaultInspectionData={defaultInspectionData}
				types={types}
				saving={saving}
				setSaving={setSaving}
			/>
		);
		entitiesSelectMapProps[ObjectStageIds.ACCEPTANCE] = {
			name: 'Внутренняя приемка',
			newName: 'Новая внутреняя приемка',
			selected: selectedInternalAcceptanceId,
			onEntityChange: setSelectedInternalAcceptanceId,
			entities: latestInternalAcceptances,
			localEntities: []
		};
	}

	const latestOwnerAcceptanceId = useAppSelector(s =>
		extractLatestOwnerAcceptanceId(s, objectId, links?.spaceId)
	);
	const latestOwnerAcceptances = useAppSelector(s =>
		extractLatestOwnerAcceptancesAsArray(s, objectId, links?.spaceId)
	);

	useEffect(() => {
		if (latestOwnerAcceptanceId) {
			setSelectedOwnerAcceptanceId(latestOwnerAcceptanceId);
		}
	}, [latestOwnerAcceptanceId]);

	useAsync(async () => {
		if (online && permissions.acceptances) {
			await dispatch(getLatestUserOwnerAcceptances());
		}
	}, [online, permissions]);

	if (permissions.acceptances) {
		contentMap[ObjectStageIds.TRANSFER] = (
			<AddingOwnerAcptGlobal
				objectId={objectId}
				spaceId={spaceId}
				onClose={onClose}
				links={links}
				ref={addingEntityRef}
				defaultProblemData={defaultProblemData}
				selectedOwnerAcceptanceId={selectedOwnerAcceptanceId}
				view={ownerAcceptanceView}
				setView={setOwnerAcceptanceView}
				saving={saving}
				setSaving={setSaving}
			/>
		);
		entitiesSelectMapProps[ObjectStageIds.TRANSFER] = {
			name: 'Передача собственнику',
			newName: 'Новая передача собственнику',
			selected: selectedOwnerAcceptanceId,
			onEntityChange: setSelectedOwnerAcceptanceId,
			entities: latestOwnerAcceptances,
			localEntities: []
		};
	}

	const latestWarrantyClaimId = useAppSelector(s =>
		extractLatestWarrantyClaimId(s, objectId, links?.spaceId)
	);
	const latestWarrantyClaims = useAppSelector(s =>
		extractLatestWarrantyClaimsAsArray(s, objectId, links?.spaceId)
	);

	useEffect(() => {
		if (latestWarrantyClaimId) {
			setSelectedWarrantyClaimId(latestWarrantyClaimId);
		}
	}, [latestWarrantyClaimId]);

	useAsync(async () => {
		if (online && permissions.claims) {
			await dispatch(getLatestUserWarrantyClaims());
		}
	}, [online, permissions]);

	if (permissions.claims) {
		contentMap[ObjectStageIds.WARRANTY] = (
			<AddingWarrantyClaimGlobal
				objectId={objectId}
				spaceId={spaceId}
				onClose={onClose}
				links={links}
				ref={addingEntityRef}
				selectedWarrantyClaimId={selectedWarrantyClaimId}
				defaultProblemData={defaultProblemData}
				view={warrantyClaimView}
				setView={setWarrantyClaimView}
				saving={saving}
				setSaving={setSaving}
			/>
		);
		entitiesSelectMapProps[ObjectStageIds.WARRANTY] = {
			name: 'Гарантийное обращение',
			newName: 'Новое гарантийное обращение',
			selected: selectedWarrantyClaimId,
			onEntityChange: setSelectedWarrantyClaimId,
			entities: latestWarrantyClaims,
			localEntities: []
		};
	}

	return [contentMap, entitiesSelectMapProps];
};
