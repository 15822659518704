import React from 'react';
import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import {useFakeItems} from '../hooks/useFakeItems';

interface IProblemsPlaceholderProps {
	includeImage?: boolean;
	includeSubTitle?: boolean;
	includeChildren?: boolean;
	count?: number;
	permanentContent?: React.ReactNode;
}

const StatsTreePlaceholder = (props: IProblemsPlaceholderProps) => {
	const {includeImage, includeSubTitle, includeChildren, count, permanentContent} = props;
	const fakeItems = useFakeItems(includeImage, includeSubTitle, includeChildren, count);

	return (
		<>
			{fakeItems.map(item => (
				<VerticalTabContent
					key={item.id}
					className="statistics-page__tabs-content-panel"
				>
					<StatsTreePanel
						id={item.id}
						title={item.title}
						subTitle={item.subTitle}
						image={item.image}
						loading
						showExpandBtnPlaceholder
						permanentContent={permanentContent}
					/>
				</VerticalTabContent>
			))}
		</>
	);
};

export default StatsTreePlaceholder;
