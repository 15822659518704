import classNames from 'classnames';
import React from 'react';
import './CirclePlaceholder.less';

interface IBlockPlaceholderProps {
	className?: string;
	style?: React.CSSProperties;
	radius?: string | number;
}

const CirclePlaceholder = ({className, style, radius}: IBlockPlaceholderProps) => {
	const styles: React.CSSProperties = {...style};
	if (radius !== undefined) {
		styles.width = radius;
		styles.height = radius;
	}

	return (
		<div
			className={classNames('circle-placeholder', className)}
			style={styles}
		/>
);
};

export default CirclePlaceholder;
