import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractTasksAndSubTasksByWorkAcceptanceIdAsArray
} from '@src/store/modules/pages/checkLists/selectors/tasks';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';

/**
 * Вычисляет количество задач по каждому статусу
 *
 */
export function useTasksCountByStatus(
): Array<{status: TaskStatusId, count: number}> {
	const tasks = useAppSelector(state => extractTasksAndSubTasksByWorkAcceptanceIdAsArray(state));
	return useMemo(() => {
		const countByStatus = tasks.reduce<Record<string, number>>((prev, task) => {
			if (!prev[task.status]) {
				prev[task.status] = 0;
			}
			prev[task.status] += 1;
			return prev;
		}, {});

		return Object.values(TaskStatusId)
			.map(status => ({
				status,
				count: countByStatus[status] ?? 0
			}));
	}, [tasks]);
}
