import React from 'react';
import {StatsTreePanel, VerticalTabContent} from '@tehzor/ui-components';
import {Tree} from 'array-to-tree';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import {ProblemsStatsChart} from './ProblemsStatsChart';
import {SubObjectStats} from './SubObjectStats';
import {sumStatsValues} from '../../../utils/sumStatsValues';
import {StatsMap} from '@src/utils/statsAggregators/problemsByStatuses';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSourcesLoading} from '@src/store/modules/statistics/ui/selectors/sources';
import {IUseNavigateProblemsProps, useNavigateProblems} from '../../../hooks/useNavigateProblems';
import {extractTargetObjects} from '@src/store/modules/dictionaries/objects/selectors';

interface IObjectStatsProps {
	data: Tree<IStatsObject>;
	objectsStats: StatsMap;
}

export const ObjectStats = ({data, objectsStats}: IObjectStatsProps) => {
	const sourcesLoading = useAppSelector(extractSourcesLoading);
	const objectStats = objectsStats[data.id];
	const totalObjectCount = objectStats?.expired
		? sumStatsValues(objectStats) - objectStats?.expired
		: sumStatsValues(objectStats);

	const navigationSettings: IUseNavigateProblemsProps = data.children
		? {navProps: [
{
				type: 'objects', prop: data.id
			}
],
			page: 'building'}
		: {objectId: data.id, page: 'building'};

	const children = useAppSelector(s => extractTargetObjects(s, data.id));
	const navigate = useNavigateProblems(navigationSettings, children);

	const handleClick = (status: string) => {
		navigate(status);
	};

	return (
		<VerticalTabContent className="statistics-page__tabs-content-panel">
			<StatsTreePanel
				id={data.id}
				title={data.name}
				image={data.image?.url}
				loading={sourcesLoading}
				showExpandBtnPlaceholder={!!data.children?.length}
				permanentContent={(
					<ProblemsStatsChart
						onClick={handleClick}
						stats={objectStats}
						totalCount={totalObjectCount}
					/>
				)}
				hiddenContent={data.children?.map(item => (
					<SubObjectStats
						key={item.id}
						data={item}
						stats={objectsStats}
						level={1}
					/>
				))}
			/>
		</VerticalTabContent>
	);
};
