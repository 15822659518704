import React from 'react';
import {StatusSelect} from '@tehzor/ui-components';
// import {CellProps} from 'react-table';
// import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import ClickPreventWrap from './ClickPreventWrap';
// import {ILocalProblem} from '@src/interfaces/ILocalProblem';

// interface ILocalProblemStatusProps {
// 	problem: ILocalProblem;
// }

export const LocalProblemStatus = () => {
	const statuses = useAppSelector(s => s.dictionaries.problemStatuses);

	return (
		<ClickPreventWrap>
			<StatusSelect
				style={{maxWidth: '100%'}}
				statuses={statuses}
				disabled
				value={ProblemStatusId.WAITING}
			/>
		</ClickPreventWrap>
	);
};

const LocalProblemStatusCell = () => (
	<LocalProblemStatus/>
);

export default LocalProblemStatusCell;
