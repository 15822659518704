import {IEditableContractState} from '@src/core/hooks/states/useEditableContractState';
import React, {memo, useCallback} from 'react';
import {SingleSelectContractField} from '../fields/SingleSelectContractField';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractContractorsAsArray, extractContractorsData} from '@src/store/modules/dictionaries/companies/selectors';
import {useFilteredLegalsArray, useLegalsMap} from '@src/core/hooks/queries/legals';
import {useAuthorizedPersonsMap, useFilteredAuthorizedPersonsArray} from '@src/core/hooks/queries/authorizedPersons';
import {useBankAccountsMap, useFilteredBankAccounts} from '@src/core/hooks/queries/bankAccounts';

interface IContractorBlockProps {
	editingState: IEditableContractState;
	isMobile?: boolean;

	onChange: (
		value: string | number | string[] | null | undefined,
		field: keyof IEditableContractState
	) => void;
	onError: (field: keyof IEditableContractState) => void;
}

export const ContractorBlock = memo(({
	editingState, isMobile, onChange, onError
}: IContractorBlockProps) => {
	const {contractorId, contractorLegalEntityId: legalEntityId} = editingState;
	
	const contractors = useAppSelector(extractContractorsAsArray);
	const contractorsData = useAppSelector(extractContractorsData);
	const contractorsMap = contractorsData.byId;
	const contractor = contractorId ? contractorsMap[contractorId] : undefined;

	const handleChangeContractor = useCallback((
		value: string | null | undefined
	) => {
		onChange(value, 'contractorId');

		// Сброс связанных сущностей
		onChange(null, 'contractorAuthorizedPersonId');
		onChange(null, 'contractorBankAccountId');
		onChange(null, 'contractorLegalEntityId');
	}, [onChange]);

	const legalEntities = useFilteredLegalsArray(contractor?.uuid);
	const legalEntitiesMap = useLegalsMap();

	const authorizedPersons = useFilteredAuthorizedPersonsArray(
		contractor?.uuid, legalEntityId
	);
	const authorizedPersonsMap = useAuthorizedPersonsMap();

	const bankAccounts = useFilteredBankAccounts(
		contractor?.uuid, legalEntityId
	);
	const bankAccountsMap = useBankAccountsMap();

	return (
		<div className="editable-contract__blocks-contractor">
			<div className="editable-contract__blocks-contractor-title">
				Подрядчик
			</div>
			<div className="editable-contract__blocks-contractor-container">
				<SingleSelectContractField
					items={contractors}
					itemsMap={contractorsMap}
					label="Подрядчик"
					dialogTitle="Подрядчик"
					errorMessage="Укажите подрядчика"
					hasError={editingState.errors.contractorId}
					required
					value={editingState.contractorId}
					isMobile={isMobile}
					onConfirm={handleChangeContractor}
					onError={() => onError('contractorId')}
				/>

				<SingleSelectContractField
					disabled={!contractorId}
					items={legalEntities}
					itemsMap={legalEntitiesMap}
					label="Юридическое лицо"
					dialogTitle="Юридическое лицо"
					errorMessage="Укажите юридическое лицо"
					hasError={editingState.errors.contractorLegalEntityId}
					required
					value={editingState.contractorLegalEntityId}
					isMobile={isMobile}
					onConfirm={value => onChange(value, 'contractorLegalEntityId')}
					onError={() => onError('contractorLegalEntityId')}
				/>

				<SingleSelectContractField
					disabled={!contractorId || !legalEntityId}
					fieldName='accountNumber'
					items={bankAccounts}
					itemsMap={bankAccountsMap}
					label="Реквизиты"
					dialogTitle="Реквизиты"
					errorMessage="Укажите реквизиты"
					hasError={editingState.errors.contractorBankAccountId}
					value={editingState.contractorBankAccountId}
					isMobile={isMobile}
					onConfirm={value => onChange(value, 'contractorBankAccountId')}
					onError={() => onError('contractorBankAccountId')}
				/>

				<SingleSelectContractField
					disabled={!contractorId || !legalEntityId}
					items={authorizedPersons}
					itemsMap={authorizedPersonsMap}
					label="Уполномоченное лицо"
					dialogTitle="Уполномоченное лицо"
					errorMessage="Укажите уполномоченное лицо"
					hasError={editingState.errors.contractorAuthorizedPersonId}
					value={editingState.contractorAuthorizedPersonId}
					isMobile={isMobile}
					onConfirm={value => onChange(value, 'contractorAuthorizedPersonId')}
					onError={() => onError('contractorAuthorizedPersonId')}
				/>
			</div>
		</div>
	);
});