import React, {CSSProperties} from 'react';
import './Table.less';
import classNames from 'classnames';

interface ITableProps {
	className?: string;
	style?: CSSProperties;
	role?: string;
	children?: React.ReactNode;
	dontSetWidth?: boolean;
}

const Table = ({className, style, role, children, dontSetWidth}: ITableProps) => {
	const s: CSSProperties = {...style};
	if (dontSetWidth && s.minWidth) {
		delete s.minWidth;
	}

	return (
		<div
			className={classNames('table2', className)}
			style={s}
			role={role}
		>
			{children}
		</div>
	);
};

export default Table;