import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {
	CategoriesSetFilterMobile,
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	FixDateFilterMobile,
	ObjectsFilterMobile,
	PlansFilterMobile,
	ProblemStatusesFilterMobile,
	RespUsersFilterMobile,
	CriticalFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {StagesFilterMobile} from '@src/components/MobileEntitiesFilters/components/StagesFilterMobile';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {stringify} from 'query-string';
import {ExpiredFilterMobile} from '@src/components/MobileEntitiesFilters/components/ExpiredFilterMobile';
import {ProblemTagsSetFilterMobile} from '@src/components/MobileEntitiesFilters/components/ProblemTagsSetFilterMobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {InspectorsFilterMobile} from '@src/components/MobileEntitiesFilters/components/InspectorsFilterMobile';
import {useProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';

const ProblemsFilterPage = () => {
	const {pushPath} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();
	const settings = useProblemSettings(objectId || 'all');
	const canGoBack = (useLocation().state as {canGoBack?: boolean | null})?.canGoBack;
	const {state, isLocal, isStateChanged, change, mobileClear, apply}
		= useEntitiesFiltersCtx<IProblemsFiltersState>();

	const search = stringify(state, {arrayFormat: 'index'});

	const handleApply = useCallback(() => {
		if (canGoBack) {
			if (isLocal) {
				apply();
				pushPath(
					objectId ? `/objects/${objectId}/problems/?${search}` : `/problems/?${search}`
				);
			} else {
				apply();
				pushPath(objectId ? `/objects/${objectId}/problems` : '/problems');
			}
		}
	}, [canGoBack, apply, isLocal, search, objectId]);

	useAppHeader(
		{
			title: 'Фильтры',
			showBackBtn: true,
			mobileRightButtons: isCleanable<IProblemsFiltersState>(state, objectId) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[state, objectId]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			{objectId === undefined && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
				/>
			)}

			<ProblemStatusesFilterMobile
				objectId={objectId}
				value={state?.statuses}
				entity={entity}
				onChange={change}
			/>

			{settings.plannedFixDate && (
				<FixDateFilterMobile
					objectId={objectId}
					from={state.plannedFixDateFrom}
					to={state.plannedFixDateTo}
					entity={entity}
				/>
			)}

			{settings.critical && (
				<CriticalFilterMobile
					value={state.critical}
					onChange={change}
				/>
)}

			<ExpiredFilterMobile
				value={state.expired}
				onChange={change}
				entity={entity}
			/>

			<ProblemTagsSetFilterMobile
				objectId={objectId}
				value={state?.problemTags}
				entity={entity}
				onChange={change}
			/>

			<CreatedByFilterMobile
				objectId={objectId}
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity}
			/>

			{settings.categoryId && (
				<CategoriesSetFilterMobile
					objectId={objectId}
					value={state?.categories}
					entity={entity}
					onChange={change}
				/>
			)}

			{objectId !== undefined && settings.location && (
				<PlansFilterMobile
					objectId={objectId}
					value={state?.plans}
					entity={entity}
					onChange={change}
				/>
			)}

			{settings.respUsers && (
				<RespUsersFilterMobile
					objectId={objectId ?? 'all'}
					value={state?.respUsers}
					entity={entity}
					onChange={change}
				/>
			)}

			{settings.inspectors && (
				<InspectorsFilterMobile
					objectId={objectId ?? 'all'}
					value={state?.inspectors}
					entity={entity}
					onChange={change}
				/>
			)}

			<StagesFilterMobile
				objectId={objectId}
				value={state?.stages}
				entity={entity}
				onChange={change}
			/>
		</FilterPage>
	);
};

export default ProblemsFilterPage;
