import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';

/**
 * Хук для хлебных крошек от "всех объектов" до структур
 *
 * @param objectId id конечного объекта
 */
export function useStructuresBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId);
		items.push({label: 'Структуры', url: `/objects/${objectId}/structures`, inactive: false});
		return items;
	}, [objectId, objects]);
}
