import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	ObjectsFilterMobile,
	RespUsersFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {stringify} from 'query-string';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTaskStatusesAsArray} from '@src/store/modules/dictionaries/taskStatuses/selectors';
import {extractTaskTypesAsArray} from '@src/store/modules/dictionaries/taskTypes/selectors';
import {extractTaskPrioritiesAsArray} from '@src/store/modules/dictionaries/taskPriorities/selectors';
import {IntervalFilterMobile} from '@src/components/MobileEntitiesFilters/components/IntervalFilterMobile';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const TasksFiltersPage = () => {
	const {pushPath} = useChangePath();
	const {entity} = useStrictParams<{entity: string}>();
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};
	const {state, isLocal, isStateChanged, change, mobileClear, apply}
		= useEntitiesFiltersCtx<ITasksFiltersState>();

	const allStatuses = useAppSelector(extractTaskStatusesAsArray);
	const allTypes = useAppSelector(extractTaskTypesAsArray);
	const allPriorities = useAppSelector(extractTaskPrioritiesAsArray);

	const search = stringify(state, {arrayFormat: 'index'});

	const handleApply = useCallback(() => {
		if (canGoBack) {
			if (isLocal) {
				apply();
				pushPath(`/tasks/?${search}`);
			} else {
				apply();
				pushPath('/tasks');
			}
		}
	}, [canGoBack, apply, isLocal, search]);

	useAppHeader(
		{
			title: 'Фильтры',
			showBackBtn: true,
			mobileRightButtons: isCleanable<ITasksFiltersState>(state) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[state]
	);

	useRouting(!canGoBack, 'all', entity);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			<ObjectsFilterMobile
				entity={entity}
				value={state?.objects}
			/>

			<BaseListFilterMobile
				value={state?.types}
				entity={entity}
				onChange={change}
				options={allTypes}
				filterName="types"
				label="Тип задачи"
			/>

			<BaseListFilterMobile
				value={state?.statuses}
				entity={entity}
				onChange={change}
				options={allStatuses}
				filterName="statuses"
				label="Статус задачи"
			/>

			<BaseListFilterMobile
				value={state?.priorities}
				entity={entity}
				onChange={change}
				options={allPriorities}
				filterName="priorities"
				label="Приоритет"
			/>

			<IntervalFilterMobile
				from={state?.taskIntervalStartFrom}
				to={state?.taskIntervalStartTo}
				label="Дата начала"
				entity={entity}
				type="intervalstart"
			/>

			<IntervalFilterMobile
				from={state?.taskIntervalEndFrom}
				to={state?.taskIntervalEndTo}
				label="Дата завершения"
				entity={entity}
				type="intervalend"
			/>

			<CreationDateFilterMobile
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity}
			/>

			<RespUsersFilterMobile
				objectId="all"
				value={state?.respUsers}
				entity={entity}
				onChange={change}
			/>

			<CreatedByFilterMobile
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>
		</FilterPage>
	);
};
