const domain = 'offline-mode';

export const CHANGE_NETWORK_STATUS = `${domain}/network-status/change`;

export const CHANGE_CACHE_STATUS = `${domain}/cache-status/change`;
export const CHANGE_TRANSFER_STATUS = `${domain}/transfer-status/change`;

export const CHANGE_DICTIONARIES_CACHE_STATE = `${domain}/dictionaries-cache-state/change`;
export const CHANGE_DICTIONARIES_CACHE_PROGRESS = `${domain}/dictionaries-cache-progress/change`;

export const CHANGE_ENTITIES_CACHE_STATE = `${domain}/entities-cache-state/change`;
export const CHANGE_ENTITIES_CACHE_PROGRESS = `${domain}/entities-cache-progress/change`;

export const CHANGE_COMMON_SAVING_STATUS = `${domain}/saving-status/common/change`;
export const CHANGE_PARTICULAR_SAVING_STATUS = `${domain}/saving-status/partial/change`;

export const CHANGE_ENTITIES_TRANSFER_STATE = `${domain}/transfer-state/change`;
