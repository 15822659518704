import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';

interface IMobileSelectionClearProps {
	onSelectedRowsChange: (value: string[]) => void;
}

const MobileSelectionClearing = ({onSelectedRowsChange}: IMobileSelectionClearProps) => {
	const handleClick = useCallback(() => {
		onSelectedRowsChange([]);
	}, [onSelectedRowsChange]);

	return (
		<IconButton onClick={handleClick}>
			<i className="tz-close-24"/>
		</IconButton>
	);
};

export default MobileSelectionClearing;
