import {IContract} from '@tehzor/tools/interfaces/contracts';
import React, {ReactNode, useState} from 'react';
import {EditableContractDialog} from '../EditableContractDialog';

export const useEditableContractDialog = (
	contract?: IContract
): [ReactNode, () => void] => {
	const [isOpen, setOpen] = useState(false);

	const open = () => setOpen(true);
	const close = () => setOpen(false);

	const dialog = (
		<EditableContractDialog
			contract={contract}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
};