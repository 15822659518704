import {wsConnector} from '../wsConnector';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';

export type IAddCategoryResponse = ICategory;

/**
 * Добавляет вид работ
 *
 * @param category данные вида работ
 */
export const requestAddCategories = (category: ISavingCategory) =>
	wsConnector.sendAuthorizedRequest<IAddCategoryResponse>('addCategory', {
		...category
	});
