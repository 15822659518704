import {IconMenu, MenuItem} from '@tehzor/ui-components';
import React from 'react';
import {useAddingCheckListProblemDialog} from '@src/pages/WorkAcceptancePage/hooks/useAddingCheckListProblemDialog';

interface IMobileItemHeaderActionsProps {
	itemId: string;
}

export const MobileItemHeaderActions = (props: IMobileItemHeaderActionsProps) => {
	const {itemId} = props;
	const [dialog, handleOpen] = useAddingCheckListProblemDialog(itemId);

	return (
		<div>
			<IconMenu>
				<MenuItem
					icon={<i className="tz-plus-24"/>}
					onClick={handleOpen}
				>
					Добавить нарушение
				</MenuItem>
			</IconMenu>
			{dialog}
		</div>
	);
};
