import React from 'react';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {offlineJournalActionType} from '../enums/actionsTypes';
import {MenuItem} from '@tehzor/ui-components';
import {formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';

export const EntityLink = (props: {item: IOfflineJournalEntity, goTo: (url: string) => void}) => {
	const {item, goTo} = props;
	let menuItem = <></>;
	let url: string;
	if (
		item.object?.objectId
		&& (item.entity.entityType === offlineJournalActionType.PROBLEM_ADD
			|| item.entity.entityType === offlineJournalActionType.PROBLEM_SPACE_ADD
			|| item.entity.entityType === offlineJournalActionType.PROBLEM_CHECKLIST_ADD)
	) {
		url = formProblemLink(item.object?.objectId, item.entity.entityId);
		menuItem = (
			<MenuItem
				key="edit"
				icon={<i className="tz-problem-24"/>}
				onClick={() => goTo(url)}
			>
				Перейти к нарушению
			</MenuItem>
		);
	}
	if (
		item.object?.objectId
		&& (item.entity.entityType === offlineJournalActionType.INSPECTION_ADD
			|| item.entity.entityType === offlineJournalActionType.INSPECTION_CHECKLIST_ADD
			|| item.entity.entityType === offlineJournalActionType.INSPECTION_SPACE_ADD)
	) {
		url = formInspectionLink(item.object?.objectId, item.entity.entityId);
		menuItem = (
			<MenuItem
				key="edit"
				icon={<i className="tz-inspection-24"/>}
				onClick={() => goTo(url)}
			>
				Перейти к осмотру
			</MenuItem>
		);
	}
	return menuItem;
};
