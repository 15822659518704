import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IAdditionalNameProps {
	space: ISpace;
}

const AdditionalName = ({space}: IAdditionalNameProps) => {
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return (
		<EntityGridItem
			label={altNamesVisible ? 'Основное название' : 'Дополнительное название'}
			icon={<i className="tz-name-20"/>}
			fullRow
		>
			<span className="semi-bold">{altNamesVisible ? space.name : space.altName}</span>
		</EntityGridItem>
	);
};

export default AdditionalName;
