import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {extractTreeCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {Tree} from 'array-to-tree';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {getInitialState} from '@src/store/modules/pages/checkLists/reducers/problems';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

const getTreeItemIds = (item: Tree<ICheckItem>): string[] => {
	const subIds = [];

	if (item.children) {
		subIds.push(...item.children.map(getTreeItemIds).flat(Infinity));
	}

	return Array.from(new Set([item.id, ...subIds])) as string[];
};

/**
 * Возвращает задачи для конкретного объекта
 */
export const extractTasksData = createSelector(
	(state: IState) => state.pages.checkLists.tasks,
	data => data || getInitialState()
);

/**
 * Возвращает задачи для приемки работ
 */
export const extractTasksAndSubTasksByWorkAcceptanceIdAsArray = createSelector(
	[extractTasksData],
	data =>
		data.allIds.reduce((acc: ITask[], id) => {
			const tasks = data.byId[id];
			acc.push(tasks);
			return acc;
		}, [])
);

/**
 * Возвращает задачи всех вложенных подкатегорий в виде массива
 */
export const extractTasksAndSubTasksByItemIdAsArray = createSelector(
	[
		extractTasksData,
		(state: IState) => state,
		(state: IState, listId: string) => listId,
		(state: IState, listId: string, itemId: string) => itemId
	],
	(data, state, listId, itemId) => {
		const item = extractTreeCheckItemById(state, listId, itemId);
		const array = item ? getTreeItemIds(item) : [];
		return data.allIds.reduce((acc: ITask[], id) => {
			const task = data.byId[id];

			if (task?.links?.checkItemId && array.includes(task?.links?.checkItemId)) {
				acc.push(task);
			}

			return acc;
		}, []);
	}
);

/**
 * Возвращает нарушения для чек-листа в виде массива
 */
export const extractTasksByCheckListIdAsArray = createSelector(
	[extractTasksData, (state: IState, checkListId: string) => checkListId],
	(data, checkListId) =>
		data.allIds.reduce((acc: ITask[], id) => {
			const task = data.byId[id];

			if (task?.links?.checkListId === checkListId) {
				acc.push(task);
			}

			return acc;
		}, [])
);

/**
 * Возвращает список задач в виде entites для категории или подкатегории чек-листа
 */
export const extractTasksEntitiesByItem = createSelector(
	[extractTasksAndSubTasksByItemIdAsArray],
	tasks => tasks.map(task => ({id: task.id, type: 'task', data: task}))
);

/**
 * Возвращает список задач в виде entites для чек-листа
 */
export const extractTasksEntitiesByList = createSelector(
	[extractTasksByCheckListIdAsArray],
	tasks => tasks.map(task => ({id: task.id, type: 'task', data: task}))
);