import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetCheckResponse} from '@src/api/backend/check';
import {queryClient} from '@src/api/QueryClient';
import {checksQueryKeys} from '@src/api/cache/checks/keys';

export type IGetCheckPayload = IGetCheckResponse;

const request = (checkId: string) => ({
	type: types.GET_REQUEST,
	payload: {checkId}
});

const success = (response: IGetCheckResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке проверки');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает проверку по Id
 */
export const getCheck = (objectId: string, checkId: string) =>
	createApiAction<IGetCheckResponse>(
		() => request(checkId),
		success,
		failure,
		() =>
			queryClient.fetchQuery<IGetCheckResponse>({
				queryKey: [...checksQueryKeys.detail(checkId), objectId],
				staleTime: Infinity
			})
	);
