import React from 'react';
import {getStyle} from '../utils/getStyle';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import useMeasure from 'react-use/lib/useMeasure';

interface IBarProps {
	status: IProblemStatus;
	value?: number;
	total: number;
}

const Bar = (props: IBarProps) => {
	const {status, value = 0, total} = props;
	const [ref, {width}] = useMeasure<HTMLDivElement>();

	if (value <= 0) {
		return null;
	}

	return (
		<div
			key={status.id}
			className="problems-bar-chart__bar"
			style={getStyle(status, value, total)}
			ref={ref}
		>
			{width > value.toString(10).length * 8 + 4 ? value : undefined}
		</div>
	);
};

export default Bar;
