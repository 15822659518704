import {Tree} from 'array-to-tree';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

interface IResult {
	item: IProblemComment;
	parent: IProblemComment;
	showAppeal: boolean;
}

/**
 * Преобразует всех потомков в одномерный массив
 *
 * @param comment комментарий
 * @param showAppeal показывать ли обращение, необходимо для >1 уровней
 */
export function flattenDescendants(comment: Tree<IProblemComment>, showAppeal = false): IResult[] {
	if (comment.children?.length) {
		return comment.children.reduce<IResult[]>((prev, item) => {
			prev.push({item, parent: comment, showAppeal});
			if (item.children?.length) {
				prev = prev.concat(flattenDescendants(item, true));
			}
			return prev;
		}, []);
	}
	return [];
}