import {wsConnector} from '../wsConnector';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ISavingWarrantyClaim';

export type IAddWarrantyClaimResponse = IWarrantyClaim;

/**
 * Добавляет гарантийное обращение
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные
 */
export const makeWarrantyClaimAddRequest = (
	objectId: string,
	links: IWarrantyClaim['links'] | undefined,
	fields: ISavingWarrantyClaim
) =>
	wsConnector.sendAuthorizedRequest<IAddWarrantyClaimResponse>('addWarrantyClaim', {
		objectId,
		links,
		...fields
	});
