import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetInternalAcceptancesResponse, requestInternalAcceptances} from '@src/api/backend/internalAcceptances';

const request = (objectId: string) => ({
	type: types.GET_SELECTION_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetInternalAcceptancesResponse) => {
	if (response) {
		addSuccessToast('Успех', 'список подгружен');
	}
	return {
		type: types.GET_SELECTION_SUCCESS,
		payload: {
			...response,
			objectId
		}
	};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка внутренних приёмок');
	}
	return {
		type: types.GET_SELECTION_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список внутренних приёмок для отображения в select
 */

export const getSelectionInternalAcceptances = (
	objects: string[],
	createdBy: string[] | undefined,
	offset: number,
	limit: number
) =>
	createApiAction<IGetInternalAcceptancesResponse>(request, success, failure, () =>
		requestInternalAcceptances({objects, createdBy}, undefined, offset, limit));
