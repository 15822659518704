import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';

export interface IGetProblemTagsSetsResponse extends INormalizedData<IProblemTagsSet> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список наборов меток нарушений
 */
export const requestProblemTagsSets = async () =>
	wsConnector.sendAuthorizedRequest<IGetProblemTagsSetsResponse>(
		'getProblemTagsSets',
		{},
		{timeout: 180}
	);
