import React from 'react';
import './SpaceTypeDecorationPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAsync, useUnmount} from 'react-use';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {Table as DesktopTable} from './components/table/Table.desktop';
import {MobileTable} from './components/table/Table.mobile';
import {AddButton} from './components/AddButton';
import {getSpaceTypeDecorationSets} from '@src/store/modules/entities/spaceTypeDecorationSets/actions/get';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import {RESET} from '@src/store/modules/entities/spaceTypeDecorationSets/constants';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import classNames from 'classnames';

export const SpaceTypeDecorationPage = () => {
	useAppHeader({
		title: 'Наборы типов отделки помещений',
		mobileRightButtons: <AppUpdateMenu/>
	});

	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();

	useUnmount(() => {
		dispatch({type: RESET});
	});

	const loadingState = useAsync(async () => {
		await Promise.all([dispatch(getSpaceTypeDecorationSets())]);
	}, []);

	return (
		<div
			className={classNames(
				'page-cont',
				isDesktop
					? 'manage-space-type-decoration-page'
					: 'manage-space-type-decoration-page_mobile'
			)}
		>
			<PageBreadcrumbs isDesktop={isDesktop}/>
			{isDesktop ? (
				<DesktopTable loading={loadingState.loading}/>
			) : (
				<>
					<MobileTable loading={loadingState.loading}/>
					<AddButton/>
				</>
			)}
		</div>
	);
};
