import React, {useCallback} from 'react';
import {EntityInnerTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceEntities} from '@src/store/modules/entities/space/selectors';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {
	formCheckLink,
	formInspectionLink,
	formProblemLink,
	formWarrantyClaimLink,
	formOwnerAcceptanceLink,
	formInternalAcceptanceLink
} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import SelectionRow from './SelectionRow';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<ISpaceEntity>>;
	hideHead?: boolean;
	spaceId: string;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

const Table = ({
	objectId,
	columns,
	hideHead,
	spaceId,
	selectedRows,
	onSelectedRowsChange
}: IDesktopTableProps) => {
	const {pushPath} = useChangePath();
	const entities = useAppSelector(extractSpaceEntities);

	const handleRowClick = useCallback(
		(entity: ISpaceEntity) => {
			switch (entity.type) {
				case 'check':
					return pushPath(formCheckLink(objectId, entity.data.id));
				case 'problem':
					return pushPath(formProblemLink(objectId, entity.data.id));
				case 'inspection':
					return pushPath(formInspectionLink(objectId, entity.data.id));
				case 'warranty-claim':
					return pushPath(formWarrantyClaimLink(objectId, entity.data.id));
				case 'owner-acceptance':
					return pushPath(formOwnerAcceptanceLink(objectId, entity.data.id));
				case 'internal-acceptance':
					return pushPath(formInternalAcceptanceLink(objectId, entity.data.id));
				default:
					return undefined;
			}
		},
		[objectId]
	);

	return (
		<EntityInnerTable
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable
			onRowClick={handleRowClick}
			selectedRows={selectedRows}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					objectId={objectId}
					spaceId={spaceId}
				/>
			)}
		/>
	);
};

export default Table;
