import React, {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import SelectionActions from '../selection/SelectionActions';
import {extractProblemsByCheckListIdAsArray} from '@src/store/modules/pages/checkLists/selectors/problems';

interface IMobileRightButtonsProps {
	objectId: string;
	listId: string;
	spaceId: string;
	selectedRows?: string[];
}

const MobileRightButtons = ({
	objectId,
	listId,
	spaceId,
	selectedRows
}: IMobileRightButtonsProps) => {
	const problems = useAppSelector(s => extractProblemsByCheckListIdAsArray(s, listId));

	const selectedEntities = useMemo(
		() => problems.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, problems]
	);

	if (!spaceId) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				spaceId={spaceId}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return null;
};

export default MobileRightButtons;
