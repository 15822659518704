import React, {useCallback, useState} from 'react';
import {
	ActionButtons,
	Button,
	EntityGridItem,
	InlineButton,
	ReadMore,
	TextField
} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editCheck} from '@src/store/modules/entities/check/actions/edit';

interface CheckDescriptionProps {
	objectId: string;
	checkId: string;
	value: string | undefined;
	canEdit?: boolean;
}

const DescriptionDesktop = ({
	objectId,
	checkId,
	value,
	canEdit
}: CheckDescriptionProps) => {
	const [isEditable, setIsEditable] = useState(false);
	const [text, setText] = useState(value);
	const [saving, setSaving] = useState(false);
	const dispatch = useAppDispatch();
	const handleChange = useCallback((v: string) => {
		setText(v);
	}, []);
	const handleSave = useCallback(async () => {
		setSaving(true);
		try {
			await dispatch(
				editCheck(objectId, checkId, {description: text})
			);
		} finally {
			setSaving(false);
			setIsEditable(false);
		}
	}, [text]);

	return (
		<EntityGridItem
			label="Описание"
			icon={<i className="tz-description-20"/>}
			fullRow
			buttons={
				canEdit && !isEditable ? (
					<InlineButton
						className="check-page__documents-edit-icon"
						type="accent"
						leftIcon={<i className="tz-edit-16"/>}
						onClick={() => setIsEditable(true)}
					/>
				) : null
			}
		>
			{isEditable ? (
				<TextField
					value={text}
					cleanable
					elementType="textarea"
					onChange={handleChange}
					className={{element: 'check-page__documents-description'}}
				/>
			) : value ? (
				<ReadMore
					className={{
						root: 'check-page__documents-description',
						button: 'check-page__documents-description-button'
					}}
					text={value}
					maxLength={100}
				/>
			) : null}
			{isEditable && (
				<ActionButtons className="check-page__info-buttons">
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={() => {
							setText(value);
							setIsEditable(false);
						}}
					/>
				</ActionButtons>
			)}
		</EntityGridItem>
	);
};
export default DescriptionDesktop;
