import React from 'react';
import './ProblemStatusesFilter.less';
import {Checkbox, CheckboxGroup, InlineButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemStatusesWithExpired} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import {useDispatch} from 'react-redux';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import classNames from 'classnames';
import {AnyAction} from 'redux';

interface IProblemStatusesFilterProps {
	value: ProblemStatusId[];

	changeAction(value: ProblemStatusId[]): AnyAction;
}

const ProblemStatusesFilter = ({value, changeAction}: IProblemStatusesFilterProps) => {
	const statuses = useAppSelector(extractProblemStatusesWithExpired);
	const dispatch = useDispatch();

	const handleChange = (v: ProblemStatusId[]) => {
		dispatch(changeAction(v));
	};

	const handleReset = () => {
		if (value.length) {
			dispatch(changeAction([]));
		}
	};

	return (
		<div className="stats-problem-statuses-filter">
			<CheckboxGroup
				value={value}
				onChange={handleChange}
			>
				{statuses.map(item => (
					<Checkbox
						key={item.id}
						className={{
							root: classNames(
								'stats-problem-statuses-filter__cb',
								`stats-problem-statuses-filter__cb_${item.id}`
							),
							icon: `stats-problem-statuses-filter__cb-icon_${item.id}`,
							content: 'stats-problem-statuses-filter__cb-label'
						}}
						value={item.id}
					>
						{item.name}
					</Checkbox>
				))}
			</CheckboxGroup>

			{value.length > 0 && (
				<InlineButton
					className="stats-display-controls__reset-btn"
					type="cancel"
					label="Сбросить"
					onClick={handleReset}
				/>
			)}
		</div>
	);
};

export default ProblemStatusesFilter;
