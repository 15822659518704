import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getWorkAcceptances} from '@src/store/modules/entities/workAcceptances/actions';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import React, {useCallback, useState} from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {EditableWorkAcceptanceDialog} from '../EditableWorkAcceptanceDialog';

export function useEditableWorkAcceptanceDialog(
	objectId: string,
	stage: ObjectStageIds,
	workAcceptance?: IWorkAcceptance
): [React.ReactNode, () => void] {
	const dispatch = useAppDispatch();
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const {state} = useEntitiesFiltersCtx();

	const [, reloadWorkAcceptancesList] = useAsyncFn(async () => {
		await dispatch(getWorkAcceptances(objectId, state));
	}, [objectId, state]);

	const dialog = (
		<EditableWorkAcceptanceDialog
			objectId={objectId}
			stage={stage}
			workAcceptance={workAcceptance}
			isOpen={isOpen}
			onClose={close}
			onSuccess={reloadWorkAcceptancesList}
		/>
	);

	return [dialog, open];
}
