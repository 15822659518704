import React, {useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ProblemCommentsVisibility} from '@src/store/modules/settings/pages/problem/reducers/comments';
import {changeCommentsVisibility} from '@src/store/modules/settings/pages/problem/actions';
import {MenuItem, MultipleMenu} from '@tehzor/ui-components';
import {IMultipleMenuProps} from '@tehzor/ui-components/src/components/menu/MultipleMenu';

const menuItems = {
	[ProblemCommentsVisibility.REPLIES]: 'Ответы',
	[ProblemCommentsVisibility.COMMENTS]: 'Комментарии'
};

const sortFn = (a: string, b: string) => (a > b ? -1 : 1);

/**
 * Формирует меню и отображаемое значение для фильтра видимости комментариев нарушения
 */
export function useVisibilityFilterMenu(): [React.ReactElement<IMultipleMenuProps>, string] {
	const visibility = useAppSelector(s => s.settings.pages.problem.comments.visibility);
	const dispatch = useAppDispatch();

	const handleChange = useCallback((keys: ProblemCommentsVisibility[]) => {
		dispatch(changeCommentsVisibility(keys.sort(sortFn)));
	}, []);

	const label = visibility.length
		? visibility.map(key => menuItems[key]).join(', ')
		: 'Выберите, что показать';

	const menu = (
		<MultipleMenu
			value={visibility}
			onChange={handleChange}
		>
			<MenuItem itemKey={ProblemCommentsVisibility.REPLIES}>
				{menuItems[ProblemCommentsVisibility.REPLIES]}
			</MenuItem>
			<MenuItem itemKey={ProblemCommentsVisibility.COMMENTS}>
				{menuItems[ProblemCommentsVisibility.COMMENTS]}
			</MenuItem>
		</MultipleMenu>
	);

	return [menu, label];
}
