import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetObjectResponse, requestObject} from '@src/api/backend/object';

export type IGetObjectPayload = IGetObjectResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetObjectResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке объекта');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает объект
 *
 * @param objectId id объекта
 */
export const getObject = (objectId: string) =>
	createApiAction<IGetObjectResponse>(request, success, failure, () => requestObject(objectId));
