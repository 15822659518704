import React from 'react';
import './CheckPage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractCheck} from '@src/store/modules/entities/check/selectors';
import {useAsync} from 'react-use';
import {getCheck, getCheckProblems} from '@src/store/modules/entities/check/actions';
import {getCheckInspections} from '@src/store/modules/entities/check/actions/inspections';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import {getCheckProblemReplies} from '@src/store/modules/entities/check/actions/problemReplies';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const CheckPage = () => {
	useScrollRestoration();

	const {objectId, checkId} = useStrictParams<{objectId: string, checkId: string}>();
	const check = useAppSelector(extractCheck);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await Promise.all([
			dispatch(getCheck(objectId, checkId)),
			dispatch(getCheckProblems(objectId, checkId)),
			dispatch(getCheckInspections(objectId, checkId)),
			dispatch(getCheckProblemReplies(objectId, checkId))
		]);
	});

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				checkId={checkId}
				check={check}
			/>
);
	}
	return (
		<Mobile
			objectId={objectId}
			checkId={checkId}
			check={check}
		/>
);
};

export default CheckPage;
