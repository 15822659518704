import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';

export type IGetResponsibilityRulesResponse = INormalizedData<IResponsibilityRule>;

/**
 * Возвращает список правил ответственности
 */
export const requestResponsibilityRules = async () =>
	wsConnector.sendAuthorizedRequest<IGetResponsibilityRulesResponse>('getResponsibilityRules');
