import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import {GET_LIST_REQUEST, GET_SCHEMA_REQUEST} from '@src/store/modules/entities/structures/constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {IChangeStructuresDisplayModePayload} from '../actions';

export interface IStructuresFiltersState extends Record<string, unknown> {
	objects?: string[];
	statuses?: string[];
	problemStatuses?: string[];
	workAcceptanceStatuses?: string[];
	indicators?: string[];
	types?: string[];
	stages?: string[];
	checkListIds?: string[];
	typeDecoration?: string[];
	checkListStatuses?: string[];
	checkListCategoryStatuses?: string[];
	categories?: string[];
}

export type IStructuresSortState = Record<string, boolean>;

export interface IStructuresPageSettingsState {
	// Способ отображения структур
	displayMode: StructuresDisplayMode;
	// Стадия
	type?: string;
	// Фильтры
	filters: IStructuresFiltersState;
	// Сортировка
	sort: IStructuresSortState;
	// Количество отображаемых элементов в таблице
	pageSize: number;

}

export type IStructuresPagesSettingsState = Record<string, IStructuresPageSettingsState>;

export const getInitialStateForPage = (): IStructuresPageSettingsState => ({
	displayMode: StructuresDisplayMode.LIST,
	type: undefined,
	filters: {},
	sort: {name: true},
	pageSize: 20
});

const checkPageExistence = (state: IStructuresPagesSettingsState, objectId: string) => {
	if (!state.hasOwnProperty(objectId)) {
		state[objectId] = getInitialStateForPage();
	}
};

export const byPage = createReducer<IStructuresPagesSettingsState>(
	{},
	{
		[GET_SCHEMA_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			checkPageExistence(state, payload.objectId);
		},
		[GET_LIST_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			checkPageExistence(state, payload.objectId);
		},
		[types.CHANGE_DISPLAY_MODE]: (
			state,
			{payload}: {payload: IChangeStructuresDisplayModePayload}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].displayMode = payload.displayMode;
		},
		[types.CHANGE_TYPE]: (state, {payload}: {payload: {objectId: string, type: string}}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].type = payload.type;
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<IStructuresFiltersState>}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].filters = payload.filters;
		},
		[types.CLEAR_FILTERS]: (state, {payload}: {payload: IClearFiltersPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].filters = {} as IStructuresFiltersState;
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<IStructuresSortState>}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].sort = payload.sort;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: IChangePageSizePayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].pageSize = payload.pageSize;
		}
	}
);
