import IUser from '@tehzor/tools/interfaces/IUser';
import {getPermissionsForInspection} from './getPermissionsForInspection';
import {IPreparedInspection} from '../interfaces/IPreparedInspection';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {format} from 'date-fns';

/**
 * Преобразовывает осмотры в необходимый для вывода формат
 *
 * @param inspections массив осмотров
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 */
export const convertInspections = (
	inspections: IListInspection[],
	networkStatus: boolean,
	user?: IUser
): IPreparedInspection[] =>
	inspections.map(item => ({
		id: item.id,
		object: item.object,
		links: item.links,
		number: item.number,
		localNumber: item.localNumber,
		transferStatus: item.transferStatus,
		plan: item.plan?.name || '',
		floor: item.floor || '',
		location: item.displayLocation || '',
		createdBy: item.createdBy?.fullName || '',
		createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
		modifiedBy: item.modifiedBy?.fullName || '',
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeCommaSeparatedFormat) : '',
		...getPermissionsForInspection(item, networkStatus, item.object?.id, user)
	}));
