import {useMemo} from 'react';
import {useSpacesBreadcrumbs} from './useSpacesBreadcrumbs';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
/**
 * Хук для хлебных крошек от "всех объектов" до помещения
 *
 * @param objectId id конечного объекта
 * @param space данные помещения
 */
export function useSpaceForWarrantyClaimBreadcrumbs(
	objectId: string,
	space?: {id: string, name: string, type: SpaceTypeId}
) {
	const res = useSpacesBreadcrumbs(objectId);
	const spaceTitle = useSpaceTitle(space);
	return useMemo(
		() =>
			res.concat({
				label: spaceTitle,
				url: space ? formSpaceLink(objectId, space.id) : '',
				inactive: false
			}),
		[objectId, res, spaceTitle, space]
	);
}
