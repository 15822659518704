import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSortedProblemViewsAsArray} from '@src/store/modules/entities/problemViews/selectors';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import declination from '@tehzor/tools/utils/declination';
import {Dialog, EntityGridItem, InlineButton, UserAvatar} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import React, {useCallback, useMemo, useState} from 'react';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';

interface IProblemViewsProps {
	problem: IPreparedProblem;
}

const icon = <i className="tz-eye-20"/>;

const words = ['человек', 'человека', 'человек'];

export const ProblemViews = ({problem}: IProblemViewsProps) => {
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const views = useAppSelector(s =>
		extractSortedProblemViewsAsArray(s, problem.id));
	const title = useMemo(() => {
		const latestView = views.length ? views.reduce((latest, view) => {
			if (view.createdAt > latest.createdAt) {
				return view;
			}
			return latest;
		}) : undefined;

		if (views.length <= 1) {
			return latestView?.user.displayName;
		}

		const numberOfViews = views.length - 1;

		return `${latestView?.user.displayName} и еще ${numberOfViews} ${declination(numberOfViews, words)}`;
	},
	[views]);

	const trigger = (
		<InlineButton
			onClick={open}
			type="accent"
			label={title}
		/>
	);

	const content = views ? views.map(view => (
		<div
			className="problem-page__views-user"
			key={view.id}
		>
			<UserAvatar
				user={view.user}
				size="40"
			/>
			<div className="problem-page__views-user__container">
				<div className="problem-page__views-user__container-title">
					<div
						className="problem-page__views-user__container-title-name"
					>
						{view.user.displayName}
					</div>
					<div
						className="problem-page__views-user__container-title-date"
					>
						{format(new Date(view.createdAt), longDateTimeFormat, {locale: ru})}
					</div>
				</div>
				<div className="problem-page__views-user__container-position">
					{view.user.position}
				</div>
			</div>

		</div>
	)) : null;

	const dialog = (
		<Dialog
			className="problem-page__views-dialog"
			isOpen={isOpen}
			title={`Просмотры нарушения №${problem.number || problem.localNumber}`}
			onRequestClose={close}
		>
			{content}
		</Dialog>
	);

	return views.length ? (
		<>
			<EntityGridItem
				className="problem-page__views"
				icon={icon}
				inline
				fullRow
			>
				{trigger}
				{' '}
				{views.length > 1 ? 'просмотрели это нарушение' : 'просмотрел это нарушение'}
			</EntityGridItem>

			{dialog}
		</>
	) : null;
};