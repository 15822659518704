import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IAddTaskResponse, makeTaskAddRequest} from '@src/api/backend/task';
import {ISavingTask} from '@tehzor/tools/interfaces/tasks/ISavingTask';
import {queryClient} from '@src/api/QueryClient';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';

export type IAddTaskPayload = IAddTaskResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddTaskResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении задачи');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет задачу
 *
 * @param fields данные для добавления
 */
export const addTask = (fields: ISavingTask) =>
	createApiAction<IAddTaskResponse>(request, success, failure, async () => {
		const data = await makeTaskAddRequest(fields);
		await queryClient.invalidateQueries(tasksQueryKeys.all());
		return data;
	});
