import React, {memo} from 'react';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import Location from '@src/components/viewFields/Location';
import Description from '@src/components/viewFields/Description';
import MobileImages from '@src/pages/InspectionPage/components/attachments/Images.mobile';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';

interface IMobileInfoProps {
	objectId: string;
	inspection: ILinkedInspection;
	images?: string[];

	onImageClick?: (index: number) => void;
}

const MobileInfo = ({objectId, inspection, images, onImageClick}: IMobileInfoProps) => (
	<EntityGrid withBorders>
		{inspection.description && <Description value={inspection.description}/>}
		{images !== undefined && images.length > 0 && (
			<MobileImages
				images={images}
				onClick={onImageClick}
			/>
		)}
		{(!!inspection.plan || !!inspection.floor || !!inspection.location) && (
			<Location
				objectId={objectId}
				planId={inspection.plan?.id}
				floor={inspection.floor}
				location={inspection.location}
			/>
		)}
		{inspection.createdAt !== inspection.modifiedAt && (
			<EditorInfo
				icon={<i className="tz-edit-20"/>}
				label="Изменено"
				date={inspection.modifiedAt}
				user={inspection.modifiedBy}
			/>
		)}
	</EntityGrid>
);

export default memo(MobileInfo);
