import React, {memo} from 'react';
import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAddingLegalDialog} from '@src/components/AddingLegalDialog';

interface IAddButtonProps {
	canAdd?: boolean;
}

const plusIcon = <i className="tz-plus-20" />;

export const AddButton = memo(({canAdd = false}: IAddButtonProps) => {
	const isLargeTablet = useIsLargeTablet();
	const [legalDialog, handleOpenLegalDialog] = useAddingLegalDialog({});

	return canAdd ? (
		<>
			{isLargeTablet ? (
				<Button
					className="legals__add-button"
					type="accent-blue"
					leftIcon={plusIcon}
					label="Добавить юрлицо"
					onClick={handleOpenLegalDialog}
				/>
			) : (
				<FloatingActionButton
					className={{root: 'legals__add-floating-button'}}
					icon={plusIcon}
					label="Юрлицо"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleOpenLegalDialog}
				/>
			)}
			{legalDialog}
		</>
	) : null;
});
