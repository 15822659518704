import React, {useContext} from 'react';
import {CellProps} from 'react-table';
import {IconButton} from '@tehzor/ui-components';
import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {useAsyncFn} from 'react-use';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';
import {DispatchActionCtx} from '../CategoriesSetsTable';

const deleteIcon = <i className="tz-delete"/>;

const DeleteCell = ({row}: CellProps<IFullCategoriesSet>) => {
	const {canDelete} = useCategoriesSetPermissions();
	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить набор',
		`Вы действительно хотите удалить набор ${row.original.name}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			dispatchAction({type: 'delete', payload: row.original});
		}
	}, [row.original, dispatchAction]);

	return canDelete ? (
		<>
			<IconButton
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	) : null;
};

export default DeleteCell;
