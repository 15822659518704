import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ISavingInternalAcceptance';
import {IEditInternalAcceptanceResponse, makeInternalAcceptanceEditRequest} from '@src/api/backend/internalAcceptance/edit';

export type IEditInternalAcceptancePayload = IEditInternalAcceptanceResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditInternalAcceptanceResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении внутреннй приемки');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет внутреннюю приемку
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приемки
 * @param fields измененные поля
 */
export const editInternalAcceptance = (
	objectId: string,
	internalAcceptanceId: string,
	fields: ISavingInternalAcceptance = {}
) =>
	createApiAction<IEditInternalAcceptanceResponse>(request, success, failure, () =>
		makeInternalAcceptanceEditRequest(objectId, internalAcceptanceId, fields));
