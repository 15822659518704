import React from 'react';
import {LegendPopup} from '../legend';
import {SpaceTypeButtons} from '../typeFilter/SpaceTypeButtons';
import classNames from 'classnames';
import SingleSchema from './SingleSchema';
import {Plate} from '@tehzor/ui-components';
import {useVisibleObjects} from '../../hooks/useVisibleObjects';
import {useSchemasLoad} from '../../hooks/useSchemasLoad';
import {DesktopSchemaViewTabs} from '../SchemaViewTabs.desktop';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {extractSpacesAsArrayWithFiltered} from '@src/store/modules/entities/spaces/selectors/schemas';
import {
	extractObject,
	extractTargetObjects
} from '@src/store/modules/dictionaries/objects/selectors';
import {extractSpaceTypesForObjectAsArray} from '@src/store/modules/dictionaries/spaceTypes/selectors';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {Table} from '../reportsTable/Table';

interface IDesktopSchemaProps {
	objectId: string;
}

const DesktopSchema = ({objectId}: IDesktopSchemaProps) => {
	useSchemasLoad(objectId);

	const objects: string[] = useVisibleObjects(objectId);
	const targetObjects = useAppSelector(s => extractTargetObjects(s, objectId));
	const object = useAppSelector(s => extractObject(s, objectId));
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const sharedSettings = useAppSelector(s => s.settings.pages.spaces.shared);
	const data = useAppSelector(s =>
		extractSpacesAsArrayWithFiltered(s, targetObjects, object, pageSettings, sharedSettings));

	const typeIds = Object.values(data).reduce(
		(acc: {spaces: string[], filtered: string[]}, el) => {
			el.filtered.forEach(space => {
				acc.filtered.push(space.type);
			});
			el.spaces.forEach(space => {
				acc.spaces.push(space.type);
			});
			return acc;
		},
		{spaces: [], filtered: []}
	);

	const spaceTypes = useAppSelector(s => extractSpaceTypesForObjectAsArray(s, targetObjects));
	const types: Array<[ISpaceType, {spaces: number, filtred: number}]> = spaceTypes.map(type => [
		type,
		{
			spaces: typeIds.spaces.filter(el => el === type.id).length,
			filtred: typeIds.filtered.filter(el => el === type.id).length
		}
	]);

	const isReportsView = pageSettings.schemaView === SpacesSchemaVariants.REPORTS;

	return (
		<Plate
			className="spaces-page__plate"
			withoutPadding
		>
			<div className="spaces-page__plate-header">
				<DesktopSchemaViewTabs objectId={objectId}/>

				{!isReportsView && (
					<div className="spaces-page__plate-header-actions">
						<LegendPopup/>
					</div>
				)}
			</div>

			{!isReportsView && (
				<div className="spaces-page__plate-content">
					<SpaceTypeButtons
						objectId={objectId}
						types={types}
					/>
					<div
						className={classNames(
							'spaces-page__schema-list',
							// TODO Заменить на реальное кол-во столбцов
							{'spaces-page__schema-list_single-col': objects.length === 1}
						)}
					>
						{objects.map(id => (
							<SingleSchema
								key={id}
								objectId={id}
								isTitleClickable={id !== objectId}
								spaces={data[id]?.spaces.filter(
									space => space.type === pageSettings.type
								)}
								filtered={data[id]?.filtered.filter(
									space => space.type === pageSettings.type
								)}
							/>
						))}
					</div>
				</div>
			)}
			{isReportsView && <Table objectId={objectId}/>}
		</Plate>
	);
};

export default DesktopSchema;
