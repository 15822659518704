import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeResponsibilityRuleDeleteRequest} from '@src/api/backend/responsibilityRules/delete';

export interface IDeleteResponsibilityRulePayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => {
	addSuccessToast('Удалено', 'Правило успешно удалено');
	return {
		type: types.DELETE_SUCCESS,
		payload: {id}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении правила');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет правило ответственности
 *
 * @param ruleId id рабочей группы
 */
export const deleteResponsibilityRule = (ruleId: string) =>
	createApiAction(
		request,
		() => success(ruleId),
		failure,
		() => makeResponsibilityRuleDeleteRequest(ruleId)
	);
