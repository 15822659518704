import {wsConnector} from '../wsConnector';

export interface IMigrateCategoriesSetResponse {
	success: boolean;
}

export interface IMigrateCategoriesSetData {
	fromSetId: string;
	toSetId: string;
	matches: Array<{ fromId: string, toId: string }>;
}

export const requestMigrateCategoriesSet = (migrateCategoriesSetData: IMigrateCategoriesSetData) =>
	wsConnector.sendAuthorizedRequest<IMigrateCategoriesSetResponse>('migrateCategoriesSet', {
		...migrateCategoriesSetData
	});
