import IUser from '@tehzor/tools/interfaces/IUser';
import {getPermissionsForWorkAcceptance} from './getPermissionsForWorkAcceptance';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {IPreparedWorkAcceptance} from '../interfaces/IPreparedWorkAcceptance';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';

/**
 * Преобразовывает приёмку работ в необходимый для вывода формат
 *
 * @param workAcceptances приёмки работ
 * @param statusesMap статусы приёмок работ
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 */
export const convertWorkAcceptances = (
	workAcceptances: IListWorkAcceptance[],
	statusesMap: Record<string, IWorkAcceptanceStatus>,
	networkStatus: boolean,
	user?: IUser
): IPreparedWorkAcceptance[] =>
	workAcceptances.map(item => ({
		id: item.id,
		object: item.object,
		number: item.number,
		status: statusesMap[item.status]?.name || '',
		createdAt: item.createdAt
			? format(new Date(item.createdAt), dateTimeCommaSeparatedFormat)
			: '',
		modifiedAt: item.modifiedAt
			? format(new Date(item.modifiedAt), dateTimeCommaSeparatedFormat)
			: '',
		...getPermissionsForWorkAcceptance(item, networkStatus, item.object?.id, user)
	}));
