import {IState} from '@src/store/modules';
import * as types from '../constants';
import {getObjects} from '@src/store/modules/dictionaries/objects/actions';
import {getProfile} from '@src/store/modules/auth/profile/actions';
import {getCompanies} from '@src/store/modules/dictionaries/companies/actions';
import {AppThunkAction, AppThunkDispatch} from '@src/store/interfaces/thunk';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {getInitialResources} from '@src/components/ResourcesLoader/utils/getInitialResources';
import {loaderSplit} from '@src/components/ResourcesLoader/utils/loaderSplit';
import {invalidateDictionariesCache} from './invalidateDictionariesCache';
import {updateLegalEntities} from '@src/core/hooks/queries/legals';

/**
 * Сбрасывает стор и вызывает методы на получение данных
 */
export const update =
	(): AppThunkAction<unknown> => async (dispatch: AppThunkDispatch, getState: () => IState) => {
		dispatch({
			type: types.TOGGLE_UPDATE_STATUS,
			payload: true
		});
		await invalidateDictionariesCache();
		dispatch({type: types.CLEAR_BEFORE_UPDATE});
		await dispatch(getProfile());

		const state = getState();
		const roles = extractUserRoles(state);

		if (!roles) {
			return;
		}

		await dispatch(getCompanies());
		await dispatch(getObjects());

		const resources = getInitialResources(roles);
		const promises = resources.map(resource => dispatch(resource() as () => Promise<unknown>));
		await loaderSplit(promises);

		await updateLegalEntities();

		dispatch({
			type: types.TOGGLE_UPDATE_STATUS,
			payload: false
		});
	};
