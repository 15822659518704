import React, {createContext, useCallback, useMemo} from 'react';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {
	EntitiesTable,
	Pagination,
	PaginationAndSize,
	PaginationPageSize,
	Plate
} from '@tehzor/ui-components';
import {
	extractChecksAsArray,
	extractChecksData
} from '@src/store/modules/entities/checks/selectors';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import {changePageSize, changeSort} from '@src/store/modules/settings/pages/checks/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {desktopColumns, desktopColumnsWithoutObject} from './desktopColumns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertChecks} from '../../utils/convertChecks';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IPreparedCheck} from '../../interfaces/IPreparedCheck';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {mobileColumns, mobileColumnsWithoutObject} from './mobileColumns';
import {
	changeSelectedRows,
	getChecks,
	changeOffset
} from '@src/store/modules/entities/checks/actions';
import ChecksFilters from '../ChecksFilters';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {deleteCheck} from '@src/store/modules/entities/check/actions';
import ChecksPageCounter from '../ChecksPageCounter';
import {useLocalChecks} from '@src/core/hooks/queries/checks/useGetChecks';
import {useChangePath} from '@src/core/hooks/useChangePath';

const pageSizes = [10, 20, 50, 100];

interface IChecksTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedCheck>) => void
>(() => ({}));

const ChecksTable = ({objectId = 'all'}: IChecksTableProps) => {
	const {pushPath} = useChangePath();
	const checks = useAppSelector(s => extractChecksAsArray(s, objectId));
	const localChecks = useLocalChecks(objectId);
	const checksData = useAppSelector(s => extractChecksData(s, objectId));
	const pageSettings = useAppSelector(s => extractChecksPageSettings(s, objectId));
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	const pagesCount = Math.ceil(checksData.total / pageSettings.pageSize);
	const currentPage = Math.floor(checksData.offset / pageSettings.pageSize);

	const preparedChecks = useMemo(
		() => convertChecks([...localChecks, ...checks], networkStatus, user),
		[checks, localChecks, networkStatus, user]
	);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить проверку?',
		' ',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, loadChecks] = useAsyncFn(() => dispatch(getChecks(objectId)), [objectId]);

	const handleRowClick = useCallback((check: IPreparedCheck) => {
		if (check.object && !check.localNumber) {
			pushPath(`/objects/${check.object.id}/checks/${check.id}`);
		}
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows(objectId, value)),
		[objectId]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort(objectId, value));
			void loadChecks();
		},
		[objectId, loadChecks]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(objectId, value));
			dispatch(changeOffset(objectId, Math.floor(checksData.offset / value)));
			void loadChecks();
		},
		[objectId, checksData.offset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (checksData.offset !== offset) {
				dispatch(changeOffset(objectId, offset));
				void loadChecks();
			}
		},
		[objectId, checksData.offset, pageSettings.pageSize]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedCheck>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await dispatch(deleteCheck(action.payload.object.id, action.payload.id));
					await loadChecks();
				}
			}
		},
		[objectId]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		desktopColumns,
		desktopColumnsWithoutObject
	);

	return (
		<>
			<div className="checks-page__filters-bar">
				<ChecksFilters objectId={objectId}/>
			</div>
			<ChecksPageCounter objectId={objectId}/>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={preparedChecks}
						selectedRows={checksData.selectedRows}
						sort={pageSettings.sort}
						selectable
						responsive={!isLargeTablet}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>

			{deleteDialog}
		</>
	);
};

export default ChecksTable;
