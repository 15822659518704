import React, {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton, Select2, SelectOption} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import SelectSearch, {
	flatFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

export interface IStatusesFilterPageProps {
	objectId?: string;
	entity?: string;
	filterName?: string;
	label?: string;
	statuses: Array<{id: string, name: string}>;
	resetOnApply?: string[];
}

const StatusesFilterPage = ({
	objectId,
	entity,
	filterName = 'statuses',
	label = 'Статус',
	statuses,
	resetOnApply
}: IStatusesFilterPageProps) => {
	const {goBack} = useChangePath();
	const {state, change} = useEntitiesFiltersCtx<{statuses?: string[]}>();
	const [selected, setSelected] = useState<string[] | undefined>(state[filterName] as string[]);
	const [search, setSearch] = useState('');

	const isSearchShown = useMemo(() => statuses.length >= 8, [statuses]);
	const filteredData = useMemo(() => flatFilter(statuses, 'name', search), [statuses, search]);

	const location = useLocation();
	const {canGoBack} = location.state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		const changes = {[filterName]: selected && selected?.length > 0 ? selected : undefined};
		resetOnApply?.forEach(el => {
			changes[el] = undefined;
		});
		change(changes);
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: label,
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label="Сбросить"
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={isLocalStateEqual(state[filterName], selected)}
			onApplyClick={handleApply}
			search={isSearchShown && (
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
		>
			<Select2
				multiple
				value={selected}
				onChange={setSelected}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</FilterPage>
	);
};

export default StatusesFilterPage;
