import React, {Dispatch, useCallback, useState} from 'react';
import './AddingWorkingGroupPerformerDialog.less';
import {ActionButtons, Button, Dialog, Select2, SelectOption} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {extractCompanyWithContractorsUsersAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editWorkingGroup} from '@src/store/modules/dictionaries/workingGroups/actions';
import {addSuccessToast} from '@src/utils/toasts';

interface IAddingWorkingGroupPerformerDialogProps {
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	isOpen: boolean;
	onClose: () => void;
	workingGroupId?: string;
}

/**
 * Окно добавления нового исполнителя
 */
export const AddingWorkingGroupPerformerDialog = (props: IAddingWorkingGroupPerformerDialogProps) => {
	const {editingState, editingDispatch, isOpen, onClose, workingGroupId} = props;
	const [selected, setSelected] = useState<string[] | undefined>(editingState.performers);
	const users = useAppSelector(s =>
		extractCompanyWithContractorsUsersAsArray(s, editingState.companyId));
	const dispatch = useAppDispatch();
	const [, handleSave] = useAsyncFn(async () => {
		if (selected) {
			editingDispatch({
				type: 'update',
				field: 'performers',
				value: [...selected]
			});
			if (workingGroupId) {
				await dispatch(editWorkingGroup(workingGroupId, {performers: selected}));
				addSuccessToast('Успешно', 'Добавлен новый исполнитель');
			}
		}

		onClose();
	}, [onClose, selected, editingState, workingGroupId]);

	const handleCancel = useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Dialog
			className={{
				root: 'adding-performer-dialog',
				title: 'adding-performer-dialog__title',
				body: 'adding-performer-dialog__body'
			}}
			isOpen={isOpen}
			title="Выберите нового участника группы"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			<Select2
				className="adding-performer-dialog__performer-select"
				multiple
				value={selected}
				onChange={setSelected}
			>
				{users
					.filter(user => !editingState.performers?.includes(user.id))
					.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.fullName}
						/>
					))}
			</Select2>
		</Dialog>
	);
};
