import AddingInspection, {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import React, {ReactNode, Ref, Dispatch, SetStateAction} from 'react';
import {useSpaceLocation} from './useSpaceLocation';
import EntityTypeSelect from '../components/EntityTypeSelect';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/selectors';
import {extractLatestInternalAcceptancesAsArray} from '@src/store/modules/dictionaries/latestUserInternalAcceptances/selectors';
import {useLatestInternalAcceptances} from './useLatestInternalAcceptances';

interface IUseInternalAcceptanceMaps {
	objectId: string;
	internalAcceptanceId?: string;
	stage: ObjectStageIds;
	links?: IInternalAcceptance['links'];
	availableTypes: IInternalAcceptanceAddingEntityType[];
	type?: IInternalAcceptanceAddingEntityType;
	addingEntityRef: Ref<IAddingProblemRefProps | IAddingInspectionRefProps>;
	problemToCopyId?: string;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedInternalAcceptanceId?: string;
	setSelectedInternalAcceptanceId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;

	onClose: () => void;
}

export const useInternalAcceptanceMaps = (
	props: IUseInternalAcceptanceMaps
): [Record<ObjectStageIds, ReactNode>, Record<ObjectStageIds, IEntitySelectProps>] => {
	const {
		objectId,
		internalAcceptanceId,
		stage,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedInternalAcceptanceId,
		setSelectedInternalAcceptanceId,
		onClose
	} = props;

	const contentMap = {} as Record<ObjectStageIds, ReactNode>;
	const entitiesSelectMapProps = {} as Record<ObjectStageIds, IEntitySelectProps>;

	const [defaultProblemData, defaultInspectionData] = useSpaceLocation(
		objectId,
		selectedInternalAcceptanceId,
		props.defaultProblemData,
		props.defaultInspectionData
	);

	const latestInternalAcceptances = useAppSelector(s =>
		extractLatestInternalAcceptancesAsArray(s, objectId, links?.spaceId)
	);

	useLatestInternalAcceptances(
		objectId,
		setSelectedInternalAcceptanceId,
		selectedInternalAcceptanceId,
		links
	);

	contentMap[stage] = (
		<>
			<div className="adding-internal-acceptance-dialog__title">
				{type !== undefined && (
					<EntityTypeSelect
						type={type}
						types={availableTypes}
						disabled={saving}
					/>
				)}
			</div>
			{type === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={links}
					stage={stage}
					defaultData={problemToCopyId ? props.defaultProblemData : defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
					problemToCopyId={problemToCopyId}
				/>
			) : type === 'inspection' ? (
				<AddingInspection
					objectId={objectId}
					links={links}
					stage={stage}
					defaultData={defaultInspectionData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	const internalAcceptance = useAppSelector(s =>
		internalAcceptanceId ? extractInternalAcceptance(s) : undefined
	);

	entitiesSelectMapProps[stage] = {
		name: 'Внутренняя приемка',
		newName: 'Новая внутренняя приемка',
		selected: selectedInternalAcceptanceId,
		onEntityChange: setSelectedInternalAcceptanceId,
		withoutNew: !!internalAcceptance,
		entities: internalAcceptance ? [internalAcceptance] : latestInternalAcceptances,
		localEntities: []
	};

	return [contentMap, entitiesSelectMapProps];
};
