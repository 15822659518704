import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useAppLocation} from '@src/core/hooks/useAppLocation';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IContractsFiltersState} from '@src/store/modules/settings/pages/contracts/reducers';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {DatePickerResult, FilterPage, InlineDateRangePicker, LinkButton} from '@tehzor/ui-components';
import React, {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';

export const SigningDateFilterPage = () => {
	const {entity = 'contracts'} = useParams<{entity?: string}>();
	const location = useAppLocation();
	const {goBack} = useChangePath();

	const {state, change} = useEntitiesFiltersCtx<IContractsFiltersState>();
	const [signingDateFrom, setSigningDateFrom] = useState(
		state.signingDateFrom
	);
	const [signingDateTo, setSigningDateTo] = useState(
		state.signingDateTo
	);

	const handleApply = useCallback(() => {
		change({signingDateFrom, signingDateTo});
		goBack();
	}, [signingDateFrom, signingDateTo]);

	const handleChange = useCallback((f: Date | null, t: Date | null) => {
		if (f) {
			setSigningDateFrom(
				new Date(f.getFullYear(), f.getMonth(), f.getDate())
			);
		}
		if (t) {
			setSigningDateTo(
				new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
			);
		}
	}, []);

	const handleClear = useCallback(() => {
		setSigningDateFrom(undefined);
		setSigningDateTo(undefined);
	}, []);

	useAppHeader(
		{
			title: 'Дата заключения',
			showBackBtn: true,
			mobileRightButtons:
				signingDateFrom && signingDateTo ? (
					<LinkButton
						label="Сбросить"
						onClick={handleClear}
					/>
				) : null
		},
		[signingDateFrom, signingDateTo]
	);

	useRouting(!location.state?.canGoBack, undefined, entity);

	return (
		<FilterPage
			applyDisabled={
				isLocalStateEqual(state.signingDateFrom, signingDateFrom)
				&& isLocalStateEqual(state.signingDateTo, signingDateTo)
			}
			onApplyClick={handleApply}
		>
			<DatePickerResult
				style={{marginTop: '20px'}}
				value1={signingDateFrom}
				value2={signingDateTo}
				placeholder1="Дата начала"
				placeholder2="Дата окончания"
				isRange
				dateFormat={dateFormat}
			/>
			<InlineDateRangePicker
				style={{marginTop: '18px'}}
				valueFrom={signingDateFrom}
				valueTo={signingDateTo}
				onChange={handleChange}
			/>
		</FilterPage>
	);
};