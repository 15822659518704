import React from 'react';
import './StructurePage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {extractStructure} from '@src/store/modules/entities/structure/selectors';
import {
	getStructure,
	getStructureInspections,
	getStructureProblems
} from '@src/store/modules/entities/structure/actions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from './components/Desktop';
import {Mobile} from './components/Mobile';
import {getObject} from '@src/store/modules/entities/object/actions';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import {getCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import {getCheckRecords, getProblems} from '@src/store/modules/pages/checkLists/actions';
import {getWorkAcceptances} from '@src/store/modules/entities/workAcceptances/actions';
import {extractWorkAcceptancesForStructureAsArray} from '@src/store/modules/entities/workAcceptances/selectors';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const StructurePage = () => {
	useScrollRestoration();

	const {objectId, structureId} = useStrictParams<{objectId: string, structureId: string}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const structure = useAppSelector(extractStructure);
	const checkLists = useAvailableCheckLists(
		objectId,
		ObjectStageIds.BUILDING,
		CheckListTypeId.WORK_ACCEPTANCES,
		structure && [structure.type]
	);

	useAsync(async () => {
		await dispatch(getStructure(objectId, structureId));
		await Promise.all<unknown>([
			dispatch(getObject(objectId)),
			dispatch(getCheckLists()),
			dispatch(getCheckItems()),
			dispatch(getStructureProblems(objectId, structureId)),
			dispatch(getStructureInspections(objectId, structureId)),
			dispatch(getWorkAcceptances(objectId, {structureIds: [structureId]}))
		]);
	}, []);

	const workAcceptances: IWorkAcceptance[] = useAppSelector(s =>
		extractWorkAcceptancesForStructureAsArray(s, objectId, structure?.id));
	const workAcceptancesIds = workAcceptances.map(workAcceptance => workAcceptance.id);

	useAsync(async () => {
		if (checkLists.length) {
			const ids = checkLists.map(l => l.id);
			await dispatch(getProblems(ids, undefined, workAcceptancesIds));
			await dispatch(getCheckRecords(ids, [objectId], undefined, workAcceptancesIds));
		}
	}, [objectId, structure, checkLists]);

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				structureId={structureId}
				structure={structure}
				checkLists={checkLists}
				workAcceptances={workAcceptances}
			/>
		);
	}

	return (
		<Mobile
			objectId={objectId}
			structureId={structureId}
			structure={structure}
			checkLists={checkLists}
			workAcceptances={workAcceptances}
		/>
	);
};
