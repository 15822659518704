import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import React, {ReactNode, useCallback, useState} from 'react';
import useAppDispatch from '../useAppDispatch';
import {exportWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions/export';

const availableDestinations = [ExportTemplatesDestinationId.WORK_ACCEPTANCE];

export const useWorkAcceptanceExport = (
	objectId: string,
	checkLists?: string[],
	problems?: string[]
): [ReactNode, (id: string) => void] => {
	const [exportingId, setExportingId] = useState<string>();
	const [isOpen, setOpen] = useState(false);
	const dispatch = useAppDispatch();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (exportingId) {
				await dispatch(
					exportWorkAcceptance(
						template.id,
						createDocument,
						email,
						objectId,
						exportingId,
						checkLists,
						problems
					)
				);
			}
			setOpen(false);
		},
		[objectId, exportingId, checkLists, problems]
	);

	const dialog = (
		<ExportDialog
			pageKey="workAcceptance"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
};
