import {flatFilter} from './flatFilter';

interface ITreeFilterReturn<T> {
	filteredData: T[];
	expanded: T[];
}

export const treeFilter = <T extends {id: string, parentId?: string}> (
	treeData: T[],
	field: string,
	search: string
): ITreeFilterReturn<T> => {
	if (search === '') {
		return {
			filteredData: treeData,
			expanded: []
		};
	}

	const filteredTreeData = [...new Set(flatFilter(treeData, field, search))];

	const findDescendants = (treeItem: T, treeData: T[]): T[] => {
		const descendant = treeData.find(item => item.id === treeItem.parentId);
		if (descendant) {
			return [descendant, ...findDescendants(descendant, treeData)];
		}
		return [];
	};

	const findAncestors = (treeItems: T[], treeData: T[]): T[] => {
		const ancestors = treeData.filter(item => treeItems.find(treeItem => treeItem.id === item.parentId));
		if (ancestors.length) {
			return [...ancestors, ...findAncestors(ancestors, treeData)];
		}
		return [];
	};

	const returnData: ITreeFilterReturn<T> = {filteredData: [], expanded: []};
	for (const treeItem of filteredTreeData) {
		const descendants = findDescendants(treeItem, treeData);
		const ancestors = findAncestors([treeItem], treeData);

		returnData.expanded.push(...descendants);
		returnData.filteredData.push(treeItem, ...ancestors, ...descendants);
	}

	return {
		filteredData: [...new Set(returnData.filteredData)],
		expanded: [...new Set(returnData.expanded)]
	};
};