/* eslint-disable @typescript-eslint/ban-ts-comment */
import {AnyAction, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import {IState, rootReducer} from './modules';
// @ts-ignore
import backendMiddleware from '@src/middlewares/backendMiddleware';
import apiMiddleware from './middlewares/api';
import loadingStatusMiddleware from './middlewares/requestsTracker';
import offlineModeMiddleware from './middlewares/offlineMode';
import thunk from 'redux-thunk';
import {formPersistConfig} from './persistentStorage/formPersistConfig';

const middleware = [
	thunk,
	apiMiddleware(),
	backendMiddleware(),
	loadingStatusMiddleware(),
	offlineModeMiddleware()
];

if (process.env.NODE_ENV === 'development') {
	// eslint-disable-next-line max-len
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires,global-require,import/no-extraneous-dependencies
	const {createLogger} = require('redux-logger');
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	middleware.push(createLogger({collapsed: true}));
}

// const migrations = {
// 	// 1.3.11
// 	// 2: (state: any) => ({
// 	// 	...state,
// 	// 	settings: {
// 	// 		...state.settings,
// 	// 		sidebar: {
// 	// 			isOpen: false,
// 	// 			isObjectsOpen: true
// 	// 		}
// 	// 	}
// 	// }),
// 	// 1.4.0
// 	// 3: (state: any) => ({
// 	// 	...state,
// 	// 	settings: {
// 	// 		...state.settings,
// 	// 		sidebar: {
// 	// 			isOpen: state.settings.isVisible,
// 	// 			itemsStates: {objects: !!state.settings.isObjectsOpen}
// 	// 		}
// 	// 	}
// 	// }),
// 	// 1.4.1
// 	// 4: (state: any) => ({
// 	// 	...state,
// 	// 	settings: {
// 	// 		...state.settings,
// 	// 		objectsPage: {
// 	// 			filter: {
// 	// 				objectName: '',
// 	// 				companies: []
// 	// 			},
// 	// 			groupsVisibilities: {}
// 	// 		}
// 	// 	}
// 	// }),
// 	// 1.4.10
// 	// 5: (state: any) => ({
// 	// 	...state,
// 	// 	settings: {
// 	// 		...state.settings,
// 	// 		locationSelect: {}
// 	// 	}
// 	// }),
// 	// 1.5.4
// 	6: (state: any) => ({
// 		...state,
// 		settings: {
// 			...state.settings,
// 			pages: {
// 				...state.settings.pages,
// 				check: {
// 					entities: {visibility: ['problems', 'inspections']}
// 				}
// 			}
// 		}
// 	}),
// 	// 1.5.8
// 	7: (state: any) => ({
// 		...state,
// 		settings: {
// 			...state.settings,
// 			pages: {
// 				...state.settings.pages,
// 				statistics: {
// 					...state.settings.pages.statistics,
// 					building: {
// 						...state.settings.pages.statistics.building,
// 						tabIndex: 0
// 					},
// 					acceptance: {
// 						...state.settings.pages.statistics.acceptance,
// 						tabIndex: 0
// 					}
// 				}
// 			}
// 		}
// 	})
// };

const persistedReducer = persistReducer(
	formPersistConfig<IState>('root', {whitelist: []}),
	rootReducer
);

export const store = configureStore<IState, AnyAction, typeof middleware>({
	reducer: persistedReducer,
	middleware,
	devTools: process.env.NODE_ENV === 'development',
	preloadedState: {}
});
export const persistor = persistStore(store);

// // @ts-ignore
// if (module.hot) {
// 	// @ts-ignore
// 	module.hot.accept('./modules', () => {
// 		// eslint-disable-next-line global-require
// 		const nextRootReducer = require('./modules').default;
// 		store.replaceReducer(persistReducer(formPersistConfig<IState>('root'), nextRootReducer));
// 	});
// }

export type AppDispatch = typeof store.dispatch;

export type AppStore = typeof store;
