import * as types from '../constants';
import {CacheStatus} from '@tehzor/tools/enums/CacheStatus';
import {IOfflineDataCachingStatuses} from '@tehzor/tools/contracts/dataCachingWebWorker/interfaces/IOfflineDataCachingStatuses';

/**
 * Изменяет статус кеширования ресурсов
 *
 * @param value статус сохранения
 */
export const changeCacheStatus = (value: CacheStatus) => ({
	type: types.CHANGE_CACHE_STATUS,
	payload: value
});

/**
 * Изменяет состояние кеширования ресурсов определённого типа справочников
 *
 * @param statuses состояние кеширования справочников
 */
export const changeDictionariesCacheState = (statuses: IOfflineDataCachingStatuses['dictionaries']) => ({
	type: types.CHANGE_DICTIONARIES_CACHE_STATE,
	payload: statuses
});
/**
 * Изменяет прогресс кеширования справочника
 *
 * @param entity сущность
 * @param value значение прогресса в %
 */
export const changeDictionariesCacheProgress = (entity: string, value: number) => ({
	type: types.CHANGE_DICTIONARIES_CACHE_PROGRESS,
	payload: {entity, value}
});
/**
 * Изменяет состояние кеширования ресурсов определённого типа сущности
 *
 * @param statuses состояние кеширования справочников
 */
export const changeEntitiesCacheState = (statuses: IOfflineDataCachingStatuses['entities']) => ({
	type: types.CHANGE_ENTITIES_CACHE_STATE,
	payload: statuses
});

/**
 * Изменяет прогресс кеширования сущности
 *
 * @param entity сущность
 * @param value значение прогресса в %
 */
export const changeEntitiesCacheProgress = (entity: string, value: number) => ({
	type: types.CHANGE_ENTITIES_CACHE_PROGRESS,
	payload: {entity, value}
});
