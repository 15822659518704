import React, {useMemo} from 'react';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {EntityGridItem} from '@tehzor/ui-components';
import './Category.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCategoriesAsArrayBySetId, extractCategoryById} from '@src/store/modules/dictionaries/categories/selectors';
import classNames from 'classnames';

interface ICategoryProps {
	categoryId: string;
	fullRow?: boolean;
}

const findDescendants = (category: ICategory, treeData: ICategory[]): ICategory[] => {
	const descendant = treeData.find(item => item.id === category.parentId);
	if (descendant) {
		return [...findDescendants(descendant, treeData), descendant];
	}
	return [];
};

const markIcon = <i className="tz-mark-2-16"/>;

export const Category = ({categoryId, fullRow}: ICategoryProps) => {
	const category = useAppSelector(s => extractCategoryById(s, categoryId));
	const categories = useAppSelector(s => extractCategoriesAsArrayBySetId(s, category?.categoriesSetId));

	const chainedCategories = useMemo(() =>
		(category && categories ? [...findDescendants(category, categories), category] : []),
		[categories, category]);

	return chainedCategories.length > 1 ? (
		<EntityGridItem
			icon={<i className="tz-category-20"/>}
			label="Вид работ"
			className="category-view-field"
			fullRow={fullRow}
		>
			<div className="category-view-field__chained">
				<div className="category-view-field__chained-line"/>
				<div className="category-view-field__chained-items">
					{chainedCategories.map(item => (
						<div
							className={classNames(
								'category-view-field__chained-items__item',
								{
									first: !item.parentId,
									last: categoryId === item.id
								}
							)}
						>
							<div className="category-view-field__chained-items__item-dot">
								{categoryId === item.id && markIcon}
							</div>
							<div
								className="category-view-field__chained-items__item-content"
								key={item.id}
							>
								{item.name}
							</div>
						</div>
					))}
				</div>
			</div>
		</EntityGridItem>
	) : (
		<EntityGridItem
			icon={<i className="tz-category-20"/>}
			label="Вид работ"
			className="category-view-field"
			fullRow={fullRow}
		>
			{category && (
				<div className="category-view-field__single">
					{category.name}
				</div>
			)}

		</EntityGridItem>
	);
};
