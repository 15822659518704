import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as statusesTypes from '../spaceStatuses/constants';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting,
	handleLoadableEntityAdding,
	handleLoadableEntityEditing,
	handleLoadableEntityDeletion,
	handleLoadableEntityReset
} from '@tehzor/tools/core/storeHelpers/reducers';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type ISpaceStatusesSetsState = ILoadableEntitiesState<ISpaceStatusesSet>;

export const spaceStatusesSets = createReducer<ISpaceStatusesSetsState>(
	getLoadableEntitiesInitialState<ISpaceStatusesSet>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ISpaceStatusesSet>(),
		[types.ADD_SUCCESS]: handleLoadableEntityAdding<ISpaceStatusesSet>(),
		[types.EDIT_SUCCESS]: handleLoadableEntityEditing<ISpaceStatusesSet>(),
		[statusesTypes.ADD_SUCCESS]: handleLoadableEntityReset<ISpaceStatusesSet>(),
		[statusesTypes.EDIT_SUCCESS]: handleLoadableEntityReset<ISpaceStatusesSet>(),
		[statusesTypes.DELETE_SUCCESS]: handleLoadableEntityReset<ISpaceStatusesSet>(),
		[statusesTypes.DELETE_MANY_SUCCESS]: handleLoadableEntityReset<ISpaceStatusesSet>(),
		[types.DELETE_SUCCESS]: handleLoadableEntityDeletion<ISpaceStatusesSet>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ISpaceStatusesSet>(),
		[CLEAR_BEFORE_UPDATE]: state => ({...state, loaded: false})
	}
);
