import React from 'react';
import {Provider} from 'react-redux';
import {RouterProvider} from 'react-router-dom';
import {PersistGate} from 'redux-persist/es/integration/react';
import {Persistor} from 'redux-persist/es/types';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client';
import {queryClient} from '@src/api/QueryClient';
import {AnyAction, Store} from 'redux';
import {ErrorBoundary} from '@tehzor/ui-components';
import {persistOptions} from '@src/store/persistentStorage/queryClientPersistOptions';
import {resumeMutations} from '@src/store/persistentStorage/queryClientResumeMutations';
import {router} from '@src/core/routes';

export interface IRootProps {
	store: Store<any, AnyAction>;
	persistor: Persistor;
}

const Root = ({store, persistor}: IRootProps) => (
	<PersistGate
		loading={null}
		persistor={persistor}
	>
		<ErrorBoundary label="Root">
			<PersistQueryClientProvider
				client={queryClient}
				persistOptions={persistOptions}
				onSuccess={resumeMutations}
			>
				<Provider store={store}>
					<React.StrictMode>
						<RouterProvider router={router} />
					</React.StrictMode>
				</Provider>
				<ReactQueryDevtools />
			</PersistQueryClientProvider>
		</ErrorBoundary>
	</PersistGate>
);

export default Root;
