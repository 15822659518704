import React from 'react';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckProblems} from '@src/store/modules/entities/check/selectors';
import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import MobileCheckExport from './CheckExport.mobile';
import MobileMenu from './Menu.mobile';

interface IMobileActionsProps {
	objectId: string;
	check: ILinkedCheck;
}

const ActionsMobile = ({objectId, check}: IMobileActionsProps) => {
	const problems = useAppSelector(extractCheckProblems);
	const permissions = useCheckPermissions(objectId, check, problems);

	return (
		<>
			{permissions.canExport && (
				<MobileCheckExport
					objectId={objectId}
					checkId={check.id}
				/>
)}

			<MobileMenu
				objectId={objectId}
				check={check}
				permissions={permissions}
			/>
		</>
	);
};

export default ActionsMobile;
