import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeStructureDeleteRequest} from '@src/api/backend/structure/delete';

export interface IDeleteStructurePayload {
	objectId: string;
	structureId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, structureId: string) => {
	addInfoToast('Удалено', 'Структура успешно удалена');
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			structureId
		} as IDeleteStructurePayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении структуры');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет структуру
 *
 * @param objectId id объекта
 * @param structureId id структуры
 */
export const deleteStructure = (objectId: string, structureId: string) =>
	createApiAction(
		request,
		() => success(objectId, structureId),
		failure,
		() => makeStructureDeleteRequest(objectId, structureId)
	);
