import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type ICheckRecordStatusesState = ILoadableEntitiesState<ICheckRecordStatus>;

export const checkRecordStatuses = createReducer<ICheckRecordStatusesState>(
	getLoadableEntitiesInitialState<ICheckRecordStatus>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ICheckRecordStatus>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ICheckRecordStatus>()
	}
);
