import React from 'react';
import {EditorInfo, EntityGrid, EntityGridItem, InlineButton, Plate} from '@tehzor/ui-components';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import Documents from './Documents';
import {useEditableCheckAttachmentsDialog} from '@src/components/EditableCheckAttachmentsDialog/hooks/useEditableCheckAttachmentsDialog';
import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import DescriptionDesktop from './Description.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import DescriptionMobile from './Description.mobile';
import {MobileEntityDocs} from '@src/components/EntityDocs';

interface IDesktopInfoProps {
	objectId: string;
	check: ILinkedCheck;
}

const Info = ({objectId, check}: IDesktopInfoProps) => {
	// Диалог редактирования вложений
	const [editingAttachmentsDialog, openEditAttachmentsDialog] = useEditableCheckAttachmentsDialog(
		objectId,
		check.id,
		check
	);
	const permissions = useCheckPermissions(objectId, check);
	const isDesktop = useIsDesktop();
	return (
		<Plate>
			<EntityGrid withBorders>
				{isDesktop ? (
					<DescriptionDesktop
						objectId={objectId}
						checkId={check.id}
						value={check.description}
						canEdit={permissions.canEdit}
					/>
				) : (
					<DescriptionMobile
						objectId={objectId}
						checkId={check.id}
						value={check.description}
						canEdit={permissions.canEdit}
					/>
				)}

				<EntityGridItem
					label="Документы"
					icon={<i className="tz-document-20" />}
					fullRow
					buttons={
						permissions.canEdit ? (
							<InlineButton
								className="check-page__documents-edit-icon"
								type="accent"
								leftIcon={<i className="tz-edit-16" />}
								onClick={openEditAttachmentsDialog}
							/>
						) : null
					}
				>
					<Documents check={check} />
				</EntityGridItem>
				<EditorInfo
					icon={<i className="tz-calendar-20" />}
					label="Создано"
					date={check.createdAt}
					user={check.createdBy}
					fullRow
				/>
				{check.createdAt !== check.modifiedAt && (
					<EditorInfo
						icon={<i className="tz-edit-20" />}
						label="Изменено"
						date={check.modifiedAt}
						user={check.modifiedBy}
						fullRow
					/>
				)}
				{isDesktop ? null : <MobileEntityDocs id={check.id} />}
			</EntityGrid>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default Info;
