import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import IObjectFieldsSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import useAppSelector from './useAppSelector';
import {extractObject, extractObjectsAsArray} from '@src/store/modules/dictionaries/objects/selectors';
import {useMemo} from 'react';

/**
 * Получает настройки из объектов по конкретным сущностям
 * 
 * @param objectId id объекта
 * @param objectFields массив названий полей настроек объекта
 */
export const useEntitySettings = (
	objectId: string, objectFields: Array<keyof IObjectFieldsSettings>
): Record<string, IObjectFieldsSetting> => {
	const objects = useAppSelector(s => (objectId !== 'all'
		? [extractObject(s, objectId)]
		: extractObjectsAsArray(s)));

	return useMemo(() => {
		const fieldsSettingsMap: Record<string, IObjectFieldsSetting> = {};

		for (const object of objects) {
			for (const objectField of objectFields) {
				const fieldsSettings = object.fieldsSettings?.[objectField];
				if (!fieldsSettings?.length) { continue; }

				for (const setting of fieldsSettings) {
					fieldsSettingsMap[setting.fieldId] = setting;
				}
			}
		}

		return fieldsSettingsMap;
	}, [objects]);
};