import {IDeleteSpaceStatusResponse} from '@src/api/backend/spaceStatuses/delete';
import {IDeleteSpaceStatusesSetResponse} from '@src/api/backend/spaceStatusesSets/delete';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {
	convertToSave,
	IEditableSpaceStatusesSetAction,
	IEditableSpaceStatusesSetOriginal,
	IEditableSpaceStatusesSetState
} from '@src/core/hooks/states/useSpaceStatusesSetState';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	addSpaceStatus,
	deleteManySpaceStatuses,
	deleteSpaceStatus,
	editSpaceStatus} from '@src/store/modules/dictionaries/spaceStatuses/actions';
import {
	addSpaceStatusesSet,
	deleteSpaceStatusesSet,
	editSpaceStatusesSet
} from '@src/store/modules/dictionaries/spaceStatusesSets/actions';
import {addErrorToast} from '@src/utils/toasts';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {Dispatch, useCallback} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface IUseUpdateContent {
	updateSpaceStatusesSet: () => Promise<ISpaceStatusesSet | undefined>;
	removeSpaceStatusesSet: (id: string) => Promise<IDeleteSpaceStatusesSetResponse | undefined>;
	updateSpaceStatus: (index: number) => Promise<ISpaceStatus | undefined>;
	removeSpaceStatus: (id: string) => Promise<IDeleteSpaceStatusResponse | undefined>;
}

export const useUpdateContent = (
	editingState?: IEditableSpaceStatusesSetState,
	editingDispatch?: Dispatch<IEditableSpaceStatusesSetAction>,
	defaultData?: IEditableSpaceStatusesSetOriginal
): IUseUpdateContent => {
	const {replacePath} = useChangePath();
	const dispatch = useAppDispatch();
	const {spaceStatusesSetId} = useStrictParams<{spaceStatusesSetId: string}>();

	const checkErrors = useCallback(
		(
			state: IEditableSpaceStatusesSetState,
			dispatch: Dispatch<IEditableSpaceStatusesSetAction>
		) => {
			const {name, companyId, objects, isLocal, defaultStatus, errors} = state;
			let result = false;

			if (!name) {
				dispatch({type: 'update-error', field: 'name'});
				result = true;
			}
			if (!companyId && !objects?.length) {
				dispatch({type: 'update-error', field: 'companyId'});
				dispatch({type: 'update-error', field: 'objects'});
				result = true;
			}
			if (isLocal && !defaultStatus) {
				dispatch({type: 'update-error', field: 'defaultStatus'});
				result = true;
			}
			if (errors) {
				for (const error of Object.values(errors)) {
					result = error ? true : result;
				}
			}

			return result;
		},
		[]
	);

	const updateSpaceStatusesSet = useCallback(async () => {
		if (!editingState || !editingDispatch || checkErrors(editingState, editingDispatch)) {
			return undefined;
		}

		const [fields] = convertToSave(editingState, defaultData);

		if (editingState.isLocal) {
			try {
				const data = await dispatch(addSpaceStatusesSet(fields));
				replacePath(`/manage/space-statuses-sets/${data?.id}`);

				return data;
			} catch (e) {
				addErrorToast(
					'Наборы статусов помещений',
					'Не удалось создать новый набор статусов помещений'
				);

				return undefined;
			}
		}

		try {
			const data = await dispatch(editSpaceStatusesSet(spaceStatusesSetId, fields));

			return data;
		} catch (e) {
			addErrorToast(
				'Наборы статусов помещений',
				'Не удалось изменить набор статусов помещений'
			);

			return undefined;
		}
	}, [editingState, defaultData, spaceStatusesSetId]);

	const removeSpaceStatusesSet = useCallback(async (id: string) => {
		try {
			const res = await dispatch(deleteSpaceStatusesSet(id));
			await dispatch(deleteManySpaceStatuses(id));

				return res;
			} catch (e) {
				addErrorToast(
					'Наборы статусов помещений',
					'Не удалось удалить набор статусов помещений'
				);

			return undefined;
		}
	}, []);

	const updateSpaceStatus = useCallback(
		async (index: number) => {
			if (
				!editingState
				|| !editingDispatch
				|| spaceStatusesSetId === 'empty'
				|| index === -1
			) {
				return undefined;
			}

			const {statuses: editingStatuses} = editingState;
			const fields = editingStatuses[index];

			if (fields.isLocal) {
				try {
					const data = await dispatch(addSpaceStatus(fields));

					return data;
				} catch (e) {
					addErrorToast(
						'Наборы статусов помещений',
						'Не удалось создать статус помещения'
					);

					return undefined;
				}
			}

			try {
				const data = await dispatch(editSpaceStatus(fields.id, fields));

				return data;
			} catch (e) {
				addErrorToast('Наборы статусов помещений', 'Не удалось изменить статус помещения');

				return undefined;
			}
		},
		[editingState, spaceStatusesSetId]
	);

	const removeSpaceStatus = useCallback(async (id: string) => {
		if (spaceStatusesSetId === 'empty' || id[0] === '_') {
			return undefined;
		}

		try {
			const res = await dispatch(deleteSpaceStatus(id, spaceStatusesSetId));

			return res;
		} catch (e) {
			addErrorToast('Наборы статусов помещений', 'Не удалось удалить статус помещения');

			return undefined;
		}
	}, [spaceStatusesSetId]);

	return {
		updateSpaceStatusesSet,
		removeSpaceStatusesSet,
		updateSpaceStatus,
		removeSpaceStatus
	};
};
