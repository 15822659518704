import React from 'react';
import './CheckListsEditPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {getCheckItems} from '@src/store/modules/dictionaries/checkItems/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {LoadingPanel} from '@tehzor/ui-components';
import {EditingCheckList} from '@src/components/EditingCheckList/EditingCheckList';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import {getCheckListTypes} from '@src/store/modules/entities/checkListTypes/actions';
import {getSpaceTypeDecorationSets} from '@src/store/modules/entities/spaceTypeDecorationSets/actions/get';
import {getSpaceTypeDecorationsAll} from '@src/store/modules/entities/spaceTypeDecorations/actions';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

export const CheckListsEditPage = () => {
	const {checkListId} = useStrictParams<{checkListId: string}>();

	useAppHeader({
		title: 'Редактирование чек-листа',
		mobileRightButtons: <AppUpdateMenu/>,
		showBackBtn: true
	});

	const dispatch = useAppDispatch();

	const loadingState = useAsync(async () => {
		await Promise.all([
			dispatch(getCheckLists()),
			dispatch(getCheckItems()),
			dispatch(getCheckListTypes()),
			dispatch(getCheckListTypes()),
			dispatch(getSpaceTypeDecorationSets()),
			dispatch(getSpaceTypeDecorationsAll())
		]);
	}, []);

	return (
		<div className="page-cont manage-check-lists-edit-page">
			{checkListId && <PageBreadcrumbs checkListId={checkListId}/>}

			<LoadingPanel active={loadingState.loading}>
				<>
					{!loadingState.loading && checkListId && (
						<EditingCheckList checkListId={checkListId}/>
					)}
				</>
			</LoadingPanel>
		</div>
	);
};
