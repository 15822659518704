import React, {useCallback} from 'react';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {EntitiesTable} from '@tehzor/ui-components';
import {columns} from './columns';
import TotalTasks from './TotalTasks';
import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {IEnrichedTaskForScheduler} from '@src/pages/TasksPage/hooks/useEnrichedTasksForSchedule';
import {useChangePath} from '@src/core/hooks/useChangePath';

export const TasksListPopup = ({
	data,
	objectId,
	startTime,
	endTime
}: {
	data: IEnrichedTaskForScheduler[];
	objectId?: string;
	startTime?: number;
	endTime?: number;
}) => {
	const {pushPath} = useChangePath();
	const permissions = useTasksPermissions();
	const [taskDialog, handleOpenTaskDialog] = useAddingTaskDialog({
		objectId,
		startTime: startTime ? startTime.toString() : undefined,
		endTime: endTime ? endTime.toString() : undefined
	});

	const handleRowClick = useCallback((task: IEnrichedTaskForScheduler) => {
		pushPath(`/tasks/${task.id}`);
	}, []);

	return (
		<div className="tasks-schedule-popup__list-content">
			<TotalTasks number={data.length}/>
			<EntitiesTable
				// selectable
				columns={columns}
				data={data}
				onRowClick={handleRowClick}
			/>

			{permissions.canAdd && (
				<button
					className="tasks-schedule-popup__button-add-task"
					onClick={handleOpenTaskDialog}
					type="button"
				>
					<i className="tz-plus-24"/>
					<span>Добавить задачу</span>
				</button>
			)}

			{permissions.canAdd && taskDialog}
		</div>
	);
};
