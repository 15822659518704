import {useEffect, useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {changeDisplayMode} from '@src/store/modules/settings/pages/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';

/**
 * Определяет уровень объекта в иерархии. Шахматку временно можно отображать только для конечного и его родителя.
 * После ускорения выполнения запросов и необходимого тестирования интерфейса, данную функцию можно будет удалить.
 *
 * @param objectId
 */
export function useTemporaryDisablingSchemas(objectId: string) {
	const object = useAppSelector(s => extractCurrentTreeObject(s, objectId));
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	const needHideSchema = useMemo(
		() => object?.children?.every(child => child.children?.length),
		[object]
	);

	useEffect(() => {
		if (needHideSchema && online) {
			dispatch(changeDisplayMode(objectId, SpacesDisplayMode.LIST));
		}
	}, [needHideSchema, online]);

	return needHideSchema;
}
