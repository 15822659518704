import React from 'react';
import {useAsyncFn} from 'react-use';
import {
	EntityGridItem,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	IOwnerAcceptanceResolution,
	OwnerAcceptanceResolutionId
} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {editOwnerAcceptance} from '@src/store/modules/entities/ownerAcceptance/actions';

interface ResolutionProps {
	objectId: string;
	ownerAcceptanceId: string;
	resolutions: IOwnerAcceptanceResolution[];
	value: string | undefined;
	canEditResolution?: boolean;
}

const Resolution = ({
	objectId,
	ownerAcceptanceId,
	resolutions,
	value,
	canEditResolution
}: ResolutionProps) => {
	const dispatch = useAppDispatch();
	const [, handleResolutionChange] = useAsyncFn(
		async (v: OwnerAcceptanceResolutionId) => {
			await dispatch(editOwnerAcceptance(objectId, ownerAcceptanceId, {resolution: v}));
		},
		[objectId, ownerAcceptanceId]
	);
	return (
		<EntityGridItem
			label="Решение"
			icon={<i className="tz-description-20"/>}
			fullRow
		>
			{canEditResolution ? (
				<SelectPopup
					noHeader
					trigger={(
						<TextFieldWithForwardedRef
							elementType="div"
							value={
								resolutions.find(
									item => item.id === value && item.id !== 'undefined'
								)?.name
							}
							icon={<i className="tz-simple-arrow-20"/>}
						/>
					)}
				>
					<Select2
						value={value}
						onChange={handleResolutionChange}
					>
						{resolutions.map(item => (
							<SelectOption
								key={item.id}
								itemKey={item.id}
								content={item.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			) : (
				<div className="owner-acceptance-page__resolution">
					{resolutions.find(item => item.id === value)?.name}
				</div>
			)}
		</EntityGridItem>
	);
};
export default Resolution;
