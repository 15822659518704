import React, {useMemo} from 'react';
import {usePermissions} from './usePermissions';
import {formSpaceLink, formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {extractWorkAcceptance} from '@src/store/modules/entities/workAcceptance/selectors';

interface IEntityLink {
	key: string;
	icon: React.ReactNode;
	label: string;
	url?: string;
	title: string;
}

export function useEntitiesLinks(objectId: string): IEntityLink[] {
	const permissions = usePermissions(objectId);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const space = useAppSelector(extractSpace);
	const workAcceptance = useAppSelector(extractWorkAcceptance);

	return useMemo((): IEntityLink[] => {
		const result: IEntityLink[] = [];
		if (space && permissions.canViewSpace) {
			result.push({
				key: 'space',
				icon: null,
				label: formSpaceTitle(
					space.name,
					space.altName,
					spaceTypes.byId[space.type],
					altNamesVisible
				),
				url: formSpaceLink(objectId, space.id),
				title: 'Перейти к помещению'
			});
		}
		if (workAcceptance && permissions.canViewWorkAcceptances) {
			result.push({
				key: 'work-acceptance',
				icon: null,
				label: `Приёмка работ № "${workAcceptance.number.toString()}"`,
				url: formWorkAcceptanceLink(objectId, workAcceptance.id),
				title: 'Перейти к приёмке'
			});
		}
		return result;
	}, [objectId, permissions, spaceTypes, altNamesVisible, space]);
}
