import * as types from './constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetCheckRecordStatusesResponse} from '@src/api/backend/checkRecordStatuses';
import {queryClient} from '@src/api/QueryClient';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';

export type IGetCheckRecordStatusesPayload = IGetCheckRecordStatusesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetCheckRecordStatusesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке статусов категорий работ');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список статусов нарушений
 */
export const getCheckRecordStatuses = () =>
	createApiAction<IGetCheckRecordStatusesResponse>(request, success, failure, () =>
		queryClient.fetchQuery(restDictionariesQueryKeys.checkRecordStatuses(), {
			staleTime: Infinity
		}));
