import Text from '@src/components/editableFields/Text';
import {convertToSave, errorsFns, isEdited, useEditableProfileState} from '@src/core/hooks/states/useEditableProfileState';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {IProfileWithSubscription} from '@tehzor/tools/interfaces/profile/IProfile';
import React, {useCallback, useEffect, useState} from 'react';

const fieldsSettings: Record<string, IObjectFieldSetting> = {
	email: {fieldId: 'email', isRequired: true},
	firstName: {fieldId: 'firstName', isRequired: true},
	lastName: {fieldId: 'lastName', isRequired: true},
	middleName: {fieldId: 'middleName', isRequired: false},
	position: {fieldId: 'position', isRequired: false},
	password: {fieldId: 'password', isRequired: false}
};

export const useEditableProfile = (user: IProfileWithSubscription): [
	React.ReactNode, () => (IProfileWithSubscription | undefined), boolean
] => {
	const [editingState, editingDispatch] = useEditableProfileState(user);
	const [isBlocking, setIsBlocking] = useState(false);

	useEffect(() => {
		setIsBlocking(
			hasErrors(editingState, errorsFns, fieldsSettings)
			|| (!isEdited(editingState, user))
		);
	}, [editingState, user]);

	const getSavingData = useCallback(
		() => {
			if (hasErrors(editingState, errorsFns, fieldsSettings)) {
				editingDispatch({type: 'update-errors'});
				return undefined;
			}

			if (!isEdited(editingState, user)) {
				return undefined;
			}

			const saving = convertToSave(editingState, user, true);

			return saving;
		},
		[editingDispatch, editingState, user]
	);

	const fields = (
		<div className="profile-edit-page__grid">
			<Text
				field="email"
				label={`Email ${fieldsSettings?.email?.isRequired ? '*' : ''}`}
				value={editingState.email}
				editingDispatch={editingDispatch}
				required={fieldsSettings?.email?.isRequired}
				hasError={editingState.errors.email}
				errorMessage="Заполните поле правильно"
			/>
			<Text
				field="firstName"
				label={`Имя ${fieldsSettings?.firstName?.isRequired ? '*' : ''}`}
				value={editingState.firstName}
				editingDispatch={editingDispatch}
				required={fieldsSettings?.firstName?.isRequired}
				hasError={editingState.errors.firstName}
			/>
			<Text
				field="lastName"
				label={`Фамилия ${fieldsSettings?.lastName?.isRequired ? '*' : ''}`}
				value={editingState.lastName}
				editingDispatch={editingDispatch}
				required={fieldsSettings?.lastName?.isRequired}
				hasError={editingState.errors.lastName}
			/>
			<Text
				field="middleName"
				label={`Отчество ${fieldsSettings?.middleName?.isRequired ? '*' : ''}`}
				value={editingState.middleName}
				editingDispatch={editingDispatch}
				required={fieldsSettings?.middleName?.isRequired}
				hasError={editingState.errors.middleName}
			/>
			<Text
				field="position"
				label={`Должность ${fieldsSettings?.position?.isRequired ? '*' : ''}`}
				value={editingState.position}
				editingDispatch={editingDispatch}
				required={fieldsSettings?.position?.isRequired}
				hasError={editingState.errors.position}
			/>
			<Text
				field="password"
				label="Новый пароль"
				value={editingState.password}
				editingDispatch={editingDispatch}
				required={fieldsSettings?.password?.isRequired}
				hasError={editingState.errors.password}
			/>
		</div>
	);

	return [fields, getSavingData, isBlocking];
};
