import React from 'react';
import {IScheduleData} from '../../../../utils/convertTasksToScheduleFormat';
import {CellContext} from '@tanstack/react-table';
import {useMonthTasks} from '@src/pages/TasksPage/hooks/useMonthTasks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';
import {createHeightForDayCell} from '@src/pages/TasksPage/utils/timeDayUtils';
import {createHeightForMonthCell} from '@src/pages/TasksPage/utils/timeMonthUtils';

export const TasksUnAssignedCell = (props: CellContext<IScheduleData, IScheduleData>) => {
	const {getValue} = props;
	const value = getValue();
	const {settings} = useAppSelector(extractTasksScheduleSettings);

	const groupedTasks = value.tasks.tasksForUnAssignedCell;

	const {tasks} = useMonthTasks({
		groupedTasks,
		hiddenNumber: 6,
		objectId: value?.objectId,
		popupTitle: 'Задачи в ожидании'
	});

	if (!groupedTasks?.length) return null;

	const maxTasksCountInRow = value.tasks?.maxTasksCountInRow;
	let height = 0;

	if (settings.currentMode === 'year') {
		height = createHeightForMonthCell(maxTasksCountInRow);
	}

	if (settings.currentMode === 'month') {
		height = createHeightForDayCell(value.tasks.tasksForDayCell.maxCountInRow);
	}

	return (
		<div
			className="manage-task-schedule-page__tasks-unassined-cell"
			style={{height}}
		>
			<div className="manage-task-schedule-page__tasks-unassined-cell-tasks">
				{tasks}
			</div>
		</div>
	);
};