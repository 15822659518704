import React, {useCallback, useMemo, useState} from 'react';
import './EntitiesMobilePhotoViewer.less';
import MobilePhotoViewer from '../MobilePhotoViewer';
import {getActiveEntityTitle} from './utils/getActiveEntityTitle';
import {useCachedIndexes} from './utils/useCachedIndexes';
import {getCommonIndex} from './utils/getCommonIndex';
import MobilePhotoEditorFooter from './components/MobilePhotoEditorFooter';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import ICanvasData from '@tehzor/tools/interfaces/ICanvasData';
import {getCanvasData} from './utils/genCanvasData';
import {ICanvasRefObject} from '../../Canvas/Canvas';
// import {getActiveEntityEditRule} from './utils/getActiveEntityEditRule';

export interface IEntity {
	id: string;
	title: string;
	files: string[];
	type?: AttachmentFileDestination;
	attachments?: Array<{
		id: string;
		url: string;
		canvas?: ICanvasData;
		original?: string;
	}>;
}

interface IEntitiesMobilePhotoViewerProps {
	className?: string;
	style?: React.CSSProperties;
	entities: IEntity[];
	activeId?: string;
	activeIndex?: number;
	isOpen: boolean;
	canDraw?: boolean;

	saveEditedImage?: (editedImageData: object) => void;
	onChange: (id: string, index: number) => void;
	onClose?: () => void;
}

const EntitiesMobilePhotoViewer = (props: IEntitiesMobilePhotoViewerProps) => {
	const {className, style, entities, activeId, activeIndex, isOpen, onChange, onClose, saveEditedImage, canDraw}
		= props;

	const images = useMemo(() => entities.reduce<string[]>((prev, item) => prev.concat(item.files), []), [entities]);
	const title = getActiveEntityTitle(entities, activeId);
	// const editable = getActiveEntityEditRule(entities, activeId);
	const indexes = useCachedIndexes(entities);
	const value = getCommonIndex(indexes, activeId, activeIndex);
	const [editing, setEditing] = useState(false);
	const [brushColor, setColor] = useState('#3392FF');
	const [drawData, setDrawData] = useState<ICanvasRefObject>();
	const mode_type = !editing ? 'view' : 'edit';
	const canvasData = getCanvasData(entities, activeId, activeIndex);

	const handleChange = useCallback(
		(index: number) => {
			if (!onChange) {
				return;
			}

			for (const id in indexes) {
				if (indexes.hasOwnProperty(id) && indexes[id].from <= index && indexes[id].to >= index) {
					onChange(id, index - indexes[id].from);
					return;
				}
			}
		},
		[indexes, onChange]
	);

	const handleEdit = () => {
		setEditing(true);
	};

	const handleCancelEdit = () => {
		setEditing(false);
	};

	const handleClear = () => {
		if (drawData) {
			drawData.clear();
		}
	};

	const handleCanvasChange = (data: ICanvasRefObject) => {
		setDrawData(data);
	};

	const handleSave = useCallback(async () => {
		setEditing(false);
		if (!saveEditedImage || !drawData || !drawData.canvas) {
			return;
		}

		const entity = entities.find(ent => ent.id === activeId);
		const attachmentId = entity?.attachments?.find(att => att.url === images[value])?.id;
		const blobs = await drawData.getBlobUrl(drawData.canvas);
		if (blobs) {
			const editedImageData = {
				drawData: drawData.drawed,
				attachmentId,
				entityId: activeId,
				entityType: entity?.type,
				editedBlob: blobs.editedBlob,
				originalBlob: blobs.originalBlob
			};
			saveEditedImage(editedImageData);
		}
	}, [saveEditedImage, drawData, entities, activeId]);
	const handleChangeColor = (color: string) => {
		setColor(color);
	};

	const handleClose = () => {
		if (editing) {
			setEditing(false);
		}
		if (onClose) {
			onClose();
		}
	};

	return (
		<MobilePhotoViewer
			className={className}
			style={style}
			data={images}
			value={value}
			isOpen={isOpen}
			onChange={handleChange}
			onClose={handleClose}
			editing={editing}
			brushColor={brushColor}
			canvasBaseData={canvasData}
			onCanvasChange={handleCanvasChange}
		>
			<div className="em-photo-viewer__previews">
				<MobilePhotoEditorFooter
					entities={entities}
					value={value}
					onChange={handleChange}
					type={mode_type}
					editable={canDraw}
					hEdit={handleEdit}
					hClear={handleClear}
					title={title}
					hCancel={handleCancelEdit}
					hSave={handleSave}
					selectedColor={brushColor}
					colors={['#3392FF', '#718198', '#FF0F31', '#FFFFFF']}
					hChangeColor={handleChangeColor}
				/>
			</div>
		</MobilePhotoViewer>
	);
};

export default EntitiesMobilePhotoViewer;
