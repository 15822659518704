import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {requestChecks} from '@src/api/backend/checks';

export type IGetSpaceChecksPayload = INormalizedData<IListCheck>;

const request = () => ({type: types.GET_CHECKS_REQUEST});

const success = (response: IGetSpaceChecksPayload) => ({
	type: types.GET_CHECKS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке проверок');
	}
	return {
		type: types.GET_CHECKS_FAILURE,
		payload: error
	};
};

/**
 * Получает список проверок для конкретного помещения
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 */
export const getSpaceChecks = (objectId: string, spaceId: string) =>
	checkExpiration<IState, IGetSpaceChecksPayload, ApiAction>(
		s => s.entities.space.checks,
		createApiAction<IGetSpaceChecksPayload>(request, success, failure, () =>
			requestChecks(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);
