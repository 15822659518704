import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeTaskDeleteRequest} from '@src/api/backend/task';
import {queryClient} from '@src/api/QueryClient';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';

export interface IDeleteTaskPayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => {
	addSuccessToast('Удалено', 'Задача успешно удалена');
	return {
		type: types.DELETE_SUCCESS,
		payload: {id}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении задачи');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет задачу
 *
 * @param taskId id задачи
 */
export const deleteTask = (taskId: string) =>
	createApiAction(
		request,
		() => success(taskId),
		failure,
		async () => {
			const data = await makeTaskDeleteRequest(taskId);
			await queryClient.invalidateQueries(tasksQueryKeys.all());
			return data;
		}
	);
