import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {getStructure} from '@src/store/modules/app/links/actions/getStructure';
import {LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureLink} from '@tehzor/tools/utils/links';

export const StructureEntityLink = ({structureId, objectId}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const structure = useAppSelector(s => s.app.links.structureId?.[structureId]);
	const structureTypes = useAppSelector(s => s.dictionaries.structureTypes.byId);

	const handleClick = useCallback(() => {
		pushPath(formStructureLink(objectId, structureId));
	}, [objectId, structureId]);

	useAsync(async () => {
		await Promise.all([dispatch(getStructure(objectId, structureId))]);
	}, [structureId, objectId]);

	if (!structure || !structureTypes[structure.type]) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`Структура: ${structure.name}`}
			leftIcon={<i className="tz-external-link-16"/>}
			onClick={handleClick}
			type="filled"
		/>
	);
};
