import React from 'react';
import {Plate} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import Common from './Common';

interface IDesktopInfoProps {
	objectId: string;
	space: ISpace;
}

const DesktopInfo = ({objectId, space}: IDesktopInfoProps) => (
	<Plate className={{content: 'space-page__info-plate'}}>
		<div className="space-page__info-title">Основное</div>
		<Common
			objectId={objectId}
			space={space}
		/>
	</Plate>
);
export default DesktopInfo;
