import React from 'react';
import declination from '@tehzor/tools/utils/declination';
import {useTasks} from '@src/core/hooks/queries/tasks';
import classNames from 'classnames';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

const TasksPageCounter = () => {
	const tasksData = useTasks();
	const text = `Всего: ${
		tasksData?.total || 0
	} ${declination(tasksData?.total || 0, ['задача', 'задачи', 'задач'])}`;
	const isDesktop = useIsDesktop();

	return (
		<div className={classNames('tasks-page__counter', {
			'tasks-page__m-control-bar-counter': !isDesktop
		})}
		>
			{text}
		</div>
	);
};

export default TasksPageCounter;
