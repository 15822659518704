import {Column} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {StructureNameCell} from '@src/components/tableCells/StructureNameCell';
import {StructureTypeCell} from '@src/components/tableCells/StructureTypeCell';
import {WorkAcceptancesStatsCell} from '@src/components/tableCells/WorkAcceptancesStatsCell';
import {CategoriesCell} from '@src/components/tableCells/CategoriesCell';
import {CheckListsCell} from '@src/components/tableCells/CheckListsCell';

export const desktopColumns: Array<Column<IEnrichedStructure>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Название',
		Cell: StructureNameCell,
		width: 110,
		minWidth: 80
	},
	{
		id: 'type',
		accessor: 'type',
		Header: 'Тип структуры',
		Cell: StructureTypeCell,
		width: 80,
		minWidth: 80
	},
	{
		id: 'categories',
		accessor: 'categories',
		Header: 'Виды работ',
		Cell: CategoriesCell,
		width: 110,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'checkLists',
		accessor: 'checkLists',
		Header: 'Чек-листы',
		Cell: CheckListsCell,
		width: 110,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'workAcceptances',
		accessor: 'workAcceptances',
		Header: 'Приёмки работ',
		Cell: WorkAcceptancesStatsCell,
		width: 110,
		minWidth: 80,
		disableSortBy: true
	}
];