import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetWarrantyClaimResponse, requestWarrantyClaim} from '@src/api/backend/warrantyClaim';

export type IGetOneUserWarrantyClaimPayload = IGetWarrantyClaimResponse;

const request = () => ({
	type: types.GET_ONE_REQUEST
});

const success = (response: IGetWarrantyClaimResponse) => ({
	type: types.GET_ONE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке гарантийного обращения');
	return {
		type: types.GET_ONE_FAILURE,
		payload: error
	};
};

/**
 * Получает одно гарантийное обращение по id
 */
export const getUserWarrantyClaim = (objectId: string, warrantyClaimId: string) =>
	createApiAction<IGetWarrantyClaimResponse>(request, success, failure, () =>
		requestWarrantyClaim(objectId, warrantyClaimId));
