import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';

/**
 * Хук для хлебных крошек от "всех объектов" до помещений
 *
 * @param objectId id конечного объекта
 */
export function useSpacesBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId);
		items.push({label: 'Помещения', url: `/objects/${objectId}/spaces`, inactive: false});
		return items;
	}, [objectId, objects]);
}
