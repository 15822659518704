import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import * as types from '../constants';
import {persistReducer} from 'redux-persist';
import {formPersistConfig} from '@src/store/persistentStorage/formPersistConfig';
import {
	OfflineDataCachingStatus,
	IOfflineDataCachingStatuses
} from '@tehzor/tools/contracts/dataCachingWebWorker/interfaces/IOfflineDataCachingStatuses';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export interface IEntityCacheState {
	status: OfflineDataCachingStatus;
	progress?: number;
	loadedAt?: number;
}

export type IEntitiesCacheState = Record<string, IEntityCacheState | undefined>;

export const entitiesCacheStateReducer = createReducer<IEntitiesCacheState>(
	{},
	{
		[types.CHANGE_ENTITIES_CACHE_STATE]: (
			state,
			{payload}: PayloadAction<IOfflineDataCachingStatuses>
		) => {
			for (const key in payload) {
				if (payload.hasOwnProperty(key)) {
					let loadedAt = state[key]?.loadedAt;

					if (state[key]?.status !== payload[key]) {
						if (payload[key] === OfflineDataCachingStatus.LOADED) {
							loadedAt = Date.now();
						}
						if (payload[key] === OfflineDataCachingStatus.SKIPPED) {
							state[key] = {
								status: state[key]?.status ?? OfflineDataCachingStatus.SKIPPED,
								loadedAt: state[key]?.loadedAt ?? Date.now()
							};
						}
					}

					state[key] = {status: payload[key], loadedAt};
				}
			}
		},
		[types.CHANGE_ENTITIES_CACHE_PROGRESS]: (
			state,
			{payload}: PayloadAction<{entity: string, value: number}>
		) => {
			const entity = state[payload.entity];
			if (entity) {
				if (entity.status !== OfflineDataCachingStatus.LOADING) {
					entity.status = OfflineDataCachingStatus.LOADING;
				}
				entity.progress = payload.value;
			}
		},
		[CLEAR_STORE]: () => ({})
	}
);

export const entitiesCacheState = persistReducer(
	formPersistConfig<ReturnType<typeof entitiesCacheStateReducer>>('entitiesState'),
	entitiesCacheStateReducer
);
