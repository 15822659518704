import {queryClient} from '@src/api/QueryClient';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {problemTemplatesSetsQueryKeys} from '@src/api/cache/problemTemplatesSets/keys';
import {regulatoryStandardsQueryKeys} from '@src/api/cache/regulatoryStandards/keys';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';

export const invalidateDictionariesCache = async () => {
	const dictionaryQueryKeys = Object.values(restDictionariesQueryKeys).slice(1);
	for (const key of dictionaryQueryKeys) {
		await queryClient.invalidateQueries(key());
	}
	await queryClient.invalidateQueries(objectsQueryKeys.list());
	await queryClient.invalidateQueries(regulatoryStandardsQueryKeys.list());
	await queryClient.invalidateQueries(problemTemplatesSetsQueryKeys.list());
	await queryClient.invalidateQueries(categoriesQueryKeys.list());
	await queryClient.invalidateQueries(categoriesSetsQueryKeys.list());
	await queryClient.invalidateQueries(responsibilityRulesQueryKeys.list());
	await queryClient.invalidateQueries(workingGroupsQueryKeys.list());
	await queryClient.invalidateQueries(checkListsQueryKeys.list());
	await queryClient.invalidateQueries(checkItemsQueryKeys.list());
	await queryClient.invalidateQueries(spaceStatusesQueryKeys.list());
	await queryClient.invalidateQueries(tasksQueryKeys.list());
	await queryClient.invalidateQueries(tasksQueryKeys.schedule());
};
