import React from 'react';
import {EntityLinkButton, TouchablePanel} from '@tehzor/ui-components';
import {useEntitiesLinks} from '../../hooks/useEntitiesLinks';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMobileLinksProps {
	objectId: string;
}

const MobileLinks = (props: IMobileLinksProps) => {
	const {pushPath} = useChangePath();
	const {objectId} = props;
	const links = useEntitiesLinks(objectId);

	return (
		<TouchablePanel>
			{links.map(item => (
				<EntityLinkButton
					key={item.key}
					type="inline"
					leftIcon={item.icon}
					label={item.label}
					url={item.url}
					title={item.title}
					onClick={pushPath}
				/>
			))}
		</TouchablePanel>
	);
};

export default MobileLinks;
