import React from 'react';
import './Progress.less';
import classNames from 'classnames';

type ProgressType = 'started' | 'success' | 'error';

interface IProgressProps {
	className?: string;
	style?: {};
	percent: number;
	type?: ProgressType;
}

const Progress = (props: IProgressProps) => {
	const {className, style, percent, type} = props;
	const classes = classNames('progress', className);
	const lineClasses = classNames('progress__line', type && `progress__line_${type}`);
	const lineStyles = {width: `${percent}%`} as React.CSSProperties;

	return (
		<div
			className={classes}
			style={style}
		>
			<div
				className={lineClasses}
				style={lineStyles}
			/>
		</div>
	);
};

Progress.defaultProps = {
	type: 'started'
};

export {ProgressType};
export default Progress;
