import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {queryClient} from '@src/api/QueryClient';
import {deleteMutation} from '@src/cache/utils/deleteMutation';

export const deleteLocalInspection = async (inspectionId: string) => {
	queryClient.removeQueries(inspectionsQueryKeys.detail(inspectionId));
	queryClient.removeQueries(inspectionsQueryKeys.savingData(inspectionId));
	await queryClient.refetchQueries({
		queryKey: inspectionsQueryKeys.localList()
	});
	deleteMutation(inspectionId);
};
