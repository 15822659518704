import React, {useCallback, useState} from 'react';
import {
	ActionButtons,
	Button,
	Dialog,
	EntityGridItem,
	InlineButton,
	ReadMore,
	TextField
} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/actions/edit';

interface InternalAcceptanceDescriptionProps {
	objectId: string;
	internalAcceptanceId: string;
	value: string | undefined;
	canEdit?: boolean;
}

const defaultTextAreaProps = {
	minRows: 9
};

const DescriptionMobile = ({
	objectId,
	internalAcceptanceId,
	value,
	canEdit
}: InternalAcceptanceDescriptionProps) => {
	const [isEditable, setIsEditable] = useState(false);
	const [text, setText] = useState(value);
	const [saving, setSaving] = useState(false);
	const dispatch = useAppDispatch();
	const handleChange = useCallback((v: string) => {
		setText(v);
	}, []);
	const handleSave = useCallback(async () => {
		setSaving(true);
		try {
			await dispatch(
				editInternalAcceptance(objectId, internalAcceptanceId, {description: text})
			);
		} finally {
			setSaving(false);
			setIsEditable(false);
		}
	}, [text]);

	return (
		<EntityGridItem
			label="Описание"
			icon={<i className="tz-description-20"/>}
			fullRow
			buttons={
				canEdit && !isEditable ? (
					<InlineButton
						className="internal-acceptance-page__documents-edit-icon"
						type="accent"
						leftIcon={<i className="tz-edit-16"/>}
						onClick={() => setIsEditable(true)}
					/>
				) : null
			}
		>
			{value ? (
				<ReadMore
					className={{
						root: 'internal-acceptance-page__documents-description',
						button: 'internal-acceptance-page__documents-description-button'
					}}
					text={value}
					maxLength={100}
				/>
			) : null}
			{isEditable && (
				<Dialog
					className="editable-attachments-dialog"
					isOpen={isEditable}
					title="Редактировать описание"
					footer={(
						<ActionButtons>
							<Button
								type="accent-blue"
								label="Сохранить"
								disabled={saving}
								onClick={handleSave}
							/>
							<Button
								type="cancel"
								label="Отменить"
								disabled={saving}
								onClick={() => {
									setText(value);
									setIsEditable(false);
								}}
							/>
						</ActionButtons>
					)}
					fullScreenOnTablet
					onRequestClose={() => setIsEditable(false)}
					onAfterClose={() => setText(value)}
				>
					<TextField
						value={text}
						cleanable
						elementProps={defaultTextAreaProps}
						elementType="textarea"
						onChange={handleChange}
					/>
				</Dialog>
			)}
		</EntityGridItem>
	);
};
export default DescriptionMobile;
