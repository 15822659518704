import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import React from 'react';
import Menu from '../Menu';
import {MobileCreateCheck} from './CreateCheck.mobile';

interface IMobileActionsProps {
	task?: ITask;
}

const MobileActions = ({task}: IMobileActionsProps) => {
	const permissions = useChecksPermissions(task?.objectId);
	const problemsPermissions = useProblemsAddingPermissions(task?.objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(task?.objectId);

	if (task === undefined || task.taskType === undefined) {
		return null;
	}

	return (
		<>
			{(task.taskType === TaskTypeId.AUDIT || task.taskType === TaskTypeId.CHECK)
				&& permissions.canAdd
				&& (problemsPermissions.canAdd || inspectionsPermissions.canAdd)
				&& (
					<MobileCreateCheck task={task}/>
				)}
			<Menu taskId={task.id}/>
		</>
	);
};

export default MobileActions;
