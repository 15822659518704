import React, {useCallback} from 'react';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ILegalsFiltersState, legalsActions} from '@src/store/modules/settings/pages/legals/reducers';
import {extractLegalsPageSettings} from '@src/store/modules/settings/pages/legals/selectors';
import {LegalsPage} from './LegalsPage';

export const AllLegalsPageWrap = () => {
	const dispatch = useAppDispatch();
	const {filters} = useAppSelector(extractLegalsPageSettings);

	const applyFilters = useCallback(
		(value: ILegalsFiltersState) => {
			dispatch(legalsActions.changeFilters(value));
			dispatch(legalsActions.changeOffset(0));
		}, [dispatch]
	);

	const onClear = useCallback(() => {
		dispatch(legalsActions.clearFilters());
	}, [dispatch]);

  return (
	<EntitiesFiltersProvider
		filters={filters}
		onApply={applyFilters}
		onClear={onClear}
		objectId="all"
	>
		<LegalsPage/>
	</EntitiesFiltersProvider>
	);
};