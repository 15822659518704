import {IGetObjectsResponse} from '@src/api/backend/objects';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {queryClient} from '@src/api/QueryClient';
import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {prefetchCache} from './cachingResorces/prefetchCache';
import {refetchCache} from './cachingResorces/refetchCache';
import {keysPermissionMapping} from './cachingResorces/utils/cacheKeysPermissionMapping';
import {startOfflineDataCaching} from './startOfflineDataCaching';
import {addWarningToast} from '@src/utils/toasts';

export const startCachingResources = async (
	roles?: IUserRole[],
	refetch?: boolean,
	objects?: string[]
) => {
	const queryKeys = keysPermissionMapping(roles);
	// Получение настроек кеширования для запросов
	if (!objects?.length) {
		addWarningToast('ДАННЫЕ НЕ ЗАКЕШИРОВАНЫ', 'Необходимо выбрать хотя бы один объект');
		return;
	}
	const objectsQuerySettings = queryClient.getQueryDefaults(objectsQueryKeys.list());
	await queryClient.prefetchQuery<IGetObjectsResponse>({
		queryKey: objectsQueryKeys.list(),
		staleTime: objectsQuerySettings?.staleTime,
		cacheTime: objectsQuerySettings?.cacheTime
	});
	if (refetch) {
		await refetchCache(queryKeys, objects);
		await startOfflineDataCaching(undefined, true);
		return;
	}
	await prefetchCache(queryKeys, objects);
	await startOfflineDataCaching();
};
