import React, {useMemo} from 'react';
import {LegendGroup} from './LegendGroup';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemStatusesAsArray} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import {extractSpaceStatusesAsArrayByObjectId} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {extractSpaceIndicatorsAsArray} from '@src/store/modules/dictionaries/spaceIndicatorsSets/selectors';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {extractCheckRecordStatusesByEntityTypeAsArray} from '@src/store/modules/dictionaries/checkRecordStatuses/selectors';
import {LegendItem} from './LegendItem';
import {ISpaceStatus, isSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';

export const LegendContent = () => {
	const {objectId} = useStrictParams<{objectId: string}>();
	const {schemaView} = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	// наборы индикаторов и статусов помещений могут быть разные для каждого вложенного объекта
	// сейчас для упрощения задачи - наборы получаем по идентификатору родительского объекта
	const problemStatuses = useAppSelector(extractProblemStatusesAsArray);
	const spaceStatuses = useAppSelector(s => extractSpaceStatusesAsArrayByObjectId(s, objectId));
	const indicators = useAppSelector(s => extractSpaceIndicatorsAsArray(s, objectId));
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);

	const spaceStatusesWithStages = useMemo(
		() =>
			spaceStatuses.reduce<Array<ISpaceStatus | {id: string, name: string}>>(
				(acc, spaceStatus) => {
					const currentStage = stagesMap[spaceStatus.stage];
					const lastAccStatus = acc.at(-1);

					if (isSpaceStatus(lastAccStatus)) {
						if (lastAccStatus?.stage === currentStage.id) {
							acc.push(spaceStatus);
							return acc;
						}
					}

					acc.push({id: currentStage.id, name: currentStage.name}, spaceStatus);
					return acc;
				},
				[]
			),
		[spaceStatuses, stagesMap]
	);

	const checkItemStatuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityTypeAsArray(s, 'check-item'));

	if (schemaView === SpacesSchemaVariants.PROBLEMS) {
		return (
			<div className="spaces-page__legend-content">
				<LegendGroup
					heading="Статусы нарушений"
					iconType="problem-status"
					items={problemStatuses}
				/>

				<LegendGroup
					heading="Индикаторы"
					iconType="indicator"
					items={indicators}
				/>

				<LegendGroup
					heading="Статусы помещений"
					iconType="space-status"
					items={spaceStatusesWithStages}
					className="spaces-page__legend-items-group-space-status"
				/>
			</div>
		);
	}

	/* ToDo переделать на динмический список когда будет ясность по цветам и их преобразованиям */

	if (schemaView === SpacesSchemaVariants.CHECK_LISTS) {
		return (
			<div className="spaces-page__legend-content">
				<div>
					<LegendGroup
						heading="Статусы чек-листов:"
						iconType="check-list-status"
					>
						<LegendItem
							type="problem-status"
							title="Не осмотрено"
							color="#C4D1E3"
						/>
						<LegendItem
							type="problem-status"
							title="В работе"
							color="#1C85FD"
						/>
						<LegendItem
							type="problem-status"
							title="Принято"
							color="#90D857"
						/>
					</LegendGroup>

					<LegendGroup
						heading="Индикаторы:"
						iconType="indicator"
						items={indicators}
					/>
				</div>
				<LegendGroup
					heading="Статусы категории работ:"
					iconType="check-item-status"
					items={checkItemStatuses}
					differentStyles
				/>
			</div>
		);
	}
	return null;
};
