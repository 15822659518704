import React, {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeOffset, getInspections} from '@src/store/modules/entities/inspections/actions';
import {useAsync} from 'react-use';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/inspections/actions';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/reducers';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const InspectionsFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await dispatch(getInspections(objectId || 'all'));
	}, [objectId]);

	const applyFilters = useCallback(
		(value: IInspectionsFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId]
	);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={clearFilters}
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};

export default InspectionsFiltersRoutingPage;
