import React from 'react';
import {InlineButton, Select2, SelectDialog, SelectOption, Tag} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	spaceIndicatorsAsArray,
	useGetSpaceIndicatorsSets
} from '@src/core/hooks/queries/spaceIndicatorsSets';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import {useEditSpaceIndicators} from '@src/core/hooks/mutations/spaces/useEditSpaceIndicators';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IIndicatorsSelectProps {
	objectId: string;
	spaceId: string;
	value?: string[];
}

const IndicatorsSelect = ({objectId, spaceId, value}: IIndicatorsSelectProps) => {
	const spaceIndicatorsSets = useGetSpaceIndicatorsSets();
	const objects = useAppSelector(s => s.dictionaries.objects);
	const indicators: ISpaceIndicator[] | null = spaceIndicatorsAsArray(
		spaceIndicatorsSets,
		objectId,
		objects
	);
	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const editSpaceIndicators = useEditSpaceIndicators(objectId);

	const [, handleChange] = useAsyncFn(
		async (v: string[]) => {
			await editSpaceIndicators({objectId, pageObjectId, spaceId, indicators: v, online});
		},
		[objectId, spaceId, online]
	);

	return (
		<SelectDialog
			title="Индикаторы помещения"
			trigger={(
				<InlineButton
					type="accent"
					leftIcon={<i className="tz-edit-20"/>}
					className="space-page__info-edit-icon"
				/>
			)}
			onChange={handleChange}
			value={value}
		>
			<Select2 multiple>
				{indicators?.map(indicator => (
					<SelectOption
						key={indicator.id}
						itemKey={indicator.id}
						content={(
							<Tag
								color={`${indicator.color}33`}
								icon={(
									<div
										className="space-page__indicator-icon"
										style={{backgroundColor: indicator.color}}
									/>
								)}
								label={indicator.name}
							/>
						)}
					/>
				))}
			</Select2>
		</SelectDialog>
	);
};
export default IndicatorsSelect;
