import React from 'react';
import './VerticalTabs.less';
import classNames from 'classnames';
import VerticalTabLink from './components/VerticalTabLink/VerticalTabLink';
import {getContent} from './utils/getContent';
import {getScrollContainer} from '@tehzor/tools/utils/getScrollContainer';
import ResizeObserver from 'resize-observer-polyfill';
import rafThrottle from '@tehzor/tools/utils/rafThrottle';
import SvgFilter from './components/SvgFilter';
import anime, {AnimeInstance} from 'animejs';

interface IVerticalTabsProps {
	className?: string;
	style?: React.CSSProperties;
	sideColumnClassName?: string;
	beforeLinks?: React.ReactNode;
	links?: React.ReactNodeArray;
	children?: React.ReactNode;
	activeTab?: number;
	defaultActiveTab?: number;
	manualMode?: boolean;
	scrollContainerId?: string;

	onActiveTabChange?(index: number): void;
}

interface IVerticalTabsState {
	tabIndex: number;
}

class VerticalTabs extends React.PureComponent<IVerticalTabsProps, IVerticalTabsState> {
	/**
	 * Корневой элемент компонента
	 */
	private _container: HTMLDivElement | null | undefined;

	/**
	 * Элемент активной таб-кнопки
	 */
	private _tab: HTMLButtonElement | null | undefined;

	/**
	 * Элемент выделения (фон) активной таб-кнопки
	 */
	private _fakeTab: HTMLDivElement | null | undefined;

	/**
	 * Ближайший элемент в котором скроллится текущий контент
	 */
	private _scrollContainer: Window | HTMLElement | undefined;

	/**
	 * Функции для отписки от событий
	 */
	private _unsubs: {[key: string]: Array<() => void>} = {
		container: [],
		tab: []
	};

	/**
	 * Предыдущее положение активной кнопки (и фона)
	 */
	private _prevTabPosition = 0;

	/**
	 * Анимация перемещения фона таб-кнопки между табами
	 */
	private _animation?: AnimeInstance;

	/**
	 * Слушатель события скролла
	 */
	private _handleScroll = rafThrottle(() => this._updateFakeTabPosition());

	/**
	 * Слушатель события изменения размера таб-кнопки
	 */
	private _handleTabResize = rafThrottle(() => this._updateFakeTabSize());

	constructor(props: IVerticalTabsProps) {
		super(props);

		this.state = {tabIndex: props.defaultActiveTab || 0};
	}

	static getDerivedStateFromProps(nextProps: IVerticalTabsProps) {
		if (nextProps.activeTab !== undefined) {
			return {tabIndex: nextProps.activeTab};
		}
		return null;
	}

	componentDidUpdate(prevProps: IVerticalTabsProps, prevState: IVerticalTabsState) {
		if (this.state.tabIndex !== prevState.tabIndex) {
			this._moveFakeTab();
		}
	}

	componentWillUnmount() {
		this._stopFakeTabAnimation();
	}

	render() {
		const {className, style, sideColumnClassName, beforeLinks, links, children, manualMode} = this.props;
		const {tabIndex} = this.state;

		const content = manualMode ? children : getContent(tabIndex, children);

		return (
			<div
				className={classNames('vertical-tabs', className)}
				style={style}
				ref={this._saveContainerRef}
			>
				<div className={classNames('vertical-tabs__side-column', sideColumnClassName)}>
					{beforeLinks !== undefined && <div className="vertical-tabs__before-links">{beforeLinks}</div>}

					<div className="vertical-tabs__links">
						{links?.map((link, i) => (
							<VerticalTabLink
								key={i}
								index={i}
								active={tabIndex === i}
								label={link}
								onClick={this._handleTabChange}
								ref={tabIndex === i ? this._saveTabRef : undefined}
							/>
						))}
					</div>

					<SvgFilter/>
				</div>

				<div className="vertical-tabs__content">
					{content}

					<div
						className="vertical-tabs__fake-tab"
						ref={this._saveFakeTabRef}
					/>
				</div>
			</div>
		);
	}

	private _handleTabChange = (index: number) => {
		const {onActiveTabChange} = this.props;
		if (onActiveTabChange) {
			onActiveTabChange(index);
		} else {
			this.setState({tabIndex: index});
		}
	};

	private _saveTabRef = (element: HTMLButtonElement | null) => {
		this._tab = element;
		this._removeEventListeners('tab');
		if (this._tab) {
			this._addResizeEventListener(this._tab, this._handleTabResize, 'tab');
		}
	};

	private _saveFakeTabRef = (element: HTMLDivElement | null) => {
		this._fakeTab = element;
		this._updateFakeTabSize();
		this._updateFakeTabPosition(true);
	};

	private _saveContainerRef = (element: HTMLDivElement | null) => {
		this._container = element;
		if (this._container) {
			this._scrollContainer = getScrollContainer(this.props.scrollContainerId ?? this._container);
			this._addEventListener(this._scrollContainer, 'scroll', this._handleScroll, 'container');
			this._addEventListener(this._scrollContainer, 'mousewheel', this._handleScroll, 'container');
		} else if (this._scrollContainer) {
			this._removeEventListeners('container');
			this._scrollContainer = undefined;
		}
		this._updateFakeTabSize();
		this._updateFakeTabPosition(true);
	};

	/**
	 * Обновляет расположение фона таб-кнопки
	 *
	 * @param initial не учитывать ли предыдущее положение
	 */
	private _updateFakeTabPosition = (initial?: boolean) => {
		if (this._container && this._tab && this._fakeTab) {
			this._stopFakeTabAnimation();

			const pos = this._tab.getBoundingClientRect().top - this._container.getBoundingClientRect().top;
			if (this._prevTabPosition !== pos || initial) {
				this._fakeTab.style.top = `${pos}px`;
				this._prevTabPosition = pos;
			}
		}
	};

	/**
	 * Обновляет размеры фона таб-кнопки
	 */
	private _updateFakeTabSize = () => {
		if (this._tab && this._fakeTab) {
			const rect = this._tab.getBoundingClientRect();
			this._fakeTab.style.width = `${rect.width}px`;
			this._fakeTab.style.height = `${rect.height}px`;
			this._fakeTab.style.left = `-${rect.width}px`;
		}
	};

	/**
	 * Останавливает анимацию перемещения фона таб-кнопки
	 */
	private _stopFakeTabAnimation = () => {
		if (this._animation) {
			this._animation.pause();
			this._animation = undefined;
		}
	};

	/**
	 * Перемещает псевдо элемент с анимацией
	 */
	private _moveFakeTab = () => {
		if (this._tab && this._container) {
			this._stopFakeTabAnimation();

			const rect = this._tab.getBoundingClientRect();
			const pos = rect.top - this._container.getBoundingClientRect().top;

			if (this._prevTabPosition !== pos) {
				setTimeout(() => {
					if (this._fakeTab) {
						this._animation = anime({
							targets: this._fakeTab,
							top: {
								value: pos,
								easing: 'easeOutQuart',
								duration: 400
							},
							height: {
								value: rect.height,
								easing: 'easeInOutQuart',
								duration: 200
							},
							update: anim => {
								this._prevTabPosition
									= pos + Math.floor(((this._prevTabPosition - pos) * (100 - anim.progress)) / 100);
							}
						});
					}
				}, 0);
			}
		}
	};

	private _addEventListener = (
		element: HTMLElement | Window,
		type: string,
		handler: () => void,
		unsubsKey: string
	) => {
		element.addEventListener(type, handler);
		this._unsubs[unsubsKey].push(() => element.removeEventListener(type, handler));
	};

	private _addResizeEventListener = (element: HTMLElement, handler: () => void, unsubsKey: string) => {
		const observer = new ResizeObserver(handler);
		observer.observe(element);
		this._unsubs[unsubsKey].push(() => observer.disconnect());
	};

	private _removeEventListeners = (unsubsKey: string) => {
		this._unsubs[unsubsKey].forEach(fn => fn());
		this._unsubs[unsubsKey] = [];
	};
}

export default VerticalTabs;
