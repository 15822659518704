import {PersistQueryClientOptions} from '@tanstack/react-query-persist-client';
import {createIDBPersister} from './queryClientPersister';

export const queryClientPersister = createIDBPersister();
export const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
	persister: queryClientPersister,
	dehydrateOptions: {
		shouldDehydrateMutation: mutation => mutation.state.status !== 'success'
	}
};
