import {wsConnector} from '../wsConnector';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';

export type IMoveCheckResponse = ICheck;

/**
 * Перемещает проверку
 */
export const makeCheckMoveRequest = (
	objectId: string,
	checkId: string,
	toObjectId: string,
	toSpaceId?: string
) =>
	wsConnector.sendAuthorizedRequest<IMoveCheckResponse>('moveCheck', {
		objectId,
		checkId,
		toObjectId,
		toSpaceId
	});
