import {useAddingObjectDialog} from '@src/components/AddingObjectDialog/hooks/useAddingObjectDialog';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import {useObjectsPermissions} from '@src/core/hooks/permissions/useObjectsPermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {IconButton} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import React from 'react';

interface IObjectsBreadcrumbsProps {
	objectId?: string;
}

export const ObjectsBreadcrumbs = ({objectId}: IObjectsBreadcrumbsProps) => {
	const object = useAppSelector(s =>
		(objectId ? extractObject(s, objectId) : undefined));

	const breadcrumbs = useObjectBreadcrumbs(objectId);

	const isDesktop = useIsDesktop();

	const [addingDialog, openAddingDialog] = useAddingObjectDialog(
		object?.id,
		object?.companyId
	);
	const {canAdd} = useObjectsPermissions();

	return (
		<div className="objects-page__breadcrumbs-container">
			<AppBreadcrumbs
				className="objects-page__breadcrumbs"
				items={breadcrumbs}
			/>

			{(isDesktop && canAdd) && (
				<>
					<IconButton
						onClick={openAddingDialog}
						type="accent-blue"
					>
						<i className="tz-plus-24"/>
					</IconButton>
					{addingDialog}
				</>
			)}
		</div>
	);
};