import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';

export interface IGetOwnerAcceptancesResponse extends INormalizedData<IListOwnerAcceptance> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список приёмок собственниками
 */
export const requestOwnerAcceptances = (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) =>
	wsConnector.sendAuthorizedRequest<IGetOwnerAcceptancesResponse>('getOwnerAcceptances', {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	});
