import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import {useIsLargeTablet} from '../../../utils/mediaQueries';
import {EntityButton} from './components/EntityButton/EntityButton';
import {StagesSelectDialog} from './components/StagesSelectDialog/StagesSelectDialog';
import {convertStages} from './utils/convertStages';
import './EntitySelectContainer.less';
import {IEntitySelectProps} from './components/EntitySelect/EntitySelect';
import {getTitle} from './utils/getTitle';

interface IEntitySelectContainerProps {
	contentMap: Record<ObjectStageIds, ReactNode>;
	entitiesSelectMapProps: Record<ObjectStageIds, IEntitySelectProps>;
	stages: IObjectStage[];
	selectedStage: ObjectStageIds;
	onSelectStage?: (stage: ObjectStageIds) => void;
}

const EntitySelectContainer = ({
	contentMap, entitiesSelectMapProps, stages, selectedStage, onSelectStage
}: IEntitySelectContainerProps) => {
	const convertedStages = useMemo(() => convertStages(stages), [stages]);
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const isDesktop = useIsLargeTablet();

	const title = useMemo(() =>
		getTitle(selectedStage, entitiesSelectMapProps), [selectedStage, entitiesSelectMapProps]);

	const convertedSelectedStage = useMemo(() =>
		convertedStages.find(val => val.id === selectedStage) || convertedStages[0],
	[convertedStages, selectedStage]);

	const handleConfirm = useCallback((stage: ObjectStageIds) => {
		if (onSelectStage) {
			onSelectStage(stage);
		}
		close();
	}, [onSelectStage, close]);

	const handleCancel = useCallback(() => close(), [close]);

	return (
		<div className="entity-select-container">
			<div className="entity-select-container__entity-btn-container">
				<EntityButton
					className="entity-select-container__entity-btn"
					icon={convertedSelectedStage.icon}
					iconColor={convertedSelectedStage.color}
					title={title}
					subTitle={`Стадия: ${convertedSelectedStage.name}`}
					onClick={open}
					withoutShadow={isDesktop}
				/>
			</div>
			{selectedStage && contentMap[selectedStage]}
			<StagesSelectDialog
				isOpen={isOpen}
				onClose={close}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
				defaultStage={selectedStage}
				stages={convertedStages}
				entitiesSelectMapProps={entitiesSelectMapProps}
			/>
		</div>
	);
};

export default EntitySelectContainer;