import React, {useCallback, useState} from 'react';
import './EditableSpaceDialog.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {addSpace, editSpace} from '@src/store/modules/entities/space/actions';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableSpace} from '@src/components/EditableSpace/hooks/useEditableSpace';

interface IEditableSpaceDialogProps {
	objectId: string;
	spaceId?: string;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно редактирования помещения
 */
const EditableSpaceDialog = ({objectId, spaceId, isOpen, onClose}: IEditableSpaceDialogProps) => {
	const space = useAppSelector(s => s.entities.space.data);
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableSpace(
		objectId,
		spaceId ? space : undefined,
		saving
	);

	const save = useCallback(async () => {
		setSaving(true);
		const savingData = await getSavingData();
		if (savingData && spaceId) {
			try {
				await dispatch(editSpace(objectId, objectId, spaceId, savingData));
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		if (savingData && !spaceId) {
			try {
				await dispatch(addSpace(objectId, savingData));
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		setSaving(false);
	}, [objectId, spaceId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-space-dialog"
			isOpen={isOpen}
			title={spaceId ? 'Редактирование помещения' : 'Добавление помещения'}
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			disableScrollBlock
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};

export default EditableSpaceDialog;
