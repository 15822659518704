import React from 'react';
import EntitiesFilters from '@src/components/EntitiesFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StatusesFilter} from '@src/components/EntitiesFilters/components/StatusesFilter';
import {extractProblemStatusesAsArray} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import {extractStructureTypesAsArray} from '@src/store/modules/dictionaries/structureTypes/selectors';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IStructuresFiltersState} from '@src/store/modules/settings/pages/structures/reducers/byPage';
import {extractWorkAcceptancesStatusesAsArray} from '@src/store/modules/dictionaries/workAcceptanceStatuses/selectors';
import {CategoriesSetFilter} from '@src/pages/ProblemsPage/components/filters/CategoriesSetFilter';
import {extractCheckRecordStatusesByEntityTypeAsArray} from '@src/store/modules/dictionaries/checkRecordStatuses/selectors';
import {extractCheckListsAsArrayForWorkAcceptances} from '@src/store/modules/dictionaries/checkLists/selectors';

interface IStructuresFiltersProps {
	objectId: string;
}

export const StructuresFilters = ({objectId}: IStructuresFiltersProps) => {
	const {state} = useEntitiesFiltersCtx<IStructuresFiltersState>();

	const problemStatuses = useAppSelector(extractProblemStatusesAsArray);
	const workAcceptanceStatuses = useAppSelector(extractWorkAcceptancesStatusesAsArray);
	const checkListRecordStatuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityTypeAsArray(s, 'check-list'));

	const checkLists = useAppSelector(s => extractCheckListsAsArrayForWorkAcceptances(s, objectId));

	const types = useAppSelector(extractStructureTypesAsArray);

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="structures"
		>
			<StatusesFilter
				label="Типы структур"
				filterName="types"
				statuses={state?.types}
				allStatuses={types}
			/>

			<StatusesFilter
				label="Статусы приёмок работ"
				filterName="workAcceptanceStatuses"
				statuses={state?.workAcceptanceStatuses}
				allStatuses={workAcceptanceStatuses}
			/>

			<StatusesFilter
				label="Статусы нарушений"
				filterName="problemStatuses"
				statuses={state?.problemStatuses}
				allStatuses={problemStatuses}
			/>

			<CategoriesSetFilter
				objectId={objectId}
				categories={state?.categories}
			/>

			<StatusesFilter
				label="Статусы чек-листов"
				filterName="checkListStatuses"
				statuses={state?.checkListStatuses ?? ([] as string[])}
				allStatuses={checkListRecordStatuses}
			/>

			<StatusesFilter
				label="Чек-листы"
				filterName="checkListIds"
				statuses={state?.checkListIds}
				allStatuses={checkLists}
				resetOnApply={['checkListCategory']}
			/>

		</EntitiesFilters>
	);
};
