import React, {Dispatch, useCallback, forwardRef, Ref} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import IReason from '@tehzor/tools/interfaces/IReason';
import {ElementPropsType} from '@tehzor/ui-components/src/components/inputs/TextField/TextField';

export interface IReasonProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	select?: React.ReactNode;
	search?: React.ReactNode;
	value?: IReason | null;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	textAreaProps?: ElementPropsType;
	onChange?: (value: string) => void;
}

export const Reason = forwardRef(<S extends Record<string, unknown>, E>({
	className,
	style,
	label = 'Основание',
	value,
	select,
	search,
	editingDispatch,
	required,
	disabled,
	hasError,
	textAreaProps,
	onChange
}: IReasonProps<S, E>, ref: Ref<HTMLTextAreaElement>) => {
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: 'reason', value: {value: v}});
			if (required) {
				editingDispatch({type: 'update-error', field: 'reason'});
			}
			if (onChange) {
				onChange(String(v));
			}
		},
		[required, editingDispatch, onChange]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel addon={select}>{label}</EditableFieldLabel>

			<TextField
				value={value?.value || ''}
				elementRef={ref}
				elementType="textarea"
				elementProps={textAreaProps}
				error={required && hasError ? 'Заполните описание' : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
			{search}
		</div>
	);
});
