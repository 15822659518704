import React, {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeOffset} from '@src/store/modules/entities/problems/actions';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/problems/actions';

import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const ProblemsFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const applyFilters = useCallback(
		(value: IProblemsFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId]
	);
	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={clearFilters}
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};

export default ProblemsFiltersRoutingPage;
