import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {DatePicker, EditableFieldLabel, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import classNames from 'classnames';
import {format} from 'date-fns';
import React, {ReactNode, memo, useCallback} from 'react';

interface IDateContractFieldProps {
	className?: string;
	label?: string;
	dialogTitle?: string;
	errorMessage?: string;
	hasError?: boolean;
	required?: boolean;
	disabled?: boolean;
	value?: number | null;

	icon?: ReactNode;

	onChange: (value: number | null) => void;
	onError: () => void;
}

const calendar = <i className="tz-calendar-20"/>;

export const DateContractField = memo(({
	className,
	label,
	dialogTitle,
	errorMessage,
	hasError,
	required,
	disabled,
	value,
	icon = calendar,
	onChange,
	onError
}: IDateContractFieldProps) => {
	const handleChange = useCallback((value: Date | null) => {
		onChange(value ? value.getTime() : null);
		if (required) {
			onError();
		}
	}, [onChange, onError, required]);

	const onClear = () => handleChange(null);

	const date = value ? new Date(value) : undefined;

	return (
		<div
			className={classNames(
				'editable-contract__fields-date', className
			)}
		>
			<EditableFieldLabel
				className="editable-contract__fields-date__label"
			>
				{required ? `${label} *` : label}
			</EditableFieldLabel>

			<DatePicker
				value={date}
				dialogProps={{title: required ? `${dialogTitle} *` : dialogTitle}}
				useApplyButton
				onChange={handleChange}
				trigger={props => (
					<TextFieldWithForwardedRef
						elementType="div"
						value={value ? format(value, dateFormat) : ''}
						disabled={disabled}
						error={hasError ? errorMessage : undefined}
						icon={icon}
						onClick={props.toggle}
						ref={props.ref}
						onClearClick={onClear}
						cleanable={!!value}
					/>
				)}
			/>
		</div>
	);
});