import React from 'react';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {MenuItem} from '../../../../menu';

export const getSpaceStageTitle = (
	stage: ObjectStageIds, stagesMap: Record<ObjectStageIds, IObjectStage>
) => (
	<MenuItem
		key={stage}
		className="space-status-select__menu-item-stage"
		titleClass="space-status-select__menu-item-stage_title"
		selectable={false}
		disabled
	>
		{stagesMap[stage].name}
	</MenuItem>
);