import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeExportTemplateDeleteRequest} from '@src/api/backend/exportTemplates/delete';

export interface IDeleteExportTemplatePayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => {
	addSuccessToast('Удалено', 'Шаблон экспорта успешно удален');
	return {
		type: types.DELETE_SUCCESS,
		payload: {id}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении шаблона экспорта');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет шаблон экспорта
 *
 * @param exportTemplateId id шаблона
 */
export const deleteExportTemplate = (exportTemplateId: string) =>
	createApiAction(
		request,
		() => success(exportTemplateId),
		failure,
		() => makeExportTemplateDeleteRequest(exportTemplateId)
	);
