import React from 'react';
import './ProblemComments.less';
import List from './components/List';
import CommentReference from './components/CommentReference';
import {Button, LoadingPanel} from '@tehzor/ui-components';
import EditableProblemComment from '@src/components/EditableProblemComment';
import useAsync from 'react-use/lib/useAsync';
import {
	getCommentsForProblem,
	resetProblemComments
} from '@src/store/modules/pages/problem/actions/comments';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {ICommentReferenceState, useCommentReference} from './hooks/useCommentReference';
import {useReplyDialog} from './hooks/useReplyDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useProblemCommentsPermissions} from '@src/core/hooks/permissions/useProblemCommentsPermissions';
import {extractProblem} from '@src/store/modules/pages/problem/selectors/problem';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';
import {Tree} from 'array-to-tree';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

interface IProblemCommentsProps {
	className?:
		| string
		| {
				root?: string;
				list?: string;
				content?: string;
				item?: string;
				footer?: string;
				replyBtn?: string;
				reference?: string;
				input?: string;
		  };
	objectId: string;
	problemId: string;
	links?: IProblemComment['links'];
	comments: Array<Tree<IProblemComment>>;

	onImageClick?: (id: string, index: number) => void;
}

const ProblemComments = ({
	className,
	objectId,
	problemId,
	links,
	comments,
	onImageClick
}: IProblemCommentsProps) => {
	const problem = useAppSelector(extractProblem);
	const hasMore = useAppSelector(s => s.pages.problem.comments.hasMore);
	const visibility = useAppSelector(s => s.settings.pages.problem.comments.visibility);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const refer = (location.state as {reference?: ICommentReferenceState} | null)?.reference;
	const permissions = useProblemCommentsPermissions(objectId, problem);
	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const loadingState = useAsync(async () => {
		dispatch(resetProblemComments());
		if (online && !problem?.transferStatus) {
			await dispatch(getCommentsForProblem(objectId, problemId));
		}
	}, [visibility, online]);

	const [, loadComments] = useAsyncFn(async () => {
		if (online && !problem?.transferStatus) {
			await dispatch(getCommentsForProblem(objectId, problemId));
		}
	}, [objectId, problemId, online]);

	// Состояние для ссылки на добавляемый/редактируемый комментарий
	const [reference, clearReference, setAddReference, setEditReference]
		= useCommentReference(refer);

	// Диалог добавления/редактирования ответа
	const [replyDialog, handleReplyAdd, handleReplyEdit] = useReplyDialog(
		objectId,
		problemId,
		links
	);

	const classes = convertClassNames(className);

	const commentInputVisible
		= permissions.canAddComments || (reference !== undefined && reference.type === 'edit');

	return (
		<div className={classNames('problem-comments', classes.root)}>
			{loadingState.loading ? (
				<LoadingPanel
					className="problem-comments__loading-panel"
					active={loadingState.loading}
				/>
			) : (
				<List
					className={{root: classes.list, content: classes.content, item: classes.item}}
					objectId={objectId}
					problemId={problemId}
					comments={comments}
					hasMore={hasMore}
					selectedId={reference?.type === 'edit' ? reference.id : undefined}
					onLoad={loadComments}
					onSubCommentAdd={permissions.canAddComments ? setAddReference : undefined}
					onCommentEdit={setEditReference}
					onReplyEdit={handleReplyEdit}
					onImageClick={onImageClick}
				/>
			)}
			{(permissions.canAddReplies || commentInputVisible) && (
				<div className={classNames('problem-comments__footer', classes.footer)}>
					{permissions.canAddReplies && (
						<Button
							className={classNames('problem-comments__reply-btn', classes.replyBtn)}
							type="accent-blue"
							label="Официальный ответ"
							outerTagType="button"
							onClick={handleReplyAdd}
						/>
					)}

					{reference !== undefined
						&& (reference.type === 'add' ? permissions.canAddComments : true) && (
							<CommentReference
								className={classes.reference}
								commentId={reference.id}
								type={reference.type}
								onClearReference={clearReference}
							/>
						)}

					{commentInputVisible && (
						<EditableProblemComment
							className={classes.input}
							objectId={objectId}
							problemId={problemId}
							commentId={reference?.type === 'edit' ? reference.id : undefined}
							links={links}
							parentId={reference?.type === 'add' ? reference.id : undefined}
							onClearReference={clearReference}
						/>
					)}
				</div>
			)}
			{replyDialog}
		</div>
	);
};

export default ProblemComments;
