import React, {forwardRef, Ref} from 'react';
import './ExpandButton.less';
import ButtonBase from '../ButtonBase';
import classNames from 'classnames';

const expandIcon = <i className="tz-simple-arrow-24"/>;

interface IExpandButtonProps {
	className?: string;
	style?: React.CSSProperties;
	label?: React.ReactNode;
	isExpanded?: boolean;

	onClick?(event: React.MouseEvent): void;
}

const ExpandButton = (props: IExpandButtonProps, ref?: Ref<HTMLButtonElement>) => {
	const {className, style, label, isExpanded, onClick} = props;

	return (
		<ButtonBase
			className={classNames({'expand-button_expanded': isExpanded}, className)}
			style={style}
			rightIcon={expandIcon}
			label={label}
			classNamePrefix="expand-button"
			outerTagType="button"
			onClick={onClick}
			ref={ref}
		/>
	);
};

ExpandButton.displayName = 'ExpandButton';

export default forwardRef(ExpandButton);
