import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeProblemTagsSetDeleteRequest} from '@src/api/backend/problemTagsSet';

export interface IDeleteProblemTagsSetPayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (id: string) => {
	addSuccessToast('Удалено', 'Набор меток успешно удален');
	return {
		type: types.DELETE_SUCCESS,
		payload: {id}
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении набора меток');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет набор меток
 *
 * @param setId id набора
 */
export const deleteProblemTagsSet = (setId: string) =>
	createApiAction(
		request,
		() => success(setId),
		failure,
		() => makeProblemTagsSetDeleteRequest(setId)
	);
