import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import {extractChecksData} from '@src/store/modules/entities/checks/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import declination from '@tehzor/tools/utils/declination';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/reducers';

interface IProblemsPageHeaderProps {
	objectId?: string;
}

const ChecksPageCounter = ({objectId = 'all'}: IProblemsPageHeaderProps) => {
	const checksData = useAppSelector(s => extractChecksData(s, objectId));
	const {state} = useEntitiesFiltersCtx<IChecksFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${isFiltersActive ? 'Найдено:' : 'Всего:'} ${checksData.total} ${declination(
		checksData.total || 0,
		['проверка', 'проверки', 'проверок']
	)}`;
	return (
		<PageHeader
			className="checks-page__header"
			title=""
			subTitle={text}
			wrapClassName="checks-page__header-wrap"
			leftSideClassName="checks-page__header-left"
			rightSideClassName="checks-page__header-right"
		/>
	);
};

export default ChecksPageCounter;
