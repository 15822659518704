import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ITaskPriority} from '@tehzor/tools/interfaces/tasks/ITaskPriority';

export type IGetTaskPrioritiesResponse = INormalizedData<ITaskPriority>;

/**
 * Возвращает список приритетов задач
 */
export const requestTaskPriorities = () =>
	wsConnector.sendAuthorizedRequest<IGetTaskPrioritiesResponse>('getTaskPriorities');
