import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddCheckListResponse, makeCheckListAddRequest} from '@src/api/backend/checkList';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';

export type IAddCheckListPayload = IAddCheckListResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddCheckListResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении чек-листа');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет чек-лист
 *
 * @param fields данные для добавления
 */
export const addCheckList = (fields: ISavingCheckList) =>
	createApiAction<IAddCheckListResponse>(request, success, failure, () =>
		makeCheckListAddRequest(fields));
