import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IAddProblemTagsSetResponse, makeProblemTagsSetAddRequest} from '@src/api/backend/problemTagsSet';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';

export type IAddProblemTagsSetPayload = IAddProblemTagsSetResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddProblemTagsSetResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении набора меток');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет набор меток
 *
 * @param fields данные для добавления
 */
export const addProblemTagsSet = (fields: ISavingProblemTagsSet) =>
	createApiAction<IAddProblemTagsSetResponse>(request, success, failure, () =>
		makeProblemTagsSetAddRequest(fields));
