import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction, ApiAction} from '@src/store/middlewares/api';
import {IGetProblemResponse} from '@src/api/backend/problem';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

export interface IGetProblemRequestPayload {
	problemId: string;
}

export type IGetProblemPayload = IGetProblemResponse;

const request = (problemId: string) => ({type: types.GET_REQUEST, payload: {problemId}});

const success = (response: IGetProblemResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке нарушения');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает нарушение по Id
 */
export const getProblem = (objectId: string, problemId: string): ApiAction<IGetProblemResponse> =>
	createApiAction<IGetProblemResponse>(
		() => request(problemId),
		success,
		failure,
		() => {
			const data = queryClient.fetchQuery<IGetProblemResponse>({
				queryKey: [...problemsQueryKeys.detail(problemId), objectId],
				staleTime: Infinity
			});
			return data;
		}
	);
