import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';

/**
 * Хук для хлебных крошек от "всех объектов" до гарантийных обращений
 *
 * @param objectId id конечного объекта
 */
export function useWarrantyClaimsBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId);
		items.push({
			label: 'Гарантийные обращения',
			url: `/objects/${objectId}/warranty-claims`,
			inactive: false
		});
		return items;
	}, [objectId, objects]);
}
