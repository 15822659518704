import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {TextField} from '../../TextField';
import './SelectSearch.less';
import {useUpdateEffect} from 'react-use';
import {convertClassNames} from '../../../../utils/convertClassNames';

interface ISelectSearchProps {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				element?: string;
				placeholder?: string;
				icon?: string;
				clearbutton?: string;
			};
	placeholder?: string;
	value?: string;
	disabled?: boolean;

	type?: 'common' | 'filter' | 'popup';

	onChange?: (value: string) => void;
	onSearch?: (value: string) => void;
	onClear?: () => void;
}

const searchIcon = <i className="tz-search-20"/>;

const SelectSearch = ({
	className,
	placeholder = 'Поиск',
	value: defaultValue = '',
	disabled,
	type = 'common',
	onChange,
	onSearch,
	onClear
}: ISelectSearchProps) => {
	const classes = convertClassNames(className);

	const [value, setValue] = useState(defaultValue);
	const [isPressed, setPressed] = useState(false);
	const ref = useRef<HTMLInputElement>(null);

	useUpdateEffect(() => {
		setValue(val => (val !== defaultValue ? defaultValue : val));
	}, [defaultValue]);

	const handleChange = useCallback((v: string) => {
		if (onChange) { onChange(v); }
		setValue(v);
	}, [onChange]);

	const handleSearch = useCallback(() => {
		if (onSearch) {
			onSearch(value);
		}
	}, [value, onSearch]);

	const handleClear = useCallback(() => {
		if (onClear) {
			onClear();
		}
		handleChange('');
	}, [onClear, handleChange]);

	useEffect(() => {
		const current = ref?.current;
		if (!current) { return; }

		const handleKeyDown = function(this: GlobalEventHandlers, ev: KeyboardEvent): any {
			if (ev.key === 'Enter') {
				handleSearch();
				setPressed(true);
			}
		};

		const handleKeyUp = function(this: GlobalEventHandlers, ev: KeyboardEvent): any {
			if (ev.key === 'Enter') {
				setPressed(false);
			}
		};

		current.onkeydown = handleKeyDown;
		current.onkeyup = handleKeyUp;
	}, [handleSearch, ref, value]);

	return (
		<TextField
			className={{
				root: classNames('select-search', classes?.root, `select-search_${type}`),
				wrap: classNames('select-search__wrap', classes?.wrap, {disabled}, `select-search_${type}`),
				element: classNames('select-search__input', classes?.element, `select-search_${type}`),
				placeholder: classNames('select-search__placeholder', classes?.placeholder, `select-search_${type}`),
				icon: classNames('select-search__icon', classes?.icon, {isPressed, disabled}, `select-search_${type}`),
				clearbutton: classNames('select-search__clearbutton', classes?.clearbutton, `select-search_${type}`)
			}}
			elementType="input"
			elementRef={ref}
			value={value}
			onChange={handleChange}
			cleanable={!!value}
			onClearClick={handleClear}
			icon={searchIcon}
			onIconClick={onSearch ? handleSearch : undefined}
			placeholder={placeholder}
			disabled={disabled}
		/>
	);
};

export default SelectSearch;