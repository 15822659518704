import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import arrayToTree from 'array-to-tree';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import IObjectRespRule from '@tehzor/tools/interfaces/objects/IObjectRespRule';
import compareDesc from 'date-fns/compareDesc';
import {getAllTreeKeys} from '@tehzor/tools/utils/tree/getAllTreeKeys';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {filterTree} from '@src/utils/tree';
import {IObjectTree} from '@src/interfaces/IObjectTree';

export const getObjectsData = (state: IState) => state.dictionaries.objects || {};
const getAllIds = (state: IState) => state.dictionaries.objects.allIds || [];
const getById = (state: IState) => state.dictionaries.objects.byId || {};
const getListFilter = (state: IState) =>
	state.settings?.pages.objects?.filters || {
		cities: [],
		stages: [],
		companies: []
	};
const getObjectFilterValue = (state: IState, objectFilterValue: string | undefined) =>
	objectFilterValue;
const getObjectId = (state: IState, objectId: string) => objectId;
const getObjectIds = (state: IState, objectIds: string[] | undefined) => objectIds;
const getExternalId = (state: IState, externalId: string) => externalId;
const getCompanyId = (state: IState, companyId: string) => companyId;

/**
 * Возвращает состояние загрузки объектов
 */
export const extractObjectsLoadingState = createSelector([getObjectsData], data => !data.loaded);

/**
 * Возвращает объекты в виде массива
 */
export const extractObjectsAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map(id => byId[id]));

interface ICity {
	id: string;
	name: string;
}
/**
 * Возвращает города объектов в виде массива
 */
export const extractObjectsCitiesAsArray = createSelector([extractObjectsAsArray], objects => {
	const set = Array.from(new Set(objects.map(obj => obj.city)));
	const array: ICity[] = [];
	set.sort().forEach(el => {
		if (el && el !== undefined) {
			array.push({id: el, name: el});
		}
	});
	return array;
});

/**
 * Возвращает объекты в виде массива согласно массиву id
 */
export const extractObjectsAsArrayByIds = createSelector(
	[getAllIds, getById, getObjectIds],
	(allIds, byId, array) => {
		if (array) {
			return allIds.filter(id => array.includes(id)).map(id => byId[id]);
		}
		return [];
	}
);

/**
 * Возвращает текущий объект
 */
export const extractObject = createSelector(
	[getById, getObjectId],
	// TODO Убрать "|| ({name: ''}"
	(byId: {[id: string]: IObject}, objectId: string) => byId[objectId] || ({name: ''} as IObject)
);

/**
 * Возвращает текущий объект по externalId
 */
export const extractCurrentObjectByExternalId = createSelector(
	[extractObjectsAsArray, getExternalId],
	(objects, externalId: string) => objects.find(object => object.externalId === externalId)
);

/**
 * Возвращает объекты в виде дерева
 */
export const extractObjectsAsTree = createSelector([extractObjectsAsArray], objects =>
	arrayToTree<IObject>(objects, {
		parentProperty: 'parentId',
		customID: 'id'
	}));

/**
 * Возвращает отфильтрованные объекты
 */
export const extractFilteredTreeObjects = createSelector(
	[extractObjectsAsTree, getListFilter],
	(tree, filters) => {
		const hasCompanies = Array.isArray(filters.companies) && filters.companies.length;

		return tree.filter(
			object =>
				(hasCompanies ? filters.companies?.includes(object.companyId) : true)
		);
	}
);

/**
 * Возвращает отфильтрованные объекты для отображения в табличном виде
 */
interface extractFilteredWithNewFiltersTreeObjectsFilter {
	cities?: string[];
	stages?: ObjectStageIds[];
	companies?: string[];
	object?: string;
}

const filterfun = (el: IObjectTree, filterArgs: extractFilteredWithNewFiltersTreeObjectsFilter) =>
	(filterArgs.cities && Array.isArray(filterArgs.cities) && filterArgs.cities.length > 0
		? el.city
			? filterArgs.cities.includes(el.city)
			: false
		: true)
	&& (filterArgs.stages && Array.isArray(filterArgs.stages) && filterArgs.stages.length > 0
		? el.stage
			? filterArgs.stages.includes(el.stage)
			: false
		: true)
	&& (filterArgs.companies && Array.isArray(filterArgs.companies) && filterArgs.companies.length > 0
		? el.companyId
			? filterArgs.companies.includes(el.companyId)
			: false
		: true)
	&& (filterArgs.object && filterArgs.object.length > 0
		? el.name.toUpperCase().indexOf(filterArgs.object.toUpperCase()) > -1
		: true);

export const extractFilteredWithNewFiltersTreeObjects = createSelector(
	[extractObjectsAsTree, getListFilter, getObjectFilterValue],
	(tree, filter, object) => filterTree(tree, filterfun, {...filter, object})
);

/**
 * Возвращает текущий объект из дерева
 */
export const extractCurrentTreeObject = createSelector(
	[extractObjectsAsTree, getObjectId],
	(tree, objectId) => findTreeNode(tree, objectId)
);

/**
 * Возвращает родительский объект текущего объекта
 */
export const extractParentTreeObject = createSelector(
	[extractObjectsAsTree, extractCurrentTreeObject],
	(tree, object) => object && object.parentId && findTreeNode(tree, object.parentId)
);

export const extractObjectRespRules = createSelector([extractObject], object => {
	if (!object.contractors) {
		return [] as IObjectRespRule[];
	}
	let rules = [] as IObjectRespRule[];
	for (const c of object.contractors) {
		if (c.respRules) {
			rules = rules.concat(c.respRules);
		}
	}
	return rules;
});

export const extractObjectRespUsers = createSelector(
	[
		extractObjectRespRules,
		(s: IState) => s.dictionaries.users.byId
	],
	(rules, users) =>
		rules
			.map(rule => users[rule.userId])
			.filter(
				(user, i, all) => user !== undefined && i === all.findIndex(u => user.id === u.id)
			)
			.sort((a: IBriefUser, b: IBriefUser) =>
				(a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0))
);

export const extractObjectAutoSelectRespRules = createSelector([extractObjectRespRules], rules =>
	rules.filter(rule => rule.autoSelect));

export const extractObjectFieldsSettings = createSelector(
	[extractObject],
	object => object.fieldsSettings ?? ({} as NonNullable<IObject['fieldsSettings']>)
);

/**
 * Определяет минимальную дату начала работы с объектами
 */
export const extractObjectsStartDate = createSelector(
	[extractObjectsAsArray],
	objects =>
		objects.reduce<Date>((min, object) => {
			if (object.createdAt) {
				const date = new Date(object.createdAt);
				if (compareDesc(date, min) === 1) {
					return date;
				}
			}
			return min;
		}, new Date())
);

export const extractObjectChildrenIds = createSelector(
	extractObjectsAsArray,
	getObjectId,
	(objects, objectId) =>
		objects.reduce<string[]>((prev, object) => {
			if (object.parentId === objectId) {
				prev.push(object.id);
			}
			return prev;
		}, [])
);

/**
 * Возвращает массив id объектов, которые можно использовать в запросах к серверу.
 * Для конечного объекта вернётся его id, для родительского - id всех объектов-потомков.
 * При необходимости, можно будет доработать, чтобы включался родительский объект также.
 */
export const extractTargetObjects = createSelector(
	extractCurrentTreeObject,
	getObjectId,
	(object, objectId) => {
		if (object?.children?.length) {
			return getAllTreeKeys(object.children);
		}
		return [objectId];
	}
);

export const extractObjectIdsForCompany = createSelector(
	getCompanyId,
	extractObjectsAsArray,
	(companyId, objects) => {
		const objectIds: string[] = [];
		for (const object of objects) {
			if (object.companyId === companyId) {
				objectIds.push(object.id);
			}
		}
		return objectIds;
	}
);
