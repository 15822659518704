import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

const extractAcceptanceData = (state: IState) => state.entities.ownerAcceptance || {};

/**
 * Извлекает текущую передачу
 */
export const extractOwnerAcceptance = createSelector(
	[extractAcceptanceData],
	acceptance => acceptance.data
);

/**
 * Извлекает нарушения передачи
 */
export const extractOwnerAcceptanceProblems = createSelector([extractAcceptanceData], data =>
	data.problems.allIds.map(id => data.problems.byId[id]));

/**
 * Извлекает ответы на нарушения у передачи
 */
export const extractOwnerAcceptanceProblemReplies = createSelector([extractAcceptanceData], data =>
	data.problemReplies.allIds.map(id => data.problemReplies.byId[id]));

/**
 * Извлекает ответы в виде объекта с ключами id нарушений
 */
export const extractOwnerAcceptanceProblemCommentsByProblem = createSelector(
	[extractOwnerAcceptanceProblemReplies],
	replies =>
		replies.reduce<Record<string, IProblemComment>>((prev, item) => {
			if (!prev[item.problemId]) {
				prev[item.problemId] = item;
			}
			return prev;
		}, {})
);

/**
 * Извлекает сущности передачи
 */
export const extractOwnerAcceptanceEntities = createSelector(
	[extractOwnerAcceptanceProblems],
	problems =>
		problems
			.map<IOwnerAcceptanceEntity>(item => ({
				id: `p_${item.id}`,
				type: 'problem',
				data: item
			}))
			.sort((a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0))
);
