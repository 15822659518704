import {wsConnector} from '../wsConnector';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';

export type IGetProblemResponse = ILinkedProblem;

/**
 * Получает нарушение по id
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const requestProblem = (objectId: string, problemId: string) => {
	const params = {objectId, problemId};
	return wsConnector.sendAuthorizedRequest<IGetProblemResponse>('getProblem', params);
};
