import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {wsConnector} from '@src/api/backend/wsConnector';

export type IGetObjectResponse = IObject;

/**
 * Получает объект
 *
 * @param objectId id объекта
 */
export const requestObject = (objectId: string) =>
	wsConnector.sendAuthorizedRequest<IGetObjectResponse>('getObject', {objectId});
