import {Column} from 'react-table';
import MenuCell from './MenuCell';
import LocationCell from './LocationCell';
import TransferStatusCell from './TransferStatusCell';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';

export const mobileColumns: Array<Column<IInspectionEntity>> = [
	{
		id: 'number',
		Header: '',
		accessor: row => row.data.number ?? row.data.localNumber,
		width: 65,
		className: 'inspections-page__m-table-number'
	},
	{
		id: 'object',
		Header: 'Объект',
		accessor: row => row.data.object?.name,
		width: 100
	},
	{
		id: 'location',
		Header: 'Место',
		Cell: LocationCell,
		width: 100,
		defaultCanSort: false
	},
	{
		Header: 'Автор',
		accessor: row => row.data.createdBy,
		width: 100
	},
	{
		Header: 'Добавлено',
		accessor: row => row.data.createdAt,
		width: 100,
		sortDescFirst: true
	},
	{
		Header: 'Изменено',
		accessor: row => row.data.modifiedAt,
		width: 100,
		sortDescFirst: true
	},
	{
		id: '_transferStatus',
		accessor: row => row.data.transferStatus,
		width: 260,
		Cell: TransferStatusCell
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		className: 'inspections-page__m-table-menu'
	}
];

export const mobileColumnsWithoutObject = mobileColumns.filter(item => item.id !== 'object');
