import IUser from '@tehzor/tools/interfaces/IUser';
import {testProblemCommentRestrictions} from '@src/utils/testRestrictions';
import {useMemo} from 'react';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

export interface IPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param objectId id объекта
 * @param user текущий пользователь
 * @param comment комментарий
 */
export const useCommentPermissions = (
	objectId: string,
	user: IUser | undefined,
	comment: IProblemComment
): IPermissions =>
	useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		if (comment.official) {
			return {
				canEdit: testProblemCommentRestrictions(
					user.id,
					user.roles,
					'problemRepliesEdit',
					objectId,
					comment.createdBy
				),
				canDelete: testProblemCommentRestrictions(
					user.id,
					user.roles,
					'problemRepliesDelete',
					objectId,
					comment.createdBy
				),
				canExport: testProblemCommentRestrictions(
					user.id,
					user.roles,
					'problemRepliesExport',
					objectId,
					comment.createdBy
				)
			};
		}
		return {
			canEdit: testProblemCommentRestrictions(
				user.id,
				user.roles,
				'problemCommentsEdit',
				objectId,
				comment.createdBy
			),
			canDelete: testProblemCommentRestrictions(
				user.id,
				user.roles,
				'problemCommentsDelete',
				objectId,
				comment.createdBy
			)
		};
	}, [objectId, user, comment]);
