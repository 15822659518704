export const loaderSplit = async (promises: Array<Promise<unknown>>): Promise<void> => {
	const blocks: Array<Array<Promise<unknown>>> = [[]];
	let blockIndex = 0;

	promises.forEach((promise, i) => {
		blocks[blockIndex].push(promise);

		if ((i + 1) % 5 === 0 && i !== promises.length - 1) {
			blockIndex++;
			blocks[blockIndex] = [];
		}
	});

	for (const block of blocks) {
		await Promise.allSettled(block);
	}
};