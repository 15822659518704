import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IEditTaskResponse, makeTaskEditRequest} from '@src/api/backend/task';
import {ISavingTask} from '@tehzor/tools/interfaces/tasks/ISavingTask';
import {queryClient} from '@src/api/QueryClient';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';

export type IEditTaskPayload = IEditTaskResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditTaskResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при обновлении задачи');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет задачу
 *
 * @param id задачи
 * @param fields данные для добавления
 */
export const editTask = (id: string, fields: ISavingTask) =>
	createApiAction<IEditTaskResponse>(request, success, failure, async () => {
		const data = await makeTaskEditRequest(id, fields);
		await queryClient.invalidateQueries(tasksQueryKeys.all());
		return data;
	});
