import React from 'react';
import './EntityGridItem.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../../../utils/convertClassNames';

export interface IEntityGridItemProps {
	className?:
		| string
		| {
				root?: string;
				header?: string;
				content?: string;
		  };
	style?: React.CSSProperties;
	children?: React.ReactNode;
	icon?: React.ReactNode;
	label?: string;
	fullRow?: boolean;
	inline?: boolean;
	buttons?: React.ReactNode;
	onClick?: () => void;
}

export const EntityGridItem = ({
	className,
	style,
	children,
	icon,
	label,
	fullRow,
	inline,
	buttons,
	onClick
}: IEntityGridItemProps) => {
	const classes = convertClassNames(className);

	return (
		<div
			className={classNames(
				'entity-grid-item',
				{'entity-grid-item_full': fullRow},
				{'entity-grid-item_inline': inline},
				classes.root
			)}
			style={style}
			onClick={onClick}
		>
			<div className={classNames(classes.header, 'entity-grid-item__header')}>
				{icon !== undefined && <div className="entity-grid-item__icon">{icon}</div>}
				{label !== undefined && <div className="entity-grid-item__label">{label}</div>}
				{buttons !== undefined && <div className="entity-grid-item__buttons">{buttons}</div>}
			</div>

			{children ? (
				<div className={classNames('entity-grid-item__content', classes.content)}>{children}</div>
			) : null}
		</div>
	);
};
