import React, {forwardRef, Ref} from 'react';
import './ColorSelectButton.less';
import ButtonBase from '../ButtonBase';

interface IColorSelectButtonProps {
	className?: string;
	style?: React.CSSProperties;
	color?: string;
	disabled?: boolean;
	selected?: boolean;

	onClick?(event: React.MouseEvent): void;
}

const ColorSelectButton = (
	{className, style, color, disabled, onClick, selected}: IColorSelectButtonProps,
	ref?: Ref<HTMLButtonElement>
) => (
	<div
		className="color-select-button_border"
		style={{border: selected ? `2px solid ${color}` : 'none'}}
	>
		<ButtonBase
			className={className}
			style={{...style, backgroundColor: color}}
			classNamePrefix="color-select-button"
			disabled={disabled}
			outerTagType="button"
			onClick={onClick}
			ref={ref}
		/>
	</div>
);

ColorSelectButton.displayName = 'ColorSelectButton';

export default forwardRef(ColorSelectButton);
