import React, {useState} from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MobileSelectionClearing from './selection/SelectionClearing.mobile';
import MobileRightButtons from './actions/RightButtons.mobile';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import MobileInfo from './info/Info.mobile';
import {Plate} from '@tehzor/ui-components';
import Table from './table/Table';
import {mobileColumns} from './table/columns.mobile';
import EntitiesTabs from './EntitiesTabs';
import EntityAdding from './actions/EntityAdding';
import EntitiesVisibility from './actions/EntitiesVisibility';
import {CheckListsLink} from '@src/components/CheckListsLink';
import {useSpaceStage} from '@src/core/hooks/spaces/useSpaceStage';
import {AdditionalInfo} from './info/AdditionalInfo';
import PageBreadcrumbs from './PageBreadcrumbs';

interface IMobileProps {
	objectId: string;
	spaceId: string;
	space?: ISpace;
}

const Mobile = ({objectId, spaceId, space}: IMobileProps) => {
	const spaceTitle = useSpaceTitle(space);

	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const stage = useSpaceStage(objectId, space);

	useAppHeader(
		{
			title: spaceTitle,
			showBackBtn: true,
			mobileLeftButton:
				selectedRows.length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows}/>
				) : undefined,
			mobileRightButtons: space ? (
				<MobileRightButtons
					objectId={objectId}
					spaceId={spaceId}
					space={space}
					selectedRows={selectedRows}
				/>
			) : null
		},
		[space, selectedRows]
	);

	return space ? (
		<div className="page-cont space-page">
			<PageBreadcrumbs
				objectId={objectId}
				spaceId={spaceId}
			/>
			<MobileInfo
				objectId={objectId}
				space={space}
			/>

			{stage && (
				<div style={{marginTop: '16px'}}>
					<CheckListsLink
						stage={stage}
						objectId={objectId}
						spaceId={spaceId}
					/>
				</div>
			)}

			{space.areaBTI
			|| space.floorBTI
			|| space.numberBTI
			|| space.contractForm
			|| space.markupForRegistration ? (
				<AdditionalInfo space={space}/>
			) : undefined}

			<EntitiesVisibility className="space-page__m-visibility-filter"/>

			<Plate withoutPadding>
				<EntitiesTabs/>

				<Table
					objectId={objectId}
					columns={mobileColumns}
					hideHead
					spaceId={spaceId}
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
				/>
			</Plate>

			<EntityAdding
				objectId={objectId}
				spaceId={spaceId}
			/>
		</div>
	) : null;
};

export default Mobile;
