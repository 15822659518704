import {useRef} from 'react';
import useAsync from 'react-use/lib/useAsync';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
// import useAppSelector from '@src/core/hooks/useAppSelector';
// import {startOfflineDataCaching} from '@src/core/offlineMode/startOfflineDataCaching';

interface IResourcesLoaderProps {
	isOnline?: boolean;
	isAuthorized?: boolean;
	isActivated?: boolean;

	onLoad(): Promise<void>;
}

/**
 * Компонент для первоначальной загрузки ресурсов при старте приложения
 */
export const ResourcesLoader = ({isOnline, isAuthorized, isActivated, onLoad}: IResourcesLoaderProps) => {
	// Флаг, показывающий были ли загружены ресурсы
	const loadingState = useRef(false);
	// const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	// const allLoaded = useAppSelector(s => s.app.loading.status);

	// Загрузка ресурсов для авторизованного и активированного пользователя
	useAsync(async () => {
		if (!loadingState.current && isOnline && isAuthorized && isActivated) {
			await onLoad();
			loadingState.current = true;
		}
	}, [isOnline, isAuthorized, isActivated]);

	// useUpdateEffect(() => {
	// 	if (isOfflineModeAvailable && isOnline && loadingState.current && isAuthorized && isActivated) {
	// 		if (allLoaded === 'loaded') {
	// 			void startOfflineDataCaching();
	// 		}
	// 	}
	// }, [isOnline, loadingState.current, isAuthorized, isActivated, allLoaded]);

	// При логауте необходимо сбросить флаг, чтобы затем была возможность снова загрузить ресурсы
	useUpdateEffect(() => {
		if (!isAuthorized) {
			loadingState.current = false;
		}
	}, [isAuthorized]);

	return null;
};
