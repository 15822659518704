import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import React from 'react';
import {useManageSpaceTypeDecorationBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageSpaceTypeDecorationBreadcrumbs';

export const EditPageBreadcrumbs = () => {
	const breadcrumbs = useManageSpaceTypeDecorationBreadcrumbs();

	return (
		<AppBreadcrumbs
			className="manage-space-type-decoration-edit-page__breadcrumbs"
			items={breadcrumbs}
		/>
	);
};
