import {wsConnector} from '../wsConnector';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export type IEditSpaceTypeDecorationSetResponse = ISpaceTypeDecorationSet;

/**
 * Изменяет набор типов отделки помещения
 *
 * @param fields данные для добавления
 */
export const editSpaceTypeDecorationSetRequest = (fields: ISpaceTypeDecorationSet) =>
	wsConnector.sendAuthorizedRequest<IEditSpaceTypeDecorationSetResponse>(
		'editSpaceTypeDecorationSet',
		{...fields}
	);
