import {wsConnector} from '../wsConnector';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';

export type IAddCheckListResponse = ICheckList;

/**
 * Создает чек-лист
 *
 * @param fields данные для добавления
 */
export const makeCheckListAddRequest = (fields: ISavingCheckList) =>
	wsConnector.sendAuthorizedRequest<IAddCheckListResponse>('addCheckList', {...fields});
