import {combineReducers} from 'redux';
import checkAdding from './checkAdding/reducers';
import locationSelect from './locationSelect/reducers';
import {offlineMode} from './offlineMode/reducers';
import pages from './pages';
import photoViewer from './photoViewer/reducers';
import sidebar from './sidebar/reducers';
import exportReducer from '../../../reducers/settings/export';
import notifications from '../../../reducers/settings/notifications';
import {persistReducer} from 'redux-persist';
import {formPersistConfig} from '@src/store/persistentStorage/formPersistConfig';
import {internalAcceptanceAdding} from './internalAcceptanceAdding/reducers';

const settingsReducer = combineReducers({
	checkAdding,
	locationSelect,
	offlineMode,
	pages,
	photoViewer,
	sidebar,
	export: exportReducer,
	notifications,
	internalAcceptanceAdding
});

export const settings = persistReducer(
	formPersistConfig<ReturnType<typeof settingsReducer>>('settings'),
	settingsReducer
);
