import React, {useEffect, useMemo, useState} from 'react';
import './TimeSelect.less';

export interface ITimeSelectProps {
	value?: Date;
	onChangeTime?: (arg: Date) => void;
}

export const TimeSelect = (props: ITimeSelectProps) => {
	const {
		value,
		onChangeTime
	} = props;

	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);

	useEffect(() => {
		if (value === undefined) return;

		setHours(value.getHours());
		setMinutes(value.getMinutes());
	}, [value]);

	const handleHoursClick = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const hour = Number(e.target?.value);

		setHours(hour);

		if (onChangeTime !== undefined && value !== undefined) {
			onChangeTime(new Date(new Date(value).setHours(hour, minutes, 0, 0)));
		}
	};

	const handleMinutesClick = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const min = Number(e.target?.value);

		setMinutes(min);

		if (onChangeTime !== undefined && value !== undefined) {
			onChangeTime(new Date(new Date(value).setHours(hours, min, 0, 0)));
		}
	};

	const hoursOptions = useMemo(
		() => (
			<>
				{[...Array(24).keys()].map(el => (
					<option
						className="time-select-hours-option"
						value={el}
						key={el}
					>
						{el < 10 ? `0${el}` : el}
					</option>
				))}
			</>
		), []
	);

	const minutesOptions = useMemo(
		() => (
			<>
				{[...Array(60).keys()].map(el => (
					<option
						className="time-select-minutes-option"
						value={el}
						key={el}
					>
						{el < 10 ? `0${el}` : el}
					</option>
				))}
			</>
		), []
	);

	return (
		<div className="time-select">
			<span className="time-select-hours">
				<select
					className="time-select-hours-select"
					name="hours"
					id="hours"
					value={hours}
					onChange={handleHoursClick}
					disabled={value === undefined}
				>
					{hoursOptions}
				</select>
			</span>
			<span className="time-select-minutes">
				<select
					className="time-select-minutes-select"
					name="minutes"
					id="minutes"
					value={minutes}
					onChange={handleMinutesClick}
					disabled={value === undefined}
				>
					{minutesOptions}
				</select>
			</span>
		</div>
	);
};
