import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {clearExpiration} from '@src/store/modules/entities/problems/actions';
import {stringify} from 'query-string';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

export interface IUseNavigateProblemsProps {
	page: 'acceptance' | 'building' | 'warranty';
	navProps?: Array<{
		type: string;
		prop: string | string[];
	}>;

	objectId?: string;
}

export const useNavigateProblems = (
	{navProps, objectId, page}: IUseNavigateProblemsProps,
	objectsIds: string[] | undefined
) => {
	const {pushPath} = useChangePath();
	let path = '';
	const stage = useAppSelector(s => s.settings.pages.statistics.section);
	const createdBy = useAppSelector(s => s.settings.pages.statistics[page].filters.users);
	const contractors = useAppSelector(s => s.settings.pages.statistics[page].filters.contractors);
	const companies = useAppSelector(s => s.dictionaries.companies.byId);

	const respUsers: string[] = [];
	if (contractors) {
		Object.keys(contractors).forEach((contracorKey: keyof typeof contractors) => {
			const contractor = contractors[contracorKey];
			if (contractor === true) {
				const contractorUsers = companies[contracorKey]?.employees?.map(employ => employ.userId) || [];
				respUsers.push(...contractorUsers);
			} else {
				respUsers.push(...contractor);
			}
		});
	}

	const filterObjectsIds = useAppSelector(
		s => s.settings.pages.statistics[page].filters?.objects
	);
	const dispatch = useAppDispatch();

	// Если есть данные в filterObjectsIds.objectsIds и в objectsIds то передаем только id, которые есть и там и там
	const objects
		= filterObjectsIds && filterObjectsIds.length
			? filterObjectsIds?.filter(obj => !objectsIds || objectsIds.includes(obj))
			: objectsIds;

	const filters: IProblemsFiltersState = {
		stages: stage
			? [
				stage
					.split('')
					.filter(val => val !== '/')
					.join('')
			]
			: undefined,
		createdBy: createdBy ? Array.from(new Set(createdBy)) : [],
		respUsers,
		objects
	};

	if (!objectsIds && navProps) {
		navProps.forEach(item => {
			filters[item.type] = Array.isArray(item.prop) ? [...item.prop] : [item.prop];
		});
	}

	if (objectId) {
		path += `/objects/${objectId}`;
		if (filters.objects) {
			filters.objects.push(objectId);
		}
	}
	navProps?.forEach(item => {
		if (item.type !== 'objects') {
			filters[item.type] = Array.isArray(item.prop) ? [...item.prop] : [item.prop];
		}
	});
	path += '/problems/?';

	return (status?: string) => {
		void dispatch(clearExpiration(objectId || 'all'));
		if (status !== ProblemStatusId.EXPIRED) {
			filters.statuses = status ? [status] : undefined;
		} else {
			filters.expired = [status];
		}
		path += stringify(filters, {arrayFormat: 'index'});
		pushPath(path);
	};
};
