import {IEditableEntityState, IEditableEntityAction, isEntityEdited, isPropEdited} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {IContract, ISavingContract} from '@tehzor/tools/interfaces/contracts';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IEditableContractState = IEditableEntityState<{
	companyId?: string;
	contractorId?: string;
	companyLegalEntityId?: string;
	contractorLegalEntityId?: string;
	companyBankAccountId?: string | null;
	contractorBankAccountId?: string | null;
	companyAuthorizedPersonId?: string | null;
	contractorAuthorizedPersonId?: string | null;
	name?: string;
	number?: string | null;
	stageId?: ObjectStageIds;
	objectIds?: string[] | null;
	categoryIds?: string[] | null;
	planIds?: string[] | null;
	signingDate?: number | null;
	startDate?: number | null;
	endDate?: number | null;
	priceNumber?: number | null;
	priceText?: string | null;
	fine?: string | null;
	attachments?: IAttachment[];
}>;

export type IEditableContractAction = IEditableEntityAction<IEditableContractState, ISavingContract>;

export const makeEmptyState = (): IEditableContractState => ({
	companyId: undefined,
	contractorId: undefined,
	companyLegalEntityId: undefined,
	contractorLegalEntityId: undefined,
	companyBankAccountId: undefined,
	contractorBankAccountId: undefined,
	companyAuthorizedPersonId: undefined,
	contractorAuthorizedPersonId: undefined,
	name: undefined,
	number: undefined,
	stageId: undefined,
	objectIds: undefined,
	categoryIds: undefined,
	planIds: undefined,
	signingDate: undefined,
	startDate: undefined,
	endDate: undefined,
	priceNumber: undefined,
	priceText: undefined,
	fine: undefined,
	attachments: undefined,
	errors: {
		companyId: false,
		contractorId: false,
		companyLegalEntityId: false,
		contractorLegalEntityId: false,
		companyBankAccountId: false,
		contractorBankAccountId: false,
		companyAuthorizedPersonId: false,
		contractorAuthorizedPersonId: false,
		name: false,
		number: false,
		stageId: false,
		objectIds: false,
		categoryIds: false,
		planIds: false,
		signingDate: false,
		startDate: false,
		endDate: false,
		priceNumber: false,
		priceText: false,
		fine: false,
		attachments: false
	}
});

export const makeDefaultData = (
	contract?: IContract, companies?: ICompany[]
): ISavingContract | undefined => {
	if (!contract) {return undefined;}

	const company = companies?.find(item =>
		contract?.companyId === item?.uuid);
	const contractor = companies?.find(item =>
		contract?.contractorId === item?.uuid);

	return {
		companyId: company?.id,
		contractorId: contractor?.id,
		companyLegalEntityId: contract.companyLegalEntityId,
		contractorLegalEntityId: contract.contractorLegalEntityId,
		companyBankAccountId: contract.companyBankAccountId,
		contractorBankAccountId: contract.contractorBankAccountId,
		companyAuthorizedPersonId: contract.companyAuthorizedPersonId,
		contractorAuthorizedPersonId: contract.contractorAuthorizedPersonId,
		name: contract.name,
		number: contract.number,
		stageId: contract.stageId,
		objectIds: contract.objectIds,
		categoryIds: contract.categoryIds,
		planIds: contract.planIds,
		signingDate: contract.signingDate,
		startDate: contract.startDate,
		endDate: contract.endDate,
		priceNumber: contract.priceNumber,
		priceText: contract.priceText,
		fine: contract.fine
	};
};

export const init = (
	original?: ISavingContract
): IEditableContractState => {
	const empty = makeEmptyState();
	if (!original) {
		return empty;
	}

	const state: IEditableContractState = {
		...original, errors: empty.errors
	};
	return state;
};

export const isEdited = (
	state: IEditableContractState, original?: IContract
): boolean =>
	isEntityEdited(
		state,
		original,
		// ! TODO (contracts) Когда полностью перейдем на go сервисы убрать комменты 
		// isPropEdited.bind(null, 'companyId'),
		// isPropEdited.bind(null, 'contractorId'),
		isPropEdited.bind(null, 'companyLegalEntityId'),
		isPropEdited.bind(null, 'contractorLegalEntityId'),
		isPropEdited.bind(null, 'companyBankAccountId'),
		isPropEdited.bind(null, 'contractorBankAccountId'),
		isPropEdited.bind(null, 'companyAuthorizedPersonId'),
		isPropEdited.bind(null, 'contractorAuthorizedPersonId'),
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'number'),
		isPropEdited.bind(null, 'stageId'),
		isPropEdited.bind(null, 'objectIds'),
		isPropEdited.bind(null, 'categoryIds'),
		isPropEdited.bind(null, 'planIds'),
		isPropEdited.bind(null, 'signingDate'),
		isPropEdited.bind(null, 'startDate'),
		isPropEdited.bind(null, 'endDate'),
		isPropEdited.bind(null, 'priceNumber'),
		isPropEdited.bind(null, 'priceText'),
		isPropEdited.bind(null, 'fine')
	);

export const errorsFns = {
	companyId: (state: IEditableContractState) => !state.companyId,
	contractorId: (state: IEditableContractState) => !state.contractorId,
	companyLegalEntityId: (state: IEditableContractState) =>
		!state.companyLegalEntityId,
	contractorLegalEntityId: (state: IEditableContractState) =>
		!state.contractorLegalEntityId,
	companyBankAccountId: (state: IEditableContractState) =>
		!state.companyBankAccountId,
	contractorBankAccountId: (state: IEditableContractState) =>
		!state.contractorBankAccountId,
	companyAuthorizedPersonId: (state: IEditableContractState) =>
		!state.companyAuthorizedPersonId,
	contractorAuthorizedPersonId: (state: IEditableContractState) =>
		!state.contractorAuthorizedPersonId,
	name: (state: IEditableContractState) => !state.name,
	number: (state: IEditableContractState) => !state.number,
	stageId: (state: IEditableContractState) => !state.stageId,
	objectIds: (state: IEditableContractState) => !state.objectIds?.length,
	categoryIds: (state: IEditableContractState) =>
		!state.categoryIds?.length,
	planIds: (state: IEditableContractState) => !state.planIds?.length,
	signingDate: (state: IEditableContractState) => !state.signingDate,
	startDate: (state: IEditableContractState) => !state.startDate,
	endDate: (state: IEditableContractState) => !state.endDate,
	priceNumber: (state: IEditableContractState) => !state.priceNumber,
	priceText: (state: IEditableContractState) => !state.priceText,
	fine: (state: IEditableContractState) => !state.fine
};

export const convertToSave = (
	state: IEditableContractState,
	original?: IContract,
	companies?: ICompany[]
): ISavingContract => {
	const defaultData = makeDefaultData(original, companies);
	const company = companies?.find(item => item.id === state.companyId);
	const contractor = companies?.find(item => item.id === state.contractorId);
	const contract: ISavingContract = {
		companyId: company?.uuid,
		contractorId: contractor?.uuid
	};

	if (isPropEdited('companyLegalEntityId', state, defaultData)) {
		contract.companyLegalEntityId = state.companyLegalEntityId;
	}
	if (isPropEdited('contractorLegalEntityId', state, defaultData)) {
		contract.contractorLegalEntityId = state.contractorLegalEntityId;
	}
	if (isPropEdited('companyBankAccountId', state, defaultData)) {
		contract.companyBankAccountId = state.companyBankAccountId;
	}
	if (isPropEdited('contractorBankAccountId', state, defaultData)) {
		contract.contractorBankAccountId = state.contractorBankAccountId;
	}
	if (isPropEdited('companyAuthorizedPersonId', state, defaultData)) {
		contract.companyAuthorizedPersonId = state.companyAuthorizedPersonId;
	}
	if (isPropEdited('contractorAuthorizedPersonId', state, defaultData)) {
		contract.contractorAuthorizedPersonId = state.contractorAuthorizedPersonId;
	}
	if (isPropEdited('name', state, defaultData)) {
		contract.name = state.name;
	}
	if (isPropEdited('number', state, defaultData)) {
		contract.number = state.number;
	}
	if (isPropEdited('stageId', state, defaultData)) {
		contract.stageId = state.stageId;
	}
	if (isPropEdited('objectIds', state, defaultData)) {
		contract.objectIds = state.objectIds;
	}
	if (isPropEdited('categoryIds', state, defaultData)) {
		contract.categoryIds = state.categoryIds;
	}
	if (isPropEdited('planIds', state, defaultData)) {
		contract.planIds = state.planIds;
	}
	if (isPropEdited('signingDate', state, defaultData)) {
		contract.signingDate = state.signingDate;
	}
	if (isPropEdited('startDate', state, defaultData)) {
		contract.startDate = state.startDate;
	}
	if (isPropEdited('endDate', state, defaultData)) {
		contract.endDate = state.endDate;
	}
	if (isPropEdited('priceNumber', state, defaultData)) {
		contract.priceNumber = state.priceNumber;
	}
	if (isPropEdited('priceText', state, defaultData)) {
		contract.priceText = state.priceText;
	}
	if (isPropEdited('fine', state, defaultData)) {
		contract.fine = state.fine;
	}

	return contract;
};