import React, {memo, useMemo} from 'react';
import {Plate, TabLink, Tabs} from '@tehzor/ui-components';

export type ViewTabsMobileVariants = 'main' | 'bankAccounts' | 'authorizedPersons';

const tabsIndexes: Record<ViewTabsMobileVariants, number> = {
	main: 0,
	bankAccounts: 1,
	authorizedPersons: 2
};

const tabsKeys: ViewTabsMobileVariants[] = ['main', 'bankAccounts', 'authorizedPersons'];

interface IViewTabsMobileProps {
	activeTab: ViewTabsMobileVariants;
	setActiveTab: (variant: ViewTabsMobileVariants) => void;
	bankAccountsCount: number;
	authorizedPersonsCount: number;
}

export const ViewTabsMobile = memo((props: IViewTabsMobileProps) => {
	const {activeTab, setActiveTab, bankAccountsCount, authorizedPersonsCount} = props;
	const handleChange = (index: number) => setActiveTab(tabsKeys[index]);

	const tabs = useMemo(() => {
		return [
			<TabLink label="Описание" />,
			<TabLink
				label={
					<span className="legal-page__view-tab">
						Реквизиты{' '}
						<span className="legal-page__view-tab-title">{bankAccountsCount}</span>
					</span>
				}
			/>,
			<TabLink
				label={
					<span className="legal-page__view-tab">
						Уполномоченные лица{' '}
						<span className="legal-page__view-tab-title">{authorizedPersonsCount}</span>
					</span>
				}
			/>
		];
	}, [bankAccountsCount, authorizedPersonsCount]);

	return (
		<Plate withoutPadding>
			<Tabs
				className="legal-page__view-tabs-mobile"
				links={tabs}
				activeTab={tabsIndexes[activeTab]}
				onActiveTabChange={handleChange}
			/>
		</Plate>
	);
});
