import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import arrayToTree from 'array-to-tree';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';

const extractAllIds = (state: IState): string[] => state.dictionaries.checkItems.allIds || [];
const extractById = (state: IState): Record<string, ICheckItem> => state.dictionaries.checkItems.byId || {};
const getCheckListId = (state: IState, checkListId: string | undefined) => checkListId;
const getCheckItemId = (state: IState, checkItemId: string | undefined) => checkItemId;

/**
 * Возвращает категории чек-листа в виде массива
 */
export const extractAllCheckItems = createSelector([extractAllIds, extractById], (allIds, byId) =>
	allIds.map(id => byId[id]));

/**
 * Возвращает категории чек-листа в виде массива
 */
export const extractCheckItems = createSelector(
	[extractAllCheckItems, getCheckListId],
	(items, checkListId) => items.filter(item => item.checkListId === checkListId)
);

/**
 * Возвращает категории верхнего уровня чек-листа в виде массива
 */
export const extractRootCheckItems = createSelector([extractCheckItems], items =>
	items.filter(el => el.parentId === undefined));

/**
 * Возвращает категории чек-листа в виде дерева
 */
export const extractCheckItemsAsTree = createSelector([extractCheckItems], items =>
	arrayToTree<ICheckItem>(items, {
		parentProperty: 'parentId',
		customID: 'id'
	}));

/**
 * Возвращает категорию чек-листа по id
 */
export const extractCheckItemById = createSelector(
	[extractById, getCheckItemId],
	(data, checkItemId) => (checkItemId ? data[checkItemId] : undefined)
);

/**
 * Возвращает категорию чек-листа по id в виде элемента дерева
 */
export const extractTreeCheckItemById = createSelector(
	[extractCheckItemsAsTree, (state: IState, _: unknown, checkItemId: string | undefined) => checkItemId],
	(tree, checkItemId) => (checkItemId ? findTreeNode<ICheckItem>(tree, checkItemId) : undefined)
);

/**
 * Возвращает категории, сгруппированные по id чек-листов
 */
export const extractCheckItemsGroupedByLists = createSelector(
	[extractAllCheckItems],
	items => items.reduce<Record<string, ICheckItem[]>>((prev, item) => {
			if (!prev[item.checkListId]) {
				prev[item.checkListId] = [];
			}
			prev[item.checkListId].push(item);
			return prev;
		}, {})
);