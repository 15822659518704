import React, {useCallback, useEffect} from 'react';
import {Menu, MenuItem, DialogMenu} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeType} from '@src/store/modules/settings/pages/spaces/actions';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SpaceTypeMenuButton} from '@src/pages/SpacesPage/components/typeFilter/SpaceTypeMenuButton';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';

interface ISpaceTypeMenuProps {
	objectId: string;
	types: Array<[ISpaceType, {spaces: number, filtred: number}]>;
}

export const SpaceTypeMenu = ({objectId, types}: ISpaceTypeMenuProps) => {
	const selectedType = useAppSelector(s => extractSpacesPageSettings(s, objectId).type);
	const typesMap = useAppSelector(s => s.dictionaries.spaceTypes.byId);
	const dispatch = useAppDispatch();

	// При переходе на страницу в первый раз (то есть нет сохраненного выбранного типа),
	// необходимо установить тип по умолчанию
	useEffect(() => {
		if (selectedType === undefined && types.length) {
			dispatch(changeType(objectId, types[0][0].id));
		}
	}, [types]);

	const handleTypeChange = useCallback(
		(type: string) => {
			dispatch(changeType(objectId, type));
		},
		[objectId]
	);

	const selectedTypeCount = types.find(type => selectedType && selectedType === type[0].id)?.[1];
	const btnLabel
		= (selectedType
			&& selectedTypeCount
			&& `${typesMap[selectedType]?.name} (${
				selectedTypeCount.filtred !== selectedTypeCount.spaces
					? `${selectedTypeCount.filtred}/${selectedTypeCount.spaces}`
					: selectedTypeCount.filtred
			})`)
		|| 'Выбрать тип';

	return types.length ? (
		<DialogMenu
			className="spaces-page__space-type-menu-dialog"
			trigger={<SpaceTypeMenuButton label={btnLabel}/>}
		>
			<Menu
				value={selectedType}
				onChange={handleTypeChange}
			>
				{types.map(([type, count]) => (
					<MenuItem
						key={type.id}
						itemKey={type.id}
					>
						{`${type.name} (${
							count.filtred !== count.spaces
								? `${count.filtred}/${count.spaces}`
								: count.filtred
						})`}
					</MenuItem>
				))}
			</Menu>
		</DialogMenu>
	) : null;
};
