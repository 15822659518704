import React from 'react';
import './InspectionsPage.less';
import {LoadingPanel} from '@tehzor/ui-components';
import classNames from 'classnames';
import InspectionsTable from './components/table/InspectionsTable';
import Filters from './components/InspectionsFilters';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import ActionsDesktop from './components/actions/Actions.desktop';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import MobileEntityAdding from './components/actions/InspectionAdding.mobile';
import InspectionsPageCounter from './components/InspectionsPageCounter';
import {useIsFetching} from '@tanstack/react-query';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useParams} from 'react-router-dom';

interface IInspectionsPageProps {
	className?: string;
}

export const InspectionsPage = ({className}: IInspectionsPageProps) => {
	useScrollRestoration();
	const {objectId} = useParams<{objectId?: string}>();
	const isFetching = useIsFetching({queryKey: inspectionsQueryKeys.list()});
	const isDesktop = useIsDesktop();

	const actions = <ActionsDesktop objectId={objectId}/>;

	return (
		<LoadingPanel
			className="inspections-page__loading-panel"
			active={!!isFetching}
		>
			<div
				className={classNames(
					'page-cont',
					'inspections-page',
					{'inspections-page_small-margin': !objectId},
					className
				)}
			>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}>
						{isDesktop && actions}
					</ObjectPageBreadcrumbs>
				) : (
					isDesktop && <AllPageBreadcrumbs>{actions}</AllPageBreadcrumbs>
				)}
				<div className="inspections-page__filters-bar">
					<Filters objectId={objectId}/>
				</div>
				<InspectionsPageCounter objectId={objectId}/>
				<InspectionsTable objectId={objectId}/>
				{objectId && !isDesktop && <MobileEntityAdding objectId={objectId}/>}
			</div>
		</LoadingPanel>
	);
};
