import {PrivateRoute} from '@src/containers/PrivateRoute';
import {SpaceStatusesSetPage} from '@src/pages/SpaceStatusesSetPage/SpaceStatusesSetPage';
import {AllSpaceStatusesSetsPage} from '@src/pages/SpaceStatusesSetsPage';
import {SpaceStatusesSetsFiltersRoutingPage} from '@src/pages/filters/SpaceStatusesSetsFiltersRoutingPage';
import React from 'react';
import {spaceStatusesSetsFilterRoutes} from './spaceStatusesSetsFilterRoutes';

export const manageSpaceStatusesSetsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllSpaceStatusesSetsPage/>}/>
	},
	{
		path: ':spaceStatusesSetId',
		element: <PrivateRoute element={<SpaceStatusesSetPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<SpaceStatusesSetsFiltersRoutingPage/>}/>,
		children: spaceStatusesSetsFilterRoutes
	}
];
