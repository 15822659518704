import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {useLocalChecksJournal} from './useLocalChecksJournal';
import {useLocalInspectionsJournal} from './useLocalInspectionsJournal';
import {useLocalInternalAcceptancesJournal} from './useLocalInternalAcceptancesJournal';
import {useLocalOwnerAcceptancesJournal} from './useLocalOwnerAcceptances';
import {useLocalProblemsJournal} from './useLocalProblemsJournal';
import {useLocalWarrantyClaimsJournal} from './useLocalWarrantyClaimsJournal';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {useMemo} from 'react';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {offlineJournalActionStatus} from '@src/pages/filters/OfflineJournalFilterPage/data/entityFilters';
import {useLocalCheckRecordsChangesJournal} from './useLocalCheckRecordsChangesJournal';
import {useLocalSpaceIndicatorsChangesJournal} from './useLocalSpaceIndicatorsChangesJournal';
import {useLocalSpaceStatusChangesJournal} from './useLocalSpaceStatusChangesJournal';

export const useJournalData = (): IOfflineJournalEntity[] => {
	const {state: filters} = useEntitiesFiltersCtx<IOfflineJournalFiltersState>();
	const problems = useLocalProblemsJournal();
	const inspections = useLocalInspectionsJournal();
	const checks = useLocalChecksJournal();
	const internalAcceptances = useLocalInternalAcceptancesJournal();
	const ownerAcceptances = useLocalOwnerAcceptancesJournal();
	const warrantyClaims = useLocalWarrantyClaimsJournal();
	const checkRecordsChanges = useLocalCheckRecordsChangesJournal();
	const spaceIndicatorsChanges = useLocalSpaceIndicatorsChangesJournal();
	const spaceStatusChanges = useLocalSpaceStatusChangesJournal();

	return useMemo(() => {
		const filteredEntities: IOfflineJournalEntity[] = [
			...inspections,
			...problems,
			...internalAcceptances,
			...ownerAcceptances,
			...checks,
			...warrantyClaims,
			...checkRecordsChanges,
			...spaceIndicatorsChanges,
			...spaceStatusChanges
		].filter((item: IOfflineJournalEntity) => {
			const createdAt = new Date(item.createdAt ?? 0);
			const createdAtTimestamp = createdAt.getTime();
			const filterFromTimestamp = filters.createdAtFrom?.getTime() ?? 0;
			const filterToTimestamp = filters.createdAtTo?.getTime() ?? Number.MAX_VALUE;
			const actions = filters.actions ?? [];
			const statuses = filters.statuses ?? [];
			return (
				createdAtTimestamp >= filterFromTimestamp
				&& createdAtTimestamp <= filterToTimestamp
				&& (actions.length === 0
					|| (item.entity.entityType && actions.includes(item.entity.entityType)))
				&& (statuses.length === 0
					|| statuses.length === 2
					|| (statuses.includes(offlineJournalActionStatus.WITH_ERRORS)
						&& item.transfer.status === OfflineDataTransferStatus.TRANSFER_ERROR)
					|| (statuses.includes(offlineJournalActionStatus.WITHOUT_ERRORS)
						&& item.transfer.status !== OfflineDataTransferStatus.TRANSFER_ERROR))
			);
		});
		return filteredEntities;
	}, [
		problems,
		inspections,
		checks,
		internalAcceptances,
		ownerAcceptances,
		warrantyClaims,
		checkRecordsChanges,
		spaceIndicatorsChanges,
		spaceStatusChanges,
		filters
	]);
};
