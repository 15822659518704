import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetProblemCommentsResponse extends INormalizedData<IProblemComment> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список комментариев на нарушения
 */
export async function requestProblemComments(
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	return wsConnector.sendAuthorizedRequest<IGetProblemCommentsResponse>(
		'getProblemComments',
		params
	);
}
