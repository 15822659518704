import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import ISavingProblemComment from '@tehzor/tools/interfaces/problemComments/ISavingProblemComment';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';

export type IEditableProblemCommentState = IEditableEntityState<{
	description: string;
	attachments: IAttachment[];
}>;

export type IEditableProblemCommentAction = IEditableEntityAction<
	IEditableProblemCommentState,
	IProblemComment
>;

const makeEmptyState = (): IEditableProblemCommentState => ({
	description: '',
	attachments: [],
	errors: {
		description: false,
		attachments: false
	}
});

export const init = (comment?: IProblemComment): IEditableProblemCommentState => {
	const empty = makeEmptyState();
	if (!comment) {
		return empty;
	}
	return {
		description: comment.description || empty.description,
		attachments: comment.attachments || empty.attachments,
		errors: empty.errors
	};
};

const isDescriptionEdited = (edited: IEditableProblemCommentState, original?: IProblemComment) =>
	(original?.description ? original.description !== edited.description : !!edited.description);

const areAttachmentsEdited = (edited: IEditableProblemCommentState, original?: IProblemComment) =>
	(original?.attachments ? original.attachments.length !== edited.attachments.length : false);

/**
 * Возвращает значение, показывающее были ли отредактированы поля нарушения
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (
	state: IEditableProblemCommentState,
	original?: IProblemComment
): boolean => isEntityEdited(state, original, isDescriptionEdited, areAttachmentsEdited);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	description: (state: IEditableProblemCommentState) => !state.description,
	attachments: (state: IEditableProblemCommentState) => !state.attachments.length
};

/**
 * Проверяет, есть ли ошибки в полях сущности
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasErrors = (
	state: IEditableProblemCommentState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.description?.isRequired && errorsFns.description(state);

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableProblemCommentState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableProblemCommentState,
	original?: IProblemComment,
	onlyEdited?: boolean
): ISavingProblemComment => {
	if (!onlyEdited) {
		return {
			description: state.description,
			attachments: state.attachments
		};
	}
	const comment: ISavingProblemComment = {};
	if (isDescriptionEdited(state, original)) {
		comment.description = state.description;
	}
	if (areAttachmentsEdited(state, original)) {
		comment.attachments = state.attachments.map(item => ({id: item.id}));
	}
	return comment;
};
