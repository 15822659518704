import React, {createContext, useCallback, useMemo} from 'react';
import {
	EntitiesTable,
	Pagination,
	PaginationPageSize,
	Plate,
	PaginationAndSize
} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import {extractInspectionsData} from '@src/store/modules/entities/inspections/selectors';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {convertInspections} from '@src/pages/InspectionsPage/utils/convertInspections';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {changeOffset, changeSelectedRows} from '@src/store/modules/entities/inspections/actions';
import {changePageSize, changeSort} from '@src/store/modules/settings/pages/inspections/actions';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {mobileColumns, mobileColumnsWithoutObject} from './mobileColumns';
import {desktopColumns, desktopColumnsWithoutObject} from './desktopColumns';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/reducers';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';
import {
	inspectionsList,
	useInspections,
	useLocalInspections
} from '@src/core/hooks/queries/inspections';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useQueryClient} from '@tanstack/react-query';

const pageSizes = [10, 20, 50, 100];

interface IInspectionsTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IInspectionEntity>) => void
>(() => ({}));

const InspectionsTable = ({objectId = 'all'}: IInspectionsTableProps) => {
	const {pushPath} = useChangePath();
	const {state} = useEntitiesFiltersCtx<IInspectionsFiltersState>();
	const queryClient = useQueryClient();
	const inspectionsCacheData = useInspections(objectId, state);
	const localInspections = useLocalInspections(objectId);

	const inspections = inspectionsList(inspectionsCacheData);
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const inspectionsData = useAppSelector(s => extractInspectionsData(s, objectId));
	const pageSettings = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const user = useAppSelector(s => s.auth.profile);
	const dispatch = useAppDispatch();

	const pagesCount = Math.ceil((inspectionsCacheData?.total ?? 0) / pageSettings.pageSize);
	const currentPage = Math.floor((inspectionsCacheData?.offset ?? 0) / pageSettings.pageSize);

	const preparedInspections = useMemo(
		() => convertInspections([...localInspections, ...(inspections || [])], online, user),
		[inspections, online, user, localInspections]
	);

	const inspectionsEntities = useMemo(
		() => [
			...(preparedInspections.map(item => ({
				id: item.id,
				type: 'inspection',
				data: item
			})) as IInspectionEntity[])
		],
		[preparedInspections]
	);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить осмотр?',
		' ',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, loadInspections] = useAsyncFn(async () => {
		await queryClient.invalidateQueries({
			queryKey: inspectionsQueryKeys.list()
		});
	}, [objectId]);
	const handleRowClick = useCallback((inspection: IInspectionEntity) => {
		if (
			!inspection.data.object
			|| (!inspection.data.links?.checkId && !inspection.data.links?.internalAcceptanceId)
		) {
			return;
		}
		pushPath(`/objects/${inspection.data.object.id}/inspections/${inspection.id}`);
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows(objectId, value)),
		[objectId]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort(objectId, value));
			if (online) {
				void loadInspections();
			}
		},
		[objectId, online]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(objectId, value));
			dispatch(
				changeOffset(objectId, Math.floor((inspectionsCacheData?.offset ?? 0) / value))
			);
		},
		[objectId, inspectionsCacheData?.offset, online]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (inspectionsCacheData?.offset !== offset) {
				dispatch(changeOffset(objectId, offset));
			}
		},
		[objectId, inspectionsCacheData?.offset, pageSettings.pageSize, online]
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async ({type, payload}: ITableContextMenuAction<IInspectionEntity>) => {
			if (type === 'delete') {
				if (
					payload.data.object
					&& (await getDeleteConfirmation())
					&& payload.type === 'inspection'
				) {
					await dispatch(deleteInspection(payload.data.object.id, payload.id));
				}
				if (online) {
					void loadInspections();
				}
			}
		},
		[objectId, online]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = determineTableColumns(
		objectId === 'all',
		isLargeTablet,
		mobileColumns,
		mobileColumnsWithoutObject,
		desktopColumns,
		desktopColumnsWithoutObject
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={inspectionsEntities}
						selectedRows={inspectionsData.selectedRows}
						sort={pageSettings.sort}
						selectable
						responsive={!isLargeTablet}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>

			{deleteDialog}
		</>
	);
};

export default InspectionsTable;
