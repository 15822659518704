import React from 'react';
import './ActionButtons.less';
import classNames from 'classnames';

interface IActionButtonsProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

const ActionButtons = (props: IActionButtonsProps) => {
	const {className, style, children} = props;

	return (
		<div
			className={classNames('action-buttons', className)}
			style={style}
		>
			{children}
		</div>
	);
};

export default React.memo(ActionButtons);
