import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IContract, ISavingContract} from '@tehzor/tools/interfaces/contracts';

export type IEditContractResponse = IContract;

/**
 * Изменяет договор
 * 
 * @param contractId id договора
 * @param fields данные договора
 */
export const requestEditContract = async (
	contractId: string,
	fields: ISavingContract
) => {
	const res = await httpRequests.withToken.post<IEditContractResponse>(
		`contracts/edit/${contractId}`, fields
	);

	return res.data;
};