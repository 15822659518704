import React from 'react';
import './DelegationHistoryList.less';
import {IEnrichedDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IEnrichedDelegationHistory';
import {useGroupedHistories} from './hooks/useGroupedHistories';
import {DelegationHistoryItem} from '../DelegationHistoryItem';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';

interface IDelegationHistoryListProps {
	data: IEnrichedDelegationHistory[];
	dateOptions?: Parameters<typeof format>[2];
}

export const DelegationHistoryList = ({data, dateOptions = {locale: ru}}: IDelegationHistoryListProps) => {
	const grouped = useGroupedHistories(data);

	return (
		<div className="delegation-history-list">
			{grouped.map(([date, histories], index) => (
				<div
					/* eslint-disable-next-line react/no-array-index-key */
					key={index}
					className="delegation-history-list__group"
				>
					{date !== -1 ? (
						<div className="delegation-history-list__date">
							{format(date, 'dd MMMM yyyy', dateOptions)}
						</div>
					) : null}

					<div className="delegation-history-list__items">
						{histories.map(history => (
							<DelegationHistoryItem
								key={history.id}
								className="delegation-history-list__item"
								data={history}
							/>
						))}
					</div>
				</div>
			))}
		</div>
	);
};

DelegationHistoryList.displayName = 'DelegationHistoryList';