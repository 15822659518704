import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {extractWorkingGroupById} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {useParams} from 'react-router-dom';

export const useManageWorkingGroupBreadcrumbs = () => {
	const {workingGroupId} = useParams<{workingGroupId?: string}>();
	const workingGroup = useAppSelector(s => extractWorkingGroupById(s, workingGroupId));
	return useMemo(() => {
		const items = [
			{
				label: 'Рабочие группы',
				url: '/manage/working-groups',
				nonClickable: !workingGroup
			}
		];

		if (workingGroupId && workingGroup) {
			items.push({
				label: `Группа "${workingGroup.name}"`,
				url: `/manage/working-groups/${workingGroup.id}`,
				nonClickable: false
			});
		}

		return items;
	}, [workingGroup]);
};
