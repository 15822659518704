import React from 'react';
import './ProblemHistoryDialog.less';
import {Dialog, HistoryList} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {useEnrichedHistories} from './hooks/useEnrichedHistories';
import {getProblemStories} from '@src/store/modules/pages/problem/actions/stories';
import {getProblemCommentsStories} from '@src/store/modules/pages/problem/actions/commentsStories';
import {getProblemMarkersStories} from '@src/store/modules/pages/problem/actions/markersStories';

interface IProblemHistoryDialogProps {
	objectId: string;
	problemId: string;
	isOpen: boolean;
	onClose: () => void;
}

export const ProblemHistoryDialog = (props: IProblemHistoryDialogProps) => {
	const {objectId, problemId, isOpen, onClose} = props;
	const dispatch = useAppDispatch();
	const stories = useEnrichedHistories(objectId);

	useAsync(async () => {
		if (isOpen) {
			await dispatch(getProblemStories(objectId, problemId));
			await dispatch(getProblemCommentsStories(objectId, problemId));
			await dispatch(getProblemMarkersStories(objectId, problemId));
		}
	}, [isOpen, objectId, problemId]);

	return (
		<Dialog
			className={{
				root: 'problem-history-dialog',
				content: 'problem-history-dialog__content'
			}}
			isOpen={isOpen}
			title="История изменений"
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<HistoryList
				entityName="нарушение"
				data={stories}
			/>
		</Dialog>
	);
};
