import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetCheckInspectionsResponse, requestCheckInspections} from '@src/api/backend/check';

export type IGetCheckInspectionsPayload = IGetCheckInspectionsResponse;

const request = () => ({type: types.GET_INSPECTIONS_REQUEST});

const success = (response: IGetCheckInspectionsResponse) => ({
	type: types.GET_INSPECTIONS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке осмотров');
	}
	return {
		type: types.GET_INSPECTIONS_FAILURE,
		payload: error
	};
};

/**
 * Получает список осмотров для конкретной проверки
 */
export const getCheckInspections = (objectId: string, checkId: string) =>
	checkExpiration<IState, IGetCheckInspectionsResponse, ApiAction>(
		s => s.entities.check.inspections,
		createApiAction<IGetCheckInspectionsResponse>(request, success, failure, () =>
			requestCheckInspections(objectId, checkId))
	);
