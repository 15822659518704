import {Column} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {StructureInfoMobileCell} from '@src/components/tableCells/StructureInfoMobileCell/StructureInfoMobileCell';

export const mobileColumns: Array<Column<IEnrichedStructure>> = [
	{
		id: 'info',
		Cell: StructureInfoMobileCell,
		className: 'structures-page__mobile-cell'
	}
];
