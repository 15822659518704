import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetInternalAcceptanceResponse, requestInternalAcceptance} from '@src/api/backend/internalAcceptance';

export type IGetOneUserInternalAcceptancePayload = IGetInternalAcceptanceResponse;

const request = () => ({
	type: types.GET_ONE_REQUEST
});

const success = (response: IGetInternalAcceptanceResponse) => ({
	type: types.GET_ONE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке внутренней приёмки');
	return {
		type: types.GET_ONE_FAILURE,
		payload: error
	};
};

/**
 * Получает одну внутреннюю приемку по id
 */
export const getUserInternalAcceptance = (objectId: string, internalAcceptanceId: string) =>
	createApiAction<IGetInternalAcceptanceResponse>(request, success, failure, () =>
		requestInternalAcceptance(objectId, internalAcceptanceId));
