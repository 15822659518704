import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import * as types from './constants';
import * as setsTypes from '../problemTagsSets/constants';
import {createReducer} from '@reduxjs/toolkit';
import {
	getLoadableEntitiesInitialState,
	ILoadableEntitiesState,
	handleLoadableEntitiesGetting,
	handleLoadableEntityAdding,
	handleLoadableEntityEditing,
	handleLoadableEntityDeletion,
	handleLoadableEntityReset
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type IProblemTagsState = ILoadableEntitiesState<IProblemTag>;

export const problemTags = createReducer<IProblemTagsState>(
	getLoadableEntitiesInitialState<IProblemTag>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IProblemTag>(),
		[types.ADD_SUCCESS]: handleLoadableEntityAdding<IProblemTag>(),
		[types.EDIT_SUCCESS]: handleLoadableEntityEditing<IProblemTag>(),
		[types.DELETE_SUCCESS]: handleLoadableEntityDeletion<IProblemTag>(),
		[setsTypes.EDIT_SUCCESS]: handleLoadableEntityReset<IProblemTag>(),
		[setsTypes.DELETE_SUCCESS]: handleLoadableEntityReset<IProblemTag>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IProblemTag>(),
		[CLEAR_BEFORE_UPDATE]: () => getLoadableEntitiesInitialState<IProblemTag>()
	}
);
