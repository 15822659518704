import React, {forwardRef, memo, Ref} from 'react';
import ButtonBase from '../../../../buttons/ButtonBase';
import classNames from 'classnames';
import './VerticalTabLink.less';

export interface IVerticalTabLinkProps {
	index: number;
	active?: boolean;
	label?: React.ReactNode;

	onClick(index: number): void;
}

const VerticalTabLink = (props: IVerticalTabLinkProps, ref?: Ref<HTMLButtonElement>) => {
	const {index, active, label, onClick} = props;

	const handleClick = () => {
		if (!active) {
			onClick(index);
		}
	};

	return (
		<ButtonBase
			className={classNames({'vertical-tab-link_active': active})}
			classNamePrefix="vertical-tab-link"
			outerTagType="button"
			label={label}
			onClick={handleClick}
			ref={ref}
		/>
	);
};

export default memo(forwardRef(VerticalTabLink));
