import React, {memo, useRef} from 'react';
import {AdaptiveHeightPanel, LoadingPanel, Plate} from '@tehzor/ui-components';
import {useCheckListBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCheckListBreadcrumbs';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {CheckListPlate} from '@src/pages/CheckListPage/components/CheckListPlate';
import {Route, Routes} from 'react-router-dom';
import {DesktopDetailColumn} from '@src/pages/CheckListPage/components/DesktopDetailColumn';
import {EntityLinks} from '@src/components/EntityLinks/EntityLinks';
import CheckListsExportDialog from './actions/CheckListsExportDialog';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IDesktopProps {
	loading: boolean;
}

export const Desktop = memo(({loading}: IDesktopProps) => {
	const {objectId, spaceId, stage} = useStrictParams<{
		objectId: string;
		spaceId?: string;
		stage: ObjectStageIds | 'all';
	}>();

	const breadcrumbs = useCheckListBreadcrumbs(objectId);

	const checkLists = useAvailableCheckLists(objectId, stage);
	const listsLoading = useAppSelector(s => s.dictionaries.checkLists.loading);

	const permissions = useCheckListPermissions();

	const pageRef = useRef(null);

	return (
		<div
			ref={pageRef}
			className="page-cont check-list-page"
		>
			<LoadingPanel active={loading}>
				<div className="check-list-page__d-container">
					<AppBreadcrumbs items={breadcrumbs}>
						{spaceId && objectId && permissions.canExport && (
							<CheckListsExportDialog
								objectId={objectId}
								spaceId={spaceId}
								checkListsIds={checkLists.map(item => item.id)}
							/>
						)}
					</AppBreadcrumbs>

					<Routes>
						{/* ловит параметры пути для совместимости со страницами в мобильной версии */}
						<Route
							path=":listId?/:pathParam?/:itemId?"
							element={
								<div className="check-list-page__d-grid">
									<div className="check-list-page__d-info">
										<Plate
											withoutPadding
											className="check-list-page__d-plate"
										>
											<EntityLinks links={{spaceId, objectId}} />
										</Plate>
									</div>

									<div className="check-list-page__d-main">
										<div className="check-list-page__d-main-list">
											<LoadingPanel active={listsLoading}>
												<>
													{checkLists.map(checkList => (
														<CheckListPlate
															key={checkList.id}
															defaultOpen={checkLists.length === 1}
															checkList={checkList}
														/>
													))}
												</>
											</LoadingPanel>
										</div>

										<div className="check-list-page__d-main-detail">
											<AdaptiveHeightPanel pageRef={pageRef.current}>
												<DesktopDetailColumn />
											</AdaptiveHeightPanel>
										</div>
									</div>
								</div>
							}
						/>
					</Routes>
				</div>
			</LoadingPanel>
		</div>
	);
});
