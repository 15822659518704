import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetProblemsResponse, requestProblems} from '@src/api/backend/problems';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import * as types from '../../constants/problems';

export type ICheckChangedProblemsPayload = IGetProblemsResponse;

const request = () => ({
	type: types.CHECK_REQUEST
});

const success = (response: IGetProblemsResponse) => ({
	type: types.CHECK_SUCCESS,
	payload: {
		...response
	} as ICheckChangedProblemsPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка нарушений');
	}
	return {
		type: types.CHECK_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список нарушений
 */
export const checkChangedProblems
	= (
		checkListIds: string[],
		spaceId: string | undefined,
		workAcceptanceId: string | undefined

	): AppThunkAction<Promise<IGetProblemsResponse>> =>
	dispatch =>
		dispatch(
			createApiAction<IGetProblemsResponse>(request, success, failure, () => {
				const spaces = [];
				const workAcceptanceIds = [];

				if (spaceId) {
					spaces.push(spaceId);
				}

				if (workAcceptanceId) {
					workAcceptanceIds.push(workAcceptanceId);
				}

				return requestProblems(
					{
						checkListIds,
						spaces,
						workAcceptanceIds
					},
					{createdAt: false},
					0,
					1000
				);
			})
		);
