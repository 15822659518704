import React, {memo, useEffect, useMemo, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceTypeDecorationsAsArray} from '@src/store/modules/entities/spaceTypeDecorations/selectors';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {extractSpaceTypeDecorationSetsAsArray} from '@src/store/modules/entities/spaceTypeDecorationSets/selectors';
import {MobileFilter} from '@tehzor/ui-components';
import {filterEmptyTypeDecorationSets} from '@src/components/editableFields/SpaceTypeDecorationTreeSelect/utils/makeTreeData';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface ISpaceTypesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpaceTypeDecorationFilterMobile = memo(
	({objectId, value, entity, onChange}: ISpaceTypesFilterMobileProps) => {
		const [selected, setSelected] = useState([] as string[]);
		const [search, setSearch] = useState('');

		const typeDecorationsSets = useAppSelector(s => extractSpaceTypeDecorationSetsAsArray(s));
		const typeDecorationsAsArray = useAppSelector(s => extractSpaceTypeDecorationsAsArray(s));
		const notEmptyTypeDecorationsSets = filterEmptyTypeDecorationSets(typeDecorationsAsArray, typeDecorationsSets);
		const typeDecorationsById = useAppSelector(s => s.entities.spaceTypeDecorations.byId);

		const filteredElements = useMemo(() => flatFilter(notEmptyTypeDecorationsSets, 'name', search), [notEmptyTypeDecorationsSets, search]);

		useEffect(() => {
			const selectedSets = value?.reduce((prev, current) => {
				const setId = typeDecorationsById?.[current]?.typeDecorationSetId;

				return prev.add(setId);
			}, new Set<string>());

			setSelected(Array.from(selectedSets || []));
		}, [value]);

		const handleChange = (v: string[] | undefined) => {
			let typeDecoration = [] as string[];
			setSelected(v || []);

			if (v && v?.length > 0) {
				typeDecoration = typeDecorationsAsArray.reduce((prev, current) => {
					if (v?.includes(current.typeDecorationSetId)) {
						prev.push(current.id);
					}
					return prev;
				}, [] as string[]);
			}

			onChange({typeDecoration});
		};

		const handleExpand = useFilterPageTransition(objectId, entity, 'typeDecoration');

		return (
			<MobileFilter
				label="Типы отделки помещений"
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
					/>
				)}
			/>
		);
	}
);
