import React, {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import SelectionActions from '../selection/SelectionActions';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';

interface ICustomSelectionRowProps extends ISelectionRowProps<IWarrantyClaimEntity> {
	objectId: string;
	warrantyClaimId: string;
}

const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {objectId, warrantyClaimId, selectedFlatRows, toggleAllRowsSelected} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				objectId={objectId}
				warrantyClaimId={warrantyClaimId}
				selectedEntities={selectedFlatRows.map(item => item.original)}
			/>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;
