import {CompaniesFilterPage} from '@src/pages/ContractsPage/components/filters/CompaniesFilterPage';
import {ContractorsFilterPage} from '@src/pages/ContractsPage/components/filters/ContractorsFilterPage';
import {SigningDateFilterPage} from '@src/pages/ContractsPage/components/filters/SigningDateFilterPage';
import {ContractsFiltersPage} from '@src/pages/filters/ContractsFiltersPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import ObjectsFilterPage from '@src/pages/filters/ObjectsFilterPage';
import React from 'react';

export const contractsFiltersRoutes = [
	{
		path: 'signingdate',
		element: <SigningDateFilterPage />
	},
	{
		path: 'companyIds',
		element: <CompaniesFilterPage />
	},
	{
		path: 'contractorIds',
		element: <ContractorsFilterPage />
	},
	{
		path: 'objectIds',
		element: <ObjectsFilterPage />
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: '',
		element: <ContractsFiltersPage />
	}
];
