import {Column} from 'react-table';
import {MenuCell} from './MenuCell';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {ResponsibleUsersCell} from './ResponsibleUsersCell';
import {TaskStatusChangeableCell} from './TaskStatusChangeableCell';
import {PriorityCell} from './PriorityCell';
import {DeadLineCell} from './DeadLineCell';
import {TaskDescriptionCell} from './TaskDescriptionCell';
import LocationCell from './LocationCell';

export const columns: Array<Column<IEnrichedTask>> = [
	{
		id: 'name',
		Header: 'Название, описание и тип задачи',
		Cell: TaskDescriptionCell,
		accessor: item => item,
		width: 150,
		minWidth: 150,
		sortDescFirst: true
	},
	{
		id: 'objectId',
		Header: 'Объект/Место',
		Cell: LocationCell,
		accessor: item => item,
		width: 120,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'status',
		Header: 'Статус',
		Cell: TaskStatusChangeableCell,
		accessor: item => item,
		width: 110,
		disableSortBy: true
	},
	{
		id: 'priority',
		Header: 'Приоритет',
		Cell: PriorityCell,
		accessor: item => item,
		width: 110,
		minWidth: 30,
		disableSortBy: true
	},
	{
		id: 'taskIntervalStart',
		Header: 'Срок исполнения',
		Cell: DeadLineCell,
		accessor: (item: IEnrichedTask) => item,
		width: 80,
		minWidth: 120,
		disableSortBy: true
	},
	{
		id: 'respUsers',
		Header: 'Ответственные',
		Cell: ResponsibleUsersCell,
		accessor: (item: IEnrichedTask) => item,
		width: 80,
		minWidth: 80,
		disableSortBy: true
	},
	{
		id: 'createdAt',
		accessor: 'createdAt',
		Header: 'Добавлено',
		width: 100,
		disableSortBy: false
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: 'modifiedAt',
		width: 100,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'tasks-page__d-table-menu',
		width: 120,
		defaultCanSort: false,
		disableResizing: true
	}
];
