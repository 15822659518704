import React from 'react';
import {EntityGrid, EntityGridItem, Plate} from '@tehzor/ui-components';
import ExpandableMobileInfo from '@src/components/ExpandableMobileInfo';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ContractFormId} from '@tehzor/tools/interfaces/spaces/IContractForm';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Dates} from '@src/components/viewFields/Dates';
import {formSpaceDates} from '@src/utils/formSpaceDates';

interface IDesktopAdditionalInfoProps {
	space: ISpace;
}

export const AdditionalInfo = ({space}: IDesktopAdditionalInfoProps) => {
	const isDesktop = useIsDesktop();
	const {allIds, byId: typesMap} = useAppSelector(s => s.dictionaries.contractForms);
	const makeName = (contractId?: ContractFormId) => {
		if (typesMap === undefined || allIds.length === 0
			|| contractId === undefined) return undefined;
		return typesMap[contractId]?.name;
	};
	const dates = formSpaceDates(space);

	const AdditionalInfoGrid = (
		<EntityGrid withBorders>
			{space.areaBTI && (
				<EntityGridItem
					label="Площадь квартиры БТИ"
					icon={<i className="tz-area-20"/>}
					fullRow
				>
					{space.areaBTI}
					{' '}
					м
					<sup>2</sup>
				</EntityGridItem>
			)}

			{space.numberBTI && (
				<EntityGridItem
					label="Номер БТИ"
					fullRow
					icon={<i className="tz-area-20"/>}
				>
					{space.numberBTI}
				</EntityGridItem>
			)}
			{space.floorBTI && (
				<EntityGridItem
					label="Этаж БТИ"
					fullRow
					icon={<i className="tz-location-20"/>}
				>
					{space.floorBTI}
				</EntityGridItem>
			)}

			{space.contractForm && (
				<EntityGridItem
					label="Форма договора"
					fullRow
					icon={<i className="tz-document-20"/>}
				>
					{makeName(space.contractForm)}
				</EntityGridItem>
			)}

			{space.markupForRegistration !== undefined && (
				<EntityGridItem
					label="Наценка за регистрацию"
					fullRow
					icon={<i className="tz-percent"/>}
				>
					{space.markupForRegistration ? 'да' : 'нет'}
				</EntityGridItem>
			)}
			{(space.decorationWarrantyExpiredDate
				|| space.constructiveWarrantyExpiredDate
				|| space.technicalEquipmentWarrantyExpiredDate) && <Dates dates={dates}/>}
		</EntityGrid>
	);

	return (
		<Plate
			className={{content: 'space-page__info-plate'}}
			style={!isDesktop ? {marginTop: '16px'} : undefined}
		>
			<div className="space-page__info-title">Дополнительно</div>
			{!isDesktop ? (
				<ExpandableMobileInfo>{AdditionalInfoGrid}</ExpandableMobileInfo>
			) : (
				<>{AdditionalInfoGrid}</>
			)}
		</Plate>
	);
};
