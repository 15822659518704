import {IDeleteManySpaceStatusesResponse, requestDeleteManySpaceStatuses} from '@src/api/backend/spaceStatuses/deleteMany';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {queryClient} from '@src/api/QueryClient';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export interface IDeleteManySpaceStatusPayload {
	spaceStatusesSetId: string;
}

const request = () => ({type: types.DELETE_MANY_REQUEST});

const success = (spaceStatusesSetId: string) => ({
	type: types.DELETE_MANY_SUCCESS,
	payload: {spaceStatusesSetId} as IDeleteManySpaceStatusPayload
});

const failure = (error: IError) => ({
	type: types.DELETE_MANY_FAILURE,
	payload: error
});

export const deleteManySpaceStatuses = (spaceStatusesSetId: string) =>
	createApiAction<IDeleteManySpaceStatusesResponse>(
		request,
		() => success(spaceStatusesSetId),
		failure,
		() => {
			void queryClient.invalidateQueries(spaceStatusesQueryKeys.list());
			void queryClient.invalidateQueries(spacesQueryKeys.list());

			return requestDeleteManySpaceStatuses(spaceStatusesSetId);
		}
	);
