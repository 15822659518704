import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetInternalAcceptanceProblemsResponse, requestInternalAcceptanceProblems} from '@src/api/backend/internalAcceptance';

export type IGetInternalAcceptanceProblemsPayload = IGetInternalAcceptanceProblemsResponse;

const request = () => ({type: types.GET_PROBLEMS_REQUEST});

const success = (response: IGetInternalAcceptanceProblemsResponse) => ({
	type: types.GET_PROBLEMS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке нарушений');
	}
	return {
		type: types.GET_PROBLEMS_FAILURE,
		payload: error
	};
};

/**
 * Получает список нарушений для конкретной внутренней приёмки
 */
export const getInternalAcceptanceProblems = (objectId: string, internalAcceptanceId: string) =>
	checkExpiration<IState, IGetInternalAcceptanceProblemsResponse, ApiAction>(
		s => s.entities.internalAcceptance.problems,
		createApiAction<IGetInternalAcceptanceProblemsResponse>(request, success, failure, () =>
			requestInternalAcceptanceProblems(objectId, internalAcceptanceId))
	);
