import useAppDispatch from '@src/core/hooks/useAppDispatch';
import React, {useCallback, useMemo, memo} from 'react';
import {
	EntitiesTable,
	Pagination,
	PaginationAndSize,
	PaginationPageSize,
	Plate
} from '@tehzor/ui-components';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {convertLegals} from '@src/pages/LegalsPage/utils/convertLegals';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';
import {useColumns} from './useColumns';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {
	ILegalsPageSettingsState,
	ILegalsSortState,
	legalsActions
} from '@src/store/modules/settings/pages/legals/reducers';
import {SelectionClearing} from '../selection/SelectionClearing';
import {LegalsActions} from '../selection/LegalsActions';
import {SelectionRow} from '../selection/SelectionRow';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {queryClient} from '@src/api/QueryClient';
import {legalsQueryKeys} from '@src/api/cache/legals/keys';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

const pageSizes = [10, 20, 50, 100];

interface ILegalsTableProps {
	data: ILegalEntity[];
	currentPage: number;
	pageCount: number;
	settings: ILegalsPageSettingsState;
}

export const LegalsTable = memo((props: ILegalsTableProps) => {
	const {data, currentPage, pageCount, settings} = props;
	const {offset, pageSize, selectedRows, sort} = settings;
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	const roles = useAppSelector(extractUserRoles);
	const preparedLegals = useMemo(() => convertLegals(data, roles), [data, roles]);

	const selectedEntities = useMemo(
		() => preparedLegals.filter(legal => selectedRows.includes(legal.id)),
		[selectedRows, preparedLegals]
	);
	const isTablet = useIsTablet();
	const columns = useColumns(isTablet);

	const handleRowClick = useCallback(
		(row: IPreparedLegal) => pushPath(`/legals/${row.id}`),
		[pushPath]
	);

	const handleSortChange = (value: ILegalsSortState) => {
		dispatch(legalsActions.changeSort(value));
	};

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				void queryClient.invalidateQueries(legalsQueryKeys.list());
				dispatch(legalsActions.changeOffset(newOffset));
			}
		},
		[offset, pageSize, dispatch]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			const newOffset = Math.floor(offset / value);

			dispatch(legalsActions.changePageSize(value));
			dispatch(legalsActions.changeOffset(newOffset));
		},
		[offset, dispatch]
	);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(legalsActions.changeSelectedRows(value)),
		[dispatch]
	);

	const clearSelectedRowsChange = useCallback(() => handleSelectedRowsChange([]), []);

	useAppHeader(
		{
			title: 'Юрлица',
			mobileLeftButton:
				selectedEntities?.length > 0 ? (
					<SelectionClearing onSelectedRowsChange={clearSelectedRowsChange} />
				) : undefined,
			mobileRightButtons: (
				<LegalsActions
					selectedEntities={selectedEntities}
					onClear={clearSelectedRowsChange}
				/>
			)
		},
		[selectedEntities]
	);

	return (
		<div className="legals__container">
			<Plate
				className="legals__container-table"
				withoutPadding
			>
				<EntitiesTable
					sort={sort}
					columns={columns}
					data={preparedLegals}
					selectable={isTablet}
					selectedRows={selectedRows}
					headVisible={isTablet}
					onRowClick={handleRowClick}
					onSortChange={handleSortChange}
					onSelectedRowsChange={handleSelectedRowsChange}
					renderSelectionRow={props => <SelectionRow {...props} />}
				/>
			</Plate>
			<PaginationAndSize
				pagination={
					<Pagination
						className="legals__container-pagination"
						pageCount={pageCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<PaginationPageSize
						className="legals__container-page-size"
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</div>
	);
});
