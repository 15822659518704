import './EditingTaskDialog.less';
import React, {useCallback, useEffect, useState} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableTask} from '../EditableTask/hooks/useEditableTask';
import {addTask, editTask, getTask} from '@src/store/modules/pages/task/actions';
import {getTasks} from '@src/store/modules/entities/tasks/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {addSuccessToast} from '@src/utils/toasts';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {extractTask} from '@src/store/modules/pages/task/selectors';

interface IEditingTaskDialogProps {
	taskId?: string;
	objectId?: string;
	startTime?: string;
	endTime?: string;
	isOpen: boolean;
	close(): void;
}

export const EditingTaskDialog = ({
	taskId,
	objectId,
	startTime,
	endTime,
	isOpen,
	close
}: IEditingTaskDialogProps) => {
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const task = useAppSelector(extractTask);
	const [taskFields, getSavingData, isBlocking, reset] = useEditableTask({
		task: taskId ? (task as ITask) : undefined,
		objectId,
		startTime,
		endTime,
		saving
	});
	useEffect(() => {
		if (taskId) {
			void dispatch(getTask(taskId));
		}
	}, [dispatch, taskId, isOpen, close]);

	const handleSave = useCallback(async () => {
		const savingData = await getSavingData();
		if (!savingData) {
			return;
		}
		setSaving(true);

		try {
			if (taskId) {
				await dispatch(editTask(taskId, savingData));
			} else {
				await dispatch(addTask(savingData));
			}
			await dispatch(getTasks());

			addSuccessToast('Успешно', taskId ? 'Задача обновлена' : 'Задача создана');
		} finally {
			setSaving(false);
			reset();
			close();
		}
	}, [close, dispatch, getSavingData, reset, taskId]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, getClosingConfirmation, close]);

	const permissions = useTasksPermissions();

	if ((!taskId && !permissions.canAdd) || (taskId && !permissions.canEdit)) return null;

	return (
		<Dialog
			className={{
				root: 'editing-task',
				content: 'adding-task__container'
			}}
			isOpen={isOpen}
			title="Редактировать задачу"
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={cancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{taskFields}
			{closingDialog}
		</Dialog>
	);
};
