import React, {useState} from 'react';
import {useAsync, useMount} from 'react-use';
import {getSpace} from '@src/store/modules/entities/space/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {CheckListPage} from './CheckListPage';
import {useSpaceCheckListsHeader} from './hooks/useSpaceCheckListsHeader';

export const SpaceCheckListPageWrap = () => {
	const {objectId, spaceId} = useStrictParams<{objectId: string, spaceId: string}>();

	useSpaceCheckListsHeader(objectId);
	const [isMounted, setIsMounted] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	useMount(() => {
		setIsMounted(true);
	});

	const {loading} = useAsync(async () => {
		if (spaceId && isMounted) {
			await dispatch(getSpace(objectId, spaceId));
		}
	}, [objectId, spaceId, isMounted]);

	return <CheckListPage loading={loading}/>;
};
