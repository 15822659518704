import React, {memo} from 'react';
import DesktopLinks from './links/Links.desktop';
import Menu from './Menu';
import {IPermissions} from '../hooks/usePermissions';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';

interface IControlsProps {
	objectId: string;
	problem: IPreparedProblem;
	permissions: IPermissions;
}

export const DesktopControls = memo(
	({objectId, problem, permissions}: IControlsProps) => (
		<div className="problem-page__d-controls">
			<div className="problem-page__d-controls-left">
				<DesktopLinks
					objectId={objectId}
					problem={problem}
					permissions={permissions}
				/>
			</div>

			<div className="problem-page__d-controls-right">
				<Menu
					objectId={objectId}
					problem={problem}
					permissions={permissions}
				/>
			</div>
		</div>
	)
);
