import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IDeletePlanResponse, makePlanDeleteRequest} from '@src/api/backend/plan';

export interface IDeletePlanPayload {
	objectId: string;
	planId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, planId: string) => ({
	type: types.DELETE_SUCCESS,
	payload: {objectId, planId}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении плана');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет план
 *
 * @param objectId id объекта
 * @param planId id вида работ
 */
export const deletePlan = (objectId: string, planId: string) =>
	createApiAction<IDeletePlanResponse>(
		request,
		() => success(objectId, planId),
		failure,
		() => makePlanDeleteRequest(objectId, planId)
	);
