import React, {createContext, useCallback, useMemo, useState} from 'react';
import {
	EntitiesTable,
	Pagination,
	PaginationPageSize,
	Plate,
	PaginationAndSize
} from '@tehzor/ui-components';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {extractProblemsData} from '@src/store/modules/entities/problems/selectors';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {changeOffset, changeSelectedRows} from '@src/store/modules/entities/problems/actions';
import {changePageSize, changeSort} from '@src/store/modules/settings/pages/problems/actions';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {convertProblems} from '../../utils/convertProblems';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getDesktopColumns} from './desktopColumns';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import SelectionRow from './SelectionRow';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useLocation} from 'react-router-dom';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import MobileRightButtons from '../actions/RightButtons.mobile';
import MobileSelectionClearing from '../selection/SelectionClearing.mobile';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {getObjectStats} from '@src/store/modules/dictionaries/objects/actions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {usePermissions} from '../../utils/usePermissions';
import ProblemAddingMobile from '../actions/ProblemAdding.mobile';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {problemsList, useLocalProblems, useProblems} from '@src/core/hooks/queries/problems';
import {extractLatestCheckId} from '@src/store/modules/dictionaries/latestUserChecks/selectors';
import {extractLatestInternalAcceptanceId} from '@src/store/modules/dictionaries/latestUserInternalAcceptances/selectors';
import {extractMarkersAsArray} from '@src/store/modules/entities/markers/selectors';
import {getMobileColumns} from './mobileColumns';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';

const pageSizes = [10, 20, 50, 100];
const types: ICheckAddingEntityType[] = ['problem'];

interface IProblemsTableProps {
	objectId?: string;
	settings?: IProblemSettings;
}

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedProblem>) => void
>(() => ({}));

const ProblemsTable = ({objectId = 'all', settings}: IProblemsTableProps) => {
	const {pushPath} = useChangePath();
	const {state} = useEntitiesFiltersCtx<IProblemsFiltersState>();

	const problemsCacheData = useProblems(objectId, state);
	const localProblems = useLocalProblems(objectId);

	const problems = problemsList(problemsCacheData);
	const problemsData = useAppSelector(s => extractProblemsData(s, objectId));
	const pageSettings = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const statuses = useAppSelector(s => s.dictionaries.problemStatuses.byId);
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const groupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = onlineManager.isOnline();
	const dispatch = useAppDispatch();
	const {search} = useLocation();
	const sectionsMenu = useObjectSectionsMenu(objectId, !!search);
	const pagesCount = Math.ceil((problemsCacheData?.total ?? 0) / pageSettings.pageSize);
	const currentPage = Math.floor((problemsCacheData?.offset ?? 0) / pageSettings.pageSize);
	const isDesktop = useIsDesktop();
	const permissions = usePermissions(user, objectId);
	const problemTagsMap = useAppSelector(s => s.dictionaries.problemTags.byId);
	const markers = useAppSelector(s => extractMarkersAsArray(s, objectId));
	const preparedProblems = useMemo(
		() =>
			convertProblems(
				[...localProblems, ...(problems || [])],
				problemTagsMap,
				statuses,
				usersMap,
				groupsMap,
				markers,
				networkStatus,
				user
			),
		[
			problems,
			problemTagsMap,
			statuses,
			usersMap,
			networkStatus,
			user,
			problemsData,
			localProblems,
			groupsMap,
			markers
		]
	);

	const queryClient = useQueryClient();
	const [, reloadList] = useAsyncFn(async () => {
		await queryClient.invalidateQueries({
			queryKey: problemsQueryKeys.list()
		});
	}, [objectId]);
	const problemsEntities = useMemo(
		() =>
			preparedProblems.map(item => ({
				id: item.id,
				type: 'problem',
				data: item
			})) as IProblemEntity[],
		[preparedProblems]
	);

	const selectedEntities = useMemo(
		() => problemsEntities.filter(item => problemsData.selectedRows?.includes(item.id)),
		[problemsData.selectedRows, problemsEntities]
	);
	const [problemToCopy, setProblemToCopy] = useState<IPreparedProblem>();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить нарушение?',
		' ',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const latestCheckId = useAppSelector(s => extractLatestCheckId(s, objectId));
	const latestInternalAcceptanceId = useAppSelector(s =>
		extractLatestInternalAcceptanceId(s, objectId));

	const problem = useMemo(() => {
		if (problemsCacheData && problemToCopy?.id) {
			return problemsCacheData.byId[problemToCopy.id];
		}
		return undefined;
	}, [problemsCacheData, problemToCopy?.id]);

	const handleRowClick = useCallback((problem: IProblemEntity) => {
		if (problem.data.object && problem.type === 'problem') {
			pushPath(`/objects/${problem.data.object.id}/problems/${problem.data.id}`);
		}
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows(objectId, value)),
		[objectId]
	);
	const object = useAppSelector(s => extractCurrentTreeObject(s, objectId));

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort(objectId, value));
			void reloadList();
		},
		[objectId]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(objectId, value));
			dispatch(changeOffset(objectId, Math.floor((problemsCacheData?.offset ?? 0) / value)));
			void reloadList();
		},
		[objectId, problemsCacheData?.offset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (problemsCacheData?.offset !== offset) {
				dispatch(changeOffset(objectId, offset));
			}
		},
		[objectId, problemsCacheData?.offset, pageSettings.pageSize]
	);

	const [copyToCheckDialog, openCopyToCheckDialog] = useAddingCheckDialog({
		objectId: problemToCopy?.objectId || 'all',
		checkId: latestCheckId,
		stage: problem?.stage,
		types,
		defaultProblemData: {
			...problem,
			categoryId: problem?.categoryId,
			location: undefined,
			floor: undefined
		},
		onSuccess: reloadList,
		problemToCopyId: problemToCopy?.id
	});

	const [copyToInternalDialog, openCopyToInternalDialog] = useAddingInternalAcceptanceDialog({
		objectId: problemToCopy?.objectId || 'all',
		internalAcceptanceId: latestInternalAcceptanceId,
		stage: problem?.stage,
		types,
		defaultProblemData: {
			...problem,
			categoryId: problem?.categoryId,
			location: undefined,
			floor: undefined
		},
		onSuccess: reloadList,
		problemToCopyId: problemToCopy?.id
	});

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedProblem>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await dispatch(deleteProblem(action.payload.object.id, action.payload.id));
					await dispatch(getObjectStats([action.payload.object.id]));
				}
			}
			if (action.type === 'copy') {
				setProblemToCopy(action.payload);
				if (action.payload.links?.checkId) {
					openCopyToCheckDialog();
				} else if (action.payload.links?.internalAcceptanceId) {
					openCopyToInternalDialog();
				}
			}
		},
		[objectId]
	);

	useObjectAppHeader(
		objectId,
		{
			title: objectId === 'all' ? 'Нарушения' : object?.name,
			sectionsMenu: objectId === 'all' ? undefined : sectionsMenu,
			showBackBtn: objectId !== 'all',
			mobileLeftButton:
				selectedEntities.length > 0 ? (
					<MobileSelectionClearing
						onSelectedRowsChange={() => handleSelectedRowsChange([])}
					/>
				) : undefined,
			mobileRightButtons: !isDesktop ? (
				<DispatchActionCtx.Provider value={handleContextMenuAction}>
					<MobileRightButtons
						objectId={objectId !== 'all' ? objectId : undefined}
						selectedEntities={selectedEntities}
						selectedClearing={() => {
							handleSelectedRowsChange([]);
						}}
					/>
				</DispatchActionCtx.Provider>
			) : null
		},
		[objectId, sectionsMenu, selectedEntities, problemsData.selectedRows, isDesktop]
	);

	const columns = useMemo(() => {
		if (isDesktop) {
			return getDesktopColumns(objectId, settings);
		}
		return getMobileColumns(objectId, settings);
	}, [isDesktop, objectId, settings]);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={problemsEntities}
						selectedRows={problemsData.selectedRows}
						sort={pageSettings.sort}
						selectable={isDesktop}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
						hideLastHeaderCell={isDesktop}
						headVisible={isDesktop}
						renderSelectionRow={props => (
							<SelectionRow
								{...props}
								objectId={objectId}
							/>
						)}
					/>
					{!isDesktop
						&& permissions.canAddCheck
						&& permissions.canAddProblem
						&& objectId !== undefined && <ProblemAddingMobile objectId={objectId}/>}
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>
			{copyToCheckDialog}
			{copyToInternalDialog}
			{deleteDialog}
		</>
	);
};

export default ProblemsTable;
