import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Button, EntityGrid, EntityGridItem, ErrorBoundary, Plate} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import React, {useCallback, useState} from 'react';
import DevicesHistoryDialog from './components/DevicesHistoryDialog/DevicesHistoryDialog';
import {getDevices} from '../../store/modules/entities/devices/actions/get';
import {OfflineModeSettings} from './components/OfflineModeSettings';
import './ProfilePage.less';
import {useMount} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ProfileMenu} from './components/ProfileMenu';
import {SubscribeSettings} from './components/SubscribeSettings';

export const ProfilePage = () => {
	const {pushPath} = useChangePath();
	const user = useAppSelector(s => s.auth.profile);
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const isDesktop = useIsDesktop();
	const dispatch = useAppDispatch();

	const [isOpenDevicesHistoryDialog, setIsOpenDevicesHistoryDialog] = useState(false);

	const handleCloseDevicesHistoryDialog = useCallback(
		() => setIsOpenDevicesHistoryDialog(false),
		[]
	);

	const handleOpenDevicesHistoryDialog = useCallback(
		() => setIsOpenDevicesHistoryDialog(true),
		[]
	);

	const handleEdit = useCallback(() => {
		pushPath('/profile/edit');
	}, []);

	const goToOfflineJournal = useCallback(() => {
		pushPath('/profile/offline-journal');
	}, []);

	useMount(() => {
		void dispatch(getDevices());
	});

	useAppHeader(
		{
			title: 'Профиль',
			showBackBtn: true,
			mobileRightButtons: <ProfileMenu onEdit={handleEdit}/>
		},
		[isDesktop]
	);

	return (
		<div className="page-cont profile-page">
			<ErrorBoundary
				label="ProfilePageEdit"
				className="profile-page__action error"
			>
				{isDesktop && (
					<div className="profile-page__action">
						<Button
							className="profile-page__action-button"
							type="accent-blue"
							label="Редактировать"
							leftIcon={<i className="tz-edit-16"/>}
							onClick={handleEdit}
						/>
					</div>
				)}
			</ErrorBoundary>

			<Plate>
				<ErrorBoundary label="ProfilePagePlate">
					<EntityGrid
						className={{wrap: 'profile-page__entities-grid'}}
						withBorders
					>
						{user?.email && (
							<EntityGridItem
								icon={<i className="tz-mail-20"/>}
								label="Email"
								inline
							>
								{user.email}
							</EntityGridItem>
						)}

						{user?.lastName && (
							<EntityGridItem
								icon={<i className="tz-user-20"/>}
								label="Фамилия"
								inline
							>
								{user.lastName}
							</EntityGridItem>
						)}

						{user?.firstName && (
							<EntityGridItem
								icon={<i className="tz-name-20"/>}
								label="Имя"
								inline
							>
								{user.firstName}
							</EntityGridItem>
						)}

						{user?.middleName && (
							<EntityGridItem
								icon={<i className="tz-name-20"/>}
								label="Отчество"
								inline
							>
								{user.middleName}
							</EntityGridItem>
						)}

						{user?.position && (
							<EntityGridItem
								icon={<i className="tz-name-20"/>}
								label="Должность"
								inline
							>
								{user.position}
							</EntityGridItem>
						)}

						<EntityGridItem className="profile-page__entities-grid-item__settings">
							<OfflineModeSettings/>
							<SubscribeSettings value={user.subscribed}/>
						</EntityGridItem>

						<EntityGridItem
							icon={<i className="tz-offline-mode-20"/>}
							label="Журнал автономных действий"
						>
							<Button
								label="Автономные действия"
								type="accent-blue"
								disabled={!isOfflineModeAvailable}
								onClick={goToOfflineJournal}
							/>
						</EntityGridItem>

						<EntityGridItem
							icon={<i className="tz-info-20"/>}
							label="Подключенные устройства"
						>
							<Button
								label="История сессий"
								type="accent-blue"
								onClick={handleOpenDevicesHistoryDialog}
							/>
							<DevicesHistoryDialog
								isOpen={isOpenDevicesHistoryDialog}
								onClose={handleCloseDevicesHistoryDialog}
							/>
						</EntityGridItem>
					</EntityGrid>
				</ErrorBoundary>
			</Plate>
		</div>
	);
};
