import React, {useCallback} from 'react';
import classNames from 'classnames';
import './MenuItem.less';
import {useSelectMenuItemCtx} from '../Menu';

export interface IMenuItemProps {
	className?: string;
	wrapClass?: string;
	iconWrapClass?: string;
	titleClass?: string;
	style?: React.CSSProperties;
	itemKey?: string;
	icon?: React.ReactNode;
	children?: React.ReactNode;
	selectable?: boolean;
	selected?: boolean;
	disabled?: boolean;

	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export interface IMenuComponentProps<V> {
	value?: V;
	defaultValue?: V;
	children?: React.ReactElement<IMenuItemProps> | Array<React.ReactElement<IMenuItemProps>>;

	onChange?: (key: V) => void;
}

const MenuItem = (props: IMenuItemProps) => {
	const {
		className,
		wrapClass,
		iconWrapClass,
		titleClass,
		style,
		itemKey,
		icon,
		children,
		selectable,
		selected,
		disabled,
		onClick
	} = props;
	const classes = classNames(
		'menu-item',
		{'menu-item_selectable': selectable},
		{'menu-item_selected': selected},
		{'menu-item_with-icon': !!icon},
		{'menu-item_disabled': disabled},
		className
	);

	const selectItem = useSelectMenuItemCtx();

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			if (selectItem && itemKey) {
				selectItem(itemKey);
			}
			if (onClick) {
				event.persist();
				onClick(event);
			}
		},
		[itemKey, selectItem, onClick]
	);

	return (
		<div
			className={classes}
			style={style}
			onClick={!disabled ? handleClick : undefined}
		>
			<div className={classNames('menu-item__wrap', wrapClass)}>
				{icon && <div className={classNames('menu-item__icon-wrap', iconWrapClass)}>{icon}</div>}

				<div className={classNames('menu-item__title', titleClass)}>{children}</div>

				{selected && <i className="tz-mark-24 menu-item__mark"/>}
			</div>
		</div>
	);
};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
