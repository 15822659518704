import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObject, extractTargetObjects} from '@src/store/modules/dictionaries/objects/selectors';
import {extractSpacesGroupedByTypes, filterSpaces} from '@src/store/modules/entities/spaces/selectors/schemas';
import {ISpacesPageSettingsState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {ISpaceType, SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {useMemo} from 'react';

export interface IReportsTableRow {
	id: string;
	name: string;
	data?: Record<string, number>;
	subRows?: IReportsTableRow[];
	isTotal?: boolean;
}

const fillTotalObjectsRow = (
	totalObjectsRow: IReportsTableRow,
	...items: Array<{field: string, value: number}>
) => {
	for (const {field, value} of items) {
		totalObjectsRow.data = {
			...totalObjectsRow.data,
			[field]: totalObjectsRow.data?.[field]
				? totalObjectsRow.data[field] + value
				: value
		};
	}

	return totalObjectsRow;
};

const countSelectedPercent = (
	selected?: number,
	total?: number
) =>
	(selected !== undefined && total !== undefined
		? Math.floor((selected * 100) / total) || 0
		: 100);


const formSubRows = (
	spacesGroupedByObjects: Record<string, IListSpace[]>,
	parentObject: IObject,
	objectsMap: Record<string, IObject>,
	fieldName: string,
	fieldArr: string[],
	fieldMap: Record<string, unknown>,
	pageSettings: ISpacesPageSettingsState
): IReportsTableRow[] => {
	const subRows: IReportsTableRow[] = [];
	let totalObjectsRow: IReportsTableRow = {
		id: parentObject.id,
		name: `${parentObject.name} (итого)`,
		isTotal: true,
		data: {}
	};

	for (const [objectId, spaces] of Object.entries(spacesGroupedByObjects)) {
		const filtersObjects = pageSettings.filters.objects;

		const object = objectsMap[objectId];
		const filteredSpaces = filterSpaces(
			spaces,
			pageSettings.filters,
			undefined,
			pageSettings.stage,
			undefined,
			object.companyId
		);

		const subRow: IReportsTableRow = {
			id: object.id,
			name: object.name,
			data: {}
		};

		let selectedCount = 0;
		let totalCount = 0;
		for (const item of fieldArr) {
			const spaceFilter = (space: IListSpace) => space[fieldName] === item;

			const count = filteredSpaces.filter(spaceFilter).length;
			totalCount += spaces.filter(spaceFilter).length;

			if (fieldMap[item]) {
				selectedCount += count;
			}
			totalObjectsRow = fillTotalObjectsRow(
				totalObjectsRow,
				{field: item, value: count}
			);
			subRow.data = {...subRow.data, [item]: count};
		}
		subRow.data = {
			...subRow.data,
			selected: selectedCount,
			total: totalCount,
			selectedPercent: countSelectedPercent(selectedCount, totalCount)
		};
		totalObjectsRow = fillTotalObjectsRow(
			totalObjectsRow,
			{field: 'selected', value: selectedCount},
			{field: 'total', value: totalCount}
		);

		if (filtersObjects?.length && !filtersObjects.includes(objectId)) {
			continue;
		}

		subRows.push(subRow);
	}

	const selectedPercent = countSelectedPercent(
		totalObjectsRow.data?.selected, totalObjectsRow.data?.total
	);
	totalObjectsRow = fillTotalObjectsRow(
		totalObjectsRow,
		{field: 'selectedPercent', value: selectedPercent}
	);

	if (Object.keys(spacesGroupedByObjects).length > 1) {
		subRows.push(totalObjectsRow);
	}

	return subRows;
};

const formRowData = (
	spacesGroupedByTypes: Record<string, Record<string, IListSpace[]>>,
	spaceTypeMap: Record<SpaceTypeId, ISpaceType>,
	object: IObject,
	objectsMap: Record<string, IObject>,
	fieldName: string,
	fieldArr: string[],
	fieldMap: Record<string, unknown>,
	pageSettings: ISpacesPageSettingsState
): IReportsTableRow[] => {
	const rowData: IReportsTableRow[] = [];

	const filtersTypes = pageSettings.filters.types;
	for (const typeKey of Object.keys(spacesGroupedByTypes)) {
		if (filtersTypes?.length && !filtersTypes.includes(typeKey)) {
			continue;
		}

		const type = spaceTypeMap[typeKey as SpaceTypeId];
		const spacesGroupedByObjects = spacesGroupedByTypes[typeKey];

		const subRows = formSubRows(
			spacesGroupedByObjects, object, objectsMap, fieldName, fieldArr, fieldMap, pageSettings
		);
		const row: IReportsTableRow = {
			id: type.id,
			name: type.name,
			subRows
		};

		rowData.push(row);
	}
	return rowData;
};

export const useReportsTable = (
	objectId: string,
	field: string,
	fieldArr: string[],
	fieldMap: Record<string, unknown>
): IReportsTableRow[] => {
	const targetObjects = useAppSelector(s => extractTargetObjects(s, objectId));
	const object = useAppSelector(s => extractObject(s, objectId));
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const spacesGroupedByTypes = useAppSelector(s =>
		extractSpacesGroupedByTypes(s, targetObjects));

	const spaceTypeMap = useAppSelector(s => s.dictionaries.spaceTypes.byId);
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);

	const rowData = useMemo(() =>
		formRowData(
			spacesGroupedByTypes, spaceTypeMap, object, objectsMap, field, fieldArr, fieldMap, pageSettings
		),
	[spacesGroupedByTypes, spaceTypeMap, object, objectsMap, field, fieldArr, fieldMap, pageSettings]);

	return rowData;
};