import {checkRecordsQueryKeys} from '../keys';
import {makeCheckRecordEditStatusRequest} from '@src/api/backend/checkRecord/editStatus';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeCheckStateActions} from '@src/store/modules/pages/checkLists/actions';
import IError from '@tehzor/tools/interfaces/IError';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useQueryClient} from '@tanstack/react-query';
import {updateCheckRecordsCache} from './utils/updateCheckRecordsCache';
import {spacesQueryKeys} from '../../spaces/keys';
import {updateCachedState} from '@src/utils/updateCachedState';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {
	IChangeCheckRecordJournalData,
	IChangeCheckRecordStatusParams
} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import useAppSelector from '@src/core/hooks/useAppSelector';

export interface IChangeCheckRecordStatusMutationParams extends IChangeCheckRecordStatusParams {
	key: string;

	journalData: IChangeCheckRecordJournalData;
}
export interface IChangedRecord extends IChangeCheckRecordStatusMutationParams {
	transferStatus: OfflineDataTransferStatus;
	type: string;
}

export const useCheckRecordsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	queryClient.setMutationDefaults(checkRecordsQueryKeys.changeList(), {
		onMutate: ({
			key,
			item,
			objectId,
			spaceId,
			workAcceptanceId,
			stage,
			status,
			record,
			journalData
		}: IChangeCheckRecordStatusMutationParams) => {
			queryClient.setQueryData<IChangedRecord>(checkRecordsQueryKeys.detail(key), {
				type: 'checklist',
				key,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				item,
				objectId,
				spaceId,
				workAcceptanceId,
				stage,
				status,
				record,
				journalData
			});
			if (!record.id) {
				record = {
					id: key,
					links: {
						spaceId,
						workAcceptanceId
					},
					stage,
					objectId
				} as ICheckRecord;
			}
			const optimisticRecord: ICheckRecord = {
				...record,
				...record.links,
				links: {...record.links, checkListId: item.id},
				status
			};
			updateCheckRecordsCache(optimisticRecord, queryClient, objects);
			dispatch(
				changeCheckStateActions.success([optimisticRecord], spaceId, workAcceptanceId)
			);
		},
		mutationFn: async ({
			key,
			item,
			objectId,
			spaceId,
			workAcceptanceId,
			stage,
			status
		}: IChangeCheckRecordStatusMutationParams) => {
			await updateCachedState<IChangedRecord>(
				checkRecordsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			dispatch(changeCheckStateActions.request());
			return makeCheckRecordEditStatusRequest(
				objectId,
				{spaceId, workAcceptanceId, checkListId: item.id},
				stage,
				status
			);
		},
		onError: async (err, {key}: IChangeCheckRecordStatusMutationParams) => {
			await updateCachedState<IChangedRecord>(
				checkRecordsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
			dispatch(changeCheckStateActions.failure(err as IError));
		},
		onSuccess: async (
			data: ICheckRecord[],
			{objectId, spaceId, workAcceptanceId, key}: IChangeCheckRecordStatusMutationParams
		) => {
			await updateCachedState<IChangedRecord>(
				checkRecordsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			queryClient.removeQueries(checkRecordsQueryKeys.detail(key));
			await queryClient.invalidateQueries({
				queryKey: [...checkRecordsQueryKeys.list(), undefined, objects]
			});
			await queryClient.invalidateQueries({
				queryKey: checkRecordsQueryKeys.list(),
				predicate: ({queryKey}) => {
					if (queryKey.includes(spaceId)) {
						return true;
					}
					return false;
				}
			});
			await queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.checkListsData(),
				predicate: ({queryKey}) => {
					if (queryKey.includes(objectId)) {
						return true;
					}
					return false;
				}
			});
			dispatch(changeCheckStateActions.success(data, spaceId, workAcceptanceId));
		}
	});
	queryClient.setMutationDefaults(checkRecordsQueryKeys.changeItem(), {
		onMutate: ({
			key,
			item,
			objectId,
			spaceId,
			workAcceptanceId,
			stage,
			status,
			record,
			journalData
		}: IChangeCheckRecordStatusMutationParams) => {
			queryClient.setQueryData<IChangedRecord>(checkRecordsQueryKeys.detail(key), {
				type: 'checkitem',
				key,
				transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
				item,
				objectId,
				spaceId,
				workAcceptanceId,
				stage,
				status,
				record,
				journalData
			});
			if (!record.id) {
				record = {
					id: key,
					links: {
						spaceId,
						workAcceptanceId
					},
					stage,
					objectId
				} as ICheckRecord;
			}
			const optimisticRecord: ICheckRecord = {
				...record,
				links: {
					...record.links,
					checkListId: (item as ICheckItem).checkListId,
					checkItemId: item.id
				},
				status
			};
			updateCheckRecordsCache(optimisticRecord, queryClient, objects);
			dispatch(
				changeCheckStateActions.success([optimisticRecord], spaceId, workAcceptanceId)
			);
		},
		mutationFn: async ({
			key,
			item,
			objectId,
			spaceId,
			workAcceptanceId,
			stage,
			status
		}: IChangeCheckRecordStatusMutationParams) => {
			await updateCachedState<IChangedRecord>(
				checkRecordsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			dispatch(changeCheckStateActions.request());
			return makeCheckRecordEditStatusRequest(
				objectId,
				{
					spaceId,
					workAcceptanceId,
					checkListId: (item as ICheckItem).checkListId,
					checkItemId: item.id
				},
				stage,
				status
			);
		},
		onError: async (err, {key}: IChangeCheckRecordStatusMutationParams) => {
			await updateCachedState<IChangedRecord>(
				checkRecordsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
			dispatch(changeCheckStateActions.failure(err as IError));
		},
		onSuccess: async (
			data: ICheckRecord[],
			{objectId, spaceId, workAcceptanceId, key}: IChangeCheckRecordStatusMutationParams
		) => {
			await updateCachedState<IChangedRecord>(
				checkRecordsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			queryClient.removeQueries(checkRecordsQueryKeys.detail(key));
			await queryClient.invalidateQueries({
				queryKey: [...checkRecordsQueryKeys.list(), undefined, objects]
			});
			await queryClient.invalidateQueries({
				queryKey: checkRecordsQueryKeys.list(),
				predicate: ({queryKey}) => {
					if (queryKey.includes(spaceId)) {
						return true;
					}
					return false;
				}
			});
			await queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.checkListsData(),
				predicate: ({queryKey}) => {
					if (queryKey.includes(objectId)) {
						return true;
					}
					return false;
				}
			});
			dispatch(changeCheckStateActions.success(data, spaceId, workAcceptanceId));
		}
	});
};
