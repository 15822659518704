import React, {useCallback} from 'react';
import {ButtonMenu, Menu, MenuItem} from '@tehzor/ui-components';
import {StatisticsGrouping} from '@src/interfaces/StatisticsGrouping';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {changeGrouping} from '@src/store/modules/settings/pages/statistics/building/actions';

const menuItems = {
	[StatisticsGrouping.COMPANIES]: 'Компании',
	[StatisticsGrouping.OBJECTS]: 'Объекты',
	[StatisticsGrouping.USERS]: 'Инженеры',
	[StatisticsGrouping.CONTRACTORS]: 'Подрядчики'
};

const BuildingGrouping = () => {
	const value = useAppSelector(s => s.settings.pages.statistics.building.grouping);
	const companies = useAppSelector(s => s.dictionaries.companies);
	const dispatch = useAppDispatch();

	const handleChange = useCallback((key: StatisticsGrouping) => {
		dispatch(changeGrouping(key));
	}, []);

	const items = [
		<MenuItem
			key={StatisticsGrouping.OBJECTS}
			itemKey={StatisticsGrouping.OBJECTS}
		>
			{menuItems[StatisticsGrouping.OBJECTS]}
		</MenuItem>,
		<MenuItem
			key={StatisticsGrouping.USERS}
			itemKey={StatisticsGrouping.USERS}
		>
			{menuItems[StatisticsGrouping.USERS]}
		</MenuItem>,
		<MenuItem
			key={StatisticsGrouping.CONTRACTORS}
			itemKey={StatisticsGrouping.CONTRACTORS}
		>
			{menuItems[StatisticsGrouping.CONTRACTORS]}
		</MenuItem>
	];

	if (companies.allIds.length > 1) {
		items.unshift(
			<MenuItem
				key={StatisticsGrouping.COMPANIES}
				itemKey={StatisticsGrouping.COMPANIES}
			>
				{menuItems[StatisticsGrouping.COMPANIES]}
			</MenuItem>
		);
	}

	return (
		<ButtonMenu
			className="statistics-page__grouping-btn"
			type="accent-blue"
			label={value ? menuItems[value] : 'Группировка'}
		>
			<Menu
				value={value}
				onChange={handleChange}
			>
				{items}
			</Menu>
		</ButtonMenu>
	);
};

export default BuildingGrouping;
