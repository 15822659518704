import React, {ReactElement, useMemo} from 'react';
import DraggableMenu, {IDraggableMenuItemProps} from '../../../navigation/DraggableMenu';
import PhotoPreview from '../../PhotoPreview';
import {IEntity} from '../EntitiesMobilePhotoViewer';
import classNames from 'classnames';

interface INavigationProps {
	entities: IEntity[];
	value: number;

	onChange: (index: number) => void;
}

const Navigation = ({entities, value, onChange}: INavigationProps) => {
	const files = useMemo(
		() => entities.reduce<Array<ReactElement<IDraggableMenuItemProps>>>((prev, item, entityIndex) => {
			prev = prev.concat(item.files.map((url, fileIndex) => (
				<PhotoPreview
					className={classNames(
						'em-photo-viewer__previews-photo',
						{
							'em-photo-viewer__previews-photo_last-of-group':
								fileIndex === item.files.length - 1 && entityIndex !== entities.length - 1
						}
					)}
					url={url}
				/>
			)));
			return prev;
		}, []),
		[entities]
	);

	return (
		<DraggableMenu
			className="em-photo-viewer__previews-nav"
			itemClassName="em-photo-viewer__previews-nav-item"
			value={value}
			items={files}
			disableFakeItem
			onSelect={onChange}
		/>
	);
};

export default Navigation;