import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {EntityGrid, EntityGridItem, Plate} from '@tehzor/ui-components';
import React from 'react';
import {excelXmlType, pdfType, wordXmlType} from '@tehzor/tools/utils/mimeTypes';
import {Temp} from './desktop/Temp';

const nameIcon = <i className="tz-name-20"/>;
const typeIcon = <i className="tz-document-type-20"/>;
const formatIcon = <i className="tz-document-format-20"/>;
const numberIcon = <i className="tz-number-20"/>;
const exportTemplateIcon = <i className="tz-export-template-20"/>;
const companyIcon = <i className="tz-company-20"/>;
const customerIcon = <i className="tz-customer-20"/>;

const fileTypeNames = {
	[wordXmlType]: 'docx',
	[excelXmlType]: 'xlsx',
	[pdfType]: 'pdf'
};

interface IDesktopProps {
	document?: IDocument;
}

export const Desktop = ({document}: IDesktopProps) => {
	if (!document) {
		return null;
	}

	return (
		<div className="document-page__plates">
			<Plate className={{content: 'document-page__info-plate'}}>
				<div className="document-page__title">Информация</div>
				<EntityGrid
					withBorders
				>
					{!!document.fileName && (
						<EntityGridItem
							label="Название"
							fullRow
							icon={nameIcon}
						>
							{document.fileName}
						</EntityGridItem>
					)}

					{!!document.type && (
						<EntityGridItem
							label="Тип документа"
							fullRow
							icon={typeIcon}
						>
							{document.type.name}
						</EntityGridItem>
					)}

					<EntityGridItem
						label="Номер"
						fullRow
						icon={numberIcon}
					>
						{document.number}
					</EntityGridItem>

					{!!document.fileType && (
						<EntityGridItem
							label="Формат документа"
							fullRow
							icon={formatIcon}
						>
							{fileTypeNames[document.fileType]}
						</EntityGridItem>
					)}

					{!!document.exportTemplate && (
						<EntityGridItem
							label="Шаблон экспорта"
							fullRow
							icon={exportTemplateIcon}
						>
							{document.exportTemplate.name}
						</EntityGridItem>
					)}

					{!!document.company && (
						<EntityGridItem
							label="Компания"
							fullRow
							icon={companyIcon}
						>
							{document.company.name}
						</EntityGridItem>
					)}

					{!!document.contractor && (
						<EntityGridItem
							label="Подрядчик"
							fullRow
							icon={customerIcon}
						>
							{document.contractor.name}
						</EntityGridItem>
					)}
				</EntityGrid>
			</Plate>

			<Plate className={{root: 'document-page__preview-plate', content: 'document-page__preview-plate-content'}}>
				<Temp/>

				<div className="document-page__preview-message">
					Интерфейс на стадии доработки
				</div>
			</Plate>
		</div>
	);
};
