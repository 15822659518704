import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IOwnerAcceptanceStatus} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';

const getAllIds = (state: IState): string[] =>
	state.dictionaries.ownerAcceptanceStatuses.allIds || [];
const getById = (state: IState): Record<string, IOwnerAcceptanceStatus> =>
	state.dictionaries.ownerAcceptanceStatuses.byId || {};

/**
 * Возвращает статусы в виде массива
 */
export const extractOwnerAcceptanceStatusesAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map(id => byId[id])
);
