import React from 'react';
import './SidebarToggleBtn.less';
import ButtonBase from '../../buttons/ButtonBase';
import classNames from 'classnames';

interface ISidebarToggleBtnProps {
	className?: string;
	style?: React.CSSProperties;
	reversed?: boolean;

	onClick?(): void;
}

const SidebarToggleBtn = (props: ISidebarToggleBtnProps) => {
	const {className, style, reversed, onClick} = props;

	return (
		<ButtonBase
			className={classNames({'sidebar-toggle-btn_reversed': reversed}, className)}
			style={style}
			leftIcon={<i className="tz-simple-arrow-24"/>}
			classNamePrefix="sidebar-toggle-btn"
			outerTagType="button"
			onClick={onClick}
		/>
	);
};

export default SidebarToggleBtn;
