import React, {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import SelectionActions from '../selection/SelectionActions';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';

const SelectionRow = (props: ISelectionRowProps<IProblemEntity>) => {
	const {selectedFlatRows, toggleAllRowsSelected} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				selectedEntities={selectedFlatRows.map(item => item.original)}
				selectedClearing={clearSelection}
			/>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;
