/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {TableRowSelect} from '@tehzor/ui-components';
import {HeaderProps, Renderer} from 'react-table';

const HeaderCellWithRowSelectWrap
	= (title: string | Renderer<HeaderProps<object>>) =>
	({getToggleAllRowsSelectedProps}: HeaderProps<object>) =>
		(
			<>
				<TableRowSelect
					{...getToggleAllRowsSelectedProps()}
					style={{marginRight: '26px'}}
				/>
				{title}
			</>
		);

export default HeaderCellWithRowSelectWrap;
