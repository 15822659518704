import React from 'react';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {DownloadButton} from './DownloadButton';

interface IDesktopActionsProps {
	document: IDocument;
}

export const Actions = ({document}: IDesktopActionsProps) => (
	<DownloadButton
		document={document}
	/>
);
