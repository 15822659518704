import React, {ReactElement, useCallback, useRef} from 'react';
import {Select2} from '../../inputs';
import Popup from '../../containers/Popup';
import {useSearchSelectHandlers} from '../hooks';
import useResizeObserver from 'use-resize-observer';
import {UseLayerOptions} from 'react-laag';
import {IAnyEntity} from '@tehzor/tools/interfaces/IAnyEntity';
import classNames from 'classnames';
import {ITextSearchProvider} from '../interfaces';
import {useVirtualizer} from '@tanstack/react-virtual';
import './SearchSelectPopup.less';

interface SearchSelectPopupProps<T extends IAnyEntity> {
	trigger: UseLayerOptions['trigger'] | undefined;
	isOpen: boolean;
	noCheckbox?: boolean;
	provider: ITextSearchProvider<T>;

	renderItem: (item: T) => ReactElement;
	onSelect: (value: T | undefined) => void;
	toggle: (nextValue?: boolean) => void;
}

export const SearchSelectPopup = <T extends IAnyEntity>({
	trigger,
	toggle,
	isOpen,
	provider,
	onSelect,
	renderItem,
	noCheckbox
}: SearchSelectPopupProps<T>) => {
	const [selected, onValueSelected] = useSearchSelectHandlers(provider, onSelect);
	const parentRef = useRef<HTMLDivElement>(null);

	const {ref, width} = useResizeObserver();

	const count = provider.filteredList.length;
	const virtualizer = useVirtualizer({
		count,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 45
	});

	const items = virtualizer.getVirtualItems();

	const handleSelection = useCallback(
		(value: string) => {
			onValueSelected(value);
			toggle(false);
		},
		[onValueSelected, toggle]
	);

	if (!trigger) {
		return null;
	}

	return (
		<div
			className="search-select-popup"
			ref={ref}
		>
			<Popup
				className={{content: 'search-select-popup__content'}}
				triggerObject={trigger}
				placement="bottom-start"
				possiblePlacements={['bottom-start']}
				isOpen={isOpen}
				onOutsideClick={toggle}
			>
				<div
					ref={parentRef}
					className="search-select-popup__list"
					style={{width}}
				>
					<div
						className="search-select-popup__list-inner"
						style={{
              height: virtualizer.getTotalSize()
						}}
					>
						<Select2
							className={classNames('search-select-popup__list-inner-content', {
								'search-select-popup__select_no-checkbox': noCheckbox
							})}
							value={selected}
							onChange={handleSelection}
							style={{transform: `translateY(${items[0] ? items[0].start : 0}px)`}}
						>
							{items.map(virtualRow => {
								const item = provider.filteredList[virtualRow.index];

								return (
									<div
										key={virtualRow.key}
										data-index={virtualRow.index}
										ref={virtualizer.measureElement}
									>
										{renderItem(item)}
									</div>
								);
							})}
						</Select2>
					</div>
				</div>
			</Popup>
		</div>
	);
};
