import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IPreparedCheck} from '../interfaces/IPreparedCheck';
import {getPermissionsForCheck} from './getPermissionsForCheck';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';

/**
 * Преобразовывает проверки в необходимый для вывода формат
 *
 * @param checks массив проверок
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 */
export const convertChecks = (
	checks: IListCheck[],
	networkStatus: boolean,
	user?: IUser
): IPreparedCheck[] =>
	checks.map(item => ({
		id: item.id,
		object: item.object,
		number: item.number,
		localNumber: item.localNumber,
		createdBy: item.createdBy,
		createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeCommaSeparatedFormat) : '',
		stats: item.stats,
		...getPermissionsForCheck(item, networkStatus, item.objectId, user)
	}));
