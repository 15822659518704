import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IOwnerAcceptanceResolution} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';

const getAllIds = (state: IState): string[] =>
	state.dictionaries.ownerAcceptanceResolutions.allIds || [];
const getById = (state: IState): Record<string, IOwnerAcceptanceResolution> =>
	state.dictionaries.ownerAcceptanceResolutions.byId || {};

/**
 * Возвращает решения в виде массива
 */
export const extractOwnerAcceptanceResolutionsAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map(id => byId[id])
);
