import {format} from 'date-fns';
import React, {useCallback} from 'react';
import './TimePicker.less';
import {useToggle} from 'react-use';
import {Popup} from '../../../../containers';
import {IPopupMenuTriggerFnProps} from '../../../../menu/PopupMenu';
import classNames from 'classnames';
import {Dialog} from '../../../../dialogs';

interface ITimePickerPopupTriggerFnProps extends IPopupMenuTriggerFnProps {
	value?: Date;
}

interface ITimePickerProps {
	value: Date;
	setValue: (value: Date) => void;
	mobile: boolean;
	timeInterval: number;
	trigger: (props: ITimePickerPopupTriggerFnProps) => React.ReactNode;
}

export const TimePicker = (props: ITimePickerProps) => {
	const {
		value,
		setValue,
		mobile,
		timeInterval,
		trigger
	} = props;

	const [isOpen, toggleOpen] = useToggle(false);

	const open = useCallback(() => {
		toggleOpen(true);
	}, [toggleOpen]);

	const close = useCallback(() => {
		toggleOpen(false);
	}, [toggleOpen]);

	const getTrigger = useCallback(
		(triggerProps: IPopupMenuTriggerFnProps) =>
			trigger({
				...triggerProps,
				value
			}),
		[value, trigger]
	);

	const triggerActions = {
		open,
		close,
		toggle: isOpen ? close : open
	};

	const handleApply = (hours: number, minutes: number) => {
		setValue(
			new Date(value.getFullYear(), value.getMonth(), value.getDate(), hours, minutes)
		);
		close();
	};

	const timeElements: number[] = [];
	if (timeInterval > 0 && timeInterval < 1440) {
		for (let i = 0; i < 86400000; i += 60000 * timeInterval) {
			timeElements.push(i);
		}
	}

	const times = timeElements.map(el => {
		const hours = Math.floor(el / 3600000);
		const minutes = Math.floor((el - hours * 3600000) / 60000);
		const active = value.getHours() === hours && value.getMinutes() === minutes;
		return (
			<div
				role="presentation"
				key={el}
				className={classNames('time-picker__row', active && 'time-picker__row_selected')}
				onClick={() => { handleApply(hours, minutes); }}
			>
				{format(new Date(0, 0, 0, hours, minutes), 'HH:mm')}
			</div>
		);
	});

	return (mobile
		? (
			<>
				{trigger({...triggerActions})}

				<Dialog
					isOpen={isOpen}
					fullScreenOnMobile
					onRequestClose={close}
				>
					<div>
						{times}
					</div>

				</Dialog>
			</>
		) : (
			<Popup
				isOpen={isOpen}
				trigger={(triggerProps: ITimePickerPopupTriggerFnProps) =>
					getTrigger({...triggerProps, ...triggerActions})}
			>
				<div className="time-picker__popup">
					{times}
				</div>
			</Popup>
		));
};
