import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting,
	handleLoadableEntityAdding,
	handleLoadableEntityDeletion,
	handleLoadableEntityEditing,
	ILoadableEntitiesState
} from '@tehzor/tools/core/storeHelpers/reducers';

export type IProblemsTagsSetsState = ILoadableEntitiesState<IProblemTagsSet>;

export const problemTagsSets = createReducer<IProblemsTagsSetsState>(
	getLoadableEntitiesInitialState<IProblemTagsSet>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IProblemTagsSet>(),
		[types.ADD_SUCCESS]: handleLoadableEntityAdding<IProblemTagsSet>(),
		[types.EDIT_SUCCESS]: handleLoadableEntityEditing<IProblemTagsSet>(),
		[types.DELETE_SUCCESS]: handleLoadableEntityDeletion<IProblemTagsSet>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IProblemTagsSet>()
	}
);