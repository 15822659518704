import React from 'react';
import {useEditableProblemTagState} from '@src/core/hooks/states/useEditableProblemTagState';
import {IExtendedSavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IExtendedSavingProblemTag';
import {EditableProblemTag} from './EditableProblemTag';
import {IProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';

interface IEditableResponsibilityRuleWrapProps {
	tag: IExtendedSavingProblemTag;
	index: number;
	problemTagsSetId: string;
	tags: IExtendedSavingProblemTag[];
	setArray: React.Dispatch<React.SetStateAction<IExtendedSavingProblemTag[]>>;
	activeTagId?: string;
	setActiveTagId: React.Dispatch<React.SetStateAction<string | undefined>>;
	permissions: IProblemTagsSetPermissions;
	disabledActions?: boolean;
}

export const EditableProblemTagWrap = ({
	tag,
	index,
	problemTagsSetId,
	tags,
	setArray,
	activeTagId,
	setActiveTagId,
	permissions,
	disabledActions
}: IEditableResponsibilityRuleWrapProps) => {
	const [editingState, editingDispatch] = useEditableProblemTagState(tag);

	return (
		<EditableProblemTag
			editingState={editingState}
			editingDispatch={editingDispatch}
			tags={tags}
			problemTagsSetId={problemTagsSetId}
			tag={tag}
			setArray={setArray}
			setActiveTagId={setActiveTagId}
			activeTagId={activeTagId}
			index={index}
			permissions={permissions}
			disabledActions={disabledActions}
		/>
	);
};
