import React, {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton, TreeSelect} from '@tehzor/ui-components';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractObjectsAsArray,
	extractTargetObjects
} from '@src/store/modules/dictionaries/objects/selectors';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const ObjectsFilterPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{objects?: string[]}>();
	const [selected, setSelected] = useState(state.objects);
	const [expanded, setExpanded] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const companies = useAppSelector(extractCompaniesAsArray);
	const objects = useAppSelector(extractObjectsAsArray);
	const targetObjects = useAppSelector(s =>
		(objectId ? extractTargetObjects(s, objectId) : undefined));

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const treeData = useMemo(() => {
		if (targetObjects) {
			return makeObjectsTreeData(objects.filter(item => targetObjects.includes(item.id)));
		}
		return makeObjectsTreeData(objects, companies);
	}, [targetObjects, objects, companies]);

	const {filteredData, expanded: expandedValue} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpanded(expandedValue.map(item => item.id));
	}, [expandedValue]);

	const handleApply = useCallback(() => {
		change({objects: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
		setExpanded(undefined);
	}, []);

	useAppHeader(
		{
			title: 'Объект',
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label="Сбросить"
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={isLocalStateEqual(state.objects, selected)}
			onApplyClick={handleApply}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selected}
				onChange={setSelected}
				expandedValue={expanded}
				onExpand={setExpanded}
			/>
		</FilterPage>
	);
};

export default ObjectsFilterPage;
