import React from 'react';
import PlansPage from '@src/pages/PlansPage';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PlanPage from '@src/pages/PlanPage';
import {PrivateRoute} from '@src/containers/PrivateRoute';

export const objectPlansRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<PlansPage/>}/>
	},
	{
		path: 'add',
		element: <PrivateRoute element={<PlanPage/>}/>
	},
	{
		path: ':planId',
		element: <PrivateRoute element={<PlanPage/>}/>
	}
];
