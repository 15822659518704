import React, {cloneElement, useCallback} from 'react';
import Popup, {IPopupBasicProps} from '../../containers/Popup';
import './PopupMenu.less';
import {IMenuItemProps, IMenuComponentProps} from '../MenuItem';
import classNames from 'classnames';
import useToggle from 'react-use/lib/useToggle';
import {RefCallback} from 'react-laag/dist/types.d';
import {useClonedMenu} from '../../../hooks/useClonedMenu';

export interface IPopupMenuTriggerElProps {
	[key: string]: unknown;

	isOpen?: boolean;
	disabled?: boolean;

	onClick?: () => void;
}

export interface IPopupMenuTriggerFnProps {
	isOpen?: boolean;
	open?: () => void;
	close?: () => void;
	toggle?: () => void;
	ref?: RefCallback;
	disabled?: boolean;
}

export interface IPopupMenuProps<V, M extends IMenuComponentProps<V>> {
	className?: string;
	trigger: React.ReactElement<IPopupMenuTriggerElProps> |
		((props: IPopupMenuTriggerFnProps) => React.ReactNode);
	children?: React.ReactElement<M> | Array<React.ReactElement<IMenuItemProps>>;
	arrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
}

const PopupMenu = <V, M extends IMenuComponentProps<V>>(props: IPopupMenuProps<V, M>) => {
	const {className, trigger, children, arrowVisible, popupProps} = props;

	const [isOpen, toggleOpen] = useToggle(false);
	const open = useCallback(() => {
		toggleOpen(true);
	}, []);

	const close = useCallback(() => {
		toggleOpen(false);
	}, []);

	// Клонирование children для отложенного запуска обработчиков onClick и onChange у MenuItem и Menu.
	// Без данного действия анимация закрытия Popup'а происходит с подлагиванием
	const clonedChildren = useClonedMenu(children, close, 160);

	return clonedChildren ? (
		<Popup
			{...popupProps}
			className={classNames('popup-menu', className)}
			trigger={triggerProps =>
				(typeof trigger === 'function'
					? trigger({
						...triggerProps,
						isOpen,
						open,
						close,
						toggle: toggleOpen
					})
					: cloneElement(trigger, {
						isOpen,
						onClick: toggleOpen,
						ref: triggerProps.ref
					}))}
			isOpen={isOpen}
			arrowVisible={arrowVisible}
			onOutsideClick={close}
			onDisappear={close}
		>
			{clonedChildren}
		</Popup>
	) : (
		<>{typeof trigger === 'function' ? trigger({disabled: true}) : cloneElement(trigger, {disabled: true})}</>
	);
};

PopupMenu.displayName = 'PopupMenu';

export default PopupMenu;
