import React, {useCallback} from 'react';
import {Button} from '@tehzor/ui-components';
import {useInspectionExport} from '@src/core/hooks/export/useInspectionExport';

interface IInspectionExportProps {
	objectId: string;
	inspectionId: string;
}

const DesktopInspectionExportBtn = ({objectId, inspectionId}: IInspectionExportProps) => {
	const [exportDialog, openExportDialog] = useInspectionExport(objectId, inspectionId);

	const handleExport = useCallback(() => {
		openExportDialog(objectId, inspectionId);
	}, [objectId, inspectionId, openExportDialog]);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-export-20"/>}
				label="Экспорт"
				onClick={handleExport}
			/>
			{exportDialog}
		</>
	);
};

export default DesktopInspectionExportBtn;
