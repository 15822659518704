import React, {useCallback, useMemo} from 'react';
import './VisibilityFilter.less';
import {FilterMenu, MenuItem, MultipleMenu} from '@tehzor/ui-components';
import {AnyAction} from 'redux';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import classNames from 'classnames';

const sortFn = (a: string, b: string) => (a > b ? -1 : 1);

interface IVisibilityFilterProps<T extends string> {
	className?: string;
	style?: React.CSSProperties;
	value: T[];
	titlesMap: Record<T, string>;

	onChange: (keys: T[]) => AnyAction;
}

const VisibilityFilter = <T extends string>(props: IVisibilityFilterProps<T>) => {
	const {className, style, value, titlesMap, onChange} = props;
	const dispatch = useAppDispatch();

	const label = useMemo(
		() =>
			(value.length
				? `Показать: ${value.map(key => titlesMap[key]).join(', ')}`
				: 'Выберите, что показать'),
		[value]
	);

	const handleChange = useCallback((v: T[]) => {
		dispatch(onChange(v.sort(sortFn)));
	}, []);

	return (
		<FilterMenu
			className={{
				root: classNames('visibility-filter', className),
				label: 'visibility-filter__label'
			}}
			style={style}
			label={label}
			active={value.length > 0}
		>
			<MultipleMenu
				value={value}
				onChange={handleChange}
			>
				{Object.entries<string>(titlesMap).map(([key, title]) => (
					<MenuItem
						key={key}
						itemKey={key}
					>
						{title}
					</MenuItem>
				))}
			</MultipleMenu>
		</FilterMenu>
	);
};

export default VisibilityFilter;
