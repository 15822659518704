import React from 'react';
import './ExpandableMobileInfo.less';
import {ExpandablePanel, InlineButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import classNames from 'classnames';

interface IExpandableMobileInfoProps {
	children?: React.ReactNode;
	noBorder?: boolean;
}

const ExpandableMobileInfo = (props: IExpandableMobileInfoProps) => {
	const {children, noBorder} = props;

	const [expanded, toggleExpanded] = useToggle(false);

	return (
		<>
			<ExpandablePanel
				className="expandable-mobile-info-panel"
				expanded={expanded}
			>
				<div className="expandable-mobile-info-panel__content">{children}</div>
			</ExpandablePanel>

			<div
				className={classNames('expandable-mobile-info', {
					'expandable-mobile-info_expanded': expanded,
					'expandable-mobile-info_no-border': expanded && noBorder
				})}
			>
				{expanded ? (
					<InlineButton
						className="expandable-mobile-info__hide-btn"
						rightIconClassName="expandable-mobile-info__hide-btn-icon"
						type="accent"
						rightIcon={<i className="tz-simple-arrow-28"/>}
						onClick={toggleExpanded}
					/>
				) : (
					<InlineButton
						className="expandable-mobile-info__expand-btn"
						rightIconClassName="expandable-mobile-info__expand-btn-icon"
						type="accent"
						label="Подробная информация"
						leftIcon={<i className="tz-info-20"/>}
						rightIcon={<i className="tz-simple-arrow-20"/>}
						onClick={toggleExpanded}
					/>
				)}
			</div>
		</>
	);
};

export default ExpandableMobileInfo;
