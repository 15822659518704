import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeSpaceDeleteRequest} from '@src/api/backend/space/delete';

export interface IDeleteSpacePayload {
	objectId: string;
	spaceId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, spaceId: string) => {
	addInfoToast('Удалено', 'Помещение успешно удалено');
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			spaceId
		} as IDeleteSpacePayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении помещения');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет помещение
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 */
export const deleteSpace = (objectId: string, spaceId: string) =>
	createApiAction(
		request,
		() => success(objectId, spaceId),
		failure,
		() => makeSpaceDeleteRequest(objectId, spaceId)
	);
