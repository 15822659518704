import classNames from 'classnames';
import React from 'react';
import {Button} from '../buttons';
import {IconMenu, MenuItem} from '../menu';

interface IItemMobileOptionsProps<S> {
	item: S;
	isCurrent?: boolean;
	onCreate: (parentId?: S) => void;
	onRemove: (id: string) => void;
	onConfirm: (id: string) => void;
	onCancel: (id: string) => void;
}

const addIcon = <i className="tz-plus-20"/>;
const removeIcon = <i className="tz-delete"/>;

export const ItemMobileOptions = <S extends { id: string, name?: string }>({
	item,
	isCurrent,
	onCreate,
	onRemove,
	onConfirm,
	onCancel
}: IItemMobileOptionsProps<S>) => (
	<div className={classNames('editable-set__item-options')}>

		{isCurrent && (
			<div className="editable-set__item-options__edit">
				<Button
					label="Сохранить"
					type="accent-blue"
					onClick={() => onConfirm(item.id)}
				/>
				<Button
					label="Отменить"
					type="cancel"
					onClick={() => onCancel(item.id)}
				/>
			</div>
		)}

		<IconMenu className="editable-set__item-options__view">
			<MenuItem
				onClick={() => onCreate(item)}
				icon={addIcon}
			>
				Вложенный элемент
			</MenuItem>
			<MenuItem
				onClick={() => onRemove(item.id)}
				icon={removeIcon}
			>
				Удалить
			</MenuItem>
		</IconMenu>

	</div>
	);
