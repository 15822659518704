import React, {useCallback, useMemo} from 'react';
import {ITreeItemProps} from '../../../Tree/components/TreeItem';
import Tree, {ITreeDataItem} from '../../../Tree';
import TreeSelectOption from '../TreeSelectOption';

export type ITreeSelectProps<
	T extends ITreeDataItem,
	V extends string = string
> = {
	className?: string;
	style?: React.CSSProperties;
	expandedValue?: string[];
	defaultExpandedValue?: string[];
	isAllExpanded?: boolean;
	isAllCheckedExpanded?: boolean;
	/**
	 * Выбирать без учёта дочерних/родительских элементов
	 */
	checkStrictly?: boolean;
	disabled?: boolean;
	data: T[];
	isCheckable?: boolean;
	latestOnly?: boolean;
	onExpand?: (keys: string[]) => void;
	TreeItem?: React.ComponentType<ITreeItemProps<T>>;
} & (
	| {
			multiple?: false;
			value?: V;

			onChange?: (value: V) => void;
	  }
	| {
			multiple: true;
			value?: V[];

			onChange?: (value: V[]) => void;
	  }
);

const TreeSelect = <T extends ITreeDataItem, V extends string = string>({
	value,
	onChange,
	multiple,
	TreeItem = TreeSelectOption,
	...props
}: ITreeSelectProps<T>) => {
	const val = useMemo(() => {
		if (!value) {
			return [];
		}
		return Array.isArray(value) ? value : [value];
	}, [value]);

	const handleChange = useCallback(
		(keys: V[]) => {
			if (onChange) {
				if (multiple) {
					(onChange as (v: V[]) => void)(keys);
				} else if (keys.length) {
					(onChange as (v: V) => void)(keys[0]);
				}
			}
		},
		[onChange, multiple]
	);

	return (
		<Tree
			{...props}
			checkedValue={val}
			multiple={multiple}
			onCheck={handleChange}
			TreeItem={TreeItem}
		/>
	);
};

TreeSelect.displayName = 'TreeSelect';

export default TreeSelect;
