import React from 'react';
import {Button, IconButton} from '@tehzor/ui-components';
import {useInspectionsExport} from '@src/core/hooks/export/useInspectionsExport';

interface IDesktopInspectionsExportProps {
	objectId?: string;
}

const DesktopInspectionsExport = ({objectId}: IDesktopInspectionsExportProps) => {
	const [exportDialog, openExportDialog] = useInspectionsExport(objectId);

	return (
		<>
			{objectId ? (
				<IconButton
					type="accent-blue"
					onClick={openExportDialog}
				>
					<i className="tz-export-20"/>
				</IconButton>
			) : (
				<Button
					type="accent-blue"
					label="Экспорт"
					leftIcon={<i className="tz-export-20"/>}
					onClick={openExportDialog}
				/>
			)}
			{exportDialog}
		</>
	);
};

export default DesktopInspectionsExport;
