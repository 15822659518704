import findSectors from './findSectors';
import AbstractHandler from './AbstractHandler';

/**
 * Класс для работы с осями
 */
class AxesHandler extends AbstractHandler {
	// Счётчик осей
	_axesCounter = 1;

	// Временный объект для рисования
	_tmpAxis = null;

	// Доступные режимы рисования
	_availableModes = ['select', 'axis'];

	// Доступные фигуры для выделения
	_availableShapes = ['line'];

	/**
	 * Добавляет ось
	 */
	addShape() {
		if (!this._svg || !this._group) {
			return;
		}

		this._tmpAxis = this._group
			.axis()
			.draw();

		this._tmpAxis.on('drawstop', () => {
			this._tmpAxis.off('drawstop');
			const attrs = this._tmpAxis.attr();

			if (Math.abs(attrs.x1 - attrs.x2) < 10 && Math.abs(attrs.y1 - attrs.y2) < 10) {
				this._tmpAxis.remove();
				this._tmpAxis = null;
			} else {
				this._shapes.push({
					element: this._tmpAxis,
					name: `Ось ${this._axesCounter++}`,
					selected: false
				});
				this._emit();
			}
			this.addShape();
		});
	}

	/**
	 * Отключает возможность добавления новой оси
	 */
	cancelShapeAdding() {
		if (this._svg && this._tmpAxis) {
			this._tmpAxis.off('drawstop');
			this._tmpAxis.draw('cancel');
			this._tmpAxis.remove();
			this._tmpAxis = null;
		}
	}

	/**
	 * Формирует сиекторы из осей
	 */
	generateShapes() {
		const axes = this._shapes.map(item => {
			const points = item.element.array().value;
			return {
				el: item.element,
				name: item.name,
				x1: points[0][0],
				y1: points[0][1],
				x2: points[1][0],
				y2: points[1][1]
			};
		});
		return findSectors(axes);
	}

	/**
	 * Очищает ресурсы, удаляет слой
	 */
	destroy() {
		super.destroy();
		this.cancelShapeAdding();
	}

	/**
	 * Добавляет необходимые атрибуты фигуре
	 * @param {Object} shape SVG.js фигура
	 * @return {Object}SVG.js фигура
	 * @private
	 */
	_enhanceShape(shape) {
		return shape.likeAxis();
	}
}

export default AxesHandler;
