import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {
	makeWarrantyClaimStatusEditRequest,
	IEditWarrantyClaimStatusResponse
} from '@src/api/backend/warrantyClaim';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';

export type IEditWarrantyClaimStatusPayload = IEditWarrantyClaimStatusResponse;

const request = () => ({
	type: types.EDIT_STATUS_REQUEST
});

const success = (response: IEditWarrantyClaimStatusResponse) => ({
	type: types.EDIT_STATUS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении статуса обращения');
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет статус гарантийного обращения
 */
export const editWarrantyClaimStatus = (
	objectId: string,
	claimId: string,
	statusId: WarrantyClaimStatusId
) =>
	createApiAction<IEditWarrantyClaimStatusResponse>(request, success, failure, () =>
		makeWarrantyClaimStatusEditRequest(objectId, claimId, statusId));
