import React, {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/structures/actions';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {extractStructuresPageSettings} from '@src/store/modules/settings/pages/structures/selectors';
import {IStructuresFiltersState} from '@src/store/modules/settings/pages/structures/reducers/byPage';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const StructuresFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId: string}>();
	const object = useAppSelector(s => extractCurrentTreeObject(s, objectId));
	const {filters} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		if (object && online) {
			await dispatch(getCheckLists());
		}
	}, [object, online]);

	const applyFilters = useCallback(
		(value: IStructuresFiltersState) => {
			if (objectId) {
				dispatch(changeFilters(objectId, value));
			}
		},
		[objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
			fullClear
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};
