import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import IPlanOrder from '@tehzor/tools/interfaces/plans/IPlanOrder';

export type IEditPlansOrderResponse = INormalizedData<IPlan>;

/**
 * Меняет порядок планов
 *
 * @param objectId id объекта
 * @param orders порядок
 *
 */
export const makePlansEditOrderRequest = (objectId: string, orders: IPlanOrder[]) =>
	wsConnector.sendAuthorizedRequest<IEditPlansOrderResponse>('editPlansOrder', {
		objectId,
		orders
	});
