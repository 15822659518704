import {IState} from '@src/store/modules';
import * as types from '../constants/problemsData';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {requestSpacesProblemsData} from '@src/api/backend/spaces/problemsData';
import {ISpacesStatsDataRequest} from '@src/interfaces/ISpacesStatsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

const request = (requestData: ISpacesStatsDataRequest) => ({
	type: types.GET_REQUEST,
	payload: requestData
});

const success = (response: Record<string, IEntityProblemsData>, objectId: string) => ({
	type: types.GET_SUCCESS,
	payload: {spacesProblemsData: response, objectId}
});

const failure = (error: IError, objectId: string) => {
	addErrorToast('Ошибка', 'при загрузке нарушений помещений');
	return {
		type: types.GET_FAILURE,
		payload: error,
		objectId
	};
};

/**
 * Получает статстику нарушений для помещений Объекта
 *
 * @param requestData данные запроса
 */
export const getSpacesProblemsData = (requestData: ISpacesStatsDataRequest) =>
	checkLoaded<IState, Record<string, IEntityProblemsData>, ApiAction>(
		s => s.pages.spaces.problemsData[requestData.objectId],

		createApiAction(
			() => request(requestData),
			result => success(result, requestData.objectId),
			error => failure(error, requestData.objectId),
			() => requestSpacesProblemsData(requestData)
		)
	);
