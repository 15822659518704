import {combineReducers} from 'redux';
import {cacheStatus} from './cacheStatus';
import {transferStatus} from './transferStatus';
import {networkStatus} from './networkStatus';
import {dictionariesCacheState} from './dictionariesCacheState';
import {entitiesCacheState} from './entitiesCacheState';
// import {saving} from './saving'

export const offlineMode = combineReducers({
	networkStatus,
	cacheStatus,
	transferStatus,
	dictionariesCacheState,
	entitiesCacheState
});