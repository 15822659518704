import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetCompaniesResponse} from '@src/api/backend/companies';
import {queryClient} from '@src/api/QueryClient';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';

export type IGetCompaniesPayload = IGetCompaniesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetCompaniesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка компаний');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список компаний
 */
export const getCompanies = () =>
	createApiAction<IGetCompaniesResponse>(request, success, failure, () =>
		queryClient.fetchQuery(restDictionariesQueryKeys.companies(), {staleTime: Infinity}));
