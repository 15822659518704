import {
	useEditableSpaceState,
	errorsFns,
	isEdited,
	hasAttachmentsError,
	convertToSave
} from '@src/core/hooks/states/useEditableSpaceState';
import {
	useUploadingFilesState,
	isEdited as isFilesExist,
	convertToSave as convertFilesToSave,
	someFilesHaveError
} from '@src/core/hooks/states/useUploadingFilesState';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingSpace} from '@tehzor/tools/interfaces/spaces/ISavingSpace';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import React, {useCallback, useEffect, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import EditableSpaceAttachments from '../EditableSpaceAttachments';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

export const useEditableSpaceAttachments = (
	objectId: string,
	space: ISpace,
	saving: boolean
): [React.ReactNode, () => Promise<ISavingSpace | undefined>, (
) => void, boolean] => {
	const [editingState, editingDispatch] = useEditableSpaceState(space);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, space) || isFilesExist(uploadingFilesState.value));
	}, [editingState, uploadingFilesState.value, space]);

	const fields = (
		<EditableSpaceAttachments
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving}
		/>
	);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();

		if (
			hasErrors(editingState, errorsFns, {})
			|| (hasAttachmentsError(editingState, {}) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}

		if (!isEdited(editingState, space) && (!isFilesExist(files) || someFilesHaveError(files))) {
			return undefined;
		}

		const savingData = convertToSave(editingState, space, true);
		savingData.newAttachments = convertFilesToSave(files);
		return savingData;
	}, [editingState, objectId, space]);

	const reset = useCallback(() => {
		editingDispatch({type: 'reset', entity: space});
		uploadingFilesDispatch({type: 'reset'});
	}, [space]);

	useUpdateEffect(reset, [space]);

	return [fields, getSavingData, reset, isBlocking];
};
