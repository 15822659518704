import {IPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import React, {memo} from 'react';
import {Menu} from './Menu';
import {Button} from '@tehzor/ui-components';
import {IPreparedContract} from '../../interfaces/IPreparedContract';

interface IDesktopActionsProps {
	contract: IPreparedContract;
	permissions: IPermissions;

	openEditDialog: () => void;
}

const editIcon = <i className="tz-edit-16"/>;

export const DesktopActions = memo(({
	contract, permissions, openEditDialog
}: IDesktopActionsProps) => (
	<div className="contract__actions">
		{permissions.canEdit && (
			<Button
				label="Редактировать"
				leftIcon={editIcon}
				type="accent-blue"
				onClick={openEditDialog}
			/>
		)}
		<Menu
			contract={contract}
			permissions={permissions}
		/>
	</div>
));