import {createRecursiveIndex} from '@tehzor/tools/utils/createRecursiveIndex';
import React, {useCallback, useMemo, Dispatch, SetStateAction} from 'react';
import {ItemName} from './ItemName';
import {ItemDesktopOptions} from './ItemDesktopOptions';
import {ItemMobileOptions} from './ItemMobileOptions';
import {useIsLargeTablet} from '../../utils/mediaQueries';
import {useDrag, useDrop} from 'react-dnd';

interface ISetItemProps<S> {
	index: string;
	number: number;
	item: S;
	edited: string[];
	current?: string;

	setCurrent: Dispatch<SetStateAction<string | undefined>>;

	onNameChange: (name: string, id: string) => void;
	onCreate: (parent?: S) => void;
	onRemove: (id: string) => void;
	onChangeEdit: (id: string) => void;
	onConfirm: (id: string) => void;
	onCancel: (id: string) => void;
	saveDrop?: () => void;
	moveRow?: (dragIndex: number, hoverIndex: number) => void;

	isChildren?: boolean;
	isEditable?: boolean;
}

export const SetItem = <S extends { id: string, name?: string, parentId?: string }>({
	index,
	number,
	item,
	edited,
	current,
	setCurrent,
	onNameChange,
	onCreate,
	onRemove,
	onChangeEdit,
	onConfirm,
	onCancel,
	saveDrop,
	moveRow,

	isChildren,
	isEditable
}: ISetItemProps<S>) => {
	const isEdited = useMemo(() => edited?.includes(item.id), [edited, item.id]);
	const isCurrent = useMemo(() => current === item.id, [current, item.id]);
	const isLargeTablet = useIsLargeTablet();

	const dropRef = React.useRef<HTMLTableRowElement>(null);
	const dragRef = React.useRef<HTMLTableCellElement>(null);

	const handleChangeFocus = useCallback((element: HTMLInputElement) => {
		if (!isEdited || !element) {
			return;
		}

		const length = item.name?.length || 0;

		element.focus();
		element.setSelectionRange(length, length);
	}, [isEdited]);

	const optionsProps = {item, onCreate, onRemove, onConfirm, onCancel, isEditable, isEdited, isCurrent};

	const DND_ITEM_TYPE = item.parentId || 'row';
	const [, drop] = useDrop({
		accept: DND_ITEM_TYPE,
		hover(dragItem: { number: number }, monitor) {
			if (!dropRef.current || !moveRow) return;

			const dragIndex = dragItem.number;
			const hoverIndex = number;

			if (dragIndex === hoverIndex) return;

			const hoverBoundingRect = dropRef.current.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

			moveRow(dragIndex, hoverIndex);
			dragItem.number = hoverIndex;
		},
		drop() {
			if (!saveDrop) return;
			saveDrop();
		}
	});

	const [{isDragging}, drag, preview] = useDrag({
		type: DND_ITEM_TYPE,
		item: {type: DND_ITEM_TYPE, number},
		collect: (monitor: { isDragging: () => boolean }) => ({
			isDragging: monitor.isDragging()
		})
	});

	preview(drop(dropRef));
	drag(dragRef);

	return (
		<div
			className={`editable-set__item ${isDragging ? 'transparent' : ''}`}
			ref={dropRef}
		>

			{(saveDrop && moveRow) && (
				<p
					className="editable-set__item-drag-icon"
					ref={dragRef}
				>
					<i className="tz-drag-and-drop-24"/>
				</p>
			)}
			<p className="editable-set__item-index">
				{createRecursiveIndex(index, number, isChildren)}
			</p>
			<ItemName
				item={item}
				onChange={onNameChange}
				onChangeEdit={onChangeEdit}
				onChangeFocus={handleChangeFocus}
				isEdited={isEdited}
				setCurrent={setCurrent}
			/>
			{isLargeTablet ? (
				<ItemDesktopOptions {...optionsProps}/>
			) : (
				<ItemMobileOptions {...optionsProps}/>
			)}
		</div>
	);
};