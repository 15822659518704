import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {useEditableWorkAcceptanceDialog} from '@src/components/EditableWorkAcceptanceDialog/hooks/useEditableWorkAcceptanceDialog';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';

interface IDesktopMenuProps {
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
}

export const DesktopMenu = ({workAcceptance, permissions}: IDesktopMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();

	// Диалог редактирования приёмки работ
	const [workAcceptanceDialog, openEditDialog] = useEditableWorkAcceptanceDialog(
		workAcceptance.objectId,
		workAcceptance.stage,
		workAcceptance
	);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить приёмку работ',
		`Вы действительно хотите удалить приёмку работ №${workAcceptance.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteWorkAcceptance(workAcceptance.objectId, workAcceptance.id));
			goBack();
		}
	}, [workAcceptance.objectId, workAcceptance.id]);

	const items = [];

	if (permissions?.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit"/>}
				onClick={openEditDialog}
			>
				Редактировать
			</MenuItem>
		);
	}

	if (permissions?.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions?.canEdit && workAcceptanceDialog}
			{permissions?.canDelete && deleteDialog}
		</>
	) : null;
};
