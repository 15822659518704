import {PrivateRoute} from '@src/containers/PrivateRoute';
import {SpaceTypeDecorationEditPage} from '@src/pages/manage/SpaceTypeDecorationEditPage/SpaceTypeDecorationEditPage';
import {SpaceTypeDecorationPage} from '@src/pages/manage/SpaceTypeDecorationPage/SpaceTypeDecorationPage';
import React from 'react';

export const manageSpaceTypeDecorationRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<SpaceTypeDecorationPage/>}/>
	},
	{
		path: ':typeDecorationSetId',
		element: <PrivateRoute element={<SpaceTypeDecorationEditPage/>}/>
	}
];
