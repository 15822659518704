import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useCheckItemIndex} from '@src/pages/CheckListPage/hooks/useCheckItemIndex';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckItemLink} from '@tehzor/tools/utils/links';
import {EntityInfoLink} from '@tehzor/ui-components';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {useEnrichedTask} from '@src/pages/TaskViewPage/hooks/useEnrichedTask';
import {CellProps} from 'react-table';
import {TaskInfoMobile} from '@src/pages/CheckListPage/components/detail/TaskInfoMobileCell/TaskInfoMobileCell';

export const CheckListTaskCellWrap = (props: CellProps<{data: ITask}>) => {
	const {pushPath} = useChangePath();
	const {itemId} = useStrictParams<{itemId: string}>();

	const {row} = props;
	const {data} = row.original;
	const {objectId, links} = data;
	const enrichedTask = useEnrichedTask(data);

	const item = useAppSelector(s => extractCheckItemById(s, links?.checkItemId));
	const currentIndex = useCheckItemIndex(item);

	const navigate = useCallback(() => {
		if (links?.checkItemId && links?.checkListId) {
			pushPath(
				formCheckItemLink({
					itemId: links?.checkItemId,
					objectId,
					listId: links?.checkListId,
					spaceId: links?.spaceId,
					stage: enrichedTask!.objectId.stage.toString()
				})
			);
		}
	}, [row.original]);

	return (
		<TaskInfoMobile
			task={data}
			depth={row.depth}
			subTitle={
				links?.checkItemId !== itemId ? (
					<EntityInfoLink
						label={`${currentIndex} ${item?.name}`}
						onClick={navigate}
					/>
				) : undefined
			}
			getToggleRowSelectedProps={row.getToggleRowSelectedProps}
		/>
	);
};
