import {createReducer} from '@reduxjs/toolkit';

export interface IDocumentsFiltersState extends Record<string, unknown> {
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
}

export type IDocumentsSortState = Record<string, boolean>;

export interface IDocumentsPageSettingsState {
	filters: IDocumentsFiltersState;
	sort: IDocumentsSortState;
	pageSize: number;
}

export type IDocumentsPagesSettingsState = IDocumentsPageSettingsState;

export const getInitialStateForPage = (): IDocumentsPageSettingsState => ({
	filters: {},
	sort: {
		modifiedAt: false
	},
	pageSize: 2000
});

export const documents = createReducer<IDocumentsPagesSettingsState>(
	{
		filters: {},
		sort: {
			modifiedAt: false
		},
		pageSize: 2000
	},
	{}
);
