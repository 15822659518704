import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import declination from '@tehzor/tools/utils/declination';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/reducers';
import {extractInternalAcceptancesData} from '@src/store/modules/entities/internalAcceptances/selectors';

interface IInternalAcceptancesPageHeaderProps {
	objectId?: string;
}

export const InternalAcceptancesPageCounter = ({
	objectId = 'all'
}: IInternalAcceptancesPageHeaderProps) => {
	const internalAcceptancesData = useAppSelector(s =>
		extractInternalAcceptancesData(s, objectId));
	const {state} = useEntitiesFiltersCtx<IChecksFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${isFiltersActive ? 'Найдено:' : 'Всего:'} ${
		internalAcceptancesData.total
	} ${declination(internalAcceptancesData.total || 0, [
		'внутренняя приёмка',
		'внутренних приёмки',
		'внутренних приёмок'
	])}`;
	return (
		<PageHeader
			className="internal-acceptances-page__header"
			title=""
			subTitle={text}
			wrapClassName="internal-acceptances-page__header-wrap"
			leftSideClassName="internal-acceptances-page__header-left"
			rightSideClassName="internal-acceptances-page__header-right"
		/>
	);
};
