import {extractSpaceStatusesSetById} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';

export const useSpaceStatusesSetBreadcrumbs = (spaceStatusesSetId?: string) => {
	const spaceStatusesSet = useAppSelector(s => (spaceStatusesSetId
		? extractSpaceStatusesSetById(s, spaceStatusesSetId) : undefined));
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);

	return useMemo(() => {
		const items: IBreadcrumb[] = [];

		items.push({
			label: 'Наборы статусов помещений',
			url: '/manage/space-statuses-sets'
		});

		if (spaceStatusesSet) {
			items.push({
				label: spaceStatusesSet.name,
				url: `/manage/space-statuses-sets/${spaceStatusesSetId}`
			});
		}

		return items;
	}, [objectsMap, spaceStatusesSet, spaceStatusesSetId]);
};