import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {editSpaceTypeDecorationSetRequest} from '@src/api/backend/spaceTypeDecorationSets/editSet';

export type IEditSpaceTypeDecorationSetPayload = ISpaceTypeDecorationSet;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: ISpaceTypeDecorationSet) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при обновлении набора типов отделки');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет набор типов отделки
 *
 * @param fields данные для добавления
 */
export const editSpaceTypeDecorationSet = (fields: ISpaceTypeDecorationSet) =>
	createApiAction<ISpaceTypeDecorationSet>(request, success, failure, () =>
		editSpaceTypeDecorationSetRequest(fields));
