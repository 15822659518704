import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import React, {memo} from 'react';
import {PriceCell} from '../cells/PriceCell';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {isBlockVisible} from '../../utils/isBlockVisible';
import classNames from 'classnames';

interface IPriceBlockProps {
	contract: IPreparedContract;
}

const visibilityFields: Array<keyof IPreparedContract> = [
	'priceText', 'priceNumber', 'fine'
];

const fineIcon = <i className="tz-percent-20"/>;

export const PriceBlock = memo(({
	contract
}: IPriceBlockProps) => isBlockVisible(contract, visibilityFields) ? (
	<div className="contract__blocks-price">
		<div className="contract__blocks-price__header">
			Стоимость
		</div>

		<EntityGrid
			className={{
				wrap: classNames(
					'contract__blocks-price__container', 'contract__cells'
				)
			}}
			withBorders
		>
			{(contract.priceNumber || contract.priceText) && (
					<PriceCell
						priceNumber={contract.priceNumber}
						priceText={contract.priceText}
					/>
				)}

			{contract.fine && (
				<EntityGridItem
					className={{
						root: 'contract__cells-item',
						header: 'contract__cells-item-header',
						content: 'contract__cells-item-content'
					}}
					label="Штраф"
					icon={fineIcon}
					inline
				>
					{contract.fine}
				</EntityGridItem>
			)}
		</EntityGrid>
	</div>
) : null);