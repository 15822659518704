import React from 'react';
import {CellProps} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';

export const OwnerCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {spaceOwner} = row.original;

	return (
		<div className="owner-acceptances-page__d-table-owner">
			<div className="owner-acceptances-page__d-table-owner-name">{spaceOwner?.name}</div>
			{spaceOwner?.email && (
				<div className="owner-acceptances-page__d-table-owner-email">
					{spaceOwner?.email}
				</div>
			)}
			{spaceOwner?.phone && (
				<div className="owner-acceptances-page__d-table-owner-phone">
					{spaceOwner?.phone}
				</div>
			)}
		</div>
	);
};