import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useSpaceStatusesSetBreadcrumbs} from '@src/core/hooks/breadcrumbs/useSpaceStatusesSetBreadcrumbs';
import {useSpaceStatusesSetPermissions} from '@src/core/hooks/permissions/useSpaceStatusesSetsPermissions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {getSpaceStatuses} from '@src/store/modules/dictionaries/spaceStatuses/actions';
import {getSpaceStatusesSets} from '@src/store/modules/dictionaries/spaceStatusesSets/actions';
import {extractSpaceStatusesSetsForPage} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {addErrorToast} from '@src/utils/toasts';
import {LoadingPanel} from '@tehzor/ui-components';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import React, {useEffect} from 'react';
import {useAsync} from 'react-use';
import {AddButton} from './components/actions/AddButton';
import {SpaceStatusesSetsFilters} from './components/SpaceStatusesSetsFilters';
import {SpaceStatusesSetsPageHeader} from './components/SpaceStatusesSetsPageHeader';
import {SpaceStatusesSetsTable} from './components/table/SpaceStatusesSetsTable';
import './SpaceStatusesSetsPage.less';

export const SpaceStatusesSetsPage = () => {
	useScrollRestoration();

	const {canView, canAdd} = useSpaceStatusesSetPermissions();

	useEffect(() => {
		if (!canView) {
			addErrorToast('Отсутствие доступа', 'к просмотру страницы');
		}
	}, [canView]);

	const dispatch = useAppDispatch();

	const breadcrumbs: IBreadcrumb[] = useSpaceStatusesSetBreadcrumbs();

	const setsData = useAppSelector(extractSpaceStatusesSetsForPage);

	const loadingState = useAsync(async () => {
		await dispatch(getSpaceStatusesSets());
		await dispatch(getSpaceStatuses());
	}, [dispatch]);

	if (!canView) {
		return null;
	}
	return (
		<LoadingPanel active={loadingState.loading}>
			<div className="space-statuses-sets">
				<AppBreadcrumbs className="space-statuses-sets__breadcrumbs" items={breadcrumbs}>
					<AddButton canAdd={canAdd} />
				</AppBreadcrumbs>
				<SpaceStatusesSetsFilters />
				<SpaceStatusesSetsPageHeader total={setsData.total} />
				<SpaceStatusesSetsTable {...setsData} />
			</div>
		</LoadingPanel>
	);
};
