import React from 'react';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';

interface ICheckInfoProps {
	data: ICheck;
}

const CheckInfo = ({data}: ICheckInfoProps) => (
	<EntityInfo
		title={`Проверка №${data.number ?? data.localNumber}`}
		subTitle={<EntityInfoDate value={data.createdAt}/>}
		icon={<i className="tz-check-28"/>}
		iconColor="#57A4FF"
		iconSize={44}
	/>
);

export default CheckInfo;
