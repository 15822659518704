import React from 'react';
import {
	ExportTemplateEditingPage,
	IExportTemplateEditingPageProps
} from './ExportTemplateEditingPage';

/**
 * Обёртка над страницей редактирования шаблона экспорта,
 * необходимо для того чтобы при переходе от создания к редактированию отрабатывали все методы жизненного цикла
 *
 * @param props свойства компонента
 * @constructor
 */
/**
 * ATTENTION!
 */
export const ExportTemplateEditingPageWrap = (props: IExportTemplateEditingPageProps) => (
	<ExportTemplateEditingPage
		key={props.exportTemplateId}
		{...props}
	/>
);
