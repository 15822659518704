import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';

export type IGetProblemTemplatesSetsResponse = INormalizedData<IProblemTemplatesSet>;

/**
 * Возвращает список наборов шаблонов нарушений
 */
export const requestProblemTemplatesSets = () =>
	wsConnector.sendAuthorizedRequest<IGetProblemTemplatesSetsResponse>('getProblemTemplatesSets');
