import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';

const title = 'Чек-листы';
export const useObjectCheckListsHeader = (objectId: string) => {
	const isDesktop = useIsDesktop();
	const object = useAppSelector(s => extractCurrentTreeObject(s, objectId));

	useAppHeader(
		{
			title: isDesktop ? title : object?.name,
			subTitle: isDesktop ? undefined : title,
			showBackBtn: true
		},
		[object]
	);
};
