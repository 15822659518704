import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../OfflineJournalPage';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {offlineJournalActionType} from '../enums/actionsTypes';
import {IChangedStatus} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export const useLocalSpaceStatusChangesJournal = () => {
	const {data} = useQuery<IChangedStatus[], unknown, IOfflineJournalEntity[]>({
		queryKey: spacesQueryKeys.status.localList(),
		select: records =>
			records.map(
				record =>
					({
						entity: {
							entityType: offlineJournalActionType.SPACE_STATUS_CHANGE,
							newStatus: record.journalData.newStatus
						},
						object: {
							objectId: record.objectId,
							objectName: record.journalData.objectName,
							locationName: record.journalData.locationName
						},
						transfer: {
							status: record.transferStatus,
							statusMessage: record.transferStatus
								? offlineModeTransferTitles[record.transferStatus]
								: ''
						},
						createdAt: Number(record.key)
					} as IOfflineJournalEntity)
			)
	});
	return data || [];
};
