import {wsConnector} from '../../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';

export type IGetCheckProblemsResponse = INormalizedData<IProblem>;

/**
 * Возвращает нарушения для конкретной проверки
 *
 * @param objectId id объекта
 * @param checkId id проверки
 */
export const requestCheckProblems = (objectId: string, checkId: string) =>
	wsConnector.sendAuthorizedRequest<IGetCheckProblemsResponse>('getCheckProblems', {
		objectId,
		checkId
	});
