import React, {useCallback, useMemo} from 'react';
import {
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	InlineButton,
	Plate,
	StatusSelect
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsyncFn} from 'react-use';
import Documents from './Documents';
import ExpandableMobileInfo from '@src/components/ExpandableMobileInfo';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {editOwnerAcceptanceStatus} from '@src/store/modules/entities/ownerAcceptance/actions';
import {useEditableOwnerAcceptanceAttachmentsDialog} from '@src/components/EditableOwnerAcceptanceAttachmentsDialog/hooks/useEditableOwnerAcceptanceAttachmentsDialog';
import SpaceOwners from './SpaceOwners';
import Resolution from './Resolution';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {extractOwnerAcceptanceResolutionsAsArray} from '@src/store/modules/dictionaries/ownerAcceptanceResolutions/selectors';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMobileInfoProps {
	objectId: string;
	ownerAcceptance: ILinkedOwnerAcceptance;
}

const MobileInfo = ({objectId, ownerAcceptance}: IMobileInfoProps) => {
	const {pushPath} = useChangePath();
	const spaceTitle = useSpaceTitle(ownerAcceptance?.space);
	const statuses = useAppSelector(s => s.dictionaries.ownerAcceptanceStatuses);
	const resolutions = useAppSelector(extractOwnerAcceptanceResolutionsAsArray);
	const permissions = useOwnerAcceptancesPermissions(objectId);
	const dispatch = useAppDispatch();

	const isStatusHidden = useMemo(
		() => ownerAcceptance.resolution === OwnerAcceptanceResolutionId.ACCEPTED,
		[ownerAcceptance.resolution]
	);

	const [editingAttachmentsDialog, openEditAttachmentsDialog]
		= useEditableOwnerAcceptanceAttachmentsDialog(objectId, ownerAcceptance.id, ownerAcceptance);

	// Диалог редактирования собственников

	const [, handleChange] = useAsyncFn(
		async (v: OwnerAcceptanceStatusId) => {
			if (ownerAcceptance) {
				await dispatch(editOwnerAcceptanceStatus(objectId, ownerAcceptance.id, v));
			}
		},
		[objectId, ownerAcceptance]
	);

	const handleClick = useCallback(() => {
		if (ownerAcceptance) {
			if (ownerAcceptance.objectId && ownerAcceptance.links?.spaceId) {
				pushPath(
					`/objects/${ownerAcceptance.objectId}/spaces/${ownerAcceptance.links.spaceId}`
				);
			}
		}
	}, [ownerAcceptance]);

	return (
		<Plate>
			<EntityGrid withBorders>
				{!isStatusHidden && (
					<EntityGridItem
						icon={<i className="tz-status-20"/>}
						label="Статус"
						inline
						fullRow
					>
						<StatusSelect
							className="owner-acceptance-page__d-entities-status"
							statuses={statuses}
							disabled={!permissions.canEditStatus}
							value={ownerAcceptance?.status}
							onChange={handleChange}
						/>
					</EntityGridItem>
				)}

				{ownerAcceptance?.resolution ? (
					<Resolution
						objectId={objectId}
						ownerAcceptanceId={ownerAcceptance.id}
						value={ownerAcceptance.resolution}
						resolutions={resolutions}
						canEditResolution={permissions.canEdit}
					/>
				) : null}
				{ownerAcceptance?.spaceOwner ? (
					<SpaceOwners
						name={ownerAcceptance.spaceOwner.name}
						phone={ownerAcceptance?.spaceOwner.phone}
						email={ownerAcceptance?.spaceOwner.email}
					/>
				) : null}

				<EntityGridItem
					label="Акт"
					icon={<i className="tz-document-20"/>}
					fullRow
					buttons={(
						<InlineButton
							className="owner-acceptance-page__documents-edit-icon"
							type="accent"
							leftIcon={<i className="tz-edit-16"/>}
							onClick={openEditAttachmentsDialog}
						/>
					)}
				>
					<Documents ownerAcceptance={ownerAcceptance}/>
				</EntityGridItem>
			</EntityGrid>

			<ExpandableMobileInfo noBorder>
				<EntityGrid withBorders>
					<EditorInfo
						icon={<i className="tz-calendar-20"/>}
						label="Создано"
						date={ownerAcceptance?.createdAt}
						user={ownerAcceptance?.createdBy}
						fullRow
					/>

					{ownerAcceptance?.createdAt !== ownerAcceptance?.modifiedAt && (
						<EditorInfo
							icon={<i className="tz-calendar-20"/>}
							label="Изменено"
							date={ownerAcceptance?.modifiedAt}
							user={ownerAcceptance?.modifiedBy}
							fullRow
						/>
					)}
					<EntityGridItem className={{content: 'owner-acceptance-page__space-btn'}}>
						<InlineButton
							label={spaceTitle}
							type="accent"
							onClick={handleClick}
							leftIcon={<i className="tz-space-24"/>}
						/>
					</EntityGridItem>
				</EntityGrid>
			</ExpandableMobileInfo>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default MobileInfo;
