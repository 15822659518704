import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {EntityGridItem, LinkButton} from '@tehzor/ui-components';
import React from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface ISpaceTasksDesktopProps {
	tasks: ITask[];
}

const linkIcon = <i className="tz-link-20" />;
const externalLinkIcon = <i className="tz-external-link-16" />;
export const SpaceTasksLinksMobile = (props: ISpaceTasksDesktopProps) => {
	const {tasks} = props;
	const {pushPath} = useChangePath();
	return (
		<EntityGridItem
			label="Ссылки"
			icon={linkIcon}
			fullRow
		>
			<div className="space-page__tasks">
				{tasks.map(task => (
					<LinkButton
						type="filled"
						className="space-page__tasks-link"
						leftIcon={externalLinkIcon}
						onClick={() => pushPath(`/tasks/${task.id}`)}
						label={task.name}
					/>
				))}
			</div>
		</EntityGridItem>
	);
};
