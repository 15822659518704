import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {extractCheck} from '@src/store/modules/entities/check/selectors';
import {formCheckLink, formSpaceLink} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';

/**
 * Хук для хлебных крошек от "всех объектов" до проверки
 *
 * @param objectId id конечного объекта
 */
export function useCheckBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	const check = useAppSelector(extractCheck);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId);
		if (check) {
			if (check.space) {
				items.push({
					label: formSpaceTitle(
						check.space.name,
						check.space.altName,
						spaceTypes.byId[check.space.type],
						altNamesVisible
					),
					url: formSpaceLink(objectId, check.space.id),
					inactive: false
				});
			} else {
				items.push({
					label: 'Проверки',
					url: `/objects/${objectId}/checks`,
					inactive: false
				});
			}
			items.push({
				label: `Проверка №${check.number}`,
				url: formCheckLink(objectId, check.id),
				inactive: false
			});
		}
		return items;
	}, [objectId, check, objects, spaceTypes]);
}
