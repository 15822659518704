const SVG = require('svg.js');
require('../../../vendor/svg.draw.js');

const stroke = {
	color: '#ff0042',
	width: 4,
	dasharray: '120, 8, 7, 8'
};

SVG.Axis = SVG.invent({
	create: 'line',
	inherit: SVG.Line,
	construct: {
		axis() {
			return this.put(new SVG.Axis()).stroke(stroke);
		}
	}
});

SVG.extend(SVG.Line, {
	likeAxis() {
		return this.stroke(stroke);
	}
});

SVG.Element.prototype.draw.extend('line', {
	init() {
		const p = this.startPoint;
		const arr = [
			[p.x, p.y],
			[p.x, p.y]
		];
		this.el.plot(arr);
	},
	calc(e) {
		const arr = this.el.array().valueOf();
		arr.pop();
		if (e) {
			const p = this.transformPoint(e.clientX, e.clientY);
			arr.push(this.snapToGrid([p.x, p.y]));
		}
		this.el.plot(arr);
	},
	point(e) {
		this.stop(e);
	}
});