import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {ISavingWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ISavingWarrantyClaim';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';

export const toWarrantyClaim = (
	user: IUser,
	objectId: string,
	links: IWarrantyClaim['links'] | undefined,
	fields: ISavingWarrantyClaim,
	key: string,
	nextNumber: number
): IWarrantyClaim => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};
	const newOwnerAcceptance: IWarrantyClaim = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId,
		publicId: '',
		links,
		attachments: fields.newAttachments
			? (fields.newAttachments?.map(att => ({
					id: att.key,
					full: att.file
						? {url: URL.createObjectURL(att.file), type: att.file?.type}
						: undefined,
					preview: att.file
						? {url: URL.createObjectURL(att.file), type: att.file?.type}
						: undefined
			  })) as IAttachment[])
			: undefined,
		status: WarrantyClaimStatusId.WAITING,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	return newOwnerAcceptance;
};

export const toListWarrantyClaim = (
	object: IObject,
	user: IUser,
	links: IWarrantyClaim['links'] | undefined,
	fields: ISavingWarrantyClaim,
	key: string,
	nextNumber: number
): IListWarrantyClaim => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};
	const newOwnerAcceptance: IListWarrantyClaim = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId: object.id,
		publicId: '',
		links,
		status: WarrantyClaimStatusId.WAITING,
		object: {
			id: object.id,
			name: object.name
		},
		attachments: fields.newAttachments
			? (fields.newAttachments?.map(att => ({
					id: att.key,
					full: att.file
						? {url: URL.createObjectURL(att.file), type: att.file?.type}
						: undefined,
					preview: att.file
						? {url: URL.createObjectURL(att.file), type: att.file?.type}
						: undefined
			  })) as IAttachment[])
			: undefined,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	return newOwnerAcceptance;
};
