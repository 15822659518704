import React from 'react';
import IconButton from '../buttons/IconButton';

const icon = <i className="tz-reason-external-link"/>;

interface IReasonSelectLink {
	externalLink?: string;
	num: number;
}

const ReasonSelectLink = (props: IReasonSelectLink) => {
	const {externalLink, num} = props;

	return externalLink ? (
		<a
			className="reason-select__standard-item-link"
			href={`${externalLink}${num}`}
			target="_blank"
			rel="noreferrer"
			title="Подробнее"
		>
			<IconButton className="reason-select__standard-item-link-btn">{icon}</IconButton>
		</a>
	) : null;
};

export default ReasonSelectLink;
