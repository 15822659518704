import React from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IPreparedSpaceStatusesSet} from '@src/pages/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';

export const CompanyCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) => {
	const isTablet = useIsTablet();

	const companiesMap = useAppSelector(s => s.dictionaries.companies.byId);
	const company = row.original?.companyId;

	return company ? (
		<div className="space-statuses-sets__cell-company">
			{!isTablet && (
				<div className="space-statuses-sets__cell-company-title">
					Компания:
				</div>
			)}

			<div className="space-statuses-sets__cell-company-name">
				{companiesMap[company]?.name}
			</div>
		</div>
	) : null;
};