import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEditableEntityAction} from '@src/core/states/editableEntityState';
import {extractAllCategoriesAsArray} from '@src/store/modules/dictionaries/categories/selectors';
import {extractCategoriesSetsAsArray} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import makeTreeData from '@src/store/modules/dictionaries/categoriesSets/utils/makeTreeData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {EditableFieldLabel, CategorySelect} from '@tehzor/ui-components';
import React, {CSSProperties, Dispatch, useCallback, useMemo} from 'react';

interface ICategoriesSetProps<S, E> {
	className?: string;
	style?: CSSProperties;
	label?: string;
	value?: string | null;
	objectId: string;
	stage: ObjectStageIds;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const Category = <S extends {categoryId?: string | null}, E>({
	className,
	style,
	label = 'Вид работ',
	value,
	objectId,
	stage,
	editingDispatch,
	required,
	disabled,
	hasError
}: ICategoriesSetProps<S, E>) => {
	const sets = useAppSelector(s => extractCategoriesSetsAsArray(s, objectId, stage));
	const categories = useAppSelector(extractAllCategoriesAsArray);
	const treeData = useMemo(() => makeTreeData(sets, categories, true), [sets, categories]);

	const category = useMemo(() => (value ? [value] : undefined), [value]);

	const handleChange = useCallback(
		(v: string[]) => {
			editingDispatch({type: 'update', field: 'categoryId', value: v[0]});

			if (required) {
				editingDispatch({type: 'update-error', field: 'categoryId'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<CategorySelect
				dialogTitle="Вид работ"
				treeData={treeData}
				value={category}
				error={required && hasError ? 'Выберите вид работ' : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};
