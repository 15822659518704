import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectOption, Select2, FilterButton, SelectPopup, SelectSearch} from '@tehzor/ui-components';
import {extractInspectors} from '@src/store/modules/dictionaries/companies/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface ICreatedByFilterProps {
	objectId: string;
	label?: string;
	createdBy?: string[];
}

export const CreatedByFilter = memo((props: ICreatedByFilterProps) => {
	const {label = 'Автор', objectId, createdBy} = props;

	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedInspectors, setSelectedInspectors] = useState(createdBy);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const inspectors = useAppSelector(s => extractInspectors(s, objectId));
	const filteredData = useMemo(() => flatFilter(inspectors, 'fullName', search), [inspectors, search]);

	const handleApply = useCallback(() => {
		dispatch({createdBy: selectedInspectors});
		clearSearch();
	}, [selectedInspectors, dispatch, clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedInspectors([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedInspectors([]);
		dispatch({createdBy: undefined});
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedInspectors(createdBy);
		clearSearch();
	}, [createdBy, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedInspectors(inspectors.map(val => val.id));
	}, [inspectors, clearSearch]);

	useUpdateEffect(() => {
		setSelectedInspectors(createdBy);
	}, [createdBy]);

	return (
		<div>
			<SelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				onSelectAll={handleSelectAll}
				clearButton={!!selectedInspectors?.length}
				count={selectedInspectors?.length}
				footer
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel(label, createdBy, inspectors)}
						active={!!createdBy?.length}
						onClear={handleFullClear}
					/>
				)}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
						type="popup"
					/>
				)}
			>
				<Select2
					multiple
					value={selectedInspectors}
					onChange={setSelectedInspectors}
				>
					{filteredData.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.fullName}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
});
