import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';

interface IPlansSelectProps<S, E> {
	field?: keyof S;
	value?: string[];
	data: IPlan[];
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const PlansSelect = <
	S extends {
		plans?: string[];
	},
	E
>({
	className,
	style,
	field = 'plans',
	label = 'Планы',
	data,
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: IPlansSelectProps<S, E>) => {
	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});

			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={value?.length ? makeFilterLabel('Выбрано', value, data) : undefined}
						icon={<i className="tz-simple-arrow-20"/>}
						error={required && hasError ? 'Выберите план' : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					multiple
					value={value}
					onChange={handleChange}
				>
					{data.map(plan => (
						<SelectOption
							key={plan.id}
							itemKey={plan.id}
							content={plan.name}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
