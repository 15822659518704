import useAppSelector from '@src/core/hooks/useAppSelector';
import {IPreparedContract} from '@src/pages/ContractsPage/interfaces/IPreparedContract';
import React from 'react';
import {CellProps} from 'react-table';

interface IObjectsCellProps extends CellProps<IPreparedContract> {
	isMobile?: boolean;
}

export const ObjectsCell = ({row, isMobile}: IObjectsCellProps) => {
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);
	const numberOfShownObjects = isMobile ? 1 : 3;

	const objects = row.original.objectIds || [];
	const shownObjects = objects.filter((objectId, i) =>
		(i + 1) <= numberOfShownObjects);
	const numberOfRemainingObjects = objects.length > numberOfShownObjects
		? objects.length - numberOfShownObjects
		: undefined;

	return (
		<div className="contracts__cell-objects">
			{shownObjects.map(objectId => (
				<div
					className="contracts__cell-objects-text"
					key={objectId}
				>
					{objectsMap[objectId]?.name}
				</div>
			))}
			{numberOfRemainingObjects && (
				<div className="contracts__cell-objects-number">
					+
					{numberOfRemainingObjects}
				</div>
			)}
		</div>
	);
};