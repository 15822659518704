import {useMemo} from 'react';
import {IScheduleData} from '../../../../utils/convertTasksToScheduleFormat';
import {MonthCell} from './MonthCell';
import {ObjectCell} from './ObjectCell';
import {TasksUnAssignedCell} from './TasksUnAssignedCell';
import {ColumnDef} from '@tanstack/react-table';
import {MonthHeader} from './MonthHeader';
import {ITasksSettingsState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {DayHeader} from './DayHeader';
import {DayCell} from './DayCell';
import {createHeaderStr} from '@src/pages/TasksPage/utils/timeDayUtils';
import {monthsName} from '@src/pages/TasksPage/utils/timeMonthUtils';
import {WorkingGroupCell} from './WorkingGroupCell';

export const useInfoColumns = () => useMemo<Array<ColumnDef<IScheduleData>>>(() => [
	{
		id: 'name',
		header: 'Наименование объекта',
		accessorFn: (item: IScheduleData) => item,
		cell: ObjectCell
	},
	{
		id: 'tasks',
		header: 'Задачи в ожидании',
		accessorFn: (item: IScheduleData) => item,
		cell: TasksUnAssignedCell
	}
], []);

export const useInfoColumnsForGroupMode = () => useMemo<Array<ColumnDef<IScheduleData>>>(() => [
	{
		id: 'name',
		header: 'Ответственные',
		accessorFn: (item: IScheduleData) => item,
		cell: WorkingGroupCell
	},
	{
		id: 'tasks',
		header: 'Задачи в ожидании',
		accessorFn: (item: IScheduleData) => item,
		cell: TasksUnAssignedCell
	}
], []);

export const useBodyColumns = (settings: ITasksSettingsState) => useMemo<Array<ColumnDef<IScheduleData>>>(() => {
	const yearColumns: Array<ColumnDef<IScheduleData>> = [];

	if (settings.currentMode === 'year') {
		for (const monthName of monthsName) {
			yearColumns.push({
				id: monthName,
				header: MonthHeader,
				accessorFn: (item: IScheduleData) => item,
				cell: MonthCell
			});
		}

		return yearColumns;
	}

	if (settings.currentMode === 'month') {
		const maxDaysInMonth = new Date(settings.year, settings.month + 1, 0).getDate();
		const monthColumns: Array<ColumnDef<IScheduleData>> = [];

		for (let i = 1; i <= maxDaysInMonth; i++) {
			monthColumns.push({
				id: createHeaderStr(settings.year, settings.month, i),
				header: DayHeader,
				accessorFn: (item: IScheduleData) => item,
				cell: DayCell
			});
		}

		return monthColumns;
	}

	return yearColumns;
}, [settings]);