import React, {memo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectPopup, FilterButton, SelectOption, Select2} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';

interface SelectOptionItem {
	id: string;
	name: string;
}

interface IStagesFilterProps<T extends SelectOptionItem> {
	label?: string;
	stages?: string[];
	allStages: T[];
	filterName?: string;
}

export const StagesFilter = memo(<T extends SelectOptionItem>(props: IStagesFilterProps<T>) => {
	const {label = 'Стадия', filterName = 'stages', stages, allStages} = props;
	const [selectedStages, setSelectedStages] = useState(stages);
	const {dispatch} = useEntitiesFiltersCtx();

	const handleApply = () => {
		dispatch({[filterName]: selectedStages});
	};

	const handleChange = (v?: string[]) => {
		if (v && v.length === 0) {
			setSelectedStages(undefined);
		} else {
			setSelectedStages(v);
		}
	};
	const handleClear = () => {
		setSelectedStages(undefined);
	};

	const handleFullClear = () => {
		setSelectedStages(undefined);
		dispatch({[filterName]: undefined});
	};

	const handleCancel = () => {
		setSelectedStages(stages);
	};

	useUpdateEffect(() => {
		setSelectedStages(stages);
	}, [stages]);

	return (
		<SelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedStages?.length}
			noHeader
			footer
			count={selectedStages?.length}
			trigger={(
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel<T>(label, stages, allStages)}
					active={!!(stages && stages.length != 0)}
					onClear={handleFullClear}
				/>
			)}
		>
			<Select2
				multiple
				value={selectedStages}
				onChange={handleChange}
			>
				{allStages.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</SelectPopup>
	);
});
