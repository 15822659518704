import React from 'react';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {CellProps} from 'react-table';
import {useEditingTaskDialog} from '@src/components/EditingTaskDialog';
import {LinkButton} from '@tehzor/ui-components';
import {IEnrichedTaskForScheduler} from '@src/pages/TasksPage/hooks/useEnrichedTasksForSchedule';

export const EditButton = ({row}: Pick<CellProps<IEnrichedTaskForScheduler>, 'row'>) => {
	const task = row.original;
	const permissions = useTasksPermissions();
	const [taskEditDialog, handleOpenEditTaskDialog] = useEditingTaskDialog({taskId: task.id});

	return (
		<>
			{permissions.canEdit && (
				<>
					<LinkButton
						onClick={handleOpenEditTaskDialog}
						leftIcon={<i className="tz-edit"/>}
					/>
					{taskEditDialog}
				</>
			)}
		</>
	);
};
