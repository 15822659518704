import {wsConnector} from '../wsConnector';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';

export type IAddProblemTagResponse = IProblemTag;

/**
 * Добавляет метку
 *
 * @param problemTagsSetId id набора 
 * @param tag данные для добавления
 */
export const makeProblemTagAddRequest = (
	problemTagsSetId: string,
	tag: ISavingProblemTag
) =>
	wsConnector.sendAuthorizedRequest<IAddProblemTagResponse>('addProblemTag', {
		problemTagsSetId,
		...tag
	});
