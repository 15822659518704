import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

/**
 * Отправляет запрос на экспорт проверок
 */
export const makeInternalAcceptancesExportRequest = async (
	templateId: string,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[],
	createDocument?: boolean
): Promise<IExportResponse> => {
	const params = {
		templateId,
		objectId,
		filters: {
			...filters,
			selected
		},
		sort: convertSortParam(sort),
		timezone: getTimezone(),
		createDocument
	};

	const response = await httpRequests.withToken.get<IExportResponse>('internal-acceptances/export', {
		params
	});
	return response.data
};
