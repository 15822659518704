import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import declination from '@tehzor/tools/utils/declination';
import {IStructuresFiltersState} from '@src/store/modules/settings/pages/structures/reducers/byPage';

interface IStructuresPageHeaderProps {
	objectId: string;
	count: number;
}

export const StructuresPageCounter = ({objectId, count}: IStructuresPageHeaderProps) => {
	const {state} = useEntitiesFiltersCtx<IStructuresFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${isFiltersActive ? 'Найдено:' : 'Всего:'} ${
		count
	} ${declination(count || 0, ['структура', 'структуры', 'структур'])}`;
	return (
		<PageHeader
			className="structures-page__counter"
			title=""
			subTitle={text}
		/>
	);
};
