import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IStructureType} from '@tehzor/tools/interfaces/structures/IStructureType';

export type IGetStructureTypesResponse = INormalizedData<IStructureType>;

/**
 * Возвращает список типов структур
 */
export const requestStructureTypes = () =>
	wsConnector.sendAuthorizedRequest<IGetStructureTypesResponse>('getStructureTypes');
