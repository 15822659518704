import React from 'react';
import './WarrantyClaimPage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {Desktop} from './components/Desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {extractWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/selectors';
import {getWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions/get';
import {getWarrantyClaimProblems} from '@src/store/modules/entities/warrantyClaim/actions/problems';
import {Mobile} from './components/Mobile';
import {getWarrantyClaimProblemReplies} from '@src/store/modules/entities/warrantyClaim/actions';

export const WarrantyClaimPage = () => {
	useScrollRestoration();
	const {objectId, warrantyClaimId} = useStrictParams<{
		objectId: string;
		warrantyClaimId: string;
	}>();
	const warrantyClaim = useAppSelector(extractWarrantyClaim);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await Promise.all<unknown>([
			dispatch(getWarrantyClaim(objectId, warrantyClaimId)),
			dispatch(getWarrantyClaimProblems(objectId, warrantyClaimId)),
			dispatch(getWarrantyClaimProblemReplies(objectId, warrantyClaimId))
		]);
	});

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				warrantyClaimId={warrantyClaimId}
				warrantyClaim={warrantyClaim}
			/>
		);
	}
	return (
		<Mobile
			objectId={objectId}
			warrantyClaimId={warrantyClaimId}
			warrantyClaim={warrantyClaim}
		/>
	);
};
