import {ExternalObjectRoot} from '@src/containers/ExternalObjectRoot';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {CategoriesSetsPage} from '@src/pages/CategoriesSetsPage';
import {ObjectsPage, SubObjectsPage} from '@src/pages/ObjectsPage';
import React from 'react';
import {categoriesSetsFilterRoutes} from './categoriesSetsFilterRoutes';
import CategoriesSetsFiltersRoutingPage from '@src/pages/filters/CategoriesSetsFiltersRoutingPage/CategoriesSetsFiltersRoutingPage';
import {objectProblemsRoutes} from './objectProblemsRoutes';
import {objectInspectionsRoutes} from './objectInspectionsRoutes';
import {objectsFilterRoutes} from './objectsFilterRoutes';
import {objectPlansRoutes} from './objectPlansRoutes';
import CategoriesSetPage from '@src/pages/CategoriesSetPage/CategoriesSetPage';
import {objectChecksRoutes} from './objectChecksRoutes';
import {ObjectPage} from '@src/pages/ObjectPage';
import {objectSpacesRoutes} from './objectSpacesRoutes';
import {OwnerAcceptancePageWrap} from '@src/pages/OwnerAcceptancePage';
import {OwnerAcceptancesFiltersRoutingPage} from '@src/pages/filters/OwnerAcceptancesFiltersRoutingPage/OwnerAcceptancesFiltersRoutingPage';
import {ownerAcceptancesFilterRoutes} from './ownerAcceptancesFilterRoutes';
import {WarrantyClaimsPage} from '@src/pages/WarrantyClaimsPage';
import {WarrantyClaimPageWrap} from '@src/pages/WarrantyClaimPage';
import WarrantyClaimsFiltersRoutingPage from '@src/pages/filters/WarrantyClaimsFiltersRoutingPage';
import {warrantyClaimsFilterRoutes} from './warrantyClaimsFilterRoutes';
import {InternalAcceptancesFiltersRoutingPage} from '@src/pages/filters/InternalAcceptancesFiltersRoutingPage/InternalAcceptancesFiltersRoutingPage';
import {internalAcceptancesFilterRoutes} from './internalAcceptancesFilterRoutes';
import {InternalAcceptancesPage} from '@src/pages/InternalAcceptancesPage';
import InternalAcceptancePage from '@src/pages/InternalAcceptancePage';
import {WorkAcceptancesPage} from '@src/pages/WorkAcceptancesPage';
import {WorkAcceptancePage} from '@src/pages/WorkAcceptancePage';
import {WorkAcceptancesFiltersRoutingPage} from '@src/pages/filters/WorkAcceptancesFiltersRoutingPage';
import {workAcceptancesFilterRoutes} from './workAcceptancesFilterRoutes';
import {ObjectCheckListPageWrap} from '@src/pages/CheckListPage';
import {StructuresPage} from '@src/pages/StructuresPage';
import {StructurePageWrap as StructurePage} from '@src/pages/StructurePage';
import {StructuresFiltersRoutingPage} from '@src/pages/filters/StructuresFiltersRoutingPage';
import {structuresFilterRoutes} from './structuresFilterRoutes';
import {OwnerAcceptancesPageWrap} from '@src/pages/OwnerAcceptancesPage/OwnerAcceptancesPageWrap';
import {contractsRoutes} from './contractsRoutes';
import {RouteObject} from 'react-router-dom';

export const objectsRoutes: RouteObject[] = [
	{
		index: true,
		element: <PrivateRoute element={<ObjectsPage />} />
	},
	{
		path: 'filters',
		children: objectsFilterRoutes
	},
	{
		path: ':objectId',
		element: <PrivateRoute element={<SubObjectsPage />} />
	},
	{
		path: ':objectId/view',
		element: <PrivateRoute element={<ObjectPage />} />
	},
	{
		path: ':objectId/problems',
		children: objectProblemsRoutes
	},
	{
		path: ':objectId/inspections',
		children: objectInspectionsRoutes
	},
	{
		path: ':objectId/checks',
		children: objectChecksRoutes
	},
	{
		path: ':objectId/plans',
		children: objectPlansRoutes
	},
	{
		path: ':objectId/spaces',
		children: objectSpacesRoutes
	},
	{
		path: ':objectId/contracts',
		children: contractsRoutes
	},
	// И тут я сдался
	{
		path: ':objectId/owner-acceptances',
		element: <PrivateRoute element={<OwnerAcceptancesPageWrap />} />
	},
	{
		path: ':objectId/owner-acceptances/:ownerAcceptanceId',
		element: <PrivateRoute element={<OwnerAcceptancePageWrap />} />
	},
	{
		path: ':objectId/owner-acceptances/filters',
		element: <PrivateRoute element={<OwnerAcceptancesFiltersRoutingPage />} />,
		children: ownerAcceptancesFilterRoutes
	},
	{
		path: ':objectId/warranty-claims',
		element: <PrivateRoute element={<WarrantyClaimsPage />} />
	},
	{
		path: ':objectId/warranty-claims/:warrantyClaimId',
		element: <PrivateRoute element={<WarrantyClaimPageWrap />} />
	},
	{
		path: ':objectId/warranty-claims/filters',
		element: <PrivateRoute element={<WarrantyClaimsFiltersRoutingPage />} />,
		children: warrantyClaimsFilterRoutes
	},
	{
		path: ':objectId/internal-acceptances',
		element: <PrivateRoute element={<InternalAcceptancesPage />} />
	},
	{
		path: ':objectId/internal-acceptances/:internalAcceptanceId',
		element: <PrivateRoute element={<InternalAcceptancePage />} />
	},
	{
		path: ':objectId/internal-acceptances/filters',
		element: <PrivateRoute element={<InternalAcceptancesFiltersRoutingPage />} />,
		children: internalAcceptancesFilterRoutes
	},
	{
		path: ':objectId/work-acceptances',
		element: <PrivateRoute element={<WorkAcceptancesPage />} />
	},
	{
		path: ':objectId/work-acceptances/:workAcceptanceId/*',
		element: <PrivateRoute element={<WorkAcceptancePage />} />
	},
	{
		path: ':objectId/work-acceptances/filters',
		element: <PrivateRoute element={<WorkAcceptancesFiltersRoutingPage />} />,
		children: workAcceptancesFilterRoutes
	},
	{
		path: ':objectId/structures',
		element: <PrivateRoute element={<StructuresPage />} />
	},
	{
		path: ':objectId/structures/:structureId/*',
		element: <PrivateRoute element={<StructurePage />} />
	},
	{
		path: ':objectId/structures/filters',
		element: <PrivateRoute element={<StructuresFiltersRoutingPage />} />,
		children: structuresFilterRoutes
	},
	{
		path: ':objectId/check-lists/:stage',
		element: <PrivateRoute element={<ObjectCheckListPageWrap />} />
	},
	{
		path: 'external/:externalId',
		element: <PrivateRoute element={<ExternalObjectRoot />} />
	},
	{
		path: ':objectId/categories-sets',
		element: <PrivateRoute element={<CategoriesSetsPage />} />
	},
	{
		path: ':objectId/categories-sets/:categoriesSetId',
		element: <CategoriesSetPage />
	},
	{
		path: ':objectId/categories-sets/filters',
		element: <PrivateRoute element={<CategoriesSetsFiltersRoutingPage />} />,
		children: categoriesSetsFilterRoutes
	}
];
