import React, {memo} from 'react';
import {useDecadesGrid} from '../hooks/useDecadesGrid';
import {makeElement} from '../../../../utils/makeElement';
import Decade, {IDecadeProps} from './Decade';

interface IDecadesGridProps {
	decade?: number;
	century: number;
	component?: React.ReactElement<IDecadeProps> | ((props: IDecadeProps) => JSX.Element);

	changeDecade(value: number): void;
}

const DecadesGrid = (props: IDecadesGridProps) => {
	const {decade, century, component = Decade, changeDecade} = props;

	const decades = useDecadesGrid(century);

	return (
		<div
			key={century}
			className="calendar__decades-grid"
		>
			{decades.map((item, i) => {
				const decadeProps = {
					...item,
					key: i,
					active: item.value === decade,
					onClick: changeDecade
				};
				return makeElement(component, decadeProps);
			})}
		</div>
	);
};

export default memo(DecadesGrid);
