import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useMovingWarrantyClaimDialog} from '@src/components/MovingWarrantyClaimDialog/hooks/useMovingWarrantyClaimDialog';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {deleteWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useEditWarrantyClaimDialog} from '../../hooks/useEditWarrantyClaimDialog';

interface IDesktopMenuProps {
	warrantyClaim: IWarrantyClaim;
}

const DesktopMenu = ({warrantyClaim}: IDesktopMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const permissions = useWarrantyClaimsPermissions(warrantyClaim.objectId);

	// Диалог переноса обращения
	const [moveDialog, openMoveDialog] = useMovingWarrantyClaimDialog(
		warrantyClaim.objectId,
		warrantyClaim
	);

	const [warrantyClaimDialog, openWarrantyClaimDialog] = useEditWarrantyClaimDialog(warrantyClaim);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить обращение',
		`Вы действительно хотите удалить обращение №${warrantyClaim.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteWarrantyClaim(warrantyClaim.objectId, warrantyClaim.id));
			goBack();
		}
	}, [warrantyClaim.objectId, warrantyClaim.id]);

	const items = [];
	if (permissions.canMove) {
		items.push(
			<MenuItem
				key="move"
				icon={<i className="tz-long-arrow-24"/>}
				onClick={openMoveDialog}
			>
				Перенести обращение
			</MenuItem>
		);
	}

	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit-20"/>}
				onClick={openWarrantyClaimDialog}
			>
				Редактировать
			</MenuItem>
		);
	}

	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
			{permissions.canMove && moveDialog}
			{permissions.canEdit && warrantyClaimDialog}
		</>
	) : null;
};

export default DesktopMenu;
