import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ITaskType} from '@tehzor/tools/interfaces/tasks/ITaskType';

export type IGetTaskTypesResponse = INormalizedData<ITaskType>;

/**
 * Возвращает список типов задач
 */
export const requestTaskTypes = () =>
	wsConnector.sendAuthorizedRequest<IGetTaskTypesResponse>('getTaskTypes');
