import React, {useMemo} from 'react';
import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';

const StagesCell = ({row}: CellProps<IEnrichedProblemTagsSet>) => {
	const stages = row.original.stages || [];
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages).byId;
	const data = useMemo(
		() =>
			stages.map(id => {
				const label = stagesMap[id]?.name || '';

				return {id, label};
			}),
		[stagesMap, stages]
	);
	const text = data.map(i => i.label).join(', ');
	return (
		<div>
			{text}
		</div>
	);
};

export default StagesCell;