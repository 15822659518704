import {makeWorkAcceptanceExportRequest} from '@src/api/backend/workAcceptance/export';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addExportToast} from '@src/components/ExportToast/addExportToast';

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse, sending: boolean) => {
	if (response.link) {
		addExportToast(
			sending ? 'Документ отправлен' : 'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document,
			undefined,
			undefined
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при экспорте');
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует приемку работ
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param objectId id объекта
 * @param workAcceptanceId id приемки работ
 * @param checkLists массив чек-листов
 * @param problems выбранные нарушения
 */
export const exportWorkAcceptance = (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	workAcceptanceId: string,
	checkLists?: string[],
	problems?: string[]
) =>
	createApiAction<IExportResponse>(request, success, failure, () =>
		makeWorkAcceptanceExportRequest(
			templateId,
			createDocument,
			email,
			objectId,
			workAcceptanceId,
			checkLists,
			problems
		)
	);
