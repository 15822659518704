import React, {memo} from 'react';
import DesktopCommentsHeader from './CommentsHeader.desktop';
import ProblemComments from '@src/components/ProblemComments';
import {Tree} from 'array-to-tree';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';

interface IDesktopCommentsProps {
	objectId: string;
	problem: IPreparedProblem;
	links?: IProblemComment['links'];
	comments: Array<Tree<IProblemComment>>;

	onImageClick?: (id: string, index: number) => void;
}

const DesktopComments = ({
	objectId,
	problem,
	links,
	comments,
	onImageClick
}: IDesktopCommentsProps) => (
	<div className="problem-page__d-comments">
		<DesktopCommentsHeader
			objectId={objectId}
			problem={problem}
		/>

		<ProblemComments
			className={{
				root: 'problem-page__d-comments-body',
				content: 'problem-page__d-comments-content',
				item: 'problem-page__d-comment',
				footer: 'problem-page__d-comments-footer',
				replyBtn: 'problem-page__d-comments-reply-btn',
				reference: 'problem-page__d-comments-reference',
				input: 'problem-page__d-comments-input'
			}}
			objectId={objectId}
			problemId={problem.id}
			links={links}
			comments={comments}
			onImageClick={onImageClick}
		/>
	</div>
);

export default memo(DesktopComments);
