import {queryClient} from '@src/api/QueryClient';
import {wsConnector} from '../wsConnector';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IGetSpaceResponse = ISpace;

/**
 * Получает помещение по id
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 */
export const requestSpace = (objectId: string, spaceId: string) => {
	void queryClient.invalidateQueries(spacesQueryKeys.list());
	void queryClient.invalidateQueries([...spacesQueryKeys.detail(spaceId), objectId]);

	return wsConnector.sendAuthorizedRequest<IGetSpaceResponse>('getSpace', {
		objectId,
		spaceId
	});
};
