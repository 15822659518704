import {wsConnector} from '../wsConnector';

/**
 * Удаляет ответ на нарушение
 *
 * @param objectId id объекта
 * @param commentId id комментария
 */
export const makeProblemReplyDeleteRequest = (objectId: string, commentId: string) =>
	wsConnector.sendAuthorizedRequest<void>('deleteProblemReply', {
		objectId,
		commentId
	});
