import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Plate} from '@tehzor/ui-components';
import './EditingWorkingGroup.less';
import {extractWorkingGroupById} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {useEditableWorkingGroup} from '../EditableWorkingGroup/hooks/useEditableWorkingGroup';

interface IEditingWorkingGroupProps {
	workingGroupId?: string;
}

export const EditingWorkingGroup = memo(({workingGroupId}: IEditingWorkingGroupProps) => {
	const workingGroup = useAppSelector(s => extractWorkingGroupById(s, workingGroupId));

	const [workingGroupFields, workingGroupPerformersFields, workingGroupSubGroupsFields] =
		useEditableWorkingGroup({
			workingGroup
		});

	return (
		<div className="editing-working-group__container">
			<Plate className="editing-working-group__info-grid">{workingGroupFields}</Plate>
			<Plate className="editing-working-group__items-grid">
				{workingGroupPerformersFields}
				{workingGroupSubGroupsFields}
			</Plate>
		</div>
	);
});
