import {queryClient} from '@src/api/QueryClient';
import {deleteMutation} from '@src/cache/utils/deleteMutation';
import {deleteLinkedLocalProblems} from '../problem/deleteLinkedLocalProblems';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {deleteOwnerAcceptanceActions} from '@src/store/modules/entities/ownerAcceptance/actions';
import {store} from '@src/store/appStore';

export const deleteLocalOwnerAcceptance = async (acpId: string, objectId?: string) => {
	queryClient.removeQueries(ownerAcceptancesQueryKeys.detail(acpId));
	await queryClient.refetchQueries({
		queryKey: ownerAcceptancesQueryKeys.localList()
	});
	if (objectId) {
		store.dispatch(deleteOwnerAcceptanceActions.success(objectId, acpId, true));
	}
	await deleteLinkedLocalProblems('ownerAcceptanceId', acpId);
	deleteMutation(acpId);
};
