import {wsConnector} from '../wsConnector';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';

export type IAddSpaceTypeDecorationResponse = ISpaceTypeDecoration[];

/**
 * Создает массово типы отделки помещения в наборе
 *
 * @param typeDecorationSetId id набора типов отделки помещения
 * @param items массив типов отделки для добавления
 */
export const makeSpaceTypeDecorationAddRequest = (
	typeDecorationSetId: string,
	items: ISavingSpaceTypeDecoration[]
) =>
	wsConnector.sendAuthorizedRequest<IAddSpaceTypeDecorationResponse>('addSpaceTypeDecoration', {
		typeDecorationSetId,
		items
	});
