import React, {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton, Select2, SelectOption, SelectSearch} from '@tehzor/ui-components';
import {extractInspectors} from '@src/store/modules/dictionaries/companies/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const CreatedByFilterPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{createdBy?: string[]}>();
	const [search, setSearch] = useState('');
	const [selected, setSelected] = useState(state.createdBy);

	const inspectors = useAppSelector(s => extractInspectors(s, objectId));
	const filteredData = useMemo(
		() => flatFilter(inspectors, 'fullName', search),
		[inspectors, search]
	);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({createdBy: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: 'Автор',
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label="Сбросить"
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={isLocalStateEqual(state.createdBy, selected)}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
			onApplyClick={handleApply}
		>
			<Select2
				multiple
				value={selected}
				onChange={setSelected}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.fullName}
					/>
				))}
			</Select2>
		</FilterPage>
	);
};

export default CreatedByFilterPage;
