import {Column} from 'react-table';
import CellWrap from '@src/components/tableCells/CellWrap';
import ProblemStatusCell from '@src/components/tableCells/ProblemStatusCell';
import ProblemFixCell from '@src/components/tableCells/ProblemFixCell';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {extractWorkAcceptanceProblemCommentsByProblem} from '@src/store/modules/entities/workAcceptance/selectors';

export const desktopColumns: Array<Column<IWorkAcceptanceEntity>> = [
	{
		id: 'number',
		Header: HeaderCellWithRowSelectWrap('№, нарушение'),
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(
				ProblemInfoDesktopCell,
				extractWorkAcceptanceProblemCommentsByProblem
			)
		}),
		width: 200,
		minWidth: 200
	},
	{
		id: 'status',
		Header: 'Статус',
		Cell: CellWrap({
			problem: ProblemStatusCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'category',
		Header: 'Вид работ',
		accessor: row => (row.type === 'problem' ? row.data.categoryId : undefined),
		width: 80,
		minWidth: 80
	},
	{
		id: 'fix',
		Header: 'Устранение',
		Cell: CellWrap({problem: ProblemFixCell}),
		width: 80,
		minWidth: 80
	}
];
