import React, {useCallback, useEffect, useState} from 'react';
import {Column, useTable} from 'react-table';
import {DndProvider} from 'react-dnd';
import {MultiBackend} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import update from 'immutability-helper';
import {RowTable} from './components/RowTable';
import './DndTable.less';
import {isEqual} from 'lodash';

export interface IDndTableProps<D extends {id: string, children?: D[]}> {
	columns: Array<Column<D>>;
	data: D[];
	onRowClick?: (data: D) => void;
	onSaveDrop?: (data: D[]) => void;
}
export const DndTable = <D extends {id: string, children?: D[]}>(props: IDndTableProps<D>) => {
	const {columns, data, onRowClick, onSaveDrop} = props;
	const [records, setRecords] = useState<D[]>([]);
	useEffect(() => {
		if (data && data.length) {
			setRecords(data);
		}
	}, [data]);

	const getRowId = useCallback((row: {id: string}) => row.id, []);

	const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable<D>({
		data: records,
		columns,
		getRowId
	});

	const moveRow = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragRecord = records[dragIndex];
			setRecords(
				update(records, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragRecord]
					]
				})
			);
		},
		[records]
	);

	const saveDrop = useCallback(() => {
		if (onSaveDrop && !isEqual(data, records)) {
			onSaveDrop(records);
		}
	}, [data, onSaveDrop, records]);

	return (
		<DndProvider
			backend={MultiBackend}
			options={HTML5toTouch}
		>
			<div className="dnd-table">
				<table
					className="dnd-table__content"
					{...getTableProps()}
				>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr
								className="dnd-table__header-row"
								{...headerGroup.getHeaderGroupProps()}
							>
								<th className="dnd-table__header-move-cell"/>
								{headerGroup.headers.map(column => (
									<th
										className="dnd-table__header-cell"
										{...column.getHeaderProps()}
									>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody
						className="dnd-table__body"
						{...getTableBodyProps()}
					>
						{rows.map((row, index) => {
							prepareRow(row);
							return (
								<RowTable
									index={index}
									row={row}
									moveRow={moveRow}
									onRowClick={onRowClick}
									onSaveDrop={saveDrop}
									{...row.getRowProps()}
								/>
							);
						})}
					</tbody>
				</table>
			</div>
		</DndProvider>
	);
};
