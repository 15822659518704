import React, {memo} from 'react';
import Menu from './Menu';

interface IControlsProps {
	taskId: string;
}

const DesktopControls = ({taskId}: IControlsProps) => (
	<div className="inspection-page__d-controls">
		<div className="inspection-page__d-controls-left">
			{/* <DesktopLinks
				objectId={objectId}
				inspection={inspection}
			/> */}
		</div>
		<div className="inspection-page__d-controls-right">
			<Menu taskId={taskId}/>
		</div>
	</div>
);

export default memo(DesktopControls);
