import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {useCallback, useMemo, useState} from 'react';
import {EntitiesTable, LoadingPanel, Plate} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {desktopColumns} from './columns.desktop';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {IPreparedSpaceTypeDecoration} from '../../interfaces/IPreparedSpaceTypeDecoration';
import {convertSpaceTypeDecorationSet} from '../../utils/convertSpaceTypeDecoration';
import {extractSpaceTypeDecorationSetsAsArray} from '@src/store/modules/entities/spaceTypeDecorationSets/selectors';
import {getSpaceTypeDecorationSets} from '@src/store/modules/entities/spaceTypeDecorationSets/actions/get';
import {IGetSpaceTypeDecorationSetsResponse} from '@src/api/backend/spaceTypeDecorationSets';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface ITableProps {
	loading?: boolean;
}

export const Table = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const data = useAppSelector(extractSpaceTypeDecorationSetsAsArray);
	const dispatch = useAppDispatch();

	const [, loadSpaceTypeDecorationSets] = useAsyncFn(
		async () =>
			dispatch(getSpaceTypeDecorationSets()) as Promise<IGetSpaceTypeDecorationSetsResponse>
	);

	const preparedData = useMemo(
		() => (!loading && data?.length > 0 ? convertSpaceTypeDecorationSet(data) : []),
		[data, loading]
	);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const handleRowClick = useCallback((item: IPreparedSpaceTypeDecoration) => {
		pushPath(`/manage/space-type-decoration/${item.id}`);
	}, []);

	return (
		<Plate withoutPadding>
			<MenuActionCtxProvider reloadList={loadSpaceTypeDecorationSets}>
				<LoadingPanel active={loading}>
					<EntitiesTable
						columns={desktopColumns}
						data={preparedData}
						selectedRows={selectedRows}
						selectable={false}
						onRowClick={handleRowClick}
						onSelectedRowsChange={setSelectedRows}
					/>
				</LoadingPanel>
			</MenuActionCtxProvider>
		</Plate>
	);
};
