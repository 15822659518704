import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import ISpaceIndicatorsSet from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicatorsSet';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type ISpaceIndicatorsSetsState = ILoadableEntitiesState<ISpaceIndicatorsSet>;

export const spaceIndicatorsSets = createReducer<ISpaceIndicatorsSetsState>(
	getLoadableEntitiesInitialState<ISpaceIndicatorsSet>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ISpaceIndicatorsSet>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ISpaceIndicatorsSet>(),
		[CLEAR_BEFORE_UPDATE]: state => ({...state, loaded: false})
	}
);
