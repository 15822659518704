import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetTasksResponse extends INormalizedData<ITask> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список задач
 */
export async function requestTasks(
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	return wsConnector.sendAuthorizedRequest<IGetTasksResponse>('getTasks', params, {
		timeout: 300
	});
}
