import {get, set, del, createStore} from 'idb-keyval';
import {PersistedClient, Persister} from '@tanstack/react-query-persist-client';

const queryCacheStore = createStore('query-cache', 'cache');

export const createIDBPersister = (idbValidKey: IDBValidKey = 'reactQuery') =>
	({
		persistClient: async (client: PersistedClient) => set(idbValidKey, client, queryCacheStore),
		restoreClient: async () => get<PersistedClient>(idbValidKey, queryCacheStore),
		removeClient: async () => del(idbValidKey, queryCacheStore)
	} as Persister);
