import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import React, {Dispatch, useCallback, useState} from 'react';
import {AddingWorkingGroupPerformerDialog} from '../AddingWorkingGroupPerformerDialog';

export function useAddingWorkingGroupPerformerDialog(
	editingState: IEditableWorkingGroupState,
	editingDispatch: Dispatch<IEditableWorkingGroupAction>,
	workingGroupId?: string
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);
	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<AddingWorkingGroupPerformerDialog
			isOpen={isOpen}
			onClose={close}
			editingState={editingState}
			editingDispatch={editingDispatch}
			workingGroupId={workingGroupId}
		/>
	);

	return [dialog, open];
}
