import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import Info from './info/Info';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MobileSelectionClearing from './selection/SelectionClearing.mobile';
import MobileRightButtons from './actions/RightButtons.mobile';
import {Plate} from '@tehzor/ui-components';
import Table from './table/Table';
import {mobileColumns} from './table/columns.mobile';
import EntitiesVisibility from './actions/EntitiesVisibility';
import EntityAdding from '@src/pages/InternalAcceptancePage/components/actions/EntityAdding';
import PageBreadcrumbs from './PageBreadcrumbs';
import FromSpacePageBreadcrumbs from './FromSpacePageBreadcrumbs';

interface IMobileProps {
	objectId: string;
	internalAcceptanceId: string;
	internalAcceptance?: ILinkedInternalAcceptance;
}

const Mobile = ({objectId, internalAcceptanceId, internalAcceptance}: IMobileProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const spaceId = (useLocation().state as {spaceId?: string} | null)?.spaceId;
	useAppHeader(
		{
			title: internalAcceptance ? `Внутренняя приёмка №${internalAcceptance.number}` : '',
			showBackBtn: true,
			mobileLeftButton:
				Object.keys(selectedRows).length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows}/>
				) : undefined,
			mobileRightButtons: (
				<MobileRightButtons
					objectId={objectId}
					internalAcceptanceId={internalAcceptanceId}
					internalAcceptance={internalAcceptance}
					selectedRows={selectedRows}
				/>
			)
		},
		[internalAcceptance, selectedRows]
	);

	return internalAcceptance !== undefined ? (
		<div className="page-cont internal-acceptance-page">
			{spaceId ? (
				<FromSpacePageBreadcrumbs
					objectId={objectId}
					internalAcceptance={internalAcceptance}
				/>
			) : (
				<PageBreadcrumbs
					objectId={objectId}
					internalAcceptance={internalAcceptance}
				/>
			)}
			<Info
				objectId={objectId}
				internalAcceptance={internalAcceptance}
			/>

			<EntitiesVisibility className="internal-acceptance-page__m-visibility-filter"/>

			<Plate withoutPadding>
				<Table
					objectId={objectId}
					columns={mobileColumns}
					hideHead
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
					internalAcceptanceId={internalAcceptanceId}
				/>
			</Plate>

			<EntityAdding
				objectId={objectId}
				internalAcceptance={internalAcceptance}
			/>
		</div>
	) : null;
};

export default Mobile;
