import React, {useCallback, useState} from 'react';
import './ColorPicker.less';
import {HexColorPicker} from 'react-colorful';
import ButtonBase from '../../buttons/ButtonBase';
import {PresetButton} from './components/PresetButton';

interface IColorPickerProps {
	value?: string;
	onChange: (value: string) => void;
	onClose: () => void;
	presetColors?: string[];
}

const ColorPicker = ({
	value, onChange, onClose, presetColors
}: IColorPickerProps) => {
	const [fullView, setFullView] = useState(false);

	const handleChangeView = useCallback(() => setFullView(s => !s), []);

	return (
		<div className="colorpicker">
			{presetColors && presetColors.length > 0 && !fullView ? (
				<div className="colorpicker__preset">
					{presetColors.map(presetColor => (
						<PresetButton
							key={presetColor}
							presetColor={presetColor}
							onChange={onChange}
							onClose={onClose}
						/>
					))}
					<ButtonBase
						key="switch"
						className="colorpicker__preset-button"
						classNamePrefix="color-picker-button-switch"
						label="+"
						outerTagType="button"
						onClick={handleChangeView}
					/>
				</div>
			) : (
				<HexColorPicker
					className="colorpicker__field"
					color={value}
					onChange={onChange}
				/>
			)}
		</div>
	);
};

export default ColorPicker;
