import React from 'react';
import {Button, IconButton} from '@tehzor/ui-components';
import {useChecksExport} from '@src/core/hooks/export/useChecksExport';

interface IDesktopChecksExportProps {
	objectId?: string;
}

const DesktopChecksExport = ({objectId}: IDesktopChecksExportProps) => {
	const [exportDialog, openExportDialog] = useChecksExport(objectId);

	return (
		<>
			{objectId ? (
				<IconButton
					type="accent-blue"
					onClick={openExportDialog}
				>
					<i className="tz-export-20"/>
				</IconButton>
			) : (
				<Button
					type="accent-blue"
					label="Экспорт"
					leftIcon={<i className="tz-export-20"/>}
					onClick={openExportDialog}
				/>
			)}
			{exportDialog}
		</>
	);
};

export default DesktopChecksExport;
