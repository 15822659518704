import React from 'react';
import './ExpandButton.less';
import ButtonBase from '../../../../buttons/ButtonBase';
import classNames from 'classnames';

const expandIcon = <i className="tz-simple-arrow-24"/>;

interface IExpandButtonProps {
	className?: string;
	style?: React.CSSProperties;
	label?: React.ReactNode;
	expanded?: boolean;

	onClick?: (event: React.MouseEvent) => void;
}

const ExpandButton = (props: IExpandButtonProps) => {
	const {className, style, label, expanded, onClick} = props;

	return (
		<ButtonBase
			className={classNames({'expandable-plate-expand-btn_expanded': expanded}, className)}
			style={style}
			rightIcon={expandIcon}
			label={label}
			classNamePrefix="expandable-plate-expand-btn"
			outerTagType="button"
			onClick={onClick}
		/>
	);
};

export default ExpandButton;
