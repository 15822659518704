import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {makeOwnerAcptExportRequest} from '@src/api/backend/ownerAcceptance';
import {addExportToast} from "@src/components/ExportToast/addExportToast";

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse) => {
	if (response.link) {
		addExportToast(
			'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document,
			undefined,
			undefined
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при экспорте');
	}
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует передачу собственнику
 *
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param acceptanceId id передачи
 * @param problems нарушения
 * @param createDocument флаг создания документа
 *
 */
export const exportOwnerAcceptance = (
	templateId: string,
	objectId: string,
	acceptanceId: string,
	problems?: string[],
	createDocument?: boolean
) =>
	createApiAction<IExportResponse>(request, success, failure, () =>
		makeOwnerAcptExportRequest(templateId, objectId, acceptanceId, problems, createDocument));
