import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {extractCheckItemsAsTree, extractTreeCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';

export const getSiblings = createSelector(
	[(s: IState) => s, (s: IState, item: ICheckItem | undefined) => item],
	(state, item) => {
		if (!item) {
			return [];
		}

		if (!item.parentId) {
			return extractCheckItemsAsTree(state, item.checkListId);
		}

		const parent = extractTreeCheckItemById(state, item.checkListId, item.parentId);

		return parent!.children || [];
	}
);
