import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {GET_REQUEST} from '@src/store/modules/entities/ownerAcceptances/constants';
import {IChangeOwnerAcceptancesTabIndexPayload} from './actions';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';

export interface IOwnerAcceptancesFiltersState extends Record<string, unknown> {
	objects?: string[];
	resolutions?: string[];
	statuses?: string[];
	spaceOwners?: string[];
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
	modifiedAtFrom?: Date;
	modifiedAtTo?: Date;
}

export type IOwnerAcceptancesSortState = Record<string, boolean>;

export interface IOwnerAcceptancesPageSettingsState {
	tabIndex: number;
	filters: IOwnerAcceptancesFiltersState;
	sort: IOwnerAcceptancesSortState;
	pageSize: number;
}

export type IOwnerAcceptancesPagesSettingsState = Record<string, IOwnerAcceptancesPageSettingsState>;

export const getInitialStateForPage = (objectId: string): IOwnerAcceptancesPageSettingsState => ({
	tabIndex: 0,
	filters: {
		objects: objectId !== 'all' ? [objectId] : undefined
	},
	sort: {
		modifiedAt: false
	},
	pageSize: 20
});

export default createReducer<IOwnerAcceptancesPagesSettingsState>(
	{},
	{
		[GET_REQUEST]: (state, {payload}: { payload: { objectId: string } }) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage(payload.objectId);
			}
		},
		[types.CHANGE_TAB_INDEX]: (state, {payload}: { payload: IChangeOwnerAcceptancesTabIndexPayload }) => {
			state[payload.objectId].tabIndex = payload.tabIndex;
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: { payload: IChangeFiltersPayload<IOwnerAcceptancesFiltersState> }
		) => {
			const {filters} = payload;
			if (payload.objectId !== 'all') {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CLEAR_FILTERS]: (state, {payload}: { payload: IClearFiltersPayload }) => {
			const filters = {} as IOwnerAcceptancesFiltersState;
			if (payload.objectId !== 'all') {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: { payload: IChangeSortPayload<IOwnerAcceptancesSortState> }
		) => {
			state[payload.objectId].sort = payload.sort;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: { payload: IChangePageSizePayload }) => {
			state[payload.objectId].pageSize = payload.pageSize;
		}
	}
);
