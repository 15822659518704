import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {extractOwnerAcceptanceStatusesAsArray} from '@src/store/modules/dictionaries/ownerAcceptanceStatuses/selectors';

interface IOwnerAcceptancesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const OwnerAcceptancesStatusesFilterMobile = memo((props: IOwnerAcceptancesFilterMobileProps) => {
	const statuses = useAppSelector(extractOwnerAcceptanceStatusesAsArray);

	return (
		<BaseListFilterMobile
			{...props}
			options={statuses}
			label="Статусы"
			filterName="statuses"
		/>
	);
});