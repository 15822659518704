import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Plate} from '@tehzor/ui-components';
import './EditingExportTemplate.less';
import {extractExportTemplateById} from '@src/store/modules/dictionaries/exportTemplates/selectors';
import {useEditableExportTemplate} from '../EditableExportTemplate/hooks/useEditableExportTemplate';

interface IEditingExportTemplateProps {
	exportTemplateId?: string;
}

export const EditingExportTemplate = ({exportTemplateId}: IEditingExportTemplateProps) => {
	const exportTemplate = useAppSelector(s => extractExportTemplateById(s, exportTemplateId));
	const [exportTemplateFields, availableFilds, commonInstruction] = useEditableExportTemplate({
		exportTemplate
	});

	return (
		<div className="editing-export-template__container">
			<Plate className="editing-export-template__info-grid">{exportTemplateFields}</Plate>
			<Plate className="editing-export-template__items-grid">
				{commonInstruction}
				{availableFilds}
			</Plate>
		</div>
	);
};
