import React, {useCallback, useEffect} from 'react';
import {Notifications} from '@tehzor/ui-components';
import INotificationMessage from '@tehzor/tools/interfaces/INotificationMessage';
import {
	subscribeOnNotifications,
	unsubscribeFromNotifications,
	getMessages,
	clearNotificationMessages,
	deleteMessage,
	deleteAllMessages
} from '@src/actions/notifications/messages';
import {
	updateWebPushSubscription,
	getPublicKey
} from '@src/actions/notifications/webPushSubscriptions';
import config from '@src/core/config';
import {
	toggleWebPushEnabled,
	toggleNotificationsVisible
} from '@src/actions/settings/notifications';
import {requestPermission, subscribeOnWebPush} from '@src/utils/webPushHelpers';
import generateFingerprint from '@src/utils/generateFingerprint';
import {useGetDeviceId} from '@src/core/hooks/useGetDeviceId';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getRouteForNotificationAction} from '@src/utils/getRouteForNotificationAction';
import {useChangePath} from '@src/core/hooks/useChangePath';

const isWebPushSupported = 'serviceWorker' in navigator && 'PushManager' in window;

const isKeyString = (key: unknown): key is string => typeof key === 'string';

const AppNotifications = () => {
	const {messages, total, hasMore} = useAppSelector(s => s.notifications.messages);
	const {isVisible, isWebPushEnabled} = useAppSelector(s => s.settings.notifications);
	const {pushPath} = useChangePath();
	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const dispatch = useAppDispatch();

	const deviceId = useGetDeviceId();

	useEffect(() => {
		// Действия при изменении статуса соединения
		const connectToNotification = async () => {
			await dispatch(subscribeOnNotifications());
			await dispatch(getMessages());
		};

		let timeout: number | undefined;
		// Реконнект при возобновлении соединения
		if (online) {
			timeout = window.setTimeout(() => {
				void connectToNotification();
			}, 1000);
		}
		// Очистка сообщений при потере соединения
		if (!online) {
			dispatch(clearNotificationMessages());
		}
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [online]);

	useEffect(() => {
		// Отключение при unmount'е
		const disconnectFromNotifications = async () => {
			await dispatch(unsubscribeFromNotifications());
		};

		return () => {
			void disconnectFromNotifications();
		};
	}, []);

	// Получение сообщений от service worker'а
	const handleServiceWorkerMsg = (
		event: MessageEvent<INotificationMessage & {event: string}>
	) => {
		if (event.data.event === 'handle-notification-action') {
			const type = event.data?.type;
			const fields = event.data?.fields;

			if (!fields) {
				return;
			}
			const route = getRouteForNotificationAction(type, fields);
			if (route) {
				pushPath(route);
			}
		}
	};

	useEffect(() => {
		const addServiceWorkerListeners = async () => {
			await navigator.serviceWorker.ready;
			navigator.serviceWorker.addEventListener('message', handleServiceWorkerMsg);
		};

		void addServiceWorkerListeners();
	}, []);

	// Запрос и подписка на web push
	const enableWebPush = useCallback(async () => {
		if (!isWebPushSupported) {
			return;
		}
		try {
			const key = await dispatch(getPublicKey());
			if (!isKeyString(key)) {
				return;
			}

			const sub = await subscribeOnWebPush(key);
			const fingerprint = await generateFingerprint();

			await dispatch(updateWebPushSubscription(sub, fingerprint));

			dispatch(toggleWebPushEnabled(true));
		} catch (error) {
			console.log(error);
		}
	}, [deviceId]);

	// Проверка прав на показ уведомлений
	const checkWebPush = async () => {
		if (Notification.permission !== 'granted' && (await requestPermission()) === 'granted') {
			await enableWebPush();
		}
	};
	useEffect(() => {
		if (deviceId) {
			void checkWebPush();
		}
	}, [deviceId]);

	// Обработка клика по сообщению
	const handleMessageClick = useCallback((event: React.MouseEvent) => {
		const target = event.target as HTMLElement;
		if (target.tagName === 'A' && !target.getAttribute('target')) {
			event.preventDefault();
			const el = event.target as HTMLAnchorElement;

			if (el.href && config.appUrl) {
				const link = document.createElement('a');
				link.href = config.appUrl;

				dispatch(toggleNotificationsVisible(false));

				setTimeout(() => {
					if (el.host === link.host) {
						pushPath(el.pathname + el.search);
					} else {
						window.open(el.href, '_blank');
					}
				}, 310);
			}
		}
	}, []);

	// Удаление всех сообщений
	const handleDeleteAll = useCallback(async () => {
		await dispatch(deleteAllMessages());
		dispatch(toggleNotificationsVisible(false));
	}, []);

	const handleDeleteMessage = useCallback((id: string) => dispatch(deleteMessage(id)), []);
	const handleLoadMessages = useCallback(() => dispatch(getMessages()), []);
	const handleToggleVisible = useCallback(() => dispatch(toggleNotificationsVisible()), []);

	return (
		<Notifications
			className="header__notifications"
			data={messages}
			messagesCount={total}
			hasMoreMessages={hasMore}
			isVisible={isVisible}
			isWebPushEnabled={isWebPushEnabled}
			onMessageDelete={handleDeleteMessage}
			onMessageClick={handleMessageClick}
			loadNextMessagesPage={handleLoadMessages}
			onAllMessagesDelete={handleDeleteAll}
			onVisibilityChange={handleToggleVisible}
			onWebPushEnableRequest={enableWebPush}
		/>
	);
};

export default AppNotifications;
