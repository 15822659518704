import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {Dispatch, useCallback, useState, useMemo} from 'react';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import {IEditableProblemAction} from '@src/core/hooks/states/useEditableProblemState';
import {extractProblemTemplatesAsArray} from '@src/store/modules/dictionaries/problemTemplatesSets/selectors';
import {
	ActionButtons,
	Button,
	Dialog,
	InlineButton,
	SearchSelect,
	useLocalSearchProvider
} from '@tehzor/ui-components';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import './TemplateSelectDialog.less';
import {useTemplateSelectHandler} from '@src/components/EditableProblem/hooks/useTemplateSelectHandler';
import {renderItem} from '@src/components/EditableProblem/utils/renderItem';
import {useToggle} from 'react-use';

interface ITemplateSelectDialogProps {
	editingDispatch: Dispatch<IEditableProblemAction>;
	objectId: string;
	stage: ObjectStageIds;

}

export const TemplateSelectDialog = ({editingDispatch, objectId, stage}: ITemplateSelectDialogProps) => {
	const templates = useAppSelector(s => extractProblemTemplatesAsArray(s, objectId));
	const filteredTemplates = useMemo(() => templates.filter(el => el.stageId === stage), [templates, stage]);
	const selectHandler = useTemplateSelectHandler({objectId, editingDispatch, stage});
	const [isOpen, toggle] = useToggle(false);
	const [selected, setSelected] = useState<IProblemTemplate>();

	const provider = useLocalSearchProvider({
		initialList: filteredTemplates,
		keys: ['description']
	});

	const handleSave = useCallback(() => {
		if (!selected) {
			toggle(false);
			return;
		}

		selectHandler(selected);

		toggle(false);
	}, [selected, selectHandler, toggle]);

	return (
		<>
			<InlineButton
				onClick={toggle}
				label="Выбрать шаблон"
				type="accent"
			/>
			<Dialog
				className={{
					content: 'template-select-dialog',
					body: 'template-select-dialog__body'
				}}
				title="Выберите шаблон"
				isOpen={isOpen}
				footer={(
					<ActionButtons>
						<Button
							type="cancel"
							label="Отменить"
							onClick={toggle}
						/>
						<Button
							type="accent-blue"
							label="Применить"
							onClick={handleSave}
						/>
					</ActionButtons>
				)}
				fullScreenOnTablet
				onRequestClose={toggle}
			>
				<SearchSelect
					provider={provider}
					renderItem={renderItem}
					onSelect={setSelected as (value: IProblemTemplate | undefined) => void}
				/>
			</Dialog>
		</>
	);
};
