import React, {useMemo, memo} from 'react';
import {PageHeader} from '@tehzor/ui-components';
import declination from '@tehzor/tools/utils/declination';
import './OfflineJournalPageCounter.less';

interface IOfflineJournalPageCounterProps {
	length: number;
	isFiltersActive?: boolean;
}

export const OfflineJournalPageCounter = memo((props: IOfflineJournalPageCounterProps) => {
	const {length, isFiltersActive = false} = props;

	// isFilterActive нужен при фильтрации (Найдено, Всего)
	const text = useMemo(
		() =>
			`${isFiltersActive ? 'Найдено:' : 'Всего:'} ${length} ${declination(length || 0, [
				'действие',
				'действия',
				'действий'
			])}`,
		[length, isFiltersActive]
	);

	return (
		<PageHeader
			className="offline-journal-page-counter"
			title=""
			subTitle={text}
			wrapClassName="offline-journal-page-counter__header-wrap"
		/>
	);
});
