import React, {useCallback, useState} from 'react';
import {createRecursiveIndex} from '@tehzor/tools/utils/createRecursiveIndex';
import classNames from 'classnames';
import {SetItem} from './SetItem';
import './EditableSet.less';
import {DndProvider} from 'react-dnd';
import {MultiBackend} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import {isEqual} from 'lodash';
import update from 'immutability-helper';
import {useUpdateEffect} from 'react-use';

interface IEtitableTreeSetProps<S> {
	items: S[];
	visibleItems: S[];
	edited: string[];
	index: string;
	onNameChange: (name: string, id: string) => void;
	onCreate: (parent?: S) => void;
	onRemove: (id: string) => Promise<void>;
	onChangeEdit: (id: string) => void;
	onConfirm: (id: string) => Promise<void>;
	onCancel: (id: string) => void;
	isChildren: boolean;
	isEditable: boolean;
	current: string | undefined;
	setCurrent: React.Dispatch<React.SetStateAction<string | undefined>>;
	onSaveDrop?: (data: S[]) => void;

}

export const EtitableSet
	= <S extends { id: string, order?: number, name?: string, parentId?: string }>(props: IEtitableTreeSetProps<S>) => {
		const {
			items,
			visibleItems,
			edited,
			index,
			onNameChange,
			onCreate,
			onRemove,
			onChangeEdit,
			onConfirm,
			onCancel,
			isChildren,
			isEditable,
			current,
			setCurrent,
			onSaveDrop
		} = props;

		const [draggableItems, setDraggableItems] = useState(visibleItems);

		useUpdateEffect(() => {
			setDraggableItems(visibleItems);
		}, [items]);

		const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
			const dragItem = draggableItems[dragIndex];
			const newDraggableItems = update(draggableItems, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragItem]
				]
			});
			setDraggableItems(newDraggableItems);
		}, [draggableItems]);

		const saveDrop = useCallback(() => {
			if (onSaveDrop && !isEqual(visibleItems, draggableItems)) {
				onSaveDrop(draggableItems);
			}
		}, [visibleItems, onSaveDrop, draggableItems]);

		return (
			<div
				className={classNames(
					'editable-set', {
					'editable-set__with-offset': current,
					'editable-children': isChildren
				}
				)}
			>
				{items.length ? (
					draggableItems
					.map((item, i) => (item ? (
						<React.Fragment key={item.id}>
							<DndProvider
								backend={MultiBackend}
								options={HTML5toTouch}
							>
								<SetItem
									item={item}
									edited={edited}
									index={index}
									number={i}
									onNameChange={onNameChange}
									onCreate={onCreate}
									onRemove={onRemove}
									onChangeEdit={onChangeEdit}
									onConfirm={onConfirm}
									onCancel={onCancel}
									isChildren={isChildren}
									isEditable={isEditable}
									current={current}
									setCurrent={setCurrent}
									saveDrop={saveDrop}
									moveRow={moveRow}

								/>
								<EtitableSet
									{...props}
									isChildren
									index={createRecursiveIndex(index, i, isChildren)}
									visibleItems={items.filter(
										val => val?.parentId === item.id
									)}
								/>
							</DndProvider>
						</React.Fragment>
					) : null))
				) : (
					<p className="editable-set__text">Нет элементов в наборе</p>
				)}
			</div>
		);
	};