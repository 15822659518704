import {createReducer} from '@reduxjs/toolkit';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ICheckGetTasksPayload} from '../actions';
import * as types from '../constants/tasks';
import {RESET} from '@src/store/modules/pages/checkLists/constants/page';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

export interface ITasksState {
	byId: {
		[id: string]: ITask;
	};
	allIds: string[];
	total: number;
}

export const getInitialState = (): ITasksState => ({
	byId: {},
	allIds: [],
	total: 0
});

export default createReducer<ITasksState>(getInitialState(), {
	[types.GET_SUCCESS]: (state, {payload}: {payload: ICheckGetTasksPayload}) => {
		state.byId = payload.byId;
		state.allIds = payload.allIds;
		state.total = payload.total;
	},
	[RESET]: getInitialState,
	[CLEAR_STORE]: getInitialState
});
