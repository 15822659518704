import {IState} from '@src/store/modules';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {createSelector} from 'reselect';

const extractAllIds = (state: IState) => state.dictionaries.workingGroups.allIds || [];
const extractById = (state: IState) => state.dictionaries.workingGroups.byId || {};
const getParentId = (state: IState, parentId: string | undefined) => parentId;
const getGroupId = (state: IState, groupId: string | undefined) => groupId;

export const extractWorkingGroupsAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map(id => byId[id])
);

export const extractWorkingGroupsAsArrayByType = createSelector(
	[
		extractWorkingGroupsAsArray,
		(s: IState) => s.dictionaries.objects.byId,
		(s: IState, type: WorkingGroupTypeId) => type,
		(s: IState, type: WorkingGroupTypeId, objectId?: string) => objectId,
		(s: IState, type: WorkingGroupTypeId, objectId?: string, stage?: ObjectStageIds) => stage
	],
	(workingGroups, objectsMap, type, objectId, stage) => {
		const object = objectId ? objectsMap[objectId] : undefined;
		const companyId = object?.companyId;

		const groups: IWorkingGroup[] = [];
		for (const workingGroup of workingGroups) {
			const isTypesEquals = workingGroup.type === type;
			const isCompaniesEquals = !companyId || workingGroup.companyId === companyId;
			const isObjectsEquals =
				!object ||
				(workingGroup.objects?.length && workingGroup.objects.includes(object.id));
			const isStagesEquals =
				!stage ||
				!workingGroup.stages ||
				(stage && workingGroup.stages && workingGroup?.stages.includes(stage));

			if (isTypesEquals && isCompaniesEquals && isObjectsEquals && isStagesEquals) {
				groups.push(workingGroup);
			}
		}

		return groups;
	}
);

export const extractProblemInspectorsGroupsAsArray = createSelector(
	[
		(s: IState) => s,
		(s: IState, objectId?: string) => objectId,
		(s: IState, objectId?: string, stage?: ObjectStageIds) => stage
	],
	(state, objectId, stage) =>
		extractWorkingGroupsAsArrayByType(
			state,
			WorkingGroupTypeId.PROBLEM_INSPECTORS,
			objectId,
			stage
		)
);

export const extractPerformersGroupsAsArray = createSelector(
	[
		(s: IState) => s,
		(s: IState, objectId?: string) => objectId,
		(s: IState, objectId?: string, stage?: ObjectStageIds) => stage
	],
	(state, objectId, stage) =>
		extractWorkingGroupsAsArrayByType(state, WorkingGroupTypeId.PERFORMERS, objectId, stage)
);

export const extractWorkingGroupById = createSelector(
	[extractById, getGroupId],
	(byId, groupId) => {
		if (groupId) {
			return byId[groupId];
		}
		return undefined;
	}
);

export const extractWorkingGroupsByParentIdAsArray = createSelector(
	[extractWorkingGroupsAsArray, getParentId],
	(groups, parentId) => {
		if (parentId) {
			return groups.filter(group => group.parentId === parentId);
		}
		return [];
	}
);
