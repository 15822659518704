import {createReducer} from '@reduxjs/toolkit';
import {schedule as types} from '../constants/index';
import {GET_REQUEST} from '@src/store/modules/entities/tasks/constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {ChangeScheduleValueMode, ITasksFiltersState, ITasksSettingsMode, ITasksSettingsState, TasksScheduleCollectMode} from '@src/store/modules/settings/pages/tasks/interfaces';
import {generateWeek} from '../utils/scheduleUtils';

export interface ITasksPageSettingsScheduleState {
	filters: ITasksFiltersState;
	settings: ITasksSettingsState;
	collectBy: TasksScheduleCollectMode;
}

export const getInitialStateForTasksSchedulePage = (): ITasksPageSettingsScheduleState => ({
	filters: {},
	settings: ({
		currentMode: 'year',
		year: new Date().getFullYear(),
		month: new Date().getMonth(),
		week: generateWeek(),
		day: new Date().getDate()
	}),
	collectBy: TasksScheduleCollectMode.OBJECT
});

export const schedule = createReducer<ITasksPageSettingsScheduleState>(
	getInitialStateForTasksSchedulePage(),
	{
		[GET_REQUEST]: state => {
			if (!state) {
				return getInitialStateForTasksSchedulePage();
			}
			return state;
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<ITasksFiltersState>}
		) => {
			state.filters = payload.filters;
			return state;
		},
		[types.CLEAR_FILTERS]: state => {
			state.filters = {};
			return state;
		},
		[types.CHANGE_SETTINGS_CURRENT_MODE]: (
			state,
			{payload}: {payload: {mode: ITasksSettingsMode}}
		) => {
			state.settings.currentMode = payload.mode;
			return state;
		},
		[types.CHANGE_SETTINGS_VALUE]: (
			state,
			{payload}: {payload: {mode: ChangeScheduleValueMode}}
		) => {
			const {currentMode, month} = state.settings;

			if (currentMode === 'year') {
				if (payload.mode === 'increment') {
					state.settings.year += 1;
				}

				if (payload.mode === 'decrement') {
					state.settings.year -= 1;
				}
			}

			if (currentMode === 'month') {
				if (payload.mode === 'increment') {
					if (month === 11) {
						state.settings.month = 0;
						state.settings.year += 1;
					} else {
						state.settings.month += 1;
					}
				}

				if (payload.mode === 'decrement') {
					if (month === 0) {
						state.settings.month = 11;
						state.settings.year -= 1;
					} else {
						state.settings.month -= 1;
					}
				}
			}
		},
		[types.CHANGE_COLLECT_MODE]: (
			state,
			{payload}: {payload: {collectMode: TasksScheduleCollectMode}}
		) => ({
			...state,
			collectBy: payload.collectMode
		})
	}
);
