import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IDescriptionProps {
	className?: string;
	objectId: string;
}

const ObjectInfo = ({className, objectId}: IDescriptionProps) => {
	const object = useAppSelector(s => s.dictionaries.objects.byId?.[objectId]);

	return (
		<EntityGridItem
			className={className}
			icon={<i className="tz-location-20"/>}
			label="Объект"
			fullRow
		>
			<div style={{whiteSpace: 'pre-wrap'}}>{object.name}</div>
		</EntityGridItem>
	);
};

export default ObjectInfo;
