import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {FilterButton, SelectPopup, TreeSelect} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {makeTreeDataSpaceTypeDecoration} from '@src/components/editableFields/SpaceTypeDecorationTreeSelect/utils/makeTreeData';
import SelectSearch, {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {ISpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecoration';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export interface ISpaceTypeDecorationFilterProps {
	typeDecorations: ISpaceTypeDecoration[];
	typeDecorationSets: ISpaceTypeDecorationSet[];
	label?: string;
	value?: string[];
	filterName?: string;
}

export const SpaceTypeDecorationFilter = memo((props: ISpaceTypeDecorationFilterProps) => {
	const {label = 'Типы отделки', value, filterName = 'typeDecoration', typeDecorations, typeDecorationSets} = props;
	const {dispatch} = useEntitiesFiltersCtx();

	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const treeData = useMemo(() =>
		makeTreeDataSpaceTypeDecoration(typeDecorations, typeDecorationSets, true),
	[typeDecorations, typeDecorationSets]);

	const {filteredData, expanded} = useMemo(() => treeFilter(treeData, 'content', search), [search, treeData]);

	useUpdateEffect(() => {
		setExpandedObjects(expanded.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		dispatch({[filterName]: selectedObjects});
		clearSearch();
	}, [dispatch, filterName, selectedObjects, clearSearch]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
		clearSearch();
	 }, [clearSearch]);

	const handleFullClear = useCallback(() => {
		dispatch({[filterName]: undefined});
		setSelectedObjects([]);
		setExpandedObjects([]);
		clearSearch();
	}, [clearSearch, dispatch, filterName]);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
		clearSearch();
	}, [clearSearch, value]);

	useUpdateEffect(() => {
		setExpandedObjects([]);
		setSelectedObjects(value);
	}, [value]);

	return (
		<SelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedObjects?.length}
			footer
			count={selectedObjects?.length}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
			trigger={(
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(label, value, treeData)}
					active={!!value?.length}
					onClear={handleFullClear}
				/>
			)}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedObjects}
				onChange={setSelectedObjects}
				expandedValue={expandedObjects}
				onExpand={handleExpand}
			/>
		</SelectPopup>
	);
});
