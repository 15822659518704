import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {BaseListFilterMobile} from '@src/components/MobileEntitiesFilters/components/BaseListFilterMobile';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {extractRootCheckItems} from '@src/store/modules/dictionaries/checkItems/selectors';

interface ICheckListsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;
	checkList: string;
	onChange: (changes: IChanges) => void;
}

export const CheckListCategoryFilterMobile = memo((props: ICheckListsFilterMobileProps) => {
	const {checkList} = props;
	const checkListCategory = useAppSelector(s => extractRootCheckItems(s, checkList));

	return (
		<BaseListFilterMobile
			{...props}
			options={checkListCategory}
			label="Категории чек-листов"
			filterName="checkListCategory"
		/>
	);
});
