import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FilesUploader from '@tehzor/tools/components/FilesUploader';
import Button from '../Button';
import {FilesPicker} from '@tehzor/ui-components';

/**
 * Шаблон файла
 *
 * @param {Object} props свойства
 * @return {*}
 * @constructor
 */
const FileTemplate = props => (
	<div>
		<div>{props.name}</div>

		<Button
			onClick={props.onDelete}
		>
			<i className="tz-close-20"/>
		</Button>

		{props.status === 'error' && (
			<Button
				onClick={props.onReload}
			>
				<i className="tz-reload-20"/>
			</Button>
		)}

		{props.status === 'started' && props.progress !== null ? (
			<div>
				{props.progress}
				%
			</div>
) : ''}
	</div>
);

/**
 * Компонент для редактирование выбранного изображения плана
 */
class PlanImage extends Component {
	_filesUploader = React.createRef();

	_picker = React.createRef();

	state = {
		files: []
	};

	/**
	 * Возвращает значение активна ли выгрузка файла в данный момент
	 *
	 * @return {Boolean}
	 */
	isUploadActive = () => (this._filesUploader ? this._filesUploader.isUploadActive() : false);

	/**
	 * Очистка временныйх файлов
	 */
	_clearFiles = () => {
		if (this._filesUploader) {
			this._filesUploader.clearFiles();
		}
	};

	render() {
		const {fullImage, addTempFile, deleteTempFile} = this.props;

		const {files} = this.state;
		const editPermission = true;

		const fileTemplate = <FileTemplate/>;

		return (
			<div style={{marginTop: '20px'}}>
				<FilesUploader
					files={files}
					accept="image/jpeg,image/png,image/gif"
					multiple={false}
					destination="planImage"
					fileTemplate={fileTemplate}
					onFilesChange={this._handleFilesChange}
					ref={this._filesUploader}
				/>

				{!files.length && <div>{fullImage ? fullImage.name : ''}</div>}

				<div style={{marginTop: '10px'}}>
					{editPermission && (
						<Button
							label="Выбрать файл"
							onClick={this._pickFile}
						/>
)}
				</div>

				<FilesPicker
					accept="image/jpeg,image/png,image/gif"
					multiple={false}
					onChange={this._handleFilesPick}
					ref={this._picker}
				/>
			</div>
		);
	}

	/**
	 * Открывает диалог выбора файлов
	 *
	 * @private
	 */
	_pickFile = () => {
		if (this._picker.current) {
			this._picker.current.open();
		}
	};

	/**
	 * Обрабатывает выбранные файлы
	 *
	 * @param files
	 * @private
	 */
	_handleFilesPick = files => {
		if (this._filesUploader.current) {
			this._filesUploader.current.addFiles(files);
		}
	};

	/**
	 * Обрабатывает изменение выгружаемого файла
	 *
	 * @param files
	 * @private
	 */
	_handleFilesChange = files => {
		this.setState({files});

		const {onChange} = this.props;
		if (onChange && typeof onChange === 'function') {
			onChange(files.length && files[0].tempFile ? files[0].tempFile : null);
		}
	};
}

PlanImage.propTypes = {
	previewImage: PropTypes.object,
	fullImage: PropTypes.object,
	permissions: PropTypes.array,
	onChange: PropTypes.func,
	addTempFile: PropTypes.func.isRequired,
	deleteTempFile: PropTypes.func.isRequired
};

PlanImage.defaultProps = {
	permissions: []
};

export default PlanImage;
