import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Button} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getChecks} from '@src/store/modules/entities/checks/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';

interface IDesktopEntityAddingProps {
	objectId: string;
}

const DesktopEntityAdding = ({objectId}: IDesktopEntityAddingProps) => {
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();

	const [, reloadList] = useAsyncFn(async () => {
		await dispatch(getChecks(objectId));
	}, [objectId]);

	const [dialog, open] = useAddingCheckDialog({
		objectId,
		stage: object.stage,
		onSuccess: reloadList
	});

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-plus-20"/>}
				label="Добавить"
				onClick={open}
			/>
			{dialog}
		</>
	);
};

export default DesktopEntityAdding;
