import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectOption, Select2, FilterButton, SelectPopup} from '@tehzor/ui-components';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';

import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface IPlansFilterProps {
	label?: string;
	objectId?: string;
	plans?: string[];
}

export const PlansFilter = memo((props: IPlansFilterProps) => {
	const {label = 'План', objectId = 'all', plans} = props;

	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedPlans, setSelectedPlans] = useState(plans);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const allPlans: IPlan[] = useAppSelector(s => extractPlansAsArray(s, objectId));
	const filteredData = useMemo(() => flatFilter(allPlans, 'name', search), [allPlans, search]);

	const handleApply = useCallback(() => {
		dispatch({plans: selectedPlans});
		clearSearch();
	}, [selectedPlans, dispatch, clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedPlans([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedPlans([]);
		dispatch({plans: undefined});
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedPlans(plans);
		clearSearch();
	}, [plans, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedPlans(allPlans.map(val => val.id));
	}, [allPlans, clearSearch]);

	useUpdateEffect(() => {
		setSelectedPlans(plans);
	}, [plans]);

	return (
		<div>
			<SelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedPlans?.length}
				onSelectAll={handleSelectAll}
				count={selectedPlans?.length}
				footer
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel<IPlan>(label, plans, allPlans)}
						active={!!(plans && plans.length != 0)}
						onClear={handleFullClear}
					/>
				)}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
						type="popup"
					/>
				)}
			>
				<Select2
					multiple
					value={selectedPlans}
					onChange={setSelectedPlans}
				>
					{filteredData.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
});
