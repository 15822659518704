import {wsConnector} from '../wsConnector';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IChangeCheckRecordStatusResponse = ICheckRecord[];

/**
 * Изменяет статус записи по категории
 *
 * @param objectId id объекта
 * @param links ссылки на сущности
 * @param stage стадия
 * @param status новый статус записи
 */
export const makeCheckRecordEditStatusRequest = (
	objectId: string,
	links: ICheckRecord['links'],
	stage: ObjectStageIds,
	status: CheckRecordStatusId
) =>
	wsConnector.sendAuthorizedRequest<IChangeCheckRecordStatusResponse>('editCheckRecordStatus', {
		objectId,
		links,
		stage,
		status
	});
