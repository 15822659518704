import {IEditableContractState} from '@src/core/hooks/states/useEditableContractState';
import React, {memo, useCallback, useMemo} from 'react';
import {SingleSelectContractField} from '../fields/SingleSelectContractField';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {TreeSelectContractField} from '../fields/TreeSelectContractField';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {extractFilteredCategoriesSetsAsArray} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import {extractAllCategoriesAsArray} from '@src/store/modules/dictionaries/categories/selectors';
import makeTreeData from '@src/store/modules/dictionaries/categoriesSets/utils/makeTreeData';
import {extractObjectIdsForCompany} from '@src/store/modules/dictionaries/objects/selectors';
import {
	extractPlansByObjectIds,
	extractPlansByObjectIdsAsArray
} from '@src/store/modules/dictionaries/plans/selectors';
import {makePlansTreeData} from '@src/store/modules/dictionaries/plans/utils/makeTreeData';

interface IMainBlockProps {
	editingState: IEditableContractState;
	isMobile?: boolean;

	onChange: (
		value: string | number | string[] | null | undefined,
		field: keyof IEditableContractState
	) => void;
	onError: (field: keyof IEditableContractState) => void;
}

export const MainBlock = memo(({editingState, isMobile, onChange, onError}: IMainBlockProps) => {
	const {companyId, stageId} = editingState;

	const stages = useAppSelector(extractObjectStagesAsArray);
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);

	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);
	const objectIds = useAppSelector(s =>
		companyId ? extractObjectIdsForCompany(s, companyId) : s.dictionaries.objects.allIds
	);
	const treeObjects = useMemo(() => {
		const objects = objectIds.map(id => objectsMap[id]);
		return makeObjectsTreeData(objects);
	}, [objectIds, objectsMap, companyId]);

	const handleChangeObjects = useCallback(
		(value?: string[] | null | undefined) => {
			onChange(value, 'objectIds');

			// Сброс связанных сущностей
			onChange(null, 'planIds');
			onChange(null, 'categoryIds');
		},
		[onChange]
	);

	const categoriesMap = useAppSelector(s => s.dictionaries.categories.byId);
	const setsFilters = {
		objects: editingState.objectIds ? editingState.objectIds : objectIds,
		stages: stageId ? [stageId] : undefined
	};
	const categoriesSets = useAppSelector(s =>
		extractFilteredCategoriesSetsAsArray(s, setsFilters)
	);
	const allCategories = useAppSelector(extractAllCategoriesAsArray);
	const treeCategories = makeTreeData(categoriesSets, allCategories);

	const {byId: plansMap} = useAppSelector(s =>
		extractPlansByObjectIds(s, editingState.objectIds || undefined)
	);
	const plans = useAppSelector(s =>
		extractPlansByObjectIdsAsArray(s, editingState.objectIds || undefined)
	);
	const treePlans = makePlansTreeData(plans, objectsMap, editingState.objectIds || undefined);

	return (
		<div className="editable-contract__blocks-main">
			<div className="editable-contract__blocks-main-title">Основное</div>
			<div className="editable-contract__blocks-main-container">
				<SingleSelectContractField
					items={stages}
					itemsMap={stagesMap}
					label="Стадия"
					dialogTitle="Стадия"
					errorMessage="Укажите стадию"
					hasError={editingState.errors.stageId}
					required
					value={editingState.stageId}
					isMobile={isMobile}
					onConfirm={value => onChange(value, 'stageId')}
					onError={() => onError('stageId')}
				/>

				<TreeSelectContractField
					items={treeObjects}
					itemsMap={objectsMap}
					label="Объекты"
					dialogTitle="Объекты"
					errorMessage="Укажите объекты"
					hasError={editingState.errors.objectIds}
					value={editingState.objectIds}
					isMobile={isMobile}
					onConfirm={handleChangeObjects}
					onError={() => onError('objectIds')}
				/>

				<TreeSelectContractField
					items={treeCategories}
					itemsMap={categoriesMap}
					label="Виды работы"
					dialogTitle="Виды работы"
					errorMessage="Укажите виды работ"
					hasError={editingState.errors.categoryIds}
					value={editingState.categoryIds}
					isMobile={isMobile}
					onConfirm={value => onChange(value, 'categoryIds')}
					onError={() => onError('categoryIds')}
				/>

				<TreeSelectContractField
					items={treePlans}
					itemsMap={plansMap}
					label="Планы"
					dialogTitle="Планы"
					errorMessage="Укажите планы"
					hasError={editingState.errors.planIds}
					value={editingState.planIds}
					isMobile={isMobile}
					onConfirm={value => onChange(value, 'planIds')}
					onError={() => onError('planIds')}
				/>
			</div>
		</div>
	);
});
