import {useQuery} from '@tanstack/react-query';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import useAppSelector from '../../useAppSelector';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import IError from '@tehzor/tools/interfaces/IError';

export const useLocalOwnerAcceptances = (objectId = 'all') => {
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const {data} = useQuery<IListOwnerAcceptance[], IError>({
		queryKey: [...ownerAcceptancesQueryKeys.localList(), objectId],
		staleTime: Infinity,
		cacheTime: Infinity,
		networkMode: 'always',
		enabled: isOfflineModeAvailable
	});
	return data || [];
};
