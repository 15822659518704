import React, {Dispatch} from 'react';
import './EditableStructure.less';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {
	IEditableStructureAction,
	IEditableStructureState
} from '@src/core/hooks/states/useEditableStructureState';
import Text from '../editableFields/Text';
import {StructureTypeSelect} from '@src/components/editableFields/StructureTypeSelect';
import {PlanSelect} from '../editableFields/PlanSelect';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';

interface IEditableStructureProps {
	objectId: string;
	editingState: IEditableStructureState;
	editingDispatch: Dispatch<IEditableStructureAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableStructure = (props: IEditableStructureProps) => {
	const {
		objectId,
		editingState,
		editingDispatch,
		fieldsSettings,
		saving
	} = props;

	const plans = useAppSelector(s => extractPlansAsArray(s, objectId));
	return (
		<div className="editable-structure">

			<div className="editable-structure__info-grid">
				{fieldsSettings.name !== undefined && (
					<Text
						field="name"
						label="Наименование"
						value={editingState.name}
						editingDispatch={editingDispatch}
						required={fieldsSettings.name.isRequired}
						disabled={saving}
						hasError={editingState.errors.name}
					/>
				)}

				{fieldsSettings.type !== undefined && (
					<StructureTypeSelect
						value={editingState.type}
						editingDispatch={editingDispatch}
						required={fieldsSettings.type.isRequired}
						disabled={saving}
						hasError={editingState.errors.type}
					/>
				)}

				{fieldsSettings.planId !== undefined && (
					<PlanSelect
						data={plans}
						value={editingState.planId}
						editingDispatch={editingDispatch}
						required={fieldsSettings.planId.isRequired}
						disabled={saving}
						hasError={editingState.errors.planId}
					/>
				)}
			</div>
		</div>
	);
};
