import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByListId} from '@src/store/modules/pages/checkLists/selectors/records';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formWorkAcceptanceCheckListLink} from '@tehzor/tools/utils/links';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {DiagramIcon} from '../DiagramIcon/DiagramIcon';
import {RecordStatusSelect} from '../RecordStatusSelect';
import {extractProblemsByCheckListIdAsArray} from '@src/store/modules/pages/checkLists/selectors/problems';
import declination from '@tehzor/tools/utils/declination';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {TitleWorkAcceptanceButtons} from '../TitleButtons.mobile';
import {TitleObjectButtons} from '../TitleObjectButtons.mobile';
import {format} from 'date-fns';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import {TaskIndicators} from '@src/pages/CheckListPage/components/TaskIndicators';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';

interface ICheckListProps {
	checkList: ICheckList;
	showDivider?: boolean;
}

// TODO Разнести компоненты по разным файлам
const CheckList = ({checkList, showDivider}: ICheckListProps) => {
	const {pushPath} = useChangePath();
	const routeParams = useStrictParams<{objectId: string, workAcceptanceId: string}>();

	const record = useAppSelector(s => extractCheckRecordByListId(s, checkList.id));
	const problems = useAppSelector(s => extractProblemsByCheckListIdAsArray(s, checkList.id));

	const {changeCheckListRecord} = useChangeCheckRecord(routeParams.objectId, checkList);
	const handleClick = useCallback(() => {
		pushPath(formWorkAcceptanceCheckListLink({...routeParams, listId: checkList.id}));
	}, [checkList.id]);

	return (
		<div
			className="work-acceptance-page__check-lists"
			onClick={handleClick}
		>
			<div
				className="check-list-page__m-list"
				role="presentation"
			>
				<DiagramIcon listId={checkList.id}/>
				<div className="check-list-page__m-list-text">
					<div className="check-list-page__m-list-text-title">{checkList.name}</div>
					<div className="check-list-page__m-list-text-subtitle">
						{record?.status === CheckRecordStatusId.ACCEPTED && record.modifiedAt
							? `принял ${format(record.modifiedAt, dateTimeLetterSeparatedFormat)} ${
									record.modifiedBy?.displayName
							  }`
							: problems.length > 0
							? `${problems.length} ${declination(problems.length, [
									'нарушение',
									'нарушения',
									'нарушений'
							  ])}`
							: null}
					</div>
				</div>
			</div>
			<div className="check-list-page__m-list-indicators">
				<ProblemIndicators checkListId={checkList.id}/>
				<TaskIndicators checkListId={checkList.id}/>
			</div>
			<div className="check-list-page__m-list-status-select">
				<ClickPreventWrap className="check-list-page__d-plate-header-status-wrap">
					<RecordStatusSelect
						item={checkList}
						mutation={changeCheckListRecord}
					/>
				</ClickPreventWrap>
			</div>

			{showDivider && <hr className="check-list-page__divider"/>}
		</div>
	);
};

interface ICheckListsProps {
	checkLists: ICheckList[];
}

export const CheckLists = ({checkLists}: ICheckListsProps) => {
	const {objectId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		workAcceptanceId: string;
	}>();
	const listsLoading = useAppSelector(s => s.dictionaries.checkLists.loading);

	useAppHeader(
		{
			title: 'Чек-листы',
			showBackBtn: true,
			titleButtons: workAcceptanceId ? (
				<TitleWorkAcceptanceButtons
					objectId={objectId}
					workAcceptanceId={workAcceptanceId}
				/>
			) : (
				<TitleObjectButtons objectId={objectId}/>
			)
		},
		[objectId, workAcceptanceId, checkLists]
	);

	return (
		<LoadingPanel active={listsLoading}>
			<Plate
				withoutPadding
				className="check-list-page__m-lists"
			>
				{checkLists.map((checkList, index) => (
					<CheckList
						key={checkList.id}
						checkList={checkList}
						showDivider={index + 1 !== checkLists.length}
					/>
				))}
			</Plate>
		</LoadingPanel>
	);
};
