import {wsConnector} from '../wsConnector';
import ISavingProblemComment from '@tehzor/tools/interfaces/problemComments/ISavingProblemComment';
import {IAddProblemCommentResponse} from '../problemComment';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

/**
 * Добавляет ответ на нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const makeProblemReplyAddRequest = (
	objectId: string,
	problemId: string,
	links: IProblemComment['links'] | undefined,
	fields: ISavingProblemComment
) =>
	wsConnector.sendAuthorizedRequest<IAddProblemCommentResponse>('addProblemReply', {
		objectId,
		problemId,
		links,
		...fields
	});
