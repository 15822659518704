import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {useMemo} from 'react';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import IPermission from '@tehzor/tools/interfaces/IPermission';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {checkRestrictions} from '@tehzor/tools/utils/permissions/restrictions/checkRestrictions';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';

export interface IResponsiblePermissions {
	canEditResponsible?: boolean;
	onlyActiveGroup?: boolean;
}

/**
 * Возвращает полномочие для редактирования ответственных
 *
 * @param objectId id объекта
 * @param entity сущность
 * @param entity тип сущности
 */
export const useResponsiblePermissions = (
	objectId: string,
	entity: IPreparedProblem | IWorkAcceptance,
	type: 'problem' | 'work-acceptance'
): IResponsiblePermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		let canEditResponsible: boolean | undefined;
		let onlyActiveGroup: boolean | undefined;

		let permission: IPermission | undefined;

		if (type === 'problem') {
			permission = findPermission(
				user.roles,
				'problemsResponsibleEdit',
				UserRoleScopes.OBJECT,
				objectId
			);
		}

		if (type === 'work-acceptance') {
			permission = findPermission(
				user.roles,
				'work-acceptances-responsible-edit',
				UserRoleScopes.OBJECT,
				objectId
			);
		}

		if (permission) {
			if (permission.restrictions) {
				const r = {...permission.restrictions} as NonNullable<IPermission['restrictions']>;
				if (r.onlyActiveGroup !== undefined) {
					// Если передано значение, отличное от boolean, то интерпретируем его как false
					onlyActiveGroup = typeof r.onlyActiveGroup === 'boolean' && r.onlyActiveGroup;
					delete r.onlyActiveGroup;
				}
				const param: Record<string, unknown> = {userId: user.id};
				if (type === 'problem') {
					param.problem = entity;
				}

				canEditResponsible = checkRestrictions(r, param);
			} else {
				canEditResponsible = true;
			}
		}
		return {
			canEditResponsible,
			onlyActiveGroup
		};
	}, [objectId, user, entity]);
};
