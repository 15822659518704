import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as objectTypes from '../../entities/object/constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting,
	handleLoadableEntityAdding,
	handleLoadableEntityDeletion,
	handleLoadableEntityEditing,
	ILoadableEntitiesState
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type IObjectsState = ILoadableEntitiesState<IObject>;

const updateStats = (state: IObjectsState, {payload}: {payload: {[id: string]: IObject['stats']}}) => {
	for (let i = Object.keys(payload).length - 1; i >= 0; i--) {
		state.byId[Object.keys(payload)[i]] = {
			...state.byId[Object.keys(payload)[i]],
			stats: payload[Object.keys(payload)[i]]
		};
	}
};

export const objects = createReducer<IObjectsState>(getLoadableEntitiesInitialState<IObject>(), {
	[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IObject>(),
	[types.GET_STATS_SUCCESS]: updateStats,
	[objectTypes.GET_SUCCESS]: (state, {payload}: {payload: IObject}) => ({
		byId: {
			...state.byId,
			[payload.id]: payload
		},
		allIds: state.allIds.includes(payload.id)
			? state.allIds
			: state.allIds.concat([payload.id]),
		loaded: state.loaded,
		loadedAt: state.loadedAt
	}),
	[objectTypes.ADD_SUCCESS]: handleLoadableEntityAdding<IObject>(),
	[objectTypes.EDIT_SUCCESS]: handleLoadableEntityEditing<IObject>(),
	[objectTypes.DELETE_SUCCESS]: handleLoadableEntityDeletion<IObject>(),
	[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IObject>(),
	[CLEAR_BEFORE_UPDATE]: () => getLoadableEntitiesInitialState<IObject>()
});
