import {wsConnector} from '../wsConnector';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';

export type IAddSpaceTypeDecorationSetResponse = ISpaceTypeDecorationSet;

/**
 * Создает набор типов отделки помещения
 *
 * @param fields данные для добавления
 */
export const makeSpaceTypeDecorationSetRequest = (fields: ISavingSpaceTypeDecorationSet) =>
	wsConnector.sendAuthorizedRequest<IAddSpaceTypeDecorationSetResponse>(
		'addSpaceTypeDecorationSet',
		{...fields}
	);
