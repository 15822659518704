import React from 'react';
import {DownloadableFile} from '@tehzor/ui-components';
import IFile from '@tehzor/tools/interfaces/IFile';

interface IDocumentsProps {
	className?: string;
	files: IFile[];
}

const Documents = ({files}: IDocumentsProps) => (
	<>
		{files.map(item => (
			<div
				key={item.id}
				className="inspection-page__doc"
			>
				<DownloadableFile
					className="inspection-page__doc-file"
					{...item}
				/>
			</div>
		))}
	</>
);

export default Documents;
