import {wsConnector} from '../wsConnector';
import {ISavingInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ISavingInternalAcceptance';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

export type IEditInternalAcceptanceResponse = IInternalAcceptance;

/**
 * Изменяет внутреннюю приемку
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приемки
 * @param fields данные внутренней приемки
 */
export const makeInternalAcceptanceEditRequest = (
	objectId: string,
	internalAcceptanceId: string,
	fields: ISavingInternalAcceptance
) =>
	wsConnector.sendAuthorizedRequest<IEditInternalAcceptanceResponse>('editInternalAcceptance', {
		objectId,
		internalAcceptanceId,
		...fields
	});
