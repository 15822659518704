import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IStatsSourcesState, getInitialState} from './reducers';
import ICompanyContractor from '@tehzor/tools/interfaces/companies/ICompanyContractor';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {extractUsersAsArray} from '@src/store/modules/dictionaries/users/selectors';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';

export const extractStatsSources = (state: IState): IStatsSourcesState =>
	state.statistics.sources || getInitialState();

/**
 * Возвращает проверяющих в виде масcива
 */
export const extractStatsInspectorsAsArray = createSelector(
	[extractCompaniesAsArray, extractUsersAsArray],
	(companies, users) => {
		const inspectors = companies.reduce<string[]>((prev, item) => {
			if (item.employees) {
				return prev.concat(item.employees.map(e => e.userId));
			}
			return prev;
		}, []);
		const inspectorsSet = new Set(inspectors);
		return users.filter(user => inspectorsSet.has(user.id));
	}
);

/**
 * Возвращает подрядчиков в виде массива
 */

export const extractStatsContractorsAsArray = createSelector(
	[extractCompaniesAsArray],
	companies => {
		const contractorsSet = new Set<ICompanyContractor>();
		companies.forEach(company => {
			if (company.contractors) {
				company.contractors.forEach(el => {
					const contractor = companies.find(item => item.id === el.subCompanyId);
					if (contractor) {
						contractorsSet.add(contractor);
					}
				});
			}
		});
		const contractors = [...contractorsSet];
		return contractors;
	}
);

/**
 * Возвращает подрядчиков в виде объекта {byId, allIds}
 */
export const extractStatsContractors = createSelector(
	[extractStatsContractorsAsArray],
	contractors =>
		contractors
			.sort((a, b) => (a.name > b.name ? 1 : -1))
			.reduce<INormalizedData<ICompanyContractor>>(
				(prev, item) => {
					prev.allIds.push(item.id);
					prev.byId[item.id] = item;
					return prev;
				},
				{
					byId: {},
					allIds: []
				}
			)
);

/**
 * Возвращает виды работ в виде массива
 */
export const extractStatsCategoriesAsArray = createSelector([extractStatsSources], sources =>
	sources.categories.data.allIds.map(id => sources.categories.data.byId[id]));
