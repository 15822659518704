import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';
import {extractProblemTagsAsArray} from '@src/store/modules/dictionaries/problemTags/selectors';

/**
 * Преобразовывает набор меток в необходимый для вывода формат
 *
 * @param data массив наборов меток
 */
export const useEnrichedProblemTagsSets = (data: IProblemTagsSet[]): IEnrichedProblemTagsSet[] => {
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const companiesMap = useAppSelector(s => s.dictionaries.companies.byId);
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);
	const tagsArray = useAppSelector(extractProblemTagsAsArray);
	return useMemo(
		() =>
			data.map(item => ({
				...item,
				company: companiesMap[item.companyId],
				objects: item.objects,
				allObjectsAvailable: item.allObjectsAvailable,
				stages: item.stages,
				tagsCount: tagsArray.filter(tag => tag.problemTagsSetId === item.id).length,
				createdBy: item.createdBy,
				createdAt: item.createdAt
					? format(item.createdAt, dateTimeCommaSeparatedFormat)
					: '',
				modifiedBy: item.modifiedBy,
				modifiedAt: item.modifiedAt
					? format(item.modifiedAt, dateTimeCommaSeparatedFormat)
					: ''
			})),
		[data, usersMap, companiesMap, stagesMap]
	);
};
