import React from 'react';
import {Dialog} from '@tehzor/ui-components';
import {Settings} from './Settings';

interface ISettingsDialogProps {
	isOpen: boolean;

	onClose?: () => void;
}

export const SettingsDialog = ({isOpen, onClose}: ISettingsDialogProps) => (
	<Dialog
		className="spaces-page__settings-dialog"
		title="Настройки отображения"
		fullScreenOnMobile
		isOpen={isOpen}
		onRequestClose={onClose}
	>
		<Settings/>
	</Dialog>
);
