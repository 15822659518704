import IUser from '@tehzor/tools/interfaces/IUser';
import {getPermissionsForClaim} from './getPermissionsForClaim';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {IPreparedClaim} from '../interfaces/IPreparedClaim';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IWarrantyClaimStatus} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';

/**
 * Преобразовывает обращения в необходимый для вывода формат
 *
 * @param claims массив обращений
 * @param statusesMap статусы обращений
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 */
export const convertClaims = (
	claims: IListWarrantyClaim[],
	statusesMap: Record<string, IWarrantyClaimStatus>,
	networkStatus: boolean,
	user?: IUser
): IPreparedClaim[] =>
	claims.map(item => ({
		id: item.id,
		publicId: item.publicId,
		object: item.object,
		links: item.links,
		number: item.number,
		localNumber: item.localNumber,
		transferStatus: item.transferStatus,
		status: statusesMap[item.status]?.name || '',
		spaceOwner: item.spaceOwner,
		replyEmail: item.replyEmail,
		replyPhone: item.replyPhone,
		claimerName: item.claimerName,
		createdAt: item.createdAt
			? format(new Date(item.createdAt), dateTimeCommaSeparatedFormat)
			: '',
		modifiedAt: item.modifiedAt
			? format(new Date(item.modifiedAt), dateTimeCommaSeparatedFormat)
			: '',
		...getPermissionsForClaim(item, networkStatus, item.object?.id, user)
	}));
