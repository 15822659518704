import {ITasksSettingsMode} from '../interfaces';

export const generateWeek = (): [number, number] => {
  const currentDate = new Date();

  const currentDay = currentDate.getDate();

  const maxDaysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

  const daysOnNextWeek = currentDay + 7;

  if (daysOnNextWeek > maxDaysInMonth) {
    return [currentDay, daysOnNextWeek - maxDaysInMonth];
  }

  return [currentDay, daysOnNextWeek];
};

export const generateModeData = (): Array<{id: ITasksSettingsMode, name: string}> => [
  {id: 'year', name: 'Год'},
  {id: 'month', name: 'Месяц'}
  // Todo: раскомментировать, при создании графика по неделе, дню
  // {id: 'week', name: 'Неделя'},
  // {id: 'day', name: 'День'}
];