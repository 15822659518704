import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialState} from '@src/store/modules/pages/checkLists/reducers/problems';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {Tree} from 'array-to-tree';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {extractTreeCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

const getTreeItemIds = (item: Tree<ICheckItem>): string[] => {
	const subIds = [];

	if (item.children) {
		subIds.push(...item.children.map(getTreeItemIds).flat(Infinity));
	}

	return Array.from(new Set([item.id, ...subIds])) as string[];
};

/**
 * Возвращает нарушения для конкретного объекта
 */
export const extractProblemsData = createSelector(
	(state: IState) => state.pages.checkLists.problems,
	data => data || getInitialState()
);

/**
 * Возвращает локальные нарушения
 */
export const extractLocalProblemsData = createSelector(
	(state: IState) => state.localEntities.problems,
	data => data || getInitialState()
);

/**
 * Возвращает нарушения в виде массива
 */
export const extractProblemsByItemIdAsArray = createSelector(
	[
		extractProblemsData,
		(state: IState, params: {itemId: string, objectId: string, spaceId?: string}) => params
	],
	(data, {objectId, spaceId, itemId}) =>
		data.allIds.reduce((acc: IProblem[], id) => {
			const problem = data.byId[id];

			if (problem?.links?.checkItemId !== itemId || problem?.objectId !== objectId) {
				return acc;
			}

			if (
				// если указан идентификатор помещения и он совпадает с нарушением
				(spaceId && problem?.links?.spaceId === spaceId)
				// или не указан совсем
				|| (!spaceId && !problem?.links?.spaceId)
			) {
				// добавляем нарушение к массиву
				return acc.concat(problem);
			}

			return acc;
		}, [])
);

/**
 * Возвращает нарушения в виде массива согласно массиву категорий
 */
export const extractProblemsByItemsIdsArrayAsArray = createSelector(
	[extractProblemsData, (state: IState, checkItemIds: string[]) => checkItemIds],
	(data, checkItemIds) =>
		data.allIds.reduce((acc: IProblem[], id) => {
			const problem = data.byId[id];

			if (problem?.links?.checkItemId && checkItemIds.includes(problem.links.checkItemId)) {
				acc.push(problem);
			}

			return acc;
		}, [])
);

/**
 * Возвращает нарушения всех вложенных подкатегорий в виде массива
 */
export const extractProblemsAndSubProblemsByItemIdAsArray = createSelector(
	[
		extractProblemsData,
		(state: IState) => state,
		(state: IState, listId: string) => listId,
		(state: IState, listId: string, itemId: string) => itemId
	],
	(data, state, listId, itemId) => {
		const item = extractTreeCheckItemById(state, listId, itemId);
		const array = item ? getTreeItemIds(item) : [];
		return data.allIds.reduce((acc: IProblem[], id) => {
			const problem = data.byId[id];

			if (problem?.links?.checkItemId && array.includes(problem?.links?.checkItemId)) {
				acc.push(problem);
			}

			return acc;
		}, []);
	}
);

/**
 * Возвращает локальные нарушения всех вложенных подкатегорий в виде массива
 */
export const extractLocalProblemsAndSubProblemsByItemIdAsArray = createSelector(
	[
		extractLocalProblemsData,
		(state: IState) => state,
		(state: IState, listId: string) => listId,
		(state: IState, listId: string, itemId: string) => itemId,
		(state: IState, listId: string, itemId: string, spaceId: string | undefined) => spaceId
	],
	(data, state, listId, itemId, spaceId) => {
		const item = extractTreeCheckItemById(state, listId, itemId);
		const array = item ? getTreeItemIds(item) : [];
		return data
			.filter(problem => {
				if (
					problem?.links?.checkItemId
					&& array.includes(problem?.links?.checkItemId)
					&& problem?.links?.spaceId === spaceId
				) {
					return true;
				}
				return false;
			})
			.map(local => ({id: local.key, ...local}));
	}
);

/**
 * Возвращает нарушения для чек-листа в виде массива
 */
export const extractProblemsByCheckListIdAsArray = createSelector(
	[extractProblemsData, (state: IState, checkListId: string) => checkListId],
	(data, checkListId) =>
		data.allIds.reduce((acc: IProblem[], id) => {
			const problem = data.byId[id];

			if (problem?.links?.checkListId === checkListId) {
				acc.push(problem);
			}

			return acc;
		}, [])
);

export const extractProblemsEntitiesByList = createSelector(
	[extractProblemsByCheckListIdAsArray],
	problems => problems.map(problem => ({id: problem.id, type: 'problem', data: problem}))
);

export const extractProblemsEntitiesByItem = createSelector(
	[
		extractProblemsAndSubProblemsByItemIdAsArray,
		extractLocalProblemsAndSubProblemsByItemIdAsArray
	],
	(problems, localProblems) => [
		...problems.map(problem => ({id: problem.id, type: 'problem', data: problem})),
		...localProblems.map(lproblem => ({
			id: lproblem.key,
			type: 'local_problem',
			data: lproblem
		}))
	]
);

export const extractProblemsCountByStatus = createSelector(
	[
		(s: IState) => s,
		(s: IState, listId: string) => listId,
		(s: IState, listId: string, itemId?: string) => itemId
	],
	(state, listId, itemId) => {
		const problems = itemId
			? extractProblemsAndSubProblemsByItemIdAsArray(state, listId, itemId)
			: extractProblemsByCheckListIdAsArray(state, listId);
		const result: Record<string, number> = {};

		const statusCountMap = problems.reduce<Record<string, number>>((acc, problem) => {
			if (!acc[problem.status]) {
				acc[problem.status] = 0;
			}

			acc[problem.status] += 1;

			return acc;
		}, {} as any);

		const sortedKeys = Object.keys(statusCountMap).sort();

		for (const key of sortedKeys) {
			result[key] = statusCountMap[key];
		}

		return result as Record<ProblemStatusId, number>;
	}
);
