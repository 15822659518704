import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';

interface IDescriptionProps {
	className?: string;
	value: string;
}

const Description = ({className, value}: IDescriptionProps) => (
	<EntityGridItem
		className={className}
		icon={<i className="tz-description-20"/>}
		label="Описание"
		fullRow
	>
		<div style={{whiteSpace: 'pre-wrap'}}>{value}</div>
	</EntityGridItem>
);

export default Description;
