import React from 'react';
import './ProblemsBarChart.less';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import classNames from 'classnames';
import Bar from './components/Bar';

interface IProblemStatsBarProps {
	className?: string;
	style?: React.CSSProperties;
	statuses: IProblemStatus[];
	counts: Record<ProblemStatusId, number | undefined>;
	totalCount?: number;
}

const ProblemsBarChart = (props: IProblemStatsBarProps) => {
	const {className, style, statuses, counts, totalCount} = props;
	const total = totalCount ?? Object.values(counts).reduce<number>((sum, num) => sum + (num ?? 0), 0);

	return (
		<div
			className={classNames('problems-bar-chart', className)}
			style={style}
		>
			<div className="problems-bar-chart__total">
				{statuses.map(status => (
					<Bar
						key={status.id}
						status={status}
						value={counts[status.id]}
						total={total}
					/>
				))}
			</div>
		</div>
	);
};

ProblemsBarChart.displayName = 'ProblemsBarChart';

export default ProblemsBarChart;
