import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {requestWarrantyClaims} from '@src/api/backend/warrantyClaims';

export type IGetSpaceWarrantyClaimsPayload = INormalizedData<IListWarrantyClaim>;

const request = () => ({type: types.GET_WARRANTY_CLAIMS_REQUEST});

const success = (response: IGetSpaceWarrantyClaimsPayload) => ({
	type: types.GET_WARRANTY_CLAIMS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке обращений');
	return {
		type: types.GET_WARRANTY_CLAIMS_FAILURE,
		payload: error
	};
};

/**
 * Получает список гарантийных обращений для конкретного помещения
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 */
export const getSpaceWarrantyClaims = (objectId: string, spaceId: string) =>
	checkExpiration<IState, IGetSpaceWarrantyClaimsPayload, ApiAction>(
		s => s.entities.space.warrantyClaims,
		createApiAction<IGetSpaceWarrantyClaimsPayload>(request, success, failure, () =>
			requestWarrantyClaims(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);
