import React from 'react';
import './CheckListInfoMobileCell.less';
import {CellProps} from 'react-table';
import {EntityInfo} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import {IPreparedCheckList} from '@src/pages/manage/CheckListsPage/interfaces/IPreparedCheckList';
import {MenuCell} from '@src/pages/manage/CheckListsPage/components/table/MenuCell';

const CheckListInfoMobileCell = (props: CellProps<IPreparedCheckList>) => {
	const {row} = props;
	const checkList = row.original;
	return (
		<div className="m-checkList-info-cell">
			<div className="m-checkList-info-cell__wrapper">
				<div className="m-checkList-info-cell__view">
					<EntityInfo
						backgroundColor="#E8EAEC"
						icon={<i className="tz-check-list"/>}
					/>
				</div>
				<div className="m-checkList-info-cell__text">
					<div className="m-checkList-info-cell__title">{checkList.name}</div>
					<div className="m-checkList-info-cell__date">
						{checkList.modifiedAt}
						<div className="m-checkList-info-cell__name">{checkList.modifiedBy}</div>
					</div>
				</div>
			</div>

			<ClickPreventWrap style={{marginLeft: 'auto', padding: '0 25px'}}>
				{/* <TableRowSelect {...row.getToggleRowSelectedProps()} style={{marginTop: '25px'}} /> */}

				<MenuCell {...props}/>
			</ClickPreventWrap>
		</div>
	);
};

export default CheckListInfoMobileCell;
