import React, {useState} from 'react';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {MobileSelectionClearing} from './selection/SelectionClearing.mobile';
import {MobileRightButtons} from './actions/RightButtons.mobile';
import {MobileInfo} from './info/Info.mobile';
import {Plate} from '@tehzor/ui-components';
import {ListDetail, MobileItemDetail} from './detail';
import {WorkAcceptancesTable} from './workAcceptances/Table';
import {Table} from './table/Table';
import {mobileColumns} from './table/columns.mobile';
import {EntityAdding} from './actions/EntityAdding';
import {EntitiesVisibility} from './actions/EntitiesVisibility';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {Outlet, Route, Routes} from 'react-router-dom';
import {CheckLists} from './list';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {workAcceptanceMobileColumns} from './workAcceptances/columns.mobile';

interface IMobileProps {
	objectId: string;
	structureId: string;
	structure?: IStructure;
	checkLists: ICheckList[];
	workAcceptances?: IListWorkAcceptance[];
}

export const Mobile = ({
	objectId,
	structureId,
	structure,
	checkLists,
	workAcceptances
}: IMobileProps) => {
	const sectionsMenu = [
		{
			url: `/objects/${objectId}/structures/${structureId}`,
			label: 'Нарушения'
		},
		{
			url: `/objects/${objectId}/structures/${structureId}/check-lists`,
			label: 'Чек-листы'
		},
		{
			url: `/objects/${objectId}/structures/${structureId}/work-acceptances`,
			label: 'Приёмки работ'
		}
	];
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	useAppHeader(
		{
			title: structure?.name,
			showBackBtn: true,
			sectionsMenu,
			mobileLeftButton:
				selectedRows.length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows}/>
				) : undefined,
			mobileRightButtons: structure ? (
				<MobileRightButtons
					objectId={objectId}
					structure={structure}
					selectedRows={selectedRows}
				/>
			) : null
		},
		[structure, selectedRows]
	);

	return structure ? (
		<div className="page-cont structure-page">
			<PageBreadcrumbs
				objectId={objectId}
				structureId={structureId}
			/>
			<MobileInfo structure={structure}/>
			<div className="page-cont check-list-page check-list-page__m-container">
				<Routes>
					<Route path="check-lists">
						<Route
							path=":listId"
							element={<ListDetail structureType={structure.type}/>}
						/>

						<Route
							path=":listId/:pathParam?/:itemId?"
							element={<MobileItemDetail/>}
						/>

						<Route
							path=""
							element={<CheckLists checkLists={checkLists}/>}
						/>
					</Route>
				</Routes>
			</div>
			<Routes>
				<Route
					path="work-acceptances"
					element={(
						<div className="structure-page__d-column structure-page__d-column_entities">
							<Plate withoutPadding>
								<WorkAcceptancesTable
									objectId={objectId}
									headVisible={false}
									columns={workAcceptanceMobileColumns}
									workAcceptances={workAcceptances}
								/>
							</Plate>
						</div>
					)}
				/>
				<Route
					path=""
					element={(
						<>
							<EntitiesVisibility className="structure-page__m-visibility-filter"/>

							<Plate withoutPadding>
								<Table
									objectId={objectId}
									columns={mobileColumns}
									hideHead
									structureId={structureId}
									selectedRows={selectedRows}
									onSelectedRowsChange={setSelectedRows}
								/>
							</Plate>

							<EntityAdding
								objectId={objectId}
								structureId={structureId}
							/>
						</>
					)}
				/>
			</Routes>
			<Outlet/>
		</div>
	) : null;
};
