import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IAddProblemCommentResponse,
	makeProblemCommentAddRequest
} from '@src/api/backend/problemComment';
import ISavingProblemComment from '@tehzor/tools/interfaces/problemComments/ISavingProblemComment';
import {addErrorToast} from '@src/utils/toasts';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

export type IAddProblemCommentPayload = IAddProblemCommentResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddProblemCommentResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении комментария');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет комментарий к нарушению
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const addProblemComment = (
	objectId: string,
	problemId: string,
	links: IProblemComment['links'] | undefined,
	fields: ISavingProblemComment
) =>
	createApiAction<IAddProblemCommentResponse>(request, success, failure, () =>
		makeProblemCommentAddRequest(objectId, problemId, links, fields));
