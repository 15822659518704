import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {ISavingOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ISavingOwnerAcceptance';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';

export type IEditableOwnerAcceptanceState = IEditableEntityState<{
	resolution?: OwnerAcceptanceResolutionId;
	spaceOwnerId?: string | null;
	attachments: IAttachment[];
}>;

export type IEditableOwnerAcceptanceAction = IEditableEntityAction<
	IEditableOwnerAcceptanceState,
	IOwnerAcceptance
>;

const makeEmptyState = (): IEditableOwnerAcceptanceState => ({
	resolution: undefined,
	spaceOwnerId: undefined,
	attachments: [],
	errors: {
		resolution: false,
		spaceOwnerId: false,
		attachments: false
	}
});

export const init = (acceptance?: IOwnerAcceptance): IEditableOwnerAcceptanceState => {
	const empty = makeEmptyState();
	return acceptance
		? {
				resolution: acceptance.resolution || undefined,
				spaceOwnerId: acceptance.spaceOwner?.id || undefined,
				attachments: acceptance.attachments || empty.attachments,
				errors: empty.errors
		  }
		: empty;
};

const isResolutionEdited = (state: IEditableOwnerAcceptanceState, original?: IOwnerAcceptance) =>
	(original?.resolution ? original.resolution !== state.resolution : !!state.resolution);

const isSpaceOwnerEdited = (state: IEditableOwnerAcceptanceState, original?: IOwnerAcceptance) =>
	(original?.spaceOwner ? original.spaceOwner.id !== state.spaceOwnerId : !!state.spaceOwnerId);

const areAttachmentsEdited = (state: IEditableOwnerAcceptanceState, original?: IOwnerAcceptance) =>
	(original?.attachments ? original.attachments.length !== state.attachments.length : false);

/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (
	state: IEditableOwnerAcceptanceState,
	original?: IOwnerAcceptance
): boolean =>
	isEntityEdited(state, original, isResolutionEdited, isSpaceOwnerEdited, areAttachmentsEdited);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	resolution: (state: IEditableOwnerAcceptanceState) => !state.resolution,
	spaceOwnerId: (state: IEditableOwnerAcceptanceState) => !state.spaceOwnerId,
	attachments: (state: IEditableOwnerAcceptanceState) => !state.attachments.length
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableOwnerAcceptanceState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableOwnerAcceptanceState,
	original?: IOwnerAcceptance,
	onlyEdited?: boolean
): ISavingOwnerAcceptance => {
	if (!onlyEdited) {
		return {
			resolution: state.resolution,
			spaceOwnerId: state.spaceOwnerId,
			attachments: state.attachments
		};
	}
	const acceptance: ISavingOwnerAcceptance = {};
	if (isResolutionEdited(state, original)) {
		acceptance.resolution = state.resolution;
	}
	if (isSpaceOwnerEdited(state, original)) {
		acceptance.spaceOwnerId = state.spaceOwnerId;
	}
	if (areAttachmentsEdited(state, original)) {
		acceptance.attachments = state.attachments.map(item => ({id: item.id}));
	}
	return acceptance;
};
