import React from 'react';
import {IconButton, Dialog, Breadcrumbs} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {EntityLinks} from '@src/components/EntityLinks/EntityLinks';
import {useWorkAcceptanceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useWorkAcceptanceBreadcrumbs';

interface ITitleButtonsProps {
	objectId: string;
	workAcceptanceId: string;
}

export const TitleWorkAcceptanceButtons = ({objectId, workAcceptanceId}: ITitleButtonsProps) => {
	const {pushPath} = useChangePath();
	const breadcrumbs = useWorkAcceptanceBreadcrumbs(objectId, workAcceptanceId, undefined);
	const [isOpen, toggle] = useToggle(false);

	return (
		<>
			<IconButton
				type="inline"
				onClick={toggle}
			>
				<i className="tz-simple-arrow-16"/>
			</IconButton>
			<Dialog
				onRequestClose={toggle}
				isOpen={isOpen}
			>
				<div className="check-list-page__links-dialog-breadcrumbs">
					<Breadcrumbs
						items={breadcrumbs}
						onNavigate={pushPath}
					/>
				</div>
				<EntityLinks links={{workAcceptanceId, objectId}}/>
			</Dialog>
		</>
	);
};
