import React, {forwardRef, Ref} from 'react';
import './StatusButton.less';
import ButtonBase, {IButtonBaseProps} from '../ButtonBase';
import classNames from 'classnames';
import {isBrightColor} from '../../../utils/isBrightColor';
import {useIsTablet} from '../../../utils/mediaQueries';

interface IProblemStatusButtonProps
	extends Pick<
		IButtonBaseProps,
		'className' | 'style' | 'label' | 'disabled' | 'outerTagProps' | 'onClick' | 'onMouseEnter' | 'onMouseLeave'
	> {
	color?: string;
	arrowDirection?: boolean;
}

const StatusButton = (props: IProblemStatusButtonProps, ref?: Ref<HTMLButtonElement>) => {
	const {className, style, color = '#C4D1E3', arrowDirection, ...rest} = props;

	const isTablet = useIsTablet();
	const arrowVisible = isTablet && arrowDirection !== undefined;

	return (
		<ButtonBase
			{...rest}
			className={classNames(isBrightColor(color, 0.8) ? 'status-button_dark' : 'status-button_light', className)}
			style={{
				...style,
				backgroundColor: color
			}}
			classNamePrefix="status-button"
			rightIconClassName={
				arrowVisible
					? classNames('status-button__arrow-icon', {'status-button__arrow-icon_reversed': arrowDirection})
					: undefined
			}
			rightIcon={arrowVisible ? <i className="tz-simple-arrow-16"/> : undefined}
			outerTagType="button"
			ref={ref}
		/>
	);
};

StatusButton.displayName = 'StatusButton';

export default forwardRef(StatusButton);
