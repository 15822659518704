import {wsConnector} from '../wsConnector';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export type IEditWorkingGroupResponse = IWorkingGroup;

/**
 * Изменяет рабочую группу
 *
 * @param groupId id рабочей группы
 * @param fields данные рабочей группы
 */
export const makeWorkingGroupEditRequest = (groupId: string, fields: ISavingWorkingGroup) =>
	wsConnector.sendAuthorizedRequest<IEditWorkingGroupResponse>('editWorkingGroup', {
		groupId,
		...fields
	});
