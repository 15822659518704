import {queryClient} from '@src/api/QueryClient';
import {Query} from '@tanstack/react-query';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {deleteLocalProblem} from './deleteLocalProblem';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

export const deleteLinkedLocalProblems = async (
	linkName: keyof NonNullable<IProblem['links']>,
	parentId: string
) => {
	const localProblemQueries = queryClient.getQueriesData<IProblem>({
		queryKey: problemsQueryKeys.details(),
		predicate: (current: Query<IProblem>) =>
			!!current.state.data?.links && current.state.data?.links[linkName] === parentId
	});
	const localProblems = localProblemQueries.map(([, problem]) => problem) as IProblem[];
	if (localProblems.length) {
		for (const p of localProblems) {
			await deleteLocalProblem(p.id);
		}
	}
};
