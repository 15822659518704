import React from 'react';
import {DesktopMenu} from './Menu.desktop';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {EntitiesVisibility} from './EntitiesVisibility';

interface IDesktopActionsProps {
	structure: IStructure;
}

export const DesktopActions = ({structure}: IDesktopActionsProps) => (
	<div className="structure-page__d-controls">
		<div className="structure-page__d-controls-left">
			<EntitiesVisibility/>
		</div>

		<div className="check-page__d-controls-right">
			<DesktopMenu structure={structure}/>
		</div>
	</div>
);
