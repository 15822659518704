import React from 'react';
import './PaginationAndSize.less';
import classNames from 'classnames';

interface IPaginationAndSizeProps {
	className?: string;
	style?: React.CSSProperties;
	pagination?: React.ReactNode;
	pageSize?: React.ReactNode;
}

export const PaginationAndSize = ({className, style, pagination, pageSize}: IPaginationAndSizeProps) => (
	<div
		className={classNames('pagination-and-size', className)}
		style={style}
	>
		<div/>
		<div className="pagination-and-size__pagination">{pagination}</div>
		<div className="pagination-and-size__page-size">{pageSize}</div>
	</div>
);