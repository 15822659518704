import React from 'react';
import './Toggle.less';
import SwitchBase from '../../SwitchBase';
import {IToggleIconProps, ToggleIcon} from '../ToggleIcon';
import {convertClassNames} from '../../../../utils/convertClassNames';
import classNames from 'classnames';
import {ToggleGroupContext} from '../ToggleGroup';

interface IToggleProps {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				icon?: string;
				content?: string;
		  };
	style?: React.CSSProperties;
	children?: React.ReactNode;
	id?: string;
	name?: string;
	value?: string;
	checked?: boolean;
	disabled?: boolean;
	icon?: React.ReactElement<IToggleIconProps>;

	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Toggle = ({className, icon, ...rest}: IToggleProps) => {
	const classes = convertClassNames(className);

	const switchIcon = icon || <ToggleIcon/>;

	return (
		<SwitchBase
			{...rest}
			className={{
				root: classNames('toggle', classes.root),
				wrap: classNames('toggle__wrap', classes.wrap),
				icon: classes.icon,
				content: classNames('toggle__content', classes.content)
			}}
			type="checkbox"
			switchIcon={switchIcon}
			GroupContext={ToggleGroupContext}
		/>
	);
};