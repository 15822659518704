import React, {useCallback} from 'react';
import {useWorkAcceptanceCombinedExport} from '../../hooks/useWorkAcceptanceCombinedExport';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {IconButton} from '@tehzor/ui-components';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface ISelectedEntitiesExportProps {
	objectId: string;
	workAcceptanceId: string;
	checkLists: ICheckList[];
	selectedEntities: IWorkAcceptanceEntity[];
}

const exportIcon = <i className="tz-export-24"/>;

export const SelectedEntitiesExport = ({
	objectId, workAcceptanceId, checkLists, selectedEntities
}: ISelectedEntitiesExportProps) => {
	const [exportDialog, openExportDialog] = useWorkAcceptanceCombinedExport(
		selectedEntities, objectId, checkLists
	);

	const handleOpen = useCallback(() => {
		openExportDialog(workAcceptanceId);
	}, [workAcceptanceId]);

	return (
		<>
			<IconButton onClick={handleOpen}>
				{exportIcon}
			</IconButton>

			{exportDialog}
		</>
	);
};