import React, {forwardRef, Ref, useCallback, useImperativeHandle, useState} from 'react';
import {SearchSelect, useLocalSearchProvider} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {bindSpaceToOwners} from '@src/store/modules/entities/spaceOwners/actions';
import {extractSpaceOwnersForCompany} from '@src/store/modules/dictionaries/spaceOwners/selectors';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {renderItem} from '../utils/renderItem';

interface IOwnersBindingProps {
	companyId: string;
	spaceId: string;
	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IOwnersBindingRefProps {
	save: () => Promise<void>;
	cancel: () => void;
}

const OwnersBinding = (props: IOwnersBindingProps, ref?: Ref<IOwnersBindingRefProps>) => {
	const {companyId, spaceId, onClose, setSaving} = props;
	const owners = useAppSelector(s => extractSpaceOwnersForCompany(s, companyId));
	const dispatch = useAppDispatch();
	const [selected, setSelected] = useState<ISpaceOwner[]>();

	const save = useCallback(async () => {
		if (selected) {
			setSaving(true);
			try {
				await dispatch(
					bindSpaceToOwners(
						selected.map(owner => owner.id),
						spaceId
					)
				);
				onClose();
			} finally {
				setSaving(false);
			}
		}
	}, [dispatch, onClose, selected, setSaving, spaceId]);

	useImperativeHandle(ref, () => ({save, cancel: onClose}), [onClose, save]);

	const provider = useLocalSearchProvider({
		initialList: owners,
		keys: ['name']
	});

	return (
		<SearchSelect
			className={{
				root: 'adding-space-owner-dialog__owners-container',
				select: 'adding-space-owner-dialog__owners-container-select'
			}}
			provider={provider}
			multiple
			renderItem={renderItem}
			onSelect={setSelected}
		/>
	);
};

export default forwardRef<IOwnersBindingRefProps, IOwnersBindingProps>(OwnersBinding);
