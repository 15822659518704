import React from 'react';
import './InspectionHistoryDialog.less';
import {Dialog, HistoryList} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {useEnrichedHistories} from './hooks/useEnrichedHistories';
import {getInspectionStories} from '@src/store/modules/pages/inspection/actions';

interface IInspectionHistoryDialogProps {
	objectId: string;
	inspectionId: string;
	isOpen: boolean;
	onClose: () => void;
}

export const InspectionHistoryDialog = (props: IInspectionHistoryDialogProps) => {
	const {objectId, inspectionId, isOpen, onClose} = props;
	const dispatch = useAppDispatch();
	const stories = useEnrichedHistories(objectId);

	useAsync(async () => {
		if (isOpen) {
			await dispatch(getInspectionStories(objectId, inspectionId));
		}
	}, [isOpen, objectId, inspectionId]);

	return (
		<Dialog
			className={{
				root: 'inspection-history-dialog',
				content: 'inspection-history-dialog__content'
			}}
			isOpen={isOpen}
			title="История изменений"
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<HistoryList
				entityName="осмотр"
				data={stories}
			/>
		</Dialog>
	);
};
