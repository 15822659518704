import React from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {deleteTask} from '@src/store/modules/pages/task/actions';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IEnrichedTask[];
	selectedClearing: () => void;
}

const SelectedEntitiesDeletion = ({
									  selectedEntities,
									  selectedClearing
								  }: ISelectedEntitiesDeletionProps) => {
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить задачи',
		'Вы действительно хотите удалить выбранные задачи?',
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			for (const task of selectedEntities) {
				await dispatch(deleteTask(task.id));
			}
			selectedClearing();
		}
	}, [selectedEntities, selectedClearing]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete"/>
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
