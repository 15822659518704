import React, {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';

type ICustomSelectionRowProps = ISelectionRowProps<IEnrichedStructure>;

export const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {toggleAllRowsSelected} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};
