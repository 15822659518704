import React, {useCallback, useRef, useState, useEffect, useMemo} from 'react';
import './DelegationDialog.less';
import {
	ActionButtons,
	Button,
	DelegationTree,
	Dialog,
	IconButton,
	SelectSearch
} from '@tehzor/ui-components';
import {getSuggestionsByRules, ISuggestions} from './utils/getSuggestionsByRules';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {extractFilteredResponsibilityRules} from '@src/store/modules/dictionaries/responsibilityRules/selectors';
import {useEnrichedGroups} from './hooks/useEnrichedGroups';
import {extractWorkingGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {useAddingRespUserDialog} from '@src/components/AddingRespUserDialog';
import {useViewButtons} from '@src/components/DelegationDialog/hooks/useViewButtons';
import {
	formAllExpandedGroups,
	formInitialExpandedGroups
} from '@tehzor/ui-components/src/components/delegation/DelegationTree/utils/formInitialExpandedGroups';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import arrayToTree from 'array-to-tree';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import debounce from 'lodash/debounce';

interface IDelegationDialogProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	categoryId?: string | null;
	planId?: string;
	structureIds?: string[];
	activeGroup?: string;
	selectedUsers: string[];
	selectedGroup?: string;
	title?: string;
	onlyInitialLevelSelectable?: boolean;
	autoApplySuggestions?: boolean;
	isOpen: boolean;
	workingGroupType?: WorkingGroupTypeId;
	onChange: (users: string[], group: string | undefined) => void;
	onClose: () => void;
}

/**
 * Модальное окно делегирования / выбора ответственных
 */

const addUserIcon = <i className="tz-add-user-24" />

export const DelegationDialog = (props: IDelegationDialogProps) => {
	const {
		objectId,
		stage,
		scope,
		categoryId,
		planId,
		structureIds,
		activeGroup,
		selectedUsers,
		selectedGroup,
		title = 'Делегирование',
		onlyInitialLevelSelectable,
		autoApplySuggestions,
		isOpen,
		workingGroupType,
		onChange,
		onClose
	} = props;

	// Локальные переменные, которые выводятся в Tree
	const [localSelectedUsers, setLocalSelectedUsers] = useState(selectedUsers);
	const [localSelectedGroup, setLocalSelectedGroup] = useState(selectedGroup);

	// Синхронизация локальных переменных с пропсами part1
	useUpdateEffect(() => setLocalSelectedUsers(selectedUsers), [selectedUsers]);
	useUpdateEffect(() => setLocalSelectedGroup(selectedGroup), [selectedGroup]);

	// Набор данных для фильтра "Отобранные по правилам"
	const [suggestions, setSuggestions] = useState<ISuggestions>({});

	// Кнопки для фильтрации списка ответственных + логика фильтров внутри хука
	const isWorkingGroupTypeFilterAvailable = workingGroupType === undefined;
	const [viewButtons, isSuggestionsFilterActive, filteredWorkingGroupType] = useViewButtons(
		suggestions,
		isWorkingGroupTypeFilterAvailable
	);

	// Поиск по ответственным
	const [searchValue, setSearchValueWrapper] = useState('');
	const setSearchValue = useMemo(() => debounce(setSearchValueWrapper, 300), []);
	useUpdateEffect(() => {
		// Сбрасываем выбранных ответственных во избежание сайд эффекта (улучшение UX),
		// потому что в списке выбранных ранее элементы могут отсутсвовать после фильтрации
		if (searchValue) {
			setLocalSelectedUsers([]);
			setLocalSelectedGroup(undefined);
			return;
		}

		// При сбросе поисковой строки, если ничего не выбрано, возвращаем предыдущее значение (улучшение UX)
		if (!searchValue && !localSelectedUsers.length && !localSelectedGroup) {
			setLocalSelectedUsers(selectedUsers);
			setLocalSelectedGroup(selectedGroup);
		}
	}, [searchValue]);
	const clearSearchValue = useCallback(() => setSearchValue(''), [setSearchValue]);

	// Все рабочие группы из справочника
	const workingGroups = useAppSelector(extractWorkingGroupsAsArray);

	// Отфильтрованные рабочие группы для диалога
	const groups = useMemo(() => {
		const type = isWorkingGroupTypeFilterAvailable
			? workingGroupType ?? filteredWorkingGroupType
			: workingGroupType;

		const filteredWorkingGroups =
			stage === undefined && scope === undefined
				? workingGroups.filter(
						g =>
							(!g.objects?.length || g.objects.includes(objectId)) &&
							(!type || g.type === type)
				  )
				: workingGroups.filter(
						group =>
							(!group.objects?.length || group.objects.includes(objectId)) &&
							(!group.stages || (stage && group.stages.includes(stage))) &&
							(!group.scope || group.scope === scope) &&
							(!type || group.type === type)
				  );

		const tree = arrayToTree<IWorkingGroup>(filteredWorkingGroups, {
			parentProperty: 'parentId',
			customID: 'id'
		});
		const found = activeGroup ? findTreeNode(tree, activeGroup) : undefined;

		return found ? [found] : tree;
	}, [
		activeGroup,
		filteredWorkingGroupType,
		isWorkingGroupTypeFilterAvailable,
		objectId,
		scope,
		stage,
		workingGroupType,
		workingGroups
	]);

	// Отфильтрованные правила ответственности из справочника
	const rules = useAppSelector(s =>
		extractFilteredResponsibilityRules(s, objectId, stage, scope)
	);

	// Расскрытые елементы в Tree
	const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

	// Фича автоcелекта ответственного (rule.autoSelect), part1
	const [suggestedGroup, setSuggestedGroup] = useState<string | undefined>();
	const [suggestedUsers, setSuggestedUsers] = useState<string[]>([]);
	// За счет того, что <DelegationDialog> постоянно висит в памяти даже для isOpen==false,
	// этот эффект запускает цепочку действий на автозаполнение поля "Ответственный"
	// при установке/смене (например, вида деятельности) на карточке редактирования нарушения
	// TODO: Отрефакторить фичу автоселекта и отключить фоновую работу диалога на автозаполнение ответственного
	useUpdateEffect(() => {
		const [s, group, users] = getSuggestionsByRules(
			groups,
			rules,
			categoryId,
			planId,
			structureIds
		);
		setSuggestions(s);
		setSuggestedGroup(group);
		setSuggestedUsers(users);
	}, [categoryId, planId, structureIds]);

	// За счет того, что <DelegationDialog> постоянно висит в памяти даже для isOpen==false,
	// эффект срабатывает раньше, чем непосредственно открытие модалки
	useEffect(() => {
		const [s] = getSuggestionsByRules(groups, rules, categoryId, planId, structureIds);
		setSuggestions(s);
	}, []);

	// Насыщенные данные, которые выводятся в Tree
	const enrichedGroups = useEnrichedGroups(
		objectId,
		groups,
		rules,
		suggestions,
		activeGroup,
		isSuggestionsFilterActive,
		searchValue
	);

	// Автоматическое раскрывание списка ответственных при поиске
	// При сбросе поиска возвращаем раскрытый список к состоянию по дефолту, как если бы модалка только открылась
	useUpdateEffect(() => {
		const newExpandedGroups = searchValue
			? formAllExpandedGroups(enrichedGroups)
			: formInitialExpandedGroups(enrichedGroups, selectedUsers, selectedGroup);

		setExpandedGroups(newExpandedGroups);
	}, [searchValue]);

	// Фича автоcелекта ответственного (rule.autoSelect), part2
	useUpdateEffect(() => {
		setExpandedGroups(
			formInitialExpandedGroups(enrichedGroups, suggestedUsers, suggestedGroup)
		);
		if (autoApplySuggestions) onChange(suggestedUsers, suggestedGroup);
	}, [suggestedGroup, suggestedUsers]);

	// Флаг сброса локального state при отмене/закрытии окна
	const needToReset = useRef<boolean>(false);

	const save = useCallback(() => {
		needToReset.current = false;
		onChange(localSelectedUsers, localSelectedGroup || activeGroup);
		clearSearchValue();
		onClose();
	}, [onChange, localSelectedUsers, localSelectedGroup, activeGroup, clearSearchValue, onClose]);

	const close = useCallback(() => {
		needToReset.current = true;
		clearSearchValue();
		onClose();
	}, [clearSearchValue, onClose]);

	// Синхронизация локальных переменных с пропсами part2
	const reset = useCallback(() => {
		if (needToReset.current) {
			setLocalSelectedUsers(selectedUsers);
			setLocalSelectedGroup(selectedGroup);
		}
	}, [selectedUsers, selectedGroup]);

	// Модалка "Добавить ответственного"
	const [addingUserDialog, openAddingUserDialog] = useAddingRespUserDialog();

	return (
		<Dialog
			className={{
				root: 'delegation-dialog',
				content: 'delegation-dialog__content',
				body: 'delegation-dialog__body'
			}}
			isOpen={isOpen}
			title={title}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={close}
					/>
					<Button
						type="accent-blue"
						label="Применить"
						disabled={localSelectedUsers.length === 0 && !localSelectedGroup}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnMobile
			onRequestClose={close}
			onAfterClose={reset}
		>
			<div className="delegation-dialog__controls">
				{viewButtons}

				<IconButton
					className="delegation-dialog__add-user-button"
					onClick={openAddingUserDialog}
				>
					{addUserIcon}
				</IconButton>
			</div>

			<div className="delegation-dialog__search-controls">
				<SelectSearch
					value={searchValue}
					onChange={setSearchValue}
				/>
			</div>

			<div className="delegation-dialog__tree">
				<DelegationTree
					groups={enrichedGroups}
					activeGroup={activeGroup}
					selectedUsers={localSelectedUsers}
					selectedGroup={localSelectedGroup}
					expandedGroups={expandedGroups}
					onlyInitialLevelSelectable={onlyInitialLevelSelectable}
					onUsersSelect={setLocalSelectedUsers}
					onGroupSelect={setLocalSelectedGroup}
					onGroupsExpand={setExpandedGroups}
				/>
			</div>

			{addingUserDialog}
		</Dialog>
	);
};
