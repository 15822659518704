import React from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractObjectsCitiesAsArray} from '@src/store/modules/dictionaries/objects/selectors';

const CitiesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const cities = useAppSelector(extractObjectsCitiesAsArray);
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={cities}
			label="Город"
			filterName="cities"
		/>
	);
};

export default CitiesFilterPage;
