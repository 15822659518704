import {requestSpaceOwners} from '@src/api/backend/spaceOwners';
import {useQueryClient} from '@tanstack/react-query';
import {restDictionariesQueryKeys} from '../keys';

export const useSpaceOwnersQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(restDictionariesQueryKeys.spaceOwners(), {
		queryFn: requestSpaceOwners,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
