import React from 'react';
import {InlineButton} from '@tehzor/ui-components';
import {useFiltersDialogDispatch} from '../utils/FiltersDialogDispatchCtx';

interface IControlsProps {
	checkedField: string;
}

const Controls = ({checkedField}: IControlsProps) => {
	const dispatch = useFiltersDialogDispatch();

	const clearFilters = () => {
		dispatch({type: 'update', field: checkedField, value: []});
	};

	return (
		<div className="stats-filters-dialog__controls">
			<InlineButton
				label="Сбросить фильтры"
				onClick={clearFilters}
			/>
		</div>
	);
};

export default Controls;
