import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting,
	handleLoadableEntityAdding,
	handleLoadableEntityEditing,
	handleLoadableEntityDeletion
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import * as ownerTypes from '@src/store/modules/entities/spaceOwner/constants';
import {IUnbindSpaceFromOwnerPayload} from '@src/store/modules/entities/spaceOwner/actions';
import * as ownersTypes from '@src/store/modules/entities/spaceOwners/constants';
import {IBindSpaceToOwnersPayload} from '@src/store/modules/entities/spaceOwners/actions';

export type ISpaceOwnersState = ILoadableEntitiesState<ISpaceOwner>;

export const spaceOwners = createReducer<ISpaceOwnersState>(
	getLoadableEntitiesInitialState<ISpaceOwner>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ISpaceOwner>(),
		[ownerTypes.ADD_SUCCESS]: handleLoadableEntityAdding<ISpaceOwner>(),
		[ownerTypes.EDIT_SUCCESS]: handleLoadableEntityEditing<ISpaceOwner>(),
		[ownerTypes.DELETE_SUCCESS]: handleLoadableEntityDeletion<ISpaceOwner>(),
		[ownerTypes.UNBIND_SPACE_SUCCESS]: (
			state,
			{payload}: {payload: IUnbindSpaceFromOwnerPayload}
		) => {
			if (state.allIds.includes(payload.ownerId)) {
				if (payload.owner?.id) {
					state.byId[payload.ownerId] = payload.owner;
				} else {
					state.allIds = state.allIds.filter(id => id !== payload.ownerId);
					delete state.byId[payload.ownerId];
				}
			}

			if (state.allIds.includes(payload.ownerId)) {
				state.allIds = state.allIds.filter(id => id !== payload.ownerId);
				delete state.byId[payload.ownerId];
			}
		},
		[ownersTypes.BIND_SPACE_SUCCESS]: (
			state,
			{payload}: {payload: IBindSpaceToOwnersPayload}
		) => {
			for (const owner of payload) {
				state.byId[owner.id] = owner;
			}
		},
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ISpaceOwner>()
	}
);
