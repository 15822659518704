import React from 'react';
import './PageHeader.less';
import classNames from 'classnames';

interface IPageHeaderProps {
	className?: string;
	style?: {};
	children?: React.ReactNode;
	title: string;
	subTitle?: string;
	wrapClassName?: string;
	leftSideClassName?: string;
	rightSideClassName?: string;
	elementBefore?: React.ReactNode;
}

class PageHeader extends React.PureComponent<IPageHeaderProps> {
	constructor(props: IPageHeaderProps) {
		super(props);
	}

	render() {
		const {
			className, style, children, title, subTitle, wrapClassName,
			leftSideClassName, rightSideClassName, elementBefore
		} = this.props;
		const classes = classNames(
			'page-header',
			className
		);
		const wrapClasses = classNames('page-header__wrap', wrapClassName);
		const leftSideClasses = classNames('page-header__left', leftSideClassName);
		const rightSideClasses = classNames('page-header__right', rightSideClassName);

		return (
			<div
				className={classes}
				style={style}
			>
				{elementBefore}

				<div className={wrapClasses}>
					<div className={leftSideClasses}>
						<div className="page-header__title">{title}</div>
						{subTitle && <div className="page-header__sub-title">{subTitle}</div>}
					</div>

					<div className={rightSideClasses}>
						{children}
					</div>
				</div>
			</div>
		);
	}
}

export default PageHeader;
