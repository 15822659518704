import {Column} from 'react-table';
import {IPreparedClaim} from '../../interfaces/IPreparedClaim';
import OwnerCell from './OwnerCell';
import MenuCell from './MenuCell';

export const desktopColumns: Array<Column<IPreparedClaim>> = [
	{
		Header: '№',
		accessor: row => row.number ?? row.localNumber,
		minWidth: 60,
		width: 60
	},
	{
		Header: 'Id',
		accessor: 'publicId',
		width: 100,
		disableSortBy: true
	},
	{
		Header: 'Объект',
		id: 'object',
		accessor: row => row.object?.name,
		width: 100
	},
	{
		id: 'status',
		Header: 'Статус',
		accessor: row => row.status,
		width: 100
	},
	{
		Header: 'Заявитель',
		id: 'spaceOwner',
		accessor: row => row.spaceOwner?.name,
		Cell: OwnerCell,
		width: 100
	},
	{
		Header: 'Добавлено',
		accessor: 'createdAt',
		width: 80,
		sortDescFirst: true
	},
	{
		Header: 'Изменено',
		accessor: 'modifiedAt',
		width: 80,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'w-claims-page__d-table-menu',
		width: 68,
		minWidth: 68,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const desktopColumnsWithoutObject = desktopColumns.filter(item => item.id !== 'object');
