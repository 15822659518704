import React from 'react';
import {StatsTreePanel} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import CommonStatsCards from './CommonStatsCards';
import {StatsByFields} from '@src/utils/statsAggregators/creation';

interface IWorkerStatsProps {
	userId: string;
	stats?: StatsByFields;
	level?: number;
}

const WorkerStats = ({userId, stats, level}: IWorkerStatsProps) => {
	const user = useAppSelector(s => s.dictionaries.users.byId[userId]);
	if (!user) {
		return null;
	}

	return (
		<StatsTreePanel
			id={user.id}
			title={user.fullName}
			subTitle={user.position}
			level={level}
			permanentContent={(
				<CommonStatsCards
					stats={stats}
					hideChecks
					hideInspections
				/>
			)}
		/>
	);
};

export default WorkerStats;
