import {useChangePath} from '@src/core/hooks/useChangePath';
import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';

const useAddingCategoriesSet = (canAdd = false): React.ReactNode | null => {
	const {pushPath} = useChangePath();
	const {pathname} = useLocation();
	const isDesktop = useIsDesktop();
	const handleClick = useCallback(() => {
		pushPath(`${pathname}/empty`, {canGoBack: true});
	}, []);

	return canAdd ? (
		isDesktop ? (
			<Button
				className="categories-sets__controls-button"
				type="accent-blue"
				leftIcon={<i className="tz-plus-20"/>}
				label="Добавить набор"
				onClick={handleClick}
			/>
		) : (
			<FloatingActionButton
				icon={<i className="tz-plus-16"/>}
				label="Добавить"
				minifyOnScroll
				scrollContainer="scroll-container"
				onClick={handleClick}
			/>
		)
	) : null;
};

export default useAddingCategoriesSet;
