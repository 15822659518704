import React from 'react';
import {isDoc, isDocx, isPdf, isXls, isXlsx} from '@tehzor/tools/utils/mimeTypes';

export function getIconByType(type: string) {
	if (isPdf(type)) {
		return <i className="tz-pdf"/>;
	}
	if (isDocx(type) || isDoc(type)) {
		return <i className="tz-docx"/>;
	}
	if (isXlsx(type) || isXls(type)) {
		return <i className="tz-xlsx"/>;
	}
	return <i className="tz-file"/>;
}