import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {DesktopItemDetail} from '@src/pages/CheckListPage/components/detail';
import React from 'react';
import {useEntityAddingPermission} from '../hooks/useEntityAddingPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {DesktopListDetail} from './detail/ListDetail.desktop';

export const DesktopDetailColumn = () => {
	const itemsLoading = useAppSelector(s => s.dictionaries.checkItems.loading);
	const listsLoading = useAppSelector(s => s.dictionaries.checkLists.loading);
	const roles = useAppSelector(extractUserRoles);

	const {
		objectId = 'all',
		listId,
		itemId
	} = useStrictParams<{
		objectId?: string;
		listId: string;
		itemId?: string;
	}>();

	const canAddEntity = useEntityAddingPermission(objectId, listId);
	const permissions = {
		canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
		canAddEntity
	};
	return (
		<Plate
			withoutPadding
			className={{root: 'check-item-detail', content: 'check-item-detail__content'}}
		>
			{itemId && (
				<LoadingPanel
					active={itemsLoading}
					style={{height: '100%'}}
				>
					<DesktopItemDetail
						canAddEntity={permissions.canAddEntity}
						canAddTask={permissions.canAddTask}
					/>
				</LoadingPanel>
			)}

			{!itemId && listId && (
				<LoadingPanel
					active={listsLoading}
					style={{height: '100%'}}
				>
					<DesktopListDetail canAddTask={permissions.canAddTask}/>
				</LoadingPanel>
			)}
			{!itemId && !listId && (
				<div className="check-item-detail__no-content">
					<div className="check-item-detail__no-content-container">
						<div className="check-item-detail__no-content-icon">
							<i className="tz-inspection"/>
						</div>
						<div className="check-item-detail__no-content-text">
							Выберите чек-лист, категорию или подкатегорию
						</div>
					</div>
				</div>
			)}
		</Plate>
	);
};
