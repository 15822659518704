import React, {useRef} from 'react';
import {ConfirmDialog, IConfirmDialogRefProps} from '@tehzor/ui-components';
import '../ObjectsPage.less';
import {useObjectsGroups} from '../hooks/useObjectsGroups';
import {ObjectsGroup} from './group/ObjectsGroup';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {Tree} from '@src/utils/tree';
import {IObjectTree} from '@src/interfaces/IObjectTree';
import {ObjectsControls} from './controls/ObjectsControls';

interface IPlatesPageProps {
	isSubPlates?: boolean;
	text: string;
	treeObjects: Array<Tree<IObjectTree>>;
}

/**
 * Страница списка объектов
 */
export const PlatesPage = ({isSubPlates, text, treeObjects}: IPlatesPageProps) => {
	const user = useAppSelector(extractUserProfile);
	const {filters} = useAppSelector(extractObjectsPageSettings);

	const groups = useObjectsGroups(treeObjects, filters, user);

	const dialog = useRef<IConfirmDialogRefProps | null>(null);

	return (
		<>
			<ObjectsControls text={text}/>

			{groups.map(group => (
				<ObjectsGroup
					isSubPlates={isSubPlates}
					key={group.companyId}
					data={group}
				/>
			))}

			<ConfirmDialog
				title="Вы действительно хотите удалить объект?"
				acceptBtnLabel="Да"
				rejectBtnLabel="Нет"
				ref={dialog}
			/>
		</>
	);
};