import React from 'react';
import {TagsContainer, SpaceIndicatorTag} from '@tehzor/ui-components';
import {
	spaceIndicatorsAsArray,
	useGetSpaceIndicatorsSets
} from '@src/core/hooks/queries/spaceIndicatorsSets';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IIndicatorsProps {
	objectId: string;
	value: string[];
}

const Indicators = ({objectId, value}: IIndicatorsProps) => {
	const spaceIndicatorsSets = useGetSpaceIndicatorsSets();
	const objects = useAppSelector(s => s.dictionaries.objects);
	const indicators: INormalizedData<ISpaceIndicator> = spaceIndicatorsAsArray(
		spaceIndicatorsSets,
		objectId,
		objects,
		true
	);

	return (
		<TagsContainer>
			{value.map(id => {
				const indicator = indicators?.byId[id];
				return indicator ? (
					<SpaceIndicatorTag
						key={id}
						color={indicator.color}
						label={indicator.name}
					/>
				) : null;
			})}
		</TagsContainer>
	);
};

export default Indicators;
