import {IEditableSpaceStatusesSetAction, IEditableSpaceStatusesSetState, IEditableStatus} from '@src/core/hooks/states/useSpaceStatusesSetState';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import generateKey from '@tehzor/tools/utils/generateKey';
import {InlineButton} from '@tehzor/ui-components';
import React, {Dispatch, useCallback, useMemo, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useGetExtendedStatusesByStage} from '../hooks/useGetExtendedStatusesByStage';
import {DesktopEditableSpaceStatus} from './desktop/DesktopEditableSpaceStatus';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {MobileEditableSpaceStatus} from './mobile/MobileEditableSpaceStatus';
import {IDeleteSpaceStatusResponse} from '@src/api/backend/spaceStatuses/delete';
import {useAddingSpaceStatusDialog} from '../hooks/useAddingSpaceStatusDialog';

interface IStageStatusesProps {
	statuses?: ISpaceStatus[];
	stage: IObjectStage;
	disabled?: boolean;
	spaceStatusesSetId: string;

	editingDispatch: Dispatch<IEditableSpaceStatusesSetAction>;
	editingState: IEditableSpaceStatusesSetState;

	updateStatus: (index: number) => Promise<ISpaceStatus | undefined>;
	removeStatus: (id: string) => Promise<IDeleteSpaceStatusResponse | undefined>;
}

const plusIcon = <i className="tz-plus-20"/>;

export const StageStatuses = ({
	statuses,
	stage,
	disabled,
	spaceStatusesSetId,
	editingDispatch,
	editingState,
	updateStatus,
	removeStatus
}: IStageStatusesProps) => {
	const title = useMemo(() => `Стадия: ${stage.name}`, [stage]);
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);

	const [editedStatuses, setEditedStatuses] = useState<string[]>([]);

	const statusesByStage = useGetExtendedStatusesByStage(editingState.statuses, stagesMap, stage);

	const handleCreateStatus = useCallback(() => {
		if (spaceStatusesSetId === 'empty') {
			return undefined;
		}

		const id = generateKey();
		const savingSpaceStatus: IEditableStatus = {
			id,
			spaceStatusesSetId,
			stage: stage.id,
			color: '#FFFFFF',
			isLocal: true
		};

		editingDispatch({
			type: 'add-in-array',
			field: 'statuses',
			value: [savingSpaceStatus]
		});
		setEditedStatuses(s => [...s, id]);

		return savingSpaceStatus;
	}, [spaceStatusesSetId, stage]);

	const isLargeTablet = useIsLargeTablet();

	const [open, addingDialog] = useAddingSpaceStatusDialog({
		createStatus: handleCreateStatus,
		updateStatus,
		editingDispatch,
		setEdited: setEditedStatuses,
		statuses,
		statusesByStage
	});

	return (
		<div className="editable-space-statuses__stage">
			<div className="editable-space-statuses__stage-title">{title}</div>
			{(statusesByStage.map(status => (
				<div key={status.id}>
					{isLargeTablet ? (
						<DesktopEditableSpaceStatus
							disabled={disabled}
							status={status}
							statuses={statuses}
							isEdited={editedStatuses.includes(status.id)}
							setEdited={setEditedStatuses}
							editingDispatch={editingDispatch}
							updateStatus={updateStatus}
							removeStatus={removeStatus}
						/>
					) : (
						<MobileEditableSpaceStatus
							disabled={disabled}
							status={status}
							statuses={statuses}
							setEdited={setEditedStatuses}
							editingDispatch={editingDispatch}
							updateStatus={updateStatus}
							removeStatus={removeStatus}
						/>
					)}
				</div>
			)))}
			<InlineButton
				className="editable-space-statuses__stage-add"
				disabled={disabled}
				leftIcon={plusIcon}
				label="Добавить статус"
				type="accent"
				onClick={isLargeTablet ? handleCreateStatus : open}
			/>
			{!isLargeTablet && !disabled && addingDialog}
		</div>
	);
};