import React, {useCallback, useState} from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {useEditableAuthorizedPerson} from '@src/components/EditableAuthorizedPerson/hooks/useEditableAuthorizedPerson';
import {makeTitle} from './utils/makeTitle';
import './EditableAuthorizedPersonDialog.less';

interface IEditableAuthorizedPersonDialogProps {
	authorizedPerson?: IAuthorizedPerson;
	legalEntityId?: string;
	companyId?: string;
	isOpen: boolean;
	onClose: () => void;
}

export const EditableAuthorizedPersonDialog = (props: IEditableAuthorizedPersonDialogProps) => {
	const {authorizedPerson, legalEntityId, companyId, isOpen, onClose} = props;

	const [saving, setSaving] = useState(false);
	const {fields, reset, getSavingData, isBlocking} = useEditableAuthorizedPerson({
		authorizedPerson,
		legalEntityId,
		companyId
	});

	const save = useCallback(async () => {
		setSaving(true);

		if (!getSavingData) {
			onClose();
			setSaving(false);
			return;
		}

		const savingData = await getSavingData();

		if (savingData) {
			onClose();
		}

		setSaving(false);
	}, [getSavingData, onClose]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation]);

	const footer = (
		<ActionButtons>
			<Button type="cancel" label="Отменить" disabled={saving} onClick={handleClose} />
			<Button type="accent-blue" label="Сохранить" disabled={saving} onClick={save} />
		</ActionButtons>
	);

	return (
		<Dialog
			className="editable-authorized-person-dialog"
			isOpen={isOpen}
			title={makeTitle(authorizedPerson)}
			footer={footer}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
