import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';

export type IGetCompaniesResponse = INormalizedData<ICompany>;

/**
 * Получает список компаний
 */
export const requestCompanies = () =>
	wsConnector.sendAuthorizedRequest<IGetCompaniesResponse>('getCompanies');
