import React from 'react';
import './SpaceInfoMobileCell.less';
import {CellProps} from 'react-table';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {EntityInfo, TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';

const SpaceInfoMobileCell = ({row}: CellProps<IEnrichedSpace>) => {
	const space = row.original;
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return (
		<div className="m-space-info-cell">
			<div className="m-space-info-cell__wrapper">
				<div className="m-space-info-cell__view">
					<EntityInfo
						backgroundColor={space.status?.color}
						icon={<i className="tz-space-24"/>}
					/>
					<div className="m-space-info-cell__indicators">
						{space.indicators?.map(indicator => (
							<div
								key={indicator.id}
								className="m-space-info-cell__indicator"
								style={{backgroundColor: indicator.color}}
							/>
						))}
					</div>
				</div>
				<div className="m-space-info-cell__text">
					<div className="m-space-info-cell__title">
						{formSpaceTitle(
							space.name,
							space.altName,
							spaceTypes.byId[space.type],
							altNamesVisible
						)}
					</div>
					<div className="m-space-info-cell__status">{space.status?.name}</div>
				</div>
			</div>

			<ClickPreventWrap style={{marginLeft: 'auto', padding: '0 25px'}}>
				<TableRowSelect
					{...row.getToggleRowSelectedProps()}
					style={{marginTop: '25px'}}
				/>
			</ClickPreventWrap>
		</div>
	);
};

export default SpaceInfoMobileCell;
