import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as ownerAcceptanceTypes from '../ownerAcceptance/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {CHANGE_SORT, CLEAR_FILTERS} from '@src/store/modules/settings/pages/ownerAcceptances/constants';
import {IGetOwnerAcceptancesPayload} from './actions/get';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {IAddOwnerAcptPayload, IDeleteOwnerAcptPayload} from '../ownerAcceptance/actions';

const {entitiesCacheTime} = config;

export interface IOwnerAcceptancesPageState {
	byId: {
		[id: string]: IOwnerAcceptance;
	};
	allIds: string[];
	offset: number;
	total: number;
	expires?: number;
	selectedRows: string[];
}

export type IOwnerAcceptancesPagesState = Record<string, IOwnerAcceptancesPageState>;

export const getInitialStateForPage = (): IOwnerAcceptancesPageState => ({
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	expires: undefined,
	selectedRows: []
});

const deleteOwnerAcceptance = (state: IOwnerAcceptancesPagesState, objectId: string, ownerAcceptanceId: string) => {
	const page = state[objectId];
	page.allIds = page.allIds.filter(id => id !== ownerAcceptanceId);
	delete page.byId[ownerAcceptanceId];
	page.expires = undefined;
};

export default createReducer<IOwnerAcceptancesPagesState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: { payload: { objectId: string } }) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SUCCESS]: (state, {payload}: { payload: IGetOwnerAcceptancesPayload }) => {
			const page = state[payload.objectId];
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.offset = payload.offset;
			page.total = payload.total;
			page.expires = Date.now() + entitiesCacheTime * 1000;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: { payload: { objectId: string, offset: number } }
		) => {
			const page = state[payload?.objectId];
			if (page) {
				page.offset = payload?.offset;
				page.expires = undefined;
			}
		},
		[types.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: { payload: { objectId: string, selectedRows: string[] } }
		) => {
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		[types.CLEAR_EXPIRATION]: (state, {payload}: { payload: { objectId: string } }) => {
			if (state && state?.[payload.objectId]?.expires) {
				state[payload.objectId].expires = undefined;
			}
		},
		// При сортировке нужно обнулить expires для игнорирования кеша
		[CHANGE_SORT]: (state, {payload}: { payload: { objectId: string } }) => {
			if (payload.objectId && !state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			state[payload.objectId].expires = undefined;
		},
		// При сбрасывании фильтров нужно обнулить expires для игнорирования кеша
		[CLEAR_FILTERS]: (state, {payload}: { payload: { objectId: string } }) => {
			state[payload.objectId].expires = undefined;
		},
		[ownerAcceptanceTypes.ADD_SUCCESS]: (state, {payload}: { payload: IAddOwnerAcptPayload }) => {
			if (state[payload.objectId]) {
				state[payload.objectId].expires = undefined;
			}
			if (state.all) {
				state.all.expires = undefined;
			}
		},
		[ownerAcceptanceTypes.DELETE_SUCCESS]: (state, {payload}: { payload: IDeleteOwnerAcptPayload }) => {
			if (payload.objectId !== 'all' && state[payload.objectId]) {
				deleteOwnerAcceptance(state, payload.objectId, payload.acceptanceId);
			}
			if (state.all) {
				deleteOwnerAcceptance(state, 'all', payload.acceptanceId);
			}
		},
		[CLEAR_STORE]: () => ({})
	}
);
