import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IState} from '@src/store/modules';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetWarrantyClaimsResponse, requestWarrantyClaims} from '@src/api/backend/warrantyClaims';
import checkLoaded from '@tehzor/tools/core/checkLoaded';

export type IGetLatestUserWarrantyClaimsPayload = IGetWarrantyClaimsResponse;

const request = () => ({
	type: types.GET_REQUEST
});

const success = (response: IGetWarrantyClaimsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке последних обращений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список последних приёмок текущего пользователя
 */
export const getLatestUserWarrantyClaims = () =>
	checkLoaded<IState, IGetWarrantyClaimsResponse, ApiAction>(
		s => s.dictionaries.latestUserWarrantyClaims,
		createApiAction<IGetWarrantyClaimsResponse>(request, success, failure, () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestWarrantyClaims({createdAtFrom: date}, {createdAt: false}, 0, 100);
		}),
		undefined,
		true
	);
