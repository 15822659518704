import _ from 'lodash';
import {createSelector} from 'reselect';
import {IState} from '../..';

export const extractAllLatestOwnerAcceptancesAsArray = createSelector(
	(state: IState) => state.dictionaries.latestUserOwnerAcceptances,
	({byId, allIds}) => allIds.map(id => byId[id])
);

/**
 * Возвращает последние ownerAcceptances для конкретного объекта в виде массива
 */
export const extractLatestOwnerAcceptancesAsArray = createSelector(
	extractAllLatestOwnerAcceptancesAsArray,
	(state: IState) => state.auth.profile.id,
	(state: IState, objectId: string | undefined) => objectId,
	(state: IState, objectId: string | undefined, spaceId?: string) => spaceId,
	(arr, userId, objectId, spaceId) =>
		arr.filter(item =>
			item.objectId === objectId
			&& item.createdBy?.id === userId
			&& (!spaceId || spaceId === item.links?.spaceId))
);

/**
 * Возвращает последнюю локальную передачу собственнику для конкретного помещения
 */
export const extractLatestLocalOwnerAcceptance = createSelector(
	(state: IState) => state.localEntities.ownerAcceptances,
	(state: IState, spaceId: string | undefined) => spaceId,
	(data, spaceId): string | undefined => {
		if (!data.length || !spaceId) {
			return undefined;
		}
		const accpForSpace = data.filter(({links}) => links?.spaceId === spaceId);
		if (accpForSpace.length) {
			return accpForSpace[accpForSpace.length - 1].key;
		}
		return undefined;
	}
);

export const extractLocalOwnerAcceptancesAsArray = createSelector(
	(state: IState) => state.localEntities.ownerAcceptances,
	(state: IState, objectId: string) => objectId,
	(state: IState, objectId: string, spaceId?: string) => spaceId,
	(data, objectId, spaceId) => {
		if (spaceId) {
			return data.filter(item => item.links?.spaceId === spaceId);
		}
		return data.filter(item => item.objectId === objectId);
	}
);

/**
 * Возвращает последнюю передачу собственнику для конкретного помещения
 */

export const extractLatestOwnerAcceptanceId = createSelector(
	extractLatestOwnerAcceptancesAsArray,
	(data): string | undefined => {
		if (data?.length) {
			return _.maxBy(data, 'modifiedAt')?.id;
		}
		return undefined;
	}
);
