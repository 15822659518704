import {IEditCategoryResponse, requestEditCategory} from '@src/api/backend/categories';
import {createApiAction} from '@src/store/middlewares/api';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {queryClient} from '@src/api/QueryClient';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';

export type IEditCategoryPayload = IEditCategoryResponse;

const request = () => ({
	type: types.EDIT_REQUEST
});

const success = (category: ICategory) => ({
	type: types.EDIT_SUCCESS,
	payload: {...category} as IEditCategoryPayload
});

const failure = (error: IError) => ({
	type: types.EDIT_FAILURE,
	payload: error
});

export const editCategory = (category: ISavingCategory) =>
	createApiAction<IEditCategoryResponse>(request, success, failure, async () => {
		const data = await requestEditCategory(category);
		await queryClient.invalidateQueries(categoriesQueryKeys.list());
		return data;
	});
