import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {
	formCheckLink,
	formInspectionLink,
	formInternalAcceptanceLink,
	formSpaceLink
} from '@tehzor/tools/utils/links';
import {extractInspection} from '@src/store/modules/entities/inspection/selectors/inspection';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';

/**
 * Хук для хлебных крошек от "всех объектов" до осмотра
 *
 * @param objectId id конечного объекта
 */
export function useInspectionBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	const inspection = useAppSelector(extractInspection);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId, 'inspections');
		if (inspection) {
			if (inspection.space) {
				items.push({
					label: formSpaceTitle(
						inspection.space.name,
						inspection.space.altName,
						spaceTypes.byId[inspection.space.type],
						altNamesVisible
					),
					url: formSpaceLink(objectId, inspection.space.id),
					inactive: false
				});
			}
			if (inspection.check) {
				items.push({
					label: `Проверка №${inspection.check.number}`,
					url: formCheckLink(objectId, inspection.check.id),
					inactive: false
				});
			}
			if (inspection.internalAcceptance) {
				items.push({
					label: `Внутренняя приемка №${inspection.internalAcceptance.number}`,
					url: formInternalAcceptanceLink(objectId, inspection.internalAcceptance.id),
					inactive: false
				});
			}
			items.push({
				label: `Осмотр №${inspection.number || inspection.localNumber}`,
				url: formInspectionLink(objectId, inspection.id),
				inactive: false
			});
		}
		return items;
	}, [objectId, inspection, objects, spaceTypes]);
}
