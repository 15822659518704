import {wsConnector} from '@src/api/backend/wsConnector';
import {IMarkers} from '@tehzor/tools/interfaces/markers/IMarkers';

export type IGetMarkersResponse = IMarkers;

/**
 * Возвращает маркеры нарушений
 * @param planId id плана
 * @param problemIds ids нарушений
 * @param taskIds ids задач
 * @param inspectionIds ids проверок
 * @param ownerAcceptanceIds ids передач собственникам
 */
export const requestMarkers = (
	planId?: string,
	problemIds?: string[],
	inspectionIds?: string[],
	ownerAcceptanceIds?: string[],
	taskIds?: string[]
) => wsConnector.sendAuthorizedRequest<IGetMarkersResponse>('getMarkers', {
		planId,
		problemIds,
		taskIds,
		inspectionIds,
		ownerAcceptanceIds
	});