import React, {PureComponent} from 'react';
import './LoadingPanel.less';
import classNames from 'classnames';
import EyeLoader from '../EyeLoader';
import Animate from 'rc-animate';

interface ILoadingPanelProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactElement;
	loaderContent?: React.ReactNode;
	loaderClassName?: string;
	overlayClassName?: string;
	active?: boolean;
	hideContent?: boolean;
	delay?: number;
}

interface ILoadingPanelState {
	loaderVisible: boolean;
}

class LoadingPanel extends PureComponent<ILoadingPanelProps, ILoadingPanelState> {
	static displayName = 'LoadingPanel';

	static defaultProps: Partial<ILoadingPanelProps> = {
		delay: 300
	};

	state = {
		loaderVisible: false
	} as ILoadingPanelState;

	private _timerId?: number;

	componentDidMount() {
		this._checkTimer();
	}

	componentWillUnmount() {
		this._clearTimer();
	}

	componentDidUpdate(prevProps: ILoadingPanelProps, prevState: ILoadingPanelState) {
		if (this.props.active !== prevProps.active) {
			this._checkTimer();
		}
	}

	render() {
		const {className, style, children, loaderContent, loaderClassName, overlayClassName, hideContent} = this.props;
		const {loaderVisible} = this.state;

		const classes = classNames('loading-panel', className);
		const loaderClasses = classNames('loading-panel__loader', loaderClassName);
		const overlayClasses = classNames('loading-panel__overlay', overlayClassName);

		return (
			<div
				className={classes}
				style={style}
			>
				<Animate
					component=""
					transitionName="loading-panel__anim"
					transitionAppear={false}
				>
					{loaderVisible ? (
						<div className={overlayClasses}>
							<div className="loading-panel__loader-wrap">
								<EyeLoader className={loaderClasses}/>
								{!!loaderContent && (
									<div className="loading-panel__loader-content">{loaderContent}</div>
								)}
							</div>
						</div>
					) : null}
				</Animate>
				<Animate
					component=""
					transitionName="loading-panel__anim"
					transitionAppear={false}
				>
					{(loaderVisible && !hideContent || !loaderVisible) ? children : null}
				</Animate>
			</div>
		);
	}

	private _checkTimer = () => {
		const {active, delay} = this.props;

		if (active) {
			if (!this._timerId) {
				this._timerId = window.setTimeout(() => {
					this._timerId = undefined;
					this.setState({loaderVisible: true});
				}, delay);
			}
		} else {
			this._clearTimer();
		}
	};

	private _clearTimer = () => {
		clearTimeout(this._timerId);
		this._timerId = undefined;
		this.setState({loaderVisible: false});
	};
}

export default LoadingPanel;
