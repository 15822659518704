import React, {useCallback, useState, useEffect, useMemo} from 'react';
import {ISuggestions} from '../utils/getSuggestionsByRules';
import {FilterButton} from '@tehzor/ui-components';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {useIsBeforeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';

/**
 * Кнопки для фильтрации списка ответственных:
 * — Отобранные по правилам (suggestions)
 * — Отфильтрованные по типу исполнитель/проверяющий
 */
export function useViewButtons(
	suggestions: ISuggestions,
	isWorkingGroupTypeFilterAvailable: boolean
): [React.ReactNode, boolean, WorkingGroupTypeId | undefined] {
	const [isSuggestionsFilterActive, setIsSuggestionsFilterActive] = useState(false);

	const hasSuggestions = useMemo(() => Object.keys(suggestions).length > 0, [suggestions]);

	// Активирует фильтр по-умолчанию
	useEffect(() => setIsSuggestionsFilterActive(hasSuggestions), [hasSuggestions]);

	const [filteredWorkingGroupType, setFilteredWorkingGroupType] = useState<WorkingGroupTypeId | undefined>(undefined);

	/**
	 * TODO: Уточнить, кнопки фильтров можно активировать параллельно, нормально ли это?
	 * Возможно, стоит изменить поведение, как на radiobutton — при установке одного фильтра сбрасывать остальные
	 */
	const showSuggested = useCallback(() => setIsSuggestionsFilterActive(true), []);
	const showPerformers = useCallback(() => setFilteredWorkingGroupType(WorkingGroupTypeId.PERFORMERS), []);
	const showProblemInspectors = useCallback(() =>
		setFilteredWorkingGroupType(WorkingGroupTypeId.PROBLEM_INSPECTORS), []);
	const showWorkAcceptanceInspectors = useCallback(() =>
		setFilteredWorkingGroupType(WorkingGroupTypeId.WORK_ACCEPTANCE_INSPECTORS), []);

	const showAll = useCallback(() => {
		setFilteredWorkingGroupType(undefined);
		setIsSuggestionsFilterActive(false);
	}, []);

	const isMobile = useIsBeforeTablet();

	const buttons = (
		<div className="delegation-dialog__view-buttons">
			{hasSuggestions && (
				<FilterButton
					className="delegation-dialog__view-button"
					label={isMobile ? 'Отобранные' : 'Отобранные по правилам'}
					active={isSuggestionsFilterActive}
					onClick={showSuggested}
				/>
			)}

			<FilterButton
				className="delegation-dialog__view-button"
				label="Все участники"
				active={!filteredWorkingGroupType && !isSuggestionsFilterActive}
				onClick={showAll}
			/>

			{isWorkingGroupTypeFilterAvailable && (
				<>
					<FilterButton
						className="delegation-dialog__view-button"
						label="Исполнители"
						active={filteredWorkingGroupType === WorkingGroupTypeId.PERFORMERS}
						onClick={showPerformers}
					/>

					<FilterButton
						className="delegation-dialog__view-button"
						label="Проверяющие по нарушениям"
						active={filteredWorkingGroupType === WorkingGroupTypeId.PROBLEM_INSPECTORS}
						onClick={showProblemInspectors}
					/>

					<FilterButton
						className="delegation-dialog__view-button"
						label="Проверяющие по приемкам работ"
						active={filteredWorkingGroupType === WorkingGroupTypeId.WORK_ACCEPTANCE_INSPECTORS}
						onClick={showWorkAcceptanceInspectors}
					/>
				</>
			)}
		</div>
	);

	return [buttons, isSuggestionsFilterActive, filteredWorkingGroupType];
}
