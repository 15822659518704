import {createReducer} from '@reduxjs/toolkit';
import {getLoadableEntitiesInitialState, handleLoadableEntitiesGetting, handleLoadableEntityAdding, handleLoadableEntityDeletion, handleLoadableEntityEditing, handleLoadableEntityReset, ILoadableEntitiesState} from '@tehzor/tools/core/storeHelpers/reducers';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';
import {CLEAR_STORE} from '../../auth/constants';
import {IDeleteManySpaceStatusPayload} from './actions';
import * as types from './constants';
import * as setTypes from '../spaceStatusesSets/constants';

export type ISpaceStatusesState = ILoadableEntitiesState<ISpaceStatus>;

export const spaceStatuses = createReducer<ISpaceStatusesState>(
	getLoadableEntitiesInitialState<ISpaceStatus>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ISpaceStatus>(),
		[types.ADD_SUCCESS]: handleLoadableEntityAdding<ISpaceStatus>(),
		[types.EDIT_SUCCESS]: handleLoadableEntityEditing<ISpaceStatus>(),
		[types.DELETE_SUCCESS]: handleLoadableEntityDeletion<ISpaceStatus>(),
		[types.DELETE_MANY_SUCCESS]: (
			state,
			{payload}: {payload: IDeleteManySpaceStatusPayload}
		) => {
			const {allIds, byId} = state;
			const deleteIds: string[] = [];

			allIds.forEach(id => {
				const {spaceStatusesSetId} = byId[id];
				if (spaceStatusesSetId === payload.spaceStatusesSetId) {
					delete state.byId[id];
					deleteIds.push(id);
				}
			});

			state.allIds = allIds.filter(id => !deleteIds.includes(id));
			state.loaded = false;
			state.loadedAt = undefined;
		},
		[setTypes.ADD_SUCCESS]: handleLoadableEntityReset<ISpaceStatus>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ISpaceStatus>(),
		[CLEAR_BEFORE_UPDATE]: state => ({...state, loaded: false})
	}
);