import React from 'react';
import './InternalAcceptanceInfoDesktopCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import InternalAcceptanceInfo from '@src/components/tableCells/InternalAcceptanceInfo';

const InternalAcceptanceInfoDesktopCell = ({row}: CellProps<{data: IInternalAcceptance}>) => (
	<div className="d-internal-acceptance-info-cell">
		<ClickPreventWrap>
			<TableRowSelect
				{...row.getToggleRowSelectedProps()}
				style={{marginTop: '12px'}}
			/>
		</ClickPreventWrap>

		<div className="d-internal-acceptance-info-cell__info">
			<InternalAcceptanceInfo data={row.original.data}/>
		</div>
	</div>
);

export default InternalAcceptanceInfoDesktopCell;
