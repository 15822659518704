import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {GET_REQUEST} from '@src/store/modules/dictionaries/checkLists/constants';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';

export interface IChecklistsFiltersState extends Record<string, unknown> {
	companies?: string[];
	createdBy?: string[];
	spaceTypes?: string[];
	types?: string;
}

export type ICheckListsSortState = Record<string, boolean>;

export interface ICheckListsPageSettingsState {
	filters: IChecklistsFiltersState;
	sort: ICheckListsSortState;
	pageSize: number;
	offset: number;
}

export const getInitialState = (): ICheckListsPageSettingsState => ({
	filters: {},
	offset: 0,
	sort: {
		modifiedAt: true
	},
	pageSize: 50
});

export default createReducer<ICheckListsPageSettingsState>(getInitialState(), {
	[GET_REQUEST]: () => getInitialState(),
	[types.CHANGE_SORT]: (
		state,
		{payload}: {payload: IChangeSortPayload<ICheckListsSortState>}
	) => {
		state.sort = payload.sort;
	}
});
