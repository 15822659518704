import React, {useRef} from 'react';
import PageBreadcrumbs from './PageBreadcrumbs';
import DesktopAttachments from './attachments/Attachments.desktop';
import DesktopControls from './Controls.desktop';
import Info from './info/Info.desktop';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {Plate} from '@tehzor/ui-components';
import {useInspectionViewer} from '@src/core/hooks/imagesViewers/desktop/useInspectionViewer';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';

interface IDesktopProps {
	objectId: string;
	inspectionId: string;
	inspection?: ILinkedInspection;
}

const Desktop = ({objectId, inspectionId, inspection}: IDesktopProps) => {
	const pageRef = useRef<HTMLDivElement>(null);

	const [imagesAttachments] = useSplitAttachments(inspection);
	const images = useAttachmentsImages(imagesAttachments);
	const imagesData = useAttachmentsImagesObjects(imagesAttachments);
	const [imagesViewer, openInspectionImage] = useInspectionViewer(
		images,
		inspectionId,
		imagesData
	);

	return (
		<div
			className="page-cont inspection-page"
			ref={pageRef}
		>
			<PageBreadcrumbs
				objectId={objectId}
				inspectionId={inspectionId}
			/>

			<div className="inspection-page__d-columns">
				{inspection !== undefined && (
					<div className="inspection-page__d-column inspection-page__d-column_attachments">
						<DesktopAttachments
							inspection={inspection}
							onImageClick={openInspectionImage}
						/>
					</div>
				)}

				<div className="inspection-page__d-column inspection-page__d-column_info">
					{inspection !== undefined && (
						<DesktopControls
							objectId={objectId}
							inspection={inspection}
						/>
					)}

					{inspection !== undefined && (
						<Plate>
							<Info
								objectId={objectId}
								inspection={inspection}
							/>
						</Plate>
					)}
				</div>
				{imagesViewer}
			</div>
		</div>
	);
};

export default Desktop;
