import React, {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IExportTemplate, {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInspectionsData} from '@src/store/modules/entities/inspections/selectors';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import useToggle from 'react-use/lib/useToggle';
import {exportInspections} from '@src/store/modules/entities/inspections/actions';

const availableDestinations = [ExportTemplatesDestinationId.INSPECTIONS];

export function useInspectionsExport(objectId = 'all'): [React.ReactNode, () => void] {
	const {selectedRows} = useAppSelector(s => extractInspectionsData(s, objectId));
	const {filters, sort} = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const [isOpen, toggleOpen] = useToggle(false);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await dispatch(
				exportInspections(template.id, createDocument, email, objectId, filters, sort, selectedRows)
			);
			toggleOpen(false);
		},
		[objectId, filters, sort, selectedRows]
	);

	const dialog = (
		<ExportDialog
			pageKey="inspections"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={toggleOpen}
		/>
	);

	return [dialog, toggleOpen];
}
