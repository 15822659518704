import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEditableSpaceStatusesSet} from '@src/components/EditableSpaceStatusesSet/hooks/useEditableSpaceStatusesSet';
import {useSpaceStatusesSetBreadcrumbs} from '@src/core/hooks/breadcrumbs/useSpaceStatusesSetBreadcrumbs';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {Plate} from '@tehzor/ui-components';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import React from 'react';
import {SpaceStatusesSetInfo} from './SpaceStatusesSetInfo';

interface ISpaceStatusesSetContentProps {
	spaceStatusesSet?: ISpaceStatusesSet;
	spaceStatuses?: ISpaceStatus[];
}

export const SpaceStatusesSetContent = (
	{spaceStatuses, spaceStatusesSet}: ISpaceStatusesSetContentProps
) => {
	const {statusesEl, setMenuEl, setEl}
		= useEditableSpaceStatusesSet(spaceStatusesSet, spaceStatuses);

	const breadcrumbs: IBreadcrumb[] = useSpaceStatusesSetBreadcrumbs(spaceStatusesSet?.id);

	const isLargeTablet = useIsLargeTablet();

	useAppHeader(
		{
			title: spaceStatusesSet?.name || 'Новый набор статусов помещений',
			showBackBtn: true,
			mobileRightButtons: setMenuEl
		},
		[spaceStatusesSet, setMenuEl]
	);

	return (
		<>
			<AppBreadcrumbs
				className="space-statuses-set__breadcrumbs"
				items={breadcrumbs}
			>
				{isLargeTablet && setMenuEl}
			</AppBreadcrumbs>
			<div className="space-statuses-set__wrap">
				<SpaceStatusesSetInfo
					spaceStatusesSet={spaceStatusesSet}
					setEl={setEl}
				/>
				<Plate
					className="space-statuses-set__wrap-statuses"
					withoutPadding
				>
					{statusesEl}
				</Plate>
			</div>
		</>
	);
};