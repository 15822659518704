import React, {useCallback, useMemo, useState} from 'react';
import {LoadingPanel} from '@tehzor/ui-components';
import './ObjectsPage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectsDisplayMode} from '@src/interfaces/ObjectsDisplayMode';
import {PlatesPage} from './components/PlatesPage';
import {ListPage} from './components/ListPage';
import {
	extractObjectsLoadingState,
	extractFilteredWithNewFiltersTreeObjects
} from '@src/store/modules/dictionaries/objects/selectors';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {IObjectsFiltersState} from '@src/store/modules/settings/pages/objects/reducers';
import {changeFilters} from '@src/store/modules/settings/pages/objects/actions';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {flattenTree} from '@src/utils/tree';
import declination from '@tehzor/tools/utils/declination';
import ObjectsFilters from './components/filters/ObjectsFilters';
import {ObjectsBreadcrumbs} from './components/breadcrumbs/ObjectsBreadcrumbs';
import {ObjectsMenu} from './components/menu/ObjectsMenu';

/**
 * Страница списка объектов
 */
export const ObjectsPage = () => {
	useScrollRestoration();
	useAppHeader({title: 'Объекты', mobileRightButtons: <ObjectsMenu/>});

	const [search, setSearch] = useState<string | undefined>(undefined);

	const treeObjects = useAppSelector(s => extractFilteredWithNewFiltersTreeObjects(s, search));

	const text = useMemo(() => {
		const count = flattenTree(treeObjects).length;
		return `Всего: ${count} ${declination(count || 0, ['объект', 'объекта', 'объектов'])}`;
	}, [treeObjects]);

	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(extractObjectsPageSettings) || {};
	const {displayMode = ObjectsDisplayMode.SCHEMA} = useAppSelector(extractObjectsPageSettings);
	const isLoading = useAppSelector(extractObjectsLoadingState);

	const applyFilters = useCallback((value: IObjectsFiltersState) => {
		dispatch(changeFilters('all', value));
	}, []);

	return (
		<LoadingPanel
			className="objects-page__loading-panel page-cont"
			active={isLoading}
		>
			<>
				<EntitiesFiltersProvider
					objectId="all"
					filters={filters}
					onApply={applyFilters}
				>
					<ObjectsBreadcrumbs/>
					<ObjectsFilters
						search={search}
						setSearch={setSearch}
					/>
					{displayMode === ObjectsDisplayMode.SCHEMA && (
						<PlatesPage
							text={text}
							treeObjects={treeObjects}
						/>
					)}
					{displayMode === ObjectsDisplayMode.LIST && (
						<ListPage
							text={text}
							treeObjects={treeObjects}
							search={search}
						/>
					)}
				</EntitiesFiltersProvider>
			</>
		</LoadingPanel>
	);
};
