import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetInternalAcceptancesResponse extends INormalizedData<IListInternalAcceptance> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список проверок
 */
export const requestInternalAcceptances = (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => wsConnector.sendAuthorizedRequest<IGetInternalAcceptancesResponse>('getInternalAcceptances', {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	});
