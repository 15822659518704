import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ObjectStats from './ObjectStats';
import {aggregateByObjects} from '@src/store/modules/statistics/building/selectors/creation';
import {useFilteredObjects} from '@src/pages/StatisticsPage/hooks/useFilteredObjects';
import {areCreationStatsLoading} from '@src/store/modules/statistics/ui/selectors/building';
import StatsTreePlaceholder from '../../StatsTreePlaceholder';
import CommonStatsCards from './CommonStatsCards';
import {isBuildingFiltersSetUp} from '@src/store/modules/settings/pages/statistics/building/selectors/filters';

const ByObjectsStats = () => {
	const statsByObjects = useAppSelector(aggregateByObjects);
	const filters = useAppSelector(s => s.settings.pages.statistics.building.filters);
	const isFiltersSetUp = useAppSelector(isBuildingFiltersSetUp);
	const objects = useFilteredObjects(statsByObjects, filters, isFiltersSetUp);
	const loading = useAppSelector(areCreationStatsLoading);

	return (
		<>
			{objects?.map(object => (
				<ObjectStats
					key={object.id}
					data={object}
					objectsStats={statsByObjects}
				/>
			))}

			{loading && !objects?.length && (
				<StatsTreePlaceholder
					includeImage
					includeChildren
					permanentContent={<CommonStatsCards/>}
				/>
			)}
		</>
	);
};

export default ByObjectsStats;
