import React, {cloneElement, Component} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

function validateLabel(props, propName, componentName) {
	if (process.env.NODE_ENV !== 'production') {
		if (!props.children && (props.label !== 0 && !props.label) && !props.icon) {
			return new Error(`Required prop label or children or icon was not specified in ${componentName}.`);
		}
	}
}

class Button extends Component {
	static propTypes = {
		active: PropTypes.bool,
		/**
		 * The content of the button.
		 * If a label is provided via the `label` prop, the text within the label
		 * will be displayed in addition to the content provided here.
		 */
		children: PropTypes.node,
		/**
		 * The CSS class name of the root element.
		 */
		className: PropTypes.string,
		/**
		 * If true, the element's ripple effect will be disabled.
		 */
		disableTouchRipple: PropTypes.bool,
		/**
		 * If true, the button will be disabled.
		 */
		disabled: PropTypes.bool,
		/**
		 * The URL to link to when the button is clicked.
		 */
		href: PropTypes.string,
		/**
		 * An icon to be displayed within the button.
		 */
		icon: PropTypes.node,
		/**
		 * The label to be displayed within the button.
		 * If content is provided via the `children` prop, that content will be
		 * displayed in addition to the label provided here.
		 */
		label: validateLabel,
		/**
		 * The position of the button's label relative to the button's `children`.
		 */
		labelPosition: PropTypes.oneOf([
			'before',
			'after'
		]),
		/**
		 * Callback function fired when the button is clicked.
		 *
		 * @param {object} event TouchTap event targeting the button.
		 */
		onClick: PropTypes.func,
		/** @ignore */
		onMouseDown: PropTypes.func,
		/** @ignore */
		onMouseEnter: PropTypes.func,
		/** @ignore */
		onMouseLeave: PropTypes.func,
		/** @ignore */
		onMouseUp: PropTypes.func,
		/** @ignore */
		onTouchEnd: PropTypes.func,
		/** @ignore */
		onTouchStart: PropTypes.func,
		/**
		 * If true, the button will use the theme's primary color.
		 */
		primary: PropTypes.bool,
		/**
		 * If true, the button will use the theme's secondary color.
		 * If both `secondary` and `primary` are true, the button will use
		 * the theme's primary color.
		 */
		secondary: PropTypes.bool
	};

	static defaultProps = {
		disabled: false,
		labelPosition: 'after',
		primary: false,
		secondary: false
	};

	state = {
		hovered: false,
		keyboardFocused: false,
		touched: false
	};

	handleMouseDown = event => {
		if (this.props.onMouseDown) {
			this.props.onMouseDown(event);
		}
	};

	handleMouseUp = event => {
		if (this.props.onMouseUp) {
			this.props.onMouseUp(event);
		}
	};

	handleMouseEnter = event => {
		if (!this.state.keyboardFocused && !this.state.touched) {
			this.setState({
				hovered: true
			});
		}
		if (this.props.onMouseEnter) {
			this.props.onMouseEnter(event);
		}
	};

	handleMouseLeave = event => {
		if (!this.state.keyboardFocused) {
			this.setState({
				hovered: false
			});
		}
		if (this.props.onMouseLeave) {
			this.props.onMouseLeave(event);
		}
	};

	handleTouchStart = event => {
		this.setState({
			touched: true
		});

		if (this.props.onTouchStart) {
			this.props.onTouchStart(event);
		}
	};

	handleTouchEnd = event => {
		this.setState({
			touched: true
		});

		if (this.props.onTouchEnd) {
			this.props.onTouchEnd(event);
		}
	};

	handleKeyboardFocus = (event, keyboardFocused) => {
		this.setState({
			keyboardFocused
		});
	};

	handleClick = e => {
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	};

	handleTap = e => {
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	};

	componentWillMount() {

	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.disabled) {
			this.setState({
				hovered: false
			});
		}
	}

	render() {
		const {
			active,
			children,
			className,
			icon,
			label,
			labelPosition
		} = this.props;

		const labelElement = label && (
			<span
				key="labelElement"
				className="btn__label"
			>
				{label}
			</span>
		);

		const iconCloned = icon && cloneElement(icon, {
			key: 'iconCloned'
		});

		// Place label before or after children.
		const buttonChildren = labelPosition === 'before'
			? [
				labelElement,
				iconCloned,
				children
			] : [
				children,
				iconCloned,
				labelElement
			];

		return (
			<button
				className={classNames('btn', className, {btn_active: active})}
				onClick={this.handleClick}
			>
				{buttonChildren}
			</button>
		);
	}
}

export default Button;
