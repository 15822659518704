import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {toCheck, toListCheck} from './utils/convertToLocalSave';
import {useLocalChecks} from '../../queries/checks/useGetChecks';
import {addCheckActions} from '@src/store/modules/entities/check/actions';
import {useAddCheckMutation} from '@src/api/cache/checks/mutations';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {IAddCheckParams} from '@src/api/cache/checks/defaults/useChecksMutationDefaults';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';

export const useAddCheck = (object: IObject) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const user = useAppSelector(s => s.auth.profile);
	const nextNumber = useNextLocalNumber<IListCheck>(useLocalChecks(), 'createdAt');
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const mutation = useAddCheckMutation();
	return ({objectId, links, stage}: Omit<IAddCheckParams, 'key'>) => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}
		const converted = toCheck(user, objectId, links, stage, key, nextNumber);
		const convertedtoList = toListCheck(object, user, links, stage, key, nextNumber);

		setTimeout(
			() =>
				queryClient.setQueryData(
					[...checksQueryKeys.detail(key), objectId],
					convertedtoList
				),
			0
		);
		if (!online && isOfflineModeAvailable) {
			addWarningToast('Локальное сохранение', 'проверка добавлена локально');
			setTimeout(() => {
				void queryClient.refetchQueries(checksQueryKeys.localList());
			}, 0);
			dispatch(addCheckActions.success(converted));
		}
		mutation.mutate({
			objectId,
			links,
			stage,
			key
		});
		return converted;
	};
};
