import {wsConnector} from '../wsConnector';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';

export type IAddSpaceStatusResponse = ISpaceStatus;

/**
 * Добавляет статус помещения
 * 
 * @param spaceStatus статус помещения
 */
export const requestAddSpaceStatus = (spaceStatus: ISavingSpaceStatus) =>
	wsConnector.sendAuthorizedRequest<IAddSpaceStatusResponse>('addSpaceStatus', {
		...spaceStatus
	});
