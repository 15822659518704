import React from 'react';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {WorkingGroupsPage} from '@src/pages/manage/WorkingGroupsPage/WorkingGroupsPage';
import {WorkingGroupEditingPage} from '@src/pages/manage/WorkingGroupEditingPage/WorkingGroupEditingPage';

export const manageWorkingGroupsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<WorkingGroupsPage/>}/>
	},
	{
		path: 'add',
		element: <PrivateRoute element={<WorkingGroupEditingPage/>}/>
	},
	{
		path: ':workingGroupId',
		element: <PrivateRoute element={<WorkingGroupEditingPage/>}/>
	}
];
