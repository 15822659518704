import * as types from '../constants';
import {ProblemCommentsVisibility} from '../reducers/comments';

/**
 * Изменяет фильтр отображения ответов и комментариев
 *
 * @param value значение фильтра
 */
export const changeCommentsVisibility = (value: ProblemCommentsVisibility[]) => ({
	type: types.CHANGE_COMMENTS_VISIBILITY,
	payload: value
});
