import React, {useCallback} from 'react';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useWorkAcceptanceExport} from '@src/core/hooks/export/useWorkAcceptanceExport';
import {IconButton} from '@tehzor/ui-components';
import {MobileMenu} from '../actions/Menu.mobile';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface IMobileControlsProps {
	workAcceptance: IWorkAcceptance;
	checkLists: ICheckList[];
	objectId: string;
	permissions?: IPermissions;
}

const exportIcon = <i className="tz-export-20"/>;

export const MobileControls = ({
	workAcceptance, checkLists, objectId, permissions
}: IMobileControlsProps) => {
	const checkListsIds = checkLists.map(({id}) => id);
	const [exportDialog, openExportDialog] = useWorkAcceptanceExport(objectId, checkListsIds);

	const handleExport = useCallback(() => {
		openExportDialog(workAcceptance.id);
	}, [workAcceptance.id, openExportDialog]);

	return (
		<>
			{permissions?.canExport && (
				<IconButton onClick={handleExport}>
					{exportIcon}
				</IconButton>
			)}
			{permissions?.canExport && exportDialog}
			<MobileMenu
				workAcceptance={workAcceptance}
				permissions={permissions}
			/>
		</>
	);
};