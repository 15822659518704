/* eslint-disable object-shorthand */

// С помощью DefinePlugin заносятся данные переменные, нельзя использовать сокращенное присваивание в объекте
declare const appUrl: string | undefined;
declare const appName: string | undefined;
declare const appVersion: string | undefined;
declare const apiUrl: string | undefined;
declare const newApiUrl: string | undefined;
declare const newWsApiUrl: string | undefined;
declare const filesStorageUrl: string | undefined;
declare const authProviders: unknown | undefined;

export const config = {
	appId: 'web_client',
	appSecret: 'a202a2fab46598599dc7108bb991d404',
	appUrl: appUrl,
	appName: appName,
	appVersion: appVersion,
	apiUrl: apiUrl,
	newApiUrl: newApiUrl,
	newWsApiUrl: newWsApiUrl,
	filesStorageUrl: filesStorageUrl,
	authProviders: authProviders,
	entitiesCacheTime: 300,
	problemDefaultFixTime: 1080,
	regStandardExternalLink:
		'https://www.faufcc.ru/technical-regulation-in-constuction/formulary-list/?s='
};

export default config;
