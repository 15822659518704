import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export const extractObjectFieldsSettings = new Map<ObjectStageIds, keyof IObjectFieldsSettings>([
	[ObjectStageIds.BUILDING, 'buildingProblem'],
	[ObjectStageIds.ACCEPTANCE, 'acceptanceProblem'],
	[ObjectStageIds.TRANSFER, 'acceptanceClaimProblem'],
	[ObjectStageIds.WARRANTY, 'warrantyClaimProblem']

]);
