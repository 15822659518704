import React, {useCallback, useRef, useState} from 'react';
import './AddingInternalAcceptanceDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IAddingProblemRefProps} from '../AddingProblem';
import {IAddingInspectionRefProps} from '../AddingInspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {useEntityType} from './hooks/useEntityType';
import {useAvailableTypes} from './hooks/useAvailableTypes';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {useInternalAcceptanceMaps} from './hooks/useInternalAcceptanceMaps';
import {saveInternalAcceptance} from './utils/saveInternalAcceptance';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import {useAddInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useAddInternalAcceptance';
import {useLocalInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/useGetInternalAcceptances';
import {getUserInternalAcceptance} from '@src/store/modules/dictionaries/latestUserInternalAcceptances/actions/getOne';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';

interface IAddingInternalAcceptanceDialogProps {
	objectId: string;
	internalAcceptanceId?: string;
	links?: IInternalAcceptance['links'];
	types?: IInternalAcceptanceAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	problemToCopyId?: string;
	createdBy?: string;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const AddingInternalAcceptanceDialog = (props: IAddingInternalAcceptanceDialogProps) => {
	const {
		objectId,
		internalAcceptanceId,
		links,
		types,
		isOpen,
		problemToCopyId,
		createdBy,
		onSuccess,
		onClose
	} = props;

	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();
	const [selectedInternalAcceptanceId, setSelectedInternalAcceptanceId] = useState<
		string | undefined
	>(internalAcceptanceId);

	const addInternalAcceptance = useAddInternalAcceptance(object);
	const localInternalAcceptances = useLocalInternalAcceptances();
	const localInternalAcceptancesIds = localInternalAcceptances.map(acpt => acpt.id);

	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingInspectionRefProps>(null);

	const availableTypes = useAvailableTypes(objectId, types, {createdBy});
	const type = useEntityType(objectId, availableTypes);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const stage = ObjectStageIds.ACCEPTANCE;
	const stages = useAppSelector(extractObjectStagesAsArray);
	const [contentMap, entitiesSelectMapProps] = useInternalAcceptanceMaps({
		objectId,
		internalAcceptanceId,
		stage,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedInternalAcceptanceId,
		setSelectedInternalAcceptanceId,
		defaultProblemData: props.defaultProblemData,
		defaultInspectionData: props.defaultInspectionData,
		onClose
	});

	const [, handleSave] = useAsyncFn(async () => {
		const addNewInternalAcceptance = () =>
			addInternalAcceptance({
				objectId,
				links,
				stage
			});
		const updateLatest = async () => {
			if (
				selectedInternalAcceptanceId &&
				!localInternalAcceptancesIds.includes(selectedInternalAcceptanceId)
			) {
				await dispatch(getUserInternalAcceptance(objectId, selectedInternalAcceptanceId));
			}
		};
		await saveInternalAcceptance({
			addingEntityRef,
			selectedInternalAcceptanceId,
			localInternalAcceptancesIds,
			online,
			updateLatest,
			addNewInternalAcceptance,
			setSaving,
			success,
			onClose
		});
	}, [objectId, links, stage, selectedInternalAcceptanceId, online, setSaving, success, onClose]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [type, onSuccess]);

	return (
		<Dialog
			className={{
				root: 'adding-internal-acceptance-dialog',
				header: 'adding-internal-acceptance-dialog__header',
				body: 'adding-internal-acceptance-dialog__body'
			}}
			isOpen={isOpen}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			<EntitySelectContainer
				contentMap={contentMap}
				entitiesSelectMapProps={entitiesSelectMapProps}
				selectedStage={stage}
				stages={stages}
			/>
		</Dialog>
	);
};

export default AddingInternalAcceptanceDialog;
