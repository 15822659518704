import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {IPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import React, {memo, useCallback} from 'react';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {useDeleteContract} from '@src/core/hooks/mutations/contracts/useDeleteContract';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMenuProps {
	contract: IPreparedContract;
	permissions: IPermissions;

	openEditDialog?: () => void;
}

const editIcon = <i className="tz-edit" />;
const deleteIcon = <i className="tz-delete" />;

export const Menu = memo(({
	contract, permissions, openEditDialog
}: IMenuProps) => {
	const isLargeTablet = useIsLargeTablet();
	const {pushPath} = useChangePath();

	const deleteContract = useDeleteContract();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить договор',
		'Вы действительно хотите удалить договор?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const updateData = useAppUpdateMenuItem();

	const handleDeleteClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteContract({contractId: contract.id});
			pushPath('/contracts/');
		}
	}, [contract.id, deleteContract]);

	const items = [];
	if (!isLargeTablet) {
		items.push(updateData);
	}

	if (permissions.canEdit && !isLargeTablet) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openEditDialog}
			>
				Редактировать
			</MenuItem>
		);
	}

	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{permissions.canDelete && deleteDialog}
		</>
	) : null;
});