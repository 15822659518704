import {wsConnector} from '../../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export type IGetInternalAcceptanceInspectionsResponse = INormalizedData<IInspection>;

/**
 * Возвращает осмотры для конкретной внутренней приёмки
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 */
export const requestInternalAcceptanceInspections = (objectId: string, internalAcceptanceId: string) =>
	wsConnector.sendAuthorizedRequest<IGetInternalAcceptanceInspectionsResponse>('getInternalAcceptanceInspections', {
		objectId,
		internalAcceptanceId
	});
