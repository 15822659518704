import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getEmailsArray from '@src/utils/getEmailsArray';
import {queryClient} from '@src/api/QueryClient';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';

/**
 * Отправляет запрос на экспорт проверок
 */
export const makeChecksExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId?: string,
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	selected?: string[]
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId,
		filters: {
			...filters,
			selected
		},
		sort: convertSortParam(sort)
	};
	void queryClient.invalidateQueries(documentsQueryKeys.list());
	const response = await httpRequests.withToken.get<IExportResponse>('checks/export', {params});
	return response.data;
};
