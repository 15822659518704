import React from 'react';
import SwitchBase, {ISwitchIconProps} from '../../SwitchBase';
import RadioButtonIcon from './components/RadioButtonIcon';
import {RadioButtonGroupContext} from '../RadioButtonGroup';

export interface IRadioButtonProps {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				icon?: string;
				content?: string;
		  };
	style?: React.CSSProperties;
	children?: React.ReactNode;
	id?: string;
	name?: string;
	label?: string;
	value?: string;
	checked?: boolean;
	disabled?: boolean;
	icon?: React.ReactElement<ISwitchIconProps>;

	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const RadioButton = ({icon, ...rest}: IRadioButtonProps) => (
	<SwitchBase
		{...rest}
		type="radio"
		switchIcon={icon || <RadioButtonIcon/>}
		GroupContext={RadioButtonGroupContext}
	/>
);

RadioButton.displayName = 'RadioButton';

export default RadioButton;
