import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export interface IEditableCheckItem {
	id: string;
	name?: string;
	description?: string;
	dependencies?: string[];
	parentId?: string;
}

export type IEditableCheckListState = IEditableEntityState<{
	name?: string;
	companyId?: string;
	objects?: string[];
	stage?: ObjectStageIds;
	spaceTypes?: SpaceTypeId[];
	structureType?: string;
	type?: CheckListTypeId;
	items?: IEditableCheckItem[];
	typeDecoration?: string[];
	categories?: string[];
}>;

export type IEditableCheckListAction = IEditableEntityAction<
	IEditableCheckListState,
	{checkList?: ICheckList, checkItems?: ICheckItem[]}
>;

const makeEmptyState = (): IEditableCheckListState => ({
	name: undefined,
	companyId: undefined,
	objects: [],
	stage: undefined,
	spaceTypes: [],
	structureType: undefined,
	type: undefined,
	typeDecoration: [],
	categories: [],
	items: [],
	errors: {
		name: false,
		companyId: false,
		type: false,
		typeDecoration: false,
		spaceTypes: false,
		structureType: false,
		items: false
	}
});

export const init = ({
	checkList,
	checkItems
}: {
	checkList?: ICheckList;
	checkItems?: ICheckItem[];
}): IEditableCheckListState => {
	const empty = makeEmptyState();
	return checkList
		? {
				name: checkList.name,
				companyId: checkList.companyId,
				objects: checkList.objects,
				stage: checkList.stage,
				spaceTypes: checkList.spaceTypes,
				structureType: checkList.structureType,
				type: checkList.type,
				typeDecoration: checkList.typeDecoration,
				categories: checkList.categories,
				items: checkItems?.map(checkItem => ({
					id: checkItem.id,
					name: checkItem.name,
					parentId: checkItem.parentId,
					description: checkItem.description,
					dependencies: checkItem.dependencies
				})),
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (
	state: IEditableCheckListState,
	original?: ICheckList | {items: ICheckItem[]}
): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'companyId'),
		isPropEdited.bind(null, 'objects'),
		isPropEdited.bind(null, 'spaceTypes'),
		isPropEdited.bind(null, 'structureType'),
		isPropEdited.bind(null, 'type'),
		isPropEdited.bind(null, 'stage'),
		isPropEdited.bind(null, 'typeDecoration'),
		isPropEdited.bind(null, 'categories'),
		isPropEdited.bind(null, 'items')
	);

export const errorsFns = {
	name: (state: IEditableCheckListState) => !state.name,
	companyId: (state: IEditableCheckListState) => !state.companyId,
	objects: (state: IEditableCheckListState) => !state.objects,
	stage: (state: IEditableCheckListState) => !state.stage,
	spaceTypes: (state: IEditableCheckListState) => !state.spaceTypes?.length,
	structureType: (state: IEditableCheckListState) => !state.structureType,
	type: (state: IEditableCheckListState) => !state.type,
	items: (state: IEditableCheckListState) => !state.items?.length,
	typeDecoration: (state: IEditableCheckListState) => !state.typeDecoration?.length,
	categories: (state: IEditableCheckListState) => !state.categories?.length
};

export const convertCheckListToSave = (
	state: IEditableCheckListState,
	original?: ICheckList,
	onlyEdited?: boolean
): ISavingCheckList => {
	if (!onlyEdited) {
		return state;
	}

	const checkList: ISavingCheckList = {};

	if (isPropEdited('name', state, original)) {
		checkList.name = state.name;
	}
	if (isPropEdited('companyId', state, original)) {
		checkList.companyId = state.companyId;
	}
	if (isPropEdited('objects', state, original)) {
		checkList.objects = state.objects;
	}
	if (isPropEdited('stage', state, original)) {
		checkList.stage = state.stage;
	}
	if (isPropEdited('spaceTypes', state, original)) {
		checkList.spaceTypes = state.spaceTypes;
	}
	if (isPropEdited('structureType', state, original)) {
		checkList.structureType = state.structureType;
	}
	if (isPropEdited('type', state, original)) {
		checkList.type = state.type;
	}
	if (isPropEdited('typeDecoration', state, original)) {
		checkList.typeDecoration = state.typeDecoration;
	}
	if (isPropEdited('categories', state, original)) {
		checkList.categories = state.categories;
	}

	return checkList;
};

export const convertCheckItemsToSave = (
	state: IEditableCheckListState,
	original?: ICheckItem[],
	onlyEdited?: boolean
): ISavingCheckItem[] => {
	if (!onlyEdited) {
		return state.items || [];
	}

	const checkItems: ISavingCheckItem[] = [];

	if (isPropEdited('items', state, {items: original})) {
		checkItems.push(...(state.items || []));
	}

	return checkItems;
};
