import React, {memo, useRef} from 'react';
import PageBreadcrumbs from './PageBreadcrumbs';
import DesktopAttachments from './attachments/Attachments.desktop';
import {DesktopControls} from './Controls.desktop';
import Info from './info/Info.desktop';
import DesktopComments from './comments/Comments.desktop';
import {useCommentsPermissions} from '../hooks/useCommentsPermissions';
import {Plate, AdaptiveHeightPanel} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemCommentsAsTree} from '@src/store/modules/pages/problem/selectors/comments';
import {useProblemAndCommentsViewer} from '@src/core/hooks/imagesViewers/desktop/useProblemAndCommentsViewer';
import {EntityLinks} from '@src/components/EntityLinks/EntityLinks';
import {extractHasLocationComments} from '@src/store/modules/pages/problem/selectors/problem';
import {IPermissions} from '../hooks/usePermissions';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {DesktopEntityDocs} from '@src/components/EntityDocs';
import {ContractLink} from '@src/components/EntityLinks/components/ContractLink';

interface IDesktopProps {
	objectId: string;
	problemId: string;
	problem?: IPreparedProblem;
	permissions: IPermissions;
	settings?: IProblemSettings;
}

const Desktop = memo(({objectId, problemId, problem, permissions, settings}: IDesktopProps) => {
	const pageRef = useRef<HTMLDivElement>(null);
	const commentsPermissions = useCommentsPermissions(objectId, problem);
	const comments = useAppSelector(extractProblemCommentsAsTree);
	const hasLocationComments = useAppSelector(extractHasLocationComments);

	const [imagesViewer, openProblemImage, openCommentImage] = useProblemAndCommentsViewer(
		objectId,
		problemId,
		problem,
		comments
	);

	if (!problem) {
		return null;
	}

	return (
		<div
			className="page-cont problem-page"
			ref={pageRef}
		>
			<PageBreadcrumbs objectId={objectId} />

			<div className="problem-page__d-columns">
				<div className="problem-page__d-column problem-page__d-column_attachments">
					{settings?.attachments && (
						<DesktopAttachments
							problem={problem}
							onImageClick={openProblemImage}
						/>
					)}

					<DesktopEntityDocs id={problemId} />

					<div style={{marginTop: '24px'}}>
						{/* TODO убрать обертку */}
						<Plate withoutPadding>
							<EntityLinks links={problem.links ? {objectId, ...problem.links} : {}}>
								{problem.contractId && permissions.canViewContract && (
									<ContractLink contractId={problem.contractId} />
								)}
							</EntityLinks>
						</Plate>
					</div>
				</div>

				<div className="problem-page__d-column problem-page__d-column_info">
					<DesktopControls
						objectId={objectId}
						problem={problem}
						permissions={permissions}
					/>

					<Plate>
						<Info
							objectId={objectId}
							problem={problem}
							hasLocationComments={hasLocationComments}
							permissions={permissions}
							settings={settings}
						/>
					</Plate>
				</div>

				<div className="problem-page__d-column problem-page__d-column_comments">
					{(commentsPermissions.canViewComments ||
						commentsPermissions.canViewReplies) && (
						<AdaptiveHeightPanel pageRef={pageRef.current}>
							<Plate
								className={{
									root: 'problem-page__d-comments-plate',
									content: 'problem-page__d-comments-plate-content'
								}}
							>
								<DesktopComments
									objectId={objectId}
									problem={problem}
									links={problem?.links}
									comments={comments}
									onImageClick={openCommentImage}
								/>
							</Plate>
						</AdaptiveHeightPanel>
					)}
				</div>

				{imagesViewer}
			</div>
		</div>
	);
});

export default Desktop;
