import {
	IWarrantyClaimsFiltersState,
	IWarrantyClaimsSortState
} from '@src/store/modules/settings/pages/warrantyClaims/reducers';

export type IWarrantyClaimsListQueryKey = [] & {
	0: string;
	1: string;
	2: IWarrantyClaimsFiltersState;
	3: IWarrantyClaimsSortState;
	4: number;
	5: number;
};
export const warrantyClaimsQueryKeys = {
	all: () => ['warranty-claims'],
	one: () => ['warranty-claim'],
	list: () => [...warrantyClaimsQueryKeys.all(), 'list'],
	latest: () => [...warrantyClaimsQueryKeys.all(), 'latest-warranty-claims'],
	localList: () => [...warrantyClaimsQueryKeys.all(), 'local-list'],
	add: () => [...warrantyClaimsQueryKeys.one(), 'add'],
	details: () => [...warrantyClaimsQueryKeys.one(), 'detail'],
	detail: (id: string) => [...warrantyClaimsQueryKeys.one(), 'detail', id]
};
