import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import React from 'react';
import {useParams} from 'react-router-dom';

export const CompaniesFilterPage = () => {
	const {entity = 'contracts'} = useParams<{entity?: string}>();
	const companies = useAppSelector(extractCompaniesAsArray);

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={companies}
			label="Заказчики"
			filterName="companyIds"
		/>
	);
};