import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import isEqual from 'lodash/isEqual';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export type IEditableInspectionState = IEditableEntityState<{
	planId?: string;
	location?: ILocation;
	floor: string;
	description: string;
	attachments: IAttachment[];
}>;

export type IEditableInspectionAction = IEditableEntityAction<
	IEditableInspectionState,
	ISavingInspection
>;

const makeEmptyState = (): IEditableInspectionState => ({
	planId: undefined,
	location: undefined,
	floor: '',
	description: '',
	attachments: [],
	errors: {
		planId: false,
		location: false,
		floor: false,
		description: false,
		attachments: false
	}
});

export const makeDefaultData = (inspection?: IInspection): ISavingInspection | undefined => {
	if (inspection) {
		return {
			planId: inspection.plan?.id,
			location: inspection.location,
			floor: inspection.floor,
			description: inspection.description,
			attachments: inspection.attachments
		};
	}
	return undefined;
};

export const init = (original?: ISavingInspection): IEditableInspectionState => {
	const empty = makeEmptyState();
	return original
		? {
				planId: original.planId ?? undefined,
				location: original.location ?? undefined,
				floor: original.floor ?? '',
				description: original.description ?? '',
				attachments: original.attachments ?? [],
				errors: empty.errors
		  }
		: empty;
};

const isPlanEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	(original?.planId ? original.planId !== state.planId : !!state.planId);

const isLocationEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	(original?.location ? !isEqual(original.location, state.location) : !!state.location);

const areFloorEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	(original?.floor ? original.floor !== state.floor : !!state.floor);

const isDescriptionEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	(original?.description ? original.description !== state.description : !!state.description);

const areAttachmentsEdited = (state: IEditableInspectionState, original?: ISavingInspection) =>
	(original?.attachments ? original.attachments.length !== state.attachments.length : false);

/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableInspectionState, original?: ISavingInspection): boolean =>
	isEntityEdited(
		state,
		original,
		isPlanEdited,
		isLocationEdited,
		areFloorEdited,
		isDescriptionEdited,
		areAttachmentsEdited
	);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	planId: (state: IEditableInspectionState) => !state.planId,
	location: (state: IEditableInspectionState) => !state.location,
	floor: (state: IEditableInspectionState) => !state.floor,
	description: (state: IEditableInspectionState) => !state.description,
	attachments: (state: IEditableInspectionState) => !state.attachments.length
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableInspectionState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableInspectionState,
	original?: ISavingInspection,
	onlyEdited?: boolean
): ISavingInspection => {
	if (!onlyEdited) {
		return {
			planId: state.planId,
			location: state.location || null,
			floor: state.floor,
			description: state.description,
			attachments: state.attachments
		};
	}
	const inspection: ISavingInspection = {};
	if (isPlanEdited(state, original)) {
		inspection.planId = state.planId;
	}
	if (isLocationEdited(state, original)) {
		inspection.location = state.location || null;
	}
	if (areFloorEdited(state, original)) {
		inspection.floor = state.floor || undefined;
	}
	if (isDescriptionEdited(state, original)) {
		inspection.description = state.description;
	}
	if (areAttachmentsEdited(state, original)) {
		inspection.attachments = state.attachments.map(item => ({id: item.id}));
	}
	return inspection;
};
