import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';
import {
	ILoadableEntitiesState,
	handleLoadableEntitiesGetting,
	getLoadableEntitiesInitialState
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IRegulatoryStandardsState = ILoadableEntitiesState<IRegulatoryStandard>;

export const regulatoryStandards = createReducer<IRegulatoryStandardsState>(
	getLoadableEntitiesInitialState<IRegulatoryStandard>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IRegulatoryStandard>(),
		[CLEAR_STORE]: getLoadableEntitiesInitialState
	}
);
