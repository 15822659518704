import {IGetProblemsResponse} from '@src/api/backend/problems';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {
	IProblemsFiltersState,
	IProblemsPageSettingsState
} from '@src/store/modules/settings/pages/problems/reducers';
import {convertLocalAttachments} from '@src/utils/convertLocalAttachments';
import {addErrorToast} from '@src/utils/toasts';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import {useMemo} from 'react';
import {ISavingProblemQuery} from '../../mutations/problem/useAddProblem';
import useAppSelector from '../../useAppSelector';

export const useProblemsPageSettings = (objectId: string) => {
	const entities = useAppSelector(s => s.entities.problems);
	const settings = useAppSelector(s => s.settings.pages.problems);

	const pageSettings: IProblemsPageSettingsState & {offset: number} = useMemo(() => {
		const offset = entities[objectId]?.offset || 0;
		const filters = settings[objectId]?.filters;
		const sort = settings[objectId]?.sort;
		const pageSize = settings[objectId]?.pageSize;
		return {offset, filters, sort, pageSize};
	}, [objectId, entities, settings]);
	return pageSettings;
};

export const useProblems = (objectId: string, state?: IProblemsFiltersState) => {
	const {offset, filters, sort, pageSize} = useProblemsPageSettings(objectId);
	const {data} = useQuery<IGetProblemsResponse, IError>({
		queryKey: [...problemsQueryKeys.list(), state || filters, sort, offset, pageSize],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке списка нарушений');
		}
	});
	return data;
};

export const useLocalProblems = (objectId = 'all') => {
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const {data} = useQuery<IListProblem[], IError>({
		queryKey: [...problemsQueryKeys.localList(), objectId],
		staleTime: Infinity,
		cacheTime: Infinity,
		networkMode: 'always',
		enabled: isOfflineModeAvailable
	});
	return data || [];
};

export const problemsList = (data?: IGetProblemsResponse) =>
	data?.allIds.map(id => {
		if (data.byId[id].localNumber) {
			return {
				...data.byId[id],
				attachments: convertLocalAttachments<ISavingProblemQuery>(id, problemsQueryKeys)
			};
		}
		return data.byId[id];
	});
