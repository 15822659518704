import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {IAddCheckItemsResponse, makeCheckItemsAddRequest} from '@src/api/backend/checkItems';

export type IAddCheckItemsPayload = IAddCheckItemsResponse;

const request = () => ({type: types.ADD_BATCH_REQUEST});

const success = (checkListId: string, response: IAddCheckItemsResponse) => ({
	type: types.ADD_BATCH_SUCCESS,
	payload: {items: response, checkListId}
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении категории');
	return {
		type: types.ADD_BATCH_FAILURE,
		payload: error
	};
};

/**
 * Добавляет несколько категорий за раз
 *
 * @param checkListId идентификатор чек-листа
 * @param fields данные для добавления
 */
export const addCheckItems = (checkListId: string, fields: ISavingCheckItem[]) =>
	createApiAction<IAddCheckItemsResponse>(
		request,
		(res: IAddCheckItemsResponse) => success(checkListId, res),
		failure,
		() => makeCheckItemsAddRequest(checkListId, fields)
	);
