/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState} from 'react';
import {IEnrichedTaskForScheduler} from './useEnrichedTasksForSchedule';
import {IGroupTask} from '../utils/groupTasksByStatusAndType';
import {useTasksSchedulePopup} from '@src/components/TasksSchedulePopup/hooks/useTasksSchedulePopup';
import declination from '@tehzor/tools/utils/declination';

interface IUseMonthTasksProps {
	groupedTasks?: IGroupTask[];
	hiddenNumber: number;
	objectId?: string;
	startTime?: number;
	endTime?: number;
	popupTitle?: string;
}

export const useMonthTasks = (props: IUseMonthTasksProps) => {
	const {groupedTasks = [], hiddenNumber, objectId, startTime, endTime, popupTitle} = props;
	const [currentTasks, setCurrentTasks] = useState<IEnrichedTaskForScheduler[]>([]);

	const [TasksSchedulePopup, openTasksSchedulePopup] = useTasksSchedulePopup(
		currentTasks,
		popupTitle,
		objectId,
		startTime,
		endTime
	);

	const visibleGroupedTasks = groupedTasks.slice(0, hiddenNumber);

	const notVisibleTasks = groupedTasks.slice(hiddenNumber)
		.reduce((acc, groupedTasks) => [...acc, ...groupedTasks.tasks], []);

	const tasksCountStr = groupedTasks.length - hiddenNumber > 0
		&& `+ ${notVisibleTasks.length} ${declination(notVisibleTasks.length, ['задача', 'задачи', 'задач'])}`;

	const handleOpenTasksSchedulePopup = (tasks: IEnrichedTaskForScheduler[]) => {
		setCurrentTasks(tasks);
		openTasksSchedulePopup();
	};

	const handleOpenNotVisibleTasksSchedulePopup = () => {
		setCurrentTasks(notVisibleTasks);
		openTasksSchedulePopup();
	};

	return {
		tasks: (
			<>
				{visibleGroupedTasks.map((task, i) => (
					<div
						key={i}
						style={{
							backgroundColor: task.scheduleTableColor,
							borderLeft: `4px solid ${task.taskTypeColor}`
						}}
						className="manage-task-schedule-page__grouped-tasks_item"
						onClick={() => handleOpenTasksSchedulePopup(task.tasks)}
					>
						{task.count}
					</div>
				))}
				{tasksCountStr && (
					<span
						onClick={handleOpenNotVisibleTasksSchedulePopup}
						className="manage-task-schedule-page__grouped-tasks_item-task"
					>
						{tasksCountStr}
					</span>
				)}
				{TasksSchedulePopup}
			</>
		)
	};
};