import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {Column} from 'react-table';
import DeleteCell from './cells/DeleteCell';
import RelationsCell from './cells/RelationsCell';
import NameCell from './cells/NameCell';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import StagesCell from './cells/StagesCell';

export const mobileColumns: Array<Column<IFullCategoriesSet>> = [
	{
		Header: 'Название',
		id: 'name',
		accessor: 'name',
		Cell: NameCell,
		width: 100
	},
	{
		Header: 'Компании/Объекты',
		id: 'companies',
		accessor: 'companies',
		Cell: RelationsCell,
		width: 100
	},
	{
		Header: 'Стадии',
		id: 'stages',
		Cell: StagesCell,
		width: 100,
		sortDescFirst: true
	},
	{
		id: 'createdAt',
		Header: 'Создано',
		accessor: row => (row.createdAt ? format(row.createdAt, dateTimeCommaSeparatedFormat) : ''),
		width: 100
	},
	{
		id: '_delete',
		Cell: DeleteCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const mobileColumnsWithoutObjects: Array<Column<IFullCategoriesSet>> = mobileColumns.filter(
	col => col.id !== 'companies'
);
