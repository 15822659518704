import React, {memo} from 'react';
import {Button} from '@tehzor/ui-components';
import './EntitiesFilters.less';
import {useFiltersActive} from './utils/useFiltersActive';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {MobileFiltersLinkBtn} from '@src/components/MobileFiltersLinkBtn';
import classNames from 'classnames';
import {useEntitiesFiltersCtx} from './utils/entitiesFiltersCtx';

interface IEntitiesFiltersProps {
	className?: string;
	children: React.ReactNode;
	objectId?: string;
	entity: string;
}

const EntitiesFilters = (props: IEntitiesFiltersProps) => {
	const {className, children, objectId = 'all', entity} = props;
	const {clear, state} = useEntitiesFiltersCtx();
	const isFiltersActive = useFiltersActive(objectId, state);
	const isDesktop = useIsDesktop();

	// TODO Вынести это отсюда после переделки страниц, где используется фильтр
	if (!isDesktop) {
		return (
			<MobileFiltersLinkBtn
				state={state}
				objectId={objectId}
				entity={entity}
			/>
);
	}
	return (
		<div className={classNames('entities-filters', className)}>
			{children}

			{isFiltersActive ? (
				<Button
					type="cancel"
					label="Сбросить"
					onClick={clear}
				/>
			) : null}
		</div>
	);
};

export default memo(EntitiesFilters);
