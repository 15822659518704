import React, {useCallback} from 'react';
import {IconButton} from '../../buttons';

export interface ISingleSwitchNavigationOption {
	key: string;
	content: React.ReactNode;
}

interface ISingleSwitchNavigationProps {
	className?: string;
	style?: React.CSSProperties;
	options: ISingleSwitchNavigationOption[];
	disabled?: boolean;
	value: string;

	onChange: (key: string) => void;
}

export const SwitchMenuButton = (props: ISingleSwitchNavigationProps) => {
	const {className, style, options, disabled, value, onChange} = props;
	const index = options.findIndex(item => item.key === value);

	const handleClick = useCallback(() => {
		const next = options[(index + 1) % options.length];
		if (next) {
			onChange(next.key);
		}
	}, [index, options, onChange]);

	return (
		<IconButton
			className={className}
			style={style}
			type="common"
			disabled={disabled}
			onClick={handleClick}
		>
			{options[index]?.content}
		</IconButton>
	);
};