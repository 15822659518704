import React, {useCallback, useState} from 'react';
import {StructureSelectDialog} from './StructureSelectDialog';

export const useStructureSelectDialog = (
	structures: Array<{
		id: string;
		name: string;
		type: string;
	}>,
	onSuccess: (value: string) => void | Promise<void>,
	entityAddingDialog?: React.ReactNode | Record<string, never> | null,
	value?: string
) => {
	const [structureSelectDialogIsOpen, setStructureSelectDialogOpen] = useState<boolean>(false);

	const openStructureSelectDialog: () => void = useCallback(() => {
		setStructureSelectDialogOpen(true);
	}, []);

	const closeStructureSelectDialog = useCallback(() => {
		setStructureSelectDialogOpen(false);
	}, []);

	const handleSuccess = (val: string) => {
		closeStructureSelectDialog();
		void onSuccess(val);
	};

	const structureSelectDialog = (
		<>
			<StructureSelectDialog
				structures={structures}
				isOpen={structureSelectDialogIsOpen}
				value={value}
				onClose={closeStructureSelectDialog}
				onSuccess={handleSuccess}
			/>
			{entityAddingDialog}
		</>
	);

	return [structureSelectDialog, openStructureSelectDialog];
};
