export type ISpacesCheckListsDataQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	5: string[];
};

export const spacesQueryKeys = {
	all: () => ['spaces'],
	one: () => ['space'],
	list: () => [...spacesQueryKeys.all(), 'list'],
	details: () => [...spacesQueryKeys.one(), 'detail'],
	detail: (id: string) => [...spacesQueryKeys.one(), 'detail', id],
	checkListsData: () => [...spacesQueryKeys.all(), 'check-lists-data'],
	indicators: {
		edit: () => [...spacesQueryKeys.one(), 'indicators', 'edit'],
		details: () => [...spacesQueryKeys.one(), 'indicators', 'detail'],
		detail: (id: string) => [...spacesQueryKeys.indicators.details(), 'detail', id],
		localList: () => [...spacesQueryKeys.one(), 'indicators', 'local-list']
	},
	status: {
		edit: () => [...spacesQueryKeys.one(), 'status', 'edit'],
		details: () => [...spacesQueryKeys.one(), 'status', 'detail'],
		detail: (id: string) => [...spacesQueryKeys.status.details(), 'detail', id],
		localList: () => [...spacesQueryKeys.one(), 'status', 'local-list']
	}
};
