import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IOwnerAcceptanceStatus} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IOwnerAcceptanceStatusesState = ILoadableEntitiesState<IOwnerAcceptanceStatus>;

export const ownerAcceptanceStatuses = createReducer<IOwnerAcceptanceStatusesState>(
	getLoadableEntitiesInitialState<IOwnerAcceptanceStatus>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IOwnerAcceptanceStatus>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IOwnerAcceptanceStatus>()
	}
);
