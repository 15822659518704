import React, {useCallback, useMemo} from 'react';
import {update} from '@src/store/modules/app/updateData/actions/update';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {MenuItem} from '@tehzor/ui-components';

export const useAppUpdateMenuItem = (): React.ReactElement => {
	const dispatch = useAppDispatch();
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const updateAppData = useCallback(async () => {
		await dispatch(update());
	}, []);
	const updateData = useMemo(() => (
		<MenuItem
			key="update"
			disabled={!online}
			icon={<i className="tz-reload-24"/>}
			onClick={updateAppData}
		>
			Обновить данные
		</MenuItem>
	), [online]);
	return updateData;
};