import React from 'react';

interface ILegendGroupProps {
	title: string;
	items: Array<{id: string, name: string, color: string}>;
}

export const LegendGroup = ({title, items}: ILegendGroupProps) => (
	<div className="objects-page__legend-group">
		<div className="objects-page__legend-group__title">{title}</div>
		{items.map(item => (
			<div
				className="objects-page__legend-group__item"
				key={item.id}
			>
				<i
					className="objects-page__legend-group__item-icon tz-problem-16"
					style={{color: item.color}}
				/>
				{item.name}
			</div>
		))}
	</div>
);