import React, {useCallback} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';
import {IconButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {exportCheckList} from '@src/store/modules/entities/checkList/actions/export';
import {sendExportedCheckList} from '@src/store/modules/entities/checkList/actions/send';

const availableDestinations = [ExportTemplatesDestinationId.CHECKLIST];

interface ICheckListExportDialog {
	objectId: string;
	spaceId: string;
	checkListId: string;
}

const CheckListExportDialog = (props: ICheckListExportDialog) => {
	const {objectId, spaceId, checkListId} = props;
	const [isOpen, toggleOpen] = useToggle(false);

	const dispatch = useAppDispatch();

	const exportList = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (email !== undefined) {
				await dispatch(
					sendExportedCheckList(email, template.id, objectId, spaceId, checkListId, undefined, createDocument)
				);
			} else {
				await dispatch(exportCheckList(template.id, objectId, spaceId, checkListId, undefined, createDocument));
			}
			toggleOpen(false);
		},
		[objectId, spaceId, checkListId]
	);

	const isDesktop = useIsDesktop();

	return (
		<>
			{isDesktop ? (
				<IconButton onClick={toggleOpen}>
					<i className="tz-export-20"/>
				</IconButton>
			) : (
				<IconButton onClick={toggleOpen}>
					<i className="tz-export-24"/>
				</IconButton>
			)}
			<ExportDialog
				pageKey="check-list"
				destinations={availableDestinations}
				isOpen={isOpen}
				onExport={exportList}
				onClose={toggleOpen}
			/>
		</>
	);
};

export default CheckListExportDialog;
