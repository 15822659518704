import React from 'react';
import {CellProps} from 'react-table';
import {IReportsTableRow} from '../../hooks/useReportsTable';

export const NameCell = ({row}: CellProps<IReportsTableRow>) => {
	const name = row.original.name;
	const data = row.original.data;

	return (
		<div className={!data ? 'name-cell-type-header' : 'name-cell-type'}>
			{name}
		</div>
	);
};