import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import React from 'react';
import './TaskIndicator.less';
import {ITaskStatus, TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';

interface TaskIndicatorProps {
	statuses: INormalizedData<ITaskStatus>;
	status: TaskStatusId;
	count: number;
}

export const TaskIndicator = ({statuses, status, count}: TaskIndicatorProps) => {
	const statusEntity = statuses.byId[status];

	return (
		<div className="task-indicator">
			<i
				className="tz-task-16"
				style={{color: statusEntity.color}}
			/>
			<div className="task-indicator__counter">{count}</div>
		</div>
	);
};
