import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IExportTemplatesDestination} from '@tehzor/tools/interfaces/IExportTemplate';

export type IGetExportTemplatesDestinationsResponse = INormalizedData<IExportTemplatesDestination>;

/**
 * Возвращает список типов шаблонов экспорта
 */
export const requestExportTemplatesDestinations = () =>
	wsConnector.sendAuthorizedRequest<IGetExportTemplatesDestinationsResponse>('getExportTemplatesDestinations');
