import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';

export interface IGetSpaceTypeDecorationSetsResponse
	extends INormalizedData<ISpaceTypeDecorationSet> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает наборы типов отделки помещений
 */
export const requestSpaceTypeDecorationSets = () =>
	wsConnector.sendAuthorizedRequest<IGetSpaceTypeDecorationSetsResponse>(
		'getSpaceTypeDecorationSets'
	);
