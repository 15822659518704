import React, {useCallback, useState} from 'react';
import './ExportToast.less';
import {CircleLoader, LinkButton} from '@tehzor/ui-components';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formDocumentLink} from '@tehzor/tools/utils/links';

interface IExportToastProps {
	title?: string;
	description?: string;
	documentRecord?: IDocument;
	url: string;
	fileName: string;
	icon?: React.ReactNode;
	onClose: () => void;
}

const sucсessIcon = <i className="tz-mark-24 export-toast__icon export-toast__success-icon"/>;

export const ExportToast = ({
	title,
	url,
	fileName,
	icon,
	description,
	documentRecord,
	onClose
}: IExportToastProps) => {
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [isDownloading, setIsDownloading] = useState(false);
	const {pushPath} = useChangePath();
	const handleDocument = useCallback(() => {
		if (documentRecord) {
			pushPath(formDocumentLink(documentRecord.id));
			onClose();
		}
	}, [documentRecord, onClose]);

	const handleDownload = () => {
		setIsDownloading(true);
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'blob';

		xhr.addEventListener('progress', event => {
			if (event.lengthComputable) {
				const percentComplete = (event.loaded / event.total) * 100;
				setDownloadProgress(percentComplete);
			}
		});

		xhr.onload = () => {
			if (xhr.status === 200) {
				const blob = xhr.response;
				const downloadUrl = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = downloadUrl;
				a.download = fileName;
				a.click();
			}
			setIsDownloading(false);
		};

		xhr.send();
	};

	return (
		<div className="export-toast__content">
			{downloadProgress === 100 ? (
				sucсessIcon
			) : icon ? (
				<div className="export-toast__icon">{icon}</div>
			) : null}
			<div className="export-toast__text">
				<div className="export-toast__title">
					{downloadProgress === 100 ? 'Скачивание завершено' : title}
				</div>
				<div className="export-toast__description">{description}</div>
				{isDownloading ? (
					<CircleLoader percent={downloadProgress}/>
				) : (
					downloadProgress !== 100 && (
						<div className="export-toast__links">
							{documentRecord ? (
								<LinkButton
									onClick={handleDocument}
									label="Перейти в документ"
								/>
							) : null}

							<LinkButton
								onClick={handleDownload}
								label="Скачать"
							/>
						</div>
					)
				)}
			</div>
		</div>
	);
};
