import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingCheck} from '@tehzor/tools/interfaces/checks/ISavingCheck';
import {IEditCheckResponse, makeCheckEditRequest} from '@src/api/backend/check/edit';

export type IEditCheckPayload = IEditCheckResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditCheckResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении проверки');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет проверку
 *
 * @param objectId id объекта
 * @param checkId id проверки
 * @param fields измененные поля
 */
export const editCheck = (
	objectId: string,
	checkId: string,
	fields: ISavingCheck = {}
) =>
	createApiAction<IEditCheckResponse>(request, success, failure, () =>
		makeCheckEditRequest(objectId, checkId, fields));
