import React, {useCallback, useMemo} from 'react';
import splitAttachments from '@tehzor/tools/utils/splitAttachments';
import {Tree} from 'array-to-tree';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {flattenTree} from '@src/utils/tree';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {getCommentsForProblem} from '@src/store/modules/pages/problem/actions/comments';
import {useEntitiesViewer} from './useEntitiesViewer';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IAttachmentsImageData from '@tehzor/tools/interfaces/IAttachmentsImageData';
import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesDesktopPhotoViewer/EntitiesDesktopPhotoViewer';
import {IPreparedProblem} from '@src/pages/ProblemPage/interfaces/IPreparedProblem';

/**
 * Хук для создания просмотрщика изображений нарушения и комментариев к нему
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param problem нарушение
 * @param comments комментарии
 */
export function useProblemAndCommentsViewer(
	objectId: string,
	problemId: string,
	problem: IPreparedProblem | undefined,
	comments: Array<Tree<IProblemComment>>
): [React.ReactNode, (i: number) => void, (id: string, i: number) => void] {
	// Формирование общего списка изображений
	const data = useMemo(() => {
		const result: IEntity[] = [];
		if (problem?.attachments?.length) {
			const [problemImagesAttachments] = splitAttachments(problem.attachments);
			const problemImages = problemImagesAttachments.reduce<string[]>((prev, item) => {
				if (item.full) {
					prev.push(item.full.url);
				}
				return prev;
			}, []);
			const problemImagesData: IAttachmentsImageData[] = [];
			for (const attachment of problemImagesAttachments) {
				if (attachment.full) {
					problemImagesData.push({
						id: attachment.id,
						url: attachment.full.url,
						canvas: attachment.canvas,
						original: attachment.original?.url
					});
				}
			}
			result.push({
				id: problemId || 'problem',
				title: 'Фото нарушения',
				files: problemImages,
				type: AttachmentFileDestination.PROBLEM,
				attachments: problemImagesData,
				date: problem.createdAt ? new Date(problem.createdAt).getTime() : undefined
			});
		}
		if (comments.length) {
			const items = flattenTree(comments);
			for (let i = items.length - 1; i >= 0; i--) {
				const item = items[i];
				if (item.attachments) {
					const [imagesAttachments] = splitAttachments(item.attachments);
					const files: string[] = [];
					const attachmentsData: IAttachmentsImageData[] = [];
					for (const attachment of imagesAttachments) {
						if (attachment.full) {
							files.push(attachment.full.url);
							attachmentsData.push({
								id: attachment.id,
								url: attachment.full.url,
								canvas: attachment.canvas,
								original: attachment.original?.url
							});
						}
					}
					if (files.length) {
						result.push({
							id: item.id,
							title: item.official
								? item.number
									? `Фото ответа №${item.number}`
									: 'Фото ответа'
								: 'Фото комментария',
							files,
							type: AttachmentFileDestination.COMMENT_OR_REPLY,
							attachments: attachmentsData,
							date: item.createdAt
						});
					}
				}
			}
		}
		return result;
	}, [problem, comments]);

	// Подгрузка комментариев
	const hasMoreComments = useAppSelector(s => s.pages.problem.comments.hasMore);
	const dispatch = useAppDispatch();
	const [, loadComments] = useAsyncFn(async () => {
		await dispatch(getCommentsForProblem(objectId, problemId));
	}, [objectId, problemId]);

	const [viewer, openImage] = useEntitiesViewer(data, false, hasMoreComments, loadComments);

	const openProblemImage = useCallback(
		(index: number) => {
			openImage(problemId, index);
		},
		[openImage, problemId]
	);

	return [viewer, openProblemImage, openImage];
}
