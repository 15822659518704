const domain = 'space-owner';

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const UNBIND_SPACE_REQUEST = `${domain}/unbind-space/request`;
export const UNBIND_SPACE_SUCCESS = `${domain}/unbind-space/success`;
export const UNBIND_SPACE_FAILURE = `${domain}/unbind-space/failure`;
