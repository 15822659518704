import {wsConnector} from '../wsConnector';

export type IDeleteCheckResponse = void;

/**
 * Удаляет проверку
 *
 * @param objectId id объекта
 * @param checkId id проверки
 */
export const makeCheckDeleteRequest = (objectId: string, checkId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteCheckResponse>('deleteCheck', {objectId, checkId});
