export interface ITasksFiltersState extends Record<string, unknown> {
	objects?: string[];
	types?: string[];
	statuses?: string[];
	priorities?: string[];
	taskIntervalStartFrom?: Date;
	taskIntervalStartTo?: Date;
	taskIntervalEndFrom?: Date;
	taskIntervalEndTo?: Date;
	createdAtFrom?: Date;
	createdAtTo?: Date;
	respUsers?: string[];
	createdBy?: string[];
	searchString?: string;
	checkListIds?: string[];
	checkItemIds?: string[];
}

export type ITasksSortState = Record<string, boolean>;

export type ITasksSettingsMode = keyof Omit<ITasksSettingsState, 'currentMode' | 'heights'>;

export type ChangeScheduleValueMode = 'increment' | 'decrement';

export interface ITasksSettingsState {
	currentMode: ITasksSettingsMode;
	year: number;
	month: number;
	week: [number, number];
	day: number;
}
export enum TasksScheduleCollectMode {
	OBJECT = 'object',
	GROUP = 'group'
}