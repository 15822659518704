import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {extractTaskPrioritiesAsArray} from '@src/store/modules/dictionaries/taskPriorities/selectors';
import {useParams} from 'react-router-dom';

export const TasksPrioritiesFilterPage = () => {
	const {entity} = useParams<{entity?: string}>();
	const priorities = useAppSelector(extractTaskPrioritiesAsArray);
	return (
		<StatusesFilterPage
			entity={entity}
			statuses={priorities}
			filterName="priorities"
			label="Статус задачи"
		/>
	);
};
