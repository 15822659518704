import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {
	extractCheckListsAsArrayFilteredBySelectedSpaceType,
	extractCheckListsAsArrayForWorkAcceptances
} from '@src/store/modules/dictionaries/checkLists/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const CheckListIdsFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const spacesCheckLists = useAppSelector(s =>
		extractCheckListsAsArrayFilteredBySelectedSpaceType(s, objectId));
	const structuresCheckLists = useAppSelector(s =>
		extractCheckListsAsArrayForWorkAcceptances(s, objectId));
	const checkLists = entity === 'spaces' ? spacesCheckLists : structuresCheckLists;

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={checkLists}
			label="Чек-листы"
			filterName="checkListIds"
			resetOnApply={['checkListCategory']}
		/>
	);
};
