import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import * as spaceTypes from '@src/store/modules/entities/space/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import * as checkRecordTypes from '@src/store/modules/pages/checkLists/constants/records';
import * as settingsTypes from '@src/store/modules/settings/pages/spaces/constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	ILoadedEntityState,
	getLoadedEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IGetSpacesSchemaPayload} from '../actions';
import {IAddSpacePayload} from '../../space/actions';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {IChangeStateCheckRecordPayload} from '@src/store/modules/pages/checkLists/actions';
import {CLEAR_BEFORE_UPDATE} from '@src/store/modules/app/updateData/constants';
import _ from 'lodash';
import * as spaceStatusesSetTypes from '@src/store/modules/dictionaries/spaceStatusesSets/constants';
import * as spaceStatusesTypes from '@src/store/modules/dictionaries/spaceStatuses/constants';
import {IAddSpaceStatusesSetPayload, IEditSpaceStatusesSetPayload} from '@src/store/modules/dictionaries/spaceStatusesSets/actions';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export interface ISpacesSchemaPageState extends ILoadedEntityState<IListSpace> {
	// Отображаемый объект в пагинации мобильной версии
	currentObject?: string;
}

export type ISpacesSchemaPagesState = Record<string, ISpacesSchemaPageState>;

export const getInitialStateForPage = getLoadedEntitiesInitialState;

const clearPage = (state: ISpacesSchemaPagesState, {payload}: {payload: {objectId: string}}) => {
	if (state[payload.objectId]) {
		state[payload.objectId].loaded = false;
	}
	if (state.all) {
		state.all.loaded = false;
	}
};

const applySpaceUpdate = (
	state: ISpacesSchemaPagesState,
	{payload}: {payload: ISpace & {pageObjectId: string}}
) => {
	const page = state[payload.objectId];
	if (page && page.allIds.includes(payload.id)) {
		page.byId[payload.id] = {...page.byId[payload.id], ...payload};
	}
};

export default createReducer<ISpacesSchemaPagesState>(
	{},
	{
		[types.GET_SCHEMA_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SCHEMA_SUCCESS]: (state, {payload}: {payload: IGetSpacesSchemaPayload}) => {
			const page = state[payload.objectId];
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.loaded = true;
		},
		[types.CHANGE_CURRENT_OBJECT]: (
			state,
			{payload}: {payload: {objectId: string, id: string}}
		) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			state[payload.objectId].currentObject = payload.id;
			state[payload.objectId].loaded = true;
		},
		[spaceTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddSpacePayload}) => {
			const page = state[payload.objectId];
			if (page) {
				page.byId[payload.id] = payload;
				page.allIds.push(payload.id);
			}
		},
		[spaceTypes.EDIT_STATUS_SUCCESS]: applySpaceUpdate,
		[spaceTypes.EDIT_INDICATORS_SUCCESS]: applySpaceUpdate,
		[spaceTypes.EDIT_SUCCESS]: applySpaceUpdate,
		[problemTypes.ADD_SUCCESS]: clearPage,
		[problemTypes.EDIT_SUCCESS]: clearPage,
		[problemTypes.EDIT_STATUS_SUCCESS]: clearPage,
		[problemTypes.DELETE_SUCCESS]: clearPage,
		[problemReplyTypes.ADD_SUCCESS]: clearPage,
		[problemReplyTypes.EDIT_SUCCESS]: clearPage,
		[checkRecordTypes.CHANGE_PERCENT_SUCCESS]: clearPage,
		[checkRecordTypes.CHANGE_STATUS_SUCCESS]: (
			state,
			{payload}: {payload: IChangeStateCheckRecordPayload}
		) => {
			for (const record of payload) {
				clearPage(state, {payload: {objectId: record.objectId}});
			}
		},
		[checkRecordTypes.ADD_SUCCESS]: clearPage,
		[settingsTypes.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<ISpacesFiltersState>}
		) => {
			const page = state[payload.objectId];
			if (page) {
				if (
					payload.filters.objects?.length
					&& payload.filters.objects.every(id => id !== page.currentObject)
				) {
					page.currentObject = payload.filters.objects[0];
				}
			}
		},
		[spaceStatusesSetTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddSpaceStatusesSetPayload}) => {
			const {companyId, objects} = payload;

			if (companyId && !objects?.length) {
				for (const objectId of Object.keys(state)) {
					clearPage(state, {payload: {objectId}});
				}
			}
			if (objects?.length) {
				for (const objectId of objects) {
					clearPage(state, {payload: {objectId}});
				}
			}
		},
		[spaceStatusesSetTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditSpaceStatusesSetPayload}) => {
			const {companyId, objects} = payload;

			if (companyId || objects?.length) {
				for (const objectId of Object.keys(state)) {
					clearPage(state, {payload: {objectId}});
				}
			}
		},
		[spaceStatusesSetTypes.DELETE_SUCCESS]: state => {
			for (const objectId of Object.keys(state)) {
				clearPage(state, {payload: {objectId}});
			}
		},
		[spaceStatusesTypes.DELETE_SUCCESS]: state => {
			for (const objectId of Object.keys(state)) {
				clearPage(state, {payload: {objectId}});
			}
		},
		[spaceStatusesTypes.DELETE_MANY_SUCCESS]: state => {
			for (const objectId of Object.keys(state)) {
				clearPage(state, {payload: {objectId}});
			}
		},
		[CLEAR_STORE]: () => ({}),
		[CLEAR_BEFORE_UPDATE]: state => {
			_.forIn(state, (_value, key) => {
				state[key].loaded = false;
			});
			void queryClient.invalidateQueries(spacesQueryKeys.list());
		}
	}
);
