import React, {memo, ReactElement, useCallback} from 'react';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {changeInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import MenuItem, {IMenuItemProps} from '@tehzor/ui-components/src/components/menu/MenuItem';
import MenuLayer from '@tehzor/ui-components/src/components/menu/MenuLayer';
import classNames from 'classnames';

interface IEntityTypeSelectProps {
	type: IInternalAcceptanceAddingEntityType;
	types: IInternalAcceptanceAddingEntityType[];
	disabled: boolean;
}

const entityTypeMap: Record<IInternalAcceptanceAddingEntityType, string> = {
	problem: 'Нарушение',
	inspection: 'Осмотр'
};

const EntityTypeSelect = ({type, types, disabled}: IEntityTypeSelectProps) => {
	const dispatch = useAppDispatch();

	const changeType = useCallback((value: IInternalAcceptanceAddingEntityType) => {
		if (!disabled) {
			dispatch(changeInternalAcceptanceAddingEntityType(value));
		}
	}, [disabled]);

	const menuContent: Array<ReactElement<IMenuItemProps>> = [];
	for (const item of types) {
		menuContent.push(
			<MenuItem
				key={item}
				onClick={() => changeType(item)}
				selected={type === item}
			>
				{entityTypeMap[item]}
			</MenuItem>
		);
	}

	return menuContent.length > 1 ? (
		<MenuLayer
			trigger={({isOpen, toggle, ref}) => (
				<div
					className={classNames(
						'adding-internal-acceptance-dialog__entity-type-select',
						{disabled}
					)}
					onClick={!disabled ? toggle : undefined}
					ref={ref}
				>
					{entityTypeMap[type]}
					{' '}
					{!disabled && (
						<i className={classNames('tz-simple-arrow-20', {isOpen})}/>
					)}
				</div>
			)}
		>
			{menuContent}
		</MenuLayer>
	) : (
		<div
			className={classNames(
				'adding-internal-acceptance-dialog__entity-type-select',
				'disabled'
			)}
		>
			{entityTypeMap[type]}
		</div>
	);
};

export default memo(EntityTypeSelect);
