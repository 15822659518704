import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {useSpaceCombinedExport} from '../../hooks/useSpaceCombinedExport';

interface IExportSelectedProps {
	objectId: string;
	spaceId: string;
	selectedEntities: ISpaceEntity[];
}

const SelectedEntitiesExport = ({objectId, spaceId, selectedEntities}: IExportSelectedProps) => {
	const [exportDialog, openExportDialog] = useSpaceCombinedExport(objectId, selectedEntities);
	const handleClick = useCallback(() => {
		openExportDialog(spaceId);
	}, [spaceId, openExportDialog]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-export-24"/>
			</IconButton>

			{exportDialog}
		</>
	);
};

export default SelectedEntitiesExport;
