import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {queryClient} from '@src/api/QueryClient';

export const prefetchCache = async (queryKeys: string[][], objects?: string[]) => {
	const plansQuerySettings = queryClient.getQueryDefaults(plansQueryKeys.lists());
	const spacesQuerySettings = queryClient.getQueryDefaults(spacesQueryKeys.list());
	const checkRecordsQuerySettings = queryClient.getQueryDefaults(checkRecordsQueryKeys.list());
	if (queryKeys.length && objects) {
		for (const queryKey of queryKeys) {
			if (queryKey.join() === plansQueryKeys.lists().join()) {
				await queryClient.prefetchQuery(plansQueryKeys.list(objects), {
					staleTime: plansQuerySettings?.staleTime,
					cacheTime: plansQuerySettings?.cacheTime
				});
				continue;
			}
			const querySettings = queryClient.getQueryDefaults(queryKey);
			await queryClient.prefetchQuery(queryKey, {
				staleTime: querySettings?.staleTime,
				cacheTime: querySettings?.cacheTime
			});
		}
	}
	// Кеширование помещений
	await queryClient.prefetchQuery(
		[...spacesQueryKeys.list(), {objects}, undefined, undefined, 1000000],
		{
			staleTime: spacesQuerySettings?.staleTime,
			cacheTime: spacesQuerySettings?.cacheTime
		}
	);
	await queryClient.prefetchQuery([...checkRecordsQueryKeys.list(), undefined, objects], {
		staleTime: checkRecordsQuerySettings?.staleTime,
		cacheTime: checkRecordsQuerySettings?.cacheTime
	});
};
