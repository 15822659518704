import React from 'react';
import {DisappearType, useHover} from 'react-laag';
import {TriggerProps} from 'react-laag/dist/useLayer.d';
import {convertClassNames} from '../../../utils/convertClassNames';
import type {UseHoverProps} from 'react-laag/dist/useHover';
import Popup from '../Popup';

export interface IHoverPopupProps {
	className?:
		| string
		| {
		root?: string;
		content?: string;
	};
	children?: React.ReactNode;
	trigger?: (props: TriggerProps, hoverProps: UseHoverProps) => React.ReactNode;
	arrowVisible?: boolean;

	onOutsideClick?: () => void;
	onDisappear?: (type: DisappearType) => void;
	onAnimationComplete?: () => void;
}

export const HoverPopup = (props: IHoverPopupProps) => {
	const {
		className,
		children,
		trigger,
		arrowVisible,
		...handlersProps
	} = props;

	const [isOver, hoverProps] = useHover({delayEnter: 350, delayLeave: 150, hideOnScroll: false});
	const classes = convertClassNames(className);

	return (
		<>
			<Popup
				className={classes.root}
				isOpen={isOver}
				arrowVisible={arrowVisible}
				trigger={trigger && (props => trigger(props, hoverProps))}
				{...handlersProps}
			>
				<div
					className={classes.content}
					{...hoverProps}
				>
					{children}
				</div>
			</Popup>
		</>
	);
};
