import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StatisticsGrouping} from '@src/interfaces/StatisticsGrouping';
import ByCompaniesStats from './ByCompaniesStats';
import ByObjectsStats from './ByObjectsStats';
import ByUsersStats from './ByUsersStats';
import ByContractorsStats from './ByContractorsStats';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsync from 'react-use/lib/useAsync';
import {getProblemsByStatusesStats} from '@src/store/modules/statistics/acceptance/actions';
import NoData from '../../NoData';
import DisplayControls, {ChartTypeMenu, ProblemStatusesFilter} from '../../DisplayControls';
import {
	changeChartType,
	changeStatuses
} from '@src/store/modules/settings/pages/statistics/acceptance/problems/actions';
import {StatisticsChartType} from '@src/interfaces/StatisticsChartType';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';

const availableChartTypes = [StatisticsChartType.BAR, StatisticsChartType.PIE];

function renderStatsComponent(grouping: StatisticsGrouping) {
	switch (grouping) {
		case StatisticsGrouping.COMPANIES:
			return <ByCompaniesStats/>;
		case StatisticsGrouping.OBJECTS:
			return <ByObjectsStats/>;
		case StatisticsGrouping.USERS:
			return <ByUsersStats/>;
		case StatisticsGrouping.CONTRACTORS:
			return <ByContractorsStats/>;
		default:
			return null;
	}
}

const ProblemsByStatusesStats = () => {
	useScrollRestoration();

	const filters = useAppSelector(s => s.settings.pages.statistics.acceptance.filters);
	const grouping = useAppSelector(s => s.settings.pages.statistics.acceptance.grouping);
	const {chartType, displayFilters} = useAppSelector(
		s => s.settings.pages.statistics.acceptance.problems
	);

	const dispatch = useAppDispatch();

	useAsync(async () => {
		await dispatch(getProblemsByStatusesStats());
	}, [filters]);

	return (
		<div>
			<DisplayControls
				left={(
					<ProblemStatusesFilter
						value={displayFilters}
						changeAction={changeStatuses}
     />
				)}
				right={(
					<ChartTypeMenu
						availableTypes={availableChartTypes}
						value={chartType}
						changeAction={changeChartType}
					/>
				)}
			/>

			{renderStatsComponent(grouping)}

			<NoData asTabContent/>
		</div>
	);
};

export default memo(ProblemsByStatusesStats);
