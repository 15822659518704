import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableInspectionAction, IEditableInspectionState, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';

export const useEditableInspectionState = (defaultData?: ISavingInspection) =>
	useReducer<
		Reducer<IEditableInspectionState, IEditableInspectionAction>,
		ISavingInspection | undefined
	>(createReducer(init, errorsFns), defaultData, init);
