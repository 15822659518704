import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import {extractWorkAcceptancesData} from '@src/store/modules/entities/workAcceptances/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import declination from '@tehzor/tools/utils/declination';

interface IWorkAcceptancesPageHeaderProps {
	objectId?: string;
}

export const WorkAcceptancesPageCounter = ({objectId = 'all'}: IWorkAcceptancesPageHeaderProps) => {
	const workAcceptancesData = useAppSelector(s => extractWorkAcceptancesData(s, objectId));
	const {state} = useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();
	const isFiltersActive = useFiltersActive(objectId, state);
	const total = workAcceptancesData.total || 0;
	const text = `${isFiltersActive ? 'Найдено:' : 'Всего:'} ${total} ${declination(
		total,
		['приёмка работ', 'приёмки работ', 'приёмок работ']
	)}`;

	return (
		<PageHeader
			className="work-acceptances-page__header"
			title=""
			subTitle={text}
			wrapClassName="work-acceptances-page__header-wrap"
			leftSideClassName="work-acceptances-page__header-left"
			rightSideClassName="work-acceptances-page__header-right"
		/>
	);
};
