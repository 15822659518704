import React from 'react';
import MobileInfo from './info/Info.mobile';
import {ExpandablePlate} from '@tehzor/ui-components';
import MobileLinks from './links/Links.mobile';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import Documents from '@src/pages/ProblemPage/components/attachments/Documents';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {useInspectionViewer} from '@src/core/hooks/imagesViewers/mobile/useInspectionViewer';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import PageBreadcrumbs from './PageBreadcrumbs';

interface ITabletProps {
	objectId: string;
	inspection?: ILinkedInspection;
}

const Tablet = ({objectId, inspection}: ITabletProps) => {
	const [imagesAttachments, docs] = useSplitAttachments(inspection);
	const images = useAttachmentsImages(imagesAttachments);
	const imagesData = useAttachmentsImagesObjects(imagesAttachments);
	const [imagesViewer, openInspectionImage] = useInspectionViewer(
		images,
		inspection ? inspection?.id : '',
		imagesData
	);

	return (
		<div className="page-cont problem-page">
			{inspection !== undefined && (
				<PageBreadcrumbs
					objectId={objectId}
					inspectionId={inspection.id}
				/>
			)}
			{inspection !== undefined && (
				<MobileLinks
					objectId={objectId}
					inspection={inspection}
				/>
			)}

			{inspection !== undefined && (
				<ExpandablePlate
					className="inspection-page__m-plate"
					title="Информация"
				>
					<MobileInfo
						objectId={objectId}
						inspection={inspection}
						images={images}
						onImageClick={openInspectionImage}
					/>
				</ExpandablePlate>
			)}

			{docs.length > 0 && (
				<ExpandablePlate
					className="inspection-page__m-plate"
					title="Вложения"
				>
					<Documents
						className="inspection-page__m-docs"
						files={docs}
					/>
				</ExpandablePlate>
			)}

			{imagesViewer}
		</div>
	);
};

export default Tablet;
