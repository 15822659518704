import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByItemId} from '@src/store/modules/pages/checkLists/selectors/records';
import {useCallback, useState} from 'react';
import {addRecord} from '@src/store/modules/pages/checkLists/actions/records/add';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {checkChangedRecords} from '@src/store/modules/pages/checkLists/actions/records/checkChangedRecords';
import {extractCheckListById} from '@src/store/modules/dictionaries/checkLists/selectors';
import {extractCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useStructureSelectDialog} from '../components/StructureSelectDialog/useStructureSelectDialog';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {extractWorkAcceptance} from '@src/store/modules/entities/workAcceptance/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const useAddingCheckListProblemDialog = (itemId: string): [JSX.Element, () => void] => {
	const {objectId, workAcceptanceId, listId} = useStrictParams<{
		objectId: string;
		workAcceptanceId?: string;
		listId: string;
		itemId: string;
	}>();

	const workAcceptance = useAppSelector(extractWorkAcceptance);
	const checkList = useAppSelector(s => extractCheckListById(s, listId));
	const checkItem = useAppSelector(s => extractCheckItemById(s, itemId));
	const checkRecord = useAppSelector(s => extractCheckRecordByItemId(s, itemId));
	const dispatch = useAppDispatch();
	const [links, setLinks] = useState<IProblem['links'] | IInspection['links']>({
		workAcceptanceId,
		checkItemId: itemId,
		checkListId: listId,
		checkRecordId: checkRecord?.id
	});

	const addCheckRecord = useCallback(() => {
		if (!checkRecord.id) {
			if (checkItem && checkList) {
				void dispatch(
					addRecord(
						objectId,
						{
							workAcceptanceId,
							checkItemId: checkItem.id,
							checkListId: checkItem.checkListId
						},
						checkList.stage,
						{status: CheckRecordStatusId.NOT_CHECKED}
					)
				);
			}
		}
	}, [checkItem, checkRecord, workAcceptanceId, objectId]);

	const [addingCheckDialog, openCheckDialog] = useAddingCheckDialog({
		objectId: objectId || '',
		links,
		stage: checkList?.stage,
		types: ['problem'],
		onSuccess: () => {
			if (checkItem) {
				void dispatch(
					checkChangedRecords([checkItem.checkListId], objectId, workAcceptanceId)
				);
			}
		}
	});

	const handleOpenCheckDialog = useCallback(() => {
		addCheckRecord();
		openCheckDialog();
	}, [checkItem, checkRecord, openCheckDialog, workAcceptanceId, objectId]);

	const onSuccess = (value: string) => {
		setLinks({...links, structureId: value});
		handleOpenCheckDialog();
	};

	const [structureSelectDialog, openStructureSelectDialog] = useStructureSelectDialog(
		workAcceptance?.structures ?? [],
		onSuccess,
		addingCheckDialog ?? null
	);

	return [structureSelectDialog as JSX.Element, openStructureSelectDialog as () => void];
};
