import React, {Dispatch} from 'react';
import {IEditableSpaceAction, IEditableSpaceState} from '@src/core/hooks/states/useEditableSpaceState';
import {IUploadingFilesAction, IUploadingFilesState} from '@src/core/hooks/states/useUploadingFilesState';
import Attachments from '../editableFields/Attachments';
import './EditableSpaceAttachments.less';

interface IEditableSpaceAttachmentsProps {
	editingState: IEditableSpaceState;
	editingDispatch: Dispatch<IEditableSpaceAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;

	saving: boolean;
}

const addIcon = <i className="tz-plus-20"/>;

const EditableSpaceAttachments = ({
	editingState,
	editingDispatch,
	uploadingFilesState,
	uploadingFilesDispatch,
	saving
}: IEditableSpaceAttachmentsProps) => (
	<Attachments
		className={{
			attachBtn: 'editable-space-attachments__attach-btn',
			file: 'editable-space-attachments__file'
		}}
		attachments={editingState.attachments}
		uploadingFiles={uploadingFilesState.value}
		editingDispatch={editingDispatch}
		uploadingFilesDispatch={uploadingFilesDispatch}
		disabled={saving}
		canDraw={false}
		hasError={editingState.errors.attachments && uploadingFilesState.error}
		label="Новый документ"
		icon={addIcon}
		showAttachBtn
	/>
);

export default EditableSpaceAttachments;