import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {formSpaceLocationForEntities} from '@src/utils/formSpaceLocationForEntities';
import {extractShape} from '@src/store/modules/dictionaries/plans/selectors';
import {transformLocations} from '@src/pages/CheckListPage/utils/transformLocations';

/**
 * Берёт из помещения местоположение и этаж и формирует исходные данные для создания нарушений и осмотров
 *
 * @param spaceId id помещения, чтобы понять, нужное ли помещение находится в store
 */
export function useSpaceLocationForEntities(objectId: string, spaceId?: string) {
	const space = useAppSelector(extractSpace);
	const [planId, sectorId] = transformLocations(space?.locations);

	const sector = useAppSelector(s => (planId && sectorId
		? extractShape(s, objectId, planId, sectorId) : undefined));

	return useMemo(() => {
		if (space && space.id === spaceId) {
			return formSpaceLocationForEntities(space, sector);
		}
		return undefined;
	}, [space, spaceId]);
}
