import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import React, {useMemo} from 'react';
import {UserAvatar} from '../../../various';
import {format} from 'date-fns';

interface IHeaderProps {
	entityName: string;
	change: IHistoryDifference;
	isLast: boolean;
	dateOptions: Parameters<typeof format>[2];
}

export const Header = ({entityName, change, isLast, dateOptions}: IHeaderProps) => {
	const str = useMemo(
		() =>
			(change.data
				? Object.entries(change.data)
						.filter(([, value]) => value)
						.map(([key]) => (change.data ? change.data[key].name : ''))
						.join(', ')
				: ''),
		[change.data]
	);

	return (
		<div className="history-list__header">
			{change.createdBy && (
				<UserAvatar
					user={change.createdBy}
					size="34"
					coloring="text"
					className="history-list__user"
				/>
			)}
			<div className="history-list__info">
				<span className="history-list__strong-text">
					{change.createdBy ? change.createdBy.displayName : 'Пользователь'}
				</span>
				{isLast || change.isCreated ? (
					<span>
						&nbsp;создал(а)
						{' '}
						<span className="history-list__strong-text">
							{change.isCreated ? (change.data ? change.data[0].name : 'параметр') : entityName}
						</span>
					</span>
				) : change.isDeleted ? (
					<span>
						&nbsp;удалил(а)
						{' '}
						<span className="history-list__strong-text">
							{change.data ? change.data[0].name : 'параметр'}
						</span>
					</span>
				) : (
					<span>
						&nbsp;изменил(а)&nbsp;
						<span className="history-list__strong-text">{str}</span>
					</span>
				)}
				<span>{change.createdAt ? ` в ${format(change.createdAt, 'HH:mm', dateOptions)}` : null}</span>
			</div>
		</div>
	);
};
