import React, {memo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectPopup, FilterButton, SelectOption, Select2} from '@tehzor/ui-components';

import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {extractOwnerAcceptanceStatusesAsArray} from '@src/store/modules/dictionaries/ownerAcceptanceStatuses/selectors';

interface IStatusesFilterProps {
	statuses?: string[];
}

export const StatusesFilter = memo(({statuses}: IStatusesFilterProps) => {
	const [selectedStatuses, setSelectedStatuses] = useState(statuses);
	const allStatuses = useAppSelector(extractOwnerAcceptanceStatusesAsArray);
	const {dispatch} = useEntitiesFiltersCtx();

	const handleApply = () => {
		dispatch({statuses: selectedStatuses});
	};

	const handleClear = () => {
		setSelectedStatuses([]);
	};

	const handleFullClear = () => {
		setSelectedStatuses([]);
		dispatch({statuses: undefined});
	};

	const handleCancel = () => {
		setSelectedStatuses(statuses);
	};

	useUpdateEffect(() => {
		setSelectedStatuses(statuses);
	}, [statuses]);

	return (
		<div>
			<SelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedStatuses?.length}
				count={selectedStatuses?.length}
				footer
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel('Статус', statuses, allStatuses)}
						active={!!statuses?.length}
						onClear={handleFullClear}
					/>
				)}
			>
				<Select2
					multiple
					value={selectedStatuses}
					onChange={setSelectedStatuses}
				>
					{allStatuses.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.name}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
});
