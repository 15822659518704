import React, {memo, useCallback} from 'react';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {makeDateFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';

interface ITasksIntervalFilterProps {
	label?: string;
	from?: Date;
	to?: Date;
	filterNameFrom: string;
	filterNameTo: string;
}

export const TasksIntervalFilter = memo((props: ITasksIntervalFilterProps) => {
	const {
		label = 'Срок устранения',
		from,
		to,
		filterNameFrom = 'taskIntervalStartFrom',
		filterNameTo = 'taskIntervalStartTo'
	} = props;
	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = useCallback((f: Date | null, t: Date | null) => {
		const intervalFrom = f
			? new Date(f.getFullYear(), f.getMonth(), f.getDate())
			: undefined;
		const intervalTo = t
			? new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999)
			: undefined;

		dispatch({[filterNameFrom]: intervalFrom, [filterNameTo]: intervalTo});
	}, [dispatch, filterNameTo, filterNameFrom]);

	const handleClear = useCallback(() => {
		dispatch({[filterNameFrom]: undefined, [filterNameTo]: undefined});
	}, [dispatch, filterNameTo, filterNameFrom]);

	return (
		<DateRangePicker
			trigger={triggerProps => (
				<FilterButton
					className="entities-filters__item"
					label={makeDateFilterLabel(label, from, to)}
					active={from !== undefined || to !== undefined}
					onClick={triggerProps.toggle}
					onClear={handleClear}
					ref={triggerProps.ref}
				/>
			)}
			valueFrom={from}
			valueTo={to}
			dateFormat="dd MMMM yyyy"
			useApplyButton
			onChange={handleChange}
		/>
	);
});
