import React, {useEffect} from 'react';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {LoadingPanel} from '@tehzor/ui-components';
import './CategoriesSetsPage.less';
import CategoriesSetsTable from './components/table/CategoriesSetsTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsyncFn} from 'react-use';
import {getCategoriesSets} from '@src/store/modules/dictionaries/categoriesSets/actions';
import {extractCategoriesSetsForPage} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCategoriesSetBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCategoriesSetBreadcrumbs';
import {getCategories} from '@src/store/modules/dictionaries/categories/actions';
import CategoriesSetsPageHeader from './components/CategoriesSetsPageHeader';
import classNames from 'classnames';
import CategoriesSetsFilters from './components/CategoriesSetsFilters';
import {ActionsDesktop} from './actions/Actions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';
import useAddingCategoriesSet from '@src/components/AddingCategoriesSet/hooks/useAddingCategoriesSet';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useParams} from 'react-router';

export const CategoriesSetsPage = ({className}: {className?: string}) => {
	useScrollRestoration();

	const {objectId} = useParams<{objectId?: string}>();

	const {state} = useEntitiesFiltersCtx();
	const isDesktop = useIsDesktop();
	const {canAdd} = useCategoriesSetPermissions();

	const breadcrumb: IBreadcrumb[] = useCategoriesSetBreadcrumbs(objectId);

	useAppHeader({title: 'Наборы видов работ'}, []);

	const dispatch = useAppDispatch();

	const addButton = useAddingCategoriesSet(canAdd);

	const setsData = useAppSelector(s => extractCategoriesSetsForPage(s, objectId || 'all'));

	const [loadingState, loadCategoriesSets] = useAsyncFn(async () => {
		await dispatch(getCategoriesSets(objectId)).then(() => {
			void dispatch(getCategories());
		});
	}, [dispatch, state]);

	useEffect(() => {
		void loadCategoriesSets();
	}, []);

	const actions = <ActionsDesktop/>;

	return (
		<LoadingPanel
			className={classNames('categories-sets__loading-panel')}
			active={loadingState.loading}
		>
			<div className={classNames('categories-sets', 'page-cont', className)}>
				<AppBreadcrumbs
					className="categories-sets__breadcrumbs"
					items={breadcrumb}
				>
					{isDesktop && actions}
				</AppBreadcrumbs>
				<CategoriesSetsPageHeader total={setsData.total}/>
				<CategoriesSetsFilters objectId={objectId}/>
				<CategoriesSetsTable
					objectId={objectId}
					{...setsData}
				/>
				{!isDesktop && addButton}
			</div>
		</LoadingPanel>
	);
};
