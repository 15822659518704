import React from 'react';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {AllInternalAcceptancesPage} from '@src/pages/InternalAcceptancesPage';
import {InternalAcceptancesFiltersRoutingPage} from '@src/pages/filters/InternalAcceptancesFiltersRoutingPage/InternalAcceptancesFiltersRoutingPage';
import {internalAcceptancesFilterRoutes} from './internalAcceptancesFilterRoutes';

export const internalAcceptancesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<AllInternalAcceptancesPage/>}/>
	},
	{
		path: 'filters',
		element: <PrivateRoute element={<InternalAcceptancesFiltersRoutingPage/>}/>,
		children: internalAcceptancesFilterRoutes
	}
];
