const domain = 'categories-sets';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const GET_ONE_REQUEST = `${domain}/getOne/request`;
export const GET_ONE_SUCCESS = `${domain}/getOne/success`;
export const GET_ONE_FAILURE = `${domain}/getOne/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;

export const MIGRATE_REQUEST = `${domain}/migrate/request`;
export const MIGRATE_SUCCESS = `${domain}/migrate/success`;
export const MIGRATE_FAILURE = `${domain}/migrate/failure`;
