import React from 'react';
import {EntityLinkButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useEntitiesLinks} from '../../hooks/useEntitiesLinks';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';

interface ILinksProps {
	objectId: string;
	inspection: ILinkedInspection;
}

const DesktopLinks = ({objectId, inspection}: ILinksProps) => {
	const {pushPath} = useChangePath();
	const links = useEntitiesLinks(objectId, inspection);
	if (links.length === 0) {
		return null;
	}
	return (
		<>
			{links.map(item => (
				<EntityLinkButton
					key={item.key}
					type="inline"
					leftIcon={item.icon}
					label={item.label}
					url={item.url}
					title={item.title}
					onClick={pushPath}
				/>
			))}
		</>
	);
};

export default DesktopLinks;
