import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import * as types from '../constants';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {IGetWorkAcceptanceResponse, requestWorkAcceptance} from '@src/api/backend/workAcceptance';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';

export type IGetWorkAcceptancePayload = IGetWorkAcceptanceResponse;

const request = (objectId: string, workAcceptanceId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId, workAcceptanceId}
});

const success = (response: IGetWorkAcceptanceResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке приёмки работ');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает приёмку работ по id
 */
export const getWorkAcceptance
	= (
		objectId: string,
		workAcceptanceId: string
	): AppThunkAction<Promise<IGetWorkAcceptanceResponse>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<IGetWorkAcceptanceResponse>(
			() => request(objectId, workAcceptanceId),
			success,
			failure,
			() => requestWorkAcceptance(objectId, workAcceptanceId)
		);

		return state.entities.workAcceptance.data?.id === workAcceptanceId
			? dispatch(
					checkExpiration<IState, IGetWorkAcceptanceResponse, ApiAction>(
						s => s.entities.workAcceptance,
						apiAction
					)
			  )
			: dispatch(apiAction);
	};
