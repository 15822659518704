import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import React, {ReactNode, useCallback, useState} from 'react';
import {EditableObjectDialog} from '../EditableObjectDialog';

export const useEditableObjectDialog = (object: IObject): [ReactNode, () => void] => {
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const dialog = (
		<EditableObjectDialog
			object={object}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
};