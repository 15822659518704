import * as types from '../constants';

/**
 * Отслеживаем статусы работающих запросов
 *
 * @param value true/false - показать/скрыть, undefined - поменять на противоположное значение
 */
export const actionsStatusChange = (payload: {type: string, status: string}) => ({
	type: types.APP_ACTIONS_STATUS_CHANGE,
	payload
});