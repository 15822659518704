import {wsConnector} from '../wsConnector';

/**
 * Удаляет нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const makeProblemDeleteRequest = (objectId: string, problemId: string) =>
	wsConnector.sendAuthorizedRequest<void>('deleteProblem', {
		objectId,
		problemId
	});
