import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IDocumentType} from '@tehzor/types-and-subjects/ts/documents';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IDocumentTypesState = ILoadableEntitiesState<IDocumentType>;

export const documentTypes = createReducer<IDocumentTypesState>(
	getLoadableEntitiesInitialState<IDocumentType>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IDocumentType>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IDocumentType>()
	}
);
