import {wsConnector} from '@src/api/backend/wsConnector';

export interface IDeleteSpaceStatusesSetResponse {
	success: boolean;
}

/**
 * Удаляет набор статусов помещений по id
 *
 * @param spaceStatusesSetId id набора статусов помещений
 */
export const requestDeleteSpaceStatusesSet = (spaceStatusesSetId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteSpaceStatusesSetResponse>('deleteSpaceStatusesSet', {
		id: spaceStatusesSetId
	});
