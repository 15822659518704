import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import React from 'react';
import {CellProps} from 'react-table';

const LocationCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const {planId, floor, objectId, plan} = row.original;

	return (
		<div className="tasks-page__d-table-location">
			{!!objectId && (
				<div className="tasks-page__d-table-location-object">
					{objectId.name}
				</div>
			)}

			{(planId || floor || location) && (
				<div className="tasks-page__d-table-location-block">
					{plan && (
						<div className="tasks-page__d-table-location-line">
							<span className="tasks-page__d-table-location-title">
								План:
							</span>
							<span className="tasks-page__d-table-location-subtitle">
								{plan.name}
							</span>
						</div>
					)}
					{floor && (
						<div className="tasks-page__d-table-location-line">
							<span className="tasks-page__d-table-location-title">
								Этаж:
							</span>
							<span className="tasks-page__d-table-location-subtitle">
								{floor}
							</span>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default LocationCell;
