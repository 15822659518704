import React, {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useEditableWorkAcceptanceDialog} from '@src/components/EditableWorkAcceptanceDialog/hooks/useEditableWorkAcceptanceDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IDesktopWorkAcceptanceAddBtnProps {
	objectId: string;
}

export const DesktopWorkAcceptanceAddBtn = ({objectId}: IDesktopWorkAcceptanceAddBtnProps) => {
	const [addingDialog, openAddingDialog] = useEditableWorkAcceptanceDialog(objectId, ObjectStageIds.BUILDING);

	const handleAdding = useCallback(() => {
		openAddingDialog();
	}, [openAddingDialog]);

	return (
		<>
			<IconButton
				type="accent-blue"
				onClick={handleAdding}
			>
				<i className="tz-plus-20"/>
			</IconButton>
			{addingDialog}
		</>
	);
};
