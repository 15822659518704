import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import {useEffect, useState} from 'react';

export const useVisibleLayersState = (layers: ILayer[]): [string[], (value: string[]) => void] => {
	const [vLayers, setVLayers] = useState<string[]>([]);

	useEffect(() => {
		setVLayers(layers.filter(item => item.visible).map(item => item.id));
	}, [layers]);

	return [vLayers, setVLayers];
};
