import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useReportsTable} from '@src/pages/SpacesPage/hooks/useReportsTable';
import {Plate} from '@tehzor/ui-components';
import {ReportsTable} from './ReportsTable';
import {buildColumns} from './buildColumns';
import {extractSpaceStatusesAsArrayByObjectId} from '@src/store/modules/dictionaries/spaceStatusesSets/selectors';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {FieldButtons} from './FieldButtons';

interface ITableProps {
	objectId: string;
}

export const Table = ({objectId}: ITableProps) => {
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const spaceStatuses = useAppSelector(s => extractSpaceStatusesAsArrayByObjectId(s, objectId));

	const spaceStatusesMap: Record<string, ISpaceStatus> = {};
	const spaceStatusesIds: string[] = [];

	const filtersStatuses = pageSettings.filters?.statuses;
	const filtersStage = pageSettings.stage;
	for (const status of spaceStatuses) {
		const statusId = status.id;

		if (
			(!filtersStatuses?.length && !filtersStage)
			|| (filtersStatuses?.length && filtersStatuses.includes(statusId))
			|| (filtersStage === status.stage && !filtersStatuses?.length)
		) {
			spaceStatusesMap[statusId] = status;
		}
		spaceStatusesIds.push(statusId);
	}

	const columns = buildColumns(spaceStatusesIds, spaceStatusesMap);
	const data = useReportsTable(objectId, 'status', spaceStatusesIds, spaceStatusesMap);

	return (
		<Plate
			className="spaces-page__reports-table-plate"
			withoutPadding
		>
			<FieldButtons/>
			<ReportsTable
				columns={columns}
				data={data}
			/>
		</Plate>
	);
};