import React, {useCallback, useState} from 'react';
import EditableProblemReply from '@src/components/EditableProblemReply';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

export function useReplyDialog(
	objectId: string,
	problemId: string,
	links?: IProblemComment['links']
): [React.ReactNode, () => void, (id: string) => void] {
	const [isOpen, setOpen] = useState<boolean>(false);
	const [commentId, setCommentId] = useState<string | undefined>();

	const add = useCallback(() => {
		setCommentId(undefined);
		setOpen(true);
	}, []);

	const edit = useCallback((id: string) => {
		setCommentId(id);
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const clear = useCallback(() => {
		setCommentId(undefined);
	}, []);

	const dialog = (
		<EditableProblemReply
			objectId={objectId}
			problemId={problemId}
			commentId={commentId}
			links={links}
			isOpen={isOpen}
			onClose={close}
			onAfterClose={clear}
		/>
	);

	return [dialog, add, edit];
}
