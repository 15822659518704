import React, {useState} from 'react';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {MobileDescription} from '../description/Description.mobile';
import {EntityAdding} from '../actions/EntityAdding';
import {Plate} from '@tehzor/ui-components';
import {WorkAcceptanceDisplayMode} from '@src/interfaces/WorkAcceptanceDisplayMode';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Outlet, Route, Routes} from 'react-router-dom';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {mobileColumns} from '../table/columns.mobile';
import {CheckLists} from '../list';
import {Table} from '../table/Table.mobile';
import {ListDetailMobile, MobileItemDetail} from '@src/pages/CheckListPage/components/detail';

interface IMobileRoutesProps {
	workAcceptance: IWorkAcceptance;
	objectId: string;
	checkLists: ICheckList[];
}

export const MobileRoutes = ({workAcceptance, objectId, checkLists}: IMobileRoutesProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const displayMode = useAppSelector(
		s => s.settings.pages.workAcceptances.byPage[objectId]?.displayMode
	);

	const checkListsRoute = (
		<div className="page-cont check-list-page check-list-page__m-container">
			<Routes>
				<Route path="check-lists">
					<Route
						path=":listId"
						element={<ListDetailMobile/>}
					/>
					<Route
						path=":listId/:pathParam?/:itemId?"
						element={<MobileItemDetail/>}
					/>
					<Route
						path=""
						element={<CheckLists checkLists={checkLists}/>}
					/>
				</Route>
			</Routes>
			<Outlet/>
		</div>
	);

	const problemsRoute = (
		<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
			<Plate withoutPadding>
				<Table
					objectId={objectId}
					columns={mobileColumns}
					selectedRows={selectedRows}
					workAcceptance={workAcceptance}
					checkLists={checkLists}
					onSelectedRowsChange={setSelectedRows}
					hideHead
				/>
				<EntityAdding
					objectId={objectId}
					workAcceptance={workAcceptance}
				/>
			</Plate>
		</div>
	);

	const descriptionRoute = (
		<div className="page-cont check-list-page check-list-page__m-container">
			<MobileDescription
				objectId={objectId}
				workAcceptance={workAcceptance}
			/>
		</div>
	);

	if (displayMode === WorkAcceptanceDisplayMode.CHECK_LISTS) {
		return checkListsRoute;
	}

	if (displayMode === WorkAcceptanceDisplayMode.PROBLEMS) {
		return problemsRoute;
	}

	if (displayMode === WorkAcceptanceDisplayMode.DESCRIPTION) {
		return descriptionRoute;
	}
	return null;
};
