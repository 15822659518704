import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {PriorityView} from '@src/components/PriorityView/PriorityView';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {CellProps} from 'react-table';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

export const PriorityCell = (data: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const row = data.row;
	const task = row.original;
	const isDesktop = useIsDesktop();
	const prioritiesMap = useAppSelector(s => s.dictionaries.taskPriorities.byId);
	const ref = useRef<HTMLDivElement>(null);
	const [priorityWidth, setPriorityWidth] = useState(0);

	useLayoutEffect(() => {
		if (ref?.current) {
			setPriorityWidth(ref.current.clientWidth);
		}
	}, []);

	useEffect(() => {
		function handleWindowResize() {
			if (ref?.current) {
				setPriorityWidth(ref.current.clientWidth);
			}
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	return (
		<div
			className="tasks-page_priority"
			ref={ref}
		>
			<PriorityView
				value={task?.taskPriority?.id || ''}
				prioritiesMap={prioritiesMap}
				short={isDesktop ? priorityWidth < 120 : false}
			/>
		</div>
	);
};
