import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';

/**
 * Хук для хлебных крошек от "всех объектов" до приемок собственником
 *
 * @param objectId id конечного объекта
 */
export function useOwnerAcceptancesBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId);
		items.push({
			label: 'Передачи собственникам',
			url: `/objects/${objectId}/owner-acceptances`,
			inactive: false
		});
		return items;
	}, [objectId, objects]);
}
