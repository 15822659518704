import React, {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Button} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
// import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsyncFn from 'react-use/lib/useAsyncFn';
// import {getProblems} from '@src/store/modules/entities/problems/actions';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {extractSpacesSchemaData} from '@src/store/modules/entities/spaces/selectors/schemas';
import {useQueryClient} from '@tanstack/react-query';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';

const types: ICheckAddingEntityType[] = ['problem'];

interface IProblemAddingDesktopProps {
	objectId: string;
}

const ProblemAddingDesktop = ({objectId}: IProblemAddingDesktopProps) => {
	const queryClient = useQueryClient();
	const object = useAppSelector(s => extractObject(s, objectId));
	const spaces = useAppSelector(s => extractSpacesSchemaData(s, objectId));

	const [, reloadList] = useAsyncFn(async () => {
		await queryClient.invalidateQueries(problemsQueryKeys.list());
	}, [objectId]);

	const checksPermissions = useChecksPermissions(objectId, 'problems');
	const ownersAcceptancesPermissions = useOwnerAcceptancesPermissions(objectId, 'problems');
	const warrantyClaimPermissions = useWarrantyClaimsPermissions(objectId, 'problems');
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId, 'problems');

	const permissions = useMemo(
		() => ({
			checks: checksPermissions.canAdd,
			acceptances: ownersAcceptancesPermissions.canAdd,
			claims: warrantyClaimPermissions.canAdd,
			internalAcceptances: internalAcceptancePermissions.canAdd
		}),
		[
			checksPermissions,
			ownersAcceptancesPermissions,
			warrantyClaimPermissions,
			internalAcceptancePermissions
		]
	);

	const [checkDialog, openCheckDialog] = useAddingCheckDialog({
		objectId,
		stage: ObjectStageIds.BUILDING,
		types,
		onSuccess: reloadList
	});

	const [globalDialog, openGlobalDialog] = useGlobalAddingEntityDialog(
		objectId,
		undefined,
		undefined,
		object.stage,
		permissions,
		undefined,
		reloadList,
		types
	);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-plus-20"/>}
				label="Добавить"
				onClick={
					object.stage === ObjectStageIds.BUILDING && spaces.allIds.length === 0
						? openCheckDialog
						: openGlobalDialog
				}
			/>
			{object.stage === ObjectStageIds.BUILDING && spaces.allIds.length === 0
				? checkDialog
				: globalDialog}
		</>
	);
};

export default ProblemAddingDesktop;
