import React from 'react';
import './CalendarButton.less';
import ButtonBase from '../ButtonBase';
import classNames from 'classnames';

interface ICalendarButtonProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	active?: boolean;
	inactive?: boolean;
	disabled?: boolean;

	onClick?(event: React.MouseEvent): void;

	onMouseEnter?(event: React.MouseEvent): void;

	onMouseLeave?(event: React.MouseEvent): void;
}

const CalendarButton = (props: ICalendarButtonProps) => {
	const {className, style, children, active, inactive, disabled, onClick, onMouseEnter, onMouseLeave} = props;

	return (
		<ButtonBase
			className={classNames({'calendar-button_active': active}, {'calendar-button_inactive': inactive}, className)}
			style={style}
			label={children}
			disabled={disabled}
			classNamePrefix="calendar-button"
			outerTagType="button"
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	);
};

CalendarButton.displayName = 'CalendarButton';

export default CalendarButton;
