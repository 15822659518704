import classNames from 'classnames';
import React from 'react';
import Plate, {IPlateProps} from '../Plate/Plate';
import './ClickablePlate.less';

interface IClickablePlateProps extends IPlateProps {
	onClick?: () => void;
}

const ClickablePlate = ({onClick, ...props}: IClickablePlateProps) => (
	<div
		className={classNames('clickable-plate', {hasHandleClick: !!onClick})}
		onClick={onClick}
	>
		<Plate
			{...props}
		/>
	</div>
);

export default ClickablePlate;