import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {
	IDeleteCheckListPayload
} from '@src/store/modules/entities/checkList/actions';
import {IAddCheckListResponse, IEditCheckListResponse} from '@src/api/backend/checkList';

const {entitiesCacheTime} = config;

export interface ICheckListState {
	data?: ICheckList;
	expires?: number;
}

export const getInitialState = (): ICheckListState => ({});

export default createReducer<ICheckListState>(getInitialState(), {
	[types.ADD_SUCCESS]: (state, {payload}: {payload: IAddCheckListResponse}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditCheckListResponse}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteCheckListPayload}) => {
		if (state.data?.id !== payload.id) {
			return state;
		}
		return getInitialState();
	},
	[CLEAR_STORE]: getInitialState
});
