import React, {memo} from 'react';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {addErrorToast} from '@src/utils/toasts';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useLegalsPermissions} from '@src/core/hooks/permissions/useLegalsPermissions';
import {useLegal} from '@src/core/hooks/queries/legals';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from './components/Desktop';
import {Mobile} from './components/Mobile';
import {LegalActions} from './components/actions/LegalActions';
import './LegalPage.less';

export const LegalPage = memo(() => {
	useScrollRestoration();

	const {legalId} = useStrictParams<{legalId: string}>();
	const legal = useLegal(legalId);
	const {canView} = useLegalsPermissions();
	const isDesktop = useIsDesktop();

	useAppHeader(
		{
			title: legal?.name || 'Юрлицо',
			showBackBtn: true,
			mobileRightButtons: <LegalActions legal={legal} />
		},
		[legal]
	);

	if (!canView) {
		addErrorToast('Отсутствие доступа', 'к просмотру страницы');
		return null;
	}

	if (isDesktop) {
		return <Desktop legal={legal} />;
	}

	return <Mobile legal={legal} />;
});
