import {Column} from 'react-table';
import StatsCell from './StatsCell';
import MenuCell from './MenuCell';
import {IPreparedCheck} from '../../interfaces/IPreparedCheck';

export const desktopColumns: Array<Column<IPreparedCheck>> = [
	{
		id: 'number',
		Header: '№',
		accessor: row => `№${row.number ?? row.localNumber}`,
		width: 60
	},
	{
		id: 'object',
		Header: 'Объект',
		accessor: row => row.object?.name,
		width: 100
	},
	{
		id: 'createdBy',
		Header: 'Автор',
		accessor: row => row.createdBy?.fullName,
		width: 100
	},
	{
		id: 'createdAt',
		Header: 'Добавлено',
		accessor: 'createdAt',
		width: 80,
		sortDescFirst: true
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: 'modifiedAt',
		width: 80,
		sortDescFirst: true
	},
	{
		id: 'stats',
		Cell: StatsCell,
		width: 120,
		defaultCanSort: false
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'checks-page__d-table-menu',
		width: 68,
		minWidth: 68,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const desktopColumnsWithoutObject = desktopColumns.filter(item => item.id !== 'object');
