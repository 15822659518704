import React, {useCallback} from 'react';
import {SwitchMenuButton} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';
import {changeDisplayMode} from '@src/store/modules/settings/pages/tasks/actions';
import {useTasksPageDisplayMode} from '@src/core/hooks/queries/tasks';

const options = [
	{key: TasksDisplayMode.SCHEDULE, content: <i className="tz-calendar-24"/>},
	{key: TasksDisplayMode.LIST, content: <i className="tz-list-24"/>}
];

export const MobileSwitchMode = () => {
	const displayMode = useTasksPageDisplayMode();
	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		(value: TasksDisplayMode) => {
			dispatch(changeDisplayMode(value));
		},
		[dispatch]
	);

	return (
		<SwitchMenuButton
			options={options}
			value={displayMode}
			onChange={handleChange}
		/>
	);
};
