import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/ISavingWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export type IEditableResultWorkAcceptanceState = IEditableEntityState<{
	comment: string;
	percent?: string;
	unit?: string;
	unitValue?: string;
}>;

export type IEditableResultWorkAcceptanceAction = IEditableEntityAction<
	IEditableResultWorkAcceptanceState,
	ISavingWorkAcceptance
>;

const makeEmptyState = (): IEditableResultWorkAcceptanceState => ({
	comment: '',
	percent: '0',
	unit: undefined,
	unitValue: '',

	errors: {
		comment: false,
		percent: false,
		unit: false,
		unitValue: false
	}
});

export const init = (original?: IWorkAcceptance): IEditableResultWorkAcceptanceState => {
	const empty = makeEmptyState();

	return original
		? {
				comment: original.comment ?? '',
				percent: original.percent?.toString() ?? '0',
				unit: original.physicalWorkScope?.unitId ?? undefined,
				unitValue: original.physicalWorkScope?.value ?? '',
				errors: empty.errors
		  }
		: empty;
};

const isCommentEdited = (state: IEditableResultWorkAcceptanceState, original?: IWorkAcceptance) =>
	(original?.comment ? original.comment !== state.comment : !!state.comment);

const isPercentEdited = (state: IEditableResultWorkAcceptanceState, original?: IWorkAcceptance) =>
	(original?.percent
		? original.percent.toString() !== state.percent
		: !!state.percent && state.percent !== '0');

const isUnitEdited = (state: IEditableResultWorkAcceptanceState, original?: IWorkAcceptance) =>
	(original?.physicalWorkScope?.unitId ? original.physicalWorkScope.unitId !== state.unit : !!state.unit);

const isUnitValueEdited = (state: IEditableResultWorkAcceptanceState, original?: IWorkAcceptance) =>
	(original?.physicalWorkScope?.value ? original.physicalWorkScope.value !== state.unitValue : !!state.unitValue);

/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (
	state: IEditableResultWorkAcceptanceState,
	original?: IWorkAcceptance
): boolean => isEntityEdited(state, original, isCommentEdited, isPercentEdited, isUnitEdited, isUnitValueEdited);

/**
 * Функции проверки полей на ошибки
 */

const regPercent = /^\d+$/;

export const errorsFns = {
	comment: (state: IEditableResultWorkAcceptanceState) => !state.comment,
	percent: (state: IEditableResultWorkAcceptanceState) =>
		!!state.percent
		&& !(
			state.percent
			&& regPercent.test(state.percent)
			&& parseInt(state.percent) <= 100
			&& parseInt(state.percent) >= 0
		),
	unit: (state: IEditableResultWorkAcceptanceState) => !state.unit,
	unitValue: (state: IEditableResultWorkAcceptanceState) =>
		!state.unitValue
		|| !(state?.unitValue?.length < 20)
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableResultWorkAcceptanceState,
	original?: IWorkAcceptance,
	onlyEdited?: boolean
): ISavingWorkAcceptance => {
	if (!onlyEdited) {
		return {
			comment: state.comment,
			percent: state.percent ? parseInt(state.percent) : 0,
			physicalWorkScope: {
				...original?.physicalWorkScope,
				unitId: state.unit,
				value: state.unitValue
			}
		};
	}
	const acceptance: ISavingWorkAcceptance = {};
	if (isCommentEdited(state, original)) {
		acceptance.comment = state.comment;
	}
	if (isPercentEdited(state, original)) {
		acceptance.percent = state.percent ? parseInt(state.percent) : 0;
	}
	if (isUnitEdited(state, original)) {
		acceptance.physicalWorkScope = {
			...original?.physicalWorkScope,
			unitId: state.unit
		};
	}
	if (isUnitValueEdited(state, original)) {
		acceptance.physicalWorkScope = {
			unitId: state.unit,
			...original?.physicalWorkScope,
			value: state.unitValue
		};
	}
	return acceptance;
};
