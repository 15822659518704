import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractStatsInspectorsAsArray} from '@src/store/modules/statistics/sources/selectors';
import arrayToTree from 'array-to-tree';
import IStatsObject from '@tehzor/tools/interfaces/statistics/IStatsObject';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {extractObjectsAsArray} from '@src/store/modules/dictionaries/objects/selectors';

/**
 * Фильтрует инженеров исходя из установленных фильтров и наличия статистики.
 * Также возвращает объекты инженера и статистику, для избежания повторных вычислений в других местах.
 */
export function useFilteredInspectors<T>(
	usersStats: {[id: string]: T},
	formObjectsStats: (userId: string) => {[id: string]: T},
	filters: {
		companies?: string[];
		objects?: string[];
		users?: string[];
	},
	isFiltersSetUp: boolean
) {
	const users = useAppSelector(extractStatsInspectorsAsArray);
	const companies = useAppSelector(extractCompaniesAsArray);
	const objects = useAppSelector(extractObjectsAsArray);

	return useMemo(() => {
		const result = [];
		for (const user of users) {
			if (isFiltersSetUp && !usersStats[user.id] && !filters.users?.includes(user.id)) {
				continue;
			}
			const userCompanies = companies
				.filter(item => item.employees?.some(e => user.id === e.userId))
				.map(item => item.id);
			const userObjects = objects.filter(item => userCompanies.includes(item.companyId));
			const tree = arrayToTree<IStatsObject>(userObjects, {
				parentProperty: 'parentId',
				customID: 'id'
			});
			const objectsStats = formObjectsStats(user.id);
			const userObjectsTree = tree.filter(item => objectsStats[item.id]);

			result.push({
				user,
				userStats: usersStats[user.id],
				objects: userObjectsTree,
				objectsStats
			});
		}
		return result;
	}, [users, companies, objects, usersStats, isFiltersSetUp, filters.users]);
}
