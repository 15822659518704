import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';

interface ICompanySelectProps<S, E> {
	field: keyof S;
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	isUUID?: boolean;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const CompanySelect = <S extends {companyId: string}, E>({
	className,
	style,
	field,
	label = 'Компания',
	value,
	isUUID = false,
	editingDispatch,
	required,
	disabled,
	hasError
}: ICompanySelectProps<S, E>) => {
	const user = useAppSelector(extractUserProfile);
	const companyMap = useAppSelector(s => s.dictionaries.companies.byId);
	const {companies} = user;

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	if (!companies) {
		return null;
	}

	const title = isUUID
		? Object.values(companyMap).find(el => el?.uuid === value)?.name
		: value && companyMap[value]?.name;

	return (
		<div className={className} style={style}>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={title}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? 'Выберите компанию' : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2 value={value} onChange={handleChange}>
					{companies.map(company => {
						const uuid = companyMap[company.id]?.uuid;
						const itemKey = isUUID && uuid ? uuid : company.id;

						return (
							<SelectOption
								key={itemKey}
								itemKey={itemKey}
								content={company.name}
								inputType="radio"
							/>
						);
					})}
				</Select2>
			</SelectPopup>
		</div>
	);
};

export default CompanySelect;
