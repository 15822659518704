import React, {useMemo} from 'react';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';
import SelectionActions from '../selection/SelectionActions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptanceEntities} from '@src/store/modules/entities/internalAcceptance/selectors';
import ActionsMobile from './Actions.mobile';

interface IMobileRightButtonsProps {
	objectId: string;
	internalAcceptanceId: string;
	internalAcceptance?: ILinkedInternalAcceptance;
	selectedRows: string[];
}

const MobileRightButtons = ({objectId, internalAcceptanceId, internalAcceptance, selectedRows}: IMobileRightButtonsProps) => {
	const entities = useAppSelector(extractInternalAcceptanceEntities);

	const selectedEntities = useMemo(
		() => entities.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, entities]
	);

	if (!internalAcceptance) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				internalAcceptanceId={internalAcceptanceId}
				internalAcceptance={internalAcceptance}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			internalAcceptance={internalAcceptance}
		/>
);
};

export default MobileRightButtons;
