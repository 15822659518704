import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';

export interface IGetExportTemplatesResponse extends INormalizedData<IExportTemplate> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список шаблонов экспорта
 */
export const requestExportTemplates = () =>
	wsConnector.sendAuthorizedRequest<IGetExportTemplatesResponse>(
		'getExportTemplates',
		{},
		{timeout: 180}
	);
