import {addWarningToast} from '@src/utils/toasts';
import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import useAppSelector from '../../useAppSelector';
import {IAddWarrantyClaimParams} from '@src/api/cache/warrantyClaims/defaults/useWarrantyClaimsMutationDefaults';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {addWarrantyClaimActions} from '@src/store/modules/entities/warrantyClaim/actions';
import {toListWarrantyClaim, toWarrantyClaim} from './utils/convertToLocalSave';
import {useAddWarrantyClaimMutation} from '@src/api/cache/warrantyClaims/mutations';
import {useLocalWarrantyClaims} from '../../queries/warrantyClaims/useGetWarrantyClaims';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';

export const useAddWarrantyClaim = (object: IObject) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const user = useAppSelector(s => s.auth.profile);
	const nextNumber = useNextLocalNumber<IListWarrantyClaim>(
		useLocalWarrantyClaims(),
		'createdAt'
	);
	const mutation = useAddWarrantyClaimMutation();
	return async ({
		objectId,
		links,
		fields
	}: Omit<IAddWarrantyClaimParams, 'key' | 'currentQueryFilter'>) => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}
		const converted = toWarrantyClaim(user, objectId, links, fields, key, nextNumber);
		const convertedToList = toListWarrantyClaim(object, user, links, fields, key, nextNumber);

		queryClient.setQueryData(
			[...warrantyClaimsQueryKeys.detail(key), objectId],
			convertedToList
		);

		if (!online) {
			addWarningToast('Локальное сохранение', 'заявление по гарантии добавлена локально');
			await queryClient.refetchQueries(warrantyClaimsQueryKeys.localList());
			dispatch(addWarrantyClaimActions.success(converted));
		}
		mutation.mutate({
			key,
			objectId,
			links,
			fields
		});
		return converted;
	};
};
