import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export function formObjectsChain(
	objects: {[id: string]: IObject},
	objectId?: string,
	defaultPage: 'problems' | 'inspections' = 'problems'
) {
	let result = [{label: 'Все объекты', url: '/objects', inactive: false}];
	if (!objectId) {
		return result;
	}

	const object = objects[objectId];
	const hasChildren = Object.values(objects)?.some(obj => obj.ancestors?.includes(objectId));
	if (object) {
		if (object.ancestors) {
			result = result.concat(
				object.ancestors
					.filter(id => objects[id])
					.map(id => ({
						label: objects[id]?.name || '',
						url: `/objects/${id}`,
						inactive: false
					}))
			);
		}
		result.push({
			label: object?.name,
			url: hasChildren ? `/objects/${object.id}` : `/objects/${object.id}/${defaultPage}`,
			inactive: false
		});
	}
	return result;
}

/**
 * Хук для хлебных крошек от "всех объектов" до конкретного
 *
 * @param objectId id конечного объекта
 */
export function useObjectBreadcrumbs(objectId?: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	return useMemo(() => formObjectsChain(objects.byId, objectId), [objectId, objects]);
}
