import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

interface ITasksPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
	canEditResponsible?: boolean;
	canEditStatus?: boolean;
}

export const useTasksPermissions = (objectId?: string): ITasksPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		return {
			canView: hasPermission(user.roles, 'tasks-view', UserRoleScopes.OBJECT, objectId),
			canAdd: hasPermission(user.roles, 'tasks-add', UserRoleScopes.OBJECT, objectId),
			canEdit: hasPermission(user.roles, 'tasks-edit', UserRoleScopes.OBJECT, objectId),
			canDelete: hasPermission(user.roles, 'tasks-delete', UserRoleScopes.OBJECT, objectId),
			canEditResponsible: hasPermission(
				user.roles,
				'tasks-edit-responsible',
				UserRoleScopes.OBJECT,
				objectId
			),
			canEditStatus: hasPermission(
				user.roles,
				'tasks-edit-status',
				UserRoleScopes.OBJECT,
				objectId
			)
		};
	}, [objectId, user]);
};
