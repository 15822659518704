import React from 'react';
import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPlan} from '@src/store/modules/dictionaries/plans/selectors';

const locationIcon = <i className="tz-location-20"/>;
const categoryIcon = <i className="tz-category-20"/>;
const statusIcon = <i className="tz-status-20"/>;

interface IDesktopInfoProps {
	structure: IStructure;
}

export const Common = ({structure}: IDesktopInfoProps) => {
	const types = useAppSelector(s => s.dictionaries.structureTypes.byId);
	const plan = useAppSelector(s => extractPlan(s, structure.objectId, structure.planId));

	return (
		<EntityGrid>

			<EntityGridItem
				label="Наименование"
				fullRow
				icon={statusIcon}
			>
				{structure.name}
			</EntityGridItem>

			<EntityGridItem
				label="Тип структуры"
				fullRow
				icon={categoryIcon}
			>
				{types[structure.type].name}
			</EntityGridItem>

			{plan && (
				<EntityGridItem
					label="План"
					fullRow
					icon={locationIcon}
				>
					{plan.name}
				</EntityGridItem>
)}

		</EntityGrid>
	);
};
