import React from 'react';
import {useAddingObjectDialog} from '@src/components/AddingObjectDialog/hooks/useAddingObjectDialog';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useObjectsPermissions} from '@src/core/hooks/permissions/useObjectsPermissions';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';

interface IObjectsMenuProps {
	objectId?: string;
}

export const ObjectsMenu = ({objectId}: IObjectsMenuProps) => {
	const object = useAppSelector(s =>
		(objectId ? extractObject(s, objectId) : undefined));
	const [addingDialog, openAddingDialog] = useAddingObjectDialog(
		object?.id,
		object?.companyId
	);

	const {canAdd} = useObjectsPermissions();
	const updateData = useAppUpdateMenuItem();

	const items = [updateData];

	if (canAdd) {
		items.push(
			<MenuItem
				key="add"
				icon={<i className="tz-plus-24"/>}
				onClick={openAddingDialog}
			>
				Добавить объект
			</MenuItem>
		);
	}

	return (
		<>
			<IconMenu>
				{items}
			</IconMenu>
			{canAdd && addingDialog}
		</>
	);
};