import React from 'react';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import CompaniesFilterPage from '@src/pages/filters/CompaniesFilterPage';
import {LegalsFiltersPage} from '@src/pages/filters/LegalsFiltersPage';

export const legalsFilterRoutes = [
	{
		path: 'companies',
		element: <CompaniesFilterPage/>
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage/>
	},
	{
		path: 'creationDate',
		element: <CreationDateFilterPage/>
	},
	{
		path: '',
		element: <LegalsFiltersPage/>
	}
];