import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingExportTemplate} from '@tehzor/tools/interfaces/exportTemplates/ISavingExportTemplate';
import {IEditExportTemplateResponse, makeExportTemplateEditRequest} from '@src/api/backend/exportTemplates/edit';

export type IEditExportTemplatePayload = IEditExportTemplateResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditExportTemplateResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при редактировании шаблона');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет шаблон
 * @param id идентификатор шаблона
 * @param fields данные для добавления
 */
export const editExportTemplate = (id: string, fields: ISavingExportTemplate) =>
	createApiAction<IEditExportTemplateResponse>(request, success, failure, () =>
		makeExportTemplateEditRequest(id, fields));
