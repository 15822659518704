import React, {Dispatch} from 'react';
import './EditableInternalAcceptanceAttachments.less';
import Attachments from '@src/components/editableFields/Attachments';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {IEditableInternalAcceptanceAction, IEditableInternalAcceptanceState} from '@src/core/hooks/states/useEditableInternalAcceptanceState';

interface IEditableInternalAcceptanceProps {
	editingState: IEditableInternalAcceptanceState;
	editingDispatch: Dispatch<IEditableInternalAcceptanceAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditableInternalAcceptanceAttachments = ({
	editingState,
	editingDispatch,
	uploadingFilesState,
	uploadingFilesDispatch,
	saving
}: IEditableInternalAcceptanceProps) => (
	<div className="editable-internal-acceptance-attachments">
		<div>
			<Attachments
				className={{
						attachBtn: 'editable-internal-acceptance-attachments__attach-btn'
				}}
				attachments={editingState.attachments}
				uploadingFiles={uploadingFilesState.value}
				editingDispatch={editingDispatch}
				uploadingFilesDispatch={uploadingFilesDispatch}
				disabled={saving}
				canDraw={false}
				hasError={editingState.errors.attachments && uploadingFilesState.error}
				showAttachBtn
			/>
		</div>
	</div>
	);
