import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {
	SelectPopup,
	FilterButton,
	TreeSelect,
	TreeSelectTagOption
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {extractProblemTagsAsArrayForObject} from '@src/store/modules/dictionaries/problemTags/selectors';
import {extractProblemTagsSetsAsArrayForObject} from '@src/store/modules/dictionaries/problemTagsSets/selectors';
import {makeTreeData} from '@src/store/modules/dictionaries/problemTagsSets/utils/makeTreeData';

interface IProblemTagsSetFilterProps {
	objectId: string;
	problemTags?: string[];
}

export const ProblemTagsSetFilter = memo(({objectId, problemTags}: IProblemTagsSetFilterProps) => {
	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedTags, setSelectedTags] = useState(problemTags);
	const [expandedTags, setExpandedTags] = useState<string[]>([]);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const tagsSets = useAppSelector(s => extractProblemTagsSetsAsArrayForObject(s, objectId));
	const allTags = useAppSelector(s => extractProblemTagsAsArrayForObject(s, objectId));
	const treeData = useMemo(() => makeTreeData(tagsSets, allTags), [tagsSets, allTags]);

	const {filteredData, expanded} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpandedTags(expanded.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		dispatch({problemTags: selectedTags});
		clearSearch();
	}, [selectedTags, dispatch, clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedTags([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		dispatch({problemTags: undefined});
		setExpandedTags([]);
		setSelectedTags([]);
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedTags(problemTags);
		clearSearch();
	}, [problemTags, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		setSelectedTags(allTags.map(val => val.id));
	}, [allTags, clearSearch]);

	useUpdateEffect(() => {
		setExpandedTags([]);
		setSelectedTags(problemTags);
	}, [problemTags]);

	return (
		<SelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedTags?.length}
			count={selectedTags?.length}
			footer
			onSelectAll={handleSelectAll}
			search={(
				<SelectSearch
					value={search}
					type="popup"
					onChange={setSearch}
				/>
			)}
			trigger={(
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel('Метки', problemTags, treeData)}
					active={!!problemTags?.length}
					onClear={handleFullClear}
				/>
			)}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedTags}
				onChange={setSelectedTags}
				expandedValue={expandedTags}
				onExpand={setExpandedTags}
				TreeItem={TreeSelectTagOption}
			/>
		</SelectPopup>
	);
});

ProblemTagsSetFilter.displayName = 'ProblemTagsSetFilter';
