import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {requestOwnerAcceptances} from '@src/api/backend/ownerAcceptances';

export type IGetSpaceOwnerAcceptancesPayload = INormalizedData<IListOwnerAcceptance>;

const request = () => ({type: types.GET_OWNER_ACCEPTANCES_REQUEST});

const success = (response: IGetSpaceOwnerAcceptancesPayload) => ({
	type: types.GET_OWNER_ACCEPTANCES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке приёмок');
	return {
		type: types.GET_OWNER_ACCEPTANCES_FAILURE,
		payload: error
	};
};

/**
 * Получает список приёмок собственниками для конкретного помещения
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 */
export const getSpaceOwnerAcceptances = (objectId: string, spaceId: string) =>
	checkExpiration<IState, IGetSpaceOwnerAcceptancesPayload, ApiAction>(
		s => s.entities.space.ownerAcceptances,
		createApiAction<IGetSpaceOwnerAcceptancesPayload>(request, success, failure, () =>
			requestOwnerAcceptances(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);
