import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialStateForPage} from '@src/store/modules/settings/pages/problems/reducers';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractWorkAcceptancesPageSettings = createSelector(
	(state: IState) => state.settings.pages.workAcceptances.byPage,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(rootData, objectId) => rootData[objectId] || getInitialStateForPage(objectId)
);