import useAppSelector from '@src/core/hooks/useAppSelector';
import {EntityGridItem, InlineButton} from '@tehzor/ui-components';
import classNames from 'classnames';
import React, {memo, useState} from 'react';

interface IObjectsCellProps {
	objectIds?: string[];
}

const NUMBER_OF_SHOWN = 2;

const objectsIcon = <i className="tz-outline-object-20"/>;

export const ObjectsCell = memo(({
	objectIds
}: IObjectsCellProps) => {
	const [isShown, setShown] = useState(false);
	const objectsMap = useAppSelector(s => s.dictionaries.objects.byId);

	const handleClick = () => setShown(s => !s);

	if (!objectIds?.length) {
		return null;
	}

	const objects = objectIds.map(id => objectsMap[id]?.name);

	const shownObjects = objects
		.filter((object, i) => (!isShown ? (i + 1) <= NUMBER_OF_SHOWN : true))
		.join(', ');
	const numberOfRemainingObjects = objects.length > NUMBER_OF_SHOWN
		? objects.length - NUMBER_OF_SHOWN
		: 0;

	return (
		<EntityGridItem
			className={{
				root: 'contract__cells-item',
				header: 'contract__cells-item-header',
				content: classNames(
					'contract__cells-objects', 'contract__cells-item-content'
				)
			}}
			label="Объекты"
			icon={objectsIcon}
			inline
		>
			<div>{shownObjects}</div>
			{!!numberOfRemainingObjects && (
				<InlineButton
					label={!isShown
						? `Показать все (${numberOfRemainingObjects})`
						: 'Скрыть'}
					type="accent"
					onClick={handleClick}
				/>
			)}
		</EntityGridItem>
	);
});