import {wsConnector} from '../wsConnector';
import {IWarrantyClaimStatus} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetWarrantyClaimStatusesResponse = INormalizedData<IWarrantyClaimStatus>;

/**
 * Возвращает список статусов гарантийных обращений
 */
export const requestWarrantyClaimStatuses = () =>
	wsConnector.sendAuthorizedRequest<IGetWarrantyClaimStatusesResponse>(
		'getWarrantyClaimStatuses'
	);
