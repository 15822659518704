import React, {memo} from 'react';
import './FixDateTag.less';
import {Tag} from '@tehzor/ui-components';
import {differenceInDays, formatDistanceStrict, isBefore} from 'date-fns';
import {ru} from 'date-fns/locale';
import classNames from 'classnames';

interface IFixDateTagProps {
	className?: string;
	plannedFixDate: number;
	size?: 'small' | 'medium' | 'large';
	actualFixDate?: number;
}

export const FixDateTag = memo(
	({className, plannedFixDate, size, actualFixDate}: IFixDateTagProps) => {
		const nowDate = actualFixDate ?? new Date();

		if (differenceInDays(plannedFixDate, nowDate) > 5) {
			return null;
		}

		const distance = formatDistanceStrict(plannedFixDate, nowDate, {
			addSuffix: false,
			locale: ru
		});
		const isExpired = isBefore(plannedFixDate, nowDate);
		const prefix = isExpired ? 'просрочено на' : 'до истечения срока';

		return (
			<Tag
				className={classNames(
					'fix-date-tag',
					isExpired
						? actualFixDate
							? ''
							: 'fix-date-tag_expired'
						: 'fix-date-tag_warning',
					className
				)}
				label={`${isExpired ? `${prefix} ${distance}` : `${distance} ${prefix}`}`}
				icon={(
					<i
						className={
							isExpired
								? actualFixDate
									? 'tz-alarm-clock'
									: 'tz-alarm-clock fix-date-tag__icon_expired'
								: 'tz-clock fix-date-tag__icon_warning'
						}
					/>
				)}
				color={isExpired ? (actualFixDate ? '#EEEFF3' : '#FFF1F1') : '#FFF5EE'}
				size={size || 'large'}
				multiline
			/>
		);
	}
);
