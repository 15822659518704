import React, {useCallback} from 'react';
import {IYearData} from '../hooks/useYearsGrid';
import CalendarButton from '../../../buttons/CalendarButton';

export interface IYearProps extends IYearData {
	active?: boolean;
	onClick?(value: number, differentPeriod: boolean): void;
}

const Year = (props: IYearProps) => {
	const {value, differentPeriod, active, onClick} = props;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(value, differentPeriod);
		}
	}, [value, differentPeriod]);

	return (
		<CalendarButton
			key={value}
			className="calendar__year"
			active={active}
			inactive={differentPeriod}
			onClick={handleClick}
		>
			{value}
		</CalendarButton>
	);
};

export default Year;
