import React, {useState} from 'react';
import {Button, InlineButton, Checkbox} from '@tehzor/ui-components';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCategoriesAsArray} from '@src/store/modules/dictionaries/categories/selectors';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import arrayToTree from 'array-to-tree';
import {extractFullCategoriesSetsAsArray} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import {useEditableCategoriesSetDialog} from '../hooks/useEditableCategoriesSetDialog';
import {deleteCategoriesSet, migrateCategoriesSet} from '@src/store/modules/dictionaries/categoriesSets/actions';
import {getTreeStructure, joinStuctures} from '@tehzor/tools/utils/tree/treeStuctures';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getStructuredCategoriesData, StructuredCategories} from './StructuredCategories';
import {ObjectSelect} from './ObjectSelect';

export const Content = () => {
	const dispatch = useAppDispatch();

	const [selectObjectsIds, setSelectObjectsIds] = useState<string[]>([]);
	const [deleteFromCategoriesSet, setDeleteFromCategoriesSet] = useState<boolean>(false);

	const categoriesSets = useAppSelector(extractFullCategoriesSetsAsArray)
		.filter(cs => cs.objects.some(c => selectObjectsIds?.includes(c)));

	const categories: ICategory[] = useAppSelector(extractCategoriesAsArray);
	const [index, setIndex] = useState<number>(0);
	const [comparedIndex, setComparedIndex] = useState<number>(0);

	const previousSet = () => { setIndex(index > 0 ? index - 1 : 0); };
	const nextSet = () => {
		if (index < (categoriesSets.length - 1)) {
			setIndex(index + 1);
		}
	};
	const previousComparedSet = () => {
		if (comparedIndex > 0) {
			setComparedIndex(comparedIndex - 1);
		}
	};
	const nextComparedSet = () => {
		if (comparedIndex < (categoriesSets.length - 1)) {
			setComparedIndex(comparedIndex + 1);
		}
	};

	if (index >= (categoriesSets.length)) {
		setIndex(categoriesSets.length - 1);
	}
	if (comparedIndex >= (categoriesSets.length)) {
		setComparedIndex(categoriesSets.length - 1);
	}

	const onSelect = (objectsIds: string[]) => {
		setSelectObjectsIds(objectsIds);
		setIndex(0);
		setComparedIndex(0);
	};

	const set: ICategoriesSet | undefined = categoriesSets[index];
	const comparedSet: ICategoriesSet = categoriesSets[comparedIndex];
	const setsCategories = categories.filter(cat => cat.categoriesSetId === set?.id);
	const tree = arrayToTree<ICategory>(setsCategories, {parentProperty: 'parentId'});
	const comparedSetsCategories = categories.filter(cat => cat.categoriesSetId === comparedSet?.id);
	const comparedTree = arrayToTree<ICategory>(comparedSetsCategories, {parentProperty: 'parentId'});

	const commonStructure = joinStuctures(
		getTreeStructure(tree, []),
		getTreeStructure(comparedTree, [])
	);

	const [editCategoriesDialog, editCategory] = useEditableCategoriesSetDialog(comparedSetsCategories, comparedSet);

	let err = false;
	const setErr = () => {
		err = true;
	};
	const matches: Array<{ fromId: string, toId: string }> = [];

	const addMatch = (match: { fromId: string, toId: string }) => {
		matches.push(match);
	};

	const migrate = async () => {
		const response = await dispatch(migrateCategoriesSet({
			fromSetId: set?.id,
			toSetId: comparedSet?.id,
			matches
		}));

		if (response?.success && deleteFromCategoriesSet) {
			void dispatch(deleteCategoriesSet(set?.id));
		}
	};

	const setStructuredCategoriesData = getStructuredCategoriesData(commonStructure, tree, comparedTree, '', true, setErr, addMatch);
	const comparedSetStructuredCategoriesData = getStructuredCategoriesData(commonStructure, comparedTree, tree, '', false, setErr, addMatch);

	return (

		<div>
			<ObjectSelect
				selectObjectsIds={selectObjectsIds}
				onSelect={onSelect}
			/>
			<div className="categories-set-migration-page__counter">{`Итого наборов: ${categoriesSets.length}`}</div>
			<div className="categories-set-migration-page__content">
				{set && (
					<div className="categories-set-migration-page__column">
						<div className="categories-set-migration-page__header">
							{`Старый набор ${set.name} (номер: ${index})`}
						</div>
						<div>
							<Button
								label="Предыдущий"
								className="categories-set-migration-page__button"
								type="common-bordered"
								onClick={previousSet}
							/>
							<Button
								label="Следующий"
								className="categories-set-migration-page__button"
								type="common-bordered"
								onClick={nextSet}
							/>
						</div>
						<div>
							<StructuredCategories
								structuredCategoriesData={setStructuredCategoriesData}
							/>

						</div>
					</div>
				)}
				{comparedSet && (
					<div className="categories-set-migration-page__column">
						<div className="categories-set-migration-page__header">
							{`Новый набор ${comparedSet.name} (номер: ${comparedIndex})`}
							<InlineButton
								className="categories-set-migration-page__inline-button"
								type="accent"
								leftIcon={<i className="tz-edit-16"/>}
								onClick={() => editCategory()}
							/>
						</div>
						<div>
							<Button
								label="Предыдущий"
								className="categories-set-migration-page__button"
								type="common-bordered"
								onClick={previousComparedSet}
							/>
							<Button
								label="Следующий"
								className="categories-set-migration-page__button"
								type="common-bordered"
								onClick={nextComparedSet}
							/>
						</div>
						<div>
							<StructuredCategories
								structuredCategoriesData={comparedSetStructuredCategoriesData}
							/>
						</div>
					</div>
				)}
			</div>
			{editCategoriesDialog}
			{set && comparedSet && (
				<div className="categories-set-migration-page__header">
					{`Миграция из ${set.name} в ${comparedSet.name}`}
				</div>
			)}
			<div>
				<Checkbox
					className="categories-set-migration-page__checkbox"
					checked={deleteFromCategoriesSet}
					onChange={() => setDeleteFromCategoriesSet(!deleteFromCategoriesSet)}
					disabled={err || set === undefined || set?.id === comparedSet?.id}
				>
					<span className="categories-set-migration-page__checkbox__label">
						Удалить старый набор
					</span>
				</Checkbox>
			</div>
			<Button
				label="Выполнить миграцию"
				className="categories-set-migration-page__button"
				type="accent-red"
				disabled={err || set === undefined || set?.id === comparedSet?.id}
				onClick={migrate}
			/>
		</div>
	);
};