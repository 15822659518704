import React from 'react';
import {
	Plate,
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	StatusSelect,
	LinkButton,
	LocationSelect
} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {editWorkAcceptanceStatus} from '@src/store/modules/entities/workAcceptance/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import Description from '@src/components/viewFields/Description';
import {Category} from '@src/components/viewFields/Category';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {Responsible} from '@src/components/viewFields/Responsible';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';

const locationIcon = <i className="tz-location-20"/>;
const calendarIcon = <i className="tz-calendar-20"/>;

interface IDesktopInfoProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
}

export const DesktopDescription = ({objectId, workAcceptance}: IDesktopInfoProps) => {
	const {pushPath} = useChangePath();
	const statuses = useAppSelector(s => s.dictionaries.workAcceptanceStatuses);
	const plans = useAppSelector(s => extractPlansAsArray(s, objectId));

	const dispatch = useAppDispatch();
	const permissions = useWorkAcceptancesPermissions(objectId);
	const [, handleChange] = useAsyncFn(
		async (v: WorkAcceptanceStatusIds) => {
			if (workAcceptance) {
				await dispatch(editWorkAcceptanceStatus(objectId, workAcceptance.id, v));
			}
		},
		[objectId, workAcceptance]
	);

	const formattedDate = workAcceptance?.acceptanceDate
		? format(workAcceptance.acceptanceDate, dateTimeFormat, {locale: ru})
		: undefined;

	const navigate = (structureId: string) => {
		pushPath(formStructureLink(objectId, structureId));
	};

	return (
		<Plate className={{content: 'work-acceptance-page__info-plate'}}>
			<EntityGrid withBorders>
				<EntityGridItem
					label="Статус"
					icon={<i className="tz-status-20"/>}
					fullRow
				>
					<StatusSelect
						className="work-acceptance-page__d-entities-status"
						statuses={statuses}
						availableStatuses={statuses.allIds}
						disabled={!permissions.canEditStatus}
						value={workAcceptance?.status}
						onChange={handleChange}
					/>
				</EntityGridItem>

				{workAcceptance.description && <Description value={workAcceptance.description}/>}

				<EntityGridItem
					className="fix-date-view-field"
					icon={calendarIcon}
					label="Срок приёмки"
					fullRow
				>
					<div className="fix-date-view-field__value">{formattedDate}</div>
				</EntityGridItem>

				{workAcceptance.categoryId && (
					<Category
						categoryId={workAcceptance.categoryId}
						fullRow
					/>
				)}

				{(workAcceptance.activeGroup || workAcceptance.respUsers?.length) && (
					<Responsible
						entity={workAcceptance}
						type="work-acceptance"
					/>
				)}

				<EntityGridItem
					icon={locationIcon}
					label="Фронт работ"
					fullRow
				>
					{workAcceptance.structures?.map(structure => (
						<div
							key={structure.id}
							className="semi-bold work-acceptance-page__structure"
						>
							<div className="work-acceptance-page__structure_name">
								<div
									role="presentation"
									onClick={() => {
										navigate(structure.id);
									}}
									className="work-acceptance-page__selected-structure "
								>
									<div className="work-acceptance-page__selected-structure_icon">
										<i className="tz-structure"/>
									</div>
									{structure.name}
								</div>
							</div>
							{structure.location && structure.planId && (
								<div className="work-acceptance-page__structure_markers">
									<LocationSelect
										mode="view"
										planId={structure.planId}
										location={structure.location}
										plans={plans}
										path="structure"
										multiplePoints
									>
										{(displayLocation: string, open: () => void) => (
											<div className="problem-page__d-entities-grid-display-place">
												<LinkButton
													label={displayLocation}
													onClick={open}
												/>
											</div>
										)}
									</LocationSelect>
								</div>
							)}
							<br/>
						</div>
					))}
				</EntityGridItem>

				<EditorInfo
					icon={calendarIcon}
					label="Создано"
					date={workAcceptance?.createdAt}
					user={workAcceptance?.createdBy}
					fullRow
				/>

				{workAcceptance?.createdAt !== workAcceptance?.modifiedAt && (
					<EditorInfo
						icon={calendarIcon}
						label="Изменено"
						date={workAcceptance?.modifiedAt}
						user={workAcceptance?.modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
		</Plate>
	);
};
