import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {extractSpacesPagesForObjects} from '@src/store/modules/entities/spaces/selectors/schemas';

const extractAllIds = (state: IState) => state.dictionaries.spaceTypes.allIds || [];
const extractById = (state: IState) => state.dictionaries.spaceTypes.byId || {};

/**
 * Возвращает типы помещений в виде массива
 */
export const extractSpaceTypesAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);

/**
 * Возвращает типы помещений для объекта
 */
export const extractSpaceTypesForObjectAsArray = createSelector(
	extractSpaceTypesAsArray,
	extractSpacesPagesForObjects,
	(types, pages) => {
		const pagesTypes = Array.from(
			new Set(
				pages.reduce((prev, page) => {
					for (const space of Object.values(page?.byId || {})) {
						prev.push(space.type);
					}
					return prev;
				}, [] as string[])
			)
		);
		return types.filter(type => pagesTypes.includes(type.id));
	}
);

/**
 * Возвращает типы помещений вместе с количеством помещений
 */
export const extractSpaceTypesAsArrayWithCount = createSelector(
	extractSpaceTypesAsArray,
	extractSpacesPagesForObjects,
	(types, pages) => {
		const counts = pages.reduce<Record<string, number>>((prev, page) => {
			for (const space of Object.values(page?.byId || {})) {
				prev[space.type] = (prev[space.type] || 0) + 1;
			}
			return prev;
		}, {});

		const result: Array<[ISpaceType, number]> = [];
		for (const type of types) {
			// Если понадобится массив со всеми типа (даже с 0),
			// то нужно будет создать отдельный селектор для возврата только непустых
			if (counts[type.id]) {
				result.push([type, counts[type.id] || 0]);
			}
		}
		return result;
	}
);
