import React, {useMemo} from 'react';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import ExpandablePlate from '@tehzor/ui-components/src/components/containers/ExpandablePlate';
import {OfflineJournalItem} from './components/OfflineJournalItem/OfflineJournalItem';
import {OfflineJournalTitle} from './components/OfflineJournalTitle/OfflineJournalTitle';
import {Breadcrumbs} from '@tehzor/ui-components';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {OfflineJournalFilters} from './components/OfflineJournalFilters/OfflineJournalFilters';
import {OfflineJournalPageCounter} from './components/OfflineJournalPageCounter/OfflineJournalPageCounter';
import {OfflineJournalSort} from './components/OfflineJournalSort/OfflineJournalSort';
import {MobileOfflineJournalFiltersBtn} from './components/MobileOfflineJournalFiltersBtn/MobileOfflineJournalFiltersBtn';
import './OfflineJournalPage.less';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {useJournalData} from './hooks/useJournalData';
import {useChangePath} from '@src/core/hooks/useChangePath';

export interface IOfflineJournalStatus {
	status?: OfflineDataTransferStatus;
	statusMessage?: string;
}
export interface IOfflineJournalEntity {
	entity: {
		entityId: string;
		entityLocalNumber?: string;
		entityType?: string;
		description?: string;
		checkListName?: string;
		checkItemName?: string;
		newRecordStatus?: string;
		newIndicators?: string[];
		newStatus?: string;
	};
	location?: {
		names: string[];
	};
	object?: {
		objectId?: string;
		objectName?: string;
		locationName?: string;
	};
	user?: {
		userName: string;
		phone?: string;
		email?: string;
	};
	transfer: IOfflineJournalStatus;
	createdAt?: Date | number;
}

const offlineJournalBreadcrumbs: IBreadcrumb[] = [
	{label: 'Профиль', url: '/profile'},
	{label: 'Журнал автономных действий', url: '/profile/offline-journal'}
];

export const OfflineJournalPage = () => {
	const isDesktop = useIsDesktop();
	const journalData = useJournalData();
	const {state} = useEntitiesFiltersCtx<IOfflineJournalFiltersState>();
	const isFiltersActive = useFiltersActive('all', state);
	const {pushPath} = useChangePath();
	const journalItems = useMemo(
		() =>
			journalData.map(el => (
				<ExpandablePlate
					key={el.entity.entityId}
					title={<OfflineJournalTitle offlineJournalEntity={el}/>}
					className={{
						root: 'offline-journal-page__list-item',
						content: 'offline-journal-page__list-content',
						expandButton: 'offline-journal-page__list-expand-button'
					}}
					initiallyClosed
				>
					<OfflineJournalItem offlineJournalEntity={el}/>
				</ExpandablePlate>
			)),
		[journalData]
	);
	return (
		<div className="page-cont offline-journal-page">
			<div className="offline-journal-page__header">
				<Breadcrumbs
					className="offline-journal-page__header-breadcrumbs"
					items={offlineJournalBreadcrumbs}
					onNavigate={pushPath}
				/>
				{isDesktop && (
					<OfflineJournalFilters
						className="offline-journal-page__header-filters"
						isFiltersActive={isFiltersActive}
					/>
				)}
				<div className="offline-journal-page__header-display">
					<OfflineJournalPageCounter length={journalItems.length}/>
					<div className="offline-journal-page__header-display-filters">
						<OfflineJournalSort/>
						{!isDesktop && (
							<MobileOfflineJournalFiltersBtn isFiltersActive={isFiltersActive}/>
						)}
					</div>
				</div>
			</div>
			<div className="offline-journal-page__list">{journalItems}</div>
		</div>
	);
};
