import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	aggregateByUsers,
	extractChecksStatsData,
	extractInspectionsStatsData,
	extractProblemRepliesStatsData,
	extractProblemsStatsData,
	extractWarrantyClaimsStatsData
} from '@src/store/modules/statistics/warranty/selectors/creation';
import UserStats from './UserStats';
import {useFilteredInspectors} from '@src/pages/StatisticsPage/hooks/useFilteredInspectors';
import {byObjects} from '@src/utils/statsAggregators/creation';
import {areCreationStatsLoading} from '@src/store/modules/statistics/ui/selectors/warranty';
import StatsTreePlaceholder from '../../StatsTreePlaceholder';
import CommonStatsCards from './CommonStatsCards';
import {isWarrantyFiltersSetUp} from '@src/store/modules/settings/pages/statistics/warranty/selectors/filters';

const ByUsersStats = () => {
	const checksStats = useAppSelector(extractChecksStatsData);
	const problemStats = useAppSelector(extractProblemsStatsData);
	const problemRepliesStats = useAppSelector(extractProblemRepliesStatsData);
	const inspectionsStats = useAppSelector(extractInspectionsStatsData);
	const warrantyClaimsStats = useAppSelector(extractWarrantyClaimsStatsData);
	const usersStats = useAppSelector(aggregateByUsers);
	const filters = useAppSelector(s => s.settings.pages.statistics.warranty.filters);
	const isFiltersSetUp = useAppSelector(isWarrantyFiltersSetUp);
	const users = useFilteredInspectors(
		usersStats,
		userId =>
			byObjects({
				checks: checksStats.filter(item => item.userId === userId),
				problems: problemStats.filter(item => item.userId === userId),
				problemReplies: problemRepliesStats.filter(item => item.userId === userId),
				inspections: inspectionsStats.filter(item => item.userId === userId),
				warrantyClaims: warrantyClaimsStats.filter(item => item.userId === userId)
			}),
		filters,
		isFiltersSetUp
	);
	const loading = useAppSelector(areCreationStatsLoading);

	return (
		<>
			{users.map(({user, userStats, objects, objectsStats}) => (
				<UserStats
					key={user.id}
					user={user}
					userStats={userStats}
					objects={objects}
					objectsStats={objectsStats}
				/>
			))}

			{loading && users.length === 0 && (
				<StatsTreePlaceholder
					includeSubTitle
					includeChildren
					permanentContent={<CommonStatsCards/>}
				/>
			)}
		</>
	);
};

export default ByUsersStats;
