import React from 'react';
import MobileMenu from './Menu.mobile';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import MobileSpaceExport from './SpaceExport.mobile';

interface IMobileActionsProps {
	objectId: string;
	space: ISpace;
}

const ActionsMobile = ({objectId, space}: IMobileActionsProps) => {
	const permissions = useSpacesPermissions(objectId);

	return (
		<>
			{permissions.canExport && (
				<MobileSpaceExport
					objectId={objectId}
					spaceId={space.id}
				/>
			)}

			<MobileMenu
				space={space}
				canDeleteSpace={permissions.canDelete}
				canEditSpace={permissions.canEdit}
			/>
		</>
	);
};

export default ActionsMobile;
