import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ITaskType} from '@tehzor/tools/interfaces/tasks/ITaskType';

export type ITaskTypesState = ILoadableEntitiesState<ITaskType>;

export const taskTypes = createReducer<ITaskTypesState>(
	getLoadableEntitiesInitialState<ITaskType>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ITaskType>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ITaskType>()
	}
);
