import React from 'react';
import './DelegationHistoryItem.less';
import {IEnrichedDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IEnrichedDelegationHistory';
import {UsersOrGroup} from './components/UsersOrGroup';
import classNames from 'classnames';
import {UserActionInfo} from './components/UserActionInfo';
import {AutomaticActionInfo} from './components/AutomaticActionInfo';

interface IDelegationHistoryItemProps {
	className?: string;
	style?: React.CSSProperties;
	data: IEnrichedDelegationHistory;
}

export const DelegationHistoryItem = ({className, style, data}: IDelegationHistoryItemProps) => {
	const {fromGroup, toGroup, toUsers, ruleId, createdBy, createdAt} = data;

	return (
		<div
			className={classNames('delegation-history-item', className)}
			style={style}
		>
			{ruleId !== undefined ? (
				<AutomaticActionInfo createdAt={createdAt}/>
			) : createdBy !== undefined ? (
				<UserActionInfo
					createdBy={createdBy}
					createdAt={createdAt}
				/>
			) : null}

			<div className="delegation-history-item__transition">
				<UsersOrGroup group={fromGroup}/>

				{fromGroup && (toGroup || toUsers?.length) ? (
					<i className="tz-arrow-20 delegation-history-item__transition-arrow"/>
				) : null}

				<UsersOrGroup
					users={toUsers}
					group={toGroup}
				/>
			</div>
		</div>
	);
};

DelegationHistoryItem.displayName = 'DelegationHistoryItem';