import {togglePlanBusy} from '@src/actions/ui/planUIActions';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddPlanResponse, makePlanAddRequest} from '@src/api/backend/plan';
import {addErrorToast} from '@src/utils/toasts';
import ISavingPlan from '@tehzor/tools/interfaces/plans/ISavingPlan';

export type IAddPlanPayload = IAddPlanResponse;

const request = () => {
	togglePlanBusy(true);
	return {type: types.ADD_REQUEST};
};

const success = (response: IAddPlanResponse) => {
	togglePlanBusy(false);
	return {
		type: types.ADD_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при создании плана');
	}
	togglePlanBusy(false);
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет новый план
 *
 * @param objectId id объекта
 * @param plan данные нового плана
 */
export const addPlan = (objectId: string, plan: ISavingPlan) =>
	createApiAction<IAddPlanResponse>(request, success, failure, () =>
		makePlanAddRequest(objectId, plan));
