import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useInternalAcceptanceDelete} from '../../hooks/useInternalAcceptanceDelete';

interface IDesktopMenuProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
	canDeleteInternalAcceptance?: boolean;
}

const DesktopMenu = ({objectId, internalAcceptance, canDeleteInternalAcceptance}: IDesktopMenuProps) => {
	const [deleteDialog, handleDeleteClick] = useInternalAcceptanceDelete(objectId, internalAcceptance);

	const items = [];
	if (canDeleteInternalAcceptance) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDeleteInternalAcceptance && deleteDialog}
		</>
	) : null;
};

export default DesktopMenu;
