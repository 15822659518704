import React, {Dispatch, useCallback, useMemo} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {CategorySelect, EditableFieldLabel} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractAllCategoriesAsArray} from '@src/store/modules/dictionaries/categories/selectors';
import makeTreeData from '@src/store/modules/dictionaries/categoriesSets/utils/makeTreeData';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {extractCategoriesSetsAsArray} from '@src/store/modules/dictionaries/categoriesSets/selectors';

interface ICategoriesSetProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value?: string[] | null;
	stages?: string[];
	objectId: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const CategoriesSet = <S extends {categories?: string[] | null}, E>({
	className,
	style,
	label = 'Виды работ',
	value,
	objectId,
	stages,
	editingDispatch,
	required,
	disabled,
	hasError
}: ICategoriesSetProps<S, E>) => {
	const categoriesSets: ICategoriesSet[] = useAppSelector(s =>
		extractCategoriesSetsAsArray(s, objectId)
	);

	const filteredCategoriesSets = useMemo(
		() =>
			categoriesSets.filter(cs =>
				stages?.length && cs.stages?.length
					? cs.stages?.some(st => stages.includes(st))
					: cs
			),
		[categoriesSets, stages]
	);

	const allCategories = useAppSelector(extractAllCategoriesAsArray);
	const treeData = makeTreeData(filteredCategoriesSets, allCategories);

	const categories = useMemo(() => value || [], [JSON.stringify(value)]);

	const handleChange = useCallback(
		(v: string[]) => {
			editingDispatch({type: 'update', field: 'categories', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'categories'});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<CategorySelect
				multiple
				treeData={treeData}
				value={categories}
				error={required && hasError ? 'Выберите вид работ' : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};
