import {useQuery} from '@tanstack/react-query';
import {authorizedPersonsQueryKeys} from '@src/api/cache/authorizedPersons/keys';
import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {useMemo} from 'react';
import {IGetAuthorizedPersonsResponse} from '@src/api/backend/authorizedPersons/get';

export const useAuthorizedPersons = () => {
	const {data} = useQuery<IGetAuthorizedPersonsResponse, IError>({
		queryKey: [...authorizedPersonsQueryKeys.list()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке уполномоченных лиц');
		}
	});
	return data;
};

export const useAllAuthorizedPersonsArray = () => {
	const authorizedPersons = useAuthorizedPersons();

	return useMemo(() => authorizedPersons?.allIds?.map(id => authorizedPersons.byId[id]) || [], [authorizedPersons]);
}

export const useAuthorizedPersonsArray = (
	legalId?: string,
	authorizedPersons?: IGetAuthorizedPersonsResponse
) =>
	useMemo(() => {
		if (!authorizedPersons?.allIds?.length) {
			return [];
		}

		return authorizedPersons.allIds
			.map(id => authorizedPersons.byId[id])
			.filter(el => el.legalEntityId === legalId);
	}, [authorizedPersons, legalId]);

export const useAuthorizedPersonsMap = () => {
	const authorizedPersons = useAuthorizedPersons();
	return useMemo(() => authorizedPersons?.byId || {}, [authorizedPersons]);
}

export const useFilteredAuthorizedPersonsArray = (
	companyId?: string, legalEntityId?: string
) => {
	const authorizedPersonsArray = useAllAuthorizedPersonsArray();

	return useMemo(() => {
		if (!companyId || !legalEntityId) { return []; }
		return authorizedPersonsArray.filter(item =>
			item.legalEntityId === legalEntityId && item.companyId === companyId);
	}, [authorizedPersonsArray, companyId, legalEntityId]);
}
