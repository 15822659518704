import {wsConnector} from '../wsConnector';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';
import {IProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTagsSet';

export type IAddProblemTagsSetResponse = IProblemTagsSet;

/**
 * Добавляет набор меток нарушений
 *
 * @param fields данные набора
 */
export const makeProblemTagsSetAddRequest = (fields: ISavingProblemTagsSet) =>
	wsConnector.sendAuthorizedRequest<IAddProblemTagsSetResponse>('addProblemTagsSet', {...fields});
