import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting,
	handleLoadableEntityAdding,
	handleLoadableEntityDeletion,
	handleLoadableEntityEditing,
	ILoadableEntitiesState
} from '@tehzor/tools/core/storeHelpers/reducers';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';

export type ITasksState = ILoadableEntitiesState<ITask>;

export const tasks = createReducer<ITasksState>(getLoadableEntitiesInitialState<ITask>(), {
	[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ITask>(),
	[types.ADD_SUCCESS]: handleLoadableEntityAdding<ITask>(),
	[types.EDIT_SUCCESS]: handleLoadableEntityEditing<ITask>(),
	[types.DELETE_SUCCESS]: handleLoadableEntityDeletion<ITask>(),
	[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ITask>()
});
