import React from 'react';
import './ButtonMenu.less';
import Button, {ButtonType} from '../../buttons/Button';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {IPopupBasicProps} from '../../containers/Popup';
import MenuLayer from '../MenuLayer';

const icon = <i className="tz-simple-arrow-16"/>;

interface IButtonMenuProps<V, M extends IMenuComponentProps<V>> {
	className?: string | {
		root?: string;
		wrap?: string;
		label?: string;
		leftIcon?: string;
		rightIcon?: string;
	};
	style?: React.CSSProperties;
	children?: React.ReactElement<M> | Array<React.ReactElement<IMenuItemProps>>;
	type?: ButtonType;
	leftIcon?: React.ReactNode;
	label?: React.ReactNode;
	disabled?: boolean;
	popupArrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
}

const ButtonMenu = <V, M extends IMenuComponentProps<V>>(props: IButtonMenuProps<V, M>) => {
	const {
		className,
		style,
		children,
		type,
		leftIcon,
		label,
		disabled,
		popupArrowVisible,
		popupProps
	} = props;

	const classes = convertClassNames(className);

	return (
		<MenuLayer
			trigger={({isOpen, toggle, ref, disabled: triggerDisabled}) => (
				<Button
					className={{
						root: classNames('button-menu__button', classes.root),
						wrap: classNames('button-menu__button-wrap', classes.wrap),
						label: classNames('button-menu__button-label', classes.label),
						leftIcon: classes.leftIcon,
						rightIcon: classNames('button-menu__button-icon', {
							'button-menu__button-icon_open': isOpen
						}, classes.rightIcon)
					}}
					style={style}
					type={type}
					leftIcon={leftIcon}
					label={label}
					rightIcon={icon}
					disabled={disabled || triggerDisabled || !children}
					onClick={toggle}
					ref={ref}
				/>
			)}
			popupArrowVisible={popupArrowVisible}
			popupProps={{placement: 'bottom-start', preferX: 'left', ...popupProps}}
		>
			{children}
		</MenuLayer>
	);
};

ButtonMenu.displayName = 'ButtonMenu';

export default ButtonMenu;
