import React from 'react';
import './MessageReference.less';
import classNames from 'classnames';
import {IconButton} from '../../buttons';

interface ICommentReferenceProps {
	className?: string;
	style?: React.CSSProperties;
	title?: string;
	description?: string;

	onDelete?: () => void;
}

const MessageReference = ({
	className,
	style,
	title,
	description,
	onDelete
}: ICommentReferenceProps) => (
	<div
		className={classNames('message-reference', className)}
		style={style}
	>
		<div className="message-reference__wrapper">
			{title !== undefined && (
				<div className="message-reference__title">{title}</div>
			)}

			{description !== undefined && (
				<div className="message-reference__description">{description}</div>
			)}
		</div>

		{onDelete !== undefined && (
			<IconButton
				className="message-reference__delete-btn"
				type="inline"
				onClick={onDelete}
			>
				<i className="tz-close-24"/>
			</IconButton>
		)}
	</div>
);

export default MessageReference;