import React from 'react';
import {toast, ToastOptions} from 'react-toastify';
import {ActionButtons, Button, IconButton} from '@tehzor/ui-components';
import './EmailNotificationsToast.less';

const closeIcon = <i className="tz-close-24" />

const toastOptions: ToastOptions = {
	autoClose: false,
	closeOnClick: true,
	className: 'email-notifications-toast',
	bodyClassName: 'body',
	closeButton: (
		<IconButton className="email-notifications-toast__close">
			{closeIcon}
		</IconButton>
	),
	position: 'bottom-right',
	containerId: 'EmailNotificationsToastContainer'
};

export const addToast = (
	title?: string,
	desc?: string,
	onSubscribe?: () => Promise<void>,
	onUnsubscribe?: () => Promise<void>
) =>
	toast(
		({closeToast}) => (
			<div
				className="email-notifications-toast__body"
				onClick={closeToast}
			>
				<div className="email-notifications-toast__body-text">
					<div className="email-notifications-toast__body-text__title">{title}</div>
					<div className="email-notifications-toast__body-text__desc">{desc}</div>
				</div>
				<div className="email-notifications-toast__body-actions">
					<ActionButtons>
						<Button
							onClick={onUnsubscribe}
							type="cancel"
							label="Нет"
						/>
						<Button
							onClick={onSubscribe}
							type="accent-blue"
							label="Да"
						/>
					</ActionButtons>
				</div>
			</div>
		),
		toastOptions
	);
