import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {getSpace} from '@src/store/modules/app/links/actions/getSpace';
import {LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formSpaceLink} from '@tehzor/tools/utils/links';

export const SpaceEntityLink = ({spaceId, objectId}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const space = useAppSelector(s => s.app.links.spaceId?.[spaceId]);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes.byId);

	const handleClick = useCallback(() => {
		pushPath(formSpaceLink(objectId, spaceId));
	}, [objectId, spaceId]);

	useAsync(async () => {
		await Promise.all([dispatch(getSpace(objectId, spaceId))]);
	}, [spaceId, objectId]);

	if (!space || !spaceTypes[space.type]) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${spaceTypes[space.type]?.singularName} №${space.name}`}
			leftIcon={<i className="tz-external-link-16"/>}
			onClick={handleClick}
			type="filled"
		/>
	);
};
