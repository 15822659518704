import React, {useMemo} from 'react';
import './ReadMore.less';
import {useToggle} from 'react-use';
import LinkButton from '../../buttons/LinkButton';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IReadMoreProps {
	text: string;
	maxLength: number;
	className?:
	| string
	| {
			root?: string;
			button?: string;
	  };
}

export const ReadMore = ({text, maxLength, className}: IReadMoreProps) => {
	const [visibleText, setVisibleText] = useToggle(false);
	const isLong = useMemo(() => text.length > maxLength, [text, maxLength]);
	const classes = convertClassNames(className);
	return (
		<div
			className={classNames('read-more', classes.root)}
		>
			{visibleText || !isLong ? `${text} ` : `${text.slice(0, maxLength)}... `}
			{isLong && (
				<LinkButton
					className={classNames('read-more-button', classes.button)}
					label={visibleText ? 'Свернуть' : 'Показать полностью'}
					onClick={setVisibleText}
				/>
			)}
		</div>
	);
};
ReadMore.displayName = 'ReadMore';
