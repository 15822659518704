import {IconButton} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import React, {useContext} from 'react';
import {useAsyncFn} from 'react-use';
import {IPreparedSpaceStatusesSet} from '../../interfaces/IPreparedSpaceStatusesSet';
import {DispatchActionCtx} from '../table/SpaceStatusesSetsTable';

interface ISelectionActionsProps {
	selectedEntities: IPreparedSpaceStatusesSet[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete"/>;

export const SelectionActions = ({
	selectedEntities, onClear
}: ISelectionActionsProps) => {
	const canDelete = selectedEntities.every(item => item.canDelete);

	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить наборы статусов помещений',
		'Вы действительно хотите удалить выбранные наборы статусов?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(
		async () => {
			if (await getDeleteConfirmation()) {
				for (const item of selectedEntities) {
					dispatchAction({type: 'delete', payload: item});
				}
				onClear();
			}
		},
		[selectedEntities]
	);

	return (
		<>
			{canDelete && (
				<>
					<IconButton onClick={handleDelete}>
						{deleteIcon}
					</IconButton>

					{deleteDialog}
				</>
			)}
		</>
	);
};