import {Column} from 'react-table';
import {IReportsTableRow} from '../../hooks/useReportsTable';
import {ExpanderCell} from './ExpanderCell';
import {FieldCell} from './FieldCell';
import {NameCell} from './NameCell';
import {SelectedCell} from './SelectedCell';
import {SelectedPercentCell} from './SelectedPercentCell';

export const buildColumns = (
	fieldArr: string[],
	fieldMap: Record<string, {id: string, name: string}>
) => {
	const columns: Array<Column<IReportsTableRow>> = [];
	columns.push(
		{
			id: 'expander',
			Cell: ExpanderCell
		},
		{
			id: 'spaces',
			Header: 'Тип помещений / Объект',
			Cell: NameCell
		},
		{
			id: 'total',
			Header: 'Всего помещений',
			Cell: SelectedCell
		}
	);

	for (const fieldKey of fieldArr) {
		const item = fieldMap?.[fieldKey];
		if (!item) { continue; }

		columns.push({
			id: item.id,
			Header: item.name,
			Cell: FieldCell
		});
	}

	columns.push(
		{
			id: 'selected',
			Header: 'Выбрано помещений',
			Cell: SelectedCell
		},
		{
			id: 'selectedPercent',
			Header: 'Выбрано помещений, %',
			Cell: SelectedPercentCell
		}
	);

	return columns;
};