import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IProblemStatusesState = ILoadableEntitiesState<IProblemStatus>;

export const problemStatuses = createReducer<IProblemStatusesState>(
	getLoadableEntitiesInitialState<IProblemStatus>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IProblemStatus>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IProblemStatus>()
	}
);
