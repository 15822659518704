import React from 'react';
import './OwnerAcceptancePage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {Desktop} from './components/Desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Mobile} from './components/Mobile';
import {
	getOwnerAcceptance,
	getOwnerAcceptanceProblemReplies,
	getOwnerAcceptanceProblems
} from '@src/store/modules/entities/ownerAcceptance/actions';
import {extractOwnerAcceptance} from '@src/store/modules/entities/ownerAcceptance/selectors/acceptance';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const OwnerAcceptancePage = () => {
	useScrollRestoration();
	const {objectId, ownerAcceptanceId} = useStrictParams<{
		objectId: string;
		ownerAcceptanceId: string;
	}>();
	const ownerAcceptance = useAppSelector(extractOwnerAcceptance);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await Promise.all<unknown>([
			dispatch(getOwnerAcceptance(objectId, ownerAcceptanceId)),
			dispatch(getOwnerAcceptanceProblems(objectId, ownerAcceptanceId)),
			dispatch(getOwnerAcceptanceProblemReplies(objectId, ownerAcceptanceId))
		]);
	});
	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				ownerAcceptanceId={ownerAcceptanceId}
				ownerAcceptance={ownerAcceptance}
			/>
		);
	}
	return (
		<Mobile
			objectId={objectId}
			ownerAcceptanceId={ownerAcceptanceId}
			ownerAcceptance={ownerAcceptance}
		/>
	);
};
