/**
 * Проверят идентичность локальных фильтров и локального состояния
 *
 * @param a1 локальное состояние
 * @param a2 фильтры
 */
export const isLocalStateEqual = (
	a1: string[] | Date | undefined,
	a2: string[] | Date | undefined
) => {
	if (a1 === undefined && a2 === undefined) {
		return true;
	}

	if (a1 instanceof Date && a2 instanceof Date) {
		return a1.getTime() === a2.getTime();
	}
	if (a1 && a2 && !(a1 instanceof Date) && !(a2 instanceof Date) && a1.length === a2.length) {
		const a = [...a1];
		const b = [...a2];
		return a.sort().every((element, index) => element === b.sort()[index]);
	}
	return false;
};
