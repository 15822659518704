import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';

export function formResponsibleDisplayValue(
	respUsers: string[] | undefined,
	activeGroup: string | undefined,
	users: Record<string, IBriefUser>,
	groups: Record<string, IWorkingGroup>
) {
	if (respUsers?.length) {
		return respUsers
			.reduce<string[]>((prev, id) => {
				if (users[id]) {
					prev.push(users[id].displayName);
				}
				return prev;
			}, [])
			.join(', ');
	}
	if (activeGroup) {
		return groups[activeGroup]?.name ?? '';
	}
	return '';
}

export function formNewResponsibleDisplayValue(
	respUsers: string[] | undefined,
	activeGroup: string | undefined,
	users: Record<string, IBriefUser>,
	groups: Record<string, IWorkingGroup>
) {
	if (respUsers?.length) {
		const array = respUsers.reduce<string[]>((prev, id) => {
			if (users[id]) {
				prev.push(users[id].displayName);
			}
			return prev;
		}, []);
		return array;
	}
	if (activeGroup) {
		return [groups[activeGroup]?.name] ?? [];
	}
	return [];
}

/**
 * Формирует отображаемое значение для ответственных нарушений (пользователи или группа)
 *
 * @param respUsers пользователи
 * @param activeGroup группа
 */
export function useResponsibleDisplayValue(respUsers?: string[], activeGroup?: string) {
	const users = useAppSelector(s => s.dictionaries.users.byId);
	const groups = useAppSelector(s => s.dictionaries.workingGroups.byId);

	return useMemo(
		() => formResponsibleDisplayValue(respUsers, activeGroup, users, groups),
		[respUsers, activeGroup, users, groups]
	);
}
