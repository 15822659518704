import React, {useCallback, useEffect, useRef, useState} from 'react';
import './AddingOwnerAcptDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IAddingAcceptanceRefProps} from './components/AddingAcceptance';
import {IAddingProblemRefProps} from '../AddingProblem';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {useOwnerAcceptanceMaps} from './hooks/useOwnerAcceptanceMaps';
import {saveOwnerAcceptance} from './utils/saveOwnerAcceptance';
import {getUserOwnerAcceptance} from '@src/store/modules/dictionaries/latestUserOwnerAcceptances/actions/getOne';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useLocalOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/useGetOwnerAcceptances';

export type AddingOwnerAcceptanceView = 'acceptance' | 'problem' | undefined;

interface IAddingOwnerAcceptanceDialogProps {
	objectId: string;
	spaceId?: string;
	acceptanceId?: string;
	links?: IOwnerAcceptance['links'];
	defaultProblemData?: ISavingProblem;
	isOpen: boolean;
	title: string;

	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const AddingOwnerAcptDialog = (props: IAddingOwnerAcceptanceDialogProps) => {
	const {
		objectId,
		spaceId,
		acceptanceId,
		links,
		defaultProblemData,
		isOpen,
		onClose,
		onSuccess,
		title
	} = props;

	const [selectedOwnerAcceptanceId, setSelectedOwnerAcceptanceId] = useState<string | undefined>(
		acceptanceId
	);
	const dispatch = useAppDispatch();
	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingAcceptanceRefProps>(null);

	const [view, setView] = useState<AddingOwnerAcceptanceView>();
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const localOwnerAcceptances = useLocalOwnerAcceptances();
	const localOwnerAcceptancesIds = localOwnerAcceptances.map(acp => acp.id);

	useEffect(() => {
		setView(!selectedOwnerAcceptanceId ? 'acceptance' : 'problem');
	}, [selectedOwnerAcceptanceId]);

	const stage = ObjectStageIds.TRANSFER;
	const stages = useAppSelector(extractObjectStagesAsArray);
	const [contentMap, entitiesSelectMapProps] = useOwnerAcceptanceMaps({
		objectId,
		acceptanceId,
		spaceId,
		stage,
		links,
		view,
		addingEntityRef,
		saving,
		setSaving,
		selectedOwnerAcceptanceId,
		setSelectedOwnerAcceptanceId,
		defaultProblemData,
		onClose
	});

	const [, handleSave] = useAsyncFn(async () => {
		const updateLatest = async () => {
			if (
				selectedOwnerAcceptanceId
				&& !localOwnerAcceptancesIds.includes(selectedOwnerAcceptanceId)
			) {
				await dispatch(getUserOwnerAcceptance(objectId, selectedOwnerAcceptanceId));
			}
		};
		await saveOwnerAcceptance({
			addingEntityRef,
			localOwnerAcceptancesIds,
			view,
			online,
			updateLatest,
			selectedOwnerAcceptanceId,
			success,
			onClose
		});
	}, [
		addingEntityRef,
		selectedOwnerAcceptanceId,
		localOwnerAcceptancesIds,
		online,
		view,
		success,
		onClose
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [onClose]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onSuccess]);

	return (
		<Dialog
			className={{
				root: 'adding-owner-acpt-dialog',
				header: 'adding-owner-acpt-dialog__header',
				body: 'adding-owner-acpt-dialog__body'
			}}
			isOpen={isOpen}
			title={title}
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			<EntitySelectContainer
				contentMap={contentMap}
				entitiesSelectMapProps={entitiesSelectMapProps}
				selectedStage={stage}
				stages={stages}
			/>
		</Dialog>
	);
};

export default AddingOwnerAcptDialog;
