import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ITaskPriority} from '@tehzor/tools/interfaces/tasks/ITaskPriority';

export type ITaskPrioritiesState = ILoadableEntitiesState<ITaskPriority>;

export const taskPriorities = createReducer<ITaskPrioritiesState>(
	getLoadableEntitiesInitialState<ITaskPriority>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ITaskPriority>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<ITaskPriority>()
	}
);
