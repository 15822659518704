import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';

export type IGetProblemTagsResponse = INormalizedData<IProblemTag>;

/**
 * Возвращает список меток нарушений
 */
export const requestProblemTags = async () =>
	wsConnector.sendAuthorizedRequest<IGetProblemTagsResponse>('getProblemTags');
