import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import {GET_REQUEST} from '@src/store/modules/entities/workAcceptances/constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeWorkAcceptanceDisplayModePayload} from '../actions';
import {WorkAcceptanceDisplayMode} from '@src/interfaces/WorkAcceptanceDisplayMode';

export interface IWorkAcceptancesFiltersState extends Record<string, unknown> {
	objects?: string[];
	statuses?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
	structureIds?: string[];
}

export interface IWorkAcceptancesSortState {
	[column: string]: boolean;
}

export interface IWorkAcceptancesPageSettingsState {
	displayMode: WorkAcceptanceDisplayMode;
	filters: IWorkAcceptancesFiltersState;
	sort: IWorkAcceptancesSortState;
	pageSize: number;
}

export type IWorkAcceptancesPagesSettingsState = Record<string, IWorkAcceptancesPageSettingsState>;

export const getInitialStateForPage = (objectId: string): IWorkAcceptancesPageSettingsState => ({
	displayMode: WorkAcceptanceDisplayMode.DESCRIPTION,
	filters: {
		objects: objectId !== 'all' ? [objectId] : undefined
	},
	sort: {
		createdAt: false
	},
	pageSize: 20
});

export const byPage = createReducer<IWorkAcceptancesPagesSettingsState>(
	{},
	{
		[GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage(payload.objectId);
			}
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<IWorkAcceptancesFiltersState>}
		) => {
			const {filters} = payload;
			if (payload.objectId !== 'all') {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CLEAR_FILTERS]: (state, {payload}: {payload: IClearFiltersPayload}) => {
			const filters = {} as IWorkAcceptancesFiltersState;
			if (payload.objectId !== 'all') {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<IWorkAcceptancesSortState>}
		) => {
			state[payload.objectId].sort = payload.sort;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: IChangePageSizePayload}) => {
			state[payload.objectId].pageSize = payload.pageSize;
		},
		[types.CHANGE_DISPLAY_MODE]: (
			state,
			{payload}: {payload: IChangeWorkAcceptanceDisplayModePayload}
		) => {
			state[payload.objectId].displayMode = payload.displayMode;
		},
		[types.SET_INITIAL_STATE]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage(payload.objectId);
			}
		}
	}
);
