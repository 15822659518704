import {useLocalInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/useGetInternalAcceptances';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLatestUserInternalAcceptances} from '@src/store/modules/dictionaries/latestUserInternalAcceptances/actions/get';
import {getUserInternalAcceptance} from '@src/store/modules/dictionaries/latestUserInternalAcceptances/actions/getOne';
import {
	extractAllLatestInternalAcceptancesAsArray,
	extractLatestInternalAcceptanceId
} from '@src/store/modules/dictionaries/latestUserInternalAcceptances/selectors';
import {extractInternalAcceptancesAsArray} from '@src/store/modules/entities/internalAcceptances/selectors';
import {onlineManager} from '@tanstack/react-query';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {useAsync} from 'react-use';

export const useLatestInternalAcceptances = (
	objectId: string,
	setSelectedInternalAcceptanceId: Dispatch<SetStateAction<string | undefined>>,
	selectedInternalAcceptanceId?: string,
	links?: IInternalAcceptance['links']
) => {
	const dispatch = useAppDispatch();
	const allInternalAcceptances = useAppSelector(s =>
		extractInternalAcceptancesAsArray(s, objectId)
	);
	const latestInternalAcceptanceId = useAppSelector(s =>
		extractLatestInternalAcceptanceId(s, objectId, links?.spaceId)
	);
	const allLatestInternalAcceptances = useAppSelector(extractAllLatestInternalAcceptancesAsArray);

	const localInternalAcceptances = useLocalInternalAcceptances();
	const isInternalAcp = !!allInternalAcceptances.find(
		item => item.id === selectedInternalAcceptanceId
	);
	const isLatestInternalAcp = !!allLatestInternalAcceptances.find(
		item => item.id === selectedInternalAcceptanceId
	);
	const isLocalInternalAcp = !!localInternalAcceptances.find(
		item => item.id === selectedInternalAcceptanceId
	);

	useEffect(() => {
		setSelectedInternalAcceptanceId(latestInternalAcceptanceId);
	}, [latestInternalAcceptanceId]);
	useAsync(async () => {
		if (onlineManager.isOnline()) {
			await dispatch(getLatestUserInternalAcceptances());
		}
	}, []);

	useAsync(async () => {
		if (
			onlineManager.isOnline() &&
			selectedInternalAcceptanceId &&
			isInternalAcp &&
			!isLatestInternalAcp &&
			!isLocalInternalAcp
		) {
			const acp = await dispatch(
				getUserInternalAcceptance(objectId, selectedInternalAcceptanceId)
			);
			setSelectedInternalAcceptanceId(acp.id);
		}
	}, [
		selectedInternalAcceptanceId,
		allLatestInternalAcceptances,
		objectId,
		localInternalAcceptances,
		allInternalAcceptances
	]);
};
