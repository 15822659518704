import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {changeFilters, changeOffset, clearFilters} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/actions';
import {ISpaceStatusesSetsFiltersState} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/reducers';
import {extractSpaceStatusesSetsPageSettings} from '@src/store/modules/settings/pages/manage/spaceStatusesSets/selectors';
import React, {useCallback} from 'react';
import {SpaceStatusesSetsPage} from './SpaceStatusesSetsPage';

export const AllSpaceStatusesSetsPageWrap = () => {
	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(extractSpaceStatusesSetsPageSettings);

	const applyFilters = useCallback(
		(value: ISpaceStatusesSetsFiltersState) => {
			dispatch(changeFilters(value));
			dispatch(changeOffset(0));
		}, []
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters());
	}, []);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId="all"
			onClear={onClear}
		>
			<SpaceStatusesSetsPage/>
		</EntitiesFiltersProvider>
	);
};