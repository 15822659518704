import React, {memo} from 'react';
import {CellProps} from 'react-table';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';

export const CompanyCell = memo(({row}: CellProps<IPreparedLegal>) => {
	const isTablet = useIsTablet();

	return (
		<div className="legals__cell-company">
			{!isTablet && 'Компания: '}
			<span className="legals__cell-company-title">{row.original.company?.name}</span>
		</div>
	);
});
