import {useEffect} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {CachingStateTracker} from '../trackers/CachingStateTracker';
import {swRegistration} from '../swRegistration';
import useAppSelector from '@src/core/hooks/useAppSelector';

/**
 * Отслеживание процесса кеширования данных
 */
export function useCachingStateTracker() {
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const dictionariesCacheState = useAppSelector(s => s.offlineMode.dictionariesCacheState);
	const entitiesCacheState = useAppSelector(s => s.offlineMode.entitiesCacheState);
	const dispatch = useAppDispatch();

	useEffect(() => {
		let cachingTracker: CachingStateTracker | undefined;

		if (isAuthenticated) {
			void swRegistration
				.waitRegistration()
				.then(registration => {
					if (registration) {
						cachingTracker = new CachingStateTracker(dispatch);
						cachingTracker.setInitialStatus(dictionariesCacheState, entitiesCacheState);
						cachingTracker.start();
					}
				})
				.catch(console.log);
		}

		return () => {
			if (cachingTracker) {
				cachingTracker.stop();
			}
		};
	}, [isAuthenticated]);
}
