import {IEnrichedTaskForScheduler} from '../hooks/useEnrichedTasksForSchedule';

interface IInYearProps {
	tasks: IEnrichedTaskForScheduler[];
	year: number;
}

interface IInMonthProps {
	tasks: IEnrichedTaskForScheduler[];
	month: number;
}

export const monthsName = [
	'Янв',
	'Фев',
	'Март',
	'Апр',
	'Май',
	'Июнь',
	'Июль',
	'Авг',
	'Сент',
	'Окт',
	'Ноя',
	'Дек'
];

export const getMonthIndex = (monthId: string) => monthsName.indexOf(monthId);

export const getMonthName = (monthIndex: number) => monthsName[monthIndex];

export function filterTasksInYear(props: IInYearProps) {
	const {tasks, year} = props;

	return tasks.filter(t => {
		const start = Number(t.taskIntervalStart);
		const end = Number(t.taskIntervalEnd);

		if (!start || !end) return false;

		const startYear = new Date(start).getFullYear();
		const endYear = new Date(end).getFullYear();

		return year >= startYear && year <= endYear;
	});
}

export function filterTasksInMonth(props: IInMonthProps) {
	const {tasks, month} = props;

	return tasks.filter(t => {
		const start = Number(t.taskIntervalStart);
		const end = Number(t.taskIntervalEnd);

		if (!start || !end) return false;

		const startMonth = new Date(start).getMonth();
		const endMonth = new Date(end).getMonth();

		return month >= startMonth && month <= endMonth;
	});
}

export const inUnAssigned = (t: IEnrichedTaskForScheduler) => t.taskIntervalStart === undefined
	|| t.taskIntervalEnd === undefined
	|| (t.respUsers === undefined && t.activeGroup === undefined)
	|| (!t.respUsers?.length && t.activeGroup === undefined);

export const isCurrentMonth = (monthIndex: number, currentYear?: number): boolean => {
	const currentRealYear = new Date().getFullYear();
	const currentRealMonth = new Date().getMonth();

	return currentRealMonth === monthIndex && currentRealYear === currentYear;
};

export const getMaxDaysInMonth = (year: number, month: number) => {
	const date1 = new Date(year, month, 1);
	const date2 = new Date(year, month + 1, 1);
	return Math.round((Number(date2) - Number(date1)) / 1000 / 3600 / 24);
};

export const createHeightForMonthCell = (maxTasksCountInRow: number) => (Math.ceil(maxTasksCountInRow / 4) + 1) * 28;