import React from 'react';
import ProblemCommentsVisibilityFilter from '@src/components/ProblemCommentsVisibilityFilter';
import {useCommentsPermissions} from '../../hooks/useCommentsPermissions';
import {makeCommentTitle} from '../../utils/makeCommentTitle';
import {useIsLargeDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';

interface IDesktopCommentsHeaderProps {
	objectId: string;
	problem: IPreparedProblem
}

const DesktopCommentsHeader = ({
	objectId, problem
}: IDesktopCommentsHeaderProps) => {
	const permissions = useCommentsPermissions(objectId, problem);

	const isLargeDesktop = useIsLargeDesktop();

	return (
		<div className="problem-page__d-comments-header">
			<div className="problem-page__d-comments-title">
				{makeCommentTitle(permissions.canViewComments, permissions.canViewReplies)}
			</div>

			{permissions.canViewComments && permissions.canViewReplies && (
				<ProblemCommentsVisibilityFilter
					className="problem-page__d-comments-v-filter"
					popupProps={
						isLargeDesktop
							? {placement: 'bottom-end', preferX: 'right'}
							: {placement: 'bottom-start', preferX: 'left'}
					}
				/>
			)}
		</div>
	);
};

export default DesktopCommentsHeader;
