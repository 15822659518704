import React, {useContext} from 'react';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {DispatchActionCtx} from '../table/ContractsTable';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useAsyncFn} from 'react-use';
import {IconButton} from '@tehzor/ui-components';

interface ISelectionActionsProps {
	selectedEntities: IPreparedContract[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete"/>;

export const SelectionActions = ({
	selectedEntities, onClear
}: ISelectionActionsProps) => {
	const canDelete = selectedEntities.every(item => item.canDelete);

	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить договоры',
		'Вы действительно хотите удалить выбранные договоры?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			for (const payload of selectedEntities) {
				dispatchAction({type: 'delete', payload});
			}
			onClear();
		}
	}, [selectedEntities]);

	return canDelete ? (
		<>
			<IconButton onClick={handleDelete}>
				{deleteIcon}
			</IconButton>

			{deleteDialog}
		</>
	) : null;
};