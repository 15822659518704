import {wsConnector} from '../wsConnector';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';

export type IAddResponsibilityRuleResponse = IResponsibilityRule;

/**
 * Добавляет правило ответственности
 *
 * @param groupId id группы
 * @param rule данные для добавления
 */
export const makeResponsibilityRuleAddRequest = (
	groupId: string,
	rule: ISavingResponsibilityRule
) =>
	wsConnector.sendAuthorizedRequest<IAddResponsibilityRuleResponse>('addResponsibilityRule', {
		...rule
	});
