import {addErrorToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import * as types from '../constants';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {IGetWarrantyClaimResponse, requestWarrantyClaim} from '@src/api/backend/warrantyClaim';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';

export type IGetWarrantyClaimPayload = IGetWarrantyClaimResponse;

const request = (objectId: string, warrantyClaimId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId, warrantyClaimId}
});

const success = (response: IGetWarrantyClaimResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке гарантийного обращения');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает гарантийное обращение по id
 */
export const getWarrantyClaim
	= (
		objectId: string,
		warrantyClaimId: string
	): AppThunkAction<Promise<IGetWarrantyClaimResponse>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<IGetWarrantyClaimResponse>(
			() => request(objectId, warrantyClaimId),
			success,
			failure,
			() => requestWarrantyClaim(objectId, warrantyClaimId)
		);

		return state.entities.warrantyClaim.data?.id === warrantyClaimId
			? dispatch(
					checkExpiration<IState, IGetWarrantyClaimResponse, ApiAction>(
						s => s.entities.warrantyClaim,
						apiAction
					)
			  )
			: dispatch(apiAction);
	};
