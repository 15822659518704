import {wsConnector} from '../wsConnector';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';

export type IEditInspectionResponse = IInspection;

/**
 * Изменяет осмотр
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 * @param fields данные осмотра
 */
export const makeInspectionEditRequest = (
	objectId: string,
	inspectionId: string,
	fields: ISavingInspection
) =>
	wsConnector.sendAuthorizedRequest<IEditInspectionResponse>('editInspection', {
		objectId,
		inspectionId,
		...fields
	});
