import React from 'react';
import './DisplayControls.less';

interface IDisplayControlsProps {
	left?: React.ReactNode;
	right?: React.ReactNode;
}

const DisplayControls = (props: IDisplayControlsProps) => {
	const {left, right} = props;

	return (
		<div className="stats-display-controls">
			{left ?? <div/>}
			{right}
		</div>
	);
};

export default DisplayControls;
