import React from 'react';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';

interface IProblemInfoProps {
	className?: string;
	style?: React.CSSProperties;
	data: IProblem;
	depth?: number;
	subTitle?: React.ReactNode;
}

export const ProblemInfo = ({className, style, data, depth = 0, subTitle}: IProblemInfoProps) => {
	const statusesMap = useAppSelector(s => s.dictionaries.problemStatuses.byId);

	return (
		<EntityInfo
			className={className}
			style={style}
			title={`Нарушение №${data.number ?? data.localNumber}`}
			subTitle={
				subTitle ?? (depth === 0 ? <EntityInfoDate value={data.createdAt}/> : undefined)
			}
			icon={<i className="tz-problem-28"/>}
			iconColor={statusesMap[data.status]?.color}
			iconSize={depth > 0 ? 32 : 44}
		/>
	);
};
