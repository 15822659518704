import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ISpaceIndicatorsSet from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicatorsSet';

export type IGetSpaceIndicatorsSetsResponse = INormalizedData<ISpaceIndicatorsSet>;

/**
 * Возвращает наборы индикаторов помещений
 */
export const requestSpaceIndicatorsSets = () =>
	wsConnector.sendAuthorizedRequest<IGetSpaceIndicatorsSetsResponse>('getSpaceIndicatorsSets');
