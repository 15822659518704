import classNames from 'classnames';
import React from 'react';
import './Breadcrumbs.less';
import Breadcrumb from './components/Breadcrumb';
import {IBreadcrumb} from './interfaces/IBreadcrumb';

interface IBreadcrumbsProps {
	className?: string;
	style?: React.CSSProperties;
	items: IBreadcrumb[];

	onNavigate?: (url: string) => void;
}

const Breadcrumbs = (props: IBreadcrumbsProps) => {
	const {className, style, items, onNavigate} = props;

	return (
		<div
			className={classNames('breadcrumbs', className)}
			style={style}
		>
			{items.map((item, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<React.Fragment key={i}>
					{i !== 0 && <i className="tz-simple-arrow-16 breadcrumbs__separator"/>}
					<Breadcrumb
						{...item}
						onClick={onNavigate}
					/>
				</React.Fragment>
			))}
		</div>
	);
};

export default Breadcrumbs;
