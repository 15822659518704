import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export enum ProblemCommentsVisibility {
	COMMENTS = 'comments',
	REPLIES = 'replies'
}

export interface ICommentsState {
	visibility: ProblemCommentsVisibility[];
}

export const getInitialState = (): ICommentsState => ({
	visibility: [ProblemCommentsVisibility.REPLIES, ProblemCommentsVisibility.COMMENTS]
});

export default createReducer<ICommentsState>(getInitialState(), {
	[types.CHANGE_COMMENTS_VISIBILITY]: (
		state,
		{payload}: {payload: ProblemCommentsVisibility[]}
	) => {
		state.visibility = payload;
	}
});
