import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import {IMoveCheckResponse, makeCheckMoveRequest} from '@src/api/backend/check';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {checksQueryKeys} from '@src/api/cache/checks/keys';

export interface IMoveCheckPayload extends ILinkedCheck {
	objectId: string;
	claimId: string;
}

const request = () => ({type: types.MOVE_REQUEST});

const success = (response: IMoveCheckResponse) => ({
	type: types.MOVE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при перемещении проверки');
	return {
		type: types.MOVE_FAILURE,
		payload: error
	};
};

/**
 * Перемещает проверку
 */
export const moveCheck = (
	objectId: string,
	checkId: string,
	toObjectId: string,
	toSpaceId?: string
) =>
	createApiAction<IMoveCheckResponse>(
		request,
		success,
		failure,
		async () => {
			await queryClient.invalidateQueries(problemsQueryKeys.list());
			await queryClient.invalidateQueries(inspectionsQueryKeys.list());
			await queryClient.invalidateQueries(checksQueryKeys.list());
			await queryClient.invalidateQueries(checksQueryKeys.latest());
			await queryClient.invalidateQueries(checksQueryKeys.detail(checkId));
			await queryClient.invalidateQueries(problemsQueryKeys.details());
			await queryClient.invalidateQueries(inspectionsQueryKeys.details());
			return makeCheckMoveRequest(objectId, checkId, toObjectId, toSpaceId);
		}
	);
