import React, {useMemo, useState} from 'react';
import './SpacePage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync, useMount} from 'react-use';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {
	getSpace,
	getSpaceChecks,
	getSpaceInspections,
	getSpaceInternalAcceptances,
	getSpaceOwnerAcceptances,
	getSpaceProblems,
	getSpaceWarrantyClaims
} from '@src/store/modules/entities/space/actions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import {getSpaceProblemReplies} from '@src/store/modules/entities/space/actions/problemReplies';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import {getSpaceOwners} from '@src/store/modules/dictionaries/spaceOwners/actions';
import {getSpaceTypeDecorationsAll} from '@src/store/modules/entities/spaceTypeDecorations/actions';
import {getObject} from '@src/store/modules/entities/object/actions';
import {getSpaceTypeDecorationSets} from '@src/store/modules/entities/spaceTypeDecorationSets/actions';
import {getResources, IResourceMapType} from '@src/utils/getResources';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {loaderSplit} from '@src/components/ResourcesLoader/utils/loaderSplit';
import {useGetSpaceIndicatorsSets} from '@src/core/hooks/queries/spaceIndicatorsSets';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {getSpaceTasks} from '@src/store/modules/entities/space/actions/tasks/get';

const SpacePage = () => {
	useScrollRestoration();

	const {objectId, spaceId} = useStrictParams<{objectId: string; spaceId: string}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const [isMounted, setIsMounted] = useState<boolean>(false);
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const space = useAppSelector(extractSpace);
	useGetSpaceIndicatorsSets();
	useMount(() => {
		setIsMounted(true);
	});

	const resourceMap: IResourceMapType = useMemo(
		() => ({
			all: [
				{actions: [() => getObject(objectId)]},
				{actions: [() => getSpace(objectId, spaceId)], offline: true}
			],
			checksView: [{actions: [() => getSpaceChecks(objectId, spaceId)]}],
			'check-lists-view': [{actions: [getCheckLists], offline: true}],
			'internal-acceptances-view': [
				{actions: [() => getSpaceInternalAcceptances(objectId, spaceId)]}
			],
			'warranty-claims-view': [{actions: [() => getSpaceWarrantyClaims(objectId, spaceId)]}],
			'owner-acceptances-view': [
				{actions: [() => getSpaceOwnerAcceptances(objectId, spaceId)]}
			],
			problemsView: [{actions: [() => getSpaceProblems(objectId, spaceId)]}],
			inspectionsView: [{actions: [() => getSpaceInspections(objectId, spaceId)]}],
			problemRepliesView: [{actions: [() => getSpaceProblemReplies(objectId, spaceId)]}],
			'space-owners-view': [{actions: [getSpaceOwners], offline: true}],
			'space-type-decoration-view': [{actions: [getSpaceTypeDecorationsAll]}],
			'space-type-decoration-sets': [{actions: [getSpaceTypeDecorationSets]}],
			'tasks-view': [{actions: [() => getSpaceTasks(spaceId)]}]
		}),
		[objectId, spaceId]
	);
	const roles = useAppSelector(extractUserRoles);
	useAsync(async () => {
		if (isMounted && spaceId) {
			const resources = getResources(resourceMap, roles, online, isOfflineModeAvailable);
			const promises = resources.map(resource =>
				dispatch(resource() as () => Promise<unknown>)
			);
			await loaderSplit(promises);
		}
	}, [online, isMounted, resourceMap, spaceId]);

	if (isDesktop) {
		return <Desktop objectId={objectId} spaceId={spaceId} space={space} />;
	}

	return <Mobile objectId={objectId} spaceId={spaceId} space={space} />;
};

export default SpacePage;
