import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import React from 'react';
import {EntityAdding} from './EntityAdding';

interface IDesktopTopActionsProps {
	objectId: string;
	workAcceptance: IWorkAcceptance;
}

export const MainActionsDesktop = ({objectId, workAcceptance}: IDesktopTopActionsProps) => (
	<>
		<EntityAdding
			objectId={objectId}
			workAcceptance={workAcceptance}
		/>
	</>
	);
