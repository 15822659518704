import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {extractCategoriesSetsPageSettings} from '@src/store/modules/settings/pages/manage/categoriesSets/selectors';
import React, {useCallback} from 'react';
import {CategoriesSetsPage} from './CategoriesSetsPage';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ICategoriesSetsFiltersState} from '@src/store/modules/settings/pages/manage/categoriesSets/reducers';
import {
	changeFilters,
	changeOffset,
	clearFilters
} from '@src/store/modules/settings/pages/manage/categoriesSets/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

const AllCategoriesSetsPageWrap = () => {
	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(s => extractCategoriesSetsPageSettings(s, 'all'));

	const applyFilters = useCallback(
		(value: ICategoriesSetsFiltersState) => {
			dispatch(changeFilters('all', value));
			dispatch(changeOffset('all', 0));
		},
		['all']
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters('all'));
	}, ['all']);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId="all"
			onClear={onClear}
		>
			<CategoriesSetsPage key="all-categories-sets"/>
		</EntitiesFiltersProvider>
	);
};

export default AllCategoriesSetsPageWrap;
