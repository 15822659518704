import React from 'react';
import {TempFileDestination} from '../enums/TempFileDestination';
import ITempFile from '../interfaces/ITempFile';
import IUploadingFile from '../interfaces/IUploadingFile';
import {QueueObject} from 'async';
import queue from 'async-es/queue';
import {UploadingFileStatus} from '../enums/UploadingFileStatus';

export type AddTempFileFn = (
	file: File,
	destination: TempFileDestination,
	onInitialized?: (abortRequest: () => void) => void,
	onProgress?: (progress: number) => void
) => Promise<ITempFile>;

export type DeleteTempFileFn = (id: string) => Promise<void>;

interface IFilesUploaderProps {
	online?: boolean;
	uploadingFiles: IUploadingFile[];

	onUploadingFilesChange: (value: IUploadingFile[]) => void;
	onAddTempFile: AddTempFileFn;
	onDeleteTempFile: DeleteTempFileFn;
}

abstract class FilesUploader<P> extends React.PureComponent<IFilesUploaderProps & P> {
	/**
	 * Объект QueueObject для управления очередью
	 */
	private queueHandler: QueueObject<string>;

	/**
	 * Ключи текущих обрабатываемых файлов
	 */
	private processingKeys: string[] = [];

	protected constructor(props: IFilesUploaderProps & P) {
		super(props);

		this.queueHandler = queue(this.uploadFile, 1);
	}

	componentDidMount() {
		this.props.uploadingFiles.forEach(this.checkAndAddTask);
	}

	componentDidUpdate(prevProps: IFilesUploaderProps) {
		if (this.props.online !== prevProps.online) {
			if (this.props.online) {
				this.handleConnected();
			} else {
				this.handleDisconnected();
			}
		}

		if (this.props.uploadingFiles !== prevProps.uploadingFiles) {
			// Добавление задач для файлов со статусом waiting
			this.props.uploadingFiles.forEach(this.checkAndAddTask);
		}
	}

	componentWillUnmount() {
		this.queueHandler.kill();
		this.abortFilesUploading();
	}

	/**
	 * Заново помещает файл в очередь на выгрузку
	 *
	 * @param key ключ файла
	 */
	reloadFile = (key: string) => {
		void this.queueHandler.push(key);
	};

	/**
	 * Удаляет файл из очереди, неважно в каком состоянии он находится
	 *
	 * @param key ключ файла
	 */
	deleteFile = (key: string) => {
		const file = this.findFile(key);
		if (file === undefined) {
			return;
		}
		const {uploadingFiles, onUploadingFilesChange, onDeleteTempFile} = this.props;
		if (file.status === UploadingFileStatus.WAITING || file.status === UploadingFileStatus.STARTED) {
			this.queueHandler.remove(({data}) => data === key);
			if (file.abort) {
				file.abort();
			}
		}
		if (file.status === UploadingFileStatus.FINISHED && file.tempFile !== undefined) {
			onDeleteTempFile(file.tempFile.id).catch(() => undefined);
		}
		const index = this.processingKeys.indexOf(key);
		if (index !== -1) {
			this.processingKeys.splice(index, 1);
		}
		onUploadingFilesChange(uploadingFiles.filter(item => item.key !== key));
	};

	/**
	 * Выгружает файл
	 *
	 * @param key
	 * @param callback функция при завершении
	 */
	private uploadFile = (key: string, callback: (error?: unknown) => void) => {
		const file = this.findFile(key);
		if (file === undefined || file.sizeError) {
			return callback();
		}

		this.updateFile(key, {status: UploadingFileStatus.STARTED, progress: 0});
		this.props
			.onAddTempFile(
				file.original,
				file.destination,
				(abort: () => void) => this.updateFile(key, {abort}),
				(progress: number) => this.updateFile(key, {progress})
			)
			.then(tempFile => {
				const index = this.processingKeys.indexOf(key);
				if (index !== -1) {
					this.processingKeys.splice(index, 1);
				}
				this.updateFile(key, {
					status: UploadingFileStatus.FINISHED,
					tempFile,
					progress: 100,
					abort: undefined
				});
				callback();
			})
			.catch((error: unknown) => {
				if (!(error instanceof Error) || error.name !== 'CancellationError') {
					const index = this.processingKeys.indexOf(key);
					if (index !== -1) {
						this.processingKeys.splice(index, 1);
					}
					this.updateFile(key, {
						status: UploadingFileStatus.ERROR,
						progress: 0,
						abort: undefined
					});
				}
				callback(error);
			});
	};

	/**
	 * Находит файл по ключу
	 *
	 * @param key ключ
	 */
	private findFile = (key: string) => this.props.uploadingFiles.find(item => item.key === key);

	/**
	 * Обновляет данные файла по ключу
	 *
	 * @param key ключ
	 * @param data данные
	 */
	private updateFile = (key: string, data: Partial<IUploadingFile>) => {
		const {uploadingFiles, onUploadingFilesChange} = this.props;
		onUploadingFilesChange(uploadingFiles.map(item => (item.key === key ? {...item, ...data} : item)));
	};

	/**
	 * Добавляет файл в очередь на выгрузку если он имеет статус waiting
	 *
	 * @param file файл
	 */
	private checkAndAddTask = (file: IUploadingFile) => {
		if (file.status === UploadingFileStatus.WAITING && !this.processingKeys.includes(file.key)) {
			this.processingKeys.push(file.key);
			void this.queueHandler.push(file.key);
		}
	};

	/**
	 * Прерывает выгрузку всех файлов
	 */
	private abortFilesUploading = () => {
		for (const file of this.props.uploadingFiles) {
			if (file.abort) {
				file.abort();
			}
		}
	};

	/**
	 * Перезапускает выгрзуку при возобновлении соединения
	 */
	private handleConnected = () => {
		this.props.onUploadingFilesChange(
			this.props.uploadingFiles.map(file => ({
				...file,
				status: file.tempFile ? UploadingFileStatus.FINISHED : UploadingFileStatus.WAITING
			}))
		);
		this.queueHandler.resume();
	};

	/**
	 * Приостанавливает выгрузку при потере соединения
	 */
	private handleDisconnected = () => {
		this.queueHandler.pause();
		this.queueHandler.remove(() => true);
		this.abortFilesUploading();
		this.props.onUploadingFilesChange(
			this.props.uploadingFiles.map(file => {
				this.processingKeys.splice(this.processingKeys.indexOf(file.key), 1);
				return {
					...file,
					status: UploadingFileStatus.LOCAL
				};
			})
		);
	};
}

export default FilesUploader;
