import React from 'react';
import './InternalAcceptanceInfoMobileCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import InternalAcceptanceInfo from '@src/components/tableCells/InternalAcceptanceInfo';

const InternalAcceptanceInfoMobileCell = ({row}: CellProps<{data: IInternalAcceptance}>) => (
	<div className="m-internal-acceptance-info-cell">
		<div className="m-internal-acceptance-info-cell__header">
			<InternalAcceptanceInfo data={row.original.data}/>

			<ClickPreventWrap style={{marginLeft: '8px'}}>
				<TableRowSelect
					{...row.getToggleRowSelectedProps()}
					style={{marginTop: '12px'}}
				/>
			</ClickPreventWrap>
		</div>
	</div>
);

export default InternalAcceptanceInfoMobileCell;
