import React, {useCallback, useState} from 'react';
import './EditableStructureDialog.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {addStructure, editStructure} from '@src/store/modules/entities/structure/actions';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableStructure} from '@src/components/EditableStructure/hooks/useEditableStructure';

interface IEditableStructureDialogProps {
	objectId: string;
	structureId?: string;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно редактирования структуры
 */
 export const EditableStructureDialog = ({objectId, structureId, isOpen, onClose}: IEditableStructureDialogProps) => {
	const structure = useAppSelector(s => s.entities.structure.data);
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableStructure(
		objectId,
		structureId ? structure : undefined,
		saving
	);

	const save = useCallback(async () => {
		setSaving(true);
		const savingData = await getSavingData();
		if (savingData && structureId) {
			try {
				await dispatch(editStructure(objectId, structureId, savingData));
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		if (savingData && !structureId) {
			try {
				await dispatch(addStructure(objectId, savingData));
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		setSaving(false);
	}, [objectId, structureId, getSavingData, dispatch, onClose]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation]);

	return (
		<Dialog
			className="editable-structure-dialog"
			isOpen={isOpen}
			title={structureId ? 'Редактирование структуры' : 'Добавление структуры'}
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
