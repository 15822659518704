import React, {memo} from 'react';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import Status from './Status';
import {Category} from '@src/components/viewFields/Category';
import {FixDate} from '@src/components/viewFields/FixDate/FixDate';
import {Responsible} from '@src/components/viewFields/Responsible';
import Location from '@src/components/viewFields/Location';
import Reason from './Reason';
import Description from '@src/components/viewFields/Description';
import Prescription from '@src/components/viewFields/Prescription';
import ProblemTags from './ProblemTags';
import {ActualFixDate} from '@src/components/viewFields/ActualFixDate/ActualFixDate';
import {IPermissions} from '../../hooks/usePermissions';
import {ProblemViews} from './ProblemViews';
import {Inspectors} from '@src/components/viewFields/Inspectors';
import {IProblemSettings} from '@src/pages/ProblemsPage/utils/useProblemSettings';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';

interface IDesktopInfoProps {
	objectId: string;
	problem: IPreparedProblem;
	hasLocationComments: boolean;
	permissions: IPermissions;
	settings?: IProblemSettings;
}

const DesktopInfo = ({
	objectId, problem, hasLocationComments, permissions, settings
}: IDesktopInfoProps) => (
	<EntityGrid
		className={{wrap: 'problem-page__d-entities-grid'}}
		withBorders
	>
		{problem && (
			<Status
				objectId={objectId}
				problem={problem}
			/>
		)}
		{problem.description && settings?.description && (
			<Description value={problem.description}/>
		)}
		{problem.plannedFixDate && settings?.plannedFixDate && (
			<FixDate
				plannedFixDate={problem.plannedFixDate}
				status={problem.status}
				critical={problem.critical}
				actualFixDate={problem.actualFixDate}
			/>
		)}
		{problem.actualFixDate && <ActualFixDate value={problem.actualFixDate}/>}
		{(problem.respUsers?.length || problem.activeGroup) && settings?.respUsers ? (
			<Responsible
				entity={problem}
				type="problem"
			/>
		) : null}

		{problem.inspectors?.length && settings?.inspectors ? (
			<Inspectors
				inspectors={problem.inspectors}
				objectId={problem.objectId}
				stage={problem.stage}
			/>
		) : null}

		{problem.problemTags && problem.problemTags?.length > 0 && settings?.problemTags ? (
			<ProblemTags
				tags={problem.problemTags}
				fullRow
			/>
		) : null}

		{(!!problem.plan || !!problem.floor || !!problem.location) && settings?.location && (
			<Location
				objectId={objectId}
				planId={problem.plan?.id}
				floor={problem.floor}
				location={problem.location}
				status={problem.status}
				hasComments={hasLocationComments}
			/>
		)}
		{problem.categoryId && settings?.categoryId && (
			<Category categoryId={problem.categoryId}/>
		)}
		{problem.reason && settings?.reason && (
			<Reason value={problem.reason}/>
		)}
		<EditorInfo
			icon={<i className="tz-document-20"/>}
			label="Создано"
			date={problem.createdAt}
			user={problem.createdBy}
			localDate={problem.localCreatedAt}
			localLabel="сохранено локально"
		/>
		{problem.createdAt !== problem.modifiedAt && (
			<EditorInfo
				icon={<i className="tz-edit-20"/>}
				label="Изменено"
				date={problem.modifiedAt}
				user={problem.modifiedBy}
			/>
		)}
		{permissions.canSeeViews && (
			<ProblemViews problem={problem}/>
		)}
		{problem.prescription && settings?.prescription && (
			<Prescription value={problem.prescription}/>
		)}
	</EntityGrid>
);

export default memo(DesktopInfo);
