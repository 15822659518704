import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';

export type IGetDelegationHistoriesByProblemResponse = INormalizedData<IDelegationHistory>;

/**
 * Получает записи из истории делегирования нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */
export const requestDelegationHistoriesByProblem = (objectId: string, problemId: string) =>
	wsConnector.sendAuthorizedRequest<IGetDelegationHistoriesByProblemResponse>(
		'getDelegationHistoriesByProblem',
		{objectId, problemId}
	);
