import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {MobileFilter, SelectSearch} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {extractProblemTagsAsArray} from '@src/store/modules/dictionaries/problemTags/selectors';
import {extractProblemTagsSetsAsArrayForObject} from '@src/store/modules/dictionaries/problemTagsSets/selectors';
import debounce from 'lodash/debounce';

interface IProblemTagsSetFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

/**
 * Фильтр по меткам для мобильных устройств
 */
export const ProblemTagsSetFilterMobile = memo(
	({objectId, value, entity, onChange}: IProblemTagsSetFilterMobileProps) => {
		const [selected, setSelected] = useState<string[] | undefined>([]);
		const [search, setSearchWrapper] = useState('');
		const setSearch = useMemo(() => debounce(setSearchWrapper, 300), []);

		const tagSets = useAppSelector(s => extractProblemTagsSetsAsArrayForObject(s, objectId || 'all'));
		const allTags = useAppSelector(extractProblemTagsAsArray);

		/**
		 * Особый режим отображения:
		 * — Если доступно несколько наборов меток, показываем только названия наборов.
		 * При выборе набора выбираются сразу все метки из этого набора.
		 * — Если активирован поиск, переключаемся в обычный режим и смешиваем все найденные метки из разных наборов.
		 */
		const isMultiTagSetMode = tagSets.length > 1 && !search;

		const elements = useMemo(() => {
			if (!tagSets.length) {
				return [];
			}

			if (isMultiTagSetMode) {
				return tagSets.map(set => ({id: set.id, name: set.name}));
			}

			const tagSetsList = tagSets.map(item => item.id);
			return allTags
				.filter(item => tagSetsList.includes(item.problemTagsSetId))
				.map(item => ({id: item.id, name: item.name}));
		}, [tagSets, allTags, isMultiTagSetMode]);

		const filteredElements = useMemo(
			() => flatFilter(elements, 'name', search),
			[elements, search]
		);

		useEffect(() => {
			if (isMultiTagSetMode) {
				const selectedSets = tagSets
					.filter(set =>
						allTags.some(
							tag => tag.problemTagsSetId === set.id && value?.includes(tag.id)
						))
					.map(set => set.id);
				setSelected(selectedSets);
				return;
			}

			setSelected(value);
		}, [value, tagSets, allTags, isMultiTagSetMode]);

		const handleChange = useCallback(
			(v: string[] | undefined) => {
				const problemTags: string[] | undefined = v ? [] : undefined;

				if (v && problemTags) {
					if (isMultiTagSetMode) {
						v.forEach(id =>
							problemTags.push(
								...allTags
									.filter(tag => tag.problemTagsSetId === id)
									.map(item => item.id)
							));
					} else {
						problemTags.push(...v);
					}
				}

				setSelected(problemTags);
				onChange({problemTags});
			},
			[onChange, isMultiTagSetMode, allTags]
		);

		const handleExpand = useFilterPageTransition(objectId, entity, 'problemTags');

		return (
			<MobileFilter
				label="Метки"
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
					/>
				)}
			/>
		);
	}
);

ProblemTagsSetFilterMobile.displayName = 'ProblemTagsSetFilterMobile';