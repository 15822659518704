import React, {useCallback, useEffect} from 'react';
import {EntitiesTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {IEnrichedStructure} from '@tehzor/tools/interfaces/structures/IEnrichedStructure';
import {extractStructuresPageSettings} from '@src/store/modules/settings/pages/structures/selectors';
import {SelectionRow} from './SelectionRow';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getStructuresList, changeSelected} from '@src/store/modules/entities/structures/actions';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {
	extractStructuresListAsArray,
	extractStructuresListData,
	extractStructuresListLoadingState
} from '@src/store/modules/entities/structures/selectors/lists';
import {changeSort} from '@src/store/modules/settings/pages/structures/actions';
import {getStructuresProblemsData} from '@src/store/modules/pages/structures/actions/getStructuresProblemsData';
import {useEnrichedList} from '../../hooks/useEnrichedList';
import {getWorkAcceptances} from '@src/store/modules/entities/workAcceptances/actions';
import {getCheckRecords} from '@src/store/modules/pages/checkLists/actions';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {CheckListTypeId} from '@tehzor/tools/interfaces/checkLists/ICheckListType';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import {extractCheckRecordsAsArray} from '@src/store/modules/pages/checkLists/selectors/records';
import {filterStructures} from '@src/store/modules/entities/structures/utils/convertStructures';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<IEnrichedStructure>>;
	hideHead?: boolean;
	noRowBorder?: boolean;
	selectable?: boolean;
	setCount?: (count: number) => void;
}

export const Table = (props: IDesktopTableProps) => {
	const {pushPath} = useChangePath();
	const {objectId, columns, hideHead, noRowBorder, selectable, setCount} = props;
	const objectsLoaded = useAppSelector(s => s.dictionaries.objects.loaded);
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const isListLoading = useAppSelector(s => extractStructuresListLoadingState(s, objectId));
	const {selected} = useAppSelector(s => extractStructuresListData(s, objectId));
	const {filters, sort} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const structures = useAppSelector(s => extractStructuresListAsArray(s, objectId));
	const structureIds = structures.map(structure => structure.id);
	const structureTypes = Array.from(new Set(structures.map(structure => structure.type)));
	const checkLists: Record<string, ICheckList[]> = {};
	const availableCheckLists = useAvailableCheckLists(
		objectId,
		ObjectStageIds.BUILDING,
		CheckListTypeId.WORK_ACCEPTANCES,
		structureTypes
	);

	structureTypes.forEach(type => {
		const availableCheckList = availableCheckLists.filter(
			checkList => checkList.structureType === type
		);
		checkLists[type] = availableCheckList;
	});

	const checkListsRecords = useAppSelector(s => extractCheckRecordsAsArray(s));
	const enrichedStructures = useEnrichedList(
		objectId,
		structures,
		structureIds,
		checkLists,
		checkListsRecords
	);

	const filtredStructures = filterStructures(enrichedStructures, filters);

	useEffect(() => {
		if (setCount) {
			setCount(filtredStructures.length);
		}
	}, [filtredStructures, setCount]);

	const loadStructures = useCallback(async () => {
		const structuresList = await dispatch(getStructuresList(objectId));

		if (structuresList && online) {
			void dispatch(
				getStructuresProblemsData({objectId, structuresIds: structuresList.allIds})
			);
			const workAcceptances = await dispatch(
				getWorkAcceptances(objectId, {structuresIds: structuresList.allIds})
			);
			const resolveCheckLists = await dispatch(getCheckLists());

			void dispatch(
				getCheckRecords(
					resolveCheckLists.allIds,
					[objectId],
					undefined,
					workAcceptances.allIds
				)
			);
		}
	}, [dispatch, objectId, online]);

	useEffect(() => {
		if (objectsLoaded) {
			void loadStructures();
		}
	}, [loadStructures, objectsLoaded]);

	useUpdateEffect(() => {
		void loadStructures();
	}, [isListLoading]);

	const handleRowClick = useCallback(
		(structure: IEnrichedStructure) =>
			pushPath(formStructureLink(structure.objectId, structure.id)),
		[]
	);

	const handleSelection = useCallback(
		(value: string[]) => dispatch(changeSelected(objectId, value)),
		[objectId, dispatch]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => dispatch(changeSort(objectId, value)),
		[objectId, dispatch]
	);

	return (
		<EntitiesTable<IEnrichedStructure>
			columns={columns}
			data={filtredStructures}
			sort={sort}
			selectedRows={selected}
			selectable={selectable}
			noRowBorder={noRowBorder}
			headVisible={!hideHead}
			onRowClick={handleRowClick}
			onSelectedRowsChange={handleSelection}
			onSortChange={handleSortChange}
			renderSelectionRow={SelectionRow}
		/>
	);
};
