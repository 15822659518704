import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptanceProblems} from '@src/store/modules/entities/internalAcceptance/selectors';
import {useInternalAcceptancePermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';
import DesktopInternalAcceptanceExportBtn from './InternalAcceptanceExport.desktop';
import EntityAdding from './EntityAdding';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

interface IDesktopMainActionsProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
}

const DesktopMainActions = ({objectId, internalAcceptance}: IDesktopMainActionsProps) => {
	const problems = useAppSelector(extractInternalAcceptanceProblems);
	const permissions = useInternalAcceptancePermissions(objectId, internalAcceptance, problems);

	return (
		<>
			{permissions.canExport && (
				<DesktopInternalAcceptanceExportBtn
					objectId={objectId}
					internalAcceptanceId={internalAcceptance.id}
				/>
			)}
			<EntityAdding
				objectId={objectId}
				internalAcceptance={internalAcceptance}
			/>
		</>
	);
};

export default DesktopMainActions;
