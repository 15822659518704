import declination from '@tehzor/tools/utils/declination';
import React, {useMemo} from 'react';

interface ISpaceStatusesSetsPageHeaderProps {
	total: number;
}

const words = ['набор', 'набора', 'наборов'];

export const SpaceStatusesSetsPageHeader = ({total}: ISpaceStatusesSetsPageHeaderProps) => {
	const amount = useMemo(() =>
		`Всего: ${total} ${declination(total, words)}`,
	[total]);

	return (
		<div className="space-statuses-sets__page-header">
			<p className="space-statuses-sets__page-header-amount">{amount}</p>
		</div>
	);
};