import React, {useCallback} from 'react';
import DesktopMenu from './Menu.desktop';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {InlineButton} from '@tehzor/ui-components';

interface IDesktopActionsProps {
	warrantyClaim: ILinkedWarrantyClaim;
}

const DesktopActions = ({warrantyClaim}: IDesktopActionsProps) => {
	const {pushPath} = useChangePath();
	const spaceTitle = useSpaceTitle(warrantyClaim?.space);

	const handleClick = useCallback(() => {
		if (warrantyClaim) {
			if (warrantyClaim.objectId && warrantyClaim.links?.spaceId) {
				pushPath(
					`/objects/${warrantyClaim.objectId}/spaces/${warrantyClaim.links.spaceId}`
				);
			}
		}
	}, [warrantyClaim]);

	return (
		<div className="warranty-claim-page__d-controls">
			<div className="warranty-claim-page__d-controls-left">
				{warrantyClaim.space ? (
					<InlineButton
						label={spaceTitle}
						type="accent"
						onClick={handleClick}
						leftIcon={<i className="tz-space-24"/>}
					/>
				) : null}
			</div>
			<div className="warranty-claim-page__d-controls-right">
				<DesktopMenu warrantyClaim={warrantyClaim}/>
			</div>
		</div>
	);
};
export default DesktopActions;
