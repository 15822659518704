import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import React from 'react';
import {CellProps} from 'react-table';

export const DeadLineCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const task = row.original;
	return (
		<>
			{task?.taskIntervalStart && task?.taskIntervalEnd && (
				<div className="tasks-page__deadline">
					<div className="tasks-page__deadline-grid">
						<div className="tasks-page__deadline-grid-preposition">С</div>
						<div>{format(task.taskIntervalStart, dateTimeCommaSeparatedFormat)}</div>
						<div className="tasks-page__deadline-grid-preposition">По</div>
						<div>{format(task.taskIntervalEnd, dateTimeCommaSeparatedFormat)}</div>
					</div>

					{task.status !== undefined
						&& task.status?.id !== TaskStatusId.ACCEPTED
						&& task.status?.id !== TaskStatusId.REJECTED && (
							<FixDateTag
								className="tasks-page__deadline-tag"
								plannedFixDate={task.taskIntervalEnd}
								size="small"
							/>
						)}
				</div>
			)}
		</>
	);
};
