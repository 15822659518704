import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import * as types from './constants';
import {createReducer} from '@reduxjs/toolkit';
import {
	alterManageableEntitySuccess,
	getManageableEntityInitialState
} from '@tehzor/tools/utils/reducersHandlers';
import config from '@src/core/config';

const {entitiesCacheTime} = config;

export interface ITaskState {
	data?: ITask;
	expires?: number;
}

export default createReducer<ITaskState>(getManageableEntityInitialState<ITask>(), {
	[types.GET_SUCCESS]: alterManageableEntitySuccess<ITask>(entitiesCacheTime),
	[types.ADD_SUCCESS]: alterManageableEntitySuccess<ITask>(entitiesCacheTime),
	[types.EDIT_SUCCESS]: alterManageableEntitySuccess<ITask>(entitiesCacheTime),
	[types.DELETE_SUCCESS]: () => getManageableEntityInitialState<ITask>(),
	[types.EDIT_RESPONSIBLE_SUCCESS]: alterManageableEntitySuccess<ITask>(entitiesCacheTime),
	[types.EDIT_TASK_STATUS_SUCCESS]: alterManageableEntitySuccess<ITask>(entitiesCacheTime),
	[CLEAR_STORE]: () => getManageableEntityInitialState<ITask>()
});
