import React, {Dispatch, useCallback, useMemo} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {ProblemTagsSelect, EditableFieldLabel} from '@tehzor/ui-components';
import {extractProblemTagsSetsAsFilteredArray} from '@src/store/modules/dictionaries/problemTagsSets/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemTagsAsArray} from '@src/store/modules/dictionaries/problemTags/selectors';
import {makeTreeData} from '@src/store/modules/dictionaries/problemTagsSets/utils/makeTreeData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IProblemTagsProps<S, E> {
	objectId: string;
	stage: ObjectStageIds;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	tags?: string[];

	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const ProblemTags = <S extends {problemTags: string[]}, E>({
	objectId,
	stage,
	className,
	style,
	label = 'Метки',
	tags,
	editingDispatch,
	required,
	disabled,
	hasError
}: IProblemTagsProps<S, E>) => {
	const sets = useAppSelector(s => extractProblemTagsSetsAsFilteredArray(s, objectId, stage));
	const tagsArray = useAppSelector(extractProblemTagsAsArray);
	const treeData = useMemo(() => makeTreeData(sets, tagsArray, true), [sets, tagsArray]);

	const handleChange = useCallback(
		(tags: string[]) => {
			editingDispatch({type: 'update', field: 'problemTags', value: tags});
			if (required) {
				editingDispatch({type: 'update-error', field: 'problemTags'});
			}
		},
		[required, editingDispatch]
	);
	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<ProblemTagsSelect
				dialogTitle="Метки"
				treeData={treeData}
				value={tags}
				error={required && hasError ? 'Выберите метки' : undefined}
				disabled={disabled}
				onChange={handleChange}
				multiple
			/>
		</div>
	);
};
