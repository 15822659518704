import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useCheckBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCheckBreadcrumbs';
import DesktopMainActions from './actions/MainActions.desktop';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IPageBreadcrumbsProps {
	objectId: string;
	check: ICheck;
}

const PageBreadcrumbs = ({objectId, check}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useCheckBreadcrumbs(objectId);
	const isDesktop = useIsDesktop();

	return (
		<AppBreadcrumbs
			className="check-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && (
				<DesktopMainActions
					objectId={objectId}
					check={check}
				/>
)}
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
