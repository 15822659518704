import {wsConnector} from '../wsConnector';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ISavingWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/ISavingWorkAcceptance';

export type IEditWorkAcceptanceResponse = IWorkAcceptance;

/**
 * Изменяет приёмку работ
 */
export const makeWorkAcceptanceEditRequest = (
	objectId: string,
	workAcceptanceId: string,
	data: ISavingWorkAcceptance
) =>
	wsConnector.sendAuthorizedRequest<IEditWorkAcceptanceResponse>('editWorkAcceptance', {
		objectId,
		workAcceptanceId,
		...data
	});
