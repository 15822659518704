import React, {useCallback, useMemo} from 'react';
import {useEntitiesViewer} from './useEntitiesViewer';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import IAttachmentsImageData from '@tehzor/tools/interfaces/IAttachmentsImageData';
import {IEntity} from '@tehzor/ui-components/src/components/photos/EntitiesDesktopPhotoViewer/EntitiesDesktopPhotoViewer';

/**
 * Хук для создания просмотрщика изображений нарушения и ответа
 *
 * @param problemImages изображения нарушения
 * @param replyImages изображения ответа
 * @param problemImagesData данные изображений нарушения
 * @param replyImagesData данные изображений ответа
 * @param replyNumber номер ответа
 * @param problemId id нарушения
 * @param replyId id ответа
 */
export function useProblemAndReplyViewer(
	problemImages: string[],
	replyImages: string[],
	problemImagesData: IAttachmentsImageData[],
	replyImagesData: IAttachmentsImageData[],
	replyNumber?: number,
	problemId?: string,
	replyId?: string
): [React.ReactNode, (i: number) => void, (i: number) => void] {
	// Формирование общего списка изображений
	const data = useMemo(() => {
		const result: IEntity[] = [];
		if (problemImages.length) {
			result.push({
				id: problemId || 'problem',
				title: 'Фото нарушения',
				files: problemImages,
				type: AttachmentFileDestination.PROBLEM,
				attachments: problemImagesData
			});
		}
		if (replyImages.length) {
			result.push({
				id: replyId || 'reply',
				title: replyNumber ? `Фото ответа №${replyNumber}` : 'Фото ответа',
				files: replyImages,
				type: AttachmentFileDestination.COMMENT_OR_REPLY,
				attachments: replyImagesData
			});
		}
		return result;
	}, [problemImages, replyImages, replyId, problemId]);

	const [viewer, openImage] = useEntitiesViewer(data, false);

	const openProblemImage = useCallback(
		(index: number) => {
			openImage(problemId || 'problem', index);
		},
		[openImage, problemId]
	);
	const openReplyImage = useCallback(
		(index: number) => {
			openImage(replyId || 'reply', index);
		},
		[openImage, replyId]
	);

	return [viewer, openProblemImage, openReplyImage];
}
