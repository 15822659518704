import React from 'react';
import './AttachmentPreview.less';
import classNames from 'classnames';
import FileIcon from '../FileIcon';

interface IAttachmentPreviewProps {
	className?: string;
	style?: React.CSSProperties;
	id: string;
	name?: string;
	url?: string;
	type?: string;
	error?: boolean;

	onClick?(id: string): void;
}

/**
 * Проверяет изображение ли файл с типом type
 *
 * @param {string} type mime-тип
 */
const isImage = (type?: string): boolean => !!type && type.includes('image');

const AttachmentPreview = (props: IAttachmentPreviewProps) => {
	const {className, style, id, name, url, type, error, onClick} = props;
	const classes = classNames(
		'attachment-preview',
		{'attachment-preview_error': error},
		{'attachment-preview_clickable': !!onClick},
		className
	);
	const styles = {
		...style
	} as React.CSSProperties;

	const handleClick = () => {
		if (onClick) {
			onClick(id);
		}
	};

	return (
		<div
			className={classes}
			style={styles}
			title={name}
			tabIndex={0}
			onClick={handleClick}
		>
			{isImage(type) ? (
				<img
					className="attachment-preview__img"
					src={url}
					alt=""
				/>
			) : (
				<div className="attachment-preview__file">
					<FileIcon
						className="attachment-preview__file-icon"
						type={type}
					/>
					{name && (
						<div className="attachment-preview__file-name-wrap">
							<div className="attachment-preview__file-name">
								{name}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

AttachmentPreview.displayName = 'AttachmentPreview';

AttachmentPreview.defaultProps = {
	error: false
};

export default AttachmentPreview;
