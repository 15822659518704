import React from 'react';
import {DelegationHistoryDialog} from '@src/components/DelegationHistoryDialog';
import useToggle from 'react-use/lib/useToggle';
import {InlineButton} from '@tehzor/ui-components';

interface IDelegationHistoryButtonProps {
	objectId: string;
	entityId: string;
	type: 'problem' | 'work-acceptance';
}

export const DelegationHistoryButton = (props: IDelegationHistoryButtonProps) => {
	const {objectId, entityId, type} = props;

	const [isOpen, toggleOpen] = useToggle(false);

	return (
		<>
			<InlineButton
				className="responsible-view-field__btn"
				type="accent"
				label="История делегирования"
				onClick={toggleOpen}
			/>

			<DelegationHistoryDialog
				objectId={objectId}
				entityId={entityId}
				isOpen={isOpen}
				onClose={toggleOpen}
				type={type}
			/>
		</>
	);
};
