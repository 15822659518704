import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import React from 'react';
import {CellProps} from 'react-table';

const ResponsibleCell = ({
	row: {original: {data: {respUsers, activeGroup}}}
}: CellProps<IProblemEntity>) => (
	<div className="problems-page__d-table-responsible">
		{activeGroup && (
			<div className="problems-page__d-table-responsible-group">
				{activeGroup}
			</div>
		)}
		{respUsers?.[0] && (
			<div className="problems-page__d-table-responsible-user">
				{respUsers[0]}
			</div>
		)}
		{!!respUsers?.length && respUsers?.length > 1 && (
			<div className="problems-page__m-table-responsible-rest">
				+
				{respUsers.length - 1}
			</div>
		)}
	</div>
);

export default ResponsibleCell;
