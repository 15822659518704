import React from 'react';
import './ProblemsStatsCell.less';
import {CellProps} from 'react-table';
import {Tag, TagsContainer} from '@tehzor/ui-components';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';

const ProblemsStatsCell = ({row}: CellProps<IEnrichedSpace>) => {
	const space = row.original;

	return space.problems?.length ? (
		<TagsContainer>
			{space.problems.map(item => (
				<Tag
					key={item.key}
					className={{
						root: 'problems-stats-cell',
						label: 'problems-stats-cell__label',
						icon: 'problems-stats-cell__icon'
					}}
					color={`${item.color}33`} // TODO Переделать установку цвета через chromajs (работает только с hex)
					icon={(
						<i
							className="tz-problem-16"
							style={{color: item.color}}
						/>
					)}
					label={item.value.toString()}
					size="large"
				/>
			))}
		</TagsContainer>
	) : null;
};

export default ProblemsStatsCell;
