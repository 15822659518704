import {wsConnector} from '../wsConnector';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

export type IEditSpaceIndicatorsResponse = ISpace;

/**
 * Изменяет индикаторы помещения
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param indicators индикаторы
 */
export const makeSpaceIndicatorsEditRequest = (
	objectId: string,
	spaceId: string,
	indicators: string[]
) =>
	wsConnector.sendAuthorizedRequest<IEditSpaceIndicatorsResponse>('editSpaceIndicators', {
		objectId,
		spaceId,
		indicators
	});
