import React from 'react';
import {IBlock} from '../PageLeavingBlock';

export const PageLeavingBlockCtx = React.createContext<{
	add(block: IBlock): void;
	delete(key: string): void;
}>({
	add: () => null,
	delete: () => null
});
