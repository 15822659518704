import React from 'react';
import './DatePickerLayer.less';
import Dialog, {IDialogProps} from '../../dialogs/Dialog';
import MobileDetect from 'mobile-detect';
import Popup, {IPopupProps} from '../../containers/Popup';
import {IPopupMenuTriggerFnProps} from '../../menu/PopupMenu';
import classNames from 'classnames';
import Button from '../../buttons/Button/Button';
import {useIsBeforeLargeTablet, useIsBeforeLargeTabletHeight} from '../../../utils/mediaQueries';

const md = new MobileDetect(window.navigator.userAgent);

export interface IDatePickerDialogProps {
	children?: React.ReactNode;
	trigger: (props: IPopupMenuTriggerFnProps) => React.ReactNode;
	result?: React.ReactNode;
	isOpen: boolean;
	isButtonsVisible?: boolean;
	dialogProps?: Omit<IDialogProps,
		| 'children'
		| 'isOpen'
		| 'footer'
		| 'fullScreenOnMobile'
		| 'onRequestClose'
		| 'onBeforeOpen'
		| 'onAfterOpen'
		| 'onBeforeClose'
		| 'onAfterClose'>;
	popupProps?: Omit<IPopupProps,
		'children'
		| 'isOpen'
		| 'trigger'
		| 'onOutsideClick'
		| 'onDisappear'
		| 'onAnimationComplete'>;

	onApply?: () => void;
	onOpen?: () => void;
	onClose?: () => void;
	onAfterClose?: () => void;
}

const DatePickerLayer = (props: IDatePickerDialogProps) => {
	const {
		children,
		trigger,
		result,
		isOpen,
		isButtonsVisible,
		dialogProps,
		popupProps,
		onApply,
		onOpen,
		onClose,
		onAfterClose
	} = props;

	const isSmallWidth = useIsBeforeLargeTablet();
	const isSmallHeight = useIsBeforeLargeTabletHeight();
	const isMobileView = md.mobile() || isSmallWidth || isSmallHeight;

	const triggerActions = {
		open: onOpen,
		close: onClose,
		toggle: isOpen ? onClose : onOpen
	};

	const buttons = isButtonsVisible && (
		<div className={classNames('date-picker-layer__buttons', {'date-picker-layer__buttons_margin': !isMobileView})}>
			<Button
				className="date-picker-layer__button"
				type="cancel"
				label="Отменить"
				onClick={onClose}
			/>

			<Button
				className="date-picker-layer__button"
				type="accent-blue"
				label="Применить"
				onClick={onApply}
			/>
		</div>
	);

	return isMobileView ? (
		<>
			{trigger({...triggerActions})}

			<Dialog
				{...dialogProps}
				isOpen={isOpen}
				footer={buttons}
				fullScreenOnMobile
				onRequestClose={onClose}
				onAfterClose={onAfterClose}
			>
				{result}
				{children}
			</Dialog>
		</>
	) : (
		<Popup
			{...popupProps}
			className={classNames('date-picker-layer_popup', popupProps?.className)}
			trigger={triggerProps => trigger({...triggerProps, ...triggerActions})}
			isOpen={isOpen}
			onOutsideClick={onClose}
			onDisappear={onClose}
			onAnimationComplete={onAfterClose}
		>
			{children}
			{buttons}
		</Popup>
	);
};

DatePickerLayer.displayName = 'DatePickerLayer';

export default DatePickerLayer;
