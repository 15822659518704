import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsEntitiesByItem} from '@src/store/modules/pages/checkLists/selectors/problems';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formProblemLink} from '@tehzor/tools/utils/links';
import {EntityInnerTable} from '@tehzor/ui-components';
import {columns} from '../../../columns';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckDetailsEntity} from '@src/interfaces/ICheckDetailsEntity';
import {Column} from 'react-table';

interface IItem extends ICheckItem {
	children?: any;
}

interface ITableProps {
	item: IItem;
}

export const Table = ({item}: ITableProps) => {
	const params = useStrictParams<{objectId: string}>();
	const {pushPath} = useChangePath();
	const problems = useAppSelector(s =>
		extractProblemsEntitiesByItem(s, item.checkListId, item.id, undefined)) as ICheckDetailsEntity[];

	const handleRowClick = useCallback(
		(entity: ICheckDetailsEntity) => pushPath(formProblemLink(params.objectId, entity.id)),
		[params.objectId]
	);

	return (
		<div className="check-item-detail__list">
			{problems.length > 0 && (
				<>
					<div className="check-item-detail__list-body">
						<EntityInnerTable
							columns={columns as Array<Column<ICheckDetailsEntity>>}
							hideHead
							data={problems}
							onRowClick={handleRowClick}
						/>
					</div>
				</>
			)}
		</div>
	);
};
