import {wsConnector} from '../wsConnector';

export interface IMoveProblemsResponse {
	objectId: string;
	problemId: string;
}

interface IProblemCheckTarget {
	checkId: string;
	internalAcceptanceId?: undefined;
}

interface IProblemInternalAcceptanceTarget {
	internalAcceptanceId: string;
	checkId?: undefined;
}
export type IProblemTarget = IProblemCheckTarget | IProblemInternalAcceptanceTarget;

/**
 * Перемещение нарушений в другую проверку
 *
 * @param objectId id объекта
 * @param target id целевой сущности
 * @param problemId id нарушения
 */
export const makeProblemsMoveRequest = (
	objectId: string,
	target: IProblemTarget,
	problemId: string
) =>
	wsConnector.sendAuthorizedRequest<IMoveProblemsResponse>('moveProblem', {
		objectId,
		target,
		problemId
	});
