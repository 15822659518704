import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
// import {extractSpaceTypesAsArray} from '@src/store/modules/dictionaries/spaceTypes/selectors';
import {IContractForm, ContractFormId} from '@tehzor/tools/interfaces/spaces/IContractForm';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {extractContractFormsAsArray} from '@src/store/modules/dictionaries/contractForms/selectors';
import './ContractFormSelect.less';

interface IContractFormSelectProps<S, E> {
	field?: keyof S;
	value?: ContractFormId | ContractFormId[];
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
}

const getInputLabel = (
	value: ContractFormId | ContractFormId[] | undefined,
	types: IContractForm[],
	typesMap: Record<string, IContractForm>
) => {
	if (!value) {
		return undefined;
	}

	if (Array.isArray(value)) {
		return value.length ? makeFilterLabel('Выбрано', value, types) : undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

const arrowIcon = <i className="tz-simple-arrow-20"/>;

const ContractFormSelect = <
	S extends {contractForm?: ContractFormId, contractTypes?: ContractFormId[]},
	E
>({
	className,
	style,
	field = 'contractForm',
	label = 'Форма договора',
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage
}: IContractFormSelectProps<S, E>) => {
	const types = useAppSelector(extractContractFormsAsArray);
	const typesMap = useAppSelector(s => s.dictionaries.contractForms.byId);

	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	const onClear = useCallback(() => {
		editingDispatch({type: 'update', field, value: undefined});
	}, []);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, types, typesMap)}
						cleanable={!!value}
						onClearClick={onClear}
						icon={arrowIcon}
						error={required && hasError ? errorMessage : undefined}
						disabled={disabled}
					/>
				)}
			>
				{Array.isArray(value) ? (
					<Select2
						multiple
						value={value}
						onChange={handleChange}
					>
						{types.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="checkbox"
							/>
						))}
					</Select2>
				) : (
					<Select2
						value={value}
						onChange={handleChange}
					>
						{types.map(type => (
							<SelectOption
								key={type.id}
								itemKey={type.id}
								content={type.name}
								inputType="radio"
							/>
						))}
					</Select2>
				)}
			</SelectPopup>
		</div>
	);
};

export default ContractFormSelect;
