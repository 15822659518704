import React from 'react';
import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog';

interface IDialogTitleProps {
	view?: AddingWarrantyClaimView;
}

const DialogTitle = ({view}: IDialogTitleProps) => (
	<div className="adding-warranty-claim-dialog__dialog-title">
		{view === 'claim' ? 'Гарантийное обращение' : view === 'problem' ? 'Нарушение' : ''}
	</div>
);

export default DialogTitle;
