import {OfflineModeStatus} from '@tehzor/tools/enums/OfflineModeStatus';

export const offlineModeTitles: Record<OfflineModeStatus, string> = {
	[OfflineModeStatus.UNKNOWN]: 'Неизвестная ошибка',
	[OfflineModeStatus.SAVING]: 'Передача данных ...',
	[OfflineModeStatus.SAVING_COMPLETE]: 'Передача данных завершена',
	[OfflineModeStatus.SAVING_ERROR]: 'Ошибка при передаче данных',
	[OfflineModeStatus.CACHING]: 'Обновление данных...',
	[OfflineModeStatus.CACHING_ERROR]: 'Ошибка при обновлении',
	[OfflineModeStatus.ONLINE_READY]: 'Данные актуальны',
	[OfflineModeStatus.ONLINE_CACHE_MISSING]: 'Данные отсутствуют',
	[OfflineModeStatus.OFFLINE_READY]: 'Автономный режим',
	[OfflineModeStatus.OFFLINE_ERROR]: 'Ошибка при обновлении данных',
	[OfflineModeStatus.OFFLINE_CACHE_MISSING]: 'Данные отсутствуют'
};
