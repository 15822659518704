import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {
	IEditWorkAcceptanceResponsibleResponse,
	makeWorkAcceptanceResponsibleEditRequest
} from '@src/api/backend/workAcceptance/editResponsible';

export type IEditWorkAcceptanceResponsiblePayload = IEditWorkAcceptanceResponsibleResponse;

const request = () => ({type: types.EDIT_RESPONSIBLE_REQUEST});

const success = (response: IEditWorkAcceptanceResponsibleResponse) => ({
	type: types.EDIT_RESPONSIBLE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении ответственных');
	return {
		type: types.EDIT_RESPONSIBLE_FAILURE,
		payload: error
	};
};

/**
 * Изменяет ответственных приемки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приемки работ
 * @param respUsers пользователи
 * @param activeGroup текущая группа
 */
export const editWorkAcceptanceResponsible = (
	objectId: string,
	workAcceptanceId: string,
	respUsers?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditWorkAcceptanceResponsibleResponse>(request, success, failure, () =>
		makeWorkAcceptanceResponsibleEditRequest(objectId, workAcceptanceId, respUsers, activeGroup));
