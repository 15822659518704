import {PrivateRoute} from '@src/containers/PrivateRoute';
import {CheckListsEditPage} from '@src/pages/manage/CheckListsEditPage/CheckListsEditPage';
import {CheckListsPage} from '@src/pages/manage/CheckListsPage/CheckListsPage';
import React from 'react';

export const manageCheckListsRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<CheckListsPage/>}/>
	},
	{
		path: ':checkListId',
		element: <PrivateRoute element={<CheckListsEditPage/>}/>
	}
];
