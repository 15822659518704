import React, {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IExportTemplate, {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {exportInternalAcceptancesUnified} from '@src/store/modules/entities/internalAcceptances/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptancesData} from '@src/store/modules/entities/internalAcceptances/selectors';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';
import useToggle from 'react-use/lib/useToggle';

const availableDestinations = [ExportTemplatesDestinationId.CHECKS, ExportTemplatesDestinationId.CHECKS_PROBLEMS];

export function useInternalAcceptancesExport(objectId = 'all'): [React.ReactNode, () => void] {
	const {selectedRows} = useAppSelector(s => extractInternalAcceptancesData(s, objectId));
	const {filters, sort} = useAppSelector(s => extractInternalAcceptancesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const [isOpen, toggleOpen] = useToggle(false);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await dispatch(
				exportInternalAcceptancesUnified(
					email,
					template.id,
					objectId !== 'all' ? objectId : undefined,
					filters,
					sort,
					selectedRows,
					createDocument
				)
			);
			toggleOpen(false);
		},
		[filters, sort, selectedRows]
	);

	const dialog = (
		<ExportDialog
			pageKey="internalAcceptances"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={toggleOpen}
		/>
	);

	return [dialog, toggleOpen];
}
