import React, {forwardRef, Ref, useCallback, useImperativeHandle} from 'react';
import {useEditableSpaceOwner} from '@src/components/EditableSpaceOwner/hooks/useEditableSpaceOwner';
import {addSpaceOwner} from '@src/store/modules/entities/spaceOwner/actions';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

interface IAddingSpaceOwnerProps {
	companyId?: string;
	spaceId?: string;
	saving: boolean;

	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingSpaceOwnerRefProps {
	save: () => Promise<void>;
	cancel: () => void;
}

const AddingSpaceOwner = (props: IAddingSpaceOwnerProps, ref?: Ref<IAddingSpaceOwnerRefProps>) => {
	const {companyId, spaceId, saving, onClose, setSaving} = props;
	const dispatch = useAppDispatch();

	const [fields, getSavingData, , isBlocking] = useEditableSpaceOwner(undefined, saving);

	const save = useCallback(async () => {
		setSaving(true);
		if (companyId && spaceId) {
			const savingData = getSavingData();
			if (savingData) {
				try {
					savingData.spaces = [spaceId];
					await dispatch(addSpaceOwner(companyId, savingData));
					onClose();
				} catch (error) {
					setSaving(false);
					throw error;
				}
			}
		}
		setSaving(false);
	}, [companyId, spaceId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, cancel}), [save, cancel]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
};

export default forwardRef<IAddingSpaceOwnerRefProps, IAddingSpaceOwnerProps>(AddingSpaceOwner);
