import React, {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import './GlobalAddingEntityDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useAsyncFn} from 'react-use';
import {IAddingProblemRefProps} from '../AddingProblem';
import {IAddingInspectionRefProps} from '../AddingInspection';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {useEntitiesMaps} from './hooks/useEntitiesMaps';
import {IAddingAcceptanceRefProps} from '../AddingOwnerAcptDialog/components/AddingAcceptance';
import {IAddingWarrantyClaimRefProps} from '../AddingWarrantyClaimDialog/components/AddingWarrantyClaim';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import {saveCheck} from '../AddingCheckDialog/utils/saveCheck';
import {saveInternalAcceptance} from '../AddingInternalAcceptanceDialog/utils/saveInternalAcceptance';
import {saveOwnerAcceptance} from '../AddingOwnerAcptDialog/utils/saveOwnerAcceptance';
import {saveClaim} from '../AddingWarrantyClaimDialog/utils/saveClaim';
import {AddingOwnerAcceptanceView} from '../AddingOwnerAcptDialog/AddingOwnerAcptDialog';
import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog/AddingWarrantyClaimDialog';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {useAddCheck} from '@src/core/hooks/mutations/checks/useAddCheck';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useLocalChecks} from '@src/core/hooks/queries/checks/useGetChecks';
import {useAddInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useAddInternalAcceptance';
import {useLocalInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/useGetInternalAcceptances';
import {useLocalOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/useGetOwnerAcceptances';
import {useLocalWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/useGetWarrantyClaims';
import {getUserCheck} from '@src/store/modules/dictionaries/latestUserChecks/actions/getOne';
import {getUserInternalAcceptance} from '@src/store/modules/dictionaries/latestUserInternalAcceptances/actions/getOne';
import {getUserOwnerAcceptance} from '@src/store/modules/dictionaries/latestUserOwnerAcceptances/actions/getOne';
import {getUserWarrantyClaim} from '@src/store/modules/dictionaries/latestUserWarrantyClaims/actions/getOne';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';

export type IGlobalAddingRef = RefObject<IGlobalAddingRefProps>;

export type IGlobalAddingRefProps =
	| IAddingProblemRefProps
	| IAddingInspectionRefProps
	| IAddingAcceptanceRefProps
	| IAddingWarrantyClaimRefProps;

export type IGlobalAddingType = ICheckAddingEntityType | IInternalAcceptanceAddingEntityType;

export interface IGlobalAddingPermissions {
	checks?: boolean;
	internalAcceptances?: boolean;
	acceptances?: boolean;
	claims?: boolean;
}

export interface IGlobalAddingLinks {
	spaceId?: string;
	structureId?: string;
	workAcceptanceId?: string;
	checkListId?: string;
	checkItemId?: string;
	checkRecordId?: string;
}

interface IGlobalAddingEntityDialogProps {
	objectId: string;
	spaceId?: string;
	links?: IGlobalAddingLinks;
	stage: ObjectStageIds;
	permissions: IGlobalAddingPermissions;
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	types?: IGlobalAddingType[];
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const getDefaultSelectedStage = (
	defaultStage: ObjectStageIds,
	entitiesSelectMapProps: Record<ObjectStageIds, IEntitySelectProps>
): ObjectStageIds => {
	if (entitiesSelectMapProps?.[defaultStage]) {
		return defaultStage;
	}

	const mapKeys = Object.keys(entitiesSelectMapProps) as ObjectStageIds[];
	return mapKeys[0];
};

const GlobalAddingEntityDialog = (props: IGlobalAddingEntityDialogProps) => {
	const {
		objectId,
		spaceId,
		links,
		stage,
		permissions,
		defaultProblemData,
		defaultInspectionData,
		isOpen,
		types,
		onClose,
		onSuccess
	} = props;
	const stages = useAppSelector(extractObjectStagesAsArray);
	const object = useAppSelector(s => extractObject(s, objectId));
	const addingEntityRef = useRef<IGlobalAddingRefProps>(null);
	const [selectedStage, setSelectedStage] = useState(stage);

	const [selectedCheckId, setSelectedCheckId] = useState<string | undefined>();
	const [selectedInternalAcceptanceId, setSelectedInternalAcceptanceId] = useState<
		string | undefined
	>();
	const [selectedOwnerAcceptanceId, setSelectedOwnerAcceptanceId] = useState<
		string | undefined
	>();
	const [selectedWarrantyClaimId, setSelectedWarrantyClaimId] = useState<string | undefined>();

	const [ownerAcceptanceView, setOwnerAcceptanceView] = useState<AddingOwnerAcceptanceView>();
	const [warrantyClaimView, setWarrantyClaimView] = useState<AddingWarrantyClaimView>();

	const localOwnerAcceptances = useLocalOwnerAcceptances();
	const localOwnerAcceptancesIds = localOwnerAcceptances.map(acp => acp.id);
	const localWarrantyClaims = useLocalWarrantyClaims();
	const localWarrantyClaimsIds = localWarrantyClaims.map(claim => claim.id);
	const localChecks = useLocalChecks();
	const localChecksIds = localChecks.map(check => check.id);
	const localInternalAcceptances = useLocalInternalAcceptances();
	const localInternalAcceptancesIds = localInternalAcceptances.map(acpt => acpt.id);
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const [contentMap, entitiesSelectMapProps] = useEntitiesMaps({
		objectId,
		spaceId,
		links,
		permissions,
		addingEntityRef,
		defaultProblemData,
		defaultInspectionData,
		types,
		saving,
		setSaving,
		selectedCheckId,
		selectedInternalAcceptanceId,
		selectedOwnerAcceptanceId,
		selectedWarrantyClaimId,
		setSelectedCheckId,
		setSelectedInternalAcceptanceId,
		setSelectedOwnerAcceptanceId,
		setSelectedWarrantyClaimId,
		ownerAcceptanceView,
		setOwnerAcceptanceView,
		warrantyClaimView,
		setWarrantyClaimView,
		onClose
	});

	useEffect(() => {
		setSelectedStage(getDefaultSelectedStage(stage, entitiesSelectMapProps));
	}, [stage]);

	const reset = useCallback(
		() => setSelectedStage(getDefaultSelectedStage(stage, entitiesSelectMapProps)),
		[stage]
	);

	const dispatch = useAppDispatch();
	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
			reset();
		}
	}, [onSuccess, reset]);

	const addCheck = useAddCheck(object);
	const addInternalAcceptance = useAddInternalAcceptance(object);

	const [, handleSave] = useAsyncFn(async () => {
		if (permissions.checks && selectedStage === ObjectStageIds.BUILDING) {
			const addNewCheck = () =>
				addCheck({
					objectId,
					links,
					stage
				});
			const updateLatest = async () => {
				if (online && selectedCheckId && !localChecksIds.includes(selectedCheckId)) {
					await dispatch(getUserCheck(objectId, selectedCheckId));
				}
			};

			await saveCheck({
				addingEntityRef,
				selectedCheckId,
				localChecksIds,
				online,
				updateLatest,
				addNewCheck,
				setSaving,
				success,
				onClose
			});
			return;
		}

		if (
			(permissions.internalAcceptances && selectedStage === ObjectStageIds.ACCEPTANCE)
			|| (!permissions.checks
				&& permissions.internalAcceptances
				&& selectedStage === ObjectStageIds.BUILDING)
		) {
			const addNewInternalAcceptance = () =>
				addInternalAcceptance({
					objectId,
					links,
					stage
				});
			const updateLatest = async () => {
				if (
					online
					&& selectedInternalAcceptanceId
					&& !localInternalAcceptancesIds.includes(selectedInternalAcceptanceId)
				) {
					await dispatch(
						getUserInternalAcceptance(objectId, selectedInternalAcceptanceId)
					);
				}
			};

			await saveInternalAcceptance({
				addingEntityRef,
				selectedInternalAcceptanceId,
				localInternalAcceptancesIds,
				online,
				updateLatest,
				addNewInternalAcceptance,
				setSaving,
				success,
				onClose
			});
			return;
		}

		if (permissions.acceptances && selectedStage === ObjectStageIds.TRANSFER) {
			const updateLatest = async () => {
				if (
					online
					&& selectedOwnerAcceptanceId
					&& !localOwnerAcceptancesIds.includes(selectedOwnerAcceptanceId)
				) {
					await dispatch(getUserOwnerAcceptance(objectId, selectedOwnerAcceptanceId));
				}
			};
			await saveOwnerAcceptance({
				addingEntityRef,
				localOwnerAcceptancesIds,
				view: ownerAcceptanceView,
				online,
				updateLatest,
				selectedOwnerAcceptanceId,
				success,
				onClose
			});
			return;
		}

		if (permissions.claims && selectedStage === ObjectStageIds.WARRANTY) {
			const updateLatest = async () => {
				if (
					online
					&& selectedWarrantyClaimId
					&& localWarrantyClaimsIds.includes(selectedWarrantyClaimId)
				) {
					await dispatch(getUserWarrantyClaim(objectId, selectedWarrantyClaimId));
				}
			};
			await saveClaim({
				addingEntityRef,
				localWarrantyClaimsIds,
				view: warrantyClaimView,
				online,
				updateLatest,
				selectedWarrantyClaimId,
				success,
				onClose
			});
		}
	}, [
		onClose,
		selectedCheckId,
		selectedInternalAcceptanceId,
		selectedOwnerAcceptanceId,
		selectedWarrantyClaimId,
		objectId,
		links,
		stage,
		localOwnerAcceptancesIds,
		localWarrantyClaimsIds,
		ownerAcceptanceView,
		warrantyClaimView,
		permissions,
		selectedStage,
		addingEntityRef,
		selectedStage,
		success
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			close();
		}
	}, [close]);

	return (
		<Dialog
			isOpen={isOpen}
			className={{
				root: 'adding-entity-dialog',
				header: 'adding-entity-dialog__header',
				body: 'adding-entity-dialog__body'
			}}
			fullScreenOnTablet
			onRequestClose={onClose}
			onAfterClose={handleAfterClose}
			title="Добавить"
			disableScrollBlock
			footer={(
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
		>
			<EntitySelectContainer
				contentMap={contentMap}
				entitiesSelectMapProps={entitiesSelectMapProps}
				stages={stages}
				selectedStage={selectedStage}
				onSelectStage={setSelectedStage}
			/>
		</Dialog>
	);
};

export default GlobalAddingEntityDialog;
