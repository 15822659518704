import React, {memo, useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ErrorBoundary, ErrorMessage, LinkButton} from '@tehzor/ui-components';
import {extractObjectsAsArray} from '@src/store/modules/dictionaries/objects/selectors';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';

interface IObjectsFilterMobileProps {
	entity: string;
	value?: string[];
	pageObjectId?: string;
	targetObjects?: string[];
	filterName?: string;
}

export const ObjectsFilterMobile = memo(({
	entity, value, pageObjectId, targetObjects, filterName = 'objects'
}: IObjectsFilterMobileProps) => {
	const objects = useAppSelector(extractObjectsAsArray);
	const companies = useAppSelector(extractCompaniesAsArray);

	const treeData = useMemo(() => {
		if (targetObjects) {
			return makeObjectsTreeData(objects.filter(item => targetObjects.includes(item.id)));
		}
		return makeObjectsTreeData(objects, companies);
	}, [targetObjects, objects, companies]);

	const handleExpand = useFilterPageTransition(pageObjectId, entity, filterName);

	const label = makeFilterLabel('Объекты', value, treeData, true);

	return (
		<ErrorBoundary
			label="ObjectsFilterMobile"
			component={(
				<div className="mobile-entities-filters__objects-filter">
					<ErrorMessage/>
				</div>
			)}
		>
			<div className="mobile-entities-filters__objects-filter">
				<div className="mobile-entities-filters__objects-filter-header">
					<div className="mobile-entities-filters__objects-filter-title">
						Объекты
					</div>
					<LinkButton
						label="Все"
						onClick={handleExpand}
					/>
				</div>
				<div className="mobile-entities-filters__objects-filter-label">
					{label}
				</div>
			</div>
		</ErrorBoundary>
	);
});
