import {contractsQueryKeys} from '@src/api/cache/contracts/keys';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEditableContractDialog} from '@src/components/EditableContractDialog/hooks/useEditableContractDialog';
import {useContractsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useContractsBreadcrumbs';
import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {useAuthorizedPersonsMap} from '@src/core/hooks/queries/authorizedPersons';
import {useBankAccountsMap} from '@src/core/hooks/queries/bankAccounts';
import {useContract} from '@src/core/hooks/queries/contracts';
import {useLegalsMap} from '@src/core/hooks/queries/legals';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {addErrorToast} from '@src/utils/toasts';
import {useIsFetching} from '@tanstack/react-query';
import {LoadingPanel} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import './ContractPage.less';
import {ContractInfo} from './components/ContractInfo';
import {ContractMain} from './components/ContractMain';
import {Menu} from './components/actions/Menu';
import {DesktopActions} from './components/actions/desktop.actions';
import {convertContract} from './utils/convertContract';
import {useAsync} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getPlans} from '@src/store/modules/dictionaries/plans/actions';
import {getUnloadedObjectIdsForPlans} from '@src/store/modules/dictionaries/plans/utils/getUnloadedObjectIdsForPlans';

export const ContractPage = () => {
	useScrollRestoration();

	const {contractId} = useParams<{contractId?: string}>();
	const contract = useContract(contractId);

	const dispatch = useAppDispatch();
	const breadcrumbs = useContractsBreadcrumbs(contract);
	const isLargeTablet = useIsLargeTablet();

	const companies = useAppSelector(extractCompaniesAsArray);
	const legalsMap = useLegalsMap();
	const bankAccountsMap = useBankAccountsMap();
	const authorizedPersonsMap = useAuthorizedPersonsMap();
	const preparedContract = useMemo(
		() =>
			convertContract(contract, companies, legalsMap, bankAccountsMap, authorizedPersonsMap),
		[contract, companies, legalsMap, bankAccountsMap, authorizedPersonsMap]
	);

	const [contractDialog, openEditDialog] = useEditableContractDialog(contract);

	const permissions = useContractsPermissions();
	const {canAdministrate} = permissions;
	useEffect(() => {
		if (!canAdministrate) {
			addErrorToast('Отсутствие доступа', 'к просмотру страницы');
		}
	}, [canAdministrate]);

	const allPlans = useAppSelector(s => s.dictionaries.plans);
	useAsync(async () => {
		if (!contract?.objectIds?.length) {
			return;
		}
		const unloadedObjectIds = getUnloadedObjectIdsForPlans(contract.objectIds, allPlans);

		await dispatch(getPlans(unloadedObjectIds));
	}, [contract?.objectIds, allPlans]);

	useAppHeader(
		{
			title: preparedContract ? preparedContract.name : '',
			showBackBtn: true,
			mobileRightButtons: preparedContract ? (
				<Menu
					contract={preparedContract}
					permissions={permissions}
					openEditDialog={openEditDialog}
				/>
			) : null
		},
		[preparedContract]
	);

	const isFetching = !!useIsFetching({
		queryKey: contractId ? contractsQueryKeys.detail(contractId) : undefined
	});

	if (!canAdministrate) {
		return null;
	}
	return (
		<LoadingPanel
			className="contract__loading-panel"
			active={isFetching}
		>
			{preparedContract && (
				<div className="contract">
					<AppBreadcrumbs
						className="contract__breadcrumbs"
						items={breadcrumbs}
					>
						{isLargeTablet && (
							<DesktopActions
								contract={preparedContract}
								permissions={permissions}
								openEditDialog={openEditDialog}
							/>
						)}
					</AppBreadcrumbs>
					<div className="contract__wrap">
						<ContractInfo contract={preparedContract} />
						<ContractMain contract={preparedContract} />
					</div>
					{permissions.canEdit && contractDialog}
				</div>
			)}
		</LoadingPanel>
	);
};
