import {
	changeCacheStatus,
	changeDictionariesCacheProgress,
	changeDictionariesCacheState,
	// changeEntitiesCacheProgress,
	changeEntitiesCacheState
} from '@src/store/modules/offlineMode/actions';
import {rehydrateState} from '@src/core/offlineMode/rehydrateState';
import {CacheStatus} from '@tehzor/tools/enums/CacheStatus';
import {
	ICachingFinishedEvent,
	ICachingStatusesChangedEvent,
	ICachingProgressChangedEvent
} from '@tehzor/tools/contracts/dataCachingWebWorker/events';
import {IWebWorkerMessage} from '@tehzor/tools/contracts/IWebWorkerMessage';
import {
	CACHING_FINISHED_EVENT_TYPE,
	CACHING_STARTED_EVENT_TYPE,
	CACHING_STATUSES_CHANGED_EVENT_TYPE,
	CACHING_PROGRESS_CHANGED_EVENT_TYPE
} from '@tehzor/tools/contracts/dataCachingWebWorker/constants';
import {AppDispatch} from '@src/store/appStore';
import {IDictionariesCacheState} from '@src/store/modules/offlineMode/reducers/dictionariesCacheState';
import {IEntitiesCacheState} from '@src/store/modules/offlineMode/reducers/entitiesCacheState';
import {OfflineDataCachingStatus} from '@tehzor/tools/contracts/dataCachingWebWorker/interfaces/IOfflineDataCachingStatuses';
import {isServiceWorkerSupported} from '../utils/isServiceWorkerSupported';

export class CachingStateTracker {
	private dispatch: AppDispatch;

	constructor(dispatch: AppDispatch) {
		if (!isServiceWorkerSupported()) {
			throw new Error('Trying to use CachingStatusTracker without ServiceWorker');
		}
		this.dispatch = dispatch;
	}

	/**
	 * Обновляет статус автономной работы исходя из состояния справочников
	 *
	 * @param states состояния справочников
	 */
	setInitialStatus = (dictionariesStates: IDictionariesCacheState, entitiesStates: IEntitiesCacheState) => {
		let dictionariesWithErrors = false;
		let entitiesWithErrors = false;
		let dictionariesLoaded = true;
		let entitiesLoaded = true;

		for (const state of Object.values(dictionariesStates)) {
			if (state?.status === OfflineDataCachingStatus.ERROR) {
				dictionariesWithErrors = true;
			}
			if (
				state?.status === OfflineDataCachingStatus.WAITING
				|| state?.status === OfflineDataCachingStatus.LOADING
			) {
				dictionariesLoaded = false;
			}
		}
		for (const state of Object.values(entitiesStates)) {
			if (state?.status === OfflineDataCachingStatus.ERROR) {
				entitiesWithErrors = true;
			}
			if (
				state?.status === OfflineDataCachingStatus.WAITING
				|| state?.status === OfflineDataCachingStatus.LOADING
			) {
				entitiesLoaded = false;
			}
		}

		// Если есть в процессе обновления, то нужно запросить статус у service worker'а

		this.dispatch(
			changeCacheStatus(
				(dictionariesWithErrors && entitiesWithErrors)
					? CacheStatus.ERROR : (dictionariesLoaded && entitiesLoaded)
						? CacheStatus.READY : CacheStatus.MISSING
			)
		);
	};

	start = () => {
		console.log('CachingStatusTracker start');
		navigator.serviceWorker.addEventListener('message', this.handleMessage);
	};

	stop = () => {
		console.log('CachingStatusTracker stop');
		navigator.serviceWorker.removeEventListener('message', this.handleMessage);
	};

	private handleMessage = (event: MessageEvent<IWebWorkerMessage>) => {
		console.log('Message from ServiceWorker (CachingStatusTracker)', event.data);

		if (typeof event.data === 'object') {
			switch (event.data.type) {
				case CACHING_STARTED_EVENT_TYPE:
					this.handleCachingStartedEvent();
					break;
				case CACHING_STATUSES_CHANGED_EVENT_TYPE:
					this.handleCachingStatusesChangedEvent(
						event.data as ICachingStatusesChangedEvent
					);
					break;
				case CACHING_PROGRESS_CHANGED_EVENT_TYPE:
					this.handleCachingProgressChangedEvent(
						event.data as ICachingProgressChangedEvent
					);
					break;
				case CACHING_FINISHED_EVENT_TYPE:
					void this.handleCachingFinishedEvent(event.data as ICachingFinishedEvent);
					break;
			}
		}
	};

	private handleCachingStartedEvent = () => {
		this.dispatch(changeCacheStatus(CacheStatus.CACHING));
	};

	private handleCachingFinishedEvent = async (data: ICachingFinishedEvent) => {
		await rehydrateState(this.dispatch);
		this.dispatch(changeCacheStatus(data.error ? CacheStatus.ERROR : CacheStatus.READY));
	};

	private handleCachingStatusesChangedEvent = (data: ICachingStatusesChangedEvent) => {
		if (data.statuses.dictionaries) {
			this.dispatch(changeDictionariesCacheState(data.statuses.dictionaries));
		}
		if (data.statuses.entities) {
			this.dispatch(changeEntitiesCacheState(data.statuses.entities));
		}
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	private handleCachingProgressChangedEvent = (data: ICachingProgressChangedEvent) => {
		if (data.entity && data.value !== undefined) {
			this.dispatch(changeDictionariesCacheProgress(data.entity, data.value));
		}
	};
}
