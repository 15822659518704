import {wsConnector} from '../wsConnector';
import {ISpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISpaceStatusesSet';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';

export type IEditSpaceStatusesSetResponse = ISpaceStatusesSet;

/**
 * Изменяет набор статусов помещений
 * 
 * @param spaceStatusesSetId id набора статусов помещения
 * @param fields поля для набора статусов помещений
 */
export const requestEditSpaceStatusesSet = (
	spaceStatusesSetId: string,
	fields: ISavingSpaceStatusesSet
) =>
	wsConnector.sendAuthorizedRequest<IEditSpaceStatusesSetResponse>('editSpaceStatusesSet', {
		...fields,
		id: spaceStatusesSetId
	});
