import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeEntitiesTab} from '@src/store/modules/entities/space/actions/changeEntitiesTab';
import {SpaceEntitiesTab} from '@src/store/modules/entities/space/reducers';
import {useUpdateEffect} from 'react-use';

const EntitiesTabs = () => {
	const hasChecks = useAppSelector(s => s.entities.space.checks.allIds.length > 0);
	const hasLocalChecks = useAppSelector(s => s.localEntities.checks.length > 0);
	const checksLength = useAppSelector(s => s.entities.space.checks.allIds.length);
	const hasInternalAcceptances = useAppSelector(
		s => s.entities.space.internalAcceptances.allIds.length > 0
	);
	const hasLocalInternalAcceptances = useAppSelector(
		s => s.localEntities.internalAcceptances.length > 0
	);
	const internalAcceptancesLength = useAppSelector(
		s => s.entities.space.internalAcceptances.allIds.length
	);
	const hasAcceptances = useAppSelector(s => s.entities.space.ownerAcceptances.allIds.length > 0);
	const hasLocalAcceptances = useAppSelector(
		s => s.localEntities.ownerAcceptances.length > 0
	);
	const acceptancesLength = useAppSelector(s => s.entities.space.ownerAcceptances.allIds.length);
	const hasClaims = useAppSelector(s => s.entities.space.warrantyClaims.allIds.length > 0);
	const hasLocalClaims = useAppSelector(
		s => s.localEntities.warrantyClaims.length > 0
	);
	const claimsLength = useAppSelector(s => s.entities.space.warrantyClaims.allIds.length);
	const tab = useAppSelector(s => s.entities.space.entitiesTab);
	const dispatch = useAppDispatch();

	const [tabsData, tabsLinks] = useMemo(() => {
		const data: Array<{key: SpaceEntitiesTab, title: string}> = [];
		if (hasClaims || hasLocalClaims) {
			data.push({key: 'warranty-claims', title: 'Гарантийные обращения'});
		}
		if (hasAcceptances || hasLocalAcceptances) {
			data.push({key: 'owner-acceptances', title: 'Передачи собственникам'});
		}
		if (hasInternalAcceptances || hasLocalInternalAcceptances) {
			data.push({key: 'internal-acceptances', title: 'Внутренние приемки'});
		}
		if (hasChecks || hasLocalChecks) {
			data.push({key: 'checks', title: 'Проверки'});
		}
		if (data.length > 1) {
			data.unshift({key: 'all', title: 'Показать все'});
		}
		const links = data.map(item => (
			<TabLink
				key={item.key}
				label={item.title}
			/>
));
		return [data, links];
	}, [
		hasChecks,
		hasLocalChecks,
		hasAcceptances,
		hasClaims,
		hasInternalAcceptances,
		hasLocalInternalAcceptances,
		hasLocalAcceptances,
		hasLocalClaims
	]);

	// TODO УБрать после доработки Tabs (использование строкового ключа)
	const [activeTab, setActiveTab] = useState(0);

	useEffect(() => {
		const index = tabsData.findIndex(item => item.key === tab);
		if (index !== -1) {
			setActiveTab(index);
		}
	}, [tab]);

	// TODO УБрать после доработки Tabs (использование строкового ключа)
	const handleTabChange = useCallback(
		(index: number) => {
			if (tabsData[index]) {
				setTimeout(() => {
					dispatch(changeEntitiesTab(tabsData[index].key));
				}, 0);
			}
		},
		[tabsData]
	);
	useUpdateEffect(() => {
		setActiveTab(0);
		handleTabChange(0);
	}, [checksLength, internalAcceptancesLength, acceptancesLength, claimsLength]);

	return tabsLinks.length > 1 ? (
		<Tabs
			className={{links: 'space-page__tabs', link: 'space-page__tab-link'}}
			activeTab={activeTab}
			links={tabsLinks}
			onActiveTabChange={handleTabChange}
		/>
	) : null;
};

export default EntitiesTabs;
