import {IState} from '@src/store/modules';
import {createSelector} from 'reselect';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

const extractAllIds = (state: IState) => state.dictionaries.responsibilityRules.allIds || [];
const extractById = (state: IState) => state.dictionaries.responsibilityRules.byId || {};
const getWorkingGroupId = (state: IState, workingGroupId: string | undefined) => workingGroupId;

export const extractResponsibilityRulesAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map(id => byId[id])
);

export const extractResponsibilityRulesForWorkingGroupAsArray = createSelector(
	[extractResponsibilityRulesAsArray, getWorkingGroupId],
	(rules, workingGroupId) => rules.filter(rule => rule.groupId === workingGroupId)
);

export const extractFilteredResponsibilityRules = createSelector(
	[
		extractResponsibilityRulesAsArray,
		(s: unknown, objectId: string) => objectId,
		(s: unknown, objectId: string, stage?: ObjectStageIds) => stage,
		(s: unknown, objectId: string, stage?: ObjectStageIds, scope?: string) => scope
	],
	(rules, objectId, stage, scope) =>
		rules.filter(
			rule =>
				(!rule.objects?.length || rule.objects.includes(objectId))
				&& (!rule.stage || rule.stage === stage)
				&& (!rule.scope || rule.scope === scope)
		)
);
