import React, {createContext, PropsWithChildren} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteProblemTagsSet} from '@src/store/modules/dictionaries/problemTagsSets/actions';
import {IGetProblemTagsResponse} from '@src/api/backend/problemTags';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IEnrichedProblemTagsSet>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	reloadList
}: PropsWithChildren<{reloadList: () => Promise<IGetProblemTagsResponse>}>) => {
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить набор меток?',
		'Все связанные данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IEnrichedProblemTagsSet>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await dispatch(deleteProblemTagsSet(action.payload.id));
				await reloadList();
			}
		}
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
