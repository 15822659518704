import React from 'react';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import MobileInternalAcceptancesExport from './InternalAcceptancesExport.mobile';
import MobileEntityAdding from './EntityAdding.mobile';
import {useProblemsAddingPermissions} from '@src/core/hooks/permissions/useProblemsAddingPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';

interface IMobileActionsProps {
	objectId?: string;
}

const ActionsMobile = ({objectId}: IMobileActionsProps) => {
	const permissions = useInternalAcceptancesPermissions(objectId);
	const problemsPermissions = useProblemsAddingPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return (
		<>
			{objectId !== undefined
				&& permissions.canAdd
				&& (problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<MobileEntityAdding objectId={objectId}/>
				)}
			{permissions.canExport && <MobileInternalAcceptancesExport objectId={objectId}/>}
		</>
	);
};

export default ActionsMobile;
