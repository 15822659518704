import React, {Dispatch} from 'react';
import './EditableProblem.less';
import {EditableDate} from '@src/components/editableFields/EditableDate';
import {Location} from '@src/components/editableFields/Location';
import Floors from '@src/components/editableFields/Floors';
import {Responsible} from '@src/components/editableFields/Responsible';
import Attachments from '@src/components/editableFields/Attachments';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {useIsDesktopHeight} from '@tehzor/ui-components/src/utils/mediaQueries';
import {
	IEditableProblemAction,
	IEditableProblemState
} from '@src/core/hooks/states/useEditableProblemState';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import SearchableProblemDescription from '@src/components/EditableProblem/components/SearchableProblemDescription';
import Description from '../editableFields/Description';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import Prescription from '../editableFields/Prescription';
import Category from '../editableFields/Category';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ProblemTags} from '../editableFields/ProblemTags';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {SearchableProblemReason} from './components/SearchableProblemReason';
import {Inspectors} from '../editableFields/Inspectors';
import {Contracts} from '../editableFields/Contracts';
import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';

const desktopDescProps = {
	minRows: 3,
	maxRows: 7
};
const mobileDescProps = {
	minRows: 3,
	maxRows: 5
};

interface IEditableProblemProps {
	objectId: string;
	stage: ObjectStageIds;
	scope?: string;
	editingState: IEditableProblemState;
	editingDispatch: Dispatch<IEditableProblemAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
	creating?: boolean;
	fieldsAvailability?: Record<string, boolean>;
	entityLocation?: ILocation;
	entityPlanId?: string;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableProblem = (props: IEditableProblemProps) => {
	const {
		objectId,
		stage,
		scope,
		editingState,
		editingDispatch,
		uploadingFilesState,
		uploadingFilesDispatch,
		fieldsSettings,
		saving,
		creating,
		fieldsAvailability,
		entityLocation,
		entityPlanId
	} = props;

	const isDesktopHeight = useIsDesktopHeight();
	const problem = useAppSelector(s => s.pages.problem.data);
	const {canView} = useContractsPermissions();

	return (
		<div className="editable-problem">
			<div>
				{fieldsSettings.description !== undefined &&
					(creating ? (
						<SearchableProblemDescription
							className="editable-problem__description"
							value={editingState.description}
							editingDispatch={editingDispatch}
							required={fieldsSettings.description.isRequired}
							disabled={saving}
							hasError={editingState.errors.description}
							textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
							objectId={objectId}
							stage={stage}
						/>
					) : (
						<Description
							className="editable-problem__description"
							value={editingState.description}
							editingDispatch={editingDispatch}
							required={fieldsSettings.description.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.description}
							hasError={editingState.errors.description}
							textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
						/>
					))}

				<div className="editable-problem__info-grid">
					{fieldsSettings.categoryId !== undefined && (
						<Category
							value={editingState.categoryId}
							objectId={objectId}
							editingDispatch={editingDispatch}
							required={fieldsSettings.categoryId.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.categoryId}
							hasError={editingState.errors.categoryId}
							stage={stage}
						/>
					)}

					{fieldsSettings.plannedFixDate !== undefined && (
						<EditableDate
							value={editingState.plannedFixDate}
							editingDispatch={editingDispatch}
							required={fieldsSettings.plannedFixDate.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.plannedFixDate}
							hasError={editingState.errors.plannedFixDate}
							criticalValue={editingState.critical}
							criticalFieldsSettings={fieldsSettings.critical}
							criticalDisabled={fieldsAvailability && !fieldsAvailability.critical}
							createdAt={editingState.createdAt}
							showTimeSelect
						/>
					)}

					{fieldsSettings.reason !== undefined && (
						<SearchableProblemReason
							value={editingState.reason}
							editingDispatch={editingDispatch}
							required={fieldsSettings.reason.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.reason}
							hasError={editingState.errors.reason}
						/>
					)}

					{fieldsSettings.problemTags !== undefined && (
						<ProblemTags
							objectId={objectId}
							stage={stage}
							tags={editingState.problemTags}
							editingDispatch={editingDispatch}
							required={fieldsSettings.problemTags.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.problemTags}
							hasError={
								editingState.errors.problemTags && editingState.errors.problemTags
							}
						/>
					)}

					{fieldsSettings.location !== undefined && (
						<Location
							planId={editingState.planId || entityPlanId}
							location={editingState.location}
							objectId={objectId}
							disabled={fieldsAvailability && !fieldsAvailability.location}
							editingDispatch={editingDispatch}
							required={fieldsSettings.location.isRequired}
							hasError={editingState.errors.location}
							entityLocation={entityLocation}
						/>
					)}

					{fieldsSettings.floor !== undefined && (
						<Floors
							value={editingState.floor}
							editingDispatch={editingDispatch}
							required={fieldsSettings.floor.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.floor}
							hasError={editingState.errors.floor}
						/>
					)}

					{fieldsSettings.respUsers !== undefined && (
						<Responsible
							objectId={objectId}
							stage={stage}
							scope={scope}
							categoryId={editingState.categoryId}
							planId={editingState.planId}
							respUsers={editingState.respUsers}
							activeGroup={editingState.activeGroup}
							editingDispatch={editingDispatch}
							required={fieldsSettings.respUsers.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.respUsers}
							workingGroupType={WorkingGroupTypeId.PERFORMERS}
							hasError={
								editingState.errors.respUsers && editingState.errors.activeGroup
							}
						/>
					)}

					{fieldsSettings.inspectors !== undefined && (
						<Inspectors
							value={editingState.inspectors}
							editingDispatch={editingDispatch}
							objectId={objectId}
							stage={stage}
							required={fieldsSettings.inspectors.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.inspectors}
							hasError={editingState.errors.inspectors}
						/>
					)}

					{fieldsSettings.contractId !== undefined && canView && (
						<Contracts
							value={editingState.contractId}
							objectId={objectId}
							stage={stage}
							planId={editingState.planId}
							categoryId={editingState.categoryId}
							editingDispatch={editingDispatch}
							required={fieldsSettings.contractId.isRequired}
							disabled={fieldsAvailability && !fieldsAvailability.contractId}
							hasError={editingState.errors.contractId}
						/>
					)}
				</div>
				{fieldsSettings.prescription !== undefined && (
					<Prescription
						className="editable-problem__prescription"
						value={editingState.prescription}
						editingDispatch={editingDispatch}
						required={fieldsSettings.prescription.isRequired}
						disabled={fieldsAvailability && !fieldsAvailability.prescription}
						hasError={editingState.errors.prescription}
						textAreaProps={isDesktopHeight ? desktopDescProps : mobileDescProps}
					/>
				)}
			</div>

			<div>
				{fieldsSettings.attachments !== undefined && (
					<Attachments
						className={{
							root: 'editable-problem__attachments',
							scrollArea: 'editable-problem__attachments-scroll-area',
							files: 'editable-problem__attachments-files',
							file: 'editable-problem__attachments-file'
						}}
						imagesTitle="Фото нарушения"
						attachmentsDestination={AttachmentFileDestination.PROBLEM}
						entityId={problem?.id}
						attachments={editingState.attachments}
						uploadingFiles={uploadingFilesState.value}
						editingDispatch={editingDispatch}
						uploadingFilesDispatch={uploadingFilesDispatch}
						required={fieldsSettings.attachments.isRequired}
						disabled={fieldsAvailability && !fieldsAvailability.attachments}
						canDraw={fieldsAvailability && fieldsAvailability.attachments}
						waitForUploading={false}
						hasError={editingState.errors.attachments && uploadingFilesState.error}
						showAttachBtn
					/>
				)}
			</div>

			{Object.keys(fieldsSettings).length === 0 && (
				<div className="editable-problem__empty-fields-msg">Все поля отключены</div>
			)}
		</div>
	);
};

export default EditableProblem;
