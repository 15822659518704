import React, {useCallback} from 'react';
import {desktopColumns} from './columns.desktop';
import Table from './Table';
import {Pagination, PaginationAndSize, PaginationPageSize, Plate} from '@tehzor/ui-components';
import {changeOffset} from '@src/store/modules/entities/spaces/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesListData} from '@src/store/modules/entities/spaces/selectors/lists';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changePageSize} from '@src/store/modules/settings/pages/spaces/actions';
import {batch} from 'react-redux';

const pageSizes = [10, 20, 50, 100];

interface IDesktopTableProps {
	objectId: string;
}

const DesktopTable = ({objectId}: IDesktopTableProps) => {
	const {total, offset} = useAppSelector(s => extractSpacesListData(s, objectId));
	const {pageSize} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			dispatch(changeOffset(objectId, selected * pageSize));
		},
		[objectId, pageSize]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			batch(() => {
				dispatch(changePageSize(objectId, value));
				dispatch(changeOffset(objectId, offset - (offset % value)));
			});
		},
		[objectId, offset]
	);

	const pageCount = Math.ceil(total / pageSize);
	const currentPage = Math.floor(offset / pageSize);

	return (
		<>
			<Plate
				className="spaces-page__plate"
				withoutPadding
			>
				<Table
					objectId={objectId}
					columns={desktopColumns}
					selectable
				/>
			</Plate>

			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pageCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>
		</>
	);
};

export default DesktopTable;
