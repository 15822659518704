import React from 'react';
import {ResourcesLoader as ResLoader} from './components/ResourcesLoader';
import {getObjects} from '@src/store/modules/dictionaries/objects/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getCompanies} from '@src/store/modules/dictionaries/companies/actions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {getProfile} from '@src/store/modules/auth/profile/actions';
import {loaderSplit} from './utils/loaderSplit';
import {getInitialResources} from './utils/getInitialResources';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {startCachingResources} from '@src/core/offlineMode/startCachingResources';
import {useQueryClient} from '@tanstack/react-query';
import {unitsQueryKeys} from '@src/api/cache/units/keys';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {updateLegalEntities} from '@src/core/hooks/queries/legals';

/**
 * Компонент для первоначальной загрузки ресурсов при старте приложения
 */
export const ResourcesLoader = () => {
	const dispatch = useAppDispatch();
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const isActivated = useAppSelector(s => s.auth.profile.activated);
	const isOfflineModeAvailable = useAppSelector(s => s.settings.offlineMode.available);
	const isOnline = useAppSelector(s => s.offlineMode.networkStatus);
	const roles = useAppSelector(extractUserRoles);
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const queryClient = useQueryClient();

	const [, loadResources] = useAsyncFn(async () => {
		if (isOnline) {
			if (isAuthenticated) {
				await dispatch(getProfile());
			}

			if (!roles) {
				return;
			}

			// Запросы разделены на несколько блоков, чтобы не прилетали на сервер все одновременно
			await dispatch(getCompanies());
			await dispatch(getObjects());

			const resources = getInitialResources(roles);
			const promises = resources.map(resource =>
				dispatch(resource() as () => Promise<unknown>)
			);
			await loaderSplit(promises);

			// Отправка команды на обновление данных
			if (isOfflineModeAvailable && objects && objects.length) {
				await startCachingResources(roles, false, objects);
			}

			// Запрос единиц измерения
			await queryClient.prefetchQuery(unitsQueryKeys.list());
			// Запрос статусов задач
			await queryClient.prefetchQuery(restDictionariesQueryKeys.taskStatuses());
			// Запрос типов задач
			await queryClient.prefetchQuery(restDictionariesQueryKeys.taskTypes());

			await updateLegalEntities();
		}
	}, [isOfflineModeAvailable, isOnline, roles]);

	return (
		<ResLoader
			isOnline={isOnline}
			isAuthorized={isAuthenticated}
			isActivated={isActivated}
			onLoad={loadResources}
		/>
	);
};
