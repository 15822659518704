import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import React, {MouseEventHandler, useCallback} from 'react';
import classNames from 'classnames';
import {ExpandablePanel, LinkButton} from '@tehzor/ui-components';
import {ItemHeader} from './ItemHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Tree} from 'array-to-tree';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {extractCheckRecordByItemId} from '@src/store/modules/pages/checkLists/selectors/records';
import {RecordStatusSelect} from '@src/pages/CheckListPage/components/RecordStatusSelect';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {useToggle} from 'react-use';
import declination from '@tehzor/tools/utils/declination';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';

interface ICheckListItemProps {
	item: Tree<ICheckItem>;
	mode: 'detail' | 'main';
	padding?: number;

	onClick?: (item: ICheckItem) => void;
}

export const CheckListItem = ({padding = 24, item, onClick, mode}: ICheckListItemProps) => {
	const {itemId, objectId} = useStrictParams<{itemId: string, objectId: string}>();
	const record = useAppSelector(s => extractCheckRecordByItemId(s, item.id));
	const [isExpanded, toggle] = useToggle(false);
	const {changeCheckItemRecord} = useChangeCheckRecord(objectId, item);
	const handleClick = useCallback<MouseEventHandler>(
		e => {
			e.stopPropagation();
			onClick?.(item);
		},
		[item]
	);

	return (
		<>
			<div
				role="presentation"
				className={classNames(
					'structure-check-list-items__item',
					'structure-check-list-items__item_disabled',
					{
						'structure-check-list-items__item_active':
							mode === 'main' && itemId === item.id
					}
				)}
				onClick={handleClick}
				style={padding ? {paddingLeft: `${padding}px`} : undefined}
			>
				<ItemHeader
					item={item}
					record={record}
				/>

				<div className="structure-check-list-items__item-indicators">
					<ProblemIndicators
						checkListId={item.checkListId}
						checkItemId={item.id}
					/>
				</div>

				<div className="structure-check-list-items__item-footer">
					<ClickPreventWrap className="structure-check-list-items__item-state">
						<RecordStatusSelect
							item={item}
							mutation={changeCheckItemRecord}
							disabled
						/>
					</ClickPreventWrap>
				</div>

				{mode === 'main' && item.children && item.children.length > 0 && (
					<div className="structure-check-list-items__item-body">
						<LinkButton
							className={classNames('structure-check-list-items__item-body-link', {
								'structure-check-list-items__item-body-link_active': isExpanded
							})}
							rightIcon={<i className="tz-simple-arrow-16"/>}
							label={
								isExpanded
									? 'Скрыть'
									: `Показать ${item.children.length} ${declination(
											item.children.length,
											['подкатегорию', 'подкатегории', 'подкатегорий']
									  )}`
							}
							onClick={toggle}
						/>
					</div>
				)}
			</div>

			{mode === 'main' && item.children && item.children.length > 0 && (
				<ExpandablePanel expanded={isExpanded}>
					<div className="structure-check-list-items structure-check-list-items__sub-items">
						{item.children.map(subitem => (
							<CheckListItem
								key={subitem.id}
								mode="main"
								padding={padding + 32}
								item={subitem}
								onClick={onClick}
							/>
						))}
					</div>
				</ExpandablePanel>
			)}
		</>
	);
};
