import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';

export const createDateDifference = (
	prev: number | undefined,
	next: number | undefined
): IHistoryData => ({
	prev: prev ? format(prev, longDateTimeFormat, {locale: ru}) : '',
	next: next ? format(next, longDateTimeFormat, {locale: ru}) : '',
	type: HistoryTypeId.PLANNED_FIX_DATE,
	name: 'срок устранения'
});
