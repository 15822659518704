import React from 'react';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {EntityInfo, EntityInfoDate} from '@tehzor/ui-components';

interface IWarrantyClaimInfoProps {
	className?: string;
	data: IWarrantyClaim;
	title?: string;
}

const WarrantyClaimInfo = ({
	className,
	data,
	title = 'Гарантийное обращение'
}: IWarrantyClaimInfoProps) => (
	<EntityInfo
		className={className}
		title={`${title} №${data.number ?? data.localNumber}`}
		subTitle={<EntityInfoDate value={data.createdAt}/>}
		icon={<i className="tz-warranty-claim-28"/>}
		iconColor="#656BEA"
		iconSize={44}
	/>
);

export default WarrantyClaimInfo;
