import React from 'react';
import {EntityGridItem} from '@tehzor/ui-components';

interface IPrescriptionProps {
	className?: string;
	value: string;
}

const Prescription = ({className, value}: IPrescriptionProps) => (
	<EntityGridItem
		className={className}
		icon={<i className="tz-prescription-20"/>}
		label="Предписание"
		fullRow
	>
		<div style={{whiteSpace: 'pre-wrap'}}>{value}</div>
	</EntityGridItem>
);

export default Prescription;
