import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {IAddSpaceOwnerResponse, makeSpaceOwnerAddRequest} from '@src/api/backend/spaceOwner';
import {queryClient} from '@src/api/QueryClient';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';

export type IAddSpaceOwnerPayload = IAddSpaceOwnerResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddSpaceOwnerResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении собственника');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет нового собственника
 *
 * @param companyId id компании
 * @param fields измененные поля
 */
export const addSpaceOwner = (companyId: string, fields: ISavingSpaceOwner) =>
	createApiAction<IAddSpaceOwnerResponse>(request, success, failure, () => {
		void queryClient.invalidateQueries(restDictionariesQueryKeys.spaceOwners());
		return makeSpaceOwnerAddRequest(companyId, fields);
	});
