import React from 'react';
import {CellProps} from 'react-table';
import {IPreparedInternalAcceptance} from '../../interfaces/IPreparedInternalAcceptance';
import {Stats} from './Stats';

export const StatsCell = ({row}: CellProps<IPreparedInternalAcceptance>) => {
	const {stats} = row.original;
	if (!stats?.problems?.length && !stats?.inspections) {
		return null;
	}
	return (
		<Stats
			stats={stats}
			size="medium"
		/>
);
};
