import React, {memo} from 'react';
import {useFilteredDocumentsArray} from '@src/core/hooks/queries/documents';
import {EntityGridItem, InlineButton} from '@tehzor/ui-components';
import {DOCUMENTS_COUNT, EntityDocsItems} from './components/EntityDocsItems';
import {useDocumentsDialog} from '../DocumentsDialog';
import './EntityDocs.less';

interface IMobileEntityDocsProps {
	id: string;
}

const documentIcon = <i className="tz-document-20" />;

export const MobileEntityDocs = memo((props: IMobileEntityDocsProps) => {
	const {id} = props;

	const documents = useFilteredDocumentsArray(id);
	const totalCount = documents.length;
	const {documentsDialog, openDocumentsDialog} = useDocumentsDialog(documents);

	if (!totalCount) {
		return null;
	}

	const showButton = totalCount > DOCUMENTS_COUNT;

	return (
		<EntityGridItem
			label="Документы"
			icon={documentIcon}
			fullRow
		>
			<EntityDocsItems documents={documents} />

			{showButton && (
				<div className="entity-docs__more">
					<InlineButton
						type="accent"
						label={`Показать все (${totalCount})`}
						onClick={openDocumentsDialog}
					/>
				</div>
			)}

			{documentsDialog}
		</EntityGridItem>
	);
});
