import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {SelectPopup, FilterButton, TreeSelect} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {extractCategoriesSetsAsArray} from '@src/store/modules/dictionaries/categoriesSets/selectors';
import {extractAllCategoriesAsArray} from '@src/store/modules/dictionaries/categories/selectors';
import makeTreeData from '@src/store/modules/dictionaries/categoriesSets/utils/makeTreeData';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface ICategoriesSetFilterProps {
	objectId: string;
	categories?: string[];
}

export const CategoriesSetFilter = memo(({objectId, categories}: ICategoriesSetFilterProps) => {
	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedCategories, setSelectedCategories] = useState(categories);
	const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const allCategories = useAppSelector(extractAllCategoriesAsArray);
	const categoriesSets = useAppSelector(s => extractCategoriesSetsAsArray(s, objectId));

	const treeData = useMemo(
		() => makeTreeData(categoriesSets, allCategories),
		[categoriesSets, allCategories]
	);

	const {filteredData, expanded} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpandedCategories(expanded.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		dispatch({categories: selectedCategories});
		clearSearch();
	}, [selectedCategories, dispatch, clearSearch]);

	const handleClear = useCallback(() => {
		setSelectedCategories([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		dispatch({categories: undefined});
		setExpandedCategories([]);
		setSelectedCategories([]);
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedCategories(categories);
		clearSearch();
	}, [categories, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		const allCats = categoriesSets.map(set => allCategories[set.id].map(cat => cat.id)).flat();
		setSelectedCategories(allCats);
	}, [categoriesSets, allCategories, clearSearch]);

	useUpdateEffect(() => {
		setExpandedCategories([]);
		setSelectedCategories(categories);
	}, [categories]);

	return (
		<SelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedCategories?.length}
			count={selectedCategories?.length}
			onSelectAll={handleSelectAll}
			footer
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
					type="popup"
				/>
			)}
			trigger={(
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel('Виды работ', categories, treeData)}
					active={!!categories?.length}
					onClear={handleFullClear}
				/>
			)}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedCategories}
				onChange={setSelectedCategories}
				expandedValue={expandedCategories}
				onExpand={setExpandedCategories}
			/>
		</SelectPopup>
	);
});
