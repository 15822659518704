import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditWorkingGroupResponse,
	makeWorkingGroupEditRequest
} from '@src/api/backend/workingGroup';
import {addErrorToast} from '@src/utils/toasts';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';

export type IEditWorkingGroupPayload = IEditWorkingGroupResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditWorkingGroupResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении рабочей группы');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет группу
 *
 * @param id идентификатор группы
 * @param fields данные для добавления
 */
export const editWorkingGroup = (id: string, fields: ISavingWorkingGroup) =>
	createApiAction<IEditWorkingGroupResponse>(request, success, failure, () =>
		makeWorkingGroupEditRequest(id, fields));
