import React from 'react';
import {usePermissions} from '../../utils/usePermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import ProblemsExportDialog from '../ProblemsExportDialog';
import ProblemAddingDesktop from './ProblemAdding.desktop';

interface IDesktopActionsProps {
	objectId?: string;
}

export const ActionsDesktop = ({objectId}: IDesktopActionsProps) => {
	const user = useAppSelector(s => s.auth.profile);
	const permissions = usePermissions(user, objectId);

	return (
		<>
			{permissions.canAddProblem
				&& objectId !== undefined
				&& ((permissions.canAddCheck
					&& permissions.canAddCheckFromProblems)
					|| (permissions.canAddInternalAcceptance
						&& permissions.canAddInternalAcceptanceFromProblems)
					|| (permissions.canAddOwnerAcceptance
						&& permissions.canAddOwnerAcceptanceFromProblems)
					|| (permissions.canAddWarrantyClaim
						&& permissions.canAddWarrantyClaimFromProblems))
				&& (
					<ProblemAddingDesktop objectId={objectId}/>
				)}
			{permissions.canExport && <ProblemsExportDialog objectId={objectId}/>}
		</>
	);
};
