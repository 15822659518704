import React, {useCallback} from 'react';
import WarrantyClaimsPage from './WarrantyClaimsPage';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IWarrantyClaimsFiltersState} from '@src/store/modules/settings/pages/warrantyClaims/reducers';
import {
	changeFilters,
	clearFilters
} from '@src/store/modules/settings/pages/warrantyClaims/actions';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import {changeOffset} from '@src/store/modules/entities/warrantyClaims/actions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

const AllWarrantyClaimsPageWrap = (props: object) => {
	const dispatch = useAppDispatch();
	useAppHeader({
		title: 'Гарантийные обращения',
		mobileRightButtons: <AppUpdateMenu/>
	});

	const {filters} = useAppSelector(s => extractWarrantyClaimsPageSettings(s, 'all'));

	const applyFilters = useCallback((value: IWarrantyClaimsFiltersState) => {
		dispatch(changeFilters('all', value));
		dispatch(changeOffset('all', 0));
	}, []);

	const onClear = useCallback(() => {
		dispatch(clearFilters('all'));
	}, []);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<WarrantyClaimsPage
				key="all-warranty-claims"
				className="page-cont_extra-bottom-margin"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default AllWarrantyClaimsPageWrap;
