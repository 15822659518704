import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetStructuresResponse extends INormalizedData<IListStructure> {
	offset: number;
	limit: number;
	total: number;
}
/**
 * Возвращает список структур
 */
export const requestStructures = (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
) =>
	wsConnector.sendAuthorizedRequest<IGetStructuresResponse>('getStructures', {
		filters,
		sort: convertSortParam(sort),
	});
