import React, {useCallback, useState} from 'react';
import AddingInternalAcceptanceDialog from '../AddingInternalAcceptanceDialog';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';

interface IAddingInternalAcceptanceDialogProps {
	objectId: string;
	internalAcceptanceId?: string;
	links?: IInternalAcceptance['links'];
	stage?: ObjectStageIds;
	types?: IInternalAcceptanceAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	onSuccess?: () => void | Promise<void>;
	problemToCopyId?: string;
	createdBy?: string;
}

export function useAddingInternalAcceptanceDialog({
	objectId,
	internalAcceptanceId,
	links,
	stage,
	types,
	defaultProblemData,
	defaultInspectionData,
	onSuccess,
	problemToCopyId,
	createdBy
}: IAddingInternalAcceptanceDialogProps): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = stage ? (
		<AddingInternalAcceptanceDialog
			objectId={objectId}
			internalAcceptanceId={internalAcceptanceId}
			links={links}
			types={types}
			defaultProblemData={defaultProblemData}
			defaultInspectionData={defaultInspectionData}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
			problemToCopyId={problemToCopyId}
			createdBy={createdBy}
		/>
	) : null;

	return [dialog, open];
}
