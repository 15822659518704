import React from 'react';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';

export function getStyle(status: IProblemStatus, value: number, total: number): React.CSSProperties {
	const width = Math.round((value / total) * 10000) / 100;
	return {
		width: `${width}%`,
		backgroundColor: status.color
	};
}
