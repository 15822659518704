import React, {useEffect} from 'react';
import './ProblemsPage.less';
import ProblemsPageCounter from './components/ProblemsPageCounter';
import {LoadingPanel} from '@tehzor/ui-components';
import classNames from 'classnames';
import ProblemsTable from '@src/pages/ProblemsPage/components/table/ProblemsTable';
import {clearExpiration} from '@src/store/modules/entities/problems/actions';
import ProblemsFilters from './components/filters/ProblemsFilters';
import useAsync from 'react-use/lib/useAsync';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {extractProblemsData} from '@src/store/modules/entities/problems/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getSpacesSchema} from '@src/store/modules/entities/spaces/actions';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import {ActionsDesktop} from './components/actions/Actions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import {useIsFetching} from '@tanstack/react-query';
import {ProblemsSearch} from './components/ProblemsSearch';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {getMarkers} from '@src/store/modules/entities/markers/actions';
import {useProblemSettings} from './utils/useProblemSettings';
import {useParams} from 'react-router-dom';

interface IProblemsPageProps {
	className?: string;
}

const ProblemsPage = ({className}: IProblemsPageProps) => {
	useScrollRestoration();
	const isFetching = useIsFetching({queryKey: problemsQueryKeys.list()});
	const {objectId} = useParams<{objectId?: string}>();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const problems = useAppSelector(s => extractProblemsData(s, objectId));
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();

	const {state, isLocal} = useEntitiesFiltersCtx<IProblemsFiltersState>();

	const problemSettings = useProblemSettings(objectId);

	const loadingState = useAsync(async () => {
		if (networkStatus) {
			if (objectId) {
				await dispatch(getSpacesSchema(objectId));
			}

			if (problems.allIds.length) {
				await dispatch(getMarkers({objectId, problemIds: problems.allIds}));
			}
		}
	}, [objectId, networkStatus, state, problems.allIds]);

	// для того чтобы после перехода со страницы фильтров отрабатывали фильтры из пути
	useEffect(
		() => () => {
			if (isLocal) {
				void dispatch(clearExpiration(objectId));
			}
		},
		[isLocal, dispatch, objectId]
	);

	const actions = <ActionsDesktop objectId={objectId}/>;

	return (
		<LoadingPanel
			className="problems-page__loading-panel"
			active={loadingState.loading || !!isFetching}
		>
			<div className={classNames('page-cont', 'problems-page', className)}>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}>
						{isDesktop && actions}
					</ObjectPageBreadcrumbs>
				) : (
					isDesktop && <AllPageBreadcrumbs>{actions}</AllPageBreadcrumbs>
				)}

				<div className="problems-page__filters-bar">
					{!isDesktop && (
						<div className="problems-page__search">
							<ProblemsSearch/>
						</div>
					)}
					<ProblemsFilters
						objectId={objectId}
						settings={problemSettings}
					/>
				</div>
				<ProblemsPageCounter objectId={objectId}/>
				<ProblemsTable
					objectId={objectId}
					settings={problemSettings}
				/>
			</div>
		</LoadingPanel>
	);
};

export default ProblemsPage;
