import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeProblemReplyDeleteRequest} from '@src/api/backend/problemReply';
import {IDeleteProblemCommentPayload} from '../../problemComment/actions';

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, problemId: string, commentId: string) => {
	addInfoToast('Удалено', 'Ответ успешно удалён');
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			problemId,
			commentId
		} as IDeleteProblemCommentPayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении ответа');
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет ответ на нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param commentId id комментария
 */
export const deleteProblemReply = (objectId: string, problemId: string, commentId: string) =>
	createApiAction(
		request,
		() => success(objectId, problemId, commentId),
		failure,
		() => makeProblemReplyDeleteRequest(objectId, commentId)
	);
