import {PrivateRoute} from '@src/containers/PrivateRoute';
import {ExportTemplateEditingPage} from '@src/pages/manage/ExportTemplateEditingPage';
import {ExportTemplatesPage} from '@src/pages/manage/ExportTemplatesPage/ExportTemplatesPage';
import React from 'react';

export const manageExportTemplatesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<ExportTemplatesPage/>}/>
	},
	{
		path: 'add',
		element: <PrivateRoute element={<ExportTemplateEditingPage/>}/>
	},
	{
		path: ':exportTemplateId',
		element: <PrivateRoute element={<ExportTemplateEditingPage/>}/>
	}
];
