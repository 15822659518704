import React, {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheck} from '@src/store/modules/entities/check/selectors';
import SelectionActions from '../selection/SelectionActions';

interface ICustomSelectionRowProps extends ISelectionRowProps<ICheckEntity> {
	objectId: string;
	checkId: string;
}

const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {objectId, checkId, selectedFlatRows, toggleAllRowsSelected} = props;
	const check = useAppSelector(extractCheck);

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				objectId={objectId}
				checkId={checkId}
				check={check}
				selectedEntities={selectedFlatRows.map(item => item.original)}
				clearSelection={clearSelection}
			/>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};

export default SelectionRow;
