import React, {useCallback, useRef, useState} from 'react';
import './Dialog.less';
import classNames from 'classnames';
import IconButton from '../../buttons/IconButton';
import {makeAnimations} from './utils/makeAnimations';
import DialogBase from '../DialogBase';
import {useIsBeforeDesktop, useIsBeforeTablet} from '../../../utils/mediaQueries';
import {convertClassNames} from '../../../utils/convertClassNames';
import {ScrollBlockContext} from '../../../context/ScrollBlockContext';
import {isIOS} from '../../../utils/isIOS';
import {useWindowSize} from 'react-use';

export interface IDialogProps {
	className?:
		| string
		| {
				root?: string;
				portal?: string;
				overlay?: string;
				layer?: string;
				content?: string;
				header?: string;
				title?: string;
				body?: string;
				footer?: string;
		  };
	style?: React.CSSProperties;
	children?: React.ReactNode;
	isOpen: boolean;
	title?: React.ReactNode;
	footer?: React.ReactNode;
	closeButton?: React.ReactElement<{className?: string, onClick?: (event: React.MouseEvent) => void}>;
	appRoot?: HTMLElement;
	shouldFocusAfterRender?: boolean;
	shouldCloseOnOverlayClick?: boolean;
	shouldCloseOnEsc?: boolean;
	// shouldReturnFocusAfterClose?: boolean;
	shouldDisableScroll?: boolean;
	hideCloseButton?: boolean;
	useContentOpenAnimation?: boolean;
	useContentCloseAnimation?: boolean;
	fullScreen?: boolean;
	fullScreenOnMobile?: boolean;
	fullScreenOnTablet?: boolean;
	disableScrollBlock?: boolean;

	onRequestClose?: (event?: React.SyntheticEvent) => void;
	onBeforeOpen?: () => void;
	onAfterOpen?: () => void;
	onBeforeClose?: () => void;
	onAfterClose?: () => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const Dialog = (props: IDialogProps) => {
	const {
		className,
		children,
		title,
		footer,
		closeButton,
		hideCloseButton,
		useContentOpenAnimation,
		useContentCloseAnimation,
		fullScreen,
		fullScreenOnMobile,
		fullScreenOnTablet,
		disableScrollBlock,
		onRequestClose,
		...rest
	} = props;

	const bodyElement = useRef<HTMLDivElement>(null);
	const isMobile = useIsBeforeTablet();
	const isTablet = useIsBeforeDesktop();
	const getScrollableElement = useCallback(() => bodyElement.current, []);

	const btnVisible = onRequestClose && !hideCloseButton;
	const classes = convertClassNames(className);

	const [isScrollBlocked, setScrollBlocked] = useState(false);

	const {height: windowHeight} = useWindowSize();

	let closeBtn;
	if (btnVisible) {
		closeBtn = closeButton ? (
			React.cloneElement(closeButton, {
				className: classNames(closeButton.props.className, 'dialog__close-btn'),
				onClick: onRequestClose
			})
		) : (
			<IconButton
				className="dialog__close-btn"
				onClick={onRequestClose}
			>
				<i className="tz-close-24"/>
			</IconButton>
		);
	}

	const isFinalScrollBlocked = disableScrollBlock ? false : isScrollBlocked;

	return (
		<DialogBase
			{...rest}
			noBodyScrollLock={isIOS}
			className={{
				overlay: classNames(
					'root-overlay',
					{
						'dialog-overlay_transparent':
							fullScreen || (fullScreenOnMobile && isMobile) || (fullScreenOnTablet && isTablet)
					},
					classes.overlay
				),
				dialog: classNames(
					'dialog',
					{dialog_fs: fullScreen},
					{dialog_fs_mobile: fullScreenOnMobile && isMobile},
					{dialog_fs_tablet: fullScreenOnTablet && isTablet},
					classes.root
				),
				layer: classNames('dialog__layer', classes.layer),
				content: classNames('dialog__content', classes.content)
			}}
			contentStyle={isIOS && isMobile ? {maxHeight: `${windowHeight}px`} : undefined}
			animations={makeAnimations(
				useContentOpenAnimation,
				useContentCloseAnimation,
				fullScreen || (fullScreenOnMobile && isMobile) || (fullScreenOnTablet && isTablet)
			)}
			onRequestClose={onRequestClose}
			getScrollableElement={getScrollableElement}
		>
			{closeBtn}

			{title !== undefined && (
				<div
					className={classNames(
						'dialog__header',
						{'dialog__header_margin-right': btnVisible},
						classes.header
					)}
				>
					<div className={classNames('dialog__header-title', classes.title)}>{title}</div>
				</div>
			)}
			<ScrollBlockContext.Provider value={setScrollBlocked}>
				{children !== undefined && (
					<div
						className={classNames('dialog__body', classes.body, {
							'dialog__body_scroll-blocked': isFinalScrollBlocked
						})}
						ref={bodyElement}
					>
						{children}
					</div>
				)}
			</ScrollBlockContext.Provider>
			{footer !== undefined && <div className={classNames('dialog__footer', classes.footer)}>{footer}</div>}
		</DialogBase>
	);
};

Dialog.displayName = 'Dialog';

export default Dialog;
