import React from 'react';
import {format} from 'date-fns';
import classNames from 'classnames';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';

export interface IDateProps {
	value: number;
	label?: string;
}

export const SingleDate = ({value, label}: IDateProps) => {
	const expired = value < new Date().setHours(0, 0, 0, 0);
	const date = format(value, dateFormat);
	return (
		<div className="dates__date">
			<div className="dates__label">{label}</div>
			<div className="dates__value-container">
				{expired && <i className="tz-alarm-clock dates__icon"/>}
				<div className={classNames('dates__value', {dates__value_expired: expired})}>
					{date}
				</div>
			</div>
		</div>
	);
};
