import React from 'react';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {DesktopWorkAcceptanceAddBtn} from './WorkAcceptanceAddBtn.desktop';

interface IDesktopActionsProps {
	objectId: string;
}

export const DesktopActions = ({objectId}: IDesktopActionsProps) => {
	const permissions = useWorkAcceptancesPermissions(objectId);
	const object = useAppSelector(s => extractCurrentTreeObject(s, objectId));
	const lastObject = object ? !object.children?.length : false;

	return (
		<>{permissions.canAdd && lastObject ? <DesktopWorkAcceptanceAddBtn objectId={objectId}/> : null}</>
	);
};
