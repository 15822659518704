import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IAddStructureResponse, makeStructureAddRequest} from '@src/api/backend/structure';

export type IAddStructurePayload = IAddStructureResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddStructureResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при добавлении структуры');
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет структуру
 *
 * @param objectId id объекта
 * @param fields измененные поля
 */
export const addStructure = (objectId: string, fields: ISavingStructure = {}) =>
	createApiAction<IAddStructureResponse>(request, success, failure, () =>
		makeStructureAddRequest(objectId, fields));
