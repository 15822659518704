import * as types from '../constants';

/**
 * Изменяет смещение списка
 *
 * @param objectId id объекта
 * @param offset смещение
 */
export const changeOffset = (objectId: string, offset: number) => ({
	type: types.CHANGE_OFFSET,
	payload: {
		objectId,
		offset
	}
});

/**
 * Изменяет выбранные строки в таблице
 *
 * @param objectId id объекта
 * @param selectedRows массив id выбранных строк
 */
export const changeSelectedRows = (objectId: string, selectedRows: string[]) => ({
	type: types.CHANGE_SELECTED_ROWS,
	payload: {
		objectId,
		selectedRows
	}
});

/**
 * Очищает параметр expiration
 */
 export const clearExpiration = (objectId = 'all') => ({
	type: types.CLEAR_EXPIRATION,
	payload: {objectId}
});

export * from './get';
export * from './export';
export * from './getSelectionChecks';
