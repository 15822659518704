import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {queryClient} from '@src/api/QueryClient';

export const refetchCache = async (queryKeys: string[][], objects?: string[]) => {
	if (queryKeys.length && objects) {
		for (const queryKey of queryKeys) {
			if (queryKey.join() === plansQueryKeys.lists().join()) {
				await queryClient.refetchQueries({
					queryKey: plansQueryKeys.list(objects),
					exact: true
				});
				continue;
			}
			await queryClient.refetchQueries({queryKey, exact: true});
		}
	}
	// Кеширование помещений
	await queryClient.refetchQueries([
		...spacesQueryKeys.list(),
		{objects},
		undefined,
		undefined,
		1000000
	]);
	await queryClient.refetchQueries([...checkRecordsQueryKeys.list(), undefined, objects]);
};
