import React from 'react';
import getDataTransferFiles from '../../../utils/getDataTransferItems';
import {extendRawFiles} from '@tehzor/tools/utils/extendRawFile';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';

interface IFilesPickerProps {
	/**
	 * Поддерживаемые типы файлов
	 */
	accept?: string;
	/**
	 * Множественный выбор
	 */
	multiple?: boolean;

	/**
	 * Функция, вызываемая при при выборе файлов
	 */
	onChange?(files: IRawFile[]): void;
}

/**
 * Компонент выбора файлов
 */
class FilesPicker extends React.PureComponent<IFilesPickerProps> {
	static defaultProps: Partial<IFilesPickerProps> = {
		accept: '',
		multiple: false
	};

	private readonly _input: React.RefObject<HTMLInputElement> = React.createRef();

	/**
	 * Открывает диалог выбора файлов
	 */
	open = () => {
		if (this._input.current) {
			this._input.current.value = '';
			this._input.current.click();
		}
	};

	render() {
		const {accept, multiple} = this.props;

		return (
			<input
				type="file"
				accept={accept}
				multiple={multiple}
				style={{display: 'none'}}
				autoComplete="off"
				ref={this._input}
				onChange={this._handleInputChange}
			/>
		);
	}

	/**
	 * Обработка выбранных файлов
	 *
	 * @param event событие
	 */
	private _handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		const {onChange} = this.props;
		if (onChange) {
			const files = getDataTransferFiles(event);
			onChange(extendRawFiles(files));
		}
	};
}

export default FilesPicker;
