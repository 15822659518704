import React from 'react';
import MediaQuery from 'react-responsive';
import * as variables from './variables';

interface IProps {
	children: React.ReactNode;
	orientation?: 'landscape' | 'portrait';
}

export const LargeMobile = (props: IProps) => (
	<MediaQuery
		{...props}
		minWidth={variables.largeMobileMin}
	/>
);

export const BeforeLargeMobile = (props: IProps) => (
	<MediaQuery
		{...props}
		maxWidth={variables.largeMobileMin - 1}
	/>
);

export const Tablet = (props: IProps) => (
	<MediaQuery
		{...props}
		minWidth={variables.tabletMin}
	/>
);

export const BeforeTablet = (props: IProps) => (
	<MediaQuery
		{...props}
		maxWidth={variables.tabletMin - 1}
	/>
);

export const LargeTablet = (props: IProps) => (
	<MediaQuery
		{...props}
		minWidth={variables.largeTabletMin}
	/>
);

export const BeforeLargeTablet = (props: IProps) => (
	<MediaQuery
		{...props}
		maxWidth={variables.largeTabletMin - 1}
	/>
);

export const Desktop = (props: IProps) => (
	<MediaQuery
		{...props}
		minWidth={variables.desktopMin}
	/>
);

export const BeforeDesktop = (props: IProps) => (
	<MediaQuery
		{...props}
		maxWidth={variables.desktopMin - 1}
	/>
);

export const LargeDesktop = (props: IProps) => (
	<MediaQuery
		{...props}
		minWidth={variables.largeDesktopMin}
	/>
);

export const BeforeLargeDesktop = (props: IProps) => (
	<MediaQuery
		{...props}
		maxWidth={variables.largeDesktopMin - 1}
	/>
);

export const Landscape = (props: { children: React.ReactNode }) => (
	<MediaQuery
		{...props}
		orientation="landscape"
	/>
);

export const Portrait = (props: { children: React.ReactNode }) => (
	<MediaQuery
		{...props}
		orientation="portrait"
	/>
);
