import React, {memo} from 'react';
import {IconButton} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {CellProps} from 'react-table';
import {useAsyncFn} from 'react-use';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';
import {useDeleteLegal} from '@src/core/hooks/mutations/legals/useDeleteLegal';

const deleteIcon = <i className="tz-delete" />;

export const DeleteCell = memo(({row}: CellProps<IPreparedLegal>) => {
	const canDelete = row.original.canDelete;
	const deleteLegal = useDeleteLegal();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить юрлицо',
		'Вы уверены, что хотите удалить юрлицо?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteLegal(row.original.id);
		}
	}, [row.original]);

	return canDelete ? (
		<>
			<IconButton
				className="legals__cell-delete"
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	) : null;
});
