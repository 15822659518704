import {queryClient} from '@src/api/QueryClient';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';

export const updateCachedState = async <E>(
	queryKey: {
		detail: (key?: string) => string[];
		localList: () => string[];
	},
	key: string,
	transferStatus: OfflineDataTransferStatus
) => {
	queryClient.setQueriesData(queryKey.detail(key), (entity: E) => ({
		...entity,
		transferStatus
	}));
	await queryClient.refetchQueries(queryKey.localList());
};
