import React, {useCallback, useEffect} from 'react';
import {ConfirmDialog} from '@tehzor/ui-components';
import {isServiceWorkerSupported} from '@src/core/offlineMode/utils/isServiceWorkerSupported';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	changeAppUpdateNewVersion,
	toggleAppUpdateDialogVisibility
} from '@src/store/modules/app/update/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SW_UPDATED_EVENT_TYPE} from '@tehzor/tools/contracts/appUpdateServiceWorker/constants';
import {IServiceWorkerUpdatedEvent} from '@tehzor/tools/contracts/appUpdateServiceWorker/events';
import config from '@src/core/config';
import {IButtonProps} from '@tehzor/ui-components/src/components/buttons/Button';

const reload = () => {
	window.location.reload();
};
const acceptBtnProps: IButtonProps = {type: 'accent-blue'};

export const AppUpdateDialog = () => {
	const {newVersion, dialogVisible} = useAppSelector(s => s.app.update);
	const cacheStatus = useAppSelector(s => s.offlineMode.cacheStatus);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (isServiceWorkerSupported()) {
			const handleMessage = (event: MessageEvent) => {
				if (typeof event.data === 'object' && event.data.type === SW_UPDATED_EVENT_TYPE) {
					const {version} = event.data as IServiceWorkerUpdatedEvent;

					console.log(
						'New version event from service worker',
						version,
						config.appVersion
					);

					if (version && version !== config.appVersion) {
						dispatch(changeAppUpdateNewVersion(version));
						dispatch(toggleAppUpdateDialogVisibility(true));
					}
				}
			};

			navigator.serviceWorker.addEventListener('message', handleMessage);

			return () => {
				navigator.serviceWorker.removeEventListener('message', handleMessage);
			};
		}
		return undefined;
	}, []);

	const reject = useCallback(() => {
		dispatch(toggleAppUpdateDialogVisibility(false));
	}, [cacheStatus]);

	return (
		<ConfirmDialog
			isOpen={dialogVisible}
			title={`Вышла новая версия ${newVersion}`}
			message="Перезагрузить приложение для обновления?"
			acceptBtnLabel="Да"
			rejectBtnLabel="Нет"
			acceptBtnProps={acceptBtnProps}
			onAccept={reload}
			onReject={reject}
		/>
	);
};
