import React from 'react';
import './ProblemFixCell.less';
import {CellProps} from 'react-table';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {FixDateDisplayValue} from '@src/components/FixDateDisplayValue/FixDateDisplayValue';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {EntityInfo, UserInfo} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';

const ProblemFixCell = ({row}: CellProps<{data: IProblem}>) => {
	const {status, plannedFixDate, respUsers, activeGroup, critical, actualFixDate}
		= row.original.data;
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const groupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);
	const isDesktop = useIsDesktop();

	return (
		<>
			{plannedFixDate ? (
				<FixDateDisplayValue
					className="problem-fix-cell-date"
					plannedFixDate={plannedFixDate}
					status={status}
					critical={critical}
					actualFixDate={actualFixDate}
				/>
			) : null}

			{respUsers?.length ? (
				respUsers.map(userId => (
					<UserInfo
						key={userId}
						className="problem-fix-cell-user"
						user={usersMap[userId]}
						avatarSize={isDesktop ? '40' : '34'}
						avatarColoring="text"
					/>
				))
			) : activeGroup ? (
				<EntityInfo
					className="problem-fix-cell-user"
					title={groupsMap[activeGroup]?.name}
					icon={<i className="tz-users-24"/>}
					iconColor="#718198"
					backgroundColor="#FFDEB8"
				/>
			) : null}
		</>
	);
};

export default ProblemFixCell;
