import {queryClient} from '@src/api/QueryClient';

export const resumeMutations = () => {
	// После маунта хранилища проверим есть ли не завершенные мутации,
	// переводим сущности в статус передачи и запускаем их
	const mutationCache = queryClient.getMutationCache();
	const mutationsToResume = mutationCache.findAll({
		predicate: mutation => mutation.state.status !== 'success'
	});
	mutationsToResume.map(mutation => {
		mutation.setState({
			...mutation.state,
			isPaused: true,
			status: 'loading',
			failureCount: 0,
			error: null,
			failureReason: null
		});
		return mutation.execute();
	});
};
