import React, {useEffect, useRef, useState} from 'react';
import './EditableProblemComment.less';
import classNames from 'classnames';
import TextInput from './components/TextInput';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {
	convertToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {
	convertToSave,
	isEdited,
	useEditableProblemCommentState
} from '@src/core/hooks/states/useEditableProblemCommentState';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	addProblemComment,
	editProblemComment
} from '@src/store/modules/entities/problemComment/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import Attachments from '../editableFields/Attachments';
import {CommentInput} from '@tehzor/ui-components';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

interface IEditableProblemCommentProps {
	className?: string;
	style?: React.CSSProperties;
	objectId: string;
	problemId: string;
	commentId?: string;
	links?: IProblemComment['links'];
	parentId?: string;

	onClearReference: () => void;
}

const EditableProblemComment = ({
	className,
	style,
	objectId,
	problemId,
	commentId,
	links,
	parentId,
	onClearReference
}: IEditableProblemCommentProps) => {
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const comment = useAppSelector(s =>
		(commentId ? s.pages.problem.comments.byId[commentId] : undefined));
	const dispatch = useAppDispatch();
	const [editingState, editingDispatch] = useEditableProblemCommentState(comment);
	const [uploadingFiles, upFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);
	const textInputRef = useRef<CommentInput>(null);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, comment) || isFilesExist(uploadingFiles.value));
	}, [editingState, uploadingFiles.value, comment]);

	// Установка фокуса на поле ввода при создании компонента,
	// если изначально были переданы id комментария (для редактирования)
	// или его родителя (для добавления вложенного)
	useEffect(() => {
		if (commentId || parentId) {
			if (textInputRef.current) {
				textInputRef.current.focus();
			}
		}
	}, []);

	// Установка фокуса на поле ввода при изменении ссылок на добавляемый/редактируемый комментарий
	useUpdateEffect(() => {
		if (textInputRef.current) {
			textInputRef.current.focus();
		}
	}, [commentId, parentId]);

	useUpdateEffect(() => {
		editingDispatch({type: 'reset', entity: comment});
		upFilesDispatch({type: 'reset'});
	}, [commentId]);

	const [savingState, save] = useAsyncFn(async () => {
		const files = await waitUploading();
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, comment)
			&& (!isFilesExist(files) || someFilesHaveError(files))
		) {
			return;
		}

		if (!networkStatus) {
			// Локальное сохранение
			const savingData = convertToSave(editingState, comment, true);
			savingData.newAttachments = convertToLocalSave(files);
			// TODO Локальное сохранение
		} else {
			const savingData = convertToSave(editingState, comment, true);
			savingData.newAttachments = convertFilesToSave(files);
			savingData.parentId = parentId;

			try {
				if (commentId === undefined) {
					await dispatch(addProblemComment(objectId, problemId, links, savingData));
				} else {
					await dispatch(editProblemComment(objectId, commentId, savingData));
				}

				editingDispatch({type: 'reset'});
				upFilesDispatch({type: 'reset'});
				onClearReference();
			} catch (error) {
				console.log(error);
				// TODO Локальное сохранение
			}
		}
	}, [
		editingState,
		commentId,
		parentId,
		networkStatus,
		objectId,
		problemId,
		links,
		onClearReference
	]);

	return (
		<div
			className={classNames('editable-problem-comment', className)}
			style={style}
		>
			<TextInput
				description={editingState.description}
				editingDispatch={editingDispatch}
				uploadingFilesDispatch={upFilesDispatch}
				isSaving={savingState.loading}
				onSave={save}
				ref={textInputRef}
			/>

			{(editingState.attachments.length > 0 || uploadingFiles.value.length) > 0 && (
				<Attachments
					className={{
						root: 'editable-problem-comment__attachments',
						scrollArea: 'editable-problem-comment__attachments-scroll-area'
					}}
					imagesTitle="Фото комменатрия"
					attachmentsDestination={AttachmentFileDestination.COMMENT_OR_REPLY}
					entityId={commentId}
					attachments={editingState.attachments}
					uploadingFiles={uploadingFiles.value}
					editingDispatch={editingDispatch}
					canDraw
					uploadingFilesDispatch={upFilesDispatch}
					disabled={savingState.loading}
				/>
			)}
		</div>
	);
};

export default EditableProblemComment;
