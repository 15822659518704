import React, {useCallback} from 'react';
import './OfflineModeBar.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineStatus} from '@src/store/modules/offlineMode/selectors';
import {offlineModeTitles} from '@src/core/offlineMode/utils/offlineModeTitles';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleOfflineDialogVisibility} from '@src/store/modules/app/offlineDialog/actions';
import {cacheStatus} from './utils/cacheStatus';

export const OfflineModeBar = () => {
	const status = useAppSelector(extractOfflineStatus);
	const queriesFetchStatus = cacheStatus();
	const dispatch = useAppDispatch();

	const openDialog = useCallback(() => {
		dispatch(toggleOfflineDialogVisibility(true));
	}, []);

	return (
		<>
			<div
				className="offline-bar"
				onClick={openDialog}
			>
				{offlineModeTitles[queriesFetchStatus] || offlineModeTitles[status]}
			</div>
		</>
	);
};
