import React from 'react';
import './ResponsibleTask.less';
import {EntityGridItem, UserInfo, WorkingGroupInfo} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {DelegationButton} from './components/DelegationButton';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';

interface IResponsibleTaskProps {
	task: IEnrichedTask;
}

export const ResponsibleTask = ({task}: IResponsibleTaskProps) => {
	const {respUsers, activeGroup} = task;

	const usersMap = useAppSelector(s => s.dictionaries.users.byId);
	const groupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);

	const taskActiveGroup = activeGroup !== undefined && groupsMap?.[activeGroup];

	const {canEditResponsible} = useTasksPermissions(task.objectId.id);

	return (
		<EntityGridItem
			className="responsible-task-view-field"
			icon={<i className="tz-user-20"/>}
			label="Ответственные"
			fullRow
		>
			<div className="responsible-task-view-field__body">
				<div className="responsible-view-field__list">
					{activeGroup !== undefined && respUsers?.length ? (
						<WorkingGroupInfo
							className="responsible-task-view-field__group"
							name={taskActiveGroup ? taskActiveGroup?.name : ''}
							leader={taskActiveGroup ? usersMap[taskActiveGroup?.leader] : undefined}
						/>
					) : null}

					{respUsers?.length ? (
						respUsers.map(userId => (
							<UserInfo
								key={userId.id}
								className="responsible-task-view-field__user"
								user={userId}
								avatarSize="40"
								avatarColoring="text"
							/>
						))
					) : activeGroup ? (
						<WorkingGroupInfo
							className="responsible-task-view-field__group"
							name={taskActiveGroup ? taskActiveGroup?.name : ''}
							leader={taskActiveGroup ? usersMap[taskActiveGroup?.leader] : undefined}
						/>
					) : null}
				</div>
			</div>

			<div className="responsible-task-view-field__buttons">
				{canEditResponsible ? (
					<DelegationButton
						task={task}
						onlyActiveGroup
					/>
) : null}
			</div>
		</EntityGridItem>
	);
};
