import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as itemTypes from '../../entities/checkItem/constants';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting, handleLoadableEntityReset,
	ILoadableEntitiesState
} from '@tehzor/tools/core/storeHelpers/reducers';
import {normalizeData} from '@tehzor/tools/utils/normalizeData';
import {CLEAR_BEFORE_UPDATE} from '../../app/updateData/constants';

export type ICheckItemsState = ILoadableEntitiesState<ICheckItem>;

export const checkItems = createReducer<ICheckItemsState>(
	getLoadableEntitiesInitialState<ICheckItem>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<ICheckItem>(),
		[itemTypes.ADD_SUCCESS]: (states, {payload}: {payload: {checkListId: string}}) => {
			// const state = states[payload.checkListId];
			// state.loadedAt = undefined;
		},
		[itemTypes.EDIT_SUCCESS]: (states, {payload}: {payload: {checkListId: string}}) => {
			// const state = states[payload.checkListId];
			// state.loadedAt = undefined;
		},
		[types.ADD_BATCH_SUCCESS]: (
			state,
			{payload}: {payload: {items: ICheckItem[], checkListId: string}}
		) => {
			const normalizedItems = normalizeData(payload.items);

			return {
				...state,
				allIds: state.allIds.concat(normalizedItems.allIds),
				byId: {...state.byId, ...normalizedItems.byId}
			};
		},
		[types.EDIT_BATCH_SUCCESS]: (
			state,
			{payload}: {payload: {items: ICheckItem[], checkListId: string}}
		) => {
			const allIds = [];
			const byId = {};

			for (const id of state.allIds) {
				if (state.byId[id]?.checkListId !== payload.checkListId) {
					allIds.push(id);
					byId[id] = state.byId[id];
				}
			}

			const normalizedItems = normalizeData(payload.items);

			return {
				...state,
				allIds: allIds.concat(normalizedItems.allIds),
				byId: {...byId, ...normalizedItems.byId}
			};
		},
		[itemTypes.DELETE_SUCCESS]: handleLoadableEntityReset<ICheckItem>(),
		[CLEAR_STORE]: () => ({}),
		[CLEAR_BEFORE_UPDATE]: () => ({})
	}
);
