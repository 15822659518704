import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import * as structureTypes from '@src/store/modules/entities/structure/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as settingsTypes from '@src/store/modules/settings/pages/structures/constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ILoadedEntityState} from '@tehzor/tools/utils/reducersHandlers';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {IGetStructuresPayload} from '../actions';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export interface IStructuresListPageState extends ILoadedEntityState<IListStructure> {
	offset: number;
	total: number;
	selected: string[];
}

export type IStructuresListPagesState = Record<string, IStructuresListPageState>;

export const getInitialStateForPage = (): IStructuresListPageState => ({
	byId: {},
	allIds: [],
	loaded: false,
	offset: 0,
	total: 0,
	selected: []
});

const clearPage = (state: IStructuresListPagesState, {payload}: {payload: {objectId: string}}) => {
	if (state[payload.objectId]) {
		state[payload.objectId].loaded = false;
		state[payload.objectId].offset = 0;
		state[payload.objectId].total = 0;
	}
	if (state.all) {
		state.all.loaded = false;
		state.all.offset = 0;
		state.all.total = 0;
	}
};

const applyStructureUpdate = (state: IStructuresListPagesState, {payload}: {payload: IStructure}) => {
	const page = state[payload.objectId];
	if (page && page.allIds.includes(payload.id)) {
		page.byId[payload.id] = {...page.byId[payload.id], ...payload};
	}
};

export const lists = createReducer<IStructuresListPagesState>(
	{},
	{
		[types.GET_LIST_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_LIST_SUCCESS]: (state, {payload}: {payload: IGetStructuresPayload}) => {
			const page = state[payload.objectId];
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.offset = payload.offset;
			page.total = payload.total;
			page.loaded = true;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string, offset: number}}
		) => {
			const page = state[payload.objectId];
			if (page) {
				page.offset = payload.offset;
				page.loaded = false;
			}
		},
		[types.CHANGE_SELECTED]: (
			state,
			{payload}: {payload: {objectId: string, value: string[]}}
		) => {
			if (state[payload.objectId]) {
				state[payload.objectId].selected = payload.value;
			}
		},
		// При манипуляциях с фильтрами, сортировкой, пагинацией необходимо сбросить loaded для игнорирования кеша
		[settingsTypes.CHANGE_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].loaded = false;
				state[payload.objectId].offset = 0;
				state[payload.objectId].total = 0;
			}
		},
		[settingsTypes.CLEAR_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].loaded = false;
				state[payload.objectId].offset = 0;
				state[payload.objectId].total = 0;
			}
		},
		[settingsTypes.CHANGE_SORT]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].loaded = false;
			}
		},
		[settingsTypes.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: {objectId: string}}) => {
			if (state[payload.objectId]) {
				state[payload.objectId].loaded = false;
			}
		},
		[structureTypes.ADD_SUCCESS]: clearPage,
		[structureTypes.EDIT_SUCCESS]: applyStructureUpdate,
		[problemTypes.ADD_SUCCESS]: clearPage,
		[problemTypes.EDIT_SUCCESS]: clearPage,
		[problemTypes.DELETE_SUCCESS]: clearPage,
		[CLEAR_STORE]: () => ({})
	}
);
