import * as types from '../constants';
import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISendResponse} from '@src/interfaces/ISendResponse';
import {makeExportedOwnerAcptSendRequest} from '@src/api/backend/ownerAcceptance';

const request = () => ({type: types.SEND_REQUEST});

const success = () => {
	addInfoToast('Отправлено', 'Документ успешно отправлен');
	return {type: types.SEND_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при отправке на email');
	return {
		type: types.SEND_FAILURE,
		payload: error
	};
};

/**
 * Отправляет передачу собственнику на email
 *
 * @param email email
 * @param templateId id шаблона экспорта
 * @param objectId id шаблона экспорта
 * @param acceptanceId id передачи
 * @param problems нарушения
 * @param createDocument флаг создания документа
 */
export const sendExportedOwnerAcceptance = (
	email: string,
	templateId: string,
	objectId: string,
	acceptanceId: string,
	problems?: string[],
	createDocument?: boolean
) =>
	createApiAction<ISendResponse>(request, success, failure, () =>
		makeExportedOwnerAcptSendRequest(email, templateId, objectId, acceptanceId, problems, createDocument));
