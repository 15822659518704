import React from 'react';
import './ProblemInfoMobileCell.less';
import {PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import classNames from 'classnames';
import {TableToggleRowsSelectedProps} from 'react-table';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {ProblemStatus} from '../ProblemStatusCell';
import {FixDateDisplayValue} from '@src/components/FixDateDisplayValue/FixDateDisplayValue';
import ProblemReply from '../ProblemReply';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useProblemAndReplyViewer} from '@src/core/hooks/imagesViewers/mobile/useProblemAndReplyViewer';
import {ProblemInfo} from '@src/components/tableCells/ProblemInfo/ProblemInfo';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import {useResponsibleDisplayValue} from '@src/core/hooks/useResponsibleDisplayValue';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';

interface IProblemInfoMobileProps {
	problem: IProblem;
	depth: number;
	subTitle?: React.ReactNode;
	replies?: Record<string, IProblemComment>;
	allProblemTags?: Record<string, IProblemTag>;
	getToggleRowSelectedProps: (
		props?: Partial<TableToggleRowsSelectedProps>
	) => TableToggleRowsSelectedProps;
}

export const ProblemInfoMobile = ({
	problem,
	depth,
	subTitle,
	replies,
	allProblemTags,
	getToggleRowSelectedProps
}: IProblemInfoMobileProps) => {
	const {
		status,
		plannedFixDate,
		respUsers,
		activeGroup,
		description,
		critical,
		problemTags,
		actualFixDate
	} = problem;
	const reply = replies?.[problem.id];

	const [problemImagesAttachments] = useSplitAttachments(problem);
	const problemImages = useAttachmentsImages(problemImagesAttachments);
	const problemImagesData = useAttachmentsImagesObjects(problemImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(reply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);

	const [imagesViewer, openProblemImage, openReplyImage] = useProblemAndReplyViewer(
		problemImages,
		replyImages,
		problemImagesData,
		replyImagesData,
		reply?.number,
		problem.id,
		reply?.id
	);
	const colors = allProblemTags ? problemTags?.map(id => allProblemTags[id]?.color) : undefined;
	const responsibleDisplayValue = useResponsibleDisplayValue(respUsers, activeGroup);

	return (
		<div className="m-problem-info-cell">
			<div
				className={classNames('m-problem-info-cell__problem', {
					'm-problem-info-cell__problem_with-padding': depth > 0
				})}
			>
				<div className="m-problem-info-cell__problem-header">
					<ProblemInfo
						style={{marginBottom: depth > 0 ? '12px' : '16px'}}
						data={problem}
						depth={depth}
						subTitle={subTitle}
					/>
					<ClickPreventWrap style={{marginLeft: '8px'}}>
						<TableRowSelect
							{...getToggleRowSelectedProps()}
							style={{marginTop: depth > 0 ? '6px' : '12px'}}
						/>
					</ClickPreventWrap>
				</div>

				{description !== undefined && (
					<div className="m-problem-info-cell__problem-desc">{description}</div>
				)}
				{colors?.length ? (
					<div className="m-problem-info-cell__extra-info">
						<ProblemTagsMarker colors={colors}/>
					</div>
				) : null}
				{plannedFixDate !== undefined && (
					<div className="m-problem-info-cell__extra-info">
						<i className="tz-calendar-20 m-problem-info-cell__extra-info-icon"/>
						<FixDateDisplayValue
							className="m-problem-info-cell__extra-info-value m-problem-info-cell__problem-fix-date"
							plannedFixDate={plannedFixDate}
							status={status}
							critical={critical}
							actualFixDate={actualFixDate}
						/>
					</div>
				)}
				{responsibleDisplayValue ? (
					<div className="m-problem-info-cell__extra-info">
						<i className="tz-user-20 m-problem-info-cell__extra-info-icon"/>
						<div className="m-problem-info-cell__extra-info-value">
							{responsibleDisplayValue}
						</div>
					</div>
				) : null}

				<div className="m-problem-info-cell__problem-status-row">
					<ProblemStatus problem={problem}/>

					{problemImages.length > 0 ? (
						<ClickPreventWrap>
							<PhotoPreview
								className="m-problem-info-cell__problem-image"
								url={problemImages[0]}
								data={0}
								onClick={openProblemImage}
							/>
						</ClickPreventWrap>
					) : null}
				</div>
			</div>

			{reply !== undefined && (
				<ProblemReply
					className="m-problem-info-cell__reply"
					reply={reply}
					images={replyImages}
					openImage={openReplyImage}
				/>
			)}

			{problemImages.length || replyImages.length ? (
				<ClickPreventWrap>{imagesViewer}</ClickPreventWrap>
			) : null}
		</div>
	);
};

const ProblemInfoMobileCell = (props: IProblemCellProps<{data: IProblem}>) => (
	<ProblemInfoMobile
		problem={props.row.original.data}
		depth={props.row.depth}
		replies={props.replies}
		allProblemTags={props.allProblemTags}
		getToggleRowSelectedProps={props.row.getToggleRowSelectedProps}
	/>
);

export default ProblemInfoMobileCell;
