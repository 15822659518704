import React from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {IDictionaryCacheState} from '@src/store/modules/offlineMode/reducers/dictionariesCacheState';
import {OfflineDataCachingStatus} from '@tehzor/tools/contracts/dataCachingWebWorker/interfaces/IOfflineDataCachingStatuses';
import {someInProgress} from '@src/components/OfflineModeDialog/utils/someInProgress';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IPlansCacheStatusProps {
	filesCacheState?: IDictionaryCacheState;
}

export const PlansCacheStatus = ({filesCacheState}: IPlansCacheStatusProps) => {
	const queryClient = useQueryClient();
	const settings = queryClient.getQueryDefaults(plansQueryKeys.lists());
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const {isError, isFetching, dataUpdatedAt} = useQuery({
		queryKey: plansQueryKeys.list(objects),
		staleTime: settings?.staleTime,
		cacheTime: settings?.cacheTime,
		enabled: !!objects && objects.length > 0
	});
	const error
		= isError && filesCacheState?.status === OfflineDataCachingStatus.ERROR
			? 'Ошибка'
			: isError
			? 'Ошибка (данные)'
			: filesCacheState?.status === OfflineDataCachingStatus.ERROR
			? 'Ошибка (файлы)'
			: undefined;

	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Планы"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={error}
			inProgress={!!isFetching || someInProgress([filesCacheState])}
			progressValue={filesCacheState?.progress}
			progressTitle={
				!!isFetching && filesCacheState?.status === OfflineDataCachingStatus.LOADING
					? 'Скачивание файлов...'
					: undefined
			}
		/>
	);
};
