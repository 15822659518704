import {wsConnector} from '../wsConnector';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';

export type IEditTaskStatusResponse = ITask;

/**
 * ОТправляет запрос изменения статуса задачи
 *
 * @param taskId id задачи
 * @param statusId id статуса
 */
export const makeTaskStatusEditRequest = (taskId: string, statusId: TaskStatusId) =>
	wsConnector.sendAuthorizedRequest<IEditTaskStatusResponse>('editTaskStatus', {
		taskId,
		statusId
	});
