import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertSpaces, enrichSpaces} from '@src/store/modules/entities/spaces/selectors/schemas';
import {extractSpacesListAsArray} from '@src/store/modules/entities/spaces/selectors/lists';
import {extractSpaceIndicatorsAsMap} from '@src/store/modules/dictionaries/spaceIndicatorsSets/selectors';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {extractCheckItemsGroupedByLists} from '@src/store/modules/dictionaries/checkItems/selectors';
import {extractSpaceStatusesData} from '@src/store/modules/dictionaries/spaceStatuses/selectors';

export function useEnrichedList(objectId: string) {
	const spaces = useAppSelector(s => extractSpacesListAsArray(s, objectId));
	const spacesStats = useAppSelector(s => s.pages.spaces);
	const object = useAppSelector(s => extractObject(s, objectId));
	const problemStatuses = useAppSelector(s => s.dictionaries.problemStatuses);
	const spaceStatuses = useAppSelector(extractSpaceStatusesData);
	const spaceIndicators = useAppSelector(s => extractSpaceIndicatorsAsMap(s, objectId));
	const checkLists = useAppSelector(s => s.dictionaries.checkLists);
	const checkItems = useAppSelector(extractCheckItemsGroupedByLists);
	const checkRecordStatuses = useAppSelector(s => s.dictionaries.checkRecordStatuses);
	const {stage} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const {filters} = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const convertSpacesData = convertSpaces(spaces, spacesStats, object.id);
	return useMemo(
		() =>
			enrichSpaces(
				convertSpacesData,
				problemStatuses,
				spaceStatuses.byId,
				spaceIndicators.byId,
				checkLists,
				checkItems,
				checkRecordStatuses,
				stage,
				filters.checkListIds,
				altNamesVisible,
				object.companyId
			),
		[
			convertSpacesData,
			problemStatuses,
			spaceStatuses.byId,
			spaceIndicators.byId,
			stage,
			altNamesVisible,
			object.companyId,
			spacesStats
		]
	);
}
