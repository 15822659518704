import * as types from '../constants';
import {IGetSpaceStatusesResponse} from '@src/api/backend/spaceStatuses';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {queryClient} from '@src/api/QueryClient';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';

export type IGetSpaceStatusesPayload = IGetSpaceStatusesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetSpaceStatusesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке статусов помещений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список статусов помещений
 */
export const getSpaceStatuses = () =>
	createApiAction<IGetSpaceStatusesResponse>(
		request, success, failure,
		() => queryClient.fetchQuery(
			spaceStatusesQueryKeys.list(),
			{staleTime: Infinity}
		)
	);
