import React, {memo} from 'react';
import {useAddingLegalDialog} from '@src/components/AddingLegalDialog';
import {FloatingActionButton} from '@tehzor/ui-components';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface IDesktopEditButtonProps {
	legal: ILegalEntity;
}

const editIcon = <i className="tz-edit-20" />;

export const MobileEditButton = memo(({legal}: IDesktopEditButtonProps) => {
	const [legalDialog, handleOpenLegalDialog] = useAddingLegalDialog({legal});

	return (
		<>
			<FloatingActionButton
				icon={editIcon}
				label="Редактировать"
				minifyOnScroll
				scrollContainer="scroll-container"
				onClick={handleOpenLegalDialog}
			/>
			{legalDialog}
		</>
	);
});
