import IProblem from '../../../../interfaces/problems/IProblem';
import {InvalidRestrictionError} from '../../../../errors/InvalidRestrictionError';
import {ProblemStatusId} from '../../../../interfaces/problems/IProblemStatus';
import {IWorkingGroup} from '../../../../interfaces/workingGroups/IWorkingGroup';
import {IBriefUser} from '../../../../interfaces/users/IBriefUser';

type ProblemModified = Omit<IProblem, 'createdBy' | 'modifiedBy'> & {createdBy: IBriefUser, modifiedBy: IBriefUser};

export function problemsCreatedByUser(
	restriction: unknown,
	{userId, problem}: {userId?: string, problem?: ProblemModified}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction && problem.createdBy) {
		return userId === problem.createdBy.id;
	}
	return false;
}

export function problemsHaveResponsibleUser(
	restriction: unknown,
	{userId, problem}: {userId?: string, problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction && problem.respUsers) {
		return problem.respUsers.includes(userId);
	}
	return false;
}

export function problemsHaveWatcherUser(
	restriction: unknown,
	{userId, problem}: {userId?: string, problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction && problem.watchers) {
		return problem.watchers.includes(userId);
	}
	return false;
}

export function availableStatuses(restriction: unknown, {status}: {status?: ProblemStatusId}) {
	if (!Array.isArray(restriction) || !status) {
		throw new InvalidRestrictionError();
	}
	if (restriction) {
		return restriction.includes(status);
	}
	return false;
}

availableStatuses.logicalOperator = 'and';

export function problemsHaveLeaderInActiveGroup(
	restriction: unknown,
	{userId, problem}: {userId?: string, problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction) {
		return problem.activeGroupLeader === userId;
	}
	return false;
}

/**
 * Только пользователи и группы одного текущего уровня могут быть выбраны ответственными
 *
 * @param restriction
 * @param problem
 * @param workingGroup
 * @param respUsers
 * @param activeGroup
 */
export function onlyActiveGroup(
	restriction: unknown,
	{
		problem,
		workingGroup,
		respUsers,
		activeGroup
	}: {problem?: IProblem, workingGroup?: IWorkingGroup, respUsers?: string[], activeGroup?: IWorkingGroup}
) {
	if (typeof restriction !== 'boolean' || !problem) {
		throw new InvalidRestrictionError();
	}
	if (!restriction) {
		return false;
	}
	// Если уже имеется предыдущая активная рабочая группа
	if (workingGroup) {
		if (respUsers?.length) {
			return respUsers.every(id => workingGroup.performers?.includes(id));
		}
		if (activeGroup) {
			return activeGroup.parentId === workingGroup.id;
		}
		// Если была уже установлена группа и пришли данные о пустых новых ответственных,
		// то разрешаем такое действие по сбросу ответственных
		return true;
	}
	// Если не были ранее выбраны ответственные, то должна быть возможность выбрать только из групп самого верхнего,
	// доступного уровня, то есть у кого нет родительских групп.
	// Для такой проверки необходимо всё дерево групп для конкретной ситуации, так как могут быть группы с parentId
	// и в то же время являться верхнеуровневой при определенных параметрах.
	// Поэтому пропускаем за неимением такой проверки.
	return true;
}

onlyActiveGroup.logicalOperator = 'and';
