import React, {memo, useCallback, useMemo, useState} from 'react';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUsersAsArray, extractUsersAsMap} from '@src/store/modules/dictionaries/users/selectors';
import SelectSearch, {flatFilter, treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {FilterButton, Select2, SelectOption, SelectPopup, TabLink, Tabs, TreeSelect, TreeSelectOption} from '@tehzor/ui-components';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {extractProblemInspectorsGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {ITreeUserGroupsItem, ITreeUserGroupsItemType, makeUserGroupsTreeData} from '../utils/makeUserGroupsTreeData';

interface IInspectorsFilterProps {
	inspectors?: string[];
	objectId?: string;
}

const links = [
	<TabLink label="По пользователю"/>,
	<TabLink label="По рабочим группам"/>
];

const getContent = (data: ITreeUserGroupsItem) => (
	<div className="entities-filters__inspectors-item">
		<div className="entities-filters__inspectors-item-content">
			{data.content}
		</div>
		<div className="entities-filters__inspectors-item-additional-content">
			{data.additionalContent}
		</div>
		{data.type === ITreeUserGroupsItemType.LEADER && (
			<div className="entities-filters__inspectors-item-leader">
				Руководитель
			</div>
		)}
	</div>
);

export const InspectorsFilter = memo(({
	inspectors, objectId
}: IInspectorsFilterProps) => {
	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedUsers, setSelectedUsers] = useState(inspectors);
	const [expandedUsers, setExpandedUsers] = useState<string[]>([]);
	const [search, setSearch] = useState('');
	useUpdateEffect(() => setSelectedUsers(inspectors), [inspectors]);

	const [tabIndex, setTabIndex] = useState(0);
	const clearSearch = useCallback(() => setSearch(''), []);

	const users = useAppSelector(extractUsersAsArray);
	const filteredData = useMemo(() => {
		if (tabIndex !== 0) { return undefined; }

		return flatFilter(users, 'fullName', search);
	}, [tabIndex, users, search]);

	const usersMap = useAppSelector(extractUsersAsMap);
	const groups = useAppSelector(s =>
		extractProblemInspectorsGroupsAsArray(s, objectId));
	const treeData = useMemo(() => {
		if (tabIndex !== 1) { return []; }

		return makeUserGroupsTreeData(groups, usersMap);
	}, [tabIndex, groups, usersMap]);
	const {treeFilteredData, expanded} = useMemo(() => {
		if (tabIndex !== 1) { return {}; }

		const {filteredData: treeFilteredData, expanded} = treeFilter(treeData, 'name', search);
		return {treeFilteredData, expanded};
	}, [tabIndex, treeData, search]);

	useUpdateEffect(() => {
		if (expanded) {
			setExpandedUsers(expanded?.map(item => item.id));
		}
	}, [expanded]);

	const handleApply = useCallback(() => {
		dispatch({inspectors: selectedUsers});
		clearSearch();
	}, [selectedUsers, dispatch, clearSearch]);

	const handleChange = useCallback((v?: string[]) => {
		setSelectedUsers(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedUsers([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedUsers([]);
		dispatch({inspectors: undefined});
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedUsers(inspectors);
		clearSearch();
	}, [inspectors, clearSearch]);

	const handleSelectAll = useCallback(() => {
		switch (tabIndex) {
			case 0: {
				setSelectedUsers(users.map(user => user.id));
				break;
			}

			case 1: {
				const allUsers = groups.map(group => {
					const groupUsers = [];
					if (group.performers) {
						groupUsers.push(...group.performers);
					}
					if (group.leader) {
						groupUsers.push(group.leader);
					}
					return groupUsers;
				});
				setSelectedUsers(allUsers.flat());
				break;
			}
		}
		clearSearch();
	}, [tabIndex, users, clearSearch]);

	const handleChangeTab = useCallback((index: number) => {
		handleClear();
		setTabIndex(index);
	}, [handleClear]);

	return users && users.length ? (
		<div>
			<SelectPopup
				className="entities-filters-with-tabs"
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				onSelectAll={handleSelectAll}
				clearButton={!!selectedUsers?.length}
				count={selectedUsers?.length}
				footer
				tabs={(
					<Tabs
						links={links}
						activeTab={tabIndex}
						onActiveTabChange={handleChangeTab}
					/>
				)}
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel<IBriefUser>('Проверяющие', inspectors, users)}
						active={!!inspectors?.length}
						onClear={handleFullClear}
					/>
				)}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
						type="popup"
					/>
				)}
			>
				<>
					{tabIndex === 0 && filteredData !== undefined && (
						<Select2
							multiple
							value={selectedUsers}
							onChange={handleChange}
						>
							{filteredData?.map(item => (
								<SelectOption
									key={item.id}
									itemKey={item.id}
									content={item?.fullName}
								/>
						))}
						</Select2>
					)}
					{tabIndex === 1 && treeFilteredData !== undefined && (
						<TreeSelect
							data={treeFilteredData}
							multiple
							value={selectedUsers}
							onChange={handleChange}
							expandedValue={expandedUsers}
							onExpand={setExpandedUsers}
							TreeItem={props => (
								<TreeSelectOption
									{...props}
									getContent={getContent}
								/>
							)}
						/>
					)}
				</>
			</SelectPopup>
		</div>
	) : null;
});