import React from 'react';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ITreeStructure, ITreeStructureElement} from '@tehzor/tools/utils/tree/treeStuctures';
import arrayToTree from 'array-to-tree';
import {flattenTree} from '@src/utils/tree';

interface IStructuredCategoriesData {
	categoryIndex: string;
	cat: arrayToTree.Tree<ICategory> | undefined;
	comparedCat: arrayToTree.Tree<ICategory> | undefined;
	className: string;
	elStrData: ITreeStructure;
	children?: IStructuredCategoriesData[];
}

export const getStructuredCategoriesData = (
	str: ITreeStructure,
	data?: Array<arrayToTree.Tree<ICategory>>,
	comparedData?: Array<arrayToTree.Tree<ICategory>>,
	preIndex?: string,
	original?: boolean,
	setErr?: () => void,
	addMatch?: (match: { fromId: string, toId: string }) => void
): IStructuredCategoriesData[] => {
	const strData = str.filter(strEl => strEl.length === 1);

	const categoriesData = strData.map((el: ITreeStructureElement) => {
		const i = el[0];
		const cat = data ? data[i] : undefined;
		const comparedCat = comparedData ? comparedData[i] : undefined;
		const categoryIndex = `${preIndex}${(i + 1).toString()}.`;
		const elStrData = str.filter(strEl => strEl[0] === i).map(strEl => {
			const newEl = [...strEl];
			newEl.shift();
			return newEl;
		});

		if (cat === undefined || comparedCat === undefined || (cat?.name !== comparedCat?.name)) {
			if (setErr) setErr();
		} else if (original && addMatch) {
			addMatch({fromId: cat.id, toId: comparedCat.id});
		}
		const className = (cat?.name)
			? (cat?.name === comparedCat?.name
				? (cat?.id === comparedCat?.id
					? ('categories-set-migration-page__data_matches')
					: 'categories-set-migration-page__data_equal')
				: 'categories-set-migration-page__data_not-equal')
			: 'categories-set-migration-page__data_undefined';

		const children = elStrData.length ? getStructuredCategoriesData(
			elStrData,
			cat?.children,
			comparedCat?.children,
			categoryIndex,
			original,
			setErr,
			addMatch
		) : undefined;

		return ({
			categoryIndex,
			cat,
			comparedCat,
			className,
			elStrData,
			children
		});
	});

	return flattenTree(categoriesData);
};

export const StructuredCategories = (
	{structuredCategoriesData}: {structuredCategoriesData: IStructuredCategoriesData[]}
	) => (
		<div>
			{structuredCategoriesData.map(category => (
				<div
					key={category.categoryIndex}
				>
					<div
						className={`categories-set-migration-page__data ${category.className}`}
						style={{paddingLeft: 5 + (10 * category.categoryIndex.length)}}
					>
						{`${category.categoryIndex}  ${category.cat ? category.cat.name : ''}`}

					</div>

				</div>
			))}
		</div>
	);
