import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractCheckListsAsArrayFilteredBySelectedSpaceType} from '@src/store/modules/dictionaries/checkLists/selectors';
import {extractRootCheckItems} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const CheckListCategoryFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const checkLists = useAppSelector(s =>
		extractCheckListsAsArrayFilteredBySelectedSpaceType(s, objectId));
	const {state} = useEntitiesFiltersCtx<ISpacesFiltersState>();
	const checkList
		= checkLists.length === 1
			? checkLists[0].id
			: state?.checkListIds?.length === 1
			? state.checkListIds[0]
			: '';

	const checkListCategory = useAppSelector(s => extractRootCheckItems(s, checkList));

	return checkLists.length === 1 || state?.checkListIds?.length === 1 ? (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={checkListCategory}
			label="Категории чек-листов"
			filterName="checkListCategory"
		/>
	) : null;
};
