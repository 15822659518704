import React from 'react';
import './EmptyPlate.less';

interface IEmptyPlateProps {
	text?: string;
	icon?: React.ReactNode;
}
export const EmptyPlate = ({text, icon}: IEmptyPlateProps) => (
	<div className="empty-plate__no-content">
		<div className="empty-plate__no-content-container">
			{icon !== undefined && (
				<div className="empty-plate__no-content-icon">
					{icon}
				</div>
			)}
			{text !== undefined && (
				<div className="empty-plate__no-content-text">
					{text}
				</div>
			)}
		</div>
	</div>
	);
