import React from 'react';
import {IConvertedProblemsData} from '@tehzor/tools/interfaces/spaces/IConvertedProblemsData';
import {IConvertedCheckListData} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';

interface IStatsBorderProps {
	item: IConvertedProblemsData | IConvertedCheckListData;
	width: number;
	height: number;
	radius: number;
	color?: string;
}

export const StatsBorderRectangle = ({item, width, height, radius, color}: IStatsBorderProps) => {
	const rx = 9;
	const totalLength = (width + height) * 2 - rx * 8 + Math.PI * rx * 2;
	const strokeLength = (totalLength / 100) * (item.percent ?? 100);
	const strokeDashoffset = totalLength / 100 * ((item.startPoint ?? 0) + 25);

	return (
		<rect
			key={item.key}
			data-key={item.key}
			className="problems-space__stats-item"
			rx={rx}
			x="12"
			y="12"
			width={width}
			height={height}
			fill="transparent"
			stroke={color ?? item.color}
			strokeWidth="3"
			strokeDasharray={`${strokeLength} ${totalLength - strokeLength}`}
			strokeDashoffset={strokeDashoffset - width / 2 + rx}
		/>
	);
};
