import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as checkTypes from '@src/store/modules/entities/check/constants';
import * as internalAcceptanceTypes from '@src/store/modules/entities/internalAcceptance/constants';
import * as acceptanceTypes from '@src/store/modules/entities/ownerAcceptance/constants';
import * as warrantyClaimTypes from '@src/store/modules/entities/warrantyClaim/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import * as problemReplyTypes from '@src/store/modules/entities/problemReply/constants';
import * as inspectionTypes from '@src/store/modules/entities/inspection/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {
	IAddProblemPayload,
	IDeleteProblemPayload,
	IEditProblemPayload,
	IEditProblemStatusPayload
} from '@src/store/modules/pages/problem/actions';
import {
	IEditSpaceIndicatorsPayload,
	IEditSpacePayload,
	IEditSpaceStatusPayload,
	IGetSpaceChecksPayload,
	IGetSpaceInspectionsPayload,
	IGetSpaceOwnerAcceptancesPayload,
	IGetSpacePayload,
	IGetSpaceProblemsPayload,
	IGetSpaceWarrantyClaimsPayload
} from './actions';
import {
	IAddWarrantyClaimPayload,
	IDeleteWarrantyClaimPayload,
	IEditWarrantyClaimStatusPayload
} from '@src/store/modules/entities/warrantyClaim/actions';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {IAddCheckPayload, IDeleteCheckPayload} from '@src/store/modules/entities/check/actions';
import {IAddInspectionPayload} from '@src/store/modules/entities/inspection/actions/add';
import {
	IDeleteInspectionPayload,
	IEditInspectionPayload
} from '@src/store/modules/entities/inspection/actions';
import {
	IAddOwnerAcptPayload,
	IDeleteOwnerAcptPayload,
	IEditOwnerAcceptanceStatusPayload,
	IEditOwnerAcptPayload
} from '@src/store/modules/entities/ownerAcceptance/actions';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {IGetSpaceProblemRepliesPayload} from '@src/store/modules/entities/space/actions/problemReplies';
import {IAddProblemCommentPayload} from '@src/store/modules/entities/problemComment/actions';
import {
	IAddInternalAcceptancePayload,
	IDeleteInternalAcceptancePayload
} from '../internalAcceptance/actions';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {IGetSpaceTasksPayload} from '@src/store/modules/entities/space/actions/tasks/get';

export type SpaceEntitiesTab =
	| 'all'
	| 'checks'
	| 'internal-acceptances'
	| 'owner-acceptances'
	| 'warranty-claims';

const {entitiesCacheTime} = config;

export interface ISpaceState {
	data?: ISpace;
	loaded?: boolean;
	entitiesTab?: SpaceEntitiesTab;
	checks: {
		byId: Record<string, IListCheck>;
		allIds: string[];
		expires?: number;
	};
	internalAcceptances: {
		byId: Record<string, IListInternalAcceptance>;
		allIds: string[];
		expires?: number;
	};
	ownerAcceptances: {
		byId: Record<string, IListOwnerAcceptance>;
		allIds: string[];
		expires?: number;
	};
	warrantyClaims: {
		byId: Record<string, IListWarrantyClaim>;
		allIds: string[];
		expires?: number;
	};
	problems: {
		byId: Record<string, IListProblem>;
		allIds: string[];
		expires?: number;
	};
	inspections: {
		byId: Record<string, IListInspection>;
		allIds: string[];
		expires?: number;
	};
	problemReplies: {
		byId: Record<string, IProblemComment>;
		allIds: string[];
		expires?: number;
	};
	tasks: {
		byId: Record<string, ITask>;
		allIds: string[];
		total: number;
	};
}

export const getInitialState = (): ISpaceState => ({
	checks: {
		byId: {},
		allIds: []
	},
	internalAcceptances: {
		byId: {},
		allIds: []
	},
	ownerAcceptances: {
		byId: {},
		allIds: []
	},
	warrantyClaims: {
		byId: {},
		allIds: []
	},
	problems: {
		byId: {},
		allIds: []
	},
	inspections: {
		byId: {},
		allIds: []
	},
	problemReplies: {
		byId: {},
		allIds: []
	},
	tasks: {
		byId: {},
		allIds: [],
		total: 0
	}
});

const updateEntitiesTab = (state: ISpaceState) => {
	const data: SpaceEntitiesTab[] = [];
	if (state.warrantyClaims.allIds.length) {
		data.push('warranty-claims');
	}
	if (state.ownerAcceptances.allIds.length) {
		data.push('owner-acceptances');
	}
	if (state.internalAcceptances.allIds.length) {
		data.push('internal-acceptances');
	}
	if (state.checks.allIds.length) {
		data.push('checks');
	}
	if (data.length > 1) {
		data.unshift('all');
		state.entitiesTab = data[1];
	} else {
		state.entitiesTab = undefined;
	}
};

export default createReducer<ISpaceState>(getInitialState(), {
	[types.GET_REQUEST]: (state, {payload}: {payload: {spaceId: string}}) =>
		state.data?.id !== payload.spaceId ? getInitialState() : state,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetSpacePayload}) => {
		state.data = payload;
		state.loaded = true;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditSpacePayload}) => {
		state.data = payload;
	},
	[types.EDIT_STATUS_SUCCESS]: (state, {payload}: {payload: IEditSpaceStatusPayload}) => {
		state.data = payload;
	},
	[types.EDIT_INDICATORS_SUCCESS]: (state, {payload}: {payload: IEditSpaceIndicatorsPayload}) => {
		state.data = payload;
	},
	// Загрузка сущностей
	[types.GET_INTERNAL_ACCEPTANCES_SUCCESS]: (
		state,
		{payload}: {payload: IGetSpaceChecksPayload}
	) => {
		state.internalAcceptances.byId = payload.byId;
		state.internalAcceptances.allIds = payload.allIds;
		state.internalAcceptances.expires = Date.now() + entitiesCacheTime * 1000;
		updateEntitiesTab(state);
	},
	[types.GET_CHECKS_SUCCESS]: (state, {payload}: {payload: IGetSpaceChecksPayload}) => {
		state.checks.byId = payload.byId;
		state.checks.allIds = payload.allIds;
		state.checks.expires = Date.now() + entitiesCacheTime * 1000;
		updateEntitiesTab(state);
	},
	[types.GET_OWNER_ACCEPTANCES_SUCCESS]: (
		state,
		{payload}: {payload: IGetSpaceOwnerAcceptancesPayload}
	) => {
		state.ownerAcceptances.byId = payload.byId;
		state.ownerAcceptances.allIds = payload.allIds;
		state.ownerAcceptances.expires = Date.now() + entitiesCacheTime * 1000;
		updateEntitiesTab(state);
	},
	[types.GET_WARRANTY_CLAIMS_SUCCESS]: (
		state,
		{payload}: {payload: IGetSpaceWarrantyClaimsPayload}
	) => {
		state.warrantyClaims.byId = payload.byId;
		state.warrantyClaims.allIds = payload.allIds;
		state.warrantyClaims.expires = Date.now() + entitiesCacheTime * 1000;
		updateEntitiesTab(state);
	},
	[types.GET_PROBLEMS_SUCCESS]: (state, {payload}: {payload: IGetSpaceProblemsPayload}) => {
		state.problems.byId = payload.byId;
		state.problems.allIds = payload.allIds;
		state.problems.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_INSPECTIONS_SUCCESS]: (state, {payload}: {payload: IGetSpaceInspectionsPayload}) => {
		state.inspections.byId = payload.byId;
		state.inspections.allIds = payload.allIds;
		state.inspections.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.GET_PROBLEM_REPLIES_SUCCESS]: (
		state,
		{payload}: {payload: IGetSpaceProblemRepliesPayload}
	) => {
		state.problemReplies.byId = payload.byId;
		state.problemReplies.allIds = payload.allIds;
		state.problemReplies.expires = Date.now() + entitiesCacheTime * 1000;
	},
	// Проверки
	[checkTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddCheckPayload}) => {
		if (state.data?.id === payload.links?.spaceId) {
			state.checks.byId[payload.id] = payload;
			state.checks.allIds.unshift(payload.id);
		}
	},
	[checkTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteCheckPayload}) => {
		if (state.checks.allIds.includes(payload.checkId)) {
			state.checks.allIds = state.checks.allIds.filter(id => id !== payload.checkId);
			delete state.checks.byId[payload.checkId];
		}
	},
	// Внутренние приёмки
	[internalAcceptanceTypes.ADD_SUCCESS]: (
		state,
		{payload}: {payload: IAddInternalAcceptancePayload}
	) => {
		if (state.data?.id === payload.links?.spaceId) {
			state.internalAcceptances.byId[payload.id] = payload;
			state.internalAcceptances.allIds.unshift(payload.id);
		}
	},
	[internalAcceptanceTypes.DELETE_SUCCESS]: (
		state,
		{payload}: {payload: IDeleteInternalAcceptancePayload}
	) => {
		if (state.internalAcceptances.allIds.includes(payload.internalAcceptanceId)) {
			state.internalAcceptances.allIds = state.internalAcceptances.allIds.filter(
				id => id !== payload.internalAcceptanceId
			);
			delete state.internalAcceptances.byId[payload.internalAcceptanceId];
		}
	},

	// Передачи собственникам
	[acceptanceTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddOwnerAcptPayload}) => {
		if (state.data?.id === payload.links?.spaceId) {
			state.ownerAcceptances.byId[payload.id] = payload;
			state.ownerAcceptances.allIds.unshift(payload.id);
		}
	},
	[acceptanceTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditOwnerAcceptanceStatusPayload}
	) => {
		if (state.ownerAcceptances.allIds.includes(payload.id)) {
			state.ownerAcceptances.byId[payload.id] = payload;
		}
	},
	[acceptanceTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditOwnerAcptPayload}) => {
		if (state.ownerAcceptances.allIds.includes(payload.id)) {
			state.ownerAcceptances.byId[payload.id] = payload;
		}
	},
	[acceptanceTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteOwnerAcptPayload}) => {
		if (state.ownerAcceptances.allIds.includes(payload.acceptanceId)) {
			state.ownerAcceptances.allIds = state.ownerAcceptances.allIds.filter(
				id => id !== payload.acceptanceId
			);
			delete state.ownerAcceptances.byId[payload.acceptanceId];
		}
	},
	// Гарантийные обращения
	[warrantyClaimTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddWarrantyClaimPayload}) => {
		if (state.data?.id === payload.links?.spaceId) {
			state.warrantyClaims.byId[payload.id] = payload;
			state.warrantyClaims.allIds.unshift(payload.id);
		}
	},
	[warrantyClaimTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditWarrantyClaimStatusPayload}
	) => {
		if (state.warrantyClaims.allIds.includes(payload.id)) {
			state.warrantyClaims.byId[payload.id] = payload;
		}
	},
	[warrantyClaimTypes.DELETE_SUCCESS]: (
		state,
		{payload}: {payload: IDeleteWarrantyClaimPayload}
	) => {
		if (state.warrantyClaims.allIds.includes(payload.claimId)) {
			state.warrantyClaims.allIds = state.warrantyClaims.allIds.filter(
				id => id !== payload.claimId
			);
			delete state.warrantyClaims.byId[payload.claimId];
		}
	},
	// Нарушения
	[problemTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemPayload}) => {
		if (state.data?.id === payload.links?.spaceId) {
			state.problems.byId[payload.id] = payload;
			state.problems.allIds.unshift(payload.id);
		}
	},
	[problemTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditProblemPayload}) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.EDIT_STATUS_SUCCESS]: (
		state,
		{payload}: {payload: IEditProblemStatusPayload}
	) => {
		if (state.problems.allIds.includes(payload.id)) {
			state.problems.byId[payload.id] = payload;
		}
	},
	[problemTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteProblemPayload}) => {
		if (state.problems.allIds.includes(payload.problemId)) {
			state.problems.allIds = state.problems.allIds.filter(id => id !== payload.problemId);
			delete state.problems.byId[payload.problemId];
		}
	},
	// Осмотры
	[inspectionTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddInspectionPayload}) => {
		if (state.data?.id === payload.links?.spaceId) {
			state.inspections.byId[payload.id] = payload;
			state.inspections.allIds.unshift(payload.id);
		}
	},
	[inspectionTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditInspectionPayload}) => {
		if (state.inspections.allIds.includes(payload.id)) {
			state.inspections.byId[payload.id] = payload;
		}
	},
	[inspectionTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteInspectionPayload}) => {
		if (state.inspections.allIds.includes(payload.inspectionId)) {
			state.inspections.allIds = state.inspections.allIds.filter(
				id => id !== payload.inspectionId
			);
			delete state.inspections.byId[payload.inspectionId];
		}
	},
	// Ответы на нарушения
	[problemReplyTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddProblemCommentPayload}) => {
		if (state.data?.id === payload.links?.spaceId) {
			state.problemReplies.byId[payload.id] = payload;
			state.problemReplies.allIds.unshift(payload.id);
		}
	},
	[types.GET_TASKS_SUCCESS]: (state, {payload}: {payload: IGetSpaceTasksPayload}) => {
		state.tasks.byId = payload.byId;
		state.tasks.allIds = payload.allIds;
		state.tasks.total = payload.total;
	},
	// Остальное
	[types.CHANGE_ENTITIES_TAB]: (state, {payload}: {payload: SpaceEntitiesTab}) => {
		state.entitiesTab = payload;
	},
	[CLEAR_STORE]: getInitialState
});
