import {createSelector} from 'reselect';
import {extractCheckItemsAsTree} from '@src/store/modules/dictionaries/checkItems/selectors';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {IState} from '@src/store/modules';
import {Tree} from 'array-to-tree';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';

const lookupParents = (
	tree: Array<Tree<ICheckItem>>,
	currentId: string,
	parentId: string
): string | undefined => {
	const parent = findTreeNode(tree, parentId);

	if (parent) {
		const currentIndex = parent.children?.findIndex(item => item.id === currentId);
		let parentIndex;

		if (parent.parentId) {
			parentIndex = lookupParents(tree, parent.id, parent.parentId);
		} else {
			parentIndex = tree.findIndex(item => item.id === parent.id);

			if (parentIndex !== undefined && parentIndex >= 0) {
				parentIndex += 1;
			}
		}

		if (currentIndex !== undefined && currentIndex >= 0) {
			return parentIndex !== undefined
			|| (typeof parentIndex === 'number' && parentIndex >= 0)
				? `${parentIndex}.${currentIndex + 1}`
				: (currentIndex + 1).toString();
		}
	}

	return undefined;
};

export const getCurrentCheckItemIndex = createSelector(
	[extractCheckItemsAsTree, (s: IState, checkListId: string, item: ICheckItem) => item],
	(items, current) => {
		let index;

		if (current.parentId) {
			index = lookupParents(items, current.id, current.parentId);
		} else {
			index = items.findIndex(item => item.id === current.id);

			if (index >= 0) {
				index += 1;
			}
		}

		return index !== undefined ? index.toString() : undefined;
	}
);