import {wsConnector} from '../wsConnector';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IAddCheckResponse = ICheck;

/**
 * Добавляет проверку
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 */
export const makeCheckAddRequest = (
	objectId: string,
	links: ICheck['links'] | undefined,
	stage: ObjectStageIds = ObjectStageIds.BUILDING
) => wsConnector.sendAuthorizedRequest<IAddCheckResponse>('addCheck', {objectId, links, stage});
