import React from 'react';
import {MobileHeader, MobileHeaderMenu} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import {useLocation} from 'react-router-dom';
import MobileLeftButton from './MobileLeftButton';

interface IMobileAppHeaderProps {
	title?: string;
	subTitle?: string;
	backBtnVisible?: boolean;
	sectionsMenu?: IHeaderSectionsMenuItem[];
	mobileLeftButton?: React.ReactNode;
	mobileRightButtons?: React.ReactNode;
	titleButtons?: React.ReactNode;
}

const MobileAppHeader = (props: IMobileAppHeaderProps) => {
	const {
		title,
		subTitle,
		backBtnVisible,
		sectionsMenu,
		mobileLeftButton,
		mobileRightButtons,
		titleButtons
	} = props;
	const location = useLocation();
	// const isTablet = useIsTablet();
	const {replacePath} = useChangePath();
	return (
		<MobileHeader
			className="app-header"
			title={title}
			subTitle={subTitle}
			titleButtons={titleButtons}
			left={
				mobileLeftButton !== undefined ? (
					mobileLeftButton
				) : (
					<MobileLeftButton backBtnVisible={backBtnVisible}/>
			)
			}
			right={mobileRightButtons}
			sectionsMenu={
				sectionsMenu ? (
					<MobileHeaderMenu
						items={sectionsMenu}
						value={location.pathname}
						onChange={replacePath}
					/>
				) : undefined
			}
			// hideOnScroll={!isTablet}
			// scrollContainerId="scroll-container"
		/>
	);
};

export default MobileAppHeader;
