import React from 'react';
import './ProblemsCount.less';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {extractProblemStatusesAsArray} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

interface IProblemCountProps {
	object: IParentObject;
}

function getProblems(obj: IParentObject, result: Record<string, number>) {
	if (obj.stats?.problems) {
		Object.values(obj.stats.problems).forEach(value => {
			Object.entries(value).forEach(([key1, value1]) => {
				result[key1] += value1.count;
			});
		});
	}
	if (obj.children) {
		obj.children.forEach((item: any) => {
			getProblems(item, result);
		});
	}
}

const ProblemsCount = ({object}: IProblemCountProps) => {
	const statuses = useAppSelector(extractProblemStatusesAsArray);

	const result = {
		[ProblemStatusId.FIXED]: 0,
		[ProblemStatusId.WAITING]: 0,
		[ProblemStatusId.HANDLING]: 0,
		[ProblemStatusId.VERIFICATION]: 0
	};

	getProblems(object, result);

	return (
		<div className="problems-count">
			{statuses.map(status => {
				if (result[status.id] > 0) {
					return (
						<div
							className="problems-count__container"
							key={status.id}
						>
							<i
								className="tz-problem-16"
								style={{color: `${status.color}`}}
							/>
							<div className="problems-count__count">{result[status.id]}</div>
						</div>
					);
				}
				return null;
			})}
		</div>
	);
};

export default ProblemsCount;
