import React from 'react';
import './DelegationHistoryDialog.less';
import {DelegationHistoryList, Dialog} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {getDelegationHistoriesByProblem} from '@src/store/modules/pages/problem/actions/delegationHistories';
import {useEnrichedHistories} from './hooks/useEnrichedHistories';
import {getDelegationHistoriesByWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions/delegationHistories';

interface IDelegationHistoryDialogProps {
	objectId: string;
	entityId: string;
	isOpen: boolean;
	type: 'problem' | 'work-acceptance';

	onClose: () => void;
}

export const DelegationHistoryDialog = (props: IDelegationHistoryDialogProps) => {
	const {objectId, entityId, isOpen, type, onClose} = props;

	const dispatch = useAppDispatch();

	const histories = useEnrichedHistories(type);

	useAsync(async () => {
		if (isOpen) {
			if (type === 'problem') {
				await dispatch(getDelegationHistoriesByProblem(objectId, entityId));
			}
			if (type === 'work-acceptance') {
				await dispatch(getDelegationHistoriesByWorkAcceptance(objectId, entityId));
			}
		}
	}, [isOpen, objectId, entityId, type]);

	return (
		<Dialog
			className={{
				root: 'delegation-histories-dialog',
				content: 'delegation-histories-dialog__content'
			}}
			isOpen={isOpen}
			title="История делегирования"
			fullScreenOnMobile
			onRequestClose={onClose}
		>
			<DelegationHistoryList data={histories}/>
		</Dialog>
	);
};
