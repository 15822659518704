import React from 'react';
import {CellProps} from 'react-table';
import {UserInfo} from '@tehzor/ui-components';
import {IPreparedSpaceStatusesSet} from '@src/pages/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';

export const UserCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) =>
	(row.original.createdBy ? (
		<div>
			<UserInfo user={row.original.createdBy}/>
		</div>
	) : null);