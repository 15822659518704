import {useEditableObjectDialog} from '@src/components/EditableObjectDialog/hooks/useEditableObjectDialog';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IPermissions} from '@src/pages/ObjectsPage/utils/usePermissionsForObject';
import {deleteObject} from '@src/store/modules/entities/object/actions';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import React from 'react';
import {useAsyncFn} from 'react-use';
import {useGenerateSpacesDialog} from '../hooks/useGenerateSpacesDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';

interface IMenuProps {
	object: IObject;
	permissions: IPermissions;
}

const spaceIcon = <i className="tz-space-24"/>;
const deleteIcon = <i className="tz-delete"/>;
const editIcon = <i className="tz-edit"/>;

export const Menu = ({object, permissions}: IMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const currentTreeObject = useAppSelector(s => extractCurrentTreeObject(s, object.id));
	const hasNoChildObjects = !currentTreeObject?.children?.length;
	const canGenerateSpaces = permissions.canGenerate && hasNoChildObjects;

	const items = [];

	const isDesktop = useIsDesktop();

	const [objectEditDialog, openObjectEditDialog] = useEditableObjectDialog(object);

	const [generateSpacesDialog, openGenerateSpacesDialog] = useGenerateSpacesDialog(object.id);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить объект',
		`Вы действительно хотите удалить объект ${object.name}`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteObject(object.id));
			goBack();
		}
	}, [object.id]);

	if (permissions.canEditObject && !isDesktop) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openObjectEditDialog}
			>
				Редактировать
			</MenuItem>
		);
	}

	if (canGenerateSpaces) {
		items.push(
			<MenuItem
				key="settings"
				icon={spaceIcon}
				onClick={openGenerateSpacesDialog}
			>
				Генерация помещений
			</MenuItem>
		);
	}

	if (permissions.canDeleteObject) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{permissions.canDeleteObject && deleteDialog}
			{permissions.canEditObject && objectEditDialog}
			{canGenerateSpaces && generateSpacesDialog}
		</>
	) : null;
};