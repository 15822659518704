import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceType} from '@tehzor/tools/interfaces/spaces/ISpaceType';

export type IGetSpaceTypesResponse = INormalizedData<ISpaceType>;

/**
 * Возвращает список типов помещений
 */
export const requestSpaceTypes = () =>
	wsConnector.sendAuthorizedRequest<IGetSpaceTypesResponse>('getSpaceTypes');
