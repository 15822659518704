import {requestObjects} from '@src/api/backend/objects';
import {useQueryClient} from '@tanstack/react-query';
import {objectsQueryKeys} from '../keys';

export const useObjectsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(objectsQueryKeys.list(), {
		queryFn: requestObjects,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
