import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ITaskStatus} from '@tehzor/tools/interfaces/tasks/ITaskStatus';

export type IGetTaskStatusesResponse = INormalizedData<ITaskStatus>;

/**
 * Возвращает список статусов задач
 */
export const requestTaskStatuses = () =>
	wsConnector.sendAuthorizedRequest<IGetTaskStatusesResponse>('getTaskStatuses');
