import {wsConnector} from '@src/api/backend/wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export type IGetUsersResponse = INormalizedData<IBriefUser>;

/**
 * Отправляет запрос на получение списка пользователей
 */
export const requestUsers = () => wsConnector.sendAuthorizedRequest<IGetUsersResponse>(
		'getUsers',
		{},
		{timeout: 120}
	);
