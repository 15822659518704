import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemDelegationHistoriesAsArray} from '@src/store/modules/pages/problem/selectors/delegationHistories';
import {IEnrichedDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IEnrichedDelegationHistory';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {extractWorkAcceptanceDelegationHistoriesAsArray} from '@src/store/modules/entities/workAcceptance/selectors';

function enrichGroup(
	groupId: string | undefined,
	groupsMap: Record<string, IWorkingGroup>,
	usersMap: Record<string, IBriefUser>
) {
	if (groupId && groupsMap[groupId]) {
		return {
			id: groupsMap[groupId].id,
			name: groupsMap[groupId].name,
			leader: usersMap[groupsMap[groupId].leader]
		};
	}
	return undefined;
}

export function useEnrichedHistories(
	type: 'problem' | 'work-acceptance'
): IEnrichedDelegationHistory[] {
	const histories = useAppSelector(type === 'problem' ? extractProblemDelegationHistoriesAsArray : extractWorkAcceptanceDelegationHistoriesAsArray);
	const groupsMap = useAppSelector(s => s.dictionaries.workingGroups.byId);
	const usersMap = useAppSelector(s => s.dictionaries.users.byId);

	return useMemo(
		() =>
			histories.map<IEnrichedDelegationHistory>(history => ({
				id: history.id,
				objectId: history.id,
				links: {
					problemId: history.links?.problemId,
					workAcceptanceId: history.links?.workAcceptanceId
				},
				fromGroup: enrichGroup(history.fromGroup, groupsMap, usersMap),
				toGroup: enrichGroup(history.toGroup, groupsMap, usersMap),
				toUsers: history.toUsers?.reduce<IBriefUser[]>((prev, id) => {
					if (usersMap[id]) {
						prev.push(usersMap[id]);
					}
					return prev;
				}, []),
				ruleId: history.ruleId,
				createdBy: history.createdBy ? usersMap[history.createdBy] : undefined,
				createdAt: history.createdAt
			})),
		[histories, groupsMap, usersMap]
	);
}
