import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {usePageReloadPrevent} from '@tehzor/tools/core/hooks/usePageReloadPrevent';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/ISavingWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {
	convertToSave,
	errorsFns,
	isEdited,
	useEditableWorkAcceptanceState
} from '@src/core/hooks/states/useEditableWorkAcceptanceState';
import {EditableWorkAcceptance} from '../EditableWorkAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

/**
 * Логика редактирования и сохранения приёмки работ (новой или существующей)
 */
export const useEditableWorkAcceptance = (
	objectId: string,
	stage: ObjectStageIds,
	workAcceptance: IWorkAcceptance | undefined,
	saving: boolean
): [React.ReactNode, () => Promise<ISavingWorkAcceptance | undefined>, (
) => void, boolean] => {
	const fieldsSettings = useMemo(() => ({
		description: {fieldId: 'description', isRequired: false},
		categoryId: {fieldId: 'categoryId', isRequired: false},
		acceptanceDate: {fieldId: 'acceptanceDate', isRequired: false},
		respUsers: {fieldId: 'respUsers', isRequired: false},
		structureIds: {fieldId: 'structureIds', isRequired: false}

	}), []);

	const [editingState, editingDispatch] = useEditableWorkAcceptanceState(workAcceptance);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	usePageReloadPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, workAcceptance) || isFilesExist(uploadingFilesState.value));
	}, [editingState, uploadingFilesState.value, workAcceptance]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, errorsFns, fieldsSettings)
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (!isEdited(editingState, workAcceptance) && (!isFilesExist(files) || someFilesHaveError(files))) {
			return undefined;
		}
		const savingData = convertToSave(editingState, workAcceptance, true);

		return savingData;
	}, [editingState, workAcceptance, fieldsSettings, editingDispatch, uploadingFilesDispatch, waitUploading]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: workAcceptance
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [workAcceptance, editingDispatch, uploadingFilesDispatch]);

	// Сброс данных для редактирования при изменении начальных данных 
	useUpdateEffect(reset, [workAcceptance]);
	const fields = (
		<EditableWorkAcceptance
			objectId={objectId}
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving}
			stage={stage}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
