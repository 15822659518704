import {Column} from 'react-table';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import {extractCheckProblemCommentsByProblem} from '@src/store/modules/entities/check/selectors';
import {ICheckDetailsEntity} from '@src/interfaces/ICheckDetailsEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import {CheckListProblemCellWrap} from '@src/pages/CheckListPage/components/wraps/CheckListProblemCellWrap';
import {CheckListTaskCellWrap} from '@src/pages/CheckListPage/components/wraps/CheckListTaskCellWrap';

export const mobileColumns: Array<Column<ICheckDetailsEntity>> = [
	{
		id: 'info',
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(
				CheckListProblemCellWrap,
				extractCheckProblemCommentsByProblem
			),
			task: CheckListTaskCellWrap
		})
	}
];
