import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {DesktopMainActions} from './actions/MainActions.desktop';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {useOwnerAcceptanceFromSpaceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useOwnerAcceptanceFromSpaceBreadcrumbs';

interface IPageBreadcrumbsProps {
	objectId: string;
	ownerAcceptance: IOwnerAcceptance;
}

export const FromSpacePageBreadcrumbs = memo(({objectId, ownerAcceptance}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useOwnerAcceptanceFromSpaceBreadcrumbs(
		objectId,
		ownerAcceptance.id,
		ownerAcceptance
	);

	return (
		<AppBreadcrumbs
			className="owner-acceptance-page__breadcrumbs"
			items={breadcrumbs}
		>
			<DesktopMainActions
				objectId={objectId}
				ownerAcceptance={ownerAcceptance}
			/>
		</AppBreadcrumbs>
	);
});