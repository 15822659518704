import React from 'react';
import {EntityGridItem, Tag} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IProblemTagsProps {
	tags: string[];
	fullRow?: boolean;
}

const ProblemTags = ({tags, fullRow}: IProblemTagsProps) => {
	const allTagsById = useAppSelector(s => s.dictionaries.problemTags.byId);
	const problemTags = tags.map(id => {
		const data = allTagsById[id];
		if (data) {
			return (
				<Tag
					className="problem-page__tag"
					key={data.id}
					label={data.name}
					dotColor={data.color}
					size="small"
				/>
			);
		}
			return null;
	});

	return (
		<EntityGridItem
			icon={<i className="tz-tag-20"/>}
			label="Метки"
			fullRow={fullRow}
		>
			<div className="problem-page__tags">{problemTags}</div>
		</EntityGridItem>
	);
};

export default ProblemTags;
