import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeCheckListsExportRequest} from '@src/api/backend/checkLists/export';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addExportToast} from '@src/components/ExportToast/addExportToast';

interface ISelected {
	spaceId?: string;
	workAcceptanceId?: string;
	objectId: string;
	checkListsIds: string[];
}

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse, sending: boolean) => {
	if (response.link) {
		addExportToast(
			sending ? 'Документ отправлен' : 'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document,
			undefined,
			undefined
		);
	}
	if (response.warnings?.itemsLimit !== undefined) {
		addWarningToast(
			'Внимание',
			`превышен лимит записей (${String(response.warnings.itemsLimit)})`
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при экспорте');
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует список чек-листов
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param selected выбранные элементы
 */
export const exportCheckLists = (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	selected: ISelected[]
) =>
	createApiAction<IExportResponse>(
		request,
		(r: IExportResponse) => success(r, !!email),
		failure,
		() => makeCheckListsExportRequest(templateId, createDocument, email, selected)
	);
