import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.pages.inspection.stories.allIds || [];
const getById = (state: IState) => state.pages.inspection.stories.byId || {};

/**
 * Извлекает текущую историю осмотра
 */
export const extractInspectionStoriesAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map(id => byId[id])
);
