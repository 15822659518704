import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {
	SelectPopup,
	FilterButton,
	Select2,
	SelectOption,
	SelectSearch,
	TabLink,
	Tabs,
	TreeSelectOption,
	TreeSelect
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {flatFilter, treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {extractUsersAsArray, extractUsersAsMap} from '@src/store/modules/dictionaries/users/selectors';
import {ITreeUserGroupsItem, ITreeUserGroupsItemType, makeUserGroupsTreeData} from '../utils/makeUserGroupsTreeData';
import {extractPerformersGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';

interface IRespUsersFilterProps {
	respUsers?: string[];
	objectId?: string;
}

const links = [
	<TabLink label="По пользователю"/>,
	<TabLink label="По рабочим группам"/>
];

const getContent = (data: ITreeUserGroupsItem) => (
	<div className="entities-filters__resp-users-item">
		<div className="entities-filters__resp-users-item-content">
			{data.content}
		</div>
		<div className="entities-filters__resp-users-item-additional-content">
			{data.additionalContent}
		</div>
		{data.type === ITreeUserGroupsItemType.LEADER && (
			<div className="entities-filters__resp-users-item-leader">
				Руководитель
			</div>
		)}
	</div>
);

export const RespUsersFilter = memo(({
	respUsers, objectId
}: IRespUsersFilterProps) => {
	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedUsers, setSelectedUsers] = useState(respUsers);
	const [expandedUsers, setExpandedUsers] = useState<string[]>([]);
	const [search, setSearch] = useState('');
	useUpdateEffect(() => setSelectedUsers(respUsers), [respUsers]);

	const [tabIndex, setTabIndex] = useState(0);
	const clearSearch = useCallback(() => setSearch(''), []);

	const users = useAppSelector(extractUsersAsArray);
	const filteredData = useMemo(() => {
		if (tabIndex !== 0) { return undefined; }

		return flatFilter(users, 'fullName', search);
	}, [tabIndex, users, search]);

	const usersMap = useAppSelector(extractUsersAsMap);
	const groups = useAppSelector(s =>
		extractPerformersGroupsAsArray(s, objectId));
	const treeData = useMemo(() => {
		if (tabIndex !== 1) { return []; }

		return makeUserGroupsTreeData(groups, usersMap);
	}, [tabIndex, groups, usersMap]);
	const {treeFilteredData, expanded} = useMemo(() => {
		if (tabIndex !== 1) { return {}; }

		const {filteredData: treeFilteredData, expanded} = treeFilter(treeData, 'name', search);
		return {treeFilteredData, expanded};
	}, [tabIndex, treeData, search]);

	useUpdateEffect(() => {
		if (expanded) {
			setExpandedUsers(expanded?.map(item => item.id));
		}
	}, [expanded]);

	const handleApply = useCallback(() => {
		dispatch({respUsers: selectedUsers});
		clearSearch();
	}, [selectedUsers, dispatch, clearSearch]);

	const handleChange = useCallback((v?: string[]) => {
		setSelectedUsers(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedUsers([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedUsers([]);
		dispatch({respUsers: undefined});
		clearSearch();
	}, [dispatch, clearSearch]);

	const handleCancel = useCallback(() => {
		setSelectedUsers(respUsers);
		clearSearch();
	}, [respUsers, clearSearch]);

	const handleSelectAll = useCallback(() => {
		switch (tabIndex) {
			case 0: {
				setSelectedUsers(users.map(user => user.id));
				break;
			}

			case 1: {
				const allUsers = groups.map(group => {
					const groupUsers = [];
					if (group.performers) {
						groupUsers.push(...group.performers);
					}
					if (group.leader) {
						groupUsers.push(group.leader);
					}
					return groupUsers;
				});
				setSelectedUsers(allUsers.flat());
				break;
			}
		}
		clearSearch();
	}, [users, clearSearch]);

	const handleChangeTab = useCallback((index: number) => {
		handleClear();
		setTabIndex(index);
	}, [handleClear]);

	return users && users.length ? (
		<div>
			<SelectPopup
				className="entities-filters-with-tabs"
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				onSelectAll={handleSelectAll}
				clearButton={!!selectedUsers?.length}
				count={selectedUsers?.length}
				footer
				tabs={(
					<Tabs
						links={links}
						activeTab={tabIndex}
						onActiveTabChange={handleChangeTab}
					/>
				)}
				trigger={(
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel<IBriefUser>('Ответственные', respUsers, users)}
						active={!!respUsers?.length}
						onClear={handleFullClear}
					/>
				)}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
						type="popup"
					/>
				)}
			>
				<Select2
					multiple
					value={selectedUsers}
					onChange={handleChange}
				>
					<>
						{tabIndex === 0 && filteredData !== undefined && (
							<Select2
								multiple
								value={selectedUsers}
								onChange={handleChange}
							>
								{filteredData?.map(item => (
									<SelectOption
										key={item.id}
										itemKey={item.id}
										content={item?.fullName}
									/>
							))}
							</Select2>
						)}
						{tabIndex === 1 && treeFilteredData !== undefined && (
							<TreeSelect
								data={treeFilteredData}
								multiple
								value={selectedUsers}
								onChange={handleChange}
								expandedValue={expandedUsers}
								onExpand={setExpandedUsers}
								TreeItem={props => (
									<TreeSelectOption
										{...props}
										getContent={getContent}
									/>
								)}
							/>
						)}
					</>
				</Select2>
			</SelectPopup>
		</div>
	) : null;
});
