import React, {memo} from 'react';
import './UserAvatar.less';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import classNames from 'classnames';
import {isBrightColor} from '../../../utils/isBrightColor';

export type UserAvatarSize = '34' | '40' | '56';

export type UserAvatarColoring = 'background' | 'text';

interface IUserAvatarProps {
	className?: string;
	style?: React.CSSProperties;
	user?: IBriefUser;
	size?: UserAvatarSize;
	coloring?: UserAvatarColoring;
}

const UserAvatar = ({className, style, user, size = '34', coloring = 'background'}: IUserAvatarProps) => {
	const initials = user?.displayName
		?.split(' ')
		.slice(0, 2)
		.map(item => item.charAt(0).toUpperCase())
		.join('');

	const coloredStyle = {...style};
	if (user?.color) {
		if (coloring === 'background') {
			coloredStyle.backgroundColor = user.color;
		} else {
			coloredStyle.color = user.color;
		}
	}

	return (
		<div
			className={classNames(
				'user-avatar',
				`user-avatar_coloring_${coloring}`,
				`user-avatar_size_${size}`,
				user?.color ? {'user-avatar_dark': isBrightColor(user.color)} : undefined,
				className
			)}
			style={coloredStyle}
		>
			{initials}
		</div>
	);
};

export default memo(UserAvatar);
