import React, {Dispatch, useCallback, SetStateAction, useState} from 'react';
import {
	IEditableCategoriesSetAction,
	IEditableCategoriesSetState,
	IEditableCategory
} from '@src/core/hooks/states/useCategoriesSetState';
import {addErrorToast} from '@src/utils/toasts';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {IDeleteCategoryResponse} from '@src/api/backend/categories';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';
import {EtitableSet} from '@tehzor/ui-components/src/components/EditableSet';
import {useUpdateEffect} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editCategoriesOrder} from '@src/store/modules/dictionaries/categories/actions/edit-order';

interface IEDitableCategoriesProps {
	isChildren?: boolean;
	isEditable?: boolean;
	index?: string;
	edited: string[];
	setEdited: Dispatch<SetStateAction<string[]>>;
	editingState: IEditableCategoriesSetState;
	editingDispatch: Dispatch<IEditableCategoriesSetAction>;
	visibleCategories: IEditableCategory[];
	allCategoriesBySet?: ICategory[];
	handleCreate: (parent?: ISavingCategory) => void;
	update: (id: string, index: number) => Promise<ICategory | undefined>;
	remove: (id: string) => Promise<IDeleteCategoryResponse | undefined>;
}

/**
 * Возвращает массив видов работ без удаленных видов,
 * связанных с идентификатором и массив удаленных видов работ
 *
 * @param id Идентификатор вида работ
 * @param categories массив видов работ
 * @returns Возвращает filteredArr и removedArr
 */
const getFilteredArrays = (
	id: string,
	categories: IEditableCategory[]
): [IEditableCategory[], IEditableCategory[]] => {
	const removedArr = categories.filter(val => val?.parentId === id || val.id === id);
	let filteredArr = categories.filter(val => val?.parentId !== id && val.id !== id);

	for (const removedItem of removedArr) {
		filteredArr = filteredArr.filter(val => {
			if (val?.parentId === removedItem.id) {
				removedArr.push(val);
				return false;
			}
			return true;
		});
	}

	return [filteredArr, removedArr];
};

export const EditableCategories = (props: IEDitableCategoriesProps) => {
	const {
		edited,
		setEdited,
		editingState,
		editingDispatch,
		visibleCategories,
		allCategoriesBySet,
		index = '',
		isChildren,
		isEditable,
		handleCreate,
		update,
		remove
	} = props;

	const categories = editingState.categories.sort((a, b) =>
		 (a.order !== undefined && b.order !== undefined ? a.order - b.order : 0));
	const dispatch = useAppDispatch();

	const [current, setCurrent] = useState<string | undefined>(undefined);

	useUpdateEffect(() => {
		const length = editingState.categories.length;

		if (length) {
			const lastCategory = categories[length - 1];
			setCurrent(lastCategory.id);
		}
	}, [editingState.categories.length]);

	const handleNameChange = useCallback(
		(name: string, id: string) => {
			const i = editingState.categories.findIndex(category => category.id === id);

			const changedCategory = categories[i];
			editingDispatch({
				type: 'update-array-item',
				field: 'categories',
				value: {...changedCategory, name},
				index: i
			});
		},
		[categories]
	);

	const handleChangeEdit = useCallback((id: string) => {
		// const {categories: editingCategories} = editingState;
		const index = categories.findIndex(item => item.id === id);
		const category = categories[index];

		if (!edited || !category) { return; }
		setEdited(s => [...s, id]);
		setCurrent(id);
	}, [edited, categories]);

	const handleConfirm = useCallback(async (id: string) => {
		// const {categories: editingCategories} = editingState;
		const index = categories.findIndex(item => item.id === id);
		const category = categories[index];

		if (!edited || !category) { return; }
		if (!category.name) {
			addErrorToast('Ошибка', 'Необходимо указать наименование вида работ');
			return;
		}

		setEdited([...edited.filter(editId => editId !== id)]);
		const value = await update(id, index);

		if (!value) { return; }

		setCurrent(undefined);
		editingDispatch({type: 'update-array-item', field: 'categories', index, value});
	}, [edited, categories]);

	const handleCancel = useCallback((id: string) => {
		setCurrent(undefined);

		// const {categories: editingCategories} = editingState;
		const index = categories.findIndex(item => item.id === id);
		const value = allCategoriesBySet?.find(item => item.id === id);

		if (index === -1) { return; }
		if (!value) {
			editingDispatch({type: 'delete-array-item', field: 'categories', index: [index]});
			setEdited([...edited.filter(editId => editId !== id)]);
			return;
		}

		editingDispatch({type: 'update-array-item', field: 'categories', index, value});
		setEdited([...edited.filter(editId => editId !== id)]);
	}, [categories, allCategoriesBySet, edited]);

	const handleRemove = useCallback(
		async (id: string) => {
			// const {categories: editingCategories} = editingState;
			const [filteredArr, removedArr] = getFilteredArrays(id, categories);

			const promises: Array<Promise<IDeleteCategoryResponse | undefined>> = [];
			removedArr.forEach(item => {
				promises.push(remove(item.id));
			});

			await Promise.all(promises);

			editingDispatch({type: 'update', field: 'categories', value: filteredArr});
			if (categories.length <= 1) {
				handleCreate();
			}
		},
		[categories]
	);

	const handleSaveDrop = useCallback(
		(newCategories: IEditableCategory[]) => {
			const newOrder = newCategories.map((item, index) => ({
				id: item.id,
				order: index
			}));
			void dispatch((editCategoriesOrder(newOrder)));
		},
		[dispatch]
	);

	return (
		<EtitableSet
			items={categories}
			visibleItems={visibleCategories}
			edited={edited}
			index={index}
			onNameChange={handleNameChange}
			onCreate={handleCreate}
			onRemove={handleRemove}
			onChangeEdit={handleChangeEdit}
			onConfirm={handleConfirm}
			onCancel={handleCancel}
			isChildren={!!isChildren}
			isEditable={!!isEditable}
			current={current}
			setCurrent={setCurrent}
			onSaveDrop={handleSaveDrop}
		/>

	);
};