import React, {useCallback, useEffect} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {changeSchemaView} from '@src/store/modules/settings/pages/spaces/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {useCheckRecordsPermissions} from '@src/core/hooks/permissions/useCheckRecordsPermissions';

const tabs = [<TabLink label="Нарушения"/>, <TabLink label="Чек-листы"/>, <TabLink label="Отчёты"/>];
const tabsIndexes = {
	[SpacesSchemaVariants.PROBLEMS]: 0,
	[SpacesSchemaVariants.CHECK_LISTS]: 1,
	[SpacesSchemaVariants.REPORTS]: 2
};
const tabsKeys = [SpacesSchemaVariants.PROBLEMS, SpacesSchemaVariants.CHECK_LISTS, SpacesSchemaVariants.REPORTS];

interface IDesktopSchemaViewTabsProps {
	objectId: string;
}

export const DesktopSchemaViewTabs = ({objectId}: IDesktopSchemaViewTabsProps) => {
	const {schemaView} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const permissions = useCheckRecordsPermissions(objectId);

	// Если в настройках сохранён недоступный вариант, то сбрасываем на нарушения
	useEffect(() => {
		if (!permissions.canView && schemaView !== SpacesSchemaVariants.PROBLEMS) {
			dispatch(changeSchemaView(objectId, SpacesSchemaVariants.PROBLEMS));
		}
	}, [permissions.canView]);

	const handleChange = useCallback(
		(index: number) => {
			dispatch(changeSchemaView(objectId, tabsKeys[index]));
		},
		[objectId]
	);

	return permissions.canView ? (
		<Tabs
			className={{
				root: 'spaces-page__plate-header-tabs',
				links: 'spaces-page__plate-header-tabs-links'
			}}
			links={tabs}
			activeTab={tabsIndexes[schemaView]}
			onActiveTabChange={handleChange}
		/>
	) : null;
};
