import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';

export const createMarkerCommentDifference = (
	prev: {
		name: string | undefined;
		description: string | undefined;
	} | undefined,
	next: {
		name: string | undefined;
		description: string | undefined;
	} | undefined
): IHistoryData => ({
	prev,
	next,
	type: HistoryTypeId.MARKER_COMMENT,
	name: 'комментарий к местоположению'
});
