import React from 'react';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import DesktopMenu from './Menu.desktop';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckProblems} from '@src/store/modules/entities/check/selectors';
import {useCheckPermissions} from '@src/core/hooks/permissions/useCheckPermissions';
import {changeEntitiesVisibility} from '@src/store/modules/settings/pages/check/actions';
import VisibilityFilter from '@src/components/VisibilityFilter';

const visibilityTitles = {
	problems: 'Нарушения',
	inspections: 'Осмотры'
};

interface IDesktopActionsProps {
	objectId: string;
	check: ICheck;
}

const DesktopActions = ({objectId, check}: IDesktopActionsProps) => {
	const problems = useAppSelector(extractCheckProblems);
	const permissions = useCheckPermissions(objectId, check, problems);
	const visibility = useAppSelector(s => s.settings.pages.check.entitiesVisibility);

	return (
		<div className="check-page__d-controls">
			<div className="check-page__d-controls-left">
				<VisibilityFilter
					value={visibility}
					titlesMap={visibilityTitles}
					onChange={changeEntitiesVisibility}
				/>
			</div>

			<div className="check-page__d-controls-right">
				<DesktopMenu
					objectId={objectId}
					check={check}
					permissions={permissions}
				/>
			</div>
		</div>
	);
};

export default DesktopActions;
