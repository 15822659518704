import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import StatusesFilterPage from '../StatusesFilterPage';
import {extractSpaceIndicatorsAsArray} from '@src/store/modules/dictionaries/spaceIndicatorsSets/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const SpacesStatusesFilterPage = () => {
	const {objectId, entity} = useStrictParams<{objectId: string, entity: string}>();
	const indicators = useAppSelector(s => extractSpaceIndicatorsAsArray(s, objectId));
	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={indicators}
			label="Индикаторы помещений"
			filterName="indicators"
		/>
	);
};

export default SpacesStatusesFilterPage;
