import React from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {deleteCheck} from '@src/store/modules/entities/check/actions';
import {deleteWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import {deleteOwnerAcceptance} from '@src/store/modules/entities/ownerAcceptance/actions';
import {getObjectStats} from '@src/store/modules/dictionaries/objects/actions';
import {deleteInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/actions';
import {deleteLocalCheck} from '@src/actions/local-entities/checks';
import {deleteLocalInternalAcceptance} from '@src/actions/local-entities/internalAcceptance';
import {deleteLocalOwnerAcceptance} from '@src/actions/local-entities/ownerAcceptance';
import {deleteLocalWarrantyClaim} from '@src/actions/local-entities/warrantyClaim';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: ISpaceEntity[];
}

const SelectedEntitiesDeletion = ({selectedEntities}: ISelectedEntitiesDeletionProps) => {
	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить нарушения/осмотры/проверки/обращения',
		'Вы действительно хотите удалить выбранные нарушения/осмотры/проверки/обращения?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			let subRowsNumber = 0;
			const objectsIds: string[] = [];
			for (const {type, data, subRows} of selectedEntities) {
				switch (type) {
					case 'problem':
						if (!subRowsNumber) {
							await dispatch(deleteProblem(data.objectId, data.id));
							objectsIds.push(data.objectId);
						}
						break;
					case 'inspection':
						if (!subRowsNumber) {
							await dispatch(deleteInspection(data.objectId, data.id));
						}
						break;
					case 'check':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						await dispatch(deleteCheck(data.objectId, data.id));
						break;
					case 'local-check':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalCheck(data.id));
						break;
					case 'internal-acceptance':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						await dispatch(deleteInternalAcceptance(data.objectId, data.id));
						break;
					case 'local-internal-acceptance':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalInternalAcceptance(data.id));
						break;
					case 'warranty-claim':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						await dispatch(deleteWarrantyClaim(data.objectId, data.id));
						break;
					case 'local-warranty-claim':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalWarrantyClaim(data.id));
						break;
					case 'owner-acceptance':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						await dispatch(deleteOwnerAcceptance(data.objectId, data.id));
						break;
					case 'local-owner-acceptance':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalOwnerAcceptance(data.id));
						break;
				}
			}
			if (objectsIds.length > 0) {
				await dispatch(getObjectStats(objectsIds));
			}
		}
	}, [selectedEntities]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete"/>
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
