import React from 'react';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IStatus} from './IStatus';
import Menu from '../../../../menu/Menu';
import {Scrollbar} from '../../../../containers';
import classNames from 'classnames';
import {getMenuItems} from './getMenuItems';

interface IGetRenderMenu <S extends IStatus> {
	selectedStatus?: S;
	statuses?: INormalizedData<S>;
	availableStatuses?: string[];
	onChange?: (value: string) => void;
}

export const getRenderMenu = <S extends IStatus>({
	selectedStatus, statuses, availableStatuses, onChange
}: IGetRenderMenu<S>) => {
	const statusesArray = statuses ? statuses.allIds.map(id => statuses.byId[id]) : undefined;
	const menuItems = getMenuItems({
		selectedStatus, statuses: statusesArray, availableStatuses
	});

	return menuItems ? (
		<Scrollbar
			className={classNames(
				'status-select__scrollbar', 'base-status-select__scrollbar'
			)}
		>
			<Menu
				value={selectedStatus?.id}
				onChange={onChange}
			>
				{menuItems}
			</Menu>
		</Scrollbar>
	) : undefined;
};
