import React, {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton, Select2, SelectOption, SelectSearch} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {extractSpaceOwnersAsArray} from '@src/store/modules/dictionaries/spaceOwners/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const SpaceOwnersFilterPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{spaceOwners?: string[]}>();
	const [search, setSearch] = useState('');
	const [selected, setSelected] = useState(state.spaceOwners);

	const spaceOwners = useAppSelector(extractSpaceOwnersAsArray);
	const filteredData = useMemo(
		() => flatFilter(spaceOwners, 'name', search),
		[spaceOwners, search]
	);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({spaceOwners: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: 'Собственник',
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label="Сбросить"
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={isLocalStateEqual(state.spaceOwners, selected)}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
				/>
			)}
			onApplyClick={handleApply}
		>
			<Select2
				multiple
				value={selected}
				onChange={setSelected}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</FilterPage>
	);
};
