import {wsConnector} from '@src/api/backend/wsConnector';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';

export type IEditCategoryResponse = ICategory;

/**
 * Изменяет вид работ
 *
 * @param category данные вида работ
 */
export const requestEditCategory = (category: ISavingCategory) =>
	wsConnector.sendAuthorizedRequest<IEditCategoryResponse>('editCategory', {
		...category
	});
