import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formObjectsChain} from './useObjectBreadcrumbs';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {extractSpace} from '@src/store/modules/entities/space/selectors';

/**
 * Хук для хлебных крошек от "всех объектов" до чек-листов помещения
 *
 * @param objectId id конечного объекта
 */
export function useCheckListBreadcrumbs(objectId: string) {
	const objects = useAppSelector(s => s.dictionaries.objects);
	const space = useAppSelector(extractSpace);
	const spaceTypes = useAppSelector(s => s.dictionaries.spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	return useMemo(() => {
		const items = formObjectsChain(objects.byId, objectId);

		if (space) {
			items.push({label: 'Помещения', url: `/objects/${objectId}/spaces`, inactive: false});

			items.push({
				label: formSpaceTitle(
					space.name,
					space.altName,
					spaceTypes.byId[space.type],
					altNamesVisible
				),
				url: formSpaceLink(objectId, space.id),
				inactive: false
			});
		}

		items.push({
			label: 'Чек-листы',
			url: '',
			inactive: true
		});

		return items;
	}, [objectId, space, objects, spaceTypes]);
}
