import React, {useCallback, useState} from 'react';
import {GenerateSpacesDialog} from '../components/generateSpaces/GenerateSpacesDialog';

export const useGenerateSpacesDialog = (objectId: string): [React.ReactNode, () => void] => {
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<GenerateSpacesDialog
			objectId={objectId}
			isOpen={isOpen}
			onClose={close}
		/>
);

	return [dialog, open];
};
