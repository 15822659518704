import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const extractAllIds = (state: IState) => state.dictionaries.exportTemplatesDestinations.allIds || [];
const extractById = (state: IState) => state.dictionaries.exportTemplatesDestinations.byId || {};

/**
 * Возвращает массив destinations для шаблонов экспорта
 */
export const extractExportTemplateDestinationsAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);
