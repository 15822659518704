const domain = 'checks';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const GET_SELECTION_REQUEST = `${domain}/get-selection/request`;
export const GET_SELECTION_SUCCESS = `${domain}/get-selection/success`;
export const GET_SELECTION_FAILURE = `${domain}/get-selection/failure`;

export const CHANGE_OFFSET = `${domain}/offset/change`;
export const CHANGE_SELECTED_ROWS = `${domain}/selected-rows/change`;
export const CLEAR_EXPIRATION = `${domain}/clear-expiration`;

export const EXPORT_REQUEST = `${domain}/export/request`;
export const EXPORT_SUCCESS = `${domain}/export/success`;
export const EXPORT_FAILURE = `${domain}/export/failure`;

export const SEND_REQUEST = `${domain}/send/request`;
export const SEND_SUCCESS = `${domain}/send/success`;
export const SEND_FAILURE = `${domain}/send/failure`;
