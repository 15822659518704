import {useWorkAcceptanceExport} from '@src/core/hooks/export/useWorkAcceptanceExport';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface ISelectedEntities {
	problems: string[];
}

const splitSelectedEntities = (selectedEntities: IWorkAcceptanceEntity[]) =>
	selectedEntities.reduce((acc: ISelectedEntities, item) => {
		switch (item.type) {
			case 'problem': {
				acc.problems.push(item.data.id);
				break;
			}
		}
		return acc;
	}, {problems: []});

export const useWorkAcceptanceCombinedExport = (
	selectedEntities: IWorkAcceptanceEntity[],
	objectId: string,
	checkLists?: ICheckList[]
) => {
	const checkListIds = checkLists?.map(({id}) => id);
	const {problems} = splitSelectedEntities(selectedEntities);

	return useWorkAcceptanceExport(objectId, checkListIds, problems);
};