import React from 'react';
import './InternalAcceptancesPage.less';
import {LoadingPanel} from '@tehzor/ui-components';
import classNames from 'classnames';
import {InternalAcceptancesTable} from './components/table/InternalAcceptancesTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsync from 'react-use/lib/useAsync';
import {getInternalAcceptances} from '@src/store/modules/entities/internalAcceptances/actions';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {ObjectPageBreadcrumbs} from './components/ObjectPageBreadcrumbs';
import {AllObjectPageBreadcrumbs} from './components/AllPageBreadcrumbs';
import ActionsDesktop from './components/actions/Actions.desktop';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {InternalAcceptancesSearch} from './components/InternalAcceptancesSearch';
import {InternalAcceptancesFilters} from './components/InternalAcceptancesFilters';
import {InternalAcceptancesPageCounter} from './components/InternalAcceptancesPageCounter';
import {getInternalAcceptancesProblemsData} from '@src/store/modules/pages/internalAcceptances/actions/getInternalAcceptancesProblemsData';
import {getInternalAcceptancesInspectionsData} from '@src/store/modules/pages/internalAcceptances/actions/getInternalAcceptancesInspectionsData';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useParams} from 'react-router-dom';

interface IInternalAcceptancesPageProps {
	className?: string;
}

export const InternalAcceptancesPage = ({className}: IInternalAcceptancesPageProps) => {
	useScrollRestoration();
	const {state} = useEntitiesFiltersCtx();
	const {objectId} = useParams<{objectId?: string}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const loadingState = useAsync(async () => {
		if (networkStatus) {
			const response = await dispatch(getInternalAcceptances(objectId || 'all', state));

			if (response) {
				const internalAcp = response.allIds.map(id => ({
					objectId: response.byId[id].objectId,
					internalAcceptanceId: response.byId[id].id
				}));
				void dispatch(
					getInternalAcceptancesProblemsData({internalAcceptances: internalAcp})
				);
				void dispatch(
					getInternalAcceptancesInspectionsData({internalAcceptances: internalAcp})
				);
			}
		}
	}, [objectId, networkStatus, state]);

	const actions = isDesktop ? <ActionsDesktop objectId={objectId}/> : null;

	return (
		<LoadingPanel
			className="internal-acceptances-page__loading-panel"
			active={loadingState.loading}
		>
			<div className={classNames('page-cont', 'internal-acceptances-page', className)}>
				{objectId !== undefined ? (
					<ObjectPageBreadcrumbs objectId={objectId}>{actions}</ObjectPageBreadcrumbs>
				) : (
					isDesktop && <AllObjectPageBreadcrumbs>{actions}</AllObjectPageBreadcrumbs>
				)}
				<div className="internal-acceptances-page__filters-bar">
					{!isDesktop && (
						<div className="internal-acceptances-page__search">
							<InternalAcceptancesSearch/>
						</div>
					)}
					<InternalAcceptancesFilters objectId={objectId}/>
				</div>
				<InternalAcceptancesPageCounter objectId={objectId}/>
				<InternalAcceptancesTable objectId={objectId}/>
			</div>
		</LoadingPanel>
	);
};
