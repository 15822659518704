import React from 'react';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {makeFirstLetterLowercase} from '@tehzor/tools/utils/firstLetter';

interface IDescriptionProps {
	data: IProblemComment;
	parent?: IProblemComment;
	showAppeal?: boolean;
}

const Description = ({data, parent, showAppeal}: IDescriptionProps) => {
	if (!showAppeal || parent?.createdBy === undefined) {
		return (
			<span className="problem-comment__desc-text">{data.description}</span>
		);
	}
	return (
		<>
			<span className="problem-comment__desc-appeal">
				{parent.createdBy.displayName}
				,
				{' '}
			</span>
			<span className="problem-comment__desc-text">
				{makeFirstLetterLowercase(data.description)}
			</span>
		</>
	);
};

export default Description;