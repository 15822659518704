import {IWebWorkerMessage} from '../../IWebWorkerMessage';
import {CACHE_OFFLINE_DATA_ACTION_TYPE} from '../constants';

export interface ICacheOfflineDataAction extends IWebWorkerMessage {
	type: 'offline-data/cache';
	entities?: string[];
	forceUpdate?: boolean;
}

/**
 * Формирует данные для действия кеширования данных
 *
 * @param entities сущности, которые необходимо закешировать
 * @param forceUpdate игнорировать ли срок действия кеша
 */
export const formCacheOfflineDataAction = (entities?: string[], forceUpdate?: boolean): ICacheOfflineDataAction => ({
	type: CACHE_OFFLINE_DATA_ACTION_TYPE,
	entities,
	forceUpdate
});
