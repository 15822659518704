import {makeCheckAddRequest} from '@src/api/backend/check';
import {addCheckActions, deleteCheckActions} from '@src/store/modules/entities/check/actions';
import {useQueryClient} from '@tanstack/react-query';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useEffect} from 'react';
import {useAddChildren} from '../../hooks/useAddChildren';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {checksQueryKeys} from '../keys';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {updateCachedState} from '@src/utils/updateCachedState';

export interface IAddCheckParams {
	key: string;
	objectId: string;
	links: ICheck['links'] | undefined;
	stage?: ObjectStageIds;
}
/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useChecksMutationDefaults = () => {
	const queryClient = useQueryClient();
	const [addProblems, addInspections] = useAddChildren();
	const {updateEntity} = useUpdateEntity(checksQueryKeys);
	useEffect(() => {
		queryClient.setMutationDefaults(checksQueryKeys.add(), {
			mutationFn: async (params: IAddCheckParams) => {
				const {objectId, links, stage, key} = params;
				await updateCachedState<ICheck>(
					checksQueryKeys,
					key,
					OfflineDataTransferStatus.TRANSFER
				);
				return makeCheckAddRequest(objectId, links, stage);
			},
			onSuccess: async (newCheck: ICheck, {key}: IAddCheckParams) => {
				await updateCachedState<ICheck>(
					checksQueryKeys,
					key,
					OfflineDataTransferStatus.TRANSFER_COMPLETE
				);
				addProblems(key, newCheck.id, 'checkId');
				addInspections(key, newCheck.id, 'checkId');
				await queryClient.invalidateQueries(checksQueryKeys.list());
				updateEntity<ICheck>(
					newCheck,
					deleteCheckActions.success,
					addCheckActions.success,
					key
				);
				await queryClient.refetchQueries({
					queryKey: checksQueryKeys.localList()
				});
				await queryClient.invalidateQueries({
					queryKey: checksQueryKeys.latest()
				});
			},
			onError: async (_, {key}: IAddCheckParams) => {
				await updateCachedState<ICheck>(
					checksQueryKeys,
					key,
					OfflineDataTransferStatus.TRANSFER_ERROR
				);
			}
		});
	}, []);
};
