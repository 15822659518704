import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {requestInternalAcceptances} from '@src/api/backend/internalAcceptances';

export type IGetSpaceInternalAcceptancesPayload = INormalizedData<IListInternalAcceptance>;

const request = () => ({type: types.GET_INTERNAL_ACCEPTANCES_REQUEST});

const success = (response: IGetSpaceInternalAcceptancesPayload) => ({
	type: types.GET_INTERNAL_ACCEPTANCES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке проверок');
	}
	return {
		type: types.GET_INTERNAL_ACCEPTANCES_FAILURE,
		payload: error
	};
};

/**
 * Получает список проверок для конкретного помещения
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 */
export const getSpaceInternalAcceptances = (objectId: string, spaceId: string) =>
checkExpiration<IState, IGetSpaceInternalAcceptancesPayload, ApiAction>(
		s => s.entities.space.internalAcceptances,
		createApiAction<IGetSpaceInternalAcceptancesPayload>(request, success, failure, () =>
			requestInternalAcceptances(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);
