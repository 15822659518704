import React, {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeFilters,
	clearFilters
} from '@src/store/modules/settings/pages/offlineJournal/actions';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {IOfflineJournalFiltersState} from '@src/store/modules/settings/pages/offlineJournal/reducers';
import {OfflineJournalFilterPage} from '../OfflineJournalFilterPage';

const OfflineJournalFiltersRoutingPage = () => {
	const filters = useAppSelector(s => s.settings.pages.offlineJournal);
	const dispatch = useAppDispatch();

	const applyFilters = useCallback(
		(value: IOfflineJournalFiltersState) => {
			dispatch(changeFilters(value));
		},
		[dispatch]
	);

	return (
		<MobileEntitiesFilters
			filters={filters}
			onApply={applyFilters}
			onClear={clearFilters}
		>
			<OfflineJournalFilterPage/>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};

export default OfflineJournalFiltersRoutingPage;
