import React from 'react';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import DesktopMenu from './Menu.desktop';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInternalAcceptanceProblems} from '@src/store/modules/entities/internalAcceptance/selectors';
import {useInternalAcceptancePermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';
import {changeEntitiesVisibility} from '@src/store/modules/settings/pages/internalAcceptance/actions';
import VisibilityFilter from '@src/components/VisibilityFilter';

const visibilityTitles = {
	problems: 'Нарушения',
	inspections: 'Осмотры'
};

interface IDesktopActionsProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
}

const DesktopActions = ({objectId, internalAcceptance}: IDesktopActionsProps) => {
	const problems = useAppSelector(extractInternalAcceptanceProblems);
	const permissions = useInternalAcceptancePermissions(objectId, internalAcceptance, problems);
	const visibility = useAppSelector(s => s.settings.pages.internalAcceptance.entitiesVisibility);

	return (
		<div className="internal-acceptance-page__d-controls">
			<div className="internal-acceptance-page__d-controls-left">
				<VisibilityFilter
					value={visibility}
					titlesMap={visibilityTitles}
					onChange={changeEntitiesVisibility}
				/>
			</div>

			<div className="internal-acceptance-page__d-controls-right">
				<DesktopMenu
					objectId={objectId}
					internalAcceptance={internalAcceptance}
					canDeleteInternalAcceptance={permissions.canDelete}
				/>
			</div>
		</div>
	);
};

export default DesktopActions;
