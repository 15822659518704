import React, {useCallback} from 'react';
import {SwitchMenu, SwitchMenuOption} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useTasksPageDisplayMode} from '@src/core/hooks/queries/tasks';
import {TasksDisplayMode} from '@src/interfaces/TasksDisplayMode';
import {changeDisplayMode} from '@src/store/modules/settings/pages/tasks/actions';

export const DesktopSwitchMode = () => {
	const displayMode = useTasksPageDisplayMode();
	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		(value: TasksDisplayMode) => {
			dispatch(changeDisplayMode(value));
		},
		[dispatch]
	);

	return (
		<SwitchMenu
			value={displayMode}
			onChange={handleChange}
			className="tasks-page__control-bar-switch-menu"
		>
			<SwitchMenuOption value={TasksDisplayMode.SCHEDULE}>
				<i className="tz-calendar-24"/>
			</SwitchMenuOption>
			<SwitchMenuOption value={TasksDisplayMode.LIST}>
				<i className="tz-list-24"/>
			</SwitchMenuOption>
		</SwitchMenu>
	);
};
