import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import {WorkAcceptancesFiltersPage} from '@src/pages/filters/WorkAcceptancesFitlersPage/WorkAcceptancesFiltersPage';
import {WorkAcceptancesStatusesFilterPage} from '@src/pages/filters/WorkAcceptancesStatusesFilterPage';
import React from 'react';

export const workAcceptancesFilterRoutes = [
	{path: 'objects', element: <FiltersPageObjects/>},
	{
		path: 'workAcceptanceStatuses',
		element: <WorkAcceptancesStatusesFilterPage/>
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage/>
	},
	{
		path: '',
		element: <WorkAcceptancesFiltersPage/>
	}
];
