import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUsersAsMap} from '@src/store/modules/dictionaries/users/selectors';
import {EntityGridItem, UserInfo, WorkingGroupInfo} from '@tehzor/ui-components';
import React, {useMemo} from 'react';
import './Inspectors.less';
import {extractProblemInspectorsGroupsAsArray} from '@src/store/modules/dictionaries/workingGroups/selectors';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import classNames from 'classnames';

interface IInspectorsProps {
	inspectors: string[];
	objectId: string;
	stage: ObjectStageIds;
}

const inspectorIcon = <i className="tz-inspector-20"/>;
const markIcon = <i className="tz-mark-2-16 inspectors-view-field__items-item_dot-icon"/>;

export const Inspectors = ({inspectors, objectId, stage}: IInspectorsProps) => {
	const usersMap = useAppSelector(extractUsersAsMap);
	const inspectorsGroups = useAppSelector(s =>
		extractProblemInspectorsGroupsAsArray(s, objectId, stage));

	const firstInspectorId = inspectors?.[0];
	const firstInspector = firstInspectorId ? usersMap[firstInspectorId] : undefined;

	const lastInspectorId = inspectors?.[inspectors.length - 1];
	const lastInspector = lastInspectorId ? usersMap[lastInspectorId] : undefined;

	const userGroup = useMemo(() => {
		if (!firstInspectorId) { return undefined; }

		const groups: IWorkingGroup[] = [];
		for (const group of inspectorsGroups) {
			if (group.performers?.includes(firstInspectorId)) {
				groups.push(group);
			} else if (group.leader === firstInspectorId) {
				groups.push(group);
			}
		}

		return groups.length === 1 ? groups[0] : undefined;
	}, [inspectorsGroups, firstInspectorId]);

	const hiddenInspectors = useMemo(() => inspectors.filter((val, i, arr) => {
		if (!userGroup) {
			return i !== 0 && i !== arr.length - 1;
		}
		return i !== arr.length - 1;
	}), [inspectors, userGroup]);

	return userGroup || inspectors?.length > 1 ? (
		<EntityGridItem
			className="inspectors-view-field"
			label="Проверяющие"
			icon={inspectorIcon}
			fullRow
		>
			<div className="inspectors-view-field__body">
				<div className="inspectors-view-field__line"/>
				<div className="inspectors-view-field__items">
					<div
						className={classNames(
							'inspectors-view-field__items-item',
							'first',
							{showLine: !userGroup, hasIcon: !userGroup}
						)}
					>
						{userGroup ? (
							<>
								<div className="inspectors-view-field__items-item_dot"/>
								<WorkingGroupInfo
									className="inspectors-view-field__items-item_content"
									name={userGroup.name}
									leader={userGroup.leader ? usersMap[userGroup.leader] : undefined}
								/>
							</>
						) : (
							<>
								<div className="inspectors-view-field__items-item_dot">
									{markIcon}
								</div>
								<UserInfo
									className="inspectors-view-field__items-item_content"
									user={firstInspector}
									avatarSize="40"
									avatarColoring="text"
								/>
							</>
						)}
					</div>

					{hiddenInspectors.map(inspector => (
						<div
							className={classNames(
								'inspectors-view-field__items-item',
								'hasIcon',
								{showLine: hiddenInspectors.length >= 0}
							)}
							key={inspector}
						>
							<div className="inspectors-view-field__items-item_dot">
								{markIcon}
							</div>
							<UserInfo
								className="inspectors-view-field__items-item_content"
								user={usersMap[inspector]}
								avatarSize="40"
								avatarColoring="text"
							/>
						</div>
					))}

					<div className="inspectors-view-field__items-item last hasIcon">
						<div className="inspectors-view-field__items-item_dot">
							{markIcon}
						</div>
						<UserInfo
							className="inspectors-view-field__items-item_content"
							user={lastInspector}
							avatarSize="40"
							avatarColoring="text"
						/>
					</div>
				</div>
			</div>
		</EntityGridItem>
	) : (
		<EntityGridItem
			className="inspectors-view-field"
			label="Проверяющие"
			icon={inspectorIcon}
			fullRow
		>
			<div className="inspectors-view-field__body">
				<UserInfo
					user={lastInspector}
					avatarSize="40"
					avatarColoring="text"
				/>
			</div>
		</EntityGridItem>
	);
};