import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import React, {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {extractLegalsPageSettings} from '@src/store/modules/settings/pages/legals/selectors';
import {ILegalsFiltersState, legalsActions} from '@src/store/modules/settings/pages/legals/reducers';

export const LegalsFiltersRoutingPage = () => {
	const {filters} = useAppSelector(extractLegalsPageSettings);

	const dispatch = useAppDispatch();

	const handleApply = useCallback((value: ILegalsFiltersState) => {
		dispatch(legalsActions.changeFilters(value));
		dispatch(legalsActions.changeOffset(0));
	}, [dispatch]);

	return (
		<MobileEntitiesFilters
			filters={filters}
			onApply={handleApply}
		>
			<Outlet/>
		</MobileEntitiesFilters>
	);
};
