import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export function useStagesTitlesMap() {
	const stagesMap = useAppSelector(s => s.dictionaries.objectStages.byId);

	return useMemo(
		() =>
			Object.entries({all: {id: 'all', name: 'Все стадии'}, ...stagesMap}).reduce(
				(acc, [stageId, stage]) => {
					acc[stageId] = stage?.name || '';
					return acc;
				},
				{}
			) as Record<ObjectStageIds, string>,
		[stagesMap]
	);
}
