import {addErrorToast} from '@src/utils/toasts';
import {restDictionariesQueryKeys} from '../../../../api/cache/restDictionaries/keys/index';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetSpaceIndicatorsSetsResponse} from '@src/api/backend/spaceIndicatorsSets';
import {IGetObjectsResponse} from '@src/api/backend/objects';
import {normalizeData} from '@tehzor/tools/utils/normalizeData';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export const useGetSpaceIndicatorsSets = () => {
	const {data} = useQuery<IGetSpaceIndicatorsSetsResponse, IError>({
		queryKey: [...restDictionariesQueryKeys.spaceIndicatorsSets()],
		onError: () => {
			addErrorToast('Ошибка', 'при загрузке индикаторов помещения');
		}
	});
	return data || null;
};
/**
 * Функция возвращает индикаторы помещений в зависимости от флага normalize
 *
 * @param spaceIndicatorsSets все индикаторы
 * @param objectId id объекта
 * @param objects все объекты
 * @param normalize нормализовать или нет
 */
export function spaceIndicatorsAsArray(
	spaceIndicatorsSets: IGetSpaceIndicatorsSetsResponse | null,
	objectId: string,
	objects: IGetObjectsResponse
	): ISpaceIndicator[] | null;

export function spaceIndicatorsAsArray(
	spaceIndicatorsSets: IGetSpaceIndicatorsSetsResponse | null,
	objectId: string,
	objects: IGetObjectsResponse,
	normalize?: boolean
	): INormalizedData<ISpaceIndicator>;

export function spaceIndicatorsAsArray(
	spaceIndicatorsSets: IGetSpaceIndicatorsSetsResponse | null,
	objectId: string,
	objects: IGetObjectsResponse,
	normalize?: boolean
	)	{
	const spaceIndicatorSetId = objects?.byId[objectId]?.spaceIndicatorsSet || null;

	if (!spaceIndicatorSetId) return null;

	const spaceIndicatorSet = spaceIndicatorsSets?.byId[spaceIndicatorSetId];

	if (normalize) {
		const s = spaceIndicatorSet;
		return normalizeData<ISpaceIndicator>(s ? s.data : []);
	}

	return spaceIndicatorSet?.data || null;
}
