import React from 'react';
import './Indicators.less';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';

interface IIndicatorsProps {
	indicators: ISpaceIndicator[];
}

export const Indicators = ({indicators}: IIndicatorsProps) => (
	<>
		{indicators.map((indicator, index) => (
			<svg
				key={`${indicator.id}-${index}`}
				className="space__indicator"
				viewBox="0 0 6 6"
			>
				<circle
					cx="3"
					cy="3"
					r="3"
					fill={indicator.color}
				/>
			</svg>
		))}
	</>
);
