import classNames from 'classnames';
import React from 'react';
import {Button, IconButton} from '../buttons';

interface IItemDesktopOptionsProps<S> {
	item: S;
	isEdited?: boolean;
	isEditable?: boolean;
	onCreate: (parent?: S) => void;
	onRemove: (id: string) => void;
	onConfirm: (id: string) => void;
	onCancel: (id: string) => void;
}

const addIcon = <i className="tz-plus-20"/>;
const removeIcon = <i className="tz-delete"/>;

export const ItemDesktopOptions = <S extends { id: string, name?: string }>({
	item,
	isEdited,
	isEditable,
	onCreate,
	onRemove,
	onConfirm,
	onCancel
}: IItemDesktopOptionsProps<S>) => (
	<div className={classNames('editable-set__item-options')}>
		{isEditable && (
				isEdited ? (
					<div className="editable-set__item-options__edit">
						<Button
							label="Сохранить"
							type="accent-blue"
							onClick={() => onConfirm(item.id)}
						/>
						<Button
							label="Отменить"
							type="cancel"
							onClick={() => onCancel(item.id)}
						/>
					</div>
				) : (
					<div className="editable-set__item-options__view">
						{item.id[0] !== '_' && (
							<IconButton
								className="editable-set__item-options__view-add"
								type="inline-blue-accent"
								onClick={() => onCreate(item)}
							>
								{addIcon}
							</IconButton>
							)}
						<IconButton
							className="editable-set__item-options__view-delete"
							type="inline-red-accent"
							onClick={() => onRemove(item.id)}
						>
							{removeIcon}
						</IconButton>
					</div>
				)
			)}
	</div>
	);