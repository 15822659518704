const domain = 'problems';

export const GET_REQUEST = `${domain}/get/request`;
export const GET_SUCCESS = `${domain}/get/success`;
export const GET_FAILURE = `${domain}/get/failure`;

export const CHANGE_OFFSET = `${domain}/change-offset`;
export const CHANGE_SELECTED_ROWS = `${domain}/change-selected-rows`;
export const CLEAR_EXPIRATION = `${domain}/clear-expiration`;
export const CHANGE_LOADING = `${domain}/change-loading`;

export const EXPORT_REQUEST = `${domain}/export/request`;
export const EXPORT_SUCCESS = `${domain}/export/success`;
export const EXPORT_FAILURE = `${domain}/export/failure`;

export const SEND_REQUEST = `${domain}/send/request`;
export const SEND_SUCCESS = `${domain}/send/success`;
export const SEND_FAILURE = `${domain}/send/failure`;

export const UPDATE_PROBLEM_LOCATION = `${domain}/udpdate/problem/location`;
