import React from 'react';
import {CellProps} from 'react-table';
import './CategoryCell.less';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {extractCategoryById} from '@src/store/modules/dictionaries/categories/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';

export const CategoryCell = ({row}: CellProps<{data: IProblem}>) => {
	const category = useAppSelector(s => extractCategoryById(s, row.original.data.categoryId));
	if (!category) {
		return null;
	}
	return (
		<div>{category.name}</div>
	);
};
