import React, {memo, useMemo} from 'react';
import {Tree} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {makeObjectsTreeData} from '../utils/makeObjectsTreeData';
import {useFiltersDialogDispatch} from '../utils/FiltersDialogDispatchCtx';
import Controls from './Controls';
import {extractCompaniesAsArray} from '@src/store/modules/dictionaries/companies/selectors';
import {extractObjectsAsArray} from '@src/store/modules/dictionaries/objects/selectors';

interface IObjectsTabProps {
	checked: string[];
	expanded?: string[];
}

const ObjectsTab = ({checked, expanded}: IObjectsTabProps) => {
	const companies = useAppSelector(extractCompaniesAsArray);
	const objects = useAppSelector(extractObjectsAsArray);

	const treeData = useMemo(() => makeObjectsTreeData(companies, objects), [companies, objects]);

	const dispatch = useFiltersDialogDispatch();

	const handleCheck = (value: string[]) => {
		dispatch({type: 'update', field: 'checkedObjects', value});
	};

	const handleExpand = (value: string[]) => {
		dispatch({type: 'update', field: 'expandedObjects', value});
	};

	return (
		<>
			<Controls checkedField="checkedObjects"/>

			<div className="stats-filters-dialog__tree-wrap">
				<Tree
					className="stats-filters-dialog__tree"
					isCheckable
					checkedValue={checked}
					expandedValue={expanded}
					isAllCheckedExpanded
					multiple
					data={treeData}
					onCheck={handleCheck}
					onExpand={handleExpand}
				/>
			</div>
		</>
	);
};

export default memo(ObjectsTab);
