interface IInDayProps {
  currentDay: number;
  currentYear: number;
  currentMonth: number;
  start?: number;
  end?: number;
}

const daysName = [
  'ВС',
	'ПН',
	'ВТ',
	'СР',
	'ЧТ',
	'ПТ',
	'СБ'
];

export const getDayName = (dayIndex: number) => daysName[dayIndex];

export const createHeaderStr = (year: number, month: number, dayId: number) => {
  const date = new Date(year, month, dayId);
  const dayIndex = date.getDay();

  return `${dayId} ${getDayName(dayIndex)}`;
};

export const isCurrentDay = (year: number, month: number, dayId: string) => {
  const currentDate = new Date();

  const currentRealYear = currentDate.getFullYear();
	const currentRealMonth = currentDate.getMonth();
  const currentRealDay = currentDate.getDate();

	return currentRealYear === year && currentRealMonth === month && currentRealDay === parseInt(dayId);
};

export const inDay = (props: IInDayProps) => {
  const {currentYear, currentMonth, currentDay, start, end} = props;

  if (!start || !end) return false;

  const currentDayTime = new Date(currentYear, currentMonth, currentDay).getTime();

  return currentDayTime >= start && currentDayTime <= end;
};

export const createHeightForDayCell = (maxTasksCountInRow: number) => {
  if (maxTasksCountInRow >= 4) {
    return 6 * 28;
  }

  return (maxTasksCountInRow + 1) * 28;
};

export const calculateDayIndexInYear = (year: number, currentTaskStartDate: Date) => {
  const startYearDate = new Date(year, 0, 0);
	const diff = Number(currentTaskStartDate) - Number(startYearDate);
	return Math.floor(diff / (1000 * 60 * 60 * 24));
};