import React, {useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {
	DatePickerResult,
	FilterPage,
	InlineDateRangePicker,
	LinkButton
} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const ModifiedDateFilterPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{modifiedAtFrom?: Date, modifiedAtTo?: Date}>();
	const [from, setFrom] = useState(state.modifiedAtFrom);
	const [to, setTo] = useState(state.modifiedAtTo);
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({modifiedAtFrom: from, modifiedAtTo: to});
		goBack();
	}, [from, to, change]);

	const handleChange = useCallback((f: Date | null, t: Date | null) => {
		if (f) {
			setFrom(new Date(f.getFullYear(), f.getMonth(), f.getDate()));
		}
		if (t) {
			setTo(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59, 999));
		}
	}, []);

	const handleClear = useCallback(() => {
		setTo(undefined);
		setFrom(undefined);
	}, []);

	useAppHeader(
		{
			title: 'Дата изменения',
			showBackBtn: true,
			mobileRightButtons:
				from && to ? (
					<LinkButton
						label="Сбросить"
						onClick={handleClear}
					/>
) : null
		},
		[from, to]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={
				isLocalStateEqual(state.modifiedAtFrom, from)
				&& isLocalStateEqual(state.modifiedAtTo, to)
			}
			onApplyClick={handleApply}
		>
			<DatePickerResult
				style={{marginTop: '20px'}}
				value1={from}
				value2={to}
				placeholder1="Дата начала"
				placeholder2="Дата окончания"
				isRange
				dateFormat="dd.MM.yyyy"
			/>
			<InlineDateRangePicker
				style={{marginTop: '18px'}}
				valueFrom={from}
				valueTo={to}
				onChange={handleChange}
			/>
		</FilterPage>
	);
};
