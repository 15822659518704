import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import IListProblem from '@tehzor/tools/interfaces/problems/IListProblem';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export const toProblem = (
	object: IObject,
	links: IProblem['links'] | undefined,
	stage: ObjectStageIds | undefined,
	fields: ISavingProblem,
	key: string,
	nextNumber?: number,
	user?: IBriefUser
): IProblem => {
	const newProblem: IProblem = {
		...(fields as Omit<ISavingProblem, 'newAttachments'>),
		objectId: object.id,
		links,
		stage: stage || ObjectStageIds.BUILDING,
		id: key,
		status: ProblemStatusId.WAITING,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		plannedFixDate: fields.plannedFixDate || undefined,
		floor: fields.floor || undefined,
		location: fields.location || undefined,
		reason: fields.reason || undefined,
		categoryId: fields.categoryId || undefined,
		contractId: fields.contractId || undefined,
		localCreatedAt: Number(key),
		createdBy: user?.id
	};
	if (nextNumber) {
		newProblem.localNumber = `АВТ-${nextNumber}`;
	}
	return newProblem;
};

export const toListProblem = (
	object: IObject,
	links: IProblem['links'] | undefined,
	stage: ObjectStageIds | undefined,
	fields: ISavingProblem,
	key: string,
	nextNumber?: number,
	user?: IBriefUser
): IProblem => {
	const newProblem: IListProblem = {
		...(fields as Omit<ISavingProblem, 'newAttachments'>),
		objectId: object.id,
		links,
		stage: stage || ObjectStageIds.BUILDING,
		id: key,
		status: ProblemStatusId.WAITING,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		plannedFixDate: fields.plannedFixDate || undefined,
		floor: fields.floor || undefined,
		location: fields.location || undefined,
		reason: fields.reason || undefined,
		object: {id: object.id, name: object.name},
		categoryId: fields.categoryId || undefined,
		contractId: fields.contractId || undefined,
		localCreatedAt: Number(key),
		createdBy: user?.id
	};
	if (nextNumber) {
		newProblem.localNumber = `АВТ-${nextNumber}`;
	}
	return newProblem;
};
