import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import {addErrorToast} from '@src/utils/toasts';
import {checkExpiration} from '@tehzor/tools/core/storeHelpers/actions';
import {IGetProblemTagsResponse, requestProblemTags} from '@src/api/backend/problemTags';

export type IGetProblemTagsPayload = IGetProblemTagsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetProblemTagsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке меток нарушений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список меток нарушений
 */
export const getProblemTags = () =>
	checkExpiration<IState, IGetProblemTagsResponse, ApiAction>(
		s => s.dictionaries.problemTags,
		createApiAction<IGetProblemTagsResponse>(request, success, failure,
			requestProblemTags),
		86400000 // 1 день
	);
