import {createSelector} from 'reselect';
import {getInitialStateForPage, IInternalAcceptancesPagesState, IInternalAcceptancesPageState} from './reducers';
import {IState} from '@src/store/modules';

/**
 * Возвращает внутренние приёмки для конкретного объекта
 */
export const extractInternalAcceptancesData = createSelector(
	(state: IState) => state.entities.internalAcceptances,
	(state: IState, objectId: string) => objectId || 'all',
	(data: IInternalAcceptancesPagesState, objectId: string): IInternalAcceptancesPageState =>
		data[objectId] || getInitialStateForPage()
);

/**
 * Возвращает внутренние приёмки в виде массива
 */
export const extractInternalAcceptancesAsArray = createSelector([extractInternalAcceptancesData], data =>
	data.allIds.map(id => data.byId[id]));
