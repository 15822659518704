import React, {useMemo} from 'react';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {Diagram} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractCheckRecordByListId,
	extractCheckRecordsAsArrayByListId
} from '@src/store/modules/pages/checkLists/selectors/records';
import {IConvertedCheckItemData, IConvertedCheckListData} from '@tehzor/tools/interfaces/spaces/IConvertedCheckListData';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {getLatestModified} from '@src/utils/selectorUtils';
import {extractRootCheckItems} from '@src/store/modules/dictionaries/checkItems/selectors';

interface IDiagramProps {
	listId: string;
}

const useDiagramData = (listId: string): IConvertedCheckListData[] => {
	// все статусы
	const statusesMap = useAppSelector(s => s.dictionaries.checkRecordStatuses.byId);
	// все записи по данному чеклисту
	const records = useAppSelector(s => extractCheckRecordsAsArrayByListId(s, listId));
	// все категории чеклиста
	const items = useAppSelector(s => extractRootCheckItems(s, listId));

	const checkList = useAppSelector(s => s.dictionaries.checkLists.byId[listId]);
	const listRecord = useAppSelector(s => extractCheckRecordByListId(s, listId));
	const listStatus = statusesMap[listRecord?.status || CheckRecordStatusId.NOT_CHECKED];

	return useMemo(
		() => [
			{
				id: listId,
				name: checkList.name,
				value: items
					? items.length
					: 0,
				status: listStatus.id,
				statusName: listStatus.name,
				color: listStatus.color,
				items: items.reduce<IConvertedCheckItemData[]>((acc, item) => {
					if (item.parentId) {
						return acc;
					}

					const record = getLatestModified<ICheckRecord>(
						records.filter(el => el.links.checkItemId === item.id)
					);
					const status = statusesMap[record?.status || CheckRecordStatusId.NOT_CHECKED];

					acc.push({
						id: item.id,
						name: item.name,
						status: status.id,
						statusName: status.name,
						color: status.color
					});

					return acc;
				}, [])
			}
		],
		[listRecord, records, items, statusesMap]
	);
};

export const DiagramIcon = ({listId}: IDiagramProps) => {
	const data = useDiagramData(listId);

	return (
		<svg
			width={50}
			height={50}
			className="check-list-page__m-list-bullet"
		>
			<Diagram
				checkLists={data}
				width={54}
				height={54}
				radius={20}
				spaceId={listId}
			/>
		</svg>
	);
};
