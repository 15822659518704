import React from 'react';
import {EntityGridItem, InlineButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useEditableSpaceOwnerDialog} from '@src/components/EditableSpaceOwnerDialog/hooks/useEditableSpaceOwnerDialog';
import {useAddingSpaceOwnerDialog} from '@src/components/AddingSpaceOwnerDialog/hooks/useAddingSpaceOwnerDialog';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {extractSpaceOwnersForSpace} from '@src/store/modules/dictionaries/spaceOwners/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';

interface SpaceOwnerProps {
	objectId: string;
	spaceId: string;
}

const SpaceOwners = ({objectId, spaceId}: SpaceOwnerProps) => {
	const owners = useAppSelector(s => extractSpaceOwnersForSpace(s, spaceId));
	const object = useAppSelector(s => extractObject(s, objectId));
	const roles = useAppSelector(extractUserRoles);

	// Диалог добавления собственника
	const [addingDialog, openAddingDialog] = useAddingSpaceOwnerDialog(object.companyId, spaceId);
	// Диалог редактирования собственника
	const [editDialog, openEditDialog] = useEditableSpaceOwnerDialog(spaceId);

	const canAdd = hasPermission(roles, 'space-owners-add');
	const canEdit = hasPermission(roles, 'space-owners-edit');

	return (
		<>
			<EntityGridItem
				label="Собственники"
				icon={<i className="tz-space-owner-20"/>}
				fullRow
				buttons={canAdd && (
					<InlineButton
						type="accent"
						leftIcon={<i className="tz-plus-20"/>}
						onClick={openAddingDialog}
						className="space-page__info-edit-icon"
					/>
				)}
			>
				{owners.length
					? owners.map(owner => (
						<div
							key={owner.id}
							className="space-page__owner"
						>
							<div className="space-page__owner-name">
								{owner.name}
								{canEdit && (
									<InlineButton
										className="space-page__owner-edit-icon"
										type="accent"
										leftIcon={<i className="tz-edit-16"/>}
										onClick={() => {
											openEditDialog(owner);
										}}
									/>
								)}
							</div>

							{owner.phone !== undefined && (
								<div className="space-page__owner-contact">
									<a href={`tel:${owner.phone}`}>{owner.phone}</a>
								</div>
							)}

							{owner.additionalPhone !== undefined && (
								<div className="space-page__owner-contact">
									<a href={`tel:${owner.additionalPhone}`}>{owner.additionalPhone}</a>
								</div>
							)}

							{owner.email !== undefined && (
								<div className="space-page__owner-contact">
									<a href={`mailto:${owner.email}`}>{owner.email}</a>
								</div>
							)}

							{owner.additionalEmail !== undefined && (
								<div className="space-page__owner-contact">
									<a href={`mailto:${owner.additionalEmail}`}>{owner.additionalEmail}</a>
								</div>
							)}

							{owner.comment !== undefined && (
								<div className="space-page__owner-contact">
									<span className="space-page__owner-contact-description">{owner.comment}</span>
								</div>
							)}
						</div>
					))
					: null}
			</EntityGridItem>

			{canAdd && addingDialog}
			{canEdit && editDialog}
		</>
	);
};
export default SpaceOwners;
