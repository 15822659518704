import React, {useCallback} from 'react';
import {Button} from '@tehzor/ui-components';
import {useInternalAcceptanceExport} from '@src/core/hooks/export/useInternalAcceptanceExport';

interface IInternalAcceptanceExportProps {
	objectId: string;
	internalAcceptanceId: string;
}

const DesktopInternalAcceptanceExportBtn = ({objectId, internalAcceptanceId}: IInternalAcceptanceExportProps) => {
	const [exportDialog, openExportDialog] = useInternalAcceptanceExport(objectId);

	const handleExport = useCallback(() => {
		openExportDialog(internalAcceptanceId);
	}, [internalAcceptanceId, openExportDialog]);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-export-20"/>}
				label="Экспорт"
				onClick={handleExport}
			/>
			{exportDialog}
		</>
	);
};

export default DesktopInternalAcceptanceExportBtn;
