import {IEditableStatus} from '@src/core/hooks/states/useSpaceStatusesSetState';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {orderBy} from 'lodash';
import {useMemo} from 'react';
import {IExtendedSpaceStatus} from '../interfaces/IExtendedSpaceStatus';

export const useGetExtendedStatusesByStage = (
	statuses: IEditableStatus[],
	stagesMap: Record<ObjectStageIds, IObjectStage>,
	stage: IObjectStage
): IExtendedSpaceStatus[] =>
	useMemo(() => {
		const statusesWithStages = statuses?.map((status, index) => ({
			...status, index, stage: stagesMap[status.stage]
		}));
		const sortedStatuses = orderBy(statusesWithStages, ['stage.name'], ['desc']);
		const extendedStatusesByStage = sortedStatuses.reduce(
			(acc: IExtendedSpaceStatus[], status, i) => {
				if (status.stage.id === stage.id) {
					acc.push({...status, number: i + 1, stage: stage.id});
				}
				return acc;
			}, []
		);
		return extendedStatusesByStage;
	}, [statuses, stagesMap, stage]);