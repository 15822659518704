import {IUsersState} from '@src/store/modules/dictionaries/users/reducers';
import {IWorkingGroupsState} from '@src/store/modules/dictionaries/workingGroups/reducers';
import {HistoryTypeId} from '@tehzor/tools/interfaces/history/IHistoryType';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';

export const createRespUsersDifference = (
	prevGroup: string | undefined,
	prevUsers: string[] | undefined,
	nextGroup: string | undefined,
	nextUsers: string[] | undefined,
	users: IUsersState,
	groups: IWorkingGroupsState
): IHistoryData => ({
	prev: prevGroup
		? {
				respUsers: prevUsers
					? prevUsers.map((item: string) => ({
							name: users.byId[item].fullName,
							position: users.byId[item].position
					  }))
					: undefined,
				activeGroup: groups.byId[prevGroup] ? groups.byId[prevGroup].name : ''
		  }
		: undefined,
	next: nextGroup
		? {
				respUsers: nextUsers
					? nextUsers.map((item: string) => ({
							name: users.byId[item].fullName,
							position: users.byId[item].position
					  }))
					: undefined,
				activeGroup: groups.byId[nextGroup] ? groups.byId[nextGroup].name : ''
		  }
		: undefined,
	type: HistoryTypeId.RESP_USERS,
	name: 'ответственных'
});
