import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as internalAcceptanceTypes from '../internalAcceptance/constants';
import config from '@src/core/config';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IGetInternalAcceptancesPayload} from '@src/store/modules/entities/internalAcceptances/actions';
import {CHANGE_SORT, CLEAR_FILTERS} from '@src/store/modules/settings/pages/internalAcceptances/constants';
import {IAddInternalAcceptancePayload, IDeleteInternalAcceptancePayload} from '@src/store/modules/entities/internalAcceptance/actions';

const {entitiesCacheTime} = config;

export interface IInternalAcceptancesPageState {
	byId: {
		[id: string]: IInternalAcceptance;
	};
	allIds: string[];
	offset: number;
	total: number;
	expires?: number;
	selectedRows: string[];
}

export type IInternalAcceptancesPagesState = Record<string, IInternalAcceptancesPageState>;

export const getInitialStateForPage = (): IInternalAcceptancesPageState => ({
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	expires: undefined,
	selectedRows: []
});

const deleteInternalAcceptance = (
	state: IInternalAcceptancesPagesState, objectId: string, internalAcceptanceId: string
) => {
	const page = state[objectId];
	page.allIds = page.allIds.filter(id => id !== internalAcceptanceId);
	delete page.byId[internalAcceptanceId];
	page.expires = undefined;
};

export const internalAcceptances = createReducer<IInternalAcceptancesPagesState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: { payload: { objectId: string } }) => {
			if (payload.objectId && !state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SUCCESS]: (state, {payload}: { payload: IGetInternalAcceptancesPayload }) => {
			if (payload.objectId) {
				if (!state.hasOwnProperty(payload.objectId)) {
					state[payload.objectId] = getInitialStateForPage();
				}
				const page = state[payload.objectId];
				page.byId = payload.byId;
				page.allIds = payload.allIds;
				page.offset = payload.offset;
				page.total = payload.total;
				page.expires = Date.now() + entitiesCacheTime * 1000;
			}
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: { payload: { objectId: string, offset: number } }
		) => {
			const page = state[payload.objectId];
			page.offset = payload.offset;
			page.expires = undefined;
		},
		[types.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: { payload: { objectId: string, selectedRows: string[] } }
		) => {
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		// При сортировке нужно обнулить expires для игнорирования кеша
		[CHANGE_SORT]: (state, {payload}: { payload: { objectId: string } }) => {
			state[payload.objectId].expires = undefined;
		},
		// При сбрасывании фильтров нужно обнулить expires для игнорирования кеша
		[CLEAR_FILTERS]: (state, {payload}: { payload: { objectId: string } }) => {
			state[payload.objectId].expires = undefined;
		},
		[internalAcceptanceTypes.ADD_SUCCESS]: (state, {payload}: { payload: IAddInternalAcceptancePayload }) => {
			if (state[payload.objectId]) {
				state[payload.objectId].expires = undefined;
			}
			if (state.all) {
				state.all.expires = undefined;
			}
		},
		[internalAcceptanceTypes.DELETE_SUCCESS]: (state, {payload}: { payload: IDeleteInternalAcceptancePayload }) => {
			if (payload.objectId !== 'all' && state[payload.objectId]) {
				deleteInternalAcceptance(state, payload.objectId, payload.internalAcceptanceId);
			}
			if (state.all) {
				deleteInternalAcceptance(state, 'all', payload.internalAcceptanceId);
			}
		},
		[CLEAR_STORE]: () => ({})
	}
);
