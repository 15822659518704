import React, {useState} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblemReply} from '@src/store/modules/entities/problemReply/actions';
import {deleteProblemComment} from '@src/store/modules/entities/problemComment/actions';

export function useCommentDeleting(
	objectId: string,
	problemId: string
): [React.ReactNode, (id: string, official?: boolean) => Promise<void>] {
	const dispatch = useAppDispatch();

	const [deletingId, setDeletingId] = useState<string>();
	const deletingComment = useAppSelector(s =>
		(deletingId ? s.pages.problem.comments.byId[deletingId] : undefined));

	const [dialog, getConfirmation] = useConfirmDialog(
		deletingComment?.official ? 'Удалить ответ' : 'Удалить комментарий',
		deletingComment?.official
			? `Вы действительно хотите удалить ответ${
					deletingComment.number ? ` №${deletingComment.number}` : ''
			  }?`
			: 'Вы действительно хотите удалить комментарий?',
		{
			acceptBtnProps: {type: 'accent-red'}
		}
	);

	const [, deleteComment] = useAsyncFn(
		async (id: string, official?: boolean) => {
			setDeletingId(id);
			if (await getConfirmation()) {
				await dispatch(
					(official ? deleteProblemReply : deleteProblemComment)(objectId, problemId, id)
				);
			}
		},
		[objectId]
	);

	return [dialog, deleteComment];
}
