import * as types from './constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetWorkingGroupTypesResponse,
	requestWorkingGroupTypes
} from '@src/api/backend/workingGroupTypes';

export type IGetWorkingGroupTypesPayload = IGetWorkingGroupTypesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetWorkingGroupTypesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке типов рабочих групп');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список типов рабочих групп
 */
export const getWorkingGroupTypes = () =>
	checkLoaded<IState, IGetWorkingGroupTypesResponse, ApiAction>(
		state => state.dictionaries.workingGroupTypes,
		createApiAction<IGetWorkingGroupTypesResponse>(
			request,
			success,
			failure,
			requestWorkingGroupTypes
		)
	);
