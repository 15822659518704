import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import * as types from './constants';
import * as groupsTypes from '../workingGroups/constants';
import {createReducer} from '@reduxjs/toolkit';
import {
	getLoadableEntitiesInitialState,
	ILoadableEntitiesState,
	handleLoadableEntitiesGetting,
	handleLoadableEntityAdding,
	handleLoadableEntityEditing,
	handleLoadableEntityDeletion,
	handleLoadableEntityReset
} from '@tehzor/tools/core/storeHelpers/reducers';

export type IResponsibilityRulesState = ILoadableEntitiesState<IResponsibilityRule>;

export const responsibilityRules = createReducer<IResponsibilityRulesState>(
	getLoadableEntitiesInitialState<IResponsibilityRule>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IResponsibilityRule>(),
		[types.ADD_SUCCESS]: handleLoadableEntityAdding<IResponsibilityRule>(),
		[types.EDIT_SUCCESS]: handleLoadableEntityEditing<IResponsibilityRule>(),
		[types.DELETE_SUCCESS]: handleLoadableEntityDeletion<IResponsibilityRule>(),
		[groupsTypes.EDIT_SUCCESS]: handleLoadableEntityReset<IResponsibilityRule>(),
		[groupsTypes.DELETE_SUCCESS]: handleLoadableEntityReset<IResponsibilityRule>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IResponsibilityRule>()
	}
);
