import {requestGetCategoriesSets} from '@src/api/backend/categoriesSets';
import {useQueryClient} from '@tanstack/react-query';
import {categoriesSetsQueryKeys} from '../keys';

export const useCategoriesSetsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(categoriesSetsQueryKeys.list(), {
		queryFn: requestGetCategoriesSets,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		cacheTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
