import {requestInternalAcceptances} from '@src/api/backend/internalAcceptances';
import {QueryFunctionContext, QueryKey, useQueryClient} from '@tanstack/react-query';
import {endOfDay, getTime} from 'date-fns';
import {IInternalAcceptancesListQueryKey, internalAcceptancesQueryKeys} from '../keys';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';

export const useInternalAcceptancesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IInternalAcceptancesListQueryKey>) => {
			const [, , filters, sort, offset, pageSize] = queryKey;
			return requestInternalAcceptances(filters, sort, offset, pageSize);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.latest(), {
		queryFn: () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestInternalAcceptances({createdAtFrom: date}, {createdAt: false}, 0, 100);
		},
		staleTime: getTime(endOfDay(new Date())),
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
	queryClient.setQueryDefaults(internalAcceptancesQueryKeys.localList(), {
		queryFn: async ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localAcpts = await new Promise<
				Array<[QueryKey, IListInternalAcceptance | undefined]>
			>(resolve => {
				const local = queryClient.getQueriesData<IListInternalAcceptance>({
					queryKey: internalAcceptancesQueryKeys.details(),
					predicate: ({state}) => {
						const {data} = state;
						if ((data as IListInternalAcceptance).transferStatus) {
							if (!objectId || objectId === 'all') {
								return true;
							}
							if (objectId === (data as IListInternalAcceptance).objectId) {
								return true;
							}
						}
						return false;
					}
				});
				resolve(local);
			});
			return localAcpts.map(([, acpt]) => acpt) as IListInternalAcceptance[];
		},
		staleTime: 0,
		cacheTime: Infinity,
		networkMode: 'always'
	});
};
