import React, {useCallback} from 'react';
import ChecksPage from './ChecksPage';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import ActionsMobile from './components/actions/Actions.mobile';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/reducers';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/checks/actions';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import {changeOffset, clearExpiration} from '@src/store/modules/entities/checks/actions';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const ChecksPageWrap = () => {
	const dispatch = useAppDispatch();
	const {objectId} = useStrictParams<{objectId: string}>();

	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {
		sectionsMenu,
		mobileRightButtons: <ActionsMobile objectId={objectId}/>
	});

	const {filters} = useAppSelector(s => extractChecksPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: IChecksFiltersState) => {
			dispatch(changeFilters(objectId, value));
			dispatch(changeOffset(objectId || 'all', 0));
		},
		[objectId]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId]);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId={objectId}
			onClear={onClear}
			clearExpiration={clearExpiration}
		>
			<ChecksPage
				key={objectId}
				className={sectionsMenu.length ? 'page-cont_extra-top-margin' : undefined}
			/>
		</EntitiesFiltersProvider>
	);
};

export default ChecksPageWrap;
