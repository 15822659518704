import React from 'react';
import {CellProps} from 'react-table';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {MobileCellInfo} from './MobileCellInfo';

export const MobileOwnerAcceptanceCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {
		number,
		resolution,
		status,
		markers,
		plan,
		spaceOwner,
		createdAt,
		modifiedAt,
		createdBy,
		modifiedBy,
		object,
		localNumber
	} = row.original;
	return (
		<div className="owner-acceptances-page__mobile-cell-info">
			<ClickPreventWrap className="owner-acceptances-page__mobile-cell-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()}/>
			</ClickPreventWrap>
			<div>
				<MobileCellInfo
					number={number}
					localNumber={localNumber}
					resolution={resolution}
					status={status}
					markers={markers}
					plan={plan}
					spaceOwner={spaceOwner}
					createdAt={createdAt}
					modifiedAt={modifiedAt}
					createdBy={createdBy}
					modifiedBy={modifiedBy}
				/>
				<div className="owner-acceptances-page__mobile-cell-object">{object?.name}</div>
			</div>
		</div>
	);
};
