import React from 'react';
import {AddingOwnerAcceptanceView} from '../AddingOwnerAcptDialog';

interface IDialogTitleProps {
	view?: AddingOwnerAcceptanceView;
}

const DialogTitle = ({view}: IDialogTitleProps) => (
	<div className="adding-owner-acpt-dialog__dialog-title">
		{view === 'acceptance' ? 'Передача собственнику' : view === 'problem' ? 'Нарушение' : ''}
	</div>
);

export default DialogTitle;
