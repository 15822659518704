import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const extractAllIds = (state: IState) => state.dictionaries.documentTypes.allIds || [];
const extractById = (state: IState) => state.dictionaries.documentTypes.byId || {};

/**
 * Возвращает типы документов в виде массива
 */
export const extractDocumentTypesAsArray = createSelector(
	[extractAllIds, extractById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);
