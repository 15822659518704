import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetChecksResponse extends INormalizedData<IListCheck> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список проверок
 */
export const requestChecks = (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) =>
	wsConnector.sendAuthorizedRequest<IGetChecksResponse>('getChecks', {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	});
