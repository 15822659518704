import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addWarningToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {makeProblemsExportRequest} from '@src/api/backend/problems';
import {addExportToast} from '@src/components/ExportToast/addExportToast';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';

const request = () => ({type: types.EXPORT_REQUEST});

const success = (response: IExportResponse, sending: boolean) => {
	if (response.link) {
		addExportToast(
			sending ? 'Документ отправлен' : 'Экспорт документа завершен',
			response.link,
			response.file.name,
			'',
			response.document
		);
	}
	if (response.warnings?.itemsLimit !== undefined) {
		addWarningToast(
			'Внимание',
			`превышен лимит записей (${String(response.warnings.itemsLimit)})`
		);
	}
	return {type: types.EXPORT_SUCCESS};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при экспорте');
	return {
		type: types.EXPORT_FAILURE,
		payload: error
	};
};

/**
 * Экспортирует список нарушений
 *
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param filters фильтры
 * @param sort сортировка
 * @param selected выбранные элементы
 */
export const exportProblems = (
	templateId: string,
	objectId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	filters: Record<string, unknown> | undefined,
	sort: Record<string, boolean> | undefined,
	selected?: string[] | undefined
) =>
	createApiAction<IExportResponse>(
		request,
		(r: IExportResponse) => success(r, !!email),
		failure,
		() =>
			makeProblemsExportRequest(
				templateId,
				objectId,
				createDocument,
				email,
				filters,
				sort,
				selected
			)
	);
