import React, {useCallback, useEffect, useState} from 'react';
import EditableInspection from '../EditableInspection';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import {useFieldsSettings} from '@src/core/hooks/useFieldsSettings';
import {
	convertToLocalSave as convertFilesToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	someFilesHaveSizeError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {
	convertToSave,
	errorsFns,
	hasAttachmentsError,
	isEdited,
	useEditableInspectionState
} from '@src/core/hooks/states/useEditableInspectionState';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';

export const useEditableInspection = (
	objectId: string,
	defaultData: ISavingInspection | undefined,
	saving: boolean,
	creating?: boolean
): [
	React.ReactNode,
	(useLocalFiles?: boolean) => Promise<ISavingInspection | undefined>,
	(
) => void,
	boolean
] => {
	// TODO Определение типа настроек
	const fieldsSettings = useFieldsSettings(objectId, 'inspection');

	const [editingState, editingDispatch] = useEditableInspectionState(defaultData);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(
			isEdited(editingState, defaultData) || isFilesExist(uploadingFilesState.value)
		);
	}, [editingState, uploadingFilesState.value, defaultData]);

	const getSavingData = useCallback(
		async (useLocalFiles?: boolean) => {
			const files = await waitUploading();
			// Так как в editingState в attachments нет новых данных пока они не будут загружены на сервер,
			// добавляем данные из uploadingFilesState
			const editingStateWithUploadingFiles = {
				...editingState,
				attachments: [
					...editingState.attachments,
					uploadingFilesState.value.map(() => ({id: ''}))
				]
			};
			// Проверка наличия ошибок в состояниях
			if (
				hasErrors(editingStateWithUploadingFiles, errorsFns, fieldsSettings)
				|| (hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
			) {
				editingDispatch({type: 'update-errors'});
				uploadingFilesDispatch({type: 'update-error'});
				return undefined;
			}
			// Проверка, есть ли файлы с превышением размера.
			// не даст сохранить нарушение в автономном режиме
			if (someFilesHaveSizeError(files)) {
				return undefined;
			}
			// Проверка, были ли отредактированы поля
			if (
				!isEdited(editingState, defaultData)
				&& (!isFilesExist(files) || someFilesHaveError(files))
			) {
				return undefined;
			}
			const savingData = convertToSave(editingState, defaultData, !creating);
			savingData.newAttachments = useLocalFiles
				? convertFilesToLocalSave(files)
				: convertFilesToSave(files);
			return savingData;
		},
		[editingState, objectId, defaultData, fieldsSettings, creating]
	);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: defaultData
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [defaultData]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [defaultData]);

	const fields = (
		<EditableInspection
			objectId={objectId}
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			fieldsSettings={fieldsSettings}
			saving={saving}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
