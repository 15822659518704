import React, {useCallback, useRef, useState} from 'react';
import AutoSizer, {Size} from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import {VariableSizeList} from 'react-window';
import SelectOption from '../SelectOption';

interface LoadableOptionsListProps {
	items: Array<{ id: string, content: string }>;
	total: number;
	handleLoad: () => void;
	maxListHeight: number;
	rowHeight: number;
}

interface LoadableOptionsListItemProps {
	index: number;
	style: React.CSSProperties;
	items: Array<{ id: string, content: string }>;
}

const Option = ({index, style, items}: LoadableOptionsListItemProps) =>
	 (
		<div style={style}>
			<SelectOption
				key={items[index].id}
				itemKey={items[index].id}
				content={items[index].content}
				inputType="radio"
			/>
		</div>
);

const LoadableOptionsList = ({
	items,
	handleLoad,
	total,
	maxListHeight,
	rowHeight
}: LoadableOptionsListProps) => {
	const [startIndexCache, setIndexCache] = useState<number[]>([]);
	const listRef = useRef<VariableSizeList | null>(null);
	const getSize = useCallback(() => rowHeight, []);
	const isItemLoaded = (index: number) => items.length === total || !!items[index];
	const loadMoreItems = (startIndex: number) => {
		if (startIndexCache.includes(startIndex)) return;
		setIndexCache([...startIndexCache, startIndex]);
		handleLoad();
	  };

	const listHeight = useCallback(() => {
		if (items.length < 8) {
			return items.length * Number(getSize);
		}
		return maxListHeight;
	}, [items]);

	return (

		<InfiniteLoader
			loadMoreItems={loadMoreItems}
			isItemLoaded={isItemLoaded}
			itemCount={total}
		>
			{({onItemsRendered, ref}) => (
				<AutoSizer
					ref={ref}
					disableHeight
				>
					{({width}: Size) => (
						<VariableSizeList
							ref={listRef}
							onItemsRendered={onItemsRendered}
							width={width}
							height={listHeight()}
							itemCount={items.length}
							itemSize={getSize}
						>
							{({...props}) => (
								<Option
									{...props}
									items={items}
								/>
									)}
						</VariableSizeList>
							)}
				</AutoSizer>
				)}
		</InfiniteLoader>
	);
};

LoadableOptionsList.displayName = 'LoadableOptionsList';

export default LoadableOptionsList;