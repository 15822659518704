import React, {useState, useEffect, useCallback} from 'react';
import {
	ActionButtons,
	Button,
	Checkbox,
	Dialog,
	Select2,
	LoadableOptionsList,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {useAsyncFn} from 'react-use';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getSelectionChecks} from '@src/store/modules/entities/checks/actions';
import './MovingProblemsDialog.less';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import {addCheck} from '@src/store/modules/entities/check/actions';
import {moveProblem} from '@src/store/modules/pages/problem/actions/move';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import {IProblemTarget} from '@src/api/backend/problem';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {getSelectionInternalAcceptances} from '@src/store/modules/entities/internalAcceptances/actions/getSelectionInternalAccetances';
import {addInternalAcceptance} from '@src/store/modules/entities/internalAcceptance/actions';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {IGetChecksResponse} from '@src/api/backend/checks';

interface IMovingProblemsDialogProps {
	selectedEntities: ICheckEntity[];
	objectId: string;
	target: IProblemTarget;
	isOpen: boolean;
	restrictions?: {[key: string]: boolean};
	onClose: () => void;
	onSuccess?: () => void;
}

/**
 * Окно перемещения нарушения
 */
const MovingProblemsDialog = ({
	selectedEntities,
	objectId,
	target,
	isOpen,
	restrictions,
	onClose,
	onSuccess
}: IMovingProblemsDialogProps) => {
	const isCheck = !!target.checkId;
	const isInternalAcceptance = !!target.internalAcceptanceId;
	const dispatch = useAppDispatch();
	const check = useAppSelector(s => s.entities.check.data);
	const internalAcceptance = useAppSelector(s => s.entities.internalAcceptance.data);
	const user = useAppSelector(s => s.auth.profile);
	const [disabled, setDisabled] = useState(false);
	const [toEntityId, setToEntityId] = useState<string>('');
	const [toNewEntity, setToNewEntity] = useState<boolean>(false);
	const [entities, setEntities] = useState<Array<{id: string, content: string}>>([]);
	const [offset, setOffset] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);
	const handleEntityChange = useCallback((value: string) => {
		setToEntityId(value);
	}, []);

	const formList = useCallback(
		(value: IListCheck[] | IListInternalAcceptance[]) => {
			const arr: Array<{id: string, content: string}> = [];
			for (const item of value) {
				const text = isCheck
					? 'Проверка №'
					: isInternalAcceptance
					? 'Внутренняя приёмка №'
					: 'Сущность №';
				arr.push({id: item.id, content: `${text}${item.number}`});
			}
			setEntities([...entities, ...arr]);
		},
		[entities, isCheck, isInternalAcceptance]
	);

	const [, getEntities] = useAsyncFn(async () => {
		let items = {} as IGetInternalAcceptancesResponse | IGetChecksResponse;
		if (isCheck) {
			 items = await dispatch(
				getSelectionChecks(
					[objectId],
					restrictions && restrictions.checksCreatedByUser ? [user.id] : undefined,
					offset,
					100
				)
			);
		} else if (isInternalAcceptance) {
			 items = await dispatch(
				getSelectionInternalAcceptances(
					[objectId],
					restrictions && restrictions.internalAcceptancesCreatedByUser
						? [user.id]
						: undefined,
					offset,
					100
				)
			);
		}
		formList([...Object.values(items.byId)]);
			setOffset(offset + 100);
			setTotal(items.total);
	}, [
		objectId,
		offset,
		check,
		entities,
		internalAcceptance,
		isCheck,
		isInternalAcceptance,
		restrictions
	]);

	useEffect(() => {
		const entityId = isCheck
			? check?.id
			: isInternalAcceptance
			? internalAcceptance?.id
			: undefined;
		setToEntityId(`${entityId}`);
	}, [check, internalAcceptance, isCheck, isInternalAcceptance]);

	useEffect(() => {
		if (entities.length === 0) {
			void getEntities();
		}
	}, []);

	const handleNewEntity = (event: React.ChangeEvent<HTMLInputElement>) => {
		setToNewEntity(event.target.checked);
	};

	const [, handleSave] = useAsyncFn(async () => {
		setDisabled(true);
		const problemsIds = selectedEntities
			.filter(item => item.type === 'problem')
			.map(({data}) => {
				const {id} = data as IProblem;
				return id;
			});

		if (isCheck) {
			if (toNewEntity && problemsIds.length) {
				const newCheck = await dispatch(addCheck(objectId, undefined, check?.stage));
				for (const problemId of problemsIds) {
					await dispatch(moveProblem(objectId, {checkId: newCheck?.id}, problemId));
				}
			}
			if (toEntityId && !toNewEntity && problemsIds.length) {
				for (const problemId of problemsIds) {
					await dispatch(moveProblem(objectId, {checkId: toEntityId}, problemId));
				}
			}
		}
		if (isInternalAcceptance) {
			if (toNewEntity && problemsIds.length) {
				const newInternalAcceptance = await dispatch(
					addInternalAcceptance(objectId, undefined, internalAcceptance?.stage)
				);
				for (const problemId of problemsIds) {
					await dispatch(
						moveProblem(
							objectId,
							{internalAcceptanceId: newInternalAcceptance?.id},
							problemId
						)
					);
				}
			}
			if (toEntityId && !toNewEntity && problemsIds.length) {
				for (const problemId of problemsIds) {
					await dispatch(
						moveProblem(objectId, {internalAcceptanceId: toEntityId}, problemId)
					);
				}
			}
		}
		if (onSuccess) {
			onSuccess();
		}
		onClose();
	}, [objectId, toEntityId, toNewEntity, isCheck, isInternalAcceptance]);

	return (
		<Dialog
			className="check-page__problems-moving"
			isOpen={isOpen}
			footer={(
				<ActionButtons>
					<Button
						disabled={
							disabled
							|| ((target.checkId === toEntityId
								|| target.internalAcceptanceId === toEntityId)
							&& !toNewEntity)
						}
						type="accent-blue"
						label="Сохранить"
						onClick={handleSave}
					/>
					<Button
						disabled={disabled}
						type="cancel"
						label="Отменить"
						onClick={onClose}
					/>
				</ActionButtons>
			)}
		>
			<div className="check-page__problems-moving__select">
				<div>
					{isCheck
						? 'Выберите проверку:'
						: isInternalAcceptance
						? 'Выберите внутреннюю приёмку:'
						: 'Выберите cущность:'}
				</div>
				<SelectPopup
					noHeader
					trigger={(
						<TextFieldWithForwardedRef
							disabled={toNewEntity}
							elementType="div"
							value={entities.find(e => e.id === toEntityId)?.content}
							icon={<i className="tz-simple-arrow-20"/>}
						/>
					)}
				>
					<Select2
						value={toEntityId}
						onChange={handleEntityChange}
					>
						<LoadableOptionsList
							items={entities}
							total={total}
							handleLoad={getEntities}
							maxListHeight={400}
							rowHeight={48}
						/>
					</Select2>
				</SelectPopup>
				<Checkbox
					className="check-page__problems-moving-cb"
					checked={toNewEntity}
					onChange={handleNewEntity}
				>
					{isCheck
						? 'В новую проверку'
						: isInternalAcceptance
						? 'В новую внутреннюю приёмку'
						: 'В новую сущность'}
				</Checkbox>
			</div>
		</Dialog>
	);
};

export default MovingProblemsDialog;
