import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {testInspectionRestrictions} from '@src/utils/testRestrictions';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';

export interface IPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canViewCheck?: boolean;
	canViewSpace?: boolean;
	canViewHistory?: boolean;
	canViewInternalAcceptance?: boolean;
	canViewTask?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param objectId id объекта
 * @param inspection осмотр
 */
export const usePermissions = (objectId: string, inspection?: ILinkedInspection): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canEdit: testInspectionRestrictions(
				user.id,
				user.roles,
				'inspectionsEdit',
				objectId,
				inspection?.createdBy
			),
			canDelete: testInspectionRestrictions(
				user.id,
				user.roles,
				'inspectionsDelete',
				objectId,
				inspection?.createdBy
			),
			canExport: testInspectionRestrictions(
				user.id,
				user.roles,
				'inspectionsExport',
				objectId,
				inspection?.createdBy
			),
			canViewCheck: hasPermission(user.roles, 'checksView', UserRoleScopes.OBJECT, objectId),
			canViewInternalAcceptance: hasPermission(user.roles, 'internal-acceptances-view', UserRoleScopes.OBJECT, objectId),
			canViewSpace: hasPermission(user.roles, 'spaces-view', UserRoleScopes.OBJECT, objectId),
			canViewHistory: hasPermission(
				user.roles,
				'inspections-history-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewTask: hasPermission(
				user.roles,
				'tasks-view',
				UserRoleScopes.OBJECT,
				objectId
			)
		};
	}, [objectId, inspection, user]);
};
