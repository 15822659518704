import React, {memo, useCallback, useState} from 'react';
import {useAsyncFn} from 'react-use';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPlansAsArray} from '@src/store/modules/dictionaries/plans/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {generateSpaces, getSpacesSchema} from '@src/store/modules/entities/spaces/actions';
import {Button, Dialog} from '@tehzor/ui-components';
import {MultiplePlansSelect} from './MultiplePlansSelect';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

interface IGenerateSpacesDialogProps {
	objectId: string;
	onClose: () => void;
	isOpen: boolean;
}
export const GenerateSpacesDialog = memo(
	({objectId, onClose, isOpen}: IGenerateSpacesDialogProps) => {
		const [selectedPlans, setSelectedPlans] = useState<string[]>([]);
		const plans = useAppSelector(s => extractPlansAsArray(s, objectId));
		const dispatch = useAppDispatch();

		const handleSelectionChange = useCallback((value: string[]) => {
			setSelectedPlans(value);
		}, []);

		const handleDialogClose = () => {
			setSelectedPlans([]);
		};

		const [generationState, generate] = useAsyncFn(async () => {
			await dispatch(generateSpaces(objectId, selectedPlans));
			await queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			await dispatch(getSpacesSchema(objectId));
			onClose();
			setSelectedPlans([]);
		}, [selectedPlans, objectId]);
		return (
			<Dialog
				className="object-page__generate-spaces-dialog"
				isOpen={isOpen}
				title="Генерация помещений"
				disableScrollBlock
				onRequestClose={onClose}
				onBeforeClose={handleDialogClose}
			>
				<MultiplePlansSelect
					label="Планы"
					optionsIds={selectedPlans}
					options={plans}
					disabled={generationState.loading}
					onChangeFilter={handleSelectionChange}
					className="object-page__generate-spaces-dialog-select"
					popupClassName="object-page__generate-spaces-dialog-select-popup"
				/>
				<div className="object-page__generate-spaces-dialog-button_container">
					<Button
						disabled={generationState.loading || selectedPlans.length === 0}
						type="accent-blue"
						label="Сгенерировать"
						onClick={generate}
					/>
				</div>
			</Dialog>
		);
	}
);
