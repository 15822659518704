import React, {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useSpaceBreadcrumbs} from '@src/core/hooks/breadcrumbs/useSpaceBreadcrumbs';
import MainActionsDesktop from './actions/MainActions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IPageBreadcrumbsProps {
	objectId: string;
	spaceId: string;
}

const PageBreadcrumbs = ({objectId, spaceId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useSpaceBreadcrumbs(objectId, spaceId);
	const isDesktop = useIsDesktop();
	return (
		<AppBreadcrumbs
			className="space-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && (
				<MainActionsDesktop
					objectId={objectId}
					spaceId={spaceId}
				/>
)}
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
