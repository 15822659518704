import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IPermissions {
	canAdd?: boolean;
	canEdit?: boolean;
	canEditStatus?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canMove?: boolean;
}

/**
 * Возвращает полномочия для приёмок работ
 *
 * @param objectId id объекта
 */
export const useWorkAcceptancesPermissions = (objectId: string): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canAdd: hasPermission(
				user.roles,
				'work-acceptances-add',
				UserRoleScopes.OBJECT,
				objectId
			),
			canEdit: hasPermission(
				user.roles,
				'work-acceptances-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canEditStatus: hasPermission(
				user.roles,
				'work-acceptances-status-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canDelete: hasPermission(
				user.roles,
				'work-acceptances-delete',
				UserRoleScopes.OBJECT,
				objectId
			),
			canExport: hasPermission(
				user.roles,
				'work-acceptances-export',
				UserRoleScopes.OBJECT,
				objectId
			)
		};
	}, [objectId, user]);
};
