import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetOwnerAcceptanceResponse, requestOwnerAcceptance} from '@src/api/backend/ownerAcceptance';

export type IGetOneUserOwnerAcceptancePayload = IGetOwnerAcceptanceResponse;

const request = () => ({
	type: types.GET_ONE_REQUEST
});

const success = (response: IGetOwnerAcceptanceResponse) => ({
	type: types.GET_ONE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке передачи собственнику');
	return {
		type: types.GET_ONE_FAILURE,
		payload: error
	};
};

/**
 * Получает одну передачу собственнику по id
 */
export const getUserOwnerAcceptance = (objectId: string, ownerAcceptanceId: string) =>
	createApiAction<IGetOwnerAcceptanceResponse>(request, success, failure, () =>
		requestOwnerAcceptance(objectId, ownerAcceptanceId));
