import React, {useCallback, useState} from 'react';
import {EditingTaskDialog} from '../EditingTaskDialog';

interface AddingTaskDialogProps {
	taskId?: string;
	objectId?: string;
	startTime?: string;
	endTime?: string;
}

export const useEditingTaskDialog = (
	props: AddingTaskDialogProps
): [React.ReactNode, (
) => void] => {
	const {
		taskId,
		objectId,
		startTime,
		endTime
	} = props;
	const [isOpen, setOpen] = useState(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<EditingTaskDialog
			isOpen={isOpen}
			close={close}
			taskId={taskId}
			objectId={objectId}
			startTime={startTime}
			endTime={endTime}
		/>
	);

	return [dialog, open];
};
