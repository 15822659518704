import {wsConnector} from '@src/api/backend/wsConnector';

export interface IDeleteSpaceStatusResponse {
	success: boolean;
}

/**
 * Удаляет статус помещения по id
 *
 * @param spaceStatusId id статуса помещений
 * @param spaceStatusesSetId id набора статусов помещений
 */
export const requestDeleteSpaceStatus = (spaceStatusId: string, spaceStatusesSetId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteSpaceStatusResponse>('deleteSpaceStatus', {
		id: spaceStatusId, spaceStatusesSetId
	});
