import React from 'react';
import MobileMenu from './Menu.mobile';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';

interface IMobileActionsProps {
	objectId: string;
}

const MobileActions = ({objectId}: IMobileActionsProps) => {
	const permissions = useSpacesPermissions(objectId);
	const object = useAppSelector(s => extractCurrentTreeObject(s, objectId));
	const lastObject = object ? !object.children?.length : false;
	const {schemaView} = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	return (
		<MobileMenu
			objectId={objectId}
			canAddSpace={permissions.canAdd && lastObject}
			canExportSpaces={permissions.canExport && lastObject}
			canOpenSettings={schemaView !== SpacesSchemaVariants.REPORTS}
		/>
	);
};

export default MobileActions;
