import React, {useCallback} from 'react';
import {
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	InlineButton,
	Plate,
	StatusSelect
} from '@tehzor/ui-components';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useAsyncFn} from 'react-use';
import {editWarrantyClaim, editWarrantyClaimStatus} from '@src/store/modules/entities/warrantyClaim/actions';
import {WarrantyClaimStatusId} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaimStatus';
import SpaceOwners from './SpaceOwners';
import {useEditableWarrantyClaimAttachmentsDialog} from '@src/components/EditableWarrantyClaimAttachmentsDialog/hooks/useEditableWarrantyClaimAttachmentsDialog';
import Documents from './Documents';
import ExpandableMobileInfo from '@src/components/ExpandableMobileInfo';
import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {RegistrationDateMobile} from './registrationDate.mobile';

interface IMobileInfoProps {
	objectId: string;
	warrantyClaim: ILinkedWarrantyClaim;
}

const calendarIcon = <i className="tz-calendar-20"/>;
const editIcon = <i className="tz-edit-16"/>;
const statusIcon = <i className="tz-status-20"/>;
const documentsIcon = <i className="tz-document-20"/>;
const spaceIcon = <i className="tz-space-24"/>;

const MobileInfo = ({objectId, warrantyClaim}: IMobileInfoProps) => {
	const {pushPath} = useChangePath();
	const spaceTitle = useSpaceTitle(warrantyClaim?.space);
	const statuses = useAppSelector(s => s.dictionaries.warrantyClaimStatuses);
	const dispatch = useAppDispatch();
	const permissions = useWarrantyClaimsPermissions(objectId);
	const [editingAttachmentsDialog, openEditAttachmentsDialog]
		= useEditableWarrantyClaimAttachmentsDialog(objectId, warrantyClaim.id, warrantyClaim);

	const [, handleChange] = useAsyncFn(
		async (v: WarrantyClaimStatusId) => {
			if (warrantyClaim) {
				await dispatch(editWarrantyClaimStatus(objectId, warrantyClaim.id, v));
			}
		},
		[objectId, warrantyClaim]
	);

	const [, handleRegDateChange] = useAsyncFn(
		async (value: Date) => {
			if (warrantyClaim) {
				await dispatch(editWarrantyClaim(objectId, warrantyClaim.id, {registrationDate: value.getTime()}));
			}
		},
		[objectId, warrantyClaim]
	);

	const handleClick = useCallback(() => {
		if (warrantyClaim) {
			if (warrantyClaim.objectId && warrantyClaim.links?.spaceId) {
				pushPath(
					`/objects/${warrantyClaim.objectId}/spaces/${warrantyClaim.links.spaceId}`
				);
			}
		}
	}, [warrantyClaim]);

	return (
		<Plate>
			<EntityGrid withBorders>
				<EntityGridItem
					// className="space-page__status"
					icon={statusIcon}
					label="Статус"
					inline
					fullRow
				>
					<StatusSelect
						className="warranty-claim-page__d-entities-status"
						statuses={statuses}
						disabled={!permissions.canEditStatus}
						value={warrantyClaim?.status}
						onChange={handleChange}
					/>
				</EntityGridItem>

				<SpaceOwners
					name={warrantyClaim.spaceOwner?.name}
					phone={warrantyClaim?.replyPhone}
					email={warrantyClaim?.replyEmail}
					claimer={warrantyClaim?.claimerName}
				/>

				<EntityGridItem
					label="Акт"
					icon={documentsIcon}
					fullRow
					buttons={(
						<InlineButton
							className="warranty-claim-page__documents-edit-icon"
							type="accent"
							leftIcon={editIcon}
							onClick={openEditAttachmentsDialog}
						/>
					)}
				>
					<Documents warrantyClaim={warrantyClaim}/>
				</EntityGridItem>
				<RegistrationDateMobile
					value={warrantyClaim.registrationDate}
					onChange={handleRegDateChange}
					canEdit={permissions.canEdit}
				/>
			</EntityGrid>
			<ExpandableMobileInfo noBorder>
				<EntityGrid withBorders>
					<EditorInfo
						icon={calendarIcon}
						label="Создано"
						date={warrantyClaim?.createdAt}
						user={warrantyClaim?.createdBy}
						fullRow
					/>

					{warrantyClaim?.createdAt !== warrantyClaim?.modifiedAt && (
						<EditorInfo
							icon={calendarIcon}
							label="Изменено"
							date={warrantyClaim?.modifiedAt}
							user={warrantyClaim?.modifiedBy}
							fullRow
						/>
					)}
					<EntityGridItem className={{content: 'warranty-claim-page__space-btn'}}>
						<InlineButton
							label={spaceTitle}
							type="accent"
							onClick={handleClick}
							leftIcon={spaceIcon}
						/>
					</EntityGridItem>
				</EntityGrid>
			</ExpandableMobileInfo>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default MobileInfo;
