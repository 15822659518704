import {requestResponsibilityRules} from '@src/api/backend/responsibilityRules';
import {useQueryClient} from '@tanstack/react-query';
import {responsibilityRulesQueryKeys} from '../keys';

export const useResponsibilityRulesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(responsibilityRulesQueryKeys.list(), {
		queryFn: requestResponsibilityRules,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
