import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
// import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
// import {useCheckDelete} from '../../hooks/useCheckDelete';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {
	useMovingWarrantyClaimDialog
} from '@src/components/MovingWarrantyClaimDialog/hooks/useMovingWarrantyClaimDialog';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {deleteWarrantyClaim} from '@src/store/modules/entities/warrantyClaim/actions';
import {useAsyncFn} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
// import {useEntitiesVisibilityFiltering} from '../../hooks/useEntitiesVisibilityFiltering';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useEditWarrantyClaimDialog} from '../../hooks/useEditWarrantyClaimDialog';

interface IMobileMenuProps {
	objectId: string;
	warrantyClaim: ILinkedWarrantyClaim;
	canDeleteWarrantyClaim?: boolean;
	canMoveWarrantyClaim?: boolean;
	canEditWarrantyClaim?: boolean;
}

const MobileMenu = ({
						objectId,
						warrantyClaim,
						canDeleteWarrantyClaim,
						canMoveWarrantyClaim,
						canEditWarrantyClaim
					}: IMobileMenuProps) => {
	const {goBack} = useChangePath();
	// const [menu, label] = useEntitiesVisibilityFiltering();
	const dispatch = useAppDispatch();
	// const [deleteDialog, handleDeleteClick] = useCheckDelete(objectId, check);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить обращение',
		`Вы действительно хотите удалить обращение №${warrantyClaim.number}?`,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [warrantyClaimDialog, openWarrantyClaimDialog] = useEditWarrantyClaimDialog(warrantyClaim);

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteWarrantyClaim(warrantyClaim.objectId, warrantyClaim.id));
			goBack();
		}
	}, [warrantyClaim.objectId, warrantyClaim.id]);

	// Диалог переноса обращения
	const [moveDialog, openMoveDialog] = useMovingWarrantyClaimDialog(
		warrantyClaim.objectId,
		warrantyClaim
	);

	const items = [];

	items.push(updateData);

	if (canMoveWarrantyClaim) {
		items.push(
			<MenuItem
				key="move"
				icon={<i className="tz-long-arrow-24"/>}
				onClick={openMoveDialog}
			>
				Перенести обращение
			</MenuItem>
		);
	}

	if (canEditWarrantyClaim) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit-20"/>}
				onClick={openWarrantyClaimDialog}
			>
				Редактировать
			</MenuItem>
		);
	}

	if (canDeleteWarrantyClaim) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDeleteWarrantyClaim && deleteDialog}
			{canMoveWarrantyClaim && moveDialog}
			{canEditWarrantyClaim && warrantyClaimDialog}
		</>
	) : null;
};

export default MobileMenu;
