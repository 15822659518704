import {wsConnector} from '../wsConnector';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetProblemStatusesResponse = INormalizedData<IProblemStatus>;

/**
 * Возвращает список статусов нарушений
 */
export const requestProblemStatuses = () =>
	wsConnector.sendAuthorizedRequest<IGetProblemStatusesResponse>('getProblemStatuses');
