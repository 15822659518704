import React, {useCallback, useState} from 'react';
import {InspectionHistoryDialog} from '../InspectionHistoryDialog';

export function useInspectionHistoryDialog(
	objectId: string,
	inspectionId: string
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<InspectionHistoryDialog
			objectId={objectId}
			inspectionId={inspectionId}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
}
