import {useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import {getSpace} from '@src/store/modules/entities/space/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync, useUpdateEffect} from 'react-use';
import {formSpaceLocationForEntities} from '@src/utils/formSpaceLocationForEntities';
import {extractShape} from '@src/store/modules/dictionaries/plans/selectors';
import {transformLocations} from '@src/pages/CheckListPage/utils/transformLocations';
import {ISpaceLocation} from '@tehzor/tools/interfaces/spaces/ISpaceLocation';

export function useSpaceLocation(
	objectId: string,
	selectedInternalAcceptanceId: string | undefined,
	defaultProblemData?: ISavingProblem,
	defaultInspectionData?: ISavingInspection
): [ISavingProblem | undefined, ISavingInspection | undefined] {
	const latestInternalAcceptances = useAppSelector(s => s.dictionaries.latestUserInternalAcceptances);
	const dispatch = useAppDispatch();
	const [locations, setLocations] = useState<ISpaceLocation[] | undefined>();

	const [planId, sectorId] = transformLocations(locations);
	const sector = useAppSelector(s => (planId && sectorId
		? extractShape(s, objectId, planId, sectorId) : undefined));

	const [problemData, setProblemData] = useState<ISavingProblem | undefined>(defaultProblemData);
	const [inspectionData, setInspectionData] = useState<ISavingInspection | undefined>(
		defaultInspectionData
	);

	useUpdateEffect(() => {
		if (defaultProblemData) {
			setProblemData(defaultProblemData);
		}
	}, [defaultProblemData]);

	useAsync(async () => {
		if (selectedInternalAcceptanceId) {
			const internalAcceptance = latestInternalAcceptances.byId[selectedInternalAcceptanceId];
			if (internalAcceptance?.links?.spaceId) {
				const space = await dispatch(getSpace(objectId, internalAcceptance.links.spaceId));
				if (space) {
					setLocations(space?.locations);
					const data = formSpaceLocationForEntities(space, sector);
					setProblemData({...defaultProblemData, ...data});
					setInspectionData({...defaultInspectionData, ...data});
					return;
				}
			}
		}
		setProblemData(defaultProblemData);
		setInspectionData(defaultInspectionData);
	}, [selectedInternalAcceptanceId, latestInternalAcceptances, sector]);

	return [problemData, inspectionData];
}
