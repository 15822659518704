import {makeInspectionEditRequest} from '@src/api/backend/inspection';
import {makeInspectionAddRequest} from '@src/api/backend/inspection/add';
import {
	addInspectionActions,
	deleteInspectionActions,
	editInspectionsActions
} from '@src/store/modules/entities/inspection/actions';
import {IInspectionsPagesSettingsState} from '@src/store/modules/settings/pages/inspections/reducers';
import {useQueryClient} from '@tanstack/react-query';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useEffect} from 'react';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {addTempFiles} from '../../utils/addTempFiles';
import {inspectionsQueryKeys} from '../keys';
import {addSuccessToast} from '@src/utils/toasts';
import {updateCachedState} from '@src/utils/updateCachedState';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IError from '@tehzor/tools/interfaces/IError';

export interface IAddInspectionParams {
	objectId: string;
	links: IInspection['links'] | undefined;
	stage: ObjectStageIds | undefined;
	fields: ISavingInspection;
	key: string;
	currentQueryFilter?: IInspectionsPagesSettingsState;
}

export interface IEditInspectionParams {
	inspectionId: string;
	objectId: string;
	fields: ISavingInspection;
}
/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useInspectionsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const {updateEntity} = useUpdateEntity(inspectionsQueryKeys);
	const dispatch = useAppDispatch();
	useEffect(() => {
		queryClient.setMutationDefaults(inspectionsQueryKeys.add(), {
			mutationFn: async (params: IAddInspectionParams) => {
				await updateCachedState<IInspection>(
					inspectionsQueryKeys,
					params.key,
					OfflineDataTransferStatus.TRANSFER
				);
				const {objectId, links, stage, fields} = params;
				return makeInspectionAddRequest(objectId, links, stage, {
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments)
				});
			},
			onSuccess: async (
				newInspection: IInspection,
				{key, objectId}: IAddInspectionParams
			) => {
				await updateCachedState<IInspection>(
					inspectionsQueryKeys,
					key,
					OfflineDataTransferStatus.TRANSFER_COMPLETE
				);
				queryClient.removeQueries(inspectionsQueryKeys.savingData(key));
				updateEntity<IInspection>(
					newInspection,
					deleteInspectionActions.success,
					addInspectionActions.success,
					key
				);
				await queryClient.refetchQueries({
					queryKey: inspectionsQueryKeys.localList()
				});
				await queryClient.invalidateQueries([
					...inspectionsQueryKeys.list(),
					{objects: [objectId]}
				]);
				addSuccessToast('Успех', 'Осмотр успешно добавлен');
			},
			onError: async (_, {key}: IAddInspectionParams) => {
				await updateCachedState<IInspection>(
					inspectionsQueryKeys,
					key,
					OfflineDataTransferStatus.TRANSFER_ERROR
				);
			},
			retry: false
		});

		queryClient.setMutationDefaults(inspectionsQueryKeys.edit(), {
			mutationFn: async ({inspectionId, objectId, fields}: IEditInspectionParams) => {
				await queryClient.cancelQueries({
					queryKey: inspectionsQueryKeys.detail(inspectionId)
				});
				return makeInspectionEditRequest(objectId, inspectionId, {
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments)
				});
			},
			onError: err => {
				dispatch(editInspectionsActions.failure(err as IError));
			},
			onSuccess: async (data: IInspection, {inspectionId}: IEditInspectionParams) => {
				dispatch(editInspectionsActions.success(data));
				await queryClient.invalidateQueries(inspectionsQueryKeys.detail(inspectionId));
				await queryClient.invalidateQueries(inspectionsQueryKeys.list());
			}
		});
	}, [queryClient]);
};
