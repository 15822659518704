import React, {useCallback} from 'react';
import {IEditableTypeDecorationAction} from '@src/core/hooks/states/useEditableSpaceTypeDecorationState';
import {TextField} from '@tehzor/ui-components';
import {IEditableSpaceTypeDecorationItemProps} from './EditableTypeDecorationItems';
import {ITextFieldProps} from '@tehzor/ui-components/src/components/inputs/TextField/TextField';

export interface ITypeDecorationTextCellProps
	extends IEditableSpaceTypeDecorationItemProps,
		ITextFieldProps {
	elemIndex: number;
	rootField: string;
	fieldName: string;
}

export const TextInputCell = (props: ITypeDecorationTextCellProps) => {
	const {elemIndex, rootField, fieldName, editingState, editingDispatch} = props;
	const itemFields = editingState?.data ? editingState?.data[elemIndex] : undefined;

	const handleChange = useCallback(
		(value: string) => {
			editingDispatch({
				type: 'update-array-item',
				index: elemIndex,
				field: rootField,
				value: {
					...itemFields,
					[fieldName]: value
				}
			} as IEditableTypeDecorationAction);
		},
		[elemIndex, itemFields]
	);

	return (
		<TextField
			elementType="input"
			value={editingState[rootField][elemIndex][fieldName] || ''}
			onChange={handleChange}
			{...props}
		/>
	);
};
