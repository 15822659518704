import {CellProps} from 'react-table';
import useAppSelector from '@src/core/hooks/useAppSelector';
import React from 'react';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {IPreparedCheckList} from '../../interfaces/IPreparedCheckList';

export const ObjectStageCell = ({row}: CellProps<IPreparedCheckList>) => {
	const stages = useAppSelector(extractObjectStagesAsArray);
	const stage = stages.find(({id}) => id === row.original.stage);

	if (!stage) {
		return null;
	}

	return <div>{stage.name}</div>;
};
