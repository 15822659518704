import React from 'react';

export const Temp = () => (
	<svg
		width="175"
		height="184"
		viewBox="0 0 175 184"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M48.5 152.5V11.5C48.5 8.18629 51.1863 5.5 54.5 5.5H163.5C166.814 5.5 169.5 8.18629 169.5 11.5V152.5C169.5 155.814 166.814 158.5 163.5 158.5H54.5C51.1863 158.5 48.5 155.814 48.5 152.5Z"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M144.5 32.5H68.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M144.5 45.5H62.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M144.5 58.5H72.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M144.5 80.5H72.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M144.5 112.5H68.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M144.5 125.5H74.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M11.5 25.5H85.9677C87.5868 25.5 89.1371 26.1543 90.2666 27.3143L123.799 61.7528C124.89 62.8731 125.5 64.375 125.5 65.9386V172.5C125.5 175.814 122.814 178.5 119.5 178.5H11.5C8.18629 178.5 5.5 175.814 5.5 172.5V31.5C5.5 28.1863 8.18629 25.5 11.5 25.5Z"
			fill="white"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M98.5 85.5H32.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M87.5 119.5H44.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M87.5 129.5H44.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M87.5 140.5H44.5"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M87.5 64.5V26.5018C87.5 26.1405 87.9411 25.9644 88.19 26.2263L124.858 64.8245C125.1 65.0793 124.92 65.5 124.568 65.5H88.5C87.9477 65.5 87.5 65.0523 87.5 64.5Z"
			fill="#C4D1E3"
			stroke="#C4D1E3"
			strokeWidth="3"
			strokeLinecap="round"
		/>
	</svg>

	);