import {ObjectStageIds} from '../objects/IObjectStage';

export enum SpaceStatusHook {
	ACCEPTANCE_PROBLEMS_ADDED = 'acceptance-problems-added',
	ACCEPTANCE_PROBLEMS_FIXED = 'acceptance-problems-fixed',
	WITH_PROBLEMS_OWNER_CHECK_ADDED = 'with-problems-owner-check-added',
	WITHOUT_PROBLEMS_OWNER_CHECK_ADDED = 'without-problems-owner-check-added',
	REJECTED_OWNER_CHECK_ADDED = 'rejected-owner-check-added',
	ACCEPT_CHECK_LISTS = 'accept-check-lists'
}

export interface ISpaceStatus {
	id: string;
	name: string;
	transitions?: string[];
	autoTransitionTo?: string;
	color: string;
	stage: ObjectStageIds;
	hooks?: SpaceStatusHook[];
	spaceStatusesSetId: string;
	default?: boolean;
}

export const isSpaceStatus = (obj: unknown): obj is ISpaceStatus => {
	if (
		!!obj
		&& typeof obj === 'object'
		&& 'id' in obj && typeof obj.id === 'string'
		&& 'name' in obj && typeof obj.name === 'string'
		&& 'color' in obj && typeof obj.color === 'string'
		&& 'stage' in obj && typeof obj.stage === 'string'
		&& 'spaceStatusesSetId' in obj && typeof obj.spaceStatusesSetId === 'string'
		) {
			return true;
		}

		return false;
};
