import React from 'react';
import './InspectionInfoMobileCell.less';
import {PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import classNames from 'classnames';
import {CellProps} from 'react-table';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useInspectionViewer} from '@src/core/hooks/imagesViewers/mobile/useInspectionViewer';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import InspectionInfo from '@src/components/tableCells/InspectionInfo';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';

const InspectionInfoMobileCell = (props: CellProps<{data: IInspection}>) => {
	const {row} = props;
	const inspection = row.original.data;

	const [imagesAttachments] = useSplitAttachments(inspection);
	const images = useAttachmentsImages(imagesAttachments);
	const imagesData = useAttachmentsImagesObjects(imagesAttachments);
	const [imagesViewer, openImage] = useInspectionViewer(images, inspection.id, imagesData);

	return (
		<>
			<div
				className={classNames('m-inspection-info-cell', {
					'm-inspection-info-cell_with-padding': row.depth > 0
				})}
			>
				<div className="m-inspection-info-cell__header">
					<InspectionInfo
						style={{marginBottom: row.depth > 0 ? '12px' : '16px'}}
						data={inspection}
						depth={row.depth}
					/>
					<ClickPreventWrap style={{marginLeft: '8px'}}>
						<TableRowSelect
							{...row.getToggleRowSelectedProps()}
							style={{marginTop: row.depth > 0 ? '6px' : '12px'}}
						/>
					</ClickPreventWrap>
				</div>

				<div className="m-inspection-info-cell__desc-row">
					{inspection.description !== undefined && (
						<div className="m-inspection-info-cell__desc">{inspection.description}</div>
					)}
					{images.length > 0 ? (
						<ClickPreventWrap>
							<PhotoPreview
								className="m-inspection-info-cell__image"
								url={images[0]}
								data={0}
								onClick={openImage}
							/>
						</ClickPreventWrap>
					) : null}
				</div>
			</div>

			{images.length ? <ClickPreventWrap>{imagesViewer}</ClickPreventWrap> : null}
		</>
	);
};

export default InspectionInfoMobileCell;
