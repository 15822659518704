import React from 'react';
import './ProblemsStatsCards.less';
import {BlockPlaceholder, StatsCard} from '@tehzor/ui-components';
import classNames from 'classnames';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemStatusesWithExpired} from '@src/store/modules/dictionaries/problemStatuses/selectors';
import {StatsByStatus} from '@src/utils/statsAggregators/problemsByStatuses';

interface IProblemsStatsCardsProps {
	className?: string;
	stats?: StatsByStatus;
	columnsCount?: number;
	loading?: boolean;
	onClick?: (status?: string) => void;
}

const ProblemsStatsCards = (props: IProblemsStatsCardsProps) => {
	const {className, stats = {} as StatsByStatus, columnsCount = 1, loading, onClick} = props;

	let statuses = useAppSelector(extractProblemStatusesWithExpired);
	const selectedStatuses = useAppSelector(
		s => s.settings.pages.statistics.acceptance.problems.displayFilters
	);
	if (selectedStatuses.length > 0) {
		statuses = statuses.filter(status => selectedStatuses.includes(status.id));
	}
	const totalCount
		= Object.values(stats).reduce<number>((sum, num) => sum + (num ?? 0), 0)
		- (stats.expired || 0);
	// const totalCount = Object.values(stats).reduce<number>((sum, num) => sum + (num ?? 0), 0);

	const cards = [];
	if (loading) {
		const count
			= selectedStatuses.length === 0
				? statuses.length > 0
					? statuses.length + 1
					: 6
				: selectedStatuses.length;
		for (let i = 0; i < count; i++) {
			cards.push(
				<BlockPlaceholder
					key={i}
					className="problems-stats-cards__card-placeholder"
				/>
			);
		}
	} else {
		for (const status of statuses) {
			cards.push(
				<StatsCard
					key={status.id}
					className="problems-stats-cards__card"
					icon={<i className={`tz-problem-${status.id} tz-problem-${status.id}-40`}/>}
					label={status.name}
					value={stats[status.id] ?? 0}
					baseColor={status.color}
					onClick={() => onClick && onClick(status.id)}
				/>
			);
		}
		if (selectedStatuses.length === 0) {
			cards.push(
				<StatsCard
					key="total"
					className="problems-stats-cards__card"
					icon={<i className="tz-problem tz-problem-40"/>}
					label="Всего нарушений"
					value={totalCount}
					baseColor="#2C8BE9"
					onClick={() => onClick && onClick()}
				/>
			);
		}
	}

	return (
		<div
			className={classNames(
				'problems-stats-cards',
				`problems-stats-cards_col_${columnsCount}`,
				className
			)}
		>
			{cards}
		</div>
	);
};

export default ProblemsStatsCards;
