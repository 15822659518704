import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export interface IEditableCategoriesSetOriginal {
	categoriesSet: ISavingCategoriesSet;
	categories: ISavingCategory[];
}

export interface IEditableCategory {
	id: string;
	parentId?: string;
	categoriesSetId: string;
	name?: string;
	isLocal?: boolean;
	order?: number;
}

export type IEditableCategoriesSetState = IEditableEntityState<{
	id: string;
	name: string;
	objects: string[];
	companies: string[];
	stages: ObjectStageIds[];
	shared: boolean;
	categories: IEditableCategory[];
}>;

export type IEditableCategoriesSetAction = IEditableEntityAction<
	IEditableCategoriesSetState,
	IEditableCategoriesSetOriginal
>;

export const makeEmptyState = (): IEditableCategoriesSetState => ({
	id: '',
	name: '',
	shared: false,
	stages: [],
	companies: [],
	objects: [],
	categories: [],
	errors: {
		id: false,
		name: false,
		shared: false,
		stages: false,
		companies: false,
		objects: false,
		categories: false
	}
});

export const makeDefaultData = (
	categoriesSet?: ICategoriesSet,
	categories: ICategory[] = []
): IEditableCategoriesSetOriginal | undefined => {
	if (!categoriesSet) {
		return undefined;
	}
	return {categories, categoriesSet};
};

export const init = (original?: IEditableCategoriesSetOriginal): IEditableCategoriesSetState => {
	const empty = makeEmptyState();

	if (!original) {
		return empty;
	}
	const {categories, categoriesSet} = original;
	const state: IEditableCategoriesSetState = {
		id: categoriesSet.id ?? '',
		name: categoriesSet?.name ?? '',
		shared: categoriesSet?.shared ?? false,
		companies: categoriesSet?.companies ?? [],
		objects: categoriesSet?.objects ?? [],
		stages: categoriesSet.stages ?? [],
		categories: categories ?? [],
		errors: empty.errors
	};

	return state;
};

const isIdEdited = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal
): boolean => (original?.categoriesSet.id ? original.categoriesSet.id !== state.id : !!state.id);

const isNameEdited = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal
): boolean =>
	(original?.categoriesSet.name ? original.categoriesSet.name !== state.name : !!state.name);

const isSharedEdited = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal
): boolean =>
	(original?.categoriesSet.shared ? original.categoriesSet.shared !== state.shared : state.shared);

const isStagesEdited = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal
): boolean => {
	if (!original) {
		return !!state.stages.length;
	}

	const {categoriesSet} = original;

	const isLengthEqual = categoriesSet.stages?.length !== state.stages.length;
	const isStagesEqual = !!categoriesSet.stages?.some(item => !state.stages.includes(item));

	return isLengthEqual || isStagesEqual;
};

const isCompaniesEdited = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal
): boolean => {
	if (!original) {
		return !!state.companies.length;
	}

	const {categoriesSet} = original;

	const isLengthEqual = categoriesSet.companies?.length !== state.companies.length;
	const isCompaniesEqual = !!categoriesSet.companies?.some(id => !state.companies.includes(id));

	return isLengthEqual || isCompaniesEqual;
};

const isObjectsEdited = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal
): boolean => {
	if (!original) {
		return !!state.objects.length;
	}

	const {categoriesSet} = original;

	const isLengthEqual = categoriesSet.objects?.length !== state.objects.length;
	const isObjectsEqual = !!categoriesSet.objects?.some(id => !state.objects.includes(id));

	return isLengthEqual || isObjectsEqual;
};

export const isCategoriesEdited = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal
): boolean => {
	if (!original) {
		return !!state.categories.length;
	}

	const {categories} = original;

	const isLengthEqual = categories.length !== state.categories.length;
	const isCategoriesEqual = !!categories.some(
		category =>
			!state.categories.find(
				item => item.id === category.id && item.categoriesSetId === category.categoriesSetId
			)
	);

	return isLengthEqual || isCategoriesEqual;
};

export const isEdited = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal
): boolean =>
	isEntityEdited(
		state,
		original,
		isNameEdited,
		isSharedEdited,
		isStagesEdited,
		isCompaniesEdited,
		isObjectsEdited,
		isCategoriesEdited
	);

export const errorsFns = {
	id: (state: IEditableCategoriesSetState) => !state.id,
	name: (state: IEditableCategoriesSetState) => !state.name,
	companies: (state: IEditableCategoriesSetState) => !state.companies.length,
	objects: (state: IEditableCategoriesSetState) => !state.objects.length,
	shared: (state: IEditableCategoriesSetState) => state.shared == null,
	stages: (state: IEditableCategoriesSetState) => !state.stages.length,
	categories: (state: IEditableCategoriesSetState) =>
		state.categories.some(category => !category.categoriesSetId)
};

export const convertToSave = (
	state: IEditableCategoriesSetState,
	original?: IEditableCategoriesSetOriginal,
	onlyEdited?: boolean
): [ISavingCategoriesSet, ISavingCategory[]] => {
	if (!onlyEdited) {
		const {categories, ...rest} = state;
		return [rest, categories];
	}
	const categoriesSet: ISavingCategoriesSet = {
		id: state.id
	};
	const categories: ISavingCategory[] = [];

	if (isIdEdited(state, original)) {
		categoriesSet.id = state.id;
	}
	if (isCompaniesEdited(state, original)) {
		categoriesSet.companies = state.companies;
	}
	if (isNameEdited(state, original)) {
		categoriesSet.name = state.name;
	}
	if (isSharedEdited(state, original)) {
		categoriesSet.shared = state.shared;
	}
	if (isObjectsEdited(state, original)) {
		categoriesSet.objects = state.objects;
	}
	if (isStagesEdited(state, original)) {
		categoriesSet.stages = state.stages;
	}
	if (isCategoriesEdited(state, original)) {
		categories.push(...state.categories);
	}
	return [categoriesSet, categories];
};
