import React, {memo} from 'react';
import {CellProps} from 'react-table';
import {IDocument} from '@tehzor/types-and-subjects/ts/documents';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';

export const CreatedCell = memo(({row}: CellProps<IDocument>) => {
	const {createdByUser, createdAt} = row.original;

	if (!createdAt) return null;

	return (
		<>
			<div>{format(new Date(createdAt), dateTimeCommaSeparatedFormat)}</div>
			<div>{createdByUser?.displayName}</div>
		</>
	);
});
