import {wsConnector} from '../wsConnector';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {ISavingOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ISavingOwnerAcceptance';

export type IEditOwnerAcptResponse = IOwnerAcceptance;

/**
 * Изменяет передачу собственнику
 *
 * @param objectId id объекта
 * @param acceptanceId id передачи
 * @param fields данные передачи
 */
export const makeOwnerAcptEditRequest = (
	objectId: string,
	acceptanceId: string,
	fields: ISavingOwnerAcceptance
) =>
	wsConnector.sendAuthorizedRequest<IEditOwnerAcptResponse>('editOwnerAcceptance', {
		objectId,
		acceptanceId,
		...fields
	});
