import React from 'react';
import {toast, ToastOptions, TypeOptions} from 'react-toastify';

const addToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions,
	type: TypeOptions = 'default',
) =>
	toast(
		<div className="custom-toast">
			{icon && <div className="custom-toast__icon">{icon}</div>}
			<div className="custom-toast__text">
				<div className="custom-toast__title">{title}</div>
				<div className="custom-toast__desc">{desc}</div>
			</div>
		</div>,
		{
			type,
			...options,
			containerId: 'AppNotificationsContainer'
		}
	);

export const addInfoToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions
) => addToast(title, desc, undefined , options,'info');

export const addSuccessToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions
) => addToast(title, desc, undefined, options,'success');

export const addWarningToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions
) => addToast(title, desc, undefined, options, 'warning');

export const addErrorToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions
) => addToast(title, desc, undefined, options, 'error');
