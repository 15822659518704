import React, {memo, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {extractInspectors} from '@src/store/modules/dictionaries/companies/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import SelectSearch, {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';

interface ICreatedByFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const CreatedByFilterMobile = memo(
	({objectId, value, entity, onChange}: ICreatedByFilterMobileProps) => {
		const [selected, setSelected] = useState(value);
		const [search, setSearch] = useState('');

		const users = useAppSelector(s => extractInspectors(s, objectId));
		const elements = useMemo(() => users.map(user => ({id: user.id, name: user.fullName})), [users]);
		const filteredElements = useMemo(() => flatFilter(elements, 'name', search), [search, users]);

		useUpdateEffect(() => {
			setSelected(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'createdBy');

		const handleChange = (v: string[] | undefined) => {
			onChange({createdBy: v});
		};

		return (
			<MobileFilter
				label="Автор"
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
					/>
				)}
			/>
		);
	}
);
