import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {Column} from 'react-table';
import {MenuCell} from './MenuCell';
import {PlanCell} from './PlanCell';

export const columns: Array<Column<IPlan>> = [
	{
		id: 'plan',
		Header: 'План',
		Cell: PlanCell,
		accessor: item => item
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		className: 'plans-page__menu-cell'
	}
];
