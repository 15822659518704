import React, {useCallback, useState} from 'react';
import {DocumentsDialog} from '../DocumentsDialog';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';

interface IUseDocumentsDialog {
	documentsDialog: JSX.Element;
	openDocumentsDialog: () => void;
}

export const useDocumentsDialog = (documents: IDocument[]): IUseDocumentsDialog => {
	const [isOpen, setIsOpen] = useState(false);

	const totalCount = documents.length;

	const onOpen = useCallback(() => {
		setIsOpen(true);
	}, [setIsOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	return {
		documentsDialog: (
			<DocumentsDialog
				documents={documents}
				totalCount={totalCount}
				title="Документы"
				isOpen={isOpen}
				onClose={onClose}
			/>
		),
		openDocumentsDialog: onOpen
	};
};
