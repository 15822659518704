import React, {memo} from 'react';
import {EditorInfo, EntityGrid} from '@tehzor/ui-components';
import Description from '@src/components/viewFields/Description';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import ObjectInfo from '@src/components/viewFields/ObjectInfo';
import IntervalView from '@src/components/viewFields/IntervalView';
import Location from '@src/components/viewFields/Location';
import {ResponsibleTask} from '@src/components/viewFields/ResponsibleTask';
import MobileImages from '../attachments/Images.mobile';
import TaskType from '@src/components/viewFields/TaskType';
import {TaskStatusGrid} from './TaskStatusGrid';
import {TaskPriority} from '@src/components/viewFields/TaskPriority';

interface IMobileInfoProps {
	task: IEnrichedTask;
	images?: string[];

	onImageClick?: (index: number) => void;
}

const MobileInfo = ({task, images, onImageClick}: IMobileInfoProps) => (
	<EntityGrid withBorders>
		<TaskStatusGrid task={task}/>
		{!!task.taskPriority && (
			<TaskPriority task={task}/>
		)}
		{task.taskType && <TaskType task={task}/>}
		{task.description && <Description value={task.description}/>}
		<ObjectInfo objectId={task.objectId.id.toString()}/>
		{(task.taskIntervalStart || task.taskIntervalEnd) && (
			<IntervalView
				start={task.taskIntervalStart}
				stop={task.taskIntervalEnd}
			/>
		)}
		{images !== undefined && images.length > 0 && (
			<MobileImages
				images={images}
				onClick={onImageClick}
			/>
		)}
		{!!task.location && (
			<Location
				objectId={task.objectId.id}
				location={task.location}
				floor={task.floor}
				planId={task.planId}
			/>
		)}

		{task.respUsers?.length || task.activeGroup ? <ResponsibleTask task={task}/> : null}
		{task.createdAtNumber && (
			<EditorInfo
				icon={<i className="tz-document-20"/>}
				label="Создано"
				date={task?.createdAtNumber}
				user={task.createdBy}
			/>
		)}
		{task.createdAt !== task.modifiedAt && (
			<EditorInfo
				icon={<i className="tz-edit-20"/>}
				label="Изменено"
				date={task?.modifiedAtNumber}
				user={task.modifiedBy}
			/>
		)}
	</EntityGrid>
);

export default memo(MobileInfo);
