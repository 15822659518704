import React from 'react';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import BaseStatusSelect, {IBaseStatusSelectProps} from '../BaseStatusSelect/BaseStatusSelect';
import {getSelectedStatus} from '../BaseStatusSelect/utils/getSelectedStatus';
import {IStatus} from './utils/IStatus';
import {useStatusMenu} from './hooks/useStatusMenu';
import {getStatusColor} from './utils/getStatusColor';
import './StatusSelect.less';

interface IStatusSelectProps extends Omit<
	IBaseStatusSelectProps, 'menu' | 'selectedStatus'
> {
	statuses?: INormalizedData<IStatus>;
	availableStatuses?: string[];
	value?: string;

	onChange?: (value: string) => void;
}

export const StatusSelect = (props: IStatusSelectProps) => {
	const {
		statuses,
		availableStatuses,
		value,
		onChange,
		...baseStatusSelectProps
	} = props;

	const selectedStatus = getSelectedStatus<IStatus>(value, statuses);
	const menu = useStatusMenu<IStatus>(
		{selectedStatus, statuses, availableStatuses, onChange}
	);

	return (
		<BaseStatusSelect
			{...baseStatusSelectProps}
			selectedStatus={selectedStatus}
			menu={menu}
			color={getStatusColor(selectedStatus)}
		/>
	);
};
