import React from 'react';
import './CommentChange.less';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {IChangeComponentProps} from '../interfaces/IChangeComponent';
import {TextChange} from '../TextChange';
import {AttachmentsChange} from '../AttachmentsChange';
import classNames from 'classnames';

interface ICommentChangeProps extends IChangeComponentProps {
	value?: {
		attachments: IAttachment[] | undefined;
		description: string | undefined;
	};
}

export const CommentChange = ({className, style, value}: ICommentChangeProps) => (
	<div
		className={classNames('history-comment-change', className)}
		style={style}
	>
		{value?.description && <TextChange value={value?.description}/>}
		{value?.attachments && value?.attachments.length > 0 && <AttachmentsChange value={value?.attachments}/>}
	</div>
);
CommentChange.displayName = 'CommentChange';
