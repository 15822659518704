import {wsConnector} from '../wsConnector';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ISavingCheckRecord} from '@tehzor/tools/interfaces/checkRecords/ISavingCheckRecord';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IAddCheckRecordResponse = ICheckRecord;

/**
 * Создает запись по категории
 *
 * @param objectId id объекта
 * @param links связанные сущности
 * @param stage стадия
 * @param fields данные для добавления
 */
export const makeCheckRecordAddRequest = (
	objectId: string,
	links: ICheckRecord['links'],
	stage: ObjectStageIds,
	fields: ISavingCheckRecord
) =>
	wsConnector.sendAuthorizedRequest<IAddCheckRecordResponse>('addCheckRecord', {
		objectId,
		links,
		stage,
		...fields
	});
