import React, {memo} from 'react';
import './UserMenu.less';
import classNames from 'classnames';
import PopupMenu from '../PopupMenu';
import {IMenuProps} from '../Menu';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IMenuItemProps} from '../MenuItem';
import UserButton from '../../buttons/UserButton';
import {UserAvatarSize} from '../../various/UserAvatar';

interface IHeaderUserMenuProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactElement<IMenuProps> | Array<React.ReactElement<IMenuItemProps>>;
	user?: IUser;
	loading?: boolean;
	avatarSize?: UserAvatarSize;
}

const UserMenu = ({className, style, children, user, loading, avatarSize}: IHeaderUserMenuProps) => (
	<PopupMenu
		trigger={({isOpen, toggle, ref, disabled: triggerDisabled}) => (
			<UserButton
				className={{
					root: classNames('user-menu__button', className),
					rightIcon: classNames('user-menu__button-icon', {'user-menu__button-icon_open': isOpen})
				}}
				style={style}
				rightIcon={(
					<div
						className="user-menu__button-icon-wrap"
						ref={ref}
     >
						<i className="tz-simple-arrow-16"/>
					</div>
				)}
				disabled={loading || triggerDisabled || !children}
				user={user}
				avatarSize={avatarSize}
				onClick={toggle}
			/>
		)}
		arrowVisible
		popupProps={{placement: 'bottom-end', preferX: 'right'}}
	>
		{children}
	</PopupMenu>
);

UserMenu.displayName = 'UserMenu';

export default memo(UserMenu);
