import classNames from 'classnames';
import React, {MouseEvent} from 'react';
import './StatsCard.less';
import {calculateColors} from './utils/calculateColors';

interface IStatsCardProps {
	className?: string;
	style?: React.CSSProperties;
	icon?: React.ReactNode;
	label?: string;
	value: number;
	difference?: number;
	reverseDifference?: boolean;
	baseColor?: string;
	backgroundColor?: string;
	iconColor?: string;
	iconBackgroundColor?: string;
	valueBackgroundColor?: string;
	valueShadowColor?: string;
	onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const StatsCard = (props: IStatsCardProps) => {
	const {
		className,
		style,
		label,
		value,
		difference,
		reverseDifference,
		icon,
		baseColor,
		backgroundColor,
		iconColor,
		iconBackgroundColor,
		valueBackgroundColor,
		valueShadowColor,
		onClick
	} = props;

	const [calcBg, calcValueBg, calcValueShadow] = calculateColors(baseColor);

	const bg = backgroundColor || calcBg;
	const iconBg = iconBackgroundColor || baseColor;
	const isSetIconBg = !!iconBg && iconBg !== 'transparent';
	const shadow = valueShadowColor || calcValueShadow;

	return (
		<div
			className={classNames('stats-card', {'stats-card_clickable': !!onClick}, className)}
			style={{
				...style,
				paddingLeft: isSetIconBg ? undefined : '16px',
				backgroundColor: bg
			}}
			onClick={onClick}
		>
			{icon && (
				<div
					className="stats-card__icon-wrap"
					style={{
						backgroundColor: iconBg,
						color: iconColor || (isSetIconBg ? '#fff' : undefined)
					}}
				>
					{icon}
				</div>
			)}

			<div
				className="stats-card__label"
				title={label}
			>
				{label}
			</div>

			<div
				className="stats-card__value-wrap"
				style={{
					backgroundColor: valueBackgroundColor || calcValueBg,
					boxShadow: shadow ? `0 4px 4px ${shadow}` : undefined
				}}
			>
				<div className="stats-card__value-cont">
					<div className="stats-card__value">{value}</div>

					{difference !== undefined && difference !== 0 && (
						<div
							className={classNames(
								'stats-card__difference',
								(reverseDifference
								? difference < 0
								: difference > 0)
									? 'stats-card__difference_positive'
									: 'stats-card__difference_negative'
							)}
						>
							<i
								className={classNames('tz-expand-arrow-heavy stats-card__difference-icon', {
									'stats-card__difference-icon_up': difference > 0
								})}
							/>
							{difference > 0 && '+'}
							{difference}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

StatsCard.displayName = 'StatsCard';

export default StatsCard;
