import * as types from '../constants';

/**
 * Изменяет смещение списка
 *
 * @param objectId id объекта
 * @param offset смещение
 */
export const changeOffset = (objectId = 'all', offset: number) => ({
	type: types.CHANGE_OFFSET,
	payload: {
		objectId,
		offset
	}
});

/**
 * Изменяет выбранные строки в таблице
 *
 * @param objectId id объекта
 * @param selectedRows массив id выбранных строк
 */
export const changeSelectedRows = (objectId = 'all', selectedRows: string[]) => ({
	type: types.CHANGE_SELECTED_ROWS,
	payload: {
		objectId,
		selectedRows
	}
});

/**
 * Переключает флаг выбора всех строк в таблице
 *
 * @param objectId id объекта
 * @param isAllRowsSelected true/false - включить/выключить, undefined - поменять на противоположное значение
 */
export const toggleAllRowsSelected = (objectId = 'all', isAllRowsSelected?: boolean) => ({
	type: types.TOGGLE_ALL_ROWS_SELECTED,
	payload: {
		objectId,
		isAllRowsSelected
	}
});

/**
 * Очищает параметр expiration
 */
 export const clearExpiration = (objectId = 'all') => ({
	type: types.CLEAR_EXPIRATION,
	payload: {objectId}
});


export * from './get';
export * from './export';
