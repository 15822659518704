import {createReducer} from '@reduxjs/toolkit';
import * as listTypes from './constants';
import * as detailTypes from '../warrantyClaim/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {CHANGE_SORT, CLEAR_FILTERS} from '../../settings/pages/warrantyClaims/constants';
import config from '@src/core/config';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {IGetWarrantyClaimsPayload} from '@src/store/modules/entities/warrantyClaims/actions';
import {
	IDeleteWarrantyClaimPayload,
	IMoveWarrantyClaimPayload
} from '@src/store/modules/entities/warrantyClaim/actions';

const {entitiesCacheTime} = config;

export interface IWarrantyClaimsPageState {
	byId: {
		[id: string]: IWarrantyClaim;
	};
	allIds: string[];
	offset: number;
	total: number;
	expires?: number;
	selectedRows: string[];
	isAllRowsSelected: boolean;
}

export interface IWarrantyClaimsPagesState {
	[objectId: string]: IWarrantyClaimsPageState;
}

export const getInitialStateForPage = (): IWarrantyClaimsPageState => ({
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	expires: undefined,
	selectedRows: [],
	isAllRowsSelected: false
});

const clearPage = (state: IWarrantyClaimsPagesState, {payload}: {payload: {objectId: string}}) => {
	if (payload.objectId !== 'all') {
		state[payload.objectId] = getInitialStateForPage();
	}
	state.all = getInitialStateForPage();
};

const deleteClaim = (state: IWarrantyClaimsPagesState, objectId: string, claimId: string) => {
	const page = state[objectId];
	page.allIds = page.allIds.filter(id => id !== claimId);
	delete page.byId[claimId];
	page.expires = undefined;
};

export default createReducer<IWarrantyClaimsPagesState>(
	{},
	{
		[listTypes.GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[listTypes.GET_SUCCESS]: (state, {payload}: {payload: IGetWarrantyClaimsPayload}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			const page = state[payload.objectId];
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.offset = payload.offset;
			page.total = payload.total;
			page.expires = Date.now() + entitiesCacheTime * 1000;
		},
		[listTypes.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string, offset: number}}
		) => {
			const page = state[payload.objectId];
			if (page) {
				page.offset = payload.offset;
				page.expires = undefined;
			}
		},
		[listTypes.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: {objectId: string, selectedRows: string[]}}
		) => {
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		[listTypes.TOGGLE_ALL_ROWS_SELECTED]: (
			state,
			{payload}: {payload: {objectId: string, isAllRowsSelected?: boolean}}
		) => {
			const page = state[payload.objectId];
			page.isAllRowsSelected
				= payload.isAllRowsSelected !== undefined
					? payload.isAllRowsSelected
					: !page.isAllRowsSelected;
		},
		// При сортировке нужно обнулить expires для игнорирования кеша
		[CHANGE_SORT]: (state, {payload}: {payload: {objectId: string}}) => {
			state[payload.objectId].expires = undefined;
		},
		// При сбрасывании фильтров нужно обнулить expires для игнорирования кеша
		[CLEAR_FILTERS]: (state, {payload}: {payload: {objectId: string}}) => {
			state[payload.objectId].expires = undefined;
		},
		// При манипуляциях с затрагивающими данными необходимо обновить нарушения
		[detailTypes.ADD_SUCCESS]: clearPage,
		[detailTypes.EDIT_STATUS_SUCCESS]: clearPage,
		[detailTypes.EDIT_SUCCESS]: clearPage,
		[detailTypes.DELETE_SUCCESS]: (
			state,
			{payload}: {payload: IDeleteWarrantyClaimPayload}
		) => {
			if (payload.objectId !== 'all' && state[payload.objectId]) {
				deleteClaim(state, payload.objectId, payload.claimId);
			}
			if (state.all) {
				deleteClaim(state, 'all', payload.claimId);
			}
		},
		[detailTypes.MOVE_SUCCESS]: (state, {payload}: {payload: IMoveWarrantyClaimPayload}) => {
			if (payload.objectId !== 'all' && state[payload.objectId]) {
				deleteClaim(state, payload.objectId, payload.claimId);
			}
			if (state.all) {
				deleteClaim(state, 'all', payload.claimId);
			}
		},
		[problemTypes.EDIT_SUCCESS]: clearPage,
		[problemTypes.EDIT_STATUS_SUCCESS]: clearPage,
		[problemTypes.DELETE_SUCCESS]: clearPage,
		[CLEAR_STORE]: () => ({})
	}
);
