import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import React from 'react';
import {useManageProblemTagsSetsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useManageProblemTagsSetsBreadcrumbs';

export const EditPageBreadcrumbs = () => {
	const breadcrumbs = useManageProblemTagsSetsBreadcrumbs();

	return (
		<AppBreadcrumbs
			className="manage-problem-tags-set-edit-page__breadcrumbs"
			items={breadcrumbs}
		/>
);
};
