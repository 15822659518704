import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IObjectTree} from '@src/interfaces/IObjectTree';
import {changeSort} from '@src/store/modules/settings/pages/objects/actions';
import {extractObjectsPageSettings} from '@src/store/modules/settings/pages/objects/selectors';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IParentObject} from '@tehzor/tools/interfaces/objects/IParentObject';
import {EntitiesTable} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {Column} from 'react-table';

interface IObjectsTableProps {
	filterValue?: string;
	columns: Array<Column<IObject>>;
	treeObjects: IObjectTree[];
	hideHead?: boolean;
	noRowBorder?: boolean;
	selectable?: boolean;
}

export const ObjectsTable = ({
	filterValue,
	columns,
	treeObjects,
	hideHead,
	noRowBorder,
	selectable
}: IObjectsTableProps) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const {sort} = useAppSelector(extractObjectsPageSettings);

	const handleRowClick = useCallback((object: IParentObject) => {
		if (object.children && object.children.length) {
			return pushPath(`/objects/${object.id}`);
		}
		return pushPath(`/objects/${object.id}/problems`);
	}, []);

	const handleSortChange = useCallback((value: Record<string, boolean>) => {
		dispatch(changeSort('all', value));
	}, []);

	return (
		<EntitiesTable
			columns={columns}
			data={treeObjects}
			selectable={selectable}
			noRowBorder={noRowBorder}
			headVisible={!hideHead}
			sort={sort}
			filterValue={filterValue}
			onRowClick={handleRowClick}
			onSortChange={handleSortChange}
		/>
	);
};
