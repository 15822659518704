import {testProblemRestrictions} from '@src/utils/testRestrictions';
import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';

export interface IPermissions {
	canViewComments?: boolean;
	canViewReplies?: boolean;
	canAddComments?: boolean;
	canAddReplies?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param objectId id объекта
 * @param problem нарушение
 */
export const useCommentsPermissions = (
	objectId: string,
	problem?: IPreparedProblem
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canViewComments: testProblemRestrictions(
				user.id,
				user.roles,
				'problemCommentsView',
				objectId,
				problem?.createdBy?.id,
				problem?.respUsers
			),
			canViewReplies: testProblemRestrictions(
				user.id,
				user.roles,
				'problemRepliesView',
				objectId,
				problem?.createdBy?.id,
				problem?.respUsers
			),
			canAddComments: testProblemRestrictions(
				user.id,
				user.roles,
				'problemCommentsAdd',
				objectId,
				problem?.createdBy?.id,
				problem?.respUsers
			),
			canAddReplies: testProblemRestrictions(
				user.id,
				user.roles,
				'problemRepliesAdd',
				objectId,
				problem?.createdBy?.id,
				problem?.respUsers
			)
		};
	}, [objectId, problem, user]);
};
