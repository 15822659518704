import React from 'react';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {IMarker} from '@tehzor/tools/interfaces/markers/IMarker';
import {IOwnerAcceptanceStatus} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';

interface IMobileCellInfoProps {
	number?: number;
	localNumber?: string;
	resolution?: string;
	status?: IOwnerAcceptanceStatus;
	markers?: IMarker[];
	plan?: IPlan;
	spaceOwner?: {
		id: string;
		name: string;
		phone?: string;
		email?: string;
	};
	createdBy?: IBriefUser;
	createdAt: string;
	modifiedAt: string;
	modifiedBy?: IBriefUser;
}

export const MobileCellInfo = ({
	number,
	localNumber,
	resolution,
	status,
	markers,
	plan,
	spaceOwner,
	createdAt,
	modifiedAt,
	createdBy,
	modifiedBy
}: IMobileCellInfoProps) => (
	<>
		<div className="owner-acceptances-page__mobile-cell-header">
			<div className="owner-acceptances-page__mobile-cell-number">
				№
				{number ?? localNumber}
			</div>
		</div>
		<div
			style={{color: status?.textColor}}
			className="owner-acceptances-page__mobile-cell-status"
		>
			{status?.name}
		</div>
		<div className="owner-acceptances-page__mobile-cell-resolution">
			<span className="owner-acceptances-page__mobile-cell-resolution-title">Решение:</span>
			<span>{resolution}</span>
		</div>
		{spaceOwner?.id && (
			<div className="owner-acceptances-page__mobile-cell-space-owner">
				<div className="owner-acceptances-page__mobile-cell-space-owner-label">
					Собственник:
				</div>
				<div className="owner-acceptances-page__mobile-cell-space-owner-container">
					{spaceOwner?.name}
					{spaceOwner?.phone && `, ${spaceOwner?.phone}`}
					{spaceOwner?.email && `, ${spaceOwner?.email}`}
				</div>
			</div>
		)}
		<div className="owner-acceptances-page__mobile-cell-created">
			<div className="owner-acceptances-page__mobile-cell-created-label">Добавлено:</div>
			<div className="owner-acceptances-page__mobile-cell-created-container">
				<div className="owner-acceptances-page__mobile-cell-created-date">{createdAt}</div>
				<div className="owner-acceptances-page__mobile-cell-created-name">
					{createdBy?.fullName}
				</div>
			</div>
		</div>
		{modifiedBy ? (
			<div className="owner-acceptances-page__mobile-cell-created">
				<div className="owner-acceptances-page__mobile-cell-created-label">Изменено:</div>
				<div className="owner-acceptances-page__mobile-cell-created-container">
					<div className="owner-acceptances-page__mobile-cell-created-date">
						{modifiedAt}
					</div>
					<div className="owner-acceptances-page__mobile-cell-created-name">
						{modifiedBy?.fullName}
					</div>
				</div>
			</div>
		) : null}
		{(plan?.name || plan?.floor || markers) && (
			<div className="owner-acceptances-page__m-table-location-block">
				{plan?.name && (
					<div className="owner-acceptances-page__m-table-location-line">
						<span className="owner-acceptances-page__m-table-location-title">
							План:
						</span>
						<div className="owner-acceptances-page__m-table-location-subtitle">
							{plan?.name}
						</div>
					</div>
				)}
				{plan?.floor && (
					<div className="owner-acceptances-page__m-table-location-line">
						<span className="owner-acceptances-page__m-table-location-title">
							Этаж:
						</span>
						<div className="owner-acceptances-page__m-table-location-subtitle">
							{plan?.floor}
						</div>
					</div>
				)}
				{!!markers?.length && (
					<div className="owner-acceptances-page__m-table-location-line">
						<span className="owner-acceptances-page__m-table-location-title">
							Место:
						</span>
						<div className="owner-acceptances-page__m-table-location-subtitle">
							{markers[0].name}
						</div>
					</div>
				)}
			</div>
		)}
	</>
);
