import React from 'react';
import './CheckListsPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAsync} from 'react-use';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {Table} from '@src/pages/manage/CheckListsPage/components/table/Table.desktop';
import {getCheckListTypes} from '@src/store/modules/entities/checkListTypes/actions';
import {PageBreadcrumbs} from '@src/pages/manage/CheckListsPage/components/PageBreadcrumbs';
import {MobileTable} from './components/table/Table.mobile';
import {DesktopAddButton} from './components/DesktopAddButton';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {getCheckLists} from '@src/store/modules/dictionaries/checkLists/actions';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

export const CheckListsPage = () => {
	useAppHeader({
		title: 'Чек-листы',
		mobileRightButtons: <AppUpdateMenu/>
	});
	const permissions = useCheckListPermissions();

	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();

	const loadingState = useAsync(async () => {
		await dispatch(getCheckLists());
		await dispatch(getCheckListTypes());
	}, []);

	if (isDesktop) {
		return (
			<div className="page-cont manage-check-lists-page">
				<PageBreadcrumbs permissions={permissions}/>
				<Table loading={loadingState.loading}/>
			</div>
		);
	}
	return (
		<div className="page-cont manage-check-lists-page_mobile">
			<PageBreadcrumbs permissions={permissions}/>
			<MobileTable loading={loadingState.loading}/>
			{permissions.canAdd ? <DesktopAddButton/> : null}
		</div>
	);
};
