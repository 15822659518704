import React from 'react';
import {OfflineDataItem} from '@tehzor/ui-components';
import {IDictionaryCacheState} from '@src/store/modules/offlineMode/reducers/dictionariesCacheState';
import {OfflineDataCachingStatus} from '@tehzor/tools/contracts/dataCachingWebWorker/interfaces/IOfflineDataCachingStatuses';
import {someInProgress} from '../utils/someInProgress';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useCacheStates} from '../hooks/useCacheStates';

interface IObjectsCacheStatusProps {
	filesCacheState?: IDictionaryCacheState;
}

export const ObjectsCacheStatus = ({filesCacheState}: IObjectsCacheStatusProps) => {
	const [isFetching, isError, isStale, isPaused, dataUpdatedAt] = useCacheStates(
		objectsQueryKeys.list()
	);
	const error
		= isError && filesCacheState?.status === OfflineDataCachingStatus.ERROR
			? 'Ошибка'
			: isStale
			? 'Данные устарели'
			: isError
			? 'Ошибка (данные)'
			: filesCacheState?.status === OfflineDataCachingStatus.ERROR
			? 'Ошибка (файлы)'
			: undefined;

	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title="Объекты"
			icon={<i className="tz-internal-acceptance-24"/>}
			date={dataUpdatedAt}
			error={error}
			inProgress={!!isFetching || isPaused || someInProgress([filesCacheState])}
			progressValue={filesCacheState?.progress}
			progressTitle={
				!!isFetching && filesCacheState?.status === OfflineDataCachingStatus.LOADING
					? 'Скачивание файлов...'
					: undefined
			}
		/>
	);
};
