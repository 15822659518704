import React, {useMemo} from 'react';
import './DownloadableFile.less';
import ButtonBase from '../../buttons/ButtonBase';
import {getIconByType} from './utils/getIconByType';
import filesize from 'filesize';
import {getLinkDownloadAttr} from './utils/getLinkDowloadAttr';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {normalizeTextEncoding} from '../../../utils/normalizeTextEncoding';

interface IDocFileProps {
	className?:
		| string
		| {
				root?: string;
				name?: string;
				size?: string;
		  };
	style?: React.CSSProperties;
	name: string;
	url?: string;
	type: string;
	size?: number;
	icon?: React.ReactNode;
	onClick?: () => void;
}

const DownloadableFile = ({
	className, style, name, url, type, size, icon, onClick
}: IDocFileProps) => {
	const classes = convertClassNames(className);

	const normalizedName = useMemo(() => normalizeTextEncoding(name), [name]);

	return (
		<ButtonBase
			className={classNames({'downloadable-file_custom-icon': icon}, classes.root)}
			style={style}
			classNamePrefix="downloadable-file"
			outerTagType="a"
			outerTagProps={url ? {
				href: url,
				title: normalizedName,
				target: '_blank',
				download: getLinkDownloadAttr(normalizedName, type),
				rel: 'noreferrer nofollow noopener'
			} : undefined}
			leftIcon={icon ?? getIconByType(type)}
			onClick={onClick}
			label={(
				<>
					<div className={classNames('downloadable-file__name', classes.name)}>{normalizedName}</div>
					{size !== undefined && (
						<div className={classNames('downloadable-file__size', classes.size)}>{filesize(size)}</div>
					)}
				</>
			)}
		/>
	);
};

export default DownloadableFile;
