import React, {useCallback, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractCheckListById,
	extractCheckListsAsArrayByObjectId
} from '@src/store/modules/dictionaries/checkLists/selectors';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckListLink} from '@tehzor/tools/utils/links';
import {
	EntityGrid,
	EntityGridItem,
	ExpandablePanel,
	LinkButton,
	LoadingPanel,
	MobilePagination,
	Plate
} from '@tehzor/ui-components';
import {CheckListItems} from '@src/pages/CheckListPage/components/list/CheckListItems/CheckListItems';
import {RecordStatusSelect} from '@src/pages/CheckListPage/components/RecordStatusSelect';
import {DiagramIcon} from '../DiagramIcon/DiagramIcon';
import MobileLinks from '../links/Links.mobile';
import {extractSpace} from '@src/store/modules/entities/space/selectors';
import {extractProblemsByCheckListIdAsArray} from '@src/store/modules/pages/checkLists/selectors/problems';
import declination from '@tehzor/tools/utils/declination';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import TitleButtons from '../TitleButtons.mobile';
import TitleObjectButtons from '../TitleObjectButtons.mobile';
import CheckListExportDialog from '../actions/CheckListExportDialog';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {ProblemIndicators} from '@src/pages/CheckListPage/components/ProblemIndicators';
import {useToggle, useUpdateEffect} from 'react-use';
import {extractCheckRecordsIsChanging} from '@src/store/modules/pages/checkLists/selectors/records';
import {TaskIndicators} from '@src/pages/CheckListPage/components/TaskIndicators';
import {useEntityAddingPermission} from '@src/pages/CheckListPage/hooks/useEntityAddingPermission';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {CheckListDetailTabs} from '@src/pages/CheckListPage/components/detail/Tabs/CheckListDetailTabs';
import classNames from 'classnames';
import {extractCheckItemsAsTree} from '@src/store/modules/dictionaries/checkItems/selectors';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import {TitleWorkAcceptanceButtons} from '@src/pages/WorkAcceptancePage/components/TitleButtons.mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const ListDetailMobile = () => {
	const {replacePath} = useChangePath();
	const params = useStrictParams<{
		objectId: string;
		stage: string;
		listId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();
	const {objectId, listId, spaceId, workAcceptanceId} = params;

	const [changingState, setChangingState] = useState<boolean>(false);
	const items = useAppSelector(s => extractCheckItemsAsTree(s, listId));
	const checkList = useAppSelector(s => extractCheckListById(s, listId));

	const {changeCheckListRecord} = useChangeCheckRecord(objectId, checkList);
	const checkLists = useAppSelector(s => extractCheckListsAsArrayByObjectId(s, objectId));
	const itemsLoading = useAppSelector(s => s.dictionaries.checkItems.loading);
	const space = useAppSelector(extractSpace);
	const problems = useAppSelector(s => extractProblemsByCheckListIdAsArray(s, listId));
	const isChanging = useAppSelector(extractCheckRecordsIsChanging);

	const list = useAppSelector(s => extractCheckListById(s, listId));
	const [isOpen, toggle] = useToggle(false);

	const roles = useAppSelector(extractUserRoles);
	const checkListPermissions = useCheckListPermissions();
	const canAddEntity = useEntityAddingPermission(objectId, listId);
	const permissions = {
		canAddTask: canAddEntity && hasPermission(roles, 'tasks-add'),
		canAddEntity
	};

	const getTitleButtons = useCallback(() => {
		if (workAcceptanceId) {
			return (
				<TitleWorkAcceptanceButtons
					objectId={objectId}
					workAcceptanceId={workAcceptanceId}
				/>
			);
		}

		if (spaceId) {
			return (
				<TitleButtons
					objectId={objectId}
					spaceId={spaceId}
				/>
);
		}

		return <TitleObjectButtons objectId={objectId}/>;
	}, [objectId, spaceId, listId, workAcceptanceId]);
	useAppHeader(
		{
			title: 'Чек-лист',
			showBackBtn: true,
			titleButtons: getTitleButtons(),
			mobileRightButtons: spaceId && checkListPermissions.canExport && (
				<CheckListExportDialog
					objectId={objectId}
					spaceId={spaceId}
					checkListId={listId}
				/>
			)
		},
		[space, objectId, spaceId, listId, workAcceptanceId]
	);

	useUpdateEffect(() => {
		if (!isChanging) {
			setChangingState(false);
		}
	}, [isChanging]);

	const handlePageChange = useCallback(
		(nextIndex: number) => {
			const next = checkLists[nextIndex];
			replacePath(formCheckListLink({...params, listId: next.id}));
		},
		[checkLists, params]
	);

	if (!checkList) {
		return null;
	}

	const currentIndex = checkLists.indexOf(checkList);

	return (
		<div className="check-list-page__m-list-detail">
			<Plate
				withoutPadding
				className="check-list-page__m-list-detail-space-info"
			>
				<EntityGrid withBorders>
					<EntityGridItem
						className={{
							content: 'check-list-page__m-list-detail-space-info-link',
							header: 'check-list-page__m-list-detail-space-info-header'
						}}
						icon={
							workAcceptanceId !== undefined ? (
								<i className="tz-internal-acceptance-24"/>
							) : (
								<i className="tz-space-24"/>
						)
						}
						label={workAcceptanceId !== undefined ? 'Приёмка работ' : 'Помещение'}
						fullRow
					>
						{(spaceId !== undefined || workAcceptanceId !== undefined) && (
							<MobileLinks objectId={objectId}/>
						)}
					</EntityGridItem>
				</EntityGrid>
			</Plate>

			<Plate
				withoutPadding
				className="check-list-page__m-list-detail-info"
			>
				<MobilePagination
					className="spaces-page__m-pagination"
					page={currentIndex}
					pageCount={checkLists.length}
					renderTitle={({page}) => `${page + 1} чек-лист`}
					onPageChange={handlePageChange}
				/>
				<EntityGrid withBorders>
					<EntityGridItem
						className={{
							content: 'check-list-page__m-list-detail-info-header'
						}}
						fullRow
					>
						<div className="check-list-page__m-list">
							<DiagramIcon listId={listId}/>
							<div className="check-list-page__m-list-text">
								<div className="check-list-page__m-list-text-title">
									{checkList.name}
								</div>
								{problems.length > 0 && (
									<div className="check-list-page__m-list-text-subtitle">
										{problems.length}
										{' '}
										{declination(problems.length, [
											'нарушение',
											'нарушения',
											'нарушений'
										])}
									</div>
								)}
							</div>
							{/* TODO: <div className="check-list-page__m-list-detail-info-menu">menu</div> */}
						</div>

						<div className="check-list-page__m-list-detail-info-tags">
							<ProblemIndicators checkListId={checkList.id}/>
							<TaskIndicators checkListId={checkList.id}/>
						</div>
						<div className="check-list-page__m-list-status">
							<RecordStatusSelect
								item={checkList}
								mutation={changeCheckListRecord}
								setChangingState={setChangingState}
								showLoader={changingState && isChanging}
								disabled={isChanging || !checkListPermissions.canEdit}
							/>
						</div>
						{items && items.length > 0 && (
							<div className="check-list-page__body-toggle">
								<LinkButton
									className={classNames('check-list-page__body-toggle-link', {
										'check-list-page__body-toggle-link_active': isOpen
									})}
									rightIcon={(
										<i
											className={classNames(
												'tz-simple-arrow-24',
												'check-list-page__d-plate-header-title-arrow',
												{
													'check-list-page__d-plate-header-title-arrow_reversed':
														isOpen
												}
											)}
										/>
									)}
									label={
										isOpen
											? 'Скрыть'
											: `Показать ${items.length} ${declination(
													items.length,
													['категорию', 'категории', 'категорий']
											  )}`
									}
									onClick={toggle}
								/>
							</div>
						)}
					</EntityGridItem>
				</EntityGrid>
			</Plate>

			<ExpandablePanel expanded={isOpen}>
				<LoadingPanel active={itemsLoading}>
					<Plate
						withoutPadding
						className="check-list-page__m-list-detail-items"
					>
						<CheckListItems
							checkList={checkList}
							canAddEntity={canAddEntity}
						/>
					</Plate>
				</LoadingPanel>
			</ExpandablePanel>

			<Plate
				withoutPadding
				className="check-list-page__m-list-detail-tabs"
			>
				<CheckListDetailTabs
					list={list}
					permissions={permissions}
				/>
			</Plate>
		</div>
	);
};
