import {wsConnector} from '../wsConnector';
import ISavingProblemComment from '@tehzor/tools/interfaces/problemComments/ISavingProblemComment';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';

export type IAddProblemCommentResponse = IProblemComment;

/**
 * Добавляет комментарий к нарушению
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const makeProblemCommentAddRequest = (
	objectId: string,
	problemId: string,
	links: IProblemComment['links'] | undefined,
	fields: ISavingProblemComment
) =>
	wsConnector.sendAuthorizedRequest<IAddProblemCommentResponse>('addProblemComment', {
		objectId,
		problemId,
		links,
		...fields
	});
