import React, {useRef, useState} from 'react';
import {CheckListPlate} from '../CheckListPlate';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {DesktopDetailColumn} from '@src/pages/CheckListPage/components/DesktopDetailColumn';
import {Outlet, Route, Routes} from 'react-router-dom';
import {Table} from '../table/Table.desktop';
import {desktopColumns} from '../table/columns.desktop';
import {MainActionsDesktop} from '../actions/MainActions.desktop';
import {DesktopDescription} from '../description/Description.desktop';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {LoadingPanel, AdaptiveHeightPanel, Plate} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IDesktopRoutesProps {
	workAcceptance: IWorkAcceptance;
	objectId: string;
	checkLists: ICheckList[];
}

export const DesktopRoutes = ({workAcceptance, objectId, checkLists}: IDesktopRoutesProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const listsLoading = useAppSelector(s => s.dictionaries.checkLists.loading);
	const pageRef = useRef(null);

	const checkListsRoute = (
		<Route
			path="check-lists/:listId?/:pathParam?/:itemId?"
			element={(
				<div className="check-list-page__d-main">
					<div className="check-list-page__d-main-list">
						<LoadingPanel active={listsLoading}>
							<>
								{checkLists.map(checkList => (
									<CheckListPlate
										key={checkList.id}
										defaultOpen={checkLists.length === 1}
										checkList={checkList}
									/>
								))}
							</>
						</LoadingPanel>
					</div>

					<div className="check-list-page__d-main-detail">
						<AdaptiveHeightPanel pageRef={pageRef.current}>
							<DesktopDetailColumn/>
						</AdaptiveHeightPanel>
					</div>
				</div>
			)}
		/>
	);

	const problemsRoute = (
		<Route
			path="problems"
			element={(
				<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
					<div className="work-acceptance-page__p-controls">
						<MainActionsDesktop
							objectId={objectId}
							workAcceptance={workAcceptance}
						/>
					</div>

					<Plate withoutPadding>
						<Table
							objectId={objectId}
							columns={desktopColumns}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
							workAcceptanceId={workAcceptance.id}
							checkLists={checkLists}
						/>
					</Plate>
				</div>
			)}
		/>
	);

	const descriptionRoute = (
		<Route
			path=""
			element={(
				<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
					<DesktopDescription
						objectId={objectId}
						workAcceptance={workAcceptance}
					/>
				</div>
			)}
		/>
	);

	return (
		<>
			<Routes>
				{checkListsRoute}
				{problemsRoute}
				{descriptionRoute}
			</Routes>
			<Outlet/>
		</>
	);
};
