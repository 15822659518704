import {Button, FloatingActionButton} from '@tehzor/ui-components';
import React from 'react';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';

export const AddButton = () => {
	const [taskDialog, handleOpenTaskDialog] = useAddingTaskDialog({});

	return (
		<>
			<Desktop>
				<div className="tasks-page_add-button">
					<Button
						style={{marginBottom: '10px'}}
						type="accent-blue"
						leftIcon={<i className="tz-plus-20"/>}
						label="Добавить задачу"
						onClick={handleOpenTaskDialog}
					/>
				</div>
			</Desktop>

			<BeforeDesktop>
				<FloatingActionButton
					icon={<i className="tz-plus-16"/>}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleOpenTaskDialog}
					style={{zIndex: 2}}
				/>
			</BeforeDesktop>

			{taskDialog}
		</>
	);
};
