import React from 'react';
import './MenuSeparator.less';
import classNames from 'classnames';

export interface IMenuSeparatorProps {
	className?: string;
	style?: React.CSSProperties;
}

const MenuSeparator = ({className, style}: IMenuSeparatorProps) => (
	<div
		className={classNames('menu-separator', className)}
		style={style}
	/>
);

MenuSeparator.displayName = 'MenuSeparator';

export default MenuSeparator;
