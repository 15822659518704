import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import config from '../../../../core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	IAddCategoryPayload,
	IEditCategoryPayload,
	IGetCategoriesPayload,
	IDeleteManyCategoriesPayload,
	IDeleteCategoryPayload
} from './actions';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ILoadableEntitiesState} from '@tehzor/tools/core/storeHelpers/reducers';
import {IEditCategoriesOrderPayload} from './actions/edit-order';

const {entitiesCacheTime} = config;

export type ICategoriesState = ILoadableEntitiesState<ICategory>;

export const getInitialState = (): ICategoriesState => ({
	allIds: [],
	byId: {},
	loaded: false,
	loadedAt: undefined
});

export const categories = createReducer<ICategoriesState>(getInitialState(), {
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetCategoriesPayload}) => {
		state.byId = payload.byId;
		state.allIds = payload.allIds;
		state.loaded = true;
		state.loadedAt = Date.now() + entitiesCacheTime * 1000;
	},
	[types.ADD_SUCCESS]: (state, {payload}: {payload: IAddCategoryPayload}) => {
		state.byId[payload.id] = payload;
		state.allIds.push(payload.id);
		state.loaded = false;
		state.loadedAt = undefined;
	},
	[types.EDIT_ORDER_SUCCESS]: (state, action: {payload: IEditCategoriesOrderPayload}) => {
		action.payload.allIds.forEach(id => {
			state.byId[id] = action.payload.byId[id];
			if (!state.allIds.find(stateId => stateId === id)) {
				state.allIds.push(id);
			}
		});
		state.loaded = false;
		state.loadedAt = undefined;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditCategoryPayload}) => {
		state.byId[payload.id] = payload;
		state.loaded = false;
		state.loadedAt = undefined;
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteCategoryPayload}) => {
		delete state.byId[payload.id];
		state.allIds = state.allIds.filter(id => id !== payload.id);
		state.loaded = false;
		state.loadedAt = undefined;
	},
	[types.DELETE_MANY_SUCCESS]: (state, {payload}: {payload: IDeleteManyCategoriesPayload}) => {
		const {allIds, byId} = state;
		const deleteIds: string[] = [];

		allIds.forEach(id => {
			const {categoriesSetId} = byId[id];
			if (payload.categoriesSetId === categoriesSetId) {
				deleteIds.push(id);
				delete state.byId[id];
			}
		});

		state.allIds = allIds.filter(id => !deleteIds.includes(id));
		state.loaded = false;
		state.loadedAt = undefined;
	},
	[CLEAR_STORE]: getInitialState
});
