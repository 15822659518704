import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractCheckRecordByItemId,
	extractCheckRecordByListId
} from '@src/store/modules/pages/checkLists/selectors/records';
import {usePercentInput} from '@src/pages/CheckListPage/hooks/usePercentInput';
import React, {useCallback} from 'react';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {CircleLoader, StatusSelect} from '@tehzor/ui-components';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {extractCheckRecordStatusesByEntityType} from '@src/store/modules/dictionaries/checkRecordStatuses/selectors';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ApiAction} from '@src/store/middlewares/api';
import {extractCheckListById} from '@src/store/modules/dictionaries/checkLists/selectors';
import {extractTreeCheckItemById} from '@src/store/modules/dictionaries/checkItems/selectors';
import classNames from 'classnames';
import {IChangeCheckRecordStatusParams} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';

export const isCheckItem = (arg: ICheckList | ICheckItem): arg is ICheckItem =>
	(arg as ICheckItem)?.checkListId !== undefined;

interface IRecordStatusSelectProps {
	item: ICheckList | ICheckItem;
	action?: (
		item: ICheckList | ICheckItem,
		objectId: string,
		spaceId: string | undefined,
		workAcceptanceId: string | undefined,
		stage: ObjectStageIds,
		status: CheckRecordStatusId
	) => ApiAction<ICheckRecord[]>;

	mutation?: (params: IChangeCheckRecordStatusParams) => void;
	showLoader?: boolean;
	setChangingState?: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
}

export const RecordStatusSelect = ({
	item,
	action,
	mutation,
	showLoader,
	setChangingState,
	disabled
}: IRecordStatusSelectProps) => {
	const dispatch = useAppDispatch();
	const {objectId, listId, workAcceptanceId} = useStrictParams<{
		objectId: string;
		listId: string;
		workAcceptanceId?: string;
	}>();
	const record = useAppSelector(s =>
		(isCheckItem(item)
			? extractCheckRecordByItemId(s, item.id)
			: extractCheckRecordByListId(s, item.id)));
	const statuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityType(s, isCheckItem(item) ? 'check-item' : 'check-list'));
	const checkList = useAppSelector(s =>
		extractCheckListById(s, isCheckItem(item) ? item.checkListId : item.id));
	const treeItem = useAppSelector(s =>
		(isCheckItem(item) ? extractTreeCheckItemById(s, item.checkListId, item.id) : undefined));

	const [, percentInput] = usePercentInput(
		item,
		record,
		objectId || '',
		checkList!.stage,
		disabled
	);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите принять категорию?',
		'Все дочерние категории будут переведены в статус "Принято", а связанные с ними нарушения - в статус "Устранено"'
	);

	const handleSelectChange = useCallback(
		async (value: CheckRecordStatusId) => {
			let confirmationResult = true;

			if (
				value === CheckRecordStatusId.ACCEPTED
				&& (treeItem?.children?.length || !isCheckItem(item))
			) {
				confirmationResult = await getClosingConfirmation();
			}

			if (!confirmationResult) {
				return;
			}
			if (setChangingState) {
				setChangingState(true);
			}
			if (action) {
				await dispatch(
					action(
						item,
						objectId || '',
						undefined,
						workAcceptanceId,
						checkList!.stage,
						value
					)
				);
			}
			if (mutation) {
				mutation({
					item,
					objectId: objectId || '',
					spaceId: undefined,
					workAcceptanceId,
					stage: checkList!.stage,
					status: value,
					record
				});
			}
		},
		[objectId, listId, workAcceptanceId]
	);

	return (
		<div className="check-list-page__status">
			<StatusSelect
				className={{
					root: 'check-list-page__status-select-menu',
					btn: classNames('check-list-page__status-select', {
						'check-list-page__status-select_hidden': showLoader,
						'check-list-page__status-select_disabled': disabled
					})
				}}
				statuses={statuses}
				value={record?.status || CheckRecordStatusId.NOT_CHECKED}
				onChange={handleSelectChange}
			/>

			{record?.status === CheckRecordStatusId.PARTIALLY_ACCEPTED && (
				<div className="check-list-page__status-percent">{percentInput}</div>
			)}

			{showLoader ? <CircleLoader className="check-list-page__status-loader"/> : null}

			{closingDialog}
		</div>
	);
};
