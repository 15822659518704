import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {ISavingExportTemplate} from '@tehzor/tools/interfaces/exportTemplates/ISavingExportTemplate';
import IExportTemplate, {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';

export type IEditableExportTemplateState = IEditableEntityState<{
	name?: string;
	fileNameTemplate?: string;
	companyId?: string;
	documentTypeId?: string;
	objects?: string[];
	attachments?: IAttachment[];
	destination?: ExportTemplatesDestinationId;
}>;

export type IEditableExportTemplateAction = IEditableEntityAction<
	IEditableExportTemplateState,
	{exportTemplate?: ISavingExportTemplate}
>;

const makeEmptyState = (): IEditableExportTemplateState => ({
	name: undefined,
	fileNameTemplate: undefined,
	destination: undefined,
	companyId: undefined,
	documentTypeId: undefined,
	objects: [],
	attachments: [],
	errors: {
		name: false,
		fileNameTemplate: false,
		companyId: false,
		documentTypeId: false,
		objects: false,
		attachments: false
	}
});

export const init = ({
	exportTemplate
}: {
	exportTemplate?: IExportTemplate;
}): IEditableExportTemplateState => {
	const empty = makeEmptyState();
	return exportTemplate
		? {
				name: exportTemplate.name,
				fileNameTemplate: exportTemplate.fileNameTemplate,
				destination: exportTemplate.destination,
				companyId: exportTemplate.companyId,
				documentTypeId: exportTemplate.documentTypeId,
				objects: exportTemplate.objects,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (
	state: IEditableExportTemplateState,
	original?: IExportTemplate
): boolean => {
	const originalWithAttachments = {...original, attachments: []};
	return isEntityEdited(
		state,
		originalWithAttachments,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'fileNameTemplate'),
		isPropEdited.bind(null, 'companyId'),
		isPropEdited.bind(null, 'documentTypeId'),
		isPropEdited.bind(null, 'objects'),
		isPropEdited.bind(null, 'destination')
	);
};

export const errorsFns = {
	name: (state: IEditableExportTemplateState) => !state.name,
	fileNameTemplate: (state: IEditableExportTemplateState) => !state.fileNameTemplate,
	companyId: (state: IEditableExportTemplateState) => !state.companyId,
	documentTypeId: (state: IEditableExportTemplateState) => !state.documentTypeId,
	objects: (state: IEditableExportTemplateState) => !state.objects?.length,
	destination: (state: IEditableExportTemplateState) => !state.destination
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertExportTemplateToSave = (
	state: IEditableExportTemplateState,
	original?: IExportTemplate,
	onlyEdited?: boolean
): ISavingExportTemplate => {
	if (!onlyEdited) {
		return state as ISavingExportTemplate;
	}

	const exportTemplate: ISavingExportTemplate = {};

	if (isPropEdited('name', state, original)) {
		exportTemplate.name = state.name;
	}
	if (isPropEdited('fileNameTemplate', state, original)) {
		exportTemplate.fileNameTemplate = state.fileNameTemplate;
	}
	if (isPropEdited('companyId', state, original)) {
		exportTemplate.companyId = state.companyId;
	}
	if (isPropEdited('documentTypeId', state, original)) {
		exportTemplate.documentTypeId = state.documentTypeId;
	}
	if (isPropEdited('objects', state, original)) {
		exportTemplate.objects = state.objects;
	}
	if (isPropEdited('destination', state, original)) {
		exportTemplate.destination = state.destination;
	}

	return exportTemplate;
};
