import {wsConnector} from '../wsConnector';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';

export type IEditCheckListResponse = ICheckList;

/**
 * Возвращает чек-лист
 *
 * @param id идентификатор чек-листа
 * @param fields данные для обновления
 */
export const makeCheckListEditRequest = (id: string, fields: ISavingCheckList) =>
	wsConnector.sendAuthorizedRequest<IEditCheckListResponse>('editCheckList', {id, ...fields});
