import React, {memo} from 'react';
import {useAddingLegalDialog} from '@src/components/AddingLegalDialog';
import {Button} from '@tehzor/ui-components';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface IDesktopEditButtonProps {
	legal: ILegalEntity;
}

const editIcon = <i className="tz-edit-20" />;

export const DesktopEditButton = memo(({legal}: IDesktopEditButtonProps) => {
	const [legalDialog, handleOpenLegalDialog] = useAddingLegalDialog({legal});

	return (
		<>
			<Button
				className="legal__edit-button"
				type="accent-blue"
				leftIcon={editIcon}
				label="Редактировать"
				onClick={handleOpenLegalDialog}
			/>
			{legalDialog}
		</>
	);
});
