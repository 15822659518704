import React from 'react';
import './ProblemInfoDesktopCell.less';
import {PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import classNames from 'classnames';
import IProblem from '@tehzor/tools/interfaces/problems/IProblem';
import ProblemReply from '../ProblemReply';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useProblemAndReplyViewer} from '@src/core/hooks/imagesViewers/desktop/useProblemAndReplyViewer';
import {ProblemInfo} from '@src/components/tableCells/ProblemInfo/ProblemInfo';
import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';

const ProblemInfoDesktopCell = (props: IProblemCellProps<{data: IProblem}>) => {
	const {row, replies, allProblemTags} = props;
	const {data} = row.original;

	const reply = replies?.[data.id];

	const colors = allProblemTags ? data.problemTags?.map(id => allProblemTags[id]?.color) : undefined;
	const [problemImagesAttachments] = useSplitAttachments(data);
	const problemImages = useAttachmentsImages(problemImagesAttachments);
	const problemImagesData = useAttachmentsImagesObjects(problemImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(reply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);

	const [imagesViewer, openProblemImage, openReplyImage] = useProblemAndReplyViewer(
		problemImages,
		replyImages,
		problemImagesData,
		replyImagesData,
		reply?.number,
		data.id,
		reply?.id
	);

	return (
		<div className="d-problem-info-cell">
			<div
				className={classNames('d-problem-info-cell__problem', {
					'd-problem-info-cell__problem_with-padding': row.depth > 0
				})}
			>
				<ClickPreventWrap>
					<TableRowSelect
						{...row.getToggleRowSelectedProps()}
						style={{marginTop: row.depth > 0 ? '6px' : '12px'}}
					/>
				</ClickPreventWrap>

				<div className="d-problem-info-cell__info">
					<div className="d-problem-info-cell__info-text">
						<ProblemInfo
							style={{marginBottom: row.depth > 0 ? '12px' : '16px'}}
							data={data}
							depth={row.depth}
						/>
						{data.description !== undefined && (
							<div className="d-problem-info-cell__desc">{data.description}</div>
						)}
						{colors?.length ? (
							<ProblemTagsMarker colors={colors}/>
						) : null}
					</div>

					{problemImages.length > 0 ? (
						<ClickPreventWrap>
							<PhotoPreview
								style={{width: '76px'}}
								url={problemImages[0]}
								data={0}
								onClick={openProblemImage}
							/>
						</ClickPreventWrap>
					) : null}
				</div>
			</div>

			{reply !== undefined && (
				<ProblemReply
					className="d-problem-info-cell__reply"
					reply={reply}
					images={replyImages}
					openImage={openReplyImage}
				/>
			)}

			{problemImages.length || replyImages.length ? (
				<ClickPreventWrap>{imagesViewer}</ClickPreventWrap>
			) : null}
		</div>
	);
};

export default ProblemInfoDesktopCell;
