import React, {createContext, PropsWithChildren} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {deleteTask} from '@src/store/modules/pages/task/actions';

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<Pick<ITask, 'id'>>) => void>(
	() => ({}));

export const MenuActionCtxProvider = ({
	children,
	reloadList
}: PropsWithChildren<{reloadList: () => Promise<any>}>) => {
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить задачу?',
		'Все связанные данные будут безвозвратно удалены',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<Pick<ITask, 'id'>>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await dispatch(deleteTask(action.payload.id));
				await reloadList();
			}
		}
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
