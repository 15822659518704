import React, {useCallback} from 'react';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';
import {CheckListsSpace, ProblemsSpace, SpacesBoard} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {formSpaceLink, formCheckListsLink} from '@tehzor/tools/utils/links';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeSchemaView} from '@src/store/modules/settings/pages/spaces/actions';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';

const getSpaceComponent = (schemaView: SpacesSchemaVariants) => {
	switch (schemaView) {
		case SpacesSchemaVariants.CHECK_LISTS:
			return CheckListsSpace;
		case SpacesSchemaVariants.PROBLEMS:
		default:
			return ProblemsSpace;
	}
};

interface ISingleSchemaProps {
	objectId: string;
	hideTitle?: boolean;
	isTitleClickable?: boolean;
	spaces?: IEnrichedSpace[];
	filtered?: IListSpace[];
}

const SingleSchema = ({
	objectId,
	hideTitle,
	isTitleClickable,
	spaces,
	filtered
}: ISingleSchemaProps) => {
	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const {pushPath} = useChangePath();
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, pageObjectId));
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();

	const goToObject = useCallback(() => {
		if (pageSettings.schemaView === SpacesSchemaVariants.PROBLEMS) {
			dispatch(changeSchemaView(objectId, SpacesSchemaVariants.PROBLEMS));
		} else if (pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS) {
			dispatch(changeSchemaView(objectId, SpacesSchemaVariants.CHECK_LISTS));
		}
		pushPath(`/objects/${objectId}/spaces`);
	}, [objectId, pageSettings, dispatch]);

	const goToSpace = useCallback(
		(space: IEnrichedSpace) => {
			if (pageSettings.schemaView === SpacesSchemaVariants.PROBLEMS) {
				pushPath(formSpaceLink(space.objectId, space.id));
			} else if (pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS) {
				pushPath(
					formCheckListsLink({
						objectId,
						spaceId: space.id,
						stage: pageSettings.stage ?? 'all'
					})
				);
			}
		},
		[objectId, pageSettings]
	);

	return spaces && filtered && spaces.length ? (
		<SpacesBoard
			className="spaces-page__schema-list-item"
			data={spaces}
			dataFiltered={filtered}
			title={hideTitle ? undefined : object?.name}
			reverseFloorSort={pageSettings.reverseFloorSort}
			SpaceComponent={getSpaceComponent(pageSettings.schemaView)}
			onSpaceClick={goToSpace}
			onTitleClick={isTitleClickable ? goToObject : undefined}
		/>
	) : null;
};

export default SingleSchema;
