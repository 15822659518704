import {Column} from 'react-table';
import {IPreparedWorkAcceptance} from '../../interfaces/IPreparedWorkAcceptance';
import {MenuCell} from './MenuCell';

export const mobileColumns: Array<Column<IPreparedWorkAcceptance>> = [
	{
		id: 'number',
		Header: '',
		accessor: row => `№${row.number}`,
		width: 65,
		className: 'work-acceptances-page__m-table-number'
	},
	{
		Header: 'Объект',
		id: 'object',
		accessor: row => row.object?.name,
		width: 100
	},
	{
		id: 'status',
		Header: 'Статус',
		accessor: row => row.status,
		width: 100
	},
	{
		Header: 'Добавлено',
		accessor: 'createdAt',
		width: 100,
		sortDescFirst: true
	},
	{
		Header: 'Изменено',
		accessor: 'modifiedAt',
		width: 100,
		sortDescFirst: true
	},
	{
		id: '_menu',
		Header: '',
		Cell: MenuCell,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		className: 'work-acceptances-page__m-table-menu'
	}
];

export const mobileColumnsWithoutObject = mobileColumns.filter(item => item.id !== 'object');
