import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICheckListType} from '@tehzor/tools/interfaces/checkLists/ICheckListType';

export type IGetCheckListTypesResponse = INormalizedData<ICheckListType>;

/**
 * Возвращает список типов чеклистов
 */
export const requestCheckListTypes = () =>
	wsConnector.sendAuthorizedRequest<IGetCheckListTypesResponse>('getCheckListTypes');
