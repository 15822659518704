import React, {memo} from 'react';
import './MenuArrowButton.less';
import classNames from 'classnames';
import IconButton from '../IconButton';

interface IMenuArrowButtonProps {
	className?: string;
	style?: React.CSSProperties;
	active?: boolean;

	onClick?(): void;
}

const MenuArrowButton = ({className, style, active, onClick}: IMenuArrowButtonProps) => (
	<IconButton
		className={classNames('menu-arrow-button', {'menu-arrow-button_active': active}, className)}
		style={style}
		onClick={onClick}
	>
		<div className="menu-arrow-button__cont">
			<div className="menu-arrow-button__line-1"/>
			<div className="menu-arrow-button__line-2"/>
			<div className="menu-arrow-button__line-3"/>
		</div>
	</IconButton>
);

MenuArrowButton.displayName = 'MenuArrowButton';

export default memo(MenuArrowButton);
