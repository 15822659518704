import React from 'react';
import {Plate} from '@tehzor/ui-components';

const EmptyAttachments = () => (
	<Plate>
		<div className="inspection-page__empty-attachments">
			<i className="tz-inspection inspection-page__empty-attachments-icon"/>
		</div>
	</Plate>
);

export default EmptyAttachments;
