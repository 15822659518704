import React, {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {SelectionActions} from '../selection/SelectionActions';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

interface ICustomSelectionRowProps extends ISelectionRowProps<IWorkAcceptanceEntity> {
	objectId: string;
	workAcceptanceId: string;
	checkLists: ICheckList[];
}

export const SelectionRow = ({
	objectId, workAcceptanceId, checkLists, selectedFlatRows, toggleAllRowsSelected
}: ICustomSelectionRowProps) => {
	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActions
				objectId={objectId}
				workAcceptanceId={workAcceptanceId}
				checkLists={checkLists}
				selectedEntities={selectedFlatRows.map(item => item.original)}
			/>
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};
