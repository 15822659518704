import React, {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
// import SelectionActions from '../selection/SelectionActions';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';

type ICustomSelectionRowProps = ISelectionRowProps<IEnrichedSpace>;

export const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {toggleAllRowsSelected} = props;

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			{/* <SelectionActions
				objectId={objectId}
				spaceId={spaceId}
				selectedEntities={selectedFlatRows.map(item => item.original)}
			/> */}
			<Button
				label="Сбросить"
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};
