import {requestSpaces} from '@src/api/backend/spaces';
import {useQueryClient} from '@tanstack/react-query';
import {spacesQueryKeys} from '../keys';
import {requestSpace} from '@src/api/backend/space';

export const useSpacesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(spacesQueryKeys.list(), {
		queryFn: async ({queryKey}) =>
			requestSpaces(
				queryKey[2] as Record<string, unknown>,
				queryKey[3] as Record<string, boolean>,
				queryKey[4] as number,
				queryKey[5] as number
			),
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		cacheTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
	queryClient.setQueryDefaults(spacesQueryKeys.details(), {
		queryFn: async ({queryKey}) => requestSpace(queryKey[3] as string, queryKey[2] as string),
		staleTime: 1000 * 60 * 5, // 5 минут
		cacheTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
