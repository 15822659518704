import {addErrorToast, addInfoToast} from '@src/utils/toasts';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import * as types from '../constants';
import {makeWorkAcceptanceDeleteRequest} from '@src/api/backend/workAcceptance';

export interface IDeleteWorkAcceptancePayload {
	objectId: string;
	workAcceptanceId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, workAcceptanceId: string) => {
	addInfoToast('Удалено', 'Приёмка работ успешно удалена');
	return {
		type: types.DELETE_SUCCESS,
		payload: {objectId, workAcceptanceId} as IDeleteWorkAcceptancePayload
	};
};

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при удалении приёмки работ');
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет приёмку работ
 * @param objectId id объекта
 * @param workAcceptanceId id приёмки работ
 */
export const deleteWorkAcceptance = (objectId: string, workAcceptanceId: string) =>
	createApiAction(
		request,
		() => success(objectId, workAcceptanceId),
		failure,
		() => makeWorkAcceptanceDeleteRequest(objectId, workAcceptanceId)
	);
