import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IEditStructureResponse, makeStructureEditRequest} from '@src/api/backend/structure/edit';

export type IEditStructurePayload = IEditStructureResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditStructureResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при изменении стурктуры');
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет структуру
 *
 * @param objectId id объекта
 * @param structureId id структуры
 * @param fields измененные поля
 */
export const editStructure = (objectId: string, structureId: string, fields: ISavingStructure = {}) =>
	createApiAction<IEditStructureResponse>(request, success, failure, () =>
		makeStructureEditRequest(objectId, structureId, fields));
