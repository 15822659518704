import React, {useCallback, useMemo} from 'react';
import {
	EntitiesTable,
	LoadingPanel,
	Pagination,
	PaginationAndSize,
	PaginationPageSize,
	Plate
} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {columns} from './columns';
import {MenuActionCtxProvider} from '../../../DispatchActionCtx';
import {useEnrichedTasks} from '../../../../hooks/useEnrichedTasks';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {tasksList, useTasks, useTasksListPageSettings} from '@src/core/hooks/queries/tasks';
import {useIsFetching, useQueryClient} from '@tanstack/react-query';
import {useAsyncFn} from 'react-use';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {
	changePageSize,
	changeOffset,
	changeSort,
	changeSelectedRows
} from '@src/store/modules/settings/pages/tasks/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {columnsMobile} from '@src/pages/TasksPage/components/tabs/TasksTablePage/components/mobile/columnsMobile';
import SelectionRow from '@src/pages/TasksPage/components/selection/SelectionRow';
import MobileSelectionClearing from '@src/pages/ProblemsPage/components/selection/SelectionClearing.mobile';
import MobileRightButtons from '@src/pages/TasksPage/components/MobileRightsButtons';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';

const pageSizes = [10, 20, 50, 100];

export const TasksTable = () => {
	const {pushPath} = useChangePath();
	const isDesktop = useIsDesktop();
	const {state} = useEntitiesFiltersCtx<ITasksFiltersState>();
	const tasksData = useTasks(state);
	const dispatch = useAppDispatch();
	const tasks = tasksList(tasksData);
	const pageSettings = useTasksListPageSettings();
	const enrichedTasks = useEnrichedTasks(tasks);
	const isFetching = useIsFetching({queryKey: tasksQueryKeys.list()});

	const pagesCount = Math.ceil((tasksData?.total ?? 0) / pageSettings.pageSize);
	const currentPage = Math.floor((tasksData?.offset ?? 0) / pageSettings.pageSize);

	const queryClient = useQueryClient();
	const [, reloadList] = useAsyncFn(async () => {
		await queryClient.invalidateQueries({
			queryKey: tasksQueryKeys.list()
		});
	}, []);

	const handleRowClick = useCallback((item: IEnrichedTask) => {
		pushPath(`/tasks/${item.id}`);
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows(value)),
		[]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize(value));
			dispatch(changeOffset(Math.floor((tasksData?.offset ?? 0) / value)));
			void reloadList();
		},
		[tasksData?.offset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (tasksData?.offset !== offset) {
				dispatch(changeOffset(offset));
				void reloadList();
			}
		},
		[tasksData?.offset, pageSettings.pageSize]
	);

	const handleSortChange = useCallback((value: {[key: string]: boolean}) => {
		dispatch(changeSort(value));
		void reloadList();
	}, []);
	const selectedEntities = useMemo(
		() => enrichedTasks.filter(item => pageSettings.selectedRows?.includes(item.id)),
		[pageSettings.selectedRows, enrichedTasks]
	);

	useAppHeader(
		{
			title: 'Задачи',
			mobileLeftButton:
				selectedEntities.length > 0 ? (
					<MobileSelectionClearing
						onSelectedRowsChange={() => handleSelectedRowsChange([])}
					/>
				) : undefined,
			mobileRightButtons: !isDesktop ? (
				<MenuActionCtxProvider reloadList={reloadList}>
					<MobileRightButtons
						selectedEntities={selectedEntities}
						selectedClearing={() => {
							handleSelectedRowsChange([]);
						}}
					/>
				</MenuActionCtxProvider>
			) : null
		},
		[selectedEntities, pageSettings.selectedRows, isDesktop]
	);

	return (
		<>
			<MenuActionCtxProvider reloadList={reloadList}>
				<Plate withoutPadding>
					<LoadingPanel active={!!isFetching}>
						<EntitiesTable
							columns={isDesktop ? columns : columnsMobile}
							data={enrichedTasks}
							selectedRows={pageSettings.selectedRows}
							sort={pageSettings.sort}
							selectable
							onRowClick={handleRowClick}
							onSelectedRowsChange={handleSelectedRowsChange}
							onSortChange={handleSortChange}
							hideLastHeaderCell={isDesktop}
							headVisible={isDesktop}
							renderSelectionRow={props => <SelectionRow {...props}/>}
						/>
					</LoadingPanel>
				</Plate>
			</MenuActionCtxProvider>
			<PaginationAndSize
				pagination={(
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				)}
				pageSize={(
					<PaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				)}
			/>
		</>
	);
};
