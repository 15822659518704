import {Action, Middleware} from 'redux';
import {IState} from '@src/store/modules';
import {AppThunkDispatch} from '@src/store/interfaces/thunk';
import {actionsStatusChange, loadStatusChange} from '@src/store/modules/app/actionsStatus/actions';
import {ApiAction, ApiDispatch} from './api';

const API_ACTION_TYPE = 'call-api';

type ApiMiddleware = Middleware<ApiDispatch, IState, AppThunkDispatch>;

/**
 * Middleware для обработки вызовов к backend api
 */
export default (): ApiMiddleware =>
	({dispatch, getState}) => next =>
		(action: Action | ApiAction) => {
			if (action.type !== API_ACTION_TYPE) {
				const {type} = action as ApiAction;
				const isRequest = type.endsWith('/request');
				const isSuccess = type.endsWith('/success');
				const isFailure = type.endsWith('/failure');
				if (isRequest) {
					dispatch(actionsStatusChange({type: type.split('/').slice(0, -1).join('/'), status: 'pending'}));
					const status = getState().app.loading.status;
					if (status === 'loaded') {
						dispatch(loadStatusChange('pending'));
					}
				}
				if (isSuccess) {
					dispatch(actionsStatusChange({type: type.split('/').slice(0, -1).join('/'), status: 'loaded'}));
					const actions = getState().app.loading.actions;
					if (!actions.length) {
						dispatch(loadStatusChange('loaded'));
					}
				}
				if (isFailure) {
					dispatch(actionsStatusChange({type: type.split('/').slice(0, -1).join('/'), status: 'error'}));
					const actions = getState().app.loading.actions;
					if (!actions.length) {
						dispatch(loadStatusChange('loaded'));
					}
				}
			}
			return next(action);
		};
