import React, {cloneElement, useCallback} from 'react';
import './SelectPopup.less';
import Popup, {IPopupBasicProps} from '../../../containers/Popup';
import ShadowBox from '../../../Shadowbox';
import classNames from 'classnames';
import {RefCallback} from 'react-laag/dist/types.d';
import useToggle from 'react-use/lib/useToggle';
import {ISelectProps} from '../Select';
import {ITreeSelectProps} from '../TreeSelect';
import {ActionButtons, LinkButton} from '../../../buttons';
import Button from '../../../buttons/Button';
import {ITreeDataItem} from '../../../Tree';

export interface IPopupSelectTriggerElProps {
	isOpen?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	ref?: RefCallback;
}

export interface IPopupSelectTriggerFnProps {
	isOpen?: boolean;
	disabled?: boolean;
	open?: () => void;
	close?: () => void;
	toggle?: () => void;
	ref?: RefCallback;
}

interface ISelectPopupProps <T extends ITreeDataItem = ITreeDataItem> {
	className?: string;
	trigger: React.ReactElement<IPopupSelectTriggerElProps> | ((props: IPopupSelectTriggerFnProps) => React.ReactNode);
	children?: React.ReactElement<ISelectProps> | React.ReactElement<ITreeSelectProps<T>>;
	popupProps?: IPopupBasicProps;
	clearButton?: boolean;
	footer?: boolean;
	noHeader?: boolean;
	count?: number;
	onClear?: () => void;
	onSelectAll?: () => void;
	onCancel?: () => void;
	onApply?: () => void;
	search?: React.ReactNode;
	tabs?: React.ReactNode;
}

const SelectPopup = (props: ISelectPopupProps) => {
	const {
		className,
		trigger,
		children,
		popupProps,
		onClear,
		onCancel,
		onApply,
		onSelectAll,
		clearButton,
		footer,
		noHeader,
		count,
		search,
		tabs
	} = props;
	const [isOpen, toggleOpen] = useToggle(false);

	const open = useCallback(() => {
		toggleOpen(true);
	}, []);

	const close = useCallback(() => {
		toggleOpen(false);
	}, []);

	const onClose = () => {
		close();
		if (onCancel) {
			onCancel();
		}
	};

	const apply = () => {
		close();
		if (onApply) {
			onApply();
		}
	};

	return children ? (
		<Popup
			{...popupProps}
			className={classNames('select-popup', className)}
			trigger={triggerProps =>
				(typeof trigger === 'function'
					? trigger({
							...triggerProps,
							isOpen,
							open,
							close,
							toggle: toggleOpen
					  })
					: cloneElement(trigger, {
							isOpen,
							onClick: toggleOpen,
							ref: triggerProps.ref
					  }))}
			isOpen={isOpen}
			onOutsideClick={onClose}
			onDisappear={onClose}
		>
			{!noHeader ? (
				<div className="select-popup__header">
					{tabs && <div className="select-popup__header-tabs">{tabs}</div>}
					{search && <div className="select-popup__header-search">{search}</div>}
					{(onSelectAll || (clearButton && !footer)) && (
						<div className="select-popup__header-actions">
							{onSelectAll ? (
								<LinkButton
									label="Выбрать все"
									onClick={onSelectAll}
								/>
							) : null}
							{clearButton && !footer ? (
								<LinkButton
									label="Сбросить"
									onClick={onClear}
								/>
							) : null}
						</div>
					)}
				</div>
			) : null}
			<ShadowBox className="select-popup__scrollable">{children}</ShadowBox>
			{footer ? (
				<div className="select-popup__footer">
					{clearButton ? (
						<div className="select-popup__info">
							<div className="d-regular-12">{`Выбрано ${count || 0}`}</div>
							<div>
								<LinkButton
									label="Сбросить все"
									className="select-popup__clear"
									onClick={onClear}
									rightIcon={<i className={classNames('tz-close-16', 'select-popup__close')}/>}
								/>
							</div>
						</div>
					) : null}

					<ActionButtons className="select-popup__actions">
						{onCancel ? (
							<Button
								type="cancel"
								label="Отменить"
								onClick={onClose}
							/>
						) : null}
						{onApply ? (
							<Button
								type="accent-blue"
								label="Применить"
								onClick={apply}
							/>
						) : null}
					</ActionButtons>
				</div>
			) : null}
		</Popup>
	) : (
		<>{typeof trigger === 'function' ? trigger({disabled: true}) : cloneElement(trigger, {disabled: true})}</>
	);
};

SelectPopup.displayName = 'SelectPopup';

export default SelectPopup;
