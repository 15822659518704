import React, {useCallback} from 'react';
import {Button} from '@tehzor/ui-components';
import {useCheckExport} from '@src/core/hooks/export/useCheckExport';

interface ICheckExportProps {
	objectId: string;
	checkId: string;
}

const DesktopCheckExportBtn = ({objectId, checkId}: ICheckExportProps) => {
	const [exportDialog, openExportDialog] = useCheckExport(objectId);

	const handleExport = useCallback(() => {
		openExportDialog(checkId);
	}, [checkId, openExportDialog]);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-export-20"/>}
				label="Экспорт"
				onClick={handleExport}
			/>
			{exportDialog}
		</>
	);
};

export default DesktopCheckExportBtn;
