import React, {memo} from 'react';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ObjectStages} from './ObjectStages';
import {ObjectManager} from './ObjectManager';
import {ObjectProblems} from './ObjectProblems';

interface IDesktopInfoProps {
	object: IObject;
	stages: IObjectStage[];
}

const nameIcon = <i className="tz-name-20"/>;
const cityIcon = <i className="tz-location-20"/>;
const contractorIcon = <i className="tz-company-20"/>;

export const DesktopInfo = memo(({object, stages}: IDesktopInfoProps) => (
	<>
		<EntityGrid
			withBorders
			className={{wrap: 'object-page__d-entities-grid'}}
		>
			<EntityGridItem
				className={{content: 'object-page__d-entities-grid-title'}}
			>
				Основное
			</EntityGridItem>
			<EntityGridItem
				label="Название"
				icon={nameIcon}
				inline
			>
				{object.name}
			</EntityGridItem>

			{object.city && (
				<EntityGridItem
					label="Город"
					icon={cityIcon}
					inline
				>
					{object.city}
				</EntityGridItem>
			)}

			{object.generalContractor && (
				<EntityGridItem
					label="Ген. Подрядчик"
					icon={contractorIcon}
					inline
				>
					{object.generalContractor}
				</EntityGridItem>
				)}

			<ObjectManager
				label="Рук. проекта"
				value={object.projectManager}
				isDesktop
			/>

			<ObjectManager
				label="Рук. строительства"
				value={object.constructionManager}
				isDesktop
			/>
		</EntityGrid>

		<ObjectStages
			object={object}
			stages={stages}
			isDesktop
		/>

		<ObjectProblems
			object={object}
			isDesktop
		/>
	</>
));