import {IGetProblemViewsResponse, requestProblemViews} from '@src/api/backend/problemViews';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {queryClient} from '@src/api/QueryClient';
import {problemViewsQueryKeys} from '@src/api/cache/problemViews/keys';

export interface IGetProblemViewsRequestPayload {
	problemId: string;
}

export interface IGetProblemViewsPayload extends IGetProblemViewsResponse {
	problemId: string;
}

const request = (problemId: string) => ({
	type: types.GET_REQUEST,
	payload: {problemId} as IGetProblemViewsRequestPayload
});

const success = (problemId: string, response: IGetProblemViewsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		problemId
	} as IGetProblemViewsPayload
});

const failure = (error: IError) => ({
	type: types.GET_FAILURE,
	payload: {...error}
});

export const getProblemViews = (problemId: string) =>
	createApiAction<IGetProblemViewsResponse>(
		() => request(problemId),
		res => success(problemId, res),
		failure,
		() => queryClient.fetchQuery({
			queryKey: problemViewsQueryKeys.detail(problemId),
			queryFn: () => requestProblemViews(problemId)
		})
	);