import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetWorkingGroupsResponse} from '@src/api/backend/workingGroups';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {queryClient} from '@src/api/QueryClient';

export type IGetWorkingGroupsPayload = IGetWorkingGroupsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetWorkingGroupsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке рабочих групп');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список рабочих групп
 */
export const getWorkingGroups = () =>
	createApiAction<IGetWorkingGroupsResponse>(request, success, failure, () =>
		queryClient.fetchQuery(workingGroupsQueryKeys.list(), {staleTime: Infinity}));
