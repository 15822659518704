import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import declination from '@tehzor/tools/utils/declination';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/reducers';
import {useInspections} from '@src/core/hooks/queries/inspections';

interface IProblemsPageHeaderProps {
	objectId?: string;
}

const InspectionsPageCounter = ({objectId = 'all'}: IProblemsPageHeaderProps) => {
	const {state} = useEntitiesFiltersCtx<IInspectionsFiltersState>();
	const inspectionsData = useInspections(objectId, state);
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${isFiltersActive ? 'Найдено:' : 'Всего:'} ${
		inspectionsData?.total || 0
	} ${declination(inspectionsData?.total || 0, ['осмотр', 'осмотры', 'осмотров'])}`;
	return (
		<PageHeader
			className="inspections-page__header"
			title=""
			subTitle={text}
			wrapClassName="inspections-page__header-wrap"
			leftSideClassName="inspections-page__header-left"
			rightSideClassName="inspections-page__header-right"
		/>
	);
};

export default InspectionsPageCounter;
