import React, {useCallback} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {Button, IconButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {exportCheckLists} from '@src/store/modules/dictionaries/checkLists/actions/export';

const availableDestinations = [ExportTemplatesDestinationId.CHECKLISTS];

interface ICheckListsExportDialog {
	objectId: string;
	spaceId: string;
	checkListsIds: string[];
}

function CheckListsExportDialog(props: ICheckListsExportDialog) {
	const {objectId, spaceId, checkListsIds} = props;
	const [isOpen, toggleOpen] = useToggle(false);

	const dispatch = useAppDispatch();

	const exportLists = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await dispatch(
				exportCheckLists(template.id, createDocument, email, [
					{spaceId, objectId, checkListsIds}
				])
			);
			toggleOpen(false);
		},
		[objectId, spaceId, checkListsIds]
	);

	const isDesktop = useIsDesktop();

	return (
		<>
			{isDesktop ? (
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-export-20" />}
					label="Экспорт"
					onClick={toggleOpen}
				/>
			) : (
				<IconButton onClick={toggleOpen}>
					<i className="tz-export-24" />
				</IconButton>
			)}
			<ExportDialog
				pageKey="check-lists"
				destinations={availableDestinations}
				isOpen={isOpen}
				onExport={exportLists}
				onClose={toggleOpen}
			/>
		</>
	);
}

export default CheckListsExportDialog;
