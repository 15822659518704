import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import {useFiltersActive} from '@src/components/EntitiesFilters/utils/useFiltersActive';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/reducers';
import declination from '@tehzor/tools/utils/declination';
import {useProblems} from '@src/core/hooks/queries/problems';

interface IProblemsPageHeaderProps {
	objectId?: string;
}

const ProblemsPageCounter = ({objectId = 'all'}: IProblemsPageHeaderProps) => {
	const {state} = useEntitiesFiltersCtx<IProblemsFiltersState>();
	const problemsData = useProblems(objectId, state);
	const isFiltersActive = useFiltersActive(objectId, state);
	const text = `${isFiltersActive ? 'Найдено:' : 'Всего:'} ${
		problemsData?.total || 0
	} ${declination(problemsData?.total || 0, ['нарушение', 'нарушения', 'нарушений'])}`;
	return (
		<PageHeader
			className="problems-page__header"
			title=""
			subTitle={text}
			wrapClassName="problems-page__header-wrap"
			leftSideClassName="problems-page__header-left"
			rightSideClassName="problems-page__header-right"
		/>
	);
};

export default ProblemsPageCounter;
