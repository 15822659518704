import {
	IEditableCheckItem,
	IEditableCheckListAction,
	IEditableCheckListState
} from '@src/core/hooks/states/useEditableCheckListState';
import React, {Dispatch, useCallback, useState} from 'react';
import {Tree} from 'array-to-tree';
import {IconButton, LinkButton, TextField} from '@tehzor/ui-components';
import {EditableCheckItemDialog} from '@src/components/EditableCheckList/components/EditableCheckItemDialog';
import {getAllTreeKeys} from '@tehzor/tools/utils/tree/getAllTreeKeys';

export const generateId = () => Math.random().toString(36).substr(2, 5);

export const ItemsLeaf = ({
	editingState,
	editingDispatch,
	itemsLeaf
}: {
	editingState: IEditableCheckListState;
	editingDispatch: Dispatch<IEditableCheckListAction>;
	itemsLeaf: Tree<IEditableCheckItem>;
}) => {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const handleAdd = useCallback(() => {
		editingDispatch({
			type: 'add-in-array',
			field: 'items',
			value: [
				{
					id: generateId(),
					parentId: itemsLeaf.id,
					name: '',
					description: '',
					dependencies: []
				}
			]
		});
	}, [itemsLeaf.id]);

	const handleRemove = useCallback(() => {
		const ids = getAllTreeKeys([itemsLeaf]);

		if (ids.length) {
			editingDispatch({
				type: 'delete-array-item',
				field: 'items',
				index: ids.reduce((acc: number[], id) => {
					const index = editingState.items?.findIndex(item => item.id === id);

					if (index !== undefined && index >= 0) {
						acc.push(index);
					}

					return acc;
				}, [])
			});
		}
	}, [editingState.items]);

	return (
		<div className="editable-check-items__item">
			<TextField
				className="editable-check-items__item-input"
				value={itemsLeaf.name}
				elementType="div"
				onClick={open}
			/>

			<IconButton
				className="editable-check-items__item-remove-button"
				type="inline-red"
				onClick={handleRemove}
			>
				<i className="tz-delete"/>
			</IconButton>

			<LinkButton
				className="editable-check-items__item-add-button"
				leftIcon={<i className="tz-plus-20"/>}
				type="common"
				label="Подкатегория"
				onClick={handleAdd}
			/>

			{!!itemsLeaf.children?.length && (
				<div className="editable-check-items__sub-items">
					{itemsLeaf.children.map(leaf => (
						<ItemsLeaf
							key={leaf.id}
							editingState={editingState}
							editingDispatch={editingDispatch}
							itemsLeaf={leaf}
						/>
					))}
				</div>
			)}

			<EditableCheckItemDialog
				isOpen={isOpen}
				close={close}
				itemsLeaf={itemsLeaf}
				editingState={editingState}
				editingDispatch={editingDispatch}
			/>
		</div>
	);
};
