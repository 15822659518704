import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FilterPage, LinkButton} from '@tehzor/ui-components';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	ModifiedDateFilterMobile,
	ObjectsFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IOwnerAcceptancesFiltersState} from '@src/store/modules/settings/pages/ownerAcceptances/reducers';
import {OwnerAcceptancesStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/OwnerAcceptancesStatusesFilterMobile';
import {OwnerAcceptancesResolutionsFilterMobile} from '@src/components/MobileEntitiesFilters/components/OwnerAcceptancesResolutionsFilterMobile';
import {SpaceOwnersFilterMobile} from '@src/components/MobileEntitiesFilters/components/SpaceOwnersFilterMobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const OwnerAcceptancesFiltersPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string, entity: string}>();
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};
	const {state, isStateChanged, change, mobileClear, apply}
		= useEntitiesFiltersCtx<IOwnerAcceptancesFiltersState>();

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			goBack();
		}
	}, [canGoBack, apply]);

	useAppHeader(
		{
			title: 'Фильтры',
			showBackBtn: true,
			mobileRightButtons: isCleanable<IOwnerAcceptancesFiltersState>(state, objectId) ? (
				<LinkButton
					label="Сбросить всё"
					onClick={mobileClear}
				/>
			) : null
		},
		[state, objectId]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<FilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			{objectId === undefined && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
				/>
			)}

			<OwnerAcceptancesStatusesFilterMobile
				objectId={objectId}
				value={state?.statuses}
				entity={entity}
				onChange={change}
			/>

			<OwnerAcceptancesResolutionsFilterMobile
				objectId={objectId}
				value={state?.resolutions}
				entity={entity}
				onChange={change}
			/>

			<SpaceOwnersFilterMobile
				objectId={objectId}
				value={state?.spaceOwners}
				entity={entity}
				onChange={change}
			/>

			<CreatedByFilterMobile
				objectId={objectId}
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity}
			/>

			<ModifiedDateFilterMobile
				objectId={objectId}
				from={state.modifiedAtFrom}
				to={state.modifiedAtTo}
				entity={entity}
			/>
		</FilterPage>
	);
};
