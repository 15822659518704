import React from 'react';
import {CellProps} from 'react-table';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';

export const StatusCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {status} = row.original;

	return (
		<div
			style={{color: status?.textColor}}
			className="owner-acceptances-page__d-table-status"
		>
			{status?.name}
		</div>
	);
};