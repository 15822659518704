import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckListLink} from '@tehzor/tools/utils/links';
import {getCheckList} from '@src/store/modules/app/links/actions/getCheckList';

export const CheckListEntityLink = ({checkListId, objectId, spaceId}: Record<string, string>) => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();
	const checkList = useAppSelector(s => s.app.links.checkListId?.[checkListId]);

	useAsync(async () => {
		await Promise.all([dispatch(getCheckList(checkListId))]);
	}, [checkListId]);

	const handleClick = useCallback(() => {
		pushPath(
			formCheckListLink({listId: checkListId, objectId, spaceId, stage: checkList!.stage})
		);
	}, [checkList, checkListId, objectId, spaceId]);

	if (!checkList) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`Чек-лист "${checkList.name}"`}
			leftIcon={<i className="tz-external-link-16"/>}
			onClick={handleClick}
			type="filled"
		/>
	);
};
