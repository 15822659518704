import React, {memo} from 'react';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import {desktopColumns} from './components/table/columns.desktop';
import {mobileColumns} from './components/table/columns.mobile';
import {Table} from './components/table/Table';
import './DocumentsPage.less';

export const DocumentsPage = memo(() => {
	useAppHeader({title: 'Документы'});
	const isDesktop = useIsDesktop();

	return (
		<div className="page-cont documents-page">
			<PageBreadcrumbs />
			<LoadingPanel className="documents-page__loading-panel">
				<Plate
					className="documents-page__plate"
					withoutPadding
				>
					<Table columns={isDesktop ? desktopColumns : mobileColumns} />
				</Plate>
			</LoadingPanel>
		</div>
	);
});
