import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {ISavingProblemQuery} from '@src/core/hooks/mutations/problem/useAddProblem';
import {convertLocalAttachments} from '@src/utils/convertLocalAttachments';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';

export const convertProblem = (
	problem: ILinkedProblem | undefined,
	usersMap: Record<string, IBriefUser>
): IPreparedProblem | undefined => {
	if (!problem) {
		return undefined;
	}

	let localAttachments: IAttachment[] | undefined;
	if (problem.localNumber) {
		localAttachments = convertLocalAttachments<ISavingProblemQuery>(
			problem.id,
			problemsQueryKeys
		);
	}

	return {
		...problem,
		attachments: localAttachments || problem.attachments,
		createdBy: problem.createdBy ? usersMap[problem.createdBy] : undefined,
		modifiedBy: problem.modifiedBy ? usersMap[problem.modifiedBy] : undefined
	};
};
