/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable no-lonely-if */
import {useMemo} from 'react';
import {IEnrichedTaskForScheduler} from '@src/pages/TasksPage/hooks/useEnrichedTasksForSchedule';
import {ITasksSettingsState, TasksScheduleCollectMode} from '@src/store/modules/settings/pages/tasks/interfaces';
import {filterTasksInMonth, filterTasksInYear, getMaxDaysInMonth, getMonthIndex, inUnAssigned, monthsName} from './timeMonthUtils';
import {IGroupTask, groupTasksByStatusAndType} from './groupTasksByStatusAndType';
import arrayToTree from 'array-to-tree';
import {calculateDayIndexInYear} from './timeDayUtils';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

export interface IScheduleData {
	id: string;
	objectId?: string;
	name: string;
	parentId?: string;
	tasks: ITasksForSchedule;
	subRows?: IScheduleData[];
	leaderName?: string;
	respPosition?: string;
	activeGroupId?: string;
	respUsers?: IBriefUser[];
}

interface ITasksForSchedule {
	tasksForMonthCell:	Record<string, IGroupTask[]>;
	tasksForDayCell:	IDayCellData;
	tasksForUnAssignedCell:	IGroupTask[];
	maxTasksCountInRow:	number;
}

export interface ITaskDayCell extends IEnrichedTaskForScheduler {
	rowSpan: number;
	duration: number;
	position: [number, number];
}

export interface ITasksDayCell {
	visibleTasks: ITaskDayCell[];
	unvisibleTasks: IEnrichedTaskForScheduler[];
}

export interface IDayCellData {
	tasks: ITasksDayCell[];
	maxCountInRow: number;
}

function arrayFromObject(obj: Record<string, unknown>) {
	const arr = [];
	const keys = Object.keys(obj);

	for (const key of keys) {
		arr.push(obj[key]);
	}

	return arr;
}

/**
 * Фильтрует задачи по id объекта и возвращает объект с финальным отображением для таблицы
 *
 * @param tasks задачи
 * @param objectId id объекта
 * @param settings настройки ITasksSettingsState
 */
const tasksFilter = (
	tasks: IEnrichedTaskForScheduler[],
	settings: ITasksSettingsState
): ITasksForSchedule => {
	const {currentMode, year, month} = settings;

	let unAssignedTasks: IEnrichedTaskForScheduler[] = [];
	const tasksForMonthCell = {} as Record<string, IGroupTask[]>;
	let tasksForDayCell: IDayCellData = {
		tasks: [],
		maxCountInRow: 0
	};
	let tasksForUnAssignedCell: IGroupTask[] = [];
	let maxTasksCountInRow = 0;

	const tasksInYear = filterTasksInYear({tasks, year});
	const tasksInMonth = filterTasksInMonth({tasks: tasksInYear, month});

	tasksInMonth.sort((a, b) => Number(a.modifiedAt) - Number(b.modifiedAt));

	if (currentMode === 'year') {
		// Создаём неназначенные задачи
		unAssignedTasks = tasksInYear.filter(inUnAssigned);

		for (const monthId of monthsName) {
			const tasksInMonth = filterTasksInMonth({tasks: tasksInYear, month: getMonthIndex(monthId)});
			const monthIndex = getMonthIndex(monthId);
			const groupedMonthTasks = groupTasksByStatusAndType(tasksInMonth);

			if (maxTasksCountInRow < groupedMonthTasks.length) {
				maxTasksCountInRow = groupedMonthTasks.length;
			}

			tasksForMonthCell[monthIndex] = groupedMonthTasks;
		}
	}

	if (currentMode === 'month') {
		// Создаём неназначенные задачи
		unAssignedTasks = tasksInMonth.filter(inUnAssigned);

		const finalResult: IDayCellData = {
			tasks: [],
			maxCountInRow: 0
		};
		const maxDaysInMonth = getMaxDaysInMonth(year, month);

		for (const currentTask of tasksInMonth) {
			let startTaskDate = new Date(Number(currentTask.taskIntervalStart));
			const endTaskDate = new Date(Number(currentTask.taskIntervalEnd));
			let startDayIndex = startTaskDate.getDate();

			// Если текущий месяц не равен месяцу начала задачи -> устанавливаем начало задачи в 1 день месяца
			if (startTaskDate !== endTaskDate && month !== startTaskDate.getMonth()) {
				startTaskDate = new Date(year, month, 1);
				startDayIndex = startTaskDate.getDate();
			}

			if (!finalResult.tasks[startDayIndex]) {
				finalResult.tasks[startDayIndex] = {
					visibleTasks: [],
					unvisibleTasks: []
				};
			}

			const startDayIndexInArr =	calculateDayIndexInYear(year, startTaskDate);
			const endDayIndexInArr =	calculateDayIndexInYear(year, endTaskDate);

			let duration = (endDayIndexInArr - startDayIndexInArr) + 1;
			const rowSpan = calculateRowSpan(finalResult.tasks, startDayIndex, duration);

			if (finalResult.maxCountInRow < rowSpan) {
				finalResult.maxCountInRow = rowSpan;
			}

			// Если таска не помещается в строке, сетаем в unvisibleTasks
			if (rowSpan !== -1) {
				let endTaskPosition = startDayIndex + duration;

				// Если	выходит за пределы, расчитываем длительность
				if (endTaskPosition > maxDaysInMonth) {
					endTaskPosition = maxDaysInMonth;
					duration = maxDaysInMonth - startDayIndex + 1;
				}

				// Сетаем в начало таски
				finalResult.tasks[startDayIndex].visibleTasks.push({
					...currentTask,
					rowSpan,
					duration,
					position: [startDayIndex, startDayIndex + duration]
				});
			} else {
				finalResult.tasks[startDayIndex].unvisibleTasks.push(currentTask);
			}
		}

		tasksForDayCell = finalResult;
	}

	// Группируем неназначенные задачи
	tasksForUnAssignedCell = groupTasksByStatusAndType(unAssignedTasks);

	return {tasksForMonthCell, tasksForDayCell, tasksForUnAssignedCell, maxTasksCountInRow};
};

/**
 * Высчитывает позицию задачи в строке 
 *
 * @param tasks задачи ITasksDayCell[]
 * @param place место в месяце
 * @param duration длительность задачи
 */
function calculateRowSpan(
	tasks:	ITasksDayCell[],
	place: number,
	duration: number
): number {
	let rowSpan = 1;

	const reducedTasks = tasks.reduce((acc, tasks) => [...acc, ...tasks.visibleTasks], []);

	// Если задача пересекается в своей строке ( rowSpan ), то увеличиваем rowSpan
	for (let i = 0; i < 4; i++) {
		const isIntersection = calculateIntersection(reducedTasks, place, duration, rowSpan);

		if (i === 3 && isIntersection) {
			rowSpan = -1;
			break;
		}

		if (isIntersection) {
			rowSpan++;
			continue;
		}

		break;
	}

	return rowSpan;
}

/**
 * Высчитывает занято ли место, по длительности задачи и ее местоположения
 *
 * @param tasks задачи ITasksDayCell[]
 * @param place место в месяце
 * @param duration длительность задачи
 * @param rowSpan длительность задачи
 */
const calculateIntersection = (
	tasks: ITaskDayCell[],
	place: number,
	duration: number,
	rowSpan: number
): boolean => {
	// Фильтруем задачи по rowSpan, для того что бы посмотреть пересечения в этой строке
	const filteredTasks = tasks.filter(t => t.rowSpan === rowSpan);

	if (!filteredTasks.length) return false;

	// В зависимости от длины задачи и ее местоположения высчитываем пересечения
	return !!filteredTasks.find(t => {
		const startDayIndex = t.position[0];

		if (startDayIndex < place) {
			return t.duration > (place - startDayIndex);
		}
		if (startDayIndex > place) {
			return duration > (startDayIndex - place);
		}
		if (startDayIndex === place) {
			return true;
		}

		return false;
	});
};

/**
 * Преобразовывает задачи в необходимый для вывода формат
 *
 * @param settings настройки для таблицы
 * @param collectMode текущая фильтрация таблицы по объектам / рабочим группам
 * @param tasks массив задач
 */
export const convertTasksToScheduleFormat = (
	settings: ITasksSettingsState,
	collectMode: TasksScheduleCollectMode,
	tasks: IEnrichedTaskForScheduler[] = []
): IScheduleData[] => useMemo<IScheduleData[]>(() => {
	tasks.sort((a, b) => a.name.localeCompare(b.name));
	const tasksMap: Record<string, IScheduleData> = {};

	if (collectMode === TasksScheduleCollectMode.OBJECT) {
		for (const task of tasks) {
			if (!task.objectId?.id) {
				continue;
			}

			if (!tasksMap.hasOwnProperty(task.objectId.id)) {
				tasksMap[task.objectId.id] = {
					id: task.objectId.id,
					objectId: task.objectId.id,
					name: task.objectId.name,
					parentId: task.objectId.parentId,
					tasks: {} as ITasksForSchedule
				};
			}
		}
	} else {
		for (const task of tasks) {
			if (task?.activeGroup?.id) {
				if (!tasksMap.hasOwnProperty(task.activeGroup.id)) {
					tasksMap[task.activeGroup.id] = {
						id: task.activeGroup.id,
						activeGroupId: task.activeGroup.id,
						name: task.activeGroup?.name,
						parentId: task.activeGroup?.parentId,
						tasks: {} as ITasksForSchedule,
						leaderName: task.activeGroupLeader?.fullName,
						objectId: task?.activeGroup?.objects ? task.activeGroup.objects[0] : undefined,
						respUsers: task?.respUsers
					};
				}
			}
			if (task?.respUsers && task?.respUsers?.length > 0) {
				for (const user of task.respUsers) {
					const key = task?.activeGroup?.id ? `${task.activeGroup.id}_${user.id}` : user.id;
					if (!tasksMap.hasOwnProperty(key)) {
						tasksMap[key] = {
							id: user.id,
							activeGroupId: user.id,
							name: user.displayName,
							parentId: task?.activeGroup?.id,
							tasks: {} as ITasksForSchedule,
							respPosition: user.position,
							objectId: task?.activeGroup?.objects ? task.activeGroup.objects[0] : undefined,
							respUsers: task?.respUsers
						};
					}
				}
			}
		}
	}

	const tasksIds = Object.keys(tasksMap);

	for (const taskId of tasksIds) {
		let currentTasks = [];

		if (collectMode === TasksScheduleCollectMode.OBJECT) {
			currentTasks = tasks.filter(el => el.objectId.id === taskId);
		} else {
			currentTasks = tasks.filter(el => el?.activeGroup?.id === taskId || !!el.respUsers?.filter(u => {
				const key = el?.activeGroup?.id ? `${el.activeGroup.id}_${u.id}` : u.id;
				return key === taskId;
			}).length);
		}

		tasksMap[taskId].tasks = tasksFilter(currentTasks, settings);
	}

	const tasksArr = arrayFromObject(tasksMap) as IScheduleData[];

	return arrayToTree(tasksArr, {
		parentProperty: 'parentId',
		childrenProperty: 'subRows'
	});
}, [settings, tasks, collectMode]);