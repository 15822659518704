import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {MobileFilter, SelectSearch} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {extractUsersAsArray} from '@src/store/modules/dictionaries/users/selectors';

interface IRespUsersFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const RespUsersFilterMobile = memo(
	({objectId, value, entity, onChange}: IRespUsersFilterMobileProps) => {
		const [selectedUsers, setSelectedUsers] = useState(value);
		const [search, setSearch] = useState('');

		const users = useAppSelector(extractUsersAsArray);
		const elements = useMemo(
			() => users.map(user => ({id: user.id, name: user.fullName})),
			[users]
		);
		const filteredElements = useMemo(
			() => flatFilter(elements, 'name', search),
			[search, users]
		);

		useUpdateEffect(() => {
			setSelectedUsers(value);
		}, [value]);

		const handleExpand = useFilterPageTransition(objectId, entity, 'respusers');

		const handleChange = useCallback((v: string[] | undefined) => {
			onChange({respUsers: v});
		}, []);

		return (
			<MobileFilter
				label="Ответственные"
				elements={filteredElements}
				selected={selectedUsers}
				onChange={handleChange}
				onExpand={handleExpand}
				search={(
					<SelectSearch
						value={search}
						onChange={setSearch}
					/>
				)}
			/>
		);
	}
);
