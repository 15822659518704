import CategoriesSetFilterPage from '@src/pages/filters/CategoriesSetFilterPage';
import {CheckListIdsFilterPage} from '@src/pages/filters/CheckListIdsFilterPage';
import {CheckListStatusesFilterPage} from '@src/pages/filters/CheckListStatusesFilterPage';
import SpacesProblemStatusesFilterPage from '@src/pages/filters/SpacesProblemStatusesFilterPage';
import {StructureTypesFilterPage} from '@src/pages/filters/StructureTypesFilterPage';
import {StructuresFiltersPage} from '@src/pages/filters/StructuresFiltersPage';
import {WorkAcceptancesStatusesFilterPage} from '@src/pages/filters/WorkAcceptancesStatusesFilterPage';
import React from 'react';

export const structuresFilterRoutes = [
	{
		path: 'types',
		element: <StructureTypesFilterPage/>
	},
	{
		path: 'problemStatuses',
		element: <SpacesProblemStatusesFilterPage/>
	},
	{
		path: 'workAcceptanceStatuses',
		element: <WorkAcceptancesStatusesFilterPage/>
	},
	{
		path: 'categories',
		element: <CategoriesSetFilterPage/>
	},
	{
		path: 'checkListStatuses',
		element: <CheckListStatusesFilterPage/>
	},
	{
		path: 'checkListIds',
		element: <CheckListIdsFilterPage/>
	},
	{
		path: '',
		element: <StructuresFiltersPage/>
	}
];
