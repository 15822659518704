import React, {Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {extractObjectsAsArray} from '@src/store/modules/dictionaries/objects/selectors';

interface IObjectSelectProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: string;
	value?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const getInputLabel = (
	value: string | undefined,
	types: IObject[],
	typesMap: Record<string, IObject>
) => {
	if (!value) {
		return undefined;
	}

	if (typesMap[value]) {
		return typesMap[value].name;
	}

	return undefined;
};

export const ObjectSelect = <S, E>({
	className,
	style,
	field,
	label = 'Объект',
	value,
	editingDispatch,
	required,
	disabled,
	hasError
}: IObjectSelectProps<S, E>) => {
	const types = useAppSelector(extractObjectsAsArray);
	const typesMap = useAppSelector(s => s.dictionaries.objects.byId);
	const handleChange = useCallback(
		(v: string | string[]) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={getInputLabel(value, types, typesMap)}
						icon={<i className="tz-outline-object-20"/>}
						error={required && hasError ? 'Выберите объект' : undefined}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{types.map(type => (
						<SelectOption
							key={type.id}
							itemKey={type.id}
							content={type.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
