import React, {useCallback} from 'react';
import classNames from 'classnames';
import {getMonth, isSameDay, isToday} from 'date-fns';
import CalendarButton from '../../../buttons/CalendarButton';
import {IDayProps} from '../../Calendar/components/Day';

export interface ISelectableDayProps extends Partial<IDayProps> {
	value?: Date;
	onClick: (date: Date) => void;
	disabledDate?: (date: Date) => boolean;
}

const SelectableDay = (props: ISelectableDayProps) => {
	const {
		date,
		day,
		activeMonth,
		value,
		onClick,
		disabledDate
	} = props;

	const handleClick = useCallback(() => {
		if (date) {
			onClick(date);
		}
	}, [date, onClick]);

	const isDisabled = useCallback(
		(d: Date) => {
			if (!disabledDate) return false;
			return disabledDate(d);
		},
		[disabledDate]
	);

	return date ? (
		<CalendarButton
			key={day}
			className={classNames(
				'calendar__day',
				{calendar__day_today: isToday(date)}
			)}
			active={value !== undefined ? isSameDay(value, date) : false}
			disabled={isDisabled(date)}
			inactive={getMonth(date) !== activeMonth}
			onClick={handleClick}
		>
			{day}
		</CalendarButton>
	) : null;
};

export default SelectableDay;
