import React, {useCallback, useRef, useState} from 'react';
import './AddingCheckDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IAddingProblemRefProps} from '../AddingProblem';
import {IAddingInspectionRefProps} from '../AddingInspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {useEntityType} from './hooks/useEntityType';
import {useAvailableTypes} from './hooks/useAvailableTypes';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {ISavingProblem} from '@tehzor/tools/interfaces/problems/ISavingProblem';
import {ISavingInspection} from '@tehzor/tools/interfaces/inspections/ISavingInspection';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {useCheckMaps} from './hooks/useCheckMaps';
import {extractObjectStagesAsArray} from '@src/store/modules/dictionaries/objectStages/selectors';
import {saveCheck} from './utils/saveCheck';
import {useAddCheck} from '@src/core/hooks/mutations/checks/useAddCheck';
import {useLocalChecks} from '@src/core/hooks/queries/checks/useGetChecks';
import {getUserCheck} from '@src/store/modules/dictionaries/latestUserChecks/actions/getOne';
import {extractObject} from '@src/store/modules/dictionaries/objects/selectors';

interface IAddingCheckDialogProps {
	objectId: string;
	checkId?: string;
	links?: ICheck['links'];
	types?: ICheckAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	problemToCopyId?: string;
	createdBy?: string;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
	title: string;
}
const AddingCheckDialog = (props: IAddingCheckDialogProps) => {
	const {
		objectId,
		checkId,
		links,
		types,
		isOpen,
		problemToCopyId,
		createdBy,
		onSuccess,
		onClose,
		title
	} = props;

	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const object = useAppSelector(s => extractObject(s, objectId));
	const dispatch = useAppDispatch();
	const [selectedCheckId, setSelectedCheckId] = useState<string | undefined>(checkId);

	const localChecks = useLocalChecks();
	const localChecksIds = localChecks.map(check => check.id);
	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingInspectionRefProps>(null);

	const availableTypes = useAvailableTypes(objectId, types, {createdBy});
	const type = useEntityType(objectId, availableTypes);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const stage = ObjectStageIds.BUILDING;
	const stages = useAppSelector(extractObjectStagesAsArray);
	const [contentMap, entitiesSelectMapProps] = useCheckMaps({
		objectId,
		checkId,
		stage,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedCheckId,
		setSelectedCheckId,
		defaultProblemData: props.defaultProblemData,
		defaultInspectionData: props.defaultInspectionData,
		onClose
	});
	const addCheck = useAddCheck(object);
	const [, handleSave] = useAsyncFn(async () => {
		const addNewCheck = () =>
			addCheck({
				objectId,
				links,
				stage
			});
		const updateLatest = async () => {
			if (online && selectedCheckId && !localChecksIds.includes(selectedCheckId)) {
				await dispatch(getUserCheck(objectId, selectedCheckId));
			}
		};

		await saveCheck({
			addingEntityRef,
			selectedCheckId,
			localChecksIds,
			updateLatest,
			online,
			addNewCheck,
			setSaving,
			success,
			onClose
		});
	}, [addingEntityRef, selectedCheckId, objectId, links, stage, online, success, onClose]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [addingEntityRef, onClose]);

	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [type, onSuccess]);

	return (
		<Dialog
			className={{
				root: 'adding-check-dialog',
				header: 'adding-check-dialog__header',
				body: 'adding-check-dialog__body'
			}}
			isOpen={isOpen}
			title={title}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
			onAfterClose={handleAfterClose}
		>
			<EntitySelectContainer
				contentMap={contentMap}
				entitiesSelectMapProps={entitiesSelectMapProps}
				stages={stages}
				selectedStage={stage}
			/>
		</Dialog>
	);
};

export default AddingCheckDialog;
