import {useEffect, useMemo} from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {getSpacesSchema} from '@src/store/modules/entities/spaces/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentTreeObject} from '@src/store/modules/dictionaries/objects/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getSpacesProblemsData} from '@src/store/modules/pages/spaces/actions/getSpacesProblemsData';
import {getSpacesCheckListsData} from '@src/store/modules/pages/spaces/actions/getSpacesCheckListsData';
/**
 * Загрузка помещения для шахматок после загрузки объектов,
 * чтобы было понятно для каких объектов грузить шахматки.
 *
 * @param pageObjectId id объекта страницы
 */
export function useSchemasLoad(pageObjectId: string) {
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const objectsLoaded = useAppSelector(s => s.dictionaries.objects.loaded);
	const treeObject = useAppSelector(s => extractCurrentTreeObject(s, pageObjectId));
	// TODO Пока что для загрузки берутся непосредственные дочерние объекты (логика перенесена из селекторов).
	// После доработок (когда можно будет грузить все объекты со всех уровней),
	// станет возможным использовать селектор ниже
	const targetObjects = useMemo(() => {
		if (treeObject?.children?.length) {
			return treeObject.children.reduce<string[]>((prev, obj) => {
				if (!obj.children?.length) {
					prev.push(obj.id);
				}
				return prev;
			}, []);
		}
		return [pageObjectId];
	}, [pageObjectId, treeObject]);

	const dispatch = useAppDispatch();
	const [, load] = useAsyncFn(
		() =>
			Promise.all(
				targetObjects.map(id =>
					dispatch(getSpacesSchema(id)).then(resolve => {
						if (resolve && online) {
							void dispatch(
								getSpacesProblemsData({objectId: id, spacesIds: resolve.allIds})
							);
							void dispatch(
								getSpacesCheckListsData({
									objectId: id,
									spacesIds: resolve.allIds
								})
							);
						}
					}))
			),
		[pageObjectId, targetObjects, online]
	);

	useEffect(() => {
		if (objectsLoaded) {
			void load();
		}
	}, [objectsLoaded, load]);
}
