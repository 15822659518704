import React from 'react';
import InspectionsFiltersPage from '@src/pages/filters/InspectionsFiltersPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import FiltersPageObjects from '@src/pages/filters/ObjectsFilterPage';
import PlansFilterPage from '@src/pages/filters/PlansFilterPage/PlansFilterPage';

export const inspectionsFilterRoutes = [
	{
		path: 'plans',
		element: <PlansFilterPage/>
	},
	{
		path: 'objects',
		element: <FiltersPageObjects/>
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage/>
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage/>
	},
	{
		path: '',
		element: <InspectionsFiltersPage/>
	}
];
