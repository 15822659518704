import React from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {CheckListPage} from './CheckListPage';
import {useObjectCheckListsHeader} from './hooks/useObjectCheckListsHeader';

export const ObjectCheckListPageWrap = () => {
	const {objectId} = useStrictParams<{objectId: string}>();

	useObjectCheckListsHeader(objectId);

	return <CheckListPage/>;
};
