import * as types from './constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetProblemStatusesResponse} from '@src/api/backend/problemStatuses';
import {queryClient} from '@src/api/QueryClient';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';

export type IGetProblemStatusesPayload = IGetProblemStatusesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetProblemStatusesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке статусов нарушений');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список статусов нарушений
 */
export const getProblemStatuses = () =>
	createApiAction<IGetProblemStatusesResponse>(request, success, failure, () =>
		queryClient.fetchQuery(restDictionariesQueryKeys.problemStatuses(), {
			staleTime: Infinity
		}));
