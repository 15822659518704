import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IProblemComment from '@tehzor/tools/interfaces/problemComments/IProblemComment';
import {requestProblemComments} from '@src/api/backend/problemComments';

export type IGetWarrantyClaimProblemRepliesPayload = INormalizedData<IProblemComment>;

const request = () => ({type: types.GET_PROBLEM_REPLIES_REQUEST});

const success = (response: IGetWarrantyClaimProblemRepliesPayload) => ({
	type: types.GET_PROBLEM_REPLIES_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке ответов на нарушения');
	return {
		type: types.GET_PROBLEM_REPLIES_FAILURE,
		payload: error
	};
};

/**
 * Получает список ответов на нарушения для конкретного помещения
 *
 * @param objectId id объекта
 * @param warrantyClaimId id обращения
 */
export const getWarrantyClaimProblemReplies = (objectId: string, warrantyClaimId: string) =>
	checkExpiration<IState, IGetWarrantyClaimProblemRepliesPayload, ApiAction>(
		s => s.entities.warrantyClaim.problemReplies,
		createApiAction<IGetWarrantyClaimProblemRepliesPayload>(request, success, failure, () =>
			requestProblemComments(
				{
					objects: [objectId],
					warrantyClaims: [warrantyClaimId],
					official: true
				},
				{createdAt: false},
				0,
				1000
			).then(({byId, allIds}) => ({
				byId,
				allIds
			})))
	);
