import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {CircleButton} from '@tehzor/ui-components';
import {isCheckItem} from '../../../RecordStatusSelect';
import {extractCheckRecordStatusesByEntityType} from '@src/store/modules/dictionaries/checkRecordStatuses/selectors';
import classNames from 'classnames';

interface IItemButtonProps {
	onClick: () => void;
	record?: ICheckRecord;
	item: ICheckItem;
	status: CheckRecordStatusId;
	icon: React.ReactElement;
	disabled?: boolean;
}

export const ItemButton = ({onClick, record, status, icon, item, disabled}: IItemButtonProps) => {
	const statuses = useAppSelector(s =>
		extractCheckRecordStatusesByEntityType(s, isCheckItem(item) ? 'check-item' : 'check-list'));

	const isAvailable = record?.status
		? statuses.byId[status].transitions?.includes(record.status)
		: true;

	return isAvailable ? (
		<div
			className={classNames('check-list-items__item-actions-btn-wrapper', {
				'check-list-items__item-actions-btn-wrapper_disabled': disabled
			})}
			role="presentation"
			onClick={onClick}
		>
			<CircleButton className="check-list-items__item-actions-btn">{icon}</CircleButton>
		</div>
	) : null;
};
