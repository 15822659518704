import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useAsync} from 'react-use';
import {LinkButton} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formTaskLink} from '@tehzor/tools/utils/links';
import {getTask} from '@src/store/modules/pages/task/actions';

export const TaskLink = ({taskId}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const task = useAppSelector(s => s.pages.task.data);

	useAsync(async () => {
		await Promise.all([dispatch(getTask(taskId))]);
	}, [taskId]);

	const handleClick = useCallback(() => {
		pushPath(formTaskLink(taskId));
	}, [taskId]);

	if (!task) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`Задача ${task?.name}`}
			leftIcon={<i className="tz-external-link-16"/>}
			onClick={handleClick}
			type="filled"
		/>
	);
};
