import React, {memo} from 'react';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ItemBullet} from '@src/pages/CheckListPage/components/list/CheckListItems/components/ItemBullet';
import {ItemHeaderActions} from '@src/pages/CheckListPage/components/actions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {format} from 'date-fns';
import {DescriptionPopUp} from './DescriptionPopUp';
import {DescriptionDialog} from './DescriptionDialog';
import {dateTimeLetterSeparatedFormat} from '@tehzor/tools/utils/dateFormats';

interface IItemHeaderProps {
	item: ICheckItem;
	record?: ICheckRecord;
	canAddEntity?: boolean;
	canEdit?: boolean;
}

export const ItemHeader = memo(({item, record, canAddEntity, canEdit}: IItemHeaderProps) => {
	const isDesktop = useIsDesktop();
	return (
		<div className="check-list-items__item-header">
			<ItemBullet
				item={item}
				record={record}
			/>

			{item.description ? (
				isDesktop ? (
					<DescriptionPopUp item={item} />
				) : (
					<DescriptionDialog item={item} />
				)
			) : (
				<div className="check-list-items__item-header-title">{item.name}</div>
			)}

			{record && record.modifiedAt ? (
				<div className="check-list-items__item-header-subtitle">
					<div className="check-list-items__item-header-subtitle_accepted">
						<p>
							{record.status === CheckRecordStatusId.ACCEPTED
								? 'Принято'
								: 'Изменено'}
						</p>
						<p>{format(record.modifiedAt, dateTimeLetterSeparatedFormat)}</p>
						<p className="check-list-items__item-header-subtitle-name">
							{record?.modifiedBy?.displayName}
						</p>
					</div>
				</div>
			) : null}

			<div className="check-list-items__item-header-actions">
				<ItemHeaderActions
					canAddEntity={canAddEntity}
					canEdit={canEdit}
					checkItemId={item.id}
					checkListId={item.checkListId}
				/>
			</div>
		</div>
	);
});
